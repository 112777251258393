import React, { useState } from "react";
import { Button, List, ListItem, ListItemText, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginRight: theme.spacing(2),
  },
}));

const StringList = ({ label, entries, onChange, onBlur, withDisplayName }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [strings, setStrings] = useState(_.isArray(entries) ? entries : [entries]);

  const handleAdd = () => {
    if (inputValue.trim() !== "") {
      const newStrings = [...strings, { displayName, value: inputValue }];
      setStrings(newStrings);
      setInputValue("");
      setDisplayName("");
      onChange(newStrings);
      onBlur(newStrings);
    }
  };

  const handleRemove = index => {
    setStrings(prevStrings => {
      const newStrings = prevStrings.filter((_, i) => i !== index);
      onChange(newStrings);
      setTimeout(() => {
        onBlur(newStrings);
      }, 200);
      return newStrings;
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <TextField
          value={displayName}
          onChange={e => setDisplayName(e.target.value)}
          label="Display Name"
          disabled={!withDisplayName}
          className={classes.textField}
        />
        <TextField
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          label={label}
          className={classes.textField}
        />
      </div>
      <Button onClick={handleAdd} variant="contained" color="primary" style={{ marginBottom: "8px" }}>
        ADD
      </Button>

      <List>
        {strings.map((str, index) => (
          <ListItem key={index}>
            {withDisplayName && <ListItemText primary={str.displayName} />}
            <ListItemText primary={str.value} />
            <Button onClick={() => handleRemove(index)} variant="contained" color="secondary" className="remove-button">
              REMOVE
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default StringList;
