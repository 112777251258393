import _ from "lodash";
import { ProposalTableFilters } from "../types";
import { SelectOption } from "../../../global";
import { logError } from "../../../utils/logger";
import { convertToDate, formatCurrency } from "../../../utils";
import { Proposal } from "@trnsact/trnsact-shared-types";

export function filterTableDataByFiltersValues(data: Proposal[], filters: ProposalTableFilters) {
  if (!Array.isArray(data) || !data?.length) return data;

  const isFiltersEmpty = !Object.values(filters).filter(Boolean).length;

  if (isFiltersEmpty) return data;

  const filterAction: Record<keyof ProposalTableFilters, (task: any) => boolean> = {
    status: proposal => filters.status.includes("all") || filters.status.includes(proposal?.status),
    search: proposal =>
      proposal?.businessName?.toLowerCase().includes(filters.search.toLowerCase()) ||
      proposal?.contactName?.toLowerCase().includes(filters.search.toLowerCase()) ||
      proposal?.amountRequested?.toLowerCase().includes(filters.search.toLowerCase()) ||
      proposal?.salesPersonName?.toLowerCase().includes(filters.search.toLowerCase()),
  };

  return data.filter(row => Object.values(filterAction).every(fn => fn(row)));
}

export function mapProposalToCreditSubmissionTableData(data: Proposal[] = []) {
  if (data && _.isArray(data)) {
    try {
      return data.map((proposal: any) => ({
        ...proposal,
        contactName: proposal.fullName || `${proposal.firstName} ${proposal.lastName}`,
        amountRequested: formatCurrency(proposal.amountRequested),
        createdDateTime: convertToDate(proposal.createdDateTime),
      }));
    } catch (error) {
      logError(error);
    }
  }

  return [];
}

// TODO check why Proposal interface does not fit this transformation - code was copied from - VOProposalList
export function mapProposalToVendorOpportunitiesTableData(data: any[] = []) {
  if (data && _.isArray(data)) {
    data.forEach(item => {
      try {
        item.contactName = item.firstName ? item.firstName + item.lastName : item.fullName;
        item.amountRequested = formatCurrency(item.amountRequested);
        item.createdDateTime = convertToDate(item.createdDateTime);

        if (item.paymentId && item.lenderPaymentOptions && item.lenderPaymentOptions.length > 0) {
          const acceptedPayment = item.lenderPaymentOptions.find((lpo: any) => {
            return lpo.paymentId === item.paymentId;
          });
          item.acceptedPayment = `Payment Amount: ${formatCurrency(acceptedPayment.payment)}
            Payment Term: ${acceptedPayment.term}`;
        } else {
          item.acceptedPayment = "N/A";
        }
      } catch (error) {
        logError(error);
      }
    });

    return data;
  }

  return [];
}

export function getStatusUniqueOptions(data: Proposal[] = []): SelectOption[] {
  const uniqueOptionsRecord = data.reduce<Record<string, string>>((acc, { status }) => {
    if (status && !acc?.[status]) acc[status] = status || "None";

    return acc;
  }, {});

  return Object.entries(uniqueOptionsRecord).map(([label, value]) => ({ label, value }));
}
