import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import MessageModal from "../../components/MessageModal";
import Tile from "../../components/Tile/Tile";
import { CreditSubmissionsDecisionForm_V2 } from "./CreditSubmissionsDecisionForm_V2";
import { makeStyles } from "@material-ui/core/styles";

const GET_CREDIT_SUBMISSIONS = gql`
  query($csId: ID!) {
    creditSubmission(dynamicsId: $csId) {
      voId
      submissionDate
      decision
      amountRequested
      approvedAmount
      approvedRate
      transactionSummary
    }
  }
`;

export const CreditSubmissionsCard_V2 = ({ cs }) => {
  const classes = useStyles();

  const [getCreditSubmissions, { error, refetch: refetchCreditSubmission }] = useLazyQuery(GET_CREDIT_SUBMISSIONS);

  if (error) console.log(`Error! ${error.message}`);

  // Fetch CreditSubmissions for the very first time:
  useEffect(() => {
    if (cs) {
      getCreditSubmissions({
        variables: {
          csId: cs.creditSubId,
        },
      });
    }
  }, []);

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});

  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };

  const handleOpenModal = () => {
    setOpenMessageModal(true);
  };

  const handleSubmissionMessage = message => {
    setSubmissionMessage(message);
  };

  return (
    <>
      <Tile title="Decision" className={classes.card}>
        <CreditSubmissionsDecisionForm_V2
          cs={cs}
          refetch={refetchCreditSubmission}
          handleOpenModal={handleOpenModal}
          handleSubmissionMessage={handleSubmissionMessage}
        />
      </Tile>

      <MessageModal
        isOpen={openMessageModal}
        title={submissionMessage.title}
        handleCloseModal={handleCloseModal}
        message={submissionMessage.message}
      />
    </>
  );
};

const useStyles = makeStyles({
  card: {
    margin: 0,
  },
});
