import {
  CHAT_V2_ADDED_PARTICIPANT,
  CHAT_V2_CONVERSATIONS,
  CHAT_V2_FETCHED_CONVERSATION,
  CHAT_V2_OPEN,
  CHAT_V2_RELATED_TASK_ID,
  CHAT_V2_REMOVE_PARTICIPANT,
  CHAT_V2_RESET,
  CHAT_V2_RESET_CONVERSATIONS,
  CHAT_V2_SELECTED_CONVERSATION_SID,
  CHAT_V2_SET_CREDIT_APP,
  CHAT_V2_SET_RECIPIENTS_LIST,
  CHAT_V2_SET_TARGET_RECIPIENT,
  CHAT_V2_TASKS,
} from "./types";
const initialState = {
  open: false,
  relatedTaskId: null,
  selectedConversationSid: null,
  conversations: [],
  tasks: [],
  targetRecipient: null,
};

export function chatV2Rducer(state = initialState, action) {
  switch (action.type) {
    case CHAT_V2_OPEN:
      return {
        ...state,
        open: action.payload,
      };
    case CHAT_V2_RELATED_TASK_ID:
      return {
        ...state,
        relatedTaskId: action.payload,
      };
    case CHAT_V2_SELECTED_CONVERSATION_SID:
      return {
        ...state,
        selectedConversationSid: action.payload,
      };
    case CHAT_V2_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
      };
    case CHAT_V2_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };
    case CHAT_V2_FETCHED_CONVERSATION:
      return {
        ...state,
        conversations: state.conversations.map(conv => {
          if (conv.sid === action.payload.sid) {
            return action.payload;
          }

          return conv;
        }),
      };
    case CHAT_V2_RESET:
      return {
        ...state,
        selectedConversationSid: null,
        open: false,
      };
    case CHAT_V2_SET_CREDIT_APP:
      return {
        ...state,
        creditApp: action.payload,
      };
    case CHAT_V2_RESET_CONVERSATIONS:
      return {
        ...state,
        conversations: [],
      };
    case CHAT_V2_REMOVE_PARTICIPANT:
      const { conversationSid, participantSid } = action.payload;

      return {
        ...state,
        conversations: (state.conversations || []).map(conversation => {
          if (conversation.sid === conversationSid) {
            return {
              ...conversation,
              participants: conversation.participants.filter(participant => {
                return participant.sid !== participantSid;
              }),
            };
          }

          return conversation;
        }),
      };
    case CHAT_V2_ADDED_PARTICIPANT:
      const { participant } = action.payload;

      return {
        ...state,
        conversations: (state.conversations || []).map(conversation => {
          if (conversation.sid === action.payload.conversationSid) {
            return {
              ...conversation,
              participants: conversation.participants.concat(participant),
            };
          }

          return conversation;
        }),
      };
    case CHAT_V2_SET_TARGET_RECIPIENT:
      return {
        ...state,
        targetRecipient: action.payload,
      };
    case CHAT_V2_SET_RECIPIENTS_LIST:
      return {
        ...state,
        recipientsList: action.payload,
      };
    default:
      return state;
  }
}
