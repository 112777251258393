import React from "react";
import { FormInputSelect } from "../../../../../../../components/form";
import { formatCurrency, formatNumberWithDelimiter } from "../../../../../../../utils";

interface Props {
  configuration: Record<"addons" | "coverage" | "deductible", any>;
  uniqueDeductibles: any[];
  handleMileageChange: any;
  disableAllFields: boolean;
  handleDeductibleChange: any;
  filteredMileageOptions: any[];
}

export const Fields = ({
  configuration,
  disableAllFields,
  uniqueDeductibles,
  handleMileageChange,
  filteredMileageOptions,
  handleDeductibleChange,
}: Props) => {
  const handleChangeDeductible = (value: string) => {
    const selectedAmount = parseInt(value, 10);
    handleDeductibleChange(selectedAmount);
  };

  const handleChangeCoverage = (value: string) => {
    const selectedIndex = parseInt(value, 10);
    const selectedTerm = filteredMileageOptions[selectedIndex];
    handleMileageChange(selectedTerm);
  };

  return (
    <>
      <FormInputSelect
        variant="standard"
        label="Deductible"
        disabled={disableAllFields}
        onChange={handleChangeDeductible}
        value={configuration.deductible || ""}
        options={[
          { label: "Select Deductible", value: "" },
          ...uniqueDeductibles.map((amount: any) => ({
            label: formatCurrency(amount),
            value: amount!.toString(),
          })),
        ]}
      />

      {configuration.deductible && (
        <FormInputSelect
          label="Coverage"
          variant="standard"
          disabled={disableAllFields}
          onChange={handleChangeCoverage}
          value={configuration.coverage ? filteredMileageOptions.indexOf(configuration.coverage) : ""}
          options={[
            { label: "Select Coverage", value: "" },
            ...filteredMileageOptions.map((term: any, index: any) => ({
              label: `${term.termMonths} Months / ${formatNumberWithDelimiter(term.termOdometer)} Miles`,
              value: index.toString(),
            })),
          ]}
        />
      )}
    </>
  );
};
