import React, { FC } from "react";
import { GAIGProductCard, NTPProductCard, SimpleProductCard } from "./products";
import { AftermarketProductTypes, ProposalProductCardProps } from "../../../types";

export const ProposalProductsCard = (props: ProposalProductCardProps) => {
  const productsCards: Record<AftermarketProductTypes, FC<ProposalProductCardProps>> = {
    [AftermarketProductTypes.Simple]: SimpleProductCard,
    [AftermarketProductTypes.Ntp]: NTPProductCard,
    [AftermarketProductTypes.Gaig]: GAIGProductCard,
  };

  const Card =
    productsCards[props.product.aftermarketProduct?.aftermarketVendorApiChannel ?? AftermarketProductTypes.Simple];

  return <Card {...props} />;
};
