import { FinanceProgram, PaymentOptionsConfigurationTermsAndRatesInput } from "@trnsact/trnsact-shared-types";
import { FinanceProgramFormValues, MarkupMethods, Structures } from "../types";
import { prepareMenuOptionsForAPI } from "./prepareMenuOptionsForAPI";

export function convertProgramToCalculateVariables(
  program: FinanceProgramFormValues["financeQuote"] | undefined,
  proposalMenu: any,
  proposalMenuByCurrentTerm: any | null,
  financeProgramDetails: FinanceProgramFormValues["financeProgramDetails"] | undefined
) {
  if (!program) return null;

  const products = proposalMenuByCurrentTerm?.menu ?? proposalMenu;

  const prepareMenu: any = {
    name: proposalMenu?.name,
    description: proposalMenu?.description,
    menuOptions: prepareMenuOptionsForAPI(products?.menuOptions, financeProgramDetails),
  };

  const terms: PaymentOptionsConfigurationTermsAndRatesInput[] = [];

  if (program.specifyRates && program.specifyRates.length > 0) {
    terms.push(
      ...program.specifyRates.map((termValue: any) => ({ term: Number(termValue.term), rate: +termValue.rate }))
    );
  } else {
    terms.push(
      ...program.terms.map(t => {
        return { term: Number(t), rate: +program.commonRate };
      })
    );
  }

  return {
    salesTaxPercentage: 0,
    numberOfAdvancePayments: Number(program.advancePayment),
    financeProgramType: program.programType.toUpperCase(),
    rateType: program.rateType,
    amountFinanced: Number(program.amount) || 0,
    financeProgramName: program.programName,
    downPaymentAmount: Number(program.downPayment) || 0,
    residualPercentage: Number(program.residual),
    menu: proposalMenu ? prepareMenu : undefined,
    customerRate:
      program.structure !== Structures.None && program.markupMethod === MarkupMethods.CustomerRate
        ? Number(program.markupRate)
        : null,
    dealerMarkup:
      program.structure !== Structures.None && program.markupMethod === MarkupMethods.Markup
        ? { identifier: "Dealer Markup", markupPercentage: Number(program.markupRate) }
        : null,
    paymentPeriodTiming: program.paymentCalculationMethod,
    markups: [{ identifier: "Lender Markup", markupPercentage: 0 }],
    terms,
    flatFees: program.fee.map(feeValue => ({
      identifier: feeValue.identifier,
      financed: feeValue.financed,
      fee: Number(feeValue.fee),
    })),
  };
}
