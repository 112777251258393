import React from "react";
import { EntityMobileCard } from "./EntityMobileCard";
import { BaseEntity, CommonPropsForAllViews } from "./types";

interface Props<Entity, CardEntity extends BaseEntity<Entity>> extends CommonPropsForAllViews<Entity, CardEntity> {
  entitiesForRender: CardEntity[];
}

export const EntityMobileCardsNoGroupedView = <Entity, CardEntity extends BaseEntity<Entity>>({
  entitiesForRender,
  ...other
}: Props<Entity, CardEntity>) => {
  return (
    <>
      {!!entitiesForRender?.length &&
        entitiesForRender.map(entity => <EntityMobileCard<Entity, CardEntity> entity={entity} {...other} />)}
    </>
  );
};
