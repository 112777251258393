import React from "react";
import Tile from "../../components/Tile/Tile";

export default function AppError() {
  return (
    <Tile title="There was an error submitting the application.">
      <p>
        Our apologies but it seems there was an error submitting the application. Please try submitting the application
        again.
      </p>
    </Tile>
  );
}
