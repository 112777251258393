import React, { FC, ReactNode } from "react";
import { Box, Checkbox, Typography } from "@material-ui/core";
import { BaseEntity } from "./types";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

interface Props<Entity, CardEntity extends BaseEntity<Entity>> {
  entity: CardEntity;
  actionsPanel?: FC<CardEntity>;
  isSelectEntityDisabled?: boolean;
  onSelectEntity?: (entity: CardEntity) => void;
}

export const EntityMobileCard = <Entity, CardEntity extends BaseEntity<Entity>>({
  entity,
  onSelectEntity,
  isSelectEntityDisabled,
  actionsPanel: ActionsPanel,
}: Props<Entity, CardEntity>) => {
  const classes = useStyles();

  const renderDataForViewDetailsByType: Record<
    "default" | "component",
    (value: ReactNode, Component: FC<{ value: ReactNode }> | undefined) => ReactNode
  > = {
    component: (value, Component) => (Component ? <Component value={value} /> : value),
    default: value => (
      <Typography content="span" variant="body2" color="textPrimary">
        {value ?? "—"}
      </Typography>
    ),
  };

  return (
    <Box className={classes.wrapper}>
      {ActionsPanel && (
        <Box className={classes.actionsPanelWrapper}>
          <ActionsPanel {...entity} />
        </Box>
      )}

      <Box className={classes.titleWrapper}>
        <Box className={classes.title}>
          {entity?.dateAndTime && (
            <Typography content="span" variant="caption" color="textSecondary">
              {entity.dateAndTime}
            </Typography>
          )}
        </Box>

        <Box className={classes.titleWithCheckbox}>
          {entity?.titleIcon && entity.titleIcon}

          {onSelectEntity && (
            <Checkbox
              size="small"
              color="primary"
              checked={entity?.isSelected}
              disabled={isSelectEntityDisabled}
              style={{ width: "20px", padding: 0 }}
              onChange={() => onSelectEntity(entity)}
            />
          )}

          <Typography content="span" variant="body1">
            {entity.title}
          </Typography>
        </Box>
      </Box>

      {entity.dataForView?.length && (
        <Box className={classes.list}>
          {entity.dataForView.map(({ label, value, formatter, type, component }) => (
            <Box key={label} className="listItem">
              <Typography content="span" variant="body2" color="textSecondary">
                {label}
              </Typography>

              {renderDataForViewDetailsByType[type](formatter ? formatter(value) : value, component)}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  wrapper: {
    gap: "8px",
    width: "100%",
    padding: "8px",
    display: "flex",
    borderRadius: "4px",
    flexDirection: "column",
    backgroundColor: blueGrey["50"],
  },
  actionsPanelWrapper: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",

    "& .MuiIconButton-root": {
      borderRadius: "4px",
      backgroundColor: "white",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  titleWithCheckbox: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
  },
  title: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
  list: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",

    "& .listItem": {
      gap: "4px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
});
