import React from "react";
import { useSimpleAutoFill } from "../../../../../hooks";
import { FormInput } from "../../../../../../../components/form";
import { factsKeyMapOfFormatters } from "../../../../../constants";
import { SimpleProductsDynamicFieldsProps } from "../../../../../types";
import { floatNumberRegexp } from "../../../../../../../pages/Prequal/constants";

export const NumericField = ({
  fact,
  facts,
  disabled,
  setFacts,
  productConfigurationRulesEngine,
}: SimpleProductsDynamicFieldsProps) => {
  const handleChange = (value: string) => {
    if (floatNumberRegexp.test(value)) {
      const parsedValue = parseFloat(value.replace(/[^0-9.]/g, ""));

      productConfigurationRulesEngine.current.addFact(fact.factKey, parsedValue);
      setFacts((prev: any) => ({ ...prev, [fact.factKey]: parsedValue }));
    }
  };

  useSimpleAutoFill(handleChange, fact);

  return (
    <FormInput
      variant="standard"
      disabled={disabled}
      onChange={handleChange}
      value={factsKeyMapOfFormatters?.[fact.factKey]?.(facts[fact.factKey]) ?? facts[fact.factKey]}
      label={fact.factKey === "DEAL_SIZE" ? "AMOUNT ($)" : fact.factKey.replace(/_/g, " ")}
    />
  );
};
