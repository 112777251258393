import React, { useEffect } from "react";
import moment from "moment";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { deskingSelectors } from "../../model";
import { GET_PROPOSALS_TABLE_DATA } from "../../api";
import { CustomPagination } from "../../../../components/Table/Pagination";
import { ProposalsTableDataResponse, ProposalsTableDataVariables } from "../../api/types";
import { CardContainer } from "../../../../components/shared/CardContainer/CardContainer";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { formatCurrency } from "utils";
import { Proposal } from "@trnsact/trnsact-shared-types";

const config = require("../../../../config");

interface Props {
  vendorGUID: string;
}

export const ProposalsTable = ({ vendorGUID }: Props) => {
  const classes = useStyles();

  const voId = useSelector((state: any) => state.vo.vendorOpportunityId);
  const proposalCreated = useSelector(deskingSelectors.proposalCreated);

  const { data, refetch } = useQuery<ProposalsTableDataResponse, ProposalsTableDataVariables>(
    GET_PROPOSALS_TABLE_DATA,
    {
      skip: !voId,
      variables: { voId },
    }
  );

  useEffect(() => {
    if (proposalCreated) refetch();
  }, [proposalCreated]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (nextValue: number) => {
    setRowsPerPage(nextValue);
    setPage(0);
  };

  const getProposalLink = (proposal: Proposal) => {
    return `https://app${
      config.environmentName === "dev" ? ".dev" : ""
    }.trnsact.com/ps/?vendorGUID=${vendorGUID}&proposalId=${proposal.id}&viewOnly=true`;
  };

  return (
    <CardContainer title="Proposals">
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Created On</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Accepted Payment</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.proposals ? (
              data?.proposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                <TableRow key={row.id}>
                  <TableCell>{moment(row.createdDateTime).format("ll") ?? "-"}</TableCell>
                  <TableCell>{row.businessName ?? "-"}</TableCell>
                  <TableCell>{(row as any).contactName ?? "-"}</TableCell>
                  <TableCell>{formatCurrency(row.amountRequested) ?? "-"}</TableCell>
                  <TableCell>{row.status ?? "-"}</TableCell>
                  <TableCell>{(row as any).acceptedPayment ?? "-"}</TableCell>
                  <TableCell>{row.salesPersonName ?? "-"}</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(getProposalLink(row), "_blank")}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No proposals
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPagination
        page={page}
        pageSize={rowsPerPage}
        onPageChange={handleChangePage}
        resolvedData={data?.proposals ?? []}
        onPageSizeChange={handleChangeRowsPerPage}
      />
    </CardContainer>
  );
};

const useStyles = makeStyles({
  container: {
    height: "440px",
  },
});
