import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import { FORM_ERROR } from "final-form";
import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInput";
import GridItem from "../../../components/Grid/GridItem";
import Tile from "../../../components/Tile/Tile";
import { setRefetch } from "../../../redux/actions";
import { getHeadersFromAuth, setFieldValue, validateValue } from "../../../utils";
import { CommonDataContext } from "contexts/CommonDataContext";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";

const config = require("../../../config.js");

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Q_OWNER_PG = gql`
  query ownerPgTest($ID: ID) {
    ownerPg(id: $ID) {
      ownerPgId
      firstName
      lastName
      title
      phone
      email
      homePhone
      mobilePhone
      creditApplicationId
    }
  }
`;

const UPDATE_CREDITAPP_QUERY = `
  mutation($input: UpdateCreditApplicationInput!) {
    updateCreditApplication(input: $input)
  }
`;

function OwnerDetails({ userProfile, match, setRefetch }) {
  const ownerPgId = match.params.ownerPgId;
  const history = useHistory();
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);
  const [phoneIsMobile, setPhoneIsMobile] = useState(false);

  const [getOwnerPgDetails, { loading: ownerPgLoading, error: ownerPgError, data: ownerPgData }] = useLazyQuery(
    Q_OWNER_PG,
    {
      variables: {
        ID: ownerPgId,
      },
      context: { autRequired: true },
    }
  );

  useEffect(() => {
    if (ownerPgId) {
      getOwnerPgDetails();
    }
  }, [getOwnerPgDetails, ownerPgData]);

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    firstName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    lastName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    title: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    phone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatPhone,
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    mobilePhone: {
      value: "",
      format: formatPhone,
    },
  });
  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (ownerPgData) {
      setPhoneIsMobile(ownerPgData.ownerPg.phone === ownerPgData.ownerPg.mobilePhone);
      setFields({
        id: {
          value: ownerPgData.ownerPg.ownerPgId ? ownerPgData.ownerPg.ownerPgId : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        firstName: {
          value: ownerPgData.ownerPg.firstName ? ownerPgData.ownerPg.firstName : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        lastName: {
          value: ownerPgData.ownerPg.lastName ? ownerPgData.ownerPg.lastName : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        title: {
          value: ownerPgData.ownerPg.title ? ownerPgData.ownerPg.title : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        phone: {
          value: ownerPgData.ownerPg.phone ? formatPhone(ownerPgData.ownerPg.phone) : "",
          validationStatus: "",
          format: formatPhone,
          validations: [{ type: "required" }],
        },
        email: {
          value: ownerPgData.ownerPg.email ? ownerPgData.ownerPg.email : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        mobilePhone: {
          value: ownerPgData.ownerPg.mobilePhone ? ownerPgData.ownerPg.mobilePhone : "",
          validationStatus: "",
          format: formatPhone,
        },
      });
    }
  }, [ownerPgData]);

  return (
    <>
      {ownerPgData ? (
        <Form
          onSubmit={values => {
            const formData = new FormData();
            formData.append(
              "operations",
              JSON.stringify({
                query: UPDATE_CREDITAPP_QUERY,
                variables: {
                  input: {
                    id: ownerPgData.ownerPg.creditApplicationId,
                    owners: {
                      ownerPgId: fields.id.value,
                      firstName: fields.firstName.value,
                      lastName: fields.lastName.value,
                      email: fields.email.value,
                      title: fields.title.value,
                      phone: fields.phone.value,
                      mobilePhone: phoneIsMobile ? fields.phone.value : fields.mobilePhone.value,
                    },
                  },
                },
              })
            );
            formData.append("map", JSON.stringify({ 0: ["variables.file"] }));
            return new Promise(async (resolve, reject) => {
              const authHeaders = await getHeadersFromAuth(Auth);
              fetch(config.DCR_GRAPHQL_ENDPOINT, {
                method: "POST",
                headers: {
                  ...authHeaders,
                },
                body: formData,
              })
                .then(response => response.json())
                .then(response => {
                  response = response || {};
                  if (response.errors && response.errors.length) {
                    const errors = response.errors.map(e => e.message).join("; ");
                    console.error("Error saving owner/pg:", errors);
                    setErrorMessage("Error saving owner/pg!");
                    setErrorOpen(true);
                    resolve();
                  } else {
                    setSnackbarOpen(true);
                    resolve();
                    setRefetch("owner/pg", true);
                  }
                })
                .catch(err => {
                  console.error("Error saving owner/pg:", err);
                  const ret = {};
                  ret[FORM_ERROR] = "Error saving owner/pg!";
                  resolve(ret);
                });
            });
          }}
          validate={checkFormValidation}
          render={({ handleSubmit, pristine, valid, submitting, values }) => {
            function uploadButtonHandler(event) {
              const file = document.getElementById("file");
              file.click();
            }
            function handleClose() {
              setSnackbarOpen(false);
              setErrorOpen(false);
            }

            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit().then(res => console.log(res));
                }}>
                <Grid container>
                  <GridItem lg={12}>
                    <Tile>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <Button
                            className="formBtn"
                            color="primary"
                            variant="outlined"
                            onClick={() => history.goBack()}>
                            Back
                          </Button>
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <CustomInput
                            labelText="First Name"
                            id="firstName"
                            formControlProps={{ fullWidth: true, disabled: isLenderUser }}
                            error={fields.firstName.validationStatus === "error"}
                            inputProps={{
                              type: "text",
                              name: "firstName",
                              value: fields.firstName.value,
                              onChange: e => setValue(e.target.value, "firstName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <CustomInput
                            labelText="Last Name"
                            id="lastName"
                            formControlProps={{ fullWidth: true, disabled: isLenderUser }}
                            disabled
                            error={fields.lastName.validationStatus === "error"}
                            inputProps={{
                              type: "text",
                              name: "lastName",
                              value: fields.lastName.value,
                              onChange: e => setValue(e.target.value, "lastName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={4}>
                          <CustomInput
                            labelText="Title"
                            id="title"
                            formControlProps={{ fullWidth: true, disabled: isLenderUser }}
                            inputProps={{
                              type: "text",
                              name: "title",
                              value: fields.title.value,
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true,
                              disabled: isLenderUser,
                            }}
                            disabled
                            inputProps={{
                              type: "text",
                              name: "email",
                              value: fields.email.value,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <CustomInput
                            labelText="Phone"
                            id="phone"
                            formControlProps={{ fullWidth: true, disabled: isLenderUser }}
                            inputProps={{
                              type: "text",
                              name: "phone",
                              value: fields.phone.value,
                              onChange: e => setValue(e.target.value, "phone"),
                            }}
                          />
                        </GridItem>
                        {isDealerUser && (
                          <GridItem xs={12} sm={2} md={2} lg={2}>
                            <label htmlFor="PhoneIsMobile">
                              <PhoneIphoneIcon fontSize="small" /> Phone is mobile?
                            </label>
                            <Checkbox
                              checked={phoneIsMobile}
                              onChange={e => {
                                setPhoneIsMobile(!!e.target.checked);
                              }}
                              id="PhoneIsMobile"
                              name="PhoneIsMobile"
                              color="primary"
                            />
                          </GridItem>
                        )}
                        {!phoneIsMobile ? (
                          <GridItem xs={12} sm={4} md={4} lg={4}>
                            <CustomInput
                              labelText="Mobile Phone Number Editing"
                              id="mobilePhoneNumber"
                              formControlProps={{ fullWidth: true }}
                              error={fields.mobilePhone.validationStatus === "error"}
                              disabled
                              inputProps={{
                                type: "text",
                                name: "mobilePhone",
                                value: fields.mobilePhone.value,
                                onChange: e => setValue(e.target.value, "mobilePhone"),
                              }}
                            />
                          </GridItem>
                        ) : null}
                        {/* <GridItem xs={12} sm={6} md={6} lg={4}>
                    <CustomInput
                      labelText="Cell Phone"
                      id="mobilePhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      disabled
                      inputProps={{
                        type: "text",
                        name: "mobilePhone",
                        value: fields.mobilePhone.value,
                      }}
                    />
                  </GridItem> */}
                      </Grid>
                      <br />
                      {isDealerUser && (
                        <Grid container justify="center" alignItems="center">
                          <GridItem xs={8} sm={3} md={3} lg={2}>
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="contained"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={submitting || !formValid}>
                              {submitting ? "Updating" : "Update"}
                            </Button>
                          </GridItem>
                        </Grid>
                      )}
                    </Tile>
                  </GridItem>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    Successfully updated the record!
                  </Alert>
                </Snackbar>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        />
      ) : (
        "Loading Owner/Pg Details"
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerDetails);
