import React, { useContext, useEffect, useState } from "react";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import Modal from "@material-ui/core/Modal";
import { DocumentContext } from "../../contexts/DocumentContext";
import { DCR_API_BASE_SERVER_URL } from "config";
import gql from "graphql-tag";
import axios from "axios";
import CreditAppModal from "./CreditAppModal";
import FileUploadModal from "./FileUploadModal";
import Tile from "../../components/Tile/Tile";
import { useSelector } from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useMutation } from "@apollo/react-hooks";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { theme } from "../../../../theme";

// from DP copied code
import CADMv2 from "../../../../../src/pages/VendorOpportunity/CADMv2";
import { portalConfigurationTypes } from "../../../../../src/pages/Prequal/constants";

const QUERY_USER_DOCS = gql`
  query($creditSubId: ID, $VOId: ID) {
    creditSubmissionDocuments(creditSubId: $creditSubId, VOId: $VOId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
    lenderDecisionLetters(creditSubId: $creditSubId) {
      documentId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
    }
  }
`;

const GET_USER_PROFILE = gql`
  query {
    userProfile {
      fullName
      id
    }
  }
`;

const QUERY_VENDOR_PROFILE = gql`
  query($id: ID, $dynamicsId: ID, $userProfileId: ID, $VOId: ID) {
    vendorProfile(id: $id, dynamicsId: $dynamicsId, userProfileId: $userProfileId, VOId: $VOId) {
      id
      name
      dynamicsId
      showDocMgt
      showLenderDocsAccessMgmt
      showCreditApp
      showPsOptions
      showMarginTool
      showLenderSubmission
      industryGroupType
      role
      logo
    }
  }
`;

const DEACTIVATE_DOC = gql`
  mutation($id: ID!) {
    deactivateDocument(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  right: {
    textAlign: "right",
  },
  reportProblemIcon: {
    color: "#ec9c12",
  },
}));

export default function DocumentsCard(props) {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const refetchDocs = useSelector(state => state.docs.refetch);
  const [documents, setDocuments] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const [vendorProfile, setVendorProfile] = useState();
  const lenderProfile = useSelector(state => state.lenderProfile);
  const { CADMData, portalConfigurations, handleSetDocuments, triggerRefetchDocs } = props;
  const [isOpenCADMv2, setIsOpenCADMv2] = useState(false);
  const [isCADMv2ForPDFOnly, setIsCADMv2ForPDFOnly] = useState(false);
  const [isPDFDownloaded, setIsPDFDownloaded] = useState(false);

  const documentsPortalConfiguration = _.find(portalConfigurations, { type: portalConfigurationTypes.documents });
  const creditAppHiddenForLenderProfileIdsSetting = _.get(
    documentsPortalConfiguration,
    "jsonDefinition.config.creditAppHiddenForLenderProfileIds"
  );
  const isNeedToHideCreditApp = _.includes(creditAppHiddenForLenderProfileIdsSetting, props.cs.dynamicsLenderProfileId);

  const { data: dataVendorProfile, loading: loadingVendorProfile, error: errorVendorProfile } = useQuery(
    QUERY_VENDOR_PROFILE,
    {
      variables: {
        VOId: props.cs.voId,
      },
    }
  );

  if (errorVendorProfile) console.log(`Error! ${errorVendorProfile.message}`);

  const documentContext = useContext(DocumentContext);

  const { loading, error, data, refetch } = useQuery(QUERY_USER_DOCS, {
    variables: {
      creditSubId: props.cs.creditSubId,
      VOId: props.cs.voId,
    },
  });

  const [deactivateDocRequest, { data: deactivateDocResponseData }] = useMutation(DEACTIVATE_DOC);

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [refetchDocs, triggerRefetchDocs]);

  useEffect(() => {
    if (dataVendorProfile !== null && dataVendorProfile !== undefined) {
      setVendorProfile(dataVendorProfile);
    }
  }, [dataVendorProfile]);

  // if (loading) console.log('Loading documents...');
  if (error) console.log(`Error! ${error.message}`);

  const {
    loading: loadingUserProfile,
    error: errorUserProfile,
    data: dataUserProfile,
    refetch: refetchUserProfile,
  } = useQuery(GET_USER_PROFILE, {
    variables: {
      VOId: props.cs.voId,
      docId: null,
    },
    context: { authRequired: true },
  });

  // if (loadingUserProfile) console.log('Loading userProfile...');
  if (errorUserProfile) console.log(`Error! ${errorUserProfile.message}`);

  //Fetch UserProfile for the very first time:
  useEffect(() => {
    if (dataUserProfile !== null && dataUserProfile !== undefined && dataUserProfile.userProfile.fullName !== null) {
      setUserProfile(dataUserProfile);
    }
  }, [dataUserProfile]);

  const handleDeactivateDoc = async value => {
    try {
      await deactivateDocRequest({
        variables: {
          id: value,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const mapData = (key, name, uploaded_by, description, docType, link) => {
    return { key, name, uploaded_by, description, docType, link };
  };

  //Fetch document for the very first time:
  useEffect(() => {
    if (data !== null && data !== undefined && data.creditSubmissionDocuments !== null) {
      let docsMerged = data.creditSubmissionDocuments;
      if (
        data.lenderDecisionLetters !== null &&
        data.lenderDecisionLetters !== undefined &&
        data.lenderDecisionLetters.length > 0
      ) {
        docsMerged = _.union(data.creditSubmissionDocuments, data.lenderDecisionLetters);
      }

      const docsToSet = docsMerged.map(row =>
        mapData(row.documentId, row.docName, row.createdBy, row.docDescription, row.docType, row.source)
      );

      setDocuments(docsToSet);
      handleSetDocuments(docsToSet);
    }
  }, [data]);

  useEffect(() => {
    //refetch data after lastDoc context changed
    refetch();
  }, [documentContext.lastDoc]);

  const [creditApp, setCreditApp] = useState();
  const [openCreditAppModal, setOpenCreditAppModal] = useState(false);

  useEffect(() => {
    if (!isPDFDownloaded) {
      return;
    }
    setIsOpenCADMv2(false);
    setIsCADMv2ForPDFOnly(false);
    setIsPDFDownloaded(false);
    //handleCloseMessageModal();
  }, [isPDFDownloaded]);

  const handleCreditAppRequest = () => {
    if (CADMData) {
      setIsOpenCADMv2(true);
      return;
    }
    setOpenCreditAppModal(true);
    return axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-lender-pdf?transaction_id=${props.cs.pgTransactionId}`,
        {
          dealerName: props.cs.dealer,
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(error => console.log(error));
  };

  const handleCreditAppRequestDownload = () => {
    if (CADMData) {
      setIsCADMv2ForPDFOnly(true);
      setIsOpenCADMv2(true);
      return;
    }

    return axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-lender-pdf?transaction_id=${props.cs.pgTransactionId}`,
        {
          dealerName: props.cs.dealer,
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          download: true,
          showCustomApp: lenderProfile.showCustomApp,
          lenderCode: lenderProfile.pdfTemplate,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then(async response => {
        // const newWindow = await window.open();
        // newWindow.location = await URL.createObjectURL(response.data, { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(response.data, {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "credit_app.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log(error));
  };

  const handleOpen = () => {
    setOpenCreditAppModal(true);
  };
  const handleClose = () => {
    setOpenCreditAppModal(false);
  };

  const tableRows = tableData => {
    return tableData.map(row => {
      return [
        row.name,
        row.link ? (
          <button className={classes.clearButton} onClick={() => handleDeactivateDoc(row.key)}>
            <DeleteForeverIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
          </button>
        ) : (
          <></>
        ),
        row.uploaded_by,
        row.description,
        row.docType,
        row.link ? (
          <Button
            size="sm"
            disabled={loadingVendorProfile}
            color="primary"
            className={classes.margin}
            href={row.link}
            target="_blank"
          >
            Download
          </Button>
        ) : (
          <Tooltip title="Access Restricted" aria-label="Access Restricted" placement="top">
            <ReportProblemOutlinedIcon className={`${classes.reportProblemIcon}`} />
          </Tooltip>
        ),
      ];
    });
  };

  const tableHead = ["Document Name", "", "Uploaded By", "Description", "Doc Type", "Download Link"];

  const creditAppRow = [
    "Credit Application",
    "",
    "Trnsact",
    "Credit Application",
    null,
    <>
      <Button
        size="sm"
        variant="outlined"
        color="primary"
        className={classes.margin}
        disableElevation
        // href={row.link}
        target="_blank"
        disabled={loadingVendorProfile}
        onClick={handleCreditAppRequest}
        spacing={2}
      >
        View
      </Button>
      <Button
        size="sm"
        variant="contained"
        color="primary"
        className={classes.margin}
        disableElevation
        disabled={loadingVendorProfile}
        // href={row.link}
        target="_blank"
        onClick={handleCreditAppRequestDownload}
        // style={{ marginLeft: '16px' }}
      >
        Download
      </Button>
      <Modal
        open={openCreditAppModal}
        onClose={handleClose}
        aria-labelledby="Credit Application"
        aria-describedby="Summary"
      >
        {creditApp ? <>{<CreditAppModal {...creditApp} />}</> : <></>}
      </Modal>

      <Modal
        open={isOpenCADMv2}
        onClose={() => setIsOpenCADMv2(false)}
        aria-labelledby="Credit Application"
        aria-describedby="Summary"
        style={{ display: isCADMv2ForPDFOnly ? "none" : "block" }}
      >
        <>
          {CADMData && (
            <CADMv2
              vo={CADMData.vo}
              account={account}
              vendorProfile={CADMData.vendorProfile}
              equipmentData={CADMData.equipmentData}
              creditAppModalConfig={CADMData.CADMPortalConfiguration.jsonDefinition}
              isForPDFOnly={isCADMv2ForPDFOnly}
              setIsPDFDownloaded={setIsPDFDownloaded}
              isEditingDisabled={true}
            />
          )}
        </>
      </Modal>
    </>,
  ];

  let tableData = [...tableRows(documents)];
  if (!isNeedToHideCreditApp) {
    tableData.unshift(creditAppRow);
  }

  if (loadingVendorProfile) {
    return (
      <Tile title="Documents">
        <div style={{ padding: "0 12px", display: "inline-block" }}>
          <CircularProgress />
        </div>
      </Tile>
    );
  }

  return (
    <Tile
      title="Documents"
      titleButtonComponent={
        <FileUploadModal
          cs={props.cs}
          buttonDescription={() => {
            return (
              <>
                <CloudUploadIcon style={{ marginRight: "8px" }} /> Upload Document
              </>
            );
          }}
        />
      }
      useCollapsing={true}
    >
      <Table
        tableHead={tableHead}
        tableData={tableData}
        customHeadClassesForCells={[3]}
        customHeadCellClasses={[classes.right]}
        customClassesForCells={[3]}
        customCellClasses={[classes.right]}
      />
    </Tile>
  );
}

// const creditManagersUserProfileID = ['d25eff48-509a-11ea-97e9-73acae60e819', '982873d4-7612-11ea-a85a-2732508feda7' , '5494d728-52d3-11ea-9690-ab47fa82eb7e',
// '54a7caa4-52d3-11ea-9690-d7573021a8fc', '590b6d80-52d3-11ea-a2cd-73f80ac1b745', '0c9b50c6-9d40-11e9-b802-9716fe45e897', '59e869f6-52d3-11ea-a2cd-23e8346f283c',
// '59ea9f14-52d3-11ea-a05d-cb6bc31c94e0', '8dabdc66-9c97-11e9-8fb9-a749ce329b5d', 'e451f79e-4caa-11ea-b45b-df246ff91835', '8059c55c-5095-11ea-991b-db484a42f208',
// 'e42c4cbe-79d8-11ea-a27c-574f128d12a1', '460c6898-786b-11ea-b7e2-d35782771037', '9ae05fa2-7751-11ea-8030-cf14367cd484', 'cf744b46-79ec-11ea-9817-0ffa4eb96708']

// const vendorProfileDynamicsID = ['a899de7a-624c-ea11-8115-005056b05a0f', '64bba3e6-056e-ea11-8119-005056b05a0f']
