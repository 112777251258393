export const questions = [
  {
    value: "isSupSpanishLang",
    questionText: "Support Spanish Language?",
    type: "radio",
    variants: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
  },
  {
    value: "isCapAmountReq",
    questionText: "Capture Amount Requested?",
    type: "radio",
    variants: [
      { value: "show", label: "Show" },
      { value: "equipPlusCreditLineOptions", label: "Equipment Financing + Credit Lines" },
      { value: "hide", label: "Hide" },
    ],
  },
  {
    value: "primaryIndustry",
    questionText: "Please Select Your Primary Industry",
    type: "select",
    variants: [
      {
        value: "Accommodation and Food Services",
        label: "Accommodation and Food Services",
      },
      {
        value: "Agriculture, Forestry, Fishing and Hunting",
        label: "Agriculture, Forestry, Fishing and Hunting",
      },
      {
        value: "Arts, Entertainment, and Recreation",
        label: "Arts, Entertainment, and Recreation",
      },
      {
        value: "Construction",
        label: "Construction",
      },
      {
        value: "Ag",
        label: "Ag",
      },
      {
        value: "Landscaping",
        label: "Landscaping",
      },
      {
        value: "Equipment Rental",
        label: "Equipment Rental",
      },
      {
        value: "Oil and Gas",
        label: "Oil and Gas",
      },
      {
        value: "Mining and Quarrying",
        label: "Mining and Quarrying",
      },
      {
        value: "Material Handling",
        label: "Material Handling",
      },
      {
        value: "Educational Services",
        label: "Educational Services",
      },
      {
        value: "Finance and Insurance",
        label: "Finance and Insurance",
      },
      {
        value: "Physician",
        label: "Physician",
      },
      {
        value: "Dentist, Orthodontist",
        label: "Dentist, Orthodontist",
      },
      {
        value: "Vet",
        label: "Vet",
      },
      {
        value: "Optometrist",
        label: "Optometrist",
      },
      {
        value: "Podiatrist",
        label: "Podiatrist",
      },
      {
        value: "Other: General",
        label: "Other: General",
      },
      {
        value: "Hospital",
        label: "Hospital",
      },
      {
        value: "Medical Laboratory",
        label: "Medical Laboratory",
      },
      {
        value: "Information",
        label: "Information",
      },
      {
        value: "Management of Companies and Enterprises",
        label: "Management of Companies and Enterprises",
      },
      {
        value: "Manufacturing",
        label: "Manufacturing",
      },
      {
        value: "Mining",
        label: "Mining",
      },
      {
        value: "Other Services (except Public Administration)",
        label: "Other Services (except Public Administration)",
      },
      {
        value: "Professional, Scientific, and Technical Services",
        label: "Professional, Scientific, and Technical Services",
      },
      {
        value: "Public Administration",
        label: "Public Administration",
      },
      {
        value: "Real Estate Rental and Leasing",
        label: "Real Estate Rental and Leasing",
      },
      {
        value: "Retail Trade",
        label: "Retail Trade",
      },
      {
        value: "Local Haul",
        label: "Local Haul",
      },
      {
        value: "Warehousing",
        label: "Warehousing",
      },
      {
        value: "Refuse Haul",
        label: "Refuse Haul",
      },
      {
        value: "Long Haul",
        label: "Long Haul",
      },
      {
        value: "Delivery",
        label: "Delivery",
      },
      {
        value: "Moving",
        label: "Moving",
      },
      {
        value: "Utilities",
        label: "Utilities",
      },
      {
        value: "Administrative and Support and Waste Management and Remediation Services",
        label: "Administrative and Support and Waste Management and Remediation Services",
      },
      {
        value: "Wholesale Trade",
        label: "Wholesale Trade",
      },
    ],
  },
  {
    value: "isDownloadDL",
    questionText: "Offer Option to Upload a Driver's License?",
    type: "radio",
    variants: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
  },
  {
    value: "minOwnership",
    questionText: "Min Ownership % on Application?",
    type: "input",
    helperText: "Skip this question if you have conditional type",
  },
  {
    value: "isReceiveApp",
    questionText: "Receive Applications from Consumer Borrowers?",
    type: "radio",
    variants: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
  },
  {
    value: "signature",
    questionText: "Signature Page Authorization",
    type: "input",
  },
  {
    value: "enableSubLenders",
    questionText: "Enable Subprime Lenders?",
    type: "radio",
    variants: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
  },
  {
    value: "isPromptReqFin",
    questionText: "Prompt to Request Financials?",
    type: "radio",
    variants: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
  },
  {
    value: "captureType",
    questionText: "Capture Applicant Industry via Option Select or Manual Input",
    type: "radio",
    variants: [{ value: "option", label: "Option Select" }, { value: "manual", label: "Manual Input" }],
  },
  {
    value: "isAgreeTerms",
    questionText: `Do You Agree to This Terms and Conditions`,
    type: "terms",
    variants: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" }],
  },
  {
    value: "createTemplate",
    questionText: "Now it is Time to Put Build your Template",
    type: "button",
  },
];
