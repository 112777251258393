import React from "react";
import { Typography } from "@material-ui/core";

export function PrescreenManagementTypeHelperText() {
  return (
    <Typography
      style={{
        color: "red",
        fontSize: 12,
        marginLeft: -14,
      }}
    >
      Settings are for viewing purposes only. Please visit the Prescreen Module to configure settings for actual use.
    </Typography>
  );
}
