import React, { useState } from "react";
import Tile from "components/Tile/Tile";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../Table/Table";
import { useQuery } from "@apollo/react-hooks";
import { CreditSubmission } from "@trnsact/trnsact-shared-types";
import Typography from "@material-ui/core/Typography";
import { GET_EQUIPMENT } from "../queries";
import Box from "@material-ui/core/Box";

interface Props {
  cs: CreditSubmission;
  invoiceDetailTableBody: any;
}

export const InvoiceEquipmentDetailsCard = ({ invoiceDetailTableBody, cs }: Props) => {
  const classes = useStyles();

  const [equipments, setEquipments] = useState([]);

  const { loading } = useQuery(GET_EQUIPMENT, {
    variables: { VOId: cs.voId },
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      if (!response.equipments) return;

      const preparedEquipments = response.equipments.map((row: any) => {
        return [
          row.quantity,
          row.newUsed,
          row.year,
          row.make,
          row.model,
          row.serialNumberVin,
          row.stockId,
          row.additionalDescription,
        ];
      });

      setEquipments(preparedEquipments);
    },
  });

  const tableHead = [
    "Quantity",
    "New/Used",
    "Year",
    "Make",
    "Model",
    "Serial Number/VIN",
    "Stock Id",
    "Additional Description",
  ];

  return (
    <Tile title="Invoice & Equipment Details">
      <Box className={classes.cardContent}>
        <Box className={classes.cardBlock}>
          <Typography component="span" variant="subtitle2">
            Details
          </Typography>

          {invoiceDetailTableBody ? (
            <Table
              tableHeaderColor="primary"
              colorsColls={["primary"]}
              customClassesForCells={[0, 1]}
              tableData={invoiceDetailTableBody}
              customCellClasses={[classes.right]}
            />
          ) : (
            <Typography component="span" variant="body2" color="textSecondary">
              No invoice info
            </Typography>
          )}
        </Box>

        <Box className={classes.cardBlock}>
          <Typography component="span" variant="subtitle2">
            Equipment Details
          </Typography>

          {!!equipments?.length && !loading ? (
            <Table
              tableHead={tableHead}
              tableData={equipments}
              colorsColls={["primary"]}
              tableHeaderColor="primary"
              customClassesForCells={[5, 6]}
              customHeadClassesForCells={[5, 6]}
              customCellClasses={[classes.right, classes.right]}
              customHeadCellClasses={[classes.right, classes.right]}
            />
          ) : (
            <Typography component="span" variant="body2" color="textSecondary">
              No Equipment Info Available
            </Typography>
          )}
        </Box>
      </Box>
    </Tile>
  );
};

const useStyles = makeStyles({
  rightA: {
    color: "red",
    textAlign: "right",
  },
  right: {
    textAlign: "right",
  },
  cardContent: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  cardBlock: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
});
