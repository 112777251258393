import { gql } from "apollo-boost";

export const GET_EQUIPMENT = gql`
  query Equipments($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
      truckClass
      attachments
      wholesaleCost
      totalWholesaleCost
      retailPrice
      mileage
      assetTypeStr
      classType
      cabType
      term
      lenderProduct
      loanType
      hours
      type
      tradeInValue
      amountOwedOnTradeIn
      equipmentIdx
    }
  }
`;
