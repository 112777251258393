import React from "react";
import { Theme } from "@material-ui/core/styles";
import { Box, Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import { lossCodesFormatter } from "../../../../../lib";
import { formatCurrency } from "../../../../../../../utils";

interface Props {
  addonsOptions: any;
  configuration: Record<"addons" | "coverage" | "deductible", any>;
  handleCheckboxChange: (lossCode: any, value: boolean) => void;
}

export const Addons = ({ addonsOptions, handleCheckboxChange, configuration }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.addonsCardContainer}>
      {addonsOptions.components.map((component: any) => (
        <Box key={component.description} className={classes.addonsCard}>
          <Typography component="span" variant="subtitle2">
            {component.description}
          </Typography>

          <Box className="list">
            {component.lossCodes.map((lossCode: any) => {
              const checked = !!configuration?.addons?.find((addon: any) => addon?.title === lossCode?.description);

              return (
                <FormControlLabel
                  key={lossCode.description}
                  label={`${lossCode.description}: ${formatCurrency(lossCode.dealerCost, true)}`}
                  control={
                    <Checkbox
                      size="small"
                      disableRipple
                      color="primary"
                      checked={checked}
                      onChange={e => handleCheckboxChange(lossCodesFormatter(component, lossCode), e.target.checked)}
                    />
                  }
                />
              );
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  addonsCardContainer: {
    gap: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  addonsCard: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    backgroundColor: palette.common.white,

    "& .list": {
      display: "flex",
      flexDirection: "column",
    },
  },
}));
