import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Tile from "../../components/Tile/Tile";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import MessageModal from "../../components/MessageModal";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { convertToDate, formatMoney, parseIntMoneyString, setFieldValue, validateValue } from "../../utils";
import CustomInput from "../../components/CustomInput/CustomInput";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  rootInput: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  paper: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    minWidth: 700,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 700,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    top: "30px",
  },
  previewDoc: {
    maxWidth: "60%",
  },
  priorityLabel: {
    display: "inline-block",
    margin: "10px 0 0 0",
  },
  smallIcon: {
    fontSize: "20px",
    margin: "15px",
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
}));

const UPSERT_FDR_MUTATION = gql`
  mutation($fdrInput: UpsertFdrInput!) {
    upsertFdr(input: $fdrInput)
  }
`;

const Q_FDR_DATA = gql`
  query($voId: ID!) {
    fdrData(voId: $voId) {
      creditApp {
        id
        dynamicsId
        amountRequested
        firstName
        lastName
        email
        businessName
        title
        phone
        businessAddressCounty
        address
        city
        state
        postalCode
        equipmentDescription
        dba
        applicationType
        creditLineAmountRequested
        creditLineStatus
        creditLineNotes
        taxId
        primaryContact {
          ownerPgId
          firstName
          lastName
          phone
          email
        }
        creditApplicationOwner {
          ownerPgId
          firstName
          lastName
          title
          address
          city
          state
          postalCode
        }
      }
      vo {
        id
        vendorOpportunityId
        dynamicsVendorOpportunityId
        potentialCustomer {
          dynamicsAccountId
        }
        equipmentDescription
        equipmentCondition
        salesTaxOnInvoice
        equipmentModelYear
        transactionId
        dealerName
        dealerDynamicsAccountId
        dealerAddress
        vehiclePriceWholesaleInvoice
        freightLaborInstallation
      }
      proposal {
        riskTier
        markupDealer
        markupInternal
        termLength
        rate
        monthlyPayment
        numOfAdvancePayments
        interestRate
        productSelected
        apr
        docFee
        amountDue
        gbbAcceptedTerms
        gbbAcceptedApr
        gbbAcceptedRate
        gbbAcceptedMonthlyPayment
        gbbAcceptedTotalAmount
        status
        type
        quantity
        year
        make
        model
        additionalDescription
        serialNumberVin
      }
      signorInfo {
        dynamicsContactId
      }
    }
  }
`;

const Q_FDR = gql`
  query($fdrId: ID) {
    fdr(fdrId: $fdrId) {
      fdrId
      dynamicsFdrId
      dynamicsTransactionId
      dynamicsLesseeAccountId
      lesseeName
      dba
      lesseePhone
      billingAddress
      billingCity
      billingState
      billingZip
      billingCounty
      bizAddressSameAsEqptAddress
      eqptAddress
      eqptCity
      eqptState
      eqptZip
      eqptCounty
      type
      fdrStatus
      taxTreatment
      edocStatus
      fundingCutOffDate
      automated
      agreementNumber
      signor0SendingParty
      signorsDynamicsContactId
      signorsName
      signorsLegalTitle
      isSignorPg
      numOfPgs
      pg1DynamicsContactId
      pg1Name
      pg1HomeAddress
      pg1HomePhone
      pg1Cell
      pg2DynamicsContactId
      pg2Name
      pg2HomeAddress
      pg2HomePhone
      pg2Cell
      pg3Name
      pg4Name
      fedTaxId
      insurancePolicyNum
      insuranceAgent
      insurancePhone
      invoiceNumber
      invoiceDate
      numberOfAdvancedPayments
      salesRepDocFee
      taxRate
      payment
      term
      checkDepositAmount
      scheduleA
      ninetyDaysDeferred
      docInvoiceTotal
      salesTax
      differenceToCollect
      advanceRent
      bankName
      bankAcctHolderName
      bankAcctNum
      bankAbbaNum
      totalEqptCost
      totalTaxAmt
      freightLaborInstall
      invoiceGrandTotal
      dynamicsLenderId
      discountedOrBrokered
      buyout
      buyrate
      numOfAdvPaymentsAmounts
      sellRate
      points
      estimatedTotalGM
      preFunding
      preFundingPercent
      preFundingSource
      apr
      titled
      newUsed
      salesLeaseback
      llcAgreementRequest
      privatePartySale
      inspectionRequirement
      bankDocFees
      createdByDynamicsAccountId
      createdByDynamicsUserId
      createdDateTime
      modifiedDateTime
      signorsEmail
      pg1Email
      pg2Email
      lenderContactDynamicsId
      lenderContactName
      lenderContactEmail
      dealerAccountDynamicsId
      dealerName
      dealerAddress
      agreementStatus
      sent
      owner
      signedOn
      agreementId
      docusignEnvelopeId
      pg1Id
      pg2Id
      pg3Id
      pg4Id
      eqptModel
      eqptMake
      eqptQuantity
      eqptYear
      approvedAmount
      approvedRate
      dealerMarkup
      dcrMarkup
      creditProduct
    }
  }
`;
const useUiStyles = makeStyles(styles);

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

const DELETE_FDR = gql`
  mutation($id: ID!) {
    deleteFdr(id: $id)
  }
`;

export default function FDRModal({ handleClose, fdrId, refetch, dynamicsTransactionId, fdr, cs, vo }) {
  const classes = { ...useStyles(), ...useUiStyles() };
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [basicErrorMessage, setBasicErrorMessage] = useState("Required");
  const [modalStyle] = useState(getModalStyle);
  const uiClasses = useUiStyles();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [creditApp, setCreditApp] = useState();
  const lenderProfile = useSelector(state => state.lenderProfile);
  const account = useSelector(state => state.account);
  const [availablePgs, setAvailablePgs] = useState([]);

  const { data: fdrData, refetch: creditAppRefetch } = useQuery(
    Q_FDR_DATA,
    {
      variables: {
        voId: vo.vendorOpportunityId,
      },
    },
    { fetchPolicy: "no-cache" }
  );

  const [getFdr, { data: fdrResponse }] = useLazyQuery(Q_FDR, {
    context: { authRequired: true },
  });

  const [deleteFdr] = useMutation(DELETE_FDR, {
    context: { authRequired: true },
  });

  const [fields, setFields] = useState({
    fdrId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    dynamicsTransactionId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    dynamicsLesseeAccountId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    lesseeName: {
      value: "",
    },
    dba: {
      value: "",
    },
    lesseePhone: {
      value: "",
    },
    billingAddress: {
      value: "",
    },
    billingCity: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    billingState: {
      value: "",
    },
    billingZip: {
      value: "",
      validationStatus: "",
    },
    billingCounty: {
      value: "",
    },
    bizAddressSameAsEqptAddress: {
      value: "",
    },
    eqptAddress: {
      value: "",
    },
    eqptCity: {
      value: "",
    },
    eqptState: {
      value: "",
    },
    eqptZip: {
      value: "",
    },
    eqptCounty: {
      value: "",
    },
    type: {
      value: "",
    },
    fdrStatus: {
      value: "",
    },
    agreementNumber: {
      value: "",
    },
    signorsLegalName: {
      value: "",
    },
    signorsLegalTitle: {
      value: "",
    },
    fedTaxId: {
      value: "",
    },
    dealerName: {
      value: "",
    },
    dealerId: {
      value: "",
    },
    dealerAddress: {
      value: "",
    },
    dealerCity: {
      value: "",
    },
    dealerState: {
      value: "",
    },
    dealerZip: {
      value: "",
    },
    bankName: {
      value: "",
    },
    bankAcctHolderName: {
      value: "",
    },
    bankAcctNum: {
      value: "",
    },
    bankAbbaNum: {
      value: "",
    },
    equipmentInfo1: {
      value: "",
    },
    equipmentInfo2: {
      value: "",
    },
    equipmentInfo3: {
      value: "",
    },
    invoiceNumber: {
      value: "",
    },
    invoiceDate: {
      value: "",
    },
    numberOfAdvancedPayments: {
      value: "",
    },
    salesRepDocFee: {
      value: "",
    },
    salesTax: {
      value: "",
    },
    totalEqptCost: {
      value: "",
    },
    totalTaxAmt: {
      value: "",
    },
    freightLaborInstall: {
      value: "",
    },
    invoiceGrandTotal: {
      value: "",
    },
    dynamicsLenderId: {
      value: "",
    },
    lenderName: {
      value: "",
    },
    lenderContact: {
      value: "",
    },
    numOfAdvPaymentsAmounts: {
      value: "",
    },
    creditProduct: {
      value: "",
    },
    payment: {
      value: "",
    },
    taxRate: {
      value: "",
    },
    term: {
      value: "",
    },
    ninetyDaysDeferred: {
      value: "",
    },
    docInvoiceTotal: {
      value: "",
    },
    createdByDynamicsAccountId: {
      value: "",
    },
    createdByDynamicsUserId: {
      value: "",
    },
    createdDateTime: {
      value: "",
    },
    approvedAmount: {
      value: "",
    },
    approvedRate: {
      value: "",
    },
    notes: {
      value: "",
    },
    contigencies: {
      value: "",
    },
    dealerMarkup: {
      value: "",
    },
    dcrMarkup: {
      value: "",
    },
    differenceToCollect: {
      value: "",
    },
    numOfPgs: {
      value: "",
    },
    ownerPg1Id: {
      value: "",
    },
    ownerPg2Id: {
      value: "",
    },
    ownerPg3Id: {
      value: "",
    },
    ownerPg4Id: {
      value: "",
    },
    owner1Address: {
      value: "",
    },
    owner2Address: {
      value: "",
    },
    owner1HomePhone: {
      value: "",
    },
    owner1CellPhone: {
      value: "",
    },
    owner2HomePhone: {
      value: "",
    },
    owner2CellPhone: {
      value: "",
    },
  });

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };

  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = async (value, fieldName) => {
    await checkFormValidation();
    await setFieldValue(value, fieldName, fields, setFields);
    await checkFormValidation();
  };

  useEffect(() => {
    if (!!fdrData && fdrData.fdrData && cs && !fdrId) {
      const creditApp = fdrData.fdrData.creditApp;
      const proposal = fdrData.fdrData.proposal;
      const vo = fdrData.fdrData.vo;
      const signorInfo = fdrData.fdrData.signorInfo;
      setAvailablePgs(creditApp.creditApplicationOwner);
      setFields({
        dynamicsTransactionId: {
          value: dynamicsTransactionId,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        dynamicsLesseeAccountId: {
          value: cs.dynamicsApplicantId,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        lesseeName: {
          value: creditApp.businessName,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        dba: {
          value: creditApp.dba,
        },
        lesseePhone: {
          value: creditApp.phone,
          format: formatPhone,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        billingAddress: {
          value: creditApp.address,
          validations: [{ type: "required" }],
          validationStatus: "",
        },
        billingCity: {
          value: creditApp.city,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        billingState: {
          value: creditApp.state,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        billingZip: {
          value: creditApp.postalCode,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        billingCounty: {
          value: creditApp.businessAddressCounty,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        bizAddressSameAsEqptAddress: {
          value: true,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        eqptAddress: {
          value: "",
        },
        eqptCity: {
          value: "",
        },
        eqptState: {
          value: "",
        },
        eqptZip: {
          value: "",
        },
        eqptCounty: {
          value: "",
        },
        type: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        fdrStatus: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        agreementNumber: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        signorsLegalName: {
          value: `${creditApp.firstName} ${creditApp.lastName}`,
        },
        signorsLegalTitle: {
          value: creditApp.title,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        fedTaxId: {
          value: creditApp.taxId,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        dealerName: {
          value: vo.dealerName,
        },
        dealerId: {
          value: vo.dealerDynamicsAccountId,
        },
        dealerAddress: {
          value: vo.dealerAddress,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        equipmentInfo1: {
          value: `${proposal.quantity} - ${proposal.year} ${proposal.make} ${proposal.model}`,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        equipmentInfo2: {
          value: proposal.additionalDescription,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        equipmentInfo3: {
          value: proposal.serialNumberVin,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        invoiceNumber: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        invoiceDate: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        numberOfAdvancedPayments: {
          value: proposal.numOfAdvancePayments,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        salesRepDocFee: {
          value: formatMoney(proposal.docFee.toFixed(2)),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        ninetyDaysDeferred: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        docInvoiceTotal: {
          value: formatMoney(
            (vo.vehiclePriceWholesaleInvoice + vo.freightLaborInstallation + vo.salesTaxOnInvoice).toFixed(2)
          ),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        salesTax: {
          value: formatMoney(vo.salesTaxOnInvoice.toFixed(2)),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        bankName: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        bankAcctHolderName: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        bankAcctNum: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        bankAbbaNum: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        creditProduct: {
          value: proposal.productSelected,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        totalEqptCost: {
          value: formatMoney(vo.vehiclePriceWholesaleInvoice.toFixed(2)),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        totalTaxAmt: {
          value: "",
          format: formatMoney,
        },
        freightLaborInstall: {
          value: formatMoney(vo.freightLaborInstallation.toFixed(2)),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        invoiceGrandTotal: {
          value: formatMoney(
            (vo.vehiclePriceWholesaleInvoice + vo.freightLaborInstallation + vo.salesTaxOnInvoice).toFixed(2)
          ),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        dynamicsLenderId: {
          value: cs.lenderAccountId,
        },
        lenderName: {
          value: cs.lenderName,
        },
        lenderContact: {
          value: lenderProfile.fdrContact.fullName,
        },
        numOfAdvPaymentsAmounts: {
          value: formatMoney(proposal.monthlyPayment * proposal.numOfAdvancePayments.toFixed(2)),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        taxRate: {
          value: proposal.interestRate.toFixed(2),
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        payment: {
          value: formatMoney(proposal.monthlyPayment.toFixed(2)),
          format: formatMoney,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        term: {
          value: proposal.termLength,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        signorDynamicsId: {
          value: signorInfo.dynamicsContactId,
        },
        approvedAmount: {
          value: formatMoney(cs.approvedAmount.toFixed(2)),
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        approvedRate: {
          value: cs.approvedRate,
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        notes: {
          value: cs.notes,
        },
        contigencies: {
          value: cs.contigencies,
        },
        dealerMarkup: {
          value: proposal.markupDealer,
        },
        dcrMarkup: {
          value: proposal.markupInternal,
        },
        differenceToCollect: {
          value: "",
          validationStatus: "",
          validations: [{ type: "required" }],
          format: formatMoney,
        },
        numOfPgs: {
          value: 0,
        },
        ownerPg1Id: {
          value: "",
        },
        ownerPg2Id: {
          value: "",
        },
        ownerPg3Id: {
          value: "",
        },
        ownerPg4Id: {
          value: "",
        },
        owner1Address: {
          value: "",
        },
        owner2Address: {
          value: "",
        },
        owner1HomePhone: {
          value: "",
          format: formatPhone,
        },
        owner1CellPhone: {
          value: "",
          format: formatPhone,
        },
        owner2HomePhone: {
          value: "",
          format: formatPhone,
        },
        owner2CellPhone: {
          value: "",
          format: formatPhone,
        },
      });
    }
  }, [fdrData, cs]);

  useEffect(() => {
    if (fdrId) {
      getFdr({
        variables: {
          fdrId: fdrId,
        },
      });
      if (!!fdrResponse && fdrResponse.fdr && !!fdrData && fdrData.fdrData) {
        const creditApp = fdrData.fdrData.creditApp;
        const proposal = fdrData.fdrData.proposal;
        const vo = fdrData.fdrData.vo;
        const signorInfo = fdrData.fdrData.signorInfo;
        setAvailablePgs(creditApp.creditApplicationOwner);
        setFields({
          dynamicsTransactionId: {
            value: dynamicsTransactionId,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          dynamicsLesseeAccountId: {
            value: cs.dynamicsApplicantId,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          lesseeName: {
            value: fdrResponse.fdr.lesseeName,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          dba: {
            value: fdrResponse.fdr.dba,
          },
          lesseePhone: {
            value: fdrResponse.fdr.lesseePhone,
            format: formatPhone,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          billingAddress: {
            value: fdrResponse.fdr.billingAddress,
            validations: [{ type: "required" }],
            validationStatus: "",
          },
          billingCity: {
            value: fdrResponse.fdr.billingCity,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          billingState: {
            value: fdrResponse.fdr.billingState,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          billingZip: {
            value: fdrResponse.fdr.billingZip,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          billingCounty: {
            value: fdrResponse.fdr.billingCounty,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          bizAddressSameAsEqptAddress: {
            value: fdrResponse.fdr.bizAddressSameAsEqptAddress,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          eqptAddress: {
            value: fdrResponse.fdr.eqptAddress,
          },
          eqptCity: {
            value: "",
          },
          eqptState: {
            value: "",
          },
          eqptZip: {
            value: "",
          },
          eqptCounty: {
            value: "",
          },
          type: {
            value: fdrResponse.fdr.type,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          fdrStatus: {
            value: fdrResponse.fdr.fdrStatus,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          agreementNumber: {
            value: fdrResponse.fdr.agreementNumber,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          signorsLegalName: {
            value: fdrResponse.fdr.signorsName,
          },
          signorsLegalTitle: {
            value: fdrResponse.fdr.signorsLegalTitle,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          fedTaxId: {
            value: fdrResponse.fdr.fedTaxId,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          dealerName: {
            value: fdrResponse.fdr.dealerName,
          },
          dealerId: {
            value: fdrResponse.fdr.dealerAccountDynamicsId,
          },
          dealerAddress: {
            value: fdrResponse.fdr.dealerAddress,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          equipmentInfo1: {
            value: `${fdrResponse.fdr.scheduleA}`,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          equipmentInfo2: {
            value: proposal.additionalDescription,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          equipmentInfo3: {
            value: proposal.serialNumberVin,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          invoiceNumber: {
            value: fdrResponse.fdr.invoiceNumber,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          invoiceDate: {
            value: fdrResponse.fdr.invoiceDate,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          numberOfAdvancedPayments: {
            value: fdrResponse.fdr.numberOfAdvancedPayments,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          salesRepDocFee: {
            value: formatMoney(fdrResponse.fdr.salesRepDocFee),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          ninetyDaysDeferred: {
            value: fdrResponse.fdr.ninetyDaysDeferred,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          docInvoiceTotal: {
            value: formatMoney(fdrResponse.fdr.docInvoiceTotal.toFixed(2)),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          salesTax: {
            value: formatMoney(fdrResponse.fdr.salesTax.toFixed(2)),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          bankName: {
            value: fdrResponse.fdr.bankName,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          bankAcctHolderName: {
            value: fdrResponse.fdr.bankAcctHolderName,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          bankAcctNum: {
            value: fdrResponse.fdr.bankAcctNum,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          bankAbbaNum: {
            value: fdrResponse.fdr.bankAbbaNum,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          creditProduct: {
            value: fdrResponse.fdr.creditProduct,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          totalEqptCost: {
            value: formatMoney(fdrResponse.fdr.totalEqptCost.toFixed(2)),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          totalTaxAmt: {
            value: fdrResponse.fdr.totalTaxAmt,
            format: formatMoney,
          },
          freightLaborInstall: {
            value: formatMoney(fdrResponse.fdr.freightLaborInstall.toFixed(2)),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          invoiceGrandTotal: {
            value: formatMoney(fdrResponse.fdr.invoiceGrandTotal.toFixed(2)),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          dynamicsLenderId: {
            value: cs.lenderAccountId,
          },
          lenderName: {
            value: cs.lenderName,
          },
          lenderContact: {
            value: fdrResponse.fdr.lenderContactName,
          },
          numOfAdvPaymentsAmounts: {
            value: formatMoney(parseFloat(fdrResponse.fdr.numOfAdvPaymentsAmounts).toFixed(2)),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          taxRate: {
            value: fdrResponse.fdr.taxRate.toFixed(2),
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          payment: {
            value: formatMoney(fdrResponse.fdr.payment.toFixed(2)),
            format: formatMoney,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          term: {
            value: fdrResponse.fdr.term,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          signorDynamicsId: {
            value: signorInfo.dynamicsContactId,
          },
          approvedAmount: {
            value: formatMoney(parseFloat(fdrResponse.fdr.approvedAmount).toFixed(2)),
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          approvedRate: {
            value: fdrResponse.fdr.approvedRate,
            validationStatus: "",
            validations: [{ type: "required" }],
          },
          notes: {
            value: cs.notes,
          },
          contigencies: {
            value: cs.contigencies,
          },
          dealerMarkup: {
            value: fdrResponse.fdr.dealerMarkup,
          },
          dcrMarkup: {
            value: fdrResponse.fdr.dcrMarkup,
          },
          differenceToCollect: {
            value: formatMoney(fdrResponse.fdr.differenceToCollect),
            validationStatus: "",
            validations: [{ type: "required" }],
            format: formatMoney,
          },
          numOfPgs: {
            value: !!fdrResponse && fdrResponse.fdr.numOfPgs ? fdrResponse.fdr.numOfPgs : 0,
          },
          ownerPg1Id: {
            value: fdrResponse.fdr.pg1Id,
          },
          ownerPg2Id: {
            value: fdrResponse.fdr.pg2Id,
          },
          ownerPg3Id: {
            value: fdrResponse.fdr.pg3Id,
          },
          ownerPg4Id: {
            value: fdrResponse.fdr.pg4Id,
          },
          owner1Address: {
            value: fdrResponse.fdr.pg1HomeAddress,
          },
          owner2Address: {
            value: fdrResponse.fdr.pg2HomeAddress,
          },
          owner1HomePhone: {
            value: fdrResponse.fdr.pg1HomePhone,
            format: formatPhone,
          },
          owner1CellPhone: {
            value: fdrResponse.fdr.pg1Cell,
            format: formatPhone,
          },
          owner2HomePhone: {
            value: fdrResponse.fdr.pg2HomePhone,
            format: formatPhone,
          },
          owner2CellPhone: {
            value: fdrResponse.fdr.pg2Cell,
            format: formatPhone,
          },
        });
      }
    }
  }, [fdrId, fdrResponse, fdrData]);

  const [upsertFdr, { data, error, loading }] = useMutation(UPSERT_FDR_MUTATION, {
    context: { authRequired: true },
  });
  const handlePostSubmit = () => {
    handleCloseModal();
    handleClose();
  };

  const handleSubmissionMessage = message => {
    setSubmissionMessage(message);
  };

  const handleDeleteFdr = async (e, fdr) => {
    e.stopPropagation();
    try {
      await deleteFdr({
        variables: {
          id: fdr.fdrId,
        },
      });
      handleCloseModal();
      handleClose();
      await refetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (formValid) {
      try {
        await handleOpenModal();
        await handleSubmissionMessage({
          title: fdrId ? "Updating" : "Sending",
          message: "Please wait.",
        });
        await upsertFdr({
          variables: {
            fdrInput: {
              fdrId: fdrId ? fdrId : undefined,
              dynamicsTransactionId: fields.dynamicsTransactionId.value,
              dynamicsLesseeAccountId: fields.dynamicsLesseeAccountId.value,
              dba: fields.dba.value,
              lesseePhone: fields.lesseePhone.value,
              billingAddress: fields.billingAddress.value,
              billingCity: fields.billingCity.value,
              billingState: fields.billingState.value,
              billingZip: fields.billingZip.value,
              billingCounty: fields.billingCounty.value,
              bizAddressSameAsEqptAddress: fields.bizAddressSameAsEqptAddress.value,
              eqptAddress: fields.eqptAddress.value,
              eqptCity: fields.eqptCity.value,
              eqptState: fields.eqptState.value,
              eqptZip: fields.eqptZip.value,
              eqptCounty: fields.eqptCounty.value,
              type: fields.type.value,
              fdrStatus: fields.fdrStatus.value,
              agreementNumber: fields.agreementNumber.value,
              signorsLegalTitle: fields.signorsLegalTitle.value,
              fedTaxId: fields.fedTaxId.value.toString(),
              invoiceNumber: fields.invoiceNumber.value,
              invoiceDate: convertToDate(fields.invoiceDate.value),
              numberOfAdvancedPayments: fields.numberOfAdvancedPayments.value,
              salesRepDocFee: parseIntMoneyString(fields.salesRepDocFee.value),
              taxRate: parseFloat(fields.taxRate.value),
              payment: parseIntMoneyString(fields.payment.value),
              term: parseInt(fields.term.value),
              scheduleA: `${fields.equipmentInfo1.value} ${fields.equipmentInfo2.value} ${fields.equipmentInfo3.value}`,
              ninetyDaysDeferred: fields.ninetyDaysDeferred.value === "true" ? true : false,
              docInvoiceTotal: parseIntMoneyString(fields.docInvoiceTotal.value),
              salesTax: parseFloat(fields.salesTax.value),
              totalEqptCost: parseIntMoneyString(fields.totalEqptCost.value),
              totalTaxAmt: parseIntMoneyString(fields.salesTax.value),
              freightLaborInstall: parseIntMoneyString(fields.freightLaborInstall.value),
              invoiceGrandTotal: parseIntMoneyString(fields.invoiceGrandTotal.value),
              dynamicsLenderId: fields.dynamicsLenderId.value,
              numOfAdvPaymentsAmounts: fields.numOfAdvPaymentsAmounts.value.toString(),
              signorsDynamicsContactId: fields.signorDynamicsId.value,
              bankName: fields.bankName.value,
              bankAcctHolderName: fields.bankAcctHolderName.value,
              bankAcctNum: fields.bankAcctNum.value,
              bankAbbaNum: fields.bankAbbaNum.value,
              lenderContactDynamicsId: lenderProfile.fdrContact.dynamicsContactId,
              voId: vo.vendorOpportunityId,
              createdByDynamicsAccountId: account.dynamicsAccountId,
              differenceToCollect: parseIntMoneyString(fields.differenceToCollect.value),
              numOfPgs: fields.numOfPgs.value,
              ownerPg1Id: fields.ownerPg1Id.value ? fields.ownerPg1Id.value : undefined,
              ownerPg2Id: fields.ownerPg2Id.value ? fields.ownerPg2Id.value : undefined,
              ownerPg3Id: fields.ownerPg3Id.value ? fields.ownerPg3Id.value : undefined,
              ownerPg4Id: fields.ownerPg4Id.value ? fields.ownerPg4Id.value : undefined,
              pg1HomeAddress: fields.owner1Address.value ? fields.owner1Address.value : undefined,
              pg1HomePhone: fields.owner1HomePhone.value ? fields.owner1HomePhone.value : undefined,
              pg1Cell: fields.owner1CellPhone.value ? fields.owner1CellPhone.value : undefined,
              pg2HomeAddress: fields.owner2Address.value ? fields.owner2Address.value : undefined,
              pg2HomePhone: fields.owner2HomePhone.value ? fields.owner2HomePhone.value : undefined,
              pg2Cell: fields.owner2CellPhone.value ? fields.owner2CellPhone.value : undefined,
              // eqptModel: !!fields.owner2CellPhone.value ? fields.owner2CellPhone.value : undefined,
              // eqptMake: !!fields.owner2CellPhone.value ? fields.owner2CellPhone.value : undefined,
              // eqptQuantity: !!fields.owner2CellPhone.value ? fields.owner2CellPhone.value : undefined,
              // eqptYear: !!fields.owner2CellPhone.value ? fields.owner2CellPhone.value : undefined,
              approvedAmount: fields.approvedAmount.value ? parseFloat(fields.approvedAmount.value) : undefined,
              approvedRate: fields.approvedRate.value ? parseFloat(fields.approvedRate.value) : undefined,
              dealerMarkup: fields.dealerMarkup.value ? parseFloat(fields.dealerMarkup.value) : undefined,
              dcrMarkup: fields.dcrMarkup.value ? parseFloat(fields.dcrMarkup.value) : undefined,
              lesseeName: fields.lesseeName.value,
              dealerAddress: fields.dealerAddress.value,
              dealerName: fields.dealerName.value,
            },
          },
        });
        await handleOpenModal();
        await handleSubmissionMessage({
          title: "Success",
          message: fdrId ? "FDR successfully updated." : "FDR successfully created.",
        });
        await refetch();
      } catch (e) {
        console.log("error", e);
        await handleSubmissionMessage({
          title: "Error",
          message: "There was something wrong with your request",
        });
      }
    } else {
      await handleSubmissionMessage({
        title: "Error",
        message: "Missing data",
      });
    }
  };

  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };
  const handleOpenModal = () => {
    setOpenMessageModal(true);
  };

  return (
    !!fdrData && (
      <div style={modalStyle} className={classes.surface}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Tile title={fdrId ? "Edit FDR" : "Create New FDR"} maxHeightLimitByScreen={true}>
          <div className={classes.formContainer}>
            <Grid container spacing={4} className={classes.formContainerGridContainer}>
              <Grid item xs={12}>
                <Typography align="center">Customer Info</Typography>
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  labelText="Lessee Name"
                  id="lesseeName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "lesseeName",
                    value: fields.lesseeName.value,
                    onChange: e => setValue(e.target.value, "lesseeName"),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CustomInput
                  labelText="DBA"
                  id="dba"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "dba",
                    value: fields.dba.value,
                    onChange: e => setValue(e.target.value, "dba"),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  labelText="Lessee Phone Number"
                  id="lesseePhone"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "lesseePhone",
                    value: fields.lesseePhone.value,
                    onChange: e => setValue(e.target.value, "lesseePhone"),
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth className={uiClasses.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                    Number of Personal Guarantors
                  </InputLabel>
                  <Select
                    classes={{ select: uiClasses.select }}
                    value={fields.numOfPgs.value}
                    inputProps={{
                      name: "numOfPgs",
                      id: "numOfPgs",
                      onChange: e => setValue(e.target.value, "numOfPgs"),
                    }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {fields.numOfPgs.value > 0 && (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={uiClasses.selectFormControl}>
                      <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                        Personal Guarantor 1
                      </InputLabel>
                      <Select
                        classes={{ select: uiClasses.select }}
                        value={fields.ownerPg1Id.value}
                        inputProps={{
                          name: "ownerPg1Id",
                          id: "ownerPg1Id",
                          onChange: e => setValue(e.target.value, "ownerPg1Id"),
                        }}
                      >
                        {availablePgs.map(({ ownerPgId, firstName, lastName }) => (
                          <MenuItem value={ownerPgId}>
                            {firstName} {lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      labelText="Personal Guarantor 1 Home Address"
                      id="owner1Address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "owner1Address",
                        value: fields.owner1Address.value,
                        onChange: e => setValue(e.target.value, "owner1Address"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      labelText="Personal Guarantor 1 Home Phone"
                      id="owner1HomePhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "owner1HomePhone",
                        value: fields.owner1HomePhone.value,
                        onChange: e => setValue(e.target.value, "owner1HomePhone"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      labelText="Personal Guarantor 1 Cell Phone"
                      id="owner1CellPhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "owner1CellPhone",
                        value: fields.owner1CellPhone.value,
                        onChange: e => setValue(e.target.value, "owner1CellPhone"),
                      }}
                    />
                  </Grid>
                </>
              )}
              {fields.numOfPgs.value > 1 && (
                <>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={uiClasses.selectFormControl}>
                      <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                        Personal Guarantor 2
                      </InputLabel>
                      <Select
                        classes={{ select: uiClasses.select }}
                        value={fields.ownerPg2Id.value}
                        inputProps={{
                          name: "ownerPg2Id",
                          id: "ownerPg2Id",
                          onChange: e => setValue(e.target.value, "ownerPg2Id"),
                        }}
                      >
                        {availablePgs.map(({ ownerPgId, firstName, lastName }) => (
                          <MenuItem value={ownerPgId}>
                            {firstName} {lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomInput
                      labelText="Personal Guarantor 2 Home Address"
                      id="owner2Address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "owner2Address",
                        value: fields.owner2Address.value,
                        onChange: e => setValue(e.target.value, "owner2Address"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      labelText="Personal Guarantor 2 Home Phone"
                      id="owner2HomePhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "owner2HomePhone",
                        value: fields.owner2HomePhone.value,
                        onChange: e => setValue(e.target.value, "owner2HomePhone"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      labelText="Personal Guarantor 2 Cell Phone"
                      id="owner2CellPhone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "owner2CellPhone",
                        value: fields.owner2CellPhone.value,
                        onChange: e => setValue(e.target.value, "owner2CellPhone"),
                      }}
                    />
                  </Grid>
                </>
              )}
              {fields.numOfPgs.value > 2 && (
                <Grid item xs={6}>
                  <FormControl fullWidth className={uiClasses.selectFormControl}>
                    <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                      Personal Guarantor 3
                    </InputLabel>
                    <Select
                      classes={{ select: uiClasses.select }}
                      value={fields.ownerPg3Id.value}
                      inputProps={{
                        name: "ownerPg3Id",
                        id: "ownerPg3Id",
                        onChange: e => setValue(e.target.value, "ownerPg3Id"),
                      }}
                    >
                      {availablePgs.map(({ ownerPgId, firstName, lastName }) => (
                        <MenuItem value={ownerPgId}>
                          {firstName} {lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {fields.numOfPgs.value > 3 && (
                <Grid item xs={6}>
                  <FormControl fullWidth className={uiClasses.selectFormControl}>
                    <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                      Personal Guarantor 4
                    </InputLabel>
                    <Select
                      classes={{ select: uiClasses.select }}
                      value={fields.ownerPg4Id.value}
                      inputProps={{
                        name: "ownerPg4Id",
                        id: "ownerPg4Id",
                        onChange: e => setValue(e.target.value, "ownerPg4Id"),
                      }}
                    >
                      {availablePgs.map(({ ownerPgId, firstName, lastName }) => (
                        <MenuItem value={ownerPgId}>
                          {firstName} {lastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomInput
                  labelText="Billing Address"
                  id="billingAddress"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "billingAddress",
                    value: fields.billingAddress.value,
                    onChange: e => setValue(e.target.value, "billingAddress"),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  labelText="Billing City"
                  id="billingCity"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "billingCity",
                    value: fields.billingCity.value,
                    onChange: e => setValue(e.target.value, "billingCity"),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CustomInput
                  labelText="Billing State"
                  id="billingState"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "billingState",
                    value: fields.billingState.value,
                    onChange: e => setValue(e.target.value, "billingState"),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomInput
                  labelText="Billing Zip"
                  id="billingZip"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "billingZip",
                    value: fields.billingZip.value,
                    onChange: e => setValue(e.target.value, "billingZip"),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomInput
                  labelText="Billing County"
                  id="billingCounty"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "billingCounty",
                    value: fields.billingCounty.value,
                    onChange: e => setValue(e.target.value, "billingCounty"),
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth className={uiClasses.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                    Biz Address Same as Eqpt Address:
                  </InputLabel>
                  <Select
                    classes={{ select: uiClasses.select }}
                    value={fields.bizAddressSameAsEqptAddress.value}
                    inputProps={{
                      name: "bizAddressSameAsEqptAddress",
                      id: "bizAddressSameAsEqptAddress",
                      onChange: e => setValue(e.target.value, "bizAddressSameAsEqptAddress"),
                    }}
                  >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {fields.bizAddressSameAsEqptAddress.value === false && (
                <>
                  <Grid item xs={12}>
                    <CustomInput
                      labelText="Equipment Address"
                      id="eqptAddress"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "eqptAddress",
                        value: fields.eqptAddress.value,
                        onChange: e => setValue(e.target.value, "eqptAddress"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      labelText="Equipment City"
                      id="eqptCity"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "eqptCity",
                        value: fields.eqptCity.value,
                        onChange: e => setValue(e.target.value, "eqptCity"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <CustomInput
                      labelText="Equipment State"
                      id="eqptState"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "eqptState",
                        value: fields.eqptState.value,
                        onChange: e => setValue(e.target.value, "eqptState"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomInput
                      labelText="Equipment Zip"
                      id="eqptZip"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "eqptZip",
                        value: fields.eqptZip.value,
                        onChange: e => setValue(e.target.value, "eqptZip"),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomInput
                      labelText="Equipment County"
                      id="eqptCounty"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        name: "eqptCounty",
                        value: fields.eqptCounty.value,
                        onChange: e => setValue(e.target.value, "eqptCounty"),
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <CustomInput
                  labelText="Bank Name"
                  id="bankName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "bankName",
                    value: fields.bankName.value,
                    onChange: e => setValue(e.target.value, "bankName"),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  labelText="Bank Account Holder Name"
                  id="bankAcctHolderName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "bankAcctHolderName",
                    value: fields.bankAcctHolderName.value,
                    onChange: e => setValue(e.target.value, "bankAcctHolderName"),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  labelText="Bank Account Number"
                  id="bankAcctNum"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "bankAcctNum",
                    value: fields.bankAcctNum.value,
                    onChange: e => setValue(e.target.value, "bankAcctNum"),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  labelText="Bank ABA Number"
                  id="bankAbbaNum"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "bankAbbaNum",
                    value: fields.bankAbbaNum.value,
                    onChange: e => setValue(e.target.value, "bankAbbaNum"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">General</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth className={uiClasses.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                    Type
                  </InputLabel>
                  <Select
                    classes={{ select: uiClasses.select }}
                    value={fields.type.value}
                    inputProps={{
                      name: "type",
                      id: "type",
                      onChange: e => setValue(e.target.value, "type"),
                    }}
                  >
                    <MenuItem value={"Lease"}>Lease</MenuItem>
                    <MenuItem value={"EFA"}>EFA</MenuItem>
                    <MenuItem value={"Loan"}>Loan</MenuItem>
                    <MenuItem value={"Working_Capital"}>Working Capital</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth className={uiClasses.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                    FDR Status
                  </InputLabel>
                  <Select
                    classes={{ select: uiClasses.select }}
                    value={fields.fdrStatus.value}
                    inputProps={{
                      name: "fdrStatus",
                      id: "fdrStatus",
                      onChange: e => setValue(e.target.value, "fdrStatus"),
                    }}
                  >
                    <MenuItem value={"New"}>New</MenuItem>
                    <MenuItem value={"Submitted"}>Submitted</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Docs_Out"}>Docs Out</MenuItem>
                    <MenuItem value={"Docs_In"}>Docs In</MenuItem>
                    <MenuItem value={"Canceled"}>Canceled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Agreement Number"
                  id="agreementNumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "agreementNumber",
                    value: fields.agreementNumber.value,
                    onChange: e => setValue(e.target.value, "agreementNumber"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Signor Info</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Signors Legal Name"
                  id="signorsLegalName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "signorsLegalName",
                    value: fields.signorsLegalName.value,
                    onChange: e => setValue(e.target.value, "signorsLegalName"),
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Signors Title"
                  id="signorsLegalTitle"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "signorsLegalTitle",
                    value: fields.signorsLegalTitle.value,
                    onChange: e => setValue(e.target.value, "signorsLegalTitle"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Fed Tax Id"
                  id="fedTaxId"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "fedTaxId",
                    value: fields.fedTaxId.value,
                    onChange: e => setValue(e.target.value, "fedTaxId"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Dealer Info</Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  labelText=" Dealer Name"
                  id="dealerName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "dealerName",
                    value: fields.dealerName.value,
                    onChange: e => setValue(e.target.value, "dealerName"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  labelText=" Dealer Address"
                  id="dealerAddress"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "dealerAddress",
                    value: fields.dealerAddress.value,
                    onChange: e => setValue(e.target.value, "dealerAddress"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Equipment Info</Typography>
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  labelText="Quantity - Year Make Model"
                  id="equipmentInfo1"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "equipmentInfo1",
                    value: fields.equipmentInfo1.value,
                    onChange: e => setValue(e.target.value, "equipmentInfo1"),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  labelText="SN or Vin #"
                  id="equipmentInfo3"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "equipmentInfo3",
                    value: fields.equipmentInfo3.value,
                    onChange: e => setValue(e.target.value, "equipmentInfo3"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  labelText="Equipment Description"
                  id="equipmentInfo2"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  multiline={3}
                  inputProps={{
                    type: "text",
                    name: "equipmentInfo2",
                    value: fields.equipmentInfo2.value,
                    onChange: e => setValue(e.target.value, "equipmentInfo2"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Lender Info</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Lender Name"
                  id="lenderName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "lenderName",
                    value: fields.lenderName.value,
                    onChange: e => setValue(e.target.value, "lenderName"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Lender Contact Name"
                  id="lenderContact"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "lenderContact",
                    value: fields.lenderContact.value,
                    onChange: e => setValue(e.target.value, "lenderContact"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Approval Info</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Approved Amount"
                  id="approvedAmount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "approvedAmount",
                    value: fields.approvedAmount.value,
                    onChange: e => setValue(e.target.value, "approvedAmount"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  labelText="Approved Rate"
                  id="approvedRate"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "approvedRate",
                    value: fields.approvedRate.value,
                    onChange: e => setValue(e.target.value, "approvedRate"),
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Invoice Info</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Invoice Number"
                  id="invoiceNumber"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "invoiceNumber",
                    value: fields.invoiceNumber.value,
                    onChange: e => setValue(e.target.value, "invoiceNumber"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Invoice Date"
                  id="invoiceDate"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "invoiceDate",
                    value: fields.invoiceDate.value,
                    onChange: e => setValue(e.target.value, "invoiceDate"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Number Of Advance Payments"
                  id="numberOfAdvancedPayments"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "numberOfAdvancedPayments",
                    value: fields.numberOfAdvancedPayments.value,
                    onChange: e => setValue(e.target.value, "numberOfAdvancedPayments"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Sales Rep Doc Fees"
                  id="salesRepDocFee"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "salesRepDocFee",
                    value: fields.salesRepDocFee.value,
                    onChange: e => setValue(e.target.value, "salesRepDocFee"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Tax Rate"
                  id="taxRate"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "taxRate",
                    value: fields.taxRate.value,
                    onChange: e => setValue(e.target.value, "taxRate"),
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Doc Invoice Total"
                  id="docInvoiceTotal"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "docInvoiceTotal",
                    value: fields.docInvoiceTotal.value,
                    onChange: e => setValue(e.target.value, "docInvoiceTotal"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Total Eqpt Cost"
                  id="totalEqptCost"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "totalEqptCost",
                    value: fields.totalEqptCost.value,
                    onChange: e => setValue(e.target.value, "totalEqptCost"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Sales Tax"
                  id="salesTax"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "salesTax",
                    value: fields.salesTax.value,
                    onChange: e => setValue(e.target.value, "salesTax"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Freight/Labor/Install/Other Fees"
                  id="freightLaborInstall"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "freightLaborInstall",
                    value: fields.freightLaborInstall.value,
                    onChange: e => setValue(e.target.value, "freightLaborInstall"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Difference To Collect"
                  id="differenceToCollect"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "differenceToCollect",
                    value: fields.differenceToCollect.value,
                    onChange: e => setValue(e.target.value, "differenceToCollect"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  labelText="Invoice Grand Total"
                  id="invoiceGrandTotal"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "invoiceGrandTotal",
                    value: fields.invoiceGrandTotal.value,
                    onChange: e => setValue(e.target.value, "invoiceGrandTotal"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">Accepted Proposal Info</Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Payment"
                  id="payment"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "payment",
                    value: fields.payment.value,
                    onChange: e => setValue(e.target.value, "payment"),
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Term"
                  id="term"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "term",
                    value: fields.term.value,
                    onChange: e => setValue(e.target.value, "term"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <FormControl fullWidth className={uiClasses.selectFormControl}>
                  <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                    90 Days Deferred
                  </InputLabel>
                  <Select
                    classes={{ select: uiClasses.select }}
                    value={fields.ninetyDaysDeferred.value}
                    inputProps={{
                      name: "ninetyDaysDeferred",
                      id: "ninetyDaysDeferred",
                      onChange: e => setValue(e.target.value, "ninetyDaysDeferred"),
                    }}
                  >
                    <MenuItem value={"true"}>Yes</MenuItem>
                    <MenuItem value={"false"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Credit Product"
                  id="creditProduct"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "creditProduct",
                    value: fields.creditProduct.value,
                    onChange: e => setValue(e.target.value, "creditProduct"),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Dealer Markup"
                  id="dealerMarkup"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "dealerMarkup",
                    value: fields.dealerMarkup.value,
                    onChange: e => setValue(e.target.value, "dealerMarkup"),
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <CustomInput
                  labelText="Trnsact Markup"
                  id="dcrMarkup"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    name: "dcrMarkup",
                    value: fields.dcrMarkup.value,
                    onChange: e => setValue(e.target.value, "dcrMarkup"),
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  className={classes.submitButton}
                  onClick={handleSubmit}
                  disabled={!formValid || (!!fdr && fdr.agreementStatus === "sent")}
                  type="submit"
                >
                  {fdr ? "Save" : "Submit"}
                </Button>
                {!!fdr && fdr.fdrStatus && (
                  <Button
                    color="primary"
                    className={classes.submitButton}
                    onClick={e => handleDeleteFdr(e, fdr)}
                    disabled={!!fdr && fdr.agreementStatus === "sent"}
                    type="submit"
                  >
                    Delete
                  </Button>
                )}
              </Grid>
              <MessageModal
                isOpen={openMessageModal}
                handleCloseModal={handlePostSubmit}
                title={submissionMessage.title}
                message={submissionMessage.message}
              />
            </Grid>
          </div>
        </Tile>
      </div>
    )
  );
}
