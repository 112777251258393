export const priorityColors: any = {
  urgent: "#bb3354",
  high: "#f44336",
  medium: "#ff9800",
  low: "#2196f3",
  in_progress: "#808080",
  closed: "#808080",
  open: "#ffd180",
  specify: "#c4c4c4",
  add: "#3bc173",
  complete: "#3bc173",
  requested: "#7a7a7a",
  cancel: "#dd5040",
};

export const taskStatusMap = {
  open: "Open",
  complete: "Complete",
  in_progress: "In Progress",
  requested: "Requested",
  cancel: "Cancel",
};

export const prioritySelectOptions = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

export const statusSelectOptions = [
  { label: "Open", value: "open" },
  { label: "Complete", value: "complete" },
  { label: "In Progress", value: "in_progress" },
  { label: "Cancel", value: "cancel" },
  { label: "Requested", value: "requested" },
];

export const priorityFiltersOptions = prioritySelectOptions;
export const statusFiltersOptions = statusSelectOptions;
