import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import LoginPage from "../pages/Auth/LoginPage";
import ForgotPasswordPage from "../pages/Auth/ForgotPasswordPage";
import SignUpPage from "../pages/Auth/SignUpPage";
import AcceptInvitationForm from "../pages/Auth/AcceptInvitationForm";
import ConsumerCreditApp from "../pages/Legacy/ConsumerCreditApp";
import { MobileContext } from "contexts/MobileContext";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [isMobile, setIsMobile] = React.useState(false);
  const mobileContextVal = { isMobile, setIsMobile };
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const partnerId = queryParams.get("partnerId");

    if (partnerId) {
      if (window.location.pathname.indexOf("/signup") === -1) {
        window.top.location = "/signup/?partnerId=" + partnerId;
      }
    }
    if (window.innerWidth <= 600) {
      setIsMobile(true);
    }
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  useEffect(() => {
    if (props.currentAuthenticatedUser === null) {
      if (
        !window.location.pathname.includes("/login") &&
        !window.location.pathname.includes("/forgotpassword") &&
        !window.location.pathname.includes("/signup")
      ) {
        window.history.pushState("", "Login", "/login/?redirectTo=" + window.location.pathname);
      }
    }
  }, [props.currentAuthenticatedUser]);

  const getBgImage = () => {
    if (window.location.pathname.indexOf("/signup") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/login") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/forgotpassword") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/welcome") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/login-error") !== -1) {
      return error;
    }
  };

  return (
    <MobileContext.Provider value={mobileContextVal}>
      <AuthNavbar brandText={""} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage} style={{ backgroundImage: "url(" + getBgImage() + ")" }}>
          <Switch>
            <Redirect from="/" to="/login" exact />
            <Route exact path="/signup" component={SignUpPage} />
            <Route path="/welcome" component={AcceptInvitationForm} {...props} />
            <Route path="/PublicForms/Form.aspx" component={ConsumerCreditApp} />
            <Route
              exact
              path="/forgotpassword"
              render={() => (
                <ForgotPasswordPage
                  onPasswordResetSuccess={props.onPasswordResetSuccess}
                  onPasswordResetError={props.onPasswordResetError}
                />
              )}
            />
            <Route
              path="*"
              render={() => <LoginPage onLoginSuccess={props.onLoginSuccess} onLoginError={props.onLoginError} />}
            />
          </Switch>
          <Footer white />
        </div>
      </div>
    </MobileContext.Provider>
  );
}
