import React from "react";
import { Modal } from "@material-ui/core";
import { ModalsKeys } from "../Modals";
import { useModal } from "../../../../hooks/useModal";
import EquipmentInputModal from "../../../EquipmentInputModal";
import { useSelector } from "react-redux";

export const CreateEquipmentModalOld = () => {
  const { isOpen, handleClose, data } = useModal(ModalsKeys.CreateEquipment);

  const vo = useSelector((state: any) => state.vo);
  const account = useSelector((state: any) => state.account);
  const creditApp = useSelector((state: any) => state.creditApp);
  const vendorProfile = useSelector((state: any) => state.vp);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <EquipmentInputModal
        vo={vo}
        account={account}
        handleClose={handleClose}
        creditApp={creditApp}
        vendorProfile={vendorProfile}
        equipmentData={data?.equipmentData}
        onEquipmentCreated={data?.onEquipmentCreated}
        equipmentId={data?.equipmentData?.equipments?.[0].equipmentId}
      />
    </Modal>
  );
};
