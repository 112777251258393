import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  SET_CHAT_OPENED,
  SET_CHAT_RESET,
  SET_CHAT_TARGET_CS,
  SET_CHAT_TARGET_TOPIC,
  SET_CHAT_TARGET_VOID,
  SET_VO,
} from "redux/types";
import { makeStyles } from "@material-ui/core/styles";

import _ from "lodash";

const useStyles = makeStyles({
  list: {},
});

const Alert = props => {
  return <MuiAlert elevation={6} {...props} />;
};

const AlertChats = () => {
  const classes = useStyles();
  const history = useHistory();
  const unread = useSelector(state => state.chat.unread);
  const relatedUsers = useSelector(state => state.relatedUsers);
  const dispatch = useDispatch();
  const [alertOpen, setAlertOpen] = useState(false);
  const [unreadMessagesListAlert, setUnreadMessagesListAlert] = useState([]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };
  const handleGoToTransaction = (e, unreadTopic) => {
    e.preventDefault();

    dispatch({
      type: SET_CHAT_RESET,
    });

    if (!unreadTopic.dynamicCreditSubmissionId) {
      history.push(`/chat`);
      return;
    }

    //Open chat
    dispatch({
      type: SET_CHAT_OPENED,
      payload: { opened: true },
    });

    //Credit sub
    dispatch({
      type: SET_CHAT_TARGET_CS,
      payload: { targetCS: unreadTopic.dynamicCreditSubmissionId },
    });

    dispatch({
      type: SET_CHAT_TARGET_TOPIC,
      payload: { targetTopic: unreadTopic.feedId },
    });

    dispatch({
      type: SET_CHAT_TARGET_VOID,
      payload: { targetVoId: unreadTopic.vendorOpportunityId },
    });

    dispatch({
      type: SET_VO,
      payload: {
        vendorOpportunityId: unreadTopic.vendorOpportunityId,
        potentialCustomer: { name: unreadTopic.relatedTo },
      },
    });
    history.push(`/vo/${unreadTopic.vendorOpportunityId}`);
  };

  useEffect(() => {
    if (!_.isEmpty(unread) && !_.isEmpty(relatedUsers)) {
      setUnreadMessagesListAlert(unread);
      setAlertOpen(true);
    } else {
      setUnreadMessagesListAlert([]);
      setAlertOpen(false);
    }
  }, [unread, relatedUsers]);

  return (
    <Snackbar
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={alertOpen}
      onClose={handleCloseAlert}
      key={"top" + "right"}
    >
      <Alert severity="info">
        You have new messages:
        <ul>
          {unreadMessagesListAlert.map((unreadTopic, key) => {
            return (
              <li key={key}>
                <p>
                  {unreadTopic.count} messages on credit submission:{" "}
                  <a
                    onClick={e => {
                      handleGoToTransaction(e, unreadTopic);
                    }}
                    href="#"
                  >
                    {unreadTopic.relatedTo}
                  </a>
                </p>
              </li>
            );
          })}
        </ul>
      </Alert>
    </Snackbar>
  );
};

export default AlertChats;
