import React, { useMemo, useState } from "react";
import ContactDetailModal from "./ContactDetailModal";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { CardContainer } from "../../components/shared/CardContainer/CardContainer";
import { ContentViewType } from "../../global";
import { RowDataList } from "../../components/shared/RowDataList/RowDataList";

export default function ContactCard({
  vo,
  refetchVO,
  creditApp,
  contactsTableBody,
  setContactsTableBody,
  multiLocationManagement,
  contentViewType = ContentViewType.Desktop,
}) {
  const classes = useStyles();
  const { push } = useHistory();

  const [openContactDetail, setOpenContactDetail] = useState(false);

  const handleOpenContactDetailModal = () => setOpenContactDetail(true);
  const handleCloseContactDetailModal = () => setOpenContactDetail(false);
  const redirectToLocation = () => push(`/locationDetail/${vo.location.locationId}`);

  // TODO decision to prepare data at different levels of the application, violates the GRASP principles - needs to rework

  const dataForDisplay = useMemo(() => {
    const initialData = [
      ...contactsTableBody,
      [<span style={{ fontWeight: "bold" }}>Status Details</span>, vo.statusDetail ? vo.statusDetail : "N/A"],
    ];

    if (multiLocationManagement) {
      initialData.unshift([
        <span style={{ fontWeight: "bold" }}>Location</span>,
        <>
          {!_.get(vo, "location") && (
            <Grid item xs={6}>
              <Box className={classes.locationBlock}>
                <Typography component="span" variant="body2">
                  Branch:
                </Typography>

                <Box className="block">
                  <Typography component="span" variant="caption" className="status">
                    Missing
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}

          {_.get(vo, "location.category") === "Branch" && (
            <Grid item xs={6}>
              {_.get(vo, "location.parentLocation.category") === "Region" && (
                <>
                  <span>Region: </span>
                  <span className={classes.cardItemText} onClick={redirectToLocation}>
                    {vo.location.parentLocation.locationName}
                  </span>
                  <span> | </span>
                </>
              )}
              <span>Branch: </span>
              <span className={classes.cardItemText} onClick={redirectToLocation}>
                {vo.location.locationName}
              </span>
            </Grid>
          )}

          {_.get(vo, "location.category") === "Region" && (
            <Grid item xs={6}>
              <span>Region: </span>
              <span className={classes.cardItemText} onClick={redirectToLocation}>
                {vo.location.locationName}
              </span>
              <span> | </span>
              <span>Branch: </span>
              <span style={{ color: "red", fontWeight: "bold" }}>Missing</span>
            </Grid>
          )}
        </>,
      ]);
    }

    return initialData;
  }, [contactsTableBody, vo]);

  return (
    <CardContainer
      title="Details"
      contentViewType={contentViewType}
      actionBtnProps={{
        label: "Edit details",
        onClick: handleOpenContactDetailModal,
      }}
    >
      <RowDataList data={dataForDisplay} contentViewType={contentViewType} />

      <ContactDetailModal
        creditApp={creditApp}
        refetchVO={refetchVO}
        open={openContactDetail}
        setContactsTableBody={setContactsTableBody}
        handleClose={handleCloseContactDetailModal}
        handleCloseContactDetailModal={handleCloseContactDetailModal}
      />
    </CardContainer>
  );
}

const useStyles = makeStyles(({ palette }) => ({
  cardItemText: {
    cursor: "pointer",
    color: palette.primary.main,
  },
  locationBlock: {
    display: "flex",
    alignItems: "center",

    "& .block": {
      display: "flex",
      maxWidth: "60px",
      marginLeft: "4px",
      padding: "6px 8px",
      borderRadius: "8px",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: red["50"],

      "& .status": {
        color: red["A400"],
        textTransform: "capitalize",
      },
    },
  },
}));
