import React from "react";
import { MenuCards, MenuFields, MenuSelector } from "../../../../components";

export const Menu = () => {
  return (
    <>
      <MenuSelector />
      <MenuFields />
      <MenuCards />
    </>
  );
};
