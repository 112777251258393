import React from "react";
import Tile from "../../components/Tile/Tile";

export default function PrequalReceipt() {
  return (
    <Tile title="Prequal Application successfully submitted.">
      <p>Prequal Receipt</p>
    </Tile>
  );
}
