import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import moment from "moment";
import { Button, Grid, IconButton, Modal, TextareaAutosize } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import Tile from "../components/Tile/Tile";
import DragIndicator from "assets/img/drag-indicator.svg";
import { isEmpty } from "lodash";
const useStyles = makeStyles(theme => ({
  fixReactTable: {
    "& .ReactTable .pagination-bottom button": {
      height: "40px",
    },
    "& .ReactTable .-pagination .-center": {
      flexWrap: "nowrap",
    },
    "& .ReactTable .-pageInfo": {
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .-totalPages": {
      marginLeft: "10px",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  noDataStyles: {
    display: "block",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.8)",
    transition: "all 0.3s ease",
    zIndex: "1",
    pointerEvents: "none",
    padding: "20px",
    color: "rgba(0, 0, 0, 0.5)",
  },
  notesReactTable: {
    // height: 320,
  },
  closeButton: {
    margin: "auto 4px",
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
  },
  surface: {
    position: "absolute",
    width: "50%",
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  saveButtonStyles: {
    marginTop: 20,
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 125,
    maxWidth: "70%",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: "-0,56px",
  },
  notesBox: {
    overflowY: "auto",
    marginTop: "10px",
    resize: "none",
    width: "100%",
    minHeight: "100px",
    maxHeight: "200px",
    border: "1px solid #DDD",
    borderRadius: "10px",
    padding: "5px",
  },
}));

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

const NotesTable = ({ notes, onNoteDelete, onNoteUpdate }) => {
  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [curNote, setCurNote] = useState(null);
  const [editNote, setEditNote] = useState(false);
  const [updatedNote, setUpdatedNote] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.fixReactTable}>
      <ReactTable
        className={"-striped -highlight " + classes.notesReactTable}
        NoDataComponent={props => {
          return (
            <div {...props} className={classes.noDataStyles}>
              No rows found
            </div>
          );
        }}
        color="primary"
        data={notes}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            style: { cursor: "pointer" },
            onClick: e => {
              setCurNote(rowInfo.original);
              setOpenNoteModal(true);
            },
          };
        }}
        onPageChange={changePage => {
          setPageNumber(changePage);
        }}
        onPageSizeChange={changePageSize => {
          setPageSizeValue(changePageSize);
          setPageNumber(0);
        }}
        pageSize={pageSizeValue}
        page={pageNumber}
        columns={[
          {
            Header: "Created On",
            accessor: "createdOn",
            resizable: false,
            Cell: props => (
              <div>
                {props.value
                  ? `${moment(props.value).format("MM-DD-yyyy")}, ${moment(props.value).format("h:mm A")}`
                  : ""}
              </div>
            ),
            Filter: () => <></>,
          },
          {
            Header: "Created By",
            accessor: "createdBy",
            Cell: props => {
              return <div>{props.value ? props.value : ""}</div>;
            },
            Filter: <></>,
            resizable: false,
          },
          {
            Header: "Note",
            accessor: "note",
            resizable: true,
            Cell: props => <div>{!isEmpty(props.value) ? props.value : "--"}</div>,
            Filter: () => <></>,
          },
        ]}
      />
      <Modal
        open={openNoteModal}
        onClose={() => {
          setOpenNoteModal(false);
          setEditNote(false);
        }}
        aria-labelledby="Note View"
        aria-describedby="Input"
        disableEnforceFocus={true}
      >
        <div style={modalStyle} className={classes.surface}>
          <Tile
            title={curNote?.createdBy}
            maxHeightLimitByScreen={true}
            titleButtonComponent={
              <div className={classes.buttonContainer}>
                <IconButton
                  aria-label="edit"
                  className={classes.closeButton}
                  onClick={() => {
                    setEditNote(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  className={classes.closeButton}
                  onClick={() => {
                    onNoteDelete(curNote);
                    setOpenNoteModal(false);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={() => {
                    setOpenNoteModal(false);
                    setEditNote(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            }
          >
            {editNote ? (
              <>
                <TextareaAutosize
                  onChange={e => setUpdatedNote(e.target.value)}
                  className={classes.notesBox}
                  defaultValue={curNote?.note}
                />
                <Button
                  className={classes.saveButtonStyles}
                  onClick={() => {
                    onNoteUpdate(curNote, updatedNote);
                    setOpenNoteModal(false);
                  }}
                >
                  SAVE
                </Button>
              </>
            ) : (
              <>
                <p style={{ marginBottom: 24 }}>{curNote?.note}</p>
                <label>
                  {moment(curNote?.createdOn).format("MM-DD-yyyy")}, {moment(curNote?.createdOn).format("h:mm A")}
                </label>
              </>
            )}
          </Tile>
        </div>
      </Modal>
    </Grid>
  );
};
export default NotesTable;
