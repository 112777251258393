import React from "react";
import { Controller } from "react-hook-form";
import { TextFieldProps } from "@material-ui/core";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { FormInputSelect } from "../FormInputSelect";

interface Props<FormValues extends FieldValues = FieldValues> extends Omit<TextFieldProps, "select" | "onChange"> {
  label: string;
  options: any;
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
}

export const SelectField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  options,
  control,
  ...other
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormInputSelect label={label} variant="standard" options={options} {...other} {...field} />
      )}
    />
  );
};
