import { SET_VP } from "./types";

const initialState = {};

export const vpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VP:
      // console.log('inside VP Reducer - State', state)
      // console.log('inside VP Reducer - payload', action.payload)
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
