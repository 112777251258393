import React from "react";
import clsx from "clsx";
import { indigo } from "@material-ui/core/colors";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CalculateMultiplePaymentsPaymentOptionsResponse } from "@trnsact/trnsact-shared-types";
import { formatCurrency } from "../../../../../utils";

interface Props {
  isCalculating: boolean;
  paymentOption: CalculateMultiplePaymentsPaymentOptionsResponse;
}

export const PaymentOptionCard = ({ paymentOption, isCalculating }: Props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.card, { [classes.cardCalculating]: isCalculating })}>
      <Typography className={classes.term} component="span" variant="h6">
        for {paymentOption.term} months
      </Typography>

      <Typography component="span" variant="h5">
        {formatCurrency(paymentOption.paymentAmountPerPeriod)}/mo.
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    maxHeight: "92px",
    flex: "1 1 200px",
    borderRadius: "2px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: indigo["50"],
  },
  cardCalculating: {
    filter: "blur(2px)",
  },
  term: {
    color: indigo["600"],
  },
});
