import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import blue from "@material-ui/core/colors/blue";
import Button from "components/CustomButtons/Button";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { QUERY_USER_DOCS, SUBMIT_APP } from "../queries";

export default function LenderSubmission(props) {
  const { data: documentsData, refetch: refetchDocuments, loading } = useQuery(QUERY_USER_DOCS, {
    variables: { creditSubId: props.cs.creditSubId, VOId: props.cs.voId },
  });
  const setOpenEquipmentInputModal = props.setOpenEquipmentInputModal;
  const setInvoiceEquipmentTabPage = props.setInvoiceEquipmentTabPage;
  const creditSubmissions = props.creditSubmissions;
  const userProfile = useSelector(state => state.userProfile);
  const classes = { ...useStyles(), ...useUiStyles() };
  const [lenderId, setLenderId] = useState("");
  const [checkedLenderName, setCheckedLenderName] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [isAddEquipment, setAddEquipment] = useState(false);
  const [isSummaryIncluded, setSummaryIncluded] = useState(false);
  const [transactionSummary, setTransactionSummary] = useState(null);
  const [transactionSummaryList, setTransactionSummaryList] = useState(undefined);
  const [transactionLender, setTransactionLender] = useState(null);
  const [errors, setErrors] = useState({
    model: false,
    make: false,
    year: false,
    truckClass: false,
    wholesaleCost: false,
    totalWholesaleCost: false,
    retailPrice: false,
  });
  const handleClose = () => {
    setOpenAlert(false);
  };

  const toyotaMinimumDataValidation = () => {
    let result = false;

    let fieldsAnalized = {
      model: false,
      make: false,
      year: false,
      truckClass: false,
      wholesaleCost: false,
      totalWholesaleCost: false,
      retailPrice: false,
      attachmentsCost: false,
      attachmentsModel: false,
      attachmentsMake: false,
    };

    try {
      setErrors([]);
      if (checkedLenderName.find(item => item.submissionMethod.toLowerCase().includes("toyota"))) {
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalized.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalized.make = true;
            result = true;
          }

          if (!equipmentData.truckClass) {
            fieldsAnalized.truckClass = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalized.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalized.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.totalWholesaleCost) {
            fieldsAnalized.totalWholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalized.retailPrice = true;
            result = true;
          }

          if (!_.isEmpty(equipmentData.attachments)) {
            equipmentData.attachments.map(a => {
              if (!a.unitCost) {
                fieldsAnalized.attachmentsCost = true;
                result = true;
              }
              if (!a.manufacturer) {
                fieldsAnalized.attachmentsMake = true;
                result = true;
              }
              if (!a.model) {
                fieldsAnalized.attachmentsModel = true;
                result = true;
              }
            });
          }
        } else {
          fieldsAnalized = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            attachmentsCost: false,
            attachmentsModel: false,
            attachmentsMake: false,
          };
          result = true;
        }

        setErrors(fieldsAnalized);

        if (result) {
          setErrorType("MissingData");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const routeOneDataValidation = () => {
    let result = false;
    let fieldsAnalized = {
      model: false,
      make: false,
      year: false,
      truckClass: false,
      wholesaleCost: false,
      totalWholesaleCost: false,
      retailPrice: false,
      attachmentsCost: false,
      attachmentsModel: false,
      attachmentsMake: false,
    };

    try {
      if (checkedLenderName.find(item => item.submissionMethod.toLowerCase().includes("routeone"))) {
        if (_.get(props, "equipmentData.equipments[0]", false)) {
          const equipmentData = props.equipmentData.equipments[0];
          if (!equipmentData.model) {
            fieldsAnalized.model = true;
            result = true;
          }

          if (!equipmentData.make) {
            fieldsAnalized.make = true;
            result = true;
          }

          if (!equipmentData.truckClass) {
            fieldsAnalized.truckClass = true;
            result = true;
          }

          if (!equipmentData.year) {
            fieldsAnalized.year = true;
            result = true;
          }

          if (!equipmentData.wholesaleCost) {
            fieldsAnalized.wholesaleCost = true;
            result = true;
          }

          if (!equipmentData.totalWholesaleCost) {
            fieldsAnalized.totalWholesaleCost = true;
            result = true;
          }

          if (!equipmentData.retailPrice) {
            fieldsAnalized.retailPrice = true;
            result = true;
          }

          if (!_.isEmpty(equipmentData.attachments)) {
            equipmentData.attachments.map(a => {
              if (!a.unitCost) {
                fieldsAnalized.attachmentsCost = true;
                result = true;
              }
              if (!a.manufacturer) {
                fieldsAnalized.attachmentsMake = true;
                result = true;
              }
              if (!a.model) {
                fieldsAnalized.attachmentsModel = true;
                result = true;
              }
            });
          }
        } else {
          fieldsAnalized = {
            model: true,
            make: true,
            year: true,
            truckClass: true,
            wholesaleCost: true,
            totalWholesaleCost: true,
            retailPrice: true,
            attachmentsCost: false,
            attachmentsModel: false,
            attachmentsMake: false,
          };
          result = true;
        }

        if (result) {
          setErrorType("No equipment");
          setOpenAlert(true);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return result;
  };

  const handleSubmit = event => {
    event.preventDefault();
    const valid = toyotaMinimumDataValidation();
    if (!valid) {
      submitCreditApp().then(res => {
        console.log(res);
      });
    }
  };

  const handleSelectLender = lenderProfileId => {
    const selected = checkedLenderName.find(lender => lender.lenderProfileId === lenderProfileId);

    if (selected) {
      setCheckedLenderName(prevData => prevData.filter(lender => lender.lenderProfileId !== lenderProfileId));
    }
  };

  const [creditSubmissionRequest] = useMutation(SUBMIT_APP, {
    context: { authRequired: true },
  });

  const submitCreditApp = async () => {
    try {
      await props.handleOpenModal();
      await props.handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Submitting your request to lenders...",
      });

      const creditSubmissionsInfo = checkedLenderName.map(lender => {
        const sharedDocuments = _.get(documentsData, "documents", []).map(
          ({ documentCreditSubmission, documentId }) => {
            let lenderAccess = false;

            if (documentCreditSubmission) {
              const thisSubmision = _.find(documentCreditSubmission, {
                creditSubmissionId: props.cs.creditSubmissionId,
              });
              if (thisSubmision) {
                lenderAccess = thisSubmision.lenderAccess;
              }
            }

            return { documentId, lenderAccess };
          }
        );

        let creditsubmissionValues = {
          lenderProfileId: lender.lenderProfileId,
          lenderContactId: lender.lenderContactId,
          transactionSummary,
          createDraftOnly: false,
          accountId: props.cs.dealerId,
          submittedLender: lender.lenderAccountId,
          dynamicsCreditApplicationId: props.cs.dynamicsCreditApplicationId,
          dynamicsVOId: props.cs.dynamicsVOId,
          dynamicsUserId: userProfile.dynamicsContactId, // we should probably pass a dynamicsUserId from original cs here
          amountRequested: props.cs.invoiceTotal,
          masterCreditSubmissionId: props.cs.creditSubId,
          syndicatedBy: userProfile.dynamicsContactId,
          source: "300",
          display: 200,
        };

        if (sharedDocuments) {
          creditsubmissionValues.sharedDocuments = sharedDocuments;
        }

        return creditsubmissionValues;
      });

      await creditSubmissionRequest({
        variables: {
          input: {
            creditSubmissions: creditSubmissionsInfo,
          },
        },
      });
      // Removes all contents of the array so that checked lender names is reset after every lender submission
      while (checkedLenderName.length) {
        checkedLenderName.pop();
      }
      await props.handleSubmissionMessage({
        title: "Success",
        message: "Submitted Successfully",
      });
      await props.refetch();
    } catch (err) {
      console.log(err);

      props.handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const handleChangeCheckedLenderName = event => {
    setCheckedLenderName(prevData => {
      console.log({ prevData, newData: event.target.value });
      return prevData.concat(event.target.value);
    });
  };

  useEffect(() => {
    setLenderId(checkedLenderName.lenderAccountId);
    routeOneDataValidation();
  }, [checkedLenderName]);

  useEffect(() => {
    if (isAddEquipment) {
      setInvoiceEquipmentTabPage(0);
      const valid = toyotaMinimumDataValidation();
      if (!valid) {
        submitCreditApp().then(res => {
          console.log(res);
        });
      }
      setAddEquipment(false);
    }
  }, [props.equipmentData]);

  useEffect(() => {
    setTransactionSummary(null);
  }, [isSummaryIncluded]);

  useEffect(() => {
    if (creditSubmissions) {
      const transactionInfo = creditSubmissions
        .map(obj => ({ lenderName: obj.lenderName, transactionSummary: obj.transactionSummary }))
        .filter(obj => obj.transactionSummary);
      setTransactionSummaryList(_.uniqWith(transactionInfo, _.isEqual));
    }
  }, [creditSubmissions]);

  return (
    <div className={classes.container}>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {errorType === "MissingData" ? (
          <>
            <DialogTitle id="alert-dialog-title">{"Missing data for Lender"}</DialogTitle>
            <DialogContent>
              You need to provide the following information in order to submit to this lender:
              <div>
                <ul>
                  {errors.make ? <li>Manufacturer</li> : null}
                  {errors.model ? <li>Model</li> : null}
                  {errors.year ? <li>Year</li> : null}
                  {errors.truckClass ? <li>Truck Class</li> : null}
                  {errors.wholesaleCost ? <li>Wholesale Cost</li> : null}
                  {errors.totalWholesaleCost ? <li>Total Wholesale Cost</li> : null}
                  {errors.retailPrice ? <li>Retail Price</li> : null}
                  {errors.attachmentsCost ? <li>Attachment Unit Cost</li> : null}
                  {errors.attachmentsModel ? <li>Attachment Model</li> : null}
                  {errors.attachmentsMake ? <li>Attachment Manufacturer</li> : null}
                </ul>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">{"No equipment data found"}</DialogTitle>
            <DialogContent>
              Before submitting to RouteOne you may add equipment details if you have them available
            </DialogContent>
            <DialogActions style={{ padding: "24px", justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  handleClose();
                  setAddEquipment(true);
                  setInvoiceEquipmentTabPage(1);
                  setOpenEquipmentInputModal(true);
                }}
                color="primary"
                autoFocus
              >
                Add equipment details
              </Button>
              <Button
                onClick={e => {
                  handleSubmit(e);
                  handleClose();
                  setErrorType("");
                }}
                color="primary"
                autoFocus
              >
                Submit without equipment details
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Grid container direction="column">
        <Grid item xs={4}>
          <Typography component="span" variant="subtitle2">
            Create submission
          </Typography>
        </Grid>
        {props.lp ? (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={[classes.selectFormControl, classes.formControlLenders].join(" ")} fullWidth>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {!checkedLenderName.length && (
                  <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                    Select Lenders
                  </InputLabel>
                )}
                <Select
                  multiline
                  classes={{ select: classes.select }}
                  className={classes.selectLender}
                  value={checkedLenderName[0] || ""}
                  renderValue={() =>
                    checkedLenderName.map(lender => {
                      return (
                        <Grid
                          key={`select_${lender.lenderProfileId}`}
                          container
                          direction="column"
                          className={classes.selectItem}
                        >
                          <Grid item>
                            <span className={classes.selectItemText}>{lender.lenderName}</span>
                          </Grid>
                          <Grid item>
                            <span className={classes.selectItemSubText}>{lender.submissionMethod}</span>
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                  onChange={handleChangeCheckedLenderName}
                  inputProps={{ name: "lenderSelect", id: "lender-select" }}
                  style={{ marginBottom: 20 }}
                >
                  {!!props &&
                    props.lp.length &&
                    props.lp.map(item => {
                      return (
                        <MenuItem
                          key={`item_${item.lenderProfileId}`}
                          value={item}
                          classes={{ root: classes.selectMenuItem }}
                          onClick={() => handleSelectLender(item.lenderProfileId)}
                        >
                          <Grid container alignItems="center">
                            <Checkbox
                              checked={checkedLenderName.find(
                                lender => lender.lenderProfileId === item.lenderProfileId
                              )}
                              tabIndex={-1}
                              onChange={() => handleSelectLender(item.lenderProfileId)}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{ checked: classes.checked }}
                            />
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item className={classes.optionText}>
                                  {item.lenderName}
                                </Grid>
                                <Grid item className={classes.optionSubText}>
                                  {item.submissionMethod}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
            </FormControl>
            <Grid container justify="space-between" alignItems="center" style={{ minHeight: "50px" }}>
              <Grid item xs={8}>
                <Typography component="span" variant="subtitle2">
                  Include a Transaction Summary?
                </Typography>
                <Switch
                  color="primary"
                  value={isSummaryIncluded}
                  onChange={(_, checked) => setSummaryIncluded(checked)}
                />
              </Grid>
              {isSummaryIncluded && transactionSummaryList.length > 0 && (
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="summary-select" style={{ textTransform: "uppercase" }}>
                      ADD Existing Summary?
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="summary-select"
                      onChange={e => {
                        setTransactionLender(e.target.value);
                        setTransactionSummary(e.target.value.transactionSummary);
                      }}
                      renderValue={e => (
                        <Grid
                          key={transactionLender.lenderName}
                          container
                          justify="space-between"
                          direction="column"
                          className={classes.selectItem}
                        >
                          <span className={classes.selectItemText}>{transactionLender.lenderName}</span>
                          <span className={classes.selectItemSubText}>{transactionLender.transactionSummary}</span>
                        </Grid>
                      )}
                    >
                      {transactionSummaryList.map(item => (
                        <MenuItem
                          key={item.lenderName + item.transactionSummary}
                          value={item}
                          classes={{ root: classes.selectMenuItem }}
                        >
                          <Grid container alignItems="center">
                            <Checkbox
                              checked={
                                transactionLender &&
                                transactionLender.transactionSummary === item.transactionSummary &&
                                transactionLender.lenderName === item.lenderName
                              }
                              tabIndex={-1}
                              checkedIcon={<Check className={classes.checkedIcon} />}
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{ checked: classes.checked }}
                            />
                            <Grid item>
                              <Grid container direction="column">
                                <Grid item className={classes.optionText}>
                                  {item.lenderName}
                                </Grid>
                                <Grid item className={classes.optionSubText}>
                                  {item.transactionSummary}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container alignItems="center" className={classes.transactionContainer}>
              {isSummaryIncluded && (
                <TextField
                  className={classes.transactionInput}
                  value={transactionSummary}
                  label="Transaction summary (to be shared with your lender(s) upon submission"
                  multiline
                  placeholder="Add summary here"
                  variant="standard"
                  fullWidth
                  onChange={e => setTransactionSummary(e.currentTarget.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
            <Button className={classes.submitButton} onClick={handleSubmit} data-test-id="submit-to-lender-btn">
              Submit
            </Button>
          </Grid>
        ) : (
          "No lenders available. Please contact our team."
        )}
      </Grid>
    </div>
  );
}

const useUiStyles = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  submitButton: {
    marginTop: 20,
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 125,
    maxWidth: "70%",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: "-0,56px",
  },
  draftCheckbox: {
    position: "absolute",
    right: 0,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: theme.spacing(2),
    paddingLeft: 0,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: "60%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  transactionContainer: {
    paddingBottom: "10px",
  },
  transactionInput: {
    borderRadius: "10px",
    marginTop: "20px",
    "& .MuiFormLabel-root": {
      color: "#000000",
      fontWeight: "400",
    },
  },
  optionText: {
    fontSize: 14,
    lineHeight: "16px",
    color: "#333",
    fontWeight: "400",
    textTransform: "uppercase",
  },
  optionSubText: {
    color: "#7A7A7A",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "400",
  },
  transactionHeader: {
    fontSize: 20,
    color: "#000",
    lineHeight: "20px",
    fontWeight: 300,
    marginRight: 30,
  },
  selectItem: {
    border: "1px solid #DADCDF",
    borderRadius: "7px",
    padding: 5,
    position: "relative",
    marginRight: 20,
  },
  selectItemText: {
    display: "inline-block",
    borderBottom: "1px solid #DADCDF",
    color: "#000",
    fontSize: "12px",
    fontWeight: 500,
  },
  selectItemSubText: {
    display: "inline-block",
    color: "#737373",
    fontSize: "12px",
    fontWeight: 400,
  },
  selectLender: {
    width: "100%",
    minWidth: 220,
    "& .MuiSelect-root": {
      overflowX: "auto",
    },
  },
}));
