import { SET_USER_PROFILE } from "./types";

const initialState = {};

export const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
