import { SET_CS, SET_CS_REFETCH } from "./types";

const initialState = { refetch: false };

export const creditSubmission = (state = initialState, action) => {
  switch (action.type) {
    case SET_CS:
      return { ...state, ...action.payload };
    case SET_CS_REFETCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
