import React, { useEffect, useState } from "react";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloProviderHooks } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { from } from "apollo-link";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";
import CADMv2 from "../pages/VendorOpportunity/CADMv2";
import { assignFieldsOCAConfigFromActiveTemplate } from "../services/creditAppModalService";
import { checkIfIndividualCreditApp, getCookie } from "../utils";
import {
  QUERY_ACCOUNT_BY_VP_ID,
  QUERY_PORTAL_CONFIGURATION,
  Q_CREDIT_APP,
  Q_VENDOR_PROFILE,
  Q_VO,
} from "../queries/common";
import { portalConfigurationTypes } from "../pages/Prequal/constants";
import _ from "lodash";

const config = require("../config");

const useStyles = makeStyles({
  htmlContainer: {
    width: 800,
    padding: 20,
    margin: "0 auto",
    backgroundColor: "white",
  },
});

export default function ApolloWrapper({ match }) {
  const voId = match.params.vendorOpportunityId;
  const dcrApiKey = getCookie("DCR_API_KEY");
  if (!voId || !dcrApiKey) {
    return null;
  }

  const httpLink = createUploadLink({
    uri: config.DCR_GRAPHQL_ENDPOINT,
  });
  const authMiddleware = setContext((operation, { headers }) => {
    return {
      headers: {
        ...headers,
        "x-dcr-api-key": dcrApiKey,
      },
    };
  });

  const client = new ApolloClient({
    link: from([authMiddleware, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <ApolloProviderHooks client={client}>
        <CADMPDFGeneration voId={voId} />
      </ApolloProviderHooks>
    </ApolloProvider>
  );
}

const CADMPDFGeneration = ({ voId }) => {
  const classes = useStyles();

  const [account, setAccount] = useState();
  const [vendorProfile, setVendorProfile] = useState();
  const [vo, setVo] = useState();
  const [CADMPortalConfiguration, setCADMPortalConfiguration] = useState();
  const [htmlForPDF, setHTMLForPDF] = useState();

  const { data: creditAppData } = useQuery(
    Q_CREDIT_APP,
    {
      variables: {
        VOId: voId,
      },
    },
    { fetchPolicy: "no-cache" }
  );

  const { data: voData, loading: voLoading } = useQuery(Q_VO, {
    context: { authRequired: false },
    fetchPolicy: "no-cache",
    variables: { voId },
  });

  const [getVendorProfile, { data: vpData }] = useLazyQuery(Q_VENDOR_PROFILE, {
    context: { autRequired: true },
    fetchPolicy: "no-cache",
  });

  const [getOcaTemplateAccount, { data: ocaTemplateAccountData }] = useLazyQuery(QUERY_ACCOUNT_BY_VP_ID, {
    context: { authRequired: false },
  });

  const [
    getCADMPortalConfigurations,
    { data: CADMPortalConfigurationData, loading: CADMPortalConfigurationDataLoading },
  ] = useLazyQuery(QUERY_PORTAL_CONFIGURATION, {
    context: { authRequired: false },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const creditApplication = _.get(creditAppData, "creditApplication");
    const vendorOpportunity = _.get(voData, "vendorOpportunity");
    if (_.some([vendorOpportunity, creditApplication], _.isEmpty)) {
      return;
    }
    setVo(vendorOpportunity);
    if (_.get(creditApplication, "ocaTemplate.vendorProfileId")) {
      getOcaTemplateAccount({
        variables: {
          vendorProfileId: creditApplication.ocaTemplate.vendorProfileId,
        },
      });
    }
    getVendorProfile({ variables: { id: vendorOpportunity.vendorProfileId } });
  }, [voData, creditAppData]);

  useEffect(() => {
    // We need to take a vp from oca related account since credit app could be submitted from both the lender's and dealer's templates
    const ocaTemplateAccount = _.get(ocaTemplateAccountData, "accountByVPId");
    const vendorProfile = _.get(vpData, "vendorProfile");
    if (_.some([vendorProfile, ocaTemplateAccount], _.isEmpty)) {
      return;
    }
    const vpId = ocaTemplateAccount.vendorProfileId || vendorProfile.vendorProfileId;
    getCADMPortalConfigurations({
      variables: {
        vendorProfileId: vpId,
        types: [portalConfigurationTypes.CADMIndividual, portalConfigurationTypes.CADMCommercial],
      },
    });
    setAccount(ocaTemplateAccount);
    setVendorProfile(vendorProfile);
  }, [vpData, ocaTemplateAccountData]);

  useEffect(() => {
    if (!CADMPortalConfigurationData || !creditAppData) {
      return;
    }
    const applicationType = _.get(creditAppData, "creditApplication.applicationType");
    const portalConfigurations = _.get(CADMPortalConfigurationData, "portalConfigurations") || [];
    const ocaTemplateJsonDefinition = _.get(creditAppData, "creditApplication.ocaTemplate.jsonDefinition");
    const isIndividualCreditApp = checkIfIndividualCreditApp(applicationType);
    const CADMConfigurationTypeToUse = isIndividualCreditApp
      ? portalConfigurationTypes.CADMIndividual
      : portalConfigurationTypes.CADMCommercial;
    const CADMConfigurationToUse = _.find(portalConfigurations, { type: CADMConfigurationTypeToUse });
    if (_.some(CADMConfigurationToUse)) {
      assignFieldsOCAConfigFromActiveTemplate(CADMConfigurationToUse.jsonDefinition, ocaTemplateJsonDefinition);
    }
    setCADMPortalConfiguration(CADMConfigurationToUse);
  }, [CADMPortalConfigurationData, creditAppData]);

  if (!CADMPortalConfiguration) {
    return null;
  }

  return (
    <>
      {htmlForPDF && (
        <div
          id="htmlForPDF"
          className={classes.htmlContainer}
          dangerouslySetInnerHTML={{
            __html: htmlForPDF,
          }}
        ></div>
      )}
      <div style={{ display: "none" }}>
        <CADMv2
          vo={vo}
          account={account}
          creditAppModalConfig={CADMPortalConfiguration.jsonDefinition}
          vendorProfile={vendorProfile}
          isForHTMLOnly={true}
          setHTMLForPDF={setHTMLForPDF}
        />
      </div>
    </>
  );
};
