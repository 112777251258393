import { useState } from "react";
import { Pagination } from "../global";

export function usePagination(tableDataLength: number, options: Partial<Omit<Pagination, "total">> = {}) {
  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    pageSize: 5,
    total: tableDataLength,
    ...options,
  });

  const onPageChange = (changePage: number) => {
    setPagination(prev => ({ ...prev, page: changePage }));
  };

  const onPageSizeChange = (changePageSize: number) => {
    setPagination(prev => ({ ...prev, pageSize: changePageSize, page: 0 }));
  };

  return {
    onPageChange,
    onPageSizeChange,
    defaultPageSize: pagination.pageSize,
    page: pagination.page,
  };
}
