import { useSelector } from "react-redux";
import { adminRoles, portalConfigurationTypes } from "pages/Prequal/constants";

export function usePermissions() {
  const userProfile = useSelector((state: any) => state.userProfile);
  const portalConfiguration = useSelector((state: any) => state.portalConfiguration);

  const isSuperAdmin = [adminRoles.super, adminRoles.singleAccountOnly].includes(userProfile.adminRole);

  const hasWriteAccess =
    portalConfiguration[`${portalConfigurationTypes.aftermarket}`]?.config.rolesSettings.assignedOnly.includes(
      userProfile.vendorContactRole
    ) || isSuperAdmin;
  const hasReadOnlyAccess = portalConfiguration[
    `${portalConfigurationTypes.aftermarket}`
  ]?.config.rolesSettings.readOnly.includes(userProfile.vendorContactRole);

  return {
    hasWriteAccess,
    hasReadOnlyAccess,
  };
}
