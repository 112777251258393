const ConsumerCreditApp = ({ match }) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  let redirectUrl = "https://creditapp.dcrportal.com/?";
  const urlParamters = [
    ["vconguid", "vendorContactID"],
    ["venguid", "vendorGUID"],
    ["repid", "repId"],
    ["transguid", "transactionGUID"],
  ];
  urlParamters.forEach((crmKey, i) => {
    let urlValue = null;
    url.searchParams.forEach((inputURLValue, inputURLKey) => {
      // Makes it so url paramater search is case insensitive. Not the most effecient code but it works.
      inputURLKey = inputURLKey.toLowerCase();
      if (crmKey[0] === inputURLKey) {
        urlValue = inputURLValue;
      }
    });
    if (urlValue) {
      if (redirectUrl[redirectUrl.length - 1] === "?") {
        redirectUrl = `${redirectUrl}${crmKey[1]}=${urlValue}`;
      } else {
        redirectUrl = `${redirectUrl}&${crmKey[1]}=${urlValue}`;
      }
    }
  });
  window.location.href = redirectUrl;
  return null;
};
export default ConsumerCreditApp;
