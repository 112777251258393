import { vendorContactRole as vendorContactRoles } from "../utils";
// libraries
import _ from "lodash";

const getDefaultFinanceProgram = (isVOView, isCreditSubmissionView) => {
  const defaultFinanceProgram = {
    financeProgramName: "",
    rates: [0],
    terms: [],
    residualPercentage: 0,
    numberOfAdvancePayments: 0,
    flatFees: [],
    markups: [{ identifier: "Lender Markup", markupPercentage: 0 }],
    customerRate: undefined,
    dealerMarkup: undefined,
    paymentPeriodTiming: "BEGINNING_OF_PERIOD",
    type: "LOAN",
    rateType: "INTEREST_RATE",
    financeProgramId: undefined,
  };
  return isVOView || isCreditSubmissionView
    ? { ...defaultFinanceProgram, amountFinanced: 0, downPaymentAmount: 0 }
    : defaultFinanceProgram;
};

const parseProposal = proposal => {
  if (proposal.terms && proposal.rates) {
    proposal.terms = proposal.terms.map((term, i) => {
      if (term.term) {
        return term;
      }
      return {
        term,
        rate: proposal.rates[i],
      };
    });
  }

  const data = {
    financeProgramName: proposal.financeProgramName,
    // rate: parseFloat(proposal.rate) || 0,
    // terms: _.map(proposal.terms, _.parseInt),
    rateType: proposal.rateType,
    terms: proposal.terms.map((term, i) => {
      return {
        term: parseInt(term.term),
        rate: parseFloat(term.rate),
      };
    }),
    amountFinanced: proposal.amountFinanced
      ? parseFloat(proposal.amountFinanced.toString().replace(/[^0-9.]/g, ""))
      : 0,
    residualPercentage: parseFloat(proposal.residualPercentage) || 0,
    markups: [
      {
        ...proposal.markups[0],
        markupPercentage: parseFloat(_.get(proposal, "markups[0].markupPercentage") || "0"),
      },
    ],
    flatFees: proposal.flatFees?.map(ff => {
      return { ...ff, fee: parseFloat(ff.fee.toString().replace(/[^0-9.]/g, "")) };
    }),
    salesTaxPercentage: 0,
    paymentPeriodTiming: proposal.paymentPeriodTiming,
    numberOfAdvancePayments: parseInt(proposal.numberOfAdvancePayments) || 0,
    downPaymentAmount: proposal.downPaymentAmount
      ? parseFloat(proposal.downPaymentAmount.toString().replace(/[^0-9.]/g, ""))
      : 0,
    customerRate: proposal.customerRate,
    dealerMarkup: proposal.dealerMarkup,
  };
  if (data.customerRate === "") {
    delete data.customerRate;
  }
  if (data.dealerMarkup === "") {
    delete data.dealerMarkup;
  } else {
    data.dealerMarkup = { markupPercentage: parseFloat(proposal.dealerMarkup), identifier: "Dealer Markup" };
  }
  if (proposal.menu) {
    data.menu = {
      name: proposal.menu.name,
      description: proposal.menu.description,
      menuOptions: proposal.menu.menuOptions.map(mo => {
        return {
          name: mo.name,
          description: mo.description,
          titleColor: mo.titleColor,
          titleBgColor: mo.titleBgColor,
          products: mo.products.map(p => {
            const aftermarketProduct = p.aftermarketProduct ? { ...p.aftermarketProduct } : undefined;
            if (aftermarketProduct && aftermarketProduct.__typename) {
              delete aftermarketProduct.__typename;
            }
            return {
              title: p.title,
              cost: p.cost,
              retailCost: p.retailCost,
              thirdPartyId: p.thirdPartyId,
              markup: p.markup,
              proposalProductId: p.proposalProductId,
              aftermarketProductToPartnerLinkId: p.aftermarketProductToPartnerLinkId,
              aftermarketProduct,
            };
          }),
        };
      }),
    };
  }
  return data;
};

const proposalRequiredFieldsValidationHashMap = {
  financeProgramName: value => !!value,
  amountFinanced: value => !!value,
  // rate: value => !!value || value === 0,
  // terms: value => _.some(value),
  terms: value => _.every(value, term => !!term.term && (!!term.rate || term.rate === 0)),
  residualPercentage: value => !!value || value === 0,
  numberOfAdvancePayments: value => !!value || value === 0,
  downPaymentAmount: value => !!value || value === 0,
  paymentPeriodTiming: value => !!value,
};

const checkProposalRequiredFields = proposal => {
  return _.every(proposalRequiredFieldsValidationHashMap, (validationFn, field) => validationFn(proposal[field]));
};

export {
  // methods
  getDefaultFinanceProgram,
  parseProposal,
  proposalRequiredFieldsValidationHashMap,
  checkProposalRequiredFields,
};

export const createDate = value => {
  return value ? new Date(/^\d+$/.test(value) ? parseInt(value) : value) : "";
};

export const programStatus = program => {
  let status = "Active";
  let start = createDate(program.validDateStart);
  let end = createDate(program.validDateEnd);

  if (end && end.getTime() < new Date().getTime()) status = "Expired";
  else if (start && start.getTime() > new Date().getTime()) status = "Prepared";

  return status;
};

export const isValidProgram = program => {
  return programStatus(program) !== "Expired" && programStatus(program) !== "Prepared";
};

export const checkIfUserAbleToSeeProposalMenuConstructor = (userProfile, vendorProfile) => {
  if (_.isEmpty(userProfile) || _.isEmpty(vendorProfile)) {
    return false;
  }
  return (
    _.includes(["show_base_gbb"], vendorProfile.showProposal) &&
    (userProfile.vendorContactRole === vendorContactRoles.credit_mgr || userProfile.vendorContactRole === vendorContactRoles.admin)
  );
};

export const checkIfUserIsAbleToSeeProposalMenus = vendorProfile => {
  if (_.isEmpty(vendorProfile)) {
    return false;
  }
  return _.includes(["show_base_gbb"], vendorProfile.showProposal);
};
