import React, { useMemo } from "react";
import { CommonMenuPriceValues, MarkupType } from "../../../types";
import { formatCurrency } from "../../../../../utils";

export const MarkupValues = ({ markup }: Pick<CommonMenuPriceValues, "markup">) => {
  const renderMarkup = useMemo(() => {
    const formatters: Record<MarkupType, (value: number) => string> = {
      [MarkupType.Flat]: (value: number) => formatCurrency(value.toString(), true),
      [MarkupType.Percentage]: (value: number) => `${value}%`,
    };

    if (!markup || !markup?.type) return "—";

    return formatters[markup.type](markup?.markup ?? 0);
  }, [markup]);

  return <>{renderMarkup}</>;
};
