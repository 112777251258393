import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";

export enum AftermarketPageTabs {
  Products = "Products",
  MenuTemplates = "Menu Templates",
  Dashboard = "Dashboard",
}

export interface MenuTemplatesTableFilters {
  search: string;
}

export interface MenuOptionCardsProps {
  menuOptions?: ProposalMenuOption[];
  isCalculating: boolean;
}

export enum ProposalMenuSearchableColumnsEnum {
  Name = "name",
  Description = "description",
}
export enum MenuOptionColorPickerKeysEnum {
  TitleColor = "titleColor",
  TitleBgColor = "titleBgColor",
}

export type ProposalMenuSearchableColumns =
  | ProposalMenuSearchableColumnsEnum.Name
  | ProposalMenuSearchableColumnsEnum.Description;

export type AddEditMenuTemplateUrlParams = {
  proposalMenuId: string;
};

export interface MenuTemplateGeneralFormInputs {
  name: string;
  description: string;
}

export interface AddEditMenuOptionFormInputs {
  name: string;
  description: string;
}

export type MenuOptionToAddEdit = Partial<ProposalMenuOption> & { description: string; archived: boolean };

export type MenuOptionColorPickerKeys =
  | MenuOptionColorPickerKeysEnum.TitleColor
  | MenuOptionColorPickerKeysEnum.TitleBgColor;
