import React from "react";
import { Checkbox, CheckboxProps, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props extends CheckboxProps {
  label?: string;
}

export const FormCheckbox = ({ label, ...checkboxProps }: Props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      color="black"
      label={label}
      control={<Checkbox size="small" disableRipple color="primary" className={classes.checkbox} {...checkboxProps} />}
    />
  );
};

const useStyles = makeStyles({
  checkbox: {
    padding: "0 9px",

    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-checked:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
});
