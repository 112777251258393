import React from "react";
import _ from "lodash";
import NumberFormat from "react-number-format";

const CustomCurrencyInput = React.forwardRef(function CurrencyFormatCustom(props: any, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      displayType="input"
      placeholder=""
      prefix="$"
    />
  );
});

export default CustomCurrencyInput;
