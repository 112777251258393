import React from "react";
import { formatCurrency } from "../../../../utils";
import { FINANCING_TYPE, LEGAL_STRUCTURE } from "@trnsact/trnsact-shared-types";

const capitalize = (s: string) => {
  if (s) {
    if (s.length <= 4) return s.toUpperCase();
    return s
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase());
  } else return s;
};

const friendlyFactName = (fact: string): string => {
  return capitalize(fact);
};

const formatValue = (type: string, value: any): string => {
  try {
    if (value === null || value === undefined || value === "") {
      return "N/A";
    }

    if (typeof value === "boolean") {
      return value ? "true" : "false";
    }

    if (typeof value === "number") {
      if (value === 0) {
        return "0";
      }
      return value.toString();
    }

    if (type === "DEAL_SIZE") {
      return formatCurrency(value);
    }

    //This on is an array of strings with numbers
    if (type === "LEGAL_STRUCTURE") {
      const LEGAL_STRUCTURE_MAP: { [key: string]: string } = {};
      Object.keys(LEGAL_STRUCTURE).forEach(key => {
        const value = LEGAL_STRUCTURE[key as keyof typeof LEGAL_STRUCTURE];
        LEGAL_STRUCTURE_MAP[value] = key;
      });
      if (type === "LEGAL_STRUCTURE") {
        if (typeof value === "string") {
          return value
            .split(",")
            .map((v: any) => capitalize(LEGAL_STRUCTURE_MAP[v]))
            .join(", ");
        } else {
          return value.map((v: any) => LEGAL_STRUCTURE_MAP[v]);
        }
      }
    }

    if (type === "FINANCING_TYPE") {
      return FINANCING_TYPE[value as keyof typeof FINANCING_TYPE];
    }
  } catch (error) {
    console.log(error);
  }

  return value.toString();
};

export const FormattedText: React.FC<{ condition: any }> = ({ condition }) => {
  try {
    const fact = friendlyFactName(condition.fact);
    let factResult = "N/A";
    if (condition.factResult) {
      factResult = formatValue(condition.fact, condition.factResult.toString());
    }
    const value = formatValue(condition.fact, condition.value.toString());

    switch (condition.operator) {
      case "greaterThanInclusive":
        return condition.result ? (
          <>
            <u>{fact}</u> is at least <b>{value}</b> (actual: <b>{factResult}</b>)
          </>
        ) : (
          <>
            Expected <u>{fact}</u> to be at least <b>{value}</b>, but it was <b>{factResult}</b>
          </>
        );
      case "lessThanInclusive":
        return condition.result ? (
          <>
            <u>{fact}</u> is at most <b>{value}</b> (actual: <b>{factResult}</b>)
          </>
        ) : (
          <>
            Expected <u>{fact}</u> to be at most <b>{value}</b>, but it was <b>{factResult}</b>
          </>
        );
      case "equal":
        return condition.result ? (
          <>
            <u>{fact}</u> is <b>{value}</b>
          </>
        ) : (
          <>
            It was not <u>{fact}</u>; expected <b>{value}</b> but got <b>{factResult}</b>
          </>
        );
      case "in":
        return condition.result ? (
          <>
            <u>{fact}</u> is included on <b>{value}</b>
          </>
        ) : (
          <>
            <u>{fact}</u> expected to be <b>{value}</b>, but got <b>{factResult}</b>
          </>
        );
      default:
        return (
          <>
            <u>{fact}</u> <b>{factResult}</b> {condition.result ? "meets" : "does not meet"} the condition{" "}
            <i>{condition.operator}</i> [<b>{value}</b>]
          </>
        );
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
