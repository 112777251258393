import gql from "graphql-tag";

export const FETCH_TRUCK_MANUFACTURERS = gql`
  query truckManufacturers {
    truckManufacturers {
      value: dcrCode
      label: name
    }
  }
`;
