import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
const useStyles = makeStyles(fieldConfig => ({
  radioGroup: {
    flexDirection: "row",
  },
  radioButton: {
    marginRight: 40,
    color: "#818181",
    "& .MuiTypography-root": {
      fontSize: 12,
    },
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
  },
  mainContent: {
    position: "relative",
  },
}));

export const RadioQuestion = ({ fieldConfig }) => {
  const classes = useStyles(fieldConfig);
  const fieldId = uuidv4();

  // used for backwards compatibility with ssnExempt field (already created fields don't have headerContent and mainContent props)
  const ssnExemptDefaultContents = {
    headerContent: {
      en: "Quick Question",
      es: "Pregunta rápida",
    },
    mainContent: {
      en:
        '<p style="margin: 10px 0; color: #606060">Does this business:</p><p style="margin: 15px 0 15px 20px; color: #606060">1. Generate $10 million or more in total sales, or</p><p style="margin: 15px 0 15px 20px; color: #606060">2. Have more than 6 owners?</p>',
      es:
        '<p style="margin: 10px 0; color: #606060">¿Este negocio:</p><p style="margin: 15px 0 15px 20px; color: #606060">1. Generar $10 millones o más en ventas totales, o</p><p style="margin: 15px 0 15px 20px; color: #606060">2. ¿Tienes más de 6 dueños?</p>',
    },
  };

  return (
    <FormControl component="fieldset" style={{ padding: 10 }}>
      <Typography variant="h5" component="h5" className={classes.header}>
        {fieldConfig.fieldName === "ssnExempt" && !fieldConfig.headerContent
          ? _.get(ssnExemptDefaultContents, `headerContent.en`, "")
          : _.get(fieldConfig, `headerContent.en`, "")}
      </Typography>
      <div
        className={classes.mainContent}
        dangerouslySetInnerHTML={{
          __html:
            (fieldConfig.fieldName === "ssnExempt" && !fieldConfig.mainContent
              ? _.get(ssnExemptDefaultContents, `mainContent.en`, "")
              : _.get(fieldConfig, `mainContent.en`, "")) +
            (fieldConfig.required ? '<span style="position: absolute; top: 5px; right: -10px"> *</span>' : ""),
        }}
      ></div>
      <RadioGroup className={classes.radioGroup} name={fieldConfig.fieldName} value={""}>
        {fieldConfig.valuesList.map((variant, index) => (
          <FormControlLabel
            key={`formControl${fieldId}${index}`}
            className={classes.radioButton}
            value={""}
            control={<Radio key={`${fieldId}${index}`} color="primary" />}
            label={_.get(variant, "displayLabel.en", "")}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
