import { useQuery } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import iconMinusSvg from "assets/img/icons/minus.svg";
import iconPlusSvg from "assets/img/icons/plus.svg";
import { format } from "date-fns";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { gql } from "apollo-boost";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_V2_CONVERSATIONS,
  CHAT_V2_OPEN,
  CHAT_V2_SELECTED_CONVERSATION_SID,
  SET_CHAT_RESET,
  SET_VO,
} from "../../redux/types";
import ChatWidget from "../../components/Chatv2";
import mixpanel from "../../mixpanel";
import { vendorContactRoleHumanReadable } from "../../utils";
import Pagination from "./ChatTablePaginations";

const Q_ALL_CONVERSATIONS = gql`
  query getAllConversationsForUser {
    getAllConversationsForUser {
      sid
      vendorOpportunityId
      creditSubmissionId
      participants {
        sid
        userProfileId
        notifyByEmail
        notifyBySMS
        notifyByWhatsapp
        identity
        mobilePhoneNumber
        mugshot
        email
        isDealer
        isApplicant
        isLender
        conversationCreator
        messagingBinding {
          type
          address
          proxyAddress
        }
      }
      taskId
      topic
      createdBy
      createdAt
      messages {
        sid
        conversationId
        body
        participantSid
        userProfileId
        author
        delivery {
          total
          sent
          delivered
          read
          failed
          undelivered
        }
      }
      task {
        regarding
        vendorOpportunityId
        status
        assignedToUserProfileId
        assignedToAccountId
        assignedByUserProfileId
        assignedByAccountId
        notes
        priority
        createdBy
        createdDateTime
        assignedByUser
        assignedByAccount
        assignedToUser
        assignedToAccount
        active
        deleteBy
        deleteDate
        creditSubId
        notifyByEmail
        notifyByText
      }
      vendorOpportunity {
        potentialCustomer {
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  tableText: {
    fontSize: "12px",
  },
  paper: {
    width: "100%",
    padding: "10px",
    marginBottom: theme.spacing(2),
    borderTopLeftRadius: "0px",
    position: "relative",
    zIndex: "30",
    boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.28)",
  },
  table: {
    minWidth: 750,
  },
  tableCellStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
  },
  icon: {
    width: "15px",
    height: "15px",
    backgroundRepeat: "none",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    margin: "15px",
  },
  iconPlus: {
    backgroundImage: `url('${iconPlusSvg}')`,
  },
  iconMinus: {
    backgroundImage: `url('${iconMinusSvg}')`,
  },
  chatButton: {
    color: "#0A84FC",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "300",
    "&:hover": {
      fontWeight: "500",
    },
  },
  tabsContainer: {
    flexDirection: "row",
  },
  tabLabel: {
    fontSize: "20px",
  },
  tab: {
    backgroundColor: "#fff",
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    width: "200px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    boxShadow: "-2px -1px 4px rgba(0, 0, 0, 0.05)",
    margin: "0px 10px 0px 0px",
    color: "#000",
    zIndex: "15",
    fontSize: "20px",
    position: "relative",
  },
  activeTab: {
    zIndex: "50",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.28)",
  },
}));

const useRowStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  tableText: {
    fontSize: "12px",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  tableCellStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
  },
  icon: {
    width: "15px",
    height: "15px",
    backgroundRepeat: "none",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    margin: "15px",
  },
  iconPlus: {
    backgroundImage: `url('${iconPlusSvg}')`,
  },
  iconMinus: {
    backgroundImage: `url('${iconMinusSvg}')`,
  },
  chatItemHeader: {
    fontSize: "18px",
  },
  chatItemHeaderText: {
    color: "#0A84FC",
    textDecoration: "underline",
    cursor: "pointer",
  },
  chatButton: {
    color: "#0A84FC",
    fontSize: "14px",
    textTransform: "uppercase",
    fontWeight: "300",
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    color: "#000000",
    fontSize: 12,
    fontWeight: "700",
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const EnhancedTableRow = props => {
  const dispatch = useDispatch();

  const { row, messagesGroupedByVendorSubject, allMessages, collapse, isTransactions, accountList, voName } = props;

  const topicList = messagesGroupedByVendorSubject[row];
  const [open, setOpen] = useState(collapse);
  const classes = useRowStyles();
  const userProfile = useSelector(state => state.userProfile);
  const relatedUsers = useSelector(state => state.relatedUsers);
  const lenderProfiles = useSelector(state => state.lp);
  const [recipientList, setRecipientList] = useState([]);

  let vendorOpportunityId,
    creditSubId,
    messagesSize,
    hasUnreadMessages,
    feedId,
    participantsList,
    targetRecipient,
    accounts,
    enhancedParticipantsList,
    createDate,
    messages;

  const handleOpenChatOnSubject = conversations => {
    if (!conversations || !conversations.length) {
      return;
    }

    dispatch({
      type: CHAT_V2_CONVERSATIONS,
      payload: conversations,
    });

    dispatch({
      type: CHAT_V2_SELECTED_CONVERSATION_SID,
      payload: conversations[0].sid,
    });

    dispatch({
      type: CHAT_V2_OPEN,
      payload: true,
    });

    console.log("CONVERSATIONS VIEW CONVERSATION CLICKED EVENT FIRED");
    mixpanel.track("CONVERSATIONS: VIEW CONVERSATION CLICKED");

    //find related // lender user
    //pass to redux
    // dispatch({
    //   type: SET_CHAT_RESET,
    // });

    // //Open chat
    // dispatch({
    //   type: SET_CHAT_OPENED,
    //   payload: { opened: true },
    // });

    // // //Target user
    // dispatch({
    //   type: SET_CHAT_TARGET_RECIPIENT,
    //   payload: { targetRecipient },
    // });
    // //Credit sub if exists
    // dispatch({
    //   type: SET_CHAT_TARGET_CS,
    //   payload: { targetCS: creditSubId },
    // });

    // dispatch({
    //   type: SET_CHAT_TARGET_TOPIC,
    //   payload: { targetTopic: topic },
    // });

    // dispatch({
    //   type: SET_VO,
    //   payload: { vendorOpportunityId, potentialCustomer: { name: relatedTo } },
    // });
  };

  useEffect(() => {
    setOpen(collapse);
  }, [collapse]);

  useEffect(() => {
    if (!!relatedUsers && relatedUsers.length) {
      const mergedRecipientList = relatedUsers.map(up => {
        return {
          id: up.id,
          accountId: up.account.id,
          mughshot: up.mugshot,
          vendorContactRole: up.vendorContactRole,
          email: up.email,
          firstName: up.firstName,
          lastName: up.lastName,
          fullName: up.fullName,
          mobilePhoneNumber: up.mobilePhoneNumber,
          company: up.account.name,
        };
      });
      setRecipientList(mergedRecipientList);
    }
  }, [relatedUsers]);

  const labelRelatedTo = _.find(allMessages, {
    vendorOpportunityId: row,
  });

  return (
    <React.Fragment key={Math.random() + "_fragment"}>
      <TableRow hover role="checkbox" tabIndex={-1} key={Math.random() + "_row"}>
        <TableCell component="th" align="left" scope="row" padding="none" className={classes.tableCellStyles}>
          <div>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <div className={classes.icon + " " + classes.iconMinus}></div>
              ) : (
                <div className={classes.icon + " " + classes.iconPlus}></div>
              )}
            </IconButton>
            <span className={classes.chatItemHeader}>{labelRelatedTo.vendorOpportunity.potentialCustomer.name}</span>
          </div>
        </TableCell>
      </TableRow>
      <TableRow key={Math.random() + "tableRow"}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} key={Math.random() + "_collapse"} timeout="auto" unmountOnExit>
            <Box margin={3}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Relating</StyledTableCell>
                    <StyledTableCell align="left">Subject(s)</StyledTableCell>
                    <StyledTableCell align="left">Date</StyledTableCell>
                    <StyledTableCell align="left">Participant(s)</StyledTableCell>
                    <StyledTableCell align="left">Last Message:</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topicList.map((conversation, key) => {
                    vendorOpportunityId = _.get(conversation, "vendorOpportunityId", null);

                    creditSubId = _.get(conversation, "dynamicCreditSubmissionId", null);

                    messages = feedId = _.get(conversation, "messages", false);

                    messagesSize = _.size(_.get(conversation, "messages", []));

                    hasUnreadMessages = _.size(
                      _.filter(_.get(conversation, "participants", []), o => {
                        if (o.userProfileId == userProfile.id && !o.read) return o;
                      })
                    );
                    feedId = _.get(conversation, "feedId", false);
                    participantsList = _.get(conversation, "participants", []);

                    createDate = _.get(conversation, "createdAt", false);

                    targetRecipient = _.filter(
                      participantsList,
                      participant => participant.userProfileId !== userProfile.id
                    ).map(el => {
                      let found = _.find(relatedUsers, {
                        id: el.userProfileId,
                      });

                      return { ...found, account: null };
                    });

                    accounts = _.uniqBy(
                      _.remove(
                        participantsList.map(participant => {
                          const user = _.find(recipientList, {
                            id: participant.userProfileId,
                          });
                          const accountId = _.get(user, "accountId", false);

                          const thisAccount = _.find(accountList, {
                            id: accountId,
                          });
                          return thisAccount;
                        }),
                        undefined
                      ),
                      "name"
                    );

                    enhancedParticipantsList = participantsList.map(participant => {
                      let user;

                      if (participant.userProfileId == userProfile.id) {
                        user = userProfile;
                      } else {
                        user = _.find(recipientList, {
                          id: participant.userProfileId,
                        });
                      }

                      let contactRole = _.get(user, "vendorContactRole", false)
                        ? vendorContactRoleHumanReadable[_.get(user, "vendorContactRole", "")]
                        : "Lender";
                      return _.get(user, "fullName", "") + ` (${contactRole})`;
                    });
                    return (
                      <TableRow key={key}>
                        <TableCell align="left" className={classes.tableText}>
                          {labelRelatedTo.vendorOpportunity.potentialCustomer.name}
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          {conversation.topic}
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          {format(new Date(createDate), "MM/dd/yyyy")}
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          {enhancedParticipantsList.map((participant, key) => {
                            return (
                              <div key={key}>
                                <p>{participant}</p>
                              </div>
                            );
                          })}
                        </TableCell>
                        <TableCell align="left" className={classes.tableText}>
                          {messages.length ? messages[messages.length - 1].body : "N/A"}
                        </TableCell>
                        <TableCell align="center" className={classes.tableText}>
                          <Button
                            onClick={() => {
                              const targetConversation = _.find(messagesGroupedByVendorSubject[row], {
                                sid: conversation.sid,
                              });

                              handleOpenChatOnSubject([targetConversation]);
                            }}
                            color="primary"
                            simple
                            className={classes.chatButton}
                          >
                            View Conversation
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const chatUnread = useSelector(state => state.chat.unread);
  const relatedUsers = useSelector(state => state.relatedUsers);
  const lenderProfiles = useSelector(state => state.lp);
  const [recipientList, setRecipientList] = useState([]);
  const [accountList, setAccountsList] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [filterRecipient, setFilterRecipient] = useState([]);
  const [filterCompany, setFilterCompany] = useState(" ");
  const [filterUnread, setFilterUnread] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [page, setPage] = useState(0);
  const [tabsValue, setTabsValue] = useState("transactions");
  const [allMessages, setAllMessages] = useState([]);
  const [messagesGroupedByVendorSubject, setMessagesGroupedByVendorSubject] = useState([]);

  const { data: listMessages, loading } = useQuery(Q_ALL_CONVERSATIONS, {
    context: { authRequired: true },
  });

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleFilterRecipient = targetRecipient => {
    setRecipients(targetRecipient);
    if (_.isEmpty(targetRecipient)) {
      setFilterRecipient([]);
    } else {
      setFilterRecipient(targetRecipient);
    }
  };

  useEffect(() => {
    console.log("CONVERSATIONS PAGE OPENED EVENT FIRED");
    mixpanel.track("CONVERSATIONS: PAGE OPENED");

    dispatch({ type: SET_CHAT_RESET });
    dispatch({
      type: SET_VO,
      payload: {
        vendorOpportunityId: null,
      },
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterCompany = value => {
    setFilterCompany(value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Object.keys(messagesGroupedByVendorSubject).length - page * rowsPerPage);

  useEffect(() => {
    const availableLenderUserProfiles = _.filter(lenderProfiles, profile => {
      if (profile.lenderUserProfile) {
        return profile;
      }
    }).map(item => item.lenderUserProfile);
    const availableLenderAccounts = _.filter(lenderProfiles, profile => {
      if (profile.lenderAccount) {
        return profile;
      }
    }).map(item => item.lenderAccount);

    const accounts = _.uniq(
      relatedUsers.map(up => {
        return up.account;
      }),
      "name"
    );

    // const usersByAccount = _.groupBy(
    //   [...relatedUsers, ...availableLenderUserProfiles],
    //   'accountId'
    // );

    const listRelatedDealers = _.filter(relatedUsers, o => {
      return o.id !== userProfile.id;
    });
    const mergedRecipientList = relatedUsers.map(up => {
      return {
        id: up.id,
        accountId: up.account.id,
        mughshot: up.mugshot,
        vendorContactRole: up.vendorContactRole,
        email: up.email,
        firstName: up.firstName,
        lastName: up.lastName,
        fullName: up.fullName,
        mobilePhoneNumber: up.mobilePhoneNumber,
        company: up.account.name,
      };
    });
    setRecipientList(mergedRecipientList);
    setAccountsList(accounts);
  }, [relatedUsers]);

  useEffect(() => {
    if (listMessages && listMessages.getAllConversationsForUser) {
      const conversations = listMessages.getAllConversationsForUser;

      const grouped = _.chain(conversations)
        .groupBy("vendorOpportunityId")
        .value();

      setAllMessages(listMessages.getAllConversationsForUser);
      setMessagesGroupedByVendorSubject(grouped);

      const key = grouped && Object.keys(grouped)[0];

      if (key) {
        dispatch({
          type: CHAT_V2_CONVERSATIONS,
          payload: grouped[key],
        });
      }
    } else {
      setMessagesGroupedByVendorSubject([]);
    }
  }, [listMessages, dispatch]);

  // useEffect(() => {
  //   let filteredMessages = allMessages;
  //   if (filterUnread) {
  //     filteredMessages = _.filter(allMessages, o => {
  //       const myself = _.find(o.participants, {
  //         userProfileId: userProfile.id,
  //         read: false
  //       });

  //       if (!_.isEmpty(myself)) return o;
  //     });
  //   }

  //   if (filterCompany !== " ") {
  //     filteredMessages = _.filter(filteredMessages, message => {
  //       if (
  //         _.some(message.participants, {
  //           accountId: filterCompany
  //         })
  //       ) {
  //         return message;
  //       }
  //     });
  //   }

  //   if (!_.isEmpty(filterRecipient)) {
  //     filteredMessages = _.filter(filteredMessages, message => {
  //       const found = message.participants.map(participant => {
  //         let contact = _.find(filterRecipient, {
  //           id: participant.userProfileId
  //         });

  //         if (contact) {
  //           return true;
  //         }

  //         return false;
  //       });
  //       if (_.some(found)) return message;
  //     });
  //   }

  //   //Aggregation magic happens here, client side
  //   setMessagesGroupedByVendorSubject(
  //     _.chain(filteredMessages)
  //       .groupBy("vendorOpportunityId")
  //       .mapValues(values =>
  //         _.chain(values)
  //           .groupBy("topic")
  //           .value()
  //       )
  //       .value()
  //   );
  // }, [filterRecipient, filterUnread, filterCompany, allMessages]);

  const handleFilterUnread = event => {
    setFilterUnread(!!event.target.checked);
  };

  if (loading || _.isEmpty(relatedUsers)) {
    return <LinearProgress />;
  }

  return allMessages.length ? (
    <div className={classes.root}>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        className={classes.tabsContainer}
        value={tabsValue}
        onChange={e => setTabsValue(e.target.value)}
      >
        <FormControlLabel
          value="transactions"
          className={tabsValue === "tasks" ? classes.tab : `${classes.tab} ${classes.activeTab}`}
          control={<Radio style={{ display: "none" }} />}
          label="Transactions"
          classes={{ label: classes.tabLabel }}
        />
        <FormControlLabel
          value="tasks"
          style={{ margin: "0px 0px 0px 10px" }}
          className={tabsValue === "transactions" ? classes.tab : `${classes.tab} ${classes.activeTab}`}
          control={<Radio style={{ display: "none" }} />}
          label="Tasks"
          classes={{ label: classes.tabLabel }}
        />
      </RadioGroup>
      <Paper className={classes.paper}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <h5>Filters: </h5>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={7}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                value={recipients}
                options={recipientList}
                groupBy={option => option.company}
                onChange={(event, newValue) => {
                  handleFilterRecipient(newValue);
                }}
                getOptionLabel={option => {
                  return (
                    option.fullName +
                    `${
                      vendorContactRoleHumanReadable[option.vendorContactRole]
                        ? ` (${vendorContactRoleHumanReadable[option.vendorContactRole]})`
                        : " (Lender)"
                    }`
                  );
                }}
                renderInput={params => <TextField {...params} variant="standard" label="Recipient(s)" placeholder="" />}
              />
            </FormControl>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={3} lg={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="simple-select">
                <b>Company:</b>
              </InputLabel>
              <Select
                size="small"
                value={filterCompany}
                onChange={e => {
                  handleFilterCompany(e.target.value);
                }}
                inputProps={{
                  name: "account",
                  id: "account",
                }}
              >
                <MenuItem key={"empty"} value={" "} size="small">
                  -- ALL --
                </MenuItem>
                {!!accountList &&
                  accountList.map((account, key) => {
                    return (
                      <MenuItem key={key} value={account.id} size="small">
                        {account.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={4} sm={4} md={3} lg={2}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={collapse}
                  onChange={() => {
                    setCollapse(!collapse);
                  }}
                  name="collapse"
                />
              }
              label="Expand Rows"
            />
          </Grid>
        </Grid>
        {/* <ChatWidget /> */}
        <hr />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
            <TableBody>
              {Object.keys(messagesGroupedByVendorSubject)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-${row}`;
                  let voName, relatedTaskId;

                  if (
                    allMessages[index].vendorOpportunity &&
                    allMessages[index].vendorOpportunity.potentialCustomer &&
                    allMessages[index].vendorOpportunity.potentialCustomer.name
                  ) {
                    voName = allMessages[index].vendorOpportunity.potentialCustomer.name;
                  }

                  if (allMessages[index].taskId) {
                    relatedTaskId = allMessages[index].taskId;
                  }
                  return (
                    <EnhancedTableRow
                      key={labelId}
                      row={row}
                      collapse={collapse}
                      allMessages={allMessages}
                      accountList={accountList}
                      relatedTaskId={relatedTaskId}
                      voName={voName}
                      isTransactions={tabsValue === "transactions"}
                      messagesGroupedByVendorSubject={messagesGroupedByVendorSubject}
                    />
                  );
                })
                .filter(row => {
                  if (tabsValue === "tasks") {
                    return row.props.relatedTaskId;
                  }
                  return row;
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 60 * emptyRows,
                  }}
                >
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Transactions per page"
          component="div"
          count={Object.keys(messagesGroupedByVendorSubject).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  ) : (
    <p>You don't have any messages yet</p>
  );
}
