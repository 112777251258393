import React, { useEffect, useRef } from "react";
import { Grid, MenuItem, Select, TextareaAutosize, Typography } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { makeStyles } from "@material-ui/core/styles";
import debounce from "lodash/debounce";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { formatCurrency } from "../../utils";
import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  creditLineCard: {
    backgroundColor: "#FFF",
    borderRadius: "10px",
  },
  creditLineHeader: {
    color: "#3c4858",
    fontSize: "25px",
    fontFamily: "Roboto",
    fontWeight: "300 !important",
    marginBottom: "10px",
  },
  creditItem: {
    padding: "8px 8px",
    borderTop: "1px solid #DDD",
  },
  creditItemHeader: {
    fontSize: "14px",
    fontWeight: "700",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  creditItemText: {
    fontSize: "14px",
    fontWeight: "300",
  },
  notesBox: {
    overflowY: "auto",
    marginTop: "10px",
    resize: "none",
    width: "100%",
    minHeight: "25px",
    maxHeight: "100px",
    border: "1px solid #DDD",
    borderRadius: "10px",
    padding: "5px",
  },
}));

const UPDATE_VENDOR_OPPORTUNITY_QUERY = gql`
  mutation($VOId: String!, $fieldsObj: UpdateVendorOpportunityInput!) {
    updateVendorOpportunity(VOId: $VOId, fieldsObj: $fieldsObj)
  }
`;

export const CreditLineCard = ({ creditApp, vo, userProfile }) => {
  const classes = useStyles();
  const [openCreditStatusSelect, setOpenCreditStatusSelect] = React.useState(false);
  const [creditStatus, setCreditStatus] = React.useState((vo && vo.creditLineStatus) || "");
  const [voAmountRequested, setVOAmountRequested] = React.useState(null);
  const inputValueRef = useRef();
  const creditStatusValues = [
    {
      value: "PENDING",
      title: "Pending",
    },
    {
      value: "APPROVED",
      title: "Approved",
    },
    {
      value: "DECLINED",
      title: "Declined",
    },
  ];

  const [updateVendorOpportunity] = useMutation(UPDATE_VENDOR_OPPORTUNITY_QUERY, {
    context: { authRequired: true },
  });

  const saveVendorOpportunity = async (creditStatusValue, notesValue) => {
    const fieldsObj = {};
    if (creditStatusValue) {
      fieldsObj.creditLineStatus = creditStatusValue;
    }
    if (typeof notesValue === "string") {
      fieldsObj.creditLineNotes = notesValue;
      localStorage.setItem(`voNote_${vo.vendorOpportunityId}`, JSON.stringify(notesValue));
    }
    try {
      await updateVendorOpportunity({
        variables: {
          VOId: vo.vendorOpportunityId,
          fieldsObj,
        },
      });
    } catch (err) {
      window.NREUM.noticeError(err);
    }
  };

  const updateAmount = amountValue => {
    try {
      updateVendorOpportunity({
        variables: {
          VOId: vo.vendorOpportunityId,
          fieldsObj: {
            creditLineAmountRequested: Number(amountValue),
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreditStatusChange = async event => {
    setCreditStatus(event.target.value);
    if (inputValueRef.current && inputValueRef.current.value && inputValueRef.current.value.trim() !== "") {
      inputValueRef.current.value += "\n\n";
    }

    inputValueRef.current.value += `${moment().format("lll")}: ${
      userProfile.fullName
    } changed status from ${creditStatus} to ${event.target.value}`;
    saveVendorOpportunity(event.target.value, inputValueRef.current.value);
  };
  const debouncedHandleTextAreaChange = debounce(async () => {
    if (inputValueRef.current) {
      const value = inputValueRef.current.value.trim();
      saveVendorOpportunity(undefined, value);
    }
  }, 500);

  const debouncedHandleAmountValueInputChange = _.debounce(updateAmount, 500);

  const handleCreditStatusClose = () => {
    setOpenCreditStatusSelect(false);
  };

  const handleCreditStatusOpen = () => {
    setOpenCreditStatusSelect(true);
  };

  useEffect(() => {
    if (!vo) {
      return;
    }
    if (vo.creditLineAmountRequested) {
      setVOAmountRequested(Number(vo.creditLineAmountRequested));
    }

    if (inputValueRef.current) {
      inputValueRef.current.value = vo.creditLineNotes || "";
    }
  }, [vo]);

  return (
    <Grid container className={classes.creditLineCard}>
      <Grid item xs={12} className={classes.creditItem}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5" component="h5" className={classes.creditItemHeader}>
              Line Requested
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {creditApp && !_.isNil(creditApp.creditLineAmountRequested)
              ? formatCurrency(creditApp.creditLineAmountRequested)
              : "Not Requested"}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.creditItem}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5" component="h5" className={classes.creditItemHeader}>
              Approved Line
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CurrencyTextField
              value={voAmountRequested}
              onChange={(event, value) => debouncedHandleAmountValueInputChange(value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.creditItem}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h5" component="h5" className={classes.creditItemHeader}>
            Status
          </Typography>
          <Grid item xs={6}>
            <Select
              label="Select"
              fullWidth
              value={creditStatus}
              defaultValue={creditStatus}
              onChange={handleCreditStatusChange}
              disabled={!vo}
              open={openCreditStatusSelect}
              onClose={handleCreditStatusClose}
              onOpen={handleCreditStatusOpen}
            >
              {creditStatusValues.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.creditItem}>
        <Grid container justify="space-between" alignItems="center">
          <Grid container item xs={12} md={12} lg={12} spacing={1}>
            <Typography variant="h5" component="h5" className={classes.creditItemHeader}>
              Notes
            </Typography>
          </Grid>
          <TextareaAutosize
            ref={inputValueRef}
            onChange={debouncedHandleTextAreaChange}
            defaultValue={vo.creditLineNotes}
            minRows={4}
            disabled={!vo}
            maxRows={6}
            className={classes.notesBox}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
