import React from "react";
import _ from "lodash";
import { Box } from "@material-ui/core";
import { LenderWaterfallPrescreenResult } from "@trnsact/trnsact-shared-types";
import { LenderWaterfallEntityMobile } from "../types";
import { LenderInfo } from "../ui/LenderInfo";
import { ResultAdditionalInfoMobile } from "../ui/ResultAdditionalInfoMobile";

export const mobileEntityAdapter = (
  entity: LenderWaterfallPrescreenResult,
  getAction: any,
  getRateSpecs: any,
  getRate: any
): LenderWaterfallEntityMobile => ({
  group: "step",
  original: entity,
  step: entity.step,
  dateAndTime: null,
  groupName: `${entity.step} Step`,
  id: entity.lenderWaterfallPrescreenCriteriaResultId,
  title: _.get(entity, "runtimeOutput.event.params.lenderProfile.name") ?? "Title",
  dataForView: [
    {
      label: "Status",
      type: "component",
      value: null,
      component: () => <ResultAdditionalInfoMobile entity={entity} />,
    },
    {
      type: "component",
      label: "Actions",
      value: entity.creditSubmissionId,
      component: () => getAction(entity),
    },
    {
      value: null,
      type: "component",
      label: "Lender",
      component: () => (
        <Box style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <LenderInfo lenderWaterfallResult={entity} />
        </Box>
      ),
    },
    {
      type: "default",
      label: "Program",
      value: _.get(entity, "runtimeOutput.event.params.financeProgram.nameInternal"),
    },
    {
      type: "default",
      label: "Max term (mos.)",
      value: getRateSpecs(entity, "term"),
    },
    {
      type: "default",
      label: "Interest Rate",
      value: <Box style={{ display: "flex", gap: "4px", alignItems: "center" }}>{getRate(entity, "rate")}</Box>,
    },
    {
      label: "Type",
      type: "default",
      value: _.get(entity, "runtimeOutput.event.params.financeProgram.paymentOptionsConfiguration.type"),
    },
  ],
});
