import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import CreditAppModalEditableInput from "../../../VendorOpportunity/CreditAppModalEditableInput";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../../../services/locationsService";

const ComplexRuleEditor = ({ changeRulesFn, ruleIndex, evalValue, targetObject }) => {
  const [tempRule, setTempRule] = useState("");
  const isOptionSet = _.some(targetObject?.fieldOptions);

  return (
    <Grid container>
      {isOptionSet ? (
        <Grid item xs={12}>
          <Select
            value={evalValue}
            fullWidth={true}
            variant="standard"
            multiple
            onChange={e => {
              changeRulesFn(ruleIndex, e.target.value, "evalValue");
            }}
            renderValue={selected => {
              return _(selected)
                .map(value => {
                  const option = _.find(targetObject.fieldOptions, { optionValue: value });
                  return _.get(option, "displayLabel.en") || _.get(option, "optionValue");
                })
                .join(", ");
            }}
          >
            {targetObject.fieldOptions.map(option => (
              <MenuItem key={option.optionValue} value={option.optionValue}>
                <Checkbox checked={_.includes(evalValue, option.optionValue)} />
                {option.displayLabel.en || option.optionValue}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <TextField
                  fullWidth={true}
                  required={true}
                  variant={"standard"}
                  onChange={e => setTempRule(e.target.value)}
                  value={tempRule}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="filled"
                  onClick={e => {
                    let prev = evalValue;
                    prev.push(tempRule);
                    changeRulesFn(ruleIndex, prev, "evalValue");
                    setTempRule("");
                  }}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <TextField fullWidth={true} required={true} value={JSON.stringify(evalValue)} />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="filled"
                  onClick={e => {
                    let prev = evalValue;
                    prev.pop();
                    changeRulesFn(ruleIndex, prev, "evalValue");
                  }}
                >
                  <RemoveCircleIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  rulesSettingsContainer: {
    margin: "12px",
    "& .react-dropdown-tree-select .tag-list .tag-item:first-child span:not(.placeholder)": {
      color: "#0000008a",
    },
    "& .react-dropdown-tree-select .tag-list .tag-item:first-child span": {
      fontSize: "12px",
    },
    "& .react-dropdown-tree-select .tag-item": {
      display: "block",
    },
  },

  selectLabel: {
    fontSize: 11,
  },
  keyCell: {
    fontWeight: 700,
  },
  valueCell: {
    backgroundColor: "#efefef",
  },
  rulesEditorTitle: {
    fontWeight: "bolder",
    fontVariant: "small-caps",
    textDecoration: "underline",
    fontSize: 22,
  },
  ruleDivider: {
    borderTop: "1px solid #DDD",
    marginTop: "12px",
  },
}));

const evalTypeList = ["have_value", "have_not_value", "contain", "eq", "lt", "gt", "gte", "lte", "neq"];
const rulesSatisfactionTypeList = ["all", "at_least_one", "exactly_one", "none"];
const complexEvalTypes = ["have_value", "have_not_value", "contain"];

const CADMCommonEditableInput = ({ field, changeRulesFn, ruleIndex, ruleKey, evalType, locations }) => {
  const onChangeFn = (field, value) => {
    changeRulesFn(ruleIndex, value, ruleKey);
  };

  const locationsOptions = _.map(locations, location => {
    return {
      ...location,
      isDefaultValue: _.isArray(field.value)
        ? _.includes(field.value, location.locationId)
        : field.value === location.locationId,
    };
  });
  const locationsTree = buildTree(locationsOptions);
  const isMultiSelectMode = ruleKey !== "valueToAutofill" && _.includes(complexEvalTypes, evalType);
  const locationsTreeOptions = getDropdownTreeSelectDefaultOptions({
    onChange: (currentNode, selectedNodes) =>
      onChangeFn(field, isMultiSelectMode ? _.map(selectedNodes, "value") : currentNode.value),
    mode: isMultiSelectMode ? "hierarchical" : "radioSelect",
  });

  return (
    <CreditAppModalEditableInput
      field={field}
      config={{}}
      classes={{}}
      onChangeFn={onChangeFn}
      locationsTree={locationsTree}
      locationsTreeOptions={locationsTreeOptions}
      skipFormattingForPayload={true}
    />
  );
};

export const RulesSettings = props => {
  const {
    rules,
    targetNames,
    rulesSatisfactionType,
    changeSelectedItemProp,
    selectedItemSettings,
    selectedItem,
    locations,
  } = props;
  const classes = useStyles();
  const scopeSettingOptions = [
    { displayValue: "Global (default)", value: "global" },
    { displayValue: "Local (used on Owners sections)", value: "local" },
  ];

  const ruleTypesSettings = [
    {
      key: "default",
      displayLabel: "Default",
    },
    {
      key: "autofill",
      displayLabel: "Autofill Value",
    },
    {
      key: "required",
      displayLabel: "Required",
    },
  ];

  const isField = !!_.get(selectedItem, "config.fieldName");

  return (
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.rulesSettingsContainer}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h4 className={classes.rulesEditorTitle}>Rules Editor</h4>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Button variant="contained" color={"primary"} onClick={e => selectedItemSettings.addNewRuleFn()}>
                Add New Rule
              </Button>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <InputLabel id="rule-satisfaction-label" className={classes.selectLabel}>
                Rule satisfaction type
              </InputLabel>
              <Select
                value={rulesSatisfactionType}
                variant="standard"
                fullWidth={true}
                labelId="rule-satisfaction-label"
                onChange={e => changeSelectedItemProp("rulesSatisfactionType", e.target.value)}
              >
                {rulesSatisfactionTypeList.map((rulesSatisfactionTypeItem, index) => (
                  <MenuItem key={`${rulesSatisfactionTypeItem}.${index}`} value={rulesSatisfactionTypeItem}>
                    {rulesSatisfactionTypeItem}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            {rules.map((rule, ruleIndex) => {
              const targetObject = _.find(targetNames, { fieldName: rule.targetName });
              console.log(targetObject);
              return (
                <>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={`ruleKey.${ruleIndex}.${Math.random()}`}
                    className={classes.ruleDivider}
                  >
                    <Grid container spacing={4}>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <h4>Rule N°{ruleIndex + 1}</h4>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <span>
                          <Button
                            variant="contained"
                            size={"small"}
                            color={"secondary"}
                            onClick={e => selectedItemSettings.removeRuleFn(ruleIndex)}
                          >
                            Remove Rule
                          </Button>
                        </span>
                      </Grid>
                    </Grid>

                    {isField && (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4} className={classes.keyCell}>
                          Rule Type:
                        </Grid>
                        <Grid item xs={8} className={classes.valueCell}>
                          <Select
                            value={rule.type || "default"}
                            fullWidth={true}
                            required={true}
                            variant="filled"
                            onChange={e => {
                              selectedItemSettings.changeRulesFn(ruleIndex, e.target.value, "type");
                              selectedItemSettings.changeRulesFn(ruleIndex, null, "valueToAutofill");
                              selectedItemSettings.changeRulesFn(ruleIndex, null, "isNeedToHideOnAutofill");
                            }}
                          >
                            {ruleTypesSettings.map(ruleTypeSetting => {
                              return (
                                <MenuItem key={ruleTypeSetting.key} value={ruleTypeSetting.key}>
                                  {ruleTypeSetting.displayLabel}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.keyCell}>
                        Target Field:
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className={classes.valueCell}>
                        {rule.targetName !== "ownersQuantity" ? (
                          <Select
                            value={rule.targetName}
                            fullWidth={true}
                            required={true}
                            variant="filled"
                            onChange={e => {
                              e.preventDefault();
                              selectedItemSettings.changeRulesFn(ruleIndex, e.target.value, "targetName");
                              const targetObject = _.find(targetNames, { fieldName: e.target.value });
                              selectedItemSettings.changeRulesFn(ruleIndex, "", "evalValue");

                              if (_.size(targetObject.fieldOptions)) {
                                selectedItemSettings.changeRulesFn(ruleIndex, "have_value", "evalType");
                                selectedItemSettings.changeRulesFn(ruleIndex, [], "evalValue");
                              }
                            }}
                          >
                            {_.sortBy(targetNames, ["fieldName"]).map((targetObj, index) => {
                              return (
                                <MenuItem key={`${targetObj.fieldName}.${index}`} value={targetObj.fieldName}>
                                  {targetObj.fieldName} {_.size(targetObj.fieldOptions) ? "(option set)" : ""}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        ) : (
                          <span>OWNERS QUANTITY (cannot be changed)</span>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.keyCell}>
                        Condition
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className={classes.valueCell}>
                        <Select
                          value={rule.evalType}
                          fullWidth={true}
                          variant="standard"
                          onChange={e => {
                            selectedItemSettings.changeRulesFn(ruleIndex, e.target.value, "evalType");
                            const evalValueToSet = _.includes(complexEvalTypes, e.target.value) ? [] : "";
                            selectedItemSettings.changeRulesFn(ruleIndex, evalValueToSet, "evalValue");
                          }}
                        >
                          {evalTypeList.map((evalTypeItem, index) => (
                            <MenuItem key={`${evalTypeItem}.${index}`} value={evalTypeItem}>
                              {evalTypeItem}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4} className={classes.keyCell}>
                        {_.includes(complexEvalTypes, rule.evalType) ? "Match Multiple Values" : "Match Value"}
                      </Grid>
                      <Grid item xs={8} className={classes.valueCell}>
                        {rule.targetName === "locationId" ? (
                          <CADMCommonEditableInput
                            field={{
                              ocaConfig: { type: "location-select", config: targetObject },
                              config: {},
                              value: rule.evalValue,
                            }}
                            changeRulesFn={selectedItemSettings.changeRulesFn}
                            ruleIndex={ruleIndex}
                            ruleKey="evalValue"
                            evalType={rule.evalType}
                            locations={locations}
                          />
                        ) : _.includes(complexEvalTypes, rule.evalType) ? (
                          <ComplexRuleEditor
                            changeRulesFn={selectedItemSettings.changeRulesFn}
                            evalValue={rule.evalValue}
                            ruleIndex={ruleIndex}
                            targetObject={targetObject}
                          />
                        ) : (
                          <TextField
                            fullWidth={true}
                            required={true}
                            onChange={e => selectedItemSettings.changeRulesFn(ruleIndex, e.target.value, "evalValue")}
                            value={rule.evalValue}
                          />
                        )}
                      </Grid>
                    </Grid>
                    {rule.type === "autofill" && (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4} className={classes.keyCell}>
                          Value To Autofill
                        </Grid>
                        <Grid item xs={8} className={classes.valueCell}>
                          <CADMCommonEditableInput
                            field={{ ocaConfig: selectedItem, config: {}, value: rule.valueToAutofill }}
                            changeRulesFn={selectedItemSettings.changeRulesFn}
                            ruleIndex={ruleIndex}
                            ruleKey="valueToAutofill"
                            evalType={rule.evalType}
                            locations={locations}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {isField && (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={classes.keyCell}>
                          Scope
                        </Grid>
                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8} className={classes.valueCell}>
                          <Select
                            value={rule.scope || "global"}
                            fullWidth={true}
                            variant="standard"
                            onChange={e => {
                              selectedItemSettings.changeRulesFn(ruleIndex, e.target.value, "scope");
                            }}
                          >
                            {scopeSettingOptions.map(item => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.displayValue}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {rule.type === "autofill" && (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} className={classes.keyCell}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={!!rule.isNeedToHideOnAutofill}
                              onChange={event =>
                                selectedItemSettings.changeRulesFn(
                                  ruleIndex,
                                  event.target.checked,
                                  "isNeedToHideOnAutofill"
                                )
                              }
                              name="isNeedToHideOnAutofill"
                              color="primary"
                            />
                          }
                          label="Hide Field When Value Autofilled"
                          labelPlacement="start"
                          style={{ color: "#3c4858", marginLeft: "8px" }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <hr />
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
