import React, { useCallback, useRef, useState } from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useDrag, useDrop } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfigurableItemActions } from "../PortalConfigurationWizard/CreditAppDetailModal/ConfigurableItemActions";
import { proposalMenuDraggableTypes } from "../../../src/pages/Prequal/constants";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ConfirmationDialog from "components/ConfirmationDialog";
import { formatCurrency } from "../../utils";
import _ from "lodash";

const UPDATE_PROPOSAL_MENU_OPTION = gql`
  mutation($input: UpdateProposalMenuOptionInput) {
    updateProposalMenuOption(input: $input) {
      proposalMenuOptionId
    }
  }
`;

const UPDATE_PROPOSAL_MENU = gql`
  mutation($input: UpdateProposalMenuInput) {
    updateProposalMenu(input: $input) {
      proposalMenuId
    }
  }
`;

const styles = {
  root: {
    border: "1px solid #e6e6e6",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    minHeight: 350,
    height: "100%",
    cursor: "move",
  },
  optionHeader: {
    padding: "26px 16px 16px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    position: "relative",
    minHeight: 120,
  },
  productsContiner: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  productListContainer: {
    listStyle: "none",
    padding: "0 12px",
    "& > li:not(:last-child)": {
      marginBottom: 15,
    },
  },
  productListItemText: {
    marginLeft: 10,
  },
  productListItemCostText: {
    fontSize: 13,
    paddingLeft: 4,
  },
  // productListContainer: {
  //   paddingLeft: 51,
  // },
  menuOptionConfigurableItemActions: {
    position: "absolute",
    top: 5,
    right: 7,
  },
  productConfigurableItemActions: {
    marginLeft: "auto",
  },
};

const useStyles = makeStyles(styles);

const ProposalMenuOption = ({
  menuOption,
  index,
  proposalMenu,
  setProposalMenu,
  refetchProposalMenu,
  handleEditMenuOptionClick,
}) => {
  const classes = useStyles();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [updateProposalMenuOption, { loading }] = useMutation(UPDATE_PROPOSAL_MENU_OPTION, {
    context: { authRequired: true },
    onCompleted() {
      refetchProposalMenu();
    },
  });

  const [updateProposalMenu, { loading: updateLoading }] = useMutation(UPDATE_PROPOSAL_MENU, {
    context: { authRequired: true },
    onCompleted() {
      //refetchProposalMenu();
    },
  });

  const menuOptionRef = useRef(null);

  const handleRemoveMenuOptionClick = menuOptionToRemove => {
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      updateProposalMenuOption({
        variables: {
          input: {
            proposalMenuOptionId: menuOption.proposalMenuOptionId,
            archived: true,
          },
        },
      });
    }
  };

  const moveMenuOption = useCallback((dragIndex, hoverIndex) => {
    setProposalMenu(prevMenu => {
      const menuCopy = _.cloneDeep(prevMenu);
      menuCopy.menuOptions.splice(dragIndex, 1);
      menuCopy.menuOptions.splice(hoverIndex, 0, prevMenu.menuOptions[dragIndex]);
      _.forEach(menuCopy.menuOptions, (mo, idx) => (mo.ordinal = idx));
      return menuCopy;
    });
  }, []);

  const handleProductProductDrop = product => {
    const newProducts = [...menuOption.products, product];
    const menuOptionSet = {
      ...menuOption,
      products: newProducts,
    };
    setProposalMenu(prevMenu => ({
      ...prevMenu,
      menuOptions: prevMenu.menuOptions.map(mo =>
        mo.proposalMenuOptionId === menuOption.proposalMenuOptionId ? menuOptionSet : mo
      ),
    }));

    //TODO: check if code above is still needed once update becomes workable
    updateProposalMenuOption({
      variables: {
        input: {
          proposalMenuOptionId: menuOption.proposalMenuOptionId,
          productIds: _.map(newProducts, "proposalProductId"),
        },
      },
    });
  };

  const handleProductRemove = product => {
    const newProducts = menuOption.products.filter(p => p.proposalProductId !== product.proposalProductId);
    const menuOptionSet = {
      ...menuOption,
      products: newProducts,
    };
    setProposalMenu(prevMenu => ({
      ...prevMenu,
      menuOptions: prevMenu.menuOptions.map(mo =>
        mo.proposalMenuOptionId === menuOption.proposalMenuOptionId ? menuOptionSet : mo
      ),
    }));

    //TODO: check if code above is still needed once update becomes workable
    updateProposalMenuOption({
      variables: {
        input: {
          proposalMenuOptionId: menuOption.proposalMenuOptionId,
          productIds: _.map(newProducts, "proposalProductId"),
        },
      },
    });
  };

  const handleMenuOptionDrop = () => {
    console.log(proposalMenu.menuOptions);
    updateProposalMenu({
      variables: {
        input: {
          proposalMenuId: proposalMenu.proposalMenuId,
          menuOptionIdsToLink: _.map(proposalMenu.menuOptions, "proposalMenuOptionId"),
        },
      },
    });
  };

  const [{ canDrop, isOver }, productDrop] = useDrop(
    () => ({
      accept: proposalMenuDraggableTypes.proposalProduct,
      drop: handleProductProductDrop,
      canDrop: item => _.findIndex(menuOption.products, { proposalProductId: item.proposalProductId }) === -1,
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [menuOption]
  );

  const [{ handlerId }, menuOptionDrop] = useDrop({
    accept: proposalMenuDraggableTypes.proposalMenuOption,
    drop: handleMenuOptionDrop,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!menuOptionRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = menuOptionRef.current?.getBoundingClientRect();
      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the right
      const hoverClientX = clientOffset.x - hoverBoundingRect.right;
      // Only perform the move when the mouse has crossed half of the items width
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Time to actually perform the action
      moveMenuOption(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, menuOptionDrag] = useDrag({
    type: proposalMenuDraggableTypes.proposalMenuOption,
    item: () => {
      return { proposalMenuOptionId: menuOption.proposalMenuOptionId, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  const totalPrice = _(menuOption.products)
    .flatMap("products")
    .map("retailCost")
    .sum();

  menuOptionDrag(menuOptionDrop(menuOptionRef));

  return (
    <>
      <Card ref={menuOptionRef} className={classes.root} style={{ opacity }} data-handler-id={handlerId}>
        <div className={classes.optionHeader} style={{ backgroundColor: menuOption.titleBgColor }}>
          <Typography variant="h4" align="center" style={{ color: menuOption.titleColor, fontWeight: 200 }}>
            {menuOption.name}
          </Typography>
          <div className={classes.menuOptionConfigurableItemActions}>
            <ConfigurableItemActions
              itemType={"Menu option"}
              onEdit={() => {
                handleEditMenuOptionClick(menuOption);
              }}
              onRemove={handleRemoveMenuOptionClick}
              onRemoveCustomIcon={DeleteIcon}
            />
          </div>
        </div>

        <div ref={productDrop} className={classes.productsContiner}>
          <ul className={classes.productListContainer}>
            {_.some(menuOption.products) ? (
              menuOption.products.map(pp => (
                /*
                {
                  "proposalProductId": "33b47bb4-e242-11ee-9fa5-dfdf62e068c5",
                  "title": "Muffler Maintenance - DF789",
                  "cost": 115.28,
                  "retailCost": 199.99,
                  "thirdPartyUrl": "www.example.com/product/muffler-maintenance",
                  "__typename": "ProposalProduct"
                }
                */
                <li key={pp.proposalProductId} className={classes.productListItem}>
                  <Grid container xs={12} style={{ flexWrap: "nowrap" }}>
                    <CheckCircleIcon style={{ color: menuOption.titleBgColor }} />
                    <Typography className={classes.productListItemText}>
                      {pp.title}
                      <span className={classes.productListItemCostText}>{formatCurrency(pp.retailCost)}</span>
                    </Typography>
                    <div className={classes.productProductConfigurableItemActions}>
                      <ConfigurableItemActions onRemove={() => handleProductRemove(pp)} itemType="Product" />
                    </div>
                  </Grid>
                </li>
              ))
            ) : (
              <li>
                <Typography align="center">No Products</Typography>
              </li>
            )}
          </ul>
        </div>
        {totalPrice > 0 && (
          <Typography align="center" paragraph={true}>
            <strong>Total Price:</strong> {formatCurrency(totalPrice.toFixed(2))}
          </Typography>
        )}
      </Card>

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove Menu Option?"
        open={isConfirmationModalOpen}
        onClose={onConfiramtionModalClose}
      />
    </>
  );
};

export default ProposalMenuOption;
