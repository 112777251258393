import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip"; // Using Material-UI for chips
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  inputContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}));

interface ChipInputProps {
  id: string;
  value?: string[]; // Optional: Controls the chips externally
  onChange?: (id: string, value: string[]) => void; // Optional: To propagate changes back to the parent
  initialValues?: string[]; // Optional: Initial values for unmanaged state
}

const ChipInput = ({ id, value, onChange, initialValues = [] }: ChipInputProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [internalChips, setInternalChips] = useState<string[]>(initialValues);

  // Determine if the component is controlled
  const isControlled = value !== undefined;

  // Sync internal state with `initialValues` when `value` is not provided (uncontrolled)
  useEffect(() => {
    if (!isControlled) {
      setInternalChips(initialValues);
    }
  }, [initialValues]);

  const chips = isControlled ? value : internalChips; // Use controlled or internal chips

  const handleAddChip = () => {
    if (chips?.includes(inputValue.trim()) || inputValue.trim() === "") return;

    const updatedChips = [...chips, inputValue.trim()];
    setInputValue("");

    if (isControlled && onChange) {
      onChange(id, updatedChips); // Trigger parent update if controlled
    } else {
      setInternalChips(updatedChips); // Update internal state if uncontrolled
    }
  };

  const handleDeleteChip = (chipToDelete: string) => () => {
    const updatedChips = chips.filter(chip => chip !== chipToDelete);

    if (isControlled && onChange) {
      onChange(id, updatedChips); // Trigger parent update if controlled
    } else {
      setInternalChips(updatedChips); // Update internal state if uncontrolled
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddChip();
    }
  };

  return (
    <div>
      <div className={classes.inputContainer}>
        <TextField
          label="Enter a value"
          variant="standard"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button variant="contained" color="primary" size="small" onClick={handleAddChip}>
          Add
        </Button>
      </div>
      <div className={classes.chipContainer}>
        {chips.map((chip, index) => (
          <Chip key={index} label={chip} onDelete={handleDeleteChip(chip)} color="primary" />
        ))}
      </div>
    </div>
  );
};

export default ChipInput;
