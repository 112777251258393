import React, { Fragment, ReactNode, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, Grid } from "@material-ui/core";
import { GET_PROPOSAL_MENUS_WITH_OPTIONS, GET_PROPOSAL_PRODUCTS } from "modules/aftermarketMenuConstructor/api";
import { ProposalProductsResponse, ProposalsDataResponse } from "modules/aftermarketMenuConstructor/api/types";
import { AddEditMenuTemplateUrlParams, MenuTemplateGeneralFormInputs } from "modules/aftermarketMenuConstructor/types";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { useForm } from "react-hook-form";
import { GeneralInformationCard } from "./GeneralInformationCard";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { modalsSelectors } from "redux/modalsReducer";
import { ModalsKeys } from "components/shared/Modals";
import { AddEditMenuOptionDialog } from "../../dialogs";
import { usePermissions } from "modules/aftermarketMenuConstructor/hooks/usePermissions";
import { HeaderCard } from "./HeaderCard";
import { ProductsCard } from "./ProductsCard";
import { MenuOptionsCard } from "./MenuOptionsCard";
import _ from "lodash";

export const AddEditMenuTemplate = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];
  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;

  const { hasWriteAccess, hasReadOnlyAccess } = usePermissions();

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.AftermarketAddEditMenuOptionDialog]: <AddEditMenuOptionDialog />,
  };

  const { proposalMenuId } = useParams<AddEditMenuTemplateUrlParams>();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<MenuTemplateGeneralFormInputs>({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const { loading: proposalMenuLoading } = useQuery<ProposalsDataResponse>(GET_PROPOSAL_MENUS_WITH_OPTIONS, {
    skip: !proposalMenuId,
    variables: { input: { proposalMenuId } },
    fetchPolicy: "no-cache",
    onCompleted(response) {
      if (!Array.isArray(response?.proposalMenus)) return;
      const proposalMenu = response.proposalMenus[0];
      proposalMenu.menuOptions = _.sortBy(proposalMenu.menuOptions, "ordinal"); //TODO: move it to BE
      dispatch(menuConstructorActions.setMenu(proposalMenu));
      dispatch(menuConstructorActions.setInitialMenu(proposalMenu));
      reset({
        name: proposalMenu.name,
        description: proposalMenu.description,
      });
    },
  });

  useQuery<ProposalProductsResponse>(GET_PROPOSAL_PRODUCTS, {
    onCompleted(response) {
      if (!Array.isArray(response?.proposalProducts)) return;
      dispatch(menuConstructorActions.setProducts(response.proposalProducts));
    },
  });

  useEffect(() => {
    dispatch(menuConstructorActions.setIsLoading(proposalMenuLoading));
  }, [proposalMenuLoading]);

  //
  useEffect(
    () => () => {
      dispatch(menuConstructorActions.clearState());
    },
    [dispatch]
  );

  return (
    <>
      {hasWriteAccess || hasReadOnlyAccess ? (
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <HeaderCard handleSubmit={handleSubmit} />
            </Grid>
            <Grid item xs={12} className={clsx({ [classes.skeleton]: isLoading })}>
              <GeneralInformationCard formControl={control} formErrors={errors} watch={watch} />
            </Grid>
            <Grid item xs={6} className={clsx({ [classes.skeleton]: isLoading })}>
              <ProductsCard />
            </Grid>
            <Grid item xs={6} className={clsx({ [classes.skeleton]: isLoading })}>
              <MenuOptionsCard />
            </Grid>
          </Grid>

          {openedModals.map(modalKey => (
            <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
          ))}
        </DndProvider>
      ) : (
        <h3>Access Denied</h3>
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  skeleton: {
    height: 150,
  },
}));
