import React, { Fragment, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { legalStructureList, salesTaxExemptList, statesAndProvincesList } from "utils";
import { countriesList, monthsList } from "services/dataService";
import { useMutation } from "@apollo/react-hooks";
import EditIcon from "@material-ui/icons/Edit";
import Button from "components/CustomButtons/Button.js";
import CreditAppModifierConfirmModal from "./CreditAppModifierConfirmModal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { gql } from "apollo-boost";
import Tile from "components/Tile/Tile";
import CustomInput from "components/CustomInput/CustomInput";
import Input from "@material-ui/core/Input";
import { Checkbox, ListItemText, OutlinedInput } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import iconPlusSvg from "../../assets/img/icons/plus.svg";
import iconMinusSvg from "../../assets/img/icons/minus.svg";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import InputMask from "react-input-mask";
import classNames from "classnames";
import {
  fleetOperationKeyValueHashMap,
  fleetSegmentsKeyValueHashMap,
  formatCurrency,
  getCreditAppSelectList,
  industryTypeKeyValueHashMap,
  operatingLineKeyValueHashMap,
  ownershipTypeKeyValueHashMap,
} from "utils";
import { theme } from "../../theme";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiInput-underline:focus-within::after": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInput-underline.Mui-error:focus-within::after": {
      borderColor: "#f44336",
    },
    "& .MuiInput-underline.Mui-error::after": {
      borderColor: "#ededed",
    },
  },

  ...extendedFormsStyle,
  blockIndented: {
    paddingLeft: 20,
  },
  rightAligned: {
    position: "float-right",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 540,
    "@media (max-width: 768px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
  detailsContainer: {},
  detailsBlock: {
    display: "flex",
    flexDirection: "column",
  },
  detailsRow: {
    minWidth: 500,
    display: "flex",
    "@media (max-width: 768px)": {
      minWidth: "80vw",
    },
  },
  detailsCell: {
    padding: "5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  detailsLeftCell: {
    width: "50%",
  },
  detailsRightCell: {
    width: "50%",
  },
  inputSlim: {
    margin: 0,
    padding: 0,
  },
  blocks: {
    position: "relative",
  },
  containerTitle: {
    position: "relative",
  },
  collapseButton: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 50,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    zIndex: 3,
    cursor: "pointer",
    color: "#999",
  },
  icon: {
    width: 20,
    height: 20,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    opacity: 0.3,
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
}));

const UPDATE_CREDITAPP_QUERY = gql`
  mutation($input: UpdateCreditApplicationInput!) {
    updateCreditApplication(input: $input)
  }
`;

const UPDATE_ACCOUNT_QUERY = gql`
  mutation($dynamicsId: ID, $fieldsObj: UpdateAccountFields) {
    updateAccount(dynamicsId: $dynamicsId, fieldsObj: $fieldsObj)
  }
`;

const UPDATE_ACCOUNT_CRM_QUERY = gql`
  mutation($entityId: ID!, $fieldsObj: UpdateAccountCRMFields) {
    updateAccountCRM(entityId: $entityId, fieldsObj: $fieldsObj)
  }
`;

export default function NewCreditAppModal(props) {
  const {
    creditApplicationId,
    refetchCreditApp,
    handleRefetchCreditAppRequest,
    ownerToScrollTo,
    softPullInDealerPortal,
  } = props;
  const history = useHistory();
  const ownerReactRefs = [];
  const ownerReactRef1 = useRef(null);
  const ownerReactRef2 = useRef(null);
  const ownerReactRef3 = useRef(null);
  const ownerReactRef4 = useRef(null);
  const ownerReactRef5 = useRef(null);
  const ownerReactRef6 = useRef(null);

  const TAX_ID_LENGTH = 9;
  const US_CITIZEN_DISPLAY_VALUE_HASH_MAP = {
    US_CITIZEN: "U.S. Citizen",
    FOREIGN_NATIONAL_WITH_E2_VISA: "Foreign National with E2 Visa",
    US_FORMED_LEGAL_ENTITY: "U.S.-Formed Legal Entity",
  };

  useEffect(() => {
    if (ownerToScrollTo !== false && typeof ownerToScrollTo === "number") {
      console.log("scrolling to owner");
      ownerReactRefs[ownerToScrollTo].current.scrollIntoView();
    }
  }, []);

  let isBusinessApp = true;
  switch (props.applicationType) {
    case "COMMERCIAL":
      break;
    case "COMMERCIAL_WITH_GUARANTOR":
      break;
    case "COMMERCIAL_WITH_MULTIPLE_GUARANTORS":
      break;
    case "INDIVIDUAL":
      isBusinessApp = false;
      break;
    case "INDIVIDUAL_WITH_SECOND_GUARANTOR":
      isBusinessApp = false;
      break;
  }

  const classes = useStyles();
  const parseFloatValue = (value = "") => {
    const val = parseFloat(value.replace(/[^0-9.]/g, ""));
    return !isNaN(val) ? val : undefined;
  };
  const detailsStateReference = { id: creditApplicationId };
  const [detailsState, setDetailsState] = useState(detailsStateReference);
  const [editMode, setEditMode] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const setDetailsField = (key, val) => setDetailsState({ ...detailsState, [key]: val });
  const owner1PgId = props.owner1PgId ? props.owner1PgId : null;
  const owner2PgId = props.owner2PgId ? props.owner2PgId : null;
  const initialMultiSelectOptions = {};

  const onMultiSelectChange = (field, event) => {
    const {
      target: { value: selectedOptions },
    } = event;
    setMultiselectValues(prevState => ({
      ...prevState,
      [field.key]: selectedOptions,
    }));
    setDetailsState({ ...detailsState, [field.key]: _.map(selectedOptions, "value") });
  };

  try {
    if (props.owner1.personalGuarantor.mostRecentCbr) {
      props.owner1.personalGuarantor.mostRecentCbr.fields.forEach(field => {
        props.owner1.personalGuarantor.mostRecentCbr[field.key] = field.value;
      });
    }
  } catch (e) {
    console.error(e);
  }

  try {
    if (props.owner2.personalGuarantor.mostRecentCbr) {
      props.owner2.personalGuarantor.mostRecentCbr.fields.forEach(field => {
        props.owner2.personalGuarantor.mostRecentCbr[field.key] = field.value;
      });
    }
  } catch (e) {
    console.error(e);
  }

  const selectLists = {
    legalStructure: [
      { text: "None", value: undefined },
      ...Object.keys(legalStructureList).map(key => ({
        text: legalStructureList[key],
        value: key,
      })),
    ],
    salesTaxExempt: [
      { text: "None", value: undefined },
      ...Object.keys(salesTaxExemptList).map(key => ({
        text: key,
        value: salesTaxExemptList[key],
      })),
    ],
    states: [
      { text: "None", value: undefined },
      ...Object.keys(statesAndProvincesList).map(key => ({
        text: key,
        value: statesAndProvincesList[key],
      })),
    ],
    seasonalBusiness: [
      { text: "None", value: null },
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
    fleetSegments: getCreditAppSelectList(fleetSegmentsKeyValueHashMap, true),
    inactiveMonths: monthsList,
    fleetOperation: getCreditAppSelectList(fleetOperationKeyValueHashMap, true),
    countryOfResidence: countriesList,
    ownershipType: getCreditAppSelectList(ownershipTypeKeyValueHashMap, true),
    operatingLine: getCreditAppSelectList(operatingLineKeyValueHashMap, true),
    industryType: getCreditAppSelectList(industryTypeKeyValueHashMap, true),
  };
  const info = [
    {
      key: "businessInfo",
      title: `${isBusinessApp ? "Business" : "Consumer"} Information`,
      collapsed: false,
      blocks: [
        {
          key: "_main",
          title: "",
          type: "block",
          fields: [
            {
              key: "DateCreated",
              title: "Date Applied",
              exclude: true,
              type: "visible",
              excluded: true,
            },
            {
              key: "businessName",
              title: isBusinessApp ? "Business Legal Name" : "Applicant Name",
              type: "input",
              exclude: false,
            },
            {
              key: "businessEmail",
              title: "Email",
              type: "hidden",
              exclude: false,
              inputType: "email",
            },
            {
              key: "dba",
              title: "DBA",
              type: "input",
            },
            {
              key: "industryType",
              title: "Industry Type",
              type: "select",
              list: selectLists.industryType,
            },
            {
              key: "businessStreetAddress1",
              queryCustomKey: "address",
              title: "Street Address",
              type: "input",
            },
            {
              key: "applicantAppartment",
              title: "Appartment #",
              type: !isBusinessApp ? "visible" : null,
            },
            {
              key: "businessAddressCity",
              queryCustomKey: "city",
              title: "City",
              type: "input",
            },
            {
              key: "businessAddressState",
              queryCustomKey: "state",
              title: "State",
              type: "input",
            },
            {
              key: "businessAddressZipcode",
              queryCustomKey: "postalCode",
              title: "Zip Code",
              type: "input",
            },
            {
              key: "businessAddressCounty",
              title: "County",
              type: "input",
              engine: value => ({
                businessAddressCounty: value || null,
              }),
            },
            {
              key: "equipmentNumInFleetFieldValue",
              engine: value => ({
                equipmentNumInFleet: value ? parseInt(value) : undefined,
              }),
              title: "Number of Pieces of Equipment Fleet",
              type: "input",
            },
            // {
            //   key: "businessPhoneNum",
            //   queryCustomKey: "bizPhone",
            //   title: `${isBusinessApp ? "Business" : ""} Phone #`,
            //   type: "input",
            // },
            {
              key: "numberOfEmployee",
              engine: value => ({
                numOfEmployees: value ? parseInt(value) : undefined,
              }),
              queryCustomKey: "numOfEmployees",
              title: "Number of Employees",
              type: "input",
              inputType: "number",
            },
            {
              key: "billingAddress",
              queryCustomKey: "billingAddress",
              title: "Billing Address",
              type: "input",
            },
            {
              key: "billingCity",
              queryCustomKey: "billingCity",
              title: "Billing City",
              type: "input",
            },
            {
              key: "billingState",
              queryCustomKey: "billingState",
              title: "Billing State",
              type: "input",
            },
            {
              key: "billingPostalCode",
              queryCustomKey: "billingPostalCode",
              title: "Billing Zip",
              type: "input",
            },
            {
              key: "contactFullName",
              engine: value => ({
                firstName:
                  !!value && !!value.split(" ")[0] && !!value.split(" ")[0].trim() ? value.split(" ")[0] : undefined,
                lastName:
                  !!value && !!value.split(" ")[1] && !!value.split(" ")[1].trim() ? value.split(" ")[1] : undefined,
              }),
              title: "Contact Name",
              type: "input",
            },
            {
              key: "contactPhone",
              queryCustomKey: "phone",
              title: "Contact Phone",
              maskType: "phone",
              type: "input",
            },
            {
              key: "contactTitle",
              queryCustomKey: "title",
              title: "Contact Title",
              type: "input",
            },
            {
              key: "contactEmail",
              queryCustomKey: "email",
              title: "Contact Email",
              type: "input",
              inputType: "email",
            },
            {
              key: "legalStructure",
              title: "Legal Structure",
              type: "select",
              list: selectLists.legalStructure,
            },
            {
              key: "typeOfBusiness",
              queryCustomKey: "businessType",
              title: "Industry",
              type: isBusinessApp ? "input" : null,
            },
            {
              key: "taxId",
              title: "Federal Tax ID",
              engine: value => ({
                taxId: parseInt(value.replace(/[^0-9.]/g, "")),
              }),
              formatFunction: value =>
                value
                  ? value
                      .toString()
                      .replace(/[^0-9]/g, "")
                      .padStart(TAX_ID_LENGTH, "0")
                      .slice(0, 2) +
                    "-" +
                    value
                      .toString()
                      .replace(/[^0-9]/g, "")
                      .padStart(TAX_ID_LENGTH, "0")
                      .slice(2)
                  : "",
              type: "input",
            },
            {
              key: "dateBusinessEstablished",
              queryCustomKey: "dateEstablished",
              title: "Date Established",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "stateOfIncorporation",
              queryCustomKey: "stateOfIncorp",
              title: "State of Incorporation",
              type: isBusinessApp ? "select" : "hidden",
              list: selectLists.states,
            },
            {
              key: "dateIncorporated",
              title: "Date Incorporated",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "yearsUnderCurrentOwnership",
              queryCustomKey: "yearsInBusiness",
              title: "Years Under Current Ownership",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "pastExp",
              queryCustomKey: "pastExperience",
              title: "Prior Business or Other Experience",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "annualRevenue",
              engine: value => ({ annualRevenue: parseFloatValue(value) }),
              title: "Annual Revenue",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "priorBankruptcy",
              title: "Has The Business or Any Principal Ever Declared Bankruptcy",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "lienOrJudgement",
              title: "Outstanding Liens or Judgements",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "lienOrJudgementDetail",
              engine: value => ({
                lienOrJudgement:
                  !!value && (value.toLowerCase() === "yes" || value.toLowerCase() === "no")
                    ? value.toLowerCase() === "yes"
                    : undefined,
                lienOrJudgementDetail: value && value.trim() ? value : undefined,
              }),
              title: "If Yes, Please List in Detail",
              type: isBusinessApp ? "input" : "hidden",
            },
            {
              key: "apContactName",
              title: "AP Contact Name",
              type: isBusinessApp ? "input" : null,
            },
            {
              key: "apContactEmail",
              title: "AP Contact Email",
              type: isBusinessApp ? "input" : null,
              inputType: "email",
            },
            {
              key: "apContactPhone",
              title: "AP Contact Phone",
              type: isBusinessApp ? "input" : null,
              maskType: "phone",
            },
            {
              key: "salesTaxExempt",
              title: "Sale Tax Exempt?",
              type: isBusinessApp ? "select" : null,
              list: selectLists.salesTaxExempt,
            },
            {
              key: "nearestRelativeFirstName",
              title: "Nearest Relative First Name",
              type: !isBusinessApp ? "input" : null,
            },

            {
              key: "nearestRelativeLastName",
              title: "Nearest Relative Last Name",
              type: !isBusinessApp ? "input" : null,
            },

            {
              key: "nearestRelativePhone",
              title: "Nearest Relative Phone #",
              type: isBusinessApp ? "hidden" : "input",
              maskType: "phone",
            },

            {
              key: "nearestRelativeCity",
              title: "Nearest Relative City",
              type: isBusinessApp ? "hidden" : "input",
            },

            {
              key: "nearestRelativeState",
              title: "Nearest Relative State",
              type: isBusinessApp ? "hidden" : "input",
            },
            {
              key: "nearestRelativeZip",
              title: "Nearest Relative Zip",
              type: isBusinessApp ? "hidden" : "input",
            },

            {
              key: "applicantEmployerName",
              title: "Employer Name",
              type: isBusinessApp ? "hidden" : "input",
            },

            {
              key: "applicantTimeAtJobYears",
              title: "Time At Job (Years)",
              type: isBusinessApp ? "hidden" : "input",
            },

            {
              key: "applicantTimeAtJobMonths",
              title: "Time At Job (Months)",
              type: isBusinessApp ? "hidden" : "input",
            },

            {
              key: "applicantEmploymentStatus",
              title: "Employment Status",
              type: isBusinessApp ? "hidden" : "input",
            },
            {
              key: "owner1EmployerAddress",
              title: "Employer Address",
              type: isBusinessApp ? "hidden" : "input",
            },

            {
              key: "applicantEmploymentCity",
              title: "Employment City",
              type: !isBusinessApp ? "input" : null,
            },

            {
              key: "applicantEmploymentState",
              title: "Employment State",
              type: !isBusinessApp ? "input" : null,
            },

            {
              key: "applicantWorkPhone",
              title: "Work Phone #",
              type: !isBusinessApp ? "input" : null,
              maskType: "phone",
            },

            {
              key: "applicantEmploymentEmail",
              title: "Work Email",
              type: !isBusinessApp ? "input" : null,
            },

            {
              key: "applicantJobTitle",
              title: "Job Title",
              type: !isBusinessApp ? "input" : null,
            },
          ],
        },
      ],
    },
    {
      key: "agriculture",
      title: "Agriculture",
      collapsed: true,
      isHiddenCondition: function() {
        const mainBlock = _.find(this.blocks, { key: "agricultureMain" });
        return _.every(mainBlock.fields, field => !_.get(props, field.key, null));
      },
      blocks: [
        {
          key: "agricultureMain",
          title: "",
          type: "block",
          fields: [
            {
              key: "agricultureData.businessOrIndividual",
              title: "Business or Individual",
              type: "visible",
              excluded: true,
            },
            {
              key: "agricultureData.primaryAgriculturalProduct",
              title: "Primary Agricultural Product",
              type: "visible",
              excluded: true,
            },
            {
              key: "agricultureData.farmingStartYear",
              title: "Year Began Farming",
              type: "visible",
              excluded: true,
            },
            {
              key: "agricultureData.usCitizen",
              title: "US Citizen or Foreign National",
              type: "visible",
              excluded: true,
              displayValueCustomizer: value => (value ? US_CITIZEN_DISPLAY_VALUE_HASH_MAP[value] : ""),
            },
            {
              key: "agricultureData.farmType",
              title: "Type of Farmer",
              type: "visible",
              excluded: true,
              displayValueCustomizer: value => (value ? value.replace(/_/g, " ").toLowerCase() : ""),
            },
            {
              key: "agricultureData.farmingIncome",
              title: "Farming Income",
              type: "visible",
              excluded: true,
              formatFunction: formatCurrency,
            },
            {
              key: "agricultureData.nonFarmingIncome",
              title: "Net Non-Farming Income",
              type: "visible",
              excluded: true,
              formatFunction: formatCurrency,
            },
          ],
        },
      ],
    },

    {
      key: "trucking",
      title: "Trucking",
      collapsed: true,
      blocks: [
        {
          key: "trucking_block",
          title: "",
          type: "block",
          fields: [
            {
              key: "fleetSegments",
              title: "Fleet Segments",
              type: "select",
              list: selectLists.fleetSegments,
            },
            {
              key: "seasonalBusiness",
              title: "Is Business Seasonal?",
              type: "select",
              list: selectLists.seasonalBusiness,
            },
            {
              key: "inactiveMonths",
              title: "Inactive Months",
              type: "multi-select",
              list: selectLists.inactiveMonths,
            },
            {
              key: "fleetOperation",
              title: "Fleet Operation",
              type: "select",
              list: selectLists.fleetOperation,
            },
            {
              key: "annualMileage",
              title: "Annual Mileage",
              type: "input",
              engine: value => ({
                annualMileage: value ? parseInt(value) : null,
              }),
            },
          ],
        },
      ],
    },

    {
      key: "references",
      title: "References",
      collapsed: true,
      blocks: [
        {
          key: "primaryBankReference",
          title: "Primary Bank Reference",
          type: "block",
          fields: [
            {
              key: "primaryBankName",
              title: "Bank Name",
              type: "input",
            },
            {
              key: "primaryBankAccountNum",
              title: "Account #",
              type: "input",
            },
            {
              key: "primaryBankContactName",
              title: "Contact Name",
              type: isBusinessApp ? "input" : null,
            },
            // {
            //   key: "primaryBankContactEmail",
            //   title: "Bank Contact Email",
            //   type: isBusinessApp ? "input" : null,
            //   inputType: "email",
            // },
            {
              key: "primaryBankPhone",
              title: "Phone #",
              type: "input",
              maskType: "phone",
            },
            {
              key: "primaryBankAverageBalance",
              engine: value => ({
                primaryBankAverageBalance: value.trim().length ? parseInt(value.replace(/[^0-9]/g, "")) : undefined,
              }),
              title: "Average Balance",
              type: "input",
            },
          ],
        },
        {
          key: "lineOfCreditInfo",
          title: "Line of Credit Info",
          type: "block",
          fields: [
            {
              key: "creditName",
              queryCustomKey: "primaryCreditName",
              title: "Bank / Credit Name",
              type: "input",
            },
            {
              key: "creditAccountNum",
              queryCustomKey: "primaryCreditAccountNum",
              title: "Account #",
              type: "input",
            },
            {
              key: "creditContactName",
              queryCustomKey: "primaryCreditContactName",
              title: "Contact Name",
              type: "input",
            },
            {
              key: "creditContactEmail",
              queryCustomKey: "primaryCreditContactEmail",
              title: "Bank / Credit Contact Email",
              type: "input",
              inputType: "email",
            },
            {
              key: "creditPhoneNum",
              queryCustomKey: "primaryCreditPhone",
              title: "Phone #",
              type: "input",
              maskType: "phone",
            },
            {
              key: "creditAverageBalance",
              engine: value => ({
                primaryCreditAverageBalance: value.trim().length
                  ? parseInt(detailsState.creditAverageBalance.replace(/[^0-9]/g, ""))
                  : undefined,
              }),
              title: "Average Balance",
              type: "input",
            },
          ],
        },
        {
          key: "tradeReference",
          title: "Trade References #1",
          type: "block",
          fields: [
            {
              key: "tradeReferenceName",
              queryCustomKey: "tradeReferenceName",
              title: "Name",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceContactName",
              queryCustomKey: "tradeReferenceContactName",
              title: "Contact Name",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceEmail",
              queryCustomKey: "tradeReferenceEmail",
              title: "Trade Contact Email",
              type: "input",
              inputType: "email",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferencePhone",
              queryCustomKey: "tradeReferencePhone",
              title: "Phone",
              type: "input",
              maskType: "phone",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceFax",
              title: "Fax",
              type: "input",
              maskType: "phone",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceAccount",
              title: "Account",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceYears",
              title: "How many years have you been working with reference?",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceMonths",
              title: "Additional months working with reference",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferencePercRevenue",
              title: "Perc Revenue",
              type: "input",
              excluded: true,
              editable: false,
              rightHandText: "%",
            },
          ],
        },
        {
          key: "tradeReference",
          title: "Trade References #2",
          type: "block",
          fields: [
            {
              key: "tradeReferenceName2",
              queryCustomKey: "tradeReferenceName2",
              title: "Name",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceContactName2",
              queryCustomKey: "tradeReferenceContactName2",
              title: "Contact Name",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceEmail2",
              queryCustomKey: "tradeReferenceEmail2",
              title: "Trade Contact Email",
              type: "input",
              inputType: "email",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferencePhone2",
              queryCustomKey: "tradeReferencePhone2",
              title: "Phone",
              type: "input",
              maskType: "phone",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceFax2",
              title: "Fax",
              type: "input",
              maskType: "phone",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceAccount2",
              title: "Account",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceYears2",
              title: "How many years have you been working with reference?",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceMonths2",
              title: "Additional months working with reference",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferencePercRevenue2",
              title: "Perc Revenue",
              type: "input",
              excluded: true,
              editable: false,
              rightHandText: "%",
            },
          ],
        },
        {
          key: "tradeReference",
          title: "Trade References #3",
          type: "block",
          fields: [
            {
              key: "tradeReferenceName3",
              queryCustomKey: "tradeReferenceName3",
              title: "Name",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceContactName3",
              queryCustomKey: "tradeReferenceContactName3",
              title: "Contact Name",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceEmail3",
              queryCustomKey: "tradeReferenceEmail3",
              title: "Trade Contact Email",
              type: "input",
              inputType: "email",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferencePhone3",
              queryCustomKey: "tradeReferencePhone3",
              title: "Phone",
              type: "input",
              maskType: "phone",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceFax3",
              title: "Fax",
              type: "input",
              maskType: "phone",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceAccount3",
              title: "Account",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceYears3",
              title: "How many years have you been working with reference?",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferenceMonths3",
              title: "Additional months working with reference",
              type: "input",
              excluded: true,
              editable: false,
            },
            {
              key: "tradeReferencePercRevenue3",
              title: "Perc Revenue",
              type: "input",
              excluded: true,
              editable: false,
              rightHandText: "%",
            },
          ],
        },
      ],
    },
    {
      key: "ownersAndGuarantors",
      title: "Owners and Guarantors",
      collapsed: false,
      blocks: [
        {
          key: "owner%(n)%",
          title: "Owner/Signor #%(n)%",
          type: "reference",
          parentQueryKey: "owners",
          maxCount: 2,
          className: classes.blockIndented,
          fields: [
            {
              key: "creditApplicationId",
              type: "hidden",
            },
            {
              key: "owner%(n)%PgId",
              queryCustomKey: "ownerPgId",
              type: "hidden",
            },
            {
              key: "owner%(n)%Fullname",
              engine: value => ({
                firstName: !!value.split(" ")[0] && !!value.split(" ")[0].trim() ? value.split(" ")[0] : undefined,
                lastName: !!value.split(" ")[1] && !!value.split(" ")[1].trim() ? value.split(" ")[1] : undefined,
              }),
              title: "Name",
              type: "input",
            },
            {
              key: "owner%(n)%Title",
              queryCustomKey: "title",
              title: "Title",
              type: "input",
            },
            {
              key: "owner%(n)%Email",
              queryCustomKey: "email",
              title: "Email",
              type: "input",
              inputType: "email",
            },
            {
              key: "owner%(n)%SSN",
              queryCustomKey: "ssn",
              title: "Social Security #",
              type: "input",
            },
            {
              key: "owner%(n)%MobileNum",
              queryCustomKey: "mobilePhone",
              title: "Mobile Phone",
              type: "input",
              maskType: "phone",
            },
            {
              key: "owner%(n)%PrimaryPhone",
              queryCustomKey: "phone",
              title: "Primary Phone",
              type: "input",
              maskType: "phone",
            },
            {
              key: "owner%(n)%PercentageOwned",
              engine: value => ({
                percOwner: value.trim().length ? parseInt(value.replace(/[^0-9]/g, "")) : undefined,
              }),
              title: "% Ownership",
              type: "input",
            },
            {
              key: "owner%(n)%EmployerName",
              queryCustomKey: "employerName",
              title: "Name of Employer",
              type: "input",
            },
            {
              key: "owner%(n)%EmployerEmail",
              queryCustomKey: "employerEmail",
              title: "Employer Email",
              type: "input",
              inputType: "email",
            },
            {
              key: "owner%(n)%Address",
              queryCustomKey: "address",
              title: "Address",
              type: "input",
            },
            {
              key: "owner%(n)%Appartment",
              queryCustomKey: "address2",
              title: "Appartment",
              type: "input",
            },
            {
              key: "owner%(n)%City",
              queryCustomKey: "city",
              title: "City",
              type: "input",
            },
            {
              key: "owner%(n)%State",
              queryCustomKey: "state",
              title: "State",
              type: "input",
            },
            {
              key: "owner%(n)%PostalCode",
              queryCustomKey: "postalCode",
              title: "Zip Code",
              type: "input",
            },
            {
              key: "owner%(n)%DOB",
              queryCustomKey: "dateOfBirth",
              title: "Date of Birth",
              type: "input",
            },
            {
              key: "owner%(n)%DriverLicense",
              queryCustomKey: "driversLicenseNum",
              title: "Driver License #",
              type: "input",
            },
            {
              key: "owner%(n)%StateIssued",
              queryCustomKey: "dlStateIssued",
              title: "State Issued",
              type: "input",
            },
            {
              key: "owner%(n)%HomeType",
              queryCustomKey: "homeType",
              title: "Home Type (rent/own)",
              type: "input",
            },
            {
              key: "owner%(n)%TimeAtAddressYears",
              queryCustomKey: "timeAtAddressYears",
              title: "Time at Address - Years",
              type: "input",
            },
            {
              key: "owner%(n)%TimeAtAddressMonths",
              queryCustomKey: "timeAtAddressMonths",
              title: "Time at Address - Months",
              type: "input",
            },
            {
              key: "owner%(n)%HomePhone",
              queryCustomKey: "homePhone",
              title: "Home Phone",
              type: "input",
              maskType: "phone",
            },
            {
              key: "owner%(n)%TimeAtJobYears",
              queryCustomKey: "timeAtJobYears",
              title: "Years at Job",
              type: "input",
              engine: value => ({
                timeAtJobYears: isNaN(parseInt(value)) ? undefined : parseInt(value),
              }),
            },
            {
              key: "owner%(n)%TimeAtJobMonths",
              queryCustomKey: "timeAtJobMonths",
              title: "Months at Job",
              type: "input",
              engine: value => ({
                timeAtJobMonths: isNaN(parseInt(value)) ? undefined : parseInt(value),
              }),
            },
            {
              key: "owner%(n)%EmploymentStatus",
              queryCustomKey: "employmentStatus",
              title: "Employment Status",
              type: "input",
            },
            {
              key: "owner%(n)%EmployerCity",
              queryCustomKey: "employerCity",
              title: "Employer City",
              type: "input",
            },
            {
              key: "owner%(n)%EmployerState",
              queryCustomKey: "employerState",
              title: "Employer State",
              type: "input",
            },
            {
              key: "owner%(n)%WorkPhone",
              queryCustomKey: "workPhone",
              title: "Work Phone",
              type: "input",
              maskType: "phone",
            },
            {
              key: "owner%(n)%JobTitle",
              queryCustomKey: "title",
              title: "Job Title",
              type: "input",
            },
            {
              key: "owner%(n)%nameSuffix",
              queryCustomKey: "nameSuffix",
              title: "Name Suffix",
              type: "input",
            },
            {
              key: "owner%(n)%NetWorth",
              queryCustomKey: "netWorth",
              engine: value => ({ netWorth: parseFloatValue(value) }),
              title: "Net Worth",
              type: "input",
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%MonthlyIncome",
              engine: value => ({
                grossMonthlyIncome: parseFloatValue(value),
              }),
              title: "Monthly Income",
              type: "input",
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%MonthlyHousePayment",
              engine: value => ({
                monthlyHousingPayment: parseFloatValue(value),
              }),
              title: "Monthly Housing Payment",
              type: "input",
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%CountryOfResidence",
              queryCustomKey: "countryOfResidence",
              title: "Country of Residence",
              type: "select",
              list: selectLists.countryOfResidence,
            },
            {
              key: "owner%(n)%OwnershipType",
              queryCustomKey: "ownershipType",
              title: "Ownership Type",
              type: "multi-select",
              list: selectLists.ownershipType,
            },

            // CBR Fields
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.bureauType",
              title: "Bureau Type",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.ficoScore",
              title: "FICO Score",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.publicRecordCount",
              title: "# Public Records",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.collectionRecordsCount",
              title: "Collection Records",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.bankruptcyRecordCount",
              title: "Bankruptcy Records",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.revolvingTotalCredit",
              title: "Revolving Total Credit",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.revolvingAvailPercent",
              title: "Revolving Avail (%)",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.revolvingBalance",
              title: "Revolving Balance",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.tradelineCount",
              title: "# of Tradelines",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.derogAccountsCount",
              title: "# Derog Accounts",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.pastDerogAccountsCount",
              title: "Past Derog Accts",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr.oldestTradeline",
              title: "Oldest Tradelines",
              type: "input",
              position: "right",
              editable: false,
            },

            // CBC
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['FICO Score']",
              title: "FICO Score",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['ACCOUNTSBALANCE']",
              title: "Accounts Balance",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['ACCOUNTSNUMBER']",
              title: "Total # of Trades",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['AMOUNTPASTDUE']",
              title: "Amount Past Due",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['AVAILABLEPERCENTAGE']",
              title: "Available %",
              type: "input",
              position: "right",
              editable: false,
              rightHandText: "%",
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['AVGMOPAYMENT']",
              title: "Monthly Payment",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['COLLECTIONS']",
              title: "Collections",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['CREDITLIMIT']",
              title: "Credit Limit",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['CURRENT']",
              title: "Current Trades",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['CURRENTRATING 30']",
              title: "30 Days",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['CURRENTRATING 60']",
              title: "60 Days",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['CURRENTRATING 90']",
              title: "90+ Days",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['HIGHCREDIT']",
              title: "High Credit",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['HISTNEGATIVE']",
              title: "Hist Neg Trades",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['INQUIRIES']",
              title: "Inquiries",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['LAST 6MINQUIRIES']",
              title: "Inquiries Last 6 Months",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['PASTDUE']",
              title: "Curr Past Due",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['PUBLICRECORDS']",
              title: "Public Records",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['NEGATIVE']",
              title: "Negative",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['UNRATED']",
              title: "Unrated Trades",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TOTALREALESTATEBAL']",
              title: "Total Real Est. Balance",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TOTALREVOLVINGBAL']",
              title: "Total Revolving Balance",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TOTALINSTALLMENTBAL']",
              title: "Total Installment Balance",
              type: "input",
              position: "right",
              editable: false,
              formatFunction: formatCurrency,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['OLDESTTRADE']",
              title: "Oldest Trade",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['NUMACCPAID']",
              title: "No. of Accounts Paid",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['MLA']",
              title: "MLA",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['BANKRUPTCIES NUMBER']",
              title: "Public Records",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['BANKRUPTCIES MOST RECENT DATE']",
              title: "Most Recent Bankruptcy Date",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['REPOSSESSIONS NUMBER']",
              title: "Number of Repossessions",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['REPOSSESSIONS MOST RECENT DATE']",
              title: "Most Recent Repossession Date",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['REPOSSESSIONS AUTO REPO NUMBER']",
              title: "Repossessions Auto Repo Number",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TRADES OPEN NO LATES 12 MONTHS']",
              title: "Open Number of Trades Last 12 Months",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TRADES OPEN SATISFACTORY']",
              title: "Trades Open Satisfactory",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TRADES CLOSED SATISFACTORY']",
              title: "Trades Closed Satisfactory",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TRADES OPEN AUTO']",
              title: "Trades Open Auto",
              type: "input",
              position: "right",
              editable: false,
            },
            {
              key: "owner%(n)%.personalGuarantor.mostRecentCbr['TRADES AUTO DEROGATORY']",
              title: "Trades Auto Derogatory",
              type: "input",
              position: "right",
              editable: false,
            },
          ],
        },
      ],
    },
    {
      key: "additionalInfo",
      title: "Additional Info",
      collapsed: true,
      blocks: [
        {
          key: "_main",
          title: "",
          type: "block",
          fields: [
            {
              key: "operatingLine",
              title: "Operating Line Secured By",
              type: "select",
              list: selectLists.operatingLine,
            },
            {
              key: "existingCustomer",
              engine: value => ({
                existingCustomer:
                  !!value && (value.toLowerCase() === "yes" || detailsState.existingCustomer.toLowerCase() === "no")
                    ? value.toLowerCase() === "yes"
                    : undefined,
              }),
              title: "Existing Customer?",
              type: "input",
            },
            {
              key: "equipmentNumInFleetFieldValue",
              engine: value => ({
                equipmentNumInFleet: value ? parseInt(value) : undefined,
              }),
              title: "Number of Pieces of Equipment Fleet",
              type: "input",
            },
            {
              key: "priorTruckingFinanceFieldValue",
              queryCustomKey: "priorTruckingFinanceExp",
              title: "Prior Trucking Financing",
              type: "input",
            },
            {
              key: "contractorLicenseNumFieldValue",
              queryCustomKey: "contractorLicenseNum",
              title: "Contractor's License #",
              type: "input",
            },
            {
              key: "truckType",
              queryCustomKey: "truckType",
              title: "Truck Type",
              type: "input",
            },
            {
              key: "purchaseReason",
              queryCustomKey: "purchaseReason",
              title: "Reason for purchase",
              type: "input",
            },
            {
              key: "piecesOfHeavyEqpt",
              queryCustomKey: "piecesOfHeavyEqpt",
              title: "# of Heavy Ept",
              type: "input",
            },
            {
              key: "piecesOfMediumEqpt",
              queryCustomKey: "piecesOfMediumEqpt",
              title: "# of Medium Ept",
              type: "input",
            },
            {
              key: "piecesOfTrailerEqpt",
              queryCustomKey: "piecesOfTrailerEqpt",
              title: "# of Trailer Ept",
              type: "input",
            },
            {
              key: "totalEqpt",
              queryCustomKey: "totalEqpt",
              title: "# of Total Ept",
              type: "input",
            },
            {
              key: "piecesOfTractorEqpt",
              title: "Number of Tractors",
              type: "input",
              engine: value => ({
                piecesOfTractorEqpt: value ? parseInt(value) : null,
              }),
            },
            {
              key: "piecesOfTruckEqpt",
              title: "Number of Trucks",
              type: "input",
              engine: value => ({
                piecesOfTruckEqpt: value ? parseInt(value) : null,
              }),
            },
            {
              key: "piecesOfUnitsPurchased",
              title: "Total Number of Units to be Purchased / Leased",
              type: "input",
              engine: value => ({
                piecesOfUnitsPurchased: value ? parseInt(value) : null,
              }),
            },
            {
              key: "numberOfAdditions",
              title: "Number of Additions to Fleet",
              type: "input",
              engine: value => ({
                numberOfAdditions: value ? parseInt(value) : null,
              }),
            },
            {
              key: "numberOfReplacements",
              title: "Number of Replacements to Fleet",
              type: "input",
              engine: value => ({
                numberOfReplacements: value ? parseInt(value) : null,
              }),
            },
            {
              key: "numOfOwnerOps",
              queryCustomKey: "numOfOwnerOps",
              title: "# of Owner Operators",
              type: "input",
            },
            {
              key: "eqptUse",
              queryCustomKey: "eqptUse",
              title: "Use for equpment",
              type: "input",
            },
            {
              key: "typesOfGoods",
              queryCustomKey: "typesOfGoods",
              title: "What goods will be hauled?",
              type: "input",
            },
            {
              key: "longHaulRegional",
              queryCustomKey: "longHaulRegional",
              title: "Long Haul OTR / Regional?",
              type: "input",
            },
            {
              key: "hazmat",
              queryCustomKey: "hazmat",
              title: "Hazmat",
              type: "input",
            },
            {
              key: "yearMakeModel",
              queryCustomKey: "yearMakeModel",
              title: "Year/Make/Model",
              type: "input",
            },
            {
              key: "eqptPaidOff",
              queryCustomKey: "eqptPaidOff",
              title: "Is equipment paid off?",
              type: "input",
            },
            {
              key: "eqptTrade",
              queryCustomKey: "eqptTrade",
              title: "Will equipment be traded in?",
              type: "input",
            },
            {
              key: "ownAuth",
              queryCustomKey: "ownAuth",
              title: "Do you have own auth?",
              type: "input",
            },
            {
              key: "mcNumber",
              queryCustomKey: "mcNumber",
              title: "MC Number",
              type: "input",
            },
            {
              key: "usDotNum",
              queryCustomKey: "usDotNum",
              title: "US DOT NUM",
              type: "input",
            },
            {
              key: "invoiceDelivery",
              queryCustomKey: "invoiceDelivery",
              title: "Invoice Delivery Preference",
              type: "input",
            },
            {
              key: "purchaseOrder",
              queryCustomKey: "purchaseOrder",
              title: "Purchase Order?",
              type: "input",
            },
          ],
        },
      ],
    },
    {
      key: "dealerAndEquipmentInfo",
      title: "Original Application Request",
      collapsed: true,
      blocks: [
        {
          key: "_main",
          title: "",
          type: "block",
          fields: [
            {
              key: "equipmentDescription",
              queryCustomKey: "equipmentDescription",
              title: "Equipment Description",
              type: "input",
              editable: false,
            },
            {
              key: "invoiceAmount",
              engine: value => ({ amountRequested: parseFloatValue(value) }),
              title: "Amount Requested",
              type: "input",
              editable: false,
            },
          ],
        },
      ],
    },
  ];
  // const isReferenceFieldsExist = (block, counter, props) => {
  // 	const keys = block.fields.map(field => field.key.replace(`%(n)%`, counter));
  // 	let fieldExist = false;
  // 	keys.forEach(key => {if (!!props[key] || props[key] === false) fieldExist = true; return true;});
  // 	return fieldExist;
  // }
  const setContainerVisibility = container => {
    container.isHidden = _.isFunction(container.isHiddenCondition) ? container.isHiddenCondition() : false;
  };

  const collapseInitialState = {};
  _.forEach(info, setContainerVisibility);
  const containersToDisplay = _.reject(info, "isHidden");

  containersToDisplay.forEach(container => {
    container.blocks.forEach(block => {
      collapseInitialState[container.key] = container.collapsed;
      const blockType = block.type;
      if (blockType === "reference") {
        let referenceCounter = 1;
        let referenceFieldExist = true;
        const maxCount = block.maxCount;
        while (referenceFieldExist && maxCount >= referenceCounter) {
          // referenceFieldExist = isReferenceFieldsExist(block, referenceCounter, props);
          referenceFieldExist = true;
          if (referenceFieldExist && maxCount >= referenceCounter) {
            const newBlock = {
              ...block,
              type: "block",
              referenced: true,
              fields: [],
              key: block.key.replace(`%(n)%`, referenceCounter),
              title: block.title.replace(`%(n)%`, referenceCounter),
            };
            block.fields.forEach(field => {
              const key = field.key.replace(`%(n)%`, referenceCounter);
              let newField;
              let selected;
              const val = _.get(props, key, null);
              const value = !val || (typeof val === "string" && !val.trim()) ? "" : val.toString().trim();
              switch (field.type) {
                case "hidden":
                  newField = { ...field, key, value, text: value };
                  detailsStateReference[key] = value;
                  break;
                case "input":
                  newField = { ...field, key, value, text: value };
                  detailsStateReference[key] = value;
                  break;
                case "select":
                  selected = field.list.find(item => item.value === value);
                  newField = {
                    ...field,
                    key,
                    value,
                    selected,
                    text: _.get(selected, "text", ""),
                  };
                  detailsStateReference[key] = value;
                  break;
                case "multi-select":
                  selected = field.list.filter(item => _.includes(val, item.value));
                  initialMultiSelectOptions[key] = selected;
                  newField = {
                    ...field,
                    key,
                    value: val,
                    selected,
                    text: _.some(selected)
                      ? _(selected)
                          .map("text")
                          .join(", ")
                      : "",
                  };
                  detailsStateReference[key] = val;
                  break;
                default:
                  break;
              }
              newBlock.fields.push(newField);
            });
            container.blocks.push(newBlock);
          }
          referenceCounter++;
          // referenceFieldExist = isReferenceFieldsExist(block, referenceCounter, props);
        }
      } else {
        block.fields.forEach(field => {
          const val = _.get(props, field.key, null);
          if (_.isFunction(field.displayValueCustomizer)) {
            field.text = field.value = field.displayValueCustomizer(val);
          } else {
            switch (field.type) {
              case "input":
                field.text = field.value = !val || (typeof val === "string" && !val.trim()) ? "" : val.toString();
                break;
              case "visible":
                field.text = field.value = !val || (typeof val === "string" && !val.trim()) ? "" : val.toString();
                break;
              case "select":
                field.value = val;
                field.selected = field.list.find(item => item.value === field.value);
                field.text = (!!field.selected && field.selected.text) || "";
                break;
              case "multi-select":
                field.value = val;
                field.selected = field.list.filter(item => _.includes(field.value, item.value));
                initialMultiSelectOptions[field.key] = field.selected;
                field.text = _.some(field.selected)
                  ? _(field.selected)
                      .map("text")
                      .join(", ")
                  : "";
                break;
              default:
                break;
            }
          }
          detailsStateReference[field.key] = field.value;
        });
      }
    });
  });
  const [collapseState, setCollapseState] = useState(collapseInitialState);
  const toggleCollapse = key => setCollapseState({ ...collapseState, [key]: !collapseState[key] });
  const [multiselectValues, setMultiselectValues] = useState(initialMultiSelectOptions);

  const checkValidEmail = email => {
    if (/.+@.+\.[A-Za-z]+$/.test(email)) return false;
    return true;
  };

  const detailRow = (field, index) => {
    if (
      (!!showAll && field.type !== "hidden") ||
      (field.type !== "hidden" && (editMode || (!editMode && (!!field.value || field.value === false)))) ||
      (field.type === "visible" && !!field.value && field.value !== false)
    ) {
      return (
        <div className={classes.detailsRow} key={`field-${index}`}>
          <div className={classes.detailsCell + " " + classes.detailsLeftCell}>
            <b>{field.title}</b>
          </div>
          <div className={classNames(classes.detailsCell, classes.detailsRightCell, classes.root)}>
            {!!editMode && field.type === "input" && (
              <>
                {field?.maskType && field?.maskType === "phone" ? (
                  <Fragment>
                    {_.get(field, "editable", true) && (
                      <InputMask
                        mask="(999) 999-9999"
                        onChange={e => setDetailsField(field.key, e.target.value)}
                        placeholder="(999) 999-9999"
                        id={field.key}
                        key={field.key}
                        type="tel"
                        value={detailsState[field.key]}
                        defaultValue={field.value ? field.value : ""}
                      >
                        {inputProps => (
                          <Input
                            {...inputProps}
                            formControlProps={{
                              name: field.key,
                              fullWidth: true,
                              className: classes.inputSlim,
                            }}
                            error={detailsState[field.key]?.replace(/[\D]+/g, "")?.length === 10 ? false : true}
                            style={{ width: "100%" }}
                            type="tel"
                          />
                        )}
                      </InputMask>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {_.get(field, "editable", true) && (
                      <CustomInput
                        id={field.key}
                        key={field.key}
                        formControlProps={{
                          name: field.key,
                          fullWidth: true,
                          className: `${classes.inputSlim}`,
                        }}
                        error={field?.inputType === "email" ? checkValidEmail(detailsState[field.key]) : false}
                        inputProps={{
                          type: `${field?.inputType ? field?.inputType : "text"}`,
                          onChange: e => setDetailsField(field.key, e.target.value),
                          defaultValue: field.value ? field.value : "",
                        }}
                      />
                    )}
                  </Fragment>
                )}
                {!_.get(field, "editable", true) && (
                  <>
                    {field.key === "owner1Fullname" ? (
                      <span
                        onClick={() => handleOwnerPgClick(owner1PgId)}
                        style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                      >
                        {field.text}
                      </span>
                    ) : field.key === "owner2Fullname" ? (
                      <span
                        onClick={() => handleOwnerPgClick(owner2PgId)}
                        style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                      >
                        {field.text}
                      </span>
                    ) : (
                      <span>
                        {field.formatFunction ? field.formatFunction(field.text, true) : field.text}
                        {field.rightHandText ? field.rightHandText : ""}
                      </span>
                    )}
                  </>
                )}
              </>
            )}
            {!!editMode && field.type === "select" && (
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  classes={{ select: classes.select }}
                  className={classes.textAlignLeft}
                  onChange={e => setDetailsField(field.key, e.target.value)}
                  inputProps={{
                    id: field.key,
                    name: field.key,
                    defaultValue: field.value,
                  }}
                >
                  {field.list.map((item, index) => (
                    <MenuItem value={item.value} key={field.key + index} classes={{ root: classes.selectMenuItem }}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!!editMode && field.type === "multi-select" && (
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  multiple
                  value={multiselectValues[field.key]}
                  onChange={e => onMultiSelectChange(field, e)}
                  input={<OutlinedInput />}
                  renderValue={selected =>
                    _(selected)
                      .map("text")
                      .join(", ")
                  }
                >
                  {field.list.map((item, index) => (
                    <MenuItem key={field.key + index} value={item}>
                      <Checkbox
                        checked={
                          _(multiselectValues[field.key])
                            .map("value")
                            .indexOf(item.value) > -1
                        }
                      />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!editMode && (
              <>
                {field.key === "owner1Fullname" ? (
                  <span
                    onClick={() => handleOwnerPgClick(owner1PgId)}
                    style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                  >
                    {field.text}
                  </span>
                ) : field.key === "owner2Fullname" ? (
                  <span
                    onClick={() => handleOwnerPgClick(owner2PgId)}
                    style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                  >
                    {field.text}
                  </span>
                ) : (
                  <span>
                    {field.formatFunction ? field.formatFunction(field.text, true) : field.text}
                    {field.rightHandText ? field.rightHandText : ""}
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
    return null;
  };
  const processFields = (input, field, val) => {
    const isValueSet = value => {
      return !!value || value === false || value === null;
    };

    if (!field.excluded) {
      if (field.queryCustomKey) {
        input[field.queryCustomKey] = isValueSet(val) ? val : undefined;
      }
      if (typeof field.engine === "function") {
        input = {
          ...input,
          ...field.engine(val),
        };
      }
      if (!field.queryCustomKey && !field.engine) {
        input = {
          ...input,
          [field.key]: isValueSet(val) ? val : undefined,
        };
      }
    }
    return input;
  };
  const prepareInput = () => {
    let input = { id: creditApplicationId };
    containersToDisplay.forEach(container => {
      container.blocks.forEach(block => {
        const parentKey = block.parentQueryKey;
        switch (block.type) {
          case "reference":
            if (typeof input[parentKey] === "undefined") input[parentKey] = [];
            let referenceCounter = 1;
            let referenceFieldExist = true;
            const maxCount = block.maxCount;
            let fields = {};
            while (referenceFieldExist && maxCount >= referenceCounter) {
              block.fields.forEach(field => {
                if (_.get(field, "editable", true)) {
                  const key = field.key.replace(`%(n)%`, referenceCounter);
                  const val = detailsState[key];
                  const newFields = processFields(fields, field, val);
                  fields = { ...fields, ...newFields };
                }
              });
              input[parentKey].push(fields);
              referenceCounter++;
            }
            break;
          case "block":
            if (!block.referenced) {
              block.fields.forEach(field => {
                const val = detailsState[field.key];
                const newFields = processFields(input, field, val);
                input = { ...input, ...newFields };
              });
            }
            break;
          default:
            break;
        }
      });
    });
    return input;
  };
  const handleSubmit = async () => {
    const input = prepareInput();
    try {
      if (input.yearsInBusiness) {
        input.yearsInBusiness = parseInt(input.yearsInBusiness) != NaN ? parseInt(input.yearsInBusiness) : 0;
      }
      if (input.dba === undefined) {
        input.dba = null;
      }
      try {
        input.numOfOwnerOps = parseInt(input.numOfOwnerOps);
      } catch (e) {
        delete input.numOfOwnerOps;
      }
      try {
        input.hazmat = input.hazmat === "true";
      } catch (e) {
        delete input.hazmat;
      }
      try {
        input.piecesOfHeavyEqpt = parseInt(input.piecesOfHeavyEqpt);
      } catch (e) {
        delete input.piecesOfHeavyEqpt;
      }
      try {
        input.piecesOfMediumEqpt = parseInt(input.piecesOfMediumEqpt);
      } catch (e) {
        delete input.piecesOfMediumEqpt;
      }
      try {
        input.piecesOfTrailerEqpt = parseInt(input.piecesOfTrailerEqpt);
      } catch (e) {
        delete input.piecesOfTrailerEqpt;
      }
      try {
        input.totalEqpt = parseInt(input.totalEqpt);
      } catch (e) {
        delete input.totalEqpt;
      }
      await updateCreditAppRequest({ variables: { input } });
      await updateAccountRequest({
        variables: {
          dynamicsId: props.potentialCustomer.dynamicsAccountId,
          fieldsObj: {
            name: input.businessName,
          },
        },
      });
      await updateAccountCRMRequest({
        variables: {
          entityId: props.potentialCustomer.dynamicsAccountId,
          fieldsObj: {
            name: input.businessName,
          },
        },
      });
      await refetchCreditApp();
      await handleRefetchCreditAppRequest();
      handleCloseModal();
    } catch (err) {
      console.log(err);
    }
  };
  const handleConfirm = () => setConfirmModal(true);
  const handleCloseModal = () => {
    setEditMode(false);
    setConfirmModal(false);
  };

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDITAPP_QUERY, {
    context: { authRequired: true },
  });
  const [updateAccountRequest] = useMutation(UPDATE_ACCOUNT_QUERY, {
    context: { authRequired: true },
  });

  const [updateAccountCRMRequest] = useMutation(UPDATE_ACCOUNT_CRM_QUERY, {
    context: { authRequired: true },
  });

  const handleOwnerPgClick = id => {
    history.push(`/ownerPgDetails/${id}`);
  };

  return (
    <div className={classes.modalStyle}>
      <Tile
        maxHeightLimitByScreen={true}
        title="Credit Application Detail"
        titleButtons={[
          {
            icon: <EditIcon />,
            props: {
              color: "transparent",
              style: { color: "grey", padding: 5 },
            },
            func: () => setEditMode(true),
          },
          {
            text: "Show All",
            func: () => {
              setShowAll(true);
            },
          },
        ]}
      >
        {containersToDisplay.map((container, containerIndex) => {
          return (
            <div key={`container-${containerIndex}`}>
              <div className={classes.containerTitle}>
                <h2>{container.title}</h2>
                <div className={classes.collapseButton} onClick={() => toggleCollapse(container.key)}>
                  {collapseState[container.key] ? (
                    <div className={classes.icon + " " + classes.iconPlus} />
                  ) : (
                    <div className={classes.icon + " " + classes.iconMinus} />
                  )}
                </div>
              </div>
              {!collapseState[container.key] && (
                <div className={classes.blocks}>
                  {container.blocks.map((block, blockIndex) => {
                    let ref;
                    if (block.key.match(/owner\d+$/g)) {
                      console.log("found an owner block");
                      if (blockIndex === 0) {
                        ref = ownerReactRef1;
                        console.log("ownerReactRef1");
                      } else if (blockIndex === 1) {
                        ref = ownerReactRef2;
                        console.log("ownerReactRef2");
                      } else if (blockIndex === 2) {
                        ref = ownerReactRef3;
                        console.log("ownerReactRef3");
                      } else if (blockIndex === 3) {
                        ref = ownerReactRef4;
                        console.log("ownerReactRef4");
                      } else if (blockIndex === 4) {
                        ref = ownerReactRef5;
                        console.log("ownerReactRef5");
                      } else if (blockIndex === 5) {
                        ref = ownerReactRef6;
                        console.log("ownerReactRef6");
                      }
                      ownerReactRefs.push(ref);
                    }

                    const blocksLeft = [];
                    const blocksRight = [];
                    block.fields.forEach((field, fieldIndex) => {
                      const dr = detailRow(field, fieldIndex);
                      if (dr) {
                        if (field.position && field.position === "right") {
                          blocksRight.push(detailRow(field, fieldIndex));
                        } else {
                          blocksLeft.push(detailRow(field, fieldIndex));
                        }
                      }
                    });

                    if (blocksLeft.length === 0 && blocksRight.length === 0) {
                      return;
                    }

                    if (
                      ((block.fields.find(f => f.position && f.position === "right") &&
                        softPullInDealerPortal === "ON_LEGACY_CRM") ||
                        softPullInDealerPortal === "CBC_EQUIFAX_SOFT_SUMMARY_ONLY" ||
                        softPullInDealerPortal === "CBC_EQUIFAX_SOFT_FULL_REPORT") &&
                      !editMode
                    ) {
                      return (
                        block.type === "block" && (
                          <div key={`blockContainer-${blockIndex}`} style={{ display: "flex", flexDirection: "row" }}>
                            <div key={`block-${blockIndex}`} ref={ref}>
                              <h3>{block.title}</h3>
                              <div className={block.className ? block.className : ""}>{blocksLeft}</div>
                            </div>
                            {blocksRight.length > 0 && (
                              <div key={`rightBlock-${blockIndex}`}>
                                <h3>Credit Bureau Report Information</h3>
                                <div className={block.className ? block.className : ""}>{blocksRight}</div>
                              </div>
                            )}
                          </div>
                        )
                      );
                    } else {
                      return (
                        block.type === "block" && (
                          <div key={`block-${blockIndex}`} ref={ref}>
                            <h3>{block.title}</h3>
                            <div className={block.className ? block.className : ""}>{blocksLeft}</div>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              )}
            </div>
          );
        })}
        {!!editMode && (
          <div className={classes.detailsRow}>
            <div className={classes.detailsCell + " " + classes.detailsLeftCell}>
              <Button onClick={() => setEditMode(false)}>Cancel</Button>
            </div>
            <div className={classes.detailsCell + " " + classes.detailsRightCell}>
              <Button color="success" onClick={handleConfirm}>
                Confirm Change
              </Button>
            </div>
            <CreditAppModifierConfirmModal open={confirmModal} close={handleCloseModal} handleSubmit={handleSubmit} />
          </div>
        )}
      </Tile>
    </div>
  );
}
