export const SET_CREDIT_APP = "SET CREDIT APP";
export const SET_VO = "SET VO";
export const SET_VP = "SET VP";
export const SET_ACCOUNT = "SET ACCOUNT";
export const SET_COMPANY_PROFILE = "SET COMPANY PROFILE";
export const SET_ACCOUNT_REP = "SET ACCOUNT REP";
export const SET_USER_PROFILE = "SET USER PROFILE";
export const SET_RELATED_USERS = "SET RELATED USERS";
// export const SET_REFETCH_PAST_SUBMISSIONS = 'CREDIT SUBMISSIONS: SET REFETCH PAST SUBMISSIONS';

export const SET_REFETCH_ACCOUNT = "SET REFETCH ACCOUNT";
export const SET_REFETCH_ACCOUNT_REP = "SET REFETCH ACCOUNT REP";
export const SET_REFETCH_USER_PROFILE = "SET REFETCH USER PROFILE";
export const SET_REFETCH_RELATED_USERS = "SET REFETCH RELATED USERS";
export const SET_REFETCH_COMPANY_PROFILE = "SET REFETCH COMPANY PROFILE";
export const SET_REFETCH_VO = "SET REFETCH VO";
export const SET_REFETCH_VP = "SET REFETCH VP";
export const SET_REFETCH_CREDIT_APP = "SET REFETCH CREDIT APP";
export const SET_REFETCH_PAST_SUBMISSIONS = "SET REFETCH PAST SUBMISSIONS";
export const SET_REFETCH_FEED = "SET REFETCH FEED";
export const SET_REFETCH_CREDIT_SUBMISSIONS = "SET REFETCH CREDIT SUBMISSIONS";

export const SET_LOADING_ACCOUNT = "SET LOADING ACCOUNT";
export const SET_LOADING_ACCOUNT_REP = "SET LOADING ACCOUNT REP";
export const SET_LOADING_USER_PROFILE = "SET LOADING USER PROFILE";
export const SET_LOADING_RELATED_USERS = "SET LOADING RELATED USERS";
export const SET_LOADING_COMPANY_PROFILE = "SET LOADING COMPANY PROFILE";
export const SET_LOADING_VO = "SET LOADING VO";
export const SET_LOADING_VP = "SET LOADING VP";
export const SET_LOADING_CREDIT_APP = "SET LOADING CREDIT APP";
export const SET_LOADING_PAST_SUBMISSIONS = "SET LOADING PAST SUBMISSIONS";
export const SET_CHAT_UNREAD_MESSAGES = "SET CHAT UNREAD MESSAGES COUNTER";
export const SET_CHAT_TARGET_RECIPIENT = "SET CHAT TARGET RECIPIENT USER PROFILE";
export const SET_CHAT_TARGET_CS = "SET CHAT TARGET CREDIT SUBMISSION";
export const SET_CHAT_OPENED = "SET CHAT OPENED";
export const SET_LP = "SET LENDER PROFILES";
export const SET_CHAT_RESET = "CHAT STATE RESET";
export const SET_CHAT_TARGET_TOPIC = "SET CHAT TARGET TOPIC";
export const SET_CHAT_TARGET_VOID = "SET CHAT TARGET VOID";
export const SET_CREDIT_SUBMISSIONS = "SET CREDIT SUBMISSIONS";
export const SET_LOADING_CREDIT_SUBMISSIONS = "SET LOADING CREDIT SUBMISSIONS";
export const SETUP_WIZARD = "SETUP WIZARD";
export const SET_CHAT_CONVERSATIONS = "SET CHAT CONVERSATIONS";
export const START_CREATING_CONVERSATION = "START CREATING CONVERSATION";
export const CONVERSATION_CREATED = "CONVERSATION CREATED";

export const SETUP_FAST_TRACK_MODAL = "SETUP FAST TRACK MODAL";

// chat v2
export const CHAT_V2_OPEN = "CHAT V2 OPEN";
export const CHAT_V2_RELATED_TASK_ID = "CHAT V2 RELATED TASK ID";
export const CHAT_V2_SELECTED_CONVERSATION_SID = "CHAT V2 SELECTED CONVERSATION SID";
export const CHAT_V2_CONVERSATIONS = "CHAT V2 CONVERSATIONS";
export const CHAT_V2_TASKS = "CHAT V2 TASKS";
export const CHAT_V2_FETCHED_CONVERSATION = "CHAT V2 FETCHED CONVERSATION";
export const CHAT_V2_RESET = "CHAT V2 RESET";
export const CHAT_V2_SET_CREDIT_APP = "CHAT V2 SET CREDIT APP";
export const CHAT_V2_RESET_CONVERSATIONS = "CHAT V2 RESET CONVERSATIONS";
export const CHAT_V2_REMOVE_PARTICIPANT = "CHAT V2 REMOVE PARTICIPANT";
export const CHAT_V2_ADDED_PARTICIPANT = "CHAT V2 ADDED PARTICIPANT";
export const CHAT_V2_SET_TARGET_RECIPIENT = "CHAT V2 SET TARGET RECIPIENT";
export const CHAT_V2_SET_RECIPIENTS_LIST = "CHAT V2 SET RECIPIENTS LIST";

// Migrated from LP
export const SET_CS = "SET CREDIT SUBMISSION";
export const SET_CS_REFETCH = "SET CS REFETCH";
export const SET_DOCS_REFETCH = "SET DOCS REFETCH";
export const SET_LENDER_PROFILE = "SET_LENDER_PROFILE";
