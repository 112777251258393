import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import { ChromePicker } from "react-color";
import _ from "lodash";

const CREATE_PROPOSAL_MENU_OPTION = gql`
  mutation($input: CreateProposalMenuOptionInput) {
    createProposalMenuOption(input: $input) {
      proposalMenuOptionId
    }
  }
`;

const UPDATE_PROPOSAL_MENU_OPTION = gql`
  mutation($input: UpdateProposalMenuOptionInput) {
    updateProposalMenuOption(input: $input) {
      proposalMenuOptionId
    }
  }
`;

const styles = {
  colorSchemaPopover: {
    position: "absolute",
    zIndex: "2",
  },
  colorSchemaCover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  colorSchemaCurrentColorContainer: {
    width: "25px",
    height: "20px",
    marginLeft: "10px",
  },
  colorSchemaButton: {
    padding: "6px 8px",
    marginRight: "10px",
  },
};
const useStyles = makeStyles(styles);

const CreateEditProposalMenuOptionModal = ({
  isOpen,
  setIsOpen,
  proposalMenu,
  proposalMenuOption,
  setProposalMenuOption,
  refetchProposalMenu,
}) => {
  const classes = useStyles();

  const isEditing = proposalMenuOption?.proposalMenuOptionId;

  const [createProposalMenuOption, { loading: createLoading }] = useMutation(CREATE_PROPOSAL_MENU_OPTION, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const [updateProposalMenuOption, { loading: updateLoading }] = useMutation(UPDATE_PROPOSAL_MENU_OPTION, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [currentColorKey, setCurrentColorKey] = useState(null);

  const onMutationCompleted = () => {
    handleClose();
    refetchProposalMenu();
  };

  const handleColorClick = key => {
    setIsColorPickerVisible(prevState => !prevState);
    setCurrentColorKey(key);
  };

  const handleColorPickerChange = selectedColor => {
    setProposalMenuOption({ ...proposalMenuOption, [currentColorKey]: selectedColor.hex });
  };

  const handleColorPickerClose = () => {
    setIsColorPickerVisible(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    const maxOrdinal = _.get(_.maxBy(proposalMenu.menuOptions, "ordinal"), "ordinal");
    isEditing
      ? updateProposalMenuOption({
          variables: {
            input: {
              proposalMenuOptionId: proposalMenuOption.proposalMenuOptionId,
              name: proposalMenuOption.name,
              description: proposalMenuOption.description,
              titleColor: proposalMenuOption.titleColor,
              titleBgColor: proposalMenuOption.titleBgColor,
            },
          },
        })
      : createProposalMenuOption({
          variables: {
            input: {
              ...proposalMenuOption,
              proposalMenuLinkingCriteria: [
                {
                  proposalMenuId: proposalMenu.proposalMenuId,
                  ordinal: maxOrdinal || maxOrdinal === 0 ? maxOrdinal + 1 : 0,
                },
              ],
            },
          },
        });
  };

  if (!proposalMenuOption) {
    return null;
  }

  const loading = createLoading || updateLoading;
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <DialogTitle id="form-dialog-title">{isEditing ? "Edit Menu Option" : "Create Menu Option"}</DialogTitle>
      {!loading && (
        <>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Name"
                  fullWidth
                  size="small"
                  value={proposalMenuOption.name}
                  onChange={e => setProposalMenuOption({ ...proposalMenuOption, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  fullWidth
                  size="small"
                  value={proposalMenuOption.description}
                  onChange={e => setProposalMenuOption({ ...proposalMenuOption, description: e.target.value })}
                />
              </Grid>
              <Grid item container xs={12}>
                <Button
                  className={classes.colorSchemaButton}
                  onClick={() => handleColorClick("titleColor")}
                  variant="outlined"
                >
                  Title Color
                  <div
                    className={classes.colorSchemaCurrentColorContainer}
                    style={{ backgroundColor: `${proposalMenuOption.titleColor}` }}
                  ></div>
                </Button>
                <Button
                  className={classes.colorSchemaButton}
                  onClick={() => handleColorClick("titleBgColor")}
                  variant="outlined"
                >
                  Title Background Color
                  <div
                    className={classes.colorSchemaCurrentColorContainer}
                    style={{ backgroundColor: `${proposalMenuOption.titleBgColor}` }}
                  ></div>
                </Button>
              </Grid>
              {isColorPickerVisible && (
                <div className={classes.colorSchemaPopover}>
                  <div className={classes.colorSchemaCover} onClick={handleColorPickerClose} />
                  <ChromePicker color={proposalMenuOption[currentColorKey]} onChange={handleColorPickerChange} />
                </div>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} color="primary" disabled={!proposalMenuOption.name}>
              Save
            </Button>
          </DialogActions>
        </>
      )}
      {loading && <LinearProgress style={{ margin: 10 }} />}
    </Dialog>
  );
};

export default CreateEditProposalMenuOptionModal;
