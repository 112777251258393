import React, { useEffect, useState } from "react";
import { FormControl, Grid, TextField } from "@material-ui/core";
import builderItems from "../../config/fields_sectionized.json";
import { DraggableControl } from "./DraggableControll";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getEquipmentDetailsBlockList, getReferencesBlockList } from "../../../../services/dynamicOCAService";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  blockList: {
    padding: "30px 15px",
    overflowY: "scroll",
    overflowX: "hidden",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  blockListHeader: {
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Roboto",
    textAlign: "center",
  },
  showBlocks: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  accordion: {
    "&.Mui-expanded": {
      margin: "0",
    },
    "&:before": {
      display: "none",
    },
  },
  accordionSummary: {
    padding: "0",
    "& .MuiAccordionSummary-content": {
      margin: "0",
    },
    "&.MuiAccordionSummary-root": {
      minHeight: "auto",
    },
  },
  accordionDetails: {
    flexDirection: "column",
    padding: "0",
  },
}));

export const BlockList = ({ selectNewElement }) => {
  const classes = useStyles();
  const referencesBlockList = getReferencesBlockList();
  const equipmentDetailsBlockList = getEquipmentDetailsBlockList();
  const allBuilderItems = _.concat(builderItems, referencesBlockList, equipmentDetailsBlockList);
  const [allFieldsAvailable, setAllFieldsAvailable] = useState(allBuilderItems);
  const [targetName, setTargetName] = useState("");
  const [accordionExpandedState, setAccordionExpandedState] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const searchableFields = ["config.fieldName", "config.displayLabel.en", "type"];

  const getBuilderItemsToSet = () => {
    return targetName ? _.map(allBuilderItems, getBuilderItemWithFilteredFieldList) : allBuilderItems;
  };

  const getBuilderItemWithFilteredFieldList = builderItem => {
    const filterdFieldList = _.filter(builderItem.fieldList, field => {
      return _.some(searchableFields, searchableField => {
        const searchableFieldValue = _.get(field, searchableField, "");
        return _.includes(_.toLower(searchableFieldValue), _.toLower(targetName));
      });
    });
    return { ...builderItem, fieldList: filterdFieldList };
  };

  useEffect(() => {
    const builderItemsToSet = getBuilderItemsToSet();
    setAllFieldsAvailable(builderItemsToSet);
    if (_.some(targetName)) {
      const titlesToExpand = _(builderItemsToSet)
        .filter(builderItem => _.some(builderItem.fieldList))
        .map("title")
        .value();
      setAccordionExpandedState(titlesToExpand);
    }
  }, [targetName]);

  useEffect(() => {
    setIsAllExpanded(accordionExpandedState.length === allFieldsAvailable.length);
  }, [accordionExpandedState]);

  return (
    <div className={classes.blockList}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12}>
          <TextField
            value={targetName}
            variant="outlined"
            label={`Search`}
            fullWidth
            onChange={e => {
              return setTargetName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Button
              onClick={() => {
                isAllExpanded
                  ? setAccordionExpandedState([])
                  : setAccordionExpandedState(_.map(allFieldsAvailable, "title"));
              }}
              color="primary"
            >
              {isAllExpanded ? <span>Collapse All</span> : <span>Expand All</span>}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      {allFieldsAvailable.map((block, index) => {
        const isExpanded = _.includes(accordionExpandedState, block.title);
        return (
          <Accordion
            key={block.title}
            onChange={() => {
              isExpanded
                ? setAccordionExpandedState(accordionExpandedState.filter(blockTitle => blockTitle !== block.title))
                : setAccordionExpandedState([...accordionExpandedState, block.title]);
            }}
            expanded={isExpanded}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${block.title}-content`}
              id={`${block.title}-header`}
              className={classes.accordionSummary}
            >
              <h4 className={classes.blockListHeader}>{block.title}</h4>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {block.fieldList.map((field, i) => {
                return (
                  <DraggableControl
                    selectNewElement={selectNewElement}
                    item={field}
                    key={`${block.title}.draggable.${i}.${_.get(field, "config.fieldName", Math.random())}`}
                    blockName={block.title}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
