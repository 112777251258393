export const countriesList = [
  {
    text: "United States",
    value: "US",
  },
  {
    text: "Canada",
    value: "CA",
  },
  {
    text: "Mexico",
    value: "MX",
  },
  {
    text: "Andorra",
    value: "AD",
  },
  {
    text: "United Arab Emirates",
    value: "AE",
  },
  {
    text: "Afghanistan",
    value: "AF",
  },
  {
    text: "Antigua and Barbuda",
    value: "AG",
  },
  {
    text: "Anguilla",
    value: "AI",
  },
  {
    text: "Albania",
    value: "AL",
  },
  {
    text: "Armenia",
    value: "AM",
  },
  {
    text: "Angola",
    value: "AO",
  },
  {
    text: "Antarctica",
    value: "AQ",
  },
  {
    text: "Argentina",
    value: "AR",
  },
  {
    text: "American Samoa",
    value: "AS",
  },
  {
    text: "Austria",
    value: "AT",
  },
  {
    text: "Australia",
    value: "AU",
  },
  {
    text: "Aruba",
    value: "AW",
  },
  {
    text: "Alland Islands",
    value: "AX",
  },
  {
    text: "Azerbaijan",
    value: "AZ",
  },
  {
    text: "Bosnia and Herzegovina",
    value: "BA",
  },
  {
    text: "Barbados",
    value: "BB",
  },
  {
    text: "Bangladesh",
    value: "BD",
  },
  {
    text: "Belgium",
    value: "BE",
  },
  {
    text: "Burkina Faso",
    value: "BF",
  },
  {
    text: "Bulgaria",
    value: "BG",
  },
  {
    text: "Bahrain",
    value: "BH",
  },
  {
    text: "Burundi",
    value: "BI",
  },
  {
    text: "Benin",
    value: "BJ",
  },
  {
    text: "Saint Barthelemy",
    value: "BL",
  },
  {
    text: "Bermuda",
    value: "BM",
  },
  {
    text: "Brunei Darussalam",
    value: "BN",
  },
  {
    text: "Bolivia",
    value: "BO",
  },
  {
    text: "Brazil",
    value: "BR",
  },
  {
    text: "Bahamas",
    value: "BS",
  },
  {
    text: "Bhutan",
    value: "BT",
  },
  {
    text: "Bouvet Island",
    value: "BV",
  },
  {
    text: "Botswana",
    value: "BW",
  },
  {
    text: "Belarus",
    value: "BY",
  },
  {
    text: "Belize",
    value: "BZ",
  },
  {
    text: "Cocos (Keeling) Islands",
    value: "CC",
  },
  {
    text: "Congo, Democratic Republic of the",
    value: "CD",
  },
  {
    text: "Central African Republic",
    value: "CF",
  },
  {
    text: "Congo, Republic of the",
    value: "CG",
  },
  {
    text: "Switzerland",
    value: "CH",
  },
  {
    text: "Cote d'Ivoire",
    value: "CI",
  },
  {
    text: "Cook Islands",
    value: "CK",
  },
  {
    text: "Chile",
    value: "CL",
  },
  {
    text: "Cameroon",
    value: "CM",
  },
  {
    text: "China",
    value: "CN",
  },
  {
    text: "Colombia",
    value: "CO",
  },
  {
    text: "Costa Rica",
    value: "CR",
  },
  {
    text: "Cuba",
    value: "CU",
  },
  {
    text: "Cape Verde",
    value: "CV",
  },
  {
    text: "Curacao",
    value: "CW",
  },
  {
    text: "Christmas Island",
    value: "CX",
  },
  {
    text: "Cyprus",
    value: "CY",
  },
  {
    text: "Czech Republic",
    value: "CZ",
  },
  {
    text: "Germany",
    value: "DE",
  },
  {
    text: "Djibouti",
    value: "DJ",
  },
  {
    text: "Denmark",
    value: "DK",
  },
  {
    text: "Dominica",
    value: "DM",
  },
  {
    text: "Dominican Republic",
    value: "DO",
  },
  {
    text: "Algeria",
    value: "DZ",
  },
  {
    text: "Ecuador",
    value: "EC",
  },
  {
    text: "Estonia",
    value: "EE",
  },
  {
    text: "Egypt",
    value: "EG",
  },
  {
    text: "Western Sahara",
    value: "EH",
  },
  {
    text: "Eritrea",
    value: "ER",
  },
  {
    text: "Spain",
    value: "ES",
  },
  {
    text: "Ethiopia",
    value: "ET",
  },
  {
    text: "Finland",
    value: "FI",
  },
  {
    text: "Fiji",
    value: "FJ",
  },
  {
    text: "Falkland Islands (Malvinas)",
    value: "FK",
  },
  {
    text: "Micronesia, Federated States of",
    value: "FM",
  },
  {
    text: "Faroe Islands",
    value: "FO",
  },
  {
    text: "France",
    value: "FR",
  },
  {
    text: "Gabon",
    value: "GA",
  },
  {
    text: "United Kingdom",
    value: "GB",
  },
  {
    text: "Grenada",
    value: "GD",
  },
  {
    text: "Georgia",
    value: "GE",
  },
  {
    text: "French Guiana",
    value: "GF",
  },
  {
    text: "Guernsey",
    value: "GG",
  },
  {
    text: "Ghana",
    value: "GH",
  },
  {
    text: "Gibraltar",
    value: "GI",
  },
  {
    text: "Greenland",
    value: "GL",
  },
  {
    text: "Gambia",
    value: "GM",
  },
  {
    text: "Guinea",
    value: "GN",
  },
  {
    text: "Guadeloupe",
    value: "GP",
  },
  {
    text: "Equatorial Guinea",
    value: "GQ",
  },
  {
    text: "Greece",
    value: "GR",
  },
  {
    text: "South Georgia and the South Sandwich Islands",
    value: "GS",
  },
  {
    text: "Guatemala",
    value: "GT",
  },
  {
    text: "Guam",
    value: "GU",
  },
  {
    text: "Guinea-Bissau",
    value: "GW",
  },
  {
    text: "Guyana",
    value: "GY",
  },
  {
    text: "Hong Kong",
    value: "HK",
  },
  {
    text: "Heard Island and McDonald Islands",
    value: "HM",
  },
  {
    text: "Honduras",
    value: "HN",
  },
  {
    text: "Croatia",
    value: "HR",
  },
  {
    text: "Haiti",
    value: "HT",
  },
  {
    text: "Hungary",
    value: "HU",
  },
  {
    text: "Indonesia",
    value: "ID",
  },
  {
    text: "Ireland",
    value: "IE",
  },
  {
    text: "Israel",
    value: "IL",
  },
  {
    text: "Isle of Man",
    value: "IM",
  },
  {
    text: "India",
    value: "IN",
  },
  {
    text: "British Indian Ocean Territory",
    value: "IO",
  },
  {
    text: "Iraq",
    value: "IQ",
  },
  {
    text: "Iran, Islamic Republic of",
    value: "IR",
  },
  {
    text: "Iceland",
    value: "IS",
  },
  {
    text: "Italy",
    value: "IT",
  },
  {
    text: "Jersey",
    value: "JE",
  },
  {
    text: "Jamaica",
    value: "JM",
  },
  {
    text: "Jordan",
    value: "JO",
  },
  {
    text: "Japan",
    value: "JP",
  },
  {
    text: "Kenya",
    value: "KE",
  },
  {
    text: "Kyrgyzstan",
    value: "KG",
  },
  {
    text: "Cambodia",
    value: "KH",
  },
  {
    text: "Kiribati",
    value: "KI",
  },
  {
    text: "Comoros",
    value: "KM",
  },
  {
    text: "Saint Kitts and Nevis",
    value: "KN",
  },
  {
    text: "Korea, Democratic People's Republic of",
    value: "KP",
  },
  {
    text: "Korea, Republic of",
    value: "KR",
  },
  {
    text: "Kuwait",
    value: "KW",
  },
  {
    text: "Cayman Islands",
    value: "KY",
  },
  {
    text: "Kazakhstan",
    value: "KZ",
  },
  {
    text: "Lao People's Democratic Republic",
    value: "LA",
  },
  {
    text: "Lebanon",
    value: "LB",
  },
  {
    text: "Saint Lucia",
    value: "LC",
  },
  {
    text: "Liechtenstein",
    value: "LI",
  },
  {
    text: "Sri Lanka",
    value: "LK",
  },
  {
    text: "Liberia",
    value: "LR",
  },
  {
    text: "Lesotho",
    value: "LS",
  },
  {
    text: "Lithuania",
    value: "LT",
  },
  {
    text: "Luxembourg",
    value: "LU",
  },
  {
    text: "Latvia",
    value: "LV",
  },
  {
    text: "Libya",
    value: "LY",
  },
  {
    text: "Morocco",
    value: "MA",
  },
  {
    text: "Monaco",
    value: "MC",
  },
  {
    text: "Moldova, Republic of",
    value: "MD",
  },
  {
    text: "Montenegro",
    value: "ME",
  },
  {
    text: "Saint Martin (French part)",
    value: "MF",
  },
  {
    text: "Madagascar",
    value: "MG",
  },
  {
    text: "Marshall Islands",
    value: "MH",
  },
  {
    text: "Macedonia, the Former Yugoslav Republic of",
    value: "MK",
  },
  {
    text: "Mali",
    value: "ML",
  },
  {
    text: "Myanmar",
    value: "MM",
  },
  {
    text: "Mongolia",
    value: "MN",
  },
  {
    text: "Macao",
    value: "MO",
  },
  {
    text: "Northern Mariana Islands",
    value: "MP",
  },
  {
    text: "Martinique",
    value: "MQ",
  },
  {
    text: "Mauritania",
    value: "MR",
  },
  {
    text: "Montserrat",
    value: "MS",
  },
  {
    text: "Malta",
    value: "MT",
  },
  {
    text: "Mauritius",
    value: "MU",
  },
  {
    text: "Maldives",
    value: "MV",
  },
  {
    text: "Malawi",
    value: "MW",
  },
  {
    text: "Malaysia",
    value: "MY",
  },
  {
    text: "Mozambique",
    value: "MZ",
  },
  {
    text: "Namibia",
    value: "NA",
  },
  {
    text: "New Caledonia",
    value: "NC",
  },
  {
    text: "Niger",
    value: "NE",
  },
  {
    text: "Norfolk Island",
    value: "NF",
  },
  {
    text: "Nigeria",
    value: "NG",
  },
  {
    text: "Nicaragua",
    value: "NI",
  },
  {
    text: "Netherlands",
    value: "NL",
  },
  {
    text: "Norway",
    value: "NO",
  },
  {
    text: "Nepal",
    value: "NP",
  },
  {
    text: "Nauru",
    value: "NR",
  },
  {
    text: "Niue",
    value: "NU",
  },
  {
    text: "New Zealand",
    value: "NZ",
  },
  {
    text: "Oman",
    value: "OM",
  },
  {
    text: "Panama",
    value: "PA",
  },
  {
    text: "Peru",
    value: "PE",
  },
  {
    text: "French Polynesia",
    value: "PF",
  },
  {
    text: "Papua New Guinea",
    value: "PG",
  },
  {
    text: "Philippines",
    value: "PH",
  },
  {
    text: "Pakistan",
    value: "PK",
  },
  {
    text: "Poland",
    value: "PL",
  },
  {
    text: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    text: "Pitcairn",
    value: "PN",
  },
  {
    text: "Puerto Rico",
    value: "PR",
  },
  {
    text: "Palestine, State of",
    value: "PS",
  },
  {
    text: "Portugal",
    value: "PT",
  },
  {
    text: "Palau",
    value: "PW",
  },
  {
    text: "Paraguay",
    value: "PY",
  },
  {
    text: "Qatar",
    value: "QA",
  },
  {
    text: "Reunion",
    value: "RE",
  },
  {
    text: "Romania",
    value: "RO",
  },
  {
    text: "Serbia",
    value: "RS",
  },
  {
    text: "Russian Federation",
    value: "RU",
  },
  {
    text: "Rwanda",
    value: "RW",
  },
  {
    text: "Saudi Arabia",
    value: "SA",
  },
  {
    text: "Solomon Islands",
    value: "SB",
  },
  {
    text: "Seychelles",
    value: "SC",
  },
  {
    text: "Sudan",
    value: "SD",
  },
  {
    text: "Sweden",
    value: "SE",
  },
  {
    text: "Singapore",
    value: "SG",
  },
  {
    text: "Saint Helena",
    value: "SH",
  },
  {
    text: "Slovenia",
    value: "SI",
  },
  {
    text: "Svalbard and Jan Mayen",
    value: "SJ",
  },
  {
    text: "Slovakia",
    value: "SK",
  },
  {
    text: "Sierra Leone",
    value: "SL",
  },
  {
    text: "San Marino",
    value: "SM",
  },
  {
    text: "Senegal",
    value: "SN",
  },
  {
    text: "Somalia",
    value: "SO",
  },
  {
    text: "Suriname",
    value: "SR",
  },
  {
    text: "South Sudan",
    value: "SS",
  },
  {
    text: "Sao Tome and Principe",
    value: "ST",
  },
  {
    text: "El Salvador",
    value: "SV",
  },
  {
    text: "Sint Maarten (Dutch part)",
    value: "SX",
  },
  {
    text: "Syrian Arab Republic",
    value: "SY",
  },
  {
    text: "Swaziland",
    value: "SZ",
  },
  {
    text: "Turks and Caicos Islands",
    value: "TC",
  },
  {
    text: "Chad",
    value: "TD",
  },
  {
    text: "French Southern Territories",
    value: "TF",
  },
  {
    text: "Togo",
    value: "TG",
  },
  {
    text: "Thailand",
    value: "TH",
  },
  {
    text: "Tajikistan",
    value: "TJ",
  },
  {
    text: "Tokelau",
    value: "TK",
  },
  {
    text: "Timor-Leste",
    value: "TL",
  },
  {
    text: "Turkmenistan",
    value: "TM",
  },
  {
    text: "Tunisia",
    value: "TN",
  },
  {
    text: "Tonga",
    value: "TO",
  },
  {
    text: "Turkey",
    value: "TR",
  },
  {
    text: "Trinidad and Tobago",
    value: "TT",
  },
  {
    text: "Tuvalu",
    value: "TV",
  },
  {
    text: "Taiwan, Province of China",
    value: "TW",
  },
  {
    text: "United Republic of Tanzania",
    value: "TZ",
  },
  {
    text: "Ukraine",
    value: "UA",
  },
  {
    text: "Uganda",
    value: "UG",
  },
  {
    text: "Uruguay",
    value: "UY",
  },
  {
    text: "Uzbekistan",
    value: "UZ",
  },
  {
    text: "Holy See (Vatican City State)",
    value: "VA",
  },
  {
    text: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  {
    text: "Venezuela",
    value: "VE",
  },
  {
    text: "British Virgin Islands",
    value: "VG",
  },
  {
    text: "US Virgin Islands",
    value: "VI",
  },
  {
    text: "Vietnam",
    value: "VN",
  },
  {
    text: "Vanuatu",
    value: "VU",
  },
  {
    text: "Wallis and Futuna",
    value: "WF",
  },
  {
    text: "Samoa",
    value: "WS",
  },
  {
    text: "Kosovo",
    value: "XK",
  },
  {
    text: "Yemen",
    value: "YE",
  },
  {
    text: "Mayotte",
    value: "YT",
  },
  {
    text: "South Africa",
    value: "ZA",
  },
  {
    text: "Zambia",
    value: "ZM",
  },
  {
    text: "Zimbabwe",
    value: "ZW",
  },
];

export const monthsList = [
  {
    text: "January",
    value: "01",
  },
  {
    text: "February",
    value: "02",
  },
  {
    text: "March",
    value: "03",
  },
  {
    text: "April",
    value: "04",
  },
  {
    text: "May",
    value: "05",
  },
  {
    text: "June",
    value: "06",
  },
  {
    text: "July",
    value: "07",
  },
  {
    text: "August",
    value: "08",
  },
  {
    text: "September",
    value: "09",
  },
  {
    text: "October",
    value: "10",
  },
  {
    text: "November",
    value: "11",
  },
  {
    text: "December",
    value: "12",
  },
];
