import gql from "graphql-tag";

const UPDATE_DYNAMICS_VO_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const UPDATE_VO_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const UPDATE_DYNAMICS_VO_STAGE_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const UPDATE_VO_STAGE_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!, $emailInput: UpdateVendorOpportunityEmailInput) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId, emailInput: $emailInput)
  }
`;

export {
  UPDATE_DYNAMICS_VO_STATUS_MUTATION,
  UPDATE_VO_STATUS_MUTATION,
  UPDATE_DYNAMICS_VO_STAGE_MUTATION,
  UPDATE_VO_STAGE_MUTATION,
};
