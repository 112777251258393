import { Button, makeStyles } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { CHAT_V2_OPEN, CHAT_V2_SELECTED_CONVERSATION_SID } from "../../redux/types";
import { format } from "date-fns";
import mixpanel from "../../mixpanel";

const useStyles = makeStyles({
  mainContainer: {
    padding: "20px 0px",
    borderBottom: "1px solid #9E9E9E",
  },
  lastConversationMainContainer: {
    padding: "20px 0px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  columnHeader: {
    fontWeight: "700",
    color: "#000",
    fontSize: "12px",
  },
  columnBody: {
    display: "flex",
    flexWrap: "wrap",
    color: "#000",
    marginTop: "10px",
    fontSize: "12px",
  },
  conversationColumn: {
    alignItems: "flex-end",
    justifyContent: "center",
  },
  conversationText: {
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "300",
    color: "#0A84FC",
    cursor: "pointer",
  },
});

function Conversation({ conversation, isLastConversationInList }) {
  const classes = useStyles();
  const messages = conversation.messages;
  const participants = conversation.participants;
  const date = conversation.createdAt;
  const dispatch = useDispatch();

  const handleOpenChatOnSubject = () => {
    console.log("CONVERSATIONS VIEW CONVERSATION CLICKED EVENT FIRED");
    mixpanel.track("CONVERSATIONS: VIEW CONVERSATION CLICKED");
    dispatch({
      type: CHAT_V2_SELECTED_CONVERSATION_SID,
      payload: conversation.sid,
    });

    dispatch({
      type: CHAT_V2_OPEN,
      payload: true,
    });
  };

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={isLastConversationInList ? classes.lastConversationMainContainer : classes.mainContainer}
    >
      <Grid container item xs={2} sm={2} md={2} lg={2} className={classes.column}>
        <h2 className={classes.columnHeader}>Subject(s)</h2>
        <div className={classes.columnBody}>{conversation.topic}</div>
      </Grid>
      <Grid container item xs={2} sm={2} md={2} lg={2} className={classes.column}>
        <h2 className={classes.columnHeader}>Related Task</h2>
        <div className={classes.columnBody}>
          {conversation.task && conversation.task.regarding ? conversation.task.regarding : "N/A"}
        </div>
      </Grid>
      <Grid container item xs={2} sm={2} md={2} lg={2} className={classes.column}>
        <h2 className={classes.columnHeader}>Date</h2>
        <div className={classes.columnBody}>{date ? format(new Date(date), "MM/dd/yyyy") : "N/A"}</div>
      </Grid>
      <Grid container item xs={2} sm={2} md={2} lg={2} className={classes.column}>
        <h2 className={classes.columnHeader}>Recipient(s)</h2>
        <div className={classes.columnBody}>
          {participants.length
            ? participants.map((participant, i) => {
                if (i < participants.length - 1) {
                  return participant.identity + " | ";
                }
                return participant.identity;
              })
            : "N/A"}
        </div>
      </Grid>
      <Grid container item xs={2} sm={2} md={2} lg={2} className={classes.column}>
        <h2 className={classes.columnHeader}>Last Message:</h2>
        <div className={classes.columnBody}>{messages.length ? messages[messages.length - 1].body : "N/A"}</div>
      </Grid>
      <Grid container item xs={2} sm={2} md={2} lg={2} className={`${classes.column} ${classes.conversationColumn}`}>
        <Button
          color="primary"
          className={classes.conversationText}
          simple
          onClick={() => {
            handleOpenChatOnSubject();
          }}
        >
          View Conversation
        </Button>
      </Grid>
    </Grid>
  );
}

export default Conversation;
