import React, { useMemo } from "react";
import clsx from "clsx";
import { useDrop } from "react-dnd";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { CardPriceBlock } from "../cardPriceBlock";
import { formatCurrency } from "../../../../../utils";
import { AftermarketProductTypes, DnDTypes, MarkupType } from "../../../types";
import { ProposalProductCard } from "./ProposalProductCard";
import { MenuOptionCardHeader } from "./MenuOptionCardHeader";
import { vendorsLogoByType } from "../../../constants";

interface Props {
  menuOption: any;
  isCalculating: boolean;
  selectedMenuOption?: any;
  onEditOption?: (currentMenuOption: any) => void;
  onRemoveOption?: (currentMenuOption: any) => void;
  onSelectMenuOption?: (optionName: string) => void;
  onRemoveProduct?: (removeIndex: number, currentMenuOption: any) => void;
  onDropProduct: (product: ProposalProduct, menuOptionName: string) => void;
}

export const MenuOptionCard = ({
  menuOption,
  isCalculating,
  onEditOption,
  onRemoveOption,
  onDropProduct,
  onRemoveProduct,
  selectedMenuOption,
  onSelectMenuOption,
}: Props) => {
  const classes = useStyles({
    cardPanelTitleColor: menuOption?.titleColor ?? "",
    cardPanelBg: menuOption?.titleBgColor ?? "",
    isSelectable: !!onSelectMenuOption,
  });

  const commonPrice = useMemo(
    () =>
      menuOption?.products.reduce(
        (acc: any, { cost, retailCost }: any) => {
          acc.cost += +cost;
          acc.markup.markup += retailCost - cost;
          acc.markup.type = MarkupType.Flat;
          acc.retailCost += +retailCost;
          return acc;
        },
        { cost: 0, markup: { markup: 0, type: null }, retailCost: 0 }
      ),
    [menuOption?.products]
  );

  const [{ isOver }, drop] = useDrop<ProposalProduct, void, { isOver: boolean }>(
    () => ({
      accept: DnDTypes.ProposalProduct,
      drop: product => onDropProduct(product, menuOption.name),
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDropProduct, menuOption]
  );

  return (
    <Box
      className={clsx(classes.card, {
        [classes.overCard]: isOver,
        [classes.selectedCard]: menuOption.name === selectedMenuOption?.name,
        [classes.selectedOverCard]: isOver && menuOption.name === selectedMenuOption?.name,
      })}
      onClick={() => {
        if (onSelectMenuOption) onSelectMenuOption(menuOption.name);
      }}
    >
      <MenuOptionCardHeader menuOption={menuOption} onEditOption={onEditOption} onRemoveOption={onRemoveOption} />

      <Box className={classes.dnd} {...({ ref: drop } as any)}>
        {!menuOption?.products.length ? (
          <Typography component="span" variant="body1" color="textSecondary">
            Drag & drop options here
          </Typography>
        ) : (
          <Box className={classes.proposalProductCards}>
            {menuOption?.products.length &&
              menuOption?.products.map((product: any, index: number) => (
                <ProposalProductCard
                  key={index}
                  product={product}
                  productIndex={index}
                  menuType={menuOption.name}
                  onRemoveProduct={onRemoveProduct && (() => onRemoveProduct(index, menuOption))}
                  logo={
                    vendorsLogoByType?.[
                      product?.aftermarketProduct?.aftermarketVendorApiChannel as AftermarketProductTypes
                    ]
                  }
                />
              ))}
          </Box>
        )}
      </Box>

      <CardPriceBlock type="withBg" isTotal {...commonPrice} />

      <Box className={classes.cardMonthPrice}>
        <Box className={clsx("monthPrice", { monthPriceCalculating: isCalculating })}>
          <Typography component="span" variant="h6">
            {formatCurrency(menuOption?.payment?.paymentAmountPerPeriod)}
          </Typography>

          <Typography component="span" variant="caption" color="textSecondary">
            per month
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { cardPanelBg: string; cardPanelTitleColor: string; isSelectable: boolean }>(
  ({ palette }) => ({
    card: {
      gap: "8px",
      padding: "4px",
      display: "flex",
      minHeight: "260px",
      minWidth: "280px",
      flexDirection: "column",
      border: "2px dashed lightgrey",
      justifyContent: "space-between",
      cursor: ({ isSelectable }) => (isSelectable ? "pointer" : "default"),
    },
    overCard: {
      border: "2px solid lightgrey",
    },
    selectedCard: {
      border: `2px dashed ${palette.primary.main}`,
    },
    selectedOverCard: {
      border: `2px solid ${palette.primary.main}`,
    },
    dnd: {
      flex: 1,
      display: "flex",
      padding: "4px 0",
      alignItems: "center",
      justifyContent: "center",
    },
    cardMonthPrice: {
      padding: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& .monthPrice": {
        gap: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& .monthPriceCalculating": {
        filter: "blur(2px)",
      },
    },
    proposalProductCards: {
      gap: "4px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  })
);
