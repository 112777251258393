import React, { useState } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  selectHeight: {
    maxHeight: "40vh",
  },
  ownerPlaceholder: {
    textDecoration: "underline",
  },
}));
const SignorAvailabilityQuestion = ({ fieldConfig }) => {
  const classes = useStyles();
  const [value, setValue] = useState("yes");
  const fieldId = uuidv4();
  return (
    <FormControl fullWidth={fieldConfig.fullWidth}>
      <InputLabel id={fieldConfig.fieldName} required={fieldConfig.required}>
        Is Owner <span className={classes.ownerPlaceholder}>OWNERNAME_PLACEHOLDER</span> Available?
      </InputLabel>
      <Select
        key={fieldId}
        fullWidth={fieldConfig.fullWidth}
        name={fieldConfig.fieldName}
        disabled={true}
        error={false}
        value={"yes"}
        labelId={fieldConfig.fieldName}
        style={{ maxHeight: "40vh" }}
        required={fieldConfig.required}
        variant="standard"
      >
        <MenuItem key={`${fieldId}.yes`} value={`yes`}>
          YES
        </MenuItem>
        <MenuItem key={`${fieldId}.no`} value={`no`}>
          NO
        </MenuItem>
      </Select>
      <FormHelperText>{_.get(fieldConfig, "displayLabel.en", "Is Owner ABCD Available?")}</FormHelperText>
    </FormControl>
  );
};

export { SignorAvailabilityQuestion };
