import gql from "graphql-tag";

export const DUPLICATE_OPPORTUNITY = gql`
  mutation($vendorOpportunityId: ID!) {
    duplicateTransaction(vendorOpportunityId: $vendorOpportunityId) {
      transactionId
      dynamicsVendorOpportunityId
      vendorOpportunityId
    }
  }
`;
