export function equipmentMileageFormatter(mileAge: number | string, option: "toView" | "toUpdate" | "toSimpleStr") {
  const formatters = {
    toView: mileAge?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "",
    toUpdate:
      mileAge
        ?.toString()
        ?.replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? "",
    toSimpleStr: mileAge?.toString()?.replace(/,/g, "") ?? "",
  };

  return formatters[option];
}
