import gql from "graphql-tag";

export const GET_EQUIPMENT = gql`
  query ($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
    }
  }
`;
