import React, { Fragment } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { ProposalProductCardModes } from "../../../types";
import { deskingActions, deskingSelectors } from "../../../model";
import { ProposalProductsCard, ProposalProductsCards } from "../index";
import { MenuTitle } from "./MenuTitle";

export const MenuCards = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const selectedMenuOption = useSelector(deskingSelectors.selectedMenuOption);
  const selectedProducts = useSelector(deskingSelectors.selectedProposalsProductsArray) as ProposalProduct[];
  const menuOptionsGroupedByName = useSelector<any, Record<string, any[]>>(deskingSelectors.menuOptionsGroupedByName);

  const updateProductConfiguration = (productId: string, menuName: string, field: string, value: any) => {
    dispatch(
      deskingActions.updateMenuProduct({
        productId,
        menuName,
        field,
        value,
      })
    );
  };

  return (
    <ProposalProductsCards>
      {selectedProducts.map(product => (
        <ProposalProductsCard
          type="simple"
          product={product}
          key={`simple-${product.proposalProductId}`}
          mode={ProposalProductCardModes.Desking}
        />
      ))}

      {Object.entries(
        selectedMenuOption ? { [selectedMenuOption.name]: selectedMenuOption } : menuOptionsGroupedByName
      ).map(([menuName, option]) => (
        <Fragment key={menuName}>
          {!!option?.products.length && (
            <Box className={classes.container}>
              <MenuTitle menuOption={option} />

              {option?.products?.map((product: ProposalProduct) => (
                <ProposalProductsCard
                  type="forEdit"
                  product={product}
                  menuName={menuName}
                  proposalProductConfiguration={product}
                  mode={ProposalProductCardModes.Desking}
                  key={`forEdit-${product.proposalProductId}`}
                  updateProductConfiguration={updateProductConfiguration}
                />
              ))}
            </Box>
          )}
        </Fragment>
      ))}
    </ProposalProductsCards>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
});
