import React from "react";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import { adminRoles } from "../../../../pages/Prequal/constants";

interface Props {
  userAdminRole: any;
  creditAppModalData: any;
}

export const OcaTemplateNameLayout = ({ userAdminRole, creditAppModalData }: Props) => {
  const templateName = _.get(creditAppModalData, "ocaTemplate.name", "");
  const adminRolesToViewOCATemplateName = [adminRoles.super, adminRoles.singleAccountOnly];

  return (
    _.includes(adminRolesToViewOCATemplateName, userAdminRole) &&
    templateName && (
      <Typography style={{ marginTop: 10 }}>
        OCA Template - {_.get(creditAppModalData, "ocaTemplate.name", "")}
      </Typography>
    )
  );
};
