import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import blue from "@material-ui/core/colors/blue";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import ContactNotificationModal from "./ContactNotificationModal";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "../LocationMgmt/LocationDetail";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import { vendorContactRole } from "utils";
import _ from "lodash";
import { theme } from "../../theme";

const useStylesPaper = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailModalInfo: {
    marginTop: "20px",
  },
  detailInfoItem: {
    marginBottom: "5px",
  },
  paper: {
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "@media (max-width: 768px)": {
      minWidth: 240,
    },
  },
  labelRoot: {
    fontWeight: 800,
    color: "black",
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  formControl: {
    minWidth: 400,
    marginBottom: "20px",
    "@media (max-width: 768px)": {
      minWidth: 240,
      width: 240,
    },
  },
  disabledMenuItem: {
    color: "black",
    fontWeight: 900,
    opacity: "1 !important",
  },
  repMenuItem: {
    paddingLeft: "35px",
  },
}));

const GET_USER_LOCATIONS = gql`
  query Locations {
    locations {
      locationId
      locationName
      category
      contactIds
    }
  }
`;

const ASSIGN_SALES_REP = gql`
  mutation($fieldsObj: AssignSalesRepInput!, $entityId: ID!, $VOId: ID!) {
    assignSalesRep(fieldsObj: $fieldsObj, entityId: $entityId, VOId: $VOId)
  }
`;

const UPDATE_DYNAMICS_VO_LOCATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const UPDATE_VO_LOCATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const UPDATE_CREDIT_APPLICATION_V2_QUERY = gql`
  mutation($creditAppId: ID!, $input: UpdateCreditApplicationV2Input!) {
    updateCreditApplicationV2(creditAppId: $creditAppId, input: $input)
  }
`;

const GET_SALES_REPS_FOR_ADMIN = gql`
  query {
    salesRepsForAdmin {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
    }
  }
`;

const financeManagersAvailableRoles = [
  vendorContactRole.credit_mgr,
  vendorContactRole.sales_mgr,
  vendorContactRole.executive,
  vendorContactRole.admin,
];

const salesGroups = {
  salesRep: "sales_rep",
  salesManager: "sales_manager",
  financeManager: "finance_manager",
};

const defaultEmailNotificationByGroup = {
  [salesGroups.salesRep]: false,
  [salesGroups.salesManager]: false,
  [salesGroups.financeManager]: false,
};

function ContactDetailModal({
  open,
  handleClose,
  vo,
  refetchVO,
  up,
  handleCloseContactDetailModal,
  setContactsTableBody,
  creditApp,
}) {
  const history = useHistory();

  const [salesManager, setSalesManager] = useState(vo.salesManager);
  const [salesRepresentative, setSalesRepresentative] = useState(vo.salesRepresentative);
  const [financeManager, setFinanceManager] = useState(vo.financeManager);
  const [currentSalesRepUserProfileId, setCurrentSalesRepUserProfileId] = useState(
    _.get(vo, "salesRepresentative.id", "")
  );
  const [currentSalesManagerUserProfileId, setCurrentSalesManagerUserProfileId] = useState(
    _.get(vo, "salesManager.id", "")
  );
  const [currentFinanceManagerUserProfileId, setCurrentFinanceManagerUserProfileId] = useState(
    _.get(vo, "financeManager.id", "")
  );
  const [emailNotificationByGroups, setEmailNotificationByGroups] = useState(defaultEmailNotificationByGroup);
  const classesPaper = useStylesPaper();
  const [selectedSalesRepObject, setSelectedSalesRepObject] = useState({
    id: _.get(vo, "salesRepresentative.id", ""),
    dynamicsContactId: _.get(vo, "salesRepresentative.dynamicsContactId", ""),
  });
  const [selectedSalesManagerObject, setSelectedSalesManagerObject] = useState({
    id: _.get(vo, "salesManager.id", ""),
    dynamicsContactId: _.get(vo, "salesManager.dynamicsContactId", ""),
  });
  const [selectedFinanceManagerObject, setSelectedFinanceManagerObject] = useState({
    id: _.get(vo, "financeManager.id", ""),
    dynamicsContactId: _.get(vo, "financeManager.dynamicsContactId", ""),
  });
  const [salesReps, setSalesReps] = useState();
  const [salesManagers, setSalesManagers] = useState();
  const [financeManagers, setFinanceManagers] = useState();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [isNeedToSendEmail, setIsNeedToSendEmail] = useState(false);
  const [currentSalesGroup, setCurrentSalesGroup] = useState(null);
  const [locationId, setLocationId] = useState(_.get(vo, "location.locationId", null));
  const [location, setLocation] = useState(_.get(vo, "location", null));
  const [waitingForEditMutationToFinish, setWaitingForEditMutationToFinish] = useState(false);

  let locations = [];

  const { data } = useQuery(GET_USER_LOCATIONS);

  if (data) {
    locations = data.locations;
  }

  const [updateSalesRep] = useMutation(ASSIGN_SALES_REP, {
    context: { authRequired: true },
  });

  const handleChangeSalesReps = (event, child) => {
    setSelectedSalesRepObject(child.props.salesrep);
    setCurrentSalesRepUserProfileId(event.target.value);
    handleOpenEmailModal(true);
    setCurrentSalesGroup(salesGroups.salesRep);
  };

  const handleChangeSalesManager = (event, child) => {
    setSelectedSalesManagerObject(child.props.salesrep);
    setCurrentSalesManagerUserProfileId(event.target.value);
    handleOpenEmailModal(true);
    setCurrentSalesGroup(salesGroups.salesManager);
  };

  const handleChangeFinanceManager = async (event, child) => {
    setSelectedFinanceManagerObject(child.props.salesrep);
    setCurrentFinanceManagerUserProfileId(event.target.value);
    handleOpenEmailModal(true);
    setCurrentSalesGroup(salesGroups.financeManager);
  };

  const [updateDynamicsVOLocation] = useMutation(UPDATE_DYNAMICS_VO_LOCATION, {
    context: { authRequired: true },
  });

  const [updateVOLocation] = useMutation(UPDATE_VO_LOCATION, {
    context: { authRequired: true },
  });

  const { data: salesRepsData } = useQuery(GET_SALES_REPS_FOR_ADMIN, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY, {
    context: { authRequired: true },
  });

  useEffect(() => {
    const salesReps = _.get(salesRepsData, "salesRepsForAdmin");
    if (!salesReps) {
      return;
    }
    const filteredSalesReps = _.filter(salesReps, { vendorContactRole: "sales_rep" });
    const filteredSalesManagers = _.filter(salesReps, { vendorContactRole: "sales_mgr" });
    const filteredFinanceManagers = _.filter(salesReps, salesRep =>
      _.includes(financeManagersAvailableRoles, salesRep.vendorContactRole)
    );

    if (salesRepresentative && !_.find(filteredSalesReps, { id: salesRepresentative.id })) {
      filteredSalesReps.push(salesRepresentative);
    }
    if (salesManager && !_.find(filteredSalesManagers, { id: salesManager.id })) {
      filteredSalesManagers.push(salesManager);
    }

    setSalesReps(filteredSalesReps);
    setSalesManagers(filteredSalesManagers);
    setFinanceManagers(filteredFinanceManagers);
  }, [salesRepsData]);

  useEffect(() => {
    if (!openEmailModal && currentSalesGroup) {
      setEmailNotificationByGroups({ ...emailNotificationByGroups, [currentSalesGroup]: isNeedToSendEmail });
    }
  }, [openEmailModal]);

  const createSaleRepMenuItems = (reps, repIdentifier) => {
    if (!reps) {
      return null;
    }

    if (!location) {
      return reps
        .sort((rep1, rep2) => {
          const rep1FullName = rep1 && rep1.fullName ? rep1.fullName : "";
          const rep2FullName = rep2 && rep2.fullName ? rep2.fullName : "";
          return rep1FullName.toLowerCase().localeCompare(rep2FullName.toLowerCase());
        })
        .map(salesRep => (
          <MenuItem
            key={salesRep.id}
            value={salesRep.id}
            salesrep={salesRep}
            classes={{ root: classesPaper.repMenuItem }}
          >{`${salesRep.fullName} - ${salesRep.email}`}</MenuItem>
        ));
    }

    let repsAtThisLocation = reps
      .filter(rep => location.contactIds && location.contactIds.includes(rep.id))
      .sort((rep1, rep2) => rep1.fullName.toLowerCase().localeCompare(rep2.fullName.toLowerCase()))
      .map(salesRep => {
        return (
          <MenuItem
            key={salesRep.id}
            value={salesRep.id}
            salesrep={salesRep}
            classes={{ root: classesPaper.repMenuItem }}
          >{`${salesRep.fullName} - ${salesRep.email}`}</MenuItem>
        );
      });
    const repsNotAtThisLocation = reps
      .filter(rep => !location.contactIds || !location.contactIds.includes(rep.id))
      .sort((rep1, rep2) => {
        if (rep1.fullName === null) {
          rep1.fullName = "";
        }
        if (rep2.fullName === null) {
          rep2.fullName = "";
        }
        return rep1.fullName.toLowerCase().localeCompare(rep2.fullName.toLowerCase());
      })
      .map(salesRep => {
        return (
          <MenuItem
            key={salesRep.id}
            value={salesRep.id}
            salesrep={salesRep}
            classes={{ root: classesPaper.repMenuItem }}
          >{`${salesRep.fullName} - ${salesRep.email}`}</MenuItem>
        );
      });

    if (repsAtThisLocation.length > 0) {
      repsAtThisLocation.push(
        <MenuItem disabled classes={{ root: classesPaper.disabledMenuItem }}>
          Other {repIdentifier}
        </MenuItem>
      );
      repsAtThisLocation = [
        <MenuItem disabled classes={{ root: classesPaper.disabledMenuItem }}>
          {repIdentifier} at this Location
        </MenuItem>,
      ].concat(repsAtThisLocation);
    }

    return repsAtThisLocation.concat(repsNotAtThisLocation);
  };

  const changeVOLocation = async value => {
    if (!vo) {
      return;
    }

    try {
      await updateDynamicsVOLocation({
        variables: {
          fieldsObj: {
            locationId: value,
          },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await updateVOLocation({
        variables: {
          fieldsObj: {
            locationId: value,
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      await updateCreditAppRequest({
        variables: {
          creditAppId: creditApp.id,
          input: { locationId: value },
        },
      });
      setLocationId(value);
    } catch (err) {
      window.NREUM.noticeError(err);
    }
  };

  const handleSubmitChangeDetails = async () => {
    setWaitingForEditMutationToFinish(true);
    if (locations) {
      await changeVOLocation(locationId);
    }
    try {
      const fieldsObj = {
        dynamicsSalesRepContactId: _.get(selectedSalesRepObject, "dynamicsContactId", null) || undefined,
        salesRepresentativeId: _.get(selectedSalesRepObject, "id", null) || undefined,

        dynamicsSalesManagerContactId: _.get(selectedSalesManagerObject, "dynamicsContactId", null) || undefined,
        salesManagerId: _.get(selectedSalesManagerObject, "id", null) || undefined,

        dynamicsFinanceManagerContactId: _.get(selectedFinanceManagerObject, "dynamicsContactId", null) || undefined,
        financeManagerId: _.get(selectedFinanceManagerObject, "id", null) || undefined,

        sendEmailTo: _(emailNotificationByGroups)
          .pickBy(_.identity)
          .keys()
          .value(),
        customerName: vo.potentialCustomer.name,
      };
      console.log("submission Obj :: ", fieldsObj, vo.vendorOpportunityId, vo.dynamicsVendorOpportunityId);

      await updateSalesRep({
        variables: {
          fieldsObj,
          VOId: vo.vendorOpportunityId,
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });

      setResponseSuccess(true);
      handleOpenMessageModal();
      setEmailNotificationByGroups(defaultEmailNotificationByGroup);
      setWaitingForEditMutationToFinish(false);

      let contactsTableBodySource = [];

      const BoldText = ({ text }) => {
        return <b>{text}</b>;
      };

      if (selectedSalesRepObject) {
        contactsTableBodySource.push([
          <BoldText text="Sales Rep" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => history.push(`/profile/${selectedSalesRepObject.dynamicsContactId}`)}
          >
            {selectedSalesRepObject.fullName}
          </span>,
        ]);
        setSalesRepresentative(selectedSalesRepObject);
      } else if (vo.salesRepresentative) {
        contactsTableBodySource.push([
          <BoldText text="Sales Rep" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => history.push(`/profile/${vo.salesRepresentative.id}`)}
          >
            {vo.salesRepresentative.fullName}
          </span>,
        ]);
      }

      if (selectedSalesManagerObject) {
        contactsTableBodySource.push([
          <BoldText text="Sales Manager" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => history.push(`/profile/${selectedSalesManagerObject.dynamicsContactId}`)}
          >
            {selectedSalesManagerObject ? selectedSalesManagerObject.fullName : null}
          </span>,
        ]);
        setSalesManager(selectedSalesManagerObject);
      } else if (vo.salesManager) {
        contactsTableBodySource.push([
          <BoldText text="Sales Manager" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => history.push(`/profile/${vo.salesManager.id}`)}
          >
            {vo.salesManager ? vo.salesManager.fullName : null}
          </span>,
        ]);
      }

      if (selectedFinanceManagerObject) {
        contactsTableBodySource.push([
          <BoldText text="Finance Manager" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => history.push(`/profile/${selectedFinanceManagerObject.dynamicsContactId}`)}
          >
            {selectedFinanceManagerObject.fullName}
          </span>,
        ]);
        setFinanceManager(selectedFinanceManagerObject);
      } else if (vo.financeManager) {
        contactsTableBodySource.push([
          <BoldText text="Finance Manager" />,
          <span
            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
            onClick={() => history.push(`/profile/${vo.salesManager.id}`)}
          >
            {vo.financeManager.fullName}
          </span>,
        ]);
      }

      setContactsTableBody(contactsTableBodySource);
      await refetchVO();

      handleCloseContactDetailModal();
    } catch (err) {
      setResponseSuccess(false);
      handleOpenMessageModal();
      setWaitingForEditMutationToFinish(false);
      console.log(err);
    }
  };

  const handleOpenMessageModal = () => {
    setOpenMessageModal(true);
  };

  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    handleCloseContactDetailModal();
  };

  const handleOpenEmailModal = () => {
    setOpenEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
  };

  const handleSetEmailOk = () => {
    setIsNeedToSendEmail(true);
    handleCloseEmailModal();
  };

  const handleSetEmailNo = () => {
    setIsNeedToSendEmail(false);
    handleCloseEmailModal();
  };

  return (
    <div style={{ width: "400px" }}>
      <Dialog onClose={handleClose} open={open}>
        <Paper className={classesPaper.paper}>
          <CardContent>
            <Typography variant="h5">Details</Typography>
            <Divider />
            <Grid container justify="flex-start" className={classesPaper.detailModalInfo}>
              {locations && locations.length > 1 && (
                <Grid item xs={12} className={classesPaper.detailInfoItem}>
                  <b>Location: </b>
                  {location && location.locationName ? (
                    location.locationName
                  ) : (
                    <span style={{ color: "red", fontWeight: "bold" }}>Missing</span>
                  )}
                </Grid>
              )}
              <Grid item xs={12} className={classesPaper.detailInfoItem}>
                <b>Sales Rep: </b>
                {salesRepresentative ? salesRepresentative.fullName : ""}
              </Grid>
              <Grid item xs={12} className={classesPaper.detailInfoItem}>
                <b>Sales Manager: </b>
                {salesManager ? salesManager.fullName : ""}
              </Grid>
            </Grid>
            {waitingForEditMutationToFinish && <LinearProgress size={34} />}

            {!waitingForEditMutationToFinish && (
              <div>
                <Grid className={classesPaper.detailModalInfo}>
                  {locations && locations.length > 1 && (
                    <Grid container>
                      <div>Change Location:</div>
                      <FormControl fullWidth className={classesPaper.formControl}>
                        <Select
                          value={locationId}
                          onChange={e => {
                            setLocationId(e.target.value);
                            if (locations) {
                              setLocation(locations.find(l => l.locationId === e.target.value));
                            }
                          }}
                        >
                          {_(locations)
                            .filter(location => location.category && location.category.toLowerCase() === "branch")
                            .sortBy("locationName")
                            .map(location => (
                              <MenuItem key={location.locationId} value={location.locationId}>
                                {location.locationName}
                              </MenuItem>
                            ))
                            .value()}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid container>
                    <div>Change Sales Rep:</div>
                    <FormControl fullWidth className={classesPaper.formControl}>
                      {salesReps ? (
                        <Select
                          variant="standard"
                          labelId="credit-sub-select"
                          id="credit-sub-select-outlined"
                          value={currentSalesRepUserProfileId}
                          onChange={handleChangeSalesReps}
                        >
                          <MenuItem aria-label="None" value="">
                            None
                          </MenuItem>
                          {createSaleRepMenuItems(
                            _.uniq(_.flatten([salesReps, salesManagers, financeManagers])),
                            "Sales Reps"
                          )}
                        </Select>
                      ) : (
                        <Select
                          variant="standard"
                          labelId="credit-sub-select"
                          id="credit-sub-select-outlined"
                          value={currentSalesRepUserProfileId}
                          onChange={handleChangeSalesReps}
                          disabled
                          renderValue={() => {
                            return <em>No other reps available</em>;
                          }}
                        >
                          <MenuItem aria-label="None" value="">
                            None
                          </MenuItem>
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  {up.vendorContactRole !== "sales_rep" && (
                    <Grid container>
                      <div>Change Sales Manager:</div>
                      <FormControl fullWidth className={classesPaper.formControl}>
                        {salesManagers ? (
                          <Select
                            variant="standard"
                            labelId="credit-sub-select"
                            id="credit-sub-select-outlined"
                            value={currentSalesManagerUserProfileId}
                            onChange={handleChangeSalesManager}
                          >
                            <MenuItem aria-label="None" value="">
                              None
                            </MenuItem>
                            {createSaleRepMenuItems(
                              _.uniq(_.flatten([salesManagers, financeManagers]), "Sales Managers")
                            )}
                          </Select>
                        ) : (
                          <Select
                            variant="standard"
                            labelId="credit-sub-select"
                            id="credit-sub-select-outlined"
                            value={currentSalesManagerUserProfileId}
                            onChange={handleChangeSalesManager}
                            disabled
                            renderValue={() => {
                              return <em>No other managers available</em>;
                            }}
                          >
                            <MenuItem aria-label="None" value="">
                              None
                            </MenuItem>
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid container>
                    <div>Change Finance Manager:</div>
                    <FormControl fullWidth className={classesPaper.formControl}>
                      <Select
                        variant="standard"
                        labelId="credit-sub-select"
                        id="credit-sub-select-outlined"
                        value={currentFinanceManagerUserProfileId}
                        onChange={handleChangeFinanceManager}
                        disabled={!financeManagers}
                      >
                        <MenuItem aria-label="None" value="">
                          None
                        </MenuItem>
                        {!!financeManagers && createSaleRepMenuItems(financeManagers, "Finance Managers")}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={4} justify="flex-end">
                  {!salesReps ? (
                    <Grid item>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        className={classesPaper.containedBlue}
                      >
                        Close
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Button
                        onClick={handleSubmitChangeDetails}
                        variant="contained"
                        color="primary"
                        className={classesPaper.containedBlue}
                      >
                        Confirm
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </CardContent>
        </Paper>
      </Dialog>
      <ContactNotificationModal
        open={openEmailModal}
        handleOpen={handleOpenEmailModal}
        handleClose={handleCloseEmailModal}
        handleSetEmailOk={handleSetEmailOk}
        handleSetEmailNo={handleSetEmailNo}
      />
      <Snackbar open={openMessageModal} autoHideDuration={2000} onClose={handleCloseMessageModal}>
        <Alert onClose={handleCloseMessageModal} severity={responseSuccess ? "success" : "error"}>
          {responseSuccess
            ? "Successfully updated Opportunity Details"
            : "Something went wrong, please contact support."}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
  up: state.userProfile,
});

export default connect(
  mapStateToProps,
  null
)(ContactDetailModal);
