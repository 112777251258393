import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import Tile from "../../components/Tile/Tile";
import { connect } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CardFooter from "../../components/Card/CardFooter";
import { setRefetch } from "../../redux/actions";
import mixpanel from "../../mixpanel";

const useStyles = makeStyles(styles);
const useStylesPaper = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "400px",
    maxWidth: "500px",
  },
  paper: {
    minWidth: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    overFlowX: "hidden",
  },
  margin: {
    marginBottom: "8px",
  },
  marginDivider: {
    marginTop: "33px",
    marginBottom: "8px",
  },
  invoiceTotalMargin: {
    "& .MuiDivider-root": {
      border: "solid 10px black",
      marginBottom: "8px",
      marginTop: "16px",
      fontWeight: 900,
    },
  },
  mainContainer: {
    "& label": {
      fontWeight: 600,
      fontSize: "20px",
    },
    "& .MuiFormControl-root": {
      marginBottom: 0,
    },
  },
}));

const ADD_NEW_LEAD_MUTATION = gql`
  mutation(
    $amountRequested: Float
    $fullName: String
    $email: String
    $businessName: String
    $title: String
    $description: String
    $source: String
    $emailRemindersEnabled: Boolean
  ) {
    createLead(
      input: {
        amountRequested: $amountRequested
        fullName: $fullName
        email: $email
        businessName: $businessName
        title: $title
        description: $description
        source: $source
        emailRemindersEnabled: $emailRemindersEnabled
      }
    ) {
      success
    }
  }
`;

function CreateNewLeadModal({ open, handleClose, account, dynamicsAccountId, accountId, refetchLeads }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const classesPaper = useStylesPaper();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);

  const handleOpenMessageModal = () => {
    setOpenMessageModal(true);
  };

  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(null);
  };

  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openLoadingSnackbar, setOpenLoadingSnackbar] = React.useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);
  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const [values, setValues] = useState({
    amountRequested: null,
    fullName: null,
    email: null,
    businessName: null,
    phone: null,
    firstName: null,
    lastName: null,
    title: null,
    description: null,
    source: "Created in Trnsact Portal",
    emailRemindersEnabled: false,
  });
  const [createNewLead, { data, error, loading }] = useMutation(ADD_NEW_LEAD_MUTATION, {
    context: { authRequired: true },
  });
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };
  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  const handleSelectChange = e => {
    setValues({ ...values, vendorContactRole: e.target.value });
  };

  const errorMessage = "Please select a role for this user";
  const [errorSubmit, setErrorSubmit] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      setOpenLoadingSnackbar(true);
      setOpenSuccessSnackbar(true);
      setOpenErrorSnackbar(true);
      await createNewLead({
        variables: {
          amountRequested: null,
          fullName: values["firstName"] + " " + values["lastName"],
          email: values["email"],
          businessName: values["businessName"],
          phone: values["phone"],
          title: values["title"],
          description: values["description"],
          source: values["source"],
          emailRemindersEnabled: values["emailRemindersEnabled"],
        },
      });

      mixpanel.track("Lead Created");

      await refetchLeads();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ width: "500px" }}>
      <Dialog onClose={handleClose} open={open} scroll={"body"} fullScreen={!matches}>
        <Paper className={classesPaper.paper}>
          <CardContent>
            <h3>Create New Lead</h3>
            <Tile>
              <div className={classesPaper.mainContainer}>
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <GridItem item lg={4} xs={12}>
                      <CustomInput
                        labelText="First Name"
                        id="firstName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "firstName",
                          onChange: handleInputChange,
                          autoComplete: "fname",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={4} xs={12}>
                      <CustomInput
                        labelText="Last Name"
                        id="lastName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "lastName",
                          onChange: handleInputChange,
                          autoComplete: "lname",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                    <GridItem lg={4} xs={12}>
                      <CustomInput
                        labelText="Title"
                        id="title"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "title",
                          onChange: handleInputChange,
                          autoComplete: "title",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12}>
                      <CustomInput
                        labelText="Business Name"
                        id="businessName"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "businessName",
                          onChange: handleInputChange,
                          autoComplete: "businessName",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12}>
                      <CustomInput
                        labelText="Email Address"
                        id="email"
                        lowerCase={true}
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "email",
                          type: "email",
                          onChange: handleInputChange,
                          autoComplete: "email",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12}>
                      <CustomInput
                        labelText="Phone Number"
                        id="phone"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "phone",
                          onChange: handleInputChange,
                          autoComplete: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} xs={12}>
                      <CustomInput
                        labelText="Description"
                        id="description"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "description",
                          onChange: handleInputChange,
                          autoComplete: "description",
                          required: true,
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button type="submit" color="primary" simple size="lg" block fullWidth={true}>
                      Create Lead
                    </Button>
                  </CardFooter>
                </form>
                {loading && (
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    open={openLoadingSnackbar}
                    autoHideDuration={6000}
                    onClose={handleLoadingSnackbarClose}
                  >
                    <Alert onClose={handleLoadingSnackbarClose} severity="info">
                      Loading...
                    </Alert>
                  </Snackbar>
                )}
                {error && (
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    open={openErrorSnackbar}
                    autoHideDuration={6000}
                    onClose={handleErrorSnackbarClose}
                  >
                    <Alert onClose={handleErrorSnackbarClose} severity="alert">
                      Create New Lead error!
                    </Alert>
                  </Snackbar>
                )}
                {data && (
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    open={openSuccessSnackbar}
                    autoHideDuration={10000}
                    onClose={handleSuccessSnackbarClose}
                  >
                    <Alert onClose={handleSuccessSnackbarClose} severity="success">
                      <h4>New Lead Created</h4>
                    </Alert>
                  </Snackbar>
                )}
              </div>
            </Tile>
          </CardContent>
        </Paper>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
});

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewLeadModal);
