import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import Button from "components/CustomButtons/Button";

const styles = {};
const useStyles = makeStyles(styles);

const CreateEditChecklistItemModal = ({ isOpen, setIsOpen, checklistItem, setChecklistItem, handleSave }) => {
  const classes = useStyles();

  const isEditing = checklistItem?.checklistItemId;

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!checklistItem) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <DialogTitle id="form-dialog-title">{isEditing ? "Edit Сhecklist Item" : "Create Сhecklist Item"}</DialogTitle>

      <DialogContent>
        <DialogContentText></DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              label="Name"
              fullWidth
              size="small"
              value={checklistItem.name}
              onChange={e => setChecklistItem({ ...checklistItem, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              fullWidth
              size="small"
              value={checklistItem.notes}
              onChange={e => setChecklistItem({ ...checklistItem, notes: e.target.value })}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={!checklistItem.name}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditChecklistItemModal;
