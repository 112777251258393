import { SETUP_WIZARD } from "./types";

const initialState = { opened: false };

export const setupWizardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_WIZARD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
