import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";

interface FormInputProps extends Omit<TextFieldProps, "onChange"> {
  startIcon?: ReactNode;
  onChange?: (value: string) => void;
  formatter?: (value: string) => string;
}

export const FormInput: React.FC<FormInputProps> = ({ onChange, formatter, startIcon, ...other }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;

    if (onChange) {
      formatter ? onChange(formatter(value)) : onChange(value);
    }
  };

  return (
    <TextField
      fullWidth
      size="small"
      variant="standard"
      onChange={handleChange}
      className={classes.input}
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment position="start" className={classes.startAdornment}>
            {startIcon}
          </InputAdornment>
        ) : null,
      }}
      {...other}
    />
  );
};

const useStyles = makeStyles({
  startAdornment: {
    margin: "0 0 3px 0",

    "& .MuiSvgIcon-root": {
      fontSize: "1.18rem",
      margin: 0,
    },
  },
  input: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
