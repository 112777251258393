import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Button, Grid } from "@material-ui/core";
import { CustomizationPanel } from "./CustomizationPanel";
import { RulesSettings } from "./RulesSettings";

import _ from "lodash";

const useStyles = makeStyles(theme => ({
  header: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "500",
    padding: "30px 30px 10px",
    margin: 0,
    textTransform: "underline",
  },
  selectedFieldNameBox: {
    border: "1px double #DDD",
    padding: "15px",
    textAlign: "center",
    fontSize: "16px",
    width: "80%",
    margin: "20px auto 0",
    borderRadius: "5px",
    fontWeight: "bolder",
  },
  buttonsContainer: {
    marginTop: "20px",
  },
  customizeSelect: {
    display: "flex",
  },
  selectLabel: {
    fontSize: 12,
  },
  customizationButton: {
    width: 120,
    padding: "8px 0",
    fontSize: "13px",
    borderRadius: "7px",
    fontWeight: "700",
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: "20px",
  },
  cancelButton: {
    backgroundColor: "#818181",
    color: "white",
  },
  deleteButton: {
    marginTop: "20px",
    color: "#8D1B11",
    borderColor: "#8D1B11",
  },
  rulesAppliedHeader: {
    fontSize: "12px",
    margin: "0 12px",
  },
}));

export function Customization({
  selectedItem,
  setConfig,
  initialItem,
  apiNames,
  setCustomizationMode,
  setSelectedItem,
  locations,
}) {
  const classes = useStyles();
  const sectionIndex = _.get(selectedItem, "extras.sectionIndex", null);
  const page = _.get(selectedItem, "extras.page", null);

  const getElementSettings = () => {
    if (!selectedItem) {
      return null;
    }

    const orderedKeys = [
      "displayLabel",
      "helperText",
      "errorMessage",
      "columns",
      "maxValue",
      "minValue",
      "minValuePerOwner",
      "required",
      "displayed",
      "disabled",
      "fullWidth",
      "fieldOptions",
    ];
    return Object.entries(selectedItem.config).sort((a, b) => orderedKeys.indexOf(a[0]) - orderedKeys.indexOf(b[0]));
  };

  if (selectedItem) {
    selectedItem.config = selectedItem.config || {};
    selectedItem.rules = selectedItem.rules || [];
  }

  const [elementSettings, setElementSettings] = useState(getElementSettings());
  const [elementRules, setElementRules] = useState(_.get(selectedItem, "rules", []));
  const [elementRulesSatisfactionType, setElementRulesSatisfactionType] = useState(
    _.get(selectedItem, "rulesSatisfactionType") || "all"
  );

  useEffect(() => {
    if (selectedItem !== "") {
      setElementSettings(getElementSettings());
      setElementRules(_.get(selectedItem, "rules", []));
      setElementRulesSatisfactionType(_.get(selectedItem, "rulesSatisfactionType") || "all");
      updateConfig(selectedItem);
    }
  }, [selectedItem]);

  const updateConfig = selectedItem => {
    if (!selectedItem) return false;
    setConfig(prevConfig => {
      return prevConfig.map(page => {
        const isPageSelected = page.id === selectedItem.id;
        return {
          ...page,
          sections: page.sections.map(section => {
            const isTitleSelected = section.title.id === selectedItem.id;
            const isSectionSelected = section.title.id === _.get(selectedItem, "title.id");
            return {
              ...section,
              title: isTitleSelected ? selectedItem : section.title,
              config: isSectionSelected ? selectedItem.config : section.config,
              rulesSatisfactionType: isSectionSelected
                ? selectedItem.rulesSatisfactionType
                : section.rulesSatisfactionType || "all",
              rules: section.rules || [],
              fields: section.fields.map(field => (field.id === selectedItem.id ? selectedItem : field)),
            };
          }),
          config: isPageSelected ? selectedItem.config : page.config,
          rules: isPageSelected ? selectedItem.rules : page.rules,
          rulesSatisfactionType: isPageSelected ? selectedItem.rulesSatisfactionType : page.rulesSatisfactionType,
        };
      });
    });
  };

  const saveSettings = () => {
    setCustomizationMode(false);
  };

  const cancelSettings = () => {
    updateConfig(initialItem);
    setCustomizationMode(false);
  };
  const deleteElement = () => {
    if (selectedItem.type === "title") {
      setConfig(prevConfig =>
        prevConfig.map(page => ({
          ...page,
          sections: page.sections.filter(section => section.title.id !== selectedItem.id),
        }))
      );
    } else {
      setConfig(prevConfig =>
        prevConfig.map(page => ({
          ...page,
          sections: page.sections.map(section => ({
            ...section,
            fields: section.fields.filter(field => field.id !== selectedItem.id),
          })),
        }))
      );
    }
    setCustomizationMode(false);
  };

  const changeSelectedItemProp = (prop, value) => {
    setSelectedItem(prevSettings => _.cloneDeep(_.set(prevSettings, prop, value)));
  };

  const changeSelectedItemConfig = (key, value) => {
    setSelectedItem(prevSettings => {
      const configToSet = { ...prevSettings.config };
      const valueToSet = key === "fontSize" ? value + "px" : value;
      _.set(configToSet, key, valueToSet);
      return {
        ...prevSettings,
        config: configToSet,
      };
    });
  };

  const _parseNewRuleValue = newValue => {
    const isInt = n => {
      return Number(n) === n && n % 1 === 0;
    };

    const isFloat = n => {
      return Number(n) === n && n % 1 !== 0;
    };

    if (!isNaN(newValue)) {
      //is number
      if (isInt(newValue)) {
        newValue = parseInt(newValue);
      }
      if (isFloat(newValue)) {
        newValue = isFloat(newValue);
      }
    }
    if (newValue === "true") {
      newValue = true;
    }
    if (newValue === "false") {
      newValue = false;
    }
    return newValue;
  };

  const changeFieldRules = (ruleIndex, newValue, attrName) => {
    newValue = _parseNewRuleValue(newValue);
    setSelectedItem(prevSettings => {
      return _.cloneDeep(_.set(prevSettings, `rules[${ruleIndex}].${attrName}`, newValue));
    });
  };

  const changeSectionRules = (ruleIndex, newValue, attrName) => {
    newValue = _parseNewRuleValue(newValue);

    setConfig(prevConfig => {
      const cloned = _.cloneDeep(
        _.set(prevConfig, `[${page}].sections[${sectionIndex}].rules[${ruleIndex}].${attrName}`, newValue)
      );
      changeSelectedItemProp(`rules[${ruleIndex}].${attrName}`, newValue);
      return cloned;
    });
  };

  const changePageRules = (ruleIndex, newValue, attrName) => {
    newValue = _parseNewRuleValue(newValue);

    setConfig(prevConfig => {
      const cloned = _.cloneDeep(_.set(prevConfig, `[${page}].rules[${ruleIndex}].${attrName}`, newValue));
      changeSelectedItemProp(`rules[${ruleIndex}].${attrName}`, newValue);
      return cloned;
    });
  };

  const addNewFieldRule = () => {
    setSelectedItem(prevSettings => {
      return {
        ...prevSettings,
        rulesSatisfactionType: "all",
        rules: [
          ...prevSettings.rules,
          {
            evalValue: "",
            evalType: "",
            targetName: "",
          },
        ],
      };
    });
  };
  const addNewSectionRule = () => {
    setConfig(prevConfig => {
      prevConfig[page].sections[sectionIndex].rules.push({
        evalValue: "",
        evalType: "eq",
        targetName: "",
      });

      return _.cloneDeep(prevConfig);
    });
  };
  const addNewPageRule = () => {
    setSelectedItem(prevSettings => {
      prevSettings.rules.push({
        evalValue: "",
        evalType: "",
        targetName: "",
      });
      prevSettings.rulesSatisfactionType = "all";
      return _.cloneDeep(prevSettings);
    });
  };
  const removeRule = index => {
    setSelectedItem(prevSettings => {
      prevSettings.rules.splice(index, 1);
      return _.cloneDeep(prevSettings);
    });
  };

  const customizableElementsSettings = {
    page: {
      addNewRuleFn: addNewPageRule,
      changeRulesFn: changePageRules,
      removeRuleFn: removeRule,
      getTitleFn: () => `Page Configuration`,
    },
    section: {
      addNewRuleFn: addNewSectionRule,
      changeRulesFn: changeSectionRules,
      removeRuleFn: removeRule,
      getTitleFn: () => `Section Configuration`,
    },
    field: {
      addNewRuleFn: addNewFieldRule,
      changeRulesFn: changeFieldRules,
      removeRuleFn: removeRule,
      getTitleFn: () =>
        `Selected field: ${_.get(selectedItem, "config.fieldName", "") ||
          _.get(selectedItem, "config.title.text", "")}`,
    },
  };

  const getSelectedItemSettings = () => {
    const isSection = _.has(selectedItem, "fields");
    const isPage = _.has(selectedItem, "sections");

    switch (true) {
      case isSection:
        return customizableElementsSettings.section;
      case isPage:
        return customizableElementsSettings.page;
      default:
        return customizableElementsSettings.field;
    }
  };

  const isTitle = _.has(selectedItem, "config.text");
  const selectedItemSettings = getSelectedItemSettings();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography className={classes.selectedFieldNameBox}>{selectedItemSettings.getTitleFn()}</Typography>
        </Grid>
        {!isTitle ? (
          <RulesSettings
            rules={elementRules}
            targetNames={apiNames}
            rulesSatisfactionType={elementRulesSatisfactionType}
            changeSelectedItemProp={changeSelectedItemProp}
            selectedItemSettings={selectedItemSettings}
            selectedItem={selectedItem}
            locations={locations}
          />
        ) : null}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6" component="h6" className={classes.header}>
            Customization options
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {elementSettings &&
          elementSettings.map(([key, value]) => {
            return (
              <CustomizationPanel
                key={key}
                config={selectedItem?.config}
                fieldAttr={key}
                value={value}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                changeSelectedItemProp={changeSelectedItemProp}
                changeSelectedItemConfig={changeSelectedItemConfig}
              />
            );
          })}
      </Grid>
      <Grid container className={classes.buttonsContainer}>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Button
              className={`${classes.customizationButton} ${classes.cancelButton}`}
              variant="contained"
              onClick={cancelSettings}
            >
              Cancel
            </Button>
            <Button
              className={`${classes.customizationButton} ${classes.saveButton}`}
              variant="contained"
              color="primary"
              onClick={saveSettings}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Button
              className={`${classes.customizationButton} ${classes.deleteButton}`}
              variant="outlined"
              onClick={deleteElement}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
