import { ChecklistItem, ChecklistItemMobileEntity } from "../type";
import _ from "lodash";
import { format } from "date-fns";

export const mobileEntityAdapter = (entity: ChecklistItem, account: any): ChecklistItemMobileEntity => ({
  original: entity,
  title: entity.name,
  id: entity.checklistItemId!,
  isSelected: entity.isCompleted,
  dateAndTime: entity?.createdDateTime ? format(new Date(entity.createdDateTime), "M/dd/yyyy") : null,
  dataForView: [
    {
      type: "default",
      label: "Completed on",
      value: entity?.completedDateTime ? format(new Date(entity?.completedDateTime), "h:mmaa MM/dd/yyyy") : null,
    },
    {
      type: "default",
      label: "Completed by",
      value: entity?.completedByUserProfileId
        ? _.get(_.find(account?.contacts, { id: entity?.completedByUserProfileId }), "fullName")
        : null,
    },
    {
      type: "default",
      label: "Notes",
      value: entity.notes,
    },
  ],
});
