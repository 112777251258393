import React from "react";
import clsx from "clsx";
import { useDrag } from "react-dnd";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Button, Avatar, Box, Typography } from "@material-ui/core";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { DnDTypes } from "../../../types";
import { FormSwitch } from "../../../../../components/form";

const config = require("../../../../../config");

interface Props {
  lender: LenderProfiles;
  isAutoSubmitted?: boolean;
  showProgramsAmount?: boolean;
  onCreateProgram?: () => void;
  onSelectProgram?: () => void;
  onDelete?: (lenderId: string) => void;
  onChangeAutoSubmit?: (lenderId: string) => void;
}

export const LenderCard = ({
  lender,
  onDelete,
  onSelectProgram,
  onCreateProgram,
  onChangeAutoSubmit,
  isAutoSubmitted = false,
  showProgramsAmount = false,
}: Props) => {
  const classes = useStyles();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.LenderCard,
      item: lender,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [lender]
  );

  return (
    <Box
      className={clsx(classes.card, {
        [classes.draggingCard]: isDragging,
      })}
      {...({ ref: drag } as any)}
    >
      <Box className={classes.title}>
        <Avatar
          alt="img"
          style={{ display: "inline-flex", padding: "5px" }}
          src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${lender.lenderLogo}`}
        />

        <Typography component="span" variant="body1">
          {lender?.lenderName}
        </Typography>

        {onDelete && (
          <IconButton
            size="small"
            onClick={() => {
              onDelete(lender.lenderAccountId!);
            }}
          >
            <DeleteOutlineIcon color="error" />
          </IconButton>
        )}
      </Box>

      {showProgramsAmount && (
        <Box>
          <Typography component="span" variant="caption">
            Created programs: 0
          </Typography>
        </Box>
      )}

      {onChangeAutoSubmit && (
        <FormSwitch
          value={isAutoSubmitted}
          onChange={() => {
            onChangeAutoSubmit(lender.lenderAccountId!);
          }}
          label="Auto submit"
        />
      )}

      {onSelectProgram && (
        <Box>
          <Button variant="outlined" color="primary" onClick={() => onSelectProgram()}>
            Select program
          </Button>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    padding: "8px",
    cursor: "grab",
    display: "flex",
    borderRadius: "8px",
    background: grey["200"],
    flexDirection: "column",
  },
  draggingCard: {
    backgroundColor: grey["50"],
  },
  title: {
    gap: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
