import React, { Fragment } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import PageviewIcon from "@material-ui/icons/Pageview";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { Task } from "@trnsact/trnsact-shared-types";
import { useMutation } from "@apollo/react-hooks";
import { DEACTIVATE_TASK } from "../../../queries";
import { TableCell } from "../../../../Table/TableCell";

interface Props {
  task: Task;
  isMyTask: boolean;
  fetchTasks: () => Promise<any>;
  onOpenCreateTaskModel: (taskId: string) => void;
}

export const OptionCell = ({ task, fetchTasks, onOpenCreateTaskModel, isMyTask }: Props) => {
  const { taskId, creditSubId, status } = task;

  if (!creditSubId || status === "complete") return null;

  const [deactivateTaskRequest] = useMutation(DEACTIVATE_TASK, {
    context: { authRequired: true },
  });

  const handleDeleteTask = async () => {
    try {
      await deactivateTaskRequest({ variables: { taskId } });
      await fetchTasks();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenCS = () => {
    window.location.href = `/cs/${creditSubId}`;
  };

  const handleOpenCreateTaskModel = () => {
    onOpenCreateTaskModel(taskId!);
  };

  return (
    <TableCell>
      {!isMyTask ? (
        <Tooltip className="tooltip-top-start" title="View Task" placement="top">
          <IconButton onClick={handleOpenCS} color="primary" aria-label="edit task" component="button">
            <PageviewIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Fragment>
          <Tooltip className="tooltip-top-start" title="Edit Task" placement="top">
            <IconButton onClick={handleOpenCreateTaskModel} color="primary" aria-label="edit task" component="button">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Task" placement="top">
            <IconButton onClick={handleDeleteTask} color="primary" aria-label="remove task" component="button">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Fragment>
      )}
    </TableCell>
  );
};
