import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import calendar from "assets/img/icons/calendar.png";
import Table from "../../components/Table/Table";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import _ from "lodash";

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Q_CREDIT_SUBMISSIONS_BY_VOLUME = gql`
  query GetReportCreditSubmissionsByVolume {
    reportCreditSubmissionsByVolume {
      day
      count
    }
  }
`;

const Q_CREDIT_SUBMISSIONS_BY_DECISION = gql`
  query GetReportCreditSubmissionsByDecision {
    reportCreditSubmissionsByDecision {
      decision
      count
    }
  }
`;

const Q_CREDIT_SUBMISSIONS_DATE_RANGE = gql`
  query GetReportCreditSubmissionsByDateRange($startDate: Date!, $endDate: Date!) {
    reportCreditSubmissionsByDateRange(startDate: $startDate, endDate: $endDate) {
      day
      count
    }
  }
`;

const Q_CREDIT_SUBMISSIONS_BY_VENDOR_PROFILE = gql`
  query GetCreditSubmissionsByVendorProfile {
    reportCreditSubmissionsPerVendorProfile {
      vendorProfileName
      count
    }
  }
`;

const Q_FETCH_REPORTS_LOCATION = gql`
  query reportsVoByLocation($accountId: ID!, $from: Date!, $to: Date!, $locationId: ID) {
    reportsVoByLocation(accountId: $accountId, from: $from, to: $to, locationId: $locationId) {
      day
      totalByLocation
    }
  }
`;

const Q_FETCH_REPORTS = gql`
  query reportVOsByAccount($accountId: ID!) {
    reportVOsByAccountPerWeek(accountId: $accountId) {
      total
      week
    }
    reportVOsByAccountPerMonth(accountId: $accountId) {
      total
      month
    }
  }
`;

const Q_FETCH_REPORTS_WITH_DATE = gql`
  query reportVOsByAccountWithDateRange($accountId: ID!, $from: Date!, $to: Date!, $locationId: ID) {
    reportVOStagesByAccountInDateRange(accountId: $accountId, from: $from, to: $to, locationId: $locationId) {
      day
      totalByStage
    }
  }
`;

const Q_FETCH_REPORTS_SALES_REP_WITH_DATE = gql`
  query reportVOsByAccountWithDateRange($accountId: ID!, $from: Date!, $to: Date!, $stage: String) {
    reportVOsByAccountPerSalesRep(accountId: $accountId, from: $from, to: $to, stage: $stage) {
      total
      salesRepresentativeId
      salesRepresentative {
        fullName
      }
    }
  }
`;

const Q_FETCH_REPORTS_SYNDICATION_PER_LENDER = gql`
  query ReportSyndicationsByLender {
    reportSyndicationsByLender {
      count
      lenderName
    }
  }
`;

const Q_FETCH_REPORTS_SYNDICATION_BY_DECISION = gql`
  query ReportSyndicationsByDecision {
    reportSyndicationsByDecision {
      decision
      count
    }
  }
`;

const Q_FETCH_REPORTS_SYNDICATION_VOLUME = gql`
  query ReportSyndicationsByVolume {
    reportSyndicationsByVolume {
      day
      count
    }
  }
`;

const useStyles = makeStyles({
  datePicker: {
    fontSize: "14px",
    width: "120px",
    textAlign: "right",
    padding: "5px",
    border: "1px solid #CCC",
    borderRadius: "5px",
    backgroundImage: `url(${calendar})`,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
  },
  calendarContainer: {
    marginBottom: "20px",
    padding: "10px",
    maxHeight: "100px",
    width: "100%",
  },
  salesRepContainer: {
    marginTop: "30px",
  },
  noResults: {
    fontSize: "1rem",
    marginTop: "15px",
  },
  separator: {
    borderTop: "1px solid #a2adb3",
  },
  categoryHeader: {
    fontVariant: "small-caps",
    fontSize: "15px",
    color: "black",
  },
  indentItem: {
    paddingLeft: "2rem",
    fontSize: "14px",
  },
});

const ReportsPage = () => {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const vendorProfile = useSelector(state => state.vp);

  const [yesterday, setYesterday] = useState(new Date().setDate(new Date().getDate() - 1));
  const [today, setToday] = useState(new Date());

  const [filterByStage, setFilterByStage] = useState("all");
  const [VOPerWeek, setVOPerWeek] = useState([]);
  const [VOPerMonth, setVOPerMonth] = useState([]);
  const [VOPerSalesRep, setVOPerSalesRep] = useState([]);
  const [VOStagesInDateRange, setVOStagesInDateRange] = useState([]);
  const [VOPerLocationInDateRange, setVOPerLocationInDateRange] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setMonth(new Date().getMonth() - 3));
  const [endDate, setEndDate] = useState(new Date());

  const [locId, setLocId] = useState(null);

  const [getReportDataWithoutRange, { data: reportDataWithoutRange, loading: loadingReports }] = useLazyQuery(
    Q_FETCH_REPORTS,
    {
      context: { authRequired: true },
      variables: {
        accountId: account.id,
      },
    }
  );

  const [
    getReportDataInDateRangeSalesRep,
    { data: reportDataSalesChart, loading: loadingDataSalesRepChart },
  ] = useLazyQuery(Q_FETCH_REPORTS_SALES_REP_WITH_DATE, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
      stage: filterByStage !== "all" ? filterByStage : undefined,
    },
  });

  const [getReportDataInDateRange, { data: reportDataInDateRange, loading: loadingDataInRangeChart }] = useLazyQuery(
    Q_FETCH_REPORTS_WITH_DATE,
    {
      context: { authRequired: true },
      variables: {
        accountId: account.id,
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
        locationId: locId,
      },
    }
  );

  const [
    getReportDataByLocationInDateRange,
    { data: reportDataByLocationInDateRange, loading: loadingDataByLocationInRangeChart },
  ] = useLazyQuery(Q_FETCH_REPORTS_LOCATION, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
      locationId: locId,
    },
  });

  const [
    getReportCreditSubmissionsByDateRange,
    { data: reportCreditSubmissionsByDateRange, loading: loadingDataReportCreditSubmissionsByDateRange },
  ] = useLazyQuery(Q_CREDIT_SUBMISSIONS_DATE_RANGE, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      startDate: format(startDate, "yyyy-MM-dd"),
      endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
    },
  });

  const [
    getReportCreditSubmissionsPerVendorProfile,
    { data: reportCreditSubmissionsPerVendorProfile, loading: loadingDataReportCreditSubmissionsPerVendorProfile },
  ] = useLazyQuery(Q_CREDIT_SUBMISSIONS_BY_VENDOR_PROFILE, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  const [
    getReportCreditSubmissionsByVolume,
    { data: reportCreditSubmissionsByVolume, loading: loadingDataReportCreditSubmissionsByVolume },
  ] = useLazyQuery(Q_CREDIT_SUBMISSIONS_BY_VOLUME, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  const [
    getReportSyndicationsByDecision,
    { data: reportSyndicationsByDecision, loading: loadingDataReportSyndicationsByDecision },
  ] = useLazyQuery(Q_FETCH_REPORTS_SYNDICATION_BY_DECISION, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  const [
    getReportSyndicationsByVolume,
    { data: reportSyndicationsByVolume, loading: loadingDataReportSyndicationsByVolume },
  ] = useLazyQuery(Q_FETCH_REPORTS_SYNDICATION_VOLUME, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  const [
    getReportSyndicationsByLender,
    { data: reportSyndicationsByLender, loading: loadingDataReportSyndicationsByLender },
  ] = useLazyQuery(Q_FETCH_REPORTS_SYNDICATION_PER_LENDER, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  const [
    getReportCreditSubmissionsByDecision,
    { data: reportCreditSubmissionsByDecision, loading: loadingDataReportCreditSubmissionsByDecision },
  ] = useLazyQuery(Q_CREDIT_SUBMISSIONS_BY_DECISION, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  useEffect(() => {
    if (account) {
      getReportCreditSubmissionsByVolume();
      getReportCreditSubmissionsPerVendorProfile();
      getReportCreditSubmissionsByDecision();
      getReportDataWithoutRange();
      //getReportDataInDateRangeSalesRep();
      getReportSyndicationsByVolume();
      getReportSyndicationsByLender();
      getReportSyndicationsByDecision();
      if (startDate && endDate) {
        getReportCreditSubmissionsByDateRange();
        // if (account.accountType === "Vendor") {
        //   getReportDataInDateRange();

        //   getReportDataByLocationInDateRange();
        // }
      }
    }
  }, [account, startDate, endDate, filterByStage]);

  useEffect(() => {
    if (reportDataSalesChart) {
      setVOPerSalesRep(_.get(reportDataSalesChart, "reportVOsByAccountPerSalesRep", []));
    }

    if (reportDataInDateRange) {
      setVOStagesInDateRange(
        reportDataInDateRange.reportVOStagesByAccountInDateRange
          ? reportDataInDateRange.reportVOStagesByAccountInDateRange.map(item => {
              return {
                day: item.day,
                ...item.totalByStage,
              };
            })
          : []
      );
    }

    if (reportDataByLocationInDateRange) {
      const resultToRender = reportDataByLocationInDateRange.reportsVoByLocation.map(item => {
        const itemParsed = item.totalByLocation.map(loc => {
          return {
            [loc.locationId]: loc.total,
          };
        });

        return _.merge(
          {
            day: item.day,
          },
          ...itemParsed
        );
      });

      setVOPerLocationInDateRange(reportDataByLocationInDateRange.reportsVoByLocation ? resultToRender : []);
    }
  }, [reportDataInDateRange, reportDataSalesChart, reportDataByLocationInDateRange]);

  useEffect(() => {}, [getReportDataWithoutRange, account]);

  useEffect(() => {
    if (reportDataWithoutRange) {
      setVOPerWeek(_.get(reportDataWithoutRange, "reportVOsByAccountPerWeek", []));
      setVOPerMonth(_.get(reportDataWithoutRange, "reportVOsByAccountPerMonth", []));
    }
  }, [reportDataWithoutRange]);

  const simpleHash = input => {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash;
  };

  const [tabSelected, setTabSelect] = useState("1");

  const handleTabChange = (event, newValue) => {
    setTabSelect(newValue);
  };

  const handleSetCalendar = e => {
    e.preventDefault();
    const range = e.currentTarget.id;
    let startDate, endDate;

    switch (range) {
      case "today":
        startDate = new Date();
        endDate = new Date();
        break;
      case "yesterday":
        startDate = addDays(new Date(), -1);
        endDate = addDays(new Date(), -1);
        break;
      case "this_week":
        startDate = startOfWeek(new Date());
        endDate = endOfWeek(new Date());
        break;
      case "last_week":
        startDate = startOfWeek(addDays(new Date(), -7));
        endDate = endOfWeek(addDays(new Date(), -7));
        break;
      case "this_month":
        startDate = startOfMonth(new Date());
        endDate = endOfMonth(new Date());
        break;
      case "last_month":
        startDate = startOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        endDate = endOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        break;
      case "this_quarter":
        startDate = startOfQuarter(new Date());
        endDate = endOfQuarter(new Date());
        break;
      case "last_quarter":
        startDate = startOfQuarter(addMonths(new Date(), -3));
        endDate = endOfQuarter(addMonths(new Date(), -3));
        break;
      case "this_year":
        startDate = startOfYear(new Date());
        endDate = endOfYear(new Date());
        break;
      case "last_year":
        startDate = startOfYear(addDays(new Date(), -365));
        endDate = endOfYear(addDays(new Date(), -365));
        break;
    }

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const quickDateActions = key => {
    const optionList = [
      { lbl: "Today", id: "today" },
      { lbl: "Yesterday", id: "yesterday" },
      { lbl: "This Week", id: "this_week" },
      { lbl: "Last Week", id: "last_week" },
      { lbl: "This Month", id: "this_month" },
      { lbl: "Last Month", id: "last_month" },
      { lbl: "This Quarter", id: "this_quarter" },
      { lbl: "Last Quarter", id: "last_quarter" },
      { lbl: "This Year", id: "this_year" },
      { lbl: "Last Year", id: "last_year" },
    ];
    return (
      <Box
        key={key}
        sx={{
          height: "100%",
          display: "flex",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup orientation="vertical" variant="contained" style={{ position: "absolute", minWidth: "25vh" }}>
          {optionList.map(opt => (
            <Button size="small" id={opt.id} key={opt.id} onClick={handleSetCalendar}>
              {opt.lbl}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );
  };
  return (
    <Grid container>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <TabContext value={tabSelected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Reporting" variant="fullWidth">
              <Tab label="Submissions" value="1" />
              <Tab
                label="Syndications"
                value="2"
                style={{ display: _.get(vendorProfile, "allowSyndication") ? "block" : "none" }}
              />
              <Tab
                label="Opportunities"
                value="3"
                style={{ display: _.get(account, "vendorProfileId") ? "block" : "none" }}
              />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    Grouped By Date
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Grid item lg={3} sm={6} xs={6} md={3}>
                        <span>From: </span>
                        <DatePicker
                          selected={startDate}
                          className={classes.datePicker}
                          onChange={date => setStartDate(date)}
                          selectsStart
                          dateFormat="MM-dd-yyyy"
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={yesterday}
                        >
                          {quickDateActions("startDateCal")}
                        </DatePicker>
                      </Grid>
                      <Grid item lg={3} sm={6} xs={6} md={3}>
                        <span>To: </span>
                        <DatePicker
                          className={classes.datePicker}
                          selected={endDate}
                          dateFormat="MM-dd-yyyy"
                          onChange={date => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          maxDate={today}
                        >
                          {quickDateActions("endDateCal")}
                        </DatePicker>
                      </Grid>
                    </div>
                    <Grid container>
                      {reportCreditSubmissionsByDateRange ? (
                        <ResponsiveContainer height={500}>
                          <BarChart
                            data={
                              reportCreditSubmissionsByDateRange
                                ? reportCreditSubmissionsByDateRange.reportCreditSubmissionsByDateRange
                                : []
                            }
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="day" tickFormatter={date => format(new Date(date), "MM-dd-yyyy")} />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="count" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : null}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    Grouped By Dealer
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <BarChart
                        data={
                          reportCreditSubmissionsPerVendorProfile
                            ? reportCreditSubmissionsPerVendorProfile.reportCreditSubmissionsPerVendorProfile
                            : []
                        }
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="vendorProfileName" />
                        <YAxis />
                        <Tooltip />

                        <Bar dataKey="count" name="Total" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                    Volume
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <BarChart
                        data={
                          reportCreditSubmissionsByVolume
                            ? reportCreditSubmissionsByVolume.reportCreditSubmissionsByVolume
                            : []
                        }
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" tickFormatter={date => format(new Date(date), "MM-dd-yyyy")} />
                        <YAxis
                          tickFormatter={d => {
                            return `$ ${d}`;
                          }}
                        />

                        <Tooltip
                          formatter={(value, name, props) => {
                            return [`$ ${value}`, "Total Volume"];
                          }}
                        />

                        <Bar dataKey="count" name="Total" fill="#ffc658" />
                      </BarChart>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                    Grouped By Decision
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <BarChart
                        data={
                          reportCreditSubmissionsByDecision
                            ? reportCreditSubmissionsByDecision.reportCreditSubmissionsByDecision
                            : []
                        }
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="decision" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" name="Total" fill="#d0ed57" />
                      </BarChart>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    Grouped By Lender
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <ResponsiveContainer height={500}>
                        <BarChart
                          data={reportSyndicationsByLender ? reportSyndicationsByLender.reportSyndicationsByLender : []}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="lenderName" />
                          <YAxis />
                          <Tooltip />

                          <Bar dataKey="count" name="Total" fill="#82ca9d" />
                        </BarChart>
                      </ResponsiveContainer>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    Volume
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <BarChart
                        data={reportSyndicationsByVolume ? reportSyndicationsByVolume.reportSyndicationsByVolume : []}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" tickFormatter={date => format(new Date(date), "MM-dd-yyyy")} />
                        <YAxis
                          tickFormatter={d => {
                            return `$ ${d}`;
                          }}
                        />

                        <Tooltip
                          formatter={(value, name, props) => {
                            return [`$ ${value}`, "Total Volume"];
                          }}
                        />

                        <Bar dataKey="count" name="Total" fill="#ffc658" />
                      </BarChart>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                    Grouped By Decision
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <BarChart
                        data={
                          reportSyndicationsByDecision ? reportSyndicationsByDecision.reportSyndicationsByDecision : []
                        }
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="decision" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" name="Total" fill="#d0ed57" />
                      </BarChart>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value="3">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <Grid container>
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        Weekly
                      </AccordionSummary>
                      <AccordionDetails>
                        <ResponsiveContainer height={500}>
                          <BarChart data={VOPerWeek}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="week"
                              tickFormatter={date => {
                                return format(new Date(date), "MM-dd-yyyy");
                              }}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total" stackId="a" fill="#007ac1" />
                          </BarChart>
                        </ResponsiveContainer>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        Monthly
                      </AccordionSummary>
                      <AccordionDetails>
                        <ResponsiveContainer height={500}>
                          <BarChart data={VOPerMonth}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="month"
                              tickFormatter={date => {
                                return format(new Date(date), "yyyy-MM-dd");
                              }}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="total" stackId="a" fill="#67daff" />
                          </BarChart>
                        </ResponsiveContainer>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default ReportsPage;
