import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { InsertionContainer } from "../PortalConfigurationWizard/CreditAppDetailModal/InsertionContainer";
import CreateEditProposalMenuOptionModal from "./CreateEditProposalMenuOptionModal";
import ProposalMenuOption from "./ProposalMenuOption";
import _ from "lodash";

const styles = {
  root: {
    padding: 20,
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: "6px",
    background: "white",
  },
  menuTitle: {
    marginBottom: 20,
  },
  addNewOptionContainer: {
    height: 350,
    border: "1px dashed gray",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    borderRadius: 8,
  },
};

const useStyles = makeStyles(styles);

const ProposalMenu = ({ proposalMenu, setProposalMenu, refetchProposalMenu }) => {
  const classes = useStyles();

  const [proposalMenuOptionToEdit, setProposalMenuOptionToEdit] = useState();
  const [isCreateEditProposalMenuOptionModalOpen, setIsCreateEditProposalMenuOptionModalOpen] = useState(false);

  const handleCreateMenuOptionClick = () => {
    const emptyMenuOption = {
      name: "",
      description: "",
      titleColor: "#000000",
      titleBgColor: "#ffffff",
    };
    setProposalMenuOptionToEdit(emptyMenuOption);
    setIsCreateEditProposalMenuOptionModalOpen(true);
  };

  const handleEditMenuOptionClick = menuOptionToEdit => {
    const menuOptionToEditCopy = _.cloneDeep(menuOptionToEdit);
    setProposalMenuOptionToEdit(menuOptionToEditCopy);
    setIsCreateEditProposalMenuOptionModalOpen(true);
  };

  const renderProposalMenuOption = useCallback(
    (menuOption, index) => {
      return (
        <Grid item xs={3} key={menuOption.proposalMenuOptionId}>
          <ProposalMenuOption
            menuOption={menuOption}
            index={index}
            proposalMenu={proposalMenu}
            setProposalMenu={setProposalMenu}
            refetchProposalMenu={refetchProposalMenu}
            handleEditMenuOptionClick={handleEditMenuOptionClick}
          />
        </Grid>
      );
    },
    [proposalMenu]
  );

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.menuTitle}>
        {" "}
        {proposalMenu.name}{" "}
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {proposalMenu.menuOptions?.map(renderProposalMenuOption)}
        <Grid item xs={3} onClick={handleCreateMenuOptionClick}>
          <div className={classes.addNewOptionContainer}>
            <InsertionContainer />
            <h3>Create Menu Option</h3>
          </div>
        </Grid>
      </Grid>

      <CreateEditProposalMenuOptionModal
        isOpen={isCreateEditProposalMenuOptionModalOpen}
        setIsOpen={setIsCreateEditProposalMenuOptionModalOpen}
        proposalMenu={proposalMenu}
        proposalMenuOption={proposalMenuOptionToEdit}
        setProposalMenuOption={setProposalMenuOptionToEdit}
        refetchProposalMenu={refetchProposalMenu}
      />
    </div>
  );
};

export default ProposalMenu;
