import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import _ from "lodash";
import { theme } from "../../../theme";

export function MultipleSelectFieldSetting({ settings, itemConfiguration, setItemConfiguration }) {
  const [value, setValue] = useState(_.get(itemConfiguration, settings.configurationKey, []));

  const updateValue = value => {
    setValue(value);
    setItemConfiguration(prevItemConfiguration => {
      return {
        ...prevItemConfiguration,
        [settings.configurationKey]: value,
      };
    });
  };
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel htmlFor={`SelectFieldSetting-${settings.configurationKey}`}>{settings?.selectLabel}</InputLabel>
        <Select
          labelId={`SelectFieldSetting-${settings.configurationKey}`}
          multiple
          value={value}
          style={{ width: "100%" }}
          onChange={event => {
            updateValue(event.target.value);
          }}
          renderValue={() =>
            settings?.options
              .filter(option => value.includes(option.value))
              .map(option => option.label)
              .join(", ")
          }
          MenuProps={{
            getContentAnchorEl: null,
            variant: "menu",
          }}
        >
          {settings?.options.map(option => (
            <MenuItem key={option.label} value={option.value} name={option.label}>
              <Checkbox checked={value.includes(option.value)} style={{ color: theme.palette.primary.main }} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
        {!!settings?.helperText && <FormHelperText>{settings?.helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
