import gql from "graphql-tag";

export const GET_AFTERMARKET_PRODUCTS_BY_VENDOR_API_CHANNEL = gql`
  query AftermarketProductsByVendorApiChannel($input: AftermarketProductsByVendorApiChannelInput!) {
    products: aftermarketProductsByVendorApiChannel(input: $input) {
      aftermarketProductId
      aftermarketVendorApiChannel
      productName
      productType
      productCategory
      productDescriptionExternal
      criteria
    }
  }
`;
