import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Tile from "../../components/Tile/Tile";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import MessageModal from "../../components/MessageModal";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  rootInput: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  paper: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    minWidth: 700,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 700,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    top: "30px",
  },
  previewDoc: {
    maxWidth: "60%",
  },
  priorityLabel: {
    display: "inline-block",
    margin: "10px 0 0 0",
  },
  smallIcon: {
    fontSize: "20px",
    margin: "15px",
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
}));

const UPSERT_ACTIVITY_MUTATION = gql`
  mutation($input: UpsertActivityInput) {
    upsertActivity(input: $input)
  }
`;

const useUiStyles = makeStyles(styles);

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

export default function ActivityModal({ handleClose, activityId, refetch, creditSubId, activity }) {
  const classes = { ...useStyles(), ...useUiStyles() };

  const [notes, setNotes] = useState(null);
  const [party, setParty] = useState(null);
  const [status, setStatus] = useState(null);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [notesError, setNotesError] = useState("");
  const [partyError, setPartyError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [basicErrorMessage, setBasicErrorMessage] = useState("Required");
  const [modalStyle] = useState(getModalStyle);
  const uiClasses = useUiStyles();
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const handleChangeNotes = e => {
    setNotes(e.target.value);
  };
  const handleChangeParty = e => {
    setParty(e.target.value);
  };
  const handleChangeStatus = e => {
    setStatus(e.target.value);
  };

  const [upsertActivity, { data, error, loading }] = useMutation(UPSERT_ACTIVITY_MUTATION, {
    context: { authRequired: true },
  });

  const handlePostSubmit = () => {
    setNotes(null);
    setParty(null);
    setStatus(null);
    handleCloseModal();
    handleClose();
  };

  const handleSubmissionMessage = message => {
    setSubmissionMessage(message);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await handleOpenModal();
      await handleSubmissionMessage({
        title: "Sending",
        message: "Please wait.",
      });
      await upsertActivity({
        variables: {
          input: {
            notes: notes,
            party: party,
            activityStatus: status,
            creditSubmissionDynamicsId: creditSubId,
            activityId: activityId ? activityId : undefined,
          },
        },
      });
      await handleOpenModal();
      await handleSubmissionMessage({
        title: "Success",
        message: "Activity successfully logged.",
      });
      await refetch();
    } catch (e) {
      console.log(e);
      await handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };
  const handleOpenModal = () => {
    setOpenMessageModal(true);
  };

  useEffect(() => {
    setSubmitEnabled(true);

    if (!notes || !party || !status) {
      setSubmitEnabled(false);
    }
  }, [notes, party, status]);

  useEffect(() => {
    if (!!activityId && !!activity) {
      setNotes(activity.notes);
      setParty(activity.party);
      setStatus(activity.activityStatus);
    }
  }, [activity, activityId]);

  return (
    <div style={modalStyle} className={classes.surface}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Tile title={activityId ? "Edit Activity" : "Create New Activity"} maxHeightLimitByScreen={true}>
        <div className={classes.formContainer}>
          <Grid container spacing={4} className={classes.formContainerGridContainer}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl fullWidth className={uiClasses.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                  Party
                </InputLabel>
                <Select
                  classes={{ select: uiClasses.select }}
                  value={party ? party : ""}
                  onChange={handleChangeParty}
                  required={true}
                  inputProps={{
                    name: "party",
                    id: "partyId",
                    required: true,
                    helpertext: "Required",
                  }}
                >
                  <MenuItem value="Applicant">Applicant</MenuItem>
                  <MenuItem value="Dealer">Dealer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormControl fullWidth className={uiClasses.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
                  Status
                </InputLabel>
                <Select
                  classes={{ select: uiClasses.select }}
                  value={status ? status : ""}
                  onChange={handleChangeStatus}
                  required={true}
                  inputProps={{
                    name: "status",
                    id: "statusId",
                    required: true,
                    helpertext: "Required",
                  }}
                >
                  <MenuItem value="Scheduled">Scheduled</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  <MenuItem value="Cancelled">Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={uiClasses.selectFormControl} fullWidth>
                <TextField
                  label="Notes"
                  value={notes}
                  onChange={handleChangeNotes}
                  multiline
                  rows={4}
                  type="text"
                  required={true}
                  helperText="Please add your notes"
                  variant="filled"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                className={classes.submitButton}
                onClick={handleSubmit}
                disabled={!submitEnabled}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <MessageModal
              isOpen={openMessageModal}
              handleCloseModal={handlePostSubmit}
              title={submissionMessage.title}
              message={submissionMessage.message}
            />
          </Grid>
        </div>
      </Tile>
    </div>
  );
}
