const proposalsMenu = (state: any) => state.aftermarketMenuConstructor.proposalMenu;
const menuOptionToAddEdit = (state: any) => state.aftermarketMenuConstructor.menuOptionToAddEdit;
const selectedMenuOption = (state: any) => state.aftermarketMenuConstructor.selectedMenuOption;
const proposalsProducts = (state: any) => state.aftermarketMenuConstructor.proposalProducts.products;
const archivedMenuOptions = (state: any) => state.aftermarketMenuConstructor.archivedMenuOptions;
const initialProposalMenu = (state: any) => state.aftermarketMenuConstructor.initialProposalMenu;
const isLoading = (state: any) => state.aftermarketMenuConstructor.isLoading;

export const menuConstructorSelectors = {
  isLoading,
  proposalsMenu,
  proposalsProducts,
  menuOptionToAddEdit,
  selectedMenuOption,
  archivedMenuOptions,
  initialProposalMenu,
};
