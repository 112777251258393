import React, { useEffect, useState } from "react";
import Tile from "components/Tile/Tile";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../components/Table/Table";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import ReceiptIcon from "@material-ui/icons/Receipt";
const GET_EQUIPMENT = gql`
  query($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
    }
  }
`;

const useStyles = makeStyles({
  cardLine: {
    display: "flex",
    minHeight: "5em",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px 0",
    "& h4": {
      width: "100%",
    },
    "& > div": {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  clearBlock: {
    width: 0,
    height: 0,
    overflow: "hidden",
  },
  alignRight: {
    display: "block",
    textAlign: "right",
  },
  uploadedInvoice: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  uploadedInvoiceData: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& div": {
      marginRight: "50px",
    },
  },
  uploadedInvoiceButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    "& button": {
      marginRight: "5px",
      "&::last-child": {
        marginRight: 0,
      },
    },
  },
  uploadedInvoiceTable: {
    "& th": {
      textAlign: "left",
      padding: "0 20px 0 0",
    },
    "& td": {
      textAlign: "left",
      padding: "0 20px 0 0",
    },
  },
  greenBorder: {
    border: "2px solid #3ec179",
  },
  transparentBorder: {
    border: "2px solid transparent",
    minHeight: "300px",
  },
  greenText: {
    color: "#3ec179",
  },
  modalContainer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.5)",
  },
  modalViewInvoiceImage: {
    position: "absolute",
    top: "10%",
    bottom: "10%",
    left: "10%",
    right: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  viewInvoiceImage: {
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  greenButton: {
    backgroundColor: "#3ec179",
  },
  right: {
    textAlign: "right",
  },
  disappearingUploadText: {
    marginLeft: 8,
    "@media (max-width: 1530px) and (min-width: 1280px)": {
      display: "none",
    },
    "@media (max-width: 1150px) and (min-width: 960px)": {
      display: "none",
    },
    "@media (max-width: 860px)": {
      display: "none",
    },
  },
  disappearingDownloadButton: {
    "@media (max-width: 780px)": {
      display: "none",
    },
  },
  appearingInvoiceData: {
    display: "none",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  disappearingInvoiceData: {
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export function InvoiceEquipmentDetailsCard({ invoiceDetailTableBody, cs }) {
  const classes = useStyles();
  const tableHead = [
    "Quantity",
    "New/Used",
    "Year",
    "Make",
    "Model",
    "Serial Number/VIN",
    "Stock Id",
    "Additional Description",
  ];
  const [equipments, setEquipments] = useState([]);

  const { data: equipmentData } = useQuery(GET_EQUIPMENT, {
    variables: {
      VOId: cs.voId,
    },
  });

  useEffect(() => {
    if (equipmentData !== null && equipmentData !== undefined && equipmentData.equipments !== null) {
      setEquipments(
        equipmentData.equipments.map(row => {
          return [
            row.quantity,
            row.newUsed,
            row.year,
            row.make,
            row.model,
            row.serialNumberVin,
            row.stockId,
            row.additionalDescription,
          ];
        })
      );
    }
  }, [equipmentData]);

  return (
    <Tile title="Invoice & Equipment Details">
      <div className={classes.cardLine}>
        <h4>
          <ReceiptIcon size="small" /> Invoice Details
        </h4>
        {invoiceDetailTableBody ? (
          <>
            <div className={classes.fullWidth}>
              <Table
                tableHeaderColor="primary"
                tableData={invoiceDetailTableBody}
                colorsColls={["primary"]}
                customCellClasses={[classes.right]}
                customClassesForCells={[1]}
              />
            </div>
          </>
        ) : (
          <div>No invoice info</div>
        )}
      </div>
      <Divider />
      <h4>Equipment Details</h4>
      <div className={classes.cardLine}>
        {!!equipments && !!equipments.length ? (
          <>
            <Table
              fullWidth
              tableHead={tableHead}
              tableData={equipments}
              tableHeaderColor="primary"
              customHeadCellClasses={[classes.right, classes.right]}
              customHeadClassesForCells={[5, 6]}
              customCellClasses={[classes.right, classes.right]}
              customClassesForCells={[5, 6]}
              colorsColls={["primary"]}
            />
          </>
        ) : (
          <>
            <div>No Equipment Info Available</div>
          </>
        )}
      </div>
    </Tile>
  );
}
