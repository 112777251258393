import React, { ReactNode } from "react";
import { IconButton } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

interface Props {
  doc: any;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  specialPanel?: ReactNode | null;
}

export const MobileCardActionPanel = ({ doc, onEdit, onDelete, specialPanel }: Props) => {
  if (specialPanel) return <>{specialPanel}</>;

  return (
    <>
      {doc?.source ? (
        <>
          <IconButton size="small" href={doc.source} target="_blank">
            <VerticalAlignBottomIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton size="small" onClick={onEdit}>
            <EditIcon fontSize="small" color="primary" />
          </IconButton>
          <IconButton size="small" onClick={onDelete}>
            <DeleteOutlineIcon fontSize="small" color="error" />
          </IconButton>
        </>
      ) : (
        <IconButton size="small" disabled>
          <ReportProblemOutlinedIcon fontSize="small" style={{ color: yellow["700"] }} />
        </IconButton>
      )}
    </>
  );
};
