import React, { useContext, useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useMutation } from "@apollo/react-hooks";
import { DocumentContext } from "../../contexts/DocumentContext";
import Modal from "@material-ui/core/Modal";

import gql from "graphql-tag";

const rand = () => Math.round(Math.random() * 20) - 10;

const getModalStyle = () => {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const UPLOAD_FILE = gql`
  mutation($input: UpsertDocumentInput!, $file: Upload) {
    upsertDocument(input: $input, file: $file)
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    // margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  primaryColor: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-shrink, & .MuiButton-text.MuiButton-textPrimary .MuiButtonLabel": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function FileUpLoadModal(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [openErrorDialogue, setOpenErrorDialogue] = useState(false);
  const [openDescriptionDialogue, setOpenDescriptionDialogue] = useState(false);
  const [openUploadZone, setOpenUploadZone] = useState(false);
  const [mutationError, setMutationError] = useState();
  const [fileType, setFileType] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [openFileDescriptionInputModal, setOpenFileDescriptionInputModal] = useState(false);

  const [fileInput, { data, error }] = useMutation(UPLOAD_FILE, {
    context: { authRequired: true },
  });

  const documentContext = useContext(DocumentContext);

  const graphqlErrorMessage = {
    duplicateMessage:
      'GraphQL error: duplicate key value violates unique constraint "document_vendor_opportunity_id_doc_name_key"',
  };

  const errorDialogue = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Error</h2>
      <p id="simple-modal-description">
        {mutationError && mutationError.message === graphqlErrorMessage.duplicateMessage
          ? "This file is already uploaded"
          : ""}
      </p>
    </div>
  );

  const handleOpenErrorDialogue = () => setOpenErrorDialogue(true);

  const handleCloseErrorDialogue = () => setOpenErrorDialogue(false);

  const handleOpenUploadZone = () => setOpenUploadZone(true);

  const handleCloseUploadZone = () => setOpenUploadZone(false);

  const handleOpenDescriptionDialogue = () => setOpenDescriptionDialogue(true);

  const handleCloseDescriptionDialogue = () => setOpenDescriptionDialogue(false);

  const handleOpenFileDescriptionInputModal = () => setOpenFileDescriptionInputModal(true);
  const handleCloseFileDescriptionInputModal = () => setOpenFileDescriptionInputModal(false);

  const handleSaveFile = async uploadFile => {
    if (uploadFile.length === 0) setMutationError("No File Selected");
    try {
      const response = await fileInput({
        variables: {
          input: {
            vendorOpportunityId: props.cs.voId,
            source: "will_be_changed_by_server",
            docType: "decision letter",
            docDescription: "Decision Letter",
            createdBy: "will_be_changed_by_server",
            docName: "will_be_changed_by_server",
            creditSubId: props.cs.creditSubId,
          },
          file: uploadFile[0],
        },
      });
      await documentContext.setLastDocument(response.data.upsertDocument);
      await setFileType("");
      await setFileDescription("");
    } catch (err) {
      setMutationError(err);
      handleOpenErrorDialogue();
      await setFileType("");
      await setFileDescription("");
    }
  };

  return (
    <div className={classes.primaryColor}>
      <Button color="primary" className={classes.button} startIcon={<CloudUploadIcon />} onClick={handleOpenUploadZone}>
        Upload Decision Letter
      </Button>
      <DropzoneDialog
        open={openUploadZone}
        onSave={file => {
          handleSaveFile(file);
          handleCloseUploadZone();
        }}
        onClose={() => {
          handleCloseUploadZone();
        }}
        filesLimit={1}
        dropzoneText={"Drag or Click Here To Upload File"}
        // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        showPreviews={true}
        maxFileSize={5000000}
      />
      <Modal
        open={openErrorDialogue}
        onClose={handleCloseErrorDialogue}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {errorDialogue}
      </Modal>
    </div>
  );
}
