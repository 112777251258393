import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormLabel,
  RadioProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@material-ui/core";

interface RadioGroupOptions {
  value: string;
  label: string;
}

interface Props extends RadioGroupProps {
  label?: string;
  radioProps?: RadioProps;
  options: RadioGroupOptions[];
  formControlProps?: FormControlProps;
}

export const FormRadioGroup = ({
  label,
  options,
  radioProps = {},
  formControlProps = {},
  ...radioGroupProps
}: Props) => {
  return (
    <FormControl {...formControlProps}>
      {!!label && <FormLabel>{label}</FormLabel>}

      <RadioGroup {...radioGroupProps}>
        {options.map(({ label, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            label={label}
            control={<Radio color="primary" {...radioProps} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
