import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import { Box, Button, Typography, Divider, Tooltip } from "@material-ui/core";
import { ContentViewType } from "../../../../../global";
import { isDesktop, isMobile } from "../../../../../utils/contentViewType";

type PreparedEquipmentForRender = { equipmentId: string; data: Record<string, string>[] }[];

interface Props {
  equipmentData: any;
  vendorProfile: any;
  onCreateEquipment: VoidFunction;
  contentViewType: ContentViewType;
  onUpdateEquipment: (equipmentId: string) => void;
}

export const EquipmentTab = ({
  equipmentData,
  vendorProfile,
  contentViewType,
  onCreateEquipment,
  onUpdateEquipment,
}: Props) => {
  const classes = useStyles();

  const isEquipmentExist = useMemo<boolean>(
    () => !!equipmentData?.equipments && !!equipmentData?.equipments?.length && vendorProfile,
    [equipmentData, vendorProfile]
  );

  const preparedEquipmentForRender = useMemo<PreparedEquipmentForRender>(() => {
    if (!isEquipmentExist) return [];

    return equipmentData?.equipments?.map((equipment: any) => ({
      equipmentId: equipment?.equipmentId,
      data: [
        { label: "Condition", value: equipment?.quantity },
        { label: "Year", value: equipment?.year },
        { label: "Make", value: equipment?.make },
        { label: "Model", value: equipment?.model },
        { label: "Serial Number/VIN", value: equipment?.serialNumberVin },
        { label: "Stock ID / Unit Number", value: equipment?.stockId },
        { label: "Mileage", value: equipment?.mileage },
        {
          label: "Attachments",
          value: equipment?.attachments?.map((attachment: any) => attachment?.type).join(", "),
        },
      ],
    }));
  }, [equipmentData, isEquipmentExist]);

  return (
    <Box className={classes.container}>
      <Box
        className={clsx(classes.panel, {
          [classes.panelDesktop]: isDesktop(contentViewType),
          [classes.panelMobile]: isMobile(contentViewType),
        })}
      >
        {isEquipmentExist && (
          <Typography component="span" variant="subtitle2">
            Equipment
          </Typography>
        )}

        <Button size="small" color="primary" variant="outlined" onClick={onCreateEquipment}>
          Add equipment
        </Button>
      </Box>

      {isEquipmentExist && (
        <Box className={classes.content}>
          {preparedEquipmentForRender.map(({ equipmentId, data }) => (
            <Box key={equipmentId} className={classes.dataViewContainer}>
              <Box className={classes.editBtnContainer}>
                <Tooltip title="Edit equipment" placement="top">
                  <IconButton
                    focusRipple
                    size="small"
                    disableRipple
                    className="btn"
                    onClick={() => onUpdateEquipment(equipmentId)}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box className={classes.mainViewContainer}>
                {data.map(({ label, value }) => (
                  <Box key={value} className={classes.dataViewItem}>
                    <Typography component="span" variant="subtitle2" color="textSecondary">
                      {label}:
                    </Typography>

                    <Typography component="span" variant="body2">
                      {value ?? <RemoveIcon />}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Divider orientation="horizontal" variant="fullWidth" flexItem style={{ height: "1px" }} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  container: {
    gap: "12px",
    display: "flex",
    marginTop: "16px",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  panel: {
    display: "flex",
    justifyContent: "flex-end",
  },
  panelDesktop: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  panelMobile: {
    gap: "4px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  dataViewContainer: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  mainViewContainer: {
    gap: "16px",
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  dataViewItem: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  editBtnContainer: {
    "& .btn": {
      borderRadius: "4px",
      border: `1px solid ${palette.primary.main}`,
    },
  },
}));
