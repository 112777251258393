import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ConfigurableItemActions } from "../ConfigurableItemActions";
import { InsertionContainer } from "../InsertionContainer";

import { ControlTypes } from "../../../DynamicOCA/untils/types";
import { configurableItemsTypes, ocaSectionTypes, pcwMovingElementActionTypes } from "../../../Prequal/constants";
import { blockTypes, getConfigurableItemWarnMessage } from "../../../../services/creditAppModalService";
import {
  getCoApplicantFieldsOCAConfiguration,
  getEquipmentFieldsOCAConfiguration,
  getReferenceFieldsOCAConfiguration,
} from "../../../../services/dynamicOCAService";

import _ from "lodash";

const useStyles = makeStyles(theme => ({
  fieldRoot: {
    margin: "16px 0",
    border: "1px dashed grey",
    cursor: "move",
  },
  fieldLeftContainer: {
    padding: "16px",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  fieldKey: {
    padding: "16px",
    backgroundColor: "#e0e0e0",
  },
  deleteIcon: {
    color: "#c62626",
    cursor: "pointer",
  },
}));

export function Field({
  section,
  block,
  field,
  fields,
  sectionIndex,
  blockIndex,
  fieldIndex,
  moveField,
  setJSONDefinition,
  addNewField,
  editConfigurableItem,
  movingElementSettings,
  setMovingElementSettings,
  isFieldMoving,
  handleFieldInsertion,
}) {
  const classes = useStyles();
  const ref = useRef(null);

  const isElementMoving = !!movingElementSettings.type;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: configurableItemsTypes.field,
    item: { field, fieldIndex },
    /*     end: (field, monitor) => {
      const dropResult = monitor.getDropResult();
    }, */
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ handlerId }, drop] = useDrop({
    accept: [ControlTypes.CONTROL, configurableItemsTypes.field],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        canDrop: monitor.canDrop(),
      };
    },
    canDrop: (item, monitor) => {
      if (monitor.getItemType() !== ControlTypes.CONTROL) {
        return false;
      }
      const isNeedToCheckForBlockType = block.type || item.sectionTypes;
      if (isNeedToCheckForBlockType) {
        if (block.type) {
          // typed block
          return _.includes(item.sectionTypes, block.type);
        }

        if (!block.type) {
          // common block (non typed)
          return _.some(item.sectionTypes) ? _.includes(item.sectionTypes, ocaSectionTypes.common) : true;
        }
        return true;
      }
      return true;
    },
    hover(field, monitor) {
      if (!ref.current || !monitor.canDrop()) {
        return;
      }
      const hoverIndex = fieldIndex;
      const isDynamicReferenceField = block.type === blockTypes.dynamicReference;
      const isCoApplicantField = block.type === blockTypes.coApplicant;
      const isEquipmentField = block.type === blockTypes.equipment;
      switch (true) {
        case isDynamicReferenceField:
          field = _.first(
            getReferenceFieldsOCAConfiguration([field.config.fieldName], block.referenceType, block.referenceIdx)
          );
          break;
        case isEquipmentField:
          field = _.first(
            getEquipmentFieldsOCAConfiguration([field.config.fieldName], block.equipmentType, block.equipmentIdx)
          );
          break;
        case isCoApplicantField:
          field = _.first(getCoApplicantFieldsOCAConfiguration([field.config.fieldName]));
          break;
      }
      const alreadyAddedField = _.find(fields, { key: _.get(field, "config.fieldName") });
      const isNeedToAddNewField = monitor.getItemType() === ControlTypes.CONTROL && !alreadyAddedField;
      if (isNeedToAddNewField) {
        addNewField(field, hoverIndex);
        return;
      }
      const dragIndex = _.has(field, "fieldIndex")
        ? field.fieldIndex
        : _.some(fields) && _.findIndex(fields, alreadyAddedField);
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveField(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      field.fieldIndex = hoverIndex;
    },
  });

  const onDelete = () => {
    setJSONDefinition(prevState => {
      const sectionToUpdate = prevState.sections[sectionIndex];
      const blockToUpdate = sectionToUpdate.blocks[blockIndex];
      const updatedBlock = {
        ...blockToUpdate,
        fields: blockToUpdate.fields.filter((field, idx) => idx !== fieldIndex),
      };

      return {
        ...prevState,
        sections: _.map(prevState.sections, (section, idx) => {
          if (idx === sectionIndex) {
            return {
              ...sectionToUpdate,
              blocks: _.map(sectionToUpdate.blocks, (block, idx) => (idx === blockIndex ? updatedBlock : block)),
            };
          }
          return section;
        }),
      };
    });
  };

  const isCurrentFieldMoving = () => {
    return (
      isFieldMoving() &&
      movingElementSettings.sectionIndex === sectionIndex &&
      movingElementSettings.blockIndex === blockIndex &&
      movingElementSettings.fieldIndex === fieldIndex
    );
  };

  const isNeedToShowMoveFieldInsertionContainerTop = () => {
    return isFieldMoving() && fieldIndex === 0 && !isCurrentFieldMoving();
  };

  const isNeedToShowMoveFieldInsertionContainerBottom = () => {
    const isNextFieldMoving = () => {
      return (
        isFieldMoving() &&
        movingElementSettings.sectionIndex === sectionIndex &&
        movingElementSettings.blockIndex === blockIndex &&
        movingElementSettings.fieldIndex === fieldIndex + 1
      );
    };

    return isFieldMoving() && !(isCurrentFieldMoving() || isNextFieldMoving());
  };

  const fieldTitle = _.get(field, "config.title", "");
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box>
      {isNeedToShowMoveFieldInsertionContainerTop() && (
        <InsertionContainer
          type={configurableItemsTypes.block}
          onClickFn={() => handleFieldInsertion(fieldIndex, ref)}
        />
      )}

      <Box className={classes.fieldRoot} style={{ opacity }} ref={ref} data-handler-id={handlerId}>
        <Grid container>
          <Grid item xs={8} container className={classes.fieldLeftContainer}>
            <Box className={classes.fieldTitle}>
              <b>{fieldTitle}</b>
            </Box>
            {!isElementMoving && (
              <ConfigurableItemActions
                warningMessage={getConfigurableItemWarnMessage(field, configurableItemsTypes.field)}
                onMove={() =>
                  setMovingElementSettings({
                    action: pcwMovingElementActionTypes.move,
                    type: configurableItemsTypes.field,
                    sectionIndex,
                    blockIndex,
                    fieldIndex,
                    refEl: ref,
                  })
                }
                onEdit={() => editConfigurableItem(field, configurableItemsTypes.field, section, block, field)}
                onDelete={onDelete}
                itemType={configurableItemsTypes.field}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.fieldKey}>
              <span>{field.key}</span>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isNeedToShowMoveFieldInsertionContainerBottom() && (
        <InsertionContainer
          type={configurableItemsTypes.block}
          onClickFn={() => handleFieldInsertion(fieldIndex + 1, ref)}
        />
      )}
    </Box>
  );
}
