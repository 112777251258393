import React from "react";
import _ from "lodash";
import ImageIcon from "@material-ui/icons/Image";
import { Avatar, Typography } from "@material-ui/core";

const config = require("../../../../config");

interface Props {
  lenderWaterfallResult: any;
}

export const LenderInfo = ({ lenderWaterfallResult }: Props) => {
  const avatarLink = `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${_.get(
    lenderWaterfallResult,
    "runtimeOutput.event.params.lenderProfile.logo"
  )}`;

  return (
    <>
      <Avatar style={{ display: "inline-flex" }} alt="img" src={avatarLink}>
        <ImageIcon textRendering={lenderWaterfallResult.lender} />
      </Avatar>

      <Typography component="span" variant="body2">
        {_.get(lenderWaterfallResult, "runtimeOutput.event.params.lenderProfile.name")}
      </Typography>
    </>
  );
};
