import React, { useState } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import _ from "lodash";

export function SelectField({ fieldConfig, selectValue, changeSelectValue }) {
  const [value, setValue] = useState(selectValue || "");

  return (
    <FormControl variant="standard" fullWidth={fieldConfig.fullWidth} style={{ pointerEvents: "none" }}>
      <InputLabel id={fieldConfig.fieldName} required={fieldConfig.required}>
        {_.get(fieldConfig, "displayLabel.en", "")}
      </InputLabel>
      <Select
        value={value}
        labelId={fieldConfig.fieldName}
        disabled={fieldConfig.disabled}
        required={fieldConfig.required}
      >
        {fieldConfig.fieldOptions.map((option, index) => {
          return (
            <MenuItem key={index} value={option.optionValue}>
              {_.get(option, "displayLabel.en", "")}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{_.get(fieldConfig, "helperText.en", "")}</FormHelperText>
    </FormControl>
  );
}
