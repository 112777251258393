import React from "react";
import clsx from "clsx";
import { grey } from "@material-ui/core/colors";
import { makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useMenuOptionTerms } from "../../../hooks";

interface Props {
  menuOption: any;
}

export const MenuTitle = ({ menuOption }: Props) => {
  const classes = useStyles({
    color: menuOption?.titleColor,
    backgroundColor: menuOption?.titleBgColor,
  });

  const {
    forwardTooltip,
    backwardTooltip,
    handleUpdateTerm,
    isForwardDisabled,
    isBackwardDisabled,
  } = useMenuOptionTerms();

  return (
    <Box className={classes.termPanel}>
      <Tooltip title={backwardTooltip}>
        <IconButton size="small" disabled={isBackwardDisabled} onClick={() => handleUpdateTerm("sub")}>
          <ArrowBackIos className={clsx(classes.actionIcon, { [classes.disabledActionIcon]: isBackwardDisabled })} />
        </IconButton>
      </Tooltip>

      <Typography component="span" variant="subtitle2">
        {menuOption.name}
      </Typography>

      <Tooltip title={forwardTooltip}>
        <IconButton size="small" disabled={isForwardDisabled} onClick={() => handleUpdateTerm("add")}>
          <ArrowForwardIos className={clsx(classes.actionIcon, { [classes.disabledActionIcon]: isForwardDisabled })} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { color: string; backgroundColor: string }>(({ palette }) => ({
  termPanel: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    justifyContent: "space-between",
    color: ({ color }) => color ?? palette.common.black,
    backgroundColor: ({ backgroundColor }) => backgroundColor ?? grey["A100"],
  },
  actionIcon: {
    color: ({ color }) => color ?? palette.common.black,
  },
  disabledActionIcon: {
    opacity: "0.5",
  },
}));
