import React from "react";
import clsx from "clsx";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green, blueGrey, red } from "@material-ui/core/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import { FormattedText } from "./FormattedText";
import { makeStyles } from "@material-ui/core/styles";

export const ResultAdditionalInfoMobile = ({ entity }: any) => {
  const classes = useStyles();

  const additionalDate = entity?.runtimeOutput?.conditions?.all ?? [];

  const getViewResultByIcon = (value: boolean) => {
    return value ? <CheckCircleIcon style={{ color: green["700"] }} /> : <CancelIcon style={{ color: red["700"] }} />;
  };

  return (
    <Box className={classes.container}>
      <Accordion elevation={0} classes={{ root: classes.accordionRoot }}>
        <AccordionSummary
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
            expanded: classes.accordionSummaryExpanded,
          }}
          expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
        >
          <Chip
            size="small"
            label={entity.result}
            icon={getViewResultByIcon(entity.result === "PASS")}
            className={clsx({
              [classes.chipPASS]: entity.result === "PASS",
              [classes.chipFAIL]: entity.result !== "PASS",
            })}
          />
        </AccordionSummary>

        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
          <Box className={classes.resultContainer}>
            {!!additionalDate.length &&
              additionalDate.map((condition: any, index: number, arr: any[]) => (
                <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                  <Box className={classes.resultDetails}>
                    {getViewResultByIcon(condition.result)}

                    <FormattedText key={condition.result} condition={condition} />
                  </Box>

                  {arr.length - 1 !== index && (
                    <Divider orientation="horizontal" flexItem variant="fullWidth" style={{ height: "2px" }} />
                  )}
                </Box>
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useStyles = makeStyles({
  accordionRoot: {
    backgroundColor: blueGrey["50"],

    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryRoot: {
    borderRadius: "4px",
    backgroundColor: "white",

    "&.Mui-expanded": {
      minHeight: "auto",
      marginBottom: "12px",
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  accordionSummaryExpanded: {
    "&.MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  accordionDetailsRoot: {
    borderRadius: "4px",
    backgroundColor: "white",
  },
  container: {
    width: "100%",
    borderRadius: "4px",
  },
  resultContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  resultDetails: {
    display: "flex",
    padding: "0.7rem 0",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "0.7rem",
  },
  chipPASS: {
    color: "#388E3C",
    backgroundColor: "#DCEDC8",
  },
  chipFAIL: {
    color: "#FF1744",
    backgroundColor: "#FEEBEE",
  },
});
