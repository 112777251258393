import gql from "graphql-tag";

export const CREATE_QUOTE = gql`
  mutation CreateQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      id
      success
      message
    }
  }
`;
