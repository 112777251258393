export let invoiceDetailAPIKey = [
  {
    label: "Equipment Cost (Subtotal)",
    apiKey: "equipmentCost",
    format: "currency",
  },
  {
    label: "Other Charges (Installation, shipping, etc)",
    apiKey: "freightLaborInstallation",
    format: "currency",
  },
  {
    label: "Trade-in / Down Payment",
    apiKey: "downPayment",
    format: "currency",
  },
  {
    label: "Sales Tax",
    apiKey: "salesTaxOnInvoice",
    format: "currency",
  },
  { label: "Invoice Total", apiKey: "invoiceTotal", format: "currency" },
];

export const deliveryTimelineAPIKey = [
  { label: "Delivery Status", apiKey: "deliveryStatus", format: "upperCase" },
  { label: "Order Date", apiKey: "orderDate", format: "date" },
  {
    label: "Expected Delivery Date",
    apiKey: "expectedDeliveryDate",
    format: "date",
  },
  {
    label: "Equipment Acceptance Date",
    apiKey: "equipmentAcceptanceDate",
    format: "date",
  },
  { label: "Invoice Status", apiKey: "invoiceStatus" },
  { label: "Delivery Date", apiKey: "deliveryDate", format: "date" },
];
