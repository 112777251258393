import { Column } from "react-table";
import moment from "moment";
import React from "react";
import { DefaultTableCell } from "../../../Table/DefaultTableCell";
import { StatusCell, PriorityCell } from "../../../TasksCommon/tableCells";

export function createTableColumns(): Array<Column<any>> {
  return [
    {
      Header: "Created On",
      accessor: "createdDateMilliseconds",
      resizable: false,
      Cell: ({ value }: any) => (
        <DefaultTableCell value={value} formatter={() => (value ? moment(value).format("ll") : "")} />
      ),
    },
    {
      Header: "Assigned To",
      accessor: "assignedToUser",
      Cell: (props: any) => {
        const row = props.original;
        const applicantTask = row.assignedToApplicant;
        if (applicantTask) {
          return <DefaultTableCell value={!props.value ? "Applicant" : props.value} />;
        } else {
          return <DefaultTableCell value={!props.value ? "Dealer" : props.value} />;
        }
      },
    },
    {
      Header: "Regarding",
      accessor: "regarding",
      Cell: ({ value }: any) => <DefaultTableCell value={value} />,
    },
    {
      Header: "Completed On",
      accessor: "completedDateTime",
      resizable: false,
      Cell: ({ value }: any) => (
        <DefaultTableCell value={value} formatter={() => (value ? moment(value).format("ll") : "")} />
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ original: task }: any) => <StatusCell task={task} />,
    },
    {
      Header: "Priority",
      accessor: "priority",
      Cell: ({ original: task }: any) => <PriorityCell task={task} />,
    },
  ].map(header => ({ ...header, headerStyle: { fontWeight: "500", padding: "0.5rem" } }));
}
