import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { ControlTypes } from "../../untils/types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Tooltip } from "@material-ui/core";
import { RuleTooltipDescription } from "../RuleTooltipDescription";
import { ocaLegacyReferenceFields } from "../../../../pages/Prequal/constants";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import { Element } from "./Element";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  pageElement: {
    border: props => (props.isOver ? "1px dashed black" : 0),
    opacity: props => (props.isDragging ? 0.5 : 1),
  },
  hasDependenciesLbl: {
    fontSize: 13,
    color: "#3870ef",
    textDecoration: "underline",
    textDecorationStyle: "dotted",
    textDecorationThickness: "2px",
  },
  apiLabel: {
    fontSize: 12,
    float: "right",
    backgroundColor: "#c7e1ba",
    textDecoration: "underline",
    border: "1px solid #953333",
    display: "inline-block",
    padding: "2px 5px",
    boxShadow: "2px 3px #a19121",
    textAlign: "center",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  labelLegacy: {
    backgroundColor: "#eb2521",
    boxShadow: "none",
  },
  importExportIcon: {
    cursor: "pointer",
    color: "#5a78e1",
    float: "right",
    fontSize: "28px",
  },
  addCircleOutlineIcon: {
    cursor: "pointer",
    color: "green",
    fontSize: "48px",
    marginLeft: "15px",
  },
  addCircleOutlineIconZeroIndex: {
    margin: "0 15px 0 0",
  },
}));

export function FormElement({
  item,
  index,
  onDrop,
  page,
  sectionIndex,
  setSelectedItem,
  isFormElementMoving,
  setIsFormElementMoving,
  handleFieldInsertion,
  setFormElementMovingSettings,
}) {
  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: ControlTypes.ELEMENT,
      item: {
        item,
        index,
        sectionIndex,
        page,
      },
      collect: monitor => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    };
  }, [item]);

  const [{ isOver }, drop] = useDrop(() => {
    return {
      accept: ControlTypes.ELEMENT,
      collect: monitor => {
        return {
          isOver: monitor.isOver(),
        };
      },
      canDrop: item => {
        if (page !== item.page) {
          return false;
        }

        return true;
      },
      drop: (item, monitor) => {
        onDrop(item.index, index, page, item.sectionIndex, sectionIndex);
      },
    };
  }, [item]);

  const getRef = element => {
    drag(element);
    drop(element);
  };

  const classes = useStyles({ isOver, isDragging });
  const isSpacerField = _.get(item, "type", null) === "spacer-field";
  const isLegacyField = _.includes(ocaLegacyReferenceFields, item.config.fieldName);
  return (
    <Box
      className={classes.pageElement}
      ref={getRef}
      onClick={() => setSelectedItem(item)}
      component={Grid}
      item
      container
      xl={_.get(item, "config.columns.xl", 12)}
      lg={_.get(item, "config.columns.lg", 12)}
      md={_.get(item, "config.columns.md", 12)}
      sm={_.get(item, "config.columns.sm", 12)}
      xs={_.get(item, "config.columns.xs", 12)}
    >
      {isFormElementMoving && index === 0 && (
        <Grid item container justify="center" alignItems="center" xs={1}>
          <AddCircleOutlineIcon
            className={[classes.addCircleOutlineIcon, classes.addCircleOutlineIconZeroIndex].join(" ")}
            onClick={event => {
              event.stopPropagation();
              handleFieldInsertion(page, sectionIndex, 0);
            }}
          />
        </Grid>
      )}
      <Grid item xs={isFormElementMoving ? (index === 0 ? 10 : 11) : 12}>
        <Element item={item} sectionIndex={sectionIndex} page={page} />
        {!isSpacerField ? (
          <Tooltip
            title={item.config.fieldName + " " + (isLegacyField ? "(Legacy field)" : "")}
            placement="top-start"
            leaveDelay={1000}
          >
            <span className={[classes.apiLabel, isLegacyField ? classes.labelLegacy : ""].join(" ")}>API ID</span>
          </Tooltip>
        ) : null}
        {!isFormElementMoving && (
          <ImportExportIcon
            className={classes.importExportIcon}
            onClick={event => {
              event.stopPropagation();
              setIsFormElementMoving(true);
              setFormElementMovingSettings({
                pageIndex: page,
                sectionIndex,
                fieldIndex: index,
              });
            }}
          />
        )}
        {_.size(item.rules) ? (
          <span>
            <RuleTooltipDescription rules={item.rules} rulesSatisfactionType={item.rulesSatisfactionType}>
              <Button variant="text" size="small" className={classes.hasDependenciesLbl}>
                Dependent field
              </Button>
            </RuleTooltipDescription>
          </span>
        ) : null}
      </Grid>
      {isFormElementMoving && (
        <Grid item container justify="center" alignItems="center" xs={1}>
          <AddCircleOutlineIcon
            className={classes.addCircleOutlineIcon}
            onClick={event => {
              event.stopPropagation();
              handleFieldInsertion(page, sectionIndex, index + 1);
            }}
          />
        </Grid>
      )}
    </Box>
  );
}
