import { useMutation } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { ProposalMenu, ProposalMenuOption /* ProposalProduct */ } from "@trnsact/trnsact-shared-types";
import { useState } from "react";
import {
  CREATE_PROPOSAL_MENU,
  CREATE_PROPOSAL_MENU_OPTION,
  UPDATE_PROPOSAL_MENU,
  UPDATE_PROPOSAL_MENU_OPTION,
} from "../api";
import { asyncForEach } from "utils";

import { menuConstructorSelectors } from "../model";
import { MenuOptionToAddEdit } from "../types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

export function useSavingMenu(proposalMenu: ProposalMenu) {
  const menuOptionsToArchive = useSelector(menuConstructorSelectors.archivedMenuOptions) as MenuOptionToAddEdit[];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [createProposalMenu] = useMutation(CREATE_PROPOSAL_MENU);
  const [updateProposalMenu] = useMutation(UPDATE_PROPOSAL_MENU);
  const [createProposalMenuOption] = useMutation(CREATE_PROPOSAL_MENU_OPTION);
  const [updateProposalMenuOption] = useMutation(UPDATE_PROPOSAL_MENU_OPTION);

  const getProductMenuOptionLinkingCriteria = (p: ProposalProduct) => ({
    productId: p.proposalProductId,
    cost: p.cost,
    retailCost: p.retailCost,
    markup: {
      type: p.markup.type,
      markup: p.markup.markup,
    },
  });

  const updateMenu = async () => {
    setIsLoading(true);

    try {
      const menuPayload = {
        proposalMenuId: proposalMenu.proposalMenuId,
        name: proposalMenu.name,
        description: proposalMenu.description,
      };

      await updateProposalMenu({
        variables: {
          input: menuPayload,
        },
      });

      const menuOptionsToAdd = proposalMenu.menuOptions?.filter(
        (option: ProposalMenuOption) => !option.proposalMenuOptionId
      );
      const menuOptionsToEdit = proposalMenu.menuOptions?.filter(
        (option: ProposalMenuOption) => option.proposalMenuOptionId
      );

      await asyncForEach(menuOptionsToAdd, async (menuOption: any) => {
        try {
          await createProposalMenuOption({
            variables: {
              input: {
                name: menuOption.name,
                description: menuOption.description,
                titleColor: menuOption.titleColor,
                titleBgColor: menuOption.titleBgColor,
                proposalMenuLinkingCriteria: [
                  {
                    proposalMenuId: proposalMenu.proposalMenuId,
                    ordinal: menuOption.ordinal,
                  },
                ],
                proposalProductMenuOptionLinkingCriteria: menuOption.products?.map(getProductMenuOptionLinkingCriteria),
              },
            },
          });
        } catch (error) {
          console.error(error);
        }
      });

      await asyncForEach(menuOptionsToEdit, async (menuOption: any) => {
        try {
          await updateProposalMenuOption({
            variables: {
              input: {
                proposalMenuOptionId: menuOption.proposalMenuOptionId,
                name: menuOption.name,
                description: menuOption.description,
                titleColor: menuOption.titleColor,
                titleBgColor: menuOption.titleBgColor,
                proposalProductMenuOptionLinkingCriteria: menuOption.products?.map(getProductMenuOptionLinkingCriteria),
              },
            },
          });
        } catch (error) {
          console.error(error);
        }
      });

      await asyncForEach(menuOptionsToArchive, async (menuOption: any) => {
        try {
          await updateProposalMenuOption({
            variables: {
              input: {
                proposalMenuOptionId: menuOption.proposalMenuOptionId,
                archived: menuOption.archived,
              },
            },
          });
        } catch (error) {
          console.error(error);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createMenu = async () => {
    setIsLoading(true);

    const formatMenuOptionForPayload = (menuOption: any /* MenuOptionToAddEdit */) => {
      return {
        name: menuOption.name,
        description: menuOption.description,
        titleColor: menuOption.titleColor,
        titleBgColor: menuOption.titleBgColor,
        proposalProductMenuOptionLinkingCriteria: menuOption.products?.map(getProductMenuOptionLinkingCriteria),
      };
    };

    try {
      const menuPayload = {
        name: proposalMenu.name,
        description: proposalMenu.description,
        newMenuOptions: proposalMenu.menuOptions?.map(formatMenuOptionForPayload),
      };

      try {
        await createProposalMenu({
          variables: {
            input: menuPayload,
          },
        });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updateMenu,
    createMenu,
    isLoading,
  };
}
