export const ControlTypes = {
  PAGE: "page",
  SECTION: "section",
  CONTROL: "control",
  ELEMENT: "element",
  OWNERS: "owners-array",
  SIGNATURE: "signatures-simple",
};

export const Steps = {
  WELCOME: 0,
  CLONE_MODE: 1,
  START_FROM_SCRATCH: 2,
};
