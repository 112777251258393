import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const extendedFormsStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    fontSize: "12px",
    color: "#3E4857",
    fontWeight: "400",
    lineHeight: "1.428571429",
    display: "inline-flex",
  },
  mrAuto: {
    marginRight: "auto",
  },
  mlAuto: {
    marginLeft: "auto",
  },
  stateSelector: {
    color: "#AAAAAA !important",
    height: "unset",
    fontSize: "14px",
    top: "-3px",
    "& .MuiInputLabel-root": {
      fontSize: 14,
      color: "#AAAAAA !important",
    },
    "& .MuiInputBase-root:before": {
      borderBottomColor: "#D2D2D2",
    },
    "& .MuiFormLabel-filled": {
      fontSize: 14,
    },
    "& .Mui-focused": {
      fontSize: 14,
    },
  },
};

export default extendedFormsStyle;
