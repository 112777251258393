import React, { useMemo } from "react";
import { Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ReadOnlyQuill } from "./ReadOnlyQuill";

interface Props {
  product: ProposalProduct;
}

export const ProductCatalogDetails = ({ product }: Props) => {
  const classes = useStyles();

  const { isDataExist, productSummary, coverageDetails } = useMemo(() => {
    const data = {
      productSummary: product?.aftermarketProduct?.aftermarketProductCatalogDetails?.productSummary,
      coverageDetails: product?.aftermarketProduct?.aftermarketProductCatalogDetails?.coverageDetails,
    };

    return {
      ...data,
      isDataExist: !!Object.values(data).length,
    };
  }, [product]);

  return (
    <>
      {isDataExist && (
        <Box className={classes.container}>
          {productSummary && (
            <>
              <ReadOnlyQuill value={productSummary} />

              <Divider />
            </>
          )}

          {coverageDetails && <ReadOnlyQuill value={coverageDetails} />}
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "6px",
    display: "flex",
    flexDirection: "column",
  },
});
