export const calcCreditProducts = ({ monthOptions, buyRate, points, loanAmount }) => {
  const creditProducts = monthOptions.map((months, i) => {
    const { sellPayment, simpleInterest, standardSingleDown } = calculateRatesAndPayments(
      loanAmount,
      months,
      buyRate,
      points
    );
    const deferredPayment = numberWithCommas(parseInt(sellPayment * 1.03)); // * 1.03 is for 90 day deferred
    return {
      months,
      payment: numberWithCommas(parseInt(sellPayment)),
      deferredPayment,
      simpleInterestRate: simpleInterest,
      standardSingleDown: numberWithCommas(parseInt(standardSingleDown)),
      rate: 0,
    };
  });
  return creditProducts;
};

export const lookupInterestRate = RUId => {
  switch (RUId) {
    case "674be5a4-94f7-11d1-4256-c2d716bf8d55": // A+ Default
      return { buyRate: 0.0524, points: 0 };
    case "dbf6c8e5-2403-b0c6-8ea6-47ec8fa0d75 d": // A Default
      return { buyRate: 0.09, points: 0 };
    case "ac3df399-f99f-1396-4518-77d26a8fbc29": // B Default
      return { buyRate: 0.12, points: 0 };
    case "c6aebca3-a572-da9f-b632-2e90398d527e": // C Default
      return { buyRate: 0.18, points: 0 };
    case "ddecaa54-1711-2622-207d-ccbea906746b": // D Default
      return { buyRate: 0.25, points: 0 };
    case "e55778e1-4c01-ee84-b42c-5e58c59cc333": // A+ Dental
      return { buyRate: 0.065, points: 0.07 };
    case "119ac09e-571a-5ade-9c5f-dcbd4ac16143": // A Dental
      return { buyRate: 0.075, points: 0.08 };
    case "bf0063b2-278c-ec32-8e0c-e7937d750db3": // B Dental
      return { buyRate: 0.095, points: 0.09 };
    case "fce1a8ae-cd90-e31f-42d8-0afee8db9374": // C Dental
      return { buyRate: 0.165, points: 0.1 };
    case "3acc06fd-0a76-1285-c734-8ef85609a86d": // D Dental
      return { buyRate: 0.205, points: 0.1 };
    default:
      return { buyRate: 0.09, points: 0 };
  }
};

export const calculateRatesAndPayments = (presentValue, terms, buyRate, points) => {
  presentValue = parseFloat(presentValue);
  const buyRatePayment = calculateBuyRatePayment(buyRate / 12, terms, presentValue);
  const sellPayment = buyRatePayment * (1 + points);
  const simpleInterest = calculateSimpleInterestRate(sellPayment, terms, presentValue);
  const sellRate = calcRate(terms, -sellPayment, presentValue) * 12;
  const standardSingleDown = calculateMonthlyPayment(terms, sellRate, presentValue, 0);
  return {
    buyRatePayment,
    sellPayment,
    simpleInterest,
    sellRate,
    standardSingleDown,
  };
};

const calculateSimpleInterestRate = (monthlyPayment, length, amountRequested) => {
  return ((monthlyPayment * length - amountRequested) * 12) / amountRequested / length;
};

const calcRate = (periods, payment, present, future, type, guess) => {
  // https://gist.github.com/kucukharf/677d8f21660efaa33f72
  guess = guess === undefined ? 0.01 : guess;
  future = future === undefined ? 0 : future;
  type = type === undefined ? 0 : type;

  // Set maximum epsilon for end of iteration
  var epsMax = 1e-10;

  // Set maximum number of iterations
  var iterMax = 10;

  // Implement Newton's method
  var y,
    y0,
    y1,
    x0,
    x1 = 0,
    f = 0,
    i = 0;
  var rate = guess;
  if (Math.abs(rate) < epsMax) {
    y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
  } else {
    f = Math.exp(periods * Math.log(1 + rate));
    y = present * f + payment * (1 / rate + type) * (f - 1) + future;
  }
  y0 = present + payment * periods + future;
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
  i = x0 = 0;
  x1 = rate;
  while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = y1;
    y1 = y;
    ++i;
  }
  return rate;
};

const calculateMonthlyPayment = (termLength, interest, amountRequested, numAdvancedPayments = 1) => {
  const interestByMonth = interest / 12;
  // See formula at the bottom of http://www.tvmcalcs.com/index.php/calculators/apps/lease_payments
  let monthlyPayment =
    amountRequested /
    ((1 - 1 / Math.pow(1 + interestByMonth, termLength - numAdvancedPayments)) / interestByMonth + numAdvancedPayments);
  monthlyPayment = parseInt(monthlyPayment);
  if (monthlyPayment === Infinity || isNaN(monthlyPayment)) {
    monthlyPayment = 0;
  }
  return monthlyPayment;
};

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const calculateBuyRatePayment = (R, n, Pv) => {
  // https://superuser.com/questions/871404/what-would-be-the-the-mathematical-equivalent-of-this-excel-formula-pmt
  // R = Periodic Interest Rate = BuyRate / 12;
  // n = Total # of interest periods;
  // Pv = Present Value;
  // P = monthly payment
  const P = (Pv * R) / (1 - Math.pow(1 + R, -n));
  return P;
};

export const residualPMT = (ir, np, pv, fv, type) => {
  //https://stackoverflow.com/questions/5294074/pmt-function-in-javascript user: vault
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
};
