import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  makeStyles,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { GET_PROPOSAL_MENUS, UPDATE_PROPOSAL_MENU } from "modules/aftermarketMenuConstructor/api";
import { ProposalsDataResponse } from "modules/aftermarketMenuConstructor/api/types";
import {
  MenuTemplatesTableFilters,
  ProposalMenuSearchableColumns,
  ProposalMenuSearchableColumnsEnum,
} from "modules/aftermarketMenuConstructor/types";
import { menuTemplatesTableInitialFilters } from "modules/aftermarketMenuConstructor/constants";
import { usePermissions } from "modules/aftermarketMenuConstructor/hooks/usePermissions";
import { ProposalMenu, ProposalProduct } from "@trnsact/trnsact-shared-types";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { FiltersPanel } from "components/shared/FiltersPanel";
import ConfirmationDialog from "components/ConfirmationDialog";
import { format } from "date-fns";
import _ from "lodash";

export const MenuTemplatesTable = () => {
  const classes = useStyles();

  const history = useHistory();

  const [proposalMenus, setProposalMenus] = useState<ProposalMenu[]>();
  const [proposalMenusToShow, setProposalMenusToShow] = useState<ProposalMenu[]>();
  const [tableFilters, setTableFilters] = useState<MenuTemplatesTableFilters>(menuTemplatesTableInitialFilters);
  const [proposalMenuToDelete, setProposalMenuToDelete] = useState<ProposalMenu>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const { hasWriteAccess, hasReadOnlyAccess } = usePermissions();

  const { data: proposalMenusData, loading, refetch: refetchProposalMenus } = useQuery<ProposalsDataResponse>(
    GET_PROPOSAL_MENUS,
    {
      variables: { input: {} },
      fetchPolicy: "no-cache",
    }
  );

  const [updateProposalMenu, { loading: updateLoading }] = useMutation(UPDATE_PROPOSAL_MENU, {
    onCompleted() {
      refetchProposalMenus();
    },
  });

  useEffect(() => {
    if (proposalMenusData?.proposalMenus) {
      setProposalMenus(proposalMenusData.proposalMenus);
    }
  }, [proposalMenusData]);

  useEffect(() => {
    let proposalMenusToShow = tableFilters.search
      ? proposalMenus?.filter((proposalMenu: ProposalMenu) =>
          Object.values(ProposalMenuSearchableColumnsEnum).some((column: ProposalMenuSearchableColumns) =>
            proposalMenu[column]?.toLowerCase().includes(tableFilters.search.toLowerCase())
          )
        )
      : proposalMenus;
    proposalMenusToShow = _.orderBy(proposalMenusToShow, [(p: ProposalMenu) => new Date(p.createdDateTime!)], "desc");
    setProposalMenusToShow(proposalMenusToShow);
  }, [proposalMenus, tableFilters]);

  const handleDeleteMenuTemplateClick = (proposalMenuToDelete: ProposalMenu) => {
    setProposalMenuToDelete(proposalMenuToDelete);
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = (event: any, reason: string) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      updateProposalMenu({
        variables: {
          input: {
            proposalMenuId: proposalMenuToDelete!.proposalMenuId,
            archived: true,
          },
        },
      });
    }
  };

  return (
    <>
      {hasWriteAccess || hasReadOnlyAccess ? (
        <>
          <CardContainer
            title="Menu Templates"
            containerClassName={classes.menuTemplatesContainerClassName}
            actionBtn={
              hasWriteAccess && (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  onClick={() => history.push(`/aftermarket/menu/new`)}
                >
                  Add Template
                </Button>
              )
            }
          >
            <FiltersPanel
              onSearchChange={(filter, nextValue) => {
                setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
              }}
            />

            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Modified</TableCell>
                    {hasWriteAccess && <TableCell>Action</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proposalMenusToShow?.map(proposalMenu => (
                    <TableRow key={proposalMenu.proposalMenuId}>
                      <TableCell>{proposalMenu.name}</TableCell>
                      <TableCell>{proposalMenu.description}</TableCell>
                      <TableCell>
                        {proposalMenu.createdDateTime && format(new Date(proposalMenu.createdDateTime), "M/dd/yyyy")}
                      </TableCell>
                      <TableCell>
                        {proposalMenu.modifiedDateTime && format(new Date(proposalMenu.modifiedDateTime), "M/dd/yyyy")}
                      </TableCell>
                      {hasWriteAccess && (
                        <TableCell>
                          <Grid container spacing={2}>
                            <Grid item>
                              <Tooltip title="Edit Menu Template" placement="top">
                                <IconButton
                                  size="small"
                                  className={clsx(classes.actionBtn, classes.actionBtnEdit)}
                                  onClick={() => history.push(`/aftermarket/menu/edit/${proposalMenu.proposalMenuId}`)}
                                >
                                  <EditIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title="Delete Menu Template" placement="top">
                                <IconButton
                                  size="small"
                                  className={clsx(classes.actionBtn, classes.actionBtnDelete)}
                                  onClick={() => handleDeleteMenuTemplateClick(proposalMenu)}
                                >
                                  <DeleteOutlineIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContainer>

          <ConfirmationDialog
            title="Confirmation"
            message="Are you sure you want to remove Menu?"
            open={isConfirmationModalOpen}
            onClose={onConfiramtionModalClose}
          />
        </>
      ) : (
        <h3>Access Denied</h3>
      )}
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  menuTemplatesContainerClassName: {
    marginTop: 6,
  },
  actionBtn: {
    borderRadius: "4px",
  },
  actionBtnEdit: {
    border: `1px solid ${palette.primary.main}`,
  },
  actionBtnDelete: {
    border: `1px solid ${palette.error.main}`,
  },
}));
