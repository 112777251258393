import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import ConsumerCreditReport from "components/ConsumerCreditReport";
import { theme } from "../../theme";

const TRIGGER_CREDIT_REPORT_BUSINESS_PULL = gql`
  mutation($input: TriggerCreditReportBusinessPullInput!) {
    triggerCreditReportBusinessPull(input: $input) {
      creditReportBusinessId
      errorDescription
      externalReportId
      humanReadableReportDocumentId
      machineReadableReport
      reportType
      score
      vendorOpportunityId
    }
  }
`;

const TRIGGER_CREDIT_REPORT_CONSUMER_PULL = gql`
  mutation($input: TriggerCreditReportConsumerPullInput!) {
    triggerCreditReportConsumerPull(input: $input) {
      creditReportConsumerId
      externalReportId
      vendorOpportunityId
      ownerPgDynamicsId
      ownerPgId
      cbrDynamicsId
      reportType
      score
      machineReadableReport {
        bureauType
        scoreType
        creditBureauType
        humanReadableReportDocumentId
        ownerPgId
        fields {
          key
          value
        }
        ####
        ficoScore
        publicRecordCount
        pastAmountDue
        totalInquiryCount
        satisfactoryAccountsCount
        installmentBalance
        monthlyPayment
        inquiryCountLastSixMonths
        derogAccountsCount
        realEstateBalance
        realEstatePayment
        tradelineCount
        pastDerogAccountsCount
        collectionRecordsCount
        revolvingAvailPercent
        paidAccountsCount
        oldestTradeline
        bankruptcyRecordCount
        revolvingBalance
        revolvingTotalCredit
        errorDescription
      }
      humanReadableReportDocumentId
      errorDescription
      createdDateTime
    }
  }
`;

const TRIGGER_WEBHOOK = gql`
  mutation TriggerWebhook($entityId: String!, $eventType: WebhookEventType!, $accountId: String!) {
    triggerWebhook(entityId: $entityId, eventType: $eventType, accountId: $accountId)
  }
`;

const HealthCheckCard = props => {
  const { owners, refetchCreditApp, creditApp, vp, full } = props;
  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const [pullingCredit, setPullingCredit] = useState(false);
  const [hasConsumerCreditPulled, setHasConsumerCreditPulled] = useState(false);
  const [hasBusinessCreditPulled, setHasBusinessCreditPulled] = useState(false);
  const [hasRedFlagReport, setHasRedFlagReport] = useState(false);

  const [triggerWebhook] = useMutation(TRIGGER_WEBHOOK, {
    context: { authRequired: false },
  });

  const [triggerCreditReportConsumerPull] = useMutation(TRIGGER_CREDIT_REPORT_CONSUMER_PULL, {
    context: { authRequired: true },
  });

  const [triggerCreditReportBusinessPull] = useMutation(TRIGGER_CREDIT_REPORT_BUSINESS_PULL, {
    context: { authRequired: true },
  });

  async function triggerCbrSoftPull(ownerPgId, ownerPgDynamicsId) {
    console.log("#######################################");
    console.log(`OWNER PG ID: ${ownerPgId} | OWNER PG DYNAMICS ID: ${ownerPgDynamicsId}`);
    const variables = {
      input: {
        dynamicsCreditApplicationId: creditApp.dynamicsId,
        ownerPgId: ownerPgId,
        ownerPgDynamicsId: ownerPgDynamicsId,
        creditReporterCode:
          vp.softPullInDealerPortal === "CBC_EQUIFAX_SOFT_SUMMARY_ONLY" ||
          vp.softPullInDealerPortal === "CBC_EQUIFAX_SOFT_FULL_REPORT"
            ? "CBC"
            : "LEGACY_CRM",
      },
    };
    console.log(JSON.stringify({ variables }));
    console.log("#######################################");
    await triggerCreditReportConsumerPull({
      variables,
    });
    setTimeout(async () => {
      await refetchCreditApp();
      setPullingCredit(false);
    }, 1200 * 10);
  }

  async function ownersCreditPull(owners) {
    setPullingCredit(true);
    owners.forEach(async owner => {
      const pgo = owner.personalGuarantor;
      await triggerCbrSoftPull(pgo.personalGuarantorId, pgo.personalGuarantorDynamicsId);
    });
  }

  async function businessCreditPull(creditApp) {
    setPullingCredit(true);
    const variables = {
      input: {
        creditReporterCode: "CBC_OFAC",
        dynamicsCreditApplicationId: creditApp.dynamicsId,
      },
    };
    await triggerCreditReportBusinessPull({
      variables,
    });
    setTimeout(async () => {
      await refetchCreditApp();
      setPullingCredit(false);
    }, 1200 * 10);
  }

  const renderBusinessOFAC = creditApp => {
    const json = creditApp.mostRecentCreditReportOfac?.machineReadableReport;

    if (json) {
      const OFAC = JSON.parse(json).OFAC;

      return (
        <div style={{ display: "flex", width: "100%" }}>
          <p style={{ marginRight: 16 }}>OFAC:</p>
          <p
            style={
              OFAC === "NOHIT"
                ? {
                    color: "#4caf50",
                    fontWeight: "bold",
                  }
                : OFAC === "HIT"
                ? {
                    color: "#ff1744",
                    fontWeight: "bold",
                  }
                : {
                    fontWeight: "bold",
                  }
            }
          >
            {OFAC === "NOHIT" ? "NO HIT" : OFAC}
          </p>
        </div>
      );
    }

    return <></>;
  };

  useEffect(() => {
    if (owners) {
      owners.forEach(owner => {
        if (owner.personalGuarantor?.mostRecentCbrs[0]?.fields) setHasConsumerCreditPulled(true);
      });
    }
    if (creditApp) {
      if (creditApp.mostRecentCreditReportOfac?.machineReadableReport) setHasBusinessCreditPulled(true);
    }
  }, [owners, creditApp]);

  useEffect(() => {
    if (hasRedFlagReport && document) {
      if (document.getElementsByClassName("red_flag_verdict_txt").length > 0) {
        let reportVerdicts = new Array(...document.getElementsByClassName("red_flag_verdict_txt"));

        reportVerdicts.forEach(reportVerdict => {
          reportVerdict.addEventListener("DOMSubtreeModified", e => {
            const ownerName = e.target.offsetParent?.getElementsByClassName("red_flag_name")[0]?.innerText;
            const owner = [...owners].find(
              owner => `${owner.firstName?.toUpperCase()} ${owner.lastName?.toUpperCase()}` === ownerName
            );

            if (account && owner) {
              triggerWebhook({
                variables: {
                  entityId: owner.ownerPgId,
                  eventType: "CREDIT_REPORT_CONSUMER_UPDATED",
                  accountId: account.id,
                },
              });
            } else if (account) {
              owners.forEach(owner => {
                triggerWebhook({
                  variables: {
                    entityId: owner.ownerPgId,
                    eventType: "CREDIT_REPORT_CONSUMER_UPDATED",
                    accountId: account.id,
                  },
                });
              });
            }
          });
        });
      }

      if (document.getElementsByClassName("red_flag_upload").length > 0) {
        new Array(...document.getElementsByClassName("red_flag_upload")).forEach(e => (e.style = "display:none;"));
      }
    }
  }, [hasRedFlagReport]);

  if (owners) {
    return (
      <div className="health-check">
        {!pullingCredit ? (
          <>
            {creditApp.applicationType === "Commercial" && (
              <>
                {hasBusinessCreditPulled ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      marginBottom: 24,
                      padding: "0 16px",
                    }}
                  >
                    <h5 style={{ fontWeight: "bold", marginBottom: 16 }}>{creditApp.businessName}</h5>
                    {renderBusinessOFAC(creditApp)}
                  </div>
                ) : (
                  <Button
                    onClick={() => businessCreditPull(creditApp)}
                    style={{
                      boxShadow:
                        "0 2px 2px 0 rgb(12 132 252 / 14%), 0 3px 1px -2px rgb(12 132 252 / 20%), 0 1px 5px 0 rgb(12 132 252 / 12%)",
                      backgroundColor: theme.palette.primary.main,
                      padding: "0.40625rem 1.25rem",
                      fontSize: "0.6875rem",
                      lineHeight: "1.5",
                      borderRadius: "0.2rem",
                      color: "#fff",
                      margin: 16,
                    }}
                  >
                    Check Business OFAC
                  </Button>
                )}
              </>
            )}
            {hasConsumerCreditPulled ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: full ? "row" : "column",
                  flexWrap: full ? "wrap" : "",
                }}
              >
                {owners.map(owner => {
                  let OFAC = null;
                  let RedFlagCompliance = null;

                  const creditReportForThisOwner = owner.personalGuarantor?.mostRecentCbrs?.find(cbr => {
                    return cbr?.creditBureauType !== "OFAC";
                  });

                  if (creditReportForThisOwner?.fields) {
                    const fields = creditReportForThisOwner.fields;
                    RedFlagCompliance = fields.find(field => field.key === "Red Flag Compliance");

                    if (RedFlagCompliance && !hasRedFlagReport) setHasRedFlagReport(true);
                  }

                  for (let i = 0; i < owner.personalGuarantor?.mostRecentCbrs?.length; i++) {
                    OFAC = owner.personalGuarantor?.mostRecentCbrs[i]?.fields?.find(field => field.key === "OFAC");
                    if (OFAC) {
                      break;
                    }
                  }

                  return (
                    <div
                      key={`owner_${owner.ownerPgId}`}
                      style={{ display: "flex", width: "100%", flexDirection: "column", flex: 1, padding: "0 16px" }}
                    >
                      <h5 style={{ marginBottom: 16, fontWeight: "bold" }}>{owner.firstName + " " + owner.lastName}</h5>
                      {OFAC && (
                        <div style={{ display: "flex", width: "100%" }}>
                          <p style={{ marginRight: 16 }}>OFAC:</p>
                          <p
                            style={
                              OFAC.value === "NO HIT" || "NOHIT"
                                ? {
                                    color: "#4caf50",
                                    fontWeight: "bold",
                                  }
                                : OFAC.value === "HIT"
                                ? {
                                    color: "#ff1744",
                                    fontWeight: "bold",
                                  }
                                : {
                                    fontWeight: "bold",
                                  }
                            }
                          >
                            {OFAC.value === "NOHIT" ? "NO HIT" : OFAC.value}
                          </p>
                        </div>
                      )}
                      {RedFlagCompliance && (
                        <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                          <p>Red Flag Compliance:</p>
                          <ConsumerCreditReport
                            report={RedFlagCompliance.value}
                            ownerId={owner.ownerPgId}
                            accountId={account.id}
                            userName={userProfile.fullName}
                            index={0}
                            creditReportConsumerId={creditReportForThisOwner.creditReportConsumerId}
                            auditLog={creditReportForThisOwner.auditLog}
                            dateReportWasPulled={new Date(creditReportForThisOwner.createdDateTime)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <Button
                onClick={() => ownersCreditPull(owners)}
                style={{
                  boxShadow:
                    "0 2px 2px 0 rgb(12 132 252 / 14%), 0 3px 1px -2px rgb(12 132 252 / 20%), 0 1px 5px 0 rgb(12 132 252 / 12%)",
                  backgroundColor: theme.palette.primary.main,
                  padding: "0.40625rem 1.25rem",
                  fontSize: "0.6875rem",
                  lineHeight: "1.5",
                  borderRadius: "0.2rem",
                  color: "#fff",
                  margin: 16,
                }}
              >
                Pull Applicant's OFAC
              </Button>
            )}
          </>
        ) : (
          <CircularProgress style={{ margin: 16 }} />
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default HealthCheckCard;
