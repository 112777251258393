import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { theme } from "../theme";

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
  },
}));

export default function MessageModal({ title, message, isOpen, handleCloseModal, link }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(isOpen);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">{title}</h2>
      <p id="simple-modal-description">{message}</p>
      {link && (
        <>
          <a
            style={{ cursor: "pointer", color: theme.palette.primary.main }}
            onClick={() => (window.location.href = `/proposalDetail/${link}`)}
          >
            {" "}
            View Created Proposal
          </a>
          <br />
        </>
      )}
      {title === "Sending" || title === "Loading" ? (
        <div />
      ) : (
        <Button
          onClick={() => {
            handleClose();
            handleCloseModal();
          }}
          variant="contained"
          color="primary"
        >
          Done
        </Button>
      )}
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          handleCloseModal();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
