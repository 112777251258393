import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import ReactTable from "react-table";
// material ui
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
// components
import LenderSubmission from "./LenderSubmission";
import Tile from "../../components/Tile/Tile";
import LenderSubmissionDetailModal from "./LenderSubmissionDetailModal";
import { connect } from "react-redux";
import { SET_CS, SET_CS_REFETCH } from "../../redux/types";
import { translateStageCodes } from "utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import { useDispatch } from "react-redux";
import _ from "lodash";
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 600,
  },
  rootButton: {
    minWidth: "45px",
  },
  buttonDetail: {
    display: "inline-block",
    float: "right",
    color: theme.palette.primary.main,
    paddingLeft: 0,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonUpdateCredentials: {
    display: "inline-block",
    color: theme.palette.primary.main,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  right: {
    textAlign: "right",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  wrapperLoader: {
    width: "42px",
    height: "42px",
    position: "absolute",
    top: 6,
    left: "-45px",
  },
  apiResultIcon: {
    fontSize: "20px",
  },
  apiResultIconSuccess: {
    color: "green",
    paddingLeft: 5,
  },
  apiResultIconFailed: {
    color: "#98260D",
    transform: "rotate(180deg)",
  },
  iconStyles: {
    margin: "0 !important",
  },
  cardBody: {
    paddingTop: 0,
    "& .-pagination": {
      padding: "30px 0 3px",
      alignItems: "center",
    },
    "& .-btn": {
      opacity: "1 !important",
      fontSize: "12px !important",
      fontWeight: "400",
      color: "#000000 !important",
      textTransform: "uppercase",
      fontFamily: "Roboto",
      maxWidth: "125px",
      maxHeight: "30px",
      height: "30px !important",
    },
    "& .-next": {
      "& .-btn": {
        display: "inline-block",
        float: "right",
      },
    },
    "& .-center": {
      flex: "5.5 1 !important",
      justifyContent: "space-between !important",
    },
    "& .-pageInfo": {
      width: "50% !important",
      margin: "0 !important",
    },
    "& .-pageJump": {
      width: "80% !important",
    },
    "& .-pageSizeOptions": {
      width: "50% !important",
      margin: "0 !important",
      "& select": {
        width: "80% !important",
      },
    },
  },
  cardTitle: {
    fontSize: 26,
    lineHeight: "30px",
    fontWeight: "300",
  },
  apiResultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    color: "#000000",
  },
  apiResultError: {
    color: "#98260D",
  },
  apiResultSuccess: {
    color: "green",
  },
}));

const GET_CREDIT_SUBMISSIONS = gql`
  query creditSubmissionsByMasterCreditSubmissionId($id: ID!) {
    creditSubmissionsByMasterCreditSubmissionId(id: $id) {
      creditSubmissionId
      voId
      creditSubId
      submissionDate
      decision
      lenderName
      amountRequested
      approvedAmount
      notes
      contingencies
      approvedRate
      creditSubStage
      apiApplicationId
      apiResult
      apiExpiredCredentials
      apiDetail
      lenderProfileId
      lenderChannelCode
      draft
      creditSubStage
      transactionSummary
      lenderContactInfo {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      masterCreditSubmissionId
      syndicatedBy {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      masterLenderName
      displayStatus
    }
  }
`;

const SUBMIT_APP = gql`
  mutation($input: CreditSubmissionInput!) {
    createCreditSubmission(input: $input)
  }
`;

const ManageSyndications = ({
  vo,
  account,
  userProfile,
  lp,
  refetch,
  vp,
  creditApp,
  equipmentData,
  cs,
  handleSubmitToLenderByProfileId,
  callbackSubmissionFinished,
  //   setOpenEquipmentInputModal,
  //   setInvoiceEquipmentTabPage,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [creditSubmissions, setCreditSubmissions] = useState();
  const [openLenderSubmissionDetailModal, setOpenLenderSubmissionDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creditSubId, setCreditSubId] = useState(null);
  const [autoRefreshInterval, setAutoRefreshInterval] = useState(null);
  const [creditSubLenderSubmissionDetailModal, setCreditSubLenderSubmissionDetailModal] = useState(null);
  const [openUpdateLenderCredentialsModal, setOpenUpdateLenderCredentialsModal] = useState(false);
  const [selectedLenderProfile, setSelectedLenderProfile] = useState(null);
  const [masterCreditSubmissionId, setMasterCreditSubmissionId] = useState(null);
  const [lenderProfiles, setLenderProfiles] = useState(null);

  const [
    getCreditSubmissions,
    {
      called: creditSubmissionsCalled,
      data: creditSubmissionsData,
      loading: creditSubmissionsLoading,
      error: creditSubmissionsError,
      startPolling: startCreditSubPolling,
      refetch: creditSubmissionsRefetch,
      called: calledOnce,
    },
  ] = useLazyQuery(GET_CREDIT_SUBMISSIONS);

  const [creditSubmissionRequest] = useMutation(SUBMIT_APP, {
    context: { authRequired: true },
  });

  const resubmitCreditApp = async row => {
    const lenderInfo = lp.find(lender => lender.lenderName === row.lenderName);
    try {
      const resultSubmission = await creditSubmissionRequest({
        variables: {
          input: {
            submittedLender: lenderInfo.lenderAccountId,
            dynamicsCreditApplicationId: creditApp.dynamicsId,
            dynamicsVOId: vo.dynamicsVendorOpportunityId,
            dynamicsUserId: userProfile.dynamicsUserId,
            amountRequested: vo.invoiceTotal,
            accountId: account.id,
            lenderProfileId: lenderInfo.lenderProfileId,
            lenderContactId: lenderInfo.lenderContactId,
            createDraftOnly: false,
            transactionSummary: row.transactionSummary,
          },
        },
      });
      const dynamicsCreditSubmissionId = _.get(resultSubmission, "data.createCreditSubmissions[0]", null);
      if (callbackSubmissionFinished) {
        callbackSubmissionFinished(dynamicsCreditSubmissionId);
      }
      await creditSubmissionsRefetch();
      handleRefetchCreditSubmission();
    } catch (err) {
      console.log(err);
    }
  };

  if (creditSubmissionsLoading) console.log("Loading Credit Submissions....");
  if (creditSubmissionsError) console.log(`Error! ${creditSubmissionsError.message}`);

  useEffect(() => {
    if (_.isEmpty(cs) || _.isEmpty(vp)) {
      return;
    }

    const isNeedToTakeCreditSubmissionLenders = account?.dynamicsAccountId == "5e7f2efe-5c02-eb11-8125-065d4ad39109"; // for Lender Network account only
    const lendersToShow = _(vp.lenderProfiles) // the ones which are added for current Lender's VP
      .concat(isNeedToTakeCreditSubmissionLenders ? cs.lenderProfiles : []) // combine LPs from current credit submission (takes LPs from related Dealer's)
      .compact()
      .uniqBy("lenderProfileId")
      .value();
    setLenderProfiles(lendersToShow);
  }, [cs, vp]);

  useEffect(() => {
    if (handleSubmitToLenderByProfileId && vp.lenderProfiles) {
      const lender = _.find(vp.lenderProfiles, { id: handleSubmitToLenderByProfileId });
      if (lender) {
        resubmitCreditApp(lender);
      } else {
        console.log("Unidenfitied lender to resubmit credit app.");
      }
    }
  }, [vp, handleSubmitToLenderByProfileId]);

  useEffect(() => {
    if (!!cs && !!cs.creditSubId) {
      getCreditSubmissions({
        variables: {
          id: cs.creditSubId,
        },
      });
    }
    if (creditSubmissionsCalled && !creditSubmissionsLoading && !!creditSubmissionsData) {
      const filteredCS = creditSubmissionsData.creditSubmissionsByMasterCreditSubmissionId.filter(el => el != null);
      filteredCS.forEach(item => {
        item.displayText =
          item.displayStatus === 100
            ? "Shown"
            : item.displayStatus === 200
            ? "Hidden"
            : item.displayStatus === 300
            ? "Shown - Dealer Created"
            : null;
        return item;
      });
      setCreditSubmissions(creditSubmissionsData.creditSubmissionsByMasterCreditSubmissionId.filter(el => el != null));
      setMasterCreditSubmissionId(cs.creditSubId);

      let myData = Object.keys(filteredCS).map(key => filteredCS[key]);
      dispatch({ type: SET_CS, payload: myData });
    }
  }, [vo, creditSubmissionsData, creditSubmissionsCalled, autoRefreshInterval, SET_CS]);

  if (refetch.creditSubmissions) {
    SET_CS_REFETCH("creditSubmissions", false);
    creditSubmissionsRefetch();
  }

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});

  const handleOpenModal = () => setOpenMessageModal(true);

  const handleSubmissionMessage = async message => {
    await handleRefetchCreditSubmission();
    return setSubmissionMessage(message);
  };

  const handleRefetchCreditSubmission = async () => {
    const time = 1000;
    setLoading(true);
    setTimeout(() => {
      creditSubmissionsRefetch(time);
    }, time);
  };

  const handleOpenLenderDetailModalView = row => {
    setCreditSubId(row.creditSubId);
    setCreditSubLenderSubmissionDetailModal(row);
    setOpenLenderSubmissionDetailModal(true);
  };

  const handleCloseLenderDetailModalView = () => {
    setOpenLenderSubmissionDetailModal(false);
    setCreditSubLenderSubmissionDetailModal(false);
    setCreditSubId("");
  };
  const [updated, setUpdated] = useState(false);

  if (!!refetch && !!refetch.pastSubmissions) {
    creditSubmissionsRefetch();
    SET_CS_REFETCH("pastSubmissions", false);
  }
  const handleCloseUpdateLenderCredentialsModal = () => {
    setOpenUpdateLenderCredentialsModal(false);
  };
  const handleOpenUpdateLenderCredentialsModal = () => {
    setOpenUpdateLenderCredentialsModal(true);
  };
  function handleUpdateCredentials(rowInfo) {
    setSelectedLenderProfile(rowInfo);
    handleOpenUpdateLenderCredentialsModal();
  }

  return (
    <Tile
      classBody={classes.cardBody}
      title={<span className={classes.cardTitle}>Manage Syndications</span>}
      titleButtonComponent={
        <div className={classes.wrapper}>
          <Fab aria-label="reload" color="primary" size="small" onClick={handleRefetchCreditSubmission}>
            <RefreshIcon className={classes.iconStyles} />
          </Fab>
          <div className={classes.wrapperLoader}>{loading && <CircularProgress size={32} />}</div>
        </div>
      }
      useCollapsing={true}
    >
      {!!creditSubmissions && creditSubmissions.length ? (
        <ReactTable
          color="primary"
          data={creditSubmissions}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                cursor: "pointer",
                fontSize: 12,
                fontWeight: 400,
                lineHeight: "14px",
              },
            };
          }}
          filterable
          columns={[
            {
              Header: "Submission Date",
              accessor: "submissionDate",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "Lender",
              accessor: "lenderName",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "Stage",
              Cell: props => {
                const row = props.original;
                let apiDetail = null;
                switch (row.apiResult) {
                  case "failure":
                    apiDetail = (
                      <Tooltip title={row.apiDetail} placement="top-start">
                        <PriorityHighIcon className={[classes.apiResultIcon, classes.apiResultIconFailed]} />
                      </Tooltip>
                    );
                    break;
                  case "in progress":
                    apiDetail = (
                      <Tooltip title={"Processing"} placement="top-start">
                        <HourglassEmptyIcon size="small" className={[classes.apiResultIcon]} />
                      </Tooltip>
                    );
                    break;
                  case "success":
                    apiDetail = (
                      <Tooltip title={row.apiDetail} placement="top-start">
                        <DoneIcon size="small" className={[classes.apiResultIcon, classes.apiResultIconSuccess]} />
                      </Tooltip>
                    );
                    break;

                  default:
                    break;
                }

                if (row.apiExpiredCredentials) {
                  return (
                    <div>
                      <PriorityHighIcon className={[classes.apiResultIcon, classes.apiResultIconFailed]} />{" "}
                      {"Credentials Expired"}
                      <Button
                        size="small"
                        className={classes.buttonUpdateCredentials}
                        onClick={() => {
                          handleUpdateCredentials(row);
                        }}
                      >
                        Update Credentials
                      </Button>
                    </div>
                  );
                }
                if (props.value) {
                  return (
                    <div>
                      {" "}
                      {translateStageCodes(props.value)} {apiDetail}
                    </div>
                  );
                } else return null;
              },
              accessor: "creditSubStage",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "Decision",
              accessor: "decision",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "Requested Amount",
              accessor: "amountRequested",
              Cell: props => <div>{props.value ? currencyFormatter.format(props.value) : ""}</div>,
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "Approval Amount",
              accessor: "approvedAmount",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
              Cell: props => <div>{props.value ? currencyFormatter.format(props.value) : ""}</div>,
            },
            {
              Header: "Sydnication From",
              accessor: "masterLenderName",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "Syndicated By",
              accessor: "syndicatedBy.fullName",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "Display Status",
              accessor: "displayText",
              Filter: () => null,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
            },
            {
              Header: "",
              Filter: () => null,
              sortable: false,
              headerStyle: {
                fontWeight: "700",
                fontSize: 12,
                color: "#000",
                lineHeight: "14px",
              },
              Cell: props => {
                const row = props.original;
                if (!!row && !!userProfile && userProfile.vendorContactRole !== "sales_rep") {
                  return (
                    <>
                      <Button
                        size="small"
                        className={classes.buttonDetail}
                        onClick={() => {
                          handleOpenLenderDetailModalView(row);
                        }}
                      >
                        View Detail
                      </Button>
                      {row.apiResult === "failure" && (
                        <Button
                          size="small"
                          className={classes.buttonDetail}
                          onClick={() => {
                            resubmitCreditApp(row);
                          }}
                        >
                          RE-SUBMIT
                          <span style={{ padding: "0px 5px", color: "#C4C4C4" }}>|</span>
                        </Button>
                      )}
                    </>
                  );
                }

                return null;
              },
            },
          ]}
          defaultPageSize={5}
          showPaginationTop={false}
          showPaginationBottom={true}
          customCellClasses={[classes.right]}
          customClassesForCells={[5]}
          className="-striped -highlight"
        />
      ) : null}
      {creditSubLenderSubmissionDetailModal ? (
        <LenderSubmissionDetailModal
          key={Math.random()}
          creditSubmission={creditSubLenderSubmissionDetailModal}
          open={openLenderSubmissionDetailModal}
          vo={vo}
          creditSubId={creditSubId}
          handleCloseLenderDetailModalView={handleCloseLenderDetailModalView}
          setOpenLenderSubmissionDetailModal={setOpenLenderSubmissionDetailModal}
          refetchCreditSubmissions={creditSubmissionsRefetch}
          relatedCreditSubmissions={creditSubmissions}
          vp={vp}
          account={account}
          userProfile={userProfile}
          masterCreditSubmissionId={masterCreditSubmissionId}
        />
      ) : null}

      <LenderSubmission
        refetch={creditSubmissionsRefetch}
        vo={vo}
        equipmentData={equipmentData}
        handleOpenModal={handleOpenModal}
        handleSubmissionMessage={handleSubmissionMessage}
        account={account}
        lp={lenderProfiles}
        creditApp={creditApp}
        // setInvoiceEquipmentTabPage={setInvoiceEquipmentTabPage}
        // setOpenEquipmentInputModal={setOpenEquipmentInputModal}
        creditSubmissions={creditSubmissions}
        cs={cs}
      />
    </Tile>
  );
};

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
  userProfile: state.userProfile,
  lp: state.lenderProfile,
  refetch: state.cs.refetch,
  vp: state.vp,
  creditApp: state.creditApp,
  creditSubmissions: state.creditSubmissions,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSyndications);

const lenderList = {
  "Ford Motor Credit": ["Tracking Only - Requires RouteOne Entry", "N/A"],
  "Test Lender #1": ["Submission by Email", "Alex Sawatzke"],
  "Toyota Industries Commercial Finance": ["Submission by Email", "Vince Monteleone"],
  "Hitachi Capital": ["Submission by Email", "Jay Saul"],
  "Isuzu Finance": ["Submission by Email", "Bob Mooney"],
  CalFund: ["Submission by Email", "N/A"],
  "Ascentium Capital": ["Submission by Email", "N/A"],
  "AP Finance": ["Submission by Email", "N/A"],
  "Ally Bank": ["Tracking Only - Requires RouteOne Entry", "Alex Sawatzke"],
  "Test Lender #2": ["Submission by Email", "Alex Sawatzke"],
};
