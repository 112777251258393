import React from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useNotifications } from "../hooks";

export const NotificationProvider = () => {
  const { notifications, hideNotification } = useNotifications();

  return (
    <>
      {notifications.map((notification, index) => (
        <Snackbar
          open
          key={notification.id}
          style={{ marginTop: index * 56 }}
          onClose={() => hideNotification(notification.id)}
          autoHideDuration={notification.duration || 3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={notification.type}
            onClose={() => hideNotification(notification.id)}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
