import {
  CircularProgress,
  FormControl,
  Grid,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import { getS3ImageUrl, setFieldValue, validateValue } from "../../utils";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CreateLocationModal from "./CreateLocationModal";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/react-hooks";
import TableSearch from "components/Table/TableSearch";
import _ from "lodash";
import DragIndicator from "assets/img/drag-indicator.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import PersonalLinkAndQRCode from "../../components/PersonalLinkAndQRCode";
import { theme } from "../../theme";
import Table from "components/Table";
import { vendorContactRoleHumanReadable } from "../../utils";
import TableSelect from "components/Table/TableSelect";

const config = require("../../config.js");

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  companyProfile: {
    paddingLeft: "5px!important",
  },
  cardStyles: {
    marginBottom: "50px !important",
  },
  buttonContainer: {
    marginBottom: 30,
    paddingLeft: "0 !important",
  },
  defaultIcon: {
    maxWidth: "15px",
  },
  linkButton: {
    marginRight: "20px",
  },
  subHeader: {
    marginTop: 0,
  },
  defaultContactSelector: {
    paddingBottom: "16px",
  },
  cardBody: {
    padding: "25px",
    "& .personalLinkAndQRCodeRoot > div": {
      margin: "15px 0",
    },
  },
  companyInfo: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "25px 10px 25px 30px !important",
    marginBottom: "50px",
    fontSize: "12px",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  companyProfilePhotoContainer: {
    boxShadow: "none",
  },
  companyProfilePhoto: {
    padding: 0,
  },
  companyProfileButton: {
    backgroundColor: "#3E83E6",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
    marginRight: "10px",
  },
  companyProfileHeader: {
    fontSize: "26px",
    fontWeight: "300",
    "& svg": {
      margin: "0 0 10px 0",
    },
  },
  companyProfileContact: {
    paddingRight: "22px",
    display: "flex",
    alignItems: "center",
  },
  companyProfileCancelButton: {
    backgroundColor: "#999",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
    marginLeft: "20px",
  },
  locationTable: {
    "& .rt-noData": {
      top: "45%",
    },
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  locationLogo: {
    width: "300px",
    objectFit: "contain",
  },
  searchFields: {
    display: "flex",
    gap: 16,
    "& svg": {
      margin: 0,
    },
  },
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Q_LOCATION_DETAILS = gql`
  query($locationId: ID) {
    location(locationId: $locationId) {
      locationId
      locationName
      category
      accountDynamicsId
      locationParentId
      logo
      colorSchema
      salesManagerId
      creditManagerId
      ocaShowOnlyChildLocations
      ocaHidden
      ocaContact {
        fullName
        dynamicsContactId
        vendorContactRole
        title
        id
      }
      contactsInfo {
        id
        dynamicsContactId
        vendorContactRole
        fullName
        title
        email
        phoneNumber
      }
      nonAssociatedContactsInfo {
        id
        dynamicsContactId
        vendorContactRole
        email
        fullName
      }
      notificationAppReceiptGroup {
        notificationGroupId
        groupName
        notificationsToUserProfiles {
          fullName
          title
          id
          email
          phoneNumber
          dynamicsContactId
          vendorContactRole
        }
      }
      parentLocation {
        locationName
        locationId
      }
      childrenLocation {
        locationName
        locationId
      }
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation($input: UpsertLocationInput!) {
    upsertLocation(input: $input)
  }
`;

const UPDATE_NOTIFICATION_GROUP = gql`
  mutation($input: UpsertNotificationGroupInput!) {
    upsertNotificationGroup(input: $input)
  }
`;

const DELETE_LOCATION = gql`
  mutation($locationId: ID) {
    deleteLocation(locationId: $locationId)
  }
`;

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      accountDynamicsId
      ocaContactId
      ocaContact {
        fullName
      }
      contactIds
      locationParentId
      notificationAppReceipt
    }
  }
`;

function LocationDetails({ userProfile, match, setRefetch }) {
  const locationId = match.params.locationId;
  const history = useHistory();
  const [locationReps, setLocationReps] = useState([]);
  const [selectedLocationReps, setSelectedLocationReps] = useState(null);
  const [editLocationReps, setEditLocationReps] = useState(false);
  const [notificationGroup, setNotificationGroup] = useState([]);
  const [editNotificationGroup, setEditNotificationGroup] = useState(false);
  const [locationsList, setLocationsList] = useState();
  const account = useSelector(state => state.account);
  const [filteredLocationReps, setFilteredLocationReps] = useState([]);
  const [refetching, setRefetching] = useState(false);
  const [deletingLocation, setDeletingLocation] = useState(false);
  const [locationRepsPageNumber, setLocationRepsPageNumber] = useState(0);
  const locationRepsRoleRef = useRef({ prevSelect: undefined, currentSelect: "" });
  const locationRepsSearchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [locationRepsSavedListSettings, setLocationRepsSavedListSettings] = useState({ page: 0, pageSize: 5 });
  const [locationRepsPageSizeValue, setLocationRepsPageSizeValue] = useState(5);

  const [filteredNotificationGroup, setFilteredNotificationGroup] = useState([]);
  const [notificationGroupPageNumber, setNotificationGroupPageNumber] = useState(0);
  const notificationsGroupRoleRef = useRef({ prevRole: undefined, currentSelect: "" });
  const notificationGroupSearchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [notificationGroupSavedListSettings, setNotificationGroupSavedListSettings] = useState({
    page: 0,
    pageSize: 5,
  });
  const [notificationGroupPageSizeValue, setNotificationGroupPageSizeValue] = useState(5);

  const { data: locationDetails, refetch: locationDetailsRefetch } = useQuery(Q_LOCATION_DETAILS, {
    variables: {
      locationId: locationId,
    },
    fetchPolicy: "no-cache",
  });

  const [getLocations, { data: locations, refetch: locationsRefetch }] = useLazyQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    context: { authRequired: true },
  });

  const [updateNotificationGroup] = useMutation(UPDATE_NOTIFICATION_GROUP, {
    context: { authRequired: true },
  });

  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    context: { authRequired: true },
  });

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false);
  const [fields, setFields] = useState({
    locationId: {
      value: "",
      validationStatus: "",
    },
    locationName: {
      value: "",
      validationStatus: "",
    },
    category: {
      value: "",
      validationStatus: "",
    },
    accountDynamicsId: {
      value: "",
      validationStatus: "",
    },
    ocaContactId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    ocaContactFullName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    ocaContactTitle: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    locationParentId: {
      value: "",
      validationStatus: "",
    },
    repsAtThisLocation: {
      value: "",
      validationStatus: "",
    },
    allReps: {
      value: "",
      validationStatus: "",
    },
    notificationGroup: {
      value: [],
    },
    notificationGroupName: {
      value: "",
    },
    notificationGroupId: {
      value: "",
    },
    parentLocation: {
      value: "",
    },
    childrenLocation: {
      value: "",
    },
    logo: {
      value: "",
    },
    creditManager: {
      value: "",
    },
    salesManager: {
      value: "",
    },
    ocaLink: {
      value: "",
    },
  });
  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  const handleCloseEditLocation = async upsertModalClosedValue => {
    setOpenEditLocationModal(false);

    if (_.get(upsertModalClosedValue, "locationId", undefined)) {
      setRefetching(true);
      await locationDetailsRefetch();
      setRefetching(false);
    }
  };

  const handleEditLocationClick = () => {
    setEditLocationReps(!editLocationReps);
  };

  const handleEditNotificationGroupClick = () => {
    setEditNotificationGroup(!editNotificationGroup);
  };

  const handleCancelEditLocationClick = () => {
    setSelectedLocationReps(
      fields.repsAtThisLocation.value.map(item => {
        item.value = item.id;
        return item.value;
      })
    );
    setEditLocationReps(false);
  };

  const handleCancelEditNotificationClick = () => {
    setNotificationGroup(
      fields.notificationGroup.value.map(item => {
        item.value = item.id;
        return item.value;
      })
    );
    setEditNotificationGroup(false);
  };

  const handleSaveEditLocationClick = async () => {
    try {
      await updateLocation({
        variables: {
          input: {
            locationId: fields.locationId.value,
            locationName: fields.locationName.value,
            category: fields.category.value,
            contactIds: selectedLocationReps.reduce((acc, curr) => (acc.push(curr.id), acc), []),
          },
        },
      });
      await locationDetailsRefetch();
      setSnackbarOpen(true);
      setRefetch("account", true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error updating location contacts!");
      setErrorOpen(true);
    }
    setEditLocationReps(false);
  };

  const handleSaveEditNotificationClick = async () => {
    try {
      await updateNotificationGroup({
        variables: {
          input: {
            notificationGroupId: fields.notificationGroupId.value,
            notificationsToUserProfileIds: notificationGroup.reduce((acc, curr) => (acc.push(curr.id), acc), []),
            groupName: fields.notificationGroupName.value
              ? fields.notificationGroupName.value
              : `${locationDetails.location.locationName} Notification Group`,
            applicationReceiptLocationId: locationDetails.location.locationId,
          },
        },
      });
      await locationDetailsRefetch();
      setSnackbarOpen(true);
      setRefetch("account", true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error updating location notification group!");
      setErrorOpen(true);
    }
    setEditNotificationGroup(false);
  };

  const handleUpdateOCAContact = async () => {
    try {
      await updateLocation({
        variables: {
          input: {
            locationId: fields.locationId.value,
            locationName: fields.locationName.value,
            category: fields.category.value,
            ocaContactId: fields.ocaContactId.value,
          },
        },
      });
      await locationDetailsRefetch();
      setSnackbarOpen(true);
      setRefetch("account", true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error updating primary contact!");
      setErrorOpen(true);
    }
  };

  const handleLocationClick = id => {
    history.push(`/locationDetail/${id}`);
  };

  const handleDeleteLocationClick = async () => {
    setDeletingLocation(true);
    console.log(`Deleting location: ${locationId}`);
    const deleteLocationResult = await deleteLocation({
      variables: {
        locationId,
      },
    });
    if (deleteLocationResult) {
      history.goBack();
    } else {
      setDeletingLocation(false);
    }
  };

  useEffect(() => {
    if (locationReps) {
      const filteredData = filterLocationReps(locationReps);
      setFilteredLocationReps(filteredData);
    }
  }, [locationReps, locationRepsSearchRef.current.currentSearch, locationRepsRoleRef.current.currentSelect]);

  useEffect(() => {
    if (fields.notificationGroup.value) {
      setFilteredNotificationGroup(filterNotificationGroup(fields.notificationGroup.value));
    }
  }, [
    fields.notificationGroup.value,
    notificationGroupSearchRef.current.currentSearch,
    notificationsGroupRoleRef.current.currentSelect,
  ]);

  const filterNotificationGroup = notificationGroup => {
    const { currentSearch } = notificationGroupSearchRef.current;
    const { currentSelect: currentRoleSelect } = notificationsGroupRoleRef.current;

    if (notificationGroupSearchRef.length === 0) {
      return notificationGroup;
    }

    const lowerCaseSearch = currentSearch.toLocaleLowerCase().trim();
    let searchData = notificationGroup.filter(el => {
      if (
        typeof el.fullName === "string" ||
        typeof el.title === "string" ||
        typeof el.email === "string" ||
        typeof el.phoneNumber === "string"
      ) {
        return (
          (el.fullName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.title || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.email || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.phoneNumber || "").replace(/[^0-9]/g, "").includes(lowerCaseSearch)
        );
      }

      return false;
    });

    if (currentRoleSelect?.length !== 0) {
      searchData = searchData.filter(el => el.vendorContactRole === currentRoleSelect);
    }

    return [...searchData];
  };

  const filterLocationReps = locationReps => {
    const { currentSearch } = locationRepsSearchRef.current;
    const { currentSelect: currentRoleSelect } = locationRepsRoleRef.current;

    if (locationRepsSearchRef.length === 0) {
      return locationReps;
    }

    const lowerCaseSearch = currentSearch.toLocaleLowerCase().trim();

    let searchData = locationReps.filter(el => {
      if (
        typeof el.fullName === "string" ||
        typeof el.title === "string" ||
        typeof el.email === "string" ||
        typeof el.phoneNumber === "string"
      ) {
        return (
          (el.fullName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.title || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.email || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.phoneNumber || "").replace(/[^0-9]/g, "").includes(lowerCaseSearch)
        );
      }

      return false;
    });

    if (currentRoleSelect?.length !== 0) {
      searchData = searchData.filter(el => el.vendorContactRole === currentRoleSelect);
    }

    return [...searchData];
  };

  useEffect(() => {
    if (locationDetails && locationDetails.location) {
      var repsAtThisLocation;
      var allReps;
      var notificationReps;

      if (locationDetails.location.contactsInfo) {
        repsAtThisLocation = locationDetails.location.contactsInfo.map(item => {
          try {
            item.value = item.id;
            item.phoneNumber = item.phoneNumber ? formatPhone(item.phoneNumber) : null;
            if (item.title == null) {
              item.label = item.fullName ? item.fullName : item.email;
            } else {
              item.label = item.fullName ? item.fullName : item.email + ", " + item.title;
            }
            return item;
          } catch (err) {
            console.error(err);
            return null;
          }
        });
        notificationReps = locationDetails.location.notificationAppReceiptGroup.notificationsToUserProfiles.map(
          item => {
            try {
              item.value = item.id;
              if (item.title == null) {
                item.label = item.fullName ? item.fullName : item.email;
              } else {
                item.label = item.fullName ? item.fullName : item.email + ", " + item.title;
              }
              return item;
            } catch (err) {
              console.error(err);
              return null;
            }
          }
        );
        setLocationReps(repsAtThisLocation);
        setSelectedLocationReps(repsAtThisLocation);
        setNotificationGroup(notificationReps);
      } else {
        setLocationReps([]);
      }
      if (locationDetails.location.nonAssociatedContactsInfo) {
        allReps = locationDetails.location.nonAssociatedContactsInfo.map(item => {
          try {
            item.value = item.id;
            if (item.title == null) {
              item.label = item.fullName ? item.fullName : item.email;
            } else {
              item.label = item.fullName ? item.fullName : item.email + ", " + item.title;
            }
            return item;
          } catch (err) {
            console.error(err);
            return null;
          }
        });
        allReps = [...repsAtThisLocation, ...allReps];
      }
      setFields({
        locationId: {
          value: locationDetails.location.locationId ? locationDetails.location.locationId : "",
          validationStatus: "",
        },
        locationName: {
          value: locationDetails.location.locationName ? locationDetails.location.locationName : "",
          validationStatus: "",
        },
        category: {
          value: locationDetails.location.category ? locationDetails.location.category : "",
          validationStatus: "",
        },
        logo: {
          value: locationDetails.location.logo,
          validationStatus: "",
        },
        salesManager: {
          value: locationDetails.location.salesManagerId
            ? _.find(account.contacts, { id: locationDetails.location.salesManagerId })
            : null,
          validationStatus: "",
        },
        creditManager: {
          value: locationDetails.location.creditManagerId
            ? _.find(account.contacts, { id: locationDetails.location.creditManagerId })
            : null,
          validationStatus: "",
        },
        accountDynamicsId: {
          value: locationDetails.location.accountDynamicsId ? locationDetails.location.accountDynamicsId : "",
          validationStatus: "",
        },
        ocaContactId: {
          value: locationDetails.location.ocaContact ? locationDetails.location.ocaContact.id : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        locationParentId: {
          value: locationDetails.location.locationParentId ? locationDetails.location.locationParentId : "",
          validationStatus: "",
        },
        ocaContactFullName: {
          value: locationDetails.location.ocaContact ? locationDetails.location.ocaContact.fullName : "",
          validationStatus: "",
        },
        ocaContactTitle: {
          value: locationDetails.location.ocaContact ? locationDetails.location.ocaContact.title : "",
          validationStatus: "",
        },
        repsAtThisLocation: {
          value: repsAtThisLocation,
          validationStatus: "",
        },
        allReps: {
          value: allReps,
          validationStatus: "",
        },
        ocaLink: {
          value:
            config.REACT_APP_OCA_BASE_URL +
            "/?vendorGUID=" +
            (locationDetails.location.accountDynamicsId
              ? locationDetails.location.accountDynamicsId
              : _.get(account, "dynamicsAccountId", null)) +
            "&locationId=" +
            locationDetails.location.locationId,
        },
        notificationGroup: {
          value: locationDetails.location.notificationAppReceiptGroup
            ? locationDetails.location.notificationAppReceiptGroup.notificationsToUserProfiles
            : [],
        },
        notificationGroupName: {
          value: locationDetails.location.notificationAppReceiptGroup
            ? locationDetails.location.notificationAppReceiptGroup.groupName
            : "",
        },
        notificationGroupId: {
          value: locationDetails.location.notificationAppReceiptGroup
            ? locationDetails.location.notificationAppReceiptGroup.notificationGroupId
            : "",
        },
        parentLocation: {
          value: locationDetails.location.parentLocation ? locationDetails.location.parentLocation : "",
        },
        childrenLocation: {
          value: locationDetails.location.childrenLocation ? locationDetails.location.childrenLocation : "",
        },
      });
    }

    if (!!account && account.dynamicsAccountId) {
      getLocations();
    }
  }, [locationDetails]);

  useEffect(() => {
    if (_.get(locations, "locations", null)) {
      setLocationsList([...locations.locations]);
    }
  }, [locations]);

  const classes = useStyles();

  const handleChangeLocationReps = value => {
    setSelectedLocationReps(value);
  };

  const handleChangeNotificationReps = value => {
    setNotificationGroup(value);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
    setErrorOpen(false);
  };

  const getEditButtonText = () => (!!fields.allReps.value && fields.allReps.value.length > 1 ? "Edit" : "");

  const getUpdateButtonText = () => (!!locationReps && locationReps.length > 1 ? "Update" : "");

  const isDisabledUpdateButton = () => !fields.ocaContactId.value;

  const handleUserClick = repId => {
    history.push(`/profile/${repId}`);
  };

  console.log(fields);

  return (
    <>
      {deletingLocation && (
        <div>
          Deleting Location
          <LinearProgress size={34} />
        </div>
      )}
      {locationDetails && !refetching ? (
        <>
          <Grid container spacing={1} className={classes.companyProfile}>
            <Grid container>
              <GridItem
                xs={12}
                sm={locationDetails.location.category === "Master" ? 10 : 8}
                md={locationDetails.location.category === "Master" ? 10 : 8}
                lg={locationDetails.location.category === "Master" ? 10 : 8}
                className={classes.buttonContainer}
              >
                <Button className="formBtn" color="primary" variant="outlined" onClick={() => history.goBack()}>
                  Back
                </Button>
              </GridItem>

              <GridItem xs={12} sm={2} md={2} lg={2} className={classes.buttonContainer}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    className="formBtn"
                    color="primary"
                    variant="outlined"
                    onClick={() => setOpenEditLocationModal(true)}
                    style={{ padding: "0px !important" }}
                  >
                    Edit Location
                  </Button>
                </div>
              </GridItem>

              {locationDetails.location.category !== "Master" && (
                <GridItem xs={12} sm={2} md={2} lg={2} className={classes.buttonContainer}>
                  <Button className="formBtn" color="danger" variant="outlined" onClick={handleDeleteLocationClick}>
                    Delete Location
                  </Button>
                </GridItem>
              )}
            </Grid>
            <Tile className={classes.cardStyles} classBody={classes.cardBody}>
              <Grid container spacing={2}>
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid container alignContent="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant={"h5"} className={classes.companyProfileHeader}>
                        Location Info
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Location Name: </b> {fields.locationName.value}
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Category: </b> {fields.category.value}
                    </Grid>
                    {!!fields.parentLocation.value && (
                      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                        <b>Parent Location: </b>{" "}
                        {fields.parentLocation.value ? fields.parentLocation.value.locationName : null}
                      </Grid>
                    )}
                    {!!fields.childrenLocation.value && fields.childrenLocation.value.length ? (
                      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                        <b>Child Locations: </b>
                        {fields.childrenLocation.value.map(({ locationId, locationName }, i) => (
                          <span
                            key={i}
                            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                            onClick={() => handleLocationClick(locationId)}
                          >
                            {locationName},
                          </span>
                        ))}
                      </Grid>
                    ) : (
                      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                        <b>Child Locations: </b> None
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant={"h5"} className={classes.companyProfileHeader} style={{ marginTop: 16 }}>
                        Contacts
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Primary Contact: </b>{" "}
                      {fields.ocaContactFullName.value ? (
                        _.find(fields.allReps.value, { id: fields.ocaContactId.value }) ? (
                          <a
                            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                            href={`/profile/${
                              _.find(fields.allReps.value, { id: fields.ocaContactId.value }).dynamicsContactId
                            }`}
                          >
                            {fields.ocaContactFullName.value}
                          </a>
                        ) : (
                          fields.ocaContactFullName.value
                        )
                      ) : (
                        "None"
                      )}
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Finance Manager: </b>{" "}
                      {fields.creditManager.value ? (
                        fields.creditManager.value.dynamicsContactId ? (
                          <a
                            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                            href={`/profile/${fields.creditManager.value.dynamicsContactId}`}
                          >
                            {fields.creditManager.value.fullName}
                          </a>
                        ) : (
                          fields.creditManager.value.fullName
                        )
                      ) : (
                        "None"
                      )}
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Sales Manager: </b>
                      {fields.salesManager.value ? (
                        fields.salesManager.value.dynamicsContactId ? (
                          <a
                            style={{ color: theme.palette.primary.main, cursor: "pointer" }}
                            href={`/profile/${fields.salesManager.value.dynamicsContactId}`}
                          >
                            {fields.salesManager.value.fullName}
                          </a>
                        ) : (
                          fields.salesManager.value.fullName
                        )
                      ) : (
                        "None"
                      )}
                    </Grid>
                    {fields.logo.value ? (
                      <Grid item xs={12} className={classes.locationLogoContainer}>
                        <img
                          src={getS3ImageUrl(fields.logo.value)}
                          alt="Location Logo"
                          className={classes.locationLogo}
                        />
                      </Grid>
                    ) : (
                      <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                        <b>Location Logo: </b> None
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <PersonalLinkAndQRCode
                  personalLink={fields.ocaLink.value}
                  linkHeader="Location Credit Application Link"
                />
              </Grid>
            </Tile>
            <Tile
              title={
                <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                  <div style={{ display: "flex" }}>
                    <h5 className={classes.companyProfileHeader}>Staff At This Location</h5>
                    <HtmlTooltip
                      title={
                        "Identify the representatives customers will be able to select on your credit application for this location."
                      }
                    >
                      <InfoIcon className={classes.defaultIcon} color="primary" />
                    </HtmlTooltip>
                  </div>
                  <div className={classes.searchFields}>
                    <TableSearch
                      setPageNumber={setLocationRepsPageNumber}
                      setSavedListSettings={setLocationRepsSavedListSettings}
                      searchRef={locationRepsSearchRef}
                      savedListSettings={locationRepsSavedListSettings}
                    />
                    <TableSelect
                      setPageNumber={setLocationRepsPageNumber}
                      setSavedListSettings={setLocationRepsSavedListSettings}
                      selectRef={locationRepsRoleRef}
                      savedListSettings={locationRepsSavedListSettings}
                      id="location-role"
                      label="Role"
                      options={Object.entries(vendorContactRoleHumanReadable).map(ent => ({
                        value: ent[0],
                        text: ent[1],
                      }))}
                      width={200}
                    />
                  </div>
                </div>
              }
              titleButtonText={getEditButtonText()}
              titleButtonFunc={handleEditLocationClick}
              className={classes.cardStyles}
              classBody={classes.cardBody}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item md={12}>
                      <Table
                        color="primary"
                        data={filteredLocationReps}
                        noDataText={<></>}
                        getTrProps={(state, rowInfo, column, instance) => ({
                          style: { cursor: "pointer" },
                          onClick: e => handleUserClick(rowInfo.original.dynamicsContactId),
                        })}
                        columns={[
                          {
                            Header: "Name",
                            accessor: "fullName",
                            resizable: false,
                          },
                          {
                            Header: "Title",
                            accessor: "title",
                          },
                          {
                            Header: "Role",
                            accessor: "vendorContactRole",
                            Cell: ({ value }) => (value ? vendorContactRoleHumanReadable[value] : null),
                          },
                          {
                            Header: "Email",
                            accessor: "email",
                          },
                          {
                            Header: "Phone",
                            accessor: "phoneNumber",
                          },
                        ]}
                        onPageChange={changePage => {
                          setLocationRepsPageNumber(changePage);
                        }}
                        onPageSizeChange={changePageSize => {
                          setLocationRepsPageSizeValue(changePageSize);
                          setLocationRepsPageNumber(0);
                        }}
                        page={locationRepsPageNumber}
                        pageSize={locationRepsPageSizeValue}
                        defaultPageSize={locationRepsSavedListSettings.pageSize}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        customCellClasses={[classes.right]}
                        customClassesForCells={[5]}
                        className={classes.locationTable}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {editLocationReps && (
                  <Grid item xs={12}>
                    <br />
                    <Grid container justify="space-between" alignItems="flex-end">
                      <Grid item md={12}>
                        <p style={{ margin: "0 0 16px" }}>
                          <b>Edit Staff For These Notifications</b>
                        </p>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            size="small"
                            value={selectedLocationReps}
                            options={fields.allReps.value.filter(
                              rep => !selectedLocationReps.find(slr => slr.id === rep.id)
                            )}
                            onChange={(event, newValue) => {
                              handleChangeLocationReps(newValue);
                            }}
                            getOptionSelected={(option, value) => {
                              return option.id === value;
                            }}
                            getOptionLabel={option => {
                              return option.label;
                            }}
                            renderInput={params => (
                              <TextField {...params} variant="standard" label="Reps" placeholder="" />
                            )}
                          />
                        </FormControl>
                        <br />
                        <br />
                        <Grid item md={12} className={classes.companyProfileContact}>
                          <Grid
                            container
                            style={{ display: "flex", width: "100%", justifyContent: "flex-end", gap: 8 }}
                          >
                            <Button onClick={handleCancelEditLocationClick}>Cancel</Button>
                            <Button color="primary" variant="contained" onClick={handleSaveEditLocationClick}>
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Tile>
            {/* <Tile
              title={<h5 className={classes.companyProfileHeader}>Default Contact At This Location</h5>}
              titleButtonText={getUpdateButtonText()}
              titleButtonFunc={handleUpdateOCAContact}
              titleButtonProps={{ color: "primary", size: "sm", disabled: isDisabledUpdateButton() }}
              classBody={classes.cardBody}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                    <b>Name: </b> {fields.ocaContactFullName.value}
                  </Grid>
                  <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                    <b>Title:</b> {fields.ocaContactTitle.value}
                  </Grid>
                </Grid>
                <Grid container alignItems="flex-end">
                  <Grid item xs={12} sm={8} md={4}>
                    {locationReps.length > 1 && (
                      <FormControl style={{ paddingTop: "16px" }} fullWidth>
                        <Grid container alignItems="center">
                          <InputLabel
                            shrink
                            style={{
                              color: "#AAAAAA !important",
                              fontWeight: "400",
                              position: "unset",
                              width: "max-content",
                              letterSpacing: "unset",
                              "& + $underline": {
                                marginTop: "0px",
                              },
                            }}
                            id="vendor-salesperson-id-label"
                          >
                            Contact
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">Default Contact</Typography>
                                  {
                                    "Select your Team Member who you'd like to have displayed on all default communications where a specific Sales Representative has yet to be assigned."
                                  }{" "}
                                </React.Fragment>
                              }
                            >
                              <InfoIcon className={classes.defaultIcon} color="primary"></InfoIcon>
                            </HtmlTooltip>
                          </InputLabel>
                        </Grid>
                        <Select
                          id="primaryContact"
                          label="Primary Contact"
                          name="ocaContactId"
                          variant="standard"
                          autoWidth
                          required
                          inputProps={{
                            type: "text",
                            name: "ocaContactId",
                            value: fields.ocaContactId.value,
                            onChange: e => {
                              setValue(e.target.value, "ocaContactId");
                            },
                          }}
                        >
                          {!!locationReps && locationReps.length > 1
                            ? locationReps.map(({ value, label }, i) => (
                                <MenuItem value={value} key={i} label={label}>
                                  {label}
                                </MenuItem>
                              ))
                            : "No Reps Available"}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Tile> */}
            {/* <div style={{ height: "50px", width: "10px" }}></div> */}
            <Tile
              title={
                <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                  <div style={{ display: "flex" }}>
                    <h5 className={classes.companyProfileHeader}>Notifications</h5>
                    <HtmlTooltip
                      title={
                        "Selected recipients will be notified via email whenever an online credit application is submitted for this location."
                      }
                    >
                      <InfoIcon className={classes.defaultIcon} color="primary" />
                    </HtmlTooltip>
                  </div>
                  <div className={classes.searchFields}>
                    <TableSearch
                      setPageNumber={setNotificationGroupPageNumber}
                      setSavedListSettings={setNotificationGroupSavedListSettings}
                      searchRef={notificationGroupSearchRef}
                      savedListSettings={notificationGroupSavedListSettings}
                    />
                    <TableSelect
                      setPageNumber={setNotificationGroupPageNumber}
                      setSavedListSettings={setNotificationGroupSavedListSettings}
                      selectRef={notificationsGroupRoleRef}
                      savedListSettings={notificationGroupSavedListSettings}
                      id="notification-role"
                      label="Role"
                      options={Object.entries(vendorContactRoleHumanReadable).map(ent => ({
                        value: ent[0],
                        text: ent[1],
                      }))}
                      width={200}
                    />
                  </div>
                </div>
              }
              titleButtonText="Edit"
              titleButtonFunc={handleEditNotificationGroupClick}
              className={classes.cardStyles}
              classBody={classes.cardBody}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justify="space-between" alignItems="flex-end">
                    <Grid item md={12}>
                      {!!fields.notificationGroup.value && fields.notificationGroup.value ? (
                        <Grid container alignItems="center">
                          <Grid item md={12}>
                            <Table
                              color="primary"
                              data={filteredNotificationGroup}
                              getTrProps={(state, rowInfo, column, instance) => ({
                                style: { cursor: "pointer" },
                                onClick: e => handleUserClick(rowInfo.original.dynamicsContactId),
                              })}
                              columns={[
                                {
                                  Header: "Name",
                                  accessor: "fullName",
                                  resizable: false,
                                },
                                {
                                  Header: "Title",
                                  accessor: "title",
                                },
                                {
                                  Header: "Role",
                                  accessor: "vendorContactRole",
                                  Cell: ({ value }) => (value ? vendorContactRoleHumanReadable[value] : null),
                                },
                                {
                                  Header: "Email",
                                  accessor: "email",
                                },
                                {
                                  Header: "Phone",
                                  accessor: "phoneNumber",
                                },
                              ]}
                              onPageChange={changePage => {
                                setNotificationGroupPageNumber(changePage);
                              }}
                              onPageSizeChange={changePageSize => {
                                setNotificationGroupPageSizeValue(changePageSize);
                                setNotificationGroupPageNumber(0);
                              }}
                              page={notificationGroupPageNumber}
                              pageSize={notificationGroupPageSizeValue}
                              defaultPageSize={notificationGroupSavedListSettings.pageSize}
                              showPaginationTop={false}
                              showPaginationBottom={true}
                              customCellClasses={[classes.right]}
                              customClassesForCells={[5]}
                              className={classes.locationTable}
                            />
                          </Grid>
                          {editNotificationGroup && (
                            <Grid item xs={12}>
                              <br />
                              <Grid container justify="space-between" alignItems="flex-end">
                                <Grid item md={12}>
                                  <p style={{ margin: "0 0 16px" }}>
                                    <b>Edit Staff For These Notifications</b>
                                  </p>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      multiple
                                      size="small"
                                      value={notificationGroup}
                                      options={fields.allReps.value.filter(
                                        rep => !notificationGroup.find(slr => slr.id === rep.id)
                                      )}
                                      onChange={(event, newValue) => {
                                        handleChangeNotificationReps(newValue);
                                      }}
                                      getOptionSelected={(option, value) => {
                                        return option.id === value;
                                      }}
                                      getOptionLabel={option => {
                                        return option.label;
                                      }}
                                      renderInput={params => (
                                        <TextField {...params} variant="standard" label="Reps" placeholder="" />
                                      )}
                                    />
                                  </FormControl>
                                  <br />
                                  <br />
                                  <Grid item md={12} className={classes.companyProfileContact}>
                                    <Grid
                                      container
                                      style={{ display: "flex", width: "100%", justifyContent: "flex-end", gap: 8 }}
                                    >
                                      <Button onClick={handleCancelEditNotificationClick}>Cancel</Button>
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSaveEditNotificationClick}
                                      >
                                        Save
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ) : (
                        "No users are currently configured to be notified.  Please EDIT to add desired recipients."
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Tile>
          </Grid>
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Successfully updated the Location!
            </Alert>
          </Snackbar>
          <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>

          <Modal open={openEditLocationModal} onClose={handleCloseEditLocation}>
            <CreateLocationModal
              locationDetails={locationDetails}
              locationsList={locationsList}
              account={account}
              closeCreateLocationModal={handleCloseEditLocation}
            />
          </Modal>
        </>
      ) : (
        <span>
          <CircularProgress size={15} /> Loading Location Details
        </span>
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails);
