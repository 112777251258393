import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import { setFieldValue, validateValue } from "../../utils";
import { gql } from "apollo-boost";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useMutation } from "@apollo/react-hooks";
import { formatSSN } from "../../utils";

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CREATE_CREDIT_APPLICATION = gql`
  mutation($creditApplication: CreateCreditApplicationInput!) {
    createCreditApplication(input: $creditApplication) {
      id
      success
      errorMessage
    }
  }
`;

function IndividualApp({ userProfile, match, setRefetch, account }) {
  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };

  const formatDate = val => {
    var formatVal = val.replace(/\D/g, "").substr(0, 8);
    var newVal = "";

    if (formatVal.length > 1) {
      val = formatVal;
    }

    if (formatVal.length > 2 && formatVal.length < 5) {
      newVal += val.substr(0, 2) + "/";
      formatVal = formatVal.substr(2);
    }

    if (formatVal.length >= 5) {
      newVal += formatVal.substr(0, 2) + "/";
      newVal += formatVal.substr(2, 2) + "/";
      formatVal = formatVal.substr(4);
    }

    newVal += formatVal;
    return newVal;
  };

  const formatNums = val => {
    return val.replace(/\D/g, "");
  };
  const [applicantIsOrWasMarried, setApplicantIsOrWasMarried] = useState(false);
  const [isApplicantInWisconsin, setApplicantInWisconsin] = useState(false);
  const [coApplicantIsOrWasMarried, setCoApplicantIsOrWasMarried] = useState(false);
  const [isCoApplicantInWisconsin, setCoApplicantInWisconsin] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [coApplicant, setCoApplicant] = useState(false);
  const [showEquipmentAdditionalFields, setShowEquipmentAdditionalFields] = useState(false);
  const [showTradeInFields, setShowTradeInFields] = useState(false);
  const [showTradeInAdditionalFields, setShowTradeInAdditionalFields] = useState(false);
  const [createCreditApp] = useMutation(CREATE_CREDIT_APPLICATION);
  const [fields, setFields] = useState({
    title: {
      value: "",
    },
    lastName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    firstName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    middleName: {
      value: "",
    },
    nameSuffix: {
      value: "",
    },
    dateOfBirth: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatDate,
    },
    ssn: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatSSN,
    },
    homePhone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatPhone,
    },
    phone: {
      value: "",
      format: formatPhone,
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "email" }],
    },
    homeAddress: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    homePostalCode: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    homeCity: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    homeState: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    homeCounty: {
      value: "",
    },
    timeAtAddressInMonths: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    timeAtAddressInYears: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    residenceType: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    monthlyHousingPayment: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    educationLevel: {
      value: "",
    },
    employeeType: {
      value: "",
    },
    employeeTitle: {
      value: "",
    },
    employmentStatus: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    employerName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    employerPhone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatPhone,
    },
    periodOfEmploymentYears: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    periodOfEmploymentMonths: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    annualIncome: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    incomeSourceOther: {
      value: "",
    },
    incomeAmountPerYearOther: {
      value: "",
      format: formatNums,
    },
    previousEmployerName: {
      value: "",
    },
    previousEmployerPeriodOfEmploymentYears: {
      value: "",
      format: formatNums,
    },
    previousEmployerPeriodOfEmploymentMonths: {
      value: "",
      format: formatNums,
    },
    primaryBankName: {
      value: "",
      label: "Bank Name",
    },
    bankAccountType: {
      value: "",
      label: "Bank Account Type",
      type: "select",
      selectOptions: [
        { value: "CHECKING", label: "Checking" },
        { value: "SAVINGS", label: "Savings" },
      ],
    },
    priorBankruptcy: {
      value: "",
      label: "Bankruptcy",
      type: "select",
      selectOptions: [
        {
          value: "Has not declared bankruptcy",
          label: "Has not declared bankruptcy",
        },
        { value: "Has declared bankruptcy", label: "Has declared bankruptcy" },
      ],
    },
    bankruptcyDate: {
      value: "",
      label: "Bankruptcy Date (MM/DD/YYYY)",
      format: formatDate,
    },
    priorRepossesion: {
      value: "",
      label: "Prior Repossession",
    },
    repossesionDate: {
      value: "",
      label: "Repossession Date (MM/DD/YYYY)",
      format: formatDate,
    },
    creditorOneName: {
      value: "",
      label: "Creditor Name",
    },
    creditorOneBalance: {
      value: "",
      label: "Creditor Balance #1",
      format: formatNums,
    },
    creditorOneMonthlyPayment: {
      value: "",
      label: "Creditor Monthly Payment #1",
      format: formatNums,
    },
    creditorTwoName: {
      value: "",
      label: "Creditor Name",
    },
    creditorTwoBalance: {
      value: "",
      label: "Creditor Balance #2",
      format: formatNums,
    },
    creditorTwoMonthlyPayment: {
      value: "",
      label: "Creditor Monthly Payment #2",
      format: formatNums,
    },
    companyFinancingAutoLoan: {
      value: "",
      label: "Co. Financing Auto Loan",
    },
    previousAutoLoanAccountNumber: {
      value: "",
      label: "Prev. Auto Loan Acct #",
    },
    previousAutoLoanMonthlyPayment: {
      value: "",
      label: "Prev. Monthly Payment",
    },
    prevAddress: {
      value: "",
    },
    prevAddressLine2: {
      value: "",
    },
    prevPostalCode: {
      value: "",
    },
    prevCity: {
      value: "",
    },
    prevState: {
      value: "",
    },
    prevCounty: {
      value: "",
    },
    timeAtPrevAddressYears: {
      value: "",
      format: formatNums,
    },
    timeAtPrevAddressMonths: {
      value: "",
      format: formatNums,
    },
    applicantMaritalStatus: {
      value: "",
    },
    applicantSpousesLastName: {
      value: "",
    },
    applicantSpousesFirstName: {
      value: "",
    },
    applicantSpousesAddress: {
      value: "",
    },
    applicantSpousesCity: {
      value: "",
    },
    applicantSpousesState: {
      value: "",
    },
    applicantSpousesZip: {
      value: "",
    },
  });

  const [coApplicantFields, setCoApplicantFields] = useState({
    relationshipCode: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    titleSalutation: {
      value: "",
    },
    lastName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    firstName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    middleName: {
      value: "",
    },
    nameSuffix: {
      value: "",
    },
    dateOfBirth: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatDate,
    },
    ssn: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatSSN,
    },
    homePhone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatPhone,
    },
    phone: {
      value: "",
      format: formatPhone,
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "email" }],
    },
    homeAddress: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    homePostalCode: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    homeCity: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    homeState: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    homeCounty: {
      value: "",
    },
    timeAtAddressInMonths: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    timeAtAddressInYears: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    residenceType: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    monthlyHousingPayment: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    educationLevel: {
      value: "",
    },
    employeeType: {
      value: "",
    },
    employeeTitle: {
      value: "",
    },
    employmentStatus: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    employerName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    employerPhone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatPhone,
    },
    periodOfEmploymentYears: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    periodOfEmploymentMonths: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    annualIncome: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    incomeSourceOther: {
      value: "",
    },
    incomeAmountPerYearOther: {
      value: "",
      format: formatNums,
    },
    previousEmployerName: {
      value: "",
    },
    previousEmployerPeriodOfEmploymentYears: {
      value: "",
      format: formatNums,
    },
    previousEmployerPeriodOfEmploymentMonths: {
      value: "",
      format: formatNums,
    },
    homeAddressLine2: {
      value: "",
    },
    prevAddress: {
      value: "",
    },
    prevAddressLine2: {
      value: "",
    },
    prevPostalCode: {
      value: "",
    },
    prevCity: {
      value: "",
    },
    prevState: {
      value: "",
    },
    prevCounty: {
      value: "",
    },
    timeAtPrevAddressYears: {
      value: "",
      format: formatNums,
    },
    timeAtPrevAddressMonths: {
      value: "",
      format: formatNums,
    },
    creditorOneBalance: {
      value: "",
    },
    creditorOneMonthlyPayment: {
      value: "",
    },
    creditorOneName: {
      value: "",
    },
    creditorTwoBalance: {
      value: "",
    },
    creditorTwoMonthlyPayment: {
      value: "",
    },
    creditorTwoName: {
      value: "",
    },
    bankAccountType: {
      value: "",
    },
    bankName: {
      value: "",
    },
    bankAccountNum: {
      value: "",
    },
    bankAverageBalance: {
      value: "",
    },
    bankPhone: {
      value: "",
    },
    bankAddress: {
      value: "",
    },
    bankAddressLine2: {
      value: "",
    },
    bankZip: {
      value: "",
    },
    bankCity: {
      value: "",
    },
    bankState: {
      value: "",
    },
    priorBankruptcy: {
      value: "",
    },
    bankruptcyDate: {
      value: "",
      format: formatDate,
    },
    priorRepossession: {
      value: "",
    },
    repossessionDate: {
      value: "",
      format: formatDate,
    },
    companyFinancingAutoLoan: {
      value: "",
    },
    previousAutoLoanAccountNumber: {
      value: "",
    },
    previousAutoLoanMonthlyPayment: {
      value: "",
    },
    coApplicantMaritalStatus: {
      value: "",
    },
    nonApplicantSpousesLastName: {
      value: "",
    },
    nonApplicantSpousesFirstName: {
      value: "",
    },
    nonApplicantSpousesAddress: {
      value: "",
    },
    nonApplicantSpousesCity: {
      value: "",
    },
    nonApplicantSpousesState: {
      value: "",
    },
    nonApplicantSpousesZip: {
      value: "",
    },
  });

  const [equipmentFields, setEquipmentFields] = useState({
    //base routeone equipment fields
    equipmentIntendedUse: {
      value: "",
      label: "Intended Use",
      type: "select",
      selectOptions: [
        { label: "Business", value: "BUSINESS" },
        { label: "Agricultural", value: "AGRICULTURAL" },
        { label: "Hazardous", value: "HAZARDOUS" },
        { label: "Local", value: "LOCAL" },
        { label: "Interstate", value: "INTERSTATE" },
        { label: "Intermediate", value: "INTERMEDIATE" },
        { label: "Personal", value: "PERSONAL" },
      ],
    },
    equipmentAssetType: {
      value: "",
      label: "Select Asset Type",
      type: "select",
      selectOptions: [
        { label: "Auto", value: "AUTO" },
        { label: "Light Truck", value: "LIGHT_TRUCK" },
        { label: "Medium Truck", value: "MEDIUM_TRUCK" },
        { label: "Conversion Van", value: "CONVERSION_VAN" },
        { label: "Other", value: "OTHER" },
      ],
    },
    equipmentNewUsed: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "New/Used",
      type: "select",
      selectOptions: [
        { label: "New", value: "New" },
        { label: "Used", value: "Used" },
        { label: "Demo", value: "Demo" },
      ],
    },
    stockId: {
      value: "",
      label: "Stock Number",
    },
    vin: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "VIN",
    },
    equipmentYear: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Year",
      format: formatNums,
    },
    equipmentMake: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Make",
    },
    // equipmentMakeOther: {
    //   value: "",
    //   label: "Other"
    // },
    equipmentModel: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Model",
    },
    // equipmentModelOther: {
    //   value: "",
    //   label: "Other"
    // },
    equipmentStyle: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Style/Trim",
    },
    // equipmentStyleOther: {
    //   value: "",
    //   validationStatus: "",
    //   validations: [{ type: "required" }],
    //   label: "Other"
    // },
    equipmentMileage: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Inception Miles",
      format: formatNums,
    },
  });

  const [tradeInFields, setTradeInFields] = useState({
    //base routeone trade-in fields
    year: {
      value: "",
      label: "Year",
      format: formatNums,
    },
    make: {
      value: "",
      label: "Make",
    },
    model: {
      value: "",
      label: "Model",
    },
  });

  const [equipmentAdditionalFields, setEquipmentAdditionalFields] = useState({
    equipmentSpecialPrograms: {
      value: "",
      label: "Special Programs",
      type: "select",
      selectOptions: [
        { label: "College Graduate", value: "COLLEGE_GRADUATE" },
        { label: "Other", value: "OTHER" },
        { label: "Military", value: "MILITARY" },
        { label: "Sales Finance", value: "SALES_FINANCE" },
        { label: "Customer Option Plan", value: "CUSTOMER_OPTION_PLAN" },
        { label: "Comtrac", value: "COMTRAC" },
        { label: "Employee Program", value: "EMPLOYEE_PROGRAM" },
        { label: "Repair Program", value: "REPAIR_PROGRAM" },
        { label: "Residual Purchase Plan", value: "RESIDUAL_PURCHASE_PLAN" },
        { label: "Credit Line", value: "CREDIT_LINE" },
        { label: "Lease To Retail", value: "LEASE_TO_RETAIL" },
        { lablel: "First Time Buyer", value: "FIRST_TIME_BUYER" },
        { label: "Lease Loyalty", value: "LEASE_LOYALTY" },
        { label: "Off Lease", value: "OFF_LEASE" },
        { label: "Single Pay Lease", value: "SINGLE_PAY_LEASE" },
        { label: "Multiple Units", value: "MULTIPLE_UNITS" },
      ],
    },
    equipmentOfferingType: {
      value: "",
      label: "Offering Type",
      type: "select",
      selectOptions: [
        { label: "New Offering", value: "NEW_OFFERING" },
        { label: "Transfer of Equity", value: "TRANSFER_OF_EQUITY" },
        { label: "Re-Write", value: "RE_WRITE" },
      ],
    },
    salesOrderNumber: {
      value: "",
      label: "Sales Order Number",
    },
    // applicationLanguage: {
    //   value: "",
    //   label: 'Application Language'
    // },
    equipmentPreapproval: {
      value: "",
      label: "Pre-Approval",
      type: "select",
      selectOptions: [
        { value: true, label: "Yes" },
        { value: "false", label: "No" },
      ],
    },
    equipmentPreapprovalProgramName: {
      value: "",
      label: "Pre-Approval Program Name",
    },
    numberOfEngineCylinders: {
      value: "",
      label: "# of Cylinders",
      format: formatNums,
    },
    equipmentOptions: {
      value: "",
      label: "Other Options",
    },
  });

  const [tradeInAdditionalFields, setTradeInAdditionalFields] = useState({
    //base routeone trade-in fields
    tradeInBodyStyle: {
      value: "",
      label: "Trade Vehicle Body Style",
    },
    options: {
      value: "",
      label: "Options",
    },
    wholesaleValueAmount: {
      value: "",
      label: "Wholesale Value Amount",
    },
  });

  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;

    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          console.log(`application ${fieldName} is invalid`);
          status = false;
        }
      }
    });
    if (coApplicant) {
      Object.keys(coApplicantFields).forEach(fieldName => {
        if (!!coApplicantFields[fieldName].validations && coApplicantFields[fieldName].validations.length) {
          const value = coApplicantFields[fieldName].value;
          const validations = coApplicantFields[fieldName].validations;
          coApplicantFields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
          if (coApplicantFields[fieldName].validationStatus !== "success") {
            console.log(`coApplicantFields ${fieldName} is invalid`);
            status = false;
          }
        }
      });
    }

    if (showEquipmentAdditionalFields) {
      Object.keys(equipmentAdditionalFields).forEach(fieldName => {
        if (
          !!equipmentAdditionalFields[fieldName].validations &&
          equipmentAdditionalFields[fieldName].validations.length
        ) {
          const value = equipmentAdditionalFields[fieldName].value;
          const validations = equipmentAdditionalFields[fieldName].validations;
          equipmentAdditionalFields[fieldName].validationStatus = validateValue(value, validations)
            ? "success"
            : "error";
          if (equipmentAdditionalFields[fieldName].validationStatus !== "success") {
            console.log(`equipmentAdditionalFields ${fieldName} is invalid`);
            status = false;
          }
        }
      });
    }

    if (showTradeInFields) {
      Object.keys(tradeInFields).forEach(fieldName => {
        if (!!tradeInFields[fieldName].validations && tradeInFields[fieldName].validations.length) {
          const value = tradeInFields[fieldName].value;
          const validations = tradeInFields[fieldName].validations;
          tradeInFields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
          if (tradeInFields[fieldName].validationStatus !== "success") {
            console.log(`tradeInFields ${fieldName} is invalid`);
            status = false;
          }
        }
      });
    }

    if (showTradeInAdditionalFields) {
      Object.keys(tradeInAdditionalFields).forEach(fieldName => {
        if (!!tradeInAdditionalFields[fieldName].validations && tradeInAdditionalFields[fieldName].validations.length) {
          const value = tradeInAdditionalFields[fieldName].value;
          const validations = tradeInAdditionalFields[fieldName].validations;
          tradeInAdditionalFields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
          if (tradeInAdditionalFields[fieldName].validationStatus !== "success") {
            console.log(`tradeInAdditionalFields ${fieldName} is invalid`);
            status = false;
          }
        }
      });
    }

    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  const setCoApplicantValue = (value, fieldName) =>
    setFieldValue(value, fieldName, coApplicantFields, setCoApplicantFields);

  const setEquipmentFieldsValue = (value, fieldName, equipmentFields, setEquipmentFields, label, type, selectOptions) =>
    setFieldValue(value, fieldName, equipmentFields, setEquipmentFields, label, type, selectOptions);

  const setEquipmentAdditionalFieldsValue = (
    value,
    fieldName,
    equipmentAdditionalFields,
    setEquipmentAdditionalFields,
    label,
    type,
    selectOptions
  ) =>
    setFieldValue(
      value,
      fieldName,
      equipmentAdditionalFields,
      setEquipmentAdditionalFields,
      label,
      type,
      selectOptions
    );

  const setTradeInFieldsValue = (value, fieldName) => setFieldValue(value, fieldName, tradeInFields, setTradeInFields);

  const setTradeInAdditionalFieldsValue = (value, fieldName) =>
    setFieldValue(value, fieldName, tradeInAdditionalFields, setTradeInAdditionalFields);

  const handleSubmit = async event => {
    try {
      checkFormValidation();
      const submitValues = {};
      const coApplicantValues = {};
      const equipmentValues = {};
      const equipmentAdditionalFieldsValues = {};
      const tradeInFieldsValues = {};
      const tradeInAdditionalFieldsValues = {};
      Object.keys(fields).forEach(fieldName => (submitValues[fieldName] = fields[fieldName].value));
      submitValues.ocaType = "RouteOne - Indiv App - portal";
      submitValues.applicationType = coApplicant ? "INDIVIDUAL_WITH_SECOND_GUARANTOR" : "INDIVIDUAL";
      submitValues.vendorGUID = account.dynamicsAccountId;
      submitValues.dateOfBirth = new Date(submitValues.dateOfBirth);
      submitValues.timeAtAddressInMonths = isNaN(parseInt(submitValues.timeAtAddressInMonths))
        ? null
        : parseInt(submitValues.timeAtAddressInMonths);
      submitValues.timeAtAddressInYears = isNaN(parseInt(submitValues.timeAtAddressInYears))
        ? null
        : parseInt(submitValues.timeAtAddressInYears);
      submitValues.monthlyHousingPayment = isNaN(parseFloat(submitValues.monthlyHousingPayment))
        ? null
        : parseFloat(submitValues.monthlyHousingPayment);
      submitValues.periodOfEmploymentYears = isNaN(parseInt(submitValues.periodOfEmploymentYears))
        ? null
        : parseInt(submitValues.periodOfEmploymentYears);
      submitValues.periodOfEmploymentMonths = isNaN(parseInt(submitValues.periodOfEmploymentMonths))
        ? null
        : parseInt(submitValues.periodOfEmploymentMonths);
      submitValues.annualIncome = isNaN(parseFloat(submitValues.annualIncome))
        ? null
        : parseFloat(submitValues.annualIncome);
      submitValues.incomeAmountPerYearOther = isNaN(parseFloat(submitValues.incomeAmountPerYearOther))
        ? null
        : parseFloat(submitValues.incomeAmountPerYearOther);
      submitValues.previousEmployerPeriodOfEmploymentYears = isNaN(
        parseInt(submitValues.previousEmployerPeriodOfEmploymentYears)
      )
        ? null
        : parseInt(submitValues.previousEmployerPeriodOfEmploymentYears);
      submitValues.previousEmployerPeriodOfEmploymentMonths = isNaN(
        parseInt(submitValues.previousEmployerPeriodOfEmploymentMonths)
      )
        ? null
        : parseInt(submitValues.previousEmployerPeriodOfEmploymentMonths);
      // submitValues.priorBankruptcy = fields.bankruptcy.value;
      submitValues.bankruptcyDate = new Date(fields.bankruptcyDate.value);
      // submitValues.priorRepossesion = fields.priorRepossesion.value;
      submitValues.repossesionDate = new Date(fields.repossesionDate.value);
      // submitValues.creditorOneName = fields.creditorOneName.value;
      // submitValues.creditorOneBalance = fields.creditorOneBalance.value;
      submitValues.creditorOneMonthlyPayment = isNaN(parseFloat(fields.creditorOneMonthlyPayment.value))
        ? null
        : parseFloat(fields.creditorOneMonthlyPayment.value);
      submitValues.creditorTwoName = fields.creditorTwoName.value;
      submitValues.creditorTwoBalance = fields.creditorTwoBalance.value;
      submitValues.creditorTwoMonthlyPayment = isNaN(parseFloat(fields.creditorTwoMonthlyPayment.value))
        ? null
        : parseFloat(fields.creditorTwoMonthlyPayment.value);
      submitValues.companyFinancingAutoLoan = fields.companyFinancingAutoLoan.value;
      submitValues.previousAutoLoanAccountNumber = isNaN(parseFloat(fields.previousAutoLoanAccountNumber.value))
        ? null
        : parseFloat(fields.previousAutoLoanAccountNumber.value);
      submitValues.previousAutoLoanMonthlyPayment = isNaN(parseFloat(fields.previousAutoLoanMonthlyPayment.value))
        ? null
        : parseFloat(fields.previousAutoLoanMonthlyPayment.value);
      submitValues.owners = [];
      submitValues.routeOne = true;
      console.log(submitValues);
      if (coApplicant) {
        Object.keys(coApplicantFields).forEach(
          fieldName => (coApplicantValues[fieldName] = coApplicantFields[fieldName].value)
        );
        submitValues.coApplicant = { ...coApplicantValues };
        submitValues.coApplicant.dateOfBirth = new Date(submitValues.coApplicant.dateOfBirth);
        submitValues.coApplicant.timeAtAddressInMonths = isNaN(parseInt(submitValues.coApplicant.timeAtAddressInMonths))
          ? null
          : parseInt(submitValues.coApplicant.timeAtAddressInMonths);
        submitValues.coApplicant.timeAtAddressInYears = isNaN(parseInt(submitValues.coApplicant.timeAtAddressInYears))
          ? null
          : parseInt(submitValues.coApplicant.timeAtAddressInYears);
        submitValues.coApplicant.monthlyHousingPayment = isNaN(
          parseFloat(submitValues.coApplicant.monthlyHousingPayment)
        )
          ? null
          : parseFloat(submitValues.coApplicant.monthlyHousingPayment);
        submitValues.coApplicant.periodOfEmploymentYears = isNaN(
          parseInt(submitValues.coApplicant.periodOfEmploymentYears)
        )
          ? null
          : parseInt(submitValues.coApplicant.periodOfEmploymentYears);
        submitValues.coApplicant.periodOfEmploymentMonths = isNaN(
          parseInt(submitValues.coApplicant.periodOfEmploymentMonths)
        )
          ? null
          : parseInt(submitValues.coApplicant.periodOfEmploymentMonths);
        submitValues.coApplicant.annualIncome = isNaN(parseFloat(submitValues.coApplicant.annualIncome))
          ? null
          : parseFloat(submitValues.coApplicant.annualIncome);
        submitValues.coApplicant.incomeAmountPerYearOther = isNaN(
          parseFloat(submitValues.coApplicant.incomeAmountPerYearOther)
        )
          ? null
          : parseFloat(submitValues.coApplicant.incomeAmountPerYearOther);
        submitValues.coApplicant.previousEmployerPeriodOfEmploymentYears = isNaN(
          parseInt(submitValues.coApplicant.previousEmployerPeriodOfEmploymentYears)
        )
          ? null
          : parseInt(submitValues.coApplicant.previousEmployerPeriodOfEmploymentYears);
        submitValues.coApplicant.previousEmployerPeriodOfEmploymentMonths = isNaN(
          parseInt(submitValues.coApplicant.previousEmployerPeriodOfEmploymentMonths)
        )
          ? null
          : parseInt(submitValues.coApplicant.previousEmployerPeriodOfEmploymentMonths);
        submitValues.coApplicant.previousAutoLoanAccountNumber = isNaN(
          parseFloat(submitValues.coApplicant.previousAutoLoanAccountNumber)
        )
          ? null
          : parseFloat(submitValues.coApplicant.previousAutoLoanAccountNumber);
        submitValues.coApplicant.previousAutoLoanMonthlyPayment = isNaN(
          parseFloat(submitValues.coApplicant.previousAutoLoanMonthlyPayment)
        )
          ? null
          : parseFloat(submitValues.coApplicant.previousAutoLoanMonthlyPayment);
      }
      Object.keys(equipmentFields).forEach(
        fieldName => (equipmentValues[fieldName] = equipmentFields[fieldName].value)
      );
      submitValues.equipment = {};
      submitValues.equipment.equipmentIntendedUse = equipmentValues.equipmentIntendedUse;
      submitValues.equipment.equipmentAssetType = equipmentValues.equipmentAssetType;
      submitValues.equipment.newUsed = equipmentValues.equipmentNewUsed;
      submitValues.equipment.stockId = equipmentValues.stockId;
      submitValues.equipment.serialNumberVin = equipmentValues.vin;
      submitValues.equipment.year = equipmentValues.equipmentYear;
      submitValues.equipment.make = equipmentValues.equipmentMake;
      submitValues.equipment.model = equipmentValues.equipmentModel;
      submitValues.equipment.style = equipmentValues.equipmentStyle;
      submitValues.equipment.mileage = isNaN(parseInt(equipmentValues.equipmentMileage))
        ? null
        : parseInt(equipmentValues.equipmentMileage);
      if (showEquipmentAdditionalFields) {
        Object.keys(equipmentAdditionalFields).forEach(
          fieldName => (equipmentAdditionalFieldsValues[fieldName] = equipmentAdditionalFields[fieldName].value)
        );
        submitValues.equipment = {
          ...submitValues.equipment,
          ...equipmentAdditionalFieldsValues,
        };
        submitValues.equipment.numberOfEngineCylinders = isNaN(
          parseInt(equipmentAdditionalFieldsValues.numberOfEngineCylinders)
        )
          ? null
          : parseInt(equipmentAdditionalFieldsValues.numberOfEngineCylinders);
      }
      if (showTradeInFields) {
        Object.keys(tradeInFields).forEach(
          fieldName => (tradeInFieldsValues[fieldName] = tradeInFields[fieldName].value)
        );
        submitValues.tradeIn = { ...tradeInFieldsValues };
      }
      if (showTradeInAdditionalFields) {
        Object.keys(tradeInAdditionalFields).forEach(
          fieldName => (tradeInAdditionalFieldsValues[fieldName] = tradeInAdditionalFields[fieldName].value)
        );
        submitValues.tradeIn = {
          ...tradeInFieldsValues,
          ...tradeInAdditionalFieldsValues,
        };
        submitValues.tradeIn.wholesaleValueAmount = isNaN(
          parseFloat(tradeInAdditionalFieldsValues.wholesaleValueAmount.value)
        )
          ? null
          : parseFloat(tradeInAdditionalFieldsValues.wholesaleValueAmount.value);
      }
      Object.keys(equipmentFields).forEach(
        fieldName => (equipmentValues[fieldName] = equipmentFields[fieldName].value)
      );
      submitValues.equipment = {};
      submitValues.equipment.equipmentIntendedUse = equipmentValues.equipmentIntendedUse;
      submitValues.equipment.equipmentAssetType = equipmentValues.equipmentAssetType;
      submitValues.equipment.newUsed = equipmentValues.equipmentNewUsed;
      submitValues.equipment.stockId = equipmentValues.stockId;
      submitValues.equipment.serialNumberVin = equipmentValues.vin;
      submitValues.equipment.year = equipmentValues.equipmentYear;
      submitValues.equipment.make = equipmentValues.equipmentMake;
      submitValues.equipment.model = equipmentValues.equipmentModel;
      submitValues.equipment.style = equipmentValues.equipmentStyle;
      submitValues.equipment.mileage = isNaN(parseInt(equipmentValues.equipmentMileage))
        ? null
        : parseInt(equipmentValues.equipmentMileage);
      if (showEquipmentAdditionalFields) {
        Object.keys(equipmentAdditionalFields).forEach(
          fieldName => (equipmentAdditionalFieldsValues[fieldName] = equipmentAdditionalFields[fieldName].value)
        );
        submitValues.equipment = {
          ...submitValues.equipment,
          ...equipmentAdditionalFieldsValues,
        };
        submitValues.equipment.numberOfEngineCylinders = isNaN(
          parseInt(equipmentAdditionalFieldsValues.numberOfEngineCylinders)
        )
          ? null
          : parseInt(equipmentAdditionalFieldsValues.numberOfEngineCylinders);
      }
      if (showTradeInFields) {
        Object.keys(tradeInFields).forEach(
          fieldName => (tradeInFieldsValues[fieldName] = tradeInFields[fieldName].value)
        );
        submitValues.tradeIn = { ...tradeInFieldsValues };
      }
      if (showTradeInAdditionalFields) {
        Object.keys(tradeInAdditionalFields).forEach(
          fieldName => (tradeInAdditionalFieldsValues[fieldName] = tradeInAdditionalFields[fieldName].value)
        );
        submitValues.tradeIn = {
          ...tradeInFieldsValues,
          ...tradeInAdditionalFieldsValues,
        };
        submitValues.tradeIn.wholesaleValueAmount = isNaN(
          parseFloat(tradeInAdditionalFieldsValues.wholesaleValueAmount.value)
        )
          ? null
          : parseFloat(tradeInAdditionalFieldsValues.wholesaleValueAmount.value);
      }

      await createCreditApp({
        variables: {
          creditApplication: {
            ...submitValues,
          },
        },
      });

      setSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error submitting credit app!");
      setErrorOpen(true);
    }
  };

  return (
    <>
      <Form
        onSubmit={values => {
          handleSubmit();
        }}
        validate={checkFormValidation}
        render={({ handleSubmit, pristine, valid, submitting, values }) => {
          function uploadButtonHandler(event) {
            const file = document.getElementById("file");
            file.click();
          }
          function uploadFileChanged(event) {
            fields.file.value = event.target.files[0];
            const reader = new FileReader();
            reader.onload = e => {
              const avatar = document.getElementById("avatar");
              setValue(e.target.result, "mugshot");
              avatar.src = e.target.result;
            };
            reader.readAsDataURL(event.target.files[0]);
            checkFormValidation();
          }
          function handleClose() {
            setSnackbarOpen(false);
            setErrorOpen(false);
          }

          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit().then(res => console.log(res));
              }}
            >
              <Grid container>
                <Tile>
                  <Grid container>
                    <GridItem xs={12} sm={3} md={3} lg={2}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="title">
                          Salutation
                        </InputLabel>
                        <Select
                          inputProps={{
                            value: fields.title.value,
                            name: "title",
                            id: "title",
                            onChange: e => setValue(e.target.value, "title"),
                          }}
                        >
                          <MenuItem value={"Mr"}>Mr</MenuItem>
                          <MenuItem value={"Mrs"}>Mrs</MenuItem>
                          <MenuItem value={"Miss"}>Miss</MenuItem>
                          <MenuItem value={"Dr"}>Dr</MenuItem>
                          <MenuItem value={"Ms"}>Ms</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} lg={3}>
                      <CustomInput
                        labelText="Last Name"
                        id="last-name"
                        formControlProps={{ fullWidth: true }}
                        // error={
                        //   fields.fullName.validationStatus === "error"
                        // }
                        inputProps={{
                          type: "text",
                          name: "lastName",
                          value: fields.lastName.value,
                          onChange: e => setValue(e.target.value, "lastName"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} lg={3}>
                      <CustomInput
                        labelText="First Name"
                        id="first-name"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "text",
                          name: "firstName",
                          value: fields.firstName.value,
                          onChange: e => setValue(e.target.value, "firstName"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} lg={2}>
                      <CustomInput
                        labelText="MI"
                        id="mi"
                        formControlProps={{ fullWidth: true }}
                        // error={
                        //   fields.phone.validationStatus === "error"
                        // }
                        inputProps={{
                          type: "text",
                          name: "middleName",
                          value: fields.middleName.value,
                          onChange: e => setValue(e.target.value, "middleName"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} lg={2}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="name-suffix">
                          Suffix
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.nameSuffix.value,
                            name: "nameSuffix",
                            id: "name-suffix",
                            onChange: e => setValue(e.target.value, "nameSuffix"),
                          }}
                        >
                          <MenuItem value={"I"}>I</MenuItem>
                          <MenuItem value={"II"}>II</MenuItem>
                          <MenuItem value={"III"}>III</MenuItem>
                          <MenuItem value={"IV"}>IV</MenuItem>
                          <MenuItem value={"V"}>V</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <CustomInput
                        labelText="DOB(MM/DD/YYYY)"
                        id="dob"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "dateOfBirth",
                          value: fields.dateOfBirth.value,
                          onChange: e => setValue(e.target.value, "dateOfBirth"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={4}>
                      <CustomInput
                        labelText="SSN"
                        id="ssn"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "ssn",
                          value: fields.ssn.value,
                          onChange: e => setValue(e.target.value, "ssn"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={3} lg={4}>
                      <CustomInput
                        labelText="Home Phone"
                        id="home-phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "homePhone",
                          value: fields.homePhone.value,
                          onChange: e => setValue(e.target.value, "homePhone"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={4}>
                      <CustomInput
                        labelText="Cell Phone"
                        id="cell-phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "phone",
                          value: fields.phone.value,
                          onChange: e => setValue(e.target.value, "phone"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={4}>
                      <CustomInput
                        labelText="Preferred Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "email",
                          value: fields.email.value,
                          onChange: e => setValue(e.target.value, "email"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={3} lg={4}>
                      <CustomInput
                        labelText="Home Address"
                        id="home-address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "homeAddress",
                          value: fields.homeAddress.value,
                          onChange: e => setValue(e.target.value, "homeAddress"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="City"
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "homeCity",
                          value: fields.homeCity.value,
                          onChange: e => setValue(e.target.value, "homeCity"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="State"
                        id="state"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "homeState",
                          value: fields.homeState.value,
                          onChange: e => {
                            setApplicantInWisconsin(e.target.value === "WI");
                            setValue(e.target.value, "homeState");
                          },
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                      <CustomInput
                        labelText="Zip"
                        id="zip"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "homePostalCode",
                          value: fields.homePostalCode.value,
                          onChange: e => setValue(e.target.value, "homePostalCode"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                      <CustomInput
                        labelText="County"
                        id="homeCounty"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "homeCounty",
                          value: fields.homeCounty.value,
                          onChange: e => setValue(e.target.value, "homeCounty"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Time At Present Address In Years"
                        id="time-years"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "timeAtAddressInYears",
                          value: fields.timeAtAddressInYears.value,
                          onChange: e => setValue(e.target.value, "timeAtAddressInYears"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Time At Present Address In Months"
                        id="time-months"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "timeAtAddressInMonths",
                          value: fields.timeAtAddressInMonths.value,
                          onChange: e => setValue(e.target.value, "timeAtAddressInMonths"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                          Residence Type
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.residenceType.value,
                            name: "residenceType",
                            id: "simple-select",
                            onChange: e => setValue(e.target.value, "residenceType"),
                          }}
                        >
                          <MenuItem value={"OWNS_HOME_OUTRIGHT"}>Owns Home Outright</MenuItem>
                          <MenuItem value={"BUYING_HOME"}>Buying Home</MenuItem>
                          <MenuItem value={"LIVING_WITH_RELATIVES"}>Living With Relatives</MenuItem>
                          <MenuItem value={"LEASING_OR_RENTING"}>Leasing Or Renting</MenuItem>
                          <MenuItem value={"OWNS_BUYING_MOBILE_HOME"}>Owns/Buying Mobile Home</MenuItem>
                          <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Rent/Mortgage"
                        id="rent-mortgage"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "monthlyHousingPayment",
                          value: fields.monthlyHousingPayment.value,
                          onChange: e => setValue(e.target.value, "monthlyHousingPayment"),
                        }}
                      />
                    </GridItem>
                  </Grid>

                  {isApplicantInWisconsin && (
                    <>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={4}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Applicant Marital Status
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: fields.applicantMaritalStatus.value,
                                name: "applicantMaritalStatus",
                                id: "simple-select",
                                onChange: e => {
                                  setValue(e.target.value, "applicantMaritalStatus");
                                  if (e.target.value === "MARRIED" || e.target.value === "SEPARATED") {
                                    setApplicantIsOrWasMarried(true);
                                  } else {
                                    setApplicantIsOrWasMarried(false);
                                  }
                                },
                              }}
                            >
                              <MenuItem value={"UNMARRIED_SINGLE_WIDOWED_DIVORCED"}>
                                Unmarried (Includes single, windowed, divorced)
                              </MenuItem>
                              <MenuItem value={"MARRIED"}>Married</MenuItem>
                              <MenuItem value={"SEPARATED"}>Separated</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        {applicantIsOrWasMarried && (
                          <>
                            <GridItem xs={12} sm={12} md={4} lg={4}>
                              <CustomInput
                                labelText="Applicant Spouse's Last Name"
                                id="applicant-spouses-last-name"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: "applicantSpousesLastName",
                                  value: fields.applicantSpousesLastName.value,
                                  onChange: e => setValue(e.target.value, "applicantSpousesLastName"),
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} lg={4}>
                              <CustomInput
                                labelText="Applicant Spouse's First Name"
                                id="applicant-spouses-first-name"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: "applicantSpousesFirstName",
                                  value: fields.applicantSpousesFirstName.value,
                                  onChange: e => setValue(e.target.value, "applicantSpousesFirstName"),
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} lg={4}>
                              <CustomInput
                                labelText="Applicant Spouse's Address"
                                id="applicant-spouses-address"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: "applicantSpousesAddress",
                                  value: fields.applicantSpousesAddress.value,
                                  onChange: e => setValue(e.target.value, "applicantSpousesAddress"),
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} lg={4}>
                              <CustomInput
                                labelText="Applicant Spouse's City"
                                id="applicant-spouses-city"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: "applicantSpousesCity",
                                  value: fields.applicantSpousesCity.value,
                                  onChange: e => setValue(e.target.value, "applicantSpousesCity"),
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} lg={4}>
                              <CustomInput
                                labelText="Applicant Spouse's State"
                                id="applicant-spouses-state"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: "applicantSpousesState",
                                  value: fields.applicantSpousesState.value,
                                  onChange: e => setValue(e.target.value, "applicantSpousesState"),
                                }}
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} lg={4}>
                              <CustomInput
                                labelText="Applicant Spouse's Zip"
                                id="applicant-spouses-zip"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: "applicantSpousesZip",
                                  value: fields.applicantSpousesZip.value,
                                  onChange: e => setValue(e.target.value, "applicantSpousesZip"),
                                }}
                              />
                            </GridItem>
                          </>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid container>
                    <GridItem xs={12} sm={12} md={3} lg={4}>
                      <CustomInput
                        labelText="Previous Address"
                        id="prev-address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "prevAddress",
                          value: fields.prevAddress.value,
                          onChange: e => setValue(e.target.value, "prevAddress"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Previous City"
                        id="prev-city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "prevCity",
                          value: fields.prevCity.value,
                          onChange: e => setValue(e.target.value, "prevCity"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Previous State"
                        id="prev-state"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "prevState",
                          value: fields.prevState.value,
                          onChange: e => setValue(e.target.value, "prevState"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                      <CustomInput
                        labelText="Previous Zip"
                        id="prev-zip"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "prevPostalCode",
                          value: fields.prevPostalCode.value,
                          onChange: e => setValue(e.target.value, "prevPostalCode"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} lg={2}>
                      <CustomInput
                        labelText="Previous County"
                        id="prevCounty"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "prevCounty",
                          value: fields.prevCounty.value,
                          onChange: e => setValue(e.target.value, "prevCounty"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Time At Previous Address In Years"
                        id="prev-time-years"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "timeAtPrevAddressYears",
                          value: fields.timeAtPrevAddressYears.value,
                          onChange: e => setValue(e.target.value, "timeAtPrevAddressYears"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Time At Previous Address In Months"
                        id="prev-time-months"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "timeAtPrevAddressMonths",
                          value: fields.timeAtPrevAddressMonths.value,
                          onChange: e => setValue(e.target.value, "timeAtPrevAddressMonths"),
                        }}
                      />
                    </GridItem>
                  </Grid>

                  <Grid container>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                          Education Level
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.educationLevel.value,
                            name: "educationLevel",
                            id: "simple-select",
                            onChange: e => setValue(e.target.value, "educationLevel"),
                          }}
                        >
                          <MenuItem value={"FOUR_YEAR_COLLEGE_GRAD"}>Four Year College Grad</MenuItem>
                          <MenuItem value={"TWO_YEAR_COLLEGE_GRAD"}>Two Year College Grad</MenuItem>
                          <MenuItem value={"SPECIALTY_TRAINING"}>Specialty Training</MenuItem>
                          <MenuItem value={"SOME_COLLEGE"}>Some College</MenuItem>
                          <MenuItem value={"HIGH_SCHOOL_GRAD"}>High School Grad</MenuItem>
                          <MenuItem value={"NOT_HIGH_SCHOOL_GRAD"}>Not High School Grad</MenuItem>
                          <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                          Employment Status
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.employmentStatus.value,
                            name: "employmentStatus",
                            id: "simple-select",
                            onChange: e => setValue(e.target.value, "employmentStatus"),
                          }}
                        >
                          <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                          <MenuItem value={"PART_TIME"}>Part-Time</MenuItem>
                          <MenuItem value={"RETIRED"}>Retired</MenuItem>
                          <MenuItem value={"MILITARY"}>Military</MenuItem>
                          <MenuItem value={"SELF_EMPLOYED"}>Self-Employed</MenuItem>
                          <MenuItem value={"CONTRACT"}>Contract</MenuItem>
                          <MenuItem value={"SEASONAL"}>Seasonal</MenuItem>
                          <MenuItem value={"TEMPORARY"}>Temporary</MenuItem>
                          <MenuItem value={"NOT_APPLICABLE"}>Not Applicable</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                          Employee Type
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.employeeType.value,
                            name: "employeeType",
                            id: "simple-select",
                            onChange: e => setValue(e.target.value, "employeeType"),
                          }}
                        >
                          <MenuItem value={"Professional"}>Professional</MenuItem>
                          <MenuItem value={"Executive/Managerial"}>Executive/Managerial</MenuItem>
                          <MenuItem value={"Clerical"}>Clerical</MenuItem>
                          <MenuItem value={"Skilled Labor"}>Skilled Labor</MenuItem>
                          <MenuItem value={"Semi-Skilled Labor"}>Semi-Skilled Labor</MenuItem>
                          <MenuItem value={"Craftsman"}>Craftsman</MenuItem>
                          <MenuItem value={"Sales/Advertising"}>Sales/Advertising</MenuItem>
                          <MenuItem value={"Government"}>Government</MenuItem>
                          <MenuItem value={"Auto Worker"}>Auto Worker</MenuItem>
                          <MenuItem value={"Farmer"}>Farmer</MenuItem>
                          <MenuItem value={"Fisherman"}>Fisherman</MenuItem>
                          <MenuItem value={"Homemaker"}>Homemaker</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Empl. Title"
                        id="empl-title"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "employeeTitle",
                          value: fields.employeeTitle.value,
                          onChange: e => setValue(e.target.value, "employeeTitle"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Employer"
                        id="employer"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "employerName",
                          value: fields.employerName.value,
                          onChange: e => setValue(e.target.value, "employerName"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Phone"
                        id="empl-phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "employerPhone",
                          value: fields.employerPhone.value,
                          onChange: e => setValue(e.target.value, "employerPhone"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Time At Job In Years"
                        id="job-time-years"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "periodOfEmploymentYears",
                          value: fields.periodOfEmploymentYears.value,
                          onChange: e => setValue(e.target.value, "periodOfEmploymentYears"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Time At Job In Month"
                        id="job-time-month"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "periodOfEmploymentMonths",
                          value: fields.periodOfEmploymentMonths.value,
                          onChange: e => setValue(e.target.value, "periodOfEmploymentMonths"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Gross Income"
                        id="gross-income"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "annualIncome",
                          value: fields.annualIncome.value,
                          onChange: e => setValue(e.target.value, "annualIncome"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Other Income Source"
                        id="other-income"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "incomeSourceOther",
                          value: fields.incomeSourceOther.value,
                          onChange: e => setValue(e.target.value, "incomeSourceOther"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <CustomInput
                        labelText="Other Income Amount"
                        id="other-income"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "incomeAmountPerYearOther",
                          value: fields.incomeAmountPerYearOther.value,
                          onChange: e => setValue(e.target.value, "incomeAmountPerYearOther"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid container>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Previous Employer Name"
                        id="prev-employer"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "previousEmployerName",
                          value: fields.previousEmployerName.value,
                          onChange: e => setValue(e.target.value, "previousEmployerName"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Time At Previous Employer In Years"
                        id="prev-empl-year"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "previousEmployerPeriodOfEmploymentYears",
                          value: fields.previousEmployerPeriodOfEmploymentYears.value,
                          onChange: e => setValue(e.target.value, "previousEmployerPeriodOfEmploymentYears"),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Time At Previous Employer In Months"
                        id="prev-empl-months"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "previousEmployerPeriodOfEmploymentMonths",
                          value: fields.previousEmployerPeriodOfEmploymentMonths.value,
                          onChange: e => setValue(e.target.value, "previousEmployerPeriodOfEmploymentMonths"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Bank Name"
                        id="bank-name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "primaryBankName",
                          value: fields.primaryBankName.value,
                          onChange: e => setValue(e.target.value, "primaryBankName"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                          Bank Account Type
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.bankAccountType.value,
                            name: "bankAccountType",
                            id: "simple-select",
                            onChange: e => setValue(e.target.value, "bankAccountType"),
                          }}
                        >
                          <MenuItem value={"CHECKING"}>Checking</MenuItem>
                          <MenuItem value={"SAVINGS"}>Savings</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                          Bankruptcy
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.priorBankruptcy.value,
                            name: "priorBankruptcy",
                            id: "simple-select",
                            onChange: e => setValue(e.target.value, "priorBankruptcy"),
                          }}
                        >
                          <MenuItem value={"Has not declared bankruptcy"}>Has not declared bankruptcy</MenuItem>
                          <MenuItem value={"Has declared bankruptcy"}>Has declared bankruptcy</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Bankruptcy Date (MM/DD/YYYY)"
                        id="bankruptcyDate"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "bankruptcyDate",
                          value: fields.bankruptcyDate.value,
                          onChange: e => setValue(e.target.value, "bankruptcyDate"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3} lg={3}>
                      <FormControl style={{ paddingTop: "10px" }} fullWidth>
                        <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                          Prior Repossession
                        </InputLabel>
                        <Select
                          required
                          inputProps={{
                            value: fields.priorRepossesion.value,
                            name: "priorRepossesion",
                            id: "simple-select",
                            onChange: e => setValue(e.target.value, "priorRepossesion"),
                          }}
                        >
                          <MenuItem value={"Yes"}>Has prior repossession</MenuItem>
                          <MenuItem value={"No"}>No prior repossession</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Repossession Date (MM/DD/YYYY)"
                        id="repossesionDate"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "repossesionDate",
                          value: fields.repossesionDate.value,
                          onChange: e => setValue(e.target.value, "repossesionDate"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Creditor Name"
                        id="creditor-name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "creditorOneName",
                          value: fields.creditorOneName.value,
                          onChange: e => setValue(e.target.value, "creditorOneName"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Creditor Balance #1"
                        id="creditor-balance-one"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "creditorOneBalance",
                          value: fields.creditorOneBalance.value,
                          onChange: e => setValue(e.target.value, "creditorOneBalance"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Creditor Monthly Payment #1"
                        id="creditor-monthly-payment-one"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "creditorOneMonthlyPayment",
                          value: fields.creditorOneMonthlyPayment.value,
                          onChange: e => setValue(e.target.value, "creditorOneMonthlyPayment"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Creditor Name #2"
                        id="creditor-name-two"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "creditorTwoName",
                          value: fields.creditorTwoName.value,
                          onChange: e => setValue(e.target.value, "creditorTwoName"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Creditor Balance #2"
                        id="creditor-balance-two"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "creditorTwoBalance",
                          value: fields.creditorTwoBalance.value,
                          onChange: e => setValue(e.target.value, "creditorTwoBalance"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Creditor Monthly Payment #2"
                        id="creditor-monthly-payment-two"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "creditorTwoMonthlyPayment",
                          value: fields.creditorTwoMonthlyPayment.value,
                          onChange: e => setValue(e.target.value, "creditorTwoMonthlyPayment"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Co. Financing Auto Loan"
                        id="company-financing-auto-loan"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "companyFinancingAutoLoan",
                          value: fields.companyFinancingAutoLoan.value,
                          onChange: e => setValue(e.target.value, "companyFinancingAutoLoan"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Prev. Auto Loan Acct #"
                        id="prev-auto-loan-account-number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "previousAutoLoanAccountNumber",
                          value: fields.previousAutoLoanAccountNumber.value,
                          onChange: e => setValue(e.target.value, "previousAutoLoanAccountNumber"),
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} lg={4}>
                      <CustomInput
                        labelText="Prev. Auto Loan Monthly Payment"
                        id="prev-auto-loan-monthly-payment"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          name: "previousAutoLoanMonthlyPayment",
                          value: fields.previousAutoLoanMonthlyPayment.value,
                          onChange: e => setValue(e.target.value, "previousAutoLoanMonthlyPayment"),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <br />
                  <Grid container justify="center" alignItems="center">
                    <GridItem xs={8} sm={3} md={3} lg={2}>
                      <Button
                        className="formBtn"
                        color="primary"
                        variant="contained"
                        onClick={() => setCoApplicant(!coApplicant)}
                      >
                        {coApplicant ? "Remove Co-Applicant" : "Add Co-Applicant"}
                      </Button>
                    </GridItem>
                  </Grid>
                  {coApplicant && (
                    <>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={4}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Relationship Code
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.relationshipCode.value,
                                name: "reltionshipCode",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "relationshipCode"),
                              }}
                            >
                              <MenuItem value={"PARENT"}>Parent</MenuItem>
                              <MenuItem value={"RESIDES_WITH"}>Resides With</MenuItem>
                              <MenuItem value={"OTHER"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={3} md={3} lg={2}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="titleSalutation">
                              Salutation
                            </InputLabel>
                            <Select
                              inputProps={{
                                value: coApplicantFields.titleSalutation.value,
                                name: "titleSalutation",
                                id: "titleSalutation",
                                onChange: e => setCoApplicantValue(e.target.value, "titleSalutation"),
                              }}
                            >
                              <MenuItem value={"Mr"}>Mr</MenuItem>
                              <MenuItem value={"Mrs"}>Mrs</MenuItem>
                              <MenuItem value={"Miss"}>Miss</MenuItem>
                              <MenuItem value={"Dr"}>Dr</MenuItem>
                              <MenuItem value={"Ms"}>Ms</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Last Name"
                            id="last-name"
                            formControlProps={{ fullWidth: true }}
                            // error={
                            //   coApplicantFields.fullName.validationStatus === "error"
                            // }
                            inputProps={{
                              type: "text",
                              name: "lastName",
                              value: coApplicantFields.lastName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "lastName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="First Name"
                            id="first-name"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "firstName",
                              value: coApplicantFields.firstName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "firstName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={2}>
                          <CustomInput
                            labelText="MI"
                            id="mi"
                            formControlProps={{ fullWidth: true }}
                            // error={
                            //   coApplicantFields.phone.validationStatus === "error"
                            // }
                            inputProps={{
                              type: "text",
                              name: "middleName",
                              value: coApplicantFields.middleName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "middleName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={2}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="name-suffix">
                              Suffix
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.nameSuffix.value,
                                name: "nameSuffix",
                                id: "name-suffix",
                                onChange: e => setCoApplicantValue(e.target.value, "nameSuffix"),
                              }}
                            >
                              <MenuItem value={"I"}>I</MenuItem>
                              <MenuItem value={"II"}>II</MenuItem>
                              <MenuItem value={"III"}>III</MenuItem>
                              <MenuItem value={"IV"}>IV</MenuItem>
                              <MenuItem value={"V"}>V</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <CustomInput
                            labelText="DOB(MM/DD/YYYY)"
                            id="dob"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "dateOfBirth",
                              value: coApplicantFields.dateOfBirth.value,
                              onChange: e => setCoApplicantValue(e.target.value, "dateOfBirth"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={4}>
                          <CustomInput
                            labelText="SSN"
                            id="ssn"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "ssn",
                              value: coApplicantFields.ssn.value,
                              onChange: e => setCoApplicantValue(e.target.value, "ssn"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={4}>
                          <CustomInput
                            labelText="Home Phone"
                            id="home-phone"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "homePhone",
                              value: coApplicantFields.homePhone.value,
                              onChange: e => setCoApplicantValue(e.target.value, "homePhone"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={4}>
                          <CustomInput
                            labelText="Cell Phone"
                            id="cell-phone"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "phone",
                              value: coApplicantFields.phone.value,
                              onChange: e => setCoApplicantValue(e.target.value, "phone"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={4}>
                          <CustomInput
                            labelText="Preferred Email"
                            id="email"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "email",
                              value: coApplicantFields.email.value,
                              onChange: e => setCoApplicantValue(e.target.value, "email"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={4}>
                          <CustomInput
                            labelText="Home Address"
                            id="home-address"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "homeAddress",
                              value: coApplicantFields.homeAddress.value,
                              onChange: e => setCoApplicantValue(e.target.value, "homeAddress"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="City"
                            id="city"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "homeCity",
                              value: coApplicantFields.homeCity.value,
                              onChange: e => setCoApplicantValue(e.target.value, "homeCity"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="State"
                            id="state"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "homeState",
                              value: coApplicantFields.homeState.value,
                              onChange: e => {
                                setCoApplicantInWisconsin(e.target.value === "WI");
                                setCoApplicantValue(e.target.value, "homeState");
                              },
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Zip"
                            id="zip"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "homePostalCode",
                              value: coApplicantFields.homePostalCode.value,
                              onChange: e => setCoApplicantValue(e.target.value, "homePostalCode"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="County"
                            id="homeCounty"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "homeCounty",
                              value: coApplicantFields.homeCounty.value,
                              onChange: e => setCoApplicantValue(e.target.value, "homeCounty"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Time At Present Address In Years"
                            id="time-years"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "timeAtAddressInYears",
                              value: coApplicantFields.timeAtAddressInYears.value,
                              onChange: e => setCoApplicantValue(e.target.value, "timeAtAddressInYears"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Time At Present Address In Months"
                            id="time-months"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "timeAtAddressInMonths",
                              value: coApplicantFields.timeAtAddressInMonths.value,
                              onChange: e => setCoApplicantValue(e.target.value, "timeAtAddressInMonths"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid container>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Residence Type
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.residenceType.value,
                                name: "residenceType",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "residenceType"),
                              }}
                            >
                              <MenuItem value={"OWNS_HOME_OUTRIGHT"}>Owns Home Outright</MenuItem>
                              <MenuItem value={"BUYING_HOME"}>Buying Home</MenuItem>
                              <MenuItem value={"LIVING_WITH_RELATIVES"}>Living With Relatives</MenuItem>
                              <MenuItem value={"LEASING_OR_RENTING"}>Leasing Or Renting</MenuItem>
                              <MenuItem value={"OWNS_BUYING_MOBILE_HOME"}>Owns/Buying Mobile Home</MenuItem>
                              <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Rent/Mortgage"
                            id="rent-mortgage"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "monthlyHousingPayment",
                              value: coApplicantFields.monthlyHousingPayment.value,
                              onChange: e => setCoApplicantValue(e.target.value, "monthlyHousingPayment"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      {isCoApplicantInWisconsin && (
                        <>
                          <Grid container>
                            <GridItem xs={12} sm={12} md={3} lg={4}>
                              <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                  Co-Applicant Marital Status
                                </InputLabel>
                                <Select
                                  required
                                  inputProps={{
                                    value: fields.coApplicantMaritalStatus.value,
                                    name: "coApplicantMaritalStatus",
                                    id: "simple-select",
                                    onChange: e => {
                                      setCoApplicantValue(e.target.value, "coApplicantMaritalStatus");
                                      if (e.target.value === "MARRIED" || e.target.value === "SEPARATED") {
                                        setCoApplicantIsOrWasMarried(true);
                                      } else {
                                        setCoApplicantIsOrWasMarried(false);
                                      }
                                    },
                                  }}
                                >
                                  <MenuItem value={"UNMARRIED_SINGLE_WIDOWED_DIVORCED"}>
                                    Unmarried (Includes single, windowed, divorced)
                                  </MenuItem>
                                  <MenuItem value={"MARRIED"}>Married</MenuItem>
                                  <MenuItem value={"SEPARATED"}>Separated</MenuItem>
                                </Select>
                              </FormControl>
                            </GridItem>
                            {coApplicantIsOrWasMarried && (
                              <>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <CustomInput
                                    labelText="Non-applicant Spouse's Last Name"
                                    id="prev-city"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: "text",
                                      name: "nonApplicantSpousesLastName",
                                      value: coApplicantFields.nonApplicantSpousesLastName.value,
                                      onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesLastName"),
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <CustomInput
                                    labelText="Non-applicant Spouse's First Name"
                                    id="prev-city"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: "text",
                                      name: "nonApplicantSpousesFirstName",
                                      value: coApplicantFields.nonApplicantSpousesFirstName.value,
                                      onChange: e =>
                                        setCoApplicantValue(e.target.value, "nonApplicantSpousesFirstName"),
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <CustomInput
                                    labelText="Non-applicant Spouse's Address"
                                    id="prev-city"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: "text",
                                      name: "nonApplicantSpousesAddress",
                                      value: coApplicantFields.nonApplicantSpousesAddress.value,
                                      onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesAddress"),
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <CustomInput
                                    labelText="Non-applicant Spouse's City"
                                    id="prev-city"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: "text",
                                      name: "nonApplicantSpousesCity",
                                      value: coApplicantFields.nonApplicantSpousesCity.value,
                                      onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesCity"),
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <CustomInput
                                    labelText="Non-applicant Spouse's State"
                                    id="prev-city"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: "text",
                                      name: "nonApplicantSpousesState",
                                      value: coApplicantFields.nonApplicantSpousesState.value,
                                      onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesState"),
                                    }}
                                  />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                  <CustomInput
                                    labelText="Non-applicant Spouse's Zip"
                                    id="prev-city"
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      type: "text",
                                      name: "nonApplicantSpousesZip",
                                      value: coApplicantFields.nonApplicantSpousesZip.value,
                                      onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesZip"),
                                    }}
                                  />
                                </GridItem>
                              </>
                            )}
                          </Grid>
                        </>
                      )}
                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={4}>
                          <CustomInput
                            labelText="Prev Home Address"
                            id="prev-home-address"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "prevAddress",
                              value: coApplicantFields.prevAddress.value,
                              onChange: e => setCoApplicantValue(e.target.value, "prevAddress"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Prev City"
                            id="prev-city"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "prevCity",
                              value: coApplicantFields.prevCity.value,
                              onChange: e => setCoApplicantValue(e.target.value, "prevCity"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Prev State"
                            id="prev-state"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "prevState",
                              value: coApplicantFields.prevState.value,
                              onChange: e => setCoApplicantValue(e.target.value, "prevState"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Prev Zip"
                            id="prev-zip"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "prevPostalCode",
                              value: coApplicantFields.prevPostalCode.value,
                              onChange: e => setCoApplicantValue(e.target.value, "prevPostalCode"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Prev County"
                            id="prevCounty"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "prevCounty",
                              value: coApplicantFields.prevCounty.value,
                              onChange: e => setCoApplicantValue(e.target.value, "prevCounty"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Time At Previous Address In Years"
                            id="time-at-previous-address-years"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "timeAtPrevAddressYears",
                              value: coApplicantFields.timeAtPrevAddressYears.value,
                              onChange: e => setCoApplicantValue(e.target.value, "timeAtPrevAddressYears"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Time At Previous Address In Months"
                            id="time-at-previous-address-months"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "timeAtPrevAddressMonths",
                              value: coApplicantFields.timeAtPrevAddressMonths.value,
                              onChange: e => setCoApplicantValue(e.target.value, "timeAtPrevAddressMonths"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid container>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Creditor One Name"
                            id="creditor-one-name"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "creditorOneName",
                              value: coApplicantFields.creditorOneName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "creditorOneName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Creditor One Balance"
                            id="creditor-one-balance"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "creditorOneBalance",
                              value: coApplicantFields.creditorOneBalance.value,
                              onChange: e => setCoApplicantValue(e.target.value, "creditorOneBalance"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Creditor One Monthly Payment"
                            id="creditor-one-monthly-payment"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "creditorOneMonthlyPayment",
                              value: coApplicantFields.creditorOneMonthlyPayment.value,
                              onChange: e => setCoApplicantValue(e.target.value, "creditorOneMonthlyPayment"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid container>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Creditor Two Name"
                            id="creditor-two-name"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "creditorTwoName",
                              value: coApplicantFields.creditorTwoName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "creditorTwoName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Creditor Two Balance"
                            id="creditor-two-balance"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "creditorTwoBalance",
                              value: coApplicantFields.creditorTwoBalance.value,
                              onChange: e => setCoApplicantValue(e.target.value, "creditorTwoBalance"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Creditor Two Monthly Payment"
                            id="creditor-two-monthly-payment"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "creditorTwoMonthlyPayment",
                              value: coApplicantFields.creditorTwoMonthlyPayment.value,
                              onChange: e => setCoApplicantValue(e.target.value, "creditorTwoMonthlyPayment"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Co. Financing Auto Loan"
                            id="co-applicant-company-financing-auto-loan"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "companyFinancingAutoLoan",
                              value: coApplicantFields.companyFinancingAutoLoan.value,
                              onChange: e => setCoApplicantValue(e.target.value, "companyFinancingAutoLoan"),
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Prev. Auto Loan Acct #"
                            id="co-applicant-prev-auto-loan-account-number"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "previousAutoLoanAccountNumber",
                              value: coApplicantFields.previousAutoLoanAccountNumber.value,
                              onChange: e => setCoApplicantValue(e.target.value, "previousAutoLoanAccountNumber"),
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Prev. Auto Loan Monthly Payment"
                            id="co-applicant-prev-auto-loan-monthly-payment"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "previousAutoLoanMonthlyPayment",
                              value: coApplicantFields.previousAutoLoanMonthlyPayment.value,
                              onChange: e => setCoApplicantValue(e.target.value, "previousAutoLoanMonthlyPayment"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid container>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <CustomInput
                            labelText="Bank Name"
                            id="co-applicant-bank-name"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankName",
                              value: coApplicantFields.bankName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} lg={2}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Bank Account Type
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.bankAccountType.value,
                                name: "bankAccountType",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "bankAccountType"),
                              }}
                            >
                              <MenuItem value={"CHECKING"}>Checking</MenuItem>
                              <MenuItem value={"SAVINGS"}>Savings</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank Account Number"
                            id="co-applicant-bank-account-number"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankAccountNum",
                              value: coApplicantFields.bankAccountNum.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankAccountNum"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank Account Balance"
                            id="co-applicant-bank-account-balance"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankAverageBalance",
                              value: coApplicantFields.bankAverageBalance.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankAverageBalance"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank Phone #"
                            id="co-applicant-bank-phone"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankPhone",
                              value: coApplicantFields.bankPhone.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankPhone"),
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank Address"
                            id="co-applicant-bank-address"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankAddress",
                              value: coApplicantFields.bankAddress.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankAddress"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank Address 2"
                            id="co-applicant-bank-address-2"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankAddressLine2",
                              value: coApplicantFields.bankAddressLine2.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankAddressLine2"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank City"
                            id="co-applicant-bank-city"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankCity",
                              value: coApplicantFields.bankCity.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankCity"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank State"
                            id="co-applicant-bank-state"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankState",
                              value: coApplicantFields.bankState.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankState"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bank Zip"
                            id="co-applicant-bank-zip"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "bankZip",
                              value: coApplicantFields.bankZip.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankZip"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Bankruptcy
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.priorBankruptcy.value,
                                name: "priorBankruptcy",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "priorBankruptcy"),
                              }}
                            >
                              <MenuItem value={"Has not declared bankruptcy"}>Has not declared bankruptcy</MenuItem>
                              <MenuItem value={"Has declared bankruptcy"}>Has declared bankruptcy</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Bankruptcy Date (MM/DD/YYYY)"
                            id="coApplicantBankruptcyDate"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "coApplicantBankruptcyDate",
                              value: coApplicantFields.bankruptcyDate.value,
                              onChange: e => setCoApplicantValue(e.target.value, "bankruptcyDate"),
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Prior Repossession
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.priorRepossession.value,
                                name: "coApplicantPriorRepossession",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "priorRepossession"),
                              }}
                            >
                              <MenuItem value={"Yes"}>Has prior repossession</MenuItem>
                              <MenuItem value={"No"}>No prior repossession</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Repossession Date (MM/DD/YYYY)"
                            id="coApplicantRepossesionDate"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "coApplicantRepossesionDate",
                              value: coApplicantFields.repossessionDate.value,
                              onChange: e => setCoApplicantValue(e.target.value, "repossessionDate"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Education Level
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.educationLevel.value,
                                name: "educationLevel",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "educationLevel"),
                              }}
                            >
                              <MenuItem value={"FOUR_YEAR_COLLEGE_GRAD"}>Four Year College Grad</MenuItem>
                              <MenuItem value={"TWO_YEAR_COLLEGE_GRAD"}>Two Year College Grad</MenuItem>
                              <MenuItem value={"SPECIALTY_TRAINING"}>Specialty Training</MenuItem>
                              <MenuItem value={"SOME_COLLEGE"}>Some College</MenuItem>
                              <MenuItem value={"HIGH_SCHOOL_GRAD"}>High School Grad</MenuItem>
                              <MenuItem value={"NOT_HIGH_SCHOOL_GRAD"}>Not High School Grad</MenuItem>
                              <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Employment Status
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.employmentStatus.value,
                                name: "employmentStatus",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "employmentStatus"),
                              }}
                            >
                              <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                              <MenuItem value={"PART_TIME"}>Part-Time</MenuItem>
                              <MenuItem value={"RETIRED"}>Retired</MenuItem>
                              <MenuItem value={"MILITARY"}>Military</MenuItem>
                              <MenuItem value={"SELF_EMPLOYED"}>Self-Employed</MenuItem>
                              <MenuItem value={"CONTRACT"}>Contract</MenuItem>
                              <MenuItem value={"SEASONAL"}>Seasonal</MenuItem>
                              <MenuItem value={"TEMPORARY"}>Temporary</MenuItem>
                              <MenuItem value={"NOT_APPLICABLE"}>Not Applicable</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              Employee Type
                            </InputLabel>
                            <Select
                              required
                              inputProps={{
                                value: coApplicantFields.employeeType.value,
                                name: "employeeType",
                                id: "simple-select",
                                onChange: e => setCoApplicantValue(e.target.value, "employeeType"),
                              }}
                            >
                              <MenuItem value={"Professional"}>Professional</MenuItem>
                              <MenuItem value={"Executive/Managerial"}>Executive/Managerial</MenuItem>
                              <MenuItem value={"Clerical"}>Clerical</MenuItem>
                              <MenuItem value={"Skilled Labor"}>Skilled Labor</MenuItem>
                              <MenuItem value={"Semi-Skilled Labor"}>Semi-Skilled Labor</MenuItem>
                              <MenuItem value={"Craftsman"}>Craftsman</MenuItem>
                              <MenuItem value={"Sales/Advertising"}>Sales/Advertising</MenuItem>
                              <MenuItem value={"Government"}>Government</MenuItem>
                              <MenuItem value={"Auto Worker"}>Auto Worker</MenuItem>
                              <MenuItem value={"Farmer"}>Farmer</MenuItem>
                              <MenuItem value={"Fisherman"}>Fisherman</MenuItem>
                              <MenuItem value={"Homemaker"}>Homemaker</MenuItem>
                              <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Empl. Title"
                            id="empl-title"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "employeeTitle",
                              value: coApplicantFields.employeeTitle.value,
                              onChange: e => setCoApplicantValue(e.target.value, "employeeTitle"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Employer"
                            id="employer"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "employerName",
                              value: coApplicantFields.employerName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "employerName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Phone"
                            id="empl-phone"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "employerPhone",
                              value: coApplicantFields.employerPhone.value,
                              onChange: e => setCoApplicantValue(e.target.value, "employerPhone"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Time At Job In Years"
                            id="job-time-years"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "periodOfEmploymentYears",
                              value: coApplicantFields.periodOfEmploymentYears.value,
                              onChange: e => setCoApplicantValue(e.target.value, "periodOfEmploymentYears"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Time At Job In Month"
                            id="job-time-month"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "periodOfEmploymentMonths",
                              value: coApplicantFields.periodOfEmploymentMonths.value,
                              onChange: e => setCoApplicantValue(e.target.value, "periodOfEmploymentMonths"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Gross Income"
                            id="gross-income"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "annualIncome",
                              value: coApplicantFields.annualIncome.value,
                              onChange: e => setCoApplicantValue(e.target.value, "annualIncome"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Other Income Source"
                            id="other-income"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "incomeSourceOther",
                              value: coApplicantFields.incomeSourceOther.value,
                              onChange: e => setCoApplicantValue(e.target.value, "incomeSourceOther"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3} lg={3}>
                          <CustomInput
                            labelText="Other Income Amount"
                            id="other-income"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "incomeAmountPerYearOther",
                              value: coApplicantFields.incomeAmountPerYearOther.value,
                              onChange: e => setCoApplicantValue(e.target.value, "incomeAmountPerYearOther"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Previous Employer Name"
                            id="prev-employer"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "previousEmployerName",
                              value: coApplicantFields.previousEmployerName.value,
                              onChange: e => setCoApplicantValue(e.target.value, "previousEmployerName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Time At Previous Employer In Years"
                            id="prev-empl-year"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "previousEmployerPeriodOfEmploymentYears",
                              value: coApplicantFields.previousEmployerPeriodOfEmploymentYears.value,
                              onChange: e =>
                                setCoApplicantValue(e.target.value, "previousEmployerPeriodOfEmploymentYears"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4}>
                          <CustomInput
                            labelText="Time At Previous Employer In Months"
                            id="prev-empl-months"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "previousEmployerPeriodOfEmploymentMonths",
                              value: coApplicantFields.previousEmployerPeriodOfEmploymentMonths.value,
                              onChange: e =>
                                setCoApplicantValue(e.target.value, "previousEmployerPeriodOfEmploymentMonths"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                    </>
                  )}
                  <h3>Sale Vehicle</h3>
                  <Grid container>
                    {Object.entries(equipmentFields).map(item =>
                      item[1].type === "select" ? (
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <FormControl style={{ paddingTop: "10px" }} fullWidth>
                            <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                              {item[1].label}
                            </InputLabel>
                            <Select
                              inputProps={{
                                name: item[0],
                                id: "simple-select",
                                onChange: e =>
                                  setEquipmentFieldsValue(
                                    e.target.value,
                                    item[0],
                                    equipmentFields,
                                    setEquipmentFields,
                                    item[1].label,
                                    item[1].type,
                                    item[1].selectOptions
                                  ),
                              }}
                            >
                              {item[1].selectOptions.map(item => (
                                <MenuItem value={item.value}>{item.label}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      ) : item[1].type === "calculated" ? (
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText={item[1].label}
                            id={item[1].label}
                            disabled
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: true,
                              type: "text",
                              name: item[0],
                              value: item[1].value,
                              onChange: e =>
                                setEquipmentFieldsValue(
                                  e.target.value,
                                  item[0],
                                  equipmentFields,
                                  setEquipmentFields,
                                  item[1].label
                                ),
                            }}
                          />
                        </GridItem>
                      ) : (
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText={item[1].label}
                            id={item[1].label}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: item[0],
                              value: item[1].value,
                              onChange: e =>
                                setEquipmentFieldsValue(
                                  e.target.value,
                                  item[0],
                                  equipmentFields,
                                  setEquipmentFields,
                                  item[1].label
                                ),
                            }}
                          />
                        </GridItem>
                      )
                    )}
                  </Grid>
                  <Button onClick={() => setShowEquipmentAdditionalFields(!showEquipmentAdditionalFields)}>
                    {showEquipmentAdditionalFields
                      ? "Hide Additional Equipment Fields"
                      : "Show Additional Equipment Fields"}
                  </Button>
                  {showEquipmentAdditionalFields && (
                    <>
                      <h3>Sale Vehicle Additional Fields</h3>
                      <Grid container>
                        {Object.entries(equipmentAdditionalFields).map(item =>
                          item[1].type === "select" ? (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                  {item[1].label}
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    name: item[0],
                                    id: "simple-select",
                                    onChange: e =>
                                      setEquipmentAdditionalFieldsValue(
                                        e.target.value,
                                        item[0],
                                        equipmentAdditionalFields,
                                        setEquipmentAdditionalFields,
                                        item[1].label,
                                        item[1].type,
                                        item[1].selectOptions
                                      ),
                                  }}
                                >
                                  {item[1].selectOptions.map(item => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                          ) : item[1].type === "calculated" ? (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                disabled
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  disabled: true,
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: e =>
                                    setEquipmentAdditionalFieldsValue(
                                      e.target.value,
                                      item[0],
                                      equipmentAdditionalFields,
                                      setEquipmentAdditionalFields,
                                      item[1].label
                                    ),
                                }}
                              />
                            </GridItem>
                          ) : (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: e =>
                                    setEquipmentAdditionalFieldsValue(
                                      e.target.value,
                                      item[0],
                                      equipmentAdditionalFields,
                                      setEquipmentAdditionalFields,
                                      item[1].label
                                    ),
                                }}
                              />
                            </GridItem>
                          )
                        )}
                      </Grid>
                    </>
                  )}
                  <Button onClick={() => setShowTradeInFields(!showTradeInFields)}>
                    {showTradeInFields ? "Hide Trade-In" : "Add Trade-In"}
                  </Button>
                  {showTradeInFields && (
                    <>
                      <h3>Trade-In Fields</h3>
                      <Grid container>
                        {Object.entries(tradeInFields).map(item =>
                          item[1].type === "select" ? (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                  {item[1].label}
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    name: item[0],
                                    id: "simple-select",
                                    onChange: e =>
                                      setTradeInFieldsValue(
                                        e.target.value,
                                        item[0],
                                        tradeInFields,
                                        setTradeInFields,
                                        item[1].label,
                                        item[1].type,
                                        item[1].selectOptions
                                      ),
                                  }}
                                >
                                  {item[1].selectOptions.map(item => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                          ) : item[1].type === "calculated" ? (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                disabled
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  disabled: true,
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: e =>
                                    setTradeInFieldsValue(
                                      e.target.value,
                                      item[0],
                                      tradeInFields,
                                      setTradeInFields,
                                      item[1].label
                                    ),
                                }}
                              />
                            </GridItem>
                          ) : (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: e =>
                                    setTradeInFieldsValue(
                                      e.target.value,
                                      item[0],
                                      tradeInFields,
                                      setTradeInFields,
                                      item[1].label
                                    ),
                                }}
                              />
                            </GridItem>
                          )
                        )}
                      </Grid>
                      <Button onClick={() => setShowTradeInAdditionalFields(!showTradeInAdditionalFields)}>
                        {showTradeInAdditionalFields
                          ? "Hide Additional Trade-In Fields"
                          : "Add Trade-In Additional Fields"}
                      </Button>
                    </>
                  )}
                  {showTradeInAdditionalFields && (
                    <>
                      <h3>Trade-In Additional Fields</h3>
                      <Grid container>
                        {Object.entries(tradeInAdditionalFields).map(item =>
                          item[1].type === "select" ? (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                  {item[1].label}
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    name: item[0],
                                    id: "simple-select",
                                    onChange: e =>
                                      setTradeInAdditionalFieldsValue(
                                        e.target.value,
                                        item[0],
                                        tradeInAdditionalFields,
                                        setTradeInAdditionalFields,
                                        item[1].label,
                                        item[1].type,
                                        item[1].selectOptions
                                      ),
                                  }}
                                >
                                  {item[1].selectOptions.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                          ) : item[1].type === "calculated" ? (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                disabled
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  disabled: true,
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: e =>
                                    setTradeInAdditionalFieldsValue(
                                      e.target.value,
                                      item[0],
                                      tradeInAdditionalFields,
                                      setTradeInAdditionalFields,
                                      item[1].label
                                    ),
                                }}
                              />
                            </GridItem>
                          ) : (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: e =>
                                    setTradeInAdditionalFieldsValue(
                                      e.target.value,
                                      item[0],
                                      tradeInAdditionalFields,
                                      setTradeInAdditionalFields,
                                      item[1].label
                                    ),
                                }}
                              />
                            </GridItem>
                          )
                        )}
                      </Grid>
                    </>
                  )}
                  <Grid container justify="center" alignItems="center">
                    <GridItem xs={8} sm={3} md={3} lg={2}>
                      <Button
                        className="formBtn"
                        color="primary"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={submitting || !formValid}
                      >
                        {submitting ? "Submitting" : "Submit"}
                      </Button>
                    </GridItem>
                  </Grid>
                </Tile>
              </Grid>
              <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Successfully submitted your credit app!
                </Alert>
              </Snackbar>
              <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </form>
          );
        }}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    account: state.account,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualApp);
