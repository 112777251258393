import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import SyntaxHighlighter from "react-syntax-highlighter";
import salesQuoteImg from "assets/img/wizards/sales_quote_example.png";

const config = require("../../../config");

const SalesQuotesInstructions = () => {
  let useStyles = makeStyles({
    linkExample: {
      padding: "5px",
      display: "block",
      margin: "5px 0",
      fontWeight: "bolder",
    },

    instructions: {
      "& > li": {
        padding: "5px",
        fontWeight: 400,
      },
    },
    imgSalesQuoteImg: {
      width: "30vw",
      borderRadius: "10px",
    },
  });
  let classes = useStyles();

  const account = useSelector(state => state.account);
  return (
    <Grid container spacing={4}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography>
          Let your prospects and customers know that financing is available right on your sales quotations. Clicking on
          a link takes them to your Secure Credit App where they will get offers in less than 3 minutes.
        </Typography>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container justify="space-evenly">
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <p>Sales quote example:</p>
            <img src={salesQuoteImg} className={classes.imgSalesQuoteImg} />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Typography variant="h5">Instructions:</Typography>

            <ol className={classes.instructions}>
              <li>
                Your link is the following one:{" "}
                <span
                  className={classes.linkExample}
                >{`${config.REACT_APP_OCA_BASE_URL}/?vendorGUID=${account.dynamicsAccountId}`}</span>
                You can use to attach it to any document/quote sent to your customers.
              </li>
              <li>
                Include the anchor tag into your quote generator as follows:
                <SyntaxHighlighter language="xml">
                  {`
<div style="text-align: center; width: 100%;">
  <h6 style="font-weight:bolder;">INSTANT FINANCING AVAILABLE!</h6>
  <br/>
  <a href="${config.REACT_APP_OCA_BASE_URL}/?vendorGUID=${account.dynamicsAccountId}">
     Click here for Instant Pre-Qualification
  </a>
  <br/>
  <p>Powered by Trnsact</p>
</div>`}
                </SyntaxHighlighter>
              </li>
              <li>
                As seen in the example on left side, the placement of the link can vary and you can choose the styling
                and wording that best fit your needs.
              </li>
            </ol>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesQuotesInstructions;
