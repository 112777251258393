import { useDrag } from "react-dnd";

interface DraggableComponentProps {
  draggableComponent: JSX.Element;
  data: any; // Data to be passed along to the drop target
}

const DraggableComponent: React.FC<DraggableComponentProps> = ({ draggableComponent, data }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "ITEM",
      item: { productsToAdd: data },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [data]
  );

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        // backgroundColor: 'lightblue',
        // padding: '10px',
        // margin: '10px',
        // border: '1px solid gray',
      }}
    >
      {draggableComponent}
    </div>
  );
};

export default DraggableComponent;
