import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "../../components/CustomButtons/Button";
import { lenderTypeMap } from "pages/VendorOpportunity/LenderSubmissionMappingModals/constants";

import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";

import MuiAlert from "@material-ui/lab/Alert";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    marginRight: "10px",
    right: theme.spacing(0.5),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  requiredHint: {
    fontSize: "10px",
    fontWeight: "300",
    textAlign: "right",
    width: "100%",
    margin: "16px 0 0",
  },
});
const useStyles = makeStyles(styles);

const UPDATE_CREDITSUB_EXPIRED_CREDENTIALS = gql`
  mutation($fieldsObj: UpdateCreditSubmissionInput!, $entityId: String!) {
    updateCreditSubmission(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const CHANGE_LENDER_WEBSITE_CREDENTIALS = gql`
  mutation UpdateLenderProfileWebsiteCredentials($input: LenderProfileWebsiteCredentialsInput!) {
    updateLenderProfileWebsiteCredentials(input: $input)
  }
`;

const INSERT_INTEGRATION_SETTINGS_VALUES = gql`
  mutation insertIntegrationSettingsValues($input: InsertIntegrationSettingsValues!) {
    insertIntegrationSettingsValues(input: $input) {
      accountId
      createdDateTime
      integrationName
      integrationSettingsValues
      integrationSettingsValuesId
      locationId
      modifiedDateTime
      userProfileId
    }
  }
`;

const INTEGRATION_SETTINGS = gql`
  query($integrationName: String!) {
    integrationSettings(integrationName: $integrationName) {
      accountLevel
      createdDateTime
      integrationAdminAccountId
      integrationName
      integrationSettings
      locationLevel
      modifiedDateTime
      userProfileLevel
    }
  }
`;

const INTEGRATION_SETTINGS_VALUES = gql`
  query($input: IntegrationSettingsValuesInput!) {
    integrationSettingsValues(input: $input) {
      accountId
      createdDateTime
      integrationName
      integrationSettingsValues
      integrationSettingsValuesId
      locationId
      modifiedDateTime
      userProfileId
    }
  }
`;

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
export default function UpdateLenderCredentials({
  open,
  handleClose: handleCloseModal,
  lenderProfile,
  account = null,
  userProfile = null,
}) {
  const classes = useStyles();

  const [updateCredentials, { loading: mutationLoading }] = useMutation(CHANGE_LENDER_WEBSITE_CREDENTIALS, {
    context: { authRequired: true },
  });

  const [updateCreditSubmissionExpiredCredentials] = useMutation(UPDATE_CREDITSUB_EXPIRED_CREDENTIALS, {
    context: { authRequired: true },
  });

  const { data: integrationSettings, refetch: getIntegrationSettings } = useQuery(INTEGRATION_SETTINGS, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    skip: true,
  });

  const { refetch: getIntegrationSettingsValues } = useQuery(INTEGRATION_SETTINGS_VALUES, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    skip: true,
  });

  const [insertIntegrationSettingsValues] = useMutation(INSERT_INTEGRATION_SETTINGS_VALUES, {
    context: { authRequired: true },
  });

  const initialFormValues = {
    username: "",
    password: "",
  };

  const initialErrorStates = {
    username: false,
    password: false,
  };
  //Fields
  const [values, setValues] = useState(initialFormValues);

  //Contro Inputs
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [havePortal, setHavePortal] = useState(false);
  const [settings, setSettings] = useState(null);
  const [settingsValues, setSettingsValues] = useState(null);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });

    //User selected Manual submission to Portal, this enables Lender's Website fields
    if (prop == "channel") {
      setHavePortal(event.target.value == 200);
    }

    if (submitted) {
      runValidations();
    }
  };
  const resetStates = () => {
    setValues(initialFormValues);
    setErrors(initialErrorStates);
    setSubmitted(false);
  };
  const handleClose = () => {
    handleCloseModal();
    resetStates();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [errors, setErrors] = useState(initialErrorStates);

  let checker = arr => arr.every(v => v === false);

  const setIntegrationSettings = async () => {
    const lenderMapName = lenderTypeMap.get(lenderProfile?.lenderChannelCode);
    const intSettings = await getIntegrationSettings({ integrationName: lenderMapName });
    setSettings(intSettings?.data?.integrationSettings);

    if (intSettings?.data?.integrationSettings?.integrationName) {
      const intSettingsValues = await getIntegrationSettingsValues({
        input: { integrationName: lenderMapName, userProfileId: userProfile.id },
      });
      setValues({ ...intSettingsValues?.data?.integrationSettingsValues?.integrationSettingsValues });
      setSettingsValues(intSettingsValues?.data?.integrationSettingsValues);
    }
  };

  useEffect(() => {
    setSubmitSuccess(false);
  }, [open]);

  useEffect(() => {
    if (integrationSettings?.data?.integrationSettings) {
      setSettings(integrationSettings?.data?.integrationSettings);
    }
  }, [integrationSettings?.data?.integrationSettings]);

  useEffect(() => {
    const botAndApiChannels = [100, 200, 150];

    if (lenderProfile) {
      if (!botAndApiChannels.includes(lenderProfile.lenderChannelCode)) {
        setIntegrationSettings();
        setHavePortal(true);
      } else {
        setHavePortal(false);
      }
    }
  }, [lenderProfile]);

  const runValidations = credentials => {
    const errorCollect = {
      ...errors,
      username: credentials.username ? false : true,
      password: credentials.password ? false : true,
    };
    setErrors(errorCollect);

    const result = checker(Object.values(errorCollect));

    return result;
  };
  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitted(true);
    setSubmitSuccess(false);

    if (settings.userProfileLevel) {
      const insertValues = { username: values.username, password: values.password };
      const validationsPassed = runValidations(insertValues);
      if (validationsPassed) {
        await insertIntegrationSettingsValues({
          variables: {
            input: {
              accountId: account?.id,
              integrationName: settings?.integrationName,
              integrationSettingsValues: insertValues,
              userProfileId: userProfile?.id,
              integrationSettingsValuesId: settingsValues?.integrationSettingsValuesId || undefined,
            },
          },
        })
          .then(res => {
            console.log("UserProfile Values Insert :: ", res);
            const urlParams = new URLSearchParams(window.location.search);
            const lenderId = urlParams.get("lender");

            if (lenderId === lenderProfile.lenderProfileId) {
              window.history.back();
            }

            setSubmitSuccess(true);
            handleClose();
          })
          .catch(error => {
            console.log("Account Values Insert Error :: ", error);
            handleClose();
          });
      }
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle disableTypography id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="h6" style={{ marginRight: 32 }}>
          {lenderProfile?.lenderName} Credentials
        </Typography>
        <span> </span>
        {handleClose ? (
          <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers>
        {!!lenderProfile && havePortal && settings ? (
          <>
            <Snackbar
              open={submitSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              autoHideDuration={2000}
              onClose={handleClose}
            >
              <Alert severity="success">Changes saved</Alert>
            </Snackbar>

            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container justify="center" spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <InputLabel htmlFor="standard-adornment-password">Username *</InputLabel>
                  <TextField
                    error={errors.username}
                    aria-label="lenderWebsiteUsername"
                    name="lenderWebsiteUsername"
                    value={values.username}
                    onChange={handleChange("username")}
                    type="text"
                    fullWidth
                    autoComplete="off"
                    required={true}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={3}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <InputLabel htmlFor="standard-adornment-password">Password *</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    error={errors.password}
                    fullWidth
                    label="Password *"
                    autoComplete={""}
                    required={true}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container justify="center">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    className={classes.submitButton}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={mutationLoading}
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
              <p className={classes.requiredHint}>
                Providing credentials allows submission to your lender to be automated saving you time.
              </p>
            </form>
          </>
        ) : (
          "This lender has no website configured yet, please contact your account manager"
        )}
      </DialogContent>
    </Dialog>
  );
}
