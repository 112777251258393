import React from "react";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import clsx from "clsx";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import DoneIcon from "@material-ui/icons/Done";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { translateStageCodes } from "../../../../../utils";
import { TableCell } from "../../../../Table/TableCell";

export const StageCell = ({ row, value, handleUpdateCredentials }: any) => {
  const classes = useStyles();

  let apiDetail = null;
  switch (row.apiResult) {
    case "failure":
      apiDetail = (
        <Tooltip title={row.apiDetail} placement="top-start">
          <PriorityHighIcon className={clsx(classes.apiResultIcon, classes.apiResultIconFailed)} />
        </Tooltip>
      );
      break;
    case "in progress":
      apiDetail = (
        <Tooltip title={"Processing"} placement="top-start">
          <HourglassEmptyIcon className={classes.apiResultIcon} />
        </Tooltip>
      );
      break;
    case "success":
      apiDetail = (
        <Tooltip title={row.apiDetail} placement="top-start">
          <DoneIcon className={clsx(classes.apiResultIcon, classes.apiResultIconSuccess)} />
        </Tooltip>
      );
      break;

    default:
      break;
  }

  if (row.apiExpiredCredentials) {
    return (
      <TableCell>
        <PriorityHighIcon className={clsx(classes.apiResultIcon, classes.apiResultIconFailed)} />
        <Typography variant="body2">Credentials Expired</Typography>
        <Button
          size="small"
          className={classes.buttonUpdateCredentials}
          onClick={() => {
            handleUpdateCredentials(row);
          }}
        >
          Update Credentials
        </Button>
      </TableCell>
    );
  }

  if (value) {
    return (
      <TableCell>
        <Typography variant="body2">
          {translateStageCodes(value)} {apiDetail}
        </Typography>
      </TableCell>
    );
  } else return null;
};

const useStyles = makeStyles(theme => ({
  apiResultIcon: {
    fontSize: "20px",
  },
  apiResultIconSuccess: {
    color: "green",
    paddingLeft: 5,
  },
  apiResultIconFailed: {
    color: "#98260D",
    transform: "rotate(180deg)",
  },
  buttonUpdateCredentials: {
    display: "inline-block",
    color: theme.palette.primary.main,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));
