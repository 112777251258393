import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { SET_CS_REFETCH } from "../../redux/types";
import { calculateRatesAndPayments, convertToDate, formatMoney, setFieldValue, validateValue } from "../../utils";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { formatCurrency } from "../../utils";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { useHistory } from "react-router-dom";

const config = require("config.js");

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const M_SEND_PROPOSAL = gql`
  mutation($input: SendProposalInput!) {
    sendProposal(input: $input) {
      success
      errorMessage
    }
  }
`;

const Q_PROPOSAL = gql`
  query proposal($id: ID) {
    proposal(id: $id) {
      id
      productId
      rate
      riskTier
      amountRequested
      markupInternal
      markupDealer
      bizName
      proposalStage
      businessName
      creditProductIds
      vendorOpportunityId
      accountId
      dynamicsAccountId
      status
      createdDateTime
      firstName
      lastName
      email
      vendorSalespersonId
      monthlyPayment
      termLength
      numOfAdvancePayments
      interestRate
      productSelected
      apr
      lender
      docFee
      amountDue
      gbbCalculatedOptions
      type
      gbbAcceptedTerms
      gbbAcceptedCalculatedOption
      gbbAcceptedMonthlyPayment
      gbbAcceptedTotalAmount
      monthlyPayment
      acceptedAftermarketOptions
      fullName
      paymentCards {
        productId
        productName
        productHeader
        term
        rate
        payment
        rateFactor
        riskTier
        amountMin
        amountMax
        docFee
        advancePayment
        markupInternal
        markupDealer
        lender
        downPaymentAmount
        calculatedPayment {
          paymentAmountPerPeriod
        }
      }
      lenderPaymentOptions {
        paymentId
        proposalId
        lenderId
        term
        residual
        lenderType
        payment
      }
      vendorOpportunity {
        equipmentDescription
      }
      contact {
        email
        firstName
        lastName
        fullName
        phoneNumber
        mugshot
        title
        availability
      }
      vendorProfile {
        logo
        styles
        dynamicsId
      }
    }
  }
`;

const DEACTIVATE_PROPOSAL = gql`
  mutation($id: ID!) {
    deactivateProposal(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  formControlInputs: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControlRate: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 200,
  },
}));

function ProposalDetails({ match, SET_CS_REFETCH, account, vo }) {
  const propId = match.params.propId;
  const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const classes = useStyles();
  const history = useHistory();

  const { data: proposalDetails } = useQuery(Q_PROPOSAL, {
    variables: {
      id: propId,
    },
  });

  const [sendProposal, { data, error, loading }] = useMutation(M_SEND_PROPOSAL, {
    context: { authRequired: true },
  });

  const [deactivateProposal] = useMutation(DEACTIVATE_PROPOSAL, {
    context: { authRequired: true },
  });

  const [salesReps, setSalesReps] = useState("");

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);
  const [renderOptions, setRenderOptions] = useState(false);
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    firstName: {
      value: "",
    },
    lastName: {
      value: "",
    },
    contactName: {
      value: "",
    },
    applicantName: {
      value: "",
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    businessName: {
      value: "",
    },
    phone: {
      value: "",
      validationStatus: "",
      format: formatPhone,
    },
    description: {
      value: "",
    },
    status: {
      value: "",
    },
    amountRequested: {
      value: "",
      format: formatMoney,
    },
    createdDateTime: {
      value: "",
    },
    vendorSalespersonId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    proposalLink: {
      value: "",
    },
  });
  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (proposalDetails) {
      setFields({
        id: {
          value: proposalDetails.proposal.id ? proposalDetails.proposal.id : "",
          validationStatus: "",
        },
        firstName: {
          value: proposalDetails.proposal.firstName ? proposalDetails.proposal.firstName : "",
          validationStatus: "",
        },
        lastName: {
          value: proposalDetails.proposal.lastName ? proposalDetails.proposal.lastName : "",
          validationStatus: "",
        },
        contactName: {
          value:
            proposalDetails.proposal.firstName && proposalDetails.proposal.lastName
              ? proposalDetails.proposal.firstName + " " + proposalDetails.proposal.lastName
              : "",
          validationStatus: "",
        },
        applicantName: {
          value: proposalDetails.proposal.fullName ? proposalDetails.proposal.fullName : "",
          validationStatus: "",
        },
        email: {
          value: proposalDetails.proposal.email ? proposalDetails.proposal.email : "",
          validationStatus: "",
        },
        amountRequested: {
          value: proposalDetails.proposal.amountRequested ? formatMoney(proposalDetails.proposal.amountRequested) : "",
          validationStatus: "",
          format: formatMoney,
        },
        businessName: {
          value: proposalDetails.proposal.businessName ? proposalDetails.proposal.businessName : "",
          validationStatus: "",
        },
        status: {
          value: proposalDetails.proposal.status ? proposalDetails.proposal.status : "",
          validationStatus: "",
        },
        phone: {
          value: proposalDetails.proposal.phone ? formatPhone(proposalDetails.proposal.phone) : "",
          validationStatus: "",
          format: formatPhone,
          // validations: [{ type: "required" }],
        },
        createdDateTime: {
          value: proposalDetails.proposal.createdDateTime
            ? convertToDate(proposalDetails.proposal.createdDateTime)
            : "",
        },
        vendorSalespersonId: {
          value: proposalDetails.proposal.vendorSalespersonId ? proposalDetails.proposal.vendorSalespersonId : "",
        },
        proposalLink: {
          value: proposalDetails.proposal.id
            ? `${config.REACT_APP_OCA_BASE_URL}/ps/?vendorGUID=${proposalDetails.proposal.dynamicsAccountId}&proposalId=${proposalDetails.proposal.id}`
            : "No Link Available",
        },
      });
    }
  }, [proposalDetails]);

  useEffect(() => {
    if (proposalDetails) {
      try {
        calculateMonthlyOptions(proposalDetails.proposal.paymentCards);
      } catch (err) {
        console.log(err);
        return null;
      }
    }
  }, [proposalDetails]);

  const sendLink = async () => {
    try {
      handleOpenModal();
      handleSubmissionMessage({ title: "Sending", message: "Please wait. Sending proposal..." });
      await sendProposal({
        variables: {
          input: {
            id: proposalDetails.proposal.id,
            amountRequested: proposalDetails.proposal.amountRequested,
            creditProductIds: proposalDetails.proposal.creditProductIds,
            riskTier: proposalDetails.proposal.tier,
            vendorOpportunityId: vo.vendorOpportunityId,
            accountId: account.id,
            vendorSalespersonId: proposalDetails.proposal.vendorSalespersonId,
            firstName: proposalDetails.proposal.firstName,
            lastName: proposalDetails.proposal.lastName,
            email: fields.email.value,
            businessName: proposalDetails.proposal.businessName,
            status: "Proposal Sent",
          },
        },
      });
      handleSubmissionMessage({ title: "Success", message: "Sent Successfully" });
    } catch (err) {
      console.log(err);
      handleSubmissionMessage({ title: "Error", message: "There was something wrong with your request" });
    }
  };

  const handleSubmit = async event => {
    try {
      sendLink();
      setSnackBarMessage("Successfully sent your proposal!");
      setSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error sending proposal!");
      setErrorOpen(true);
    }
  };

  const handleDeleteProposal = async id => {
    try {
      await deactivateProposal({
        variables: {
          id: id,
        },
      });
      setSnackBarMessage("Successfully deleted your proposal!");
      setSnackbarOpen(true);
    } catch (err) {
      console.log(err);
      setErrorMessage("Error deleting proposal!");
      setErrorOpen(true);
    }
  };

  const calculateMonthlyOptions = async obj => {
    if (!!obj && obj.length) {
      obj.forEach((item, i) => {
        item.amountRequested = parseFloat(proposalDetails.proposal.amountRequested);

        if (item.calculatedPayment && item.calculatedPayment.paymentAmountPerPeriod) {
          item.monthlyPayments = numberWithCommas(item.calculatedPayment.paymentAmountPerPeriod);
          return;
        }

        item.monthlyPayments = item.amountRequested * item.rateFactor;
        if (typeof item.docFee !== "number") {
          item.docFee = parseFloat(item.docFee.replace(/[^0-9.-]+/g, ""));
        }
        item.mpWithPoints = item.monthlyPayments * (1 + (item.markupInternal / 100 + item.markupDealer / 100));
        item.monthlyPayments = item.mpWithPoints;
        item.monthlyPayments = item.monthlyPayments.toFixed(2);
        const rateSandPay = calculateRatesAndPayments(
          item.amountRequested,
          item.term,
          item.rate,
          item.markupInternal / 100 + item.markupDealer / 100
        );
        if (!item.rateFactor) {
          item.monthlyPayments = rateSandPay.sellPayment;
        }
        item.interestRate = (rateSandPay.simpleInterest * 100).toFixed(2);
        if (!!item.advancePayment && typeof item.advancePayment !== "number") {
          item.advancePayment = parseFloat(item.advancePayment.replace(/[^0-9.-]+/g, ""));
        }
        if (item.advancePayment > 0) {
          item.amountDue = item.advancePayment * parseFloat(item.monthlyPayments) + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        } else {
          item.amountDue = item.monthlyPayments + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        }
        item.monthlyPayments = numberWithCommas(item.monthlyPayments);
        item.emailHeader = "Option " + parseInt(i + 1);
        delete item.interestRate;
        delete item.credittProduct;
        delete item.mpWithPoints;
        delete item.__typename;
      });
      setTimeout(() => setRenderOptions(true), 500);
    }
  };

  return (
    <>
      {proposalDetails ? (
        <Form
          onSubmit={values => {
            handleSubmit();
          }}
          validate={checkFormValidation}
          initialValues={!!proposalDetails.proposal}
          render={({ handleSubmit, pristine, valid, submitting, values }) => {
            function uploadButtonHandler(event) {
              const file = document.getElementById("file");
              file.click();
            }
            function uploadFileChanged(event) {
              fields.file.value = event.target.files[0];
              const reader = new FileReader();
              reader.onload = e => {
                const avatar = document.getElementById("avatar");
                setValue(e.target.result, "mugshot");
                avatar.src = e.target.result;
              };
              reader.readAsDataURL(event.target.files[0]);
              checkFormValidation();
            }
            function handleClose() {
              setSnackbarOpen(false);
              setErrorOpen(false);
            }

            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit().then(res => console.log(res));
                }}
              >
                <Grid container>
                  <GridItem lg={6}>
                    <Tile>
                      <Grid container alignItems="center">
                        <GridItem xs={12} sm={6} md={6} lg={3}>
                          <Fab onClick={() => history.goBack()} aria-label="go-back">
                            <KeyboardBackspaceIcon
                              fontSize="large"
                              style={{ cursor: "pointer" }}
                            ></KeyboardBackspaceIcon>
                          </Fab>
                        </GridItem>
                      </Grid>
                      <br />
                      <Grid container>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Created On"
                            id="createdDateTime"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled
                            inputProps={{
                              type: "text",
                              name: "createdDateTime",
                              value: fields.createdDateTime.value,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={5}>
                          <CustomInput
                            labelText="Status"
                            id="status"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled
                            inputProps={{
                              type: "text",
                              name: "status",
                              value: fields.status.value,
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Business Name"
                            id="businessName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "businessName",
                              value: fields.businessName.value,
                              onChange: e => setValue(e.target.value, "businessName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Amount Requested"
                            id="amountRequested"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              name: "amountRequested",
                              value: formatCurrency(fields.amountRequested.value),
                              onChange: e => setValue(e.target.value, "amountRequested"),
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid container alignItems="center">
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <CustomInput
                            labelText="Proposal Link"
                            id="proposal-link"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled
                            inputProps={{
                              type: "text",
                              name: "proposalLink",
                              value: fields.proposalLink.value,
                            }}
                          />
                        </GridItem>
                      </Grid>

                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Contact Name"
                            id="contactName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              disabled: true,
                              type: "text",
                              name: "contactName",
                              value: fields.contactName.value ? fields.contactName.value : fields.applicantName.value,
                              onChange: e =>
                                fields.contactName.value
                                  ? setValue(e.target.value, "contactName")
                                  : setValue(e.target.value, "applicantName"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "email",
                              value: fields.email.value,
                              onChange: e => setValue(e.target.value, "email"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <br />
                      <Grid container justify="center" alignItems="center">
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <Button
                            className="formBtn"
                            color="primary"
                            variant="outlined"
                            onClick={() => handleDeleteProposal(proposalDetails.proposal.id)}
                          >
                            Delete Proposal
                          </Button>
                        </GridItem>
                        {(proposalDetails.proposal.status !== "Accepted" ||
                          proposalDetails.proposal.status !== "accepted - pending bank") && (
                          <GridItem xs={8} sm={6} md={6} lg={6}>
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="contained"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={submitting || !formValid}
                            >
                              {submitting ? "Sending" : "Send"}
                            </Button>
                          </GridItem>
                        )}
                      </Grid>
                    </Tile>
                  </GridItem>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={12}>
                    {renderOptions &&
                      proposalDetails.proposal.status !== "Accepted" &&
                      proposalDetails.proposal.type === "base" && (
                        <Paper>
                          <CardHeader title="Generated Proposal" />
                          <Divider />
                          <CardContent style={{ padding: 0 }}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow className={classes.row}>
                                    <TableCell>Credit Product</TableCell>
                                    <TableCell>Term </TableCell>
                                    <TableCell>Monthly Payment</TableCell>
                                    <TableCell>Rate / Factor Rate</TableCell>
                                    <TableCell>Amount Due</TableCell>
                                    <TableCell>Dealer Markup</TableCell>
                                    <TableCell>Internal Markup</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {proposalDetails.proposal.paymentCards.map(
                                    (
                                      {
                                        term,
                                        monthlyPayments,
                                        productHeader,
                                        amountDue,
                                        markupInternal,
                                        markupDealer,
                                        rate,
                                        rateFactor,
                                      },
                                      i
                                    ) => (
                                      <>
                                        {(i === 0 || term < proposalDetails.proposal.paymentCards[i - 1].term) && (
                                          <TableRow key={i}>
                                            <TableCell colSpan={7} align="left">
                                              Credit Product {productHeader}
                                            </TableCell>
                                          </TableRow>
                                        )}
                                        <TableRow key={i}>
                                          <TableCell></TableCell>
                                          <TableCell>{term} Months</TableCell>
                                          <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                          <TableCell>{rate ? `${rate * 100}%` : rateFactor}</TableCell>
                                          <TableCell>{formatCurrency(amountDue)}</TableCell>
                                          <TableCell>{markupDealer}</TableCell>
                                          <TableCell>{markupInternal}</TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Paper>
                      )}
                    {!!proposalDetails.proposal &&
                      proposalDetails.proposal.status == "Accepted" &&
                      proposalDetails.proposal.type === "base" && (
                        <Paper>
                          <CardHeader title="Accepted Proposal" />
                          <Divider />
                          <CardContent style={{ padding: 0 }}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow className={classes.row}>
                                    <TableCell>Credit Product</TableCell>
                                    <TableCell>Term </TableCell>
                                    <TableCell>Monthly Payment</TableCell>
                                    <TableCell>Interest</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{proposalDetails.proposal.productSelected}</TableCell>
                                    <TableCell>{proposalDetails.proposal.termLength} Months</TableCell>
                                    <TableCell>{formatCurrency(proposalDetails.proposal.monthlyPayment)}</TableCell>
                                    <TableCell>{proposalDetails.proposal.interestRate.toFixed(2)}%</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Paper>
                      )}
                  </Grid>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        />
      ) : (
        "Loading Proposal"
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    account: state.account,
    vo: state.vo,
  };
};

const mapDispatchToProps = {
  SET_CS_REFETCH,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDetails);
