import gql from "graphql-tag";

export const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!, $lenderProfileIds: [ID]) {
    financePrograms(accountId: $accountId, lenderProfileIds: $lenderProfileIds) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      createdDateTime
      modifiedDateTime
      financeProgramToLenderProfiles {
        lenderProfileId
        financeProgramToLenderProfileId
        createdDateTime
        modifiedDateTime
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

export const Q_PROCESS = gql`
  query GetProcess($filters: ProcessesFiltersInput) {
    processes(filters: $filters) {
      accountId
      processDescription
      processId
      processKey
      processName
      processConfigValues
      active
    }
  }
`;

export const UPDATE_WORKFLOW = gql`
  mutation($input: UpdateWorkflowInput!) {
    updateWorkflow(input: $input) {
      success
      message
    }
  }
`;

export const M_SAVE_PROCESS = gql`
  mutation($input: CreateProcessInput!) {
    createProcess(input: $input) {
      accountId
      processDescription
      processId
      processKey
      processName
      processConfigValues
    }
  }
`;
