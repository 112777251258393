import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CreditApplicationRouterState } from "../types";
import { CreditApplicationDetailsLayout } from "./layout/CreditApplicationDetailsLayout";
import { BaseModalProps, ValueOf } from "../../../global";
import CloseIcon from "@material-ui/icons/Close";

// TODO display: data?.isForPDFOnly ? "none" : "block" remove. Create new component only for download PDF file
interface Props extends BaseModalProps {
  data: ValueOf<CreditApplicationRouterState, "creditApplicationState">;
}

export const CreditApplicationDetailsDialog = ({ isOpen, onClose, data }: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="lg"
      onClose={onClose}
      style={{ display: data?.isForPDFOnly ? "none" : "block" }}
    >
      <DialogTitle>
        <Box className={classes.title}>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ color: "black" }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <CreditApplicationDetailsLayout {...data} />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
