import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { FormControlLabel, FormGroup, Snackbar, Switch, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Tile from "../../components/Tile/Tile";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";

import _ from "lodash";

const QUERY_TASKS_TEMPLATES = gql`
  query GetTasksTemplates($options: UpsertTaskInput) {
    tasks(options: $options) {
      taskId
      regarding
      assignedToAccountId
      assignedByAccountId
      assignedToAccountId
      assignedByAccount
      assignedToApplicant
      active
      status
      priority
      notifyByEmail
      isTemplate
    }
  }
`;

const QUERY_ACCOUNT_TASKS_TEMPLATES = gql`
  query GetAccountTasksTemplates($options: UpsertTaskInput) {
    tasks(options: $options) {
      taskId
      regarding
      assignedToAccountId
      assignedByAccountId
      assignedToAccountId
      assignedByAccount
      assignedToApplicant
      active
      status
      priority
      notifyByEmail
      isTemplate
    }
  }
`;

const UPSERT_TASK = gql`
  mutation($input: UpsertTaskInput!) {
    upsertTask(input: $input)
  }
`;

const UPDATE_TASK = gql`
  mutation($taskId: ID!, $input: UpdateTaskInput!) {
    updateTask(taskId: $taskId, input: $input)
  }
`;

const useStyles = makeStyles(theme => ({
  switchFormGroup: {
    marginLeft: "7px",
  },
}));

export default function TasksTemplates({ account }) {
  const classes = useStyles();

  const { data: tasksTemplatesData } = useQuery(QUERY_TASKS_TEMPLATES, {
    variables: {
      options: {
        assignedToAccountId: null,
        isTemplate: true,
      },
    },
    fetchPolicy: "no-cache",
  });

  const { data: accountTasksTemplatesData } = useQuery(QUERY_ACCOUNT_TASKS_TEMPLATES, {
    variables: {
      options: {
        assignedToAccountId: account.id,
        isTemplate: true,
      },
    },
    fetchPolicy: "no-cache",
  });

  const [upsertTask] = useMutation(UPSERT_TASK, {
    context: { authRequired: true },
  });

  const [updateTask] = useMutation(UPDATE_TASK, {
    context: { authRequired: true },
  });

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 5 });

  const [tasksTemplates, setTasksTemplates] = useState();
  const [isTaskUpdating, setIsTaskUpdating] = useState(false);
  const [switchToggleSucceed, setSwitchToggleSucceed] = useState(false);
  const [switchToggleErrored, setSwitchToggleErrored] = useState(false);

  // Creates new account specific task based on task template
  const createAccountTaskTemplate = async taskTemplate => {
    try {
      const taskToCreate = {
        ...taskTemplate,
        assignedToAccountId: account.id,
        assignedByAccountId: account.id,
        assignedToAccount: account.name,
        assignedByAccount: account.name,
      };
      delete taskToCreate.taskId;
      delete taskToCreate.__typename;
      const result = await upsertTask({
        variables: {
          input: taskToCreate,
        },
      });
      taskToCreate.taskId = result.data.upsertTask.id;
      setTasksTemplates(prevValue =>
        _.map(prevValue, prevTask => (prevTask.taskId === taskTemplate.taskId ? taskToCreate : prevTask))
      );
    } catch (err) {
      console.error(err);
      setSwitchToggleErrored(true);
    }
  };

  // Toggle account specific task
  const toggleAccountTaskTemplate = async taskTemplate => {
    try {
      const deactivatedTask = {
        ...taskTemplate,
        active: !taskTemplate.active,
      };
      delete deactivatedTask.__typename;
      await updateTask({
        variables: {
          taskId: deactivatedTask.taskId,
          input: deactivatedTask,
        },
      });
      setTasksTemplates(prevValue =>
        _.map(prevValue, prevTask => (prevTask.taskId === deactivatedTask.taskId ? deactivatedTask : prevTask))
      );
    } catch (err) {
      console.error(err);
      setSwitchToggleErrored(true);
    }
  };

  const handleSwitchToggle = async taskTemplate => {
    try {
      setIsTaskUpdating(true);
      taskTemplate.assignedToAccountId
        ? await toggleAccountTaskTemplate(taskTemplate)
        : await createAccountTaskTemplate(taskTemplate);
      setSwitchToggleSucceed(true);
    } catch (err) {
      console.error(err);
      setSwitchToggleErrored(true);
    } finally {
      setIsTaskUpdating(false);
    }
  };

  useEffect(() => {
    if (_.isEmpty(tasksTemplatesData) || _.isEmpty(accountTasksTemplatesData)) {
      return;
    }
    const tasksToSet = _.map(
      tasksTemplatesData.tasks,
      taskTemplate => _.find(accountTasksTemplatesData.tasks, { regarding: taskTemplate.regarding }) || taskTemplate
    );
    setTasksTemplates(tasksToSet);
  }, [tasksTemplatesData, accountTasksTemplatesData]);

  return (
    <div>
      <Tile title="Tasks Templates">
        <TableHeader />
        <Table
          color="primary"
          page={pageNumber}
          data={tasksTemplates}
          columns={[
            {
              Header: "Regarding",
              accessor: "regarding",
              width: 300,
            },
            {
              Header: "Description",
              Cell: props =>
                "Automatically creates a document signing task for the applicant if the document was not signed during the OCA process",
            },
            {
              Header: "Enabled",
              width: 120,
              Cell: props => (
                <FormGroup row className={classes.switchFormGroup}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.original.assignedToAccountId && props.original.active}
                        onChange={() => {
                          handleSwitchToggle(props.original);
                        }}
                        color="primary"
                      />
                    }
                    disabled={isTaskUpdating}
                  />
                </FormGroup>
              ),
            },
          ]}
          onPageChange={changePage => {
            setPageNumber(changePage);
          }}
          onPageSizeChange={changePageSize => {
            setPageSizeValue(changePageSize);
            setPageNumber(0);
          }}
          pageSize={pageSizeValue}
          defaultPageSize={savedListSettings.pageSize}
          showPaginationTop={false}
          showPaginationBottom={true}
          onChangeRowsPerPage={() => {}}
        />
      </Tile>
      <Snackbar
        open={switchToggleSucceed}
        autoHideDuration={3000}
        onClose={() => setSwitchToggleSucceed(state => !state)}
      >
        <Alert severity="success">Changes Saved</Alert>
      </Snackbar>
      <Snackbar
        open={switchToggleErrored}
        autoHideDuration={3000}
        onClose={() => setSwitchToggleErrored(state => !state)}
      >
        <Alert severity="error">Error on Saving</Alert>
      </Snackbar>
    </div>
  );
}
