import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import ReactTable, { Column, Filter, SortingRule } from "react-table";
import SearchIcon from "@material-ui/icons/Search";
import { Grid, LinearProgress, TextField, InputAdornment, Button } from "@material-ui/core";
import Tile from "components/Tile/Tile";

const useStyles = makeStyles(theme => ({
  root: {
    "& .ReactTable .rt-table": {
      borderRadius: theme.shape.borderRadius,
    },
  },
  tableContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  },
  header: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.common.black,
    fontWeight: "bolder",
    fontSize: "0.775rem",
    whiteSpace: "nowrap",
    padding: "8px 16px",
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
  cell: {
    padding: theme.spacing(1),
    fontSize: "0.875rem",
  },
  low: { backgroundColor: "#164b35db", color: "white", textAlign: "center" },
  mid: { backgroundColor: "#533f04db", color: "white", textAlign: "center" },
  high: { backgroundColor: "#5d1f1adb", color: "white", textAlign: "center" },
  notAvailable: { backgroundColor: "#dedede", color: "black", textAlign: "center" },
  pagination: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  searchContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
}));

const CREDIT_SUBMISSIONS_QUERY = gql`
  query CreditSubmissionsHubQuery($limit: Int, $offset: Int, $sortBy: String, $sortOrder: String, $searchTerm: JSON) {
    creditSubmissionsHub(
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      sortOrder: $sortOrder
      searchTerm: $searchTerm
    ) {
      total
      creditSubmissions {
        creditSubId
        submissionDate
        stage
        decision
        lenderName
        dealer
        applicantName
        applicationStage
        timeToReviewInHours
        timeToDecisionInHours
        timeDeltas {
          decisions {
            timeElapsed
            previous
            current
          }
          stages {
            timeElapsed
            previous
            current
          }
        }
      }
    }
  }
`;

interface Submission {
  id: number;
  submissionDate: string;
  salesRep: string;
  customerName: string;
  voStatusStage: string;
  decisionStatus: string;
  lenderName: string;
  stage: number;
  timeToReviewInHours: string;
  timeToDecisionInHours: string;
  timeDeltas: {
    decisions: { timeElapsed: number; previous: number; current: number }[];
    stages: { timeElapsed: number; previous: number; current: number }[];
  };
}

const stagesCodes: { [key: number]: string } = {
  804790000: "Submitted to Lender",
  804790001: "Lender Reviewing",
  804790002: "Lender - Additional Info Requested",
  804790003: "Lender Approved",
  804790004: "Lender Declined",
  804790005: "Dealer Accepted",
  804790006: "Dealer Cancelled",
  804790007: "Docs Out",
  804790008: "Docs in",
  804790009: "Funding Review",
  804790010: "Funding Items Requested",
  804790011: "Customer Cancelled",
  804790013: "Lender Pre-Approved",
  804790020: "Funded",
  105: "Draft Application Created",
  100: "Awaiting Transmission to Lender",
  300: "Submission Failed",
  101: "Awaiting PreQual Transmission to Lender",
};

const SubmissionsHub: React.FC = () => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [submissions, setSubmissions] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useState<string>("submissionDate");
  const [sortOrder, setSortOrder] = useState<string>("DESC");
  const [searchTerm, setSearchTerm] = useState({});
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState({});
  const [fetchData, { data, loading, error }] = useLazyQuery(CREDIT_SUBMISSIONS_QUERY, {
    variables: { limit, offset, sortBy, sortOrder, searchTerm: debouncedSearchTerm },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    // Clear timeout if the user continues typing within the delay period
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    if (data?.creditSubmissionsHub) {
      setSubmissions(
        data.creditSubmissionsHub.creditSubmissions.map((submission: any) => ({
          id: submission.creditSubId,
          submissionDate: submission.submissionDate,
          salesRep: submission.dealer,
          customerName: submission.applicantName,
          lenderName: submission.lenderName,
          voStatusStage: submission.applicationStage,
          decisionStatus: submission.decision,
          stage: submission.stage,
          timeDeltas: submission.timeDeltas,
          timeToReview: submission.timeToReviewInHours,
          timeToDecision: submission.timeToDecisionInHours,
        })) || []
      );
      setTotal(data?.creditSubmissionsHub.total || 0);
    }
  }, [data]);

  if (error) return <p>Error: {error.message}</p>;

  const getClassByTime = (time: string): string => {
    const [hours, minutes] = time.split(":").map(Number);
    if (time === "00:00:00") return classes.notAvailable;
    const totalMinutes = hours * 60 + minutes;

    if (totalMinutes < 5) return classes.low;
    if (totalMinutes < 30) return classes.mid;
    return classes.high;
  };

  const columns: Column<Submission>[] = [
    {
      Header: "Created On",
      accessor: "submissionDate",
      Cell: ({ value }: { value: string }) => {
        return (
          <div className={classes.cell}>
            {new Date(parseInt(value)).toLocaleDateString()} {new Date(parseInt(value)).toLocaleTimeString()}
          </div>
        );
      },
      filterable: false,
    },
    {
      Header: "Lender",
      accessor: "lenderName",
      className: classes.cell,
    },
    {
      Header: "Sales Rep",
      accessor: "salesRep",
      Cell: ({ value }: { value: string }) => {
        return <div className={classes.cell}>{value ?? "N/A"}</div>;
      },
      className: classes.cell,
    },
    {
      Header: "Customer",
      accessor: "customerName",
      className: classes.cell,
    },
    {
      Header: "App. Stage",
      accessor: "voStatusStage",
      id: "applicationStage",
      className: classes.cell,
    },
    {
      Header: "Sub. Decision",
      accessor: "decisionStatus",
      id: "decision",
      className: classes.cell,
    },
    {
      Header: "Sub. Stage",
      accessor: submission => stagesCodes[submission.stage],
      id: "stage",
      className: classes.cell,
    },
    {
      Header: "Time to Review",
      id: "timeToReview",
      accessor: "timeToReview",
      Cell: ({ value }: { value: string }) => {
        const className = getClassByTime(value);
        return <div className={`${classes.cell} ${className}`}>{value == "00:00:00" ? "N/A" : value}</div>;
      },
      sortable: false,
      filterable: false,
    },
    {
      Header: "Time to Decision",
      id: "timeToDecision",
      accessor: "timeToDecision",
      Cell: ({ value }: { value: string }) => {
        const className = getClassByTime(value);
        return <div className={`${classes.cell} ${className}`}>{value == "00:00:00" ? "N/A" : value}</div>;
      },
      sortable: false,
      filterable: false,
    },
  ];
  const handleSearchChange = (args: Filter[]) => {
    setSearchTerm(args);
    setOffset(0); // Reset to the first page when searching
  };
  const handleSortChange = (newSorted: SortingRule[]) => {
    if (newSorted.length > 0) {
      const { id, desc } = newSorted[0];
      setSortBy(id);
      setSortOrder(desc ? "DESC" : "ASC");
    } else {
      setSortBy("DESC");
      setSortOrder("submissionDate");
    }
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Tile className={classes.tableContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {loading ? <LinearProgress /> : null}
            </Grid>
            <Grid item xs={12}>
              <ReactTable<Submission>
                data={submissions}
                pages={Math.ceil(total / limit)}
                page={Math.ceil(offset / limit)}
                getTrProps={() => ({ className: classes.row })}
                getTheadThProps={() => ({ className: classes.header })}
                getTdProps={() => ({ className: classes.cell })}
                onFilteredChange={(args: Filter[]) => {
                  handleSearchChange(args);
                }}
                columns={columns}
                filterable={true}
                manual={true}
                defaultPageSize={limit}
                onSortedChange={handleSortChange}
                sorted={[{ id: sortBy, desc: sortOrder === "DESC" }]}
                onPageChange={pageIndex => setOffset(pageIndex * limit)}
                onPageSizeChange={pageSize => setLimit(pageSize)}
              />
            </Grid>
          </Grid>
        </Tile>
      </Grid>
    </Grid>
  );
};

export default SubmissionsHub;
