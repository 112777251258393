import React, { useState } from "react";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { FormInputSelect } from "../../../components/form";

export function SelectFieldSetting({ settings, itemConfiguration, setItemConfiguration }) {
  const [value, setValue] = useState(_.get(itemConfiguration, settings.configurationKey, ""));

  const updateValue = value => {
    setValue(value);
    setItemConfiguration(prevItemConfiguration => {
      return {
        ...prevItemConfiguration,
        [settings.configurationKey]: value,
      };
    });
  };
  return (
    <Box>
      <FormInputSelect
        label={settings?.selectLabel}
        helperText={settings?.helperText}
        value={value}
        onChange={updateValue}
        options={settings.options ?? []}
      />
    </Box>
  );
}
