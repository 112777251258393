import React, { useContext, useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import Table from "../../components/Table/Table";
import InvoiceDetailModal from "./InvoiceDetailModal";
import Modal from "@material-ui/core/Modal";
import EquipmentInputModal from "../../components/EquipmentInputModal";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import InvoiceQuoteUploadModal from "../../components/InvoiceQuoteUploadModal";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ContractTable from "./Contract";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import TableUI from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Tab, Tabs } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import TabPanel from "components/TabPanel";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import _ from "lodash";
import { theme } from "../../theme";
import { CardContainer } from "../../components/shared/CardContainer/CardContainer";

const UPDATE_DYNAMICS_INVOICE_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;
const UPDATE_INVOICE_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;
const DEACTIVATE_DOC = gql`
  mutation($id: ID!) {
    deactivateDocument(id: $id)
  }
`;
const UPDATE_VO = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const UPDATE_DYNAMICS_VO = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

const useStyles = makeStyles({
  cardLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px 0",
    "& h4": {
      width: "100%",
    },
    "& > div": {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  clearBlock: {
    width: 0,
    height: 0,
    overflow: "hidden",
  },
  spacer: {
    margin: "15px 0",
  },
  alignRight: {
    display: "block",
    textAlign: "right",
  },
  uploadedInvoice: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  uploadedInvoiceData: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& div": {
      marginRight: "50px",
    },
  },
  uploadedInvoiceButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexWrap: "wrap",
    "& button": {
      marginRight: "5px",
      "&::last-child": {
        marginRight: 0,
      },
    },
  },
  uploadedInvoiceTable: {
    "& th": {
      textAlign: "left",
      padding: "0 20px 0 0",
    },
    "& td": {
      textAlign: "left",
      padding: "0 20px 0 0",
    },
  },
  greenBorder: {
    height: "100%",
    border: "2px solid #3ec179",
  },
  transparentBorder: {
    height: "100%",
    border: "2px solid transparent",
  },
  greenText: {
    color: "#3ec179",
  },
  modalContainer: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.5)",
  },
  modalViewInvoiceImage: {
    position: "absolute",
    top: "10%",
    bottom: "10%",
    left: "10%",
    right: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  viewInvoiceImage: {
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  greenButton: {
    backgroundColor: "#3ec179",
  },
  right: {
    textAlign: "right",
  },
  disappearingUploadText: {
    marginLeft: 8,
    "@media (max-width: 1530px) and (min-width: 1280px)": {
      display: "none",
    },
    "@media (max-width: 1150px) and (min-width: 960px)": {
      display: "none",
    },
    "@media (max-width: 860px)": {
      display: "none",
    },
  },
  disappearingDownloadButton: {
    "@media (max-width: 780px)": {
      display: "none",
    },
  },
  appearingInvoiceData: {
    display: "none",
    "@media (max-width: 600px)": {
      display: "block",
    },
  },
  disappearingInvoiceData: {
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export function InvoiceEquipmentDetailsCard({
  invoiceDetailTableBody,
  invoiceDoc,
  refetchDocs,
  equipmentData,
  refetchEquipment,
  vo,
  creditApp,
  refetchVO,
  invoiceStatus,
  account,
  userProfile,
  vendorProfile,
  refetchCreditApp,
  openEquipmentInputModal,
  setOpenEquipmentInputModal,
  vendorProfilesAssociatedToLender,
  loadingVendorProfilesAssociatedToLender,
}) {
  const classes = useStyles();
  const tableHead = [
    "Quantity",
    "New/Used",
    "Year",
    "Make",
    "Model",
    "Serial Number/VIN",
    "Stock Id",
    "Additional Description",
  ];
  const [openInvoiceDetailModal, setOpenInvoiceDetailModal] = useState(false);
  const [equipmentId, setEquipmentId] = useState(null);

  const [equipments, setEquipments] = useState([]);
  const [greenBorder, setGreenBorder] = useState(false);
  const [startProcess, setStartProcess] = useState();
  const [isSuccessEquipmentInputSnackbarOpen, setisSuccessEquipmentInputSnackbarOpen] = useState(false);
  const [isErrorEquipmentInputSnackbarOpen, setIsErrorEquipmentInputSnackbarOpen] = useState(false);
  const [curTab, setCurTab] = useState("invoice");
  const isVpAssignedToVoByLender = vo.vendorProfileId !== vendorProfile.id;
  const [vpAssignedByLender, setVpAssignedByLender] = useState();
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);

  const [updateDynamicsInvoiceStatus] = useMutation(UPDATE_DYNAMICS_INVOICE_STATUS_MUTATION, {
    context: { authRequired: true },
  });
  const [updateInvoiceStatus] = useMutation(UPDATE_INVOICE_STATUS_MUTATION, {
    context: { authRequired: true },
  });
  const [deactivateDocRequest] = useMutation(DEACTIVATE_DOC, {
    context: { authRequired: true },
  });
  const [updateDynamicsVO] = useMutation(UPDATE_DYNAMICS_VO, {
    context: { authRequired: true },
  });

  const [updateVO] = useMutation(UPDATE_VO, {
    context: { authRequired: true },
  });

  const [uploadInvoiceModal, setUploadInvoiceModal] = useState(false);

  const handleTabChange = (event, newValue) => {
    setCurTab(newValue);
  };

  const handleOpenInvoiceDetailModal = () => {
    if (startProcess === null || startProcess === undefined) {
      setStartProcess("invoice");
    }
    setOpenInvoiceDetailModal(true);
  };
  const handleCloseInvoiceDetailModal = () => {
    // if (startProcess === "upload" || startProcess === "invoice") {
    //   setStartProcess(null);
    // }
    setOpenInvoiceDetailModal(false);
  };
  const handleOpenEquipmentInputModal = () => {
    setOpenEquipmentInputModal(true);
  };
  const handleCloseEquipmentInputModal = responseResult => {
    if (responseResult && responseResult.success) {
      setisSuccessEquipmentInputSnackbarOpen(true);
    }
    if (responseResult && responseResult.error) {
      setIsErrorEquipmentInputSnackbarOpen(true);
    }
    setOpenEquipmentInputModal(false);
  };
  const handleInvoiceDownload = () => {
    const link = document.createElement("a");
    link.href = invoiceDoc.source;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };
  // const handleOpenInvoiceView = () => setOpenInvoiceView(true);
  // const handleCloseInvoiceView = () => setOpenInvoiceView(false);

  const handleOpenUploadInvoiceModal = () => {
    setUploadInvoiceModal(true);
  };
  const handleCloseUploadInvoiceModal = () => {
    setUploadInvoiceModal(false);
  };

  const handleSnackbarClose = () => {
    setisSuccessEquipmentInputSnackbarOpen(false);
    setIsErrorEquipmentInputSnackbarOpen(false);
  };

  const setVODealer = async () => {
    try {
      //TODO: nullify also sales reps and location
      await updateDynamicsVO({
        variables: {
          fieldsObj: {
            vendorProfileId: vpAssignedByLender.dynamicsId,
          },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await updateVO({
        variables: {
          fieldsObj: {
            vendorProfileId: vpAssignedByLender.id,
            sourceVendorProfileId: vo.vendorProfileId,
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      await refetchVO();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (equipmentData !== null && equipmentData !== undefined && equipmentData.equipments !== null) {
      setEquipments(equipmentData.equipments);
    }
  }, [equipmentData]);

  useEffect(() => {
    if (invoiceStatus === "Requested") {
      setGreenBorder(true);
    } else if (invoiceStatus !== "Requested") {
      setGreenBorder(false);
    }
  }, [invoiceStatus]);

  useEffect(() => {
    if (isVpAssignedToVoByLender && _.some(vendorProfilesAssociatedToLender)) {
      const vpAssignedByLenderToSet = isVpAssignedToVoByLender
        ? _.find(vendorProfilesAssociatedToLender, { id: vo.vendorProfileId })
        : null;
      setVpAssignedByLender(vpAssignedByLenderToSet);
    }
  }, [vendorProfilesAssociatedToLender, isVpAssignedToVoByLender]);

  const handleChangeDoc = async event => {
    try {
      await updateDynamicsInvoiceStatus({
        variables: {
          fieldsObj: {
            dynamicInvoiceStatus: 200,
          },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });
      await updateInvoiceStatus({
        variables: {
          fieldsObj: {
            invoiceStatus: "Received (Pending Approval)",
          },
          VOId: vo.vendorOpportunityId,
        },
      });
      await refetchVO();
      await refetchDocs();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeactivateDoc = async value => {
    try {
      await deactivateDocRequest({
        variables: {
          id: value,
        },
      });
      await refetchVO();
      await refetchDocs();
    } catch (err) {
      console.log(err);
    }
  };

  if (!!invoiceDoc && !!invoiceDoc.docDescription) {
    invoiceDoc.name = `${invoiceDoc.docDescription.split("#")[1]}`;
    const invType = invoiceDoc.docDescription.split("#")[0];
    invoiceDoc.type = invType.substr(0, 1).toUpperCase() + invType.substr(1);
  }

  return (
    <CardContainer
      title="Sale & Equipment Details"
      containerClassName={greenBorder ? classes.greenBorder : classes.transparentBorder}
    >
      <Tabs
        value={curTab}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="full width tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <Tab
          value="invoice"
          label="Invoice"
          style={{ color: curTab === "invoice" ? theme.palette.primary.main : "" }}
        />
        <Tab
          value="equipment"
          label="Equipment"
          style={{ color: curTab === "equipment" ? theme.palette.primary.main : "" }}
        />
        {vendorProfile.showContract === true && (
          <Tab
            value="contract"
            label="Contract"
            style={{ color: curTab === "contract" ? theme.palette.primary.main : "" }}
          />
        )}
        {isLenderUser && (
          <Tab
            value="dealer"
            label="Dealer"
            style={{ color: curTab === "contract" ? theme.palette.primary.main : "" }}
          />
        )}
      </Tabs>
      <Divider />
      <TabPanel value={curTab} index="invoice">
        <>
          <div className={classes.cardLine}>
            <h4>{invoiceDoc ? "Uploaded Invoice" : "Upload Invoice"}</h4>
            {invoiceDoc ? (
              <div className={classes.uploadedInvoice}>
                <div className={classes.uploadedInvoiceData}>
                  <table className={classes.uploadedInvoiceTable}>
                    <thead>
                      <tr>
                        <th className={classes.disappearingInvoiceData}>Name</th>
                        <th className={classes.disappearingInvoiceData}>Type</th>
                        <th className={classes.disappearingInvoiceData}>Date Uploaded</th>
                        <th className={classes.disappearingInvoiceData}>Uploaded By</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className={classes.disappearingInvoiceData}>{invoiceDoc.name}</span>
                          <span className={classes.appearingInvoiceData}>
                            <b>Name:</b>
                            <br />
                            {invoiceDoc.type} {invoiceDoc.name}
                            <br />
                            <br />
                            <b>Uploaded Date:</b>
                            <br />
                            {invoiceDoc.date}
                            <br />
                            <br />
                            <b>Uploaded By:</b>
                            <br />
                            {invoiceDoc.createdBy}
                          </span>
                        </td>
                        <td className={classes.disappearingInvoiceData}>{invoiceDoc.type}</td>
                        <td className={classes.disappearingInvoiceData}>{invoiceDoc.date}</td>
                        <td className={classes.disappearingInvoiceData}>{invoiceDoc.createdBy}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={classes.uploadedInvoiceButtons}>
                  <Button
                    simple
                    size="sm"
                    style={{ padding: 6, color: "#737373" }}
                    onClick={() => handleDeactivateDoc(invoiceDoc.documentId)}
                  >
                    <DeleteForeverIcon style={{ margin: 0, width: 19, height: 19 }} />
                  </Button>
                  <InvoiceQuoteUploadModal
                    vo={vo}
                    equipmentUpload={true}
                    handleChangeDoc={handleChangeDoc}
                    handleOpenInvoiceModal={handleOpenInvoiceDetailModal}
                    handleCloseUploadInvoiceModal={handleCloseUploadInvoiceModal}
                    startProcess={startProcess}
                    setStartProcess={setStartProcess}
                    uploadZoneOpened={uploadInvoiceModal}
                    refetchDoc={refetchDocs}
                    buttonDescription="Upload Invoice/Quote"
                  />
                  <Button
                    color="primary"
                    size="sm"
                    className={classes.disappearingDownloadButton}
                    onClick={handleInvoiceDownload}
                  >
                    Download
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <span style={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                  Please upload an invoice via "Upload Invoice/Quote" button
                </span>
                <div className={`${classes.alignRight} ${classes.fullWidth}`}>
                  <InvoiceQuoteUploadModal
                    vo={vo}
                    equipmentUpload={true}
                    handleChangeDoc={handleChangeDoc}
                    handleOpenInvoiceModal={handleOpenInvoiceDetailModal}
                    handleCloseUploadInvoiceModal={handleCloseUploadInvoiceModal}
                    startProcess={startProcess}
                    setStartProcess={setStartProcess}
                    uploadZoneOpened={uploadInvoiceModal}
                    refetchDoc={refetchDocs}
                    buttonDescription="Upload Invoice/Quote"
                  />
                </div>
              </>
            )}
          </div>
          <div className={classes.cardLine}>
            <h4>Details</h4>
            {invoiceDetailTableBody ? (
              <>
                <div className={classes.fullWidth}>
                  <Table
                    tableHeaderColor="primary"
                    tableData={invoiceDetailTableBody}
                    colorsColls={["primary"]}
                    customCellClasses={[classes.right]}
                    customClassesForCells={[1]}
                    striped={true}
                  />
                </div>
                <div className={`${classes.alignRight} ${classes.fullWidth}`}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={handleOpenInvoiceDetailModal}
                    data-test-id="edit-invoice-btn"
                  >
                    EDIT
                  </Button>
                </div>
              </>
            ) : (
              <div className={classes.cardLine}>
                <span className={classes.greenText}>Please add invoice details via "Edit" button</span>
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleOpenInvoiceDetailModal}
                  className={classes.greenButton}
                >
                  EDIT
                </Button>
              </div>
            )}
          </div>
          <InvoiceDetailModal
            open={openInvoiceDetailModal}
            handleClose={() => setOpenInvoiceDetailModal(false)}
            handleOpenUploadInvoiceModal={handleOpenUploadInvoiceModal}
            refetchVO={refetchVO}
            refetchDoc={refetchDocs}
            handleChangeDoc={handleChangeDoc}
            handleOpenInvoiceModal={handleOpenInvoiceDetailModal}
            handleCloseUploadInvoiceModal={handleCloseUploadInvoiceModal}
            startProcess={startProcess}
            setStartProcess={setStartProcess}
            uploadZoneOpened={uploadInvoiceModal}
          />
        </>
      </TabPanel>
      <TabPanel value={curTab} index="equipment">
        <>
          <div className={classes.cardLine}>
            {!!equipmentData?.equipments && !!equipmentData?.equipments?.length && vendorProfile ? (
              <>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                  <TableUI className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qty.</TableCell>
                        <TableCell align="center">Condition</TableCell>
                        <TableCell align="center">Year</TableCell>
                        <TableCell align="center">Make</TableCell>
                        <TableCell align="center">Model</TableCell>

                        <TableCell align="center">Serial Number/VIN</TableCell>
                        <TableCell align="center">Stock ID / Unit Number</TableCell>
                        <TableCell align="center">Mileage</TableCell>
                        <TableCell align="center">Attachments</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {equipmentData?.equipments.map(row => (
                        <TableRow key={row.equipmentId}>
                          <TableCell align="center">{row.quantity}</TableCell>
                          <TableCell align="center">{_.toUpper(row.newUsed)}</TableCell>
                          <TableCell align="center">{row.year}</TableCell>
                          <TableCell align="center">{row.make}</TableCell>
                          <TableCell align="center">{row.model}</TableCell>
                          <TableCell align="center">{row.serialNumberVin}</TableCell>
                          <TableCell align="center">{row.stockId}</TableCell>
                          <TableCell align="center">{row.mileage}</TableCell>
                          <TableCell align="center">
                            {!_.isEmpty(row.attachments)
                              ? row.attachments.map((attachment, index) => {
                                  const lastItem = index === _.size(row.attachments) - 1;
                                  try {
                                    return (
                                      <b key={`row.attachments.${index}`}>
                                        {attachment.type}
                                        {lastItem ? null : ","}
                                      </b>
                                    );
                                  } catch (e) {
                                    return null;
                                  }
                                })
                              : null}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setEquipmentId(row.equipmentId);
                                handleOpenEquipmentInputModal();
                              }}
                              aria-label="Edit"
                            >
                              <Edit />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </TableUI>
                </TableContainer>

                <div className={`${classes.alignRight} ${classes.fullWidth} ${classes.spacer}`}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      setEquipmentId(null);
                      handleOpenEquipmentInputModal();
                    }}
                  >
                    ADD
                  </Button>
                </div>
              </>
            ) : (
              <>
                <span style={{ color: theme.palette.primary.main, fontWeight: 400 }}>
                  Please add equipment details via "Add" button
                </span>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => {
                    setEquipmentId(null);
                    handleOpenEquipmentInputModal();
                  }}
                  style={{ color: "#fff" }}
                  data-test-id="add-equipment-details-btn"
                >
                  ADD
                </Button>
              </>
            )}
          </div>
          <Modal
            open={openEquipmentInputModal}
            onClose={handleCloseEquipmentInputModal}
            aria-labelledby="Equipment Input Modal"
            aria-describedby="Input"
          >
            <EquipmentInputModal
              vo={vo}
              creditApp={creditApp}
              equipmentData={equipmentData}
              handleClose={handleCloseEquipmentInputModal}
              refetchVO={refetchVO}
              equipmentId={equipmentId}
              refetch={refetchEquipment}
              vendorProfile={vendorProfile}
              refetchCreditApp={refetchCreditApp}
              account={account}
              refetchDocs={refetchDocs}
            />
          </Modal>
        </>
      </TabPanel>
      {vendorProfile.showContract === true && (
        <TabPanel value={curTab} index="contract">
          <ContractTable vo={vo} account={account} userProfile={userProfile} refetchVO={refetchVO} />
        </TabPanel>
      )}
      {isLenderUser && (
        <TabPanel value={curTab} index="dealer">
          <>
            {isVpAssignedToVoByLender && vpAssignedByLender ? (
              <h4>Dealer: {vpAssignedByLender.name}</h4>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h4>No Associated Dealer</h4>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: 300 }}>
                    {loadingVendorProfilesAssociatedToLender ? (
                      <span>
                        Loading list, please wait... <CircularProgress size={20} />
                      </span>
                    ) : (
                      <>
                        <InputLabel id="vo-choose-dealer-label">Choose Dealer</InputLabel>
                        <Select
                          labelId="vo-choose-dealer-label"
                          id="vo-choose-dealer"
                          fullWidth={true}
                          value={vpAssignedByLender}
                          onChange={e => setVpAssignedByLender(e.target.value)}
                        >
                          {_.sortBy(vendorProfilesAssociatedToLender, [vp => vp.name.replace("VP - ", "")]).map(vp => (
                            <MenuItem key={vp.id} value={vp}>
                              {vp.name.replace("VP - ", "")}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={setVODealer}
                    className={classes.greenButton}
                    disabled={_.isEmpty(vpAssignedByLender)}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        </TabPanel>
      )}
      <Snackbar
        open={isSuccessEquipmentInputSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {equipmentId ? "Equipment details successfully updated" : "Equipment details successfully added"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorEquipmentInputSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          An error has occured
        </Alert>
      </Snackbar>
    </CardContainer>
  );
}
