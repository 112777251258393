import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { ChromePicker } from "react-color";
import Button from "./CustomButtons/Button";

const styles = {
  colorSchemaPopover: {
    position: "absolute",
    zIndex: "2",
  },
  colorSchemaCover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  colorSchemaCurrentColorContainer: {
    width: "25px",
    height: "20px",
    marginLeft: "10px",
  },
  colorSchemaButton: {
    padding: "6px 8px",
    marginRight: "10px",
  },
};

const useStyles = makeStyles(styles);

export default function ColorSchemaPicker({ colorSchema, setColorSchema }) {
  const classes = useStyles();

  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [currentColorKey, setCurrentColorKey] = useState(null);

  const handleColorClick = key => {
    setIsColorPickerVisible(prevState => !prevState);
    setCurrentColorKey(key);
  };

  const handleColorPickerChange = selectedColor => {
    setColorSchema(prevState => ({ ...prevState, [currentColorKey]: selectedColor.hex }));
  };

  const handleColorPickerClose = () => {
    setIsColorPickerVisible(false);
  };

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12}>
        <Button className={classes.colorSchemaButton} onClick={() => handleColorClick("color")} variant="outlined">
          Main
          <div
            className={classes.colorSchemaCurrentColorContainer}
            style={{ backgroundColor: `${colorSchema["color"]}` }}
          ></div>
        </Button>
        <Button className={classes.colorSchemaButton} onClick={() => handleColorClick("secColor")} variant="outlined">
          Secondary
          <div
            className={classes.colorSchemaCurrentColorContainer}
            style={{ backgroundColor: `${colorSchema["secColor"]}` }}
          ></div>
        </Button>
        <Button className={classes.colorSchemaButton} onClick={() => handleColorClick("textColor")} variant="outlined">
          Text
          <div
            className={classes.colorSchemaCurrentColorContainer}
            style={{ backgroundColor: `${colorSchema["textColor"]}` }}
          ></div>
        </Button>
        <Button
          className={classes.colorSchemaButton}
          onClick={() => handleColorClick("panelTextColor")}
          variant="outlined"
        >
          Panel Text
          <div
            className={classes.colorSchemaCurrentColorContainer}
            style={{ backgroundColor: `${colorSchema["panelTextColor"]}` }}
          ></div>
        </Button>
      </Grid>
      {isColorPickerVisible && (
        <div className={classes.colorSchemaPopover}>
          <div className={classes.colorSchemaCover} onClick={handleColorPickerClose} />
          <ChromePicker color={colorSchema[currentColorKey]} onChange={handleColorPickerChange} />
        </div>
      )}
    </Grid>
  );
}
