import React from "react";
import ReactTable from "react-table";
import { createTableColumns } from "./tableConfig";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface Props {
  userProfile: any;
  creditSubmissions: any;
  currencyFormatter: any;
  resubmitCreditApp: any;
  handleUpdateCredentials: any;
  handleOpenLenderDetailModalView: any;
}

export const SyndicationsTable = ({
  userProfile,
  currencyFormatter,
  creditSubmissions,
  resubmitCreditApp,
  handleUpdateCredentials,
  handleOpenLenderDetailModalView,
}: Props) => {
  const classes = useStyles();

  const columns = createTableColumns({
    handleUpdateCredentials,
    currencyFormatter,
    userProfile,
    handleOpenLenderDetailModalView,
    resubmitCreditApp,
  });

  return (
    <ReactTable
      data={creditSubmissions}
      getTrProps={() => ({
        style: {
          cursor: "pointer",
          fontSize: 12,
          fontWeight: 400,
          lineHeight: "14px",
        },
      })}
      columns={columns}
      defaultPageSize={5}
      showPaginationBottom
      showPaginationTop={false}
      className={clsx("-highlight", classes.table)}
    />
  );
};

const useStyles = makeStyles({
  table: {
    "& .rt-thead": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
});
