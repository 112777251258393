import {
  SET_REFETCH_ACCOUNT,
  SET_REFETCH_ACCOUNT_REP,
  SET_REFETCH_COMPANY_PROFILE,
  SET_REFETCH_CREDIT_APP,
  SET_REFETCH_CREDIT_SUBMISSIONS,
  SET_REFETCH_FEED,
  SET_REFETCH_PAST_SUBMISSIONS,
  SET_REFETCH_USER_PROFILE,
  SET_REFETCH_VO,
  SET_REFETCH_VP,
} from "./types";

const initialState = {
  account: false,
  accountRep: false,
  userProfile: false,
  companyProfile: false,
  vp: false,
  vo: false,
  creditApp: false,
  pastSubmissions: false,
  feed: false,
  creditSubmissions: false,
};

export const refetchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFETCH_ACCOUNT:
      return { ...state, account: action.payload };
    case SET_REFETCH_ACCOUNT_REP:
      return { ...state, accountRep: action.payload };
    case SET_REFETCH_USER_PROFILE:
      return { ...state, userProfile: action.payload };
    case SET_REFETCH_COMPANY_PROFILE:
      return { ...state, companyProfile: action.payload };
    case SET_REFETCH_VO:
      return { ...state, vo: action.payload };
    case SET_REFETCH_VP:
      return { ...state, vp: action.payload };
    case SET_REFETCH_CREDIT_APP:
      return { ...state, creditApp: action.payload };
    case SET_REFETCH_PAST_SUBMISSIONS:
      return { ...state, pastSubmissions: action.payload };
    case SET_REFETCH_FEED:
      return { ...state, feed: action.payload };
    case SET_REFETCH_CREDIT_SUBMISSIONS:
      return { ...state, feed: action.payload };
    default:
      return state;
  }
};
