import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useUiStyles = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  primaryBorderColor: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function TemplateDescriptionModal(props) {
  const classes = { ...useStyles(), ...useUiStyles() };
  const [open, setOpen] = useState(false);

  const handleChange = event => {
    props.setFileType(event.target.value || "");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.fileType === "other" && props.fileDescription === "") return;
    setOpen(false);
    props.handleCloseFileDescriptionInputModal();
  };

  const handleChangeName = event => {
    props.setTemplateName(event.target.value || "");
  };

  const handleChangeDocDescription = event => {
    props.setFileDescription(event.target.value || "");
  };

  return (
    <div>
      <Dialog disableEscapeKeyDown open={props.openFileDescriptionInputModal} onClose={handleClose}>
        <DialogTitle>Please Describe The Uploading File</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-multiline-static1"
                label="Document Name"
                multiline
                rows={4}
                value={props.name}
                className={classes.primaryBorderColor}
                variant="outlined"
                onChange={handleChangeName}
                required
                helperText={"Please enter a name for the document"}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-multiline-static1"
                label="Document Description"
                multiline
                rows={4}
                value={props.fileDescription}
                className={classes.primaryBorderColor}
                variant="outlined"
                onChange={handleChangeDocDescription}
                required={props.fileType === "other"}
                error={props.fileType === "other"}
                helperText={props.fileType === "other" ? "Please describe your document" : false}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" simple>
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" simple>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
