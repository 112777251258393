import React from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
import { MenuOptionCardsProps, MenuOptionToAddEdit } from "../../../types";
import { MenuOptionCard } from "modules/desking";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { useModal } from "hooks/useModal";
import { ModalsKeys } from "components/shared/Modals";
import { usePermissions } from "modules/aftermarketMenuConstructor/hooks/usePermissions";

export const MenuOptions = ({ menuOptions, isCalculating }: MenuOptionCardsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedMenuOption = useSelector(menuConstructorSelectors.selectedMenuOption);

  const scrollRef = useHorizontalScroll();
  const { handleOpen: handleAddEditMenuOptionDialogOpen } = useModal(ModalsKeys.AftermarketAddEditMenuOptionDialog);
  const { hasWriteAccess } = usePermissions();

  const onDropProduct = (product: ProposalProduct, menuOptionName: string) => {
    dispatch(
      menuConstructorActions.addMenuOptionProduct({
        menuType: menuOptionName,
        product,
      })
    );
  };

  const onRemoveProduct = (removeIndex: number, currentMenuOption: any) => {
    dispatch(
      menuConstructorActions.removeMenuOptionProduct({
        menuType: currentMenuOption.name,
        removeIndex,
      })
    );
  };

  const onEditOption = (currentMenuOption: MenuOptionToAddEdit) => {
    dispatch(
      menuConstructorActions.setMenuOptionToAddEdit({
        currentMenuOption,
      })
    );
    handleAddEditMenuOptionDialogOpen();
  };

  const onRemoveOption = (currentMenuOption: MenuOptionToAddEdit) => {
    dispatch(
      menuConstructorActions.removeMenuOption({
        currentMenuOption,
      })
    );
  };

  const onSelectMenuOption = (optionName: string) => {
    const menuOptionToSet =
      selectedMenuOption?.name === optionName
        ? null
        : menuOptions?.find((menuOption: ProposalMenuOption) => menuOption.name === optionName)!;
    dispatch(menuConstructorActions.selectMenuOption({ menuOption: menuOptionToSet }));
  };

  return (
    <Box className={classes.cardsContainer} {...({ ref: scrollRef } as any)}>
      {menuOptions?.map((menuOption: ProposalMenuOption) => (
        <MenuOptionCard
          key={menuOption.proposalMenuOptionId}
          menuOption={menuOption}
          isCalculating={isCalculating}
          selectedMenuOption={selectedMenuOption}
          onEditOption={hasWriteAccess && onEditOption}
          onRemoveOption={hasWriteAccess && onRemoveOption}
          onDropProduct={hasWriteAccess && onDropProduct}
          onRemoveProduct={hasWriteAccess && onRemoveProduct}
          onSelectMenuOption={hasWriteAccess && onSelectMenuOption}
        />
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  cardsContainer: {
    gap: "8px",
    display: "flex",
    overflowX: "auto",
    paddingBottom: "6px",
    alignItems: "flex-start",

    "& .actions": {
      gap: "2px",
      display: "flex",
      alignItems: "center",
    },
  },
});
