import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PaymentOptionCard } from "./PaymentOptionCard";
import { MenuCards } from "../../../types";

export const PaymentOptionCards = ({ paymentOptions, isCalculating }: MenuCards) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardContainer}>
      {paymentOptions.map((paymentOption, index) => (
        <PaymentOptionCard key={index} paymentOption={paymentOption} isCalculating={isCalculating} />
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    gap: "8px",
    display: "flex",
    flexWrap: "wrap",
  },
});
