import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Snackbar,
} from "@material-ui/core";
import gql from "graphql-tag";
import { Grid } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {
  checkProposalRequiredFields,
  getDefaultFinanceProgram,
  isValidProgram,
  parseProposal,
} from "../../src/services/proposalsService";
import { formatCurrency } from "../utils";
import { makeStyles } from "@material-ui/core/styles";
import Tile from "./Tile/Tile";
import FinanceProgramForm from "./FinanceProgramForm";
import ProposalOptionCard from "./ProposalOptionCard";
import { checkIfUserIsAbleToSeeProposalMenus } from "../../src/services/proposalsService";
import { theme } from "../theme";
import _ from "lodash";
import Alert from "@material-ui/lab/Alert";
import AftermarketNtpProductComponent from "./Aftermarket/NTP/AftermarketNtpProductComponent";
import AftermarketGaigProductComponent from "./Aftermarket/GAIG/AftermarketGaigProductComponent";
import AftermarketProductComponent from "./Aftermarket/AftermarketProductComponent";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const floatNumberRegexp = /^\$?([\d,.]*)?$/;

const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!) {
    financePrograms(accountId: $accountId) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

const GET_PROPOSAL_MENUS = gql`
  query {
    proposalMenus(input: {}) {
      proposalMenuId
      name
      description
      menuOptions {
        name
        ordinal
        description
        proposalMenuOptionId
        titleBgColor
        titleColor
        products {
          cost
          retailCost
          title
          proposalProductId
          thirdPartyUrl
          accountIdCreatedBy
          aftermarketProductToPartnerLinkId
          aftermarketProduct {
            aftermarketProductId
            aftermarketVendorApiChannel
            productName
            productType
            productCategory
            productDescriptionExternal
            criteria
          }
        }
      }
    }
  }
`;

const CALCULATE_MULTIPLE_PAYMENTS = gql`
  query CalculateMultiplePayments($input: [CalculateMultiplePaymentsInput]) {
    calculateMultiplePayments(input: $input) {
      criteria {
        amountFinanced
        paymentPeriodTiming
        # rate
        residualPercentage
        salesTaxPercentage
        numberOfAdvancePayments
        downPaymentAmount
        flatFees {
          fee
          identifier
        }
        markups {
          identifier
          markupPercentage
        }
        ##
        menu {
          name
          description
          menuOptions {
            name
            payment {
              paymentAmountPerPeriod
              financeProfit
            }
            description
            titleColor
            titleBgColor
            products {
              title
              # products
              # ordinal
            }
            ordinal
          }
        }
      }
      paymentOptions {
        paymentAmountPerPeriod
        dealerMarkup
        financeProfit
        paymentAmountPerPeriodWithoutDealerMarkup
        rate
        term
        customerRate
        subsidyAmount
        subsidyPercentage
        menu {
          name
          proposalMenuId
          description
          menuOptions {
            name
            description
            payment {
              paymentAmountPerPeriod
              financeProfit
              dealerMarkup
              paymentAmountPerPeriodWithoutDealerMarkup
              rate
              term
              customerRate
              subsidyAmount
              subsidyPercentage
            }
            titleColor
            titleBgColor
            products {
              proposalProductId
              title
              retailCost
              markup {
                type
                markup
              }
            }
          }
        }
        term
      }
    }
  }
`;

const CREATE_PROPOSAL_V2 = gql`
  mutation CreateProposalV2($input: CreateProposalV2Input!) {
    createProposalV2(input: $input) {
      errorMessage
      id
      success
      vendorOpportunityId
    }
  }
`;

const useStyles = makeStyles(() => ({
  selectFinanceProgramFormControl: {
    width: "250px",
    margin: "0 auto 0 40px",
  },
  selectProposalMenuFormControl: {
    width: "250px",
    margin: "0 auto 0 40px",
  },
  card: {
    margin: "180px",
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
}));

export default function FinanceProgramCard({
  account,
  vp,
  initProgram = null,
  cardTitle = null,
  isVOView,
  vo,
  isCreditSubmissionView = null,
  cs = null,
  onProgramChange = null,
  quoteToolModeEnabled = null,
  quoteInfoComponent = null,
  submitCallback = null,
  externalLoadingState = null,
}) {
  const { loading: isFinanceProgramsDataLoading, data: financeProgramsData } = useQuery(GET_FINANCE_PROGRAMS, {
    fetchPolicy: "no-cache",
    variables: { accountId: account.id },
  });

  const { loading: isProposalMenusDataLoading, data: proposalMenusData } = useQuery(GET_PROPOSAL_MENUS, {
    fetchPolicy: "no-cache",
    variables: {},
  });

  const [
    calculateMultiplePayments,
    { loading: calculatedPaymentsLoading, data: calculatedPaymentsData },
  ] = useLazyQuery(CALCULATE_MULTIPLE_PAYMENTS, {
    context: { authRequired: true },
  });

  const [createProposalV2, { loading: createProposalLoading, data: propsalCreationResponse }] = useMutation(
    CREATE_PROPOSAL_V2,
    {
      context: { authRequired: true },
      onCompleted: data => {
        if (data.createProposalV2.success) {
          window.location.href = `/proposalDetail/${data.createProposalV2.id}`;
        }
      },
    }
  );

  const classes = useStyles();
  const [calculatedPaymentOptions, setCalculatedPaymentOptions] = useState();
  const [selectedFinanceProgram, setSelectedFinanceProgram] = useState("");
  const [selectedProposalMenu, setSelectedProposalMenu] = useState("");
  const [initialSelectedFinanceProgram, setInitialSelectedFinanceProgram] = useState("");
  const [initialSelectedProposalMenu, setInitialSelectedProposalMenu] = useState("");
  const [financePrograms, setFinancePrograms] = useState();
  const [proposalMenus, setProposalMenus] = useState();
  const [isNeedToSendEmailOnProposalCreation, setIsNeedToSendEmailOnProposalCreation] = useState(false);
  const [program, setProgram] = useState();
  const calculateMultiplePaymentsDebounced = useCallback(_.debounce(calculateMultiplePayments, 500), []);
  const [financeProgramsHaveBeenUpdated, setFinanceProgramsHaveBeenUpdated] = useState(false);
  const [isProgramOwner, setIsProgramOwner] = useState(true);

  const [requiredToQuoteEngineResults, setRequiredToQuoteEngineResults] = useState({
    qualifies: true,
    overridden: false,
    engineNeedsToRun: false,
    engineHasRun: false,
  }); // { qualifies: boolean, overridden: boolean, engineHasRun:  }

  const [draggableAftermarketProducts, setDraggableAftermarketProducts] = useState(null);

  const updateFinancePrograms = (newFinancePrograms, updatedFinanceProgramIds) => {
    setFinancePrograms(newFinancePrograms);
    // console.log(`||| selectedFinanceProgram: ${JSON.stringify(selectedFinanceProgram)}`)
    // console.log(`||| Updated programs: ${JSON.stringify(newFinancePrograms)}`);
    if (selectedFinanceProgram && updatedFinanceProgramIds.includes(selectedFinanceProgram.financeProgramId)) {
      setFinanceProgramsHaveBeenUpdated(true);
      setSelectedFinanceProgram(
        newFinancePrograms.find(fp => fp.financeProgramId === selectedFinanceProgram.financeProgramId)
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(financeProgramsData)) {
      setFinancePrograms(financeProgramsData.financePrograms);
    }
  }, [financeProgramsData]);

  useEffect(() => {
    if (!_.isEmpty(proposalMenusData)) {
      setProposalMenus(proposalMenusData.proposalMenus);
    }
  }, [proposalMenusData]);

  useEffect(() => {
    const getAmountFinancedValue = () => {
      if (program?.amountFinanced > 0) {
        return program.amountFinanced;
      }
      if (!_.isEmpty(initProgram)) {
        return initProgram.amountFinanced;
      }
      if (isVOView && vo) {
        return vo.invoiceTotal;
      }
      /*       if (isCreditSubmissionView && cs) {
              return cs.amountRequested;
            } */
      return null;
    };

    const amountFinanced = getAmountFinancedValue();

    let programToSet = {};

    if (selectedFinanceProgram !== "") {
      programToSet = {
        amountFinanced,
        ...selectedFinanceProgram,
        ..._.cloneDeep(selectedFinanceProgram.paymentOptionsConfiguration),
      };
    } else if (initProgram) {
      programToSet = { amountFinanced, ..._.cloneDeep(initProgram) };
    } else {
      programToSet = getDefaultFinanceProgram(isVOView, isCreditSubmissionView);
    }

    // const baseProgramToSet =
    //   _.get(selectedFinanceProgram, "paymentOptionsConfiguration") ||
    //   initProgram ||
    //   getDefaultFinanceProgram(isVOView, isCreditSubmissionView);

    // let programToSet = {
    //   ..._.cloneDeep(baseProgramToSet), // to not modify original nested objects (markups and fee)
    //   financeProgramId: selectedFinanceProgram.financeProgramId,
    //   amountFinanced,
    //   prescreenCriteria: { ..._.cloneDeep(selectedFinanceProgram.prescreenCriteria) },
    //   financeProgramModificationSettings: { ..._.cloneDeep(selectedFinanceProgram.financeProgramModificationSettings) },
    //   dealerMarkup: selectedFinanceProgram.dealerMarkup,
    //   customerRate: selectedFinanceProgram.customerRate,
    // };

    delete programToSet.paymentOptionsConfiguration;

    if (programToSet) {
      if (!programToSet.dealerMarkup) {
        programToSet.dealerMarkup = "";
      }
      if (!programToSet.customerRate) {
        programToSet.customerRate = "";
      }
      programToSet.rates = programToSet.terms.map(term => {
        return term.rate;
      });
      programToSet.terms = programToSet.terms.map(term => {
        return term.term;
      });
      delete programToSet.rate; // Important
      programToSet.buyRate = programToSet.rates[0];
    }

    if (selectedProposalMenu) {
      programToSet = {
        ..._.cloneDeep(programToSet),
        menu: selectedProposalMenu,
      };
    }

    setProgram(programToSet);
    setInitialSelectedFinanceProgram(programToSet);
  }, [selectedFinanceProgram, selectedProposalMenu]);

  useEffect(() => {
    if (_.isEmpty(program)) {
      return;
    }
    const parsedProposal = parseProposal(program);
    if (!checkProposalRequiredFields(parsedProposal)) {
      setCalculatedPaymentOptions([]);
      return;
    }
    calculateMultiplePaymentsDebounced({ variables: { input: parsedProposal } });
  }, [program, requiredToQuoteEngineResults]);

  useEffect(() => {
    if (calculatedPaymentsData) {
      try {
        if (calculatedPaymentsData.calculateMultiplePayments[0].criteria.menu) {
          let paymentOptions = [];
          calculatedPaymentsData.calculateMultiplePayments[0].paymentOptions.forEach(po => {
            const menuPaymentOptions = po.menu.menuOptions.map(mo => {
              return {
                paymentAmountPerPeriod: mo.payment.paymentAmountPerPeriod,
                financeProfit: mo.payment.financeProfit,
                term: po.term,
                rate: po.rate,
                menuName: calculatedPaymentsData.calculateMultiplePayments[0].criteria.menu.name,
                menuOptionName: mo.name,
                titleColor: mo.titleColor,
                titleBgColor: mo.titleBgColor,
                products: mo.products,
              };
            });
            paymentOptions = paymentOptions.concat(menuPaymentOptions);
          });
          setCalculatedPaymentOptions(paymentOptions);
        } else {
          setCalculatedPaymentOptions(calculatedPaymentsData.calculateMultiplePayments[0].paymentOptions);
        }
      } catch (e) {
        console.log(`Error calculating payments: ${e.message}`);
      }
    }
  }, [calculatedPaymentsData]);

  const onFinanceProgramValuesChange = values => {
    setProgram(values);
  };

  useEffect(() => {
    if (onProgramChange) {
      onProgramChange(program);
    }
  }, [program]);

  useEffect(() => {
    console.log(`requiredToQuoteEngineResults: ${JSON.stringify(requiredToQuoteEngineResults)}`);
  }, [requiredToQuoteEngineResults]);

  const handleCreateProposal = () => {
    const parsedProposal = parseProposal(program);
    createProposalV2({
      variables: {
        input: {
          paymentOptions: parsedProposal,
          vendorOpportunityId: isVOView && vo.vendorOpportunityId,
          sendProposal: isNeedToSendEmailOnProposalCreation,
          financeProgramId: selectedFinanceProgram.financeProgramId,
        },
      },
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {/* <RequiredToQuoteInputs financePrograms={financePrograms} updateFinancePrograms={updateFinancePrograms} /> */}

      <Tile
        title={cardTitle || "Finance Program"}
        titleButtonComponent={
          <div style={{ margin: "0 auto 0 0" }}>
            <FormControl className={classes.selectFinanceProgramFormControl}>
              <InputLabel shrink id="select-finance-program-label">
                {quoteToolModeEnabled ? "Select finance program" : "Select existing or create new"}
              </InputLabel>
              <Select
                value={selectedFinanceProgram}
                displayEmpty
                labelId="select-finance-program-label"
                fullWidth
                onChange={e => {
                  setRequiredToQuoteEngineResults({
                    qualifies: true,
                    overridden: false,
                    engineNeedsToRun: false,
                    engineHasRun: false,
                  });
                  setSelectedFinanceProgram(e.target.value);
                  setIsProgramOwner(e.target.value.accountId === account.id);
                }}
              >
                <MenuItem key="none" value="">
                  {quoteToolModeEnabled ? "None" : "Create New"}
                </MenuItem>
                {_.some(financePrograms) &&
                  financePrograms
                    .filter(program => isValidProgram(program))
                    .map(financeProgram => (
                      <MenuItem key={financeProgram.financeProgramId} value={financeProgram}>
                        {financeProgram.nameInternal}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            {checkIfUserIsAbleToSeeProposalMenus(vp) && (
              <span>
                <FormControl className={classes.selectProposalMenuFormControl}>
                  <InputLabel shrink id="select-proposal-menu-label">
                    Select F&I Menu
                  </InputLabel>
                  <Select
                    value={selectedProposalMenu}
                    displayEmpty
                    labelId="select-proposal-menu-label"
                    fullWidth
                    onChange={e => {
                      setSelectedProposalMenu(e.target.value);
                    }}
                  >
                    <MenuItem key="none" value="">
                      None
                    </MenuItem>
                    {_.some(proposalMenus) &&
                      proposalMenus.map(proposalMenu => (
                        <MenuItem key={proposalMenu.proposalMenuId} value={proposalMenu}>
                          {proposalMenu.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </span>
            )}
          </div>
        }
      >
        {isFinanceProgramsDataLoading || isProposalMenusDataLoading ? (
          <CircularProgress />
        ) : (
          <div>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FinanceProgramForm
                  isVOView={isVOView}
                  isCreditSubmissionView={isCreditSubmissionView}
                  financeProgram={initialSelectedFinanceProgram}
                  onValuesChange={onFinanceProgramValuesChange}
                  quoteToolModeEnabled
                  requiredToQuoteEngineResults={requiredToQuoteEngineResults}
                  setRequiredToQuoteEngineResults={setRequiredToQuoteEngineResults}
                  setDraggableAftermarketProducts={setDraggableAftermarketProducts}
                  isProgramOwner={isProgramOwner}
                />
              </Grid>
              {requiredToQuoteEngineResults.engineNeedsToRun && !requiredToQuoteEngineResults.engineHasRun && (
                <Grid item xs={8}>
                  <h3>Please fill out the inputs on the left</h3>
                </Grid>
              )}
              {requiredToQuoteEngineResults.engineHasRun && !requiredToQuoteEngineResults.qualifies && (
                <Grid item xs={8}>
                  <Alert severity="error">
                    <div style={{ fontSize: "22px" }}>Does not qualify</div>
                    <ul>
                      <li>Make sure the values you entered are correct.</li>
                      <li>Please contact your lender for more program options.</li>
                    </ul>
                  </Alert>
                </Grid>
              )}
              {requiredToQuoteEngineResults.qualifies && (
                <Grid item xs={8}>
                  {requiredToQuoteEngineResults.engineHasRun && (
                    <Alert severity="success" style={{ marginBottom: "12px" }}>
                      <div style={{ fontSize: "22px" }}>Qualifies</div>
                    </Alert>
                  )}
                  {draggableAftermarketProducts &&
                    draggableAftermarketProducts.length > 0 &&
                    draggableAftermarketProducts.map((product, index) => {
                      if (product.AFTERMARKET_VENDOR_API_CHANNEL === "NTP") {
                        return (
                          <AftermarketNtpProductComponent key={`AftermarketNtpProduct-${index}`} jsonData={product} />
                        );
                      } else if (product.AFTERMARKET_VENDOR_API_CHANNEL === "GAIG") {
                        return (
                          <AftermarketGaigProductComponent key={`AftermarketGaigProduct-${index}`} jsonData={product} />
                        );
                      } else {
                        return <AftermarketProductComponent key={`AftermarketProduct-${index}`} jsonData={product} />;
                      }
                    })}
                  {_.some(calculatedPaymentOptions) && !calculatedPaymentsLoading && (
                    <div style={{ paddingLeft: 16 }}>
                      <div>
                        {calculatedPaymentOptions[0].financeProfit > 0 && (
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              Finance Profit: {formatCurrency(calculatedPaymentOptions[0].financeProfit)}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Dealer Markup: {calculatedPaymentOptions[0].dealerMarkup}%
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Customer Rate: {calculatedPaymentOptions[0].customerRate}%
                            </div>
                          </div>
                        )}
                        {calculatedPaymentOptions[0].subsidyAmount > 0 && (
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              Subsidy Amount: {formatCurrency(calculatedPaymentOptions[0].subsidyAmount)}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Subsidy Percentage: {calculatedPaymentOptions[0].subsidyPercentage}%
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Customer Rate: {calculatedPaymentOptions[0].customerRate}%
                            </div>
                          </div>
                        )}
                      </div>
                      <>
                        {calculatedPaymentOptions[0].menuName ? (
                          <>
                            {Object.entries(Object.groupBy(calculatedPaymentOptions, ({ term }) => term)).map(group => {
                              return (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginBottom: 16,
                                  }}
                                  key={group[0]} // Add key prop here
                                >
                                  <h5>
                                    <b>{group[0]} months</b>
                                  </h5>
                                  <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div
                                      style={{
                                        gap: 8,
                                        width: "100%",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      {group[1].map((po, i) => {
                                        return (
                                          <ProposalOptionCard
                                            key={`${po.term}-${po.paymentAmountPerPeriod}-${i}`}
                                            term={po.term}
                                            paymentAmountPerPeriod={po.paymentAmountPerPeriod}
                                            menuName={po.menuName}
                                            menuOptionName={po.menuOptionName}
                                            titleColor={po.titleColor}
                                            titleBgColor={po.titleBgColor}
                                            products={po.products}
                                            dropZoneId={`zone${i}`}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div
                            style={{
                              marginLeft: 24,
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Grid container spacing={1}>
                              {calculatedPaymentOptions.map((po, index) => {
                                return (
                                  <ProposalOptionCard
                                    key={`${po.term}-${po.paymentAmountPerPeriod}`}
                                    term={po.term}
                                    paymentAmountPerPeriod={po.paymentAmountPerPeriod}
                                    menuName={po.menuName}
                                    menuOptionName={po.menuOptionName}
                                    titleColor={po.titleColor}
                                    titleBgColor={po.titleBgColor}
                                    products={po.products}
                                    dropZoneId={`zone${index}`}
                                  />
                                );
                              })}
                            </Grid>
                          </div>
                        )}
                      </>
                    </div>
                  )}
                </Grid>
              )}
              {quoteInfoComponent}
              {!(createProposalLoading || externalLoadingState) && isVOView && (
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: theme.palette.primary.main, color: "white", marginRight: "30px" }}
                    onClick={
                      quoteToolModeEnabled
                        ? () => {
                            submitCallback(program);
                          }
                        : handleCreateProposal
                    }
                    disabled={createProposalLoading || externalLoadingState}
                  >
                    {quoteToolModeEnabled ? "Create & Send Quote" : "Create Proposal"}
                  </Button>
                  {quoteToolModeEnabled ? null : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isNeedToSendEmailOnProposalCreation}
                          onChange={() => setIsNeedToSendEmailOnProposalCreation(!isNeedToSendEmailOnProposalCreation)}
                          color="primary"
                          disabled={createProposalLoading || externalLoadingState}
                        />
                      }
                      label={"Send proposal email"}
                      style={{ color: "rgba(0,0,0,0.85)", fontSize: 14 }}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                {(createProposalLoading || externalLoadingState) && <LinearProgress />}
              </Grid>
            </Grid>
          </div>
        )}

        <Snackbar
          open={financeProgramsHaveBeenUpdated}
          autoHideDuration={10000}
          onClose={(event, reason) => {
            setFinanceProgramsHaveBeenUpdated(false);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="info">
            <div style={{ fontSize: "22px" }}>
              Based on the qualifying data you entered, the program you selected has changed.
              <p>
                <b>Please review the new program details.</b>
              </p>
            </div>
          </Alert>
        </Snackbar>
      </Tile>
    </DndProvider>
  );
}
