import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table";
import { gql } from "apollo-boost";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
import { useLazyQuery } from "@apollo/react-hooks";
import Tile from "../../components/Tile/Tile";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableSearch from "components/Table/TableSearch";
import DragIndicator from "assets/img/drag-indicator.svg";

const _ = require("lodash");

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
});

const useStyles = makeStyles(styles);

const GET_PARTNER_LINKS = gql`
  query GetPartnerLinks($dynamicsId: ID!) {
    getPartnerLinks(dynamicsId: $dynamicsId) {
      accountName
      primaryContactId
      primaryContactFullName
      primaryContactEmail
      dynamicsPartnerAccountId
      dynamicsPartnerLinkId
    }
  }
`;

const DealerManagementList = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const account = useSelector(state => state.account);
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [pageSizeValue, setPageSizeValue] = useState(20);

  const [getPartnerLinks, { data: partnerLinks, refetch: partnerLinksRefetch }] = useLazyQuery(GET_PARTNER_LINKS, {
    context: { authRequired: true },
    variables: {
      dynamicsId: account.dynamicsAccountId,
    },
  });

  const [dealerProfileList, setDealerProfileList] = useState();
  useEffect(() => {
    if (!!account && account.dynamicsAccountId) {
      getPartnerLinks();
    }
  }, [account, partnerLinks]);

  useEffect(() => {
    if (_.get(partnerLinks, "getPartnerLinks", null)) {
      setDealerProfileList(filterDealerProfileList([...partnerLinks.getPartnerLinks]));
    }

    setLoading(false);
  }, [loading, partnerLinks, searchRef.current.currentSearch]);

  const filterDealerProfileList = dealerProfileList => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return dealerProfileList;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = dealerProfileList.filter(el => {
      if (
        typeof el.accountName === "string" &&
        typeof el.primaryContactFullName === "string" &&
        typeof el.primaryContactEmail === "string"
      ) {
        return (
          (el.accountName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.primaryContactFullName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.primaryContactEmail || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return [...searchData];
  };

  if (loading) {
    return <LinearProgress size={16} />;
  }

  function handleDetailRowClick(dynamicsId) {
    props.history.push(`/partnerAccountDetails/${dynamicsId}`);
  }

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item xs={12}>
          <Tile>
            <div className={classes.fixReactTable}>
              <TableSearch
                setPageNumber={setPageNumber}
                setSavedListSettings={setSavedListSettings}
                searchRef={searchRef}
                savedListSettings={savedListSettings}
              />
              <ReactTable
                color="primary"
                data={dealerProfileList}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    style: { cursor: "pointer" },
                    onClick: e => {
                      {
                        handleDetailRowClick(rowInfo.original.dynamicsPartnerLinkId);
                      }
                    },
                  };
                }}
                columns={[
                  {
                    Header: "Dealer Name",
                    accessor: "accountName",
                    resizable: false,
                    Cell: props => <div>{props.original.accountName ? props.original.accountName : ""}</div>,
                    filterMethod: (filter, row) => {
                      const value = filter.value.toLowerCase();
                      const accountName = row.accountName.toLowerCase();
                      if (accountName.includes(value)) {
                        return row;
                      }
                      return false;
                    },
                  },
                  {
                    Header: "Dealer Contact",
                    accessor: "primaryContactFullName",
                  },
                  {
                    Header: "Dealer Email",
                    accessor: "primaryContactEmail",
                  },
                ]}
                onPageChange={changePage => {
                  setPageNumber(changePage);
                }}
                onPageSizeChange={changePageSize => {
                  setPageSizeValue(changePageSize);
                  setPageNumber(0);
                }}
                page={pageNumber}
                pageSize={pageSizeValue}
                defaultPageSize={savedListSettings.pageSize}
                showPaginationTop={false}
                showPaginationBottom={true}
                customCellClasses={[classes.right]}
                customClassesForCells={[5]}
                className="-striped -highlight"
              />
            </div>
          </Tile>
        </Grid>
      </Grid>
    </div>
  );
};

export default DealerManagementList;
