import React from "react";
import ReactTable from "react-table";
import { createTableColumns } from "./tableConfig";
import { usePagination } from "../../../../hooks/usePagination";
import { makeStyles } from "@material-ui/core/styles";
import { CustomPagination } from "../../../Table/Pagination";
import clsx from "clsx";

interface Props {
  tasks: any[];
  isTableDataLoading: boolean;
  onRowClick: (taskId: string) => void;
}

export const TaskTable = ({ tasks, onRowClick, isTableDataLoading }: Props) => {
  const classes = useStyles();

  const columns = createTableColumns();

  const paginationProps = usePagination(tasks?.length);

  return (
    <ReactTable
      getTrProps={(_: any, rowInfo: any) => {
        return {
          style: { cursor: "pointer" },
          onClick: (e: any) => {
            e.preventDefault();
            onRowClick(rowInfo.original.taskId);
          },
        };
      }}
      loading={isTableDataLoading}
      loadingText={"Loading tasks"}
      noDataText={isTableDataLoading ? "" : "There are no tasks"}
      data={tasks.sort((a: any, b: any) => Number(new Date(b.createdDateTime)) - Number(new Date(a.createdDateTime)))}
      columns={columns}
      showPaginationTop={false}
      showPaginationBottom
      PaginationComponent={CustomPagination}
      className={clsx("-highlight", classes.table)}
      {...paginationProps}
    />
  );
};

const useStyles = makeStyles({
  table: {
    minHeight: "30rem",

    "& .rt-thead": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },

    "& .rt-tr": {
      margin: 0,
      minHeight: "4rem",
    },

    "& .rt-td": {
      margin: 0,
    },
  },
});
