import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  CalculatePaymentProductAddonInput,
  CalculatePaymentProductInput,
} from "@trnsact/trnsact-shared-types/dist/generated";
import "./AftermarketProductComponent.css";
import DraggableComponent from "components/DragAndDrop/DraggableComponent";
import { ProposalProduct } from "@trnsact/trnsact-shared-types";

interface AftermarketProductComponentProps {
  jsonData: {
    MARKUP: {
      TYPE: "PERCENTAGE" | "FLAT";
      AMOUNT: string;
    };
    DEALER_COST: string;
    PRICING_MAX: string;
    PRICING_MIN: string;
    SUGGESTED_RETAIL_PRICE: string;
    product: ProposalProduct;
  };
}

const AftermarketProductComponent: React.FC<AftermarketProductComponentProps> = ({ jsonData }) => {
  const [data, setData] = useState(jsonData);

  // const handleCheckboxChange = (assetIndex: number, isSelected: boolean) => {
  //   const updatedData = _.cloneDeep(data);

  //   // Add `isSelected` property to the asset
  //   // updatedData.API_RESPONSE_DATA.availableAssets[assetIndex].isSelected = isSelected;

  //   setData(updatedData);
  // };

  // const mapDataToDraggableComponent = () => {
  //   const newMappedData: CalculatePaymentProductInput = data;
  // };

  return (
    <div className="draggable-container">
      <div className="header">
        {/* <img className="logo" src={_LOGO} alt=" Logo" /> */}
        <div className="description">
          {data.product?.title} {data.product?.cost}
        </div>
        <span>Dealer Cost: {data.DEALER_COST}</span>
        <span>Pricing Min: {data.PRICING_MIN}</span>
        <span>Pricing Max: {data.PRICING_MAX}</span>
        <span>Suggested Retail: {data.SUGGESTED_RETAIL_PRICE}</span>
        <span>
          Markup: {data.MARKUP.TYPE}: {data.MARKUP.AMOUNT}
        </span>
      </div>
    </div>
  );
};

export default AftermarketProductComponent;
