import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Button } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { GET_CREDIT_SUBMISSIONS, SUBMIT_APP } from "../queries";
import Tile from "../../Tile/Tile";
import { SET_CS, SET_CS_REFETCH } from "../../../redux/types";
import LenderSubmissionDetailModal from "../../../pages/VendorOpportunity/LenderSubmissionDetailModal";
import Typography from "@material-ui/core/Typography";
import LenderSubmission_V2 from "./LenderSubmission_V2";
import { SyndicationsTable } from "./table/SyndicationsTable";
import { logError } from "../../../utils/logger";

interface Props {
  cs: any;
  equipmentData: any;
  callbackSubmissionFinished: any;
  handleSubmitToLenderByProfileId: any;

  vo?: any;
  account?: any;
  userProfile?: any;
  lp?: any;
  refetch?: any;
  vp?: any;
  creditApp?: any;
}

const SyndicationsList = ({
  cs,
  vo,
  lp,
  vp,
  refetch,
  account,
  creditApp,
  userProfile,
  equipmentData,
  handleSubmitToLenderByProfileId,
  callbackSubmissionFinished,
}: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [creditSubmissions, setCreditSubmissions] = useState<any>();
  const [openLenderSubmissionDetailModal, setOpenLenderSubmissionDetailModal] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [creditSubId, setCreditSubId] = useState<any>(null);
  const [autoRefreshInterval, setAutoRefreshInterval] = useState<any>(null);
  const [creditSubLenderSubmissionDetailModal, setCreditSubLenderSubmissionDetailModal] = useState<any>(null);
  const [openUpdateLenderCredentialsModal, setOpenUpdateLenderCredentialsModal] = useState<any>(false);
  const [selectedLenderProfile, setSelectedLenderProfile] = useState<any>(null);
  const [masterCreditSubmissionId, setMasterCreditSubmissionId] = useState<any>(null);
  const [lenderProfiles, setLenderProfiles] = useState<any>(null);

  const [
    getCreditSubmissions,
    {
      called: creditSubmissionsCalled,
      data: creditSubmissionsData,
      loading: creditSubmissionsLoading,
      error: creditSubmissionsError,
      refetch: creditSubmissionsRefetch,
    },
  ] = useLazyQuery(GET_CREDIT_SUBMISSIONS);

  const [creditSubmissionRequest] = useMutation(SUBMIT_APP, {
    context: { authRequired: true },
  });

  const resubmitCreditApp = async (row: any) => {
    const lenderInfo = lp.find((lender: any) => lender.lenderName === row.lenderName);

    try {
      const resultSubmission = await creditSubmissionRequest({
        variables: {
          input: {
            submittedLender: lenderInfo.lenderAccountId,
            dynamicsCreditApplicationId: creditApp.dynamicsId,
            dynamicsVOId: vo.dynamicsVendorOpportunityId,
            dynamicsUserId: userProfile.dynamicsUserId,
            amountRequested: vo.invoiceTotal,
            accountId: account.id,
            lenderProfileId: lenderInfo.lenderProfileId,
            lenderContactId: lenderInfo.lenderContactId,
            createDraftOnly: false,
            transactionSummary: row.transactionSummary,
          },
        },
      });

      const dynamicsCreditSubmissionId = _.get(resultSubmission, "data.createCreditSubmissions[0]", null);

      if (callbackSubmissionFinished) callbackSubmissionFinished(dynamicsCreditSubmissionId);

      await creditSubmissionsRefetch();
      await handleRefetchCreditSubmission();
    } catch (err) {
      console.log(err);
    }
  };

  if (creditSubmissionsLoading) console.log("Loading Credit Submissions....");
  if (creditSubmissionsError) console.log(`Error! ${creditSubmissionsError.message}`);

  useEffect(() => {
    if (_.isEmpty(cs) || _.isEmpty(vp)) return;

    const isNeedToTakeCreditSubmissionLenders = account?.dynamicsAccountId == "5e7f2efe-5c02-eb11-8125-065d4ad39109"; // for Lender Network account only
    const lendersToShow = _(vp.lenderProfiles) // the ones which are added for current Lender's VP
      .concat(isNeedToTakeCreditSubmissionLenders ? cs.lenderProfiles : []) // combine LPs from current credit submission (takes LPs from related Dealer's)
      .compact()
      .uniqBy("lenderProfileId")
      .value();
    setLenderProfiles(lendersToShow);
  }, [cs, vp]);

  useEffect(() => {
    if (handleSubmitToLenderByProfileId && vp.lenderProfiles) {
      const lender = _.find(vp.lenderProfiles, { id: handleSubmitToLenderByProfileId });
      if (lender) {
        resubmitCreditApp(lender);
      } else {
        console.log("Unidenfitied lender to resubmit credit app.");
      }
    }
  }, [vp, handleSubmitToLenderByProfileId]);

  useEffect(() => {
    "eff";
    console.log(creditSubmissionsData);
  }, [creditSubmissionsData]);

  useEffect(() => {
    if (!!cs && !!cs.creditSubId) {
      getCreditSubmissions({
        variables: {
          id: cs.creditSubId,
        },
      });
    }

    if (creditSubmissionsCalled && !!creditSubmissionsData) {
      const filteredCS = creditSubmissionsData.creditSubmissionsByMasterCreditSubmissionId.filter(
        (el: any) => el != null
      );
      filteredCS.forEach((item: any) => {
        item.displayText =
          item.displayStatus === 100
            ? "Shown"
            : item.displayStatus === 200
            ? "Hidden"
            : item.displayStatus === 300
            ? "Shown - Dealer Created"
            : null;
        return item;
      });

      setCreditSubmissions(
        creditSubmissionsData.creditSubmissionsByMasterCreditSubmissionId.filter((el: any) => el !== null)
      );
      setMasterCreditSubmissionId(cs.creditSubId);

      let myData = Object.keys(filteredCS).map(key => filteredCS[key]);
      dispatch({ type: SET_CS, payload: myData });
    }
  }, [vo, creditSubmissionsData, creditSubmissionsCalled, autoRefreshInterval, SET_CS]);

  if (refetch.creditSubmissions) {
    dispatch({ type: SET_CS_REFETCH, payload: false });
    creditSubmissionsRefetch();
  }

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [openMessageModal, setOpenMessageModal] = useState<any>(false);
  const [submissionMessage, setSubmissionMessage] = useState<any>({});

  const handleOpenModal = () => setOpenMessageModal(true);

  const handleSubmissionMessage = async (message: any) => {
    await handleRefetchCreditSubmission();
    return setSubmissionMessage(message);
  };

  const handleRefetchCreditSubmission = async () => {
    try {
      await creditSubmissionsRefetch();
    } catch (error) {
      logError(error);
    }
  };

  const handleOpenLenderDetailModalView = (row: any) => {
    setCreditSubId(row.creditSubId);
    setCreditSubLenderSubmissionDetailModal(row);
    setOpenLenderSubmissionDetailModal(true);
  };

  const handleCloseLenderDetailModalView = () => {
    setOpenLenderSubmissionDetailModal(false);
    setCreditSubLenderSubmissionDetailModal(false);
    setCreditSubId("");
  };

  if (!!refetch && !!refetch.pastSubmissions) {
    creditSubmissionsRefetch();
    dispatch({ type: SET_CS_REFETCH, payload: false });
  }

  const handleOpenUpdateLenderCredentialsModal = () => {
    setOpenUpdateLenderCredentialsModal(true);
  };
  function handleUpdateCredentials(rowInfo: any) {
    setSelectedLenderProfile(rowInfo);
    handleOpenUpdateLenderCredentialsModal();
  }

  return (
    <>
      <Tile
        useCollapsing
        classBody={classes.cardBody}
        title={
          <Typography variant="h6" className={classes.cardTitle}>
            Manage Syndications
          </Typography>
        }
        titleButtonComponent={
          <Button
            size="small"
            color="primary"
            variant="outlined"
            disabled={creditSubmissionsLoading}
            aria-label="reload"
            onClick={handleRefetchCreditSubmission}
            startIcon={<RefreshIcon style={{ margin: 0 }} />}
          >
            {creditSubmissionsLoading ? "Syncing..." : "Sync submissions"}
          </Button>
        }
      >
        <LenderSubmission_V2
          refetch={creditSubmissionsRefetch}
          vo={vo}
          equipmentData={equipmentData}
          handleOpenModal={handleOpenModal}
          handleSubmissionMessage={handleSubmissionMessage}
          account={account}
          lp={lenderProfiles}
          creditApp={creditApp}
          creditSubmissions={creditSubmissions}
          cs={cs}
        />

        {!!creditSubmissions?.length && (
          <SyndicationsTable
            userProfile={userProfile}
            creditSubmissions={creditSubmissions}
            currencyFormatter={currencyFormatter}
            resubmitCreditApp={resubmitCreditApp}
            handleUpdateCredentials={handleUpdateCredentials}
            handleOpenLenderDetailModalView={handleOpenLenderDetailModalView}
          />
        )}
      </Tile>

      {creditSubLenderSubmissionDetailModal && (
        <LenderSubmissionDetailModal
          key={Math.random()}
          creditApp={null}
          creditSubmission={creditSubLenderSubmissionDetailModal}
          open={openLenderSubmissionDetailModal}
          vo={vo}
          handleCloseLenderDetailModalView={handleCloseLenderDetailModalView}
          refetchCreditSubmissions={creditSubmissionsRefetch}
          relatedCreditSubmissions={creditSubmissions}
          vp={vp}
          account={account}
          userProfile={userProfile}
          masterCreditSubmissionId={masterCreditSubmissionId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  vo: state.vo,
  account: state.account,
  userProfile: state.userProfile,
  lp: state.lenderProfile,
  refetch: state.cs.refetch,
  vp: state.vp,
  creditApp: state.creditApp,
  creditSubmissions: state.creditSubmissions,
});

export default connect(mapStateToProps, null)(SyndicationsList);

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 600,
  },
  rootButton: {
    minWidth: "45px",
  },
  buttonDetail: {
    display: "inline-block",
    float: "right",
    color: theme.palette.primary.main,
    paddingLeft: 0,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  right: {
    textAlign: "right",
  },
  cardBody: {
    paddingTop: 0,
    "& .-pagination": {
      padding: "30px 0 3px",
      alignItems: "center",
    },
    "& .-btn": {
      opacity: "1 !important",
      fontSize: "12px !important",
      fontWeight: 400,
      color: "#000000 !important",
      textTransform: "uppercase",
      fontFamily: "Roboto",
      maxWidth: "125px",
      maxHeight: "30px",
      height: "30px !important",
    },
    "& .-next": {
      "& .-btn": {
        display: "inline-block",
        float: "right",
      },
    },
    "& .-center": {
      flex: "5.5 1 !important",
      justifyContent: "space-between !important",
    },
    "& .-pageInfo": {
      width: "50% !important",
      margin: "0 !important",
    },
    "& .-pageJump": {
      width: "80% !important",
    },
    "& .-pageSizeOptions": {
      width: "50% !important",
      margin: "0 !important",
      "& select": {
        width: "80% !important",
      },
    },
  },
  cardTitle: {
    textTransform: "capitalize",
  },
  apiResultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    color: "#000000",
  },
  apiResultError: {
    color: "#98260D",
  },
  apiResultSuccess: {
    color: "green",
  },
}));
