import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";

const styles = {
  searchForm: {
    "& .MuiInputAdornment-positionStart": {
      margin: 0,
    },
  },
};

const useStyles = makeStyles(styles);

const TableSearch = ({
  searchRef,
  savedListSettings,
  setSavedListSettings,
  setPageNumber,
  width = "250",
  styles = {},
}) => {
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const throttleSetSearchRef = _.throttle(text => {
    searchRef.current = { ...searchRef.current, currentSearch: text };
  }, 500);

  const handleSearchChange = text => {
    setSearch(text);
    throttleSetSearchRef(text);
    setSavedListSettings({ ...savedListSettings, page: 0 });
    setPageNumber(0);
  };

  return (
    <>
      <FormControl style={{ margin: 0, width: width, ...styles }} className={classes.searchForm}>
        <TextField
          size="small"
          variant="outlined"
          onChange={e => handleSearchChange(e.target.value)}
          value={search}
          style={{ backgroundColor: "#fff", borderRadius: 4, width: "100%" }}
          placeholder="Search"
          InputProps={{
            endAdornment:
              search.length > 0 ? (
                <InputAdornment
                  position="end"
                  disablePointerEvents={search.length === 0}
                  onClick={() => {
                    if (!search.length) {
                      return;
                    }

                    setSearch("");
                    searchRef.current = {
                      ...searchRef.current,
                      currentSearch: "",
                    };
                    setSavedListSettings({ ...savedListSettings, page: 0 });
                    setPageNumber(0);
                  }}
                >
                  <ClearIcon />
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "rgba(0,0,0,0.56)" }} />
                </InputAdornment>
              ),
          }}
        />
      </FormControl>
    </>
  );
};

export default TableSearch;
