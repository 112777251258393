import React, { useState } from "react";
import { Box, TextField } from "@material-ui/core";
import _ from "lodash";

export function TextFieldSetting({ settings, itemConfiguration, setItemConfiguration }) {
  const [value, setValue] = useState(_.get(itemConfiguration, settings.configurationKey, ""));

  const updateValue = value => {
    setValue(value);
    setItemConfiguration(prevItemConfiguration => {
      return {
        ...prevItemConfiguration,
        [settings.configurationKey]: value,
      };
    });
  };
  return (
    <Box>
      <TextField value={value} variant="outlined" fullWidth onChange={e => updateValue(e.target.value)} />
    </Box>
  );
}
