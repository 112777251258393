import ocaFieldsConfig from "../../../DynamicOCA/config/fields_sectionized.json";
import { Button, Grid, InputLabel, TextareaAutosize } from "@material-ui/core";
import { FormControl, FormHelperText, MenuItem, Select, TextField } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { ocaUploadFileTypes } from "../../../Prequal/constants";

const ocaFieldList = _.flatMap(ocaFieldsConfig, "fieldList");

const useStyles = makeStyles(theme => ({
  customizeSelect: {
    display: "flex",
  },
  selectLabel: {
    position: "absolute",
    fontSize: 16,
  },
  formControll: {
    minWidth: "200px",
  },
  customizeContainer: {
    margin: "10px 20px 15px",
    "& .MuiToggleButton-root.Mui-selected": {
      background: theme.palette.primary.main,
      color: "white",
    },
  },
  mb20: {
    marginBottom: "20px",
  },
  ml10: {
    marginLeft: "10px",
  },
  optionContainer: {
    borderBottom: "1px solid gray",
    marginBottom: "20px",
  },
  optionHidden: {
    background: "#d2d2d2",
  },
  rightAlignedButton: {
    marginLeft: "auto",
  },
  narrowedButton: {
    padding: "5px 10px",
  },
  blockSettings: {
    borderTop: "1px solid gray",
    borderBottom: "1px solid gray",
  },
}));

export const CustomizationPanel = ({
  selectedItem,
  config,
  fieldAttr,
  value,
  setSelectedItem,
  changeSelectedItemProp,
  changeSelectedItemConfig,
}) => {
  const classes = useStyles();

  const languagesSettings = [
    { code: "en", label: "English" },
    { code: "es", label: "Spanish" },
  ];

  const handleToggleButtonClick = (event, value) => {
    if (value !== null) {
      changeSelectedItemConfig(fieldAttr, value);
    }
  };

  const changeSelectOption = (options, optionIndex, key, newValue) => {
    return options.map((option, idx) => {
      if (idx === optionIndex) {
        const newOption = { ...option };
        _.set(newOption, key, newValue);
        return newOption;
      }
      return option;
    });
  };

  const createFieldLabel = value => {
    let res = value.match(/[A-Z]?[a-z]+/g);
    res[0] = res[0][0].toUpperCase() + res[0].slice(1);
    return res.join(" ");
  };

  const getInputValue = (key, value) => {
    if (key === "fontSize") {
      return value.replace(/[\D]/g, "");
    } else {
      return value;
    }
  };

  const getSettingDefaultLayout = label => {
    return (
      <Grid item xs={12} className={classes.customizeContainer}>
        <TextField
          fullWidth
          label={label || createFieldLabel(fieldAttr)}
          value={getInputValue(fieldAttr, value)}
          variant="standard"
          onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
        />
      </Grid>
    );
  };

  const handleFieldTypeUpdate = value => {
    const defaultFieldOptionsByFieldName = {
      title: [
        {
          optionLabel: "Company Driver",
          optionValue: "Company Driver",
          displayLabel: {
            en: "Company Driver",
            es: "Conductor de la empresa",
          },
        },
        {
          optionLabel: "Owner Operator",
          optionValue: "Owner Operator",
          displayLabel: {
            en: "Owner Operator",
            es: "Operador propietario",
          },
        },
        {
          optionLabel: "Other",
          optionValue: "Other",
          displayLabel: {
            en: "Other",
            es: "Otro",
          },
        },
      ],
    };

    if (value === "select-field") {
      changeSelectedItemConfig("fieldOptions", defaultFieldOptionsByFieldName[config.fieldName]);
      changeSelectedItemProp("type", value);
    }
    if (value === "text-field") {
      setSelectedItem(prevSettings => {
        const newSettings = _.cloneDeep(prevSettings);
        newSettings.type = value;
        delete newSettings.config.fieldOptions;
        return newSettings;
      });
    }
  };

  createFieldLabel("textAlign");
  switch (fieldAttr) {
    case "message":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <TextareaAutosize
            onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            value={value}
            minRows={4}
            maxRows={6}
            label={fieldAttr}
            placeholder="Type your message here"
            variant="standard"
          />
        </Grid>
      );
    case "fieldLabel":
      return null;
    case "fieldName":
      return null;
    case "columns":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="body1">
                How much space this field is going to occupy? (scoped by device size)
              </Typography>
            </Grid>
          </Grid>
          {[
            { code: "xs", label: "Extra Small" },
            { code: "sm", label: "Small" },
            { code: "md", label: "Medium" },
            { code: "lg", label: "Large" },
            { code: "xl", label: "Extra Large" },
          ].map(deviceSize => {
            return (
              <Grid container spacing={2} key={Math.random()}>
                <Grid item xs={4}>
                  {deviceSize.label}
                </Grid>
                <Grid item xs={8}>
                  <Select
                    value={_.get(value, deviceSize.code)}
                    variant="standard"
                    onChange={e => changeSelectedItemConfig(`${fieldAttr}.${deviceSize.code}`, e.target.value)}
                  >
                    {_.range(1, 13).map(size => {
                      return (
                        <MenuItem key={Math.random()} value={size}>
                          {size}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      );
    case "textAlign":
      return null;
    case "valuesList":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">Option Set Display Labels</Typography>
            </Grid>

            {value.map((option, index) => {
              return (
                <Grid container key={Math.random()} spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      variant="filled"
                      label={`API ID`}
                      fullWidth
                      key={`optionVal.${index}`}
                      value={option.value}
                      onChange={e =>
                        changeSelectedItemConfig(fieldAttr, changeSelectOption(value, index, "value", e.target.value))
                      }
                      disabled
                    />
                  </Grid>
                  <hr />
                  {languagesSettings.map((language, idx) => {
                    return (
                      <Grid item xs={12} key={Math.random()}>
                        <TextField
                          variant="outlined"
                          label={`${language.label} label`}
                          fullWidth
                          key={`displayLbl.${language.code}${idx}`}
                          value={_.get(option.displayLabel, language.code, "")}
                          onChange={e =>
                            changeSelectedItemConfig(
                              fieldAttr,
                              changeSelectOption(value, index, `displayLabel.${language.code}`, e.target.value)
                            )
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
            <hr />
            {/*             <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                variant="outlined"
                color="primary"
                disabled={false}
                onClick={() =>
                  changeSelectedItemConfig(
                    `fieldOptions`,
                    value.concat({
                      optionLabel: "NEW OPTION",
                      optionValue: "NEW OPTION",
                      displayLabel: { en: "NEW OPTION", es: "" },
                    })
                  )
                }
              >
                Add new option
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      );
    case "headerContent":
    case "mainContent":
    case "helperText":
    case "errorMessage":
    case "displayLabel":
      const headersByFieldAttr = {
        headerContent: "Header Content",
        mainContent: "Main Content",
        displayLabel: "Field Displayed Label",
        helperText: "Field Helper Text",
        errorMessage: "Error Message Text",
      };
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">{headersByFieldAttr[fieldAttr]}</Typography>
            </Grid>
          </Grid>
          {languagesSettings.map((language, idx) => {
            return (
              <>
                <Grid container spacing={1} key={`${fieldAttr}.${language.code}.${idx}`}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      variant="outlined"
                      label={`${language.label} label`}
                      fullWidth
                      value={_.get(value, language.code, "")}
                      onChange={e => changeSelectedItemConfig(`${fieldAttr}.${language.code}`, e.target.value)}
                    />
                  </Grid>
                </Grid>
                <hr />
              </>
            );
          })}
        </Grid>
      );
    case "color":
      return null;
    case "displayed":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="field-required-label" className={classes.selectLabel}>
              Show Field? (rules have precedence)
            </InputLabel>
            <Select
              labelId="field-required-label"
              value={value}
              variant="standard"
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem className={classes.customizeSelect} value={true}>
                Yes
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value={false}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "type":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="field-width-label" className={classes.selectLabel}>
              Input Type
            </InputLabel>
            <Select
              labelId="field-width-label"
              value={value}
              variant="standard"
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem className={classes.customizeSelect} value="float">
                Float
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value="integer">
                Integer
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value="string">
                String
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "fullWidth":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="field-width-label" className={classes.selectLabel}>
              Full Width?
            </InputLabel>
            <Select
              labelId="field-width-label"
              value={value}
              variant="standard"
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem className={classes.customizeSelect} value={true}>
                Yes
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value={false}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "required":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="field-required-label" className={classes.selectLabel}>
              Field is Required?
            </InputLabel>
            <Select
              labelId="field-required-label"
              value={value}
              variant="standard"
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem className={classes.customizeSelect} value={true}>
                Yes
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value={false}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "disabled":
      return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="field-disabled-label" className={classes.selectLabel}>
              Field is read-only?
            </InputLabel>
            <Select
              labelId="field-disabled-label"
              value={value}
              variant="standard"
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem className={classes.customizeSelect} value={true}>
                Yes
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value={false}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "yearsSettings":
      const minYear = _.get(config, "minYear") || 1900;
      const maxYear = _.get(config, "maxYear") || 2050;
      const maxYearsRange = _.concat("", _.range(maxYear, minYear - 1)); // "" used for current year
      const minYearsRange = _.concat(_.range(maxYear, minYear - 1), ""); // "" used for current year
      return (
        <Grid item block xs={12} className={classes.blockSettings}>
          <Grid item xs={12} className={classes.customizeContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h6">Years Settings: </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl className={classes.formControll} fullWidth>
                  <Autocomplete
                    value={config.yearsSettings.minYear}
                    options={minYearsRange}
                    onChange={(e, newValue) => changeSelectedItemConfig("yearsSettings.minYear", newValue)}
                    getOptionLabel={year => (year ? _.toString(year) : "Current Year")}
                    renderInput={params => <TextField {...params} variant="standard" label="Min Year" />}
                    disableClearable={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControll} fullWidth>
                  <Autocomplete
                    value={config.yearsSettings.maxYear}
                    options={maxYearsRange}
                    onChange={(e, newValue) => changeSelectedItemConfig("yearsSettings.maxYear", newValue)}
                    getOptionLabel={year => (year ? _.toString(year) : "Current Year")}
                    renderInput={params => <TextField {...params} variant="standard" label="Max Year" />}
                    disableClearable={true}
                  />
                </FormControl>
              </Grid>
              {config.yearsSettings.maxYear === "" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Add Years In Advance"
                    value={config.yearsSettings.yearsInAdvance}
                    variant="standard"
                    onChange={e => changeSelectedItemConfig("yearsSettings.yearsInAdvance", _.parseInt(e.target.value))}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    case "fieldOptions":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6">Options Set</Typography>
            </Grid>
            {value.map((option, index) => {
              return (
                <Grid
                  container
                  item
                  key={option.value}
                  spacing={3}
                  className={classes.optionContainer + (option.isHidden ? " " + classes.optionHidden : "")}
                >
                  {languagesSettings.map((language, idx) => {
                    return (
                      <Grid item xs={12} key={`displayLbl.${language.code}.${idx}.${option.value}`}>
                        <TextField
                          variant="outlined"
                          label={`${language.label} label`}
                          fullWidth
                          value={_.get(option.displayLabel, language.code, "")}
                          onChange={e =>
                            changeSelectedItemConfig(
                              fieldAttr,
                              changeSelectOption(value, index, `displayLabel.${language.code}`, e.target.value)
                            )
                          }
                        />
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <TextField
                      variant="filled"
                      label={`API ID (be careful when changing)`}
                      fullWidth
                      value={option.optionValue}
                      onChange={e =>
                        changeSelectedItemConfig(
                          fieldAttr,
                          changeSelectOption(value, index, "optionValue", e.target.value)
                        )
                      }
                    />
                  </Grid>
                  <Grid item container spacing={1} xs={12}>
                    {value.length !== 1 && index !== 0 && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            const newValues = _.cloneDeep(value);
                            newValues.splice(index, 1);
                            newValues.splice(index - 1, 0, option);
                            return changeSelectedItemConfig(fieldAttr, newValues);
                          }}
                        >
                          <ArrowUpward />
                        </Button>
                      </Grid>
                    )}
                    {value.length !== index + 1 && (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            const newValues = _.cloneDeep(value);
                            newValues.splice(index, 1);
                            newValues.splice(index + 1, 0, option);
                            return changeSelectedItemConfig(fieldAttr, newValues);
                          }}
                        >
                          <ArrowDownward />
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          changeSelectedItemConfig(
                            fieldAttr,
                            changeSelectOption(value, index, `isHidden`, !option.isHidden)
                          );
                        }}
                      >
                        <span>{option.isHidden ? "Show" : "Hide"}</span>
                      </Button>
                    </Grid>
                    <Grid item className={classes.rightAlignedButton}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          const newValues = value.filter((el, idx) => idx !== index);
                          return changeSelectedItemConfig(`fieldOptions`, newValues);
                        }}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <hr />
            <Grid item container xs={12} justifyContent="space-between">
              <Button
                className={classes.narrowedButton}
                variant="outlined"
                onClick={() => {
                  const field = _.find(ocaFieldList, field => _.get(field, "config.fieldName") === config.fieldName);
                  const defaultOptions = _.get(field, "config.fieldOptions", []);
                  return changeSelectedItemConfig(fieldAttr, _.cloneDeep(defaultOptions));
                }}
              >
                Set Default Options
              </Button>
              <Button
                className={classes.narrowedButton}
                variant="contained"
                color="primary"
                onClick={() =>
                  changeSelectedItemConfig(
                    `fieldOptions`,
                    value.concat({
                      optionValue: "NEW OPTION",
                      displayLabel: { en: "NEW OPTION", es: "NUEVA OPCIÓN" },
                    })
                  )
                }
              >
                Add new option
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    case "apiIds":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">API ID: </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                value={config.selectedApiId}
                variant="standard"
                label="API ID"
                fullWidth
                onChange={e => changeSelectedItemConfig("selectedApiId", e.target.value)}
              >
                <MenuItem key="not-set" value="">
                  Not Set
                </MenuItem>
                {value.map(apidId => {
                  return (
                    <MenuItem key={Math.random()} value={apidId}>
                      {apidId}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                If the API ID is set, the HTML output of this field will be saved in credit_application. If not, then
                the HTML markup will simply be displayed
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      );
    case "signatureAvailableApiIds":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">API ID: </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                value={config.selectedApiId || "signature"}
                variant="standard"
                label="API ID"
                fullWidth
                onChange={e => changeSelectedItemConfig("selectedApiId", e.target.value)}
              >
                {value.map(apiId => {
                  return (
                    <MenuItem key={Math.random()} value={apiId}>
                      {apiId}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      );
    case "availableFieldTypes":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">Field Type: </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                value={selectedItem.type}
                variant="standard"
                label="Field Type"
                fullWidth
                onChange={e => handleFieldTypeUpdate(e.target.value)}
              >
                {value.map(fieldType => {
                  return (
                    <MenuItem key={Math.random()} value={fieldType}>
                      {fieldType}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      );
    case "componentVersion":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <h3>Component Version:</h3>
          <ToggleButtonGroup
            value={value || "v1" /* locationComponentVersion */}
            exclusive
            onChange={handleToggleButtonClick}
            aria-label="Location component version"
          >
            <ToggleButton value="v1" aria-label="version 1">
              Only Region
            </ToggleButton>
            <ToggleButton value="v2" aria-label="version 2">
              Dynamic
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      );
    case "isNeedToShowMasterLocation":
      return (
        config.componentVersion === "v2" && (
          <Grid item xs={12} className={classes.customizeContainer}>
            <FormControlLabel
              value="Show Master Location"
              control={
                <Switch color="primary" checked={value} onChange={() => changeSelectedItemConfig(fieldAttr, !value)} />
              }
              label="Show Master Location"
              labelPlacement="end"
            />
          </Grid>
        )
      );
    case "sources":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">HTML Source: </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Select
                value={config.selectedSource}
                variant="standard"
                fullWidth
                onChange={e => changeSelectedItemConfig("selectedSource", e.target.value)}
              >
                {value.map(source => {
                  return (
                    <MenuItem key={Math.random()} value={source}>
                      {source}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            {_.get(config, "selectedSource") === "Custom Markup" && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="HTML Markup To Render"
                  fullWidth
                  value={config.customMarkup}
                  onChange={e => changeSelectedItemConfig("customMarkup", e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    case "pdfTemplateId":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">Document To Sign: </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Select
                labelId="field-document-to-sign-label"
                value={value}
                variant="standard"
                onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
              >
                <MenuItem className={classes.customizeSelect} value="17LFpw2fZRdFnuO8SJcN">
                  Dynamic Sign Quinn Addendum (PDF Template ID - 17LFpw2fZRdFnuO8SJcN)
                </MenuItem>
                <MenuItem className={classes.customizeSelect} value="d90lSgEjKDeXgy8veKDV">
                  Dynamic Sign Ziegler Addendum (PDF Template ID - d90lSgEjKDeXgy8veKDV)
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      );
    case "requireAcceptanceToProceed":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <h3 style={{ marginTop: 0 }}>Require Acceptance To Proceed OCA</h3>
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleToggleButtonClick}
            aria-label="Require Acceptance To Proceed OCA"
          >
            <ToggleButton value={true} aria-label="yes">
              Yes
            </ToggleButton>
            <ToggleButton value={false} aria-label="no">
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      );
    case "requireSigning":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <h3 style={{ marginTop: 0 }}>Require Signing Document To Proceed OCA</h3>
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleToggleButtonClick}
            aria-label="Require Signing Document To Proceed OCA"
          >
            <ToggleButton value={true} aria-label="yes">
              Yes
            </ToggleButton>
            <ToggleButton value={false} aria-label="no">
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      );
    case "tasksAutoCreation":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <h3 style={{ marginTop: 0 }}>Tasks Auto Creation</h3>
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={handleToggleButtonClick}
            aria-label="Tasks Auto Creation"
          >
            <ToggleButton value={true} aria-label="yes">
              Yes
            </ToggleButton>
            <ToggleButton value={false} aria-label="no">
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      );
    case "ownerIdx":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h6">Owner Index: </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                value={value}
                variant="standard"
                label="Owner Index"
                fullWidth
                onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
              >
                <MenuItem value="not-set">Not Set</MenuItem>
                <MenuItem className={classes.customizeSelect} value={0}>
                  0
                </MenuItem>
                <MenuItem className={classes.customizeSelect} value={1}>
                  1
                </MenuItem>
              </Select>
              <FormHelperText>Select the index of the owner to which this block will refer</FormHelperText>
            </Grid>
          </Grid>
        </Grid>
      );
    case "signaturesRequiredSetting":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Typography variant="h6">Signatures settings:</Typography>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="required-to-sign-settings-label" className={classes.selectLabel}>
              Required to Sign settings
            </InputLabel>
            <Select
              labelId="required-to-sign-settings-label"
              value={value}
              label="Required to Sign settings:"
              fullWidth
              onChange={e => {
                const value = e.target.value;
                changeSelectedItemConfig(fieldAttr, value);
                if (value === "all-owners-require-to-sign") {
                  changeSelectedItemConfig("helperText.en", "All Owners signatures are required");
                  changeSelectedItemConfig("helperText.es", "Se requieren todas las firmas de los solicitantes");
                  return;
                }
                if (value === "one-owner-require-to-sign") {
                  changeSelectedItemConfig("helperText.en", "At least one Owner is required to sign");
                  changeSelectedItemConfig("helperText.es", "Se requiere al menos una Propietaria para firmar");
                  return;
                }
                if (value === "one-owner-or-poc-require-to-sign") {
                  changeSelectedItemConfig("helperText.en", "At least one Owner or a POC is required to sign");
                  changeSelectedItemConfig(
                    "helperText.es",
                    "Se requiere al menos una Propietario o un POC para firmar"
                  );
                  return;
                }
              }}
            >
              <MenuItem value={"all-owners-require-to-sign"}>All Owners</MenuItem>
              <MenuItem value={"one-owner-require-to-sign"} className={classes.customizeSelect}>
                1 Signor - Must be an Owner
              </MenuItem>
              <MenuItem value={"one-owner-or-poc-require-to-sign"} className={classes.customizeSelect}>
                1 Singor - POC or an Owner
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "signaturesSignorsSetting":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="non-signor-setting-label" className={classes.selectLabel}>
              Signors setting
            </InputLabel>
            <Select
              labelId="non-signor-setting-label"
              value={value}
              fullWidth
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem value={"not-set"}>No actions required</MenuItem>
              <MenuItem value={"add-option-to-send-signature-task-for-non-signors"}>
                Add option to send signature task for <b>&nbsp;non-signors</b>
              </MenuItem>
              <MenuItem value={"auto-create-signature-task-for-non-signors"}>
                Automatically create signature task for <b>&nbsp;non-signors</b>
              </MenuItem>
              <MenuItem value={"auto-create-signature-task-for-each-signor"}>
                Automatically create signature task for each <b>&nbsp;potential signor</b>
              </MenuItem>
            </Select>
          </FormControl>
          {/* <hr /> */}
        </Grid>
      );
    case "signaturesRequireAgreementsOfElectronicSignature":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="field-width-label" className={classes.selectLabel}>
              Require Agreements of Electronic Signature?
            </InputLabel>
            <Select
              labelId="field-width-label"
              value={value}
              variant="standard"
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem className={classes.customizeSelect} value={true}>
                Yes
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value={false}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "signaturesRequireAgreementsOfElectronicSignatureMessage":
      return getSettingDefaultLayout("Agreement Message");
    case "requireTermsAndConditionsView":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <FormControl className={classes.formControll} fullWidth>
            <InputLabel id="field-width-label" className={classes.selectLabel}>
              Require T&C View?
            </InputLabel>
            <Select
              labelId="field-width-label"
              value={value}
              variant="standard"
              onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
            >
              <MenuItem className={classes.customizeSelect} value={true}>
                Yes
              </MenuItem>
              <MenuItem className={classes.customizeSelect} value={false}>
                No
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    case "minValue":
      if (config.fieldName === "percOwner") {
        return getSettingDefaultLayout("Min Value From All Owners");
      }
      return getSettingDefaultLayout();

    case "maxValue":
      if (config.fieldName === "percOwner") {
        return getSettingDefaultLayout("Max Value Per Owner");
      }
      return getSettingDefaultLayout();
    case "defaultFileType":
      return (
        <Grid item xs={12} className={classes.customizeContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h6">Default File Type: </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                value={value || ""}
                variant="standard"
                label="Default File Type"
                fullWidth
                onChange={e => changeSelectedItemConfig(fieldAttr, e.target.value)}
              >
                <MenuItem value="">Not Set</MenuItem>
                {ocaUploadFileTypes.map(fileTypeSetting => {
                  return (
                    <MenuItem key={fileTypeSetting.value} value={fileTypeSetting.value}>
                      {fileTypeSetting.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      );
    case "selectedSource":
    case "selectedApiId":
    case "customMarkup":
    case "useDropdown":
      return null;
    default:
      return getSettingDefaultLayout();
  }
};
