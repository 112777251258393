import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Modal from "@material-ui/core/Modal";
import EquipmentDetailModal from "../../components/EquipmentDetailModal";
import EquipmentInputModal from "../../components/EquipmentInputModal";
import Tile from "../../components/Tile/Tile";
import Table from "../../components/Table/Table";

const GET_EQUIPMENT = gql`
  query($id: ID, $VOId: ID) {
    equipments(id: $id, VOId: $VOId) {
      equipmentId
      vendorOpportunityId
      quantity
      newUsed
      year
      make
      model
      serialNumberVin
      additionalDescription
      stockId
      mileage
      assetTypeStr
      classType
      cabType
      term
      lenderProduct
      loanType
    }
  }
`;

export default function EquipmentDescriptionCard(props) {
  const [equipmentDetails, setEquipmentDetails] = useState();
  const [openEquipmentDescriptionModal, setOpenEquipmentDescriptionModal] = useState(false);

  const { data, refetch } = useQuery(GET_EQUIPMENT, {
    variables: {
      VOId: props.vo.vendorOpportunityId,
    },
  });

  useEffect(() => {
    setEquipmentDetails(data);
  }, [data]);

  const handleOpenEquipmentDescriptionModal = () => setOpenEquipmentDescriptionModal(true);

  const handleCloseEquipmentDescriptionModal = () => setOpenEquipmentDescriptionModal(false);

  const [openEquipmentInputModal, setOpenEquipmentInputModal] = useState(false);

  const handleOpenEquipmentInputModal = () => {
    setOpenEquipmentDescriptionModal(false);
    setOpenEquipmentInputModal(true);
  };
  const handleCloseEquipmentInputModal = () => {
    setOpenEquipmentDescriptionModal(true);
    setOpenEquipmentInputModal(false);
  };

  let equipmentTableData = [];

  const showEquipmentTable = !!equipmentDetails && !!equipmentDetails.equipments && equipmentDetails.equipments.length;
  if (showEquipmentTable) {
    equipmentTableData = equipmentDetails.equipments.map(row => [
      `${row.newUsed} ${row.year}`,
      `${row.make} ${row.model}`,
    ]);
  }

  const equipmentTileData = {
    title: "Equipment",
    titleButtonFunc: handleOpenEquipmentDescriptionModal,
    titleButtonText: "Detail",
  };

  return (
    <Tile {...equipmentTileData}>
      {showEquipmentTable ? (
        <Table tableHeaderColor="primary" tableData={equipmentTableData} colorsColls={["primary"]} />
      ) : (
        ""
      )}
      <Modal
        open={openEquipmentDescriptionModal}
        onClose={handleCloseEquipmentDescriptionModal}
        aria-labelledby="Equipment Description Modal"
        aria-describedby="Summary"
      >
        {equipmentDetails ? (
          <div>
            <EquipmentDetailModal
              {...props}
              handleCloseEquipmentDescriptionModal={handleCloseEquipmentDescriptionModal}
              handleOpenEquipmentInputModal={handleOpenEquipmentInputModal}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>
      <Modal
        open={openEquipmentInputModal}
        onClose={handleCloseEquipmentInputModal}
        aria-labelledby="Equipment Input Modal"
        aria-describedby="Input"
      >
        {equipmentDetails ? (
          <div>
            <EquipmentInputModal
              vo={props.vo}
              handleCloseEquipmentInputModal={handleCloseEquipmentInputModal}
              refetch={refetch}
            />
          </div>
        ) : (
          <div />
        )}
      </Modal>
    </Tile>
  );
}
