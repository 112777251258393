import React from "react";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import { formatMoney, parseIntMoneyString } from "../utils";

const CurrencyInput = ({ name, label, value }) => {
  return (
    <Field
      name={name}
      label={label}
      value={value ?? null}
      component={TextField}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      format={v => formatMoney(v)}
      parse={v => parseIntMoneyString(v)}
      fullWidth
    />
  );
};

export default CurrencyInput;
