import gql from "graphql-tag";

const UPDATE_DYNAMICS_INVOICE_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;
const UPDATE_INVOICE_STATUS_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;
const DEACTIVATE_DOC = gql`
  mutation($id: ID!) {
    deactivateDocument(id: $id)
  }
`;
const UPDATE_VO = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

const UPDATE_DYNAMICS_VO = gql`
  mutation($fieldsObj: UpdateVendorOpportunityCRMFields, $entityId: ID!) {
    updateVendorOpportunityCRM(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

export {
  UPDATE_DYNAMICS_INVOICE_STATUS_MUTATION,
  UPDATE_INVOICE_STATUS_MUTATION,
  DEACTIVATE_DOC,
  UPDATE_VO,
  UPDATE_DYNAMICS_VO,
};
