import React from "react";
import Tile from "../../components/Tile/Tile";

export default function Receipt() {
  return (
    <Tile title="Credit Application successfully submitted.">
      <p>
        Instant credit processing will be attempted. Otherwise, processing may take up to 4 business hours.
        <br />
        <br />
        You will receive an email notification once complete.
      </p>
    </Tile>
  );
}
