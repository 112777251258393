import { SelectOption } from "../../../global";

export enum VOStatus {
  Prospect = 100,
  PreQualified = 150,
  Approved = 200,
  Funded = 300,
  Cancelled = 400,
  Declined = 500,
}

export const VOStatusMapValueToString: Record<VOStatus, string> = {
  [VOStatus.Prospect]: "prospect",
  [VOStatus.PreQualified]: "pre_qualified",
  [VOStatus.Approved]: "approved",
  [VOStatus.Funded]: "funded",
  [VOStatus.Cancelled]: "cancelled",
  [VOStatus.Declined]: "declined",
};

export const stageOptions: SelectOption<VOStatus>[] = [
  { value: VOStatus.Prospect, label: "Prospect" },
  { value: VOStatus.PreQualified, label: "Pre-Qualified" },
  { value: VOStatus.Approved, label: "Approved" },
  { value: VOStatus.Funded, label: "Funded" },
  { value: VOStatus.Cancelled, label: "Cancelled" },
  { value: VOStatus.Declined, label: "Declined" },
];

export enum VOStage {
  Lead = 100,
  ApplicationProcessing = 105,
  ApplicationComplete = 107,
  CreditProcessing = 110,
  DocGeneration = 115,
  DocOutstanding = 120,
  FundingReview = 125,
  Booked = 130,
  Declined = 150,
  LostSale = 155,
  Deferred = 160,
  DealerCancelled = 170,
  CustomerCancelled = 175,
}

export const VOStageMapValueToString: Record<VOStage, string> = {
  [VOStage.Lead]: "Lead",
  [VOStage.ApplicationProcessing]: "Application Processing",
  [VOStage.ApplicationComplete]: "Application Complete",
  [VOStage.CreditProcessing]: "Credit Processing",
  [VOStage.DocGeneration]: "Doc Generation",
  [VOStage.DocOutstanding]: "Doc Outstanding",
  [VOStage.FundingReview]: "Funding Review",
  [VOStage.Booked]: "Booked",
  [VOStage.Declined]: "Declined",
  [VOStage.LostSale]: "Lost Sale",
  [VOStage.Deferred]: "Deferred ",
  [VOStage.DealerCancelled]: "Dealer Cancelled",
  [VOStage.CustomerCancelled]: "Customer Cancelled",
};

export const stageCodeOptions: SelectOption<VOStage>[] = [
  { value: VOStage.Lead, label: "Lead" },
  { value: VOStage.ApplicationProcessing, label: "Application Processing" },
  { value: VOStage.ApplicationComplete, label: "Application Complete" },
  { value: VOStage.DealerCancelled, label: "Dealer Cancelled" },
  { value: VOStage.CustomerCancelled, label: "Customer Cancelled" },
];

export const cancelledStageCodeOptions: SelectOption<VOStage>[] = [
  { value: VOStage.DealerCancelled, label: "Dealer Cancelled" },
  { value: VOStage.CustomerCancelled, label: "Customer Cancelled" },
];
