import React, { PropsWithChildren } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

export const ProposalProductsCardsContainer = ({ children }: PropsWithChildren) => {
  const classes = useStyles();

  return <Box className={classes.container}>{children}</Box>;
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    maxHeight: "800px",
    overflowY: "auto",
    flexDirection: "column",
  },
});
