import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import _ from "lodash";
export const PercentField = ({ fieldConfig }) => {
  const [value, setValue] = useState(undefined);

  return (
    <TextField
      fullWidth={fieldConfig.fullWidth}
      disabled={fieldConfig.disabled}
      variant="standard"
      label={_.get(fieldConfig, "displayLabel.en", "")}
      required={fieldConfig.required}
      helperText={_.get(fieldConfig, "errorMessage.en", "")}
      error={false}
      value={value}
      style={{ pointerEvents: "none" }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  );
};
