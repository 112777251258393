import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import Fab from "@material-ui/core/Fab";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import BlockIcon from "@material-ui/icons/Block";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MuiAlert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import { FORM_ERROR } from "final-form";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect, useSelector } from "react-redux";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import {
  setFieldValue,
  validateValue,
  vendorContactRole,
  vendorContactRoleEnumValue,
  vendorContactRoleHumanReadable,
} from "../../utils";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "../../components/CustomButtons/Button";
import { IconButton } from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import PersonalLinkAndQRCode from "../../components/PersonalLinkAndQRCode";
import DealerRelatedLdcOcaLinks from "./DealerRelatedLdcOcaLinks";
import avatar from "../../../src/assets/img/default-avatar.png";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, Modal, Switch } from "@material-ui/core";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { adminRoles, portalConfigurationTypes } from "../Prequal/constants";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import moment from "moment";
import _ from "lodash";

const config = require("../../config.js");

const statusEnum = {
  active: "Active",
  pending_approval: "Staged",
  approved: "Invited",
  blocked: "Blocked",
};

const getModalUpdateStyle = () => {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
};
const getModalInvitationStyle = () => {
  return {
    top: `40%`,
    left: `60%`,
    transform: `translate(-50%, -50%)`,
  };
};

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UPDATE_USER_REGISTRATION_STATUS = gql`
  mutation($input: UpdateUserRegistrationStatusInput!) {
    updateUserRegistrationStatus(input: $input) {
      errorMessage
      message
      status
      success
    }
  }
`;

const Q_SEND_USER_VERIFICATION_CODE = gql`
  query SendUserPhoneVerification {
    sendUserPhoneVerificationCode {
      success
      errorMessage
    }
  }
`;
const Q_VERIFY_USER_PHONE = gql`
  query VerifyUserPhoneCode($code: String!) {
    verifyUserPhoneCode(code: $code) {
      success
      errorMessage
    }
  }
`;

const M_USER_PROFILE = includePicture =>
  `
  mutation($entityId: String!, $fieldsObj: AdminUpdateUserProfileInput! ${includePicture ? ", $file : Upload" : ""}) {
    adminUpdateUserProfile(entityId: $entityId, fieldsObj: $fieldsObj ${includePicture ? ", file: $file" : ""}) {
      id
      mugshot
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation($entityId: String!, $fieldsObj: AdminUpdateUserProfileInput!) {
    adminUpdateUserProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

const Q_CONTACT = gql`
  query($dynamicsId: ID!) {
    contact(dynamicsId: $dynamicsId) {
      accountId
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
      lastLogin
      invitationDatetime
      status
      id
      locationId
      isDemoMode
      mobilePhoneNumber
      mobilePhoneNumberVerified
      mfaSmsEnabled
    }
  }
`;

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;

const GET_ACCOUNTS = gql`
  query($accountsNameFilter: String, $isVPAccountsOnly: Boolean) {
    accounts(accountsNameFilter: $accountsNameFilter, isVPAccountsOnly: $isVPAccountsOnly) {
      id
      dynamicsAccountId
      name
    }
  }
`;

const QUERY_PORTAL_CONFIGURATION = gql`
  query($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

const UserProfile = props => {
  const { userProfile, match, setRefetch, vendorProfile } = props;
  const { data: locationsDataResponse } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });

  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(0, 2),
    },
    resendModal: {
      position: "absolute",
      minWidth: "50%",
      maxWidth: 524,
      display: "flex",
      textAlign: "center",
      "@media (max-width: 660px)": {
        width: "90%",
        minWidth: "0",
      },
      "& .btnContainer": {
        margin: "16px 0",
        "& button": {
          minWidth: 120,
        },
      },
    },
    updateModal: {
      position: "absolute",
      minWidth: "240px",
      width: "50%",
      display: "flex",
      textAlign: "center",
      "@media (max-width: 660px)": {
        width: "90%",
        minWidth: "0",
      },
      "& .btnContainer": {
        margin: "16px 0",
      },
    },
    userStatusACTIVE: {
      margin: "auto",
      padding: "4px 8px",
      color: "#2E7D32",
      backgroundColor: "#DCEDC8",
      width: "fit-content",
      minWidth: 76,
      textAlign: "center",
      borderRadius: 8,
    },
    userStatusBLOCKED: {
      margin: "auto",
      padding: "4px 8px",
      color: "#C62828",
      backgroundColor: "#FFCDD2",
      width: "fit-content",
      minWidth: 76,
      textAlign: "center",
      borderRadius: 8,
    },
    userStatusSTAGED: {
      margin: "auto",
      padding: "4px 8px",
      color: "#424242",
      backgroundColor: "#EEEEEE",
      width: "fit-content",
      minWidth: 76,
      textAlign: "center",
      borderRadius: 8,
    },
    userStatusINVITED: {
      margin: "auto",
      padding: "4px 8px",
      color: "#1565C0",
      backgroundColor: "#BBDEFB",
      width: "fit-content",
      minWidth: 76,
      textAlign: "center",
      borderRadius: 8,
    },
    dataSection: {
      //display: "flex",
      columnGap: 16,
      marginBottom: 16,
    },
    dataField: {
      display: "flex",
      padding: "5px",
      "& label": {
        marginRight: 4,
      },
    },
    contactTileSection: {
      height: "fit-content",
    },
    toolsContainer: {
      display: "flex",

      "& button": {
        background: "none",
        boxShadow: "none",
        color: theme.palette.primary.main,
        fontWeight: "bold",

        "&:hover": {
          background: "none",
          fontWeight: "bold",
          color: theme.palette.primary.main,
        },
      },
    },
    iconBtn: {
      margin: "auto 4px",
      color: theme.palette.grey[500],
      zIndex: "19",
      cursor: "pointer",
      width: "30px",
      height: "30px",
    },
    backbtn: {
      background: "none",
      padding: 0,
      color: theme.palette.grey[500],
      boxShadow: "none",

      "&:hover": {
        background: "none",
        color: "rgba(0,0,0,0.8)",
        boxShadow: "none",
      },
    },
    QRCode: {
      "& .MuiGrid-grid-sm-6.personalLinkAndQRCodeRoot": {
        maxWidth: "100%",
      },
    },
    secondaryBtn: {
      background: "none",
      boxShadow: "none",
      color: theme.palette.primary.main,
      fontWeight: "bold",

      "&:hover": {
        background: "none",
        fontWeight: "bold",
        color: theme.palette.primary.main,
      },
    },
    locationsTreeViewDropdownContainer: {
      marginTop: "23px",
      textAlign: "left",
    },
  }));

  const classes = useStyles();

  const dynamicsContactId = match.params.dynamicsContactId;
  const params = new URLSearchParams(props.location.search);
  const lpId = params.get("lpId");
  const history = useHistory();
  const [mugshotFallback, setMugshotFallback] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isUserProfileOwner, setIsUserProfileOwner] = useState(false);

  const [userManagementReadOnlyRoles, setUserManagementReadOnlyRoles] = useState(null);
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);

  const { data: repDetails, refetch: refetchContact } = useQuery(Q_CONTACT, {
    variables: {
      dynamicsId: dynamicsContactId,
    },
    fetchPolicy: "no-cache",
  });

  const { data: portalConfigurationData, loading: portalConfigurationDataLoading } = useQuery(
    QUERY_PORTAL_CONFIGURATION,
    {
      variables: {
        vendorProfileId: vendorProfile.id,
        types: [portalConfigurationTypes.userManagement],
      },
      fetchPolicy: "no-cache",
    }
  );

  const [updateUserRegistrationStatus] = useMutation(UPDATE_USER_REGISTRATION_STATUS, {
    variables: {
      input: {
        email: userDetails?.email,
        registrationStatusCode: 200,
        sendInvitation: true,
      },
    },
  });

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);

  const [getAccounts, { data: accountsData, loading: accountsDataLoading }] = useLazyQuery(GET_ACCOUNTS, {
    context: { authRequired: true },
  });

  const [sendUserPhoneVerificationCode, { data: sendUserPhoneVerificationCodeResult }] = useLazyQuery(
    Q_SEND_USER_VERIFICATION_CODE,
    {
      context: { authRequired: true },
      fetchPolicy: "no-cache",
    }
  );
  const [verifyUserPhone, { data: verifyUserPhoneResult }] = useLazyQuery(Q_VERIFY_USER_PHONE, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const getAccountsDebounced = _.debounce(getAccounts, 500);

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const account = useSelector(state => state.account);
  const [snackbarUpdateOpen, setSnackbarUpdateOpen] = useState(false);
  const [errorUpdateOpen, setErrorUpdateOpen] = useState(false);
  const [errorUpdateMessage, setErrorUpdateMessage] = useState(false);
  const [snackbarInvitationOpen, setSnackbarInvitationOpen] = useState(false);
  const [errorInvitationOpen, setErrorInvitationOpen] = useState(false);
  const [errorInvitationMessage, setErrorInvitationMessage] = useState(false);
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [mobilePhoneVerificationModalOpen, setMobilePhoneVerificationModalOpen] = useState(false);
  const [modalUpdateStyle] = useState(getModalUpdateStyle);
  const [modalInvitationStyle] = useState(getModalInvitationStyle);
  const [mobilePhoneVerificationStyle] = useState(getModalInvitationStyle);
  const [blocked, setBlocked] = useState(false);
  const [locations, setLocations] = useState(null);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [confirmationCodeSMS, setConfirmationCodeSMS] = useState("");
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    firstName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    lastName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    vendorContactRole: {
      value: "",
      validationStatus: "",
      validations: isDealerUser ? [{ type: "required" }] : [],
    },
    phoneNumber: {
      value: "",
      validationStatus: "",
      format: formatPhone,
      validations: [{ type: "required" }],
    },
    mobilePhoneNumber: {
      value: "",
      format: formatPhone,
    },
    mobilePhoneNumberVerified: {
      value: false,
    },
    mfaSmsEnabled: {
      value: false,
    },
    mugshot: {
      value: "",
    },
    ocaLink: {
      value: "",
    },
    file: {
      value: "",
    },
    status: {
      value: "",
      validationStatus: "",
    },
    invitationDatetime: {
      value: "",
    },
    title: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    lastLogin: {
      value: "",
    },
    location: {
      value: null,
      valueReadable: null,
    },
    isDemoMode: {
      value: false,
    },
  });
  const [formValid, setFormValid] = useState(false);
  const [locationsTree, setLocationsTree] = useState();

  /*   const adminRolesToChangeAccount = [adminRoles.super];
  const adminRolesToEnableDemoMode = [adminRoles.super];
  const adminRolesToChangeUserRole = [adminRoles.super]; */
  const isSuperAdmin = userProfile.adminRole === adminRoles.super;
  const currentAccountOption = _.pick(account, ["id", "dynamicsAccountId", "name"]);
  const [isChangeAccountAutocompleteOpen, setIsChangeAccountAutocompleteOpen] = useState(false);
  const [changeAccountAutocompleteOptions, setChangeAccountAutocompleteOptions] = useState([currentAccountOption]);
  const [accountSelected, setAccountSelected] = useState();

  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: selectedNode => setValue(selectedNode.value, "location"),
    texts: { placeholder: "Default Location" },
  });

  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const getOCALink = () => {
    if (!userDetails.dynamicsContactId) {
      return "";
    }
    return (
      config.REACT_APP_OCA_BASE_URL +
      `/?${lpId ? `lenderProfileId=${lpId}&` : ``}vendorcontactId=${userDetails.dynamicsContactId}`
    );
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (_.isEmpty(repDetails)) {
      return;
    }
    setUserDetails(repDetails.contact);
  }, [repDetails]);

  useEffect(() => {
    if (_.isEmpty(userProfile) || _.isEmpty(userDetails)) {
      return;
    }
    setIsCurrentUser(userProfile.email && userProfile.email === userDetails.email);
  }, [userProfile, userDetails]);

  useEffect(() => {
    if (userDetails) {
      setIsUserProfileOwner(userProfile.id === userDetails.id);

      setFields({
        id: {
          value: userDetails.accountId ? userDetails.accountId : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        firstName: {
          value: userDetails.firstName ? userDetails.firstName : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        lastName: {
          value: userDetails.lastName ? userDetails.lastName : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        email: {
          value: userDetails.email ? userDetails.email : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        vendorContactRole: {
          valueReadable: userDetails.vendorContactRole
            ? vendorContactRoleHumanReadable[userDetails.vendorContactRole]
            : "",
          value: userDetails.vendorContactRole ? vendorContactRoleEnumValue[userDetails.vendorContactRole] : null,
          validationStatus: "",
          validations: isDealerUser ? [{ type: "required" }] : [],
        },
        phoneNumber: {
          value: userDetails.phoneNumber ? formatPhone(userDetails.phoneNumber) : "",
          validationStatus: "",
          format: formatPhone,
          validations: [{ type: "required" }],
        },
        mobilePhoneNumber: {
          format: formatPhone,
          value: userDetails.mobilePhoneNumber ? userDetails.mobilePhoneNumber : "",
        },
        mobilePhoneNumberVerified: {
          value: userDetails.mobilePhoneNumberVerified ? userDetails.mobilePhoneNumberVerified : false,
        },
        mfaSmsEnabled: {
          value: userDetails.mfaSmsEnabled ? userDetails.mfaSmsEnabled : false,
        },

        mugshot: {
          value: userDetails.mugshot ? userDetails.mugshot : avatar,
        },
        ocaLink: {
          value: getOCALink(),
        },
        file: {
          value: "",
        },
        status: {
          value: userDetails.status ? statusEnum[userDetails.status] : "",
          validationStatus: "",
        },
        invitationDatetime: {
          value: userDetails.invitationDatetime ? userDetails.invitationDatetime : "",
        },
        lastLogin: {
          value: userDetails.lastLogin ? userDetails.lastLogin : "",
        },
        title: {
          value: userDetails.title ? userDetails.title : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        location: {
          // will be set later, once locations loaded
          value: null,
          valueReadable: null,
        },
        isDemoMode: {
          value: userDetails.isDemoMode,
        },
      });
      setBlocked(userDetails.status.toLowerCase().includes("blocked"));
    }
  }, [userDetails]);

  useEffect(() => {
    const locations = _.get(locationsDataResponse, "locations");
    if (_.isEmpty(locations) || _.isEmpty(userDetails)) {
      return;
    }
    setLocations(locations);
    const currentLocation = _.find(locations, { locationId: userDetails.locationId });
    setFields(prevValue => ({
      ...prevValue,
      location: {
        value: currentLocation ? currentLocation.locationId : null,
        valueReadable: currentLocation ? currentLocation.locationName : null,
      },
    }));
  }, [locationsDataResponse, userDetails]);

  useEffect(() => {
    const options = _.map(locations, location => {
      return {
        ...location,
        isDefaultValue: location.locationId === _.get(fields, "location.value"),
      };
    });
    setLocationsTree(buildTree(options));
  }, [fields.location]);

  useEffect(() => {
    const accountsToSet = _.get(accountsData, "accounts");
    _.some(accountsToSet)
      ? setChangeAccountAutocompleteOptions(
          accountsToSet /* _.uniqBy([currentAccountOption, ...accountsToSet], "id") */
        )
      : setChangeAccountAutocompleteOptions([currentAccountOption]);
  }, [accountsData]);

  useEffect(() => {
    if (!portalConfigurationData) {
      return;
    }

    const userManagementPortalConfiguration = _.find(portalConfigurationData.portalConfigurations, {
      type: portalConfigurationTypes.userManagement,
    });
    setUserManagementReadOnlyRoles(
      _.get(userManagementPortalConfiguration, "jsonDefinition.config.rolesSettings.readOnly")
    );
  }, [portalConfigurationData]);

  const handleResendInvitation = () => {
    setSendingInvitation(true);
    updateUserRegistrationStatus()
      .then(() => {
        setSnackbarInvitationOpen(true);
        setSendingInvitation(false);
        setOpenInvitationModal(false);
      })
      .catch(() => {
        setErrorInvitationMessage("Invitation not sent. Something went wrong.");
        setErrorInvitationOpen(true);
        setSendingInvitation(false);
        setOpenInvitationModal(false);
      });
  };

  const handleBlockContact = block => {
    setOpenBlockModal(false);
    setBlocked(block);
    if (block) {
      setFields({
        ...fields,
        status: {
          value: "blocked",
          validationStatus: "",
        },
      });
    } else {
      setFields({
        ...fields,
        status: {
          value: "staged",
          validationStatus: "",
        },
      });
    }
    updateUserProfile({
      variables: {
        entityId: dynamicsContactId,
        fieldsObj: {
          id: userDetails.userProfileId,
          firstName: fields.firstName.value,
          lastName: fields.lastName.value,
          fullName: `${fields.firstName.value} ${fields.lastName.value}`,
          mugshot: fields.mugshot.value == avatar ? null : fields.mugshot.value,
          phoneNumber: fields.phoneNumber.value,
          vendorContactRole: fields.vendorContactRole.value,
          title: fields.title.value,
          email: fields.email.value,
          status: block ? "blocked" : "pending_approval",
        },
      },
    });
  };

  const userRolesAllowedToEditOtherUserRole = [
    vendorContactRole.admin,
    vendorContactRole.executive,
    vendorContactRole.credit_mgr,
  ];

  const isRoleSelectVisible =
    isDealerUser &&
    (isSuperAdmin ||
      (!isCurrentUser && _.includes(userRolesAllowedToEditOtherUserRole, userProfile.vendorContactRole)));

  const handleSendVerificationCode = async e => {
    e.preventDefault();
    await verifyUserPhone({ variables: { code: confirmationCodeSMS } });
  };

  useEffect(() => {
    if (verifyUserPhoneResult) {
      const success = _.get(verifyUserPhoneResult, "verifyUserPhoneCode.success");
      if (!success) {
        const errorMessage = _.get(verifyUserPhoneResult, "verifyUserPhoneCode.errorMessage");
        setErrorUpdateMessage(errorMessage);
        setErrorUpdateOpen(true);
        setConfirmationCodeSMS("");
      }
      setMobilePhoneVerificationModalOpen(false);
      refetchContact();
    }
  }, [verifyUserPhoneResult]);

  useEffect(() => {
    if (sendUserPhoneVerificationCodeResult) {
      const success = _.get(sendUserPhoneVerificationCodeResult, "sendUserPhoneVerificationCode.success");
      if (!success) {
        const errorMessage = _.get(sendUserPhoneVerificationCodeResult, "sendUserPhoneVerificationCode.errorMessage");
        setErrorUpdateMessage(errorMessage);
        setErrorUpdateOpen(true);
        setMobilePhoneVerificationModalOpen(false);
      }
    }
  }, [sendUserPhoneVerificationCodeResult]);

  return (
    <>
      {userDetails ? (
        <Form
          onSubmit={values => {
            const formData = new FormData();

            if (fields?.status?.value === "Staged") {
              fields.status.value = "pending_approval";
            } else if (fields?.status?.value === "Invited") {
              fields.status.value = "approved";
            }

            formData.append(
              "operations",
              JSON.stringify({
                query: M_USER_PROFILE(!!fields.file.value),
                variables: {
                  entityId: dynamicsContactId,
                  fieldsObj: {
                    id: userDetails.userProfileId,
                    firstName: fields.firstName.value,
                    lastName: fields.lastName.value,
                    fullName: `${fields.firstName.value} ${fields.lastName.value}`,
                    mugshot: fields.mugshot.value == avatar ? null : fields.mugshot.value,
                    phoneNumber: fields.phoneNumber.value,
                    mobilePhoneNumber: fields.mobilePhoneNumber.value,
                    vendorContactRole: fields.vendorContactRole.value,
                    accountId: _.get(accountSelected, "id") || _.get(currentAccountOption, "id"),
                    title: fields.title.value,
                    status: fields.status.value.toLowerCase(),
                    email: fields.email.value,
                    locationId: fields.location.value,
                    isDemoMode: fields.isDemoMode.value,
                  },
                },
              })
            );
            formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
            if (fields.file.value) {
              formData.append("0", fields.file.value);
            }
            return new Promise(async (resolve, reject) => {
              fetch(config.DCR_GRAPHQL_ENDPOINT, {
                method: "POST",
                headers: {
                  "x-dcr-api-token": (await Auth.currentSession()).getIdToken().getJwtToken(),
                  "x-dcr-api-user-access-token": (await Auth.currentSession()).getAccessToken().getJwtToken(),
                },
                body: formData,
              })
                .then(response => response.json())
                .then(response => {
                  response = response || {};
                  if (response.errors && response.errors.length) {
                    const errors = response.errors.map(e => e.message).join("; ");
                    console.error("Error saving profile:", errors);
                    setErrorUpdateMessage("Error saving profile!");
                    setErrorUpdateOpen(true);
                    refetchContact();
                    resolve();
                  } else {
                    setSnackbarUpdateOpen(true);
                    setOpenUpdateModal(false);
                    if (response && response.data && response.data.updateUserProfile) {
                      values.mugshot = response.data.updateUserProfile.mugshot;
                    }
                    refetchContact();
                    resolve();
                    setRefetch("account", true);
                    if (_.get(currentAccountOption, "id") !== _.get(accountSelected, "id")) {
                      // user's account was updated
                      window.localStorage.removeItem("transactionListSettings");
                      setTimeout(() => window.location.reload(), 1500);
                    }
                  }
                })
                .catch(err => {
                  console.error("Error saving profile:", err);
                  const ret = {};
                  ret[FORM_ERROR] = "Error saving profile!";
                  resolve(ret);
                });
            });
          }}
          validate={checkFormValidation}
          initialValues={!!userDetails}
          render={({ handleSubmit, pristine, valid, submitting, values }) => {
            function uploadButtonHandler(event) {
              const file = document.getElementById("file");
              file.click();
            }
            function uploadFileChanged(event) {
              fields.file.value = event.target.files[0];
              if (fields.file.value.size > 5242880) {
                setErrorUpdateMessage("File exceeds 5MB limit! Please upload a smaller file.");
                setErrorUpdateOpen(true);
                return;
              }
              const reader = new FileReader();
              reader.onload = e => {
                const avatar = document.getElementById("avatar");
                setValue(e.target.result, "mugshot");
                avatar.src = e.target.result;
              };
              reader.readAsDataURL(event.target.files[0]);
              checkFormValidation();
            }
            function handleClose() {
              setSnackbarUpdateOpen(false);
              setErrorUpdateOpen(false);
              setSnackbarInvitationOpen(false);
              setErrorInvitationOpen(false);
            }

            console.log("Fields :: ", fields);

            return (
              <>
                <Tile>
                  <Grid container direction="row" justify="space-between">
                    {!isCurrentUser && (
                      <Button onClick={() => history.goBack()} className={classes.backbtn}>
                        <ArrowBackIcon style={{ marginRight: 4, fontSize: 16 }} />
                        Back
                      </Button>
                    )}
                  </Grid>
                  <Grid container direction="row">
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      alignItems="center"
                      direction="column"
                      className={classes.contactTileSection}
                      style={{ padding: 16 }}
                    >
                      {!mugshotFallback && fields.mugshot.value && (
                        <img
                          id="avatar"
                          alt="User Avatar"
                          style={{
                            width: 236,
                            height: 240,
                            borderRadius: "50%",
                            display: "block",
                            objectFit: "contain",
                          }}
                          src={
                            !fields.mugshot.value
                              ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                              : typeof fields.mugshot.value === "string"
                              ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${fields.mugshot.value}`
                              : fields.mugshot.value
                          }
                          onError={() => setMugshotFallback(true)}
                        />
                      )}
                      {mugshotFallback && (
                        <img
                          id="avatar"
                          src={avatar}
                          alt="..."
                          style={{
                            width: 236,
                            height: 240,
                            borderRadius: "50%",
                            display: "block",
                          }}
                        />
                      )}
                      {fields.firstName.value && fields.lastName.value && (
                        <h3 style={{ marginBottom: 4 }}>{fields.firstName.value + " " + fields.lastName.value}</h3>
                      )}
                      {fields.title.value && <label style={{ marginBottom: 16 }}>{fields.title.value}</label>}
                      {fields.status.value && (
                        <p className={classes[`userStatus${fields.status.value.toUpperCase()}`]}>
                          {fields.status.value.charAt(0).toUpperCase() + fields.status.value.slice(1)}
                        </p>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={5}
                      className={classes.contactTileSection}
                      style={{ padding: "16px 32px" }}
                    >
                      <Grid container direction="column">
                        <h4>Profile Information</h4>
                        <div className={classes.dataSection}>
                          <div className={classes.dataField}>
                            <label>Email:</label>
                            {fields.email.value ? (
                              <a href={"mailto:" + fields.email.value}>{fields.email.value}</a>
                            ) : (
                              <p>----</p>
                            )}
                          </div>
                          <div className={classes.dataField}>
                            <label>Phone:</label>
                            {fields.phoneNumber.value ? (
                              <a href={"tel:+" + fields.phoneNumber.value}>{fields.phoneNumber.value}</a>
                            ) : (
                              <p>----</p>
                            )}
                          </div>
                          <div className={classes.dataField}>
                            <label>Mobile Phone:</label>
                            {fields.mobilePhoneNumber.value ? (
                              <>
                                <a href={"tel:" + fields.mobilePhoneNumber.value}>{fields.mobilePhoneNumber.value}</a>
                                <span>
                                  {fields.mobilePhoneNumberVerified.value ? (
                                    <Fab
                                      variant="extended"
                                      size="small"
                                      disabled={true}
                                      style={{ height: "25px", fontSize: "10px", marginLeft: "1rem", bottom: "2px" }}
                                    >
                                      <VerifiedUserIcon fontSize="small" color="primary" />
                                      Verified
                                    </Fab>
                                  ) : (
                                    <Fab
                                      disabled={!isUserProfileOwner}
                                      variant="extended"
                                      size="small"
                                      onClick={() => {
                                        sendUserPhoneVerificationCode();
                                        setMobilePhoneVerificationModalOpen(true);
                                      }}
                                      style={{ height: "25px", fontSize: "10px", marginLeft: "1rem", bottom: "2px" }}
                                    >
                                      <VerifiedUserIcon fontSize="small" color="error" />
                                      Not Verified
                                    </Fab>
                                  )}
                                </span>
                              </>
                            ) : (
                              <p>----</p>
                            )}
                          </div>
                          <div className={classes.dataField}>
                            <label>Default Location:</label>
                            {fields.location.valueReadable ? <span>{fields.location.valueReadable}</span> : <p>----</p>}
                          </div>
                        </div>
                      </Grid>
                      {isDealerUser && (
                        <Grid container direction="column">
                          <h4>Roles & Permissions</h4>
                          <div className={classes.dataSection}>
                            {fields.vendorContactRole.valueReadable && (
                              <div className={classes.dataField}>
                                <label>Role:</label>
                                <p>{fields.vendorContactRole.valueReadable}</p>
                              </div>
                            )}
                          </div>
                        </Grid>
                      )}
                      <Grid container direction="column">
                        <h4>Status</h4>
                        <div className={classes.dataSection}>
                          <div className={classes.dataField}>
                            <label>Last Login:</label>
                            <p>{fields?.lastLogin?.value ? moment(fields?.lastLogin?.value).format("ll") : "----"}</p>
                          </div>
                          <div className={classes.dataField}>
                            <label>Invitation Sent:</label>
                            <p>
                              {fields?.invitationDatetime?.value
                                ? moment(fields.invitationDatetime.value).format("ll")
                                : "No Invitation Sent"}
                            </p>
                          </div>
                        </div>
                      </Grid>

                      {!portalConfigurationDataLoading &&
                        !lpId &&
                        !_.includes(userManagementReadOnlyRoles, userProfile.vendorContactRole) && (
                          <Grid container direction="column" className={classes.toolsContainer} spacing={3}>
                            <h4>Tools</h4>
                            <GridItem xs={6} sm={6} md={6} lg={6}>
                              <Button
                                aria-label="Edit Profile"
                                color="primary"
                                onClick={() => setOpenUpdateModal(true)}
                              >
                                <EditIcon />
                                Edit Profile
                              </Button>
                            </GridItem>
                            {!isCurrentUser &&
                              (userProfile.vendorContactRole === "credit_mgr" ||
                                userProfile?.vendorContactRole === "executive" ||
                                userProfile?.vendorContactRole === "admin") && (
                                <>
                                  <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <Button
                                      aria-label="Send Invitation"
                                      color="primary"
                                      onClick={() => setOpenInvitationModal(true)}
                                    >
                                      <SendIcon />
                                      Send Invitation
                                    </Button>
                                  </GridItem>
                                  <GridItem xs={6} sm={6} md={6} lg={6}>
                                    <Button
                                      aria-label="Block Profile"
                                      color="primary"
                                      onClick={() => setOpenBlockModal(true)}
                                    >
                                      {!blocked ? <BlockIcon /> : <LockOpenIcon />}
                                      {!blocked ? "Block Profile" : "Unblock Profile"}
                                    </Button>
                                  </GridItem>
                                </>
                              )}
                            {isSuperAdmin && isCurrentUser && (
                              <Grid item>
                                <a
                                  href={
                                    "https://retool" +
                                    (config.environmentName === "dev" ? ".dev" : "") +
                                    ".dcrportal.com/apps/admin-app-account-details?accountId=" +
                                    userDetails.accountId +
                                    "#"
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  ReTool account Settings
                                </a>
                              </Grid>
                            )}
                          </Grid>
                        )}
                    </Grid>
                    <Grid container direction="column" xs={12} sm={12} md={12} lg={4} className={classes.QRCode}>
                      {!_.isEmpty(vendorProfile) && (
                        <PersonalLinkAndQRCode
                          personalLink={fields.ocaLink.value}
                          linkHeader="Personal Credit Application Link"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Snackbar open={snackbarUpdateOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                      Successfully uploaded your profile!
                    </Alert>
                  </Snackbar>
                  <Snackbar open={errorUpdateOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                      {errorUpdateMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar open={snackbarInvitationOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                      Successfully sent the invitation!
                    </Alert>
                  </Snackbar>
                  <Snackbar open={errorInvitationOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                      {errorInvitationMessage}
                    </Alert>
                  </Snackbar>
                </Tile>

                {isDealerUser && (
                  <div style={{ margin: "20px 0" }}>
                    <DealerRelatedLdcOcaLinks userDetails={userDetails} />
                  </div>
                )}

                <Modal
                  open={openUpdateModal}
                  onClose={() => setOpenUpdateModal(false)}
                  aria-describedby="Input"
                  disableEnforceFocus={true}
                >
                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      handleSubmit().then(res => {
                        openUpdateModal(false);
                        console.log(res);
                      });
                    }}
                  >
                    <Grid container style={modalUpdateStyle} className={classes.updateModal}>
                      <Tile
                        title="Edit Profile Information"
                        titleButtonComponent={
                          <IconButton
                            aria-label="delete"
                            className={classes.iconBtn}
                            onClick={() => setOpenUpdateModal(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      >
                        <Grid container alignItems="center" justify="space-evenly">
                          <GridItem xs={12} sm={6} md={6} lg={3}>
                            <InputLabel style={{ marginBottom: 16 }}>User Photo</InputLabel>
                            {!mugshotFallback && fields.mugshot.value && (
                              <img
                                id="avatar"
                                alt="User Avatar"
                                style={
                                  fields.mugshot.value
                                    ? {
                                        marginRight: "32px",
                                        height: "64px",
                                        width: "64px",
                                        borderRadius: "50%",
                                        display: "block",
                                        margin: "0 auto",
                                      }
                                    : {
                                        height: "1px",
                                        width: "1px",
                                        borderRadius: "50%",
                                      }
                                }
                                src={
                                  !fields.mugshot.value
                                    ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                    : typeof fields.mugshot.value === "string"
                                    ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${fields.mugshot.value}`
                                    : fields.mugshot.value
                                }
                                onError={() => setMugshotFallback(true)}
                              />
                            )}
                            {mugshotFallback && (
                              <img
                                id="avatar"
                                src={avatar}
                                alt="..."
                                style={{
                                  marginRight: "32px",
                                  height: "64px",
                                  width: "64px",
                                  borderRadius: "50%",
                                  display: "block",
                                  margin: "0 auto",
                                }}
                              />
                            )}
                          </GridItem>
                          <GridItem xs={6} sm={6} md={6} lg={3}>
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="outlined"
                              style={{ marginTop: "24px" }}
                              onClick={uploadButtonHandler}
                            >
                              Upload&nbsp;
                              <PhotoCameraIcon />
                            </Button>
                          </GridItem>
                          <Grid item container xs={12} sm={6} md={6} lg={6} justifyContent="flex-end">
                            <Grid item>
                              <input
                                accept="image/*"
                                id="file"
                                type="file"
                                style={{ display: "none" }}
                                onChange={uploadFileChanged}
                              />
                            </Grid>
                            {isSuperAdmin && isCurrentUser && (
                              <Grid item>
                                <FormControlLabel
                                  value="Enable Demo Mode"
                                  control={
                                    <Switch
                                      color="primary"
                                      checked={fields.isDemoMode.value}
                                      onChange={() => setValue(!fields.isDemoMode.value, "isDemoMode")}
                                    />
                                  }
                                  label="Enable Demo Mode"
                                  labelPlacement="start"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="First Name"
                              id="firstName"
                              formControlProps={{ fullWidth: true }}
                              disabled
                              error={fields.firstName.validationStatus === "error"}
                              inputProps={{
                                type: "text",
                                name: "firstName",
                                value: fields.firstName.value,
                                onChange: e => setValue(e.target.value, "firstName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="Last Name"
                              id="lastName"
                              formControlProps={{ fullWidth: true }}
                              disabled
                              error={fields.lastName.validationStatus === "error"}
                              inputProps={{
                                type: "text",
                                name: "lastName",
                                value: fields.lastName.value,
                                onChange: e => setValue(e.target.value, "lastName"),
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="Title"
                              id="title"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: "text",
                                name: "title",
                                value: fields.title.value,
                                onChange: e => setValue(e.target.value, "title"),
                                disabled: false,
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="Email"
                              id="email"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: "text",
                                name: "email",
                                value: fields.email.value,
                                onChange: e => setValue(e.target.value, "email"),
                                disabled: true,
                              }}
                            />
                          </GridItem>
                          {vendorProfile.multiLocationManagement && (
                            <GridItem
                              xs={12}
                              sm={6}
                              md={6}
                              lg={6}
                              className={classes.locationsTreeViewDropdownContainer}
                            >
                              <FormControl fullWidth>
                                <LocationsTreeViewDropdown tree={locationsTree} treeSelectOptions={treeSelectOptions} />
                              </FormControl>
                            </GridItem>
                          )}
                        </Grid>
                        <Grid container>
                          {/* <GridItem xs={12} sm={6} md={6} lg={6} style={{ marginTop: ".7%" }}>
                              <FormControl fullWidth>
                                <InputLabel id="status-label">Status</InputLabel>
                                <Select
                                  style={{ marginTop: "10px" }}
                                  id="status"
                                  label="User Status"
                                  variant="standard"
                                  value={fields.status.value}
                                  onChange={e => setValue(e.target.value, "status")}
                                  autoWidth
                                  inputProps={{ "aria-label": "Without label" }}
                                  fullWidth={true}
                                  disabled={fields.status.value === "pending_approval"}
                                >
                                  {fields.status.value === "pending_approval" && (
                                    <MenuItem value={"pending_approval"}>Invited</MenuItem>
                                  )}
                                  <MenuItem value={"approved"}>Active</MenuItem>
                                  <MenuItem value={"blocked"}>Blocked</MenuItem>
                                </Select>
                              </FormControl>
                            </GridItem> */}
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText="Phone Number"
                              id="phoneNumber"
                              formControlProps={{ fullWidth: true }}
                              error={fields.phoneNumber.validationStatus === "error"}
                              disabled
                              inputProps={{
                                type: "text",
                                name: "phoneNumber",
                                value: fields.phoneNumber.value,
                                onChange: e => setValue(e.target.value, "phoneNumber"),
                              }}
                            />
                          </GridItem>
                          {isUserProfileOwner && (
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText="Mobile Phone Number"
                                id="mobilePhoneNumber"
                                formControlProps={{ fullWidth: true }}
                                error={fields.mobilePhoneNumber.validationStatus === "error"}
                                inputProps={{
                                  type: "text",
                                  name: "mobilePhoneNumber",
                                  value: fields.mobilePhoneNumber.value,
                                  onChange: e => setValue(e.target.value, "mobilePhoneNumber"),
                                }}
                              />
                            </GridItem>
                          )}
                          {isRoleSelectVisible && (
                            <GridItem xs={12} sm={6} md={6} lg={6} style={{ marginTop: "18px" }}>
                              <FormControl fullWidth>
                                <InputLabel id="vendor-contact-role-label">Role</InputLabel>
                                <Select
                                  style={{ marginTop: "10px" }}
                                  id="vendorContactRole"
                                  label="Trnsact User Role"
                                  variant="standard"
                                  value={fields.vendorContactRole.value}
                                  onChange={e => setValue(e.target.value, "vendorContactRole")}
                                  autoWidth
                                >
                                  <MenuItem value={100000000}>Sales Rep</MenuItem>
                                  <MenuItem value={100000001}>Sales Manager</MenuItem>
                                  <MenuItem value={100000002}>Admin</MenuItem>
                                  <MenuItem value={100000003}>Executive</MenuItem>
                                  <MenuItem value={100000004}>Credit Manager</MenuItem>
                                  <MenuItem value={100000005}>Support Person</MenuItem>
                                </Select>
                              </FormControl>
                            </GridItem>
                          )}
                          {isSuperAdmin && isCurrentUser && (
                            <GridItem xs={12} sm={6}>
                              <Autocomplete
                                open={isChangeAccountAutocompleteOpen}
                                onOpen={() => setIsChangeAccountAutocompleteOpen(true)}
                                onClose={() => setIsChangeAccountAutocompleteOpen(false)}
                                onInputChange={(event, value, reason) => {
                                  if (!value || value.length < 3 || reason === "reset") {
                                    return;
                                  }
                                  getAccountsDebounced({
                                    variables: { accountsNameFilter: value, isVPAccountsOnly: true },
                                  });
                                }}
                                onChange={(event, value, reason) => setAccountSelected(value)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={option => option.name}
                                options={changeAccountAutocompleteOptions}
                                loading={accountsDataLoading}
                                disableClearable={true}
                                defaultValue={currentAccountOption}
                                style={{ marginTop: "10px" }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Choose Account"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {accountsDataLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </GridItem>
                          )}
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                          <GridItem xs={8} sm={3} md={3} lg={2}>
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="contained"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={submitting || !formValid}
                              style={{ marginTop: 32 }}
                            >
                              {submitting ? "Updating" : "Update"}
                            </Button>
                          </GridItem>
                        </Grid>
                      </Tile>
                    </Grid>
                  </form>
                </Modal>
                <Modal
                  open={openInvitationModal}
                  onClose={() => setOpenInvitationModal(false)}
                  aria-describedby="Input"
                  disableEnforceFocus={true}
                >
                  <div style={modalInvitationStyle} className={classes.resendModal}>
                    <Tile
                      title="Send Invitation"
                      style={modalInvitationStyle}
                      className={classes.resendModal}
                      titleButtonComponent={
                        <IconButton
                          aria-label="delete"
                          className={classes.iconBtn}
                          onClick={() => setOpenInvitationModal(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      {!sendingInvitation ? (
                        <>
                          <p>Would you like to send an invitation?</p>
                          <Grid className="btnContainer" container justify="space-evenly" alignItems="center">
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="contained"
                              type="submit"
                              onClick={() => setOpenInvitationModal(false)}
                            >
                              No
                            </Button>
                            <Button
                              className={classes.secondaryBtn}
                              type="submit"
                              onClick={() => handleResendInvitation()}
                            >
                              Yes
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <div
                            style={{ display: "flex", justifyContent: "center", color: "rgba(0,0,0,0.5)", padding: 32 }}
                          >
                            <SendIcon style={{ marginRight: 8 }} />
                            <p style={{ fontSize: 18 }}>Sending Invitation...</p>
                          </div>
                        </>
                      )}
                    </Tile>
                  </div>
                </Modal>
                <Modal
                  open={openBlockModal}
                  onClose={() => setOpenBlockModal(false)}
                  aria-describedby="Input"
                  disableEnforceFocus={true}
                >
                  <div style={modalInvitationStyle} className={classes.resendModal}>
                    <Tile
                      title="Block Profile"
                      style={modalInvitationStyle}
                      className={classes.resendModal}
                      titleButtonComponent={
                        <IconButton className={classes.iconBtn} onClick={() => setOpenBlockModal(false)}>
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      <p>{`Are you sure you would like to ${!blocked ? "block" : "unblock"} this profile?`}</p>
                      <Grid className="btnContainer" container justify="space-evenly" alignItems="center">
                        <Button
                          className="formBtn"
                          color="primary"
                          variant="contained"
                          type="submit"
                          onClick={() => setOpenBlockModal(false)}
                        >
                          No
                        </Button>
                        <Button
                          className={classes.secondaryBtn}
                          type="submit"
                          onClick={() => {
                            if (blocked) {
                              handleBlockContact(false);
                            } else {
                              handleBlockContact(true);
                            }
                          }}
                        >
                          Yes
                        </Button>
                      </Grid>
                    </Tile>
                  </div>
                </Modal>
                <Modal
                  open={mobilePhoneVerificationModalOpen}
                  onClose={() => setMobilePhoneVerificationModalOpen(false)}
                  aria-describedby="Input"
                  disableEnforceFocus={true}
                >
                  <div style={mobilePhoneVerificationStyle} className={classes.resendModal}>
                    <Tile
                      title="Mobile Phone Verification"
                      style={mobilePhoneVerificationStyle}
                      className={classes.resendModal}
                      titleButtonComponent={
                        <IconButton
                          className={classes.iconBtn}
                          onClick={() => setMobilePhoneVerificationModalOpen(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      }
                    >
                      <p>{`A confirmation code was sent to your phone`}</p>
                      <Grid className="btnContainer" container justify="space-evenly" alignItems="center">
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Confirmation Code"
                            id="confirmationCodeSMS"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "confirmationCodeSMS",
                              value: confirmationCodeSMS,
                              onChange: e => setConfirmationCodeSMS(e.target.value),
                            }}
                          />
                        </GridItem>
                        <Button className={classes.secondaryBtn} type="submit" onClick={handleSendVerificationCode}>
                          Confirm
                        </Button>
                      </Grid>
                    </Tile>
                  </div>
                </Modal>
              </>
            );
          }}
        />
      ) : (
        "Loading userProfile"
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    vendorProfile: state.vp,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
