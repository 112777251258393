import React, { useEffect, useState } from "react";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import ChatIcon from "@material-ui/icons/Chat";
import { useSelector } from "react-redux";
import _ from "lodash";

const useStyles = makeStyles({
  root: {
    float: "left",
    marginRight: "20px",
  },
});

const BadgedChatIcon = () => {
  const classes = useStyles();
  const unread = useSelector(state => state.chat.unread);
  const [badgedContent, setBadgeContent] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(unread)) {
      setBadgeContent(_.sumBy(unread, o => o.count));
    } else {
      setBadgeContent(0);
    }
  }, [unread]);

  return (
    <Badge color="secondary" className={classes.root} badgeContent={badgedContent}>
      <ChatIcon />
    </Badge>
  );
};

export default BadgedChatIcon;
