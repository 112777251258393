import React from "react";
import { Box } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { logError } from "../../../../../utils/logger";
import { PanelForm } from "./PanelForm";
import { PanelFormActions } from "./PanelFormActions";
import { accountSelectors } from "../../../../../redux/accountReducer";
import { CREATE_PROPOSAL_V2, GET_EQUIPMENT, GET_FINANCE_PROGRAMS } from "../../../api";
import { FinanceProgramFormValues, SubmitOptions, UserActionsEvents } from "../../../types";
import { collectDataForCreateProposal, equipmentMileageFormatter, getDefaultValues } from "../../../lib";
import {
  CreateProposalResponse,
  CreateProposalVariables,
  EquipmentsResponse,
  EquipmentsVariables,
  FinanceProgramsResponse,
  FinanceProgramsVariables,
} from "../../../api/types";
import { useNotifications } from "modules/notification";
import { useModal } from "../../../../../hooks/useModal";
import { equipmentDependentFields } from "../../../constants";
import { deskingActions, deskingSelectors } from "../../../model";
import { ModalsKeys } from "../../../../../components/shared/Modals";

export const PanelFormContainer = ({
  onEquipmentCreated,
  onEquipmentUpdated,
  onEquipmentRemoved,
}: Partial<UserActionsEvents>) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.DeskingConfirmSaveDraftDialog);
  const { showNotification } = useNotifications();

  const dispatch = useDispatch();
  const vo = useSelector((state: any) => state.vo);
  const accountId = useSelector(accountSelectors.accountId);
  const menuByTerms = useSelector(deskingSelectors.menuByTerms);
  const creditApplication = useSelector((state: any) => state.creditApp);

  const { data: equipmentData, refetch: refetchEquipmentData } = useQuery<
    EquipmentsResponse,
    Partial<EquipmentsVariables>
  >(GET_EQUIPMENT, {
    skip: !vo.vendorOpportunityId,
    variables: { VOId: vo.vendorOpportunityId },
    onCompleted(response) {
      if (!Array.isArray(response?.equipments)) return;
      dispatch(deskingActions.setEquipmentData(response.equipments));
    },
  });

  const [getEquipmentDataById] = useLazyQuery<EquipmentsResponse, EquipmentsVariables>(GET_EQUIPMENT, {
    onCompleted(response) {
      const equipment = response?.equipments?.[0];

      form.setValue("equipment.selectedEquipment", equipment?.equipmentId);
      form.setValue(`equipment.mileage`, equipmentMileageFormatter(equipment?.mileage, "toView"));

      equipmentDependentFields.forEach(field => form.setValue(`equipment.${field}`, equipment?.[field] ?? ""));

      dispatch(deskingActions.setIsNeedToAutoFillProductsDynamicFields());
      dispatch(deskingActions.setCurrentEquipmentData(equipment?.equipmentId));
    },
  });

  useQuery<FinanceProgramsResponse, FinanceProgramsVariables>(GET_FINANCE_PROGRAMS, {
    skip: !accountId,
    variables: { accountId },
    onCompleted(response) {
      if (!Array.isArray(response?.financePrograms)) return;
      dispatch(deskingActions.setFinancePrograms(response.financePrograms));
    },
  });

  const [onCreateProposal, { loading: isProposalLoading }] = useMutation<
    CreateProposalResponse,
    CreateProposalVariables
  >(CREATE_PROPOSAL_V2);

  const form = useForm<FinanceProgramFormValues>({
    defaultValues: getDefaultValues(vo, creditApplication, equipmentData),
  });

  const handleCreateProposal = async (variables: any) => {
    try {
      const { data } = await onCreateProposal({ variables });

      if (data?.createProposalV2?.id) {
        showNotification("Proposal created!");
        dispatch(deskingActions.setProposalCreated(true));
      } else {
        showNotification(
          "Proposal not created, Buy Rate, Amount Financed, and/or Term are missing, please check and try again!",
          {
            type: "warning",
            duration: 5000,
          }
        );
      }
    } catch (error) {
      logError(error);
    } finally {
      dispatch(deskingActions.setProposalCreated(false));
    }
  };

  const handleSubmitForm = async ({ isDraft }: SubmitOptions) => {
    const formData = form.getValues();
    const paymentOptions = collectDataForCreateProposal(formData, menuByTerms);

    const variables = {
      input: {
        paymentOptions,
        sendProposal: !isDraft,
        vendorOpportunityId: vo.vendorOpportunityId,
        financeProgramId: formData.financeQuote?.program,
      },
    };

    if (isDraft) handleOpen({ onSaveAsDraft: () => handleCreateProposal(variables) });
    else await handleCreateProposal(variables);
  };

  return (
    <Box className={classes.formContainer}>
      <FormProvider {...form}>
        <PanelForm
          onEquipmentCreated={async equipmentId => {
            await refetchEquipmentData();
            if (onEquipmentCreated) await onEquipmentCreated(equipmentId);

            getEquipmentDataById({ variables: { VOId: vo.vendorOpportunityId, id: equipmentId } });
          }}
          onEquipmentUpdated={async equipmentId => {
            await refetchEquipmentData();
            if (onEquipmentUpdated) await onEquipmentUpdated(equipmentId);

            dispatch(deskingActions.setIsNeedToAutoFillProductsDynamicFields());
          }}
          onEquipmentRemoved={async equipmentId => {
            await refetchEquipmentData();
            if (onEquipmentRemoved) await onEquipmentRemoved(equipmentId);
          }}
        />
      </FormProvider>

      <PanelFormActions onSubmitForm={handleSubmitForm} isProposalLoading={isProposalLoading} />
    </Box>
  );
};

const useStyles = makeStyles(({ palette: { primary } }) => ({
  formContainer: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",

    "& .productName": {
      textTransform: "capitalize",
    },

    "& .row": {
      gap: "8px",
      display: "flex",

      "& > *": {
        flex: 1,
      },
    },

    "& .section": {
      gap: "6px",
      display: "flex",
      flexDirection: "column",

      "& .actions": {
        gap: "2px",
        display: "flex",
        alignItems: "center",
      },

      "& .sectionHeader": {
        display: "flex",
        minHeight: "56px",
        borderRadius: "2px",
        position: "relative",
        padding: "13px 16px",
        alignItems: "center",
        backgroundColor: blue["50"],
        justifyContent: "space-between",

        "& .collapse": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .actionPanel": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .MuiButton-contained": {
          borderRadius: "var(--borderRadius, 4px)",
          background: "var(--common-white_states-main, #FFF)",
          boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",

          "& > span.MuiButton-label": {
            color: primary.main,
          },

          "&.Mui-disabled": {
            boxShadow: "none",

            "& > span.MuiButton-label": {
              color: primary.light,
            },
          },
        },
      },

      "& .sectionFields": {
        gap: "8px",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
}));
