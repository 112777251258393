import { UPDATE_ACTIVE_STATUS } from "./actionTypes";

const updateActiveStatus = (payload: boolean) => ({
  type: UPDATE_ACTIVE_STATUS,
  payload,
});

export const prescreenActions = {
  updateActiveStatus,
};
