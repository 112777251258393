import React, { useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import MessageModal from "../../components/MessageModal";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { connect } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  paper: {
    position: "absolute",
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    minWidth: 600,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 600,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0",
  },
}));

const CREATE_NEW_CREDIT_PROGRAM = gql`
  mutation($fieldsObj: CreateCreditProgramInput!) {
    createCreditProgram(fieldsObj: $fieldsObj) {
      id
      success
      errorMessage
    }
  }
`;

const useUiStyles = makeStyles(styles);

function ResidualNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={"##"}
    />
  );
}

function RateNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={".#####"}
      // mask={'.'}
    />
  );
}

ResidualNumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

RateNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const AddCreditProgram = ({ account, vp, userProfile, open, handleClose, refetchCreditPrograms }) => {
  const classes = useStyles();
  const uiClasses = useUiStyles();
  const [email, setEmail] = useState("");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [emailError, setEmailError] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("Required");
  const [lenderName, setLenderName] = useState("");
  const [lenderContactName, setLenderContactName] = useState("");
  const [lenderNameError, setLenderNameError] = useState("");
  const [lenderContactNameError, setLenderContactNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [term, setTerm] = useState("");
  const [rate, setRate] = useState("");
  const [rateFactor, setRateFactor] = useState("");
  const [riskTier, setRiskTier] = useState("A");
  const [amountMin, setAmountMin] = useState("");
  const [amountMax, setAmountMax] = useState("");
  const [docFee, setDocFee] = useState("");
  const [advancePayment, setAdvancePayment] = useState("");
  const [markupDealer, setMarkupDealer] = useState("");
  const [markupInternal, setMarkupInternal] = useState("");
  const [lender, setLender] = useState("");
  const [productHeader, setProductHeader] = useState("");
  const [accountId, setAccountId] = useState("");
  const [contractType, setContractType] = useState("");
  const [residualRate, setResidualRate] = useState("");
  const [manufacturers, setManufacturers] = useState("");
  const [makes, setMakes] = useState("");
  const [modelYears, setModelYears] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [additionalTerms, setAdditionalTerms] = useState("");
  const [pdfFlyer, setPdfFlyers] = useState("");

  const handleChangeField = (setField, event, format) => {
    if (format === "num") {
      const value = event.target.value;
      const regTest = /^\d+(\.\d{1,2})?$/;
      if (value === "" || regTest.test(value)) {
        setField(event.target.value);
      }
    } else {
      setField(event.target.value);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    sendCreditProgram();
  };
  const handleCloseModal = () => {
    setOpenMessageModal(false);
  };
  const handleOpenModal = () => {
    setOpenMessageModal(true);
  };
  const handleSubmissionMessage = message => {
    setSubmissionMessage(message);
  };
  const [createCreditProgram] = useMutation(CREATE_NEW_CREDIT_PROGRAM, {
    context: { authRequired: true },
  });

  const sendCreditProgram = async () => {
    try {
      await handleOpenModal();
      await handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Creating your credit program...",
      });

      await createCreditProgram({
        variables: {
          fieldsObj: {
            productName: productName,
            productHeader: productHeader,
            term: parseInt(term),
            rate: parseFloat(rate) / 1000000,
            rateFactor: parseFloat(rateFactor),
            riskTier: riskTier ? riskTier : "A",
            amountMin: parseFloat(amountMin),
            amountMax: parseFloat(amountMax),
            docFee: parseFloat(docFee),
            downPaymentAmount: parseFloat(advancePayment),
            lenderName: lender,
            accountId: account.id,
            source: "dealer",
            contractType: contractType,
            residual: residualRate ? parseFloat(residualRate) : null,
            validFrom: validFrom ? new Date(validFrom) : null,
            validTo: validTo ? new Date(validTo) : null,
            additionalTerms: additionalTerms,
            createdBy: userProfile.fullName,
          },
        },
      });
      await handleSubmissionMessage({
        title: "Success",
        message: "Successfully Created New Credit Program.",
      });
      await refetchCreditPrograms();
    } catch (err) {
      console.log(err);
      await handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const handleResidualChange = event => {
    setResidualRate(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={"md"} scroll={"body"}>
      <Grid style={{ border: "solid", padding: "20px" }} container>
        <Grid item xs={12}>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Paper>
            <CardHeader title="Add New Credit Program" />
            <Divider />
            <CardContent style={{ padding: 0 }}>
              <Grid container>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={lenderContactNameError === true}
                      label="Internal Product Name"
                      value={productName}
                      onChange={e => handleChangeField(setProductName, e)}
                      type="text"
                      required={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Product Name for customer"
                      value={productHeader}
                      onChange={e => handleChangeField(setProductHeader, e)}
                      type="text"
                      required={true}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <InputLabel
                      style={{
                        color: "#AAAAAA !important",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "1.42857",
                        letterSpacing: "unset",
                        "& + $underline": {
                          marginTop: "0px",
                        },
                      }}
                      id="contract-type-label"
                    >
                      Contract Type
                    </InputLabel>
                    <Select
                      id="contract-type-select"
                      label="Contract Type"
                      name="contractType"
                      variant="standard"
                      autoWidth
                      required
                      inputProps={{
                        type: "text",
                        name: "contractType",
                        value: contractType,
                        onChange: e => {
                          handleChangeField(setContractType, e);
                        },
                      }}
                    >
                      <MenuItem value={"trac"}>Trac</MenuItem>
                      <MenuItem value={"lease"}>Lease</MenuItem>
                      <MenuItem value={"loan"}>Loan</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {(contractType === "trac" || contractType === "lease") && (
                  <Grid item xs={12} md={3} lg={3}>
                    <FormControl className={classes.formControlLenders}>
                      <TextField
                        label="Residual Rate"
                        value={residualRate}
                        onChange={e => handleResidualChange(e)}
                        name="residualRate"
                        id="residual-rate"
                        InputProps={{
                          inputComponent: ResidualNumberFormatCustom,
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl className={classes.formControlLenders}>
                    <InputLabel
                      style={{
                        color: "#AAAAAA !important",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "1.42857",
                        letterSpacing: "unset",
                        "& + $underline": {
                          marginTop: "0px",
                        },
                      }}
                      id="term-length-label"
                    >
                      Term Length
                    </InputLabel>
                    <Select
                      id="term-length-select"
                      label="Term Length"
                      name="term"
                      variant="standard"
                      autoWidth
                      required
                      inputProps={{
                        type: "text",
                        name: "term",
                        value: term,
                        onChange: e => {
                          handleChangeField(setTerm, e);
                        },
                      }}
                    >
                      <MenuItem value={12}>12 Months</MenuItem>
                      <MenuItem value={24}>24 Months</MenuItem>
                      <MenuItem value={36}>36 Months</MenuItem>
                      <MenuItem value={48}>48 Months</MenuItem>
                      <MenuItem value={60}>60 Months</MenuItem>
                      <MenuItem value={72}>72 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Interest Rate"
                      value={rate}
                      onChange={e => handleChangeField(setRate, e, "num")}
                      required={true}
                      InputProps={{
                        inputComponent: RateNumberFormat,
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Risk Tier"
                      value={riskTier}
                      onChange={e => handleChangeField(setRiskTier, e)}
                      type="text"
                      required={true}
                      disabled
                      variant="filled"
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Amount Min"
                      value={amountMin}
                      onChange={e => handleChangeField(setAmountMin, e, "num")}
                      type="text"
                      required={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Amount Max"
                      value={amountMax}
                      onChange={e => handleChangeField(setAmountMax, e, "num")}
                      type="text"
                      required={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Doc Fees"
                      value={docFee}
                      onChange={e => handleChangeField(setDocFee, e, "num")}
                      type="text"
                      required={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Due in Advance (Down Payment)"
                      value={advancePayment}
                      onChange={e => handleChangeField(setAdvancePayment, e, "num")}
                      type="text"
                      required={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Lender(if applicable)"
                      value={lender}
                      onChange={e => handleChangeField(setLender, e)}
                      type="text"
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Valid From"
                      value={validFrom}
                      onChange={e => handleChangeField(setValidFrom, e)}
                      InputLabelProps={{ shrink: true, required: true }}
                      type="date"
                      required={true}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Valid To"
                      value={validTo}
                      onChange={e => handleChangeField(setValidTo, e)}
                      InputLabelProps={{ shrink: true, required: true }}
                      type="date"
                      required={true}
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl className={classes.formControlLenders}>
                    <TextField
                      // error={emailError === true}
                      label="Additonal Terms & Conditions (if Applicable)"
                      value={additionalTerms}
                      onChange={e => handleChangeField(setAdditionalTerms, e)}
                      type="text"
                      required={true}
                      multiline
                      rows={4}
                      variant="outlined"
                      // helperText={emailErrorMessage}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <Button color="primary" className={classes.submitButton} onClick={handleSubmit} type="submit">
                    Create Credit Program
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Grid>
        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseModal}
          title={submissionMessage.title}
          message={submissionMessage.message}
        />
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  account: state.account,
  vp: state.vp,
  userProfile: state.userProfile,
});

export default connect(
  mapStateToProps,
  null
)(AddCreditProgram);
