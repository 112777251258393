import React, { PropsWithChildren, ReactNode, useEffect, useMemo } from "react";
import clsx from "clsx";
import { useDrag } from "react-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Controller, useForm, useWatch } from "react-hook-form";
import { blue, blueGrey, grey } from "@material-ui/core/colors";
import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { Box, IconButton, Link, makeStyles, Tooltip, Typography, LinearProgress } from "@material-ui/core";
import { parseCurrency } from "../../../../../../utils";
import { useModal } from "../../../../../../hooks/useModal";
import { markupProposalOptions } from "../../../../constants";
import { ProductCatalogDetails } from "../../catalogDetails/ProductCatalogDetails";
import { proposalProductUpdateDependentFields } from "../../../../lib";
import { ModalsKeys } from "../../../../../../components/shared/Modals";
import { ProposalProductsCardTypeChip } from "../../ProposalProductsCardTypeChip";
import { CommonMenuPriceValues, DnDTypes, ProposalProductCardModes } from "../../../../types";
import { CurrencyInputField, FormRadioGroup, InterestInputField } from "../../../../../../components/form";

interface Props {
  logo?: string;
  menuName?: string;
  type: "simple" | "forEdit";
  mode: ProposalProductCardModes;
  proposalProduct: ProposalProduct;
  isDynamicPricingLoading?: boolean;
  isPricingEditable?: boolean;
  configuration?: Record<string, any>;
  forcePriceValue: CommonMenuPriceValues;
  updateProductConfiguration?: (productId: string, menuName: string, field: string, value: any) => void;
}

export const ProposalCardContainer = ({
  logo,
  type,
  children,
  menuName = "",
  proposalProduct,
  forcePriceValue,
  configuration = {},
  updateProductConfiguration,
  isDynamicPricingLoading = false,
  isPricingEditable = true,
  mode,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.DeskingProductCatalogDialog);

  const { control, setValue } = useForm<CommonMenuPriceValues>({
    defaultValues: {
      cost: proposalProduct?.cost,
      retailCost: proposalProduct?.retailCost,
      markup: { markup: proposalProduct.markup?.markup, type: MarkupType.Flat },
    },
  });

  const price = useWatch<CommonMenuPriceValues>({ control });

  useEffect(() => {
    if (!forcePriceValue) return;

    setValue("cost", forcePriceValue?.cost ?? 0);
    setValue("retailCost", forcePriceValue?.retailCost ?? 0);
    setValue("markup.markup", forcePriceValue?.markup?.markup ?? 0);
    setValue("markup.type", forcePriceValue?.markup?.type ?? MarkupType.Flat);
  }, [forcePriceValue]);

  const productWithConfiguration = useMemo(() => {
    return {
      ...proposalProduct,
      configuration,
      cost: Number(price.cost),
      retailCost: Number(price.retailCost),
      markup: { type: price.markup?.type, markup: Number(price.markup?.markup) },
    };
  }, [proposalProduct, price, configuration]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.ProposalProduct,
      item: productWithConfiguration,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [productWithConfiguration]
  );

  const handleChangeCost = (nextCost: unknown, forceMarkupType?: MarkupType) => {
    const cost = price.cost!;
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;
    const markupType = forceMarkupType ?? price.markup?.type!;

    const { markupNextValue, costNextValue, retailCostNextValue } = proposalProductUpdateDependentFields(
      "cost",
      Number(nextCost ?? 0),
      proposalProduct,
      {
        cost,
        markup,
        markupType,
        retailCost,
      }
    );

    setValue("retailCost", retailCostNextValue);
    setValue("cost", costNextValue);
    setValue("markup.markup", markupNextValue);

    if (type === "forEdit" && updateProductConfiguration) {
      updateProductConfiguration(proposalProduct.proposalProductId, menuName, "cost", Number(nextCost));
      updateProductConfiguration(proposalProduct.proposalProductId, menuName, "retailCost", +retailCostNextValue);
    }
  };

  const handleChangeMarkup = (nextMarkup: number, forceMarkupType?: MarkupType) => {
    const cost = Number(price.cost!);
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;
    const markupType = forceMarkupType ?? price.markup?.type!;

    const { retailCostNextValue, costNextValue, markupNextValue } = proposalProductUpdateDependentFields(
      "markup.markup",
      nextMarkup,
      proposalProduct,
      {
        cost,
        markup,
        markupType,
        retailCost,
      }
    );

    setValue("retailCost", retailCostNextValue);
    setValue("cost", costNextValue);
    setValue("markup.markup", markupNextValue);

    if (type === "forEdit" && updateProductConfiguration) {
      updateProductConfiguration(proposalProduct.proposalProductId, menuName, "markup", {
        markup: +nextMarkup,
        type: markupType,
      });
      updateProductConfiguration(proposalProduct.proposalProductId, menuName, "retailCost", +retailCostNextValue);
    }
  };

  const handleChangeRetailCost = (nextRetailCost: unknown, forceMarkupType?: MarkupType) => {
    const cost = Number(price.cost!);
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;

    const markupType = forceMarkupType ?? price.markup?.type!;

    const { markupNextValue, costNextValue, retailCostNextValue } = proposalProductUpdateDependentFields(
      "retailCost",
      Number(nextRetailCost ?? 0),
      proposalProduct,
      {
        cost,
        markup,
        markupType,
        retailCost,
      }
    );

    setValue("retailCost", retailCostNextValue);
    setValue("cost", costNextValue);
    setValue("markup.markup", markupNextValue);

    if (type === "forEdit" && updateProductConfiguration) {
      updateProductConfiguration(proposalProduct.proposalProductId, menuName, "markup", {
        markup: +markupNextValue,
        type: markupType,
      });
      updateProductConfiguration(proposalProduct.proposalProductId, menuName, "retailCost", Number(nextRetailCost));
    }
  };

  const handleChangeMarkupType = (nextType: MarkupType) => {
    const markup = price.markup?.markup!;

    setValue("markup.type", nextType);

    handleChangeMarkup(markup, nextType);
  };

  const markupField: Record<MarkupType, ReactNode> = {
    [MarkupType.Flat]: (
      <CurrencyInputField
        label="Markup ($)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(Number(parseCurrency(event.target.value))),
        }}
      />
    ),
    [MarkupType.Percentage]: (
      <InterestInputField
        label="Markup (%)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(parseFloat(event.target.value)),
        }}
      />
    ),
  };

  return (
    <Box
      className={clsx(classes.card, {
        [classes.draggingCard]: isDragging,
        [classes.editCard]: type === "forEdit",
      })}
    >
      <Box className={classes.titleAndLogo} {...({ ref: drag } as any)}>
        <Box className={classes.titleContainer}>
          <Box className="title">
            <IconButton className="dndIcon">
              <DragHandleIcon style={{ color: "black" }} />
            </IconButton>

            {logo && <img className={classes.productLogo} src={logo} alt="Proposal product logo" />}

            <Box className="titleWithVendorName">
              <Typography className="productName" variant="h6">
                {proposalProduct.title}
              </Typography>

              {proposalProduct.aftermarketProduct?.productCategory && (
                <ProposalProductsCardTypeChip productCategory={proposalProduct.aftermarketProduct.productCategory} />
              )}

              {proposalProduct.aftermarketProduct?.aftermarketVendorApiChannel && (
                <Typography className="productName" variant="body2">
                  {proposalProduct.aftermarketProduct?.aftermarketVendorApiChannel}
                </Typography>
              )}
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Tooltip title="Click to see product details">
              <IconButton
                onClick={() => {
                  handleOpen({ proposalProduct });
                }}
              >
                <InfoOutlined color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>

      <ProductCatalogDetails product={proposalProduct} />

      {proposalProduct?.thirdPartyUrl && (
        <Typography>
          <Link href={proposalProduct.thirdPartyUrl} target="_blank">
            {proposalProduct.thirdPartyUrl}
          </Link>
        </Typography>
      )}

      {children}

      {isDynamicPricingLoading && <LinearProgress style={{ width: "100%" }} />}

      {!isDynamicPricingLoading && (
        <Box className={classes.price}>
          <CurrencyInputField
            name="cost"
            label="Cost"
            control={control}
            textFieldProps={{
              onChange: event => handleChangeCost(parseCurrency(event.target.value)),
              disabled: mode === ProposalProductCardModes.Constructor || isPricingEditable === false,
            }}
          />

          {isPricingEditable && (
            <Box>
              {markupField[price.markup?.type!]}

              <Controller
                control={control}
                name="markup.type"
                render={({ field }) => (
                  <FormRadioGroup
                    row
                    options={markupProposalOptions}
                    {...field}
                    onChange={(event, value) => handleChangeMarkupType(value as MarkupType)}
                  />
                )}
              />
            </Box>
          )}

          {isPricingEditable && (
            <CurrencyInputField
              control={control}
              name="retailCost"
              label="Retail Price"
              textFieldProps={{
                onChange: event => handleChangeRetailCost(parseCurrency(event.target.value)),
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: blueGrey["50"],
  },
  editCard: {
    backgroundColor: blue["50"],
  },
  draggingCard: {
    backgroundColor: grey["50"],
  },
  titleAndLogo: {
    display: "flex",
    flexDirection: "column",

    "&:hover": {
      cursor: "grab",
    },
  },
  titleContainer: {
    gap: "6px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",

    "& .title": {
      gap: "6px",
      display: "flex",
      alignItems: "flex-start",

      "& .titleWithVendorName": {
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  price: {
    gap: "4px",
    display: "flex",

    "& > *": {
      flex: 1,
    },
  },
  productLogo: {
    width: "36px",
    height: "36px",
  },
});
