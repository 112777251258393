import {
  SET_LOADING_ACCOUNT,
  SET_LOADING_ACCOUNT_REP,
  SET_LOADING_COMPANY_PROFILE,
  SET_LOADING_CREDIT_APP,
  SET_LOADING_CREDIT_SUBMISSIONS,
  SET_LOADING_PAST_SUBMISSIONS,
  SET_LOADING_USER_PROFILE,
  SET_LOADING_VO,
  SET_LOADING_VP,
} from "./types";

const initialState = {
  account: false,
  accountRep: false,
  userProfile: false,
  companyProfile: false,
  vp: false,
  vo: false,
  creditApp: false,
  creditSubmissions: false,
};

export const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_ACCOUNT:
      return { ...state, account: action.payload };
    case SET_LOADING_ACCOUNT_REP:
      return { ...state, accountRep: action.payload };
    case SET_LOADING_USER_PROFILE:
      return { ...state, userProfile: action.payload };
    case SET_LOADING_COMPANY_PROFILE:
      return { ...state, companyProfile: action.payload };
    case SET_LOADING_VO:
      return { ...state, vo: action.payload };
    case SET_LOADING_VP:
      return { ...state, vp: action.payload };
    case SET_LOADING_CREDIT_APP:
      return { ...state, creditApp: action.payload };
    case SET_LOADING_PAST_SUBMISSIONS:
      return { ...state, creditApp: action.payload };
    case SET_LOADING_CREDIT_SUBMISSIONS:
      return { ...state, creditSubmissions: action.payload };
    default:
      return state;
  }
};
