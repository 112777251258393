import { UPDATE_ACTIVE_STATUS } from "./actionTypes";

const initialState = {
  isActive: false,
};

export const prescreenReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case UPDATE_ACTIVE_STATUS:
      return { ...state, isActive: payload };

    default:
      return state;
  }
};
