import React from "react";
import { DialogContent, DialogTitle, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContactFormValues, ContactType } from "../../types";
import { MainInformationForm } from "./FormLayout/MainInformationForm";
import { AdditionalInformationForm } from "./FormLayout/AdditionalInformationForm";

interface Props {
  contactFormValues: ContactFormValues;
  contactType: ContactType;
  setContactFormValues: any;
  setContactType: any;
  isLoading: boolean;
}

export const GuaranteeLayout = ({
  contactFormValues,
  contactType,
  setContactFormValues,
  setContactType,
  isLoading,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <DialogTitle>
        Make Guarantor
        <Divider style={{ marginTop: 10 }} />
      </DialogTitle>
      {!isLoading && (
        <DialogContent>
          <Grid container spacing={2} className={classes.mainInformationContainer}>
            <MainInformationForm
              contactFormValues={contactFormValues}
              contactType={contactType}
              setContactFormValues={setContactFormValues}
              setContactType={setContactType}
              hideContactType={true}
            />

            <AdditionalInformationForm
              contactFormValues={contactFormValues}
              setContactFormValues={setContactFormValues}
            />
          </Grid>
        </DialogContent>
      )}
    </>
  );
};

const useStyles = makeStyles({
  mainInformationContainer: {
    backgroundColor: "#f5f5f5",
    padding: 15,
    borderRadius: 6,
  },
});
