import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  CalculatePaymentProductAddonInput,
  CalculatePaymentProductInput,
} from "@trnsact/trnsact-shared-types/dist/generated";
import { GaigProgramCode } from "@trnsact/aftermarket-vendor-sdk-gaig";
import "./AftermarketGaigProductComponent.css";
import GAIG_LOGO from "../../../assets/img/aftermarket-vendor-logos/gaig-logo.png";
import DraggableComponent from "components/DragAndDrop/DraggableComponent";

interface AftermarketGaigProductComponentProps {
  jsonData: {
    API_RESPONSE_DATA: GaigProgramCode;
  };
}

const AftermarketGaigProductComponent: React.FC<AftermarketGaigProductComponentProps> = ({ jsonData }) => {
  const [data, setData] = useState(jsonData);
  const [billingFrequency, setBillingFrequency] = useState<string | null>(null);
  const [contractTerm, setContractTerm] = useState<number | null>(null);
  const [coverageTerm, setCoverageTerm] = useState<number | null>(null);

  const handleCheckboxChange = (assetIndex: number, isSelected: boolean) => {
    const updatedData = _.cloneDeep(data);

    // Add `isSelected` property to the asset
    // @ts-ignore hack
    updatedData.API_RESPONSE_DATA.availableAssets[assetIndex].isSelected = isSelected;

    setData(updatedData);
  };

  const mapDataToDraggableComponent = () => {
    const newMappedData: CalculatePaymentProductInput & { apiData: any } = {
      proposalProductId: "",
      retailCost: data.API_RESPONSE_DATA.InsuredValueMaximum,
      title: "Assets",
      cost: data.API_RESPONSE_DATA.InsuredValueMaximum,
      addons: data.API_RESPONSE_DATA.availableAssets.map(asset => {
        return {
          proposalProductAddonId: asset.code,
          retailCost: data.API_RESPONSE_DATA.InsuredValueMaximum,
          cost: data.API_RESPONSE_DATA.InsuredValueMaximum,
          title: asset.displayName,
        };
      }),
      apiData: data,
      // proposalPackageId: data.API_RESPONSE_DATA.ProgramCode,
    };

    // You would now set this state to be used in DraggableComponent or similar
  };

  return (
    <div className="draggable-container">
      <div className="header">
        <img className="logo" src={GAIG_LOGO} alt="GAIG Logo" />
        <div className="description">{data.API_RESPONSE_DATA.ProgramDescriptionExternal}</div>
      </div>

      <div className="select-container">
        <div className="select-group">
          <label>Billing Frequency:</label>
          <select value={billingFrequency || ""} onChange={e => setBillingFrequency(e.target.value)}>
            <option value="" disabled>
              Select Billing Frequency
            </option>
            {data.API_RESPONSE_DATA.BillingFrequencyOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="select-group">
          <label>Contract Term:</label>
          <select value={contractTerm || ""} onChange={e => setContractTerm(Number(e.target.value))}>
            <option value="" disabled>
              Select Contract Term
            </option>
            {Array.from(
              { length: data.API_RESPONSE_DATA.ContractTermMax - data.API_RESPONSE_DATA.ContractTermMin + 1 },
              (_, i) => data.API_RESPONSE_DATA.ContractTermMin + i
            ).map((term, index) => (
              <option key={index} value={term}>
                {term} Months
              </option>
            ))}
          </select>
        </div>

        <div className="select-group">
          <label>Coverage Term:</label>
          <select value={coverageTerm || ""} onChange={e => setCoverageTerm(Number(e.target.value))}>
            <option value="" disabled>
              Select Coverage Term
            </option>
            {Array.from(
              { length: data.API_RESPONSE_DATA.CoverageTermMax - data.API_RESPONSE_DATA.CoverageTermMin + 1 },
              (_, i) => data.API_RESPONSE_DATA.CoverageTermMin + i
            ).map((term, index) => (
              <option key={index} value={term}>
                {term} Months
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="asset-checkboxes">
        <label>Available Assets:</label>
        {data.API_RESPONSE_DATA.availableAssets.map((asset, index) => (
          <div key={asset.code}>
            <input
              type="checkbox"
              // @ts-ignore hack
              checked={asset.isSelected || false}
              onChange={e => handleCheckboxChange(index, e.target.checked)}
            />
            <label>{asset.displayName}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AftermarketGaigProductComponent;
