import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Box } from "@material-ui/core";

interface Props {
  value: any;
}

export const ReadOnlyQuill = ({ value }: Props) => {
  const quillRef = useRef<any>(null);

  const [containerHeight, setContainerHeight] = useState<string>("");

  useEffect(() => {
    const quillEditor = quillRef.current.getEditor();

    const editorContent = quillEditor.root;
    editorContent.style.padding = "0px";

    setContainerHeight(editorContent.scrollHeight);
  }, [value]);

  return (
    <Box style={{ height: containerHeight }}>
      <ReactQuill ref={quillRef} value={value} theme="bubble" readOnly />
    </Box>
  );
};
