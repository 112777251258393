import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const EquipmentEmptySection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="span" variant="body2" color="textSecondary">
        You don’t have any equipment yet. Please, press ‘Add Equipment’ button to add one.
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    padding: "12px 0",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
