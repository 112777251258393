import React from "react";
import classnames from "classnames";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CloseIcon from "@material-ui/icons/Close";

// components
import Tile from "../../../components/Tile/Tile";

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";

const useStyles = makeStyles({
  ...styles,
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 900,
    "@media (max-width: 900px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
});

export default function EngsMappingModal({ vo, creditApp, handleEngsMappingModalClose }) {
  const classes = useStyles();

  const tableCellClasses = classnames(classes.tableCell);

  /*
  {
    engsApiProperty: '',
    dcrProperty: '',
    valueToBeSubmitted: '',
  }
  */

  const engsMapping = [
    {
      engsApiProperty: "Company",
      dcrProperty: "creditApplication.businessName",
      valueToBeSubmitted: creditApp.businessName,
    },
    {
      engsApiProperty: "Company_Type__c",
      dcrProperty: "creditApplication.legalStructure",
      valueToBeSubmitted: creditApp.legalStructure,
    },
    {
      engsApiProperty: "Email",
      dcrProperty: "creditApplication.email",
      valueToBeSubmitted: creditApp.email,
    },
    {
      engsApiProperty: "Equipment_Description__c",
      dcrProperty: "creditApplication.equipmentDescription or vendorOpportunity.equipmentDescription",
      valueToBeSubmitted: vo.equipmentDescription ? vo.equipmentDescription : creditApp.equipmentDescription,
    },
    {
      engsApiProperty: "Equipment_Price__c",
      dcrProperty: "vendorOpportunity.invoiceTotal or creditApplication.amountRequested",
      valueToBeSubmitted: vo.invoiceTotal ? vo.invoiceTotal : creditApp.amountRequested,
    },
    {
      engsApiProperty: "Equipment_Quantity__c",
      dcrProperty: "N/A",
      valueToBeSubmitted: "1",
    },
    {
      engsApiProperty: "Equipment_Year__c",
      dcrProperty: "vendorOpportunity.equipmentModelYear",
      valueToBeSubmitted: vo.equipmentModelYear,
    },
    {
      engsApiProperty: "FirstName",
      dcrProperty: "creditApplication.firstName",
      valueToBeSubmitted: creditApp.firstName,
    },
    {
      engsApiProperty: "LastName",
      dcrProperty: "creditApplication.lastName",
      valueToBeSubmitted: creditApp.lastName,
    },
    {
      engsApiProperty: "Phone",
      dcrProperty: "creditApplication.bizPhone",
      valueToBeSubmitted: creditApp.bizPhone,
    },
    {
      engsApiProperty: "Cell_Phone__c",
      dcrProperty: "creditApplication.bizPhone",
      valueToBeSubmitted: creditApp.bizPhone,
    },
    {
      engsApiProperty: "Street",
      dcrProperty: "creditApplication.address",
      valueToBeSubmitted: creditApp.address,
    },
    {
      engsApiProperty: "City",
      dcrProperty: "creditApplication.city",
      valueToBeSubmitted: creditApp.city,
    },
    {
      engsApiProperty: "State",
      dcrProperty: "creditApplication.state",
      valueToBeSubmitted: creditApp.state,
    },
    {
      engsApiProperty: "PostalCode",
      dcrProperty: "creditApplication.postalCode",
      valueToBeSubmitted: creditApp.postalCode,
    },
    {
      engsApiProperty: "State_of_Incorportation__c",
      dcrProperty: "creditApplication.stateOfIncorp",
      valueToBeSubmitted: creditApp.stateOfIncorp,
    },
    {
      engsApiProperty: "Broker_Email_Address__c",
      dcrProperty: "N/A",
      valueToBeSubmitted: "Trnsact Email Address",
    },
    {
      engsApiProperty: "SSN__c",
      dcrProperty: "creditApplication.ssn",
      valueToBeSubmitted: creditApp.ssn,
    },
    {
      engsApiProperty: "Fed_Tax_ID__c",
      dcrProperty: "creditApplication.taxId",
      valueToBeSubmitted: creditApp.taxId,
    },
  ];

  const engsMappingModalTitleData = {
    title: "ENGS API Mapping",
  };

  return (
    <div className={classes.modalStyle}>
      <Tile
        {...engsMappingModalTitleData}
        titleButtons={[
          {
            icon: <CloseIcon />,
            func: () => handleEngsMappingModalClose(),
          },
        ]}
      >
        {
          <div>
            <Table className={classes.table}>
              <TableBody>
                <TableRow className={classes.tableHeader}>
                  <TableCell style={{ fontWeight: "bold" }} className={tableCellClasses}>
                    {["ENGS API Property"]}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} className={tableCellClasses}>
                    {["Trnsact Property"]}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} className={tableCellClasses}>
                    {["Value to be Submitted"]}
                  </TableCell>
                </TableRow>
                {engsMapping &&
                  engsMapping.map(engsMappingRow => (
                    <TableRow key={engsMappingRow.dcrProperty} className={classes.tableRow}>
                      <TableCell className={tableCellClasses} style={{ paddingRight: "50px" }}>
                        {[engsMappingRow.engsApiProperty]}
                      </TableCell>
                      <TableCell className={tableCellClasses} style={{ paddingRight: "40px" }}>
                        {[engsMappingRow.dcrProperty]}
                      </TableCell>
                      <TableCell className={tableCellClasses}>{[engsMappingRow.valueToBeSubmitted]}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        }
      </Tile>
    </div>
  );
}
