import {
  containerFluid,
  dangerColor,
  defaultBoxShadow,
  defaultFont,
  grayColor,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react.js";

const headerStyle = theme => ({
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[6],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
  },
  container: {
    ...containerFluid,
    minHeight: "50px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important",
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px",
  },
  "@keyframes fadeInUp": {
    from: {
      opacity: 0,
      transform: "translate3d(0, 100%, 0)",
      "-webkit-transform": "translate3d(0, 100%, 0)",
    },

    to: {
      opacity: 1,
      transform: "none",
      "-webkit-transform": "none",
    },
  },
  fastTrackMobileView: {
    opacity: 0,
    cursor: "pointer",
    display: "flex",
    fontSize: 12,
    fontWeight: "bold",
    columnGap: 5,
    alignItems: "center",
    paddingLeft: 10,
    position: "relative",
    animation: `$fadeInUp 1s ease-in-out 0s forwards`,
  },
  fastTrackDesktopView: {
    opacity: 0,
    fontSize: "1rem",
    display: "flex",
    fontWeight: "bold",
    columnGap: 5,
    alignItems: "center",
    paddingLeft: 10,
    position: "relative",
    animation: `$fadeInUp 1s ease-in-out 0s forwards`,
    borderLeft: "1px solid #555555",
    cursor: "pointer",
  },
  fastTrackDesktopWrapper: {
    display: "flex",
    alignItems: "center",
  },
  fastTrackTextColor: {
    color: "#000",
  },
  fastTrackTextStyles: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  brandTextStyles: {
    paddingLeft: "20px",
    fontSize: "1.6rem",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingRight: "10px",
  },
  fastTrackImageStyles: {
    width: 30,
    height: 30,
  },
});

export default headerStyle;
