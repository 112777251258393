import React, { useEffect, useState } from "react";
import ConsumerCreditReport from "components/ConsumerCreditReport";

const getCreditReport = ({ ownerId, token, apiRoot }) => {
  try {
    return new Promise((resolve, reject) => {
      fetch(`${apiRoot}/creditReportConsumer/${ownerId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then(response => response.json())
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  } catch (error) {
    console.error(error);
  }
};

const putAuditLog = ({ creditReportConsumerId, token, apiRoot, auditLog }) => {
  try {
    return new Promise((resolve, reject) => {
      fetch(`${apiRoot}/creditReportConsumer/${creditReportConsumerId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        method: "PUT",
        body: JSON.stringify({
          audit_log: auditLog,
        }),
      })
        .then(response => response.json())
        .then(data => {
          resolve(data);
        })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  } catch (error) {
    console.error(error);
  }
};

const CreditReportiFrame = props => {
  const [report, setReport] = useState(null);
  const [token, setToken] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [apiRoot, setApiRoot] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (document) {
      let params = new URL(document.location).searchParams;
      let paramOwnerId = params.get("ownerId");
      let paramToken = params.get("token");
      let paramAccountId = params.get("accountId");
      let paramName = params.get("userName");
      let apiRoot = `https://customer-api${document.location.host === "portal.trnsact.com" ? "" : ".dev"}.trnsact.com`;

      setOwnerId(paramOwnerId);
      setAccountId(paramAccountId);
      setToken(paramToken);
      setApiRoot(apiRoot);
      setUserName(paramName);
    }
  }, []);

  useEffect(() => {
    if (!!token && !!ownerId) {
      async function fetchData() {
        const data = await getCreditReport({ ownerId, token, apiRoot });
        setReport(data);
      }
      fetchData();
    }
  }, [token, ownerId]);

  if (report?.machine_readable_report?.RED_FLAG_COMPLIANCE.XML_INTERFACE.CREDITREPORT.REPORT) {
    return (
      <main style={{ height: "100vh", width: "100vw", background: "#fff" }}>
        <ConsumerCreditReport
          report={report?.machine_readable_report?.RED_FLAG_COMPLIANCE.XML_INTERFACE.CREDITREPORT.REPORT}
          ownerId={ownerId}
          accountId={accountId}
          userName={userName}
          index={0}
          creditReportConsumerId={report.credit_report_consumer_id}
          isIframe={true}
          auditLog={report.audit_log}
          putAuditLog={auditLog =>
            putAuditLog({
              creditReportConsumerId: report.credit_report_consumer_id,
              token,
              apiRoot,
              auditLog,
            })
          }
          dateReportWasPulled={new Date(report.created_date_time)}
        />
      </main>
    );
  }

  return (
    <div style={{ display: "flex", padding: 32 }}>
      <p>No report found.</p>
    </div>
  );
};

export default CreditReportiFrame;
