import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { ContentViewType } from "../../global";
import { ContentViewTypeContextValue } from "./types";
import { viewTypeContext as ViewTypeContext, viewTypeContextInitialValues } from "./viewTypeContext";

export const ViewTypeContextProvider = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const [viewTypeContext, setViewTypeContext] = useState<ContentViewTypeContextValue>(viewTypeContextInitialValues);

  const handleChangeViewTypeContext = useCallback((nextContentViewType: ContentViewType) => {
    const updateMapper: Record<ContentViewType, () => ContentViewTypeContextValue> = {
      [ContentViewType.Desktop]: () => ({
        isDesktop: true,
        isMobile: false,
        contentViewType: ContentViewType.Desktop,
      }),
      [ContentViewType.Mobile]: () => ({
        isDesktop: false,
        isMobile: true,
        contentViewType: ContentViewType.Mobile,
      }),
    };

    setViewTypeContext(updateMapper[nextContentViewType]);
  }, []);

  useEffect(() => {
    handleChangeViewTypeContext(isDesktop ? ContentViewType.Desktop : ContentViewType.Mobile);
  }, [isDesktop]);

  const value = useMemo(
    () => ({
      ...viewTypeContext,
      onChaneContentViewType: handleChangeViewTypeContext,
    }),
    [viewTypeContext]
  );

  return <ViewTypeContext.Provider value={value}>{children}</ViewTypeContext.Provider>;
};
