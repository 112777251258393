import React from "react";
import { Typography, Breadcrumbs as BreadcrumbsMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

interface BreadcrumbsConfigItem<Value> {
  label: string;
  value: Value;
  onClick: (nextValue: Value) => void;
}

interface Props<Value> {
  currentValue: string;
  config: BreadcrumbsConfigItem<Value>[];
}

export const Breadcrumbs = <Value extends string = string>({ currentValue, config }: Props<Value>) => {
  const classes = useStyles();

  return (
    <BreadcrumbsMUI>
      {config.map(({ label, value, onClick }) => (
        <Typography
          key={label}
          className={clsx({
            [classes.crumb]: currentValue !== value,
            [classes.activeCrumb]: currentValue === value,
          })}
          color={currentValue === value ? "textPrimary" : "textSecondary"}
          onClick={() => {
            if (currentValue === value) return;
            onClick(value);
          }}
        >
          {label}
        </Typography>
      ))}
    </BreadcrumbsMUI>
  );
};

const useStyles = makeStyles({
  activeCrumb: {
    cursor: "default",
  },
  crumb: {
    "&:hover": {
      opacity: 0.7,
      cursor: "pointer",
    },
  },
});
