import { gql } from "apollo-boost";

export const GET_CREDIT_SUBMISSIONS = gql`
  query CreditSubmissionsByDynamicsVOId($id: ID!, $display: String) {
    creditSubmissionsByDynamicsVOId(id: $id, display: $display) {
      creditSubmissionId
      creditSubId
      submissionDate
      decision
      lenderName
      amountRequested
      approvedAmount
      notes
      contingencies
      approvedRate
      creditSubStage
      apiApplicationId
      apiResult
      apiExpiredCredentials
      apiDetail
      lenderProfileId
      lenderChannelCode
      lenderTypeCode
      draft
      creditSubStage
      transactionSummary
      mappedValues
      source
      submittedBy
      lenderContactInfo {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      fastTrackResults {
        approvedBy
        fastTrackId
        maxApprovalAmount
        rate
        inputs {
          key
          value
        }
        outputs {
          key
          value
        }
      }
    }
  }
`;

export const SUBMIT_APP = gql`
  mutation($input: CreditSubmissionInput!) {
    createCreditSubmission(input: $input)
  }
`;
