import React, { useRef, useState } from "react";
import TableSearch from "components/Table/TableSearch";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Close from "@material-ui/icons/Close";
import Tile from "components/Tile/Tile";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from "@material-ui/core";
import Table from "components/Table";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import TableHeader from "components/Table/TableHeader";
// import AddNewCustomer from "./AddNewCustomer";

const GET_POTENTIAL_CUSTOMERS = gql`
  query GET_POTENTIAL_CUSTOMERS($vendorProfileId: ID!) {
    potentialCustomers(vendorProfileId: $vendorProfileId) {
      name
      numOfContacts
      contacts
      numOfOpportunities
      latestTransactionDate
      accountId
    }
  }
`;

const useStyles = makeStyles(theme => ({
  tileContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addCustomerBtn: {
    height: "100%",
    padding: "8px 12px",
    fontWeight: 600,
    textTransform: "none",
  },
  customerTable: {
    "& .rt-noData": {
      top: "45%",
    },
  },
  callToActionContainer: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
  },
  filterSelect: {},
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },
}));

export default function CustomersPage() {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 10 });
  const [pageSizeValue, setPageSizeValue] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [openAddCustomerModal, setOpenAddCustomerModal] = useState(false);

  const filterPotentialCustomers = potentialCustomers => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return potentialCustomers;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = potentialCustomers.filter(el => {
      if (typeof el.name === "string") {
        return (el.name || "").toLowerCase().includes(lowerCaseSearch);
      }

      return false;
    });

    return [...searchData];
  };

  const processPotentialCustomers = potentialCustomers => {
    if (!_.isEmpty(potentialCustomers)) {
      return potentialCustomers.map(customer => ({
        ...customer,
        latestTransactionDate: moment(customer.latestTransactionDate).format("MM/DD/YYYY"),
      }));
    }

    return [];
  };

  return (
    <>
      <Query
        context={{ authRequired: true }}
        query={GET_POTENTIAL_CUSTOMERS}
        variables={{ vendorProfileId: account.vendorProfileId }}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return <h3>{String(error)}</h3>;

          const filteredPotentialCustomers = processPotentialCustomers(
            filterPotentialCustomers(data.potentialCustomers)
          ).filter(el => el.name !== null);

          return (
            <Grid>
              <Grid item xs={12}>
                <Tile>
                  <TableHeader
                    filterComps={
                      <>
                        <TableSearch
                          setPageNumber={setPageNumber}
                          setSavedListSettings={setSavedListSettings}
                          searchRef={searchRef}
                          savedListSettings={savedListSettings}
                        />
                      </>
                    }
                  >
                    {/* <Button className={classes.addCustomerBtn} color="primary" onClick={() => setOpenAddCustomerModal(true)}>
                      <PersonAddIcon />
                      Add Customer
                    </Button> */}
                  </TableHeader>
                  <Grid item xs={12}>
                    <Grid container justify="space-between" alignItems="flex-end">
                      <Grid item md={12}>
                        <Table
                          color="primary"
                          noDataText={<h3>No results with current settings</h3>}
                          className="-highlight"
                          getTrProps={(state, rowInfo, column, instance) => ({
                            style: { cursor: "pointer" },
                            onClick: e => {
                              window.location.href = `/accountDetails/${rowInfo.original.accountId}`;
                            },
                          })}
                          data={filteredPotentialCustomers}
                          columns={[
                            {
                              Header: "Customer",
                              accessor: "name",
                            },
                            {
                              Header: "Contacts",
                              accessor: "numOfContacts",
                            },
                            {
                              Header: "Opportunities",
                              accessor: "numOfOpportunities",
                            },
                            {
                              Header: "Latest Opportunity",
                              accessor: "latestTransactionDate",
                              sortMethod: (a, b) => {
                                const dateA = new Date(a).getTime();
                                const dateB = new Date(b).getTime();

                                return dateA - dateB;
                              },
                            },
                          ]}
                          pageSize={pageSizeValue}
                          pageNumber={pageNumber}
                          defaultPageSize={savedListSettings.pageSize}
                          showPaginationTop={false}
                          showPaginationBottom={true}
                          onPageChange={changePage => {
                            setPageNumber(changePage);
                          }}
                          onPageSizeChange={changePageSize => {
                            setPageSizeValue(changePageSize);
                            setPageNumber(0);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Tile>
              </Grid>
            </Grid>
          );
        }}
      </Query>
      <Dialog
        open={openAddCustomerModal}
        onClose={() => setOpenAddCustomerModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
          <h6 style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
            <PersonAddIcon style={{ marginRight: 8 }} />
            Add Customer
          </h6>
          <IconButton className={classes.closeBtn} onClick={() => setOpenAddCustomerModal(false)}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>{/* <AddNewCustomer /> */}</DialogContent>
      </Dialog>
    </>
  );
}
