import React from "react";
import WorkflowConstructor from "./index";
import { useSelector } from "react-redux";
import { portalConfigurationsSelectors } from "../../redux/portalConfigurationReducer";

export const PrescreenModuleContainer = () => {
  const account = useSelector((state: any) => state.account);
  const vp = useSelector((state: any) => state.vp);

  const waterfallActive = useSelector(portalConfigurationsSelectors.isPrescreenModuleActive);
  const type = useSelector(portalConfigurationsSelectors.prescreenModuleType);
  const moduleName = useSelector(portalConfigurationsSelectors.prescreenModuleName);

  return (
    <WorkflowConstructor
      vp={vp}
      type={type}
      account={account}
      moduleName={moduleName}
      waterfallActive={waterfallActive}
    />
  );
};
