import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import chatIcon from "assets/img/icons/chat-widget.png";
import closeIcon from "assets/img/icons/chat-close-icon.png";
import {
  CHAT_V2_ADDED_PARTICIPANT,
  CHAT_V2_CONVERSATIONS,
  CHAT_V2_OPEN,
  CHAT_V2_RELATED_TASK_ID,
  CHAT_V2_REMOVE_PARTICIPANT,
  CHAT_V2_RESET,
  CHAT_V2_SELECTED_CONVERSATION_SID,
  CHAT_V2_SET_TARGET_RECIPIENT,
} from "../../redux/types";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import InputAdornment from "@material-ui/core/InputAdornment";
import iconSmile from "assets/img/smile.svg";
import iconCamera from "assets/img/camera.svg";
import mixpanel from "../../mixpanel";
import { format } from "date-fns";
import classNames from "classnames";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { io } from "socket.io-client";
import { DCR_API_BASE_SERVER_URL } from "../../config";
import sortBy from "lodash/sortBy";
import _ from "lodash";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import avatar from "../../assets/img/default-avatar.png";
import CircularProgress from "@material-ui/core/CircularProgress";
const config = require("../../config");

const CACHED_MSGS = new Map();
const useStyles = makeStyles({
  sponsoredChatContainer: {
    position: "fixed",
    bottom: 105,
    right: 15,
    maxWidth: "calc(100vw - 30px)",
    maxHeight: "calc(100vh - 30px)",
    borderRadius: "10px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    height: 150,
    width: 700,
    padding: "20px",
    boxShadow: "-24px 7px 39px -23px rgba(0,0,0,0.75)",
    zIndex: 1350,
  },
  sponsorMessage: {
    textAlign: "center",
    lineHeight: "15px",
    fontSize: "20px",
    fontWeight: 400,
    fontVariant: "small-caps",
  },
  sponsorMessageLink: {
    cursor: "pointer",
    fontWeight: "bolder",
    fontSize: "22px",
    color: "black",
    textDecoration: "underline",
  },
  bottomRight: {
    position: "fixed",
    bottom: 30,
    right: 15,
    backgroundColor: "transparent",
    border: "0",
    borderRadius: "50%",
    height: 60,
    marginTop: 10,
    cursor: "pointer",
    width: "60px",
    zIndex: 1350,
  },
  itemRoot: {
    fontSize: 13,
  },
  chatContainer: {
    position: "fixed",
    bottom: 105,
    right: 15,
    maxWidth: "calc(100vw - 30px)",
    maxHeight: "calc(90vh - 50px)",
    borderRadius: "10px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    minHeight: 500,
    width: 700,
    boxShadow: "-24px 7px 39px -23px rgba(0,0,0,0.75)",
    zIndex: 1350,
  },
  chatHeader: {
    backgroundColor: "#333333",
    color: "#fff",
    fontSize: 25,
    fontWeight: 300,
    borderRadius: "7px 7px 0px 0px",
    padding: "15px 25px",
    "& *": {
      transition: "0.2s linear",
    },
    "& .MuiSvgIcon-root": {
      color: "#b3b3b3",
    },
  },
  subText: {
    color: "#b3b3b3",
    fontSize: 14,
  },
  rotate180: {
    transform: "rotate(180deg)",
  },
  rotate0: {
    transform: "rotate(0deg)",
  },
  padding0: {
    padding: 0,
  },
  details: {
    minHeight: 240,
    padding: 10,
    backgroundColor: "#f5f5f5",
    position: "absolute",
    top: 75,
    zIndex: 10,
  },
  collapseContainer: {
    position: "absolute",
    top: 75,
    width: "100%",
    zIndex: 10,
  },
  itemPadding: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  itemSpacing: {
    marginTop: 5,
    marginBottom: 5,
  },
  checkbox: {
    color: "#0176FF !important",
  },
  grow1: {
    flexGrow: 1,
  },
  expandedByDetails: {
    paddingTop: 250,
    flexGrow: 1,
  },
  icon: {
    width: 24,
    height: 24,
    backgroundRepeat: "none",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    marginRight: "15px",
    cursor: "pointer",
  },
  iconCamera: {
    height: 23.87,
    width: 28.1,
    backgroundImage: `url("${iconCamera}")`,
  },
  iconSmile: {
    height: 24.3,
    width: 24.3,
    backgroundImage: `url("${iconSmile}")`,
  },
  chatInput: {
    backgroundColor: "#f5f5f5",
    height: 70,
    display: "flex",
    alignItems: "center",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  chatInputNoMessages: {
    border: "1px solid #40c179",
  },
  noMessagesWarning: {
    position: "absolute",
    bottom: 90,
    left: "50%",
    transform: "translate(-50%)",
    padding: 15,
    textAlign: "center",
    color: "#878787",
    border: "1px solid #c0c0c0",
    borderRadius: 6,
    fontSize: 16,
    width: "60%",
  },
  chatTextFieldStyles: {
    position: "relative",
    paddingLeft: "10px",
    top: "50%",
    transform: "translate(0px, -20%)",
    caretColor: "#0176FF",
  },
  chatWithoutMessagesFieldStyles: {
    position: "relative",
    paddingLeft: "10px",
    top: "50%",
    transform: "translate(0px, -20%)",
    caretColor: "#0176FF",
    "&::placeholder": {
      color: "#40c179",
      opacity: "1",
    },
  },
  sendMessageBtn: {
    color: "#0176FF",
    fontWeight: "500",
    fontSize: "20px",
    letterSpacing: "0.15px",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  noBorder: {
    border: "none",
  },
  streamText: {
    overflowY: "scroll",
    width: "100%",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  streamTextInitialLine: {
    widht: "100%",
    textAlign: "center",
  },
  streamTextLines: {
    margin: 0,
    padding: 0,
  },
  messageLine: {
    listStyle: "none",
    margin: "45px auto",
  },
  myselfHighlight: {
    marginRight: "25px",
    backgroundColor: "#0176FF",
    color: "#fff",
  },
  recipientHighlight: {
    marginLeft: "25px",
    backgroundColor: "#F5F5F5",
    color: "#000",
  },
  textLineBox: {
    position: "relative",
    borderRadius: "7px",
    padding: "15px 25px",
    fontSize: "14px",
    width: "100%",
    wordBreak: "break-all",
  },
  messageSenderContent: {
    margin: "0px",
  },
  messageSender: {
    color: "#757575",
    position: "absolute",
    left: 25,
    bottom: "-25px",
  },
  messageDate: {
    color: "#757575",
    position: "absolute",
    right: 25,
    bottom: "-25px",
  },
  labelText: {
    fontSize: "14px",
    fontWeight: "300",
    textTransform: "uppercase",
    color: "#333333",
  },
  popper: {
    zIndex: 1500,
  },
  messageCharacters: {
    marginRight: "15px",
    color: props => (props.characterCount > props.maxCharacterCount ? "red" : "black"),
  },
  chatDetails: {
    width: "100%",
  },
  avatarImg: {
    width: "25px",
    verticalAlign: "middle",
    border: "0",
    marginRight: "4px",
  },
  chipRoot: {
    borderRadius: 0,
    color: "#CCC",
    boxSizing: "border-box",
    fontSize: "11px",
    border: "1px solid",
    borderColor: "#bddaff",
  },
  disabledNotificationUpdates: {
    opacity: "0.6",
  },
  senderDataInfo: {
    margin: "5px",
    padding: "5px",
    fontWeight: 400,
    fontSize: 13,
  },
});

function ChatButton({ open, onClick, ...props }) {
  return (
    <button className={props.className} onClick={onClick}>
      <img src={open ? closeIcon : chatIcon} />
    </button>
  );
}

const RecipientTag = props => {
  const classes = useStyles();

  const customAvatar = props.mugshot ? (
    <Avatar alt="img" src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${props.mugshot}`} />
  ) : (
    <Avatar>{_.get(props, "label[0]", "")}</Avatar>
  );

  return (
    <Tooltip title={props.tooltip} placement="top" arrow>
      <Chip className={classes.chipRoot} {...props} avatar={customAvatar} />
    </Tooltip>
  );
};

function ChatWidget(props) {
  const onMessageAdd = props.onMessageAdd;

  const dispatch = useDispatch();
  const open = useSelector(state => state.chatV2.open);
  const creditApp = useSelector(state => state.chatV2.creditApp);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableSMSOption, setDisableSMSOption] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [characterCount, setCharacterCount] = useState(0);
  const maxCharacterCount = 320;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const vo = useSelector(state => state.vo);
  const selectedConversationSid = useSelector(state => state.chatV2.selectedConversationSid);
  const conversations = useSelector(state => state.chatV2.conversations);
  const targetRecipient = useSelector(state => state.chatV2.targetRecipient);

  const classes = useStyles({ showDetails, characterCount, maxCharacterCount });
  const [showSelect, setShowSelect] = useState(true);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [topic, setTopic] = useState("");
  const [error, setError] = useState("");
  const [disableNotificationOptions, setDisableNotificationOptions] = useState(false);
  const [notifySettings, setNotifySettings] = useState({
    notifyViaSMS: false,
    notifyViaEmail: true,
  });
  const userProfile = useSelector(state => state.userProfile);
  const relatedUsers = useSelector(state => state.relatedUsers);
  const recipientsList = useSelector(state => state.chatV2.recipientsList);
  const lenderProfiles = useSelector(state => state.lp);
  const tasks = useSelector(state => state.chatV2.tasks);

  const [availableRecipients, setAvailableRecipients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [msg, setMsg] = useState("");
  const account = useSelector(state => state.account);
  const [messages, setMessages] = useState([]);
  const [relatedTo, setRelatedTo] = useState("");
  const msgContainerRef = useRef();
  const [userConversationSid, setUserConversationSid] = useState("");
  const msgsCount = useRef(0);
  const [tempConv, setTempConv] = useState(null);
  const participantsQueue = useRef([]);
  const messagesQueue = useRef([]);
  const emojiContainerRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const lastCaretPos = useRef({ start: 0, end: 0 });
  const ioRef = useRef(null);

  const C_SEND_MESSAGE = gql`
    mutation SendConversationMessage($input: ConversationMessageInput!) {
      sendConversationMessage(input: $input)
    }
  `;
  const C_ADD_PARTICIPANT = gql`
    mutation AddConversationParticipant($input: ConversationParticipantInput!) {
      addConversationParticipant(input: $input)
    }
  `;
  const C_UPDATE_CONVERSATION = gql`
    mutation UpdateConversation($input: UpdateConversationInput!) {
      updateConversation(input: $input)
    }
  `;

  const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
  const C_GET_CONVERSATION = gql`
    query getConversation($sid: String!) {
      getConversation(sid: $sid) {
        sid
        vendorOpportunityId
        createdAt
        participants {
          sid
          userProfileId
          notifyByEmail
          notifyBySMS
          notifyByWhatsapp
          mobilePhoneNumber
          email
          mugshot
          identity
          isDealer
          isLender
          isApplicant
        }
        task {
          regarding
        }
        taskId
        messages {
          participantSid
          userProfileId
          body
          author
          delivery {
            total
            read
            sent
            delivered
            failed
            undelivered
          }
        }
        topic
      }
    }
  `;
  const C_REMOVE_PARTICIPANT = gql`
    mutation RemoveParticipantFromConversation($input: RemoveConversationParticipantInput!) {
      removeParticipantFromConversation(input: $input)
    }
  `;

  const C_CREATE = gql`
    mutation CreateConversation($input: CreateConversationInput!) {
      createConversation(input: $input) {
        sid
        vendorOpportunityId
        creditSubmissionId
        taskId
        topic
        createdBy
        createdAt
        messages {
          body
        }
        participants {
          sid
          identity
          userProfileId
        }
      }
    }
  `;

  const [sendMessageToConversation] = useMutation(C_SEND_MESSAGE, {
    context: { authRequired: true },
  });
  const [addParticipant] = useMutation(C_ADD_PARTICIPANT, {
    context: { authRequired: true },
  });

  const [updateConversation] = useMutation(C_UPDATE_CONVERSATION, {
    context: { authRequired: true },
  });

  const [removeParticipant] = useMutation(C_REMOVE_PARTICIPANT, {
    context: { authRequired: true },
  });

  const [createConversation] = useMutation(C_CREATE, {
    context: { authRequired: true },
  });

  const [getConversation, { data: fetchedConversation, refetch }] = useLazyQuery(C_GET_CONVERSATION, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    dispatch({ type: CHAT_V2_RESET });
  }, []);

  useEffect(() => {
    if (vo && vo.potentialCustomer) {
      setRelatedTo(vo.potentialCustomer.name);
    }
  }, [vo]);

  useEffect(() => {
    setCharacterCount(msg.length);
  }, [msg]);

  useEffect(() => {
    const profiles = (recipientsList || []).filter(user => user.id !== userProfile.id);

    if (creditApp && creditApp.primaryContact) {
      const { firstName, lastName, phone, email } = creditApp.primaryContact;
      const fullName = `${firstName} ${lastName}`;
      const mobilePhoneNumber = phone ? phone.replace(/\D+/g, "") : null;

      profiles.unshift({
        fullName,
        category: "Applicant",
        identity: fullName,
        email: email,
        id: null,
        mobilePhoneNumber,
        mugshot: null,
        isDealer: false,
        isLender: false,
        isApplicant: true,
      });
    }

    setAvailableRecipients(profiles);
  }, [userProfile, relatedUsers, lenderProfiles, creditApp]);

  function deleteParticipant(sid) {
    if (!sid || !selectedConversationSid) return;
    removeParticipant({
      variables: {
        input: {
          conversationSid: selectedConversationSid,
          participantSid: sid,
        },
      },
    });
  }

  const sortParticipants = participant => participant.identity.toLowerCase();

  useEffect(() => {
    if (fetchedConversation && fetchedConversation.getConversation) {
      const fetched = fetchedConversation.getConversation;
      if (selectedConversationSid !== fetchedConversation.getConversation.sid) {
        return;
      }

      setSelectedConversation(fetched);

      msgsCount.current = messages.length;

      if (!_.isEmpty(fetched.messages)) {
        if (_.size(messages) < _.size(fetched.messages)) {
          setMessages(fetched.messages);
        }
      }
      setTopic(fetched.topic);
    }
  }, [fetchedConversation, selectedConversationSid]);

  useEffect(() => {
    if (messages.length > msgsCount.current) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    setShowDetails(open);
  }, [open]);

  useEffect(() => {
    try {
      if (!userProfile.id) {
        return;
      }

      ioRef.current = io(DCR_API_BASE_SERVER_URL, {
        path: "/notifications",
        transports: ["websocket"],
        secure: true,
        withCredentials: true,
        auth: {
          userId: userProfile.id,
        },
      });

      try {
        ioRef.current.connect();
        ioRef.current.on("onMessageAdded", () => {
          if (onMessageAdd) {
            onMessageAdd();
          }
          refetch && refetch();
        });

        ioRef.current.on("onParticipantAdded", data => {
          try {
            if (data) {
              const parsed = JSON.parse(data);

              const {
                conversationSid,
                participantSid,
                identity,
                email,
                isDealer,
                mugshot,
                mobilePhoneNumber,
                isLender,
                isApplicant,
                userProfileId,
                notifyByEmail,
                notifyBySMS,
              } = parsed;

              const participant = {
                sid: participantSid,
                participantSid,
                identity,
                isDealer,
                isLender,
                isApplicant,
                mugshot,
                mobilePhoneNumber,
                email,
                userProfileId,
                notifyByEmail,
                notifyBySMS,
              };

              onParticipantAdded(conversationSid, participant);
            }
          } catch (error) {
            console.log(error);
          }
        });

        ioRef.current.on("onParticipantRemoved", data => {
          const parsed = JSON.parse(data);
          const { conversationSid, participantSid } = parsed;

          onParticipantRemoved(conversationSid, participantSid);
        });
      } catch (error) {}

      return () => {
        ioRef.current && ioRef.current.disconnect();
      };
    } catch (e) {
      console.log(`Chat widget error: ${e.message}`);
    }
  }, [userProfile, onParticipantRemoved, onParticipantAdded, refetch]);

  const onParticipantRemoved = useCallback(
    (conversationSid, participantSid) => {
      dispatch({
        type: CHAT_V2_REMOVE_PARTICIPANT,
        payload: {
          conversationSid,
          participantSid,
        },
      });
    },
    [selectedConversationSid, dispatch]
  );

  const onParticipantAdded = useCallback(
    (conversationSid, participant) => {
      dispatch({
        type: CHAT_V2_ADDED_PARTICIPANT,
        payload: {
          conversationSid,
          participant,
        },
      });
    },
    [selectedConversationSid, dispatch]
  );

  const getConversationBySid = useCallback(
    sid => {
      return (conversations || []).find(conversation => conversation.sid === sid);
    },
    [conversations]
  );

  useEffect(() => {
    if (!conversations || !conversations.length) {
      return;
    }
    const conversation = conversations.find(({ sid }) => sid === selectedConversationSid);

    if (conversation) {
      if (!conversation.topic) {
        conversation.topic = "N/A";
      }

      setSelectedConversation(conversation);
      setTopic(conversation.topic);

      if ((conversation.participants || []).length === 1) {
        setShowDetails(true);
      }

      msgsCount.current = messages.length;
      console.log("updates message list");
      setMessages(conversation.messages);
    } else {
      setSelectedConversation(null);
    }
  }, [selectedConversationSid, conversations]);

  // useEffect(() => {
  //   if (selectedConversationSid) {
  //     getConversation({
  //       variables: {
  //         sid: selectedConversationSid,
  //       },
  //     });
  //   }
  // }, [selectedConversationSid]);

  useEffect(() => {
    if (!selectedConversation) {
      return;
    }

    const userInRecipients = selectedConversation.participants.find(rec => rec.userProfileId === userProfile.id);

    if (userInRecipients) {
      setUserConversationSid(userInRecipients.sid);
    } else {
      setUserConversationSid("");
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (selectedConversation) {
      const recipientsList = selectedConversation.participants.filter(
        ({ userProfileId }) => userProfileId !== userProfile.id
      );

      if (!_.isEmpty(recipientsList)) {
        setRecipients(sortBy(recipientsList, sortParticipants));
      }
    }
  }, [selectedConversation, userProfile]);

  async function addConversation(subject) {
    if (_.size(recipientsList)) {
      const input = {
        vendorOpportunityId: vo.vendorOpportunityId,
        topic: subject || "RE: " + relatedTo,
        initialParticipant: {
          participantUserProfileId: userProfile.id,
          identity: userProfile.fullName,
          email: userProfile.email,
          mugshot: userProfile.mugshot,
          isDealer: true,
          isLender: false,
          isApplicant: false,
          phoneNumber: null,
          notifyByEmail: notifySettings.notifyViaEmail,
          notifyBySMS: notifySettings.notifyViaSMS,
          notifyByWhatsapp: false,
        },
      };

      try {
        setLoading(true);
        const { data } = await createConversation({
          variables: {
            input: input,
          },
        });

        if (data.createConversation) {
          dispatch({
            type: CHAT_V2_SELECTED_CONVERSATION_SID,
            payload: data.createConversation.sid,
          });
          setDisableNotificationOptions(true);
          return data.createConversation;
        }
      } catch (err) {
        return null;
      } finally {
        setLoading(false);
      }
    } else {
      console.log("There are no recipients, conversation won't be created");
    }
  }

  const renderOptions = () => {
    const options = [
      <MenuItem
        classes={{
          root: classes.itemRoot,
        }}
        key="empty"
        value="create"
        size="small"
      >
        <span>Create new subject</span>
      </MenuItem>,
    ];

    if ((conversations && conversations.length) || tempConv) {
      let list = (conversations || []).slice();

      if (tempConv) {
        list.push(tempConv);
      }

      list.forEach((conversation, key) => {
        options.push(
          <MenuItem
            classes={{
              root: classes.itemRoot,
            }}
            key={key}
            value={conversation.sid}
            size="small"
            name={conversation.topic}
          >
            {conversation.topic}
          </MenuItem>
        );
      });
    }

    return options;
  };

  const optimisticSend = sid => {
    msgsCount.current = messages.length;
    const newMessage = {
      conversationSid: sid,
      participantSid: "",
      identity: userProfile.fullName,
      body: msg,
    };

    messagesQueue.current = [...messagesQueue.current, newMessage];

    const updatedMessages = [
      ...messages,
      {
        ...newMessage,
        createdDateTime: format(new Date(), "MM/dd/yyyy hh:mm a"),
      },
    ];

    setMessages(updatedMessages);
    CACHED_MSGS.set(sid, updatedMessages);
    setMsg("");
  };

  const sendMsg = async () => {
    setLoading(true);
    msgsCount.current = messages.length;

    const newMessage = {
      conversationSid: selectedConversationSid,
      participantSid: userConversationSid,
      identity: userProfile.fullName,
      body: msg,
    };

    const updatedMessages = [
      ...messages,
      {
        ...newMessage,
        createdDateTime: format(new Date(), "MM/dd/yyyy hh:mm a"),
      },
    ];

    setMessages(updatedMessages);
    CACHED_MSGS.set(selectedConversationSid, updatedMessages);
    setMsg("");
    try {
      sendMessageToConversation({
        variables: {
          input: newMessage,
        },
      });
      console.log("CONVERSATIONS: SEND BUTTON FIRED");
      mixpanel.track("CONVERSATIONS: SEND BUTTON CLICKED");
      window.waitForGlobal("_mfq", function() {
        window._mfq.push(["tag", "chat-sent"]);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    dispatch({
      type: CHAT_V2_CONVERSATIONS,
      payload: conversations.map(conv => {
        if (conv.sid === selectedConversationSid) {
          return {
            ...conv,
            messages: updatedMessages,
          };
        }

        return conv;
      }),
    });
  };

  const sendMessage = () => {
    if (!msg.trim()) {
      return;
    }
    if (!_.size(recipients)) {
      console.log("No recipients on the list");
      return;
    }

    const id = Math.random()
      .toString(36)
      .slice(-5);

    if (!selectedConversationSid && tempConv) {
      optimisticSend(id);
      addConversation(tempConv.topic).then(result => {
        if (!result) return;
        const list = CACHED_MSGS.get(id);
        CACHED_MSGS.delete(id);
        CACHED_MSGS.set(result.sid, list);

        if (result && participantsQueue.current.length) {
          const added = [];

          while (participantsQueue.current.length > 0) {
            added.push(createParticipant(participantsQueue.current.pop(), result.sid));
          }

          Promise.all(added).then(q => {
            setTempConv(null);
          });
        }
      });

      return;
    }

    if (!userConversationSid) {
      return;
    }

    sendMsg();
  };

  const deactivateSMSFeature = () => {
    console.log("SMS Feature has been disabled");
    setNotifySettings(settings => ({
      ...settings,
      ["notifyViaSMS"]: false,
    }));
  };

  const onNotifySettingsChange = e => {
    if (!e.target) {
      return;
    }

    const name = e.target.name;

    setNotifySettings(settings => ({
      ...settings,
      [name]: !notifySettings[name],
    }));
  };
  const onChatButtonClick = useCallback(() => {
    console.log("CONVERSATIONS: CHAT WIDGET ICON CLICKED EVENT FIRED");
    mixpanel.track("CONVERSATIONS: CHAT WIDGET ICON CLICKED");
    dispatch({ type: CHAT_V2_OPEN, payload: !open });
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (selectedConversationSid) {
      getConversation({
        variables: {
          sid: selectedConversationSid,
        },
      });
    } else {
      const wasSelected = selectedConversationSid && selectFirstConversationIfExist();
      if (!wasSelected) {
        const topic = "RE: " + relatedTo;
        setTempConv({ sid: `${new Date().getTime()}`, topic });
        setRecipients([]);
        setMessages([]);
        setTopic(topic);
        setShowDetails(true);
      }
    }
  }, [open, selectFirstConversationIfExist, selectedConversationSid]);

  useEffect(() => {
    if (!showDetails) {
      scrollToBottom();
    }
  }, [showDetails]);

  useEffect(() => {
    if (targetRecipient) {
      const hasTargetInRecipients = recipients.findIndex(({ userProfileId }) => userProfileId === targetRecipient) > -1;

      if (!hasTargetInRecipients) {
        const target = availableRecipients.find(({ id }) => id === targetRecipient);
        if (target) {
          target.identity = target.fullName;

          setRecipients(prev => {
            if (prev.findIndex(p => p.id === target.id) > -1) {
              return prev;
            }

            return [...prev, target];
          });

          if (!selectedConversationSid) {
            participantsQueue.current = [target];
          } else {
            const convRecipients = (getConversationBySid(selectedConversationSid) || {}).participants || [];

            if (
              convRecipients.findIndex(recipient => recipient.userProfileId === target.id) === -1 &&
              participantsQueue.current.findIndex(recipient => recipient.id === target.id) === -1
            ) {
              createParticipant(target, null);
            }
          }
        }
      }

      return () => {
        dispatch({
          type: CHAT_V2_SET_TARGET_RECIPIENT,
          payload: null,
        });
      };
    }
    //Determine if SMS will be enabled
    setDisableSMSOption(!_.isEmpty(_.filter(recipients, { mobilePhoneNumber: null })));
  }, [targetRecipient, recipients, availableRecipients, selectedConversationSid, getConversationBySid]);

  useEffect(() => {
    if (participantsQueue.current.length > 0 && selectedConversationSid) {
      const convRecipients = (getConversationBySid(selectedConversationSid) || {}).participants || [];

      while (participantsQueue.current.length > 0) {
        const participant = participantsQueue.current.pop();
        if (convRecipients.findIndex(recipient => recipient.userProfileId === participant.id) === -1) {
          createParticipant(participant, selectedConversationSid);
        }
      }
    }
  }, [selectedConversationSid, getConversationBySid]);

  useEffect(() => {
    if (messagesQueue.current.length && selectedConversationSid && userConversationSid) {
      messagesQueue.current.forEach(m => {
        const newMessage = {
          conversationSid: selectedConversationSid,
          participantSid: userConversationSid,
          identity: userProfile.fullName,
          body: m.body,
        };

        sendMessageToConversation({
          variables: {
            input: newMessage,
          },
        });

        window.waitForGlobal("_mfq", function() {
          window._mfq.push(["tag", "chat-sent"]);
        });
        dispatch({
          type: CHAT_V2_CONVERSATIONS,
          payload: conversations.map(conv => {
            if (conv.sid === selectedConversationSid) {
              return {
                ...conv,
                messages: CACHED_MSGS.get(selectedConversationSid),
              };
            }

            return conv;
          }),
        });
      });

      messagesQueue.current = [];
    }

    if (!!selectedConversationSid && _.size(recipients) && _.size(messages)) {
      setDisableNotificationOptions(true);
    } else {
      setDisableNotificationOptions(false);
    }
  }, [selectedConversationSid, userConversationSid]);

  const selectFirstConversationIfExist = () => {
    if (conversations && conversations.length) {
      let sid = conversations[0].sid;

      if (targetRecipient) {
        const withTarget = conversations.find(conversation => {
          return conversation.participants.findIndex(participant => participant.userProfileId === targetRecipient) > -1;
        });

        if (withTarget) {
          sid = withTarget.sid;
        }
      }

      dispatch({
        type: CHAT_V2_SELECTED_CONVERSATION_SID,
        payload: sid,
      });

      return true;
    }

    return null;
  };

  const onArrowClick = e => {
    e.stopPropagation();
    setSelectOpen(open => !open);
  };

  const getSelectValue = () => {
    if (tempConv) {
      return showSelect ? tempConv.sid : topic;
    }

    return showSelect ? selectedConversationSid || "" : topic;
  };

  function updateTopic() {
    const updated = topic.trim();

    if (updated && updated !== selectedConversation.topic) {
      dispatch({
        type: CHAT_V2_CONVERSATIONS,
        payload: conversations.map(conv => {
          if (conv.sid === selectedConversationSid) {
            return {
              ...conv,
              topic: updated,
            };
          }

          return conv;
        }),
      });

      updateConversation({
        variables: {
          input: {
            conversationSid: selectedConversationSid,
            topic: updated,
          },
        },
      });
    }
  }

  function updateRelatedTask(value) {
    updateConversation({
      variables: {
        input: {
          conversationSid: selectedConversationSid,
          taskId: value,
        },
      },
    });
  }

  function avoidDuplicates() {
    return _.reject(availableRecipients, function(o) {
      const possibleMatch = _.find(recipients, { id: o.id });

      if (!_.isEmpty(possibleMatch)) {
        return true;
      } else {
        return false;
      }
    });
  }

  async function createParticipant(participant, sid) {
    let input = {
      conversationSid: selectedConversationSid || sid,
      participantUserProfileId: participant.id || null,
      mugshot: participant.mugshot || null,
      phoneNumber: participant.mobilePhoneNumber,
      identity: participant.fullName,
      email: participant.email,
      notifyByEmail: notifySettings.notifyViaEmail,
      notifyBySMS: false,
    };

    if (notifySettings.notifyViaSMS) {
      input.notifyBySMS = notifySettings.notifyViaSMS;
    }

    if (!input.identity) return;

    try {
      return addParticipant({
        variables: {
          input,
        },
      });
    } catch (error) {
      console.log(error.message);

      if (error.message.includes("Participant already exists")) {
        setError("The participant you tried to add already exists");
      } else {
        setError(
          "The participant you tried to add cannot receive SMS messages since it's already attached to an existing Chat"
        );
        setOpenAlert(true);
        deactivateSMSFeature();
      }

      return "";
    }
  }

  const onEnterPress = e => {
    if (loading) return;
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (msgContainerRef.current) {
      const ul = msgContainerRef.current.querySelector("ul");

      ul && ul.lastElementChild && ul.lastElementChild.scrollIntoView({ behavior: "smooth" });
    }
  };

  // //If no conversation yet, do not show the icon
  // if (!userConversationSid) {
  //   return null;
  // }

  return (
    <>
      <ChatButton open={open} className={classes.bottomRight} onClick={onChatButtonClick} />

      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Grid className={classes.chatContainer} container direction="column" wrap="nowrap" alignItems="center">
          <ClickAwayListener
            onClickAway={e => {
              if (e.target === emojiContainerRef.current) {
                return;
              }
              setShowEmojiPicker(false);
            }}
          >
            <Popper open={showEmojiPicker} anchorEl={emojiContainerRef.current} className={classes.popper}>
              <Picker
                onSelect={e => {
                  const { start, end } = lastCaretPos.current;
                  const first = msg.slice(0, start);
                  const second = msg.slice(end, msg.length);
                  const msgWithEmoji = `${first}${e.native}${second}`;

                  setMsg(msgWithEmoji);
                }}
                style={{ position: "relative", right: 50, top: -10 }}
              />
            </Popper>
          </ClickAwayListener>
          <Grid className={classes.chatHeader} container wrap="nowrap">
            <Grid container direction="column">
              Conversation
              <Grid container className={classes.subText} style={{ cursor: "pointer" }} alignItems="center">
                <IconButton
                  size="small"
                  disableRipple
                  className={`${classes.padding0} ${classes.subText}`}
                  onClick={() => {
                    setShowDetails(show => !show);
                  }}
                >
                  {showDetails ? "Hide" : "Show"} details
                  <KeyboardArrowUp className={showDetails ? classes.rotate0 : classes.rotate180} />
                </IconButton>
              </Grid>
            </Grid>
            <IconButton
              size="medium"
              className={classes.padding0}
              onClick={() => dispatch({ type: CHAT_V2_OPEN, payload: false })}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            direction="row"
            className={showDetails ? classes.expandedByDetails : classes.grow1}
            style={{ overflow: "auto", display: "block" }}
          >
            <Grid item xs={12} className={classes.chatDetails}>
              <Collapse in={showDetails} timeout={0}>
                <Grid
                  container
                  className={classes.details}
                  direction="column"
                  wrap="nowrap"
                  style={{ overflow: "auto" }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4} className={(classes.itemSpacings, classes.itemPadding)}>
                      <Grid container direction="column">
                        <Typography noWrap>Related Opportunity</Typography>
                        <Typography noWrap>{relatedTo}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} className={(classes.itemSpacings, classes.itemPadding)}>
                      <InputLabel htmlFor="simple-select">
                        Related Task
                        <Select
                          value={selectedConversation ? selectedConversation.taskId || "optional" : "optional"}
                          MenuProps={{
                            style: {
                              zIndex: 1500,
                            },
                          }}
                          onChange={e => {
                            dispatch({
                              type: CHAT_V2_RELATED_TASK_ID,
                              payload: e.target.value,
                            });

                            const task = tasks.find(t => t.taskId === e.target.value);
                            if (e.target.value === selectedConversation.taskId) {
                              return;
                            }
                            const list = conversations.map(conv => {
                              if (conv.sid === selectedConversationSid) {
                                return {
                                  ...conv,
                                  taskId: e.target.value,
                                  task: {
                                    regarding: task.regarding,
                                  },
                                };
                              }
                              return conv;
                            });
                            dispatch({
                              type: CHAT_V2_CONVERSATIONS,
                              payload: list,
                            });
                            updateRelatedTask(e.target.value);
                          }}
                          fullWidth
                        >
                          <MenuItem value="optional" disabled>
                            <span>{tasks && tasks.length ? "Optional" : "None Exist"}</span>
                          </MenuItem>
                          {tasks.map((task, key) => (
                            <MenuItem value={task.taskId} key={key}>
                              {task.regarding}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputLabel>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.itemSpacings}>
                      <Tooltip
                        title={"Notification settings cannot be changed once conversation has been started"}
                        placement="bottom"
                        arrow
                        disableHoverListener={!disableNotificationOptions}
                        disableTouchListener={!disableNotificationOptions}
                        disableFocusListener={!disableNotificationOptions}
                      >
                        <Grid
                          className={classNames({
                            [classes.itemPadding]: true,
                            [classes.disabledNotificationUpdates]: disableNotificationOptions,
                          })}
                        >
                          <Typography noWrap>Notify via</Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                size="small"
                                name="notifyViaEmail"
                                className={classes.checkbox}
                                checked={notifySettings.notifyViaEmail}
                                onChange={onNotifySettingsChange}
                                disabled={disableNotificationOptions}
                              />
                            }
                            label="Email"
                            size="small"
                          />
                          {!disableSMSOption || disableNotificationOptions ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  name="notifyViaSMS"
                                  disabled={disableNotificationOptions || disableSMSOption}
                                  className={classes.checkbox}
                                  checked={notifySettings.notifyViaSMS && !disableSMSOption}
                                  onChange={onNotifySettingsChange}
                                />
                              }
                              size="small"
                              label="SMS"
                            />
                          ) : (
                            <Tooltip
                              title={
                                "One or more of the recipients in this conversation do not have a mobile phone number configured.  Please add mobile phone numbers to each desired recipient to enable SMS for this conversation."
                              }
                              placement="bottom-start"
                              arrow
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    color="primary"
                                    name="notifyViaSMS"
                                    disabled={true}
                                    className={classes.checkbox}
                                    checked={false}
                                  />
                                }
                                size="small"
                                label="SMS"
                              />
                            </Tooltip>
                          )}
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <FormControl fullWidth xs={12} className={`${classes.itemPadding} ${classes.itemSpacing}`}>
                    <Autocomplete
                      multiple
                      classes={{
                        popper: classes.popper,
                      }}
                      getOptionSelected={(option, value) => {
                        return option.id === value.userProfileId;
                      }}
                      limitTags={2}
                      value={recipients}
                      size="small"
                      options={avoidDuplicates()}
                      groupBy={option => option.category}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => {
                          let tooltipContent = `Email: ${option.email} `;
                          if (option.mobilePhoneNumber) {
                            tooltipContent += ` || Phone: ${option.mobilePhoneNumber}`;
                          }

                          return (
                            <RecipientTag
                              {...getTagProps({ index })}
                              label={option.identity}
                              key={`tag-${index}`}
                              mugshot={option.mugshot ? option.mugshot : null}
                              tooltip={tooltipContent}
                            />
                          );
                        });
                      }}
                      renderOption={(option, props) => {
                        return (
                          <Box component="span" sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
                            {option.mugshot ? (
                              <img
                                loading="lazy"
                                width="5"
                                src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${option.mugshot}`}
                                className={classes.avatarImg}
                                alt="..."
                              />
                            ) : (
                              <img src={avatar} className={classes.avatarImg} alt="..." />
                            )}
                            {option.identity}
                          </Box>
                        );
                      }}
                      onChange={async (event, newValue) => {
                        if (newValue.length > recipients.length) {
                          const added = newValue[newValue.length - 1];
                          added.identity = added.fullName;

                          const updatedParticipants = [...recipients, added];

                          setRecipients(sortBy(updatedParticipants, sortParticipants));

                          if (!selectedConversationSid) {
                            participantsQueue.current = updatedParticipants;
                            return;
                          }

                          createParticipant(added).then(() => {
                            console.log("reload conversation");
                            refetch && refetch();
                          });
                        } else {
                          if (selectedConversationSid) {
                            recipients.forEach(recipient => {
                              if (!newValue.find(profile => profile.userProfileId === recipient.userProfileId)) {
                                const participantSidToDelete = recipient.sid || recipient.participantSid;
                                deleteParticipant(participantSidToDelete);
                              }
                            });
                          }

                          setRecipients(sortBy(newValue, sortParticipants));

                          participantsQueue.current = newValue;
                        }
                      }}
                      getOptionLabel={option => option.identity}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Recipients"
                          placeholder=""
                          InputLabelProps={{
                            className: classes.labelText,
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={`${classes.itemPadding} ${classes.itemSpacing}`}
                  >
                    <TextField
                      value={getSelectValue()}
                      size="small"
                      autoFocus
                      label="SUBJECT"
                      InputLabelProps={{
                        className: classes.labelText,
                      }}
                      onClick={() => {
                        if ((userConversationSid && selectedConversationSid) || tempConv) {
                          setShowSelect(false);
                        }
                      }}
                      SelectProps={{
                        IconComponent: () => {
                          return <ArrowDropDownIcon style={{ cursor: "pointer" }} onClick={onArrowClick} />;
                        },
                        onClose: e => {
                          e.stopPropagation();
                          setSelectOpen(false);
                        },
                        open: selectOpen,
                        MenuProps: {
                          style: {
                            zIndex: 1500,
                          },
                        },
                      }}
                      select={showSelect}
                      onBlur={() => {
                        setShowSelect(true);

                        if (tempConv) {
                          setTempConv(prev => ({ ...prev, topic }));
                          return;
                        }

                        if (!selectedConversation || !userConversationSid) {
                          return;
                        }

                        updateTopic();
                      }}
                      onChange={e => {
                        if (showSelect) {
                          if (e.target.value === "create") {
                            addConversation();
                          } else {
                            dispatch({
                              type: CHAT_V2_SELECTED_CONVERSATION_SID,
                              payload: e.target.value,
                            });
                          }
                        } else {
                          setTopic(e.target.value);
                        }
                      }}
                    >
                      {showSelect && renderOptions()}
                    </TextField>
                  </FormControl>
                  <Grid item xs={12} xl={12} lg={12} sm={12} md={12}>
                    <div className={classes.senderDataInfo}>
                      FROM: {userProfile.fullName}
                      {` <${userProfile.email}>`}
                      {userProfile.mobilePhoneNumber ? ` <${userProfile.mobilePhoneNumber}>` : null}
                    </div>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            {!messages.length && (
              <div className={classes.noMessagesWarning}>
                No messages yet, please type your message below to start this conversation...
              </div>
            )}
            <Grid item xs={12} className={classes.streamText} id="streamText" ref={msgContainerRef}>
              <ul className={classes.streamTextLines}>
                {messages.map((message, key) => {
                  const isYourMessage =
                    message.author === userProfile.fullName || message.identity === userProfile.fullName;
                  return (
                    <Grid
                      container
                      key={key}
                      justify={isYourMessage ? "flex-end" : "flex-start"}
                      className={classes.messageLine}
                    >
                      <Grid
                        xs={10}
                        item
                        className={classNames(classes.textLineBox, {
                          [classes.myselfHighlight]: isYourMessage,
                          [classes.recipientHighlight]: !isYourMessage,
                        })}
                      >
                        <p className={classes.messageSenderContent}>{message.body}</p>
                        <span className={classes.messageSender}>{isYourMessage ? "You" : message.author}</span>
                        <span className={classes.messageDate}>
                          {message.createdAt ? format(new Date(message.createdAt), "hh:mm a") : ""}
                        </span>
                      </Grid>
                    </Grid>
                  );
                })}
              </ul>
            </Grid>
          </Grid>
          <Grid style={{ width: "100%" }}>
            <TextField
              onChange={e => {
                setMsg(e.target.value);
              }}
              onSelect={e => {
                lastCaretPos.current = {
                  start: e.target.selectionStart,
                  end: e.target.selectionEnd,
                };
              }}
              multiline
              fullWidth
              value={msg}
              rows={2}
              autoFocus
              type="text"
              onKeyDown={onEnterPress}
              placeholder={messages.length ? "Type a message" : "Type your first message here..."}
              variant="outlined"
              inputProps={{
                className: `${classes.chatTextFieldStyles} ${
                  !messages.length ? classes.chatWithoutMessagesFieldStyles : ""
                }`,
                classes: { notchedOutline: classes.noBorder },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {loading ? <CircularProgress size={20} /> : null}
                    {notifySettings.notifyViaSMS && (
                      <div className={classes.messageCharacters}>
                        {characterCount}/{maxCharacterCount}
                      </div>
                    )}
                    <div
                      ref={emojiContainerRef}
                      className={`${classes.icon} ${classes.iconSmile}`}
                      onClick={() => {
                        setShowEmojiPicker(open => !open);
                      }}
                    />
                    {!loading && msg.length && (!!recipients && recipients.length) && (
                      <span className={classes.sendMessageBtn} onClick={sendMessage}>
                        Send
                      </span>
                    )}
                  </InputAdornment>
                ),
                className: messages.length ? classes.chatInput : `${classes.chatInput} ${classes.chatInputNoMessages}`,
                classes: { notchedOutline: classes.noBorder },
              }}
            />
          </Grid>
        </Grid>
      </Slide>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ChatWidget;
