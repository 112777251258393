import React, { useEffect, useState } from "react";
import iconPlusSvg from "assets/img/icons/plus.svg";
import iconMinusSvg from "assets/img/icons/minus.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { Block } from "./Block";
import { AddNewConfigurableItem } from "../AddNewConfigurableItem";
import { InsertionContainer } from "../InsertionContainer";
import { ConfigurableItemActions } from "../ConfigurableItemActions";
import { configurableItemsTypes, pcwMovingElementActionTypes } from "../../../Prequal/constants";

import {
  defaultMovingElementSettings,
  getConfigurableItemWarnMessage,
  getItemDefaultConfig,
} from "../../../../services/creditAppModalService";

import _ from "lodash";

const useStyles = makeStyles(theme => ({
  sectionRoot: {
    padding: "16px",
    border: "1px dashed grey",
    marginBottom: "16px",
  },
  sectionTitle: {
    //marginTop: 0,
    margin: "0 0 16px",
  },
  collapseButton: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 50,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    zIndex: 3,
    cursor: "pointer",
    color: "#999",
  },
  icon: {
    width: 20,
    height: 20,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    opacity: 0.3,
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
}));

export function SectionTitle({ section, isSectionCollapsed, setIsSectionCollapsed }) {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      <h2>{section.title.label}</h2>
      <div className={classes.collapseButton} onClick={() => setIsSectionCollapsed(!isSectionCollapsed)}>
        {isSectionCollapsed ? (
          <div className={classes.icon + " " + classes.iconPlus} />
        ) : (
          <div className={classes.icon + " " + classes.iconMinus} />
        )}
      </div>
    </div>
  );
}

export function Section({
  section,
  sectionIndex,
  setJSONDefinition,
  editConfigurableItem,
  movingElementSettings,
  setMovingElementSettings,
}) {
  const classes = useStyles();
  const [blocks, setBlocks] = useState(section.blocks);
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(!!section.isCollapsed);
  const isElementMoving = !!movingElementSettings.type;

  useEffect(() => {
    if (!section) {
      return;
    }
    setBlocks(section.blocks);
  }, [section]);

  const isBlockMoving = () => {
    return (
      movingElementSettings.action === pcwMovingElementActionTypes.move &&
      movingElementSettings.type === configurableItemsTypes.block
    );
  };

  const onDelete = () => {
    setJSONDefinition(prevState => {
      return {
        ...prevState,
        sections: prevState.sections.filter((section, idx) => idx !== sectionIndex),
      };
    });
  };

  const addNewBlock = () => {
    setJSONDefinition(prevState => {
      const blockDefaultConfig = getItemDefaultConfig(configurableItemsTypes.block);
      const sectionToUpdate = prevState.sections[sectionIndex];
      const updatedSection = {
        ...sectionToUpdate,
        blocks: [...sectionToUpdate.blocks, blockDefaultConfig],
      };
      return {
        ...prevState,
        sections: prevState.sections.map((section, idx) => (idx === sectionIndex ? updatedSection : section)),
      };
    });
  };

  const handleBlockInsertion = blockIndexToInsert => {
    setJSONDefinition(prevState => {
      const sectionToMoveFrom = prevState.sections[movingElementSettings.sectionIndex];
      const sectionToMoveIn = prevState.sections[sectionIndex];
      const blockToMove = sectionToMoveFrom.blocks.find((block, idx) => idx == movingElementSettings.blockIndex);
      const sectionToMoveInBlocksCopy = [...sectionToMoveIn.blocks];
      if (sectionToMoveFrom === sectionToMoveIn) {
        const tempObj = {}; // used to keep constant indexes of spliced array
        sectionToMoveInBlocksCopy.splice(movingElementSettings.blockIndex, 1, tempObj);
        sectionToMoveInBlocksCopy.splice(blockIndexToInsert, 0, blockToMove);
        sectionToMoveInBlocksCopy.splice(sectionToMoveInBlocksCopy.indexOf(tempObj), 1);
        const updatedSection = {
          ...sectionToMoveIn,
          blocks: sectionToMoveInBlocksCopy,
        };
        return {
          ...prevState,
          sections: prevState.sections.map((section, idx) => (idx === sectionIndex ? updatedSection : section)),
        };
      }

      const updatedSectionToMoveFrom = {
        ...sectionToMoveFrom,
        blocks: sectionToMoveFrom.blocks.filter((block, idx) => idx !== movingElementSettings.blockIndex),
      };
      sectionToMoveInBlocksCopy.splice(blockIndexToInsert, 0, blockToMove);
      const updatedSectionToMoveIn = {
        ...sectionToMoveIn,
        blocks: sectionToMoveInBlocksCopy,
      };

      return {
        ...prevState,
        sections: prevState.sections.map((section, idx) => {
          switch (idx) {
            case movingElementSettings.sectionIndex:
              return updatedSectionToMoveFrom;
            case sectionIndex:
              return updatedSectionToMoveIn;
            default:
              return section;
          }
        }),
      };
    });
    setMovingElementSettings(defaultMovingElementSettings);
  };

  return (
    <Box className={classes.sectionRoot}>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <h2 className={classes.sectionTitle}>{section.config.title}</h2>
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          {!isElementMoving && (
            <ConfigurableItemActions
              warningMessage={getConfigurableItemWarnMessage(section, configurableItemsTypes.section)}
              onEdit={() => editConfigurableItem(section, configurableItemsTypes.section)}
              onDelete={onDelete}
              itemType={configurableItemsTypes.section}
            />
          )}
        </Grid>
      </Grid>
      {isBlockMoving() && _.isEmpty(blocks) && (
        <InsertionContainer type={configurableItemsTypes.block} onClickFn={() => handleBlockInsertion(0)} />
      )}
      {blocks.map((block, index) => (
        <Block
          key={block.id}
          block={block}
          blockIndex={index}
          section={section}
          sectionIndex={sectionIndex}
          setJSONDefinition={setJSONDefinition}
          setBlocks={setBlocks}
          editConfigurableItem={editConfigurableItem}
          movingElementSettings={movingElementSettings}
          setMovingElementSettings={setMovingElementSettings}
          handleBlockInsertion={handleBlockInsertion}
          isBlockMoving={isBlockMoving}
        />
      ))}
      {!isElementMoving && <AddNewConfigurableItem displayLabel={"Add New Block"} onClickFn={addNewBlock} />}
    </Box>
  );
}
