import React from "react";
import { LinearProgress, Box, Typography, Backdrop } from "@material-ui/core";

interface LoadingModalProps {
  message?: string | JSX.Element;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ message = "Loading, please wait..." }) => {
  return (
    <Backdrop
      style={{
        color: "#fff",
        zIndex: 1301, // Typically higher than most elements
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
      open={true}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 16,
          borderRadius: 8,
          backgroundColor: "#282c34",
          color: "#fff",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {message}
        </Typography>
        <LinearProgress style={{ width: "100%", marginTop: 16 }} />
      </Box>
    </Backdrop>
  );
};

export default LoadingModal;
