import React, { useMemo } from "react";
import _ from "lodash";
import { FormInput, FormInputSelect } from "components/form";
import { useSimpleAutoFill } from "../../../../../hooks";
import { SelectOption } from "../../../../../../../global";
import { SimpleProductsDynamicFieldsProps } from "../../../../../types";

export const StringField = ({
  fact,
  facts,
  setFacts,
  disabled,
  productConfigurationRulesEngine,
}: SimpleProductsDynamicFieldsProps) => {
  const onChange = (value: string) => {
    setFacts((prev: any) => {
      productConfigurationRulesEngine.current.addFact(fact.factKey, value);

      return { ...prev, [fact.factKey]: value };
    });
  };

  const selectValue = useMemo(() => {
    if (facts[fact.factKey]) return facts[fact.factKey];

    const isSingleOption = fact.options && fact.options.length === 1;

    if (isSingleOption) onChange(fact?.options?.[0] ?? "");

    return isSingleOption ? fact?.options?.[0] : "";
  }, [facts, fact, onChange]);

  const options: SelectOption[] = [
    { label: "None of these / I don't Know", value: "None of These" },
    ..._.orderBy(fact.options, "asc").map(option => ({ label: option, value: option })),
  ];

  useSimpleAutoFill(onChange, fact);

  return (
    <>
      {fact.options ? (
        <FormInputSelect
          options={options}
          variant="standard"
          value={selectValue}
          onChange={onChange}
          disabled={disabled || !options.length}
          label={fact.factKey.replace(/_/g, " ")}
        />
      ) : (
        <FormInput
          disabled={disabled}
          onChange={onChange}
          value={facts[fact.factKey]}
          label={fact.factKey.replace(/_/g, " ")}
        />
      )}
    </>
  );
};
