import React from "react";
const config = require("../../../config");

interface Props {
  partnerLogo: string;
}

export const VendorLogoImg = ({ partnerLogo }: Props) => {
  const src =
    partnerLogo && partnerLogo.includes("/")
      ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${partnerLogo}`
      : `https://credit-app-images.s3-us-west-2.amazonaws.com/${partnerLogo}`;

  return <img alt="Partner Logo" style={{ maxHeight: "50px", maxWidth: "100%" }} src={src} />;
};
