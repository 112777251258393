import React from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Box } from "@material-ui/core";
import { deskingSelectors } from "../../../../model";
import { FormSectionHeader } from "../../../components";
import { CurrencyInputField } from "../../../../../../components/form";
import { FinanceProgramFormValues, FormSectionProps } from "../../../../types";

export const SaleDetails = ({ sectionName, onUpdateCollapse }: FormSectionProps) => {
  const { control } = useFormContext<FinanceProgramFormValues>();

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  return (
    <Box className="section">
      <FormSectionHeader
        name="Sale Details"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
      />

      <Box className="sectionFields">
        <CurrencyInputField control={control} name="saleDetails.financeAmount" label="Net Finance Amount" />

        <Box className="row">
          <CurrencyInputField control={control} name="saleDetails.equipmentTotal" label="Equipment Total" />

          <CurrencyInputField control={control} name="saleDetails.downPayment" label="Down Payment" />
        </Box>

        <Box className="row">
          <CurrencyInputField control={control} name="saleDetails.salesTax" label="Sales Tax" />

          <CurrencyInputField control={control} name="saleDetails.fees" label="Fees" />
        </Box>

        <CurrencyInputField control={control} name="saleDetails.invoiceTotal" label="Invoice Total" />
      </Box>
    </Box>
  );
};
