import React from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const LocationSelect = ({ fieldConfig }) => {
  const fieldId = uuidv4();

  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ pointerEvents: "none" }}>
        <FormControl fullWidth={true}>
          <InputLabel id={fieldConfig.fieldName} required={fieldConfig.required}>
            {_.get(fieldConfig, "displayLabel.en", "")}
          </InputLabel>
          <Select
            key={fieldId}
            fullWidth={true}
            name={fieldConfig.fieldName}
            disabled={fieldConfig.disabled}
            value={""}
            labelId={fieldConfig.fieldName}
            style={{ maxHeight: "40vh" }}
            required={fieldConfig.required}
            variant="standard"
          >
            {[].map((option, index) => {
              return (
                <MenuItem key={`${fieldId}${index}`} value={option}>
                  LOCATION
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{_.get(fieldConfig, "helperText.en", "")}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export { LocationSelect };
