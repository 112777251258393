import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deskingActions, deskingSelectors } from "../model";

export function useMenuOptionTerms() {
  const dispatch = useDispatch();

  const currentTerm = useSelector(deskingSelectors.term);
  const groupedMenuOptionsByTerm = useSelector(deskingSelectors.menuByTerms);

  const termsOptions = useMemo(() => {
    const nextVale = Object.keys(groupedMenuOptionsByTerm)[currentTerm.index + 1];
    const backVale = Object.keys(groupedMenuOptionsByTerm)[currentTerm.index - 1];

    return {
      forwardTooltip: nextVale ? `Go to ${nextVale}` : "",
      backwardTooltip: backVale ? `Back to ${backVale}` : "",
      isForwardDisabled: Object.keys(groupedMenuOptionsByTerm).length <= currentTerm.index + 1,
      isBackwardDisabled: currentTerm.index <= 0,
    };
  }, [currentTerm]);

  const handleUpdateTerm = useCallback(
    (action: "add" | "sub") => {
      const actionMapper = {
        add: currentTerm.index + 1,
        sub: currentTerm.index - 1,
      };

      dispatch(
        deskingActions.updateTerm({
          index: actionMapper[action],
          term: Object.keys(groupedMenuOptionsByTerm)[actionMapper[action]],
        })
      );
    },
    [dispatch, groupedMenuOptionsByTerm, currentTerm]
  );

  return {
    currentTerm,
    ...termsOptions,
    handleUpdateTerm,
  };
}
