import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  baseStyles: {
    position: "relative",
    display: "inline-block",
    padding: "5px",
    cursor: "pointer",
  },
  boxBaseStyles: {
    position: "relative",
    width: "50px",
    height: "20px",
    backgroundColor: "#c5c5c5",
    border: "13px",
    borderRadius: "14px",
    boxShadow: "1px 0 #f0f0f0",
    transition: "background-color 0.25s ease 0s",
  },
  boxBackgroundStyles: {
    fontSize: "12px",
    color: "#ffffff",
    width: "95%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    fontWeight: "400",
    textTransform: "uppercase",
    paddingTop: "1px",
  },
  boxStyles: {
    backgroundColor: props => props.isActive && "#23a02f",
  },
  handlerBaseStyles: {
    position: "absolute",
    top: "4.5px",
    width: "10px",
    height: "10px",
    verticalAlign: "text-bottom",
    backgroundColor: "#f5f5f5",
    borderRadius: "14px",
    transform: "translateX(5px)",
    transition: "transform 0.25s ease-in 0s",
  },
  handlerStyles: {
    transform: props => props.isActive && "translateX(31px)",
    boxShadow: props => (props.isActive ? "-1px 0 #bdbdbd" : "1px 0 #818181"),
  },
  textStyles: {
    transition: "opacity 0.25s ease 0s",
  },
  textOnStyles: {
    opacity: props => (props.isActive ? 1 : 0),
    paddingLeft: 5,
    verticalAlign: "text-bottom",
  },
  textOffStyles: {
    opacity: props => (props.isActive ? 0 : 1),
    color: "#000",
    paddingRight: 5,
    verticalAlign: "text-bottom",
  },
}));

export const CustomSwitch = props => {
  const [isActive, setIsActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(props.disabled);

  const classes = useStyles({ isActive });

  const switchEl = useRef();

  const handleClick = () => {
    setIsActive(isActive => {
      props.onChange(!isActive);
      return !isActive;
    });
  };

  useEffect(() => {
    setIsDisabled(props.disabled);
    if (isDisabled) {
      switchEl.current.removeEventListener("click", handleClick);
    } else {
      switchEl.current.addEventListener("click", handleClick);
    }

    return () => switchEl.current.removeEventListener("click", handleClick);
  }, [props.disabled]);

  return (
    <div className={classes.baseStyles} ref={switchEl}>
      <div className={`${classes.boxBaseStyles} ${classes.boxStyles}`}>
        <div className={classes.boxBackgroundStyles}>
          <span className={`${classes.textOnStyles} ${classes.textStyles}`}>{props.onText}</span>
          <span className={`${classes.textOffStyles} ${classes.textStyles}`}>{props.offText}</span>
        </div>
        <div className={`${classes.handlerBaseStyles} ${classes.handlerStyles}`} />
      </div>
      <input type="checkbox" role="switch" style={{ display: "none" }} />
    </div>
  );
};
