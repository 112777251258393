import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProductsFilters, ShowTypes } from "../types";

export function proposalProductsFilter(product: ProposalProduct, filters: ProductsFilters) {
  const searchResult = product.title?.toLowerCase()?.includes(filters?.search?.toLowerCase() ?? "");

  const showResult = (product.aftermarketProduct?.productCategory as string) === filters?.showOnly;

  const vendorsResult = filters?.vendors?.includes(product.aftermarketProduct?.aftermarketVendorApiChannel as string);

  return (
    searchResult &&
    (filters?.showOnly === ShowTypes.All || showResult) &&
    (filters?.vendors.length ? vendorsResult : true)
  );
}
