import React, { useRef, useState } from "react";
import ReactTable from "react-table";
import { gql } from "apollo-boost";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
import { Query } from "react-apollo";
import Tile from "../../components/Tile/Tile";
import { convertToDate, formatCurrency } from "../../utils";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { setRefetch, setValue } from "../../redux/actions";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import TableSearch from "components/Table/TableSearch";
import DragIndicator from "assets/img/drag-indicator.svg";

const styles = theme => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  fixReactTable: {
    "& .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td": {
      display: "flex",
      alignItems: "center",
      lineHeight: "1rem !important",
    },
    "& .ReactTable .rt-thead .rt-resizable-header-content": {
      position: "relative",
      paddingRight: "1rem",
      overflow: "visible",
      display: "flex",
      alignItems: "center",
    },
    "& .ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
      position: "absolute",
      right: "0",
      top: "50%",
      transform: "translateY(-50%)",
      bottom: "unset",
    },
    "& .ReactTable .rt-thead.-filters input, & .ReactTable .-pagination .-pageJump input": {
      height: "26px",
    },
    "& .ReactTable .rt-thead.-filters input:focus, & .ReactTable .-pagination .-pageJump input:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2)`,
    },
    "& .ReactTable .rt-resizer": {
      backgroundImage: `url(${DragIndicator})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left center",
      backgroundSize: "26px",
    },
  },
  filterSelect: {
    height: "1rem !important",
    boxSizing: "content-box",
    fontSize: "14px !important",
    "&:focus": {
      backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}), linear-gradient(#d2d2d2, #d2d2d2) !important`,
    },
  },
  noDataStyles: {
    display: "block",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.8)",
    transition: "all 0.3s ease",
    zIndex: "1",
    pointerEvents: "none",
    padding: "20px",
    color: "rgba(0, 0, 0, 0.5)",
  },
});

const useStyles = makeStyles(styles);

const GET_VO_PROPOSAL_LIST = gql`
  query($voId: ID) {
    proposals(voId: $voId) {
      id
      amountRequested
      creditProductIds
      riskTier
      vendorOpportunityId
      accountId
      vendorSalespersonId
      firstName
      lastName
      email
      businessName
      status
      createdDateTime
      salesPersonName
      fullName
      paymentId
      creditSubmissionDynamicsId
      lenderPaymentOptions {
        lenderId
        lenderType
        payment
        paymentId
        proposalId
        residual
        residualMultiplier
        term
        useMaxApprovedAmount
      }
    }
  }
`;

const DEACTIVATE_PROPOSAL = gql`
  mutation($id: ID!) {
    deactivateProposal(id: $id)
  }
`;

function VOProposalList(props) {
  const voId = props.vo.vendorOpportunityId;
  const vo = props.vo;
  const applicant = props.applicant;
  const classes = useStyles();
  const [proposalList, setProposalList] = useState();
  const statusesSel = [];
  const rolesSel = [];
  const [rowAction, setRowAction] = useState("");
  const [proposals, setProposals] = useState("self");
  const history = useHistory();
  const cl = props.cl;
  const [selectedProposal, setSelectedProposal] = useState("");
  const [openReProposalModal, setOpenReSendProposalModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 10 });
  const [pageSizeValue, setPageSizeValue] = useState(5);

  const handleOpenReSendProposalModal = () => {
    setOpenReSendProposalModal(true);
  };
  const handleCloseReSendProposalModal = () => {
    setOpenReSendProposalModal(false);
  };

  const handleResend = (e, proposal) => {
    e.stopPropagation();
    handleOpenReSendProposalModal();
    setSelectedProposal(proposal);
  };
  function handleProposalRowClick(propId) {
    history.push(`/proposalDetail/${propId}`);
  }

  const filterProposalListData = proposalListData => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return proposalListData;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    const searchData = proposalListData.proposals.filter(el => {
      if (
        typeof el.createdDateTime === "string" ||
        typeof el.businessName === "string" ||
        typeof el.contactName === "string" ||
        typeof String(el.amountRequested) === "string" ||
        typeof el.salesPersonName === "string"
      ) {
        return (
          (el.createdDateTime || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.businessName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.contactName || "").toLowerCase().includes(lowerCaseSearch) ||
          (String(el.amountRequested) || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.salesPersonName || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return { ...proposalListData, proposals: [...searchData] };
  };

  function processProposalListData(proposals) {
    if (proposals && _.isArray(proposals.proposals)) {
      const statuses = [];
      const roles = [];
      proposals.proposals.forEach(item => {
        try {
          item.contactName = item.firstName ? item.firstName + item.lastName : item.fullName;
          item.amountRequested = formatCurrency(item.amountRequested);
          item.createdDateTime = convertToDate(item.createdDateTime);

          if (statuses.find(listItem => listItem === item.status) === undefined && !!item.status) {
            statuses.push(item.status);
          }

          if (item.paymentId && item.lenderPaymentOptions && item.lenderPaymentOptions.length > 0) {
            const acceptedPayment = item.lenderPaymentOptions.find(lpo => {
              return lpo.paymentId === item.paymentId;
            });
            item.acceptedPayment = `Payment Amount: ${formatCurrency(acceptedPayment.payment)}
            Payment Term: ${acceptedPayment.term}`;
          } else {
            item.acceptedPayment = "N/A";
          }
        } catch (error) {
          console.log(error);
        }
      });
      statuses.forEach(item => {
        const statusesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        statusesSel.push(statusesSelItem);
      });
      roles.forEach(item => {
        const rolesSelItem = {
          value: item,
          text: item ? item : "None",
        };
        rolesSel.push(rolesSelItem);
      });

      return [...proposals.proposals];
    }

    return [];
  }

  const [deactivateProposal] = useMutation(DEACTIVATE_PROPOSAL, {
    context: { authRequired: true },
  });

  const handleDeleteProposal = async (event, id) => {
    try {
      setRowAction("delete");
      await deactivateProposal({
        variables: {
          id: id,
        },
      });
      setRowAction("");
    } catch (err) {
      setRowAction("");
      console.log(err);
    }
  };

  return (
    <>
      <Query context={{ authRequired: true }} query={GET_VO_PROPOSAL_LIST} variables={{ voId }} fetchPolicy="no-cache">
        {({ loading, error, data, refetch: refetchProposals }) => {
          if (loading) return "Loading...";
          if (error) return <h3>{String(error)}</h3>;

          let filteredProposalListData = processProposalListData(filterProposalListData(data));
          if (proposals !== "self") {
            filteredProposalListData = processProposalListData(filterProposalListData(data));
          }

          return (
            <div className={classes.root}>
              <Grid>
                <Grid item xs={12}>
                  <Tile>
                    <div className={classes.fixReactTable}>
                      <h3>Proposals</h3>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <TableSearch
                          setPageNumber={setPageNumber}
                          setSavedListSettings={setSavedListSettings}
                          searchRef={searchRef}
                          savedListSettings={savedListSettings}
                        />
                      </Grid>
                      <ReactTable
                        color="primary"
                        NoDataComponent={props => {
                          return (
                            <div {...props} className={classes.noDataStyles}>
                              No rows found
                            </div>
                          );
                        }}
                        data={filteredProposalListData}
                        getTrProps={(state, rowInfo, column, instance) => ({
                          style: { cursor: "pointer" },
                          onClick: e => handleProposalRowClick(rowInfo.original.id),
                        })}
                        columns={[
                          {
                            Header: "Created On",
                            accessor: "createdDateTime",
                            resizable: false,
                          },
                          {
                            Header: "Company",
                            accessor: "businessName",
                          },
                          {
                            Header: "Contact",
                            accessor: "contactName",
                          },
                          {
                            Header: "Amount",
                            accessor: "amountRequested",
                            value: "test",
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                            filterable: true,
                            Filter: ({ filter, onChange }) => {
                              return (
                                <select
                                  className={classes.filterSelect}
                                  onChange={e => onChange(e.target.value)}
                                  value={filter ? filter.value : ""}
                                  style={{ width: "100%" }}
                                >
                                  <option value="">Show All</option>
                                  {statusesSel.map((item, index) => (
                                    <option key={"status-" + index} value={item.value}>
                                      {item.text}
                                    </option>
                                  ))}
                                </select>
                              );
                            },
                          },
                          {
                            Header: "Accepted Payment",
                            accessor: "acceptedPayment",
                          },
                          {
                            Header: "Owner",
                            accessor: "salesPersonName",
                          },
                          // {
                          //   Header: "Resend",
                          //   Cell: props => {
                          //     return (
                          //       <>
                          //         <SendIcon onClick={e => handleResend(e, props.original)} color="primary"></SendIcon>
                          //       </>
                          //     );
                          //   },
                          // },
                        ]}
                        defaultPageSize={savedListSettings.pageSize}
                        pageSize={pageSizeValue}
                        onPageChange={changePage => {
                          setPageNumber(changePage);
                        }}
                        onPageSizeChange={changePageSize => {
                          setPageSizeValue(changePageSize);
                          setPageNumber(0);
                        }}
                        page={pageNumber}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        customCellClasses={[classes.right]}
                        customClassesForCells={[5]}
                        className="-striped -highlight"
                      />
                    </div>
                  </Tile>
                </Grid>
              </Grid>
              {/* <CreateProposal vo={vo} applicant={applicant} refetchProposals={refetchProposals} cl={cl} />
              <ReSendProposalModal
                open={openReProposalModal}
                handleClose={() => handleCloseReSendProposalModal()}
                handleOpenAddNewProposalModal={handleOpenReSendProposalModal}
                proposalId={selectedProposal.id}
                proposalApplicant={selectedProposal.fullName}
                proposalEmail={selectedProposal.email}
                proposal={selectedProposal}
              /> */}
            </div>
          );
        }}
      </Query>
    </>
  );
}

const mapStateToProps = state => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
  creditSubmissions: state.creditSubmissions,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(VOProposalList);
