import React from "react";
import avatar from "../assets/img/default-avatar.png";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "../assets/jss/material-dashboard-pro-react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

const config = require("../config");

const useStyles = makeStyles({
  accountRepBlock: {
    padding: "20px 0",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    height: "99%",
    marginBottom: "50px",
  },
  accountRepImage: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  accountRepFullName: {},
  accountRepPhone: {
    fontSize: "18px",
    fontWeight: "600",
  },
  avatarImg: {
    width: "100%",
    verticalAlign: "middle",
    border: "0",
  },
  accountRepEmail: {
    fontSize: "14px",
    fontWeight: "600",
    "& a": {
      color: primaryColor[0],
    },
  },
  miniActiveImage: {
    width: "40px",
    height: "40px",
  },
  needHelp: {
    "& button": {
      fontSize: "14px",
      fontWeight: "600",
      backgroundColor: primaryColor[0],
      color: "#fff",
      padding: "8px 12px",
      textTransform: "none",

      "&:hover": {
        opacity: 0.8,
      },
    },
  },
});

function CompanyProfileSidebarBlock({ miniActive, accountRep }) {
  const classes = useStyles();
  let imageClass = classes.accountRepImage;
  if (miniActive) imageClass += " " + classes.miniActiveImage;

  return (
    <div className={classes.accountRepBlock}>
      <div className={imageClass}>
        {accountRep.mugshot ? (
          <img
            src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${accountRep.mugshot}`}
            className={classes.avatarImg}
            alt="..."
          />
        ) : (
          <img src={avatar} className={classes.avatarImg} alt="..." />
        )}
      </div>
      {/* <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "-10px" }}>Need Help?</div> */}
      {!miniActive ? (
        <>
          <div className={classes.accountRepFullName}>
            <h3>{accountRep.fullName}</h3>
          </div>
          {/* <div className={classes.accountRepPhone}>{formatPhoneNumber(accountRep.phoneNumber)}</div>
          <div className={classes.accountRepEmail}>
            <a href={"mailto:" + accountRep.email} target="_blank" rel="noopener noreferrer">
              {accountRep.email}
            </a>
          </div> */}
          <a
            className={classes.needHelp}
            rel="noopener noreferrer"
            href="https://dcr.atlassian.net/servicedesk/customer/portals"
            target="_blank"
          >
            <Button>Need Help?</Button>
          </a>
        </>
      ) : (
        <></>
      )}
    </div>
  );

  // return (
  //   <Query context={queryContext} query={GET_COMPANY_PROFILE}>
  //     {({ loading, error, data }) => {
  //       if (loading) {
  //         return('Loading');
  //       }
  //       const accountRep = data && data.accountRep;
  //       return (
  //         <div className={classes.accountRepBlock}>
  //             {!!accountRep
  //               ? (
  //                   <>
  //                     <div className={imageClass}>
  //                       {!!accountRep.mugshot
  //                         ? <img
  //                             src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${accountRep.mugshot}`}
  //                             className={classes.avatarImg} alt="..." />
  //                         : <img
  //                             src={avatar}
  //                             className={classes.avatarImg} alt="..." />
  //                       }
  //                     </div>
  //                     <div style={{textAlign: 'center', marginTop: '10px', marginBottom: '-10px'}}>
  //                       Need Help?
  //                     </div>
  //                     { !miniActive
  //                       ? <>
  //                           <div className={classes.accountRepFullName}>
  //                             <h3>{accountRep.fullName}</h3>
  //                           </div>
  //                           <div className={classes.accountRepPhone}>
  //                             {formatPhoneNumber(accountRep.phoneNumber)}
  //                           </div>
  //                           <div className={classes.accountRepEmail}>
  //                             <a href={"mailto:" + accountRep.email} target="_blank" rel="noopener noreferrer">{accountRep.email}</a>
  //                           </div>
  //                         </>
  //                       : <></>
  //                     }
  //                   </>
  //                 )
  //               : (<div/>)
  //             }
  //         </div>
  //       )
  //   }}
  // </Query>)
}

const mapStateToProps = state => {
  return {
    accountRep: state.accountRep,
  };
};

export default connect(
  mapStateToProps,
  null
)(CompanyProfileSidebarBlock);
