import React, { useState } from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import Tile from "../../components/Tile/Tile";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LinearProgress } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const styles = {
  dashboardContainer: {
    minHeight: "80vh",
  },
  iframeContainer: {
    minHeight: "80vh",
  },
};

const useStyles = makeStyles(styles);

const GET_DASHBOARDS = gql`
  query dashboards {
    dashboards {
      dashboardId
      dashboardName
      dashboardDescription
      dashboardUrl
      superAdminOnly
    }
  }
`;

const DealerDashboards = props => {
  const classes = useStyles();
  // const [redirecting, setRedirecting] = useState(false);
  const account = useSelector(state => state.account);

  const [dashboardId, setDashboardId] = useState(false);
  const [refetching, setRefetching] = useState(false);

  return (
    <div className={classes.root}>
      {
        <Query context={{ authRequired: true }} query={GET_DASHBOARDS} fetchPolicy="no-cache">
          {({ loading, error, data, refetch }) => {
            if (loading) return <LinearProgress />;
            if (error) return <h3>{String(error)}</h3>;

            const handleDashboardChange = async event => {
              console.log(`handleDashboardChange: ${event.target.value}`);
              setRefetching(true);
              await refetch();
              setDashboardId(event.target.value);
            };

            // If this user only has 1 dashboard, don't make them select it, just display it.
            if (data.dashboards.length === 1) {
              setDashboardId(data.dashboards[0].dashboardId);
            }

            return (
              <>
                <Grid>
                  <Grid item xs={12}>
                    <Tile>
                      <div className={classes.dashboardContainer}>
                        {data.dashboards.length > 1 && (
                          <FormControl fullWidth>
                            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                              Select Dashboard
                            </InputLabel>
                            <Select value={dashboardId ? dashboardId : null} onChange={handleDashboardChange}>
                              {!loading &&
                                data &&
                                data.dashboards.map(db => (
                                  <MenuItem value={db.dashboardId} key={db.dashboardId}>
                                    {db.dashboardName}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )}

                        <div className={classes.iframeContainer}>
                          {refetching && (
                            <span>
                              <CircularProgress size={16} /> Loading Dashboard
                            </span>
                          )}
                          {dashboardId && (
                            <iframe
                              style={{
                                display: refetching ? "none" : "inherit",
                                minHeight: "79vh",
                              }}
                              width="100%"
                              frameBorder="0"
                              src={data.dashboards.find(db => db.dashboardId === dashboardId).dashboardUrl}
                              onLoad={() => {
                                setTimeout(() => {
                                  setRefetching(false);
                                }, 2000);
                              }}
                            ></iframe>
                          )}
                        </div>
                      </div>
                    </Tile>
                  </Grid>
                </Grid>
              </>
            );
          }}
        </Query>
      }
    </div>
  );
};

export default DealerDashboards;
