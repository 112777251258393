import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, DialogContent, DialogTitle, FormControl, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { setRefetch } from "../../redux/actions";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { AddOutlined, Check, RemoveOutlined } from "@material-ui/icons";
import { formatCurrency, parseCurrency } from "utils";

const useStyles = makeStyles({
  textField: {
    margin: "12px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  // ...styles
});
const useRadioStyles = makeStyles(customCheckboxRadioSwitch);
const useStylesPaper = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "400px",
    maxWidth: "500px",
  },
  paper: {
    minWidth: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[6],
    overFlowX: "hidden",
  },
  margin: {
    marginBottom: "8px",
  },
  marginDivider: {
    marginTop: "33px",
    marginBottom: "8px",
  },
  invoiceTotalMargin: {
    "& .MuiDivider-root": {
      border: "solid 10px black",
      marginBottom: "8px",
      marginTop: "16px",
      fontWeight: 900,
    },
  },
}));

const UPDATE_VO_CONTRACT_MUTATION = gql`
  mutation($fieldsObj: UpdateVendorOpportunityInput!, $VOId: String!) {
    updateVendorOpportunity(fieldsObj: $fieldsObj, VOId: $VOId)
  }
`;

function AddNewContractModal({ open, handleClose, account, dynamicsAccountId, accountId, VOId, vo, refetchVO }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const classesRadio = useRadioStyles();

  const classesPaper = useStylesPaper();
  const [showLoanOptions, setShowLoanOptions] = useState(false);
  const [showLeaseOptions, setShowLeaseOptions] = useState(false);

  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openLoadingSnackbar, setOpenLoadingSnackbar] = React.useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);
  const handleInputChange = (e, name) => {
    if (name === "leaseRateMoneyFactor" || name === "annualPercentageRate" || name === "lengthOfContractInMonths") {
      setValues({ ...values, [name]: e.target.value });
    }
    setValues({ ...values, [name]: parseCurrency(e.target.value) });
  };
  const [values, setValues] = useState({
    amountRequested: vo.amountRequested || 0.0,
    equipmentCost: vo.equipmentCost || 0.0,
    salesTax: vo.salesTax || 0.0,
    additionalFees: vo.additionalFees || 0.0,
    lengthOfContractInMonths: vo.lengthOfContractInMonths || 0.0,
    annualPercentageRate: vo.annualPercentageRate || 0.0,
    totalMonthlyPaymentEstimated: vo.totalMonthlyPaymentEstimated || 0.0,
    vehiclePriceWholesaleInvoice: vo.vehiclePriceWholesaleInvoice || 0.0,
    vehiclePriceMSRP: vo.vehiclePriceMSRP || 0.0,
    downPaymentAmount: vo.downPaymentAmount || 0.0,
    manufacturerRebateAmount: vo.manufacturerRebateAmount || 0.0,
    tradeAllowanceGrossTradeIn: vo.tradeAllowanceGrossTradeIn || 0.0,
    tradeOwedBalanceAmount: vo.tradeOwedBalanceAmount || 0.0,
    creditLifePremiumAmount: vo.creditLifePremiumAmount || 0.0,
    disabilityPremiumAmount: vo.disabilityPremiumAmount || 0.0,
    msrpGuidePercentage: vo.msrpGuidePercentage || 0.0,
    residualAmount: vo.residualAmount || 0.0,
    leaseRatePercentage: vo.leaseRatePercentage || 0.0,
    leaseRateMoneyFactor: vo.leaseRateMoneyFactor || 0.0,
    totalExtendedWarrantyAmount: vo.totalExtendedWarrantyAmount || 0.0,
    vehicleSellingPrice: vo.vehicleSellingPrice || 0.0,
    baseMonthlyPayment: vo.baseMonthlyPayment || 0.0,
    insuranceTotalExtendedWarrantyAmount: vo.insuranceTotalExtendedWarrantyAmount || 0.0,
    extendedWarrantyAmount: vo.extendedWarrantyAmount || 0.0,
    gapInsuranceAmount: vo.gapInsuranceAmount || 0.0,
    roadsideCoverageAmount: vo.roadsideCoverageAmount || 0.0,
    roadHazardAmount: vo.roadHazardAmount || 0.0,
    docFeeAmount: vo.docFeeAmount || 0.0,
    physicalDamageCoverageAmount: vo.physicalDamageCoverageAmount || 0.0,
    federalExciseTaxAmount: vo.federalExciseTaxAmount || 0.0,
  });
  const [updateVOContract, { data, error, loading }] = useMutation(UPDATE_VO_CONTRACT_MUTATION, {
    context: { authRequired: true },
  });

  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };
  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  const handleSubmit = event => {
    event.preventDefault();

    setOpenLoadingSnackbar(true);
    setOpenSuccessSnackbar(true);
    setOpenErrorSnackbar(true);

    updateVOContract({
      variables: {
        fieldsObj: {
          amountRequested: parseFloat(values["amountRequested"]),
          salesTax: parseFloat(values["salesTax"]),
          additionalFees: parseFloat(values["additionalFees"]),
          lengthOfContractInMonths: parseInt(values["lengthOfContractInMonths"]),
          annualPercentageRate: parseFloat(values["annualPercentageRate"]),
          totalMonthlyPaymentEstimated: parseFloat(values["totalMonthlyPaymentEstimated"]),
          vehiclePriceWholesaleInvoice: parseFloat(values["vehiclePriceWholesaleInvoice"]),
          vehiclePriceMSRP: parseFloat(values["vehiclePriceMSRP"]),
          downPaymentAmount: parseFloat(values["downPaymentAmount"]),
          manufacturerRebateAmount: parseFloat(values["manufacturerRebateAmount"]),
          tradeAllowanceGrossTradeIn: parseFloat(values["tradeAllowanceGrossTradeIn"]),
          tradeOwedBalanceAmount: parseFloat(values["tradeOwedBalanceAmount"]),
          creditLifePremiumAmount: parseFloat(values["creditLifePremiumAmount"]),
          disabilityPremiumAmount: parseFloat(values["disabilityPremiumAmount"]),
          msrpGuidePercentage: isNaN(parseFloat(values["residualAmount"]) / parseFloat(values["vehiclePriceMSRP"]))
            ? 0
            : parseFloat(values["residualAmount"]) / parseFloat(values["vehiclePriceMSRP"]),
          residualAmount: parseFloat(values["residualAmount"]),
          leaseRatePercentage: parseFloat(values["leaseRatePercentage"]),
          leaseRateMoneyFactor: parseFloat(values["leaseRateMoneyFactor"]),
          totalExtendedWarrantyAmount: parseFloat(values["totalExtendedWarrantyAmount"]),
          vehicleSellingPrice: parseFloat(values["vehicleSellingPrice"]),
          baseMonthlyPayment: values["baseMonthlyPayment"] ? parseFloat(values["baseMonthlyPayment"]) : null,
          extendedWarrantyAmount: parseFloat(values["extendedWarrantyAmount"]),
          gapInsuranceAmount: parseFloat(values["gapInsuranceAmount"]),
          roadsideCoverageAmount: parseFloat(values["roadsideCoverageAmount"]),
          roadHazardAmount: parseFloat(values["roadHazardAmount"]),
          docFeeAmount: parseFloat(values["docFeeAmount"]),
          physicalDamageCoverageAmount: parseFloat(values["physicalDamageCoverageAmount"]),
          federalExciseTaxAmount: parseFloat(values["federalExciseTaxAmount"]),
        },
        VOId: VOId,
      },
    }).then(() => {
      setTimeout(() => refetchVO(), 3000);
    });
  };

  return (
    <div style={{ width: "500px" }}>
      <Dialog onClose={handleClose} open={open} scroll={"body"} maxWidth={"sm"} fullWidth>
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "space-between",
            paddingBottom: 0,
          }}
        >
          <h4>
            <b>Contract Details</b>
          </h4>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        </DialogTitle>
        {data ? (
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "20rem",
              }}
            >
              <Check style={{ color: "#4caf50", fontSize: 64 }} />
              <h4>Contract Updated!</h4>
            </div>
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["gapInsuranceAmount"]) === ""
                            ? "$0"
                            : formatCurrency(values["gapInsuranceAmount"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Gap Insurance Amount"
                        id="gap-insurance-amount"
                        onChange={e => handleInputChange(e, "gapInsuranceAmount")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["equipmentCost"]) === ""
                            ? "$0"
                            : formatCurrency(values["equipmentCost"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Equipment Subtotal"
                        id="equipment-amount"
                        onChange={e => handleInputChange(e, "equipmentCost")}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["roadsideCoverageAmount"]) === ""
                            ? "$0"
                            : formatCurrency(values["roadsideCoverageAmount"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Roadside Coverage Amount"
                        id="roadside-coverage-amount"
                        onChange={e => handleInputChange(e, "roadsideCoverageAmount")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["roadHazardAmount"]) === ""
                            ? "$0"
                            : formatCurrency(values["roadHazardAmount"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Road Hazard Amount"
                        id="road-hazard-amount"
                        onChange={e => handleInputChange(e, "roadHazardAmount")}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["docFeeAmount"]) === "" ? "$0" : formatCurrency(values["docFeeAmount"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Doc Fee Amount"
                        id="doc-fee-amount"
                        onChange={e => handleInputChange(e, "docFeeAmount")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["physicalDamageCoverageAmount"]) === ""
                            ? "$0"
                            : formatCurrency(values["physicalDamageCoverageAmount"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Physical Damage Coverage Amount"
                        id="physical-damage-coverage-amount"
                        onChange={e => handleInputChange(e, "physicalDamageCoverageAmount")}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["federalExciseTaxAmount"]) === ""
                            ? "$0"
                            : formatCurrency(values["federalExciseTaxAmount"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Federal Excise Tax Amount"
                        id="federal-excise-tax-amount"
                        onChange={e => handleInputChange(e, "federalExciseTaxAmount")}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem lg={6} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        value={
                          formatCurrency(values["extendedWarrantyAmount"]) === ""
                            ? "$0"
                            : formatCurrency(values["extendedWarrantyAmount"])
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Extended Warranty Amount"
                        id="extended-warranty-amount"
                        onChange={e => handleInputChange(e, "extendedWarrantyAmount")}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <div
                    style={{ display: "flex", gap: 4, cursor: "pointer", alignItems: "center" }}
                    onClick={() => setShowLoanOptions(!showLoanOptions)}
                  >
                    <p style={{ margin: "16px 0" }}>
                      <b>Loan Details</b>
                    </p>
                    {showLoanOptions ? <RemoveOutlined /> : <AddOutlined />}
                  </div>
                </GridItem>
                {showLoanOptions && (
                  <>
                    <GridContainer>
                      <GridItem item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cash Price"
                            variant="outlined"
                            value={
                              formatCurrency(values["amountRequested"]) === ""
                                ? "$0"
                                : formatCurrency(values["amountRequested"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "amountRequested")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="cashPrice"
                            formControlProps={{ fullWidth: true, required: true }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Term"
                            variant="outlined"
                            value={values["lengthOfContractInMonths"]}
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "lengthOfContractInMonths")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="term"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "lengthOfContractInMonths",
                              onChange: handleInputChange,
                              autoComplete: "lengthOfContractInMonths",
                              required: true,
                              autoFocus: true,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Taxes"
                            variant="outlined"
                            value={
                              formatCurrency(values["salesTax"]) === "" ? "$0" : formatCurrency(values["salesTax"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "salesTax")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="taxes"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "salesTax",
                              onChange: handleInputChange,
                              autoComplete: "salesTax",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Customer Rate %"
                            variant="outlined"
                            value={values["annualPercentageRate"]}
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "annualPercentageRate")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="customerRate"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "annualPercentageRate",
                              onChange: handleInputChange,
                              autoComplete: "annualPercentageRate",
                              required: true,
                              autoFocus: true,
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Title/Lic/Other Fees"
                            variant="outlined"
                            value={
                              formatCurrency(values["additionalFees"]) === ""
                                ? "$0"
                                : formatCurrency(values["additionalFees"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "additionalFees")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="additionalFees"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "additionalFees",
                              onChange: handleInputChange,
                              autoComplete: "additionalFees",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Monthly Payment"
                            variant="outlined"
                            value={
                              formatCurrency(values["totalMonthlyPaymentEstimated"]) === ""
                                ? "$0"
                                : formatCurrency(values["totalMonthlyPaymentEstimated"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "totalMonthlyPaymentEstimated")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="totalMonthlyPaymentEstimated"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "totalMonthlyPaymentEstimated",
                              onChange: handleInputChange,
                              autoComplete: "totalMonthlyPaymentEstimated",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cash Down"
                            variant="outlined"
                            value={
                              formatCurrency(values["downPaymentAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["downPaymentAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "downPaymentAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="cashDown"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "downPaymentAmount",
                              onChange: handleInputChange,
                              autoComplete: "downPaymentAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Wholesale/Invoice"
                            variant="outlined"
                            value={
                              formatCurrency(values["vehiclePriceWholesaleInvoice"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceWholesaleInvoice"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "vehiclePriceWholesaleInvoice")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="invoice"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "vehiclePriceWholesaleInvoice",
                              onChange: handleInputChange,
                              autoComplete: "vehiclePriceWholesaleInvoice",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Rebate"
                            variant="outlined"
                            value={
                              formatCurrency(values["manufacturerRebateAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["manufacturerRebateAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "manufacturerRebateAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="rebate"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "manufacturerRebateAmount",
                              onChange: handleInputChange,
                              autoComplete: "manufacturerRebateAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="MSRP/Retail"
                            variant="outlined"
                            value={
                              formatCurrency(values["vehiclePriceMSRP"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceMSRP"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "vehiclePriceMSRP")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="msrp"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "vehiclePriceMSRP",
                              onChange: handleInputChange,
                              autoComplete: "vehiclePriceMSRP",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Trade Allowance"
                            variant="outlined"
                            value={
                              formatCurrency(values["tradeAllowanceGrossTradeIn"]) === ""
                                ? "$0"
                                : formatCurrency(values["tradeAllowanceGrossTradeIn"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "tradeAllowanceGrossTradeIn")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="tradeAllowance"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "tradeAllowanceGrossTradeIn",
                              onChange: handleInputChange,
                              autoComplete: "tradeAllowanceGrossTradeIn",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Trade Owed"
                            variant="outlined"
                            value={
                              formatCurrency(values["tradeOwedBalanceAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["tradeOwedBalanceAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "tradeOwedBalanceAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="tradeOwed"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "tradeOwedBalanceAmount",
                              onChange: handleInputChange,
                              autoComplete: "tradeOwedBalanceAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Credit Life"
                            variant="outlined"
                            value={
                              formatCurrency(values["creditLifePremiumAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["creditLifePremiumAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "creditLifePremiumAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="creditLife"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "creditLifePremiumAmount",
                              onChange: handleInputChange,
                              autoComplete: "creditLifePremiumAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Disability"
                            variant="outlined"
                            value={
                              formatCurrency(values["disabilityPremiumAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["disabilityPremiumAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "disabilityPremiumAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="disability"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "disabilityPremiumAmount",
                              onChange: handleInputChange,
                              autoComplete: "disabilityPremiumAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </>
                )}
                <GridItem xs={12}>
                  <div
                    style={{ display: "flex", gap: 4, cursor: "pointer", alignItems: "center" }}
                    onClick={() => setShowLeaseOptions(!showLeaseOptions)}
                  >
                    <p style={{ margin: "16px 0" }}>
                      <b>Lease Details</b>
                    </p>
                    {showLeaseOptions ? <RemoveOutlined /> : <AddOutlined />}
                  </div>
                </GridItem>
                {showLeaseOptions && (
                  <>
                    <GridContainer>
                      <GridItem item lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cap Cost"
                            variant="outlined"
                            value={
                              formatCurrency(values["amountRequested"]) === ""
                                ? "$0"
                                : formatCurrency(values["amountRequested"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "amountRequested")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="capCost"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "amountRequested",
                              onChange: handleInputChange,
                              autoComplete: "amountRequested",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Term"
                            variant="outlined"
                            value={values["lengthOfContractInMonths"]}
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "lengthOfContractInMonths")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="term"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "lengthOfContractInMonths",
                              onChange: handleInputChange,
                              autoComplete: "lengthOfContractInMonths",
                              required: true,
                              autoFocus: true,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Credit Life"
                            variant="outlined"
                            value={
                              formatCurrency(values["creditLifePremiumAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["creditLifePremiumAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "creditLifePremiumAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="creditLife"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "creditLifePremiumAmount",
                              onChange: handleInputChange,
                              autoComplete: "creditLifePremiumAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Base Monthly Payment"
                            variant="outlined"
                            value={
                              formatCurrency(values["baseMonthlyPayment"]) === ""
                                ? "$0"
                                : formatCurrency(values["baseMonthlyPayment"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "baseMonthlyPayment")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="baseMonthlyPayment"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "baseMonthlyPayment",
                              onChange: handleInputChange,
                              autoComplete: "baseMonthlyPayment",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Disability"
                            variant="outlined"
                            value={
                              formatCurrency(values["disabilityPremiumAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["disabilityPremiumAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "disabilityPremiumAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="disability"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "disabilityPremiumAmount",
                              onChange: handleInputChange,
                              autoComplete: "disabilityPremiumAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Total Monthly Payment"
                            variant="outlined"
                            value={
                              formatCurrency(values["totalMonthlyPaymentEstimated"]) === ""
                                ? "$0"
                                : formatCurrency(values["totalMonthlyPaymentEstimated"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "totalMonthlyPaymentEstimated")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="totalPayment"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "totalMonthlyPaymentEstimated",
                              onChange: handleInputChange,
                              autoComplete: "totalMonthlyPaymentEstimated",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Ins/SVC/Other"
                            variant="outlined"
                            value={
                              formatCurrency(values["totalExtendedWarrantyAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["totalExtendedWarrantyAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "totalExtendedWarrantyAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="insOther"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "totalExtendedWarrantyAmount",
                              onChange: handleInputChange,
                              autoComplete: "totalExtendedWarrantyAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Wholesale/Invoice"
                            variant="outlined"
                            value={
                              formatCurrency(values["vehiclePriceWholesaleInvoice"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceWholesaleInvoice"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "vehiclePriceWholesaleInvoice")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="invoice"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "vehiclePriceWholesaleInvoice",
                              onChange: handleInputChange,
                              autoComplete: "vehiclePriceWholesaleInvoice",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cash"
                            variant="outlined"
                            value={
                              formatCurrency(values["downPaymentAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["downPaymentAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "downPaymentAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="cash"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "downPaymentAmount",
                              onChange: handleInputChange,
                              autoComplete: "downPaymentAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="MSRP/Retail"
                            variant="outlined"
                            value={
                              formatCurrency(values["vehiclePriceMSRP"]) === ""
                                ? "$0"
                                : formatCurrency(values["vehiclePriceMSRP"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "vehiclePriceMSRP")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="msrp"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "vehiclePriceMSRP",
                              onChange: handleInputChange,
                              autoComplete: "vehiclePriceMSRP",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Rebate"
                            variant="outlined"
                            value={
                              formatCurrency(values["manufacturerRebateAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["manufacturerRebateAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "manufacturerRebateAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="rebate"
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "manufacturerRebateAmount",
                              onChange: handleInputChange,
                              autoComplete: "manufacturerRebateAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Residual %"
                            variant="outlined"
                            value={values["msrpGuidePercentage"]}
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "msrpGuidePercentage")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="residual"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Trade Allowance"
                            variant="outlined"
                            value={
                              formatCurrency(values["tradeAllowanceGrossTradeIn"]) === ""
                                ? "$0"
                                : formatCurrency(values["tradeAllowanceGrossTradeIn"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "tradeAllowanceGrossTradeIn")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="tradeAllowance"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "tradeAllowanceGrossTradeIn",
                              onChange: handleInputChange,
                              autoComplete: "tradeAllowanceGrossTradeIn",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Residual Value"
                            variant="outlined"
                            value={
                              formatCurrency(values["residualAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["residualAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "residualAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="residualValue"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "residualAmount",
                              onChange: handleInputChange,
                              autoComplete: "residualAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Trade Owed"
                            variant="outlined"
                            value={
                              formatCurrency(values["tradeOwedBalanceAmount"]) === ""
                                ? "$0"
                                : formatCurrency(values["tradeOwedBalanceAmount"])
                            }
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "tradeOwedBalanceAmount")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="tradeOwed"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "tradeOwedBalanceAmount",
                              onChange: handleInputChange,
                              autoComplete: "tradeOwedBalanceAmount",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Cust. Rate/Money Factor"
                            variant="outlined"
                            value={values["leaseRateMoneyFactor"]}
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "leaseRateMoneyFactor")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="leaseRateMoneyFactor"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            inputProps={{
                              name: "leaseRateMoneyFactor",
                              onChange: handleInputChange,
                              autoComplete: "leaseRateMoneyFactor",
                              required: true,
                              autoFocus: true,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={6} xs={12}>
                        <FormControl fullWidth>
                          <TextField
                            label="Net Trade"
                            variant="outlined"
                            className={classes.textField}
                            onChange={e => handleInputChange(e, "netTrade")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            id="netTrade"
                            lowerCase={true}
                            formControlProps={{ fullWidth: true, required: true }}
                            value={
                              formatCurrency(values["tradeAllowanceGrossTradeIn"]) === ""
                                ? "$0"
                                : formatCurrency(values["tradeAllowanceGrossTradeIn"])
                            }
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    {/* <GridContainer>
                      <GridItem lg={6} xs={12}>
                      <FormControl fullWidth>  
                      <TextField
                          label="Net Cap Cost"
                          variant="outlined"
                          value={values[]}
                          className={classes.textField}
                          onChange={(e) => handleInputChange(e, "")}
                          InputLabelProps={{
                              shrink: true,
                            }}
                          id="netCapCost"
                          formControlProps={{ fullWidth: true, required: true }}
                          disabled
                          inputProps={{
                            autoFocus: true,
                            startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                            value: isNaN(parseFloat(values['amountRequested']) - (parseFloat(values['downPaymentAmount']) + parseFloat(values['manufacturerRebateAmount']))) ? '0' : parseFloat(values['amountRequested']) - (parseFloat(values['downPaymentAmount']) + parseFloat(values['manufacturerRebateAmount']))
                          }}
                        />
                      </GridItem>
                    </GridContainer> */}
                  </>
                )}
                <GridItem xs={12}>
                  <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 16 }}>
                    <Button
                      type="submit"
                      color="primary"
                      style={{
                        color: "#fff",
                        backgroundColor: theme.palette.primary.main,
                        fontSize: 14,
                        padding: "4px 12px",
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </GridItem>
              </form>
              {loading && (
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={openLoadingSnackbar}
                  autoHideDuration={5000}
                  onClose={handleLoadingSnackbarClose}
                >
                  <Alert onClose={handleLoadingSnackbarClose} severity="info">
                    Loading...
                  </Alert>
                </Snackbar>
              )}
              {error && (
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={openErrorSnackbar}
                  autoHideDuration={5000}
                  onClose={handleErrorSnackbarClose}
                >
                  <Alert onClose={handleErrorSnackbarClose} severity="alert">
                    Create New Contract error!
                  </Alert>
                </Snackbar>
              )}
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
});

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewContractModal);
