import React from "react";
import { FormCheckbox } from "../FormCheckbox";
import { Controller } from "react-hook-form";
import { CheckboxProps } from "@material-ui/core";
import { Control } from "react-hook-form/dist/types/form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

interface Props<FormValues extends FieldValues = FieldValues> {
  label: string;
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
  checkboxProps?: CheckboxProps;
}

export const CheckboxField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  checkboxProps = {},
}: Props<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <FormCheckbox label={label} {...field} {...checkboxProps} />}
    />
  );
};
