import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MessageModal from "../../components/MessageModal";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import NumberFormat from "react-number-format";
import TableContainer from "@material-ui/core/TableContainer";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import formatString from "format-string-by-pattern";

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  formControlInputs: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControlRate: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 200,
  },
}));

const useRadioStyles = makeStyles(customCheckboxRadioSwitch);

const M_SEND_PROPOSAL = gql`
  mutation($input: SendProposalInput!) {
    sendProposal(input: $input) {
      success
      errorMessage
    }
  }
`;

function ResidualNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={"##"}
    />
  );
}

ResidualNumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function SalesTaxNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={"#.###"}
    />
  );
}

SalesTaxNumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ReSendProposalModal = ({
  userProfile,
  account,
  vp,
  vo,
  open,
  handleClose,
  proposalId,
  proposalApplicant,
  proposalEmail,
  proposal,
}) => {
  const [email, setEmail] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [applicantNameError, setApplicantNameError] = useState(false);
  const [applicantNameMessage, setApplicantNameMessage] = useState("Required");
  const [emailErrorMessage, setEmailErrorMessage] = useState("Required");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("Please Enter A Valid Phone Number");
  const handleChangedEmail = event => setEmail(event.target.value);
  const handleChangedPhone = value => setPhone(value);
  const handleChangedApplicantName = value => setApplicantName(value);

  const upperCaseFirstLetters = value => {
    let formattedValue = value.split("");

    return formattedValue
      .map((letter, i) => {
        if (i === 0 || formattedValue[i - 1] === " " || formattedValue[i - 1] === ".") {
          return letter.toUpperCase();
        }

        return letter.toLowerCase();
      })
      .join("");
  };
  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);

  useEffect(() => {
    if (proposal) {
      if (proposal.fullName) {
        setApplicantName(proposal.fullName);
      }
      if (proposal.firstName !== "" || proposal.lastName !== "") {
        setApplicantName(`${proposal.firstName} ${proposal.lastName}`.trim());
      }
      setEmail(proposal.email);
    }
  }, [proposal]);

  const [sendProposal, { data, error, loading }] = useMutation(M_SEND_PROPOSAL);

  useEffect(() => {
    setApplicantNameError(!applicantName);
  }, [applicantName]);

  useEffect(() => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setEmailError(true);
      setEmailErrorMessage("Required");
    } else if (re.test(String(email).toLowerCase())) {
      setEmailError(false);
      setEmailErrorMessage("");
    } else {
      setEmailError(true);
      setEmailErrorMessage("Please ensure email has been entered correctly");
    }
  }, [email]);

  useEffect(() => {
    if (!phone) {
      setPhoneError(true);
      setPhoneErrorMessage("Required");
    } else if (phone.length > 0 && phone.replace(/[\D]+/g, "").length < 10) {
      setPhoneError(true);
      setPhoneErrorMessage("Please enter a valid 10 Digit Phone Number");
    } else {
      setPhoneError(false);
    }
  }, [phone]);

  const sendLink = async () => {
    if (!phoneError && !emailError && !applicantNameError) {
      try {
        handleOpenModal();
        handleSubmissionMessage({
          title: "Sending",
          message: "Please wait. Sending proposal...",
        });
        await sendProposal({
          variables: {
            input: {
              id: proposalId,
              vendorOpportunityId: vo.vendorOpportunityId,
              accountId: account.id,
              vendorSalespersonId: userProfile.id,
              fullName: applicantName,
              email: email,
              phone: phone && phone.replace(/[\D]+/g, ""),
              status: "Proposal Sent",
            },
          },
        }).then(resp => {
          if (!resp.data.sendProposal.success) {
            handleSubmissionMessage({
              title: "Error",
              message: resp.data.sendProposal.errorMessage,
            });
          } else {
            handleSubmissionMessage({
              title: "Success",
              message: "Sent Successfully",
            });
            handleClose();
          }
        });
      } catch (err) {
        console.log(err);
        handleSubmissionMessage({
          title: "Error",
          message: "There was something wrong with your request",
        });
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"xl"} scroll={"body"}>
      <br />
      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <CardHeader title="Resend Proposal" />
            <Divider />
            <CardContent style={{ padding: 0 }}>
              <br />
              <TableContainer component={Paper}>
                <Table
                  style={{
                    tableLayout: "fixed",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <FormControl>
                          <TextField
                            label="Applicant Name"
                            value={applicantName}
                            onChange={e => {
                              const value = upperCaseFirstLetters(e.target.value);
                              handleChangedApplicantName(value);
                            }}
                            defaultValue={proposalApplicant && proposalApplicant.trim()}
                            required={true}
                            error={applicantNameError}
                            helperText={applicantNameError && applicantNameMessage}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl>
                          <TextField
                            label="Email"
                            value={email}
                            onChange={handleChangedEmail}
                            required={true}
                            error={emailError}
                            helperText={emailError && emailErrorMessage}
                            defaultValue={proposalEmail}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl>
                          <TextField
                            label="Phone"
                            value={phone}
                            onChange={e => {
                              const value = formatString("(999) 999-9999", e.target.value.replace(/[^\d-() ]/, ""));
                              handleChangedPhone(value);
                            }}
                            error={phoneError}
                            helperText={phoneError && phoneErrorMessage}
                            required={true}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Button color="primary" onClick={sendLink}>
                          Send Proposal
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Paper>
        </Grid>
        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseModal}
          title={submissionMessage.title}
          message={submissionMessage.message}
        />
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  userProfile: state.userProfile,
  account: state.account,
  vp: state.vp,
  vo: state.vo,
});

export default connect(
  mapStateToProps,
  null
)(ReSendProposalModal);
