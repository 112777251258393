import { ProposalMenu } from "@trnsact/trnsact-shared-types";
import { MenuOptionToAddEdit, MenuTemplatesTableFilters } from "../types";

export const menuTemplatesTableInitialFilters: MenuTemplatesTableFilters = { search: "" };

export const initialProposalMenu: Partial<ProposalMenu> = {
  name: "",
  description: "",
  menuOptions: [],
};

export const initialMenuOption: MenuOptionToAddEdit = {
  name: "",
  description: "",
  products: [],
  titleColor: "#000000",
  titleBgColor: "#ffffff",
  archived: false,
};
