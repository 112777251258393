import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import _ from "lodash";
import formatString from "format-string-by-pattern";

const styles = makeStyles(theme => ({
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },
  textField: {
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    background: "white",
    padding: "0 4px",
  },
  locationTree: {
    padding: "10px 0",
    "& .tag-item": {
      position: "inherit !important",
    },
    "& .tag-item span": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "#f4f4f4",
      border: "1px solid #e9e9e9",
      padding: "2px 0 2px 8px",
      borderRadius: "4px",
      display: "inline-block",
    },
    "& .tag-item button": {
      display: "inline-block",
      fontSize: "100%",
    },
    "& .tag-item span.placeholder": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "transparent",
      border: "none",
      padding: "0",
      borderRadius: "0",
      display: "inline-block",
    },
  },
  selectStyles: {
    height: 40,
    marginBottom: 8,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

const SIGNUP_USER_MUTATION = gql`
  mutation(
    $email: String!
    $firstName: String
    $lastName: String
    $title: String
    $phone: String
    $dynamicsAccountId: String
    $accountId: String
    $vendorContactRole: Int
    $locationId: ID
    $sendInvitation: Boolean
    $isDealerInviteFromLender: Boolean
    $lenderProfileId: ID
  ) {
    adminCreateUser(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        title: $title
        phone: $phone
        dynamicsAccountId: $dynamicsAccountId
        accountId: $accountId
        vendorContactRole: $vendorContactRole
        locationId: $locationId
        sendInvitation: $sendInvitation
        isDealerInviteFromLender: $isDealerInviteFromLender
        lenderProfileId: $lenderProfileId
      }
    ) {
      status
      message
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation($entityId: String!, $fieldsObj: AdminUpdateUserProfileInput!) {
    adminUpdateUserProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

const vendorContactRoleEnumValue = {
  sales_rep: 100000000,
  sales_mgr: 100000001,
  admin: 100000002,
  executive: 100000003,
  credit_mgr: 100000004,
  support_person: 100000005,
};

const ContactModal = ({
  contact,
  open,
  handleClose,
  create,
  dynamicsAccountId,
  accountId,
  lenderProfileId,
  refetchContacts,
  disableEdit,
}) => {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [removingContact, setRemovingContact] = useState(false);
  const [modalContact, setModalContact] = useState({});
  const [sendInvite, setSendInvite] = useState(true);

  const [requestSignupUser] = useMutation(SIGNUP_USER_MUTATION, {
    context: { authRequired: false },
  });

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);

  useEffect(() => {
    if (contact) {
      setModalContact({ ...contact, vendorContactRole: vendorContactRoleEnumValue[contact.vendorContactRole] });
    }
  }, [contact]);

  const onClose = () => {
    handleClose();
    setTimeout(() => {
      setSendInvite(true);
      setModalContact({});
    }, 1500);
  };

  const handleSubmit = async () => {
    if (_.isEmpty(modalContact)) {
      return;
    }

    setLoading(true);

    if (create) {
      await requestSignupUser({
        variables: {
          email: modalContact.email,
          firstName: modalContact.firstName,
          lastName: modalContact.lastName,
          title: modalContact.title,
          phone: modalContact.phoneNumber,
          dynamicsAccountId: dynamicsAccountId,
          accountId: accountId,
          vendorContactRole: 100000000,
          sendInvitation: sendInvite,
          isDealerInviteFromLender: true,
          lenderProfileId,
        },
      });
    } else {
      await updateUserProfile({
        variables: {
          entityId: modalContact.dynamicsContactId,
          fieldsObj: {
            id: modalContact.userProfileId,
            firstName: modalContact.firstName,
            lastName: modalContact.lastName,
            fullName: `${modalContact.firstName} ${modalContact.lastName}`,
            phoneNumber: modalContact.phoneNumber,
            vendorContactRole: modalContact.vendorContactRole,
            title: modalContact.title,
            email: modalContact.email,
          },
        },
      });
    }
    refetchContacts();
    setLoading(false);
    onClose();
  };

  const handleRemove = async () => {
    setLoading(true);
    await updateUserProfile({
      variables: {
        entityId: modalContact.dynamicsContactId,
        fieldsObj: {
          id: modalContact.userProfileId,
          firstName: modalContact.firstName,
          lastName: modalContact.lastName,
          fullName: `${modalContact.firstName} ${modalContact.lastName}`,
          phoneNumber: modalContact.phoneNumber,
          vendorContactRole: modalContact.vendorContactRole,
          title: modalContact.title,
          email: modalContact.email,
          status: "blocked",
        },
      },
    });
    refetchContacts();
    setRemovingContact(false);
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
        <h4>
          <b>Contact</b>
        </h4>
        <IconButton className={classes.closeBtn} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      {loading ? (
        <DialogContent
          style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: 320 }}
        >
          <CircularProgress size={72} />
        </DialogContent>
      ) : (
        <>
          {removingContact ? (
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 320,
              }}
            >
              <h4>Are you sure you want to remove this contact?</h4>
              <div style={{ display: "flex", gap: 4, marginTop: 24 }}>
                <Button color="primary" onClick={() => setRemovingContact(false)}>
                  No
                </Button>
                <Button color="default" onClick={() => handleRemove()}>
                  Yes
                </Button>
              </div>
            </DialogContent>
          ) : (
            <DialogContent>
              <div style={{ display: "flex", width: "100%", gap: 12 }}>
                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  label={"First Name"}
                  required={true}
                  value={modalContact?.firstName || contact?.firstName}
                  style={{ width: "100%" }}
                  onChange={e => setModalContact({ ...modalContact, firstName: e.target.value })}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={contact?.status === "active"}
                />
                <TextField
                  className={classes.textField}
                  fullWidth={true}
                  label={"Last Name"}
                  required={true}
                  value={modalContact?.lastName || contact?.lastName}
                  onChange={e => setModalContact({ ...modalContact, lastName: e.target.value })}
                  style={{ width: "100%" }}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={contact?.status === "active"}
                />
              </div>
              <TextField
                className={classes.textField}
                fullWidth={true}
                label={"Title"}
                required={true}
                value={modalContact?.title || contact?.title}
                onChange={e => setModalContact({ ...modalContact, title: e.target.value })}
                style={{ width: "100%" }}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={contact?.status === "active"}
              />
              <TextField
                className={classes.textField}
                fullWidth={true}
                label={"Email"}
                required={true}
                value={modalContact?.email || contact?.email || contact?.notificationContact}
                onChange={e => setModalContact({ ...modalContact, email: e.target.value })}
                style={{ width: "100%" }}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!create || contact?.status === "active"}
                // error={fieldsEmpty && !modalContact?.email}
              />
              <TextField
                className={classes.textField}
                fullWidth={true}
                label={"Phone Number"}
                required={true}
                value={modalContact?.phoneNumber}
                inputProps={{
                  onChange: e =>
                    setModalContact({
                      ...modalContact,
                      phoneNumber: formatString("(999) 999-9999", e.target.value.replace(/[^\d-() ]/, "")),
                    }),
                }}
                style={{ width: "100%" }}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={contact?.status === "active"}
              />
              {/* <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
              <InputLabel id="vendor-contact-role-label" className={classes.selectLabel}>
                * User Role
              </InputLabel>
              <Select
                labelId="vendor-contact-role-label"
                id="vendorContactRole"
                label="Trnsact User Role"
                onChange={e => setModalContact({ ...modalContact, vendorContactRole: e.target.value })}
                className={classes.selectStyles}
                disabled={contact?.status === "active"}
                value={modalContact.vendorContactRole}
              >
                <MenuItem value={100000000}>Sales Rep</MenuItem>
                <MenuItem value={100000001}>Sales Manager</MenuItem>
                <MenuItem value={100000002}>System Admin</MenuItem>
                <MenuItem value={100000003}>Executive</MenuItem>
                <MenuItem value={100000004}>Credit Manager</MenuItem>
                <MenuItem value={100000005}>Support Admin</MenuItem>
              </Select>
            </FormControl> */}
              {create && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "16px 0",
                  }}
                >
                  <label style={{ marginRight: 4, color: sendInvite ? "rgba(0,0,0,0.85)" : "", fontWeight: 400 }}>
                    {sendInvite ? "Send Invitation" : "Stage Invitation"}
                  </label>
                  <Switch
                    value={sendInvite}
                    defaultChecked={true}
                    onChange={() => setSendInvite(!sendInvite)}
                    color="primary"
                  />
                </div>
              )}
            </DialogContent>
          )}
        </>
      )}
      <DialogActions>
        {!loading && contact?.status !== "active" && (
          <>
            {/* {!create && !removingContact && (
              <Button color="default" style={{ color: "#f44336" }} onClick={() => setRemovingContact(true)}>
                Remove
              </Button>
            )} */}
            {!removingContact && (
              <Button color="primary" onClick={() => handleSubmit()}>
                {create ? "Create" : "Update"}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ContactModal;
