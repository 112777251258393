import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const DELETE_DOCSET = gql`
  mutation($id: ID!) {
    deleteDocSet(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 2),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  primaryBorderColor: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function DeleteDocSetModal({ open, handleClose, selectedDocSet, docSetRefetch }) {
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);

  const [deleteDocSet] = useMutation(DELETE_DOCSET, {
    context: { authRequired: true },
  });
  const classes = useStyles();

  const handleSubmit = async () => {
    try {
      const response = await deleteDocSet({
        variables: {
          id: selectedDocSet.key,
        },
      });
      if (response.data) {
        await setResponseSuccess(true);
        await handleOpenMessageModal();
        await setResponseSuccess(false);
        await handleCloseMessageModal();
        await docSetRefetch();
        await handleClose();
      } else {
        await setResponseSuccess(false);
        await handleOpenMessageModal();
        await docSetRefetch();
      }
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };
  const handleCancel = () => {
    handleClose();
  };
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(true);
  };

  return (
    <>
      {!!selectedDocSet && (
        <div style={{ width: "400px" }}>
          <Dialog onClose={handleClose} open={open} scroll={"body"} maxWidth={"md"} fullWidth={true}>
            <DialogTitle>Delete Doc Set</DialogTitle>
            <DialogContent>
              <form className={classes.container}>
                <div>Are you sure you want to delete this docset?</div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" simple>
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" simple>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}
