import React from "react";
import { Task } from "@trnsact/trnsact-shared-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell } from "../../Table/TableCell";
import { priorityColors, taskStatusMap } from "../../../constants/tasks";

interface Props {
  task: Task;
}

export const StatusCell = ({ task }: Props) => {
  const classes = useStyles();

  const { status } = task;

  return (
    <TableCell>
      <Box className={classes.cellContent}>
        <CheckCircleIcon style={{ color: priorityColors[status!] }} />

        <Typography variant="body2">{(taskStatusMap as any)?.[status!] ?? status}</Typography>
      </Box>
    </TableCell>
  );
};

const useStyles = makeStyles({
  cellContent: {
    zIndex: 2,
    gap: "0.3rem",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
