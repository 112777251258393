import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: "pointer",
    fontSize: "48px",
    //marginBottom: "16px",
    color: "green",
  },
}));

export function InsertionContainer({ type, onClickFn }) {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {/* <h3>Move {type} here</h3> */}
      <AddCircleOutlineIcon className={classes.icon} onClick={onClickFn} />
    </Box>
  );
}
