import React, { useEffect, useRef, useState } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import Table from "components/Table";
import _ from "lodash";
// material ui
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import CustomButton from "components/CustomButtons/Button";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
// components
import LenderSubmission from "./LenderSubmission";
import Tile from "../../components/Tile/Tile";
import LenderSubmissionDetailModal from "./LenderSubmissionDetailModal";
import { connect } from "react-redux";
import { setRefetch, setValue } from "../../redux/actions";
import { translateStageCodes } from "utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import UpdateLenderCredentials from "../LenderAdmin/credentials";
import TableSearch from "components/Table/TableSearch";
import rocketFastTrack from "assets/img/big-rocket.svg";
import { SETUP_FAST_TRACK_MODAL } from "../../redux/types";
import { useDispatch } from "react-redux";
import { lenderTypesSettings } from "../Prequal/constants";
import { CREDIT_SUBMISSION_SOURCE, CREDIT_SUBMISSION_SOURCE_HUMAN_READABLE, isVideoOrImage } from "../../utils";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import ImageIcon from "@material-ui/icons/Image";
import { theme } from "../../theme";

import EngsMappingModal from "./LenderSubmissionMappingModals/EngsMappingModal";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  table: {
    minWidth: 600,
  },
  rootButton: {
    minWidth: "45px",
  },
  buttonDetail: {
    display: "inline-block",
    float: "right",
    color: theme.palette.primary.main,
    paddingLeft: 0,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonUpdateCredentials: {
    display: "inline-block",
    color: theme.palette.primary.main,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  right: {
    textAlign: "right",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  wrapperLoader: {
    width: "42px",
    height: "42px",
    position: "absolute",
    top: 6,
    left: "-45px",
  },
  apiResultIcon: {
    fontSize: "20px",
  },
  apiResultIconSuccess: {
    color: "green",
    paddingLeft: 5,
  },
  apiResultIconFailed: {
    color: "#98260D",
    transform: "rotate(180deg)",
  },
  iconStyles: {
    margin: "0 !important",
  },
  cardBody: {
    paddingTop: 0,
    "& .-pagination": {
      padding: "30px 0 3px",
      alignItems: "center",
    },
    "& .-btn": {
      opacity: "1 !important",
      fontSize: "12px !important",
      fontWeight: "400",
      color: "#000000 !important",
      textTransform: "uppercase",
      fontFamily: "Roboto",
      maxWidth: "125px",
      maxHeight: "30px",
      height: "30px !important",
    },
    "& .-next": {
      "& .-btn": {
        display: "inline-block",
        float: "right",
      },
    },
    "& .-center": {
      flex: "5.5 1 !important",
      justifyContent: "space-between !important",
    },
    "& .-pageInfo": {
      width: "50% !important",
      margin: "0 !important",
    },
    "& .-pageJump": {
      width: "80% !important",
    },
    "& .-pageSizeOptions": {
      width: "50% !important",
      margin: "0 !important",
      "& select": {
        width: "80% !important",
      },
    },
  },
  cardTitle: {
    fontSize: 26,
    lineHeight: "30px",
    fontWeight: "300",
  },
  apiResultText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    color: "#000000",
  },
  apiResultError: {
    color: "#98260D",
  },
  apiResultSuccess: {
    color: "green",
  },
  fixReactTable: {},
  fastTrackSubmissionBanner: {
    marginTop: "1px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: 10,
    width: "fit-content",
    backgroundColor: "#cbf7d5",
    "&:hover": {
      filter: "drop-shadow(0 0 2px #cbf7d5)",
    },
  },
  fastTrackTextContent: {
    fontWeight: "bold",
    marginLeft: 10,
  },
  fastTrackText: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  },
  fastTrackLogoViewDetails: {
    marginRight: 10,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    maxWidth: "70vw",
    height: "auto",
  },
  videoContainer: {
    width: "100%", // El video toma el 100% del modal
    height: "auto",
  },
  closeButton: {
    display: "block",
    margin: "20px auto",
  },
  link: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
}));

const GET_CREDIT_SUBMISSIONS = gql`
  query CreditSubmissionsByDynamicsVOId($id: ID!, $display: String) {
    creditSubmissionsByDynamicsVOId(id: $id, display: $display) {
      creditSubmissionId
      creditSubId
      submissionDate
      decision
      lenderName
      amountRequested
      approvedAmount
      notes
      contingencies
      approvedRate
      creditSubStage
      apiApplicationId
      apiResult
      apiExpiredCredentials
      apiDetail
      lenderProfileId
      lenderChannelCode
      lenderTypeCode
      draft
      creditSubStage
      transactionSummary
      mappedValues
      source
      submittedBy
      timeDeltas {
        decisions {
          current
          timeElapsed
          previous
        }
        stages {
          current
          timeElapsed
          previous
        }
      }
      lenderContactInfo {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      fastTrackResults {
        approvedBy
        fastTrackId
        maxApprovalAmount
        rate
        inputs {
          key
          value
        }
        outputs {
          key
          value
        }
      }
    }
  }
`;

const SUBMIT_APP = gql`
  mutation($input: CreditSubmissionInput!) {
    createCreditSubmission(input: $input)
  }
`;

const status = {
  APPROVED: "Approved",
};

const LenderViewTable = ({
  vo,
  account,
  userProfile,
  lp,
  refetch,
  setRefetch,
  vp,
  creditApp,
  equipmentData,
  setValue,
  setOpenEquipmentInputModal,
  setInvoiceEquipmentTabPage,
  portalConfiguration,
  handleSubmitToLenderByProfileId,
  callbackSubmissionFinished,
}) => {
  const classes = useStyles();
  const [creditSubmissions, setCreditSubmissions] = useState();
  const [openLenderSubmissionDetailModal, setOpenLenderSubmissionDetailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creditSubId, setCreditSubId] = useState(null);
  const [autoRefreshInterval, setAutoRefreshInterval] = useState(null);
  const [creditSubLenderSubmissionDetailModal, setCreditSubLenderSubmissionDetailModal] = useState(null);
  const [openUpdateLenderCredentialsModal, setOpenUpdateLenderCredentialsModal] = useState(false);
  const [selectedLenderProfile, setSelectedLenderProfile] = useState(null);
  const [filteredCreditSubmissions, setFilteredCreditSubmissions] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [enableFastTrack, setEnableFastTrack] = useState(false);
  const [refetching, setRefetching] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const openModal = url => {
    setSelectedVideo(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedVideo(null);
  };

  const [openEngsMappingModal, setOpenEngsMappingModal] = useState(false);

  const hasEngsLenderProfile = false; // lp.find(lender => lender.lenderName.toLowerCase().includes("engs"));
  const lenderTypesSettingsByValue = _.keyBy(lenderTypesSettings, "code");
  const commonHeaderStyle = {
    fontWeight: "700",
    fontSize: 12,
    color: "#000",
    lineHeight: "14px",
  };

  const [
    getCreditSubmissions,
    {
      called: creditSubmissionsCalled,
      data: creditSubmissionsData,
      loading: creditSubmissionsLoading,
      error: creditSubmissionsError,
      startPolling: startCreditSubPolling,
      stopPolling: stopCreditSubPolling,
      refetch: creditSubmissionsRefetch,
      called: calledOnce,
    },
  ] = useLazyQuery(GET_CREDIT_SUBMISSIONS);

  const [creditSubmissionRequest] = useMutation(SUBMIT_APP, {
    context: { authRequired: true },
  });

  const resubmitCreditApp = async row => {
    const lenderInfo = lp.find(lender => lender.lenderName === row.lenderName);
    try {
      await creditSubmissionRequest({
        variables: {
          input: {
            submittedLender: lenderInfo.lenderAccountId,
            dynamicsCreditApplicationId: creditApp.dynamicsId,
            dynamicsVOId: vo.dynamicsVendorOpportunityId,
            dynamicsUserId: userProfile.dynamicsUserId,
            amountRequested: vo.invoiceTotal,
            accountId: account.id,
            lenderProfileId: lenderInfo.lenderProfileId,
            lenderContactId: lenderInfo.lenderContactId,
            createDraftOnly: false,
            transactionSummary: row.transactionSummary,
          },
        },
      });

      await creditSubmissionsRefetch();
      handleRefetchCreditSubmission();
    } catch (err) {
      console.log(err);
    }
  };

  if (creditSubmissionsLoading) console.log("Loading Credit Submissions....");
  if (creditSubmissionsError) console.log(`Error! ${creditSubmissionsError.message}`);

  useEffect(() => {
    if (_.get(creditSubmissionsData, "creditSubmissionsByDynamicsVOId")) {
      const filteredCS = creditSubmissionsData.creditSubmissionsByDynamicsVOId.filter(el => el != null);
      setCreditSubmissions(creditSubmissionsData.creditSubmissionsByDynamicsVOId.filter(el => el != null));

      let myData = Object.keys(filteredCS).map(key => filteredCS[key]);
      setValue("creditSubmissions", myData);
    } else {
      if (!!vo && !!vo.dynamicsVendorOpportunityId) {
        getCreditSubmissions({
          variables: {
            id: vo.dynamicsVendorOpportunityId,
            display: vp.creditSubmissionDisplay,
          },
        });
      }
    }
  }, [creditSubmissionsData, getCreditSubmissions]);

  const filterCreditSubmissions = creditSubmissions => {
    const { currentSearch } = searchRef.current;

    if (currentSearch.length === 0) {
      return creditSubmissions;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    let searchData = creditSubmissions.filter(el => {
      if (
        typeof el.submissionDate === "string" ||
        typeof el.lenderName === "string" ||
        typeof el.creditSubStage === "number" ||
        typeof el.decision === "string" ||
        typeof el.amountRequested === "number" ||
        typeof el.approvedAmount === "number"
      ) {
        return (
          (el.submissionDate || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.lenderName || "").toLowerCase().includes(lowerCaseSearch) ||
          (String(el.creditSubStage) || "").includes(lowerCaseSearch) ||
          (el.decision || "").toLowerCase().includes(lowerCaseSearch) ||
          (String(el.amountRequested) || "").includes(lowerCaseSearch) ||
          (String(el.approvedAmount) || "").includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return [...searchData];
  };

  useEffect(() => {
    if (creditSubmissions) {
      let updatedCreditSubmissions = [...creditSubmissions];
      updatedCreditSubmissions = [...updatedCreditSubmissions].map(el => {
        const splitAPIDetail = el.apiDetail?.toString().split("Screenshots:");
        let screenshots =
          splitAPIDetail && splitAPIDetail[1]
            ? splitAPIDetail[1]
                .split("\n")
                ?.map(i => i.trim())
                ?.filter(i => i)
            : [];

        return {
          ...el,
          apiDetail: splitAPIDetail ? splitAPIDetail[0] : el.apiDetail,
          apiScreenshots: screenshots,
        };
      });
      setFilteredCreditSubmissions(filterCreditSubmissions(updatedCreditSubmissions));
    }
  }, [creditSubmissions, searchRef.current.currentSearch]);

  if (refetch.creditSubmissions) {
    setRefetch("creditSubmissions", false);
    creditSubmissionsRefetch();
  }

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});

  const handleOpenModal = () => setOpenMessageModal(true);

  const handleSubmissionMessage = async message => {
    await handleRefetchCreditSubmission();
    return setSubmissionMessage(message);
  };

  const handleRefetchCreditSubmission = async () => {
    const time = 1000;
    setRefetching(true);
    await creditSubmissionsRefetch();
    setTimeout(() => {
      startCreditSubPolling(time);
    }, time);
    setTimeout(() => {
      setRefetching(false);
      stopCreditSubPolling();
    }, 7000);
  };

  const handleOpenLenderDetailModalView = row => {
    setCreditSubId(row.creditSubId);
    setCreditSubLenderSubmissionDetailModal(row);
    setOpenLenderSubmissionDetailModal(true);
  };

  const handleCloseLenderDetailModalView = () => {
    setOpenLenderSubmissionDetailModal(false);
    setCreditSubLenderSubmissionDetailModal(false);
    setCreditSubId("");
  };
  const [updated, setUpdated] = useState(false);

  if (!!refetch && !!refetch.pastSubmissions) {
    creditSubmissionsRefetch();
    setRefetch("pastSubmissions", false);
  }
  const handleCloseUpdateLenderCredentialsModal = () => {
    setOpenUpdateLenderCredentialsModal(false);
  };
  const handleOpenUpdateLenderCredentialsModal = () => {
    setOpenUpdateLenderCredentialsModal(true);
  };
  function handleUpdateCredentials(rowInfo) {
    setSelectedLenderProfile(rowInfo);
    handleOpenUpdateLenderCredentialsModal();
  }

  const dispatch = useDispatch();

  const handleOpenFastTrackModal = e => {
    e.preventDefault();

    dispatch({
      type: SETUP_FAST_TRACK_MODAL,
      payload: {
        opened: true,
        currentVo: vo,
        vp,
        creditAppData: {
          creditApplication: creditApp,
        },
      },
    });
  };

  const handleOpenEngsMappingModal = () => {
    setOpenEngsMappingModal(true);
  };

  const handleEngsMappingModalClose = () => {
    setOpenEngsMappingModal(false);
  };

  useEffect(() => {
    if (vo) {
      const fastTrackStatus = _.get(vo, "fastTrackResults[0].applicationStatus", "");
      const whiteListToShowFastTrack = [
        "AWAITING_DEALER_APPROVAL",
        "PROPOSAL_SENT",
        "PROPOSAL_VIEWED",
        "PROPOSAL_ACCEPTED",
        "FUNDED_FAST",
      ];
      const isFastTrackEnabled =
        vp.fastTrackSetting === "YES_SET_CAMUNDA_PROCESS_CODE" ||
        vp.fastTrackSetting === "YES_AUTOMATED_FOR_DEALER" ||
        vp.fastTrackSetting === "YES_BUT_DEALER_MUST_TRIGGER";
      if (isFastTrackEnabled && fastTrackStatus && whiteListToShowFastTrack.includes(fastTrackStatus)) {
        setEnableFastTrack(true);
      }
    }
  }, [vo]);

  return (
    <Tile
      classBody={classes.cardBody}
      title={
        <span className={classes.cardTitle}>
          Manage Submissions
          <span style={{ paddingLeft: "15px" }}>
            {hasEngsLenderProfile && (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  handleOpenEngsMappingModal();
                }}
              >
                See ENGS Mapping
              </Button>
            )}
          </span>
        </span>
      }
      titleButtonComponent={
        <div className={classes.wrapper}>
          <CustomButton
            onClick={handleRefetchCreditSubmission}
            style={{ width: 148, background: theme.palette.primary.main }}
          >
            <RefreshIcon className={classes.iconStyles} />
            <p style={{ margin: "0 0 0 4px", fontSize: 10 }}>Sync Submissions</p>
          </CustomButton>
          <div className={classes.wrapperLoader}>{refetching && <CircularProgress size={32} />}</div>
        </div>
      }
      useCollapsing={true}
    >
      {hasEngsLenderProfile && (
        <Modal open={openEngsMappingModal} onClose={handleEngsMappingModalClose} aria-labelledby="ENGS API Mapping">
          <>
            <EngsMappingModal vo={vo} creditApp={creditApp} handleEngsMappingModalClose={handleEngsMappingModalClose} />
          </>
        </Modal>
      )}

      {enableFastTrack && (
        <div className={classes.fastTrackSubmissionBanner} onClick={e => handleOpenFastTrackModal(e)}>
          <img src={rocketFastTrack} width="30px" height="30px" alt="Fast track icon" />
          <div className={classes.fastTrackTextContent}>
            This application has already been approved and is on the{" "}
            <span className={classes.fastTrackText}>fast track!</span>
          </div>
        </div>
      )}
      {!!creditSubmissions && creditSubmissions.length ? (
        <div className={classes.fixReactTable}>
          <div style={{ marginBottom: 16 }}>
            <TableSearch
              setPageNumber={setPageNumber}
              setSavedListSettings={setSavedListSettings}
              searchRef={searchRef}
              savedListSettings={savedListSettings}
            />
          </div>
          <Table
            color="primary"
            data={filteredCreditSubmissions}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                style: {
                  cursor: "pointer",
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: "14px",
                },
              };
            }}
            columns={[
              {
                Header: "Submission Date",
                accessor: "submissionDate",
                Filter: () => null,
                resizable: false,
              },
              {
                Header: "Lender",
                accessor: "lenderName",
                Filter: () => null,
              },
              {
                Header: "Stage",
                Cell: props => {
                  const row = props.original;
                  let apiDetail = null;
                  switch (row.apiResult) {
                    case "error":
                      apiDetail = (
                        <span>
                          <span style={{ color: "red" }}>Error</span>
                          <Tooltip title={row.apiDetail} placement="top-start">
                            <PriorityHighIcon className={[classes.apiResultIcon, classes.apiResultIconFailed]} />
                          </Tooltip>
                        </span>
                      );
                      break;
                    case "failure":
                      apiDetail = (
                        <span>
                          <span style={{ color: "red" }}>Error</span>
                          <Tooltip title={row.apiDetail} placement="top-start">
                            <PriorityHighIcon className={[classes.apiResultIcon, classes.apiResultIconFailed]} />
                          </Tooltip>
                        </span>
                      );
                      break;
                    case "in progress":
                      apiDetail = (
                        <Tooltip title={"Processing"} placement="top-start">
                          <HourglassEmptyIcon size="small" className={[classes.apiResultIcon]} />
                        </Tooltip>
                      );
                      break;
                    case "success":
                      apiDetail = (
                        <Tooltip title={row.apiDetail} placement="top-start">
                          <DoneIcon size="small" className={[classes.apiResultIcon, classes.apiResultIconSuccess]} />
                        </Tooltip>
                      );
                      break;

                    default:
                      break;
                  }

                  if (row.apiExpiredCredentials) {
                    return (
                      <div>
                        <PriorityHighIcon className={[classes.apiResultIcon, classes.apiResultIconFailed]} />{" "}
                        {"Credentials Expired"}
                        <Button
                          size="small"
                          className={classes.buttonUpdateCredentials}
                          onClick={() => {
                            handleUpdateCredentials(row);
                          }}
                        >
                          Update Credentials
                        </Button>
                      </div>
                    );
                  }
                  if (props.value) {
                    return (
                      <div>
                        {" "}
                        {translateStageCodes(props.value)} {apiDetail}
                      </div>
                    );
                  } else return null;
                },
                accessor: "creditSubStage",
                Filter: () => null,
              },
              {
                Header: "Decision",
                accessor: "decision",
                Cell: props => {
                  const row = props.original;
                  if (row.apiResult === "error" || row.apiResult === "failure") {
                    return <span>Error Details: {row.apiDetail}</span>;
                  }
                  return row.decision;
                },
                Filter: () => null,
              },
              {
                Header: "Recordings",
                accessor: "apiScreenshots",
                Cell: props => {
                  const row = props.original;

                  if (row.apiScreenshots.length > 0) {
                    return (
                      <div>
                        {row.apiScreenshots.map((url, i) => {
                          const isVideo = isVideoOrImage(url) === "video";
                          const isImage = isVideoOrImage(url) === "image";
                          return (
                            <span key={`capture_${i}_${row.creditSubId}`}>
                              {i !== 0 && ", "}
                              <a
                                href={url}
                                target={isVideo ? "_self" : "_blank"}
                                rel="noreferrer"
                                className={classes.link}
                                onClick={e => {
                                  if (isVideo) {
                                    e.preventDefault();
                                    openModal(url);
                                  }
                                }}
                              >
                                {isVideo ? (
                                  <>
                                    <VideoLibraryIcon /> {`Video #${i + 1}`}
                                  </>
                                ) : (
                                  <>
                                    {isImage && <ImageIcon />} {`Image #${i + 1}`}
                                  </>
                                )}
                              </a>
                            </span>
                          );
                        })}

                        <Modal
                          open={modalIsOpen}
                          onClose={closeModal}
                          className={classes.modal}
                          aria-labelledby="video-modal"
                          aria-describedby="video-player"
                        >
                          <div className={classes.paper}>
                            {selectedVideo && (
                              <div>
                                <video width="100%" height={"auto"} controls>
                                  <source src={selectedVideo} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            )}
                            <Button className={classes.closeButton} onClick={closeModal} variant="contained">
                              Close
                            </Button>
                          </div>
                        </Modal>
                      </div>
                    );
                  } else {
                    return <div>-</div>;
                  }
                },
              },
              {
                Header: "Requested",
                accessor: "amountRequested",
                Cell: props => <div>{props.value ? currencyFormatter.format(props.value) : ""}</div>,
                Filter: () => null,
              },
              {
                Header: "Approved",
                accessor: "approvedAmount",
                Filter: () => null,
                Cell: props => <div>{props.value ? currencyFormatter.format(props.value) : ""}</div>,
              },
              {
                Header: "Type",
                accessor: "lenderTypeCode",
                Cell: props => {
                  const lenderTypeSetting = lenderTypesSettingsByValue[props.value];
                  return lenderTypeSetting ? (
                    <>
                      <b style={{ color: lenderTypeSetting.color }}>{_.get(lenderTypeSetting, "letter", "")}</b>
                      <span> {_.get(lenderTypeSetting, "title", "")}</span>
                    </>
                  ) : null;
                },
              },
              {
                Header: "Submitted Byxxxxx",
                accessor: "source",
                Cell: props => {
                  const submittedByText = CREDIT_SUBMISSION_SOURCE_HUMAN_READABLE[props.value];
                  if (
                    props.value === CREDIT_SUBMISSION_SOURCE.FAST_TRACK_AUTO ||
                    props.value === CREDIT_SUBMISSION_SOURCE.FAST_TRACK_MANUAL
                  ) {
                    return (
                      <span>
                        <img src={rocketFastTrack} width="30px" height="30px" alt="Fast track icon" />
                        {submittedByText}
                      </span>
                    );
                  } else if (props.value === CREDIT_SUBMISSION_SOURCE.PORTAL_BY_DEALER) {
                    return <span>{props.row._original.submittedBy}</span>;
                  } else {
                    return <span>TRNSACT</span>;
                  }
                },
              },
              {
                Header: " ",
                Filter: () => null,
                sortable: false,
                Cell: props => {
                  const row = props.original;

                  // shutting off the fast track rocket for now because there are issues with matching FT to a lender submission
                  const isShowFastTrack = false; // row.decision === status.APPROVED && !row.draft && enableFastTrack;
                  if (!!row && !!userProfile && userProfile.vendorContactRole !== "sales_rep") {
                    return (
                      <>
                        <Button
                          size="small"
                          className={classes.buttonDetail}
                          onClick={e => {
                            if (isShowFastTrack) {
                              handleOpenFastTrackModal(e);
                            } else {
                              handleOpenLenderDetailModalView(row);
                            }
                          }}
                        >
                          {isShowFastTrack && (
                            <img
                              src={rocketFastTrack}
                              height="30px"
                              width="30px"
                              alt="Fast Track icon"
                              className={classes.fastTrackLogoViewDetails}
                            />
                          )}
                          View Detail
                        </Button>
                        {row.apiResult === "failure" && (
                          <Button
                            size="small"
                            className={classes.buttonDetail}
                            onClick={() => {
                              resubmitCreditApp(row);
                            }}
                          >
                            RE-SUBMIT
                            <span style={{ padding: "0px 5px", color: "#C4C4C4" }}>|</span>
                          </Button>
                        )}
                      </>
                    );
                  }

                  return null;
                },
              },
            ]}
            pageSize={pageSizeValue}
            pageNumber={pageNumber}
            defaultPageSize={savedListSettings.pageSize}
            showPaginationTop={false}
            showPaginationBottom={true}
            onPageChange={changePage => {
              setPageNumber(changePage);
            }}
            onPageSizeChange={changePageSize => {
              setPageSizeValue(changePageSize);
              setPageNumber(0);
            }}
            className="-highlight"
          />
        </div>
      ) : null}
      {creditSubLenderSubmissionDetailModal ? (
        <LenderSubmissionDetailModal
          key={Math.random()}
          creditSubmission={creditSubLenderSubmissionDetailModal}
          open={openLenderSubmissionDetailModal}
          vo={vo}
          creditSubId={creditSubId}
          handleCloseLenderDetailModalView={handleCloseLenderDetailModalView}
          setOpenLenderSubmissionDetailModal={setOpenLenderSubmissionDetailModal}
          refetchCreditSubmissions={creditSubmissionsRefetch}
          relatedCreditSubmissions={creditSubmissions}
          vp={vp}
          account={account}
          userProfile={userProfile}
          submitting={loading}
          lenderSubmitting={filteredCreditSubmissions[filteredCreditSubmissions.length - 1]}
          handleUpdateCredentials={handleUpdateCredentials}
          creditApp={creditApp}
        />
      ) : null}

      <LenderSubmission
        refetch={creditSubmissionsRefetch}
        vo={vo}
        equipmentData={equipmentData}
        handleOpenModal={handleOpenModal}
        handleSubmissionMessage={handleSubmissionMessage}
        submissionMessage={submissionMessage}
        account={account}
        lp={lp}
        creditApp={creditApp}
        setInvoiceEquipmentTabPage={setInvoiceEquipmentTabPage}
        setOpenEquipmentInputModal={setOpenEquipmentInputModal}
        creditSubmissions={creditSubmissions}
        submitting={loading}
        lenderSubmitting={filteredCreditSubmissions[filteredCreditSubmissions.length - 1]}
        handleUpdateCredentials={handleUpdateCredentials}
        portalConfiguration={portalConfiguration}
        vp={vp}
        handleSubmitToLenderByProfileId={handleSubmitToLenderByProfileId}
        callbackSubmissionFinished={callbackSubmissionFinished}
      />
      <UpdateLenderCredentials
        open={openUpdateLenderCredentialsModal}
        handleClose={() => {
          return handleCloseUpdateLenderCredentialsModal();
        }}
        handleOpenAddNewRepModal={handleOpenUpdateLenderCredentialsModal}
        lenderProfile={selectedLenderProfile}
      />
    </Tile>
  );
};

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
  userProfile: state.userProfile,
  lp: state.vp.lenderProfiles,
  refetch: state.refetch,
  vp: state.vp,
  creditApp: state.creditApp,
  creditSubmissions: state.creditSubmissions,
});

const mapDispatchToProps = {
  setRefetch,
  setValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(LenderViewTable);

const lenderList = {
  "Ford Motor Credit": ["Tracking Only - Requires RouteOne Entry", "N/A"],
  "Test Lender #1": ["Submission by Email", "Alex Sawatzke"],
  "Toyota Industries Commercial Finance": ["Submission by Email", "Vince Monteleone"],
  "Hitachi Capital": ["Submission by Email", "Jay Saul"],
  "Isuzu Finance": ["Submission by Email", "Bob Mooney"],
  CalFund: ["Submission by Email", "N/A"],
  "Ascentium Capital": ["Submission by Email", "N/A"],
  "AP Finance": ["Submission by Email", "N/A"],
  "Ally Bank": ["Tracking Only - Requires RouteOne Entry", "Alex Sawatzke"],
  "Test Lender #2": ["Submission by Email", "Alex Sawatzke"],
};
