import React, { useContext, useEffect, useState } from "react";
import { DCR_API_BASE_SERVER_URL } from "config";
import axios from "axios";
import gql from "graphql-tag";
import moment from "moment";
import GetApp from "@material-ui/icons/GetApp";
import Description from "@material-ui/icons/Description";
import Edit from "@material-ui/icons/Edit";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { IconButton } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DocumentContext } from "contexts/DocumentContext";
import MessageModal from "components/MessageModal";
import DocumentAccessSettingsModal from "components/DocumentAccessSettingsModal";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import NewCreditAppModal from "./NewCreditAppModal";
import CADMv2 from "./CADMv2";
import FileUploadModal from "./FileUploadModal";
import Tile from "../../components/Tile/Tile";
import { setValue } from "../../redux/actions";
import { connect } from "react-redux";
import { documentsByKey } from "../Prequal/constants";
import _ from "lodash";
import { theme } from "../../theme";

const DEACTIVATE_DOC = gql`
  mutation($id: ID!) {
    deactivateDocument(id: $id)
  }
`;

const UPDATE_DOC = gql`
  mutation($id: ID!, $input: UpdateDocumentInput) {
    updateDocument(id: $id, input: $input) {
      id
      source
    }
  }
`;

const useStyles = makeStyles(() => ({
  clearButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  cardIconTitle: {
    ...cardTitle,
  },
  flexJustifyEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cloudIcon: {
    margin: "0px 8px 0px 0px !important",
  },
  documentModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 540,
    borderRadius: 8,
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
  reportProblemIcon: {
    color: "#ec9c12",
  },
}));

const fileTypeOptionsFull = [
  documentsByKey.quote,
  documentsByKey.invoice,
  documentsByKey.buyers_order,
  documentsByKey.decision_letter,
  documentsByKey.insurance_certificate,
  documentsByKey.driver_license,
  documentsByKey.bank_statements,
  documentsByKey.tax_returns,
  documentsByKey.equipment_specs,
  documentsByKey.sales_tax_exemption,
  documentsByKey.credit_app_scan,
  documentsByKey.financial,
  documentsByKey.other,
];

function Documents({
  vo,
  account,
  userProfile,
  vendorProfile,
  refetchCreditApp,
  userDocs,
  refetchDocs,
  userDocsLoading,
  userDocsError,
  showDcrCreditAppRow,
  CADMPortalConfiguration,
  documentsPortalConfiguration,
  portalConfigurationDataLoading,
  tasksData,
  isCADMEditingDisabled,
}) {
  const classes = useStyles();
  let tableData = [[]];
  const [showCreditApp, setShowCreditApp] = useState(1);
  const [customCreditApp, setCustomCreditApp] = useState([]);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isOpenCADMv2, setIsOpenCADMv2] = useState(false);
  const [isCADMv2ForPDFOnly, setIsCADMv2ForPDFOnly] = useState(false);
  const [isPDFDownloaded, setIsPDFDownloaded] = useState(false);
  const [isViewDocumentModal, setIsViewDocumentModal] = useState(false);
  const [viewDocument, setViewDocument] = useState(null);
  const [isUpdateDocumentModal, setIsUpdateDocumentModal] = useState(false);
  const [updateDocument, setUpdateDocument] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDocumentAccessSettingsModalOpen, setIsDocumentAccessSettingsModalOpen] = useState(false);

  const [deactivateDocRequest, { data: deactivateDocResponseData }] = useMutation(DEACTIVATE_DOC, {
    context: { authRequired: true },
  });
  const [updateDoc, { data: updateDocResponseData }] = useMutation(UPDATE_DOC, {
    context: { authRequired: true },
  });

  const documentContext = useContext(DocumentContext);
  const handleClose = () => setOpenVoCreditAppModal(false);
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => setOpenMessageModal(false);

  useEffect(() => {
    refetchCreditApp();
  }, [refetchCreditApp]);

  useEffect(() => {
    if (vendorProfile && userProfile) {
      if (vendorProfile.showCreditApp && vo.entityType !== "CASH_SALE") {
        let customPDF = [];
        _.map(vendorProfile.lenderProfiles, lp => {
          if (lp.showCustomApp) {
            customPDF.push([
              `Lender Credit Application`,
              "",
              "Trnsact",
              "",
              `Credit Application`,
              `${lp.pdfTemplate} Credit Application`,
              <React.Fragment>
                {vendorProfile.showCreditApp ||
                (userProfile !== undefined && userProfile.vendorContactRole === "credit_mgr") ? (
                  <IconButton size="sm" onClick={() => handleCustomerLenderPDFDownload(lp.pdfTemplate)}>
                    <GetApp style={{ fill: theme.palette.primary.main }} />
                  </IconButton>
                ) : null}
              </React.Fragment>,
            ]);
          }
        });

        setCustomCreditApp(customPDF);
      }

      setShowCreditApp(vendorProfile.showCreditApp);
    }
  }, [vendorProfile, userProfile]);

  if (userDocsLoading) console.log("Loading documents...");
  if (userDocsError) console.log(`Error! ${userDocsError.message}`);

  const mapData = (key, name, uploaded_by, uploaded_on, type, description, link) => {
    return { key, name, uploaded_by, uploaded_on, type, description, link };
  };

  let creditAppDownloadTableRow = [];

  if (showDcrCreditAppRow && vo.entityType !== "CASH_SALE") {
    creditAppDownloadTableRow.push(
      "Credit Application",
      "",
      "Trnsact",
      "",
      "Credit Application",
      "",
      <div style={{ display: "flex" }}>
        {showCreditApp === undefined || userProfile === undefined ? null : showCreditApp === 3 ||
          (showCreditApp === 13 && userProfile.vendorContactRole === "credit_mgr") ? (
          <>
            {!portalConfigurationDataLoading && (
              <>
                {_.some(CADMPortalConfiguration) ? (
                  <IconButton color="primary" size="sm" onClick={() => handleCADMv2PDFDownload()}>
                    <GetApp style={{ fill: theme.palette.primary.main }} />
                  </IconButton>
                ) : (
                  <IconButton color="primary" size="sm" onClick={() => handleCreditAppRequestDownload()}>
                    <GetApp style={{ fill: theme.palette.primary.main }} />
                  </IconButton>
                )}
              </>
            )}
          </>
        ) : null}
        {showCreditApp === undefined ? null : showCreditApp === 2 ||
          showCreditApp === 3 ||
          showCreditApp === 11 ||
          showCreditApp === 13 ? (
          <>
            {!portalConfigurationDataLoading && (
              <>
                {_.some(CADMPortalConfiguration) ? (
                  <IconButton size="sm" onClick={() => setIsOpenCADMv2(true)}>
                    <Description style={{ fill: theme.palette.primary.main }} />
                  </IconButton>
                ) : (
                  <IconButton size="sm" onClick={() => handleCreditAppRequest()}>
                    <Description style={{ fill: theme.palette.primary.main }} />
                  </IconButton>
                )}
              </>
            )}
            {portalConfigurationDataLoading && (
              <Button type="button" color="primary" size="sm" onClick={_.noop}>
                Loading...
              </Button>
            )}
          </>
        ) : null}
      </div>
    );
  }

  const wellsFargoPDFRow = [
    "Wells Fargo Credit Application",
    "",
    "Trnsact",
    "Credit Application",
    "Wells Fargo Credit Application",
    <React.Fragment>
      {/* { showCreditApp === undefined
        ? null
        : (showCreditApp === 2 || showCreditApp === 3 || showCreditApp === 11 || showCreditApp === 13)
          ? (
            <Button type="button" color="primary" size="sm" onClick={() => handleCreditAppRequest()}>
              View
            </Button>
          )
          : null
      } */}
      {showCreditApp === undefined || userProfile === undefined ? null : showCreditApp === 3 ||
        (showCreditApp === 13 && userProfile.vendorContactRole === "credit_mgr") ? (
        <IconButton size="sm" onClick={() => handleCustomerLenderPDFDownload("WELLS")}>
          <GetApp style={{ fill: theme.palette.primary.main }} />
        </IconButton>
      ) : null}
    </React.Fragment>,
  ];

  const tableHead = ["File Name", "", "Uploaded By", "Uploaded On", "Type", "Description", "Action"];
  function addDocumentsToTableData(documents) {
    const documentsRows = [];
    if (!!documents && documents.length) {
      documents.forEach(row => {
        if (
          row.type === "Credit Report" &&
          vendorProfile.softPullInDealerPortal !== "CBC_EQUIFAX_SOFT_FULL_REPORT" &&
          !row.name.startsWith("Commercial")
        ) {
          return;
        }
        const tableRow = [
          row.name,
          row.link ? (
            <button className={classes.clearButton} onClick={() => handleDeactivateDoc(row.key)}>
              <DeleteForeverIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
            </button>
          ) : (
            <></>
          ),
          row.uploaded_by,
          row.uploaded_on,
          row.type,
          row.description,
          <div style={{ display: "flex" }}>
            {/*             <IconButton size="sm" onClick={() => setIsDocumentAccessSettingsModalOpen(true)}>
              <SettingsIcon style={{ fill: theme.palette.primary.main }} />
            </IconButton> */}
            {row.link ? (
              <>
                <IconButton size="sm" href={row.link} target="_blank">
                  <GetApp style={{ fill: theme.palette.primary.main }} />
                </IconButton>
                <IconButton size="sm" onClick={() => handleisUpdateDocumentModal(row)}>
                  <Edit style={{ fill: theme.palette.primary.main }} />
                </IconButton>
              </>
            ) : (
              <div style={{ padding: "12px" }}>
                <Tooltip title="Access Restricted" aria-label="Access Restricted" placement="top">
                  <ReportProblemOutlinedIcon className={`${classes.reportProblemIcon}`} />
                </Tooltip>
              </div>
            )}
            {/* <IconButton size="sm" onClick={() => handleViewDocument(row)}>
              <Description style={{ fill: theme.palette.primary.main }} />
            </IconButton> */}
          </div>,
        ];
        documentsRows.push(tableRow);
      });
    }
    tableData =
      (vendorProfile &&
        vendorProfile.vendorProfile &&
        vendorProfile.vendorProfile.dynamicsId === "29e4c282-7d12-e811-80ea-005056b05a0f") ||
      (vendorProfile &&
        vendorProfile.vendorProfile &&
        vendorProfile.vendorProfile.dynamicsId === "a899de7a-624c-ea11-8115-005056b05a0f")
        ? [...[creditAppDownloadTableRow], ...[wellsFargoPDFRow], ...documentsRows]
        : [...[creditAppDownloadTableRow], ...customCreditApp, ...documentsRows];
  }

  let documents;
  if (userDocs) {
    documents = userDocs.documents.map(row =>
      mapData(
        row.documentId,
        row.docName,
        row.createdBy,
        row.createdDateTime ? moment(row.createdDateTime).format("MM-DD-yyyy") : "",
        row.docType,
        row.docDescription,
        row.source
      )
    );
  }
  addDocumentsToTableData(documents);

  useEffect(() => {
    //refetch data after lastDoc context changed
    if (refetchDocs) refetchDocs();
  }, [documentContext.lastDoc]);

  useEffect(() => {
    //refetch data after doc deactivated
    if (refetchDocs) refetchDocs();
  }, [deactivateDocResponseData]);

  useEffect(() => {
    if (!isPDFDownloaded) {
      return;
    }
    setIsOpenCADMv2(false);
    setIsCADMv2ForPDFOnly(false);
    setIsPDFDownloaded(false);
    handleCloseMessageModal();
  }, [isPDFDownloaded]);

  const [creditApp, setCreditApp] = useState();
  const [openVoCreditAppModal, setOpenVoCreditAppModal] = useState(false);
  const handleCreditAppRequest = () => {
    setOpenVoCreditAppModal(true);
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
        {
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          account: { account },
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(error => console.log(error));
  };
  const handleRefetchCreditAppRequest = () => {
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
        {
          vendorProfile: vendorProfile,
          userProfile: userProfile,
          account: { account },
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(error => console.log(error));
  };

  const handleCreditAppRequestDownload = () => {
    handleOpenMessageModal();
    axios
      .post(
        // Check for credit app created by Anvil workflow first
        `${DCR_API_BASE_SERVER_URL}/credit-app?transaction_id=${vo.transactionId}`,
        {
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          account: { account },
          download: true,
        }
      )
      .then(async response => {
        if (response.data) {
          handleCloseMessageModal();
          const fileURL = response.data;
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("target", "_blank");
          link.setAttribute("download", "credit_app.pdf");
          document.body.appendChild(link);
          link.click();
        } else {
          // If no Anvil workflow credit app found, generate our own pdf
          axios
            .post(
              `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
              {
                userProfile: userProfile,
                vendorProfile: vendorProfile,
                account: { account },
                download: true,
              },
              {
                responseType: "blob",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/pdf",
                },
              }
            )
            .then(async response => {
              handleCloseMessageModal();
              const fileURL = window.URL.createObjectURL(response.data, {
                type: "application/pdf",
              });
              const link = document.createElement("a");
              link.href = fileURL;
              link.setAttribute("target", "_blank");
              link.setAttribute("download", "credit_app.pdf");
              document.body.appendChild(link);
              link.click();
            })
            .catch(error => console.log(error));
        }
      })
      .catch(error => console.log(error));
  };

  const handleCADMv2PDFDownload = () => {
    handleOpenMessageModal();
    setIsCADMv2ForPDFOnly(true);
    setIsOpenCADMv2(true);
  };

  const handleCustomerLenderPDFDownload = lenderCode => {
    handleOpenMessageModal();
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/lender-specific-pdf?transaction_id=${vo.transactionId}`,
        {
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          lenderCode,
          account: { account },
          download: true,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then(async response => {
        handleCloseMessageModal();
        const fileURL = window.URL.createObjectURL(response.data, {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "credit_app.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log(error));
  };

  const handleDeactivateDoc = async value => {
    try {
      await deactivateDocRequest({
        variables: {
          id: value,
        },
      });
      await refetchDocs();
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewDocument = row => {
    setIsViewDocumentModal(true);
    setViewDocument({ ...row });
  };

  const handleisUpdateDocumentModal = row => {
    setUpdateDocument({ ...row });
    setIsUpdateDocumentModal(true);
  };

  const handleUpdateDoc = async () => {
    setIsUpdating(true);
    console.log(updateDocument);
    try {
      await updateDoc({
        variables: {
          id: updateDocument.key,
          input: {
            docDescription: updateDocument.description,
            docType: updateDocument.type,
          },
        },
      });
      await refetchDocs();
    } catch (err) {
      console.log(err);
    }
    setIsUpdating(false);
    setUpdateDocument(null);
    setIsUpdateDocumentModal(false);
  };

  return (
    <Tile
      title="Documents"
      id="documentList"
      titleButtonComponent={
        <FileUploadModal
          vo={vo}
          equipmentUpload={false}
          documentsPortalConfiguration={documentsPortalConfiguration}
          buttonDescription={() => {
            return (
              <>
                <CloudUploadIcon className={classes.cloudIcon} /> Upload Document
              </>
            );
          }}
        />
      }
      useCollapsing={true}
    >
      <Table tableHead={tableHead} tableData={tableData} />
      <Modal
        open={isOpenCADMv2}
        onClose={() => setIsOpenCADMv2(false)}
        aria-labelledby="Credit Application"
        aria-describedby="Summary"
        style={{ display: isCADMv2ForPDFOnly ? "none" : "block" }}
      >
        <>
          {CADMPortalConfiguration && (
            <CADMv2
              vo={vo}
              account={account}
              creditAppModalConfig={CADMPortalConfiguration.jsonDefinition}
              vendorProfile={vendorProfile}
              isForPDFOnly={isCADMv2ForPDFOnly}
              setIsPDFDownloaded={setIsPDFDownloaded}
              vendorOpportunityTasks={tasksData?.vendorOpportunityTask}
              isEditingDisabled={isCADMEditingDisabled}
            />
          )}
        </>
      </Modal>

      <Modal
        open={openVoCreditAppModal}
        onClose={handleClose}
        aria-labelledby="Credit Application"
        aria-describedby="Summary"
      >
        {creditApp ? (
          <React.Fragment>
            <NewCreditAppModal
              {...creditApp}
              showCreditApp={showCreditApp}
              userProfile={userProfile.userProfile}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              refetchCreditApp={refetchCreditApp}
              handleRefetchCreditAppRequest={handleRefetchCreditAppRequest}
            />
          </React.Fragment>
        ) : (
          <div />
        )}
      </Modal>
      <MessageModal
        isOpen={openMessageModal}
        handleCloseModal={handleCloseMessageModal}
        title={"Loading"}
        message={"Please wait while we prepare your document."}
      />
      <DocumentAccessSettingsModal
        isDocumentAccessSettingsModalOpen={isDocumentAccessSettingsModalOpen}
        setIsDocumentAccessSettingsModalOpen={setIsDocumentAccessSettingsModalOpen}
      />
      <Modal
        open={isViewDocumentModal}
        onClose={() => setIsViewDocumentModal(false)}
        aria-labelledby={viewDocument?.docName || ""}
        aria-describedby="Summary"
      >
        <div className={classes.documentModal}>
          <h3>{viewDocument?.name}</h3>
          {/* {viewDocument?.link && (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={[{ uri: viewDocument.link }]}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                },
              }}
              style={{ height: "80%", width: "80%" }}
            />
          )} */}
        </div>
      </Modal>
      <Modal
        open={isUpdateDocumentModal}
        onClose={() => setIsUpdateDocumentModal(false)}
        aria-labelledby={updateDocument?.docName || ""}
        aria-describedby="Summary"
      >
        <div className={classes.documentModal}>
          <h3>{updateDocument?.name || ""}</h3>
          <div style={{ width: "100%", justifyContent: "flex-start", padding: 16 }}>
            <Box component="form" style={{ marginBottom: 16 }} noValidate autoComplete="off">
              <TextField
                label="Description"
                multiline
                variant="outlined"
                rows={4}
                value={updateDocument?.description || ""}
                onChange={e => setUpdateDocument({ ...updateDocument, description: e.target.value })}
                fullWidth
              />
            </Box>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={updateDocument?.type || ""}
                label="Type"
                onChange={e => setUpdateDocument({ ...updateDocument, type: e.target.value })}
              >
                {fileTypeOptionsFull.map(({ value, text }, index) => (
                  <MenuItem key={index} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button onClick={() => handleUpdateDoc()} color="primary" style={{ marginBottom: 32 }} disabled={isUpdating}>
            {isUpdating ? "Updating..." : "Update"}
          </Button>
        </div>
      </Modal>
    </Tile>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account,
    userProfile: state.userProfile,
    vendorProfile: state.vp,
  };
};

const mapDispatchToProps = {
  reduxSetValue: setValue,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);
