import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Rate } from "@trnsact/ntp-sdk";
import { Addons } from "./Addons";
import { Fields } from "./Fields";
import {
  CommonMenuPriceValues,
  MarkupType,
  ProposalProductCardModes,
  ProposalProductCardProps,
} from "../../../../../types";
import { vendorsLogoByType } from "../../../../../constants";
import { ProposalCardContainer } from "../ProposalCardContainer";

export const Card = ({
  mode,
  product,
  menuName = "",
  type = "simple",
  updateProductConfiguration,
  proposalProductConfiguration,
}: ProposalProductCardProps) => {
  const [forcePriceValue, setForcePriceValue] = useState<CommonMenuPriceValues>({
    retailCost: product.retailCost,
    cost: product.cost,
    markup: product.markup,
  });

  const [configuration, setConfiguration] = useState<Record<"addons" | "coverage" | "deductible", any>>({
    addons: [],
    coverage: null,
    deductible: null,
  });

  const productWithAddons = useMemo(
    () => ({
      ...product,
      addons: configuration.addons,
    }),
    [product, configuration]
  );

  useEffect(() => {
    if (type !== "forEdit" || !proposalProductConfiguration) return;

    setConfiguration(proposalProductConfiguration);
  }, [proposalProductConfiguration]);

  const { deductibleOptions, mileageOptions, addonsOptions } = useMemo(() => {
    const data: { API_RESPONSE_DATA: Rate } =
      product?.aftermarketProduct?.criteria?.[0]?.event?.params?.AFTERMARKET_PRODUCT_VENDOR_API_DATA;

    const accessKey = `${configuration.coverage?.termMonths}-${configuration.coverage?.termOdometer}`;

    return {
      deductibleOptions: _.uniqBy(data?.API_RESPONSE_DATA.terms, term => term.deductible.amount).map(
        term => term.deductible.amount
      ),
      mileageOptions: configuration.deductible
        ? data?.API_RESPONSE_DATA.terms.filter(term => term.deductible.amount === configuration.deductible)
        : [],
      addonsOptions: data?.API_RESPONSE_DATA.terms.reduce<any>((acc, data) => {
        const key = `${data.termMonths}-${data.termOdometer}`;

        acc[key] = data;

        return acc;
      }, {})?.[accessKey],
    };
  }, [product, configuration]);

  const handleProductConfigurationUpdate = (nextConfiguration: any) => {
    if (updateProductConfiguration) {
      updateProductConfiguration(product.proposalProductId, menuName, "configuration", nextConfiguration);
      updateProductConfiguration(product.proposalProductId, menuName, "addons", nextConfiguration.addons);
    }
  };

  const handleDeductibleChange = (selectedAmount: number) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        coverage: null,
        deductible: selectedAmount,
      };

      if (type === "forEdit") handleProductConfigurationUpdate(nextConfiguration);
      return nextConfiguration;
    });
  };

  const handleMileageChange = (selectedTerm: any) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        coverage: selectedTerm,
      };

      if (type === "forEdit") handleProductConfigurationUpdate(nextConfiguration);
      return nextConfiguration;
    });

    setForcePriceValue({
      cost: selectedTerm?.dealerCost ?? 0,
      retailCost: selectedTerm?.dealerCost ?? 0,
      markup: { type: MarkupType.Percentage, markup: 0 },
    });
  };

  const handleCheckboxChange = (lossCode: any, value: boolean) => {
    if (!configuration.coverage) return;

    setConfiguration(prev => {
      const nextAddons = !value
        ? prev.addons.filter((addon: any) => addon.title !== lossCode.title)
        : [...prev.addons, lossCode];

      const nextConfiguration = {
        ...prev,
        addons: nextAddons,
      };

      if (type === "forEdit") handleProductConfigurationUpdate(nextConfiguration);
      return nextConfiguration;
    });
  };

  return (
    <ProposalCardContainer
      mode={mode}
      type={type}
      menuName={menuName}
      configuration={configuration}
      logo={vendorsLogoByType["NTP"]}
      forcePriceValue={forcePriceValue}
      proposalProduct={productWithAddons}
      updateProductConfiguration={updateProductConfiguration}
    >
      <Fields
        configuration={configuration}
        uniqueDeductibles={deductibleOptions}
        filteredMileageOptions={mileageOptions}
        handleMileageChange={handleMileageChange}
        handleDeductibleChange={handleDeductibleChange}
        disableAllFields={mode === ProposalProductCardModes.Constructor}
      />

      {addonsOptions && (
        <Addons
          configuration={configuration}
          addonsOptions={addonsOptions}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </ProposalCardContainer>
  );
};
