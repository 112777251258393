import React from "react";
import clsx from "clsx";
import { useDrop } from "react-dnd";
import { DeleteOutline } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Tooltip, IconButton } from "@material-ui/core";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { DnDTypes } from "../../../types";
import { LenderCard } from "../lenderCard/LenderCard";

interface Props {
  stepNumber: number;
  onDrop?: VoidFunction;
  onRemove?: VoidFunction;
  lenders: LenderProfiles[];
  onCreateProgram?: () => void;
  onSelectProgram?: () => void;
  onRemoveLender?: (lenderId: string) => void;
  onChangeAutoSubmit?: (lenderId: string) => void;
}

export const StepCard = ({
  onDrop,
  onRemove,
  lenders,
  stepNumber,
  onRemoveLender,
  onCreateProgram,
  onSelectProgram,
  onChangeAutoSubmit,
}: Props) => {
  const classes = useStyles();

  const [{ isOver }, drop] = useDrop<LenderProfiles, void, { isOver: boolean }>(
    () => ({
      accept: DnDTypes.LenderCard,
      drop: onDrop,
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDrop]
  );

  return (
    <Box
      className={clsx(classes.card, {
        [classes.isOverCard]: isOver,
      })}
      {...({ ref: drop } as any)}
    >
      <Box className={classes.cardHeader}>
        {onRemove && (
          <Tooltip title={`Remove step ${stepNumber}`}>
            <IconButton size="small" onClick={onRemove}>
              <DeleteOutline color="error" onClick={onRemove} />
            </IconButton>
          </Tooltip>
        )}

        <Typography component="span" variant="subtitle2">
          Step #{stepNumber}
        </Typography>
      </Box>

      <>
        {!lenders.length ? (
          <Typography align="center" component="span" variant="body2">
            Drag & drop your lenders here
          </Typography>
        ) : (
          <Box className={classes.cardContent}>
            {lenders.map(lender => (
              <LenderCard
                isAutoSubmitted
                lender={lender}
                onDelete={onRemoveLender}
                key={lender.lenderAccountId}
                onCreateProgram={onCreateProgram}
                onSelectProgram={onSelectProgram}
                onChangeAutoSubmit={onChangeAutoSubmit}
              />
            ))}
          </Box>
        )}
      </>
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    width: "100%",
    padding: "8px",
    display: "flex",
    minHeight: "120px",
    borderRadius: "8px",
    flexDirection: "column",
    border: `dashed 2px ${grey["500"]}`,
  },
  isOverCard: {
    border: `solid 2px ${grey["500"]}`,
  },
  cardHeader: {
    gap: "8px",
    padding: "8px",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    background: grey["200"],
    justifyContent: "flex-start",
  },
  cardContent: {
    gap: "8px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
});
