import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import ProgressStepper from "./SubmissionProgressStepper";
import Paper from "@material-ui/core/Paper";
import blue from "@material-ui/core/colors/blue";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
// import DecisionLetters from "./LenderSubmissionDecisionLetters";
import UpdateMessageModal from "../../components/UpdateMessageModal";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// import { CHAT_V2_OPEN, CHAT_V2_RESET, CHAT_V2_SET_TARGET_RECIPIENT, CHAT_V2_TASKS } from "../../redux/types";
import { isTwoDecimalsFormat, numberWithCommas } from "utils";

const MAX_CUR_AMOUNT = 10000000;

const useStylesPaper = makeStyles(theme => ({
  clear: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: 600,
    marginTop: 0,
    paddingTop: 0,
  },
  labelRoot: {
    fontWeight: 800,
    color: "black",
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
  container: {
    padding: "25px",
    margin: "0px",
    width: "100%",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16739)",
    borderRadius: "7px",
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  formButtonsContainer: {
    margin: "0px",
    display: "flex",
    justifyContent: "space-between",
  },
  formButton: {
    padding: "0px !important",
    fontSize: "12px",
    fontWeight: 400,
  },
  headerContainer: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  submissionDateContainer: {
    width: "100%",
    padding: "0px",
    margin: "15px 0px 15px",
    display: "flex",
    justifyContent: "space-between",
  },
  submissionDateWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  submissionAPIContainer: {
    display: "flex",
    width: "100%",
    padding: "0 10px",
    margin: "20px 0px 0px",
    fontSize: "9px",
    justifyContent: "flex-start",
  },
  formControl: {
    minWidth: 500,
    marginTop: "20px",
    marginBottom: "20px",
  },
  margin: {
    marginLeft: "15px",
    padding: "5px 10px",
  },
  formInput: {
    paddingTop: "11px",
  },
  cardHeaderStyles: {
    padding: "0px",
    fontWeight: "200",
    fontSize: "20px",
  },
  errorText: {
    color: "#AE0000 !important",
    fontWeight: "700",
  },
  titleStyles: {
    fontSize: "25px",
    fontWeight: "200",
    textTransform: "none",
    marginLeft: "4px",
  },
  subtitleStyles: {
    fontSize: "20px",
    fontWeight: "300",
  },
  ...extendedFormsStyle,
}));

const styles = theme => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  confirmChanges: {
    padding: "20px 0px",
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    fontSize: 40,
    width: 18,
    height: 18,
  },
  taskList: {
    marginTop: "10px",
    "& > div > .MuiGrid-root": {
      maxWidth: "100%",
    },
  },
  submissionInfo: {
    color: theme.palette.primary.main,
  },
  submissionInfoText: {
    color: "#000",
  },
  openChatBtn: {
    padding: 10,
    marginRight: 30,
  },
  detailInput: {
    "& .MuiFormLabel-root": {
      fontSize: "14px",
      textTransform: "uppercase",
      fontWeight: "400",
      lineHeight: "14px",
      color: "#3C4858 !important",
    },
  },
  detailCardInput: {
    flex: "1 0",
  },
  cardDetail: {
    "& .MuiPaper-root": {
      margin: "10px 0px 0px 0px !important",
      width: "100%",
    },
  },
  taskHeader: {
    marginLeft: "5px !important",
    marginRight: "13px !important",
  },
  taskTile: {
    fontSize: "20px",
    fontWeight: "300",
    textTransform: "none",
    color: "#000000",
  },
  taskButton: {
    color: "#000",
    fontSize: 12,
    fontWeight: "400",
    backgroundColor: "#E0E0E0",
    width: "175px",
    padding: "10px 0",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },
  cancelButton: {
    color: "#000 !important",
    width: 145,
    backgroundColor: "#E0E0E0",
  },
  modalButton: {
    margin: "0 20px 0 0",
    padding: "10px",
    fontSize: 12,
    fontWeight: 300,
    fontFamily: "Roboto",
  },
  modalHeader: {
    padding: "0px 0px 0px 20px",
    fontSize: 25,
    fontWeight: 300,
    color: "#000",
    lineHeight: "26px",
    textTransform: "none",
    marginRight: "100px",
  },
});

const useStyles = makeStyles(styles);

const UPDATE_CREDITSUB_STAGE = gql`
  mutation($fieldsObj: UpdateCreditSubmissionInput!, $entityId: String!) {
    updateCreditSubmission(fieldsObj: $fieldsObj, entityId: $entityId)
  }
`;

export default function LenderSubmissionDetailModal({
  vo,
  open,
  handleCloseLenderDetailModalView,
  creditSubmission,
  relatedCreditSubmissions,
  refetchCreditSubmissions,
  vp,
  account,
  userProfile,
  // updated,
  // setUpdated,
  masterCreditSubmissionId,
}) {
  const dispatch = useDispatch();
  const classesPaper = useStylesPaper();
  const [creditSubStage, setCreditSubStage] = useState(!!creditSubmission && creditSubmission.creditSubStage);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [approvedAmount, setApprovedAmount] = useState(
    creditSubmission && creditSubmission.approvedAmount
      ? numberWithCommas(parseFloat(!!creditSubmission && creditSubmission.approvedAmount).toFixed(2)) ||
          numberWithCommas(Number(0).toFixed(2))
      : ""
  );
  const [approvedRate, setApprovedRate] = useState((!!creditSubmission && creditSubmission.approvedRate) || "");
  const [transactionSummary, setTransactionSummary] = useState(
    (!!creditSubmission && creditSubmission.transactionSummary) || ""
  );
  const [amountRequested, setAmountRequested] = useState(
    numberWithCommas(parseFloat(!!creditSubmission && creditSubmission.amountRequested).toFixed(2)) ||
      numberWithCommas(Number(0).toFixed(2))
  );
  const [creditDecision, setCreditDecision] = useState(!!creditSubmission && creditSubmission.decision);
  const [creditNotes, setCreditNotes] = useState(!!creditSubmission && creditSubmission.notes);
  const [contingencies, setContingencies] = useState(
    !!creditSubmission.contingencies && creditSubmission.contingencies ? creditSubmission.contingencies : ""
  );
  const [displayStatus, setDisplayStatus] = useState(
    !!creditSubmission.displayStatus && creditSubmission.displayStatus ? creditSubmission.displayStatus : ""
  );
  const [updateStage] = useMutation(UPDATE_CREDITSUB_STAGE, {
    context: { authRequired: true },
  });
  const classes = useStyles();
  const [updated, setUpdated] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCancelOtherCS, setOpenCancelOtherCS] = useState(false);

  const [lenderContact, setLenderContact] = useState(null);
  const lenderProfiles = useSelector(state => state.lp);

  useEffect(
    () => {
      if (creditSubmission) {
        if (creditSubmission.lenderName) {
          setLenderContact(
            _.find(lenderProfiles, o => {
              return o.lenderName === creditSubmission.lenderName && o.lenderUserProfile && o.lenderAccount;
            })
          );
        }
        if (creditSubmission.decision) {
          if (creditSubmission.decision === "Awaiting Review") {
            setCreditDecision(803370000);
          } else if (creditSubmission.decision === "Requested Additional Information") {
            setCreditDecision(803370001);
          } else if (creditSubmission.decision === "Declined") {
            setCreditDecision(803370002);
          } else if (creditSubmission.decision === "Approved") {
            setCreditDecision(803370003);
          } else if (creditSubmission.decision === "Pre-Approved") {
            setCreditDecision(803370004);
          }
        }
      }
    },
    [] /* to make it execute one */
  );
  const handleChangeStage = event => {
    setCreditSubStage(event.target.value);
    setUpdated(true);
  };
  const handleChangeDecision = event => {
    setCreditDecision(event.target.value);
    setUpdated(true);
  };
  const handleChangeDisplayStatus = event => {
    setDisplayStatus(event.target.value);
    setUpdated(true);
  };
  // const onTasksLoaded = loadedInfo => {
  //   dispatch({ type: CHAT_V2_TASKS, payload: loadedInfo.tasks });
  // };
  const handleSubmitStageChange = async () => {
    try {
      if (updated && openConfirm) {
        setUpdated(false);
        setOpenConfirm(false);
      }
      const response = await updateStage({
        variables: {
          fieldsObj: {
            creditSubStage: creditSubStage,
            relatedCreditSubmissions: relatedCreditSubmissions,
            approvedAmount: parseFloat(approvedAmount.replaceAll(",", "")),
            rate: parseFloat(approvedRate),
            decision: creditDecision,
            notes: creditNotes,
            contingencies: contingencies,
            amountRequested: parseFloat(amountRequested.replaceAll(",", "")),
            transactionSummary: transactionSummary,
            masterCreditSubmissionId: masterCreditSubmissionId,
            displayStatus: displayStatus,
          },
          entityId: creditSubmission.creditSubId,
        },
      });
      if (response.data.updateCreditSubmission) {
        await refetchCreditSubmissions();
        await setResponseSuccess(true);
        await handleOpenMessageModal();
        setTimeout(() => {
          setResponseSuccess(false);
          handleCloseMessageModal();
          handleCloseLenderDetailModalView();
        }, 2000);
      } else {
        await setResponseSuccess(false);
        await handleOpenMessageModal();
      }
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(true);
  };
  const handleRadioChange = event => {
    setConfirmed(event.target.value === "true");
    setUpdated(true);
  };
  const handleChangeApprovedAmount = event => {
    const valueWithoutCommas = event.target.value.replaceAll(",", "");

    if (event.target.value === "") {
      setApprovedAmount(event.target.value);
    }

    const incorrectFormat =
      valueWithoutCommas &&
      valueWithoutCommas.length > 1 &&
      valueWithoutCommas[0] === "0" &&
      valueWithoutCommas[1] !== ".";

    if (!isTwoDecimalsFormat(event) || incorrectFormat) {
      return;
    }

    const parsedValue = parseFloat(valueWithoutCommas);

    if (parsedValue <= MAX_CUR_AMOUNT) {
      setApprovedAmount(valueWithoutCommas);
      setUpdated(true);
    }
  };

  const handleChangeApprovedRate = event => {
    setApprovedRate(ownerPercentageFormat(event.target.value));
    setUpdated(true);
  };

  const handleChangeAmountRequested = event => {
    const valueWithoutCommas = event.target.value.replaceAll(",", "");
    if (event.target.value === "") {
      setAmountRequested(event.target.value);
    }
    const incorrectFormat =
      valueWithoutCommas.length > 1 && valueWithoutCommas[0] === "0" && valueWithoutCommas[1] !== ".";
    if (!isTwoDecimalsFormat(event) || !valueWithoutCommas || incorrectFormat) {
      return;
    }
    const parsedValue = parseFloat(valueWithoutCommas);

    if (parsedValue <= MAX_CUR_AMOUNT) {
      setAmountRequested(valueWithoutCommas);
      setUpdated(true);
    }
  };

  const ownerPercentageFormat = v => {
    if (v) {
      v = v.toString();
      v = v.replace(/[^0-9.]/g, "");
      if (v > 100) {
        v = parseFloat(v.toString().slice(0, -1));
      }
    }

    return v;
  };

  // const handleChatWithLender = event => {
  //   event.preventDefault();
  //   if (!lenderContact) return;
  //   dispatch({
  //     type: CHAT_V2_SET_TARGET_RECIPIENT,
  //     payload: lenderContact.lenderUserProfile.id,
  //   });
  //   dispatch({ type: CHAT_V2_RESET });
  //   //Open chat
  //   dispatch({
  //     type: CHAT_V2_OPEN,
  //     payload: true,
  //   });
  //   //Close Window
  //   handleCloseLenderDetailModalView();
  // };

  const handleSetOpenConfirmOpen = () => setOpenConfirm(true);
  const handleSetOpenConfirmClose = () => {
    setOpenConfirm(false);
    handleCloseLenderDetailModalView();
  };

  const handleSetOpenCancelOtherCSOpen = () => setOpenCancelOtherCS(true);
  const handleSetOpenCancelOtherCSClose = () => {
    setOpenCancelOtherCS(false);
  };

  return (
    <>
      {!!creditSubmission && (
        <div style={{ width: "400px" }} key={creditSubmission.creditSubId}>
          <Dialog
            onClose={updated ? handleSetOpenConfirmOpen : handleCloseLenderDetailModalView}
            open={open}
            scroll={"body"}
            maxWidth={"md"}
            fullWidth={true}
          >
            <MuiDialogTitle disableTypography className={classes.root} style={{ paddingBottom: 0 }}>
              <Grid container className={classesPaper.headerContainer}>
                <Typography variant="h6" className={classesPaper.titleStyles}>
                  Submission Detail{" "}
                  {creditDecision === "Declined" && (
                    <span className={classesPaper.errorText}> Lender Submission Failed</span>
                  )}
                </Typography>
                <Grid item style={{ textAlign: "center" }}>
                  <div style={{ fontSize: "15px", padding: "0" }}>
                    <b className={classes.submissionInfo}>Submission Date: </b>
                    {creditSubmission.submissionDate || ""}
                  </div>
                </Grid>
                <Grid item>
                  {/* <Grid item style={{ marginRight: "30px" }}>
                    <Button onClick={handleChatWithLender} color="primary" className={classes.openChatBtn}>
                      <ChatBubbleIcon fontSize="small" />
                      Chat with Lender
                    </Button>
                  </Grid> */}
                  {open ? (
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={updated ? handleSetOpenConfirmOpen : handleCloseLenderDetailModalView}
                    >
                      <CloseIcon fontSize="large" />
                    </IconButton>
                  ) : null}
                </Grid>
              </Grid>
            </MuiDialogTitle>
            <Paper className={classesPaper.paper}>
              <CardContent style={{ padding: "0 20px" }}>
                <div>
                  <Grid
                    container
                    spacing={4}
                    className={`${classesPaper.submissionDateContainer} ${creditDecision === "Declined" &&
                      classesPaper.errorText}`}
                    style={{ paddingLeft: 0 }}
                  >
                    <Grid item xs={12} md={4} lg={4} style={{ paddingLeft: 4 }}>
                      <div style={{ fontSize: "15px", padding: "0" }}>
                        <b className={classes.submissionInfo}>Lender Name: </b>
                        {creditSubmission.lenderName || ""}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} style={{ textAlign: "center", paddingLeft: 0 }}>
                      <div style={{ fontSize: "15px", padding: "0" }}>
                        <b className={classes.submissionInfo}>Application ID:</b>
                        <span className={classes.submissionInfoText}>{creditSubmission.apiApplicationId || ""}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} style={{ textAlign: "right" }}>
                      <div style={{ fontSize: "15px", padding: "0" }}>
                        <b className={classes.submissionInfo}>Requested Amount: </b>
                        {amountRequested ? `$${numberWithCommas(amountRequested)}` : ""}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      margin: "20px 0 10px",
                      boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.16739)",
                      borderRadius: "7px",
                    }}
                  >
                    <ProgressStepper
                      creditDecision={creditDecision}
                      setCreditDecision={setCreditDecision}
                      creditSubStage={creditSubStage}
                      setCreditSubStage={setCreditSubStage}
                    />
                  </Grid>
                  <Grid container spacing={4} className={classesPaper.container}>
                    <CardHeader
                      title="Details"
                      className={classesPaper.cardHeaderStyles}
                      titleTypographyProps={{
                        variant: "h6",
                        className: classesPaper.subtitleStyles,
                      }}
                    />
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4} lg={4} className={classes.detailCardInput}>
                        <FormControl fullWidth className={classesPaper.selectFormControl}>
                          <InputLabel
                            htmlFor="credit-sub-select-outlined"
                            className={classesPaper.selectLabel}
                            style={{ top: 9 }}
                          >
                            Decision
                          </InputLabel>
                          <Select
                            classes={{ select: classesPaper.select }}
                            inputProps={{
                              name: "credit-sub-select-outlined",
                              id: "credit-sub-select-outlined",
                              value: creditDecision,
                              onChange: handleChangeDecision,
                            }}
                          >
                            <MenuItem aria-label="None" value="" />
                            <MenuItem value={803370000}>Awaiting Review</MenuItem>
                            <MenuItem value={803370001}>Requesting Additional Information</MenuItem>
                            <MenuItem value={803370002}>Declined</MenuItem>
                            <MenuItem value={803370004}>Pre-Approved</MenuItem>
                            <MenuItem value={803370003}>Approved</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3} lg={3} className={`${classes.detailInput} ${classes.detailCardInput}`}>
                        <CustomInput
                          labelProps={{
                            shrink: true,
                            className: classesPaper.selectLabel,
                          }}
                          labelText="Rate"
                          formControlProps={{
                            fullWidth: true,
                            className: classesPaper.formInput,
                          }}
                          inputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            value: approvedRate,
                            onChange: e => handleChangeApprovedRate(e),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3} className={classes.detailInput}>
                        <CustomInput
                          labelProps={{
                            shrink: true,
                            className: classesPaper.selectLabel,
                          }}
                          labelText="Max Approval Amount"
                          formControlProps={{
                            fullWidth: true,
                            className: classesPaper.formInput,
                          }}
                          inputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            value: approvedAmount,
                            onChange: e => handleChangeApprovedAmount(e),
                            onFocus: () => {
                              setApprovedAmount(approvedAmount.replaceAll(",", ""));
                            },
                            onBlur: () => {
                              if (approvedAmount !== "") {
                                setApprovedAmount(numberWithCommas(parseFloat(approvedAmount).toFixed(2)));
                              } else {
                                setApprovedAmount(parseFloat(0).toFixed(2));
                              }
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3} className={classes.detailInput}>
                        <CustomInput
                          labelProps={{
                            shrink: true,
                            className: classesPaper.selectLabel,
                          }}
                          labelText="Transaction Amount"
                          formControlProps={{
                            fullWidth: true,
                            className: classesPaper.formInput,
                          }}
                          inputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            value: amountRequested,
                            onChange: e => handleChangeAmountRequested(e),
                            onFocus: () => {
                              setAmountRequested(amountRequested.replaceAll(",", ""));
                            },
                            onBlur: () => {
                              if (amountRequested !== "") {
                                setAmountRequested(numberWithCommas(parseFloat(amountRequested).toFixed(2)));
                              } else {
                                setAmountRequested(parseFloat(0).toFixed(2));
                              }
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4} lg={4} className={classes.detailCardInput}>
                        <FormControl fullWidth className={classesPaper.selectFormControl}>
                          <InputLabel
                            htmlFor="credit-sub-select-outlined"
                            className={classesPaper.selectLabel}
                            style={{ top: 9 }}
                          >
                            Display Status
                          </InputLabel>
                          <Select
                            classes={{ select: classesPaper.select }}
                            inputProps={{
                              name: "credit-sub-select-outlined",
                              id: "credit-sub-select-outlined",
                              value: displayStatus,
                              onChange: handleChangeDisplayStatus,
                            }}
                          >
                            <MenuItem aria-label="None" value="" />
                            <MenuItem value={100}>Show</MenuItem>
                            <MenuItem value={200}>Hide</MenuItem>
                            <MenuItem value={300}>Show - Dealer Created</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={4}>
                      <Grid item xs={12} style={{ paddingBottom: 0 }} className={classes.detailInput}>
                        <CustomInput
                          labelText="TRANSACTION SUMMARY"
                          labelProps={{
                            shrink: true,
                            className: classesPaper.selectLabel,
                          }}
                          formControlProps={{
                            fullWidth: true,
                            className: classesPaper.formInput,
                          }}
                          inputProps={{
                            placeholder: "Type notes here",
                            value: transactionSummary,
                            multiline: true,
                            onChange: e => setTransactionSummary(e.target.value),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: 0 }} className={classes.detailInput}>
                        <TextField
                          label="Lender Notes"
                          fullWidth
                          margin="normal"
                          multiline={true}
                          maxRows={15}
                          minRows={3}
                          onChange={e => {
                            setCreditNotes(e.target.value);
                          }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          value={creditNotes}
                        />
                      </Grid>
                    </Grid>
                    {creditSubmission ? (
                      <Grid container spacing={4}>
                        <Grid item xs={12} className={classes.detailInput}>
                          <TextField
                            label="Dealer notes"
                            fullWidth
                            margin="normal"
                            multiline={true}
                            maxRows={15}
                            minRows={3}
                            onChange={e => {
                              setContingencies(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              disabled: false,
                              classes: { disabled: classesPaper.disabledInput },
                            }}
                            variant="outlined"
                            value={contingencies || ""}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  {/* <Grid container className={classes.cardDetail}>
                    <DecisionLetters vo={vo} creditSubId={creditSubmission.creditSubId} initCollapsed />
                  </Grid> */}
                  {/* <Grid item xs={12} className={`${classes.taskList} ${classes.cardDetail}`}>
                    {!vp ? (
                      "no VendorProfile loaded"
                    ) : vp.showTasks === "show" ? (
                      <SpecificTasks
                        vo={vo}
                        account={account}
                        // onTasksLoaded={onTasksLoaded}
                        userProfile={userProfile}
                        creditSubId={creditSubmission.creditSubId}
                        lenderContact={creditSubmission.lenderContactInfo}
                        submissionsTable={true}
                        initCollapsed
                        classNameHeader={classes.taskHeader}
                        tileTitle={<span className={classes.taskTile}>Task</span>}
                        classNameButton={classes.taskButton}
                      />
                    ) : (
                      <div />
                    )}
                  </Grid> */}
                  <br />
                  <Grid container className={classesPaper.formButtonsContainer}>
                    <Grid item className={classesPaper.formButton}>
                      <Button onClick={handleSetOpenConfirmClose} color="secondary" className={classes.cancelButton}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item className={classesPaper.formButton}>
                      <Button onClick={handleSubmitStageChange} color="primary">
                        Confirm Changes
                      </Button>
                    </Grid>
                  </Grid>
                  <UpdateMessageModal
                    open={openMessageModal}
                    handleCloseMessageModal={handleCloseMessageModal}
                    responseSuccess={responseSuccess}
                  />
                </div>
                <br />
              </CardContent>
            </Paper>
          </Dialog>
          <Dialog
            onClose={updated ? handleCloseLenderDetailModalView : handleCloseLenderDetailModalView}
            open={openConfirm}
            scroll={"body"}
            maxWidth={"sm"}
          >
            <MuiDialogTitle disableTypography className={classes.confirmChanges}>
              <Typography component="h6" variant="h6" className={classes.modalHeader}>
                Confirm Changes Before Closing?{" "}
              </Typography>
              {open ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenConfirm(false)}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              ) : null}
            </MuiDialogTitle>
            <Paper>
              <CardContent style={{ paddingBottom: 0, paddingTop: 0 }}>
                <div style={{ padding: 0 }}>
                  <Grid container style={{ paddingBottom: 0 }}>
                    <Grid item style={{ marginLeft: "4px" }}>
                      <Button
                        onClick={handleSubmitStageChange}
                        className={classes.modalButton}
                        style={{ backgroundColor: "#3E83E6" }}
                      >
                        Confirm Changes
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleSetOpenConfirmClose}
                        className={classes.modalButton}
                        style={{ backgroundColor: "#E0E0E0", color: "#000" }}
                      >
                        Cancel & Close
                      </Button>
                    </Grid>
                  </Grid>
                  <UpdateMessageModal
                    open={openMessageModal}
                    handleCloseMessageModal={handleCloseMessageModal}
                    responseSuccess={responseSuccess}
                  />
                </div>
                <br />
              </CardContent>
            </Paper>
          </Dialog>
          {/* <Dialog
            onClose={handleSetOpenCancelOtherCSClose}
            open={openCancelOtherCS}
            scroll={"body"}
            maxWidth={"md"}
            fullWidth={true}
          ></Dialog> */}
        </div>
      )}
    </>
  );
}
