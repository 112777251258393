import React, { useContext, useEffect, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, Snackbar, TextField } from "@material-ui/core";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import Tile from "components/Tile/Tile";
import { useSelector } from "react-redux";
import CreditSubmissionsTable from "./CreditSubmissionsTable";
import LenderContactsTable from "./LenderContactsTable";
import FinanceProgramMgmt from "pages/FinanceProgramMgmt";
import {
  AddOutlined,
  ArrowBackIosOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LockOpenOutlined,
} from "@material-ui/icons";
import ContactModal from "./ContactModal";
import ChangeLenderContact from "./contact";
import UpdateLenderCredentials from "./credentials";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { referenceTypes } from "../../pages/Prequal/constants";
import { LenderMatchingCriteria } from "./LenderMatchingCriteria";
import DealerCredentialsModal from "../../copiedLP/src/pages/DealerMgmt/DealerCredentialsModal/DealerCredentialsModal";
import PersonalLinkAndQRCode from "../../components/PersonalLinkAndQRCode";
import { lenderTypeMap } from "../../pages/VendorOpportunity/LenderSubmissionMappingModals/constants";
import { theme } from "../../theme";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import { REACT_APP_OCA_BASE_URL, S3_BUCKET_NAME } from "../../config.js";
import _ from "lodash";

const statusEnum = {
  active: "Active",
  pending_approval: "Staged",
  approved: "Invited",
  blocked: "Blocked",
};

const styles = theme => ({
  addContactButton: {
    boxShadow:
      "0 2px 2px 0 rgba(12, 132, 252, 0.14), 0 3px 1px -2px rgba(12, 132, 252, 0.2), 0 1px 5px 0 rgba(12, 132, 252, 0.12)",
    backgroundColor: theme.palette.primary.main,
    height: "fit-content",
    fontSize: 12,
    color: "#ffffff !important",
  },
  formButton: {
    padding: "12px 20px",
    width: "125px",
    backgroundColor: "#3E83E6",
    borderRadius: 7,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "18px",
  },
});

const GET_LENDER_PROFILE_CONTACTS = gql`
  query GetLenderProfileByDynamicsId($lenderProfileDynamicsId: ID!) {
    getLenderProfileByDynamicsId(lenderProfileDynamicsId: $lenderProfileDynamicsId) {
      lenderMatrixMatchingRules
      lenderProfileUsers {
        accessDealerLocationIds
        accessDealerUserIds
        lenderProfileId
        lenderProfileToUserId
        lenderUserProfileId
        receivesNotifications
        notificationContact
        userProfile {
          # account
          accountId
          adminRole
          availability
          dynamicsContactId
          email
          firstName
          lastName
          fullName
          title
          status
          lastLogin
          phoneNumber
          mobilePhoneNumber
        }
      }
    }
  }
`;

const GET_CREDIT_SUBMISSIONS_BY_DYNAMICS_LP = gql`
  query CreditSubmissionsByDynamicsLPId($id: ID!) {
    creditSubmissionsByDynamicsLPId(id: $id) {
      voId
      creditSubId
      submissionDate
      submittedBy
      decision
      applicantName
      lenderName
      amountRequested
      approvedAmount
      notes
      contingencies
      approvedRate
      creditSubStage
      apiApplicationId
      apiResult
      apiExpiredCredentials
      apiDetail
      lenderProfileId
      lenderChannelCode
      lenderTypeCode
      draft
      creditSubStage
      transactionSummary
      mappedValues
      lenderContactInfo {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
      fastTrackResults {
        approvedBy
        fastTrackId
        maxApprovalAmount
        rate
        inputs {
          key
          value
        }
        outputs {
          key
          value
        }
      }
    }
  }
`;

const ADD_LENDER_PROFILE_USER = gql`
  mutation AddLenderProfileUser($input: AddLenderProfileUserInput) {
    addLenderProfileUser(input: $input) {
      lenderProfileToUserId
      userProfile {
        lastName
        firstName
        fullName
        lastLogin
        email
        status
        phoneNumber
        mobilePhoneNumber
        title
        lastLogin
      }
    }
  }
`;

const UPDATE_LENDER_PROFILE_USER = gql`
  mutation UpdateLenderProfileUser($input: UpdateLenderProfileUserInput) {
    updateLenderProfileUser(input: $input) {
      accessDealerLocationIds
      accessDealerUserIds
      lenderProfileId
      lenderProfileToUserId
      userProfile {
        lastName
        firstName
        fullName
        lastLogin
        email
        status
        phoneNumber
        mobilePhoneNumber
        title
        lastLogin
      }
    }
  }
`;

const REMOVE_LENDER_PROFILE_USER = gql`
  mutation RemoveLenderProfileUser($lenderProfileToUserId: ID!) {
    removeLenderProfileUser(lenderProfileToUserId: $lenderProfileToUserId)
  }
`;

const Q_CONTACT = gql`
  query($dynamicsId: ID!) {
    contact(dynamicsId: $dynamicsId) {
      accountId
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
      lastLogin
      invitationDatetime
      status
      id
      locationId
    }
  }
`;

const QUERY_ACTIVE_OCA_TEMPLATE = gql`
  query($vendorProfileId: ID!, $isActive: Boolean) {
    getOcaTemplatesByVendorProfile(vendorProfileId: $vendorProfileId, isActive: $isActive) {
      ocaTemplateId
      name
      vendorProfileName
      jsonDefinition
      active
    }
  }
`;

const UPDATE_LENDER_PROFILE_GQL = gql`
  mutation($entityId: String!, $fieldsObj: UpdateLenderProfileInput!) {
    updateLenderProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
      logo
    }
  }
`;

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;

const INTEGRATION_SETTINGS = gql`
  query($integrationName: String!) {
    integrationSettings(integrationName: $integrationName) {
      integrationName
      integrationSettings
    }
  }
`;

// const GET_LENDER_MATRIX_PROPERTIES = gql`
//   query($lenderType: LenderType!) {
//     lenderProperties(lenderType: $lenderType) {
//       details {
//         category
//         dcrPropertySource
//         # dependentLenderFields
//         displayName
//         fieldInputType
//         hidden
//         lenderFieldParentId
//         notesExternal
//         notesInternal
//         ordinal
//         readOnly
//         required
//         rulesConfiguration
//         section
//       }
//       lenderFieldId
//       lenderProperty
//     }
//   }
// `;

const useStyles = makeStyles(styles);

const mergeContacts = (primaryContact, contacts) => {
  let arr = [];
  if (primaryContact) arr.push({ ...primaryContact, isPrimaryContact: true });
  if (contacts) {
    arr = arr.concat(contacts);
    arr = _.remove(arr, contact => !(contact.email === primaryContact?.email && contact.isPrimaryContact));
  }
  return arr;
};

const LenderDetails = ({ match }) => {
  const classes = useStyles();

  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);

  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const lenderProfiles = useSelector(state => state.lp);
  const vp = useSelector(state => state.vp);
  const [lender, setLender] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [submissions, setSubmissions] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [contact, setContact] = useState(null);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const [locations, setLocations] = useState([]);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [submissionTotals, setSubmissionTotals] = useState({ approved: 0, awaitingApproval: 0, declined: 0 });
  const [openChangeLenderContactModal, setOpenChangeLenderContactModal] = useState(false);
  const [openUpdateLenderCredentialsModal, setOpenUpdateLenderCredentialsModal] = useState(false);
  const [insuranceRequirements, setInsuranceRequirements] = useState("");
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [saveLenderProfileSuccess, setSaveLenderProfileSuccess] = useState(false);
  const [saveLenderProfileError, setSaveLenderProfileError] = useState(false);
  const [isInsuranceModuleSetInOca, setIsInsuranceModuleSetInOca] = useState(false);
  const [lenderMatchingCriteriaInputs, setLenderMatchingCriteriaInputs] = useState([]);
  const [isSettingLenderMatchingRulesInProgress, setIsSettingLenderMatchingRulesInProgress] = useState(false);
  const [editName, setEditName] = useState(false);
  const [lenderName, setLenderName] = useState("");
  const [dealerCredentialsModalIsOpen, setDealerCredentialsModalIsOpen] = useState(false);
  const [ocaLink, setOcaLink] = useState();
  const [lenderActiveOcaTemplate, setLenderActiveOcaTemplate] = useState();

  const lenderProfileDynamicsId = match.params.dynamicLenderProfileId;
  const { loading: lpContactsLoading, data: lpContactsData, refetch } = useQuery(GET_LENDER_PROFILE_CONTACTS, {
    variables: {
      lenderProfileDynamicsId,
    },
    fetchPolicy: "no-cache",
  });
  const { loading: lpSubmissionsLoading, data: lpSubmissionsData } = useQuery(GET_CREDIT_SUBMISSIONS_BY_DYNAMICS_LP, {
    variables: {
      id: lenderProfileDynamicsId,
    },
    fetchPolicy: "no-cache",
  });
  const { data: accountActiveOCATemplateData } = useQuery(QUERY_ACTIVE_OCA_TEMPLATE, {
    variables: {
      vendorProfileId: account.vendorProfileId,
      isActive: true,
    },
    fetchPolicy: "no-cache",
  });

  const [
    getLenderActiveOCATemplate,
    { data: lenderActiveOCATemplateData, loading: isLenderActiveOCATemplateDataLoading },
  ] = useLazyQuery(QUERY_ACTIVE_OCA_TEMPLATE, {
    variables: {
      isActive: true,
    },
    fetchPolicy: "no-cache",
  });

  const { loading: loadingIntegrationSettings, data: integrationSettingsData } = useQuery(INTEGRATION_SETTINGS, {
    skip: !lenderTypeMap.get(lender?.lenderChannelCode),
    variables: { integrationName: lenderTypeMap.get(lender?.lenderChannelCode) },
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  // const { loading: lenderMatrixPropertiesLoading, data: lenderMatrixPropertiesData } = useQuery(
  //   GET_LENDER_MATRIX_PROPERTIES,
  //   {
  //     variables: {
  //       lenderType: "LENDER_MATRIX",
  //     },
  //     fetchPolicy: "no-cache",
  //   }
  // );
  const [fetchContact, { data: primaryContactResponse }] = useLazyQuery(Q_CONTACT, {
    fetchPolicy: "no-cache",
  });

  const { data: locationsDataResponse } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });

  const [updateLenderProfile] = useMutation(UPDATE_LENDER_PROFILE_GQL, {
    context: { authRequired: true },
    onCompleted() {
      setSaveLenderProfileSuccess(true);
      setIsSavingInProgress(false);
      setEditName(false);
    },
    onError() {
      setSaveLenderProfileError(true);
      setIsSavingInProgress(false);
      setLenderName(lender?.lenderName);
      setEditName(false);
    },
  });

  const [addLenderProfileUser] = useMutation(ADD_LENDER_PROFILE_USER);
  const [updateLenderProfileUser] = useMutation(UPDATE_LENDER_PROFILE_USER);
  const [removeLenderProfileUser] = useMutation(REMOVE_LENDER_PROFILE_USER);

  const handleLenderMatchingCriteriaChange = lenderMatchingCriteria => {
    console.log(lenderMatchingCriteria);
    setLenderMatchingCriteriaInputs(lenderMatchingCriteria);
  };

  useEffect(() => {
    if (!locationsDataResponse || _.isEmpty(locationsDataResponse.locations)) {
      return;
    }
    setLocations(locationsDataResponse.locations);
  }, [locationsDataResponse]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!!urlParams.get("openCredentials") && lenderProfileDynamicsId) {
      setOpenUpdateLenderCredentialsModal(true);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (!!urlParams.get("openDealerCredentials") && lenderProfileDynamicsId) {
      setDealerCredentialsModalIsOpen(true);
    }
  }, [integrationSettingsData]);

  useEffect(() => {
    if (lenderProfiles && lenderProfileDynamicsId) {
      const lender = lenderProfiles.find(lender => lender.lenderProfileId === lenderProfileDynamicsId);
      setLender(lender);
      setLenderName(lender?.lenderName);
      setOcaLink(`${REACT_APP_OCA_BASE_URL}/credit-app?lenderProfileId=${lender.id}`);

      const lenderVPId = _.get(lender, "lenderAccount.vendorProfileId");
      if (isDealerUser && lenderVPId) {
        getLenderActiveOCATemplate({ variables: { vendorProfileId: lenderVPId } });
      }
    }
  }, [lenderProfiles]);

  useEffect(() => {
    if (lender && lender?.lenderUserProfile?.dynamicsContactId) {
      fetchContact({ variables: { dynamicsId: lender.lenderUserProfile?.dynamicsContactId } });
      setInsuranceRequirements(lender.insuranceRequirements);
    }
  }, [lender]);

  useEffect(() => {
    if (primaryContactResponse?.contact) {
      setPrimaryContact({
        ...primaryContactResponse.contact,
        status: statusEnum[primaryContactResponse.contact.status],
      });
    }
  }, [primaryContactResponse]);

  useEffect(() => {
    if (lpContactsData?.getLenderProfileByDynamicsId?.lenderProfileUsers?.length > 0) {
      setContacts([
        ...lpContactsData.getLenderProfileByDynamicsId.lenderProfileUsers.map(user => ({
          ...user,
          ...user.userProfile,
        })),
      ]);
    }
  }, [lpContactsData]);

  useEffect(() => {
    if (lpSubmissionsData?.creditSubmissionsByDynamicsLPId) {
      const submissions = lpSubmissionsData.creditSubmissionsByDynamicsLPId.filter(el => el != null);
      setSubmissions(submissions.sort((a, b) => new Date(b.submissionDate) - new Date(a.submissionDate)));

      const approved = submissions.filter(el => el.decision === "Approved").length;
      const declined = submissions.filter(el => el.decision === "Declined").length;
      const awaitingApproval = submissions.filter(el => el.decision === "Awaiting Review").length;

      setSubmissionTotals({ approved, declined, awaitingApproval });
    }
  }, [lpSubmissionsData]);

  useEffect(() => {
    if (!accountActiveOCATemplateData) {
      return;
    }
    const activeOcaTemplate = _.first(accountActiveOCATemplateData.getOcaTemplatesByVendorProfile);
    const isInsuranceModuleSetInOca = _(activeOcaTemplate)
      .chain()
      .get("jsonDefinition")
      .flatMap("sections")
      .some({ referenceType: referenceTypes.insurance })
      .value();
    setIsInsuranceModuleSetInOca(isInsuranceModuleSetInOca);
  }, [accountActiveOCATemplateData]);

  useEffect(() => {
    if (!lenderActiveOCATemplateData) {
      return;
    }
    const activeOcaTemplate = _.first(lenderActiveOCATemplateData.getOcaTemplatesByVendorProfile);
    setLenderActiveOcaTemplate(activeOcaTemplate);
  }, [lenderActiveOCATemplateData]);

  const handleSaveLenderMatchCriteria = () => {
    try {
      setIsSettingLenderMatchingRulesInProgress(true);
      // console.log(`SUCH UPDATE: ${JSON.stringify(LenderMatchingCriter)}`);
      updateLenderProfile({
        variables: {
          fieldsObj: {
            lenderMatrixMatchingRules: lenderMatchingCriteriaInputs,
          },
          entityId: lender.lenderProfileId,
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSettingLenderMatchingRulesInProgress(false);
    }
  };

  const handleSaveLenderProfile = () => {
    try {
      setIsSavingInProgress(true);
      updateLenderProfile({
        variables: {
          fieldsObj: {
            insuranceRequirements,
          },
          entityId: lender.lenderProfileId,
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const handleUpdateLenderName = () => {
    try {
      setIsSavingInProgress(true);
      updateLenderProfile({
        variables: {
          fieldsObj: {
            name: lenderName,
          },
          entityId: lender.lenderProfileId,
        },
      });
    } catch (err) {
      console.error(err);
      setLenderName(lender?.lenderName);
    }
  };

  return (
    <>
      <Grid container>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0 0 16px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <Button className="formBtn" color="primary" onClick={() => (window.location.href = "/lenderadmin")}>
                <ArrowBackIosOutlined style={{ fontSize: 16, marginRight: 4 }} />
                Back
              </Button>
              <h3 style={{ margin: "16px 0", display: "flex", alignItems: "center" }}>
                {editName ? (
                  <TextField value={lenderName} onChange={e => setLenderName(e.target.value)} />
                ) : (
                  <b>{lenderName}</b>
                )}
                {isSavingInProgress ? (
                  <CircularProgress style={{ padding: 4, marginLeft: 8, height: 30, width: 30 }} />
                ) : (
                  <>
                    {editName && (
                      <IconButton
                        style={{ padding: 4, marginLeft: 8 }}
                        onClick={() => handleUpdateLenderName()}
                        color="primary"
                        aria-label="change contact"
                        component="button"
                      >
                        <CheckOutlined style={{ fontSize: 24 }} />
                      </IconButton>
                    )}
                    <IconButton
                      style={{ padding: 4, marginLeft: 8 }}
                      onClick={() => setEditName(!editName)}
                      color="primary"
                      aria-label="change contact"
                      component="button"
                    >
                      {editName ? (
                        <CloseOutlined style={{ fontSize: 24 }} />
                      ) : (
                        <EditOutlined style={{ fontSize: 24 }} />
                      )}
                    </IconButton>
                  </>
                )}
              </h3>
            </div>
            {!loadingIntegrationSettings && integrationSettingsData?.integrationSettings && (
              <>
                <Button
                  className={classes.addContactButton}
                  color="primary"
                  onClick={() => {
                    setDealerCredentialsModalIsOpen(true);
                  }}
                >
                  <LockOpenOutlined style={{ fontSize: 16, marginRight: 4 }} />
                  Update Credentials
                </Button>
                <DealerCredentialsModal
                  integrationName={lenderTypeMap.get(lender.lenderChannelCode)}
                  isOpen={dealerCredentialsModalIsOpen}
                  handleClose={() => setDealerCredentialsModalIsOpen(false)}
                  dealerLocations={locations ? locations : []}
                  dealerContacts={[userProfile]}
                  dealerAccountId={account.id}
                ></DealerCredentialsModal>
              </>
            )}
            {!loadingIntegrationSettings &&
              lender?.submissionMethod !== "Submission By Email" &&
              !integrationSettingsData?.integrationSettings && (
                <Button
                  className={classes.addContactButton}
                  color="primary"
                  onClick={() => {
                    setOpenUpdateLenderCredentialsModal(true);
                  }}
                >
                  <LockOpenOutlined style={{ fontSize: 16, marginRight: 4 }} />
                  Update Credentials
                </Button>
              )}
          </div>
        </GridItem>

        {isDealerUser && (
          <Tile>
            {!lender ||
              (isLenderActiveOCATemplateDataLoading && (
                <CircularProgress style={{ marginLeft: "auto", height: 44, width: 44 }} />
              ))}
            {lender && !isLenderActiveOCATemplateDataLoading && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {lender?.lenderLogo && (
                    <img
                      alt="Lender Logo"
                      style={{
                        maxHeight: "200px",
                        maxWidth: "100%",
                      }}
                      src={`https://${S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${lender.lenderLogo}`}
                    />
                  )}
                </Grid>
                <PersonalLinkAndQRCode
                  personalLink={ocaLink}
                  linkHeader="Lender Dealer Credit Application Link"
                  isOCAUnready={!lenderActiveOcaTemplate}
                  isOCAUnreadyMessage="Not yet configured. Contact your lender to enable"
                />
              </Grid>
            )}
          </Tile>
        )}

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", margin: "0 0 16px" }}>
            <h3 style={{ margin: "16px 0" }}>
              <b>Analytics</b>
            </h3>
          </div>
          <Tile>
            {lpSubmissionsLoading ? (
              <CircularProgress style={{ marginLeft: "auto", height: 44, width: 44 }} />
            ) : (
              <>
                {submissionTotals?.awaitingApproval + submissionTotals?.approved + submissionTotals?.declined === 0 ? (
                  <h4>
                    <b>No Submissions</b>
                  </h4>
                ) : (
                  <Grid container>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h3>
                          <b>{submissionTotals.awaitingApproval}</b>
                        </h3>
                        <p>
                          <b>Awaiting Review</b>
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h3>
                          <b>{submissionTotals.approved}</b>
                        </h3>
                        <p>
                          <b>Approved</b>
                        </p>
                      </div>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h3>
                          <b>{submissionTotals.declined}</b>
                        </h3>
                        <p>
                          <b>Declined</b>
                        </p>
                      </div>
                    </GridItem>
                  </Grid>
                )}
              </>
            )}
          </Tile>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", margin: "16px 0" }}>
            <h3 style={{ margin: "16px 0" }}>
              <b>Contacts</b>
            </h3>
            <Button
              className={classes.addContactButton}
              color="primary"
              onClick={() => {
                setCreating(true);
                setOpenModal(true);
              }}
            >
              <AddOutlined style={{ fontSize: 16, marginRight: 4 }} />
              Add Contact
            </Button>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Tile>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {lpContactsLoading && <CircularProgress style={{ marginLeft: "auto", height: 24, width: 24 }} />}
              <LenderContactsTable
                lenderContacts={contacts?.sort((a, b) => a?.firstName?.localeCompare(b?.firstName)) || []}
                onRowClick={contact => {
                  setContact(contact);
                  setOpenModal(true);
                }}
                onEditClick={contact => {
                  setContact(contact);
                  setEditing(true);
                  if (contact.isPrimaryContact) setOpenChangeLenderContactModal(true);
                  else setOpenModal(true);
                  setEditing(true);
                }}
                locations={locations}
                territoryManagement={vp.territoryManagement}
              />
            </div>
          </Tile>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: "flex", margin: "16px 0" }}>
            <h3 style={{ margin: "16px 0" }}>
              <b>Submissions</b>
            </h3>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Tile>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {lpSubmissionsLoading && <CircularProgress style={{ marginLeft: "auto", height: 24, width: 24 }} />}
              <CreditSubmissionsTable creditSubmissions={submissions || []} />
            </div>
          </Tile>
        </GridItem>
        {isInsuranceModuleSetInOca && (
          <>
            <GridItem xs={12}>
              <div style={{ display: "flex", margin: "16px 0" }}>
                <h3 style={{ margin: "16px 0" }}>
                  <b>Insurance Requirements</b>
                </h3>
              </div>
            </GridItem>
            <GridItem xs={12}>
              <Tile>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <ReactQuill value={insuranceRequirements} onChange={value => setInsuranceRequirements(value)} />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveLenderProfile}
                      style={{ backgroundColor: theme.palette.primary.main }}
                    >
                      {isSavingInProgress ? "Saving" : "Save"}
                    </Button>
                  </Grid>
                </Grid>
              </Tile>
            </GridItem>
          </>
        )}
        {!lpContactsLoading && vp.lenderMatrixAccess && (
          <>
            <GridItem xs={12}>
              <div style={{ display: "flex", margin: "16px 0" }}>
                <h3 style={{ margin: "16px 0" }}>
                  <b>Lender Match Criteria</b>
                </h3>
              </div>
            </GridItem>
            <GridItem xs={12}>
              <Tile>
                <LenderMatchingCriteria
                  onLenderMatchingCriteriaChange={handleLenderMatchingCriteriaChange}
                  lenderMatrixMatchingRules={lpContactsData.getLenderProfileByDynamicsId.lenderMatrixMatchingRules}
                ></LenderMatchingCriteria>
                <Grid style={{ paddingTop: "25px" }}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveLenderMatchCriteria}
                      style={{ backgroundColor: theme.palette.primary.main }}
                    >
                      {isSettingLenderMatchingRulesInProgress ? "Setting..." : "Set Match Criteria"}
                    </Button>
                  </Grid>
                </Grid>
              </Tile>
            </GridItem>
          </>
        )}

        {lender && (
          <>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <div style={{ display: "flex", margin: "16px 0" }}>
                <h3 style={{ margin: "16px 0" }}>
                  <b>Finance Programs</b>
                </h3>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Tile>
                <FinanceProgramMgmt targetLenderProfileId={lender.id} />
              </Tile>
            </GridItem>
          </>
        )}
      </Grid>
      <ContactModal
        open={openModal}
        handleClose={() => {
          setOpenModal(!openModal);
          setCreating(false);
          setEditing(false);
          setContact(null);
        }}
        contact={contact}
        create={creating}
        edit={editing}
        isPrimaryContact={primaryContact && contact?.id === primaryContact?.id}
        addLenderProfileUser={addLenderProfileUser}
        updateLenderProfileUser={updateLenderProfileUser}
        removeLenderProfileUser={removeLenderProfileUser}
        lenderProfileId={lender?.id}
        locations={locations}
        refetchContacts={() => refetch()}
        territoryManagement={vp.territoryManagement}
        lenderContacts={contacts?.sort((a, b) => a?.firstName?.localeCompare(b?.firstName)) || []}
      />
      <ChangeLenderContact
        open={openChangeLenderContactModal}
        handleClose={() => {
          setContact(null);
          setOpenChangeLenderContactModal(false);
        }}
        lenderProfile={lender}
        contact={contact}
      />
      <UpdateLenderCredentials
        open={openUpdateLenderCredentialsModal}
        handleClose={() => setOpenUpdateLenderCredentialsModal(false)}
        lenderProfile={lender}
        account={account}
        userProfile={userProfile}
      />
      <Snackbar
        open={saveLenderProfileSuccess}
        autoHideDuration={3000}
        onClose={() => setSaveLenderProfileSuccess(state => !state)}
      >
        <Alert severity="success">
          <AlertTitle>Changes Saved!</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar
        open={saveLenderProfileError}
        autoHideDuration={3000}
        onClose={() => setSaveLenderProfileError(state => !state)}
      >
        <Alert severity="error">
          <AlertTitle>Error on saving</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
};

export default LenderDetails;
