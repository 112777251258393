import { SETUP_FAST_TRACK_MODAL } from "./types";

const initialState = { opened: false, currentVo: {} };

export const setupFastTrackModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_FAST_TRACK_MODAL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
