import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import formatString from "format-string-by-pattern";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "../../components/CustomButtons/Button";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { Box, Switch, TextField } from "@material-ui/core";
import CardFooter from "../../components/Card/CardFooter";
import { setRefetch } from "../../redux/actions";
import InputLabel from "@material-ui/core/InputLabel";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";

import _ from "lodash";

const useStyles = makeStyles(styles);
const useStylesPaper = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "500px",
    maxWidth: "600px",
  },
  paper: {
    minWidth: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    overFlowX: "hidden",
  },
  margin: {
    marginBottom: "8px",
  },
  marginDivider: {
    marginTop: "33px",
    marginBottom: "8px",
  },
  inputRole: {
    color: "#aaa !important",
  },
  invoiceTotalMargin: {
    "& .MuiDivider-root": {
      border: "solid 10px black",
      marginBottom: "8px",
      marginTop: "16px",
      fontWeight: 900,
    },
  },
  mainContainer: {
    "& .MuiFormControl-root": {
      marginBottom: 16,
    },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 13px) scale(0.9)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  selectStyles: {
    height: 40,
    marginBottom: 8,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

const SIGNUP_USER_MUTATION = gql`
  mutation(
    $email: String!
    $firstName: String
    $lastName: String
    $title: String
    $phone: String
    $dynamicsAccountId: String
    $accountId: String
    $vendorContactRole: Int
    $locationId: ID
    $sendInvitation: Boolean
  ) {
    adminCreateUser(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        title: $title
        phone: $phone
        dynamicsAccountId: $dynamicsAccountId
        accountId: $accountId
        vendorContactRole: $vendorContactRole
        locationId: $locationId
        sendInvitation: $sendInvitation
      }
    ) {
      status
      message
    }
  }
`;

function AddNewRepModal({
  open,
  handleClose,
  account,
  refetchUsers,
  dynamicsAccountId,
  accountId,
  setOpenErrorSnackbar,
  setOpenLoadingSnackbar,
  setOpenSuccessSnackbar,
  locations,
  vendorProfile,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const classesPaper = useStylesPaper();
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  const [sendInvite, setSendInvite] = React.useState(true);
  const [locationsTree, setLocationsTree] = useState();
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === "phone") {
      let phoneValue = value.replace(/[^\d-() ]/, "");
      setValues({
        ...values,
        [name]: formatString("(999) 999-9999", phoneValue),
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  const [values, setValues] = useState({
    firstName: null,
    lastName: null,
    title: null,
    email: null,
    phone: null,
    companyName: null,
    submitSuccess: null,
    submitError: null,
    vendorContactRole: null,
    locationId: null,
  });
  //console.log(values);
  const [requestSignupUser, { data, error, loading }] = useMutation(SIGNUP_USER_MUTATION, {
    context: { authRequired: false },
  });

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  useEffect(() => {
    if (_.isEmpty(locations)) {
      return;
    }

    const options = _.map(locations, location => {
      return {
        ...location,
        //checked: false,
        isDefaultValue: location.locationId === values.locationId,
      };
    });
    setLocationsTree(buildTree(options));
  }, [locations, values.locationId]);

  const handleSelectChange = e => {
    setValues({ ...values, vendorContactRole: e.target.value });
  };

  const errorMessage = "Please select a role for this user";
  const [errorSubmit, setErrorSubmit] = useState(false);

  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: selectedNode => setValues({ ...values, locationId: selectedNode.value }),
    texts: { placeholder: "Default Location" },
  });

  const addUserClose = () => {
    setSendInvite(true);
    setValues({
      firstName: null,
      lastName: null,
      title: null,
      email: null,
      phone: null,
      companyName: null,
      submitSuccess: null,
      submitError: null,
      vendorContactRole: null,
      locationId: null,
    });
    handleClose();
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (isDealerUser && !values.vendorContactRole) {
      setErrorSubmit(true);
    } else {
      setOpenLoadingSnackbar(true);

      requestSignupUser({
        variables: {
          email: values["email"],
          firstName: values["firstName"],
          lastName: values["lastName"],
          title: values["title"],
          phone: values["phone"],
          dynamicsAccountId: dynamicsAccountId,
          accountId: accountId,
          vendorContactRole: values["vendorContactRole"],
          mobilePhone: null,
          locationId: values.locationId,
          sendInvitation: sendInvite,
        },
      })
        .then(() => {
          refetchUsers();
          setOpenSuccessSnackbar(true);
          setSendInvite(true);
          handleClose();
        })
        .catch(() => {
          setOpenErrorSnackbar(true);
        })
        .finally(() => {
          setValues({
            firstName: null,
            lastName: null,
            title: null,
            email: null,
            phone: null,
            companyName: null,
            submitSuccess: null,
            submitError: null,
            vendorContactRole: null,
            locationId: null,
          });
        });
    }
  };

  return (
    <div style={{ width: "500px" }}>
      <Dialog onClose={() => addUserClose()} open={open} scroll={"body"} fullScreen={!matches}>
        <Paper className={classesPaper.paper}>
          <CardContent>
            <h3 style={{ margin: "0 0 8px", fontWeight: 400, fontSize: 20 }}>Add New User</h3>
            <div className={classesPaper.mainContainer}>
              <form onSubmit={handleSubmit}>
                <GridContainer>
                  <GridItem item lg={6} xs={12}>
                    <Box component="form" noValidate autoComplete="off">
                      <TextField
                        className={classesPaper.textField}
                        id="firstName"
                        formControlProps={{ fullWidth: true, required: true }}
                        label="* First Name"
                        inputProps={{
                          name: "firstName",
                          onChange: handleInputChange,
                          autoComplete: "fname",
                          required: true,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </GridItem>
                  <GridItem lg={6} xs={12}>
                    <Box component="form" noValidate autoComplete="off">
                      <TextField
                        className={classesPaper.textField}
                        id="lastName"
                        formControlProps={{ fullWidth: true, required: true }}
                        label="* Last Name"
                        inputProps={{
                          name: "lastName",
                          onChange: handleInputChange,
                          autoComplete: "lname",
                          required: true,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem lg={12} xs={12}>
                    <Box component="form" noValidate autoComplete="off">
                      <TextField
                        className={classesPaper.textField}
                        label="* Title"
                        id="title"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "title",
                          onChange: handleInputChange,
                          autoComplete: "title",
                          required: true,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridContainer className={classesPaper.emailMargin}>
                  <GridItem lg={12} xs={12}>
                    <Box component="form" noValidate autoComplete="off">
                      <TextField
                        className={classesPaper.textField}
                        label="* Email Address"
                        id="email"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "email",
                          onChange: handleInputChange,
                          autoComplete: "email",
                          required: true,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
                {isDealerUser && (
                  <GridContainer>
                    <GridItem lg={12} xs={12}>
                      <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
                        <InputLabel id="vendor-contact-role-label" className={classesPaper.selectLabel}>
                          * User Role
                        </InputLabel>
                        <Select
                          labelId="vendor-contact-role-label"
                          id="vendorContactRole"
                          label="Trnsact User Role"
                          onChange={e => handleSelectChange(e)}
                          className={classesPaper.selectStyles}
                        >
                          <MenuItem value={100000000}>Sales Rep</MenuItem>
                          <MenuItem value={100000001}>Sales Manager</MenuItem>
                          <MenuItem value={100000002}>System Admin</MenuItem>
                          <MenuItem value={100000003}>Executive</MenuItem>
                          <MenuItem value={100000004}>Credit Manager</MenuItem>
                          <MenuItem value={100000005}>Support Admin</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem lg={12} xs={12}>
                    <Box component="form" noValidate autoComplete="off">
                      <TextField
                        className={classesPaper.textField}
                        label="* Phone Number"
                        id="phone"
                        formControlProps={{ fullWidth: true, required: true }}
                        inputProps={{
                          name: "phone",
                          value: values.phone,
                          onChange: handleInputChange,
                          autoComplete: "phone",
                          required: true,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </GridItem>
                </GridContainer>
                {vendorProfile.multiLocationManagement && (
                  <GridContainer>
                    <GridItem
                      xs={12}
                      className={classesPaper.locationsTreeViewDropdownContainer}
                      style={{ marginTop: 16 }}
                    >
                      <FormControl fullWidth>
                        <LocationsTreeViewDropdown tree={locationsTree} treeSelectOptions={treeSelectOptions} />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer justifyContent="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "16px 0",
                    }}
                  >
                    <label style={{ marginRight: 4, color: sendInvite ? "rgba(0,0,0,0.85)" : "", fontWeight: 400 }}>
                      {sendInvite ? "Send Invitation" : "Stage Invitation"}
                    </label>
                    <Switch
                      value={sendInvite}
                      defaultChecked={true}
                      onChange={() => setSendInvite(!sendInvite)}
                      color="primary"
                    />
                  </div>
                </GridContainer>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type="submit" color="primary" size="md" style={{ width: "fitContent", fontWeight: "bold" }}>
                    Register User
                  </Button>
                </CardFooter>
              </form>
            </div>
          </CardContent>
        </Paper>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
  vendorProfile: state.vp,
});

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewRepModal);
