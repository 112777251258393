import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import HelpIcon from "@material-ui/icons/Help";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => setOpen(!open);
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => (window.location.href.indexOf(routeName) > -1 ? true : false);
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({ [" " + classes[color]]: color });
  const list = (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <NavLink to={"/login"} className={cx(classes.navLink, { [classes.navLinkActive]: activeRoute("/login") })}>
          <FingerprintIcon className={classes.listItemIcon} />
          <ListItemText primary={"Sign In"} disableTypography={true} className={classes.listItemText} />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/signup"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/signup"),
          })}
        >
          <PersonAddIcon className={classes.listItemIcon} />
          <ListItemText primary={"Sign Up"} disableTypography={true} className={classes.listItemText} />
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <NavLink
          to={"/forgotpassword"}
          className={cx(classes.navLink, {
            [classes.navLinkActive]: activeRoute("/forgotpassword"),
          })}
        >
          <HelpIcon className={classes.listItemIcon} />
          <ListItemText primary={"Forgot password"} disableTypography={true} className={classes.listItemText} />
        </NavLink>
      </ListItem>
    </List>
  );
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={open}
            classes={{ paper: classes.drawerPaper }}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
          >
            {list}
          </Drawer>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
