import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import { convertToDate, formatMoney, setFieldValue, validateValue } from "../../utils";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Fab from "@material-ui/core/Fab";
import InputAdornment from "@material-ui/core/InputAdornment";

const config = require("../../config.js");

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Q_CREDIT_PRODUCT = gql`
  query accountCreditProduct($id: ID!) {
    accountCreditProduct(id: $id) {
      id
      productId
      productName
      productHeader
      term
      rate
      payment
      rateFactor
      riskTier
      amountMin
      amountMax
      docFee
      advancePayment
      markupDealer
      markupInternal
      lender
      accountId
      residual
      lenderName
      manufacturers
      makes
      modelYears
      validFrom
      validTo
      additionalTerms
      pdfFlyer
      source
      createdDateTime
      contractType
      createdBy
    }
  }
`;

const DEACTIVATE_CREDIT_PRODUCT = gql`
  mutation($id: ID!) {
    deactivateCreditProduct(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  formControlInputs: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControlRate: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 200,
  },
}));

function CreditProductDetails({ userProfile, match, setRefetch, account, vo }) {
  const creditProductId = match.params.creditProdId;
  const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const classes = useStyles();

  const { data: creditProduct } = useQuery(Q_CREDIT_PRODUCT, {
    variables: {
      id: creditProductId,
    },
  });

  const [deactivateCreditProduct] = useMutation(DEACTIVATE_CREDIT_PRODUCT, {
    context: { authRequired: true },
  });

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);
  const [renderOptions, setRenderOptions] = useState(false);
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    productName: {
      value: "",
    },
    productHeader: {
      value: "",
    },
    term: {
      value: "",
    },
    rate: {
      value: "",
    },
    riskTier: {
      value: "",
    },
    amountMin: {
      value: "",
      format: formatMoney,
    },
    amountMax: {
      value: "",
      format: formatMoney,
    },
    docFee: {
      value: "",
    },
    advancePayment: {
      value: "",
    },
    createdDateTime: {
      value: "",
    },
    markupDealer: {
      value: "",
    },
    lenderName: {
      value: "",
    },
    residual: {
      value: "",
    },
    validFrom: {
      value: "",
    },
    validTo: {
      value: "",
    },
    additionalTerms: {
      value: "",
    },
    contractType: {
      value: "",
    },
    createdBy: {
      value: "",
    },
  });
  const [formValid, setFormValid] = useState(false);
  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (creditProduct) {
      setFields({
        id: {
          value: creditProduct.accountCreditProduct.id ? creditProduct.accountCreditProduct.id : "",
          validationStatus: "",
        },
        productName: {
          value: creditProduct.accountCreditProduct.productName ? creditProduct.accountCreditProduct.productName : "",
          validationStatus: "",
        },
        productHeader: {
          value: creditProduct.accountCreditProduct.productHeader
            ? creditProduct.accountCreditProduct.productHeader
            : "",
          validationStatus: "",
        },
        term: {
          value:
            creditProduct.accountCreditProduct.term && creditProduct.accountCreditProduct.term
              ? creditProduct.accountCreditProduct.term
              : "",
          validationStatus: "",
        },
        rate: {
          value: creditProduct.accountCreditProduct.rate ? creditProduct.accountCreditProduct.rate : "",
          validationStatus: "",
        },
        riskTier: {
          value: creditProduct.accountCreditProduct.riskTier ? creditProduct.accountCreditProduct.riskTier : "",
          validationStatus: "",
        },
        amountMin: {
          value: creditProduct.accountCreditProduct.amountMin
            ? formatMoney(creditProduct.accountCreditProduct.amountMin)
            : "",
          format: formatMoney,
        },
        amountMax: {
          value: creditProduct.accountCreditProduct.amountMax
            ? formatMoney(creditProduct.accountCreditProduct.amountMax)
            : "",
          format: formatMoney,
        },
        docFee: {
          value: creditProduct.accountCreditProduct.docFee
            ? formatMoney(creditProduct.accountCreditProduct.docFee)
            : "",
          format: formatMoney,
        },
        advancePayment: {
          value: creditProduct.accountCreditProduct.advancePayment
            ? creditProduct.accountCreditProduct.advancePayment
            : "",
        },
        createdDateTime: {
          value: creditProduct.accountCreditProduct.createdDateTime
            ? convertToDate(creditProduct.accountCreditProduct.createdDateTime)
            : "",
        },
        markupDealer: {
          value: creditProduct.accountCreditProduct.markupDealer ? creditProduct.accountCreditProduct.markupDealer : "",
        },
        lenderName: {
          value: creditProduct.accountCreditProduct.lenderName ? creditProduct.accountCreditProduct.lenderName : "",
        },
        residual: {
          value: creditProduct.accountCreditProduct.residual ? creditProduct.accountCreditProduct.residual : "",
        },
        validFrom: {
          value: creditProduct.accountCreditProduct.validFrom
            ? convertToDate(creditProduct.accountCreditProduct.validFrom)
            : "",
        },
        validTo: {
          value: creditProduct.accountCreditProduct.validTo
            ? convertToDate(creditProduct.accountCreditProduct.validTo)
            : "",
        },
        additionalTerms: {
          value: creditProduct.accountCreditProduct.additionalTerms
            ? creditProduct.accountCreditProduct.additionalTerms
            : "",
        },
        contractType: {
          value: creditProduct.accountCreditProduct.contractType
            ? creditProduct.accountCreditProduct.contractType.charAt(0).toUpperCase() +
              creditProduct.accountCreditProduct.contractType.slice(1)
            : "",
        },
        createdBy: {
          value: creditProduct.accountCreditProduct.createdBy ? creditProduct.accountCreditProduct.createdBy : "",
        },
      });
    }
  }, [creditProduct]);

  const handleSubmit = async event => {
    try {
      setSnackBarMessage("Successfully sent your proposal!");
      setSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error sending proposal!");
      setErrorOpen(true);
    }
  };

  const handleDeleteCreditProduct = async id => {
    try {
      await deactivateCreditProduct({
        variables: {
          id: id,
        },
      });
      setSnackBarMessage("Successfully deleted your credit product!");
      setSnackbarOpen(true);
    } catch (err) {
      console.log(err);
      setErrorMessage("Error deleting credit product!");
      setErrorOpen(true);
    }
  };

  return (
    <>
      {creditProduct ? (
        <Form
          onSubmit={values => {
            handleSubmit();
          }}
          validate={checkFormValidation}
          initialValues={!!creditProduct.accountCreditProduct}
          render={({ handleSubmit, pristine, valid, submitting, values }) => {
            function uploadButtonHandler(event) {
              const file = document.getElementById("file");
              file.click();
            }
            function uploadFileChanged(event) {
              fields.file.value = event.target.files[0];
              const reader = new FileReader();
              reader.onload = e => {
                const avatar = document.getElementById("avatar");
                setValue(e.target.result, "mugshot");
                avatar.src = e.target.result;
              };
              reader.readAsDataURL(event.target.files[0]);
              checkFormValidation();
            }
            function handleClose() {
              setSnackbarOpen(false);
              setErrorOpen(false);
            }

            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit().then(res => console.log(res));
                }}
              >
                <Grid container>
                  <GridItem lg={12}>
                    <Tile>
                      <Grid container alignItems="center">
                        <GridItem xs={12} sm={6} md={6} lg={3}>
                          <Fab onClick={() => (window.location.href = "/creditPrograms")} aria-label="add">
                            <KeyboardBackspaceIcon
                              fontSize="large"
                              style={{ cursor: "pointer" }}
                            ></KeyboardBackspaceIcon>
                          </Fab>
                        </GridItem>
                      </Grid>
                      <br />
                      <Grid container>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Created On"
                            id="createdDateTime"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled
                            inputProps={{
                              type: "text",
                              name: "createdDateTime",
                              value: fields.createdDateTime.value,
                              disabled: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="createdBy"
                            id="createdBy"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled
                            inputProps={{
                              type: "text",
                              name: "createdBy",
                              value: fields.createdBy.value,
                              disabled: true,
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Internal Product Name"
                            id="productName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "productName",
                              value: fields.productName.value,
                              disabled: true,
                              onChange: e => setValue(e.target.value, "productName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Customer Product Name"
                            id="productHeader"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "productHeader",
                              value: fields.productHeader.value,
                              disabled: true,
                              onChange: e => setValue(e.target.value, "productHeader"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Contract Type"
                            id="contractType"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "contractType",
                              value: fields.contractType.value,
                              disabled: true,
                              onChange: e => setValue(e.target.value, "contractType"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Term"
                            id="term"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "term",
                              value: fields.term.value,
                              disabled: true,
                              onChange: e => setValue(e.target.value, "term"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Rate"
                            id="rate"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "rate",
                              value: fields.rate.value,
                              disabled: true,
                              onChange: e => setValue(e.target.value, "rate"),
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <CustomInput
                            labelText="Risk Tier"
                            id="riskTier"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "riskTier",
                              value: fields.riskTier.value,
                              disabled: true,
                              onChange: e => setValue(e.target.value, "riskTier"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Amount Min"
                            id="amountMin"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "amountMin",
                              value: fields.amountMin.value,
                              disabled: true,
                              onChange: e => setValue(e.target.value, "amountMin"),
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Amount Max"
                            id="amountMax"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "amountMax",
                              disabled: true,
                              value: fields.amountMax.value,
                              onChange: e => setValue(e.target.value, "amountMax"),
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Doc Fee"
                            id="docFee"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "docFee",
                              disabled: true,
                              value: fields.docFee.value,
                              onChange: e => setValue(e.target.value, "docFee"),
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Advance Payment"
                            id="advancePayment"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "advancePayment",
                              disabled: true,
                              value: fields.advancePayment.value,
                              onChange: e => setValue(e.target.value, "advancePayment"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Markup"
                            id="markupDealer"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "markupDealer",
                              disabled: true,
                              value: fields.markupDealer.value,
                              onChange: e => setValue(e.target.value, "markupDealer"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Lender Name"
                            id="lenderName"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "lenderName",
                              disabled: true,
                              value: fields.lenderName.value,
                              onChange: e => setValue(e.target.value, "lenderName"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Valid From"
                            id="validFrom"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "validFrom",
                              disabled: true,
                              value: fields.validFrom.value,
                              onChange: e => setValue(e.target.value, "validFrom"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Valid To"
                            id="validTo"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              disabled: true,
                              name: "validTo",
                              value: fields.validTo.value,
                              onChange: e => setValue(e.target.value, "validTo"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={3}>
                          <CustomInput
                            labelText="Additional Terms"
                            id="additionalTerms"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              name: "additionalTerms",
                              disabled: true,
                              value: fields.additionalTerms.value,
                              onChange: e => setValue(e.target.value, "additionalTerms"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <br />
                      <Grid container justify="center" alignItems="center">
                        {/* <GridItem xs={8} sm={6} md={6} lg={6}>
                          <Button
                            className="formBtn"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={submitting || !formValid}
                          >
                            {submitting ? "Sending" : "Send"}
                          </Button>
                        </GridItem> */}
                        {userProfile.vendorContactRole == "credit_mgr" && (
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <Button
                              className="formBtn"
                              color="primary"
                              variant="outlined"
                              onClick={() => handleDeleteCreditProduct(creditProduct.accountCreditProduct.id)}
                            >
                              Delete Proposal
                            </Button>
                          </GridItem>
                        )}
                      </Grid>
                    </Tile>
                  </GridItem>
                </Grid>
                <br />
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        />
      ) : (
        "Loading Credit Product"
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    account: state.account,
    vo: state.vo,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditProductDetails);
