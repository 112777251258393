import React, { useEffect, useState } from "react";
import TaskList from "../components/TaskList";
import gql from "graphql-tag";
import { useDispatch, useSelector } from "react-redux";
import { CHAT_V2_TASKS } from "../redux/types";

const QUERY_ACCOUNT_TASKS = gql`
  query AccountUserTasks($accountId: ID, $limit: Int) {
    consolidatedTask(accountId: $accountId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      createdDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notifyByEmail
      notifyByText
      lastReminderSent
      nextReminder
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
    }
  }
`;

const QUERY_USER_TASKS = gql`
  query MyUserTasks($userProfileId: ID, $limit: Int) {
    userRelatedTasks(userProfileId: $userProfileId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      createdDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notifyByEmail
      notifyByText
      lastReminderSent
      nextReminder
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
    }
  }
`;

function ConsolidatedTasks(props) {
  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const relatedUsers = useSelector(state => state.relatedUsers);
  const vo = useSelector(state => state.vo);
  const [onlyUserTasks, setOnlyUserTasks] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!(userProfile.vendorContactRole == "credit_mgr" || userProfile.vendorContactRole == "sales_mgr")) {
      setOnlyUserTasks(true);
    } else {
      setOnlyUserTasks(false);
    }
  }, [userProfile]);

  const onTasksLoaded = loadedInfo => {
    dispatch({ type: CHAT_V2_TASKS, payload: loadedInfo.tasks });
  };

  return (
    <TaskList
      vo={vo}
      account={account}
      queryTasksOptions={{
        variables: { limit: 100 },
        context: { authRequired: true },
      }}
      queryTasks={onlyUserTasks ? QUERY_USER_TASKS : QUERY_ACCOUNT_TASKS}
      userProfile={userProfile}
      creditSubId={null}
      tasksQueryKey={onlyUserTasks ? "userRelatedTasks" : "consolidatedTask"}
      relatedUsers={relatedUsers}
      taskOnClickAction={onlyUserTasks ? "openVO" : ""}
      addTaskFlag={false}
      refetchDocs={null}
      onTasksLoaded={onTasksLoaded}
    ></TaskList>
  );
}

export default ConsolidatedTasks;
