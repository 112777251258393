import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

// @material-ui/icons
import FeedbackIcon from "@material-ui/icons/Feedback";

// core components
import Button from "components/CustomButtons/Button.js";
import { DUPLICATE_OPPORTUNITY } from "services/opportunities";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import NewVOModal from "components/NewVOModal";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const history = useHistory();
  const voID = useSelector(state => state?.vo?.vendorOpportunityId);
  const vp = useSelector(state => state.vp);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowSuccessSnackbar, setIsShowSuccessSnackbar] = useState(false);
  const [routeType, setRouteType] = useState("");

  const [openNotification, setOpenNotification] = useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const { rtlActive } = props;

  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const handleFeedback = () => {
    window._mfq = window._mfq || [];
    window._mfq.push(["activateFeedback", "TxMTca8ZUk6eWDqNH5QyaQ"]);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setIsLoading(false);
  };

  const [duplicateOpportunity] = useMutation(DUPLICATE_OPPORTUNITY, {
    context: { authRequired: true },
  });

  const handleCreateNewOpportunity = async () => {
    setIsLoading(true);
    let isCreated = false;
    try {
      const res = await duplicateOpportunity({
        variables: { vendorOpportunityId: voID },
      });
      const newVoId = res?.data?.duplicateOpportunity?.vendorOpportunityId;
      if (newVoId) {
        history.push(`/vo/${newVoId}`);
        isCreated = true;
      }
    } catch (error) {
      window.NREUM.noticeError(error);
    } finally {
      toggleModal();
      setIsShowSuccessSnackbar(isCreated);
    }
  };

  const handleCloseSuccessSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsShowSuccessSnackbar(false);
  };

  const modalBodyStyles = {
    textAlign: "center",
    fontWeight: "bold",
    padding: "5px 15px 25px",
  };

  useEffect(() => {
    if (window) {
      setRouteType(window.location.pathname);
      history.listen(location => {
        setRouteType(location.pathname);
      });
    }
  }, []);

  const showCreateOpportunityBtn = Boolean(
    vp.manualVendorOpportunityCreationAccess && routeType && routeType === "/vo"
  );

  return (
    <div className={wrapper}>
      {showCreateOpportunityBtn ? (
        <Button onClick={toggleModal} color="primary">
          Create new opportunity
        </Button>
      ) : null}
      <IconButton aria-label="feedback" onClick={handleFeedback}>
        <FeedbackIcon />
      </IconButton>
      {showCreateOpportunityBtn ? <NewVOModal open={isOpen} onClose={() => setIsOpen(!isOpen)} /> : null}
      <Snackbar
        open={isShowSuccessSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleCloseSuccessSnackbar} severity="success" variant="filled">
          Success! You’re now looking at the NEW opportunity.
        </Alert>
      </Snackbar>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
