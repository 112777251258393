import gql from "graphql-tag";

export const CREATE_PROPOSAL_V2 = gql`
  mutation CreateProposalV2($input: CreateProposalV2Input!) {
    createProposalV2(input: $input) {
      errorMessage
      id
      success
      vendorOpportunityId
    }
  }
`;
