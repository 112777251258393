import React from "react";
import Grid from "@material-ui/core/Grid";

import GridItem from "components/Grid/GridItem";
import errorImage from "assets/img/error-sad.png";

export class ErrorHandler extends React.Component {
  static state = { hasError: false };
  constructor(props) {
    super(props);
    this.state = { hasError: false, loaderDots: "" };
  }

  static getDerivedStateFromError(error) {
    setTimeout(() => {
      window.location.href = "/vo";
    }, 55000);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // We can log the error to a service here.

    console.error(errorInfo);
    window.NREUM.noticeError(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <Grid>
            <GridItem xs={12} container justify="center">
              <div style={{ paddingTop: "75px" }}>
                <img src={errorImage} height={75} width={75} />
              </div>
            </GridItem>
          </Grid>
          <Grid>
            <GridItem xs={12} container justify="center">
              <h3 style={{ fontWeight: "bold" }}>Something went wrong.</h3>
            </GridItem>
          </Grid>
          <Grid>
            <GridItem xs={12} container justify="center">
              <div style={{ textAlign: "left", paddingTop: "25px" }}>
                <div style={{ fontSize: "16px" }}>
                  <p>Oops, sorry about that! We’ve encountered an error while trying to load the page.</p>
                  <p>Our engineers have been notified</p>
                  <p>In the meantime, we’re redirecting you to the homepage.</p>
                  <p style={{ fontWeight: "bolder" }}>Thank you for your patience!</p>
                </div>

                <div style={{ paddingTop: "15px" }}>
                  If the issue persists, please send a brief description of how you encountered your issue to:{" "}
                  <a href="mailto:support@trnsact.com" style={{ textDecoration: "underline" }}>
                    support@trnsact.com
                  </a>
                </div>

                <div style={{ paddingTop: "15px", fontStyle: "italic" }}>Please include screenshots if you can!</div>
              </div>
            </GridItem>
          </Grid>
        </div>
      );
    }

    return this.props.children;
  }
}
