import { SET_LP } from "./types";

const initialState = [];

export const lpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LP:
      return action.payload;
    default:
      return state;
  }
};
