import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { RoleSettingSelect } from "./RoleSettingSelect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { rolesSettingsKeys } from "../../../services/pcwService";
import { documentsByKey } from "../../Prequal/constants";
import _ from "lodash";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export function DocumentsByTypeSettings({ settings, itemConfiguration, setItemConfiguration, itemType, extraData }) {
  const classes = useStyles();
  const getValue = () => {};

  const updateValue = (e, value) => {};

  const data = _.orderBy(
    _.map(itemConfiguration.documentsByTypeSettings, (item, key) => {
      return {
        key,
        rolesSettings: item.rolesSettings,
        text: _.get(documentsByKey[key], "text", ""),
      };
    }),
    "text"
  );

  const updateRolesSettingsHiddenValue = (value, key, docTypeKey) => {
    setItemConfiguration(prevItemConfiguration => {
      return {
        ...prevItemConfiguration,
        [settings.configurationKey]: {
          ...prevItemConfiguration[settings.configurationKey],
          [docTypeKey]: {
            ...prevItemConfiguration[settings.configurationKey][docTypeKey],
            rolesSettings: {
              ...prevItemConfiguration[settings.configurationKey][docTypeKey].rolesSettings,
              [key]: value,
            },
          },
        },
      };
    });
  };

  const handleHiddenForLendersChange = (checked, docTypeKey) => {
    setItemConfiguration(prevItemConfiguration => {
      return {
        ...prevItemConfiguration,
        [settings.configurationKey]: {
          ...prevItemConfiguration[settings.configurationKey],
          [docTypeKey]: {
            ...prevItemConfiguration[settings.configurationKey][docTypeKey],
            hiddenForLenders: checked,
          },
        },
      };
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Document Type</TableCell>
            <TableCell align="center">Hidden By User Roles</TableCell>
            <TableCell align="center">Hidden For Lender</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => {
            const documentTypeSettings = _.get(itemConfiguration, `${settings.configurationKey}.${row.key}`);
            return (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row">
                  {row.text}
                </TableCell>
                <TableCell>
                  <RoleSettingSelect
                    rolesSettingsSelected={documentTypeSettings.rolesSettings[rolesSettingsKeys.hidden]}
                    rolesSettingsSelectedKey={rolesSettingsKeys.hidden}
                    updateRolesSettingsValue={_.partialRight(updateRolesSettingsHiddenValue, row.key)}
                    isHideHelperText={true}
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <Checkbox
                    checked={documentTypeSettings.hiddenForLenders}
                    onChange={event => handleHiddenForLendersChange(event.target.checked, row.key)}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
