import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useLazyQuery } from "@apollo/react-hooks";

const UPDATE_DOCSET = gql`
  mutation($input: UpdateDocSetInput!, $id: ID!) {
    updateDocSet(input: $input, id: $id) {
      id
    }
  }
`;

const DOC_SET = gql`
  query($docSetId: ID) {
    docSet(docSetId: $docSetId) {
      docSetId
      name
      docusignTemplateIds
      approvalTerms
      description
      status
      active
      createdBy
      templateName
      templateDocusignId
    }
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 2),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  primaryBorderColor: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function DocSetDetailModal({ open, handleClose, selectedDocSet, docSetRefetch }) {
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [docusignTemplateIds, setDocusignTemplateId] = useState([]);
  const [docSetName, setDocSetName] = useState("");
  const [docSetDescription, setDocSetDescription] = useState("");
  const [updatedDocusignTemplates, setUpdatedDocusignTemplates] = useState([]);

  const [updateDocSet] = useMutation(UPDATE_DOCSET, {
    context: { authRequired: true },
  });
  const classes = useStyles();

  const handleChangeDocusignTemplateIdsOrder = event => {
    setDocusignTemplateId(event.target.value);
  };

  const handleChangeDocSetName = event => {
    setDocSetName(event.target.value || "");
  };
  const handleChangeDocSetDescription = event => {
    setDocSetDescription(event.target.value || "");
  };

  const [getDocSet, { data: docSet, refetch: updateDocSetRefetch }] = useLazyQuery(DOC_SET);

  useEffect(() => {
    if (!!selectedDocSet && !!selectedDocSet.key) {
      getDocSet({
        variables: {
          docSetId: selectedDocSet.key,
        },
      });
    }
    if (!!docSet && docSet.docSet.length) {
      console.log("docSet", docSet);
      setDocSetName(docSet.docSet[0].name);
      setDocSetDescription(docSet.docSet[0].description);
      setDocusignTemplateId(docSet.docSet);
    }
  }, [getDocSet, selectedDocSet, docSet]);

  const handleSubmit = async () => {
    try {
      const reduceDocSetTemplates = updatedDocusignTemplates.reduce(
        (acc, curr) => (acc.push(curr.templateDocusignId), acc),
        []
      );
      const docuSignTemplateIds = reduceDocSetTemplates.join(",");
      const response = await updateDocSet({
        variables: {
          input: {
            docusignTemplateIds: docuSignTemplateIds,
          },
          id: selectedDocSet.key,
        },
      });
      if (response.data) {
        await setResponseSuccess(true);
        await handleOpenMessageModal();
        await docSetRefetch();
        await updateDocSetRefetch();
        setTimeout(() => {
          setResponseSuccess(false);
          handleCloseMessageModal();
          handleClose();
        }, 2000);
      } else {
        await setResponseSuccess(false);
        await handleOpenMessageModal();
        await docSetRefetch();
        await updateDocSetRefetch();
      }
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };
  const handleCancel = () => {
    setDocusignTemplateId("");
    handleClose();
  };
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(true);
  };

  return (
    <>
      {!!docSet && (
        <div style={{ width: "400px" }} key={docSet.docSetId}>
          <Dialog onClose={handleClose} open={open} scroll={"body"} maxWidth={"md"} fullWidth={true}>
            <DialogTitle>Edit Doc Set</DialogTitle>
            <DialogContent>
              <form className={classes.container}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-multiline-static1"
                    label="Name"
                    multiline
                    rows={1}
                    value={docSetName}
                    className={classes.primaryBorderColor}
                    variant="outlined"
                    onChange={handleChangeDocSetName}
                    required
                    helperText={"Please enter a name for the document"}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-multiline-static1"
                    label="Description"
                    multiline
                    rows={4}
                    value={docSetDescription}
                    className={classes.primaryBorderColor}
                    variant="outlined"
                    onChange={handleChangeDocSetDescription}
                    required
                  />
                </FormControl>
                <FormControl className={[classes.selectFormControl, classes.formControl].join(" ")}>
                  <Autocomplete
                    multiple
                    value={docusignTemplateIds}
                    size="small"
                    options={docSet.docSet}
                    onChange={(event, newValue) => {
                      setDocusignTemplateId(newValue);
                      setUpdatedDocusignTemplates(newValue);
                    }}
                    getOptionLabel={option => option.templateName}
                    renderInput={params => (
                      <TextField {...params} variant="standard" label="Templates (In Order)" placeholder="" />
                    )}
                  />
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" simple>
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary" simple>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}
