import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton, InputBase, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";

const styles = {
  searchForm: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "15px",
    marginRight: "15px",
    marginTop: "25px",
    marginBottom: "10px",
    height: "30px",
    width: "250px",
  },
};

const useStyles = makeStyles(styles);

const TableSearch = ({ searchRef, savedListSettings, setSavedListSettings }) => {
  const [search, setSearch] = useState("");
  const classes = useStyles();

  const throttleSetSearchRef = _.throttle(text => {
    searchRef.current = { ...searchRef.current, currentSearch: text };
  }, 500);

  const handleSearchChange = text => {
    setSearch(text);
    throttleSetSearchRef(text);
    setSavedListSettings({ ...savedListSettings, page: 0 });
  };

  return (
    <>
      <Paper className={classes.searchForm}>
        <InputBase
          placeholder="Search"
          value={search}
          onChange={e => {
            handleSearchChange(e.target.value);
          }}
        />
        <IconButton
          type="submit"
          aria-label="search"
          onClick={() => {
            if (!search.length) {
              return;
            }

            setSearch("");
            searchRef.current = {
              ...searchRef.current,
              currentSearch: "",
            };
            setSavedListSettings({ ...savedListSettings, page: 0 });
          }}
        >
          {search.length > 0 ? <ClearIcon fontSize="small" /> : <SearchIcon fontSize="small" />}
        </IconButton>
      </Paper>
    </>
  );
};

export default TableSearch;
