import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import blue from "@material-ui/core/colors/blue";
import FormControl from "@material-ui/core/FormControl";
import UpdateMessageModal from "../../components/UpdateMessageModal";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStylesPaper = makeStyles(theme => ({
  clear: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minWidth: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  labelRoot: {
    fontWeight: 800,
    color: "black",
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[700],
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: blue[800],
      },
    },
  },
  formControl: {
    minWidth: 500,
    marginTop: "20px",
    marginBottom: "20px",
  },
  margin: {
    marginLeft: "15px",
    padding: "5px 10px",
  },
  ...extendedFormsStyle,
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  selectFixed: {
    // marginBottom: '0 !important',
    // '& label + .MuiInput-formControl': {
    //   marginTop: 0
    // },
    // '& .MuiInputLabel-formControl': {
    //   transform: 'translate(0, 6px) scale(1)'
    // },
    // '& .MuiInputLabel-shrink': {
    //   transform: 'translate(0, 1.5px) scale(0.75)'
    // }
  },
  colTopic: {
    width: "20%",
    minWidth: 100,
  },
  colRecipient: {
    width: "15%",
    minWidth: 75,
  },
  colMessage: {
    width: "65%",
    minWidth: 325,
  },
});

const useStyles = makeStyles(styles);
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

const SEND_ENVELOPE = gql`
  mutation($fdrId: ID, $input: DocuSignTemplateInputs!) {
    sendFDREnvelope(fdrId: $fdrId, input: $input)
  }
`;

export default function SendContractModal({
  open,
  handleClose,
  docSets,
  vo,
  creditApp,
  userProfile,
  account,
  refetch,
  fdrId,
  fdr,
  templates,
}) {
  const classesPaper = useStylesPaper();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState(null);
  const [confirmed, setConfirmed] = useState(null);
  const [selectedDocSet, setSelectedDocSet] = useState();
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [availableTemplates, setAvailableTemplates] = useState(templates);

  const [sendEnvelope] = useMutation(SEND_ENVELOPE, {
    context: { authRequired: true },
  });
  const classes = useStyles();
  const [updated, setUpdated] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    if (selectedDocSet) {
      const filteredTemplates = availableTemplates.filter(item => {
        return !selectedDocSet.docusignTemplateIds.includes(item.docusignTemplateId);
      });
      setAvailableTemplates(filteredTemplates);
    }
  }, [selectedDocSet]);

  const handleSubmit = async () => {
    try {
      const reduceTemplateDocuSignIds = selectedTemplates.reduce(
        (acc, curr) => (acc.push(curr.docusignTemplateId), acc),
        []
      );
      const docuSignTemplateIds = reduceTemplateDocuSignIds.join(",");
      let combineDocuSignIds;
      if (!!selectedDocSet && selectedDocSet.docusignTemplateIds) {
        combineDocuSignIds = selectedDocSet.docusignTemplateIds;
        combineDocuSignIds = docuSignTemplateIds ? combineDocuSignIds + "," + docuSignTemplateIds : combineDocuSignIds;
      } else {
        combineDocuSignIds = docuSignTemplateIds;
      }

      const response = await sendEnvelope({
        variables: {
          input: {
            templateIds: combineDocuSignIds,
            status: "sent",
            agreementId: fdr.agreementId,
          },
          fdrId: fdrId,
        },
      });
      if (response.data) {
        await setResponseSuccess(true);
        await handleOpenMessageModal();
        refetch();
        setTimeout(() => {
          setResponseSuccess(false);
          handleCloseMessageModal();
          handleClose();
        }, 2000);
      } else {
        await setResponseSuccess(false);
        await handleOpenMessageModal();
        refetch();
      }
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };
  const handleCreateEnvelope = async () => {
    try {
      const response = await sendEnvelope({
        variables: {
          input: {
            templateIds: selectedDocSet.docusignTemplateIds,
            docusignTemplateIds: selectedDocSet.docSetId,
            status: "created",
          },
          fdrId: fdrId,
        },
      });
      if (response.data) {
        await setResponseSuccess(true);
        await handleOpenMessageModal();
        refetch();
        setTimeout(() => {
          setResponseSuccess(false);
          handleCloseMessageModal();
          handleClose();
        }, 2000);
      } else {
        await setResponseSuccess(false);
        await handleOpenMessageModal();
        refetch();
      }
    } catch (err) {
      await setResponseSuccess(false);
      await handleOpenMessageModal();
      console.log(err);
    }
  };
  const handleCancel = () => {
    setSelectedDocSet(null);
    handleClose();
  };
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => {
    setOpenMessageModal(false);
    setResponseSuccess(true);
  };

  const handleSetOpenConfirmOpen = () => setOpenConfirm(true);
  const handleSetOpenConfirmClose = () => {
    setOpenConfirm(false);
    handleClose();
  };

  return (
    <>
      {!!docSets && !!availableTemplates && (
        <div style={{ width: "400px" }}>
          <Dialog onClose={handleClose} open={open} scroll={"body"} maxWidth={"sm"} fullWidth={true}>
            <MuiDialogTitle disableTypography className={classes.root}>
              <Typography variant="h6">Send Docs</Typography>
              {open ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </MuiDialogTitle>
            <Paper className={classesPaper.paper}>
              <CardContent>
                <div style={{ padding: "20px 0" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      Signor Name: {fdr.signorsName}
                    </Grid>
                    <Grid item xs={12}>
                      Signor Email: {fdr.signorsEmail}
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <FormControl fullWidth className={classes.selectFormControl + " " + classes.selectFixed}>
                        <Autocomplete
                          value={selectedDocSet}
                          size="small"
                          options={docSets}
                          onChange={(event, newValue) => {
                            setSelectedDocSet(newValue);
                          }}
                          getOptionLabel={option => `${option.name}`}
                          renderInput={params => (
                            <TextField {...params} variant="standard" label="Available Doc Sets" placeholder="" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <FormControl fullWidth className={classes.selectFormControl + " " + classes.selectFixed}>
                        <Autocomplete
                          multiple
                          value={selectedTemplates}
                          size="small"
                          options={availableTemplates}
                          onChange={(event, newValue) => {
                            setSelectedTemplates(newValue);
                          }}
                          getOptionLabel={option => `${option.name}`}
                          renderInput={params => (
                            <TextField {...params} variant="standard" label="Available Templates" placeholder="" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Button onClick={handleCancel} color="primary">
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleSubmit}
                        disabled={!selectedDocSet && !selectedTemplates.length}
                        color="primary"
                      >
                        Send
                      </Button>
                    </Grid>
                    {/* <Grid item>
											<Button onClick={handleCreateEnvelope} color="primary">
												Create Envelope
											</Button>
										</Grid> */}
                  </Grid>
                  <br />
                  <UpdateMessageModal
                    open={openMessageModal}
                    handleCloseMessageModal={handleCloseMessageModal}
                    responseSuccess={responseSuccess}
                  />
                </div>
                <br />
              </CardContent>
            </Paper>
          </Dialog>
        </div>
      )}
    </>
  );
}
