import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { makeStyles } from "@material-ui/core/styles";
import { Query } from "react-apollo";
import Tile from "../../components/Tile/Tile";
import Button from "components/CustomButtons/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LinearProgress from "@material-ui/core/LinearProgress";
import { vendorContactRoleHumanReadable } from "../../utils";
import { Grid } from "@material-ui/core";
import TableSelect from "components/Table/TableSelect";
import AddNewRepModal from "./AddNewRep";
import { connect } from "react-redux";
import { setRefetch, setValue } from "../../redux/actions";
import { ShepherdTourContext } from "react-shepherd";
import { CommonDataContext } from "../../contexts/CommonDataContext";
import TableSearch from "components/Table/TableSearch";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import { portalConfigurationTypes } from "../Prequal/constants";
import moment from "moment";

const _ = require("lodash");

const styles = {
  root: {
    "& .ReactTable .access-control-locations-cell": {
      overflow: "visible",
    },
    "& .ReactTable .rt-table": {
      overflow: "visible",
    },
    "& .ReactTable .rt-tbody": {
      overflow: "visible",
    },
    // to overwrite some styles from LocationsTreeViewDropdown component

    "& .access-control-locations-cell .tag-list .tag-item": {
      display: "block",
    },
    "& .access-control-locations-cell .tag-list .tag-item span": {
      color: "black",
      fontSize: "14px",
    },
    /*     "& .access-control-locations-cell .tag-list .tag-item:not(:last-child) .tag:after": {
      content: `", "`,
    }, */
    "& .access-control-locations-cell .tag-list .tag-item:nth-child(2)": {
      position: "initial",
    },
  },
  userStatusActive: {
    margin: "auto",
    padding: "4px 8px",
    color: "#2E7D32",
    backgroundColor: "#DCEDC8",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  userStatusBlocked: {
    margin: "auto",
    padding: "4px 8px",
    color: "#C62828",
    backgroundColor: "#FFCDD2",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  userStatusStaged: {
    margin: "auto",
    padding: "4px 8px",
    color: "#424242",
    backgroundColor: "#EEEEEE",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  userStatusInvited: {
    margin: "auto",
    padding: "4px 8px",
    color: "#1565C0",
    backgroundColor: "#BBDEFB",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  cardBody: {
    overflow: "visible !important",
  },
  /*   tableFilters: {
    display: "flex",
    alignItems: "center",
    width: "100%",

    "& .select-filters": {
      display: "flex",
      width: "100%",
      maxWidth: 360,
      columnGap: 16,
      paddingRight: 16,
    },
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,

    "& button": {
      height: "fit-content",
    }
  }, */
  right: {
    textAlign: "right",
  },
};

const useStyles = makeStyles(styles);

const GET_REP_LIST = gql`
  query {
    salesRepsForAdmin {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
      lastLogin
      invitationDatetime
      status
      locationId
      leadsAccessControlLocationIds
      opportunitiesAccessControlLocationIds
    }
  }
`;

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;

const QUERY_PORTAL_CONFIGURATION = gql`
  query($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation($entityId: String!, $fieldsObj: AdminUpdateUserProfileInput!) {
    adminUpdateUserProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

function PageRepList(props) {
  const { data: locationsDataResponse } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });

  const userProfileDynamicsAccountId = props.account.dynamicsAccountId;
  const userProfileAccountId = props.account.id;
  const userProfile = props.userProfile;
  const [tourEnabled, setTourEnabled] = useState(props.tourEnabled);
  const classes = useStyles();
  const statusesSel = [];
  const [componentLoaded, setComponentLoaded] = useState(false);
  const tour = useContext(ShepherdTourContext);
  const { isDealerUser, isLenderUser } = useContext(CommonDataContext);
  const [openAddNewRepModal, setOpenAddNewRepModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const statusRef = useRef({ prevStatus: undefined, currentSelect: "" });
  const roleRef = useRef({ prevRole: undefined, currentSelect: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [pageSizeValue, setPageSizeValue] = useState(20);
  const [statusFilter, setStatusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openLoadingSnackbar, setOpenLoadingSnackbar] = React.useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);
  const [locations, setLocations] = useState(null);
  const [locationsTree, setLocationsTree] = useState(null);
  const [leadsAccessControlByLocationRoles, setLeadsAccessControlByLocationRoles] = useState(null);
  const [opportunitiesAccessControlByLocationRoles, setOpportunitiesAccessControlByLocationRoles] = useState(null);
  const [userManagementReadOnlyRoles, setUserManagementReadOnlyRoles] = useState(null);
  const [saveUserProfileSuccess, setSaveUserProfileSuccess] = useState(false);
  const [saveUserProfileError, setSaveUserProfileError] = useState(false);

  const rolesSel = [];

  const { data: portalConfigurationData, loading } = useQuery(QUERY_PORTAL_CONFIGURATION, {
    variables: {
      vendorProfileId: props.account.vendorProfileId,
      types: [
        portalConfigurationTypes.leads,
        portalConfigurationTypes.opportunities,
        portalConfigurationTypes.userManagement,
      ],
    },
    fetchPolicy: "no-cache",
  });

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    context: { authRequired: true },
    onCompleted() {
      //setSaveUserProfileSuccess(true);
    },
    onError() {
      //setSaveUserProfileError(true);
    },
    ignoreResults: true, // to avoid closing the locations tree dropdown
  });

  const updateUserAccessLocations = async (user, key, locations) => {
    try {
      await updateUserProfile({
        variables: {
          entityId: user.dynamicsContactId,
          fieldsObj: {
            [key]: _.map(locations, "locationId"),
          },
        },
      });
      //await refetchUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const updateUserAccessLocationsDebounced = _.debounce(updateUserAccessLocations, 1500);

  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: selectedNode => {},
    texts: { placeholder: "Default Location" },
  });

  const statusTitles = {
    Active: "User has registered successfully and is connected to the account.",
    Blocked: "User has been removed from the account.",
    Staged: "User has been added to the account but has yet to receive a registration invite.",
    Invited: "User has been sent a registration invite but has yet to connect to the account.",
  };

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };
  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnackbar(false);
  };

  useEffect(() => {
    if (statusFilter !== "" || statusFilter !== "Show All") {
    }
    if (roleFilter !== "" || roleFilter !== "Show All") {
    }
  }, [statusFilter, roleFilter]);

  useEffect(() => {
    if (tourEnabled && componentLoaded) {
      tour.start();
    }
  }, [componentLoaded]);

  useEffect(() => {
    if (!locationsDataResponse || _.isEmpty(locationsDataResponse.locations)) {
      return;
    }
    setLocations(locationsDataResponse.locations);
    const treeOptions = _.map(locationsDataResponse.locations, location => {
      return {
        ...location,
        //checked: false,
        //isDefaultValue: location.locationId === fields.locationId.value,
      };
    });
    setLocationsTree(buildTree(treeOptions));
  }, [locationsDataResponse]);

  useEffect(() => {
    if (!portalConfigurationData) {
      return;
    }

    const leadsPortalConfiguration = _.find(portalConfigurationData.portalConfigurations, {
      type: portalConfigurationTypes.leads,
    });
    const opportunitiesPortalConfiguration = _.find(portalConfigurationData.portalConfigurations, {
      type: portalConfigurationTypes.opportunities,
    });
    const userManagementPortalConfiguration = _.find(portalConfigurationData.portalConfigurations, {
      type: portalConfigurationTypes.userManagement,
    });
    setLeadsAccessControlByLocationRoles(
      _.get(leadsPortalConfiguration, "jsonDefinition.config.rolesSettings.locationBased")
    );
    setOpportunitiesAccessControlByLocationRoles(
      _.get(opportunitiesPortalConfiguration, "jsonDefinition.config.rolesSettings.locationBased")
    );
    setUserManagementReadOnlyRoles(
      _.get(userManagementPortalConfiguration, "jsonDefinition.config.rolesSettings.readOnly")
    );
  }, [portalConfigurationData]);

  const assignLocationsToSalesReps = salesRepsForAdmin => {
    if (_.isEmpty(locations)) {
      return;
    }
    const locationsHashMap = _.keyBy(locations, "locationId");
    _.forEach(salesRepsForAdmin, salesRep => {
      const salesRepLocation = locationsHashMap[salesRep.locationId];
      salesRep.location = salesRepLocation ? salesRepLocation : null;
    });
  };

  function handleVORowClick(repId) {
    const url = `/profile/${repId}`;
    if (tourEnabled) {
      window.top.location = url;
    } else {
      props.history.push(url);
    }
  }

  const filterBySelectFields = RepListData => {
    const { currentSelect: currentStatusSelect } = statusRef.current;
    const { currentSelect: currentRoleSelect } = roleRef.current;
    let newData = { ...RepListData };

    if (currentStatusSelect?.length !== 0) {
      newData.salesRepsForAdmin = newData.salesRepsForAdmin.filter(el => el.status === currentStatusSelect);
    }

    if (currentRoleSelect?.length !== 0) {
      newData.salesRepsForAdmin = newData.salesRepsForAdmin.filter(
        el => el.vendorContactRoleReadable === currentRoleSelect
      );
    }

    return newData;
  };

  const filterRepListData = RepListData => {
    const { currentSearch } = searchRef.current;

    RepListData = filterBySelectFields(RepListData);

    if (currentSearch.length === 0) {
      return RepListData;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    let filteredData = RepListData.salesRepsForAdmin.filter(el => {
      if (
        typeof el.vendorContactRoleReadable === "string" ||
        typeof el.firstName === "string" ||
        typeof el.lastName === "string" ||
        typeof el.title === "string" ||
        typeof el.email === "string" ||
        typeof el.phoneNumber === "string" ||
        el.location
      ) {
        return (
          (el.vendorContactRoleReadable || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.firstName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.lastName || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.email || "").includes(lowerCaseSearch) ||
          (el.phoneNumber || "").replace(/[^0-9]/g, "").includes(lowerCaseSearch) ||
          (el.title || "").includes(lowerCaseSearch) ||
          _.toLower(_.get(el, "location.locationName", "")).includes(lowerCaseSearch)
        );
      }

      if (typeof el.fullName === "string") {
        return el.fullName.toLowerCase().includes(lowerCaseSearch);
      }

      return false;
    });

    return { ...RepListData, salesRepsForAdmin: filteredData };
  };

  function processRepListData(repListData) {
    const { salesRepsForAdmin } = repListData;
    if (_.isEmpty(salesRepsForAdmin)) {
      return;
    }
    const statuses = [];
    const roles = [];
    salesRepsForAdmin.forEach(item => {
      try {
        if (item.status === "pending_approval") {
          item.status = "Staged";
        }
        if (item.status === "approved") {
          item.status = "Invited";
        }
        if (item.status === "blocked") {
          item.status = "Blocked";
        }
        if (item.status === "active") {
          item.status = "Active";
        }
        item.vendorContactRoleReadable = vendorContactRoleHumanReadable[item.vendorContactRole];
        if (statuses.find(listItem => listItem === item.status) === undefined && !!item.status) {
          statuses.push(item.status);
        }
        if (
          roles.find(listItem => listItem === item.vendorContactRoleReadable) === undefined &&
          !!item.vendorContactRoleReadable
        ) {
          roles.push(item.vendorContactRoleReadable);
        }
      } catch (error) {
        console.log(error);
      }
    });
    statuses.forEach(item => {
      const statusesSelItem = {
        value: item,
        text: item ? item : "None",
      };
      statusesSel.push(statusesSelItem);
    });
    roles.forEach(item => {
      const rolesSelItem = {
        value: item,
        text: item ? item : "None",
      };
      rolesSel.push(rolesSelItem);
    });
    assignLocationsToSalesReps(salesRepsForAdmin);

    return salesRepsForAdmin;
  }

  const handleOpenAddNewRepModal = () => {
    setOpenAddNewRepModal(true);
  };
  const handleCloseAddNewRepModal = () => {
    setOpenAddNewRepModal(false);
  };

  const handleCloseTour = () => {
    if (tourEnabled) {
      tour.complete();
    }
  };

  return (
    <>
      <Query context={{ authRequired: true }} query={GET_REP_LIST} fetchPolicy="no-cache">
        {({ loading, error, data, refetch }) => {
          if (loading) return <LinearProgress />;
          if (error) return <h3>{String(error)}</h3>;

          const salesRepsForAdmin = processRepListData(filterRepListData(data));

          return (
            <div className={classes.root} onLoad={tourEnabled ? setComponentLoaded(true) : () => false}>
              <Grid>
                <Grid item xs={12}>
                  <Tile classBody={classes.cardBody}>
                    <TableHeader
                      filterComps={
                        <>
                          <TableSearch
                            setPageNumber={setPageNumber}
                            setSavedListSettings={setSavedListSettings}
                            searchRef={searchRef}
                            savedListSettings={savedListSettings}
                          />
                          <TableSelect
                            setPageNumber={setPageNumber}
                            setSavedListSettings={setSavedListSettings}
                            selectRef={statusRef}
                            savedListSettings={savedListSettings}
                            id="status"
                            label="Status"
                            options={statusesSel}
                            width={200}
                          />
                          {isDealerUser && (
                            <TableSelect
                              setPageNumber={setPageNumber}
                              setSavedListSettings={setSavedListSettings}
                              selectRef={roleRef}
                              savedListSettings={savedListSettings}
                              id="role"
                              label="Role"
                              options={rolesSel}
                              width={200}
                            />
                          )}
                        </>
                      }
                    >
                      {!_.includes(userManagementReadOnlyRoles, userProfile.vendorContactRole) && (
                        <Button
                          color="primary"
                          size="sm"
                          data-tour="add-user-button"
                          onClick={() => {
                            handleCloseTour();
                            return handleOpenAddNewRepModal();
                          }}
                        >
                          <PersonAddIcon />
                          Add User
                        </Button>
                      )}
                    </TableHeader>
                    <Table
                      color="primary"
                      page={pageNumber}
                      data={salesRepsForAdmin}
                      getTrProps={(state, rowInfo, column, instance) => ({
                        style: { cursor: "pointer" },
                        onClick: e => handleVORowClick(rowInfo.original.dynamicsContactId),
                      })}
                      columns={[
                        {
                          Header: "Status",
                          resizable: false,
                          accessor: "status",
                          // filterable: true,
                          width: 136,
                          Cell: props => (
                            <div title={statusTitles[props.value]} className={classes[`userStatus${props.value}`]}>
                              {props.value}
                            </div>
                          ),
                        },
                        {
                          Header: "Name",
                          accessor: "fullName",
                        },
                        {
                          Header: "Title",
                          accessor: "title",
                        },
                        {
                          Header: "Email",
                          accessor: "email",
                          minWidth: 200,
                        },
                        {
                          Header: "Role",
                          accessor: "vendorContactRoleReadable",
                          show: isDealerUser,
                        },
                        {
                          Header: "Last Login",
                          accessor: "lastLogin",
                          Cell: props => <>{props.value ? moment(props.value.split("T")[0]).format("ll") : ""}</>,
                        },
                        {
                          Header: "Default Location",
                          accessor: "location.locationName",
                        },
                        {
                          Header: "Leads Access Control by Location",
                          accessor: "leadsAccessControlLocationIds",
                          Cell: props => {
                            return (
                              <AccessControlLocationsDropdown
                                locations={locations}
                                accessControlByLocationRoles={leadsAccessControlByLocationRoles}
                                accessor="leadsAccessControlLocationIds"
                                vendorContactRole={_.get(props, "original.vendorContactRole")}
                                original={props.original}
                                userManagementReadOnlyRoles={userManagementReadOnlyRoles}
                                userProfile={userProfile}
                                updateUserAccessLocationsDebounced={updateUserAccessLocationsDebounced}
                              />
                            );
                          },
                          className: "access-control-locations-cell",
                          width: 250,
                          show: _.some(leadsAccessControlByLocationRoles),
                        },
                        {
                          Header: "Opportunities Access Control by Location",
                          accessor: "opportunitiesAccessControlLocationIds",
                          Cell: props => {
                            return (
                              <AccessControlLocationsDropdown
                                locations={locations}
                                accessControlByLocationRoles={opportunitiesAccessControlByLocationRoles}
                                accessor="opportunitiesAccessControlLocationIds"
                                vendorContactRole={_.get(props, "original.vendorContactRole")}
                                original={props.original}
                                userManagementReadOnlyRoles={userManagementReadOnlyRoles}
                                userProfile={userProfile}
                                updateUserAccessLocationsDebounced={updateUserAccessLocationsDebounced}
                              />
                            );
                          },
                          className: "access-control-locations-cell",
                          width: 250,
                          show: _.some(opportunitiesAccessControlByLocationRoles),
                        },
                      ]}
                      onPageChange={changePage => {
                        setPageNumber(changePage);
                      }}
                      onPageSizeChange={changePageSize => {
                        setPageSizeValue(changePageSize);
                        setPageNumber(0);
                      }}
                      pageSize={pageSizeValue}
                      defaultPageSize={savedListSettings.pageSize}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      customCellClasses={[classes.right]}
                      customClassesForCells={[5]}
                      className=" -highlight"
                    />
                  </Tile>
                </Grid>
              </Grid>
              <AddNewRepModal
                open={openAddNewRepModal}
                handleClose={() => handleCloseAddNewRepModal()}
                handleOpenAddNewRepModal={handleOpenAddNewRepModal}
                dynamicsAccountId={userProfileDynamicsAccountId}
                accountId={userProfileAccountId}
                setOpenErrorSnackbar={setOpenErrorSnackbar}
                setOpenLoadingSnackbar={setOpenLoadingSnackbar}
                setOpenSuccessSnackbar={setOpenSuccessSnackbar}
                refetchUsers={() => refetch()}
                locations={locations}
              />
            </div>
          );
        }}
      </Query>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openLoadingSnackbar}
        autoHideDuration={6000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Loading...
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="alert">
          Create New Rep error!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSuccessSnackbar}
        autoHideDuration={10000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          <h4>New User Registered!</h4>
        </Alert>
      </Snackbar>

      <Snackbar
        open={saveUserProfileSuccess}
        autoHideDuration={3000}
        onClose={() => setSaveUserProfileSuccess(state => !state)}
      >
        <Alert severity="success">
          <AlertTitle>Changes Saved!</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar
        open={saveUserProfileError}
        autoHideDuration={3000}
        onClose={() => setSaveUserProfileError(state => !state)}
      >
        <Alert severity="error">
          <AlertTitle>Error on saving</AlertTitle>
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = state => ({
  account: state.account,
  userProfile: state.userProfile,
  vp: state.vp,
  refetch: state.refetch,
});

const mapDispatchToProps = {
  reduxSetValue: setValue,
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageRepList);

const AccessControlLocationsDropdown /* React.memo( */ = ({
  locations,
  accessControlByLocationRoles,
  accessor,
  vendorContactRole,
  original,
  userManagementReadOnlyRoles,
  userProfile,
  updateUserAccessLocationsDebounced,
}) => {
  const selectedLocationIdsToSet = _.get(original, accessor, []);
  const [selectedLocationIds, setSelectedLocationIds] = useState(selectedLocationIdsToSet);

  const getSelectedValuesDisplayLabel = value => {
    return _.get(_.find(locations, { locationId: value }), "locationName");
  };

  const handleChange = (currentNode, selectedNodes) => {
    const selectedLocations = _.map(selectedNodes, "original");
    setSelectedLocationIds(_.map(selectedLocations, "locationId"));
    updateUserAccessLocationsDebounced(original, accessor, selectedLocations);
  };

  const handleDropdownClick = event => {
    event.stopPropagation();
  };

  const isNeedToShowLocationsTree = _.some(locations) && _.includes(accessControlByLocationRoles, vendorContactRole);
  if (!isNeedToShowLocationsTree) {
    return <span>No Restrictions by Locations</span>;
  }
  const isReadOnlyAccess = _.includes(userManagementReadOnlyRoles, userProfile.vendorContactRole);
  if (isReadOnlyAccess) {
    return (
      <span>
        {_(selectedLocationIds)
          .map(getSelectedValuesDisplayLabel)
          .value()
          .join(", ")}
      </span>
    );
  }
  const options = _.map(locations, location => {
    return {
      ...location,
      checked: _.includes(selectedLocationIds, location.locationId),
    };
  });
  const tree = buildTree(options);
  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: handleChange,
    mode: "hierarchical",
    texts: _.some(options, "checked") ? {} : { placeholder: "Choose Locations" }, // hotfix - to show placeholder
  });

  return (
    <div style={{ padding: "10px 0" }} onClick={handleDropdownClick}>
      <LocationsTreeViewDropdown tree={tree} treeSelectOptions={treeSelectOptions} />
    </div>
  );
}; /* , (props, nextProps) => _.isEqual(nextProps.options, props.options) */
/* ) */
