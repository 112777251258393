import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@material-ui/core";
import { ModalsKeys } from "./Modals";
import { useModal } from "../../../hooks/useModal";

export const LoadModal = () => {
  const classes = useStyles();

  const { isOpen, data } = useModal(ModalsKeys.Load);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <DialogContent>
        <Box className={classes.content}>
          <CircularProgress color="primary" />

          <Typography component="span" variant="h6">
            {data?.message}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  content: {
    gap: "12px",
    display: "flex",
    padding: "8px 0",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
});
