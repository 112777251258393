import React, { useState } from "react";
import { useDrag } from "react-dnd";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
const config = require("../../config");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "80vh",
    overflowX: "auto",
    position: "sticky",
    top: "10vh",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    fontSize: "10px",
    border: "1px solid #CECECE",
  },
  pill: {
    border: "2px solid #2b438938",
    borderRadius: "20px",
    fontSize: "12px",
    margin: "10px auto",
    width: "95%",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  searchInput: {
    flexGrow: 1,
    marginRight: "10px",
  },
}));

const LenderProfileList = ({ profiles, onDropFinaceProgram, handleCreateProgram }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredProfiles = profiles.filter(profile =>
    profile.lenderName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  return (
    <>
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <div className={classes.searchContainer}>
              <TextField
                className={classes.searchInput}
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <Button variant="contained" color="secondary" size="small" onClick={handleClearSearch}>
                Clear
              </Button>
            </div>
          </ListSubheader>
        }
        className={classes.root}
      >
        {filteredProfiles.map(profile => (
          <LenderProfileItem
            key={profile.id}
            profile={profile}
            onDropFinaceProgram={onDropFinaceProgram}
            handleCreateProgram={handleCreateProgram}
          />
        ))}
      </List>
    </>
  );
};

const LenderProfileItem = ({ profile, onDropFinaceProgram, handleCreateProgram }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "PROFILE",
    item: profile,
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        onDropFinaceProgram(profile);
      }
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleCollapse = () => {
    setOpen(!open);
  };

  return (
    <ListItem key={profile.id} onClick={handleCollapse} className={classes.pill} ref={drag}>
      <ListItemAvatar>
        <Avatar
          style={{ display: "inline-flex", padding: "5px" }}
          alt="img"
          src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${profile.lenderLogo}`}
        >
          <ImageIcon />
        </Avatar>
        <span style={{ marginLeft: "10px" }}>{profile.lenderName}</span>
      </ListItemAvatar>
    </ListItem>
  );
};

const FinanceProgramItem = ({ financeProgram, onDropFinaceProgram }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleCollapse = () => {
    setOpen(!open);
  };

  const [{ isDragging }, drag] = useDrag({
    type: "PROFILE",
    item: financeProgram,
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        onDropFinaceProgram(financeProgram);
      }
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <ListItem ref={drag} key={`${financeProgram.financeProgramId}`} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <ListItemText
        primary={
          <span style={{ border: "1px solid #CCCCCC", padding: "5px", borderRadius: "5px" }}>
            {financeProgram.nameInternal}
          </span>
        }
      />
    </ListItem>
  );
};

export default LenderProfileList;
