import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { DCR_API_BASE_SERVER_URL } from "config";
import axios from "axios";
import moment from "moment";
import GetApp from "@material-ui/icons/GetApp";
import Description from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Box, Button, IconButton, Modal, Tooltip } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DocumentContext } from "contexts/DocumentContext";
import MessageModal from "components/MessageModal";
import DocumentAccessSettingsModal from "components/DocumentAccessSettingsModal";
import Table from "components/Table/Table";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { setValue } from "../../redux/actions";
import { connect } from "react-redux";
import _ from "lodash";
import { theme } from "../../theme";
import { DEACTIVATE_DOC } from "./queries";
import FileUploadModal from "../../pages/VendorOpportunity/FileUploadModal";
import { NewCreditAppDocumentsModal, UpdateDocumentModal } from "../DocumentsCommon/modals";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import {
  creditSubmissionDocumentsFiltersInitialValue,
  creditSubmissionDocumentsTypesFiltersOptions,
} from "../DocumentsCommon/constants";
import { DocumentsTableFilters } from "../DocumentsCommon/types";
import { filterTableDataByFiltersValues } from "../DocumentsCommon/lib";
import { CreditApplicationDetailsDialog } from "../../modules/creditApplicationDetails";
import { ContentViewType, PageTypes } from "../../global";
import { useViewTypeContext } from "../../contexts/contentViewType";
import { CardContainer } from "../shared/CardContainer/CardContainer";
import { EntityMobileCards } from "../shared/EntityMobileCards";
import { FiltersPanel } from "../shared/FiltersPanel";
import { DocumentItemMobileEntity } from "./types";
import { mobileEntityAdapter } from "./lib/mobileEntityAdapter";
import { MobileCardActionPanel } from "./ui/MobileCardActionPanel";
import { capitalizeFirstLetter } from "../../formatters";

const Documents = ({
  vo,
  account,
  userProfile,
  vendorProfile,
  refetchCreditApp,
  userDocs,
  refetchDocs,
  userDocsLoading,
  userDocsError,
  showDcrCreditAppRow,
  CADMPortalConfiguration,
  documentsPortalConfiguration,
  portalConfigurationDataLoading,
  tasksData,
  isCADMEditingDisabled,
}: any) => {
  const classes = useStyles();

  const { contentViewType } = useViewTypeContext();

  let tableData = [[]];

  const [tableFilters, setTableFilters] = useState<DocumentsTableFilters>(creditSubmissionDocumentsFiltersInitialValue);

  const filteredUserDocs = useMemo(
    () =>
      filterTableDataByFiltersValues(userDocs.documents, tableFilters, {
        search: "docName",
        type: "docType",
      }),
    [userDocs.documents, tableFilters]
  );

  const [showCreditApp, setShowCreditApp] = useState<any>(1);

  const [customCreditApp, setCustomCreditApp] = useState<any>([]);
  const [customCreditAppForMobile, setCustomCreditAppForMobile] = useState<any>([]);

  const [openMessageModal, setOpenMessageModal] = useState<any>(false);
  const [isEditable, setIsEditable] = useState<any>(false);
  const [isOpenCADMv2, setIsOpenCADMv2] = useState<any>(false);
  const [isCADMv2ForPDFOnly, setIsCADMv2ForPDFOnly] = useState<any>(false);
  const [isPDFDownloaded, setIsPDFDownloaded] = useState<any>(false);
  const [isViewDocumentModal, setIsViewDocumentModal] = useState<any>(false);
  const [viewDocument, setViewDocument] = useState<any>(null);
  const [isUpdateDocumentModal, setIsUpdateDocumentModal] = useState<any>(false);
  const [updateDocument, setUpdateDocument] = useState<any>(null);
  const [isDocumentAccessSettingsModalOpen, setIsDocumentAccessSettingsModalOpen] = useState<any>(false);

  const [deactivateDocRequest, { data: deactivateDocResponseData, loading: isDeactivatingDoc }] = useMutation(
    DEACTIVATE_DOC,
    {
      context: { authRequired: true },
    }
  );

  const documentContext = useContext(DocumentContext);
  const handleClose = () => setOpenVoCreditAppModal(false);
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => setOpenMessageModal(false);

  useEffect(() => {
    refetchCreditApp();
  }, [refetchCreditApp]);

  useEffect(() => {
    if (vendorProfile && userProfile) {
      if (vendorProfile.showCreditApp && vo.entityType !== "CASH_SALE") {
        let customPDF: any[] = [];
        let customPDFForMobile: any[] = [];

        _.map(vendorProfile.lenderProfiles, lp => {
          const Actions = (
            <React.Fragment>
              {vendorProfile.showCreditApp ||
              (userProfile !== undefined && userProfile.vendorContactRole === "credit_mgr") ? (
                <IconButton size="small" onClick={() => handleCustomerLenderPDFDownload(lp.pdfTemplate)}>
                  <GetApp style={{ fill: theme.palette.primary.main }} />
                </IconButton>
              ) : null}
            </React.Fragment>
          );

          if (lp.showCustomApp) {
            customPDF.push([
              `Lender Credit Application`,
              "Trnsact",
              "",
              `Credit Application`,
              `${lp.pdfTemplate} Credit Application`,
              Actions,
            ]);

            customPDFForMobile.push({
              createdBy: "Trnsact",
              docName: "Lender Credit Application",
              docType: "Credit Application",
              docDescription: null,
              createdDateTime: null,
              documentId: new Date().toString(),
              specialPanel: Actions,
            });
          }
        });

        setCustomCreditApp(customPDF);

        setCustomCreditAppForMobile(customPDFForMobile);
      }

      setShowCreditApp(vendorProfile.showCreditApp);
    }
  }, [vendorProfile, userProfile]);

  if (userDocsLoading) console.log("Loading documents...");
  if (userDocsError) console.log(`Error! ${userDocsError.message}`);

  const mapData = (key: any, name: any, uploaded_by: any, uploaded_on: any, type: any, description: any, link: any) => {
    return { key, name, uploaded_by, uploaded_on, type, description, link };
  };

  let creditAppDownloadTableRow: any[] = [];
  let creditAppDownloadTableRowForMobile: any[] = [];

  if (showDcrCreditAppRow && vo.entityType !== "CASH_SALE") {
    const ActionsNode = (
      <>
        {showCreditApp === undefined || userProfile === undefined ? null : showCreditApp === 3 ||
          (showCreditApp === 13 && userProfile.vendorContactRole === "credit_mgr") ? (
          <>
            {!portalConfigurationDataLoading && (
              <Tooltip title="Download" placement="top">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    if (_.some(CADMPortalConfiguration)) handleCADMv2PDFDownload();
                    else handleCreditAppRequestDownload();
                  }}
                >
                  <VerticalAlignBottomIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : null}
        {showCreditApp === undefined ? null : showCreditApp === 2 ||
          showCreditApp === 3 ||
          showCreditApp === 11 ||
          showCreditApp === 13 ? (
          <>
            {!portalConfigurationDataLoading && (
              <Tooltip title="View" placement="top">
                <IconButton
                  size="small"
                  onClick={() => {
                    if (_.some(CADMPortalConfiguration)) setIsOpenCADMv2(true);
                    else handleCreditAppRequest();
                  }}
                >
                  <Description style={{ fill: theme.palette.primary.main }} />
                </IconButton>
              </Tooltip>
            )}
            {portalConfigurationDataLoading && (
              <Button color="primary" size="small" onClick={_.noop}>
                Loading...
              </Button>
            )}
          </>
        ) : null}
      </>
    );

    creditAppDownloadTableRowForMobile.push({
      createdBy: "Trnsact",
      docName: "Credit Application",
      docType: "Credit Application",
      docDescription: null,
      createdDateTime: null,
      documentId: new Date().toString(),
      specialPanel: ActionsNode,
    });

    creditAppDownloadTableRow.push(
      "Credit Application",
      "Trnsact",
      "",
      "Credit Application",
      "",
      <Box className={classes.cell}>{ActionsNode}</Box>
    );
  }

  const wellsFargoPDFRowActions = (
    <>
      {showCreditApp === undefined || userProfile === undefined ? null : showCreditApp === 3 ||
        (showCreditApp === 13 && userProfile.vendorContactRole === "credit_mgr") ? (
        <IconButton size="small" onClick={() => handleCustomerLenderPDFDownload("WELLS")}>
          <GetApp style={{ fill: theme.palette.primary.main }} />
        </IconButton>
      ) : null}
    </>
  );

  const wellsFargoPDFRow = [
    "Wells Fargo Credit Application",
    "Trnsact",
    "Credit Application",
    "Wells Fargo Credit Application",
    wellsFargoPDFRowActions,
  ];

  const wellsFargoPDFRowForMobile = [
    {
      createdBy: "Trnsact",
      docName: "Wells Fargo Credit Application",
      docType: "Credit Application",
      docDescription: "Wells Fargo Credit Application",
      createdDateTime: "",
      documentId: new Date().toString(),
      specialPanel: wellsFargoPDFRowActions,
    },
  ];

  const tableHead = ["File Name", "Uploaded By", "Uploaded On", "Type", "Description", "Action"];

  function addDocumentsToTableData(documents: any) {
    const documentsRows: any[] = [];

    if (!!documents && documents.length) {
      documents.forEach((row: any) => {
        if (
          row.type === "Credit Report" &&
          vendorProfile.softPullInDealerPortal !== "CBC_EQUIFAX_SOFT_FULL_REPORT" &&
          !row.name.startsWith("Commercial")
        ) {
          return;
        }

        const tableRow = [
          row.name,
          row.uploaded_by,
          row.uploaded_on,
          row.type,
          row.description,
          <Box className={classes.cell}>
            {row.link ? (
              <>
                <Tooltip title="Download" placement="top">
                  <IconButton size="small" href={row.link} target="_blank">
                    <VerticalAlignBottomIcon color="primary" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Edit" placement="top">
                  <IconButton size="small" onClick={() => handleisUpdateDocumentModal(row)}>
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete" placement="top">
                  <IconButton size="small" disabled={isDeactivatingDoc} onClick={() => handleDeactivateDoc(row.key)}>
                    <DeleteOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <div style={{ padding: "12px" }}>
                <Tooltip title="Access Restricted" aria-label="Access Restricted" placement="top">
                  <ReportProblemOutlinedIcon className={`${classes.reportProblemIcon}`} />
                </Tooltip>
              </div>
            )}
          </Box>,
        ];

        documentsRows.push(tableRow);
      });
    }

    tableData =
      (vendorProfile &&
        vendorProfile.vendorProfile &&
        vendorProfile.vendorProfile.dynamicsId === "29e4c282-7d12-e811-80ea-005056b05a0f") ||
      (vendorProfile &&
        vendorProfile.vendorProfile &&
        vendorProfile.vendorProfile.dynamicsId === "a899de7a-624c-ea11-8115-005056b05a0f")
        ? [...[creditAppDownloadTableRow], ...[wellsFargoPDFRow], ...documentsRows]
        : [...[creditAppDownloadTableRow], ...customCreditApp, ...documentsRows];
  }

  let documents;

  if (userDocs) {
    documents = filteredUserDocs.map((row: any) =>
      mapData(
        row.documentId,
        row.docName,
        row.createdBy,
        row.createdDateTime ? moment(row.createdDateTime).format("MM-DD-yyyy") : "",
        row?.docType ? capitalizeFirstLetter(row.docType) : "",
        row.docDescription,
        row.source
      )
    );
  }
  addDocumentsToTableData(documents);

  useEffect(() => {
    //refetch data after lastDoc context changed
    if (refetchDocs) refetchDocs();
  }, [documentContext.lastDoc]);

  useEffect(() => {
    //refetch data after doc deactivated
    if (refetchDocs) refetchDocs();
  }, [deactivateDocResponseData]);

  useEffect(() => {
    if (!isPDFDownloaded) {
      return;
    }
    setIsOpenCADMv2(false);
    setIsCADMv2ForPDFOnly(false);
    setIsPDFDownloaded(false);
    handleCloseMessageModal();
  }, [isPDFDownloaded]);

  const [creditApp, setCreditApp] = useState<any>();
  const [openVoCreditAppModal, setOpenVoCreditAppModal] = useState<any>(false);

  const handleCreditAppRequest = () => {
    setOpenVoCreditAppModal(true);
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
        {
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          account: { account },
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(error => console.log(error));
  };

  const handleRefetchCreditAppRequest = () => {
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
        {
          vendorProfile: vendorProfile,
          userProfile: userProfile,
          account: { account },
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(error => console.log(error));
  };

  const handleCreditAppRequestDownload = () => {
    handleOpenMessageModal();
    axios
      .post(
        // Check for credit app created by Anvil workflow first
        `${DCR_API_BASE_SERVER_URL}/credit-app?transaction_id=${vo.transactionId}`,
        {
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          account: { account },
          download: true,
        }
      )
      .then(async response => {
        if (response.data) {
          handleCloseMessageModal();
          const fileURL = response.data;
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("target", "_blank");
          link.setAttribute("download", "credit_app.pdf");
          document.body.appendChild(link);
          link.click();
        } else {
          // If no Anvil workflow credit app found, generate our own pdf
          axios
            .post(
              `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`,
              {
                userProfile: userProfile,
                vendorProfile: vendorProfile,
                account: { account },
                download: true,
              },
              {
                responseType: "blob",
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/pdf",
                },
              }
            )
            .then(async response => {
              handleCloseMessageModal();
              const fileURL = window.URL.createObjectURL(response.data);
              const link = document.createElement("a");
              link.href = fileURL;
              link.setAttribute("target", "_blank");
              link.setAttribute("download", "credit_app.pdf");
              document.body.appendChild(link);
              link.click();
            })
            .catch(error => console.log(error));
        }
      })
      .catch(error => console.log(error));
  };

  const handleCADMv2PDFDownload = () => {
    handleOpenMessageModal();
    setIsCADMv2ForPDFOnly(true);
    setIsOpenCADMv2(true);
  };

  const handleCustomerLenderPDFDownload = (lenderCode: any) => {
    handleOpenMessageModal();
    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/lender-specific-pdf?transaction_id=${vo.transactionId}`,
        {
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          lenderCode,
          account: { account },
          download: true,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then(async response => {
        handleCloseMessageModal();
        const fileURL = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "credit_app.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => console.log(error));
  };

  const handleDeactivateDoc = async (value: any) => {
    try {
      await deactivateDocRequest({ variables: { id: value } });
      await refetchDocs();
    } catch (err) {
      console.log(err);
    }
  };

  const handleisUpdateDocumentModal = (row: any) => {
    setUpdateDocument({ ...row });
    setIsUpdateDocumentModal(true);
  };

  const documentsEntitiesForMobileRender = useMemo(() => {
    const isSpecialAccount = ["29e4c282-7d12-e811-80ea-005056b05a0f", "a899de7a-624c-ea11-8115-005056b05a0f"].includes(
      vendorProfile?.vendorProfile?.dynamicsId
    );

    const additionalDocs = isSpecialAccount ? wellsFargoPDFRowForMobile : customCreditAppForMobile;

    return [...creditAppDownloadTableRowForMobile, ...additionalDocs, ...filteredUserDocs];
  }, [filteredUserDocs, vendorProfile]);

  const renderByViewType: Record<ContentViewType, ReactNode> = {
    [ContentViewType.Mobile]: (
      <EntityMobileCards<any, DocumentItemMobileEntity>
        entities={documentsEntitiesForMobileRender}
        entityAdapter={mobileEntityAdapter}
        actionsPanel={({ id, specialPanel, original }) => (
          <MobileCardActionPanel
            doc={original}
            specialPanel={specialPanel}
            onDelete={() => handleDeactivateDoc(id)}
            onEdit={() =>
              handleisUpdateDocumentModal({
                description: original.docDescription,
                key: original.documentId,
                link: original.source,
                name: original.docName,
                type: original.docType,
                uploaded_by: original.createdBy,
                uploaded_on: original.createdDateTime ? moment(original.createdDateTime).format("MM-DD-yyyy") : "",
              })
            }
          />
        )}
      />
    ),
    [ContentViewType.Desktop]: <Table tableHead={tableHead} tableData={tableData} />,
  };

  return (
    <>
      <CardContainer
        title="Documents"
        contentViewType={contentViewType}
        actionBtn={
          <FileUploadModal
            vo={vo}
            equipmentUpload={false}
            documentsPortalConfiguration={documentsPortalConfiguration}
            buttonDescription={() => {
              return (
                <>
                  <CloudUploadIcon className={classes.cloudIcon} /> Upload Document
                </>
              );
            }}
          />
        }
      >
        <Box mb="1rem">
          <FiltersPanel
            options={{ type: { label: "Type", values: creditSubmissionDocumentsTypesFiltersOptions } }}
            onOptionsChange={(filter, nextValue) => {
              setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
            }}
            onSearchChange={(filter, nextValue) => {
              setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
            }}
          />
        </Box>

        {renderByViewType[contentViewType]}
      </CardContainer>

      <NewCreditAppDocumentsModal creditApp={creditApp} isOpen={openVoCreditAppModal} onClose={handleClose} />

      {CADMPortalConfiguration && (
        <CreditApplicationDetailsDialog
          isOpen={isOpenCADMv2}
          onClose={() => setIsOpenCADMv2(false)}
          data={{
            vo,
            account,
            vendorProfile,
            setIsPDFDownloaded,
            isForPDFOnly: isCADMv2ForPDFOnly,
            pageType: PageTypes.VendorOpportunities,
            isEditingDisabled: isCADMEditingDisabled,
            vendorOpportunityTasks: tasksData?.vendorOpportunityTask,
            creditAppModalConfig: CADMPortalConfiguration?.jsonDefinition,
          }}
        />
      )}

      <UpdateDocumentModal
        refetchDocuments={refetchDocs}
        isOpen={isUpdateDocumentModal}
        updateDocument={updateDocument}
        setUpdateDocument={setUpdateDocument}
        onClose={() => {
          setIsUpdateDocumentModal(false);
        }}
      />

      <NewCreditAppDocumentsModal
        creditApp={creditApp}
        onClose={handleClose}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        isOpen={openVoCreditAppModal}
        showCreditApp={showCreditApp}
        refetchCreditApp={refetchCreditApp}
        userProfile={userProfile.userProfile}
        handleRefetchCreditAppRequest={handleRefetchCreditAppRequest}
      />

      <MessageModal
        link={null}
        isOpen={openMessageModal}
        handleCloseModal={handleCloseMessageModal}
        title={"Loading"}
        message={"Please wait while we prepare your document."}
      />

      <DocumentAccessSettingsModal
        isDocumentAccessSettingsModalOpen={isDocumentAccessSettingsModalOpen}
        setIsDocumentAccessSettingsModalOpen={setIsDocumentAccessSettingsModalOpen}
      />

      <Modal
        open={isViewDocumentModal}
        onClose={() => setIsViewDocumentModal(false)}
        aria-labelledby={viewDocument?.docName || ""}
        aria-describedby="Summary"
      >
        <div className={classes.documentModal}>
          <h3>{viewDocument?.name}</h3>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    account: state.account,
    userProfile: state.userProfile,
    vendorProfile: state.vp,
  };
};

const mapDispatchToProps = {
  reduxSetValue: setValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);

const useStyles = makeStyles(() => ({
  clearButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  cardIconTitle: {
    ...cardTitle,
  },
  flexJustifyEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cloudIcon: {
    margin: "0px 8px 0px 0px !important",
  },
  documentModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 540,
    borderRadius: 8,
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media (max-width: 768px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
  reportProblemIcon: {
    color: "#ec9c12",
  },
  cell: {
    gap: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));
