import React from "react";
import { FormCheckbox } from "../../../../../../../components/form";
import { SimpleProductsDynamicFieldsProps } from "../../../../../types";

export const BooleanField = ({
  fact,
  facts,
  setFacts,
  disabled,
  productConfigurationRulesEngine,
}: SimpleProductsDynamicFieldsProps) => {
  const onChange = (event: any) => {
    productConfigurationRulesEngine.current.addFact(fact.factKey, event.target.checked ? "true" : "false");
    setFacts((prev: any) => ({ ...prev, [fact.factKey]: event.target?.checked ? "true" : "false" })); // This breaks sometimes
  };

  return (
    <FormCheckbox
      disabled={disabled}
      onChange={onChange}
      label={fact.factKey.replace(/_/g, " ")}
      checked={facts[fact.factKey] === "true"}
    />
  );
};
