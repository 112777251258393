import { useRef, useEffect } from "react";

export function useHorizontalScroll() {
  const elRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const node = elRef.current;

    if (node) {
      const onWheel = (event: any) => {
        if (event.deltaY == 0) return;

        event.preventDefault();
        node.scrollTo({ left: node.scrollLeft + event.deltaY, behavior: "smooth" });
      };

      node.addEventListener("wheel", onWheel);

      return () => node.removeEventListener("wheel", onWheel);
    }
  }, []);

  return elRef;
}
