import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { EXPIRATION_VALUES_PER_USER_ROLE } from "config";
import { SESSION_TIMEOUT_COOKIE_NAME } from "../../constants";

const UserSessionControl = ({ userRole }) => {
  const createSessionCookie = () => {
    const sessionTime = JSON.parse(EXPIRATION_VALUES_PER_USER_ROLE);
    let expirationTime;
    // userRole could be defaulted to "dealer" or "lender"
    if (userRole) {
      console.log(`Applying expiration time based on user role: ${userRole}`);
      expirationTime = new Date(new Date().getTime() + sessionTime[userRole] * 60 * 1000);
    } else {
      return;
    }

    const userSession = Cookies.get(SESSION_TIMEOUT_COOKIE_NAME);
    if (!userSession) {
      Cookies.set(SESSION_TIMEOUT_COOKIE_NAME, "loggedIn", { expires: expirationTime });
    }
  };

  const checkSession = async () => {
    const userSession = Cookies.get(SESSION_TIMEOUT_COOKIE_NAME);

    if (!userSession) {
      await Auth.signOut();
      localStorage.clear();
      sessionStorage.clear();
      document.location.pathname = "";
    }
  };

  useEffect(() => {
    createSessionCookie();
    checkSession();
    const interval = setInterval(() => {
      checkSession();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default UserSessionControl;
