import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Button as MUIButton,
  MenuItem,
  Select,
  Tab,
  Grid,
  makeStyles,
} from "@material-ui/core";

import { BpmnVisualization } from "bpmn-visualization";
import "bpmn-js/dist/assets/bpmn-js.css";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import Close from "@material-ui/icons/Close";
import FinanceProgramForm from "../../../components/FinanceProgramForm";
import CriteriaForm from "./CriteriaForm";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
const beautify = require("xml-beautifier");
interface Account {
  id: string;
}

interface AppState {
  account: Account;
}

const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

const styles = makeStyles(theme => ({
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },

  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
  locationTree: {
    padding: "10px 0",
    "& .tag-item": {
      position: "inherit !important",
    },
    "& .tag-item span": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "#f4f4f4",
      border: "1px solid #e9e9e9",
      padding: "2px 0 2px 8px",
      borderRadius: "4px",
      display: "inline-block",
    },
    "& .tag-item button": {
      display: "inline-block",
      fontSize: "100%",
    },
    "& .tag-item span.placeholder": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "transparent",
      border: "none",
      padding: "0",
      borderRadius: "0",
      display: "inline-block",
    },
  },
}));

const Visualizer = ({ open, onClose, xml }: { open: any; onClose: any; xml: string }) => {
  const classes = styles();

  const account = useTypedSelector(state => state.account);
  const [bpmnVisualization, setBpmnVisualization] = useState<BpmnVisualization>();

  const handleClose = () => {
    onClose();
  };
  useEffect(() => {}, []);

  useEffect(() => {
    if (!_.isEmpty(xml)) {
      setTimeout(() => {
        const x = new BpmnVisualization({
          container: "bpmn-container",
          navigation: { enabled: false },
        });
        x.load(xml);
      }, 500);
    }
  }, [xml]);
  const handleSubmit = () => {};

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="visualizer-title"
      aria-describedby="visualizer-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="visualizer-title" style={{ paddingBottom: 0 }}>
        <h4>
          <b>Workflow</b>
        </h4>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <pre style={{ fontSize: "11px", overflow: "scroll" }}>{beautify(xml)}</pre>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} id="bpmn-container"></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MUIButton
          color="primary"
          onClick={() => {
            const fileName = "model.bpmn";
            const blob = new Blob([xml], { type: "application/xml" });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;

            link.click();

            window.URL.revokeObjectURL(link.href);
          }}
        >
          Download BPMN File
        </MUIButton>

        <MUIButton color="primary" variant="contained" onClick={() => onClose()}>
          Save & Close
        </MUIButton>
      </DialogActions>
    </Dialog>
  );
};

export default Visualizer;
