import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useLazyQuery } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import Tile from "../../../components/Tile/Tile";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Backdrop, LinearProgress } from "@material-ui/core";
import { DndProvider } from "react-dnd";
import { BlockList } from "../../DynamicOCA/components/FormBuilder/BlockList";
import { ItemConfigurationPanel } from "../CommonComponents/ItemConfigurationPanel";
import { HTML5Backend } from "react-dnd-html5-backend";
import CreditAppDetailModalConfigurable from "./CreditAppDetailModal";
import {
  defaultMovingElementSettings,
  getCreditAppModalDefaultPortalConfiguration,
  getJSONDefinitionBasedOnOCATemplate,
} from "../../../services/creditAppModalService";
import { configurableItemsTypes, portalConfigurationTypes } from "../../Prequal/constants";
import _ from "lodash";

const CREATE_PORTAL_CONFIGURATION = gql`
  mutation($input: UpsertPortalConfigurationInput!) {
    savePortalConfiguration(input: $input)
  }
`;

const QUERY_PORTAL_CONFIGURATION = gql`
  query($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

const DELETE_PORTAL_CONFIGURATION = gql`
  mutation($portalConfigurationId: ID!) {
    deletePortalConfiguration(portalConfigurationId: $portalConfigurationId)
  }
`;

const QUERY_ACTIVE_OCA_TEMPLATE = gql`
  query($vendorProfileId: ID!, $isActive: Boolean) {
    getOcaTemplatesByVendorProfile(vendorProfileId: $vendorProfileId, isActive: $isActive) {
      ocaTemplateId
      name
      vendorProfileName
      jsonDefinition
      active
    }
  }
`;

const styles = {
  configurationWrapper: {
    backgroundColor: "white",
    padding: "16px 16px 200px",
    //minWidth: "400px",
    width: "400px",
    borderRadius: "6px",
    position: "fixed",
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  modalContainer: {
    position: "relative",
  },
  backdrop: {
    position: "absolute",
    zIndex: "1000",
    margin: "16px",
    borderRadius: "6px",
  },
};

const useStyles = makeStyles(styles);

const CreditAppDetailModalContainer = props => {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const [portalConfiguration, setPortalConfiguration] = useState(null);
  const [jsonDefinition, setJSONDefinition] = useState(null);
  const [configurableItemSettings, setConfigurableItemSettings] = useState(null);
  const [isItemConfigurationMode, setIsItemConfigurationMode] = useState(false);
  const [isCustomConfigurationSaved, setIsCustomConfigurationSaved] = useState(false);
  const [movingElementSettings, setMovingElementSettings] = useState(defaultMovingElementSettings);
  const [selectedConfigurationType, setSelectedConfigurationType] = useState(portalConfigurationTypes.CADMCommercial);

  let activeOcaTemplate = null;

  const [createPortalConfiguration] = useMutation(CREATE_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
  });

  const [deletePortalConfiguration] = useMutation(DELETE_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
  });

  const { data: portalConfigurationData, refetch: refetchPortalConfigurationData, loading, error } = useQuery(
    QUERY_PORTAL_CONFIGURATION,
    {
      variables: {
        vendorProfileId: account.vendorProfileId,
        types: [portalConfigurationTypes.CADMCommercial, portalConfigurationTypes.CADMIndividual],
      },
      fetchPolicy: "no-cache",
    }
  );

  const [getActiveOcaTemplate, { data: activeOCATemplateData }] = useLazyQuery(QUERY_ACTIVE_OCA_TEMPLATE, {
    variables: {
      vendorProfileId: account.vendorProfileId,
      isActive: true,
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!activeOCATemplateData) {
      return;
    }
    activeOcaTemplate = _.first(activeOCATemplateData.getOcaTemplatesByVendorProfile);
    const jsonDefinition = getJSONDefinitionBasedOnOCATemplate(activeOcaTemplate, selectedConfigurationType);
    setJSONDefinition(jsonDefinition);
  }, [activeOCATemplateData]);

  useEffect(() => {
    if (!portalConfigurationData) {
      return;
    }
    const selectedPortalConfiguration = _.find(portalConfigurationData.portalConfigurations, {
      type: selectedConfigurationType,
    });
    const isCustomConfigurationSaved = _.some(selectedPortalConfiguration);
    const portalConfigurationToSet = isCustomConfigurationSaved
      ? selectedPortalConfiguration
      : getCreditAppModalDefaultPortalConfiguration(account.vendorProfileId, selectedConfigurationType);
    delete portalConfigurationToSet.__typename;
    setPortalConfiguration(portalConfigurationToSet);
    setJSONDefinition(portalConfigurationToSet.jsonDefinition);
    setIsCustomConfigurationSaved(isCustomConfigurationSaved);
  }, [portalConfigurationData, selectedConfigurationType]);

  useEffect(() => {
    refetchPortalConfigurationData();
  }, [selectedConfigurationType]);

  useEffect(() => {
    if (!jsonDefinition || jsonDefinition === portalConfiguration.jsonDefinition) {
      return;
    }
    console.log(`jsonDefinition:`);
    console.log(jsonDefinition);
    savePortalConfiguration();
  }, [jsonDefinition]);

  useEffect(() => {
    if (!movingElementSettings.refEl) {
      return;
    }
    movingElementSettings.refEl.current.scrollIntoView({ block: "center" });
  }, [movingElementSettings]);

  const loadCurrentOCATemplate = () => {
    if (activeOcaTemplate) {
      const jsonDefinition = getJSONDefinitionBasedOnOCATemplate(activeOcaTemplate, selectedConfigurationType);
      setJSONDefinition(jsonDefinition);
      return;
    }
    getActiveOcaTemplate();
  };

  const savePortalConfiguration = async () => {
    const getJSONDefinitionToSave = jsonDefinition => {
      //HOTFIX: TODO: find a place where null saves in DB
      let jsonDefinitionToSave = _.cloneDeep(jsonDefinition);
      _.forEach(jsonDefinitionToSave.sections, section => {
        _.forEach(section.blocks, block => {
          block.fields = _.filter(block.fields);
        });
      });
      return jsonDefinitionToSave;
    };

    try {
      await createPortalConfiguration({
        variables: {
          input: {
            ...portalConfiguration,
            jsonDefinition: getJSONDefinitionToSave(jsonDefinition),
          },
        },
      }).then(({ data }) => {
        if (portalConfiguration.portalConfigurationId) {
          return;
        }
        setPortalConfiguration(prevPortalConfiguration => ({
          ...prevPortalConfiguration,
          portalConfigurationId: _.get(data, "savePortalConfiguration", null),
        }));
        setIsCustomConfigurationSaved(true);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const editConfigurableItem = (item, type, section, block, field) => {
    setIsItemConfigurationMode(true);
    setConfigurableItemSettings({
      item,
      type,
      section,
      block,
      field,
    });
  };

  const saveItemConfig = async configurableItemSettings => {
    setJSONDefinition(prevState => {
      if (configurableItemSettings.type === configurableItemsTypes.section) {
        return {
          ...prevState,
          sections: prevState.sections.map(section => {
            if (section.id === configurableItemSettings.item.id) {
              return {
                ...section,
                config: configurableItemSettings.item.config,
              };
            }
            return section;
          }),
        };
      }
      if (configurableItemSettings.type === configurableItemsTypes.block) {
        return {
          ...prevState,
          sections: prevState.sections.map(section => {
            if (section.id === configurableItemSettings.section.id) {
              return {
                ...section,
                blocks: section.blocks.map(block => {
                  if (block.id === configurableItemSettings.item.id) {
                    return {
                      ...block,
                      config: configurableItemSettings.item.config,
                    };
                  }
                  return block;
                }),
              };
            }
            return section;
          }),
        };
      }
      if (configurableItemSettings.type === configurableItemsTypes.field) {
        return {
          ...prevState,
          sections: prevState.sections.map(section => {
            if (section.id === configurableItemSettings.section.id) {
              return {
                ...section,
                blocks: section.blocks.map(block => {
                  if (block.id === configurableItemSettings.block.id) {
                    return {
                      ...block,
                      fields: block.fields.map(field => {
                        if (field.key === configurableItemSettings.item.key) {
                          return {
                            ...field,
                            config: configurableItemSettings.item.config,
                          };
                        }
                        return field;
                      }),
                    };
                  }
                  return block;
                }),
              };
            }
            return section;
          }),
        };
      }
    });
    setIsItemConfigurationMode(false);
  };

  const handleDeletePortalConfiguration = async () => {
    if (!portalConfiguration.portalConfigurationId) {
      return;
    }
    try {
      await deletePortalConfiguration({
        variables: {
          portalConfigurationId: portalConfiguration.portalConfigurationId,
        },
      }).then(() => {
        const defaultPortalConfiguration = getCreditAppModalDefaultPortalConfiguration(
          account.vendorProfileId,
          selectedConfigurationType
        );
        setPortalConfiguration(defaultPortalConfiguration);
        setJSONDefinition(defaultPortalConfiguration.jsonDefinition);
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={classes.root}>
      {loading && <LinearProgress />}
      {error && <h3>{String(error)}</h3>}
      {portalConfiguration && (
        <DndProvider backend={HTML5Backend}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={8}
              className={classes.modalContainer}
              style={isItemConfigurationMode ? { pointerEvents: "none" } : {}}
            >
              {loading && (
                <Tile>
                  <span>
                    <CircularProgress size={16} /> Loading ...
                  </span>
                </Tile>
              )}
              {_.some(portalConfiguration) && (
                <CreditAppDetailModalConfigurable
                  jsonDefinition={jsonDefinition}
                  setJSONDefinition={setJSONDefinition}
                  editConfigurableItem={editConfigurableItem}
                  deletePortalConfiguration={handleDeletePortalConfiguration}
                  loadCurrentOCATemplate={loadCurrentOCATemplate}
                  movingElementSettings={movingElementSettings}
                  setMovingElementSettings={setMovingElementSettings}
                  selectedConfigurationType={selectedConfigurationType}
                  setSelectedConfigurationType={setSelectedConfigurationType}
                />
              )}
              <Backdrop className={classes.backdrop} open={isItemConfigurationMode}></Backdrop>
            </Grid>
            <Grid item xs={4} className={classes.blockList}>
              <div className={classes.configurationWrapper}>
                {isItemConfigurationMode ? (
                  <ItemConfigurationPanel
                    configurableItemSettings={configurableItemSettings}
                    onSaveFn={saveItemConfig}
                    onCancelFn={() => setIsItemConfigurationMode(false)}
                  />
                ) : (
                  <BlockList selectNewElement={() => {}} />
                )}
              </div>
            </Grid>
          </Grid>
        </DndProvider>
      )}
    </div>
  );
};

export default CreditAppDetailModalContainer;
