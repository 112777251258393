import React, { useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import DoneIcon from "@material-ui/icons/Done";
import {
  CREDIT_SUBMISSION_SOURCE,
  CREDIT_SUBMISSION_SOURCE_HUMAN_READABLE,
  translateStageCodes,
} from "../../../../utils";
import _ from "lodash";
import rocketFastTrack from "../../../../assets/img/big-rocket.svg";
import Table from "../../../Table";
import { lenderTypesSettings } from "../../../../pages/Prequal/constants";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import { SUBMIT_APP } from "../../queries";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";

interface Props {
  creditSubmissionsRefetch: any;
  filteredCreditSubmissions: any;
  handleUpdateCredentials: any;
  handleOpenFastTrackModal: any;
  handleOpenLenderDetailModalView: any;
  pageNumber: any;
  savedListSettings: any;
  setPageNumber: any;
}

export const SyndicationsTable = ({
  pageNumber,
  savedListSettings,
  setPageNumber,
  creditSubmissionsRefetch,
  filteredCreditSubmissions,
  handleUpdateCredentials,
  handleOpenFastTrackModal,
  handleOpenLenderDetailModalView,
}: Props) => {
  const classes = useStyles();

  const lp = useSelector((state: any) => state.vp.lenderProfiles);
  const vo = useSelector((state: any) => state.vo);
  const account = useSelector((state: any) => state.account);
  const userProfile = useSelector((state: any) => state.userProfile);
  const creditApp = useSelector((state: any) => state.creditApp);

  const [creditSubmissionRequest] = useMutation(SUBMIT_APP, {
    context: { authRequired: true },
  });

  const [pageSizeValue, setPageSizeValue] = useState<any>(5);

  const lenderTypesSettingsByValue = _.keyBy(lenderTypesSettings, "code");

  const resubmitCreditApp = async (row: any) => {
    const lenderInfo = lp.find((lender: any) => lender.lenderName === row.lenderName);
    try {
      await creditSubmissionRequest({
        variables: {
          input: {
            submittedLender: lenderInfo.lenderAccountId,
            dynamicsCreditApplicationId: creditApp.dynamicsId,
            dynamicsVOId: vo.dynamicsVendorOpportunityId,
            dynamicsUserId: userProfile.dynamicsUserId,
            amountRequested: vo.invoiceTotal,
            accountId: account.id,
            lenderProfileId: lenderInfo.lenderProfileId,
            lenderContactId: lenderInfo.lenderContactId,
            createDraftOnly: false,
            transactionSummary: row.transactionSummary,
          },
        },
      });

      await creditSubmissionsRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <Table
      color="primary"
      data={filteredCreditSubmissions}
      getTrProps={() => {
        return {
          style: {
            cursor: "pointer",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "14px",
          },
        };
      }}
      columns={[
        {
          Header: "Submission Date",
          accessor: "submissionDate",
          Filter: () => null,
          resizable: false,
        },
        {
          Header: "Lender",
          accessor: "lenderName",
          Filter: () => null,
        },
        {
          Header: "Stage",
          Cell: (props: any) => {
            const row = props.original;
            let apiDetail = null;
            switch (row.apiResult) {
              case "error":
                apiDetail = (
                  <span>
                    <span style={{ color: "red" }}>Error</span>
                    <Tooltip title={row.apiDetail} placement="top-start">
                      <PriorityHighIcon />
                    </Tooltip>
                  </span>
                );
                break;
              case "failure":
                apiDetail = (
                  <span>
                    <span style={{ color: "red" }}>Error</span>
                    <Tooltip title={row.apiDetail} placement="top-start">
                      <PriorityHighIcon />
                    </Tooltip>
                  </span>
                );
                break;
              case "in progress":
                apiDetail = (
                  <Tooltip title={"Processing"} placement="top-start">
                    <HourglassEmptyIcon />
                  </Tooltip>
                );
                break;
              case "success":
                apiDetail = (
                  <Tooltip title={row.apiDetail} placement="top-start">
                    <DoneIcon />
                  </Tooltip>
                );
                break;

              default:
                break;
            }

            if (row.apiExpiredCredentials) {
              return (
                <div>
                  <PriorityHighIcon /> {"Credentials Expired"}
                  <Button
                    size="small"
                    className={classes.buttonUpdateCredentials}
                    onClick={() => {
                      handleUpdateCredentials(row);
                    }}
                  >
                    Update Credentials
                  </Button>
                </div>
              );
            }
            if (props.value) {
              return (
                <div>
                  {" "}
                  {translateStageCodes(props.value)} {apiDetail}
                </div>
              );
            } else return <>-</>;
          },
          accessor: "creditSubStage",
          Filter: () => null,
        },
        {
          Header: "Decision",
          accessor: "decision",
          Cell: (props: any) => {
            const row = props.original;
            if (row.apiResult === "error" || row.apiResult === "failure") {
              return <span>Error Details: {row.apiDetail}</span>;
            }
            return row.decision;
          },
          Filter: () => null,
        },
        {
          Header: "Screenshots",
          accessor: "apiScreenshots",
          Cell: (props: any) => {
            const row = props.original;
            if (row.apiScreenshots.length > 0) {
              return (
                <div>
                  {row.apiScreenshots.map((url: any, i: any) => (
                    <React.Fragment key={url}>
                      {i !== 0 && ", "}
                      <a target="_blank" href={url} rel="noreferrer">
                        image-{i + 1}
                      </a>
                    </React.Fragment>
                  ))}
                </div>
              );
            }

            return <>-</>;
          },
        },
        {
          Header: "Requested",
          accessor: "amountRequested",
          Cell: (props: any) => <div>{props.value ? currencyFormatter.format(props.value) : ""}</div>,
          Filter: () => null,
        },
        {
          Header: "Approved",
          accessor: "approvedAmount",
          Filter: () => null,
          Cell: (props: any) => <div>{props.value ? currencyFormatter.format(props.value) : "-"}</div>,
        },
        {
          Header: "Type",
          accessor: "lenderTypeCode",
          Cell: (props: any) => {
            const lenderTypeSetting = lenderTypesSettingsByValue[props.value];
            return lenderTypeSetting ? (
              <>
                <b style={{ color: lenderTypeSetting.color }}>{_.get(lenderTypeSetting, "letter", "")}</b>
                <span> {_.get(lenderTypeSetting, "title", "")}</span>
              </>
            ) : (
              <>-</>
            );
          },
        },
        {
          Header: "Submitted By",
          accessor: "source",
          Cell: (props: any) => {
            const submittedByText = (CREDIT_SUBMISSION_SOURCE_HUMAN_READABLE as any)[props.value];
            if (
              props.value === CREDIT_SUBMISSION_SOURCE.FAST_TRACK_AUTO ||
              props.value === CREDIT_SUBMISSION_SOURCE.FAST_TRACK_MANUAL
            ) {
              return (
                <span>
                  <img src={rocketFastTrack} width="30px" height="30px" alt="Fast track icon" />
                  {submittedByText}
                </span>
              );
            } else if (props.value === CREDIT_SUBMISSION_SOURCE.PORTAL_BY_DEALER) {
              return <span>{props.row._original.submittedBy}</span>;
            } else {
              return <span>TRNSACT</span>;
            }
          },
        },
        {
          Header: "Action",
          Filter: () => null,
          sortable: false,
          Cell: (props: any) => {
            const row = props.original;

            // shutting off the fast track rocket for now because there are issues with matching FT to a lender submission
            const isShowFastTrack = false; // row.decision === status.APPROVED && !row.draft && enableFastTrack;
            if (!!row && !!userProfile && userProfile.vendorContactRole !== "sales_rep") {
              return (
                <>
                  <Tooltip title="View Detail" placement="top">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={e => {
                        if (isShowFastTrack) handleOpenFastTrackModal(e);
                        else handleOpenLenderDetailModalView(row);
                      }}
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>

                  {row.apiResult === "failure" && (
                    <Button
                      size="small"
                      className={classes.buttonDetail}
                      onClick={() => {
                        resubmitCreditApp(row);
                      }}
                    >
                      RE-SUBMIT
                      <span style={{ padding: "0px 5px", color: "#C4C4C4" }}>|</span>
                    </Button>
                  )}
                </>
              );
            }

            return null;
          },
        },
      ]}
      showPaginationBottom
      pageNumber={pageNumber}
      pageSize={pageSizeValue}
      showPaginationTop={false}
      defaultPageSize={savedListSettings.pageSize}
      onPageChange={setPageNumber}
      onPageSizeChange={(changePageSize: any) => {
        setPageSizeValue(changePageSize);
        setPageNumber(0);
      }}
      className="-highlight"
    />
  );
};

const useStyles = makeStyles(theme => ({
  buttonDetail: {
    display: "inline-block",
    float: "right",
    color: theme.palette.primary.main,
    paddingLeft: 0,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonUpdateCredentials: {
    display: "inline-block",
    color: theme.palette.primary.main,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  fastTrackLogoViewDetails: {
    marginRight: 10,
  },
}));
