import { useMutation, useQuery } from "@apollo/react-hooks";
import { Query } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Tile from "../components/Tile/LenderSubmissionsTile";
import TaskForm from "./TaskForm";
import { vendorContactRoleHumanReadable } from "../utils";
import _ from "lodash";
import { Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import TableSearch from "./Table/TableSearch";
import { Assignment, CheckCircle, Close as CloseIcon, FlagRounded } from "@material-ui/icons";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
} from "@material-ui/core";
import Table from "./Table";
import TableHeader from "./Table/TableHeader";
import TableSelect from "./Table/TableSelect";
import { theme } from "../theme";
import { taskStatusMap } from "../constants/tasks";

const UPSERT_TASK = gql`
  mutation($input: UpsertTaskInput!) {
    upsertTask(input: $input)
  }
`;

const DEACTIVATE_TASK = gql`
  mutation($taskId: ID!) {
    deactivateTask(taskId: $taskId)
  }
`;

const UPDATE_TASK = gql`
  mutation($taskId: ID!, $input: UpdateTaskInput!) {
    updateTask(taskId: $taskId, input: $input)
  }
`;

const SEND_REMINDER_TASK = gql`
  mutation($input: SendTaskReminderInput!) {
    sendTaskReminder(input: $input)
  }
`;

const styles = theme => ({
  root: {
    "& .MuiInput-underline:after": {
      borderColor: theme.palette.primary.main,
    },
    marginBottom: "16px",
  },
  right: {
    textAlign: "right",
  },
  addButtonStyles: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: "300",
    fontSize: "12px",
    boxShadow: "0px 2px 2px rgba(55, 133, 244, 0.25)",
    borderRadius: "3px",
    width: "120px",
  },
  greenButton: {
    backgroundColor: "#4caf50",
    color: "#fff",
    marginTop: "20px",
    width: "100%",
  },
  noDataStyles: {
    display: "block",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.8)",
    transition: "all 0.3s ease",
    zIndex: "1",
    pointerEvents: "none",
    padding: "20px",
    color: "rgba(0, 0, 0, 0.5)",
  },
  modalStyle: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    position: "absolute",
    minWidth: "400px",
  },
  closeButton: {
    position: "absolute",
    right: "5px",
    top: "35px",
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    top: "3px",
  },
  statusStyle: {
    width: "100px",
    marginLeft: "10px",
    padding: "5px 25px",
    borderRadius: "10px",
    textTransform: "capitalize",
    color: "#fff",
  },
  saveBtn: {
    marginTop: "25px",
  },
  margin: {
    row: {
      height: "20px",
    },
  },
  surface: {
    position: "absolute",
    minWidth: 700,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 700,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  previewDoc: {
    maxWidth: "60%",
  },
  priorityLabel: {
    display: "inline-block",
    margin: "10px 0 0 0",
  },
  smallIcon: {
    fontSize: "20px",
    margin: "15px",
  },
});

const priorityColors = {
  urgent: "#bb3354",
  high: "#f44336",
  medium: "#ff9800",
  low: "#2196f3",
  in_progress: "#808080",
  closed: "#808080",
  open: "#ffd180",
  specify: "#c4c4c4",
  add: "#3bc173",
  complete: "#3bc173",
  requested: "#7a7a7a",
  cancel: "#dd5040",
};

const useStyles = makeStyles(styles);
const useUiStyles = makeStyles(styles);

export default function TaskList(props) {
  const {
    vo,
    account,
    queryTasks,
    queryTasksOptions,
    tasksQueryKey,
    addTaskFlag,
    userProfile,
    tileTitle,
    refetchDocs,
    relatedUsers,
    creditSubId,
    taskOnClickAction,
    onTasksLoaded,
    conversations,
    initCollapsed,
    classNameHeader,
    classNameButton,
    documentsPortalConfiguration,
    voTasks,
    lenderProfileDynamicsId,
    documents,
  } = props;

  const defaultStatusOptions = [
    { value: "open", text: "Open" },
    { value: "complete", text: "Complete" },
    { value: "in_progress", text: "In Progress" },
    { value: "cancel", text: "Cancel" },
    { value: "requested", text: "Requested" },
  ];

  const defaultPriorityOptions = [
    { value: "high", text: "High" },
    { value: "medium", text: "Medium" },
    { value: "low", text: "Low" },
  ];

  const defaultOptions = {
    createdBy: [],
    assignedTo: [],
    status: defaultStatusOptions,
    priority: defaultPriorityOptions,
  };

  const defaultFilters = [
    {
      header: "Created By",
      value: [""],
    },
    {
      header: "Assigned To",
      value: [""],
    },
    {
      header: "Status",
      value: [""],
    },
    {
      header: "Priority",
      value: [""],
    },
  ];

  const classes = useStyles();
  const uiClasses = useUiStyles();
  const [openSendReminderPopup, setOpenSendReminderPopup] = useState(false);
  const [emailAddressReminder, setEmailAddressReminder] = useState("");
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const statusRef = useRef({ prevStatus: undefined, currentSelect: "" });
  const priorityRef = useRef({ prevRole: undefined, currentSelect: "" });
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [pageSizeValue, setPageSizeValue] = useState(10);
  const [tasksData, setTasksData] = useState(undefined);

  const { loading, error, data: tasksQueryData, refetch } = useQuery(queryTasks, queryTasksOptions);

  useEffect(() => {
    if (props.taskId) {
      setOpenTaskModal(true);
      setTaskId(props.taskId);
    }
  }, [props.taskId]);

  useEffect(() => {
    if (voTasks) {
      setTasksData(voTasks);
    } else {
      setTasksData(tasksQueryData);
    }
  }, [voTasks, tasksQueryData]);

  const defaultEditTaskModal = {
    isOpen: false,
    taskId: null,
    value: null,
  };

  const [columnsFilters, setColumnsFilters] = useState(defaultFilters);

  const [editTaskPriorityModal, setEditTaskPriorityModal] = useState(defaultEditTaskModal);
  const [editTaskStatusModal, setEditTaskStatusModal] = useState(defaultEditTaskModal);
  const [isTaskUpdating, setIsTaskUpdating] = useState(false);

  const [upsertTask] = useMutation(UPSERT_TASK, {
    context: { authRequired: true },
  });

  const [updateTask] = useMutation(UPDATE_TASK, {
    context: { authRequired: true },
  });

  const [deactivateTaskRequest] = useMutation(DEACTIVATE_TASK, {
    context: { authRequired: true },
  });

  const [sendReminderTask] = useMutation(SEND_REMINDER_TASK, {
    context: { authRequired: true },
  });

  const handleCloseTaskModal = () => {
    setOpenTaskModal(false);
    setTaskId(null);
  };

  const handleAddTask = async (payload, refetch) => {
    try {
      await upsertTask({
        variables: {
          input: payload,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveTask = async (payload, refetch) => {
    try {
      await updateTask({
        variables: payload,
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteTask = async (id, refetch) => {
    // deactivateTaskRequest
    try {
      await deactivateTaskRequest({
        variables: {
          taskId: id,
        },
      });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const sendReminder = async (taskId, refetch) => {
    try {
      const result = await sendReminderTask({
        variables: {
          input: { taskId },
        },
      });

      setEmailAddressReminder(result.data.sendTaskReminder);
      await refetch();
      setOpenSendReminderPopup(true);
      setTimeout(() => {
        setOpenSendReminderPopup(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  const handlSendReminderPopup = () => {
    setOpenSendReminderPopup(false);
  };

  const handleOpenVo = task => {
    window.location.href = `/vo/${task.vendorOpportunityId}#taskList`;
  };

  const filterBySelectFields = taskList => {
    const { currentSelect: currentStatusSelect } = statusRef.current;
    const { currentSelect: currentPrioritySelect } = priorityRef.current;
    let newData = { ...taskList };

    if (currentStatusSelect?.length !== 0) {
      newData.vendorOpportunityTask = newData.vendorOpportunityTask.filter(el => el.status === currentStatusSelect);
    }

    if (currentPrioritySelect?.length !== 0) {
      newData.vendorOpportunityTask = newData.vendorOpportunityTask.filter(el => el.priority === currentPrioritySelect);
    }

    return newData;
  };

  const filterTasks = taskListData => {
    const { currentSearch } = searchRef.current;

    taskListData = filterBySelectFields(taskListData);

    if (currentSearch.length === 0) {
      return taskListData;
    }

    const lowerCaseSearch = currentSearch.toLowerCase().trim();
    taskListData.vendorOpportunityTask = taskListData.vendorOpportunityTask.filter(el => {
      if (
        typeof el.createdDateTime === "string" ||
        typeof el.createdBy === "string" ||
        typeof el.assignedToUser === "string" ||
        typeof el.regarding === "string"
      ) {
        return (
          moment(el.createdDateTime)
            .format("ll")
            .toLowerCase()
            .includes(lowerCaseSearch) ||
          (el.createdBy || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.assignedToUser || "").toLowerCase().includes(lowerCaseSearch) ||
          (el.regarding || "").toLowerCase().includes(lowerCaseSearch)
        );
      }

      return false;
    });

    return { ...taskListData };
  };

  const isAdmin = userProfile =>
    userProfile.vendorContactRole == vendorContactRoleHumanReadable.credit_mgr ||
    userProfile.vendorContactRole == vendorContactRoleHumanReadable.sales_mgr;

  const processTasks = tasksData => {
    let tasks = [];

    if (!!tasksData && !!tasksData[tasksQueryKey]) {
      let loadedTasks = tasksData[tasksQueryKey];
      onTasksLoaded({ loading: false, tasks: loadedTasks });
      loadedTasks = loadedTasks.map(task => {
        task.createdDateMilliseconds = new Date(task.createdDateTime.replace(/\..+/, "")).getTime();
        return task;
      });
      const userLoggedIsAdmin = isAdmin(userProfile);

      if (tasksQueryKey == "consolidatedTask" || tasksQueryKey == "vendorOpportunityTask" || userLoggedIsAdmin) {
        tasks = [...loadedTasks];
      } else {
        const filterTasks = _.filter(loadedTasks, task => {
          return task.assignedToUserProfileId == userProfile.id || task.assignedByUserProfileId == userProfile.id;
        });
        tasks = [...filterTasks];
      }
    }

    return tasks;
  };

  const onUpdateTaskPriority = async () => {
    setIsTaskUpdating(true);
    const variables = {
      taskId: editTaskPriorityModal?.task?.original?.taskId,
      input: {
        priority: editTaskPriorityModal?.value,
      },
    };
    await updateTask({
      variables,
    });
    await refetch();
    setIsTaskUpdating(false);
    setEditTaskPriorityModal(defaultEditTaskModal);
  };

  const onUpdateTaskStatus = async () => {
    setIsTaskUpdating(true);
    const variables = {
      taskId: editTaskStatusModal?.task?.original?.taskId,
      input: {
        status: editTaskStatusModal?.value,
      },
    };
    await updateTask({
      variables,
    });
    await refetch();
    setIsTaskUpdating(false);
    setEditTaskStatusModal(defaultEditTaskModal);
  };

  const EditTaskPriorityModal = () => (
    <Modal
      open={editTaskPriorityModal?.isOpen}
      onClose={() => setEditTaskPriorityModal({ defaultEditTaskModal })}
      aria-labelledby="Task Form"
      aria-describedby="Input"
    >
      <div style={styles.modalStyle}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setEditTaskPriorityModal({ defaultEditTaskModal })}
        >
          <CloseIcon />
        </IconButton>
        <Tile title={"Update priority"} maxHeightLimitByScreen={false} titleIcon={<Assignment />}>
          <p>{`Created By: ${editTaskPriorityModal?.task?.original?.createdBy}`}</p>
          <p>{`Assigned to: ${editTaskPriorityModal?.task?.original?.assignedToUser}`}</p>
          <p>{`Created on: ${
            editTaskPriorityModal?.task?.original?.createdDateTime
              ? moment(editTaskPriorityModal?.task?.original?.createdDateTime).format("ll")
              : ""
          }`}</p>
          <p>
            Status:
            <span
              style={{
                backgroundColor: priorityColors?.[editTaskPriorityModal?.task?.original?.status],
                ...styles.statusStyle,
              }}
            >
              {editTaskPriorityModal?.task?.original?.status.replace("_", " ")}
            </span>
          </p>
          <FormControl fullWidth className={uiClasses.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
              Priority:
            </InputLabel>
            <Select
              disabled={taskId ? true : false}
              classes={{ select: uiClasses.select }}
              value={editTaskPriorityModal?.value}
              onChange={e => setEditTaskPriorityModal({ ...editTaskPriorityModal, value: e.target.value })}
              inputProps={{
                name: "priority",
                id: "priority",
              }}
            >
              <MenuItem value={"high"}>High</MenuItem>
              <MenuItem value={"medium"}>Medium</MenuItem>
              <MenuItem value={"low"}>Low</MenuItem>
            </Select>
          </FormControl>
          <Button color="primary" style={styles.greenButton} onClick={onUpdateTaskPriority} disabled={isTaskUpdating}>
            {`${isTaskUpdating ? "Updating..." : "Update"}`}
          </Button>
        </Tile>
      </div>
    </Modal>
  );

  const EditTaskStatusModal = () => (
    <Modal
      open={editTaskStatusModal?.isOpen}
      onClose={() => setEditTaskStatusModal({ defaultEditTaskModal })}
      aria-labelledby="Task Form"
      aria-describedby="Input"
    >
      <div style={styles.modalStyle}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setEditTaskStatusModal({ defaultEditTaskModal })}
        >
          <CloseIcon />
        </IconButton>
        <Tile title={"Update status"} maxHeightLimitByScreen={false} titleIcon={<Assignment />}>
          <p>{`Created By: ${editTaskStatusModal?.task?.original?.createdBy}`}</p>
          <p>{`Assigned to: ${editTaskStatusModal?.task?.original?.assignedToUser}`}</p>
          <p>{`Created on: ${
            editTaskStatusModal?.task?.original?.createdDateTime
              ? moment(editTaskStatusModal?.task?.original?.createdDateTime).format("ll")
              : ""
          }`}</p>
          <p>
            Priority:
            <span
              style={{
                backgroundColor: priorityColors?.[editTaskStatusModal?.task?.original?.priority],
                ...styles.statusStyle,
              }}
            >
              {editTaskStatusModal?.task?.original?.priority.replace("_", " ")}
            </span>
          </p>
          <FormControl fullWidth className={uiClasses.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={uiClasses.selectLabel}>
              Status:
            </InputLabel>
            <Select
              disabled={taskId ? true : false}
              classes={{ select: uiClasses.select }}
              value={editTaskStatusModal?.value}
              onChange={e => setEditTaskStatusModal({ ...editTaskStatusModal, value: e.target.value })}
              inputProps={{
                name: "status",
                id: "status",
              }}
            >
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="cancel">Cancel</MenuItem>
              <MenuItem value="requested">Requested</MenuItem>
            </Select>
          </FormControl>
          <Button color={"primary"} style={styles.greenButton} onClick={onUpdateTaskStatus} disabled={isTaskUpdating}>
            {`${isTaskUpdating ? "Updating..." : "Update"}`}
          </Button>
        </Tile>
      </div>
    </Modal>
  );

  const convertValueToText = str => {
    let selectText = str;
    const convertedOptions = [...defaultStatusOptions, ...defaultPriorityOptions];
    convertedOptions.forEach(option => {
      if (selectText.indexOf(option.value) !== -1) selectText = selectText.replace(option.value, option.text);
    });
    return selectText;
  };

  const getColumnSelectValue = filterHeader => {
    try {
      const foundedEl = columnsFilters.find(col => col.header === filterHeader);

      if (foundedEl) {
        return _.get(foundedEl, "value", []);
      }
    } catch (error) {
      return [""];
    }
  };

  const isFilterChecked = (filterHeader, includesValue) => {
    try {
      const foundedEl = columnsFilters.find(col => col.header === filterHeader);
      return _.get(foundedEl, "value", null) && _.get(foundedEl, "value").includes(includesValue);
    } catch (error) {
      return false;
    }
  };

  function createFiltersColumns(sel, header) {
    const filter = ({ filter, onChange }) => {
      return sel.length > 0 ? (
        <Select
          multiple
          className={classes.filterSelect}
          renderValue={() =>
            columnsFilters.find(col => col.header === header).value[0] !== ""
              ? convertValueToText(columnsFilters.find(col => col.header === header).value.join(", "))
              : "Show All"
          }
          onChange={e => {
            let value =
              e.target.value.length > 1 && e.target.value[0] === ""
                ? e.target.value.filter(val => val !== "")
                : e.target.value;
            if (value[value.length - 1] === "") {
              value = value.filter(val => val === "");
            }
            onChange(value);
            setColumnsFilters(prevArray =>
              prevArray.map(col =>
                col.header === header
                  ? {
                      ...col,
                      value: value,
                    }
                  : col
              )
            );
          }}
          value={getColumnSelectValue(header)}
          style={{ width: "100%" }}
          MenuProps={{
            getContentAnchorEl: null,
            variant: "menu",
          }}
        >
          <MenuItem value="">
            <Checkbox checked={isFilterChecked(header, "")} style={{ color: theme.palette.primary.main }} />
            <ListItemText primary="Show All" />
          </MenuItem>
          {sel.map((item, index) => {
            return (
              <MenuItem key={"stage-" + index} value={item.value} name={item.text}>
                <Checkbox checked={isFilterChecked(header, item.value)} style={{ color: theme.palette.primary.main }} />
                <ListItemText primary={item.text} />
              </MenuItem>
            );
          })}
        </Select>
      ) : null;
    };
    return filter;
  }

  return (
    <div className={classes.root}>
      {!!account && (
        <Query
          context={queryTasksOptions.context}
          variables={queryTasksOptions.variables}
          query={queryTasks}
          fetchPolicy="no-cache"
        >
          {({ loading, error, data: tasksData, refetch }) => {
            if (loading) return <LinearProgress />;
            if (error) return <h3>{String(error)}</h3>;

            let options = defaultOptions;

            const tasks = processTasks(filterTasks(tasksData));
            const createdByOptions = [];
            const assignedToOptions = [];

            if (tasksData && tasksData.consolidatedTask) {
              tasksData.consolidatedTask.map(task => {
                if (!createdByOptions.find(option => task.createdBy === option.value) && task.createdBy) {
                  createdByOptions.push({ value: task?.createdBy, text: task?.createdBy });
                }
                if (!assignedToOptions.find(option => task.assignedToUser === option.value) && task.assignedToUser) {
                  assignedToOptions.push({ value: task?.assignedToUser, text: task?.assignedToUser });
                }
              });
            } else {
              tasksData = {
                consolidatedTask: [],
              };
            }

            options = { ...options, createdBy: createdByOptions, assignedTo: assignedToOptions };

            return (
              <Grid container id="taskList">
                <Grid item xs={12}>
                  <Tile
                    title={!tileTitle ? "Tasks" : tileTitle}
                    useCollapsing={true}
                    initCollapsed={initCollapsed}
                    classNameHeader={classNameHeader}
                  >
                    <Grid container>
                      <Dialog
                        open={openSendReminderPopup}
                        onClose={handlSendReminderPopup}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ backgroundColor: "#ffffff" }}
                      >
                        <DialogTitle id="alert-dialog-title">
                          <u>{"Success:"}</u>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            A reminder has been sent to{" "}
                            <a href={`mailto:${emailAddressReminder}`}>{emailAddressReminder}</a>
                          </DialogContentText>
                        </DialogContent>
                      </Dialog>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableHeader
                          filterComps={
                            <>
                              <TableSearch
                                setPageNumber={setPageNumber}
                                setSavedListSettings={setSavedListSettings}
                                searchRef={searchRef}
                                savedListSettings={savedListSettings}
                              />
                              <TableSelect
                                setPageNumber={setPageNumber}
                                setSavedListSettings={setSavedListSettings}
                                selectRef={statusRef}
                                savedListSettings={savedListSettings}
                                id="status"
                                label="Status"
                                options={options.status}
                                width={200}
                              />
                              <TableSelect
                                setPageNumber={setPageNumber}
                                setSavedListSettings={setSavedListSettings}
                                selectRef={priorityRef}
                                savedListSettings={savedListSettings}
                                id="priority"
                                label="Priority"
                                options={options.priority}
                                width={200}
                              />
                            </>
                          }
                        >
                          {addTaskFlag ? (
                            <Button
                              className={classNameButton || classes.addButtonStyles}
                              onClick={() => {
                                setTaskId(null);
                                setOpenTaskModal(true);
                              }}
                            >
                              ADD TASK
                            </Button>
                          ) : (
                            <></>
                          )}
                        </TableHeader>
                        {!!tasks && tasks ? (
                          <Table
                            NoDataComponent={props => {
                              return (
                                <div {...props} className={classes.noDataStyles}>
                                  No rows found
                                </div>
                              );
                            }}
                            color="primary"
                            data={tasks.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime))}
                            getTrProps={(state, rowInfo, column, instance) => {
                              return {
                                style: { cursor: "pointer" },
                                onClick: e => {
                                  e.preventDefault();
                                  setTaskId(rowInfo.original.taskId);
                                  setOpenTaskModal(true);
                                },
                              };
                            }}
                            columns={[
                              {
                                Header: "Created On",
                                accessor: "createdDateMilliseconds",
                                resizable: false,
                                Cell: props => <div>{props.value ? moment(props.value).format("ll") : ""}</div>,
                              },
                              // {
                              //   Header: "Created By",
                              //   accessor: "createdBy",
                              //   Cell: props => {
                              //     return <div>{!props.value ? "Trnsact" : props.value}</div>;
                              //   },
                              // },
                              {
                                Header: "Assigned To",
                                accessor: "assignedToUser",
                                Cell: props => {
                                  const row = props.original;
                                  const applicantTask = row.assignedToApplicant;
                                  if (applicantTask) {
                                    return <div>{!props.value ? "Applicant" : props.value}</div>;
                                  } else {
                                    return <div>{!props.value ? "Dealer" : props.value}</div>;
                                  }
                                },
                              },
                              {
                                Header: "Regarding",
                                accessor: "regarding",
                              },
                              {
                                Header: "Completed On",
                                accessor: "completedDateTime",
                                resizable: false,
                                Cell: props => <div>{props.value ? moment(props.value).format("ll") : ""}</div>,
                              },
                              {
                                Header: "Status",
                                accessor: "status",
                                Cell: props => {
                                  return (
                                    <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                                      {props.value === "complete" && <CheckCircle style={{ color: "#4caf50" }} />}
                                      {taskStatusMap[props.value]}
                                    </div>
                                  );
                                },
                              },
                              {
                                Header: "Priority",
                                Cell: props => {
                                  let color = priorityColors[props.row.priority];

                                  return (
                                    <div style={{ display: "flex", gap: 4 }}>
                                      <FlagRounded style={{ color }} />
                                      {props.value.charAt(0).toUpperCase() + props.value.slice(1)}
                                    </div>
                                  );
                                },
                                accessor: "priority",
                              },
                            ]}
                            onPageChange={changePage => {
                              setPageNumber(changePage);
                            }}
                            onPageSizeChange={changePageSize => {
                              setPageSizeValue(changePageSize);
                              setPageNumber(0);
                            }}
                            pageSize={pageSizeValue}
                            page={pageNumber}
                            defaultPageSize={savedListSettings.pageSize}
                            showPaginationTop={false}
                            showPaginationBottom={true}
                            customCellClasses={[classes.right]}
                            customClassesForCells={[5]}
                            className="-striped -highlight"
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                    <Modal
                      open={openTaskModal}
                      onClose={handleCloseTaskModal}
                      aria-labelledby="Task Form"
                      aria-describedby="Input"
                      disableEnforceFocus={true}
                    >
                      <div>
                        <TaskForm
                          vo={vo}
                          handleClose={handleCloseTaskModal}
                          handleAddTask={payload => handleAddTask(payload, refetch)}
                          handleSaveTask={payload => handleSaveTask(payload, refetch)}
                          taskId={taskId}
                          tasksData={tasks}
                          creditSubId={creditSubId}
                          refetch={refetch}
                          refetchDocs={refetchDocs}
                          relatedUsers={relatedUsers}
                          conversations={(conversations || []).filter(conv => conv.taskId === taskId)}
                          documentsPortalConfiguration={documentsPortalConfiguration}
                          lenderProfileDynamicsId={lenderProfileDynamicsId}
                          documents={documents}
                        />
                      </div>
                    </Modal>
                  </Tile>
                </Grid>
                <EditTaskPriorityModal />
                <EditTaskStatusModal />
              </Grid>
            );
          }}
        </Query>
      )}
    </div>
  );
}
