import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Button from "../../components/CustomButtons/Button";
import { Grid, TextField, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import MuiAlert from "@material-ui/lab/Alert";
import { Auth } from "aws-amplify";
import Tile from "../../components/Tile/Tile";
import {
  defaultColorSchema,
  formatPhoneNumber,
  getHeadersFromAuth,
  setFieldValue,
  validateValue,
  vendorContactRole,
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { SET_VP } from "../../redux/types";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/react-hooks";
import formatString from "format-string-by-pattern";
import { SET_ACCOUNT } from "../../redux/types";
import PersonalLinkAndQRCode from "../../components/PersonalLinkAndQRCode";
import ColorSchemaPicker from "../../components/ColorSchemaPicker";
import { adminRoles } from "../../pages/Prequal/constants";
import ColorThief from "colorthief";
import rgbHex from "rgb-hex";

import _ from "lodash";

const config = require("../../config.js");

const complementaryColor = require("complementary-color");
export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  companyProfile: {
    paddingLeft: "10px!important",
    marginTop: "50px",
  },
  defaultIcon: {
    position: "relative",
    right: "17px",
    maxWidth: "15px",
  },
  defaultContactSelector: {
    paddingBottom: "16px",
  },
  companyInfo: {
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "25px",
    marginBottom: "50px",
    fontSize: "12px",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  companyProfilePhotoTile: {
    boxShadow: "none",
  },
  companyProfilePhotoContainer: {
    margin: "10px 0 0",
  },
  companyProfilePhoto: {
    padding: 0,
  },
  companyProfileButton: {
    backgroundColor: "#3E83E6",
    borderRadius: "7px",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
  },
  companyProfileHeader: {
    fontSize: "26px",
    fontWeight: "300",
    marginBottom: "15px",
  },
  companyProfileCancelButton: {
    backgroundColor: "#999",
    borderRadius: "7px",
    fontSize: "16px",
    fontWeight: "bold",
    display: "flex",
    padding: "12px 20px",
    minWidth: "126px",
    marginLeft: "20px",
  },
  openFormLabel: {
    fontSize: 12,
    marginTop: 10,
    fontWeight: "700",
    color: "#3c4858 !important",
    marginBottom: 10,
  },
  linkButton: {
    padding: "10px 15px",
    marginRight: 20,
    width: "200px",
  },
  companyLink: {
    paddingLeft: "8px !important",
  },
  tileBody: {
    padding: "0",
  },
  saveBtn: {
    marginTop: "25px",
  },
  primaryContactSaveBtn: {
    margin: "0",
  },
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const UPDATE_COMPANY_PROFILE_VP = `
    mutation($entityId: String!, $fieldsObj: UpdateVendorProfileInput!, $file: Upload) {
      updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj, file: $file) {
        id
      }
    }
  `;

const UPDATE_COMPANY_PROFILE_LP = `
mutation($entityId: String!, $fieldsObj: UpdateLenderProfileInput!, $file: Upload) {
  updateLenderProfile(entityId: $entityId, fieldsObj: $fieldsObj, file: $file) {
    id
  }
}
`;

const GET_REP_LIST = gql`
  query {
    salesRepsForAdmin {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
      lastLogin
      invitationDatetime
      status
    }
  }
`;

const M_UPDATE_VP = gql`
  mutation($entityId: String!, $fieldsObj: UpdateVendorProfileInput!) {
    updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

const M_UPDATE_LP = gql`
  mutation($entityId: String!, $fieldsObj: UpdateLenderProfileInput!) {
    updateLenderProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

const UPDATE_COMPANY_INFO = gql`
  mutation($dynamicsId: ID, $fieldsObj: UpdateAccountFields) {
    updateAccount(dynamicsId: $dynamicsId, fieldsObj: $fieldsObj)
  }
`;

const UPDATE_COMPANY_INFO_CRM = gql`
  mutation($entityId: ID!, $fieldsObj: UpdateAccountCRMFields) {
    updateAccountCRM(entityId: $entityId, fieldsObj: $fieldsObj)
  }
`;

function CompanyProfile() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);
  const [updateProfileCRMSuccess, setUpdateProfileCRMSuccess] = useState(false);
  const [disableUpdate, setDisabledUpdate] = useState(false);
  const [dominantColor, setDominantColor] = useState("");
  const [secondaryColorHex, setSecondaryColorHex] = useState("");
  const [dominantColorComplement, setDominantColorComplement] = useState("");

  const [onLoad, setOnLoad] = useState(false);
  const dispatch = useDispatch();
  const vendorProfile = useSelector(state => state.vp);
  const lenderProfile = useSelector(state => state.lenderProfile);
  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const vpStyles = _.merge({}, defaultColorSchema, vendorProfile.styles);
  const [styles, setStyles] = useState(vpStyles);

  const [fields, setFields] = useState({
    id: {
      value: null,
    },
    logo: {
      value: null,
    },
    file: {
      value: null,
      validations: [{ type: "required" }],
    },
    defaultOcaContact: {
      value: null,
    },
    ocaLink: {
      value: null,
    },
  });

  const setValue = (value, fieldName) => {
    return setFieldValue(value, fieldName, fields, setFields);
  };
  const [formValid, setFormValid] = useState(false);
  const [companyProfile, setCompanyProfile] = useState({});
  const [userContactRole, setUserContactRole] = useState(null);
  const [companyReps, setCompanyReps] = useState("");
  const [domainValue, setDomainValue] = useState(account.domain ?? "");
  const [userLogo, setUserLogo] = useState(fields.logo.value);
  const [phoneValue, setPhoneValue] = useState(
    account.phone ? formatString("(999) 999-9999", account.phone.replace(/[^\d-() ]/, "")) : ""
  );

  const adminRolesToUpdateLogoAndOCAColors = [adminRoles.super, adminRoles.singleAccountOnly];
  const isUserAbleToUpdateLogoAndOCAColors = _.includes(adminRolesToUpdateLogoAndOCAColors, userProfile.adminRole);

  function checkFormValidation() {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  }

  const handleSavingVPStyles = async () => {
    console.log("Executed handleSavingVPStyles");
    await updateVP({
      variables: {
        entityId: vendorProfile.id,
        fieldsObj: {
          styles,
        },
      },
    });
  };
  const _executeGraphQLRequest = async formData => {
    const authHeaders = await getHeadersFromAuth(Auth);
    return fetch(config.DCR_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        ...authHeaders,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        response = response || {};
        if (response.errors && response.errors.length) {
          const errors = response.errors.map(e => e.message).join("; ");
          console.error("Error saving profile:", errors);
          setErrorMessage("Error saving profile!");
          setErrorOpen(true);
        } else {
          setSnackbarOpen(true);

          // if (response && response.data && response.data.updateVendorProfile) {
          //   fields.logo.value = response.data.updateVendorProfile.logo;
          // }

          // vendorProfile.logo = fields.logo.value;
          // dispatch({ type: SET_VP });
          // console.log("Window Will Reload now");
          window.location.reload();
        }
      })
      .catch(err => {
        const ret = {};
        ret[FORM_ERROR] = "Error saving profile!";
      });
  };
  const handleSaveLenderProfileLogo = async () => {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: UPDATE_COMPANY_PROFILE_LP,
        variables: {
          entityId: lenderProfile.id,
          fieldsObj: {
            logo: fields.logo.value,
          },
        },
      })
    );

    formData.append(
      "map",
      JSON.stringify({
        "0": ["variables.file"],
      })
    );
    formData.append("0", fields.file.value);

    return _executeGraphQLRequest(formData);
  };

  const handleSaveVendorProfileLogo = async () => {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: UPDATE_COMPANY_PROFILE_VP,
        variables: {
          entityId: fields.id.value,
          fieldsObj: {
            logo: fields.logo.value,
            logoPrimaryColor: dominantColor,
            logoComplementaryColor: dominantColorComplement,
          },
        },
      })
    );

    formData.append(
      "map",
      JSON.stringify({
        "0": ["variables.file"],
      })
    );
    formData.append("0", fields.file.value);

    return _executeGraphQLRequest(formData);
  };

  const submitFields = values => {
    const accountType = account.accountType;

    if (disableUpdate) {
      setErrorOpen(true);
      setErrorMessage("Only Admin Can Change Profile Information");
      return;
    }

    const logoFieldValue = _.get(fields, "logo.value", false);
    const hasLogoDataToUpload = logoFieldValue && logoFieldValue.startsWith("data:image");

    //Saving styles...

    handleSavingVPStyles();

    if (!hasLogoDataToUpload) {
      console.log("No logo changed, skipping logo upload");
      return;
    }
    //Logo Update
    if (accountType === "Lender") {
      handleSaveLenderProfileLogo();
    }

    handleSaveVendorProfileLogo();
  };

  useEffect(() => {
    if (vendorProfile) {
      setCompanyProfile(vendorProfile);
      setUserContactRole(userProfile.vendorContactRole);

      setFields({
        id: {
          value: vendorProfile.id,
        },
        logo: {
          value: vendorProfile.logo ? vendorProfile.logo : "",
          validationStatus: "",
        },
        defaultOcaContact: {
          value: vendorProfile.defaultOcaContact ? vendorProfile.defaultOcaContact : "",
          validationStatus: "",
        },
        file: {
          value: null,
          validations: [{ type: "required" }],
        },
        ocaLink: {
          value: account.dynamicsAccountId
            ? config.REACT_APP_OCA_BASE_URL + "/oca/?vendorGUID=" + account.dynamicsAccountId
            : "",
        },
      });
    } else {
      if (lenderProfile) {
        setCompanyProfile(lenderProfile);
        setUserContactRole(userProfile.lenderContactRole);
        setFields({
          id: {
            value: lenderProfile.id,
          },
          logo: {
            value: lenderProfile.logo ? lenderProfile.logo : "",
            validationStatus: "",
          },
          defaultOcaContact: {
            value: "",
            validationStatus: "",
          },
          file: {
            value: null,
            validations: [{ type: "required" }],
          },
          ocaLink: {
            value: "",
          },
        });
      }
    }
  }, [account, userProfile, vendorProfile]);

  useEffect(() => {
    if (userProfile) {
      if (
        userProfile.vendorContactRole == vendorContactRole.sales_rep ||
        userProfile.vendorContactRole == vendorContactRole.sales_mgr
      ) {
        setDisabledUpdate(true);
      }
      setUserContactRole(userProfile.vendorContactRole);
    }
  }, [userProfile]);

  useEffect(() => {
    if (fields?.file?.value) {
      const reader = new FileReader();
      reader.onload = e => {
        const avatar = document.getElementById("avatar");
        avatar.src = e.target.result;
        setValue(e.target.result, "logo");
        companyProfile.logo = e.target.result;
        checkFormValidation();
      };
      reader.readAsDataURL(fields.file.value);
      checkFormValidation();
    }
  }, [fields.file.value]);

  const { loading: loadingReps, error: errorFetchReps, data: repsData } = useQuery(GET_REP_LIST, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (repsData) {
      var reps;
      if (repsData.salesRepsForAdmin) {
        reps = repsData.salesRepsForAdmin.map(item => {
          try {
            item.value = item.dynamicsContactId;
            if (item.title == null) {
              item.label = item.fullName;
            } else {
              item.label = item.fullName + ", " + item.title;
            }
            return item;
          } catch (err) {
            console.error(err);
            return null;
          }
        });
        setCompanyReps(reps);
      } else {
        setCompanyReps([]);
      }
    }
  }, [repsData]);

  useEffect(() => {
    console.log(styles);
  }, [styles]);

  const [updateVP, { data, error, loading }] = useMutation(M_UPDATE_VP, {
    context: { authRequired: true },
  });

  const handleSavingContact = async () => {
    try {
      await updateVP({
        variables: {
          entityId: vendorProfile.id,
          fieldsObj: {
            defaultOcaContact: fields.defaultOcaContact.value,
          },
        },
      });
    } catch (e) {
      setErrorMessage("Error saving profile!");
      setErrorOpen(true);
    }
  };

  const classes = useStyles();

  const [updateCompanyInfo, { loading: companyInfoLoading }] = useMutation(UPDATE_COMPANY_INFO, {
    context: { authRequired: true },
    onCompleted() {
      dispatch({
        type: SET_ACCOUNT,
        payload: {
          ...account,
          phone: phoneValue,
        },
      });
      setUpdateProfileSuccess(true);
      setIsModalOpen(false);
    },
  });

  const [updateCompanyCRMInfo, { loading: companyCRMInfoLoading }] = useMutation(UPDATE_COMPANY_INFO_CRM, {
    context: { authRequired: true },
    onCompleted() {
      setUpdateProfileCRMSuccess(true);
      setIsModalOpen(false);
    },
  });
  function uploadFileChangedColor(event) {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = function(e) {
        const imgElement = document.createElement("img");
        imgElement.src = e.target.result;

        imgElement.onload = function() {
          //Color computation
          const colorThief = new ColorThief();

          const colors = colorThief.getPalette(imgElement, 2) || [(0, 0, 0)];
          const primaryColor = colors[0];
          const secondaryColor = colors[1];

          setDominantColor(`#${rgbHex(primaryColor[0], primaryColor[1], primaryColor[2])}`);
          setSecondaryColorHex(`#${rgbHex(secondaryColor[0], secondaryColor[1], secondaryColor[2])}`);
          //Background color is always White
          setDominantColorComplement(`#FFFFFF`);
        };
      };

      reader.readAsDataURL(file);
    }
  }
  function uploadButtonHandler() {
    const img = document.getElementById("uploadLogo");
    setValue(vendorProfile.id, "id");
    img.click();
  }

  function uploadFileChanged(event) {
    uploadFileChangedColor(event);
    setValue(event.target.files[0], "file");
    setOnLoad(true);
    setUserLogo(fields.logo.value);
  }

  function handleClose() {
    setSnackbarOpen(false);
    setErrorOpen(false);
  }

  const required = value => (value ? undefined : "Required");
  const isValidPhoneNumber = field => field && field.replace(/[^\d]/g, "").length < 10;

  const handleOnSubmit = async () => {
    console.log("Executed handle on submit");

    const phone = phoneValue.replace(/[\D]+/g, "");

    try {
      let updateCompanyInfoPayload = {
        variables: {
          dynamicsId: account.dynamicsAccountId,
          fieldsObj: {
            phone,
          },
        },
      };

      if (isSuperAdmin) {
        console.log("updateCompanyInfo");
        updateCompanyInfo({
          variables: {
            dynamicsId: account.dynamicsAccountId,
            fieldsObj: {
              domain: domainValue,
              phone,
            },
          },
        });
      } else {
        console.log("updateCompanyInfo for non super admin");
        updateCompanyInfo(updateCompanyInfoPayload);
      }

      updateCompanyCRMInfo({
        variables: {
          entityId: account.dynamicsAccountId,
          fieldsObj: {
            telephone1: phone,
          },
        },
      });

      submitFields();
    } catch (error) {
      console.error(error);
    }
  };

  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

  const canEdit =
    userProfile.vendorContactRole === vendorContactRole.credit_mgr ||
    userProfile.vendorContactRole === vendorContactRole.admin;

  const [isSuperAdmin] = useState(userProfile.adminRole === adminRoles.super);
  const handleCalculateOcaColors = e => {
    e.preventDefault();
    setStyles({ ...styles, color: dominantColor, secColor: secondaryColorHex });
  };
  return (
    <>
      <Form
        onSubmit={values => {
          submitFields(values);
        }}
        validate={checkFormValidation}
        initialValues={companyProfile}
        render={({ handleSubmit, submitting }) => {
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit().then(res => console.log(res));
              }}
            >
              <Grid container spacing={1} className={classes.companyProfile}>
                <Grid container justify="space-between" xs={12} className={classes.companyInfo}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <Typography variant={"h5"} className={classes.companyProfileHeader}>
                            Company Info
                          </Typography>
                        </Grid>
                        <Form
                          onSubmit={handleOnSubmit}
                          render={({ handleSubmit, form, submitting, values }) => (
                            <form onSubmit={handleSubmit}>
                              <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <Field name="companyName" validate={required} defaultValue={account.name}>
                                  {({ input, meta }) => (
                                    <TextField {...input} label="Company Name" error={!meta.valid} disabled />
                                  )}
                                </Field>
                                <Field name="companyAddress" validate={required} defaultValue={account.address}>
                                  {({ input, meta }) => (
                                    <TextField
                                      required
                                      label="Company Address"
                                      {...input}
                                      error={!meta.valid}
                                      disabled
                                    />
                                  )}
                                </Field>
                                <Field
                                  name="companyPhone"
                                  validate={composeValidators(required, isValidPhoneNumber)}
                                  defaultValue={account.phone}
                                >
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      label="Company Phone"
                                      error={!meta.valid}
                                      inputProps={{
                                        onChange: e => {
                                          const value = formatString(
                                            "(999) 999-9999",
                                            e.target.value.replace(/[^\d-() ]/, "")
                                          );
                                          setPhoneValue(value);
                                        },
                                        value: phoneValue,
                                      }}
                                      helperText={
                                        isValidPhoneNumber(values.companyPhone)
                                          ? "Please ensure phone number has been entered correctly."
                                          : null
                                      }
                                      disabled={!canEdit}
                                    />
                                  )}
                                </Field>
                                {isSuperAdmin ? (
                                  <Grid item lg={12} md={12} xs={12}>
                                    <TextField
                                      label="Domain"
                                      fullWidth={true}
                                      inputProps={{
                                        onChange: e => {
                                          setDomainValue(e.target.value);
                                        },
                                        value: domainValue,
                                      }}
                                      helperText={!domainValue ? "Enter a valid Domain, e.g: trucks.com" : null}
                                      disabled={!canEdit}
                                    />
                                  </Grid>
                                ) : null}
                                <Grid item xs={12}>
                                  <InputLabel>OCA Color Schema:</InputLabel>
                                  {isUserAbleToUpdateLogoAndOCAColors ? (
                                    <ColorSchemaPicker colorSchema={styles} setColorSchema={setStyles} />
                                  ) : (
                                    <span style={{ marginLeft: "10px" }}>Please contact your Admin to change</span>
                                  )}
                                </Grid>

                                <Grid item xs={12} className={classes.companyProfilePhotoContainer}>
                                  <Tile className={classes.companyProfilePhotoTile} classBody={classes.tileBody}>
                                    <input
                                      accept="image/*"
                                      id="uploadLogo"
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={uploadFileChanged}
                                    />
                                    <Grid container alignItems="flex-end">
                                      <Grid item xs={7}>
                                        <img
                                          id="avatar"
                                          alt="Company Logo"
                                          style={
                                            fields.logo.value
                                              ? {
                                                  maxHeight: "200px",
                                                  maxWidth: "100%",
                                                }
                                              : {
                                                  height: "250px",
                                                  width: "450px",
                                                }
                                          }
                                          src={
                                            !fields.logo.value
                                              ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                              : typeof fields.logo.value === "string"
                                              ? fields.logo.value.indexOf("data:image") === -1
                                                ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${fields.logo.value}`
                                                : fields.logo.value
                                              : fields.logo.value
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={5}>
                                        {dominantColor && dominantColorComplement ? (
                                          <Grid
                                            container
                                            style={{
                                              border: "1px solid #959595",
                                              borderRadius: "5px",
                                              padding: "10px",
                                            }}
                                          >
                                            <Grid item xs={12}>
                                              <Grid container>
                                                <Grid item xs={12}>
                                                  <h6>Logo Detected Colors</h6>
                                                </Grid>
                                              </Grid>
                                              <Grid container>
                                                <Grid item xs={6}>
                                                  <p style={{ fontSize: "12px" }}>Primary color</p>
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <div
                                                    style={{
                                                      padding: "10px",
                                                      backgroundColor: dominantColor,
                                                      border: "1px solid #959595",
                                                    }}
                                                  ></div>
                                                </Grid>
                                              </Grid>
                                              <Grid container>
                                                <Grid item xs={6}>
                                                  <p style={{ fontSize: "12px" }}>Secondary color</p>
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <div
                                                    style={{
                                                      padding: "10px",
                                                      backgroundColor: secondaryColorHex,
                                                      border: "1px solid #959595",
                                                    }}
                                                  ></div>
                                                </Grid>
                                              </Grid>
                                              <Grid container>
                                                <Button
                                                  color="primary"
                                                  disabled={disableUpdate}
                                                  variant="outlined"
                                                  onClick={handleCalculateOcaColors}
                                                >
                                                  Set OCA Color Schema
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        ) : null}

                                        {!isUserAbleToUpdateLogoAndOCAColors ? (
                                          <span>Please contact your Admin to update the Company's logo</span>
                                        ) : (
                                          <div
                                            style={{ marginTop: "30px", display: "flex", justifyContent: "flex-end" }}
                                          >
                                            {!onLoad ? (
                                              <Button
                                                className={classes.companyProfileButton}
                                                color="primary"
                                                disabled={disableUpdate}
                                                variant="outlined"
                                                onClick={uploadButtonHandler}
                                              >
                                                Upload&nbsp;
                                                <PhotoCameraIcon />
                                              </Button>
                                            ) : (
                                              <Grid container justify="flex-end">
                                                <Button
                                                  className={classes.companyProfileButton}
                                                  color="primary"
                                                  variant="contained"
                                                  type="submit"
                                                  onClick={() => {
                                                    setOnLoad(false);
                                                    handleSubmit();
                                                  }}
                                                >
                                                  {submitting ? "Submitting" : "Save"}
                                                </Button>
                                                <Button
                                                  className={classes.companyProfileCancelButton}
                                                  variant="contained"
                                                  onClick={() => {
                                                    setOnLoad(false);
                                                    setDominantColor("");
                                                    setDominantColorComplement("");
                                                    fields.logo.value = userLogo;
                                                  }}
                                                >
                                                  Cancel
                                                </Button>
                                              </Grid>
                                            )}
                                          </div>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Tile>
                                </Grid>
                              </div>
                              {canEdit && (
                                <Button
                                  size="small"
                                  color="primary"
                                  disabled={submitting || companyInfoLoading || companyCRMInfoLoading}
                                  onClick={() => {
                                    form.submit();
                                  }}
                                  className={classes.linkButton + " " + classes.saveBtn}
                                >
                                  Save
                                </Button>
                              )}
                            </form>
                          )}
                        />
                      </Grid>
                      {!_.isEmpty(vendorProfile) && (
                        <PersonalLinkAndQRCode
                          personalLink={fields.ocaLink.value}
                          linkHeader="Company Credit Application Link"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={8}
                  xl={8}
                  md={8}
                  sm={12}
                  xs={12}
                  className={classes.companyInfo}
                  style={{ padding: "20px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <Typography variant={"h5"} className={classes.companyProfileHeader} style={{ margin: "0" }}>
                        Primary Contact
                      </Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Name:</b> {account.primaryContactName}
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Phone:</b> {formatPhoneNumber(account.primaryContactPhone)}
                    </Grid>
                    <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                      <b>Email:</b> {account.primaryContactEmail}
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                    <Grid container justify="space-between" alignItems="flex-end">
                      <Grid item md={6}>
                        {companyReps.length !== 0 && userProfile.vendorContactRole !== vendorContactRole.sales_rep && (
                          <FormControl style={{ paddingTop: "16px" }} fullWidth>
                            <Grid container alignItems="center">
                              <InputLabel
                                style={{
                                  color: "#AAAAAA !important",
                                  fontWeight: "400",
                                  position: "unset",
                                  width: "max-content",
                                  letterSpacing: "unset",
                                  "& + $underline": {
                                    marginTop: "0px",
                                  },
                                }}
                                id="vendor-salesperson-id-label"
                              >
                                Default Contact
                              </InputLabel>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit">Default Contact</Typography>
                                    {
                                      "Select your Team Member who you'd like to have displayed on all default communications where a specific Sales Representative has yet to be assigned."
                                    }{" "}
                                  </React.Fragment>
                                }
                              >
                                <InfoIcon className={classes.defaultIcon} color="primary"></InfoIcon>
                              </HtmlTooltip>
                            </Grid>
                            <Select
                              id="defaultOcaContact"
                              label="Default Contact"
                              name="defaultOcaContact"
                              variant="standard"
                              autoWidth
                              required
                              inputProps={{
                                type: "text",
                                name: "defaultOcaContact",
                                value: fields.defaultOcaContact.value,
                                onChange: e => {
                                  setValue(e.target.value, "defaultOcaContact");
                                },
                              }}
                            >
                              {!!companyReps && companyReps.length > 1
                                ? companyReps.map(({ value, label }, i) => (
                                    <MenuItem value={value} key={i} label={label}>
                                      {label}
                                    </MenuItem>
                                  ))
                                : "No Reps Available"}
                            </Select>
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item md={6}>
                        <Grid container justify="flex-end" alignItems="flex-end">
                          {userProfile.vendorContactRole !== vendorContactRole.sales_rep ? (
                            <Button
                              className={classes.linkButton + " " + classes.primaryContactSaveBtn}
                              color="primary"
                              variant="contained"
                              onClick={() => handleSavingContact()}
                            >
                              Save
                            </Button>
                          ) : (
                            "Sales Reps are unable to update this field. Please contact your manager if this needs to be changed."
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Successfully uploaded your profile!
                </Alert>
              </Snackbar>
              <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                open={updateProfileSuccess}
                autoHideDuration={3000}
                onClose={() => setUpdateProfileSuccess(state => !state)}
              >
                <Alert onClose={handleClose} severity="success">
                  Successfully updated your profile.
                </Alert>
              </Snackbar>
              <Snackbar
                open={updateProfileCRMSuccess}
                autoHideDuration={3000}
                onClose={() => setUpdateProfileCRMSuccess(state => !state)}
              >
                <Alert onClose={handleClose} severity="success">
                  Successfully updated your CRM profile.
                </Alert>
              </Snackbar>
            </form>
          );
        }}
      />
    </>
  );
}

export default CompanyProfile;
