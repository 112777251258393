import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Grid, Snackbar, Switch, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { FormInput } from "components/form";
import { formatMinMaxValue } from "formatters";

const useStyles = makeStyles(theme => ({
  requireCreditAppRecertificationRoot: {
    marginTop: "25px",
  },
}));

interface Props {
  vendorProfile: any /* VendorProfile */;
  updateVPDebounced: Function;
}

interface VendorProfileEditableFields {
  requireCreditAppRecertification: boolean;
  daysToCreditAppRecertification: number;
}

export default function RequireCreditAppRecertificationToggle({ vendorProfile, updateVPDebounced }: Props) {
  const classes = useStyles();

  const [vendorProfileEditableFields, setVendorProfileEditableFields] = useState<VendorProfileEditableFields>({
    requireCreditAppRecertification: vendorProfile.requireCreditAppRecertification,
    daysToCreditAppRecertification: vendorProfile.daysToCreditAppRecertification,
  });

  const handleRequireCreditAppRecertificationChange = () => {
    const vendorProfileEditableFieldsToSet: VendorProfileEditableFields = {
      ...vendorProfileEditableFields,
      requireCreditAppRecertification: !vendorProfileEditableFields.requireCreditAppRecertification,
    };
    setVendorProfileEditableFields(vendorProfileEditableFieldsToSet);
    updateVP(vendorProfileEditableFieldsToSet);
  };

  const handleRecertificationDaysChange = (value: string) => {
    const newValue = value ? parseInt(value) : 0 /* null */;
    const vendorProfileEditableFieldsToSet: VendorProfileEditableFields = {
      ...vendorProfileEditableFields,
      daysToCreditAppRecertification: newValue,
    };
    setVendorProfileEditableFields(vendorProfileEditableFieldsToSet);
    updateVP(vendorProfileEditableFieldsToSet);
  };

  const updateVP = async (vendorProfileEditableFields: VendorProfileEditableFields) => {
    try {
      await updateVPDebounced({
        variables: {
          entityId: vendorProfile.id,
          fieldsObj: vendorProfileEditableFields,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Grid container spacing={2} alignItems="flex-start" className={classes.requireCreditAppRecertificationRoot}>
      <Grid item xs={12}>
        <FormGroup row>
          <Tooltip title="Flags OCAs for recertification based on a set time period" placement="top">
            <FormControlLabel
              control={
                <Switch
                  checked={vendorProfileEditableFields.requireCreditAppRecertification}
                  onChange={handleRequireCreditAppRecertificationChange}
                  color="primary"
                />
              }
              label="Require Recertification"
            />
          </Tooltip>
        </FormGroup>
      </Grid>
      {vendorProfileEditableFields.requireCreditAppRecertification && (
        <Grid item xs={12}>
          <Typography variant="body1">
            Streamline compliance and credit application management by highlighting which OCAs need to be reviewed
            and/or re-signed following a specified timeframe
          </Typography>
          <FormInput
            label="Days"
            type="number"
            variant="standard"
            fullWidth={false}
            value={vendorProfileEditableFields.daysToCreditAppRecertification}
            style={{ marginTop: 5, width: 300 }}
            formatter={value => formatMinMaxValue(value, 1, 5000)}
            helperText="Require recertification for credit applications after"
            onChange={handleRecertificationDaysChange}
          />
        </Grid>
      )}
    </Grid>
  );
}
