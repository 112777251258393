import React, { Fragment, ReactNode } from "react";
import { DndProvider } from "react-dnd";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { HTML5Backend } from "react-dnd-html5-backend";
import { green, red } from "@material-ui/core/colors";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProgramsDialog } from "../dialogs";
import { modalsSelectors } from "../../../../redux/modalsReducer";
import { ModalsKeys } from "../../../../components/shared/Modals";
import { Header, ConfigurationPanel, LendersList } from "../components";

export const PrescreenPage = () => {
  const classes = useStyles();

  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];
  const lenderProfiles = useSelector((state: any) => state.vp?.lenderProfiles ?? []) as LenderProfiles[];

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.PrescreenProgramsDialog]: <ProgramsDialog />,
  };

  return (
    <>
      <Box className={classes.mainPage}>
        <Header />

        <Box className={classes.pageContent}>
          <DndProvider backend={HTML5Backend}>
            <LendersList lenders={lenderProfiles} />

            <ConfigurationPanel />
          </DndProvider>
        </Box>
      </Box>

      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  mainPage: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",

    "& .pageHeader": {
      padding: "24px",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",

      "& .pageHeaderMain": {
        gap: "24px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",

        "& .pageHeaderMainTitle": {
          display: "flex",
          flexDirection: "column",
        },

        "& .statusActive": {
          color: green["500"],
        },

        "& .statusInactive": {
          color: red["500"],
        },
      },
    },
  },
  pageContent: {
    gap: "4px",
    display: "flex",

    "& .list": {
      flex: 1,
      height: "68vh",
    },

    "& .panel": {
      flex: 2,
      overflow: "auto",
      height: "68vh",
    },
  },
});
