const OPEN = "OPEN";
const CLOSE = "CLOSE";

type State = Partial<Record<string, { isOpen: boolean; data?: any }>>;

const initialState: State = {};

const modalsReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case OPEN:
      return {
        ...state,
        [payload.modalKey]: { isOpen: true, data: payload?.data },
      };
    case CLOSE:
      return {
        ...state,
        [payload.modalKey]: { isOpen: false },
      };
    default:
      return state;
  }
};

// Actions

const onOpenModal = (modalKey: string, data: any = null) => ({
  type: OPEN,
  payload: { modalKey, data },
});

const onCloseModal = (modalKey: string) => ({
  type: CLOSE,
  payload: { modalKey },
});

// Selectors

const selectIsOpen = (state: any, modalKey: string) => state.modals?.[modalKey]?.isOpen;
const selectData = (state: any, modalKey: string) => state.modals?.[modalKey]?.data;
const openedModals = (state: any) =>
  Object.entries(state.modals)
    .filter(([key, modalState]) => !!(modalState as any)?.isOpen)
    .map(([key]) => key);

const actions = {
  onOpenModal,
  onCloseModal,
};

const modalsSelectors = {
  selectIsOpen,
  selectData,
  openedModals,
};

export { modalsReducer, modalsSelectors, actions };
