import React, { useEffect, useState } from "react";
import Button from "../components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Tile from "./Tile/Tile";
import { CircularProgress, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  cnhAssetTypeOptions,
  cnhMakeOptions,
  cnhOccupationOptions,
  dtfAssetTypeOptions,
  dtfMakeOptions,
  formatCurrency,
  parseCurrency,
} from "../utils";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "components/TabPanel";
import InventorySearch from "./InventorySearch";
import { theme } from "../theme";

const PACCAR_LENDER_DYNAMICS_ACCOUNT_ID = "9f32d34d-3e8a-e111-84ce-005056a20000";
const CNH_LENDER_SUBMISSION_CHANNEL_IDS = [404, 10404, 11404];
const DTF_LENDER_SUBMISSION_CHANNEL_IDS = [311];

const FETCH_TRUCK_MANUFACTURERS = gql`
  query truckManufacturers {
    truckManufacturers {
      dcrCode
      name
    }
  }
`;

const FETCH_PACCAR_MANUFACTURERS = gql`
  query paccarManufactureres {
    paccarManufactureres {
      name
      paccarCode
      type
      assetType
      classType
      cabType
      assetType
    }
  }
`;

const FETCH_PACCAR_ASSET_TYPES = gql`
  query paccarAssetTypes {
    paccarAssetTypes {
      name
      paccarCode
      type
    }
  }
`;
const FETCH_PACCAR_CAB_TYPES = gql`
  query paccarCabTypes {
    paccarCabTypes {
      name
      paccarCode
      type
    }
  }
`;

const FETCH_PACCAR_CLASS_TYPES = gql`
  query paccarClassTypes {
    paccarClassTypes {
      name
      paccarCode
      type
    }
  }
`;

const FETCH_INVENTORY_SEARCH_ACCESS = gql`
  query IntegrationSettingsValues($input: IntegrationSettingsValuesInput!) {
    integrationSettingsValues(input: $input) {
      accountId
    }
  }
`;

const UPSERT_EQUIPMENT = gql`
  mutation($input: UpsertEquipmentInput!) {
    upsertEquipment(input: $input) {
      id
    }
  }
`;

const DELETE_EQUIPMENT = gql`
  mutation($equipmentId: ID!, $dynamicsVendorOpportunityId: ID!, $vendorOpportunityId: ID!) {
    deleteEquipment(
      equipmentId: $equipmentId
      dynamicsVendorOpportunityId: $dynamicsVendorOpportunityId
      vendorOpportunityId: $vendorOpportunityId
    )
  }
`;

const UPDATE_INVOICE_MUTATION = gql`
  mutation($fieldsObj: UpdateInvoiceInput!, $entityId: ID!, $VOId: ID!) {
    updateInvoice(fieldsObj: $fieldsObj, entityId: $entityId, VOId: $VOId)
  }
`;

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  rootInput: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  paper: {
    position: "absolute",
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    minWidth: 600,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    width: 600,
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectStyles: {
    margin: "8px 0",
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    marginTop: 8,
    zIndex: 2,
    backgroundColor: "#fff",
    padding: "0px 4px",
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

export function AdditionalDescriptionField(props) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 16 }}>
      <TextField
        className={props.classes.textField}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        label="Additional Description"
        id="equipmentAdditionalInfo"
        error={props.errors.additionalDescription}
        formControlProps={{ fullWidth: true, required: props.isRequired }}
        onChange={props.handleAdditionalDescription}
        value={props.additionalDescription || ""}
      />
    </Grid>
  );
}

export function SerialNumberVinField(props) {
  return (
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <TextField
        className={props.classes.textField}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        label={props.label}
        id="equipmentSerialNumberVIN"
        error={props.errors.serialNumberVin}
        formControlProps={{ fullWidth: true, required: props.isRequired }}
        onChange={props.handleSerialNumberVin}
        value={props.serialNumberVin || ""}
      />
    </Grid>
  );
}

export default function EquipmentInputModals(props) {
  const classes = useStyles();
  const amountRegExp = /^\$?([\d,.]*)?$/;
  const intNumberRegExp = /^\d+$/;
  const refetchDocs = props.refetchDocs;
  const vendorProfile = props.vendorProfile;
  const creditApp = props.creditApp;
  const equipmentId = props.equipmentId; //NULL FOR NEW EQUIPMENT
  const equipmentData = _.get(props.equipmentData, "equipments", []);
  const [currentTabSelected, setCurrentTabSelected] = useState("equipment");

  const [modalStyle] = useState(getModalStyle);
  const [quantity, setQuantity] = useState(1);
  const [newUsed, setNewUsed] = useState("new");
  const [year, setYear] = useState(new Date().getFullYear());
  const [make, setMake] = useState(""); //AKA Manufacturer
  const [mileage, setMileage] = useState("");
  const [makeOther, setMakeOther] = useState(""); //AKA Manufacturer
  const [truckClass, setTruckClass] = useState(null);
  const [model, setModel] = useState("");
  const [serialNumberVin, setSerialNumberVin] = useState("");
  const [wholesaleCost, setWholesaleCost] = useState("");
  const [federalExciseTax, setFederalExciseTax] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const [totalWholesaleCost, setTotalWholesaleCost] = useState("");
  const [retailPrice, setRetailPrice] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [additionalDescription, setAdditionalDescription] = useState("");
  const [stockId, setStockId] = useState("");
  const [truckManufacturers, setTruckManufacturers] = useState([]);
  const [truckClassSelectEnabled, setTruckClassSelectEnabled] = useState(false);
  const [isTruckingVendor, setIsTruckingVendor] = useState(false);
  const [paccarManufacturers, setPaccarManufacturers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    model: false,
    make: false,
    year: false,
    truckClass: false,
    wholesaleCost: false,
    totalWholesaleCost: false,
    retailPrice: false,
  });
  const [assetType, setAssetType] = useState([]);
  const [filteredAssetType, setFilteredAssetType] = useState([]);
  const [selectedAssetType, setSelectedAssetType] = useState();
  const [cabType, setCabType] = useState([]);
  const [filteredCabType, setFilteredCabType] = useState([]);
  const [selectedCabType, setSelectedCabType] = useState();
  const [classType, setClassType] = useState([]);
  const [filteredClassType, setFilteredClassType] = useState([]);
  const [selectedClassType, setSelectedClassType] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const [selectedLenderProduct, setSelectedLenderProduct] = useState();
  const [cnhAvailableModelOptions, setCNHAvailableModelOptions] = useState([]);
  const [loanType, setLoanType] = useState();
  const [selectedOccupation, setSelectedOccupation] = useState(null);

  const [isPaccarTabHidden, setIsPaccarTabHidden] = useState(true);
  const [isCNHTabHidden, setIsCNHTabHidden] = useState(true);
  const [isDTFTabHidden, setIsDTFTabHidden] = useState(true);

  const [isUsedInfoAlertOpen, setIsUsedInfoAlertOpen] = useState(false);
  const [hasAccessToInventorySearch, setHasAccessToInventorySearch] = useState(false);

  const responseResult = {
    success: false,
    error: false,
  };

  const [upsertEquipmentRequest] = useMutation(UPSERT_EQUIPMENT, {
    context: { authRequired: true },
    onCompleted(data) {
      props?.onEquipmentCreated?.(data?.upsertEquipment?.id);
    },
  });

  const [deleteEquipment] = useMutation(DELETE_EQUIPMENT, {
    context: { authRequired: true },
  });

  const [updateInvoice] = useMutation(UPDATE_INVOICE_MUTATION, {
    context: { authRequired: true },
  });

  const { data: truckManufacturersData } = useQuery(FETCH_TRUCK_MANUFACTURERS, {
    fetchPolicy: "no-cache",
  });

  const { data: paccarManufacturersData } = useQuery(FETCH_PACCAR_MANUFACTURERS, {
    fetchPolicy: "no-cache",
  });

  const { data: paccarAssetsData } = useQuery(FETCH_PACCAR_ASSET_TYPES, {
    fetchPolicy: "no-cache",
  });

  const { data: paccarCabData } = useQuery(FETCH_PACCAR_CAB_TYPES, {
    fetchPolicy: "no-cache",
  });

  const { data: paccarClassData } = useQuery(FETCH_PACCAR_CLASS_TYPES, {
    fetchPolicy: "no-cache",
  });

  const { loading: inventorySearchAccessLoading, data: inventorySearchAccessData } = useQuery(
    FETCH_INVENTORY_SEARCH_ACCESS,
    {
      fetchPolicy: "no-cache",
      variables: {
        input: {
          integrationName: "ARCADIUM",
          accountId: props.account.id,
        },
      },
    }
  );

  useEffect(() => {
    if (_.isEmpty(inventorySearchAccessData)) {
      return;
    }
    const hasAccessToInventorySearch = !!_.get(inventorySearchAccessData, "integrationSettingsValues.accountId");
    setHasAccessToInventorySearch(hasAccessToInventorySearch);
  }, [inventorySearchAccessData]);

  useEffect(() => {
    if (equipmentId !== null && !_.isEmpty(equipmentData)) {
      const found = _.find(equipmentData, { equipmentId });
      if (found) {
        setMake(found.make);
        setModel(found.model);
        setYear(found.year);
        setNewUsed(found.newUsed);
        setAdditionalDescription(found.additionalDescription);
        setStockId(found.stockId);
        setQuantity(found.quantity);
        setTruckClass(found.truckClass);
        setSerialNumberVin(found.serialNumberVin);
        setAttachments(found.attachments || []);
        setWholesaleCost(formatCurrency(found.wholesaleCost));
        setMileage(found.mileage);
        setTotalWholesaleCost(formatCurrency(found.totalWholesaleCost));
        setRetailPrice(formatCurrency(found.retailPrice));
        setSelectedAssetType(found.assetTypeStr);
        setSelectedCabType(found.cabType);
        setSelectedClassType(found.classType);
        setSelectedLenderProduct(found.lenderProduct);
        setSelectedTerm(found.term);
        setLoanType(found.loanType);
        const selectedOccupation = _.find(cnhOccupationOptions, { optionValue: creditApp?.industryType });
        setSelectedOccupation(selectedOccupation || null);
      }
    }
  }, [equipmentId, equipmentData]);

  useEffect(() => {
    if (props.vo) {
      setDownPayment(formatCurrency(props.vo.downPayment));
      setFederalExciseTax(formatCurrency(props.vo.federalExciseTaxAmount));
    }
  }, [props.vo]);

  useEffect(() => {
    const currentTabName = _.get(currentTabSelected, "tabName", null);
    if (newUsed === "used" && currentTabName === "Paccar Details") {
      setIsUsedInfoAlertOpen(true);
    }
  }, [newUsed]);

  useEffect(() => {
    const currentTabName = _.get(currentTabSelected, "tabName", null);
    const paccarAndCNHAvailableNewUsedValues = ["new", "used"];
    const isNeedToResetNewUsed =
      _.includes(["Paccar Details", "CNH Details"], currentTabName) &&
      !_.includes(paccarAndCNHAvailableNewUsedValues, newUsed);
    if (isNeedToResetNewUsed) {
      setNewUsed("new");
    }
  }, [currentTabSelected]);

  useEffect(() => {
    if (_.isEmpty(vendorProfile)) {
      return;
    }
    const isVPIndustryGroupTypeTrucking = vendorProfile.industryGroupType === "trucking";
    const isPaccarTabVisible = _.some(
      vendorProfile.lenderProfiles,
      lenderProfile => lenderProfile.lenderAccountId === PACCAR_LENDER_DYNAMICS_ACCOUNT_ID
    );
    const isCNHTabVisible = _.some(vendorProfile.lenderProfiles, lenderProfile =>
      _.includes(CNH_LENDER_SUBMISSION_CHANNEL_IDS, lenderProfile.lenderChannelCode)
    );
    const isDTFTabVisible = _.some(vendorProfile.lenderProfiles, lenderProfile =>
      _.includes(DTF_LENDER_SUBMISSION_CHANNEL_IDS, lenderProfile.lenderChannelCode)
    );
    setTruckClassSelectEnabled(isVPIndustryGroupTypeTrucking);
    setIsTruckingVendor(isVPIndustryGroupTypeTrucking);
    setIsPaccarTabHidden(!isPaccarTabVisible);
    setIsCNHTabHidden(!isCNHTabVisible);
    setIsDTFTabHidden(!isDTFTabVisible);
  }, [vendorProfile]);

  const handleIntNumberChange = (e, setValueCallback) => {
    const { value } = e.target;

    if (intNumberRegExp.test(value)) {
      setValueCallback(parseInt(value));
    }
  };

  const handleNewUsed = event => event && setNewUsed(event.target.value);
  const handleYear = event => setYear(event.target.value);
  const handleMake = event => setMake(event.target.value);
  const handleModel = event => setModel(event.target.value);
  const handleSerialNumberVin = event => setSerialNumberVin(event.target.value);
  const handleAdditionalDescription = event => setAdditionalDescription(event.target.value);
  const handleStockId = event => setStockId(event.target.value);
  const handleMakeSelect = event => setMake(event.target.value);
  const handleTruckClassSelect = event => setTruckClass(event.target.value);
  const handleMakeOther = event => setMakeOther(event.target.value);
  const handleWholesaleCost = event => {
    if (amountRegExp.test(event.target.value)) {
      setWholesaleCost(formatCurrency(event.target.value));
      handleCalculate(parseCurrency(event.target.value), attachments);
    }
  };
  /* const handleMileage = event => setMileage(event.target.value); */
  const handleTotalWholesaleCost = event => {
    if (amountRegExp.test(event.target.value)) {
      setTotalWholesaleCost(formatCurrency(event.target.value));
    }
  };
  const handleFederalExciseTax = event => {
    if (amountRegExp.test(event.target.value)) {
      setFederalExciseTax(formatCurrency(event.target.value));
    }
  };
  const handleDownPayment = event => {
    if (amountRegExp.test(event.target.value)) {
      setDownPayment(formatCurrency(event.target.value));
    }
  };
  const handleRetailPrice = event => {
    if (amountRegExp.test(event.target.value)) {
      setRetailPrice(formatCurrency(event.target.value));
    }
  };
  const handleLoanType = event => setLoanType(event.target.value);

  const handleAddAttachments = () => {
    setAttachments([
      ...attachments,
      {
        manufacturer: "",
        type: "Body",
        model: "",
        category: "Truck",
        unitCost: "",
        quantityPerAsset: 1,
      },
    ]);
  };

  const handleCalculate = (wCost, attach) => {
    let total = 0;

    if (wCost) {
      total = total + parseFloat(wCost);
    }
    if (attach) {
      attach.map(attachment => {
        if (attachment.unitCost) {
          total = total + parseFloat(parseCurrency(attachment.unitCost));
        }
      });
    }

    setTotalWholesaleCost(formatCurrency(total));
  };
  const handleAssetTypeSelect = event => setSelectedAssetType(event.target.value);
  const handleClassTypeSelect = event => setSelectedClassType(event.target.value);
  const handleCabTypeSelect = event => setSelectedCabType(event.target.value);
  const handleLenderProductSelect = event => setSelectedLenderProduct(event.target.value);
  const handleOccupationSelect = event => setSelectedOccupation(event.target.value);

  const handleTabChange = (event, newValue) => {
    setCurrentTabSelected(newValue);
  };

  const handleRemoveAttachments = () => {
    let previous = _.cloneDeep(attachments);
    previous.pop();
    setAttachments(previous);
    if (wholesaleCost) {
      handleCalculate(parseCurrency(wholesaleCost), previous);
    } else {
      handleCalculate(0, previous);
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    try {
      await deleteEquipment({
        variables: {
          equipmentId,
          dynamicsVendorOpportunityId: props.vo.dynamicsVendorOpportunityId,
          vendorOpportunityId: props.vo.vendorOpportunityId,
        },
      });
      await props?.refetchCreditApp?.();
      await props?.refetch?.();
    } catch (e) {
      console.log("ERROR :: removing equipment unsuccessful :: ", e);
    }
    setLoading(false);
    await props.handleClose(responseResult);
    setLoading(false);
    //hack! time for crm to update invoice total
    await sleep();
    await props?.refetchVO?.();
  };

  const handleSaveAttachment = (index, key, value) => {
    let previous = _.cloneDeep(attachments);
    previous[index][key] = value;
    setAttachments(previous);
    if (wholesaleCost) {
      handleCalculate(parseCurrency(wholesaleCost), previous);
    } else {
      handleCalculate(0, previous);
    }
  };

  useEffect(() => {
    if (truckManufacturersData) {
      setTruckManufacturers(truckManufacturersData.truckManufacturers);
    }
    if (paccarManufacturersData) {
      setPaccarManufacturers(paccarManufacturersData.paccarManufactureres);
    }
    if (paccarAssetsData) {
      setAssetType(paccarAssetsData.paccarAssetTypes);
    }
    if (paccarCabData) {
      setCabType(paccarCabData.paccarCabTypes);
    }
    if (paccarClassData) {
      setClassType(paccarClassData.paccarClassTypes);
    }
  }, [truckManufacturersData, paccarManufacturersData, paccarAssetsData, paccarCabData, paccarClassData]);

  useEffect(() => {
    if (!!make && !!paccarManufacturers) {
      let selectedPaccarMake = paccarManufacturers.filter(item => item.name === make)[0];
      if (selectedPaccarMake) {
        setFilteredAssetType(assetType.filter(item => selectedPaccarMake.assetType.includes(item.type)));
        setFilteredCabType(cabType.filter(item => selectedPaccarMake.cabType.includes(item.type)));
        setFilteredClassType(classType.filter(item => selectedPaccarMake.classType.includes(item.type)));
      }
    }
  }, [make, paccarManufacturers]);

  useEffect(() => {
    const cnhSelectedAssetType = _.find(cnhAssetTypeOptions, { name: selectedAssetType });
    const cnhAvailableModelOptions = _.get(cnhSelectedAssetType, "options", []);
    setCNHAvailableModelOptions(cnhAvailableModelOptions);
  }, [selectedAssetType]);

  const truckIndustryValidations = () => {
    let result = false;

    let fieldsAnalyzed = {
      model: false,
      make: false,
      year: false,
      truckClass: false,
      wholesaleCost: false,
      totalWholesaleCost: false,
      retailPrice: false,
    };

    if (!model) {
      fieldsAnalyzed.model = true;
      result = true;
    }

    if (!make) {
      fieldsAnalyzed.make = true;
      result = true;
    }

    if (!truckClass) {
      fieldsAnalyzed.truckClass = true;
      result = true;
    }

    if (!year) {
      fieldsAnalyzed.year = true;
      result = true;
    }

    if (!wholesaleCost) {
      fieldsAnalyzed.wholesaleCost = true;
      result = true;
    }

    if (!totalWholesaleCost) {
      fieldsAnalyzed.totalWholesaleCost = true;
      result = true;
    }

    if (!retailPrice) {
      fieldsAnalyzed.retailPrice = true;
      result = true;
    }
    setErrors(fieldsAnalyzed);

    return result;
  };

  const paccarEquipmentValidations = () => {
    const fieldsAnalyzed = {
      model: !model,
      make: !make,
      year: !year,
      wholesaleCost: !wholesaleCost,
      retailPrice: !retailPrice,
      assetType: !selectedAssetType,
      cabType: !selectedCabType,
      classType: !selectedClassType,
      term: !selectedTerm,
      lenderProduct: !selectedLenderProduct,
    };
    setErrors(fieldsAnalyzed);
    return _.some(fieldsAnalyzed);
  };

  const cnhEquipmentValidations = () => {
    const fieldsAnalyzed = {
      model: !model,
      make: !make,
      year: !year,
      wholesaleCost: !wholesaleCost,
      retailPrice: !retailPrice,
      term: !selectedTerm,
      loanType: !loanType,
      assetType: !selectedAssetType,
      occupation: !selectedOccupation,
      serialNumberVin: !serialNumberVin,
      additionalDescription: !additionalDescription,
    };

    setErrors(fieldsAnalyzed);
    return _.some(fieldsAnalyzed);
  };

  const handleReloadEquipmentData = async () => {
    await props?.refetchCreditApp();
    await props?.refetch();
    await props?.handleClose();
    await sleep();
    await props?.refetchVO?.();
  };

  const dtfEquipmentValidations = () => {
    const fieldsAnalyzed = {
      quantity: !quantity,
      year: !year,
      newUsed: !newUsed,
      // federalExciseTax: !federalExciseTax, // This is NOT required.
      make: !make,
      totalWholesaleCost: newUsed === "new" ? !totalWholesaleCost : false,
      retailPrice: !retailPrice,
      term: !selectedTerm,
      loanType: !loanType,
      assetType: !selectedAssetType,
      serialNumberVin: !serialNumberVin,
      // truckClass: !truckClass, // This isn't on the form
      // stockId: !stockId, // This is NOT required
      // mileage: !mileage, // This is NOT required.
      // downPayment: !downPayment, // This is NOT required
    };

    setErrors(fieldsAnalyzed);
    return _.some(fieldsAnalyzed);
  };

  const handleSubmit = async () => {
    try {
      const formHasErrors = isTruckingVendor ? truckIndustryValidations() : false;

      if (formHasErrors) {
        return;
      }
      setLoading(true);
      let input = {
        dynamicsVendorOpportunityId: props.vo.dynamicsVendorOpportunityId,
        vendorOpportunityId: props.vo.vendorOpportunityId,
        quantity: quantity || 1,
        newUsed: newUsed,
        year: year.toString(),
        make: make !== "Other" ? make : makeOther,
        model: model,
        serialNumberVin: serialNumberVin,
        additionalDescription: additionalDescription,
        stockId: stockId,
      };

      if (equipmentId) {
        input.equipmentId = equipmentId;
      }

      if (!_.isEmpty(attachments)) {
        input.attachments = attachments;
      }

      if (truckClass) {
        input.truckClass = truckClass;
      }

      if (wholesaleCost) {
        input.wholesaleCost = parseFloat(parseCurrency(wholesaleCost));
      }

      if (mileage) {
        input.mileage = parseInt(mileage);
      }

      if (totalWholesaleCost) {
        input.totalWholesaleCost = parseFloat(parseCurrency(totalWholesaleCost));
      }

      if (retailPrice) {
        input.retailPrice = parseFloat(parseCurrency(retailPrice));
      }

      await upsertEquipmentRequest({
        variables: {
          input,
          context: { authRequired: true },
        },
      });

      await props?.refetchCreditApp?.();
      await props?.refetch?.();
      responseResult.success = true;
    } catch (err) {
      responseResult.error = true;
      console.log(err);
    }
    await props.handleClose();
    setLoading(false);
    //hack! time for crm to update invoice total
    await sleep();
    await props?.refetchVO?.();
  };

  const handlePaccarSubmit = async () => {
    try {
      const formHasErrors = paccarEquipmentValidations();

      if (formHasErrors) {
        return;
      }
      setLoading(true);
      let input = {
        dynamicsVendorOpportunityId: props.vo.dynamicsVendorOpportunityId,
        vendorOpportunityId: props.vo.vendorOpportunityId,
        quantity: quantity || 1,
        newUsed: newUsed,
        year: year.toString(),
        make: make,
        model: model,
        assetTypeStr: selectedAssetType,
        cabType: selectedCabType,
        classType: selectedClassType,
        lenderProduct: selectedLenderProduct,
        term: parseInt(selectedTerm),
      };

      if (equipmentId) {
        input.equipmentId = equipmentId;
      }

      if (wholesaleCost) {
        input.wholesaleCost = parseFloat(parseCurrency(wholesaleCost));
      }

      if (totalWholesaleCost) {
        input.totalWholesaleCost = parseFloat(parseCurrency(totalWholesaleCost));
      }

      if (retailPrice) {
        input.retailPrice = parseFloat(parseCurrency(retailPrice));
      }

      await upsertEquipmentRequest({
        variables: {
          input,
          context: { authRequired: true },
        },
      });
      await props?.refetchCreditApp?.();
      await props?.refetch?.();
      responseResult.success = true;
    } catch (err) {
      responseResult.error = true;
      console.log(err);
    }
    await props.handleClose(responseResult);
    setLoading(false);
    //hack! time for crm to update invoice total
    await sleep();
    await props?.refetchVO?.();
  };
  const handleCNHSubmit = async () => {
    try {
      const formHasErrors = cnhEquipmentValidations();

      if (formHasErrors) {
        return;
      }
      setLoading(true);
      let input = {
        dynamicsVendorOpportunityId: props.vo.dynamicsVendorOpportunityId,
        vendorOpportunityId: props.vo.vendorOpportunityId,
        quantity: quantity || 1,
        newUsed: newUsed,
        year: year.toString(),
        make: make,
        assetTypeStr: selectedAssetType,
        model: model,
        term: parseInt(selectedTerm),
        loanType: loanType,
        occupation: selectedOccupation.optionValue,
        serialNumberVin: serialNumberVin,
        additionalDescription: additionalDescription,
      };

      if (equipmentId) {
        input.equipmentId = equipmentId;
      }

      if (wholesaleCost) {
        input.wholesaleCost = parseFloat(parseCurrency(wholesaleCost));
      }

      if (totalWholesaleCost) {
        input.totalWholesaleCost = parseFloat(parseCurrency(totalWholesaleCost));
      }

      if (retailPrice) {
        input.retailPrice = parseFloat(parseCurrency(retailPrice));
      }

      await upsertEquipmentRequest({
        variables: {
          input,
          context: { authRequired: true },
        },
      });
      await props?.refetchCreditApp?.();
      await props?.refetch?.();
      responseResult.success = true;
    } catch (err) {
      responseResult.error = true;
      console.log(err);
    }
    await props.handleClose(responseResult);
    setLoading(false);
    //hack! time for crm to update invoice total
    await sleep();
    await props?.refetchVO?.();
  };
  const handleDTFSubmit = async () => {
    try {
      const formHasErrors = dtfEquipmentValidations();

      if (formHasErrors) {
        console.log("HAS FORM ERRORS");
        return;
      }
      setLoading(true);
      let equipInput = {
        dynamicsVendorOpportunityId: props.vo.dynamicsVendorOpportunityId,
        vendorOpportunityId: props.vo.vendorOpportunityId,
        quantity: quantity || 1,
        newUsed: newUsed,
        year: year.toString(),
        make: make,
        assetTypeStr: selectedAssetType,
        model: model,
        term: parseInt(selectedTerm),
        loanType: loanType,
        serialNumberVin: serialNumberVin,
        stockId: stockId,
      };
      let fieldsObj = {};

      if (mileage) {
        equipInput.mileage = parseInt(mileage);
      }

      // if (truckClass) {
      //   equipInput.truckClass = truckClass;
      // }

      if (equipmentId) {
        equipInput.equipmentId = equipmentId;
      }

      if (totalWholesaleCost) {
        equipInput.totalWholesaleCost = parseFloat(parseCurrency(totalWholesaleCost));
      }

      if (retailPrice) {
        equipInput.retailPrice = parseFloat(parseCurrency(retailPrice));
      }

      if (federalExciseTax) {
        fieldsObj.federalExciseTaxAmount = parseFloat(parseCurrency(federalExciseTax));
      }

      if (downPayment) {
        fieldsObj.downPayment = parseFloat(parseCurrency(downPayment));
      }

      await upsertEquipmentRequest({
        variables: {
          input: equipInput,
          context: { authRequired: true },
        },
      });

      if (!_.isEmpty(fieldsObj)) {
        await updateInvoice({
          variables: {
            fieldsObj,
            VOId: props.vo.vendorOpportunityId,
            entityId: props.vo.dynamicsVendorOpportunityId,
          },
        });
      }
      await props?.refetchCreditApp?.();
      await props?.refetch?.();
      responseResult.success = true;
    } catch (err) {
      responseResult.error = true;
      console.log(err);
    }
    await props.handleClose(responseResult);
    setLoading(false);
    //hack! time for crm to update invoice total
    await sleep();
    await props?.refetchVO?.();
  };

  const sleep = () => {
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve("anything");
      }, 5000);
    });
  };

  if (loading) {
    return (
      <div style={modalStyle} className={classes.surface}>
        <Tile title={equipmentId ? "Editing Equipment" : "Adding Equipment"} maxHeightLimitByScreen={true}>
          <p>Please wait, this will take a few.</p>
          <div className={classes.formContainer}>
            <LinearProgress />
          </div>
        </Tile>
      </div>
    );
  }

  return (
    <div style={modalStyle} className={classes.surface}>
      <Tile maxHeightLimitByScreen={true}>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
          <h4>
            <b>Add Equipment</b>
          </h4>
          <IconButton aria-label="close" onClick={props.handleClose}>
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        </div>
        <Tabs
          value={currentTabSelected}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="full width tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          <Tab
            value="equipment"
            label="Equipment"
            style={{ color: currentTabSelected === "equipment" ? theme.palette.primary.main : "" }}
          />
          {!isDTFTabHidden && (
            <Tab
              value="dtf"
              label="DTF Details"
              style={{ color: currentTabSelected === "dtf" ? theme.palette.primary.main : "" }}
            />
          )}
          {!isCNHTabHidden && (
            <Tab
              value="cnh"
              label="CNH Details"
              style={{ color: currentTabSelected === "cnh" ? theme.palette.primary.main : "" }}
            />
          )}
          {!isPaccarTabHidden && (
            <Tab
              value="paccar"
              label="Paccar Details"
              style={{ color: currentTabSelected === "paccar" ? theme.palette.primary.main : "" }}
            />
          )}
          <Tab
            value="inventorySearch"
            label="Search Inventory"
            style={{ color: currentTabSelected === "inventorySearch" ? theme.palette.primary.main : "" }}
          />
        </Tabs>
        <TabPanel value={currentTabSelected} index="equipment">
          <>
            <div className={classes.formContainer}>
              <form noValidate autoComplete="off">
                <Grid container spacing={4} className={classes.formContainerGridContainer}>
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Quantity"
                      id="equipmentQuantity"
                      formControlProps={{ fullWidth: true }}
                      onChange={e => handleIntNumberChange(e, setQuantity)}
                      value={quantity || "1"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-condition`} className={classes.selectLabel}>
                        Condition
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-condition`}
                        value={newUsed || "new"}
                        onChange={handleNewUsed}
                        inputProps={{
                          name: "equipmentNewUsed",
                          id: "equipmentNewUsed",
                        }}
                      >
                        <MenuItem value={"new"}>New</MenuItem>
                        <MenuItem value={"used"}>Used</MenuItem>
                        <MenuItem value={"demo"}>Demo</MenuItem>
                        <MenuItem value={"reconditioned"}>Reconditioned</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-year`} className={classes.selectLabel}>
                        Year
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-year`}
                        value={year}
                        error={errors.year}
                        onChange={handleYear}
                        inputProps={{
                          name: "year",
                          id: "year",
                        }}
                      >
                        {_.range(1990, new Date().getFullYear() + 3).map((k, v) => (
                          <MenuItem value={k} key={`year_${k}`}>
                            {k}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {isTruckingVendor && !_.isEmpty(truckManufacturers) && (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`select-label-manu`} className={classes.selectLabel}>
                          Manufacturer
                        </InputLabel>
                        <Select
                          className={classes.selectStyles}
                          labelId={`select-label-manu`}
                          value={make || ""}
                          error={errors.make}
                          onChange={handleMakeSelect}
                          inputProps={{
                            name: "equipmentMakeSel",
                            id: "equipmentMakeSel",
                          }}
                        >
                          {truckManufacturers.map(o => {
                            const selected = make ? make.toLowerCase() === o.name.toLowerCase() : false;
                            return (
                              <MenuItem key={o.dcrCode} selected={selected} value={o.name}>
                                {o.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}

                    {!isTruckingVendor && (
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Make"
                        id="equipmentMake"
                        onChange={handleMake}
                        value={make || ""}
                      />
                    )}
                  </Grid>

                  {make == "Other" ? (
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Other Manufacturer"
                        id="equipmentMakeOther"
                        formControlProps={{ fullWidth: true }}
                        onChange={handleMakeOther}
                        value={makeOther || ""}
                      />
                    </Grid>
                  ) : null}

                  <Grid
                    item
                    xs={12}
                    sm={truckClassSelectEnabled ? 3 : 6}
                    md={truckClassSelectEnabled ? 3 : 6}
                    lg={truckClassSelectEnabled ? 3 : 6}
                  >
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Model"
                      id="equipmentModel"
                      error={errors.model}
                      formControlProps={{ fullWidth: true }}
                      onChange={handleModel}
                      value={model || ""}
                    />
                  </Grid>
                  {truckClassSelectEnabled ? (
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`select-label-tc`} className={classes.selectLabel}>
                          Truck Class
                        </InputLabel>
                        <Select
                          className={classes.selectStyles}
                          labelId={`select-label-tc`}
                          value={truckClass || ""}
                          error={errors.truckClass}
                          onChange={handleTruckClassSelect}
                          inputProps={{
                            name: "truckClass",
                            id: "truckClass",
                          }}
                        >
                          {_.range(1, 9).map((k, v) => (
                            <MenuItem value={k} key={`truckClass_${k}`}>
                              {k}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}

                  <SerialNumberVinField
                    handleSerialNumberVin={handleSerialNumberVin}
                    serialNumberVin={serialNumberVin}
                    errors={errors}
                    label="Serial / VIN #"
                    classes={classes}
                  />
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Stock Id"
                      id="stockId"
                      formControlProps={{ fullWidth: true }}
                      onChange={handleStockId}
                      value={stockId || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Wholesale Cost"
                      id="wholesaleCost"
                      error={errors.wholesaleCost}
                      formControlProps={{ fullWidth: false }}
                      onChange={handleWholesaleCost}
                      value={wholesaleCost || ""}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Mileage"
                      id="mileage"
                      error={errors.mileage}
                      formControlProps={{ fullWidth: false }}
                      onChange={e => handleIntNumberChange(e, setMileage)}
                      value={mileage || ""}
                    />
                  </Grid>

                  <AdditionalDescriptionField
                    handleAdditionalDescription={handleAdditionalDescription}
                    additionalDescription={additionalDescription}
                    errors={errors}
                    classes={classes}
                  />

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Total Wholesale Cost"
                      id="totalWholesaleCost"
                      error={errors.totalWholesaleCost}
                      formControlProps={{ fullWidth: false }}
                      onChange={handleTotalWholesaleCost}
                      value={totalWholesaleCost || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Total Retail Price"
                      id="retailPrice"
                      error={errors.retailPrice}
                      formControlProps={{ fullWidth: false }}
                      onChange={handleRetailPrice}
                      value={retailPrice || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <br />
                    <h4>Attachments</h4>
                    {_.size(attachments) ? <hr /> : null}
                    {attachments.map((attachment, index) => {
                      return (
                        <div key={`attachments.${index}.general`}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <h6 className={classes.attachmentSubtitle}>Attachment #{index + 1}</h6>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Qty. per asset"
                                key={`attachments[${index}].quantityPerAsset`}
                                formControlProps={{ fullWidth: false }}
                                onChange={e => {
                                  handleSaveAttachment(index, "quantityPerAsset", e.target.value);
                                }}
                                value={attachment.quantityPerAsset || ""}
                                inputProps={{
                                  type: "text",
                                  id: `attachments[${index}].quantityPerAsset`,
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Manufacturer"
                                formControlProps={{ fullWidth: false }}
                                onChange={e => {
                                  handleSaveAttachment(index, "manufacturer", e.target.value);
                                }}
                                value={attachment.manufacturer || ""}
                                inputProps={{
                                  type: "text",
                                  id: `attachments[${index}].manufacturer`,
                                }}
                              />
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel id={`select-label-type`} className={classes.selectLabel}>
                                  Type
                                </InputLabel>
                                <Select
                                  className={classes.selectStyles}
                                  labelId={`select-label-type`}
                                  value={attachment.type || ""}
                                  key={`attachments[${index}].type`}
                                  onChange={e => {
                                    handleSaveAttachment(index, "type", e.target.value);
                                  }}
                                  inputProps={{
                                    name: `attachments[${index}].type`,
                                    id: `attachments[${index}].type`,
                                  }}
                                >
                                  {["Trailer", "Body", "Attachment"].map((name, key) => {
                                    return (
                                      <MenuItem key={`attachments[${index}].[${name}]`} value={name}>
                                        {name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Model"
                                key={`attachments[${index}].model`}
                                formControlProps={{ fullWidth: false }}
                                onChange={e => {
                                  handleSaveAttachment(index, "model", e.target.value);
                                }}
                                value={attachment.model || ""}
                                inputProps={{
                                  type: "text",
                                  id: `attachments[${index}].model`,
                                }}
                              />
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Category"
                                disabled
                                formControlProps={{ fullWidth: false }}
                                onChange={e => {
                                  handleSaveAttachment(index, "category", e.target.value);
                                }}
                                value={attachment.category || ""}
                                inputProps={{
                                  type: "text",
                                  id: `attachments[${index}].category`,
                                }}
                              />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4}>
                              <TextField
                                className={classes.textField}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label="Wholesale Cost"
                                disabled={true}
                                formControlProps={{ fullWidth: false }}
                                onChange={e => {
                                  if (amountRegExp.test(e.target.value)) {
                                    handleSaveAttachment(index, "unitCost", formatCurrency(e.target.value));
                                  }
                                }}
                                value={attachment.unitCost || ""}
                                inputProps={{
                                  type: "text",
                                  id: `attachments[${index}].unitCost`,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container justify="flex-start">
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Button
                          onClick={handleAddAttachments}
                          color="primary"
                          size={"sm"}
                          data-test-id="add-attachment-btn"
                        >
                          Add
                        </Button>
                        {_.size(attachments) ? (
                          <Button onClick={handleRemoveAttachments} color="primary" size={"sm"}>
                            Remove
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                </Grid>
              </form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "40px",
                }}
              >
                {equipmentId && (
                  <Button onClick={handleRemove} color="secondary" size={"sm"} style={{ marginRight: 8 }}>
                    Remove
                  </Button>
                )}
                <Button onClick={handleSubmit} color="primary" size={"sm"} data-test-id="submit-btn">
                  Save
                </Button>
              </div>
            </div>
          </>
        </TabPanel>
        <TabPanel value={currentTabSelected} index="dtf">
          <>
            <div className={classes.formContainer}>
              <form noValidate autoComplete="off">
                <Grid container spacing={4} className={classes.formContainerGridContainer}>
                  <Grid item xs={12}>
                    <p style={{ margin: "16px 0" }}>
                      <b>Equipment Details</b>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Quantity"
                      id="equipmentQuantity"
                      formControlProps={{ fullWidth: true }}
                      onChange={e => handleIntNumberChange(e, setQuantity)}
                      value={quantity || "1"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-condition`} className={classes.selectLabel}>
                        Condition
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-condition`}
                        value={newUsed || "new"}
                        onChange={handleNewUsed}
                        inputProps={{
                          name: "equipmentNewUsed",
                          id: "equipmentNewUsed",
                        }}
                      >
                        <MenuItem value={"new"}>New</MenuItem>
                        <MenuItem value={"used"}>Used</MenuItem>
                        <MenuItem value={"demo"}>Demo</MenuItem>
                        <MenuItem value={"reconditioned"}>Reconditioned</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5} md={5} lg={5}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-year`} className={classes.selectLabel}>
                        Year
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-year`}
                        value={year}
                        error={errors.year}
                        onChange={handleYear}
                        inputProps={{
                          name: "year",
                          id: "year",
                        }}
                      >
                        {_.range(1990, new Date().getFullYear() + 3).map((k, v) => (
                          <MenuItem value={k} key={`year_${k}`}>
                            {k}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} sm={2} md={2} lg={2}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id={`select-label-tc`} className={classes.selectLabel}>
                            Class
                          </InputLabel>
                          <Select
                            className={classes.selectStyles}
                            labelId={`select-label-tc`}
                            value={truckClass || ""}
                            error={errors.truckClass}
                            onChange={handleTruckClassSelect}
                            inputProps={{
                              name: "truckClass",
                              id: "truckClass",
                            }}
                          >
                            {_.range(1, 9).map((k, v) => (
                              <MenuItem value={k} key={`truckClass_${k}`}>
                                {k}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid> */}

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl variant="outlined" error={errors.assetType} required fullWidth>
                      <InputLabel id={`select-label-series`} className={classes.selectLabel}>
                        Type
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-series`}
                        value={selectedAssetType || ""}
                        onChange={event => {
                          handleAssetTypeSelect(event);
                          setErrors(prevErrors => ({ ...prevErrors, assetType: !event.target.value }));
                        }}
                        inputProps={{
                          name: "assetTypeSel",
                          id: "assetTypeSel",
                        }}
                      >
                        {dtfAssetTypeOptions.map((o, index) => {
                          const selected = selectedAssetType
                            ? selectedAssetType.toLowerCase() === o.name.toLowerCase()
                            : false;
                          return (
                            <MenuItem key={index} selected={selected} value={o.name}>
                              {o.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl variant="outlined" error={errors.make} required fullWidth>
                      <InputLabel id={`select-label-make`} className={classes.selectLabel}>
                        Make
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-make`}
                        value={make || ""}
                        onChange={event => {
                          handleMakeSelect(event);
                          setErrors(prevErrors => ({ ...prevErrors, make: !event.target.value }));
                        }}
                        inputProps={{
                          name: "equipmentMakeSel",
                          id: "equipmentMakeSel",
                        }}
                        disabled={!selectedAssetType}
                      >
                        {dtfMakeOptions
                          .filter(opt => opt.type === selectedAssetType)
                          .map((option, index) => {
                            const selected = make ? make.toLowerCase() === option.make.toLowerCase() : false;
                            return (
                              <MenuItem key={index} selected={selected} value={option.make}>
                                {option.make}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={truckClassSelectEnabled ? 3 : 6}
                    md={truckClassSelectEnabled ? 3 : 6}
                    lg={truckClassSelectEnabled ? 3 : 6}
                  >
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Model"
                      id="equipmentModel"
                      error={errors.model}
                      formControlProps={{ fullWidth: true }}
                      onChange={handleModel}
                      value={model || ""}
                    />
                  </Grid>

                  <SerialNumberVinField
                    handleSerialNumberVin={handleSerialNumberVin}
                    serialNumberVin={serialNumberVin}
                    errors={errors}
                    label="Serial / VIN #"
                    classes={classes}
                  />
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Stock Id"
                      id="stockId"
                      formControlProps={{ fullWidth: true }}
                      onChange={handleStockId}
                      value={stockId || ""}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Mileage"
                      id="mileage"
                      error={errors.mileage}
                      formControlProps={{ fullWidth: false }}
                      onChange={e => handleIntNumberChange(e, setMileage)}
                      value={mileage || ""}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <p style={{ margin: "16px 0" }}>
                      <b>Equipment Cost</b>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Total Dealer Cost"
                      id="totalWholesaleCost"
                      error={errors.totalWholesaleCost}
                      formControlProps={{ fullWidth: false }}
                      onChange={handleTotalWholesaleCost}
                      value={totalWholesaleCost || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Total Retail Price"
                      id="retailPrice"
                      error={errors.retailPrice}
                      formControlProps={{ fullWidth: false }}
                      onChange={handleRetailPrice}
                      value={retailPrice || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <p style={{ margin: "16px 0" }}>
                      <b>Finance Structure</b>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Term"
                      id="term"
                      error={errors.term}
                      formControlProps={{ fullWidth: true, required: true }}
                      onChange={e => handleIntNumberChange(e, setSelectedTerm)}
                      value={selectedTerm || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <FormControl variant="outlined" error={errors.loanType} required fullWidth>
                      <InputLabel id={`select-label-lt`} className={classes.selectLabel}>
                        Loan Type
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-lt`}
                        value={loanType || ""}
                        onChange={event => {
                          handleLoanType(event);
                          setErrors(prevErrors => ({ ...prevErrors, loanType: !event.target.value }));
                        }}
                        InputPr
                        inputProps={{
                          name: "loanType",
                          id: "loanType",
                        }}
                      >
                        <MenuItem value={"Retail Loan"}>Retail Loan</MenuItem>
                        <MenuItem value={"Lease FMV"}>Lease FMV</MenuItem>
                        <MenuItem value={"Lease TRAC"}>Lease TRAC</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Cash Down"
                      id="downPayment"
                      // error={errors.totalWholesaleCost}
                      formControlProps={{ fullWidth: false }}
                      onChange={handleDownPayment}
                      value={downPayment || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Federal Excise Tax"
                      id="federalExciseTax"
                      // error={errors.totalWholesaleCost}
                      formControlProps={{ fullWidth: false }}
                      onChange={handleFederalExciseTax}
                      value={federalExciseTax || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "40px",
                }}
              >
                {equipmentId && (
                  <Button onClick={handleRemove} color="secondary" size={"sm"} style={{ marginRight: 8 }}>
                    Remove
                  </Button>
                )}
                <Button onClick={handleDTFSubmit} color="primary" size={"sm"} data-test-id="submit-btn">
                  Save
                </Button>
              </div>
            </div>
          </>
        </TabPanel>
        <TabPanel value={currentTabSelected} index="cnh">
          <>
            <div className={classes.formContainer}>
              <form noValidate autoComplete="off">
                <Grid container spacing={4} className={classes.formContainerGridContainer}>
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Quantity"
                      id="equipmentQuantity"
                      formControlProps={{ fullWidth: true }}
                      onChange={e => handleIntNumberChange(e, setQuantity)}
                      value={quantity || "1"}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-nu`} className={classes.selectLabel}>
                        New or Used?
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-nu`}
                        value={newUsed || "new"}
                        onChange={handleNewUsed}
                        inputProps={{
                          name: "equipmentNewUsed",
                          id: "equipmentNewUsed",
                        }}
                      >
                        <MenuItem value={"new"}>New</MenuItem>
                        <MenuItem value={"used"}>Used</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-year`} className={classes.selectLabel}>
                        Year
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-year`}
                        value={year || ""}
                        error={errors.year}
                        onChange={handleYear}
                        inputProps={{
                          name: "year",
                          id: "year",
                        }}
                      >
                        {_.range(1990, new Date().getFullYear() + 3).map((k, v) => (
                          <MenuItem value={k} key={`year_${k}`}>
                            {k}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl variant="outlined" error={errors.make} required fullWidth>
                      <InputLabel id={`select-label-make`} className={classes.selectLabel}>
                        Make
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-make`}
                        value={make || ""}
                        onChange={event => {
                          handleMakeSelect(event);
                          setErrors(prevErrors => ({ ...prevErrors, make: !event.target.value }));
                        }}
                        inputProps={{
                          name: "equipmentMakeSel",
                          id: "equipmentMakeSel",
                        }}
                      >
                        {cnhMakeOptions.map((option, index) => {
                          const selected = make ? make.toLowerCase() === option.toLowerCase() : false;
                          return (
                            <MenuItem key={index} selected={selected} value={option}>
                              {option}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {cnhAssetTypeOptions && !_.isEmpty(cnhAssetTypeOptions) && (
                      <FormControl variant="outlined" error={errors.assetType} required fullWidth>
                        <InputLabel id={`select-label-series`} className={classes.selectLabel}>
                          Series
                        </InputLabel>
                        <Select
                          className={classes.selectStyles}
                          labelId={`select-label-series`}
                          value={selectedAssetType || ""}
                          onChange={event => {
                            handleAssetTypeSelect(event);
                            setErrors(prevErrors => ({ ...prevErrors, assetType: !event.target.value }));
                          }}
                          inputProps={{
                            name: "assetTypeSel",
                            id: "assetTypeSel",
                          }}
                        >
                          {cnhAssetTypeOptions.map((o, index) => {
                            const selected = selectedAssetType
                              ? selectedAssetType.toLowerCase() === o.name.toLowerCase()
                              : false;
                            return (
                              <MenuItem key={index} selected={selected} value={o.name}>
                                {o.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={truckClassSelectEnabled ? 3 : 6}
                    md={truckClassSelectEnabled ? 3 : 6}
                    lg={truckClassSelectEnabled ? 3 : 6}
                  >
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={cnhAvailableModelOptions}
                        value={model || ""}
                        onChange={(event, newValue) => {
                          if (newValue && newValue.inputValue) {
                            setModel(newValue.inputValue);
                            setErrors(prevErrors => ({ ...prevErrors, model: !newValue.inputValue }));
                          } else {
                            setModel(newValue);
                            setErrors(prevErrors => ({ ...prevErrors, model: !newValue }));
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filter = createFilterOptions();
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          const isExisting = _.some(options, option => option === inputValue);
                          if (inputValue !== "" && !isExisting) {
                            filtered.push(`${inputValue}`);
                          }
                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        renderInput={params => (
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...params}
                            label="Model"
                            error={errors.model}
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <SerialNumberVinField
                    handleSerialNumberVin={event => {
                      const { target } = event;
                      handleSerialNumberVin(event);
                      setErrors(prevErrors => ({ ...prevErrors, serialNumberVin: !target.value }));
                    }}
                    serialNumberVin={serialNumberVin}
                    errors={errors}
                    isRequired={true}
                    label="Serial #/PIN"
                    classes={classes}
                  />
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Wholesale Cost"
                      id="wholesaleCost"
                      error={errors.wholesaleCost}
                      formControlProps={{ fullWidth: true, required: true }}
                      onChange={event => {
                        const { target } = event;
                        handleWholesaleCost(event);
                        setErrors(prevErrors => ({
                          ...prevErrors,
                          wholesaleCost: amountRegExp.test(target.value)
                            ? !formatCurrency(target.value)
                            : prevErrors.wholesaleCost,
                        }));
                      }}
                      value={wholesaleCost || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Retail Price"
                      id="retailPrice"
                      error={errors.retailPrice}
                      formControlProps={{ fullWidth: true, required: true }}
                      onChange={event => {
                        const { target } = event;
                        handleRetailPrice(event);
                        setErrors(prevErrors => ({
                          ...prevErrors,
                          retailPrice: amountRegExp.test(target.value)
                            ? !formatCurrency(target.value)
                            : prevErrors.retailPrice,
                        }));
                      }}
                      value={retailPrice || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl variant="outlined" error={errors.loanType} required fullWidth>
                      <InputLabel id={`select-label-lt`} className={classes.selectLabel}>
                        Loan Type
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-lt`}
                        value={loanType || ""}
                        onChange={event => {
                          handleLoanType(event);
                          setErrors(prevErrors => ({ ...prevErrors, loanType: !event.target.value }));
                        }}
                        inputProps={{
                          name: "loanType",
                          id: "loanType",
                        }}
                      >
                        <MenuItem value={"Retail Loan"}>Retail Loan</MenuItem>
                        <MenuItem value={"Finance Lease"}>Finance Lease</MenuItem>
                        <MenuItem value={"Operating Lease"}>Operating Lease</MenuItem>
                        <MenuItem value={"Municipal Lease"}>Municipal Lease</MenuItem>
                        <MenuItem value={"Rental Yard"}>Rental Yard</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Term"
                      id="term"
                      error={errors.term}
                      formControlProps={{ fullWidth: true, required: true }}
                      onChange={e => handleIntNumberChange(e, setSelectedTerm)}
                      value={selectedTerm || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl variant="outlined" error={errors.occupation} required fullWidth>
                      <InputLabel id={`select-label-occ`} className={classes.selectLabel}>
                        Occupation
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-occ`}
                        value={selectedOccupation || ""}
                        onChange={event => {
                          handleOccupationSelect(event);
                          setErrors(prevErrors => ({ ...prevErrors, occupation: !event.target.value }));
                        }}
                        inputProps={{
                          name: "equipmentOccupationSel",
                          id: "equipmentOccupationSel",
                        }}
                      >
                        {cnhOccupationOptions.map((option, index) => {
                          const isSelected = selectedOccupation
                            ? selectedOccupation.optionValue === option.optionValue
                            : false;
                          return (
                            <MenuItem key={index} selected={isSelected} value={option}>
                              {option.optionLabel}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <AdditionalDescriptionField
                    handleAdditionalDescription={event => {
                      const { target } = event;
                      handleAdditionalDescription(event);
                      setErrors(prevErrors => ({ ...prevErrors, additionalDescription: !target.value }));
                    }}
                    additionalDescription={additionalDescription}
                    errors={errors}
                    isRequired={true}
                    classes={classes}
                  />
                </Grid>
              </form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "40px",
                }}
              >
                {equipmentId && (
                  <Button onClick={handleRemove} color="secondary" size={"sm"} style={{ marginRight: 8 }}>
                    Remove
                  </Button>
                )}
                <Button onClick={handleCNHSubmit} color="primary" size={"sm"} data-test-id="submit-btn">
                  Save
                </Button>
              </div>
            </div>
          </>
        </TabPanel>
        <TabPanel value={currentTabSelected} index="paccar">
          <>
            <div className={classes.formContainer}>
              <form noValidate autoComplete="off">
                <Grid container spacing={4} className={classes.formContainerGridContainer}>
                  <Grid item xs={12} sm={2} md={2} lg={2}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Quantity"
                      id="equipmentQuantity"
                      formControlProps={{ fullWidth: true }}
                      onChange={e => handleIntNumberChange(e, setQuantity)}
                      value={quantity || "1"}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-newused`} className={classes.selectLabel}>
                        New or Used?
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-newused`}
                        value={newUsed || "new"}
                        onChange={handleNewUsed}
                        inputProps={{
                          name: "equipmentNewUsed",
                          id: "equipmentNewUsed",
                        }}
                      >
                        <MenuItem value={"new"}>New</MenuItem>
                        <MenuItem value={"used"}>Used</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-year2`} className={classes.selectLabel}>
                        Year
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-year2`}
                        value={year || ""}
                        error={errors.year}
                        onChange={handleYear}
                        inputProps={{
                          name: "year",
                          id: "year",
                        }}
                      >
                        {_.range(1990, new Date().getFullYear() + 3).map((k, v) => (
                          <MenuItem value={k} key={`year_${k}`}>
                            {k}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {_.some(paccarManufacturers) && (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`select-label-make2`} className={classes.selectLabel}>
                          Make
                        </InputLabel>
                        <Select
                          className={classes.selectStyles}
                          labelId={`select-label-make2`}
                          value={make || ""}
                          error={errors.make}
                          onChange={handleMakeSelect}
                          inputProps={{
                            name: "equipmentMakeSel",
                            id: "equipmentMakeSel",
                          }}
                        >
                          {paccarManufacturers.map(o => {
                            const selected = make ? make.toLowerCase() === o.name.toLowerCase() : false;
                            return (
                              <MenuItem key={o.paccarCode} selected={selected} value={o.name}>
                                {o.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={truckClassSelectEnabled ? 3 : 6}
                    md={truckClassSelectEnabled ? 3 : 6}
                    lg={truckClassSelectEnabled ? 3 : 6}
                  >
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Model"
                      id="equipmentModel"
                      error={errors.model}
                      formControlProps={{ fullWidth: true }}
                      onChange={handleModel}
                      value={model || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Wholesale Cost"
                      id="wholesaleCost"
                      error={errors.wholesaleCost}
                      formControlProps={{ fullWidth: true }}
                      onChange={handleWholesaleCost}
                      value={wholesaleCost || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Cash Price"
                      id="retailPrice"
                      error={errors.retailPrice}
                      formControlProps={{ fullWidth: true }}
                      onChange={handleRetailPrice}
                      value={retailPrice || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {!!make && !_.isEmpty(assetType) && (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`select-label-at`} className={classes.selectLabel}>
                          Asset Type
                        </InputLabel>
                        <Select
                          className={classes.selectStyles}
                          labelId={`select-label-at`}
                          value={selectedAssetType || ""}
                          error={errors.assetType}
                          onChange={handleAssetTypeSelect}
                          inputProps={{
                            name: "assetTypeSel",
                            id: "assetTypeSel",
                          }}
                        >
                          {filteredAssetType.map(o => {
                            const selected = assetType ? assetType === o.name : false;
                            return (
                              <MenuItem key={o.paccarCode} selected={selected} value={o.name}>
                                {o.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {!!make && !_.isEmpty(cabType) && (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`select-label-cat`} className={classes.selectLabel}>
                          Cab Type
                        </InputLabel>
                        <Select
                          className={classes.selectStyles}
                          labelId={`select-label-cat`}
                          value={selectedCabType || ""}
                          error={errors.cabType}
                          onChange={handleCabTypeSelect}
                          inputProps={{
                            name: "cabTypeSel",
                            id: "cabTypeSel",
                          }}
                        >
                          {filteredCabType.map(o => {
                            const selected = cabType ? cabType === o.name : false;
                            return (
                              <MenuItem key={o.paccarCode} selected={selected} value={o.name}>
                                {o.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {!!make && !_.isEmpty(classType) && (
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`select-label-clt`} className={classes.selectLabel}>
                          Class Type
                        </InputLabel>
                        <Select
                          className={classes.selectStyles}
                          labelId={`select-label-clt`}
                          value={selectedClassType || ""}
                          error={errors.classType}
                          onChange={handleClassTypeSelect}
                          inputProps={{
                            name: "classTypeSel",
                            id: "classTypeSel",
                          }}
                        >
                          {filteredClassType.map(o => {
                            const selected = classType ? classType === o.name : false;
                            return (
                              <MenuItem key={o.paccarCode} selected={selected} value={o.name}>
                                {o.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`select-label-product`} className={classes.selectLabel}>
                        Product
                      </InputLabel>
                      <Select
                        className={classes.selectStyles}
                        labelId={`select-label-product`}
                        value={selectedLenderProduct || ""}
                        onChange={handleLenderProductSelect}
                        inputProps={{
                          name: "lenderProduct",
                          id: "lenderProduct",
                        }}
                      >
                        <MenuItem value={"Retail Loan"}>Retail Loan</MenuItem>
                        <MenuItem value={"Trac Lease"}>Trac Lease</MenuItem>
                        <MenuItem value={"Modified Trac Lease"}>Modified Trac Lease</MenuItem>
                        <MenuItem value={"FMV/Operating Lease"}>FMV/Operating Lease</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Term (in Months)"
                      id="term"
                      error={errors.term}
                      formControlProps={{ fullWidth: true }}
                      onChange={e => handleIntNumberChange(e, setSelectedTerm)}
                      value={selectedTerm || ""}
                      inputProps={{
                        type: "text",
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
              <Snackbar
                open={isUsedInfoAlertOpen}
                autoHideDuration={4000}
                onClose={(event, reason) => {
                  setIsUsedInfoAlertOpen(false);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert severity="info">Submission for Used Equipment are currently not supported.</Alert>
              </Snackbar>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "40px",
                }}
              >
                {equipmentId && (
                  <Button onClick={handleRemove} color="secondary" size={"sm"} style={{ marginRight: 8 }}>
                    Remove
                  </Button>
                )}
                <Button onClick={handlePaccarSubmit} color="primary" size={"sm"} data-test-id="submit-btn">
                  Save
                </Button>
              </div>
            </div>
          </>
        </TabPanel>
        <TabPanel value={currentTabSelected} index="inventorySearch">
          {inventorySearchAccessLoading && (
            <CircularProgress size={24} style={{ margin: "20px auto", display: "block" }} />
          )}
          {!inventorySearchAccessLoading && (
            <div style={{ margin: "16px 0 0" }}>
              <InventorySearch
                vendorOpportunityId={props.vo.vendorOpportunityId}
                handleReloadEquipmentData={handleReloadEquipmentData}
                hasAccessToInventorySearch={hasAccessToInventorySearch}
                refetchDocs={refetchDocs}
              />
            </div>
          )}
        </TabPanel>
      </Tile>
    </div>
  );
}
