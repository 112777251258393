import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";

const styles = {
  dialogTitle: {
    "& .MuiTypography-root": {
      borderBottom: "2px solid #d8d8d8",
    },
  },
  myOrganizationSettings: {
    backgroundColor: "#ebf3fe",
  },
  lendersSettings: {
    backgroundColor: "#f2f2f2",
  },
};

const useStyles = makeStyles(styles);

export default function DocumentAccessSettingsModal({
  isDocumentAccessSettingsModalOpen,
  setIsDocumentAccessSettingsModalOpen,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={isDocumentAccessSettingsModalOpen}
      onClose={() => setIsDocumentAccessSettingsModalOpen(false)}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle className={classes.dialogTitle}>Document Settings</DialogTitle>
      <DialogContent>
        <h4 style={{ fontWeight: "bold" }}>Who should be able to access this file?</h4>
        <Grid container /* spacing={2} */>
          <Grid item xs={6} /* spacing={2} */ className={classes.myOrganizationSettings}>
            Test1
          </Grid>
          <Grid item xs={6} /* spacing={2} */ className={classes.lendersSettings}>
            Test1
          </Grid>
        </Grid>

        <DialogContentText></DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
