import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tile from "../../components/Tile/Tile";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  surface: {
    position: "absolute",
    minWidth: 400,
    maxWidth: 400,
  },
}));

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

export default function CreditAppModal({
  date_applied,
  applicant_name,
  street_address,
  city,
  state,
  postalCode,
  phone,
  dateEstablished,
  tib,
  amountRequested,
  equipmentDescription,
  owner1Fullname,
  owner1Title,
  owner1_percentage,
  owner1_dob,
  owner2_name,
  owner2_title,
  owner2_percentage,
  owner2_dob,
  agreed_to_term,
  rep,
  created_by,
  dealer_name,
  dealer_address,
  dealer_phone,
  dealer_email,
  dealer_website,
  signature,
  date_sign,
}) {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const renderDescription = () => {
    if (!equipmentDescription) {
      return "";
    } else if (equipmentDescription.length > 40) {
      return "Download To View";
    } else {
      return equipmentDescription;
    }
  };

  return (
    <div style={modalStyle} className={classes.surface}>
      <Tile>
        <div>
          <b>Date Applied:</b> {date_applied}
        </div>
        <div>
          <b>Applicant Name:</b> {applicant_name}
        </div>
        <div>
          <b>Street Address:</b> {street_address}
        </div>
        <div>
          <b>City:</b> {city}
        </div>
        <div>
          <b>State:</b> {state}
        </div>
        <div>
          <b>Zipcode:</b> {postalCode}
        </div>
        <br></br>
        <div>
          <b>Applicant Phone:</b> {phone}
        </div>
        <div>
          <b>Date Established:</b> {dateEstablished}
        </div>
        <div>
          <b>TIB: {tib}</b>
        </div>
        <div>
          <b>Amount Requested:</b> {amountRequested}
        </div>
        <div>
          <b>Equipment Description:</b> {renderDescription()}
        </div>
        <br></br>
        <div>
          <b>Owners:</b>{" "}
        </div>
        <div>Owner 1</div>
        <div>Name : {owner1Fullname}</div>
        <div>Title : {owner1Title}</div>
        <div>Ownership Percentage : {owner1_percentage}</div>
        <div>DOB : {owner1_dob}</div>
        <br></br>
        <div>Owner 2</div>
        <div>Name: {owner2_name}</div>
        <div>Title: {owner2_title}</div>
        <div>Ownership Percentage: {owner2_percentage}</div>
        <div>DOB: {owner2_dob}</div>
        <br></br>
        <div>
          <b>Agreed To Term:</b> Yes
        </div>
        <div>
          <b>Rep: {rep}</b>
        </div>
        <div>
          <b>Created_by:</b> Trnsact Admin
        </div>
        <div>
          <b>Dealer name:</b> {dealer_name}
        </div>
        <div>
          <b>Dealer address:</b> {dealer_address}
        </div>
        <div>
          <b>Dealer phone:</b> {dealer_phone}
        </div>
        <div>
          <b>Dealer email:</b> {dealer_email}
        </div>
        <div>
          <b>Dealer website:</b> {dealer_website}
        </div>
        <div>
          <b>Signature:</b> {signature}
        </div>
        <div>
          <b>Date sign:</b> {date_sign}
        </div>
      </Tile>
    </div>
  );
}
