import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import { Check } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useUiStyles = makeStyles(styles);
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  primaryBorderColor: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function FileDescriptionInputModal(props) {
  const classes = { ...useStyles(), ...useUiStyles() };
  const [open, setOpen] = useState(false);

  const handleChange = event => {
    props.setFileType(event.target.value || "");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.fileType === "other" && props.fileDescription === "") return;
    setOpen(false);
    props.handleCloseFileDescriptionInputModal();
  };

  const handleChangeDocDescription = event => {
    props.setFileDescription(event.target.value || "");
  };

  useEffect(() => {
    setOpen(props.openFileDescriptionInputModal);
  }, [props.openFileDescriptionInputModal]);

  const fileTypeOptionsBase = [{ value: "quote", text: "Quote" }, { value: "invoice", text: "Invoice" }];

  const fileTypeOptionsFull = [
    { value: "quote", text: "Quote" },
    { value: "invoice", text: "Invoice" },
    { value: "buyers order", text: "Buyer's Order" },
    { value: "decision letter", text: "Decision Letter" },
    { value: "insurance certificate", text: "Insurance Certificate" },
    { value: "driver license", text: "Driver License" },
    { value: "bank statements", text: "Bank Statements" },
    { value: "tax returns", text: "Tax Returns" },
    { value: "other", text: "Other" },
  ];

  return (
    <div>
      <Dialog disableEscapeKeyDown open={props.openFileDescriptionInputModal} onClose={handleClose}>
        <DialogTitle>Please Describe The Uploading File</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={[classes.selectFormControl, classes.formControl].join(" ")}>
              <InputLabel htmlFor="demo-dialog-native">Document Type</InputLabel>
              <Select
                color="primary"
                classes={{ select: classes.select }}
                value={props.fileType}
                onChange={handleChange}
                inputProps={{ name: "lenderSelect", id: "lender-select" }}
              >
                {props.equipmentUpload
                  ? fileTypeOptionsBase.map((item, index) => (
                      <MenuItem key={index} value={item.value} classes={{ root: classes.selectMenuItem }}>
                        <Checkbox
                          checked={props.fileType === item.value}
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{ checked: classes.checked }}
                        />
                        {item.text}
                      </MenuItem>
                    ))
                  : fileTypeOptionsFull.map((item, index) => (
                      <MenuItem key={index} value={item.value} classes={{ root: classes.selectMenuItem }}>
                        <Checkbox
                          checked={props.fileType === item.value}
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{ checked: classes.checked }}
                        />
                        {item.text}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-multiline-static1"
                label="Document Description"
                multiline
                rows={4}
                value={props.fileDescription}
                className={classes.primaryBorderColor}
                variant="outlined"
                onChange={handleChangeDocDescription}
                required={props.fileType === "other"}
                error={props.fileType === "other"}
                helperText={props.fileType === "other" ? "Please describe your document" : false}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" simple>
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" simple>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
