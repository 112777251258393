import { PortalConfiguration, UserProfile } from "@trnsact/trnsact-shared-types";
import { adminRoles, portalConfigurationTypes } from "../pages/Prequal/constants";
import { PrescreenTypes } from "../global";

const SET_CONFIG_DATA = "SET_CONFIG_DATA";
const UPDATE_CONFIG_DATA = "UPDATE_CONFIG_DATA";

interface Configuration {
  vendorProfileId: string;
  portalConfigurationId: string;
  config: Record<string, unknown>;
}

type State = Partial<Record<string, Configuration>>;

const initialState: State = {};

const portalConfigurationReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_CONFIG_DATA:
      return configurationFromArrToObj(payload);
    case UPDATE_CONFIG_DATA:
      return configurationFromArrToObj(payload);
    default:
      return state;
  }
};

function configurationFromArrToObj(payload: PortalConfiguration[]) {
  if (!Array.isArray(payload) || !payload.length) return {};

  return payload.reduce<Partial<Record<string, Configuration>>>((acc, portalItemConfig) => {
    if (!portalItemConfig?.type) return acc;

    acc[portalItemConfig.type] = {
      portalConfigurationId: portalItemConfig.portalConfigurationId!,
      vendorProfileId: portalItemConfig.vendorProfileId!,
      config: portalItemConfig.jsonDefinition.config!,
    };

    return acc;
  }, {});
}

// Actions

const setConfigData = (payload: PortalConfiguration[] = []) => ({
  type: SET_CONFIG_DATA,
  payload,
});

const updateConfigData = (payload: PortalConfiguration[] = []) => ({
  type: UPDATE_CONFIG_DATA,
  payload,
});

// Selectors

const configDataByType = (state: any, type: string) => state.portalConfiguration?.[type];

// PrescreenModule

const prescreenModuleConfig = (state: any) => configDataByType(state, portalConfigurationTypes.prescreen)?.config;

const prescreenModuleType = (state: any) => prescreenModuleConfig(state)?.prescreenType;

const checkIfPrescreenHiddenConfigSetForUser = (state: any, prop: string, userProfile: UserProfile) => {
  // Check for userProfile?.vendorContactRole is a hotfix since we want to show it by default for HYUNDAI users ONLY.
  // HYUNDAI users have vendorContactRole = credit_mgr, when rest Lenders have null
  return (
    !userProfile?.vendorContactRole || prescreenModuleConfig(state)?.[prop]?.includes(userProfile?.vendorContactRole)
  );
};

const checkIfUserHaveAccessToPrescreenPage = (state: any, userProfile: UserProfile) => {
  const isHiddenByPCW = checkIfPrescreenHiddenConfigSetForUser(state, "hiddenPrescreenPage", userProfile);
  return !isHiddenByPCW || userProfile.adminRole === adminRoles.super;
};

const checkIfUserHaveAccessToPrescreenResults = (state: any, userProfile: UserProfile) => {
  const isHiddenByPCW = checkIfPrescreenHiddenConfigSetForUser(state, "hiddenPrescreenResults", userProfile);
  return !isHiddenByPCW || userProfile.adminRole === adminRoles.super;
};

const checkIfUserHaveAccessToSubmit = (state: any, userProfile: UserProfile) => {
  const isHiddenByPCW = checkIfPrescreenHiddenConfigSetForUser(state, "hiddenPrescreenSubmissionAccess", userProfile);
  return !isHiddenByPCW || userProfile.adminRole === adminRoles.super;
};

const isPrescreenModuleActive = (state: any) => prescreenModuleType(state) !== PrescreenTypes.Off;

const prescreenModuleName = (state: any) =>
  prescreenModuleType(state) === PrescreenTypes.Prescreen
    ? { full: "Prescreen", short: "Prescreen" }
    : { full: "Lender Waterfall", short: "Waterfall" };

const portalConfigurationsActions = {
  setConfigData,
  updateConfigData,
};

const portalConfigurationsSelectors = {
  configDataByType,
  prescreenModuleConfig,
  prescreenModuleType,
  checkIfUserHaveAccessToPrescreenPage,
  checkIfUserHaveAccessToPrescreenResults,
  checkIfUserHaveAccessToSubmit,
  isPrescreenModuleActive,
  prescreenModuleName,
};

export { portalConfigurationReducer, portalConfigurationsSelectors, portalConfigurationsActions };
