import React, { useEffect, useState } from "react";
import { Box, TextField, makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";
import * as _ from "lodash";

import { formatCurrency, formatNumberWithDelimiter } from "../../../../utils";

const styles = {
  textField: {
    margin: "16px 0 0 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
};

const useStyles = makeStyles(styles);

const TextFieldComp = props => {
  const classes = useStyles();
  const {
    field,
    type,
    index,
    handleBlur,
    inputProps,
    setReference,
    setReferenceToRequiredFunction,
    setReferenceToErrorsValueFunction,
    mask,
    validRegexPattern,
  } = props;
  const [value, setValue] = useState(field.mappedValue ? field.mappedValue : "");
  const [required, setRequired] = useState(field.details.required === "REQUIRED");
  const [readOnly, setReadOnly] = useState(field.details.readOnly);
  /*
  Errors have the shape:
  { name: string, message: string }[]
  */
  const [errors, setErrors] = useState(field.details.errors);

  function hasRegexError(value) {
    if (validRegexPattern) {
      const validRegexPatternActual = new RegExp(validRegexPattern);
      if (
        _.isString(value) &&
        value
          .split("")
          .filter(c => validRegexPatternActual.test(c))
          .join("") !== value
      ) {
        return true;
      }
    }
    return false;
  }

  function doesHaveError(valueToCheck, field) {
    try {
      if (errors && errors.length > 0) {
        return true;
      }
    } catch (e) {
      console.log(`Error checking field.details.error in doesHaveError: ${JSON.stringify(field)}: ${e}`);
    }
    return (
      (!valueToCheck && valueToCheck !== 0 && required) || (_.isString(valueToCheck) && hasRegexError(valueToCheck))
    );
  }

  const [showInputErrorHelperText, setShowInputErrorHelperText] = useState(hasRegexError(field.mappedValue));
  const [hasError, setHasError] = useState(doesHaveError(field.mappedValue, field));
  // console.log(field.details.displayName, field.mappedValue);

  const setRequiredValue = newRequiredValue => {
    if (_.isString(newRequiredValue)) {
      newRequiredValue = newRequiredValue === "REQUIRED";
    }
    console.log(`Setting required of ${field.lenderProperty} to ${newRequiredValue}`);
    setRequired(newRequiredValue);
  };

  const setErrorsValue = errors => {
    setErrors(errors);
  };

  const formatValue = value => {
    let val = value;

    if (!val) {
      val = "";
    }

    if (type === "FLOAT" || type === "MONEY") {
      val = parseFloat(
        val
          .toString()
          .replace(/\$/g, "")
          .replace(/,/g, "")
      ).toFixed(2);
      if (!val || isNaN(val)) {
        val = parseFloat(0).toFixed(2);
      }
      if (inputProps.max && val > inputProps.max) {
        val = inputProps.max;
      }
      if (inputProps.min && val < inputProps.min) {
        val = inputProps.min;
      }
    }
    return val;
  };

  setReference(field.lenderProperty, v => {
    setValue(v);
    handleBlur(v, index);
  });
  setReferenceToRequiredFunction(field.lenderProperty, setRequiredValue);
  setReferenceToErrorsValueFunction(field.lenderProperty, setErrorsValue);

  try {
    const maxLength = field.details?.rulesConfiguration?.configuration?.maxLength;
    if (maxLength) {
      inputProps.maxLength = parseInt(maxLength);
    }
  } catch (error) {
    console.error(error);
  }

  try {
    const minLength = field.details?.rulesConfiguration?.configuration?.minLength;
    if (minLength) {
      inputProps.minLength = parseInt(minLength);
    }
  } catch (error) {
    console.error(error);
  }

  useEffect(() => {
    if (field.mappedValue !== value) {
      setValue("");
    }
  }, [props]);

  useEffect(() => {
    if (hasRegexError(value) && field.details?.inputErrorHelperText) {
      setShowInputErrorHelperText(true);
    } else {
      setShowInputErrorHelperText(false);
    }

    if (doesHaveError(value, field)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [value, required]);

  function doesMaskInputHaveAnError() {
    if (errors && errors.length > 0) {
      return true;
    }
    if (required && (field.mappedValue === null || field.mappedValue === undefined)) {
      return true;
    }
    if (!required && (field.mappedValue === null || field.mappedValue === undefined)) {
      return false;
    }
    if (type === "PHONE_NUMBER") {
      const phoneNumberLength = field.mappedValue.toString().replace(/[()\-_\s]/g, "").length;
      if (required) {
        return phoneNumberLength !== 10;
      } else {
        return phoneNumberLength !== 10 && phoneNumberLength !== 0;
      }
    }
    if (type === "SSN" || type === "FEIN") {
      const ssnFeinLength = field.mappedValue.toString().replace(/[-_]/g, "").length;
      if (required) {
        return ssnFeinLength !== 9;
      } else {
        return ssnFeinLength !== 9 && ssnFeinLength !== 0;
      }
    }
    if (hasRegexError(field.mappedValue)) {
      return true;
    }
  }

  let valueToDisplay = value;
  if (type === "FLOAT") {
    valueToDisplay = formatNumberWithDelimiter(formatValue(value));
  } else if (type === "MONEY") {
    valueToDisplay = formatCurrency(formatValue(value));
  }

  return (
    <Box component="form" noValidate autoComplete="off" key={index} style={{ width: "100%" }}>
      {mask ? (
        <>
          <InputMask
            mask={mask}
            value={value}
            onBlur={() => {
              setValue(value);
              handleBlur(value, index);
            }}
            onChange={e => {
              let val = e.target.value;

              setValue(val);
            }}
          >
            {() => (
              <TextField
                key={index}
                className={classes.textField}
                fullWidth={true}
                label={(required ? "* " : "") + field.details.displayName}
                style={{ width: "100%" }}
                error={doesMaskInputHaveAnError()}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </InputMask>
          {errors && errors.length > 0 && (
            <div style={{ color: "red", fontSize: "0.75rem" }}>{errors.map(e => e.message).join(", ")}</div>
          )}
        </>
      ) : (
        <div>
          {type === "FLOAT" || type === "MONEY" ? (
            <NumberFormat
              value={value}
              thousandSeparator={true}
              prefix={type === "MONEY" ? "$" : ""}
              decimalScale={type === "MONEY" ? 2 : undefined}
              fixedDecimalScale={true}
              customInput={TextField}
              fullWidth={true}
              label={(required ? "* " : "") + field.details.displayName}
              variant="outlined"
              onBlur={() => {
                // let formattedValue = formatValue(value, type);
                // setValue(formattedValue);
                handleBlur(value, index);
              }}
              onValueChange={(newValue) => {
                let val = newValue.value;

                if (type === "INTEGER") {
                  val = parseInt(val);
                  if (!val) {
                    val = 0;
                  }
                  if (inputProps.max && val > inputProps.max) {
                    val = inputProps.max;
                  }
                  if (inputProps.min && val < inputProps.min) {
                    val = inputProps.min;
                  }
                }

                if (type === "FLOAT") {
                  val = parseFloat(val.replace(/,/g, ""));
                  if (!val) {
                    val = 0;
                  }
                  if (inputProps.max && val > inputProps.max) {
                    val = inputProps.max;
                  }
                  if (inputProps.min && val < inputProps.min) {
                    val = inputProps.min;
                  }
                }

                if (type === "MONEY") {
                  val = parseFloat(val.replace(/[$]],/g, ""));
                  if (!val) {
                    val = 0;
                  }
                  if (inputProps.max && val > inputProps.max) {
                    val = inputProps.max;
                  }
                  if (inputProps.min && val < inputProps.min) {
                    val = inputProps.min;
                  }
                }

                if ((type !== "STRING" && parseInt(val) < 0) || (type === "YEAR" && parseInt(val) > 9999)) {
                  val = field.mappedValue;
                }

                if (type === "STRING") {
                  if (inputProps.maxLength && val.length > inputProps.maxLength) {
                    val = val.slice(0, inputProps.maxLength);
                  }
                }

                if (type === "INITIALS") {
                  val = val.toUpperCase();
                }

                if (validRegexPattern) {
                  const validRegexPatternActual = new RegExp(validRegexPattern);
                  val = val
                    .split("")
                    .filter(char => validRegexPatternActual.test(char))
                    .join("");
                }

                setValue(val);
                // setValue(newValue);
                // handleBlur(newValue, index); // Pass updated float value for the onChange
              }}
              className={classes.textField}
              error={hasError}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                ...inputProps,
              }}
            />
          ) : (
            <TextField
              key={index}
              className={classes.textField}
              fullWidth={true}
              label={(required ? "* " : "") + field.details.displayName}
              value={valueToDisplay}
              onBlur={() => {
                let formattedValue = formatValue(value, type);
                setValue(formattedValue);
                handleBlur(formattedValue, index);
              }}
              style={{ width: "100%" }}
              onChange={e => {
                let val = e.target.value;

                if (type === "INTEGER") {
                  val = parseInt(val);
                  if (!val) {
                    val = 0;
                  }
                  if (inputProps.max && val > inputProps.max) {
                    val = inputProps.max;
                  }
                  if (inputProps.min && val < inputProps.min) {
                    val = inputProps.min;
                  }
                }

                if (type === "FLOAT") {
                  val = parseFloat(val.replace(/,/g, ""));
                  if (!val) {
                    val = 0;
                  }
                  if (inputProps.max && val > inputProps.max) {
                    val = inputProps.max;
                  }
                  if (inputProps.min && val < inputProps.min) {
                    val = inputProps.min;
                  }
                }

                if ((type !== "STRING" && parseInt(val) < 0) || (type === "YEAR" && parseInt(val) > 9999)) {
                  val = field.mappedValue;
                }

                if (type === "STRING") {
                  if (inputProps.maxLength && val.length > inputProps.maxLength) {
                    val = val.slice(0, inputProps.maxLength);
                  }
                }

                if (type === "INITIALS") {
                  val = val.toUpperCase();
                }

                if (validRegexPattern) {
                  const validRegexPatternActual = new RegExp(validRegexPattern);
                  val = val
                    .split("")
                    .filter(char => validRegexPatternActual.test(char))
                    .join("");
                }

                setValue(val);
                // if (doesHaveError(val, field)) {
                //   setHasError(true);
                // } else {
                //   setHasError(false);
                // }
              }}
              variant="outlined"
              error={hasError}
              inputProps={
                (type !== "STRING" && {
                  type: type !== "STRING" ? "number" : "string",
                  step: type === "FLOAT" ? "0.01" : "1",
                  min: "1",
                  max: type === "YEAR" ? "9999" : undefined,
                },
                { ...inputProps },
                { disabled: readOnly })
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <div>
            {showInputErrorHelperText && (
              <div style={{ color: "red", fontSize: "0.75rem" }}>{field.details?.inputErrorHelperText}</div>
            )}
            {errors && errors.length > 0 && (
              <div style={{ color: "red", fontSize: "0.75rem" }}>{errors.map(e => e.message).join(", ")}</div>
            )}
          </div>
        </div>
      )}
    </Box>
  );
};

export default TextFieldComp;
