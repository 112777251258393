import React from "react";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContactType } from "../../../types";

interface Props {
  contactType: ContactType;
  setContactType: any;
}

export const ContactTypeRadio = ({ contactType, setContactType }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.typeLabel}>
          Type
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 0 }}>
        <FormControl component="fieldset">
          <RadioGroup row value={contactType} onChange={e => setContactType(e.target.value as ContactType)}>
            <FormControlLabel value="contact-only" control={<Radio color="primary" />} label="Contact Only" />
            <FormControlLabel value="signor-only" control={<Radio color="primary" />} label="Signor Only" />
            <FormControlLabel
              value="signor-and-guarantor"
              control={<Radio color="primary" />}
              label="Signor and Guarantor"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  typeLabel: {
    marginBottom: "-22px",
  },
});
