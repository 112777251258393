import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import { convertToDate, formatMoney, parseIntMoneyString, setFieldValue, validateValue } from "../../utils";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useMutation } from "@apollo/react-hooks";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { ArrowBackOutlined } from "@material-ui/icons";
import { theme } from "../../theme";

const config = require("../../config.js");

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const M_UPDATE_LEAD = gql`
  mutation($input: UpdateLeadInput!) {
    updateLead(input: $input)
  }
`;

const Q_LEAD = gql`
  query GetLead($id: ID, $salesRep: Boolean) {
    lead(id: $id, salesRep: $salesRep) {
      id
      accountId
      vendorSalespersonId
      email
      fullName
      amountRequested
      description
      title
      phone
      createdDateTime
      status
      businessName
      vendorSalespersonName
      lastDraftReminderSent
      draftReminderSent
      source
      nextReminderDate
      emailRemindersEnabled
      dealerName
      locationName
      creditAppPhone
      vendorOpportunityId
      equipmentDescription
      businessPhone
    }
  }
`;

const QUERY_SALES_REPS = gql`
  query($dynamicsId: ID) {
    publicAccount(dynamicsId: $dynamicsId) {
      salesReps {
        userProfileId
        dynamicsContactId
        email
        #dynamicsId
        firstName
        lastName
        fullName
        phoneNumber
        mugshot
        availability
        vendorContactRole
        title
      }
    }
  }
`;

const DEACTIVATE_LEAD = gql`
  mutation($id: ID!) {
    deactivateLead(id: $id)
  }
`;

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function LeadDetails({ userProfile, match, setRefetch, account }) {
  const leadId = match.params.leadId;

  const { data: leadDetails, refetch: refetchLead } = useQuery(Q_LEAD, {
    variables: {
      id: leadId,
      salesRep: userProfile.vendorContactRole === "sales_mgr" ? true : false,
    },
  });

  const [salesReps, setSalesReps] = useState([]);
  const [partnerView, setPartnerView] = useState(false);
  useEffect(() => {
    if (account) {
      setPartnerView(account.accountType === "Partner");
    }
  }, [account]);

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fields, setFields] = useState({
    id: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    fullName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    email: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    businessName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    phone: {
      value: "",
      validationStatus: "",
      format: formatPhone,
    },
    description: {
      value: "",
    },
    status: {
      value: "",
    },
    amountRequested: {
      value: "",
      format: formatMoney,
    },
    createdDateTime: {
      value: "",
    },
    title: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    vendorSalespersonId: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    draftReminderSent: {
      value: "",
    },
    lastDraftReminderSent: {
      value: "",
    },
    source: {
      value: "",
    },
    location: {
      value: "",
    },
    nextReminderDate: {
      value: "",
    },
    emailRemindersEnabled: {
      value: "",
    },
  });
  const [formValid, setFormValid] = useState(false);

  const checkFormValidation = () => {
    let status = true;
    Object.keys(fields).forEach(fieldName => {
      if (!!fields[fieldName].validations && fields[fieldName].validations.length) {
        const value = fields[fieldName].value;
        const validations = fields[fieldName].validations;
        fields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (fields[fieldName].validationStatus !== "success" && fieldName !== "vendorSalespersonId") {
          status = false;
        }
      }
    });
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName) => setFieldValue(value, fieldName, fields, setFields);

  useEffect(() => {
    if (leadDetails) {
      const { lead } = leadDetails;

      setFields({
        id: {
          value: lead.id ? lead.id : "",
          validationStatus: "",
        },
        fullName: {
          value: lead.fullName ? lead.fullName : "",
          validationStatus: "",
        },
        email: {
          value: lead.email ? lead.email : "",
          validationStatus: "",
        },
        amountRequested: {
          value: lead.amountRequested ? formatMoney(lead.amountRequested) : "",
          validationStatus: "",
          format: formatMoney,
        },
        businessName: {
          value: lead.businessName ? lead.businessName : "",
          validationStatus: "",
        },
        status: {
          value: lead.status ? lead.status : "",
          validationStatus: "",
        },
        title: {
          value: lead.title ? lead.title : "",
          validationStatus: "",
        },
        phone: {
          value: formatPhone(lead.phone || lead.phoneNumber || lead.creditAppPhone || lead.businessPhone) || "",
          validationStatus: "",
          format: formatPhone,
          // validations: [{ type: "required" }],
        },
        createdDateTime: {
          value: lead.createdDateTime ? convertToDate(lead.createdDateTime) : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        description: {
          value: lead.description || lead.equipmentDescription || "",
          validationStatus: "",
        },
        vendorSalespersonId: {
          value: lead.vendorSalespersonId ? lead.vendorSalespersonId : "",
          validationStatus: "",
          validations: [{ type: "required" }],
        },
        draftReminderSent: {
          value: lead.draftReminderSent ? lead.draftReminderSent : "",
          validationStatus: "",
        },
        lastDraftReminderSent: {
          value: lead.lastDraftReminderSent ? convertToDate(lead.lastDraftReminderSent) : "",
          validationStatus: "",
        },
        source: {
          value: lead.source ? lead.source : "",
          validationStatus: "",
        },
        location: {
          value: lead.locationName ? lead.locationName : "",
          validationStatus: "",
        },
        nextReminderDate: {
          value:
            !!lead.nextReminderDate && !!lead.emailRemindersEnabled && !!lead.emailRemindersEnabled === true
              ? convertToDate(lead.nextReminderDate)
              : "",
          validationStatus: "",
        },
        emailRemindersEnabled: {
          value:
            !!lead.emailRemindersEnabled &&
            !!lead.draftReminderSent &&
            (lead.draftReminderSent >= 3 || lead.emailRemindersEnabled === false)
              ? false
              : lead.emailRemindersEnabled,
          validationStatus: "",
        },
      });
    }
  }, [leadDetails]);

  useEffect(() => {
    if (account.contacts) {
      var salesRepArray;
      salesRepArray = account.contacts.map(item => {
        return {
          value: item.id,
          label: item.title ? item.fullName + ", " + item.title : item.fullName,
        };
      });
      setSalesReps(salesRepArray);
    }
  }, [account]);

  const [updateLead, { data, error, loading }] = useMutation(M_UPDATE_LEAD, {
    context: { authRequired: true },
  });

  const [deactivateLead] = useMutation(DEACTIVATE_LEAD, {
    context: { authRequired: true },
  });

  const handleSubmit = async event => {
    try {
      await updateLead({
        variables: {
          input: {
            id: leadDetails.lead.id,
            fullName: `${fields.fullName.value}`,
            phone: fields.phone.value,
            status: fields.status.value,
            accountId: leadDetails.lead.accountId,
            title: fields.title.value,
            description: fields.description.value,
            email: fields.email.value,
            businessName: fields.businessName.value,
            amountRequested: parseIntMoneyString(fields.amountRequested.value),
            vendorSalespersonId: fields.vendorSalespersonId.value,
            source: parseSource(fields.source.value),
            emailRemindersEnabled: fields.emailRemindersEnabled.value,
          },
        },
      });
      setSnackBarMessage("Successfully updated your lead!");
      setSnackbarOpen(true);
      refetchLead();
    } catch (e) {
      console.log(e);
      setErrorMessage("Error updating lead!");
      setErrorOpen(true);
    }
  };

  const handleDeleteLead = async id => {
    try {
      await deactivateLead({
        variables: {
          id: id,
        },
      });
      setSnackBarMessage("Successfully deleted your lead!");
      setSnackbarOpen(true);
      // await refetch();
    } catch (err) {
      console.log(err);
      setErrorMessage("Error deleting lead!");
      setErrorOpen(true);
    }
  };

  const parseSource = value => {
    let source = value;

    switch (value) {
      case "Dealer Website Product Page":
        source = "PP";
        break;
      case "Dealer Website Inventory List Page":
        source = "ILP";
        break;
      case "Dealer Website Finance Page":
        source = "FP";
        break;
      default:
        source = value;
        break;
    }
    return source;
  };

  const renderSource = value => {
    let source = value;

    switch (value) {
      case "PP":
        source = "Dealer Website Product Page";
        break;
      case "ILP":
        source = "Dealer Website Inventory List Page";
        break;
      case "FP":
        source = "Dealer Website Finance Page";
        break;
      default:
        source = value;
        break;
    }
    return source;
  };

  return (
    <>
      {!!leadDetails && !!salesReps ? (
        <Form
          onSubmit={values => {
            handleSubmit();
          }}
          validate={checkFormValidation}
          initialValues={!!leadDetails.lead}
          render={({ handleSubmit, pristine, valid, submitting, values }) => {
            function uploadButtonHandler(event) {
              const file = document.getElementById("file");
              file.click();
            }
            function uploadFileChanged(event) {
              fields.file.value = event.target.files[0];
              const reader = new FileReader();
              reader.onload = e => {
                const avatar = document.getElementById("avatar");
                setValue(e.target.result, "mugshot");
                avatar.src = e.target.result;
              };
              reader.readAsDataURL(event.target.files[0]);
              checkFormValidation();
            }
            function handleClose() {
              setSnackbarOpen(false);
              setErrorOpen(false);
            }

            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit().then(res => console.log(res));
                }}
              >
                <Grid container>
                  <GridItem lg={6}>
                    <Tile>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          onClick={() => (window.location.href = "/leads")}
                          color="primary"
                          style={{ padding: 8 }}
                        >
                          <ArrowBackOutlined />
                          Back
                        </Button>
                        <div style={{ display: "flex", columnGap: 8 }}>
                          {leadDetails.lead.vendorOpportunityId && (
                            <a
                              onClick={() => (window.location.href = `/vo/${leadDetails.lead.vendorOpportunityId}`)}
                              style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: "pointer" }}
                            >
                              View Opportunity
                            </a>
                          )}
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">Lead Info</Typography>
                                {" Leads are created automatically when:"} <br />
                                1. You send your customer a credit application link, or
                                <br />
                                2. When a prospective Customer visits your application link, but fails to submit the
                                application.
                                <br />
                                <br />
                                In both cases, your customer will receive reminder emails to complete their application.
                                You will be CC'd on these emails. Reminder emails are sent following the below schedule:
                                <br />
                                • 1st email: 15 minutes from last modified.
                                <br />
                                • 2nd email: 3 days from last reminder.
                                <br />
                                • 3rd email: 7 days from last reminder.
                                <br />
                                <br />
                                *Reminders will stop once the application is completed successfully. You may disable
                                reminders for any customer using the Disable button below.
                              </React.Fragment>
                            }
                          >
                            <InfoIcon color="primary"></InfoIcon>
                          </HtmlTooltip>
                        </div>
                      </div>
                      <Grid container alignItems="center">
                        <GridItem xs={12} sm={6} md={6} lg={6} xl={6} spacing={0}>
                          <Grid container>
                            {partnerView ? (
                              <GridItem xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  <b>Dealer ({leadDetails.lead.dealerName})</b>
                                </div>
                              </GridItem>
                            ) : null}
                          </Grid>
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3} lg={6}>
                          {salesReps.length !== 0 && userProfile.vendorContactRole !== "sales_rep" && (
                            <FormControl style={{ paddingTop: "6%" }} fullWidth>
                              {partnerView ? (
                                <p
                                  style={{
                                    fontWeight: "bolder",
                                    fontSize: "16px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Partner Dealer Contact: {leadDetails.lead.vendorSalespersonName}
                                </p>
                              ) : (
                                <>
                                  <InputLabel
                                    style={{
                                      color: "#AAAAAA !important",
                                      fontWeight: "400",
                                      fontSize: "14px",
                                      lineHeight: "1.42857",
                                      top: "10px",
                                      letterSpacing: "unset",
                                      "& + $underline": {
                                        marginTop: "0px",
                                      },
                                    }}
                                    id="vendor-salesperson-id-label"
                                  >
                                    Sales Rep
                                  </InputLabel>
                                  <Select
                                    id="vendorSalespersonId"
                                    label="Sales Rep"
                                    name="vendorSalespersonId"
                                    variant="standard"
                                    autoWidth
                                    required
                                    inputProps={{
                                      disabled: partnerView,
                                      type: "text",
                                      name: "vendorSalespersonId",
                                      value: fields.vendorSalespersonId.value,
                                      onChange: e => {
                                        setValue(e.target.value, "vendorSalespersonId");
                                      },
                                    }}
                                  >
                                    {!!salesReps && salesReps.length > 1
                                      ? salesReps.map(({ value, label }, i) => (
                                          <MenuItem value={value} key={i} label={label}>
                                            {label}
                                          </MenuItem>
                                        ))
                                      : "No Sales Reps Available"}
                                  </Select>
                                </>
                              )}
                            </FormControl>
                          )}
                        </GridItem>
                      </Grid>
                      <br />
                      <Grid container>
                        <GridItem xs={12} sm={4} md={4} lg={6}>
                          <CustomInput
                            labelText="Created On"
                            id="createdDateTime"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled
                            inputProps={{
                              disabled: true,
                              type: "text",
                              name: "createdDateTime",
                              value: fields.createdDateTime.value,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={5}>
                          <CustomInput
                            labelText="Status"
                            id="status"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            disabled
                            inputProps={{
                              disabled: true,
                              type: "text",
                              name: "status",
                              value: fields.status.value,
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Business Name"
                            id="businessName"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: partnerView,
                              type: "text",
                              name: "businessName",
                              value: fields.businessName.value,
                              onChange: e => setValue(e.target.value, "businessName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Amount Requested"
                            id="amountRequested"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: partnerView,
                              type: "text",
                              name: "amountRequested",
                              value: fields.amountRequested.value,
                              onChange: e => setValue(e.target.value, "amountRequested"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Contact Name"
                            id="fullName"
                            formControlProps={{ fullWidth: true }}
                            error={fields.fullName.validationStatus === "error"}
                            inputProps={{
                              disabled: partnerView,
                              type: "text",
                              name: "fullName",
                              value: fields.fullName.value,
                              onChange: e => setValue(e.target.value, "fullName"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Title"
                            id="title"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              disabled: partnerView,
                              type: "text",
                              name: "title",
                              value: fields.title.value,
                              onChange: e => setValue(e.target.value, "title"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Phone Number"
                            id="phone"
                            formControlProps={{ fullWidth: true }}
                            error={fields.phone.validationStatus === "error"}
                            disabled
                            inputProps={{
                              type: "text",
                              disabled: partnerView,
                              name: "phone",
                              value: fields.phone.value,
                              onChange: e => setValue(e.target.value, "phone"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                              type: "text",
                              disabled: partnerView,
                              name: "email",
                              value: fields.email.value,
                              onChange: e => setValue(e.target.value, "email"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <CustomInput
                            labelText="Equipment Description"
                            id="description"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              disabled: partnerView,
                              name: "description",
                              value: fields.description.value,
                              onChange: e => setValue(e.target.value, "description"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <CustomInput
                            labelText="Source"
                            id="source"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              disabled: partnerView,
                              name: "source",
                              value: renderSource(fields.source.value),
                              onChange: e => setValue(e.target.value, "source"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <CustomInput
                            labelText="Location"
                            id="location"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              type: "text",
                              disabled: true,
                              name: "location",
                              value: fields.location.value,
                              onChange: e => setValue(e.target.value, "location"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <h4>Communication Detail</h4>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Last Reminder Sent On"
                            id="last-reminder-date"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: partnerView,
                              type: "text",
                              name: "lastDraftReminderSent",
                              value: fields.lastDraftReminderSent.value,
                              onChange: e => setValue(e.target.value, "lastDraftReminderSent"),
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="# of Reminder Emails Sent"
                            id="reminders-sent"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: partnerView,
                              type: "text",
                              name: "draftReminderSent",
                              value: fields.draftReminderSent.value,
                              onChange: e => setValue(e.target.value, "draftReminderSent"),
                            }}
                          />
                        </GridItem>
                      </Grid>
                      <Grid container>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <CustomInput
                            labelText="Next Reminder Date"
                            id="next-reminder"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: partnerView,
                              type: "text",
                              name: "nextReminderDate",
                              value: fields.nextReminderDate.value,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <FormControl style={{ paddingTop: "6%" }} fullWidth>
                            <InputLabel
                              style={{
                                color: "#AAAAAA !important",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "1.42857",
                                top: "10px",
                                letterSpacing: "unset",
                                "& + $underline": {
                                  marginTop: "0px",
                                },
                              }}
                              id="email-reminders-label"
                            >
                              Email Reminders
                            </InputLabel>
                            <Select
                              id="email-reminders-select"
                              label="Email Reminder Status"
                              name="emailRemindersEnabled"
                              variant="standard"
                              autoWidth
                              disabled={
                                fields.draftReminderSent.value >= 3 || fields.emailRemindersEnabled.value === false
                              }
                              required
                              inputProps={{
                                type: "text",
                                disabled: partnerView,
                                name: "emailRemindersEnabled",
                                value: fields.emailRemindersEnabled.value,
                                onChange: e => {
                                  setValue(e.target.value, "emailRemindersEnabled");
                                },
                              }}
                            >
                              <MenuItem value={true}>Enabled</MenuItem>
                              <MenuItem value={false}>Disabled</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </Grid>
                      <br />
                      <Grid container justify="center" alignItems="center">
                        <GridItem xs={8} sm={6} md={6} lg={6}>
                          <Button
                            className="formBtn"
                            color="primary"
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={partnerView || submitting || !formValid}
                            style={{ padding: 8 }}
                          >
                            {submitting ? "Updating Lead" : "Update Lead"}
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                            <Button
                              disabled={partnerView}
                              className="formBtn"
                              color="primary"
                              variant="outlined"
                              onClick={() => handleDeleteLead(leadDetails.lead.id)}
                              style={{ padding: 8 }}
                            >
                              Delete Lead
                            </Button>
                          </div>
                        </GridItem>
                      </Grid>
                    </Tile>
                  </GridItem>
                </Grid>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
                <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              </form>
            );
          }}
        />
      ) : (
        "Loading Leads"
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    account: state.account,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadDetails);
