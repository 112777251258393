import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InsertionContainer } from "../PortalConfigurationWizard/CreditAppDetailModal/InsertionContainer";
import CreateEditProposalProductModal from "./CreateEditProposalProductModal";
import DraggableProductItem from "./DraggableProductItem";
import _ from "lodash";

const styles = {
  root: {},
  list: {
    width: "100%",
  },
  addNewListItem: {
    flexDirection: "column",
    "& .MuiListItemText-root": {
      marginTop: 10,
    },
  },
};

const useStyles = makeStyles(styles);

const ProposalMenuSidebar = ({ products, setProductPackageToEdit, refetchProducts, refetchProposalMenu }) => {
  const classes = useStyles();

  const [expandedStates, setExpandedStates] = useState({ products: true });

  const [proposalProductToEdit, setProposalProductToEdit] = useState();
  const [isCreateEditProposalProductModalOpen, setIsCreateEditProposalProductModalOpen] = useState(false);

  const handleCreateProductPackageClick = () => {
    const emptyProductPackage = {
      name: "",
      products: [],
    };
    setProductPackageToEdit(emptyProductPackage);
  };

  const handleEditProductPackageClick = productPackageToEdit => {
    const productPackageToEditCopy = _.cloneDeep(productPackageToEdit);
    setProductPackageToEdit(productPackageToEditCopy);
  };

  const handleCreateProductClick = () => {
    const emptyProduct = {
      title: "",
      cost: null,
      retailCost: null,
      thirdPartyUrl: "",
    };
    setProposalProductToEdit(emptyProduct);
    setIsCreateEditProposalProductModalOpen(true);
  };

  const handleEditProductClick = productToEdit => {
    const productToEditCopy = _.cloneDeep(productToEdit);
    setProposalProductToEdit(productToEditCopy);
    setIsCreateEditProposalProductModalOpen(true);
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expandedStates.products}
        onChange={() => setExpandedStates({ ...expandedStates, products: !expandedStates.products })}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="products-content" id="products-header">
          <Typography variant="h5">Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav" className={classes.list}>
            <ListItem button className={classes.addNewListItem} onClick={handleCreateProductClick}>
              <InsertionContainer />
              <ListItemText primary="Add new Product" />
            </ListItem>
            {products?.map(product => (
              <DraggableProductItem
                key={product.proposalProductId}
                product={product}
                handleEditProductClick={handleEditProductClick}
                refetchProducts={refetchProducts}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <CreateEditProposalProductModal
        isOpen={isCreateEditProposalProductModalOpen}
        setIsOpen={setIsCreateEditProposalProductModalOpen}
        proposalProduct={proposalProductToEdit}
        setProposalProduct={setProposalProductToEdit}
        refetchProducts={refetchProducts}
      />
    </div>
  );
};

export default ProposalMenuSidebar;
