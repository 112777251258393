import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import calendar from "assets/img/icons/calendar.png";
import Table from "../../components/Table/Table";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import LocationsTreeViewDropdown from "../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../services/locationsService";
import _ from "lodash";

import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Q_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      accountDynamicsId
      ocaContactId
      ocaContact {
        fullName
      }
      contactIds
      locationParentId
      notificationAppReceipt
      childrenLocation {
        locationId
        locationName
      }
      locationParentName
    }
  }
`;

const Q_FETCH_REPORTS_LOCATION = gql`
  query reportsVoByLocation($accountId: ID!, $from: Date!, $to: Date!, $locationId: ID) {
    reportsVoByLocation(accountId: $accountId, from: $from, to: $to, locationId: $locationId) {
      day
      totalByLocation
    }
  }
`;

const Q_FETCH_REPORTS = gql`
  query reportVOsByAccount($accountId: ID!) {
    reportVOsByAccountPerWeek(accountId: $accountId) {
      total
      week
    }
    reportVOsByAccountPerMonth(accountId: $accountId) {
      total
      month
    }
  }
`;

const Q_FETCH_REPORTS_WITH_DATE = gql`
  query reportVOsByAccountWithDateRange($accountId: ID!, $from: Date!, $to: Date!, $locationId: ID) {
    reportVOStagesByAccountInDateRange(accountId: $accountId, from: $from, to: $to, locationId: $locationId) {
      day
      totalByStage
    }
  }
`;

const Q_FETCH_REPORTS_SALES_REP_WITH_DATE = gql`
  query reportVOsByAccountWithDateRange($accountId: ID!, $from: Date!, $to: Date!, $stage: String) {
    reportVOsByAccountPerSalesRep(accountId: $accountId, from: $from, to: $to, stage: $stage) {
      total
      salesRepresentativeId
      salesRepresentative {
        fullName
      }
    }
  }
`;

const Q_FETCH_PARTNER_REPORT = gql`
  query reportVOsForPartner($dynamicsAccountId: ID!, $from: Date!, $to: Date!) {
    reportVOsForPartner(dynamicsAccountId: $dynamicsAccountId, from: $from, to: $to) {
      total
      accountName
    }
  }
`;

const Q_FETCH_PARTNER_REPORTS_WITH_DATE = gql`
  query reportVOsByPartnerWithDateRange($partnerAccountDynamicsId: ID!, $from: Date!, $to: Date!) {
    reportVOStagesByPartnerProfileInDateRange(
      partnerAccountDynamicsId: $partnerAccountDynamicsId
      from: $from
      to: $to
    ) {
      day
      totalByStage
    }
  }
`;

const Q_FETCH_REPORTS_PARTNER_REP = gql`
  query reportVOsByPartner($dynamicsAccountId: ID!, $from: Date!, $to: Date!) {
    reportVOStagesByPartnerLinkContact(dynamicsAccountId: $dynamicsAccountId, from: $from, to: $to) {
      total
      partnerRepId
      partnerRep {
        fullName
      }
    }
  }
`;

const Q_FETCH_REPORTS_PARTNER_DEALERS_REP = gql`
  query PartnerDealersContactReport($dynamicsAccountId: ID!) {
    partnerDealersContactReport(dynamicsAccountId: $dynamicsAccountId) {
      totalMissingPrimaryContact
      totalDealers
      partnerRep {
        fullName
      }
    }
  }
`;

const useStyles = makeStyles({
  datePicker: {
    fontSize: "14px",
    width: "120px",
    textAlign: "right",
    padding: "5px",
    border: "1px solid #CCC",
    borderRadius: "5px",
    backgroundImage: `url(${calendar})`,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
  },
  calendarContainer: {
    marginBottom: "20px",
    borderBottom: "1px solid #ccc1c1",
    padding: "10px",
  },
  salesRepContainer: {
    marginTop: "30px",
  },
  noResults: {
    fontSize: "1rem",
    marginTop: "15px",
  },
  separator: {
    borderTop: "1px solid #a2adb3",
  },
  categoryHeader: {
    fontVariant: "small-caps",
    fontSize: "15px",
    color: "black",
  },
  indentItem: {
    paddingLeft: "2rem",
    fontSize: "14px",
  },
});

const ReportsPage = () => {
  const classes = useStyles();
  const account = useSelector(state => state.account);

  const [yesterday, setYesterday] = useState(new Date().setDate(new Date().getDate() - 1));
  const [today, setToday] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [filterByStage, setFilterByStage] = useState("all");
  const [VOPerWeek, setVOPerWeek] = useState([]);
  const [VOPerMonth, setVOPerMonth] = useState([]);
  const [VOPerSalesRep, setVOPerSalesRep] = useState([]);
  const [VOStagesInDateRange, setVOStagesInDateRange] = useState([]);
  const [VOPerLocationInDateRange, setVOPerLocationInDateRange] = useState([]);
  const [dealerPartnerPerSalesRepDataReport, setDealerPartnerPerSalesRepDataReport] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setMonth(new Date().getMonth() - 3));
  const [endDate, setEndDate] = useState(new Date());
  const [partnerVOs, setPartnerVOs] = useState([]);
  const [locationsTree, setLocationsTree] = useState();
  const [locationChartLabels, setLocationChartLabels] = useState([]);
  const [locId, setLocId] = useState(null);

  const [getLocations, { data: locationsData, loading: loadingLocations }] = useLazyQuery(Q_LOCATIONS, {
    context: { authRequired: true },
  });

  const [getReportDataWithoutRange, { data: reportDataWithoutRange, loading: loadingReports }] = useLazyQuery(
    Q_FETCH_REPORTS,
    {
      context: { authRequired: true },
      variables: {
        accountId: account.id,
      },
    }
  );

  const [getReportDataInDateRange, { data: reportDataInDateRange, loading: loadingDataInRangeChart }] = useLazyQuery(
    Q_FETCH_REPORTS_WITH_DATE,
    {
      context: { authRequired: true },
      variables: {
        accountId: account.id,
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
        locationId: locId,
      },
    }
  );

  const [
    getReportDataByLocationInDateRange,
    { data: reportDataByLocationInDateRange, loading: loadingDataByLocationInRangeChart },
  ] = useLazyQuery(Q_FETCH_REPORTS_LOCATION, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
      locationId: locId,
    },
  });

  const [
    getReportDataInDateRangeSalesRep,
    { data: reportDataSalesChart, loading: loadingDataSalesRepChart },
  ] = useLazyQuery(Q_FETCH_REPORTS_SALES_REP_WITH_DATE, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
      stage: filterByStage !== "all" ? filterByStage : undefined,
    },
  });

  const [getPartnerReport, { data: partnerReportData, loading: loadingPartnerReportData }] = useLazyQuery(
    Q_FETCH_PARTNER_REPORT,
    {
      context: { authRequired: true },
      variables: {
        dynamicsAccountId: account.dynamicsAccountId,
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
      },
    }
  );

  const [
    getDealerPartnerPerSalesRepData,
    { data: dealerPartnerPerSalesRepData, loading: loadingDealersPerSalesRepReportData },
  ] = useLazyQuery(Q_FETCH_REPORTS_PARTNER_DEALERS_REP, {
    context: { authRequired: true },
    variables: {
      dynamicsAccountId: account.dynamicsAccountId,
    },
  });

  const [
    getPartnerReportDataInDateRange,
    { data: partnerReportDataInDateRange, loading: loadingPartnerDataInRangeChart },
  ] = useLazyQuery(Q_FETCH_PARTNER_REPORTS_WITH_DATE, {
    context: { authRequired: true },
    variables: {
      partnerAccountDynamicsId: account.dynamicsAccountId,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
    },
  });

  const [
    getPartnerContactReport,
    { data: partnerContactReportData, loading: loadingPartnerContactReportData },
  ] = useLazyQuery(Q_FETCH_REPORTS_PARTNER_REP, {
    context: { authRequired: true },
    variables: {
      dynamicsAccountId: account.dynamicsAccountId,
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
    },
  });

  useEffect(() => {
    if (startDate && endDate && account) {
      if (!locationsData && !loadingLocations) {
        getLocations();
      }
      if (account.accountType === "Vendor") {
        getReportDataInDateRange();
        getReportDataInDateRangeSalesRep();
        getReportDataByLocationInDateRange();
      }
      if (account.accountType === "Partner") {
        getPartnerReportDataInDateRange();
      }
    }
  }, [account, startDate, endDate, filterByStage]);

  useEffect(() => {
    if (reportDataSalesChart) {
      setVOPerSalesRep(_.get(reportDataSalesChart, "reportVOsByAccountPerSalesRep", []));
    }

    if (reportDataInDateRange) {
      setVOStagesInDateRange(
        reportDataInDateRange.reportVOStagesByAccountInDateRange
          ? reportDataInDateRange.reportVOStagesByAccountInDateRange.map(item => {
              return {
                day: item.day,
                ...item.totalByStage,
              };
            })
          : []
      );
    }

    if (reportDataByLocationInDateRange) {
      const resultToRender = reportDataByLocationInDateRange.reportsVoByLocation.map(item => {
        const itemParsed = item.totalByLocation.map(loc => {
          return {
            [loc.locationId]: loc.total,
          };
        });

        return _.merge(
          {
            day: item.day,
          },
          ...itemParsed
        );
      });

      setVOPerLocationInDateRange(reportDataByLocationInDateRange.reportsVoByLocation ? resultToRender : []);
    }
    if (partnerReportDataInDateRange) {
      setVOStagesInDateRange(
        partnerReportDataInDateRange.reportVOStagesByPartnerProfileInDateRange
          ? partnerReportDataInDateRange.reportVOStagesByPartnerProfileInDateRange.map(item => {
              return {
                day: item.day,
                ...item.totalByStage,
              };
            })
          : []
      );
    }
  }, [reportDataInDateRange, reportDataSalesChart, partnerReportDataInDateRange, reportDataByLocationInDateRange]);

  useEffect(() => {
    if (account) {
      getReportDataWithoutRange();
    }
  }, [getReportDataWithoutRange, account]);

  useEffect(() => {
    if (reportDataWithoutRange) {
      setVOPerWeek(_.get(reportDataWithoutRange, "reportVOsByAccountPerWeek", []));
      setVOPerMonth(_.get(reportDataWithoutRange, "reportVOsByAccountPerMonth", []));
    }
  }, [reportDataWithoutRange]);

  const handleFilterByStage = e => {
    setFilterByStage(e.target.value);
  };

  useEffect(() => {
    if (account && account.accountType === "Partner") {
      getPartnerReport();
      getDealerPartnerPerSalesRepData();
      getPartnerContactReport();
    }
  }, [account]);

  useEffect(() => {
    if (partnerReportData) {
      setPartnerVOs(partnerReportData.reportVOsForPartner);
    }
    if (partnerContactReportData) {
      setVOPerSalesRep(_.get(partnerContactReportData, "reportVOStagesByPartnerLinkContact", []));
    }

    if (dealerPartnerPerSalesRepData) {
      //Add data for Table Report

      const reportRows = dealerPartnerPerSalesRepData.partnerDealersContactReport.map(ppRep => {
        return [ppRep.partnerRep.fullName, ppRep.totalDealers, ppRep.totalMissingPrimaryContact];
      });

      //Summarize for last row
      const totalDealersSummarized = _.sumBy(dealerPartnerPerSalesRepData.partnerDealersContactReport, function(ppRep) {
        return ppRep.totalDealers;
      });
      const missingPrimaryContactsSummarized = _.sumBy(
        dealerPartnerPerSalesRepData.partnerDealersContactReport,
        function(ppRep) {
          return ppRep.totalMissingPrimaryContact;
        }
      );

      setDealerPartnerPerSalesRepDataReport([
        ...reportRows,
        ["TOTAL: ", totalDealersSummarized, missingPrimaryContactsSummarized],
      ]);
    }
  }, [partnerReportData, partnerContactReportData, dealerPartnerPerSalesRepData]);

  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: selectedNode => setLocId(selectedNode.value),
    texts: { placeholder: "Choose Location" },
  });
  const simpleHash = input => {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash;
  };

  const stringToColorHex = inputString => {
    const hash = simpleHash(inputString);

    const hex = Math.abs(hash).toString(16);

    return "#" + hex.padEnd(6, "0").substring(0, 6);
  };
  useEffect(() => {
    if (locationsData) {
      const options = _.map(locationsData.locations, location => {
        return {
          ...location,
          checked: location.locationId == locId,
        };
      });
      setLocationChartLabels(
        locationsData.locations.map(l => {
          return {
            ...l,
            hexColor: stringToColorHex(l.locationId),
          };
        })
      );
      options.push({ locationId: null, locationName: "Unassigned", checked: locId == null });

      setLocationsTree(buildTree(options));
    }
  }, [locationsData, locId]);

  const reportTableHead = ["Sales Rep", "Total Dealers", "Missing Primary Contact"];
  const [tabSelected, setTabSelect] = useState("1");

  const handleTabChange = (event, newValue) => {
    setTabSelect(newValue);
  };

  if (account.accountType === "Partner") {
    return (
      <>
        <Grid container>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <h4>Dealers by Sales Rep</h4>
            <Grid container>
              {loadingDealersPerSalesRepReportData ? (
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <LinearProgress />
                </Grid>
              ) : (
                <Grid container>
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <Table
                      tableHead={reportTableHead}
                      tableData={dealerPartnerPerSalesRepDataReport}
                      tableHeaderColor="primary"
                      colorsColls={["primary"]}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <hr className={classes.separator} />
          </Grid>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <h4>Opportunities by Date Range</h4>
          </Grid>
          <Grid container className={classes.calendarContainer}>
            <Grid item lg={3} sm={6} xs={6} md={3}>
              <span>From: </span>
              <DatePicker
                selected={startDate}
                className={classes.datePicker}
                onChange={date => setStartDate(date)}
                selectsStart
                dateFormat="yyyy-MM-dd"
                startDate={startDate}
                endDate={endDate}
                maxDate={yesterday}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={6} md={3}>
              <span>To: </span>
              <DatePicker
                className={classes.datePicker}
                selected={endDate}
                dateFormat="yyyy-MM-dd"
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={today}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <h4>Opportunities By Partner Dealers</h4>
            </Grid>
            <Grid container>
              {loadingPartnerReportData ? (
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <LinearProgress />
                </Grid>
              ) : (
                <Grid container>
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <ResponsiveContainer height={300}>
                      <BarChart data={partnerVOs}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="accountName" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar label="Total" dataKey="total" stackId="a" fill="#9ccc65" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container>
            {loadingPartnerDataInRangeChart ? (
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <LinearProgress />
              </Grid>
            ) : (
              <Grid container>
                <Grid item lg={12} sm={12} xs={12} md={12}>
                  <h4>Opportunities By Stages</h4>
                </Grid>
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <ResponsiveContainer height={300}>
                    <BarChart data={VOStagesInDateRange}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="day"
                        tickFormatter={date => {
                          return format(new Date(date), "yyyy-MM-dd");
                        }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar label="Approved" dataKey="approved" stackId="a" fill="#9ccc65" />
                      <Bar dataKey="prospect" stackId="a" fill="#78909c" />
                      <Bar dataKey="cancelled" stackId="a" fill="#d32f2f" />
                      <Bar dataKey="pre_qualified" stackId="a" fill="#007ac1" />
                      <Bar dataKey="funded" stackId="a" fill="#039be5" />
                      <Bar dataKey="dealer_cancelled" stackId="a" fill="#bf360c" />
                      <Bar dataKey="declined" stackId="a" fill="#d50000" />
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {loadingPartnerContactReportData ? (
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <LinearProgress />
          </Grid>
        ) : VOPerSalesRep ? (
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <h4>Opportunities By Rep</h4>
            </Grid>
            <ResponsiveContainer height={500}>
              <BarChart data={VOPerSalesRep} layout="vertical" margin={{ top: 15, right: 0, left: 50, bottom: 5 }}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis type="number" />
                <YAxis type="category" dataKey="partnerRep.fullName" />
                <Tooltip />

                <Bar dataKey="total" stackId="a" fill="#435463"></Bar>
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        ) : (
          <p className={classes.noResults}>No sales representatives found</p>
        )}
      </>
    );
  }
  const handleSetCalendar = e => {
    e.preventDefault();
    const range = e.currentTarget.id;
    let startDate, endDate;

    switch (range) {
      case "today":
        startDate = new Date();
        endDate = new Date();
        break;
      case "yesterday":
        startDate = addDays(new Date(), -1);
        endDate = addDays(new Date(), -1);
        break;
      case "this_week":
        startDate = startOfWeek(new Date());
        endDate = endOfWeek(new Date());
        break;
      case "last_week":
        startDate = startOfWeek(addDays(new Date(), -7));
        endDate = endOfWeek(addDays(new Date(), -7));
        break;
      case "this_month":
        startDate = startOfMonth(new Date());
        endDate = endOfMonth(new Date());
        break;
      case "last_month":
        startDate = startOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        endDate = endOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        break;
      case "this_quarter":
        startDate = startOfQuarter(new Date());
        endDate = endOfQuarter(new Date());
        break;
      case "last_quarter":
        startDate = startOfQuarter(addMonths(new Date(), -3));
        endDate = endOfQuarter(addMonths(new Date(), -3));
        break;
      case "this_year":
        startDate = startOfYear(new Date());
        endDate = endOfYear(new Date());
        break;
      case "last_year":
        startDate = startOfYear(addDays(new Date(), -365));
        endDate = endOfYear(addDays(new Date(), -365));
        break;
    }

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const quickDateActions = key => {
    const optionList = [
      { lbl: "Today", id: "today" },
      { lbl: "Yesterday", id: "yesterday" },
      { lbl: "This Week", id: "this_week" },
      { lbl: "Last Week", id: "last_week" },
      { lbl: "This Month", id: "this_month" },
      { lbl: "Last Month", id: "last_month" },
      { lbl: "This Quarter", id: "this_quarter" },
      { lbl: "Last Quarter", id: "last_quarter" },
      { lbl: "This Year", id: "this_year" },
      { lbl: "Last Year", id: "last_year" },
    ];
    return (
      <Box
        key={key}
        sx={{
          height: "100%",
          display: "flex",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup orientation="vertical" variant="contained" style={{ position: "absolute", minWidth: "25vh" }}>
          {optionList.map(opt => (
            <Button size="small" id={opt.id} key={opt.id} onClick={handleSetCalendar}>
              {opt.lbl}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );
  };
  return (
    <Grid container>
      <Grid container className={classes.calendarContainer} spacing={2}>
        <Grid item lg={12} sm={12} xs={12} md={12}>
          <Typography variant="h6">Filters:</Typography>
        </Grid>

        <Grid item lg={2} sm={6} xs={6} md={3}>
          <span>From: </span>
          <DatePicker
            selected={startDate}
            className={classes.datePicker}
            onChange={date => setStartDate(date)}
            selectsStart
            dateFormat="MM-dd-yyyy"
            startDate={startDate}
            endDate={endDate}
            maxDate={yesterday}
          >
            {quickDateActions("startDateCal")}
          </DatePicker>
        </Grid>
        <Grid item lg={2} sm={6} xs={6} md={3}>
          <span>To: </span>
          <DatePicker
            className={classes.datePicker}
            selected={endDate}
            dateFormat="MM-dd-yyyy"
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={today}
          >
            {quickDateActions("endDateCal")}
          </DatePicker>
        </Grid>
        <Grid item lg={4} xs={12} md={8} sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="repSelect">Filter by Application Stage</InputLabel>
            <Select
              value={filterByStage}
              id="repSelect"
              onChange={handleFilterByStage}
              inputProps={{
                name: "filterByStage",
                id: "filterByStage",
              }}
            >
              <MenuItem value={"all"}>-- All --</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"prospect"}>Prospect</MenuItem>
              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"pre_qualified"}>Pre Qualified</MenuItem>
              <MenuItem value={"funded"}>Funded</MenuItem>
              <MenuItem value={"dealer_cancelled"}>Dealer Canceled</MenuItem>
              <MenuItem value={"declined"}>Declined</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} xs={12} md={8} sm={12}>
          <FormControl fullWidth style={{ paddingTop: "10px" }}>
            <LocationsTreeViewDropdown tree={locationsTree} treeSelectOptions={treeSelectOptions} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <TabContext value={tabSelected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Reporting" variant="fullWidth">
              <Tab label="By Stages" value="1" />
              <Tab label="By Sales Representative" value="2" />
              <Tab label="By Location" value="3" />
              <Tab label="Grouped By" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                {loadingDataInRangeChart ? (
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <LinearProgress />
                  </Grid>
                ) : (
                  <ResponsiveContainer height={500}>
                    <BarChart data={VOStagesInDateRange}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="day"
                        tickFormatter={date => {
                          return format(new Date(date), "MM-dd-yyyy");
                        }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="approved" stackId="a" fill="#9ccc65" />
                      <Bar dataKey="prospect" stackId="a" fill="#78909c" />
                      <Bar dataKey="cancelled" stackId="a" fill="#d32f2f" />
                      <Bar dataKey="pre_qualified" stackId="a" fill="#007ac1" />
                      <Bar dataKey="funded" stackId="a" fill="#039be5" />
                      <Bar dataKey="dealer_cancelled" stackId="a" fill="#bf360c" />
                      <Bar dataKey="declined" stackId="a" fill="#d50000" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <ResponsiveContainer height={500}>
                  <BarChart data={VOPerSalesRep} layout="vertical" margin={{ top: 15, right: 0, left: 50, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="salesRepresentative.fullName" />
                    <Tooltip />

                    <Bar dataKey="total" stackId="a" fill="#435463"></Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                {loadingDataByLocationInRangeChart || loadingLocations ? (
                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <LinearProgress />
                  </Grid>
                ) : VOPerLocationInDateRange ? (
                  <ResponsiveContainer height={500}>
                    <BarChart data={VOPerLocationInDateRange}>
                      <CartesianGrid strokeDasharray="1 1" />
                      <XAxis
                        dataKey="day"
                        padding="gap"
                        minTickGap={5}
                        tickFormatter={date => {
                          return format(new Date(date), "MM-dd-yyyy");
                        }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar key={"null_key"} dataKey={"null"} name={"Unassigned"} stackId="a" fill="#435463"></Bar>
                      {locationChartLabels.map(location => {
                        return (
                          <Bar
                            key={location.locationId}
                            dataKey={location.locationId}
                            name={location.locationName}
                            stackId="a"
                            fill={location.hexColor}
                          ></Bar>
                        );
                      })}
                    </BarChart>
                  </ResponsiveContainer>
                ) : null}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="4">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    Weekly
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <BarChart data={VOPerWeek}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="week"
                          tickFormatter={date => {
                            return format(new Date(date), "MM-dd-yyyy");
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" stackId="a" fill="#007ac1" />
                      </BarChart>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    Monthly
                  </AccordionSummary>
                  <AccordionDetails>
                    <ResponsiveContainer height={500}>
                      <BarChart data={VOPerMonth}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="month"
                          tickFormatter={date => {
                            return format(new Date(date), "yyyy-MM-dd");
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" stackId="a" fill="#67daff" />
                      </BarChart>
                    </ResponsiveContainer>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </TabPanel>
          {/* <TabPanel value="5">
            <Grid container>
              <Grid item lg={12} xs={12} md={12} sm={12}>
                <ResponsiveContainer height={500}>
                  <BarChart data={VOPerMonth}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="month"
                      tickFormatter={date => {
                        return format(new Date(date), "yyyy-MM-dd");
                      }}
                    />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" stackId="a" fill="#67daff" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
          </TabPanel> */}
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default ReportsPage;
