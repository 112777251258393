import React, { useState } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

const RepresentativeSelect = ({ fieldConfig }) => {
  const [questionResponse, setQuestionResponse] = useState("no");
  const fieldId = uuidv4();
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FormControl fullWidth={fieldConfig.fullWidth}>
          <InputLabel id={fieldConfig.fieldName} required={fieldConfig.required}>
            Are you working with a Representative?
          </InputLabel>
          <Select
            key={fieldId}
            fullWidth={fieldConfig.fullWidth}
            name={fieldConfig.fieldName}
            value={questionResponse}
            labelId={fieldConfig.fieldName}
            style={{ maxHeight: "40vh" }}
            disabled={true}
            required={fieldConfig.required}
            variant="standard"
          >
            <MenuItem key={`${fieldId}.yes`} value={"yes"}>
              Yes
            </MenuItem>
            <MenuItem key={`${fieldId}.no`} value={"no"}>
              No
            </MenuItem>
          </Select>
          <FormHelperText>{_.get(fieldConfig, "helperText.en", "")}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {questionResponse && questionResponse === "yes" ? (
          <FormControl fullWidth={fieldConfig.fullWidth}>
            <InputLabel id={fieldConfig.fieldName} required={fieldConfig.required}>
              {_.get(fieldConfig, "displayLabel.en", "")}
            </InputLabel>
            <Select
              key={fieldId}
              fullWidth={fieldConfig.fullWidth}
              name={fieldConfig.fieldName}
              disabled={true}
              value={""}
              labelId={fieldConfig.fieldName}
              style={{ maxHeight: "40vh" }}
              required={fieldConfig.required}
              variant="standard"
            >
              <MenuItem key={`${fieldId}0`} value={""}>
                REP_PLACEHOLDER
              </MenuItem>
            </Select>
            <FormHelperText>{_.get(fieldConfig, "helperText.en", "")}</FormHelperText>
          </FormControl>
        ) : null}
      </Grid>
    </Grid>
  );
};

export { RepresentativeSelect };
