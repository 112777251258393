import React from "react";
import { DndProvider } from "react-dnd";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import { useFormContext, useWatch } from "react-hook-form";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  EquipmentSection,
  CustomerSection,
  MenuBuilderSection,
  StructureSection,
  SaleDetailsSection,
  SendViaSection,
} from "./sections";
import { GET_PROPOSAL_PRODUCTS } from "../../../api";
import { equipmentMileageFormatter } from "../../../lib";
import { useNotifications } from "../../../../notification";
import { ProposalProductsResponse } from "../../../api/types";
import { deskingActions, deskingSelectors } from "../../../model";
import { FinanceProgramFormValues, FormSections, UserActionsEvents } from "../../../types";

export const PanelForm = (props: Partial<UserActionsEvents>) => {
  const { control } = useFormContext<FinanceProgramFormValues>();
  const { equipment, customer, financeQuote } = useWatch<FinanceProgramFormValues>({ control });

  const { showNotification } = useNotifications();

  const dispatch = useDispatch();
  const proposalsProducts = useSelector(deskingSelectors.proposalsProducts);

  const handleUpdateCollapse = (section: FormSections, nextValue: boolean) => {
    dispatch(
      deskingActions.updateSectionIsOpenStatus({
        section,
        isOpen: nextValue,
      })
    );
  };

  useQuery<ProposalProductsResponse>(GET_PROPOSAL_PRODUCTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      if (!Array.isArray(response?.proposalProducts)) return;

      if (proposalsProducts.length) showNotification("Products updated");

      dispatch(deskingActions.setProducts(response.proposalProducts));
    },
    variables: {
      input: {
        proposalProductCriteria: {
          odometer: Number(equipmentMileageFormatter(equipment?.mileage ?? "", "toSimpleStr")),
          vin: equipment?.serialNumberVin ?? "",
          state: customer?.state ?? "",
          insuredAmount: financeQuote?.amount ? parseFloat(financeQuote.amount) : 0,
        },
      },
    },
  });

  return (
    <>
      <Box className="row">
        <CustomerSection sectionName={FormSections.Customer} />

        <SaleDetailsSection sectionName={FormSections.SaleDetails} />
      </Box>

      <EquipmentSection sectionName={FormSections.Equipment} onUpdateCollapse={handleUpdateCollapse} {...props} />

      <Box className="row">
        <DndProvider backend={HTML5Backend}>
          <StructureSection sectionName={FormSections.Structure} />

          <MenuBuilderSection sectionName={FormSections.MenuBuilder} />
        </DndProvider>
      </Box>

      <SendViaSection sectionName={FormSections.SendVia} />
    </>
  );
};
