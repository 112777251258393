import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { CheckCircleOutline, CloseOutlined, ErrorOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import formatString from "format-string-by-pattern";
import { useSelector } from "react-redux";
import { formatCurrency, getStatesArray, parseCurrency } from "../utils";

const config = require("../config.js");

const GET_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      locationParentId
    }
  }
`;

const CREATE_CREDIT_APP = gql`
  mutation CreateCreditApplication($input: CreateCreditApplicationInput!) {
    createCreditApplication(input: $input) {
      dynamicsId
      errorMessage
      id
      success
    }
  }
`;

const GET_VENDOR_OPPORTUNITY = gql`
  query getVendorOpportunityByDunamicsCreditApplicationId($dynamicsCreditApplicationId: ID!) {
    vendorOpportunityByDynamicsCreditApplicationId(dynamicsCreditApplicationId: $dynamicsCreditApplicationId) {
      vendorOpportunityId
    }
  }
`;

// const GET_OCA_TEMPLATE = gql`
//   query GetOCATemplate($dynamicsId: ID) {
//     vendorProfile(dynamicsId: $dynamicsId) {
//       id
//       ocaTemplate {
//         ocaTemplateId
//         active
//       }
//     }
//   }
// `;

const styles = {
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    background: "white",
    padding: "0 4px",
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginTop: "0.5rem",
  },
  stateWrapping: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 500,
    height: 500,
    "& h4": {
      marginTop: 16,
    },
  },
};

const useStyle = makeStyles(styles);

export default ({ open, onClose }) => {
  const classes = useStyle();
  const [values, setValues] = useState({});
  const [locations, setLocations] = useState(null);
  const [creditAppAlert, setCreditAppAlert] = useState({ success: false, open: false, loading: false, message: "" });
  const userProfile = useSelector(state => state.userProfile);
  const account = useSelector(state => state.account);
  const vp = useSelector(state => state.vp);

  const [createCreditApp] = useMutation(CREATE_CREDIT_APP, {
    context: { authRequired: true },
  });
  const { data: locationsDataResponse } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
  });
  const [getVendorOpportunity, { data: vendorOpportunityResponse, error: vendorOpportunityError }] = useLazyQuery(
    GET_VENDOR_OPPORTUNITY,
    {
      context: { authRequired: true },
      skip: true,
    }
  );
  // const { data: vpTemplate } = useQuery(GET_OCA_TEMPLATE, {
  //   context: { authRequired: true },
  //   variables: { dynamicsId: vp.dynamicsId },
  // });

  useEffect(() => {
    console.log("LOCATIONS", locationsDataResponse);
    if (!locationsDataResponse || _.isEmpty(locationsDataResponse.locations)) {
      return;
    }
    setLocations(locationsDataResponse.locations);
  }, [locationsDataResponse]);

  useEffect(() => {
    if (vendorOpportunityResponse?.vendorOpportunityByDynamicsCreditApplicationId?.vendorOpportunityId) {
      setCreditAppAlert({ success: true, open: true, loading: false });
      setTimeout(() => {
        window.location.href = `/vo/${vendorOpportunityResponse.vendorOpportunityByDynamicsCreditApplicationId.vendorOpportunityId}`;
      }, 2500);
    } else if (
      vendorOpportunityResponse?.vendorOpportunityByDynamicsCreditApplicationId?.vendorOpportunityId === null
    ) {
      setCreditAppAlert({ success: true, open: true, loading: false });
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } else if (vendorOpportunityError) {
      console.error("ERROR :: ", vendorOpportunityError);
      setCreditAppAlert({ success: false, open: true, loading: false });
      setTimeout(() => {
        setCreditAppAlert({ success: false, open: false, loading: false });
      }, 3000);
    }
  }, [vendorOpportunityResponse, vendorOpportunityError]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === "phone" || name === "bizPhone") {
      let phoneValue = value?.replace(/[^\d-() ]/, "");
      setValues({
        ...values,
        [name]: formatString("(999) 999-9999", phoneValue),
      });
    } else if (name === "amountRequested") {
      setValues({
        ...values,
        [name]: value?.replace(/[^\d\.]/g, ""),
      });
    } else if (name === "ssn") {
      let ssn = value?.replace(/[^0-9]/g, "");
      setValues({
        ...values,
        [name]: formatString("999-99-9999", ssn),
      });
    } else if (name === "taxId") {
      let taxId = value?.replace(/[^0-9]/g, "");
      setValues({
        ...values,
        [name]: formatString("99-9999999", taxId),
      });
    } else if (name === "primary_postalCode" || name === "business_postalCode") {
      let postalCode = value?.replace(/[^0-9]/g, "");
      setValues({
        ...values,
        [name]: postalCode.substring(0, 5),
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setCreditAppAlert({ success: false, open: false, loading: false });
      setValues({});
    }, 1500);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setCreditAppAlert({ success: false, open: false, loading: true, message: "Creating Opportunity..." });

    const contact = {
      firstName: values.firstName,
      lastName: values.lastName,
      ssn: values.ssn,
      email: values.email,
      phone: values.phone,
      address: values.primary_address,
      city: values.primary_city,
      state: values.primary_state,
      postalCode: values.primary_postalCode,
      dateOfBirth: values.dateOfBirth,
    };

    const pocInfo =
      values.bizOrIndividual === "Individual"
        ? {
            ...contact,
          }
        : {
            ...contact,
            businessName: values.businessName,
            address: values.business_address,
            city: values.business_city,
            state: values.business_state,
            postalCode: values.business_postalCode,
            taxId: parseInt(values.taxId?.replace("-", "")),
            dateEstablished: values.dateEstablished,
            bizPhone: values.bizPhone,
          };

    const input = {
      ...pocInfo,
      owners: [
        {
          ...contact,
          isOwnerPc: true,
          isOwnerPg: false,
          isOwnerPcOnly: false,
        },
      ],
      formId: uuidv4(),
      applicationType: values.bizOrIndividual === "Individual" ? "INDIVIDUAL" : "COMMERCIAL",
      vendorGUID: account.dynamicsAccountId,
      signature: values.firstName + " " + values.lastName,
      locationId: values.locationId,
      dynamicsContactId: userProfile.dynamicsContactId,
      entityType: values.entityType,
      amountRequested: parseFloat(parseCurrency(values.amountRequested)),
      ccg_transaction_equipmentdescription: values.equipmentDescription,
      ocaEnv: config.environmentName === "dev" ? 100 : 200,
      attemptToSubmit: true,
    };

    await createCreditApp({ variables: { input } })
      .then(async response => {
        setTimeout(() => {
          setCreditAppAlert({ success: false, open: true, loading: true, message: "Syncing Information..." });
        }, 10000);
        setTimeout(async () => {
          await getVendorOpportunity({
            variables: { dynamicsCreditApplicationId: response.data.createCreditApplication?.dynamicsId },
          });
        }, 20000);
      })
      .catch(error => {
        console.error("ERROR :: ", error);
        setCreditAppAlert({ success: false, open: true, loading: false });
        setTimeout(() => {
          setCreditAppAlert({ success: false, open: false, loading: false });
        }, 3000);
      });
  };

  const renderAddressComps = key => (
    <>
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={`* Address`}
        style={{ width: "100%" }}
        inputProps={{
          name: `${key}_address`,
          onChange: handleInputChange,
          autoComplete: `address`,
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        value={values[`${key}_address`]}
      />
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={`* City`}
        style={{ width: "100%" }}
        inputProps={{
          name: `${key}_city`,
          onChange: handleInputChange,
          autoComplete: `city`,
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        value={values[`${key}_city`]}
      />
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
          <InputLabel id={`${key}-state-select-label`} className={classes.selectLabel} shrink={true}>
            * State
          </InputLabel>
          <Select
            labelId={`${key}-state-select-label`}
            id={`${key}-state-select`}
            label="* State"
            className={classes.selectStyles}
            inputProps={{
              name: `${key}_state`,
              onChange: handleInputChange,
              autoComplete: `state`,
              required: true,
            }}
            value={values[`${key}_state`]}
          >
            {getStatesArray().map(state => (
              <MenuItem key={key + state.name} value={state.abbr}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className={classes.textField}
          fullWidth={true}
          label={`* Postal Code`}
          style={{ width: "100%" }}
          value={values[`${key}_postalCode`]}
          inputProps={{
            name: `${key}_postalCode`,
            onChange: handleInputChange,
            autoComplete: `${key}_postalCode`,
            required: true,
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </>
  );

  const renderContact = label => (
    <div className={classes.formSection}>
      <h5 style={{ fontWeight: "bold" }}>{label}</h5>
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <TextField
          className={classes.textField}
          fullWidth={true}
          label={"* First Name"}
          style={{ width: "100%" }}
          inputProps={{
            name: "firstName",
            onChange: handleInputChange,
            autoComplete: "firstName",
            required: true,
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={values[`firstName`]}
        />
        <TextField
          className={classes.textField}
          fullWidth={true}
          label={"* Last Name"}
          style={{ width: "100%" }}
          inputProps={{
            name: "lastName",
            onChange: handleInputChange,
            autoComplete: "lastName",
            required: true,
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={values[`lastName`]}
        />
      </div>
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"* Email"}
        style={{ width: "100%" }}
        inputProps={{
          name: "email",
          onChange: handleInputChange,
          autoComplete: "email",
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        value={values[`email`]}
      />
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"* Phone"}
        style={{ width: "100%" }}
        value={values.phone}
        inputProps={{
          name: "phone",
          onChange: handleInputChange,
          autoComplete: "phone",
          requied: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {renderAddressComps("primary")}
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"Social Security Number"}
        style={{ width: "100%" }}
        value={values.ssn}
        inputProps={{
          name: "ssn",
          onChange: handleInputChange,
          autoComplete: "ssn",
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"Date of Birth"}
        type="date"
        style={{ width: "100%" }}
        value={values.dateOfBirth}
        inputProps={{
          name: "dateOfBirth",
          onChange: handleInputChange,
          autoComplete: "dateOfBirth",
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );

  const renderEquipmentComps = cashSale => (
    <div className={classes.formSection}>
      <h5 style={{ fontWeight: "bold" }}>Equipment Information</h5>
      {cashSale && (
        <TextField
          className={classes.textField}
          fullWidth={true}
          label={`* Invoice Total`}
          style={{ width: "100%" }}
          value={formatCurrency(values.amountRequested)}
          inputProps={{
            name: "amountRequested",
            onChange: handleInputChange,
            autoComplete: "amountRequested",
            required: true,
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"* Equipment Description"}
        style={{ width: "100%" }}
        inputProps={{
          name: "equipmentDescription",
          onChange: handleInputChange,
          autoComplete: "equipmentDescription",
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        value={values[`equipmentDescription`]}
      />
    </div>
  );

  const renderBusinessComps = () => (
    <div className={classes.formSection}>
      <h5 style={{ fontWeight: "bold" }}>Business Information</h5>
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"* Business Name"}
        style={{ width: "100%" }}
        inputProps={{
          name: "businessName",
          onChange: handleInputChange,
          autoComplete: "businessName",
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        value={values[`businessName`]}
      />
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"* Phone"}
        style={{ width: "100%" }}
        value={values.bizPhone}
        inputProps={{
          name: "bizPhone",
          onChange: handleInputChange,
          autoComplete: "bizPhone",
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      {renderAddressComps("business")}
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"Tax Id"}
        style={{ width: "100%" }}
        value={values.taxId}
        inputProps={{
          name: "taxId",
          onChange: handleInputChange,
          autoComplete: "taxId",
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        className={classes.textField}
        fullWidth={true}
        label={"Date Established"}
        type="date"
        style={{ width: "100%" }}
        value={values.dateEstablished}
        inputProps={{
          name: "dateEstablished",
          onChange: handleInputChange,
          autoComplete: "dateEstablished",
          required: true,
        }}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );

  const renderOpportunityDetailsComps = account => (
    <div className={classes.formSection}>
      <h5 style={{ fontWeight: "bold" }}>Opportunity Details</h5>
      {/* <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
        <InputLabel id={`sales-rep-select-label`} className={classes.selectLabel} shrink={true}>
          * Representative
        </InputLabel>
        <Select
          labelId={`sales-rep-select-label`}
          id={`sales-rep-select`}
          label="* Representative"
          className={classes.selectStyles}
          inputProps={{
            name: "dynamicsContactId",
            onChange: handleInputChange,
            autoComplete: "dynamicsContactId",
            required: true,
          }}
          value={values[`dynamicsContactId`]}
        >
          {account.contacts
            .sort((a, b) => a.fullName.localeCompare(b.fullName))
            .map((contact, i) => (
              <MenuItem key={`${i}-sales_rep`} value={contact.dynamicsContactId}>
                {contact.fullName}
              </MenuItem>
            ))}
        </Select>
      </FormControl> */}
      <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
        <InputLabel id={`locations-select-label`} className={classes.selectLabel} shrink={true}>
          Location
        </InputLabel>
        <Select
          labelId={`locations-select-label`}
          id={`locations-select`}
          label="Location"
          className={classes.selectStyles}
          inputProps={{
            name: "locationId",
            onChange: handleInputChange,
            autoComplete: "locationId",
          }}
          value={values[`locationId`]}
        >
          {locations
            ?.sort((a, b) => a.locationName.localeCompare(b.locationName))
            .map((location, i) => (
              <MenuItem key={`${i}-location`} value={location.locationId}>
                {location.locationName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );

  const renderModalByState = () => {
    if (creditAppAlert.loading)
      return (
        <DialogContent className={classes.stateWrapping}>
          <CircularProgress />
          <h4>{creditAppAlert.message ? creditAppAlert.message : "Creating Opportunity..."}</h4>
          <p>This can take about 30 seconds.</p>
        </DialogContent>
      );
    if (creditAppAlert.open && creditAppAlert.success)
      return (
        <DialogContent className={classes.stateWrapping}>
          <CheckCircleOutline style={{ fontSize: 64, color: "#4caf50" }} />
          <h4>Opportunity Created Successfully!</h4>
        </DialogContent>
      );
    if (creditAppAlert.open && !creditAppAlert.success)
      return (
        <DialogContent className={classes.stateWrapping}>
          <ErrorOutline style={{ fontSize: 64, color: "#f44336" }} />
          <h4>Something went wrong.</h4>
        </DialogContent>
      );

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <DialogTitle>Create New Opportunity</DialogTitle>
          <IconButton onClick={() => handleClose()}>
            <CloseOutlined />
          </IconButton>
        </div>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <p style={{ marginBottom: "2rem" }}>
              Please provide the following information to create a new opportunity.
            </p>
            <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
              <InputLabel id={`opportunity-type-select-label`} className={classes.selectLabel} shrink={true}>
                * Opportunity Type
              </InputLabel>
              <Select
                labelId={`opportunity-type-select-label`}
                id={`opportunity-type-select`}
                label="* Opportunity Type"
                className={classes.selectStyles}
                inputProps={{
                  name: "entityType",
                  onChange: handleInputChange,
                  autoComplete: "entityType",
                  required: true,
                }}
              >
                <MenuItem key={"cashSale"} value={"CASH_SALE"}>
                  Cash Sale
                </MenuItem>
              </Select>
            </FormControl>
            {values.entityType && (
              <>
                {values.entityType !== "CASH_SALE" && (
                  <TextField
                    className={classes.textField}
                    fullWidth={true}
                    label={"* Requested Amount"}
                    style={{ width: "100%" }}
                    inputProps={{
                      name: "requestedAmount",
                      onChange: handleInputChange,
                      autoComplete: "requestedAmount",
                      required: true,
                    }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values[`entityType`]}
                  />
                )}
                <div className={classes.formSection}>
                  <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
                    <InputLabel id={`application-type-select-label`} className={classes.selectLabel} shrink={true}>
                      * Application Type
                    </InputLabel>
                    <Select
                      labelId={`application-type-select-label`}
                      id={`application-type-select`}
                      label="* Application Type"
                      className={classes.selectStyles}
                      inputProps={{
                        name: "bizOrIndividual",
                        onChange: handleInputChange,
                        autoComplete: "bizOrIndividual",
                        required: true,
                      }}
                      value={values[`bizOrIndividual`]}
                    >
                      <MenuItem key={"individual"} value={"Individual"}>
                        Individual
                      </MenuItem>
                      <MenuItem key={"commercial"} value={"Commercial"}>
                        Commercial
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {values.bizOrIndividual && (
                  <>
                    {values.bizOrIndividual === "Individual" && <>{renderContact("Applicant")}</>}
                    {values.bizOrIndividual === "Commercial" && (
                      <>
                        {renderBusinessComps()}
                        {renderContact("Contact")}
                      </>
                    )}
                    {renderEquipmentComps(values.entityType === "CASH_SALE")}
                    {renderOpportunityDetailsComps(account)}
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {renderModalByState()}
    </Dialog>
  );
};
