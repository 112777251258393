import React, { useState } from "react";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { Done, HourglassEmpty, PriorityHigh } from "@material-ui/icons";
import Table from "components/Table";
import { makeStyles } from "@material-ui/core/styles";
import { translateStageCodes } from "utils";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const CreditSubmissionsTable = ({ creditSubmissions }) => {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(10);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });

  return (
    <>
      <Table
        color="primary"
        data={creditSubmissions}
        getTrProps={() => ({
          style: {
            cursor: "pointer",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "14px",
          },
        })}
        columns={[
          {
            Header: "Submission Date",
            accessor: "submissionDate",
            Filter: () => null,
            resizable: false,
          },
          {
            Header: "Applicant",
            accessor: "applicantName",
            Filter: () => null,
            resizable: false,
          },
          {
            Header: "Requested",
            accessor: "amountRequested",
            Cell: props => <div>{props.value ? currencyFormatter.format(props.value) : ""}</div>,
            Filter: () => null,
          },
          {
            Header: "Decision",
            accessor: "decision",
            Cell: props => {
              const row = props.original;
              if (row.apiResult === "error" || row.apiResult === "failure") {
                return <span>Error Details: {row.apiDetail}</span>;
              }
              return row.decision;
            },
            Filter: () => null,
          },
          {
            Header: "Stage",
            Cell: props => {
              const row = props.original;
              let apiDetail = null;
              switch (row.apiResult) {
                case "error":
                  apiDetail = (
                    <span>
                      <span style={{ color: "red" }}>Error</span>
                      <Tooltip title={row.apiDetail} placement="top-start">
                        <PriorityHigh className={[classes.apiResultIcon, classes.apiResultIconFailed]} />
                      </Tooltip>
                    </span>
                  );
                  break;
                case "failure":
                  apiDetail = (
                    <span>
                      <span style={{ color: "red" }}>Error</span>
                      <Tooltip title={row.apiDetail} placement="top-start">
                        <PriorityHigh className={[classes.apiResultIcon, classes.apiResultIconFailed]} />
                      </Tooltip>
                    </span>
                  );
                  break;
                case "in progress":
                  apiDetail = (
                    <Tooltip title={"Processing"} placement="top-start">
                      <HourglassEmpty size="small" className={[classes.apiResultIcon]} />
                    </Tooltip>
                  );
                  break;
                case "success":
                  apiDetail = (
                    <Tooltip title={row.apiDetail} placement="top-start">
                      <Done size="small" className={[classes.apiResultIcon, classes.apiResultIconSuccess]} />
                    </Tooltip>
                  );
                  break;

                default:
                  break;
              }

              if (row.apiExpiredCredentials) {
                return (
                  <div>
                    <PriorityHigh className={[classes.apiResultIcon, classes.apiResultIconFailed]} />{" "}
                    {"Credentials Expired"}
                    <Button
                      size="small"
                      className={classes.buttonUpdateCredentials}
                      onClick={() => {
                        // handleUpdateCredentials(row);
                      }}
                    >
                      Update Credentials
                    </Button>
                  </div>
                );
              }
              if (props.value) {
                return (
                  <div>
                    {" "}
                    {translateStageCodes(props.value)} {apiDetail}
                  </div>
                );
              } else return null;
            },
            accessor: "creditSubStage",
            Filter: () => null,
          },
          {
            Header: "Submitted By",
            accessor: "submittedBy",
            Filter: () => null,
            resizable: false,
          },
          {
            Header: "Lender Contact",
            accessor: "lenderContactInfo",
            Filter: () => null,
            Cell: props => <div>{props.value[0]?.fullName}</div>,
            resizable: false,
          },
          {
            Header: "Opportunity",
            Filter: () => null,
            sortable: false,
            Cell: props => (
              <>
                {!!props.original.voId && (
                  <Tooltip title="Go to opportunity details">
                    <IconButton
                      size="small"
                      onClick={() => {
                        window.open(`/vo/${props.original.voId}`, "_blank");
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ),
          },
        ]}
        pageSize={pageSizeValue}
        pageNumber={pageNumber}
        defaultPageSize={savedListSettings.pageSize}
        showPaginationTop={false}
        showPaginationBottom={true}
        onPageChange={changePage => {
          setPageNumber(changePage);
        }}
        onPageSizeChange={changePageSize => {
          setPageSizeValue(changePageSize);
          setPageNumber(0);
        }}
        className={classes.root + " -highlight"}
      />
    </>
  );
};

export default CreditSubmissionsTable;
