import { InvoiceDetailAPIKey } from "../types";

export const invoiceDetailAPIKey: InvoiceDetailAPIKey[] = [
  {
    label: "Equipment Cost (Subtotal)",
    apiKey: "equipmentCost",
    format: "currency",
  },
  {
    label: "Other Charges (Installation, shipping, etc)",
    apiKey: "freightLaborInstallation",
    format: "currency",
  },
  {
    label: "Trade-in / Down Payment",
    apiKey: "downPayment",
    format: "currency",
  },
  {
    label: "Sales Tax",
    apiKey: "salesTaxOnInvoice",
    format: "currency",
  },
  {
    label: "Invoice Total",
    apiKey: "invoiceTotal",
    format: "currency",
  },
];
