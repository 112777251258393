import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { TaskList } from "./TaskList";
import { QUERY_RELATED_USERS, QUERY_TASKS, QUERY_TASKS_BY_CS } from "../queries";

export const SpecificTasks = ({
  vo,
  account,
  userProfile,
  creditSubId = null,
  lenderContact = null,
  lenderProfile = null,
  refetchDocs,
  submissionsTable = null,
  conversations,
  refetchConversations,
  conversationsLoading,
  voId,
  onTasksLoaded,
  initCollapsed = null,
  classNameHeader = null,
  tileTitle = null,
  classNameButton = null,
  handleConversationCreation,
  documentsPortalConfiguration,
  voTasks = null,
  taskId,
  documents,
}: any) => {
  const [relatedUsers, setRelatedUsers] = useState([]);

  const [
    getRelatedUsers,
    { loading: relatedUsersLoading, error: relatedUsersError, data: relatedUsersData },
  ] = useLazyQuery(QUERY_RELATED_USERS, {
    variables: {
      accountId: account.id,
    },
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  useEffect(() => {
    if (relatedUsersData !== null && relatedUsersData !== undefined && relatedUsersData.relatedUsers !== null) {
      setRelatedUsers(relatedUsersData.relatedUsers);
    } else {
      getRelatedUsers();
    }
  }, [getRelatedUsers, relatedUsersData]);

  if (relatedUsersLoading) console.log("Loading related users profiles...");
  if (relatedUsersError) console.log(`Error! ${relatedUsersError.message}`);
  if ((!!vo && !!vo.vendorOpportunityId) || creditSubId) {
    return (
      <TaskList
        vo={vo}
        taskId={taskId}
        account={account}
        voTasks={voTasks}
        queryTasksOptions={
          !creditSubId
            ? {
                variables: {
                  VOId: vo.vendorOpportunityId,
                  csId: null,
                  limit: 100,
                },
                context: { authRequired: true },
                fetchPolicy: "no-cache",
              }
            : {
                variables: {
                  VOId: null,
                  csId: creditSubId,
                  limit: 100,
                },
                context: { authRequired: true },
                fetchPolicy: "no-cache",
              }
        }
        queryTasks={!creditSubId ? QUERY_TASKS : QUERY_TASKS_BY_CS}
        userProfile={userProfile}
        creditSubId={creditSubId ? creditSubId : null}
        tasksQueryKey="vendorOpportunityTask"
        relatedUsers={!lenderContact ? relatedUsers : lenderContact}
        taskOnClickAction="editTask"
        addTaskFlag={true}
        refetchDocs={refetchDocs}
        onTasksLoaded={onTasksLoaded}
        conversations={conversations}
        refetchConversations={refetchConversations}
        conversationsLoading={conversationsLoading}
        submissionsTable={submissionsTable}
        voId={voId}
        initCollapsed={initCollapsed}
        classNameHeader={classNameHeader}
        tileTitle={tileTitle}
        classNameButton={classNameButton}
        handleConversationCreation={handleConversationCreation}
        documentsPortalConfiguration={documentsPortalConfiguration}
        lenderProfileDynamicsId={lenderProfile ? lenderProfile.lenderProfileId : null}
        documents={documents}
      ></TaskList>
    );
  } else return null;
};
