import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInput";
import GridItem from "../../components/Grid/GridItem";
import Tile from "../../components/Tile/Tile";
import { setRefetch } from "../../redux/actions";
import { formatSSN, setFieldValue, validateValue } from "../../utils";
import { gql } from "apollo-boost";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useMutation } from "@apollo/react-hooks";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { makeStyles } from "@material-ui/core/styles";

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useRadioStyles = makeStyles(customCheckboxRadioSwitch);

const CREATE_CREDIT_APPLICATION = gql`
  mutation($creditApplication: CreateCreditApplicationInput!) {
    createCreditApplication(input: $creditApplication) {
      id
      success
      errorMessage
    }
  }
`;

function RouteOneBizApp({ userProfile, match, setRefetch, account }) {
  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };

  const formatDate = val => {
    var formatVal = val.replace(/\D/g, "").substr(0, 8);
    var newVal = "";

    if (formatVal.length > 1) {
      val = formatVal;
    }

    if (formatVal.length > 2 && formatVal.length < 5) {
      newVal += val.substr(0, 2) + "/";
      formatVal = formatVal.substr(2);
    }

    if (formatVal.length >= 5) {
      newVal += formatVal.substr(0, 2) + "/";
      newVal += formatVal.substr(2, 2) + "/";
      formatVal = formatVal.substr(4);
    }

    newVal += formatVal;
    return newVal;
  };

  const formatNums = val => {
    return val.replace(/\D/g, "");
  };

  const [createCreditApp] = useMutation(CREATE_CREDIT_APPLICATION);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [coApplicantIsOrWasMarried, setCoApplicantIsOrWasMarried] = useState(false);
  const [isCoApplicantInWisconsin, setCoApplicantInWisconsin] = useState(false);
  const classesRadio = useRadioStyles();
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showExpandedFields, setShowExpandedFields] = useState(false);
  const [showRouteOneAdditionalFields, setShowRouteOneAdditionalFields] = useState(false);
  const [showEquipmentAdditionalFields, setShowEquipmentAdditionalFields] = useState(false);
  const [showTradeInFields, setShowTradeInFields] = useState(false);
  const [showTradeInAdditionalFields, setShowTradeInAdditionalFields] = useState(false);
  const [contractType, setContractType] = useState("retail");
  const [coApplicant, setCoApplicant] = useState(false);

  const [routeOneFields, setRouteOneFields] = useState({
    //base routeone fields
    businessName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Legal Name",
    },
    dba: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Trade Name",
    },
    legalStructure: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      type: "select",
      label: "Type of Enterprise",
      selectOptions: [
        { label: "Corporation", value: "803370001" },
        { label: "LLC", value: "803370002" },
        { label: "Partnership", value: "803370003" },
        { label: "Proprietor", value: "803370000" },
      ],
    },
    taxId: {
      value: "",
      label: "Tax ID",
    },
    yearsInBusiness: {
      value: "",
      label: "Yrs. In Business",
      format: formatNums,
    },
    bizPhone: {
      value: "",
      label: "Business Ph. #",
      format: formatPhone,
    },
    businessAddress: {
      value: "",
      label: "Address",
    },
    businessPostalCode: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Zip",
      format: formatNums,
    },
    businessCity: {
      value: "",
      label: "City",
    },
    businessState: {
      value: "",
      label: "State",
    },
    timeAtAddressInYears: {
      value: "",
      label: "Time At Address Years",
      format: formatNums,
    },
    timeAtAddressInMonths: {
      value: "",
      label: "Time at Adress Months",
      format: formatNums,
    },
    homeType: {
      value: "",
      type: "select",
      selectOptions: [
        { label: "Owns Outright", value: "OWNS_HOME_OUTRIGHT" },
        { label: "Buying", value: "BUYING_HOME" },
        { label: "Leasing/Renting", value: "LEASING_OR_RENTING" },
        { label: "Unknown", value: "UNKNOWN" },
      ],
      label: "Residence Type",
    },
    monthlyHousingPayment: {
      value: "",
      label: "Mo. Rent/Mortgage Amt.",
      format: formatNums,
    },
    operatorLastName: {
      value: "",
      label: "Operator Last Name",
    },
    operatorMiddleName: {
      value: "",
      label: "Operator Middle Name",
    },
    operatorFirstName: {
      value: "",
      label: "Operator First Name",
    },
    operatorAddress: {
      value: "",
      label: "Operator Address",
    },
    operatorAddress2: {
      value: "",
      label: "Operator Address 2",
    },
    operatorZip: {
      value: "",
      label: "Operator Zip",
      format: formatNums,
    },
    operatorCity: {
      value: "",
      label: "Operator City",
    },
    operatorState: {
      value: "",
      label: "Operator State",
    },
  });

  const [routeOneAdditonalBizFields, setRouteOneAdditionalBizFields] = useState({
    //fields to be expanded when no longer hidden
    routeOneIndustryType: {
      value: "",
      // type: "select",
      // selectOptions:[{label: "test"}],
      label: "Industry Type",
    },
    dateIncorporated: {
      value: "",
      label: "Date Incorporated (MM/DD/YYYY)",
      format: formatDate,
    },
    stateOfIncorp: {
      value: "",
      label: "State of Incorp.",
    },
    bizEmail: {
      //email
      value: "",
      label: "Email",
    },
    presAddress2: {
      value: "",
      label: "Pres. Address 2",
    },
    principal1LastName: {
      value: "",
      label: "Principal Last Name",
    },
    principal1FirstName: {
      value: "",
      label: "Principal First Name",
    },
    principal1MiddleName: {
      value: "",
      label: "Principal Middle name",
    },
    principal1Suffix: {
      value: "",
      label: "Principal Suffix",
      type: "select",
      selectOptions: [
        { label: "Jr", value: "JR" },
        { label: "SR", value: "SR" },
        { label: "I", value: "I" },
        { label: "II", value: "II" },
        { label: "III", value: "III" },
        { label: "IV", value: "IV" },
        { label: "V", value: "V" },
      ],
    },
    principal1TimeAtJobYears: {
      value: "",
      label: "Years as Principal",
      format: formatNums,
    },
    principal1Address: {
      value: "",
      label: "Address",
    },
    principal1Address2: {
      value: "",
      label: "Address 2",
    },
    principal1PostalCode: {
      value: "",
      label: "Zip",
      format: formatNums,
    },
    principal1City: {
      value: "",
      label: "City",
    },
    principal1State: {
      value: "",
      label: "State",
    },
    principal1Phone: {
      value: "",
      label: "Ph #",
      format: formatPhone,
    },
    principal1Title: {
      value: "",
      label: "Principal Title",
    },
    principal1OwnerPercentage: {
      value: "",
      label: "Principal % of Ownership",
      format: formatNums,
    },
    principal1YearlyIncome: {
      value: "",
      label: "Principal Yearly Income",
      format: formatNums,
    },
    principal1MonthsAsOO: {
      value: "",
      label: "Total Mos. As Owner Operator",
      format: formatNums,
    },
    principal2LastName: {
      value: "",
      label: "2nd Principal Last Name",
    },
    principal2FirstName: {
      value: "",
      label: "2nd Principal First Name",
    },
    principal2MiddleName: {
      value: "",
      label: "2nd Principal Middle name",
    },
    principal2Suffix: {
      value: "",
      label: "2nd Principal Suffix",
      type: "select",
      selectOptions: [
        { label: "Jr", value: "JR" },
        { label: "SR", value: "SR" },
        { label: "I", value: "I" },
        { label: "II", value: "II" },
        { label: "III", value: "III" },
        { label: "IV", value: "IV" },
        { label: "V", value: "V" },
      ],
    },
    principal2Title: {
      value: "",
      label: "2nd Principal Title",
    },
    principal2OwnerPercentage: {
      value: "",
      label: "Principal % of Ownership",
      format: formatNums,
    },
    operatorRelationship: {
      value: "",
      label: "Operator Relationship",
    },
    insuranceCompanyName: {
      value: "",
      label: "Insurance Company Name",
    },
    dunsNumber: {
      value: "",
      label: "DUNS Number",
      format: formatNums,
    },
    primaryBankName: {
      value: "",
      label: "Bank Name",
    },
    bankAccountType: {
      value: "",
      label: "Bank Account Type",
      type: "select",
      selectOptions: [{ value: "CHECKING", label: "Checking" }, { value: "SAVINGS", label: "Savings" }],
    },
    primaryBankAccountNum: {
      value: "",
      label: "Account Number",
      format: formatNums,
    },
    primaryBankAverageBalance: {
      value: "",
      label: "Account Balance",
      format: formatNums,
    },
    primaryBankPhone: {
      value: "",
      label: "Bank Ph. #",
      format: formatPhone,
    },
    bankAddress: {
      value: "",
      label: "Bank Address",
    },
    bankAddress2: {
      value: "",
      label: "Bank Address 2",
    },
    bankZip: {
      value: "",
      label: "Bank Zip",
      format: formatNums,
    },
    bankCity: {
      value: "",
      label: "Bank City",
    },
    bankState: {
      value: "",
      label: "Bank State",
    },
    accountantName: {
      value: "",
      label: "Accountant Name",
    },
    accountantPhone: {
      value: "",
      label: "Accountant Phone",
      format: formatPhone,
    },
    accountantFax: {
      value: "",
      label: "Accountant Fax",
      format: formatPhone,
    },
    accountantAddress: {
      value: "",
      label: "Accountant Address",
    },
    accountantAddress2: {
      value: "",
      label: "Accountant Address 2",
    },
    accountantZip: {
      value: "",
      label: "Accountant Zip",
      format: formatNums,
    },
    accountantCity: {
      value: "",
      label: "Accountant City",
    },
    accountantState: {
      value: "",
      label: "Accountant State",
    },
    currentAssets: {
      value: "",
      label: "Current Asset",
      format: formatNums,
    },
    intangibleAsset: {
      value: "",
      label: "Intangible Asset",
      format: formatNums,
    },
    fixedAssets: {
      value: "",
      label: "Fixed Assets",
      format: formatNums,
    },
    totalAssets: {
      value: "",
      label: "Total Assets",
      format: formatNums,
    },
    currentLiabilities: {
      value: "",
      label: "Current Liabilities",
      format: formatNums,
    },
    longTermDirectLiabilities: {
      value: "",
      label: "Long Term/Direct Liabilities",
      format: formatNums,
    },
    totalLiabilities: {
      value: "",
      label: "Total Liabilities",
      format: formatNums,
    },
    capital: {
      value: "",
      label: "Capital",
      format: formatNums,
    },
    earnedSurplus: {
      value: "",
      label: "Earned Surplus",
      format: formatNums,
    },
    grossProfit: {
      value: "",
      label: "Gross Profit",
      format: formatNums,
    },
    netProfit: {
      value: "",
      label: "Net Profit",
      format: formatNums,
    },
    workingCapital: {
      value: "",
      label: "Working Capital",
      format: formatNums,
    },
    netWorth: {
      value: "",
      label: "Total Net Worth",
      format: formatNums,
    },
    debtNetWorthRatio: {
      value: "",
      label: "Debt/Total Net Worth",
    },
    currentAssetsLiabilitiesRatio: {
      value: "",
      label: "Current Assets/Liabilities Ratio",
    },
    bankruptcy: {
      value: "",
      label: "Bankruptcy",
      type: "select",
      selectOptions: [
        {
          value: "Has not declared bankruptcy",
          label: "Has not declared bankruptcy",
        },
        {
          value: "Has declared bankruptcy",
          label: "Has declared bankruptcy",
        },
      ],
    },
    bankruptcyDate: {
      value: "",
      label: "Bankruptcy Date (MM/DD/YYYY)",
      format: formatDate,
    },
    priorRepossesion: {
      value: "",
      label: "Prior Repossession",
      type: "select",
      selectOptions: [
        { value: "No", label: "No prior repossession" },
        { value: "Yes", label: "Has prior repossession" },
      ],
    },
    repossesionDate: {
      value: "",
      label: "Repossession Date (MM/DD/YYYY)",
      format: formatDate,
    },
    creditorOneName: {
      value: "",
      label: "Creditor Name",
    },
    creditorOneBalance: {
      value: "",
      label: "Creditor Balance #1",
      format: formatNums,
    },
    creditorOneMonthlyPayment: {
      value: "",
      label: "Creditor Monthly Payment #1",
      format: formatNums,
    },
    creditor2Name: {
      value: "",
      label: "Creditor Name",
    },
    creditor2Balance: {
      value: "",
      label: "Creditor Balance #2",
      format: formatNums,
    },
    creditor2MonthlyPayment: {
      value: "",
      label: "Creditor Monthly Payment #2",
      format: formatNums,
    },
    companyFinancingAutoLoan: {
      value: "",
      label: "Co. Financing Auto Loan",
    },
    prevAutoLoanAccountNumber: {
      value: "",
      label: "Prev. Auto Loan Acct #",
    },
    previousAutoLoanMonthlyPayment: {
      value: "",
      label: "Prev. Monthly Payment",
    },
  });

  const [coApplicantFields, setCoApplicantFields] = useState({
    coApplicantRelationshipCode: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantTitleSalutation: {
      value: "",
    },
    coApplicantLastName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantFirstName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantMiddleName: {
      value: "",
    },
    coApplicantNameSuffix: {
      value: "",
    },
    coApplicantDateOfBirth: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatDate,
    },
    coApplicantSsn: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatSSN,
    },
    coApplicantHomePhone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatPhone,
    },
    coApplicantPhone: {
      value: "",
      format: formatPhone,
    },
    coApplicantEmail: {
      value: "",
      validationStatus: "",
      validations: [{ type: "email" }],
    },
    coApplicantHomeAddress: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantHomePostalCode: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    coApplicantHomeCity: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantHomeState: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantHomeCounty: {
      value: "",
    },
    coApplicantTimeAtAddressInMonths: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    coApplicantTimeAtAddressInYears: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    coApplicantResidenceType: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantMonthlyHousingPayment: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    coApplicantEducationLevel: {
      value: "",
    },
    coApplicantEmployeeType: {
      values: "",
    },
    coApplicantEmployeeTitle: {
      value: "",
    },
    coApplicantEmploymentStatus: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantEmployerName: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
    },
    coApplicantEmployerPhone: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatPhone,
    },
    coApplicantPeriodOfEmploymentYears: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    coApplicantPeriodOfEmploymentMonths: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    coApplicantAnnualIncome: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      format: formatNums,
    },
    coApplicantIncomeSourceOther: {
      value: "",
    },
    coApplicantIncomeAmountPerYearOther: {
      value: "",
      format: formatNums,
    },
    coApplicantPreviousEmployerName: {
      value: "",
    },
    coApplicantPreviousEmployerPeriodOfEmploymentYears: {
      value: "",
      format: formatNums,
    },
    coApplicantPreviousEmployerPeriodOfEmploymentMonths: {
      value: "",
      format: formatNums,
    },
    coApplicantHomeAddressLine2: {
      value: "",
    },
    coApplicantPrevAddress: {
      value: "",
    },
    coApplicantPrevAddressLine2: {
      value: "",
    },
    coApplicantPrevPostalCode: {
      value: "",
    },
    coApplicantPrevCity: {
      value: "",
    },
    coApplicantPrevState: {
      value: "",
    },
    coApplicantPrevCounty: {
      value: "",
    },
    coApplicantTimeAtPrevAddressYears: {
      value: "",
      format: formatNums,
    },
    coApplicantTimeAtPrevAddressMonths: {
      value: "",
      format: formatNums,
    },
    coApplicantCreditorOneBalance: {
      value: "",
    },
    coApplicantCreditorOneMonthlyPayment: {
      value: "",
    },
    coApplicantCreditorOneName: {
      value: "",
    },
    coApplicantCreditorTwoBalance: {
      value: "",
    },
    coApplicantCreditorTwoMonthlyPayment: {
      value: "",
    },
    coApplicantCreditorTwoName: {
      value: "",
    },
    coApplicantBankAccountType: {
      value: "",
    },
    coApplicantBankName: {
      value: "",
    },
    coApplicantBankAccountNum: {
      value: "",
    },
    coApplicantBankAverageBalance: {
      value: "",
    },
    coApplicantBankPhone: {
      value: "",
    },
    coApplicantBankAddress: {
      value: "",
    },
    coApplicantBankAddressLine2: {
      value: "",
    },
    coApplicantBankZip: {
      value: "",
    },
    coApplicantBankCity: {
      value: "",
    },
    coApplicantBankState: {
      value: "",
    },
    coApplicantPriorBankruptcy: {
      value: "",
    },
    coApplicantBankruptcyDate: {
      value: "",
      format: formatDate,
    },
    coApplicantPriorRepossession: {
      value: "",
    },
    coApplicantRepossessionDate: {
      value: "",
      format: formatDate,
    },
    coApplicantCompanyFinancingAutoLoan: {
      value: "",
    },
    coApplicantPreviousAutoLoanAccountNumber: {
      value: "",
    },
    coApplicantPreviousAutoLoanMonthlyPayment: {
      value: "",
    },
    coApplicantMaritalStatus: {
      value: "",
    },
    nonApplicantSpousesLastName: {
      value: "",
    },
    nonApplicantSpousesFirstName: {
      value: "",
    },
    nonApplicantSpousesAddress: {
      value: "",
    },
    nonApplicantSpousesCity: {
      value: "",
    },
    nonApplicantSpousesState: {
      value: "",
    },
    nonApplicantSpousesZip: {
      value: "",
    },
  });

  const [equipmentFields, setEquipmentFields] = useState({
    //base routeone equipment fields
    equipmentIntendedUse: {
      value: "",
      label: "Intended Use",
      type: "select",
      selectOptions: [
        { label: "Business", value: "BUSINESS" },
        { label: "Agricultural", value: "AGRICULTURAL" },
        { label: "Hazardous", value: "HAZARDOUS" },
        { label: "Local", value: "LOCAL" },
        { label: "Interstate", value: "INTERSTATE" },
        { label: "Intermediate", value: "INTERMEDIATE" },
        { label: "Personal", value: "PERSONAL" },
      ],
    },
    equipmentAssetType: {
      value: "",
      label: "Select Asset Type",
      type: "select",
      selectOptions: [
        { label: "Auto", value: "AUTO" },
        { label: "Light Truck", value: "LIGHT_TRUCK" },
        { label: "Medium Truck", value: "MEDIUM_TRUCK" },
        { label: "Conversion Van", value: "CONVERSION_VAN" },
        { label: "Other", value: "OTHER" },
      ],
    },
    equipmentNewUsed: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "New/Used",
      type: "select",
      selectOptions: [
        { label: "New", value: "New" },
        { label: "Used", value: "Used" },
        { label: "Demo", value: "Demo" },
      ],
    },
    stockId: {
      value: "",
      label: "Stock Number",
    },
    vin: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "VIN",
    },
    equipmentYear: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Year",
      format: formatNums,
    },
    equipmentMake: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Make",
    },
    // equipmentMakeOther: {
    //   value: "",
    //   label: "Other"
    // },
    equipmentModel: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Model",
    },
    // equipmentModelOther: {
    //   value: "",
    //   label: "Other"
    // },
    equipmentStyle: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Style/Trim",
    },
    // equipmentStyleOther: {
    //   value: "",
    //   validationStatus: "",
    //   validations: [{ type: "required" }],
    //   label: "Other"
    // },
    equipmentMileage: {
      value: "",
      validationStatus: "",
      validations: [{ type: "required" }],
      label: "Inception Miles",
      format: formatNums,
    },
  });

  const [tradeinFields, setTradeinFields] = useState({
    //base routeone trade-in fields
    year: {
      value: "",
      label: "Year",
      format: formatNums,
    },
    make: {
      value: "",
      label: "Make",
    },
    model: {
      value: "",
      label: "Model",
    },
  });

  const [equipmentAdditionalFields, setEquipmentAdditionalFields] = useState({
    equipmentSpecialPrograms: {
      value: "",
      label: "Special Programs",
      type: "select",
      selectOptions: [
        { label: "College Graduate", value: "COLLEGE_GRADUATE" },
        { label: "Other", value: "OTHER" },
        { label: "Military", value: "MILITARY" },
        { label: "Sales Finance", value: "SALES_FINANCE" },
        { label: "Customer Option Plan", value: "CUSTOMER_OPTION_PLAN" },
        { label: "Comtrac", value: "COMTRAC" },
        { label: "Employee Program", value: "EMPLOYEE_PROGRAM" },
        { label: "Repair Program", value: "REPAIR_PROGRAM" },
        { label: "Residual Purchase Plan", value: "RESIDUAL_PURCHASE_PLAN" },
        { label: "Credit Line", value: "CREDIT_LINE" },
        { label: "Lease To Retail", value: "LEASE_TO_RETAIL" },
        { lablel: "First Time Buyer", value: "FIRST_TIME_BUYER" },
        { label: "Lease Loyalty", value: "LEASE_LOYALTY" },
        { label: "Off Lease", value: "OFF_LEASE" },
        { label: "Single Pay Lease", value: "SINGLE_PAY_LEASE" },
        { label: "Multiple Units", value: "MULTIPLE_UNITS" },
      ],
    },
    equipmentOfferingType: {
      value: "",
      label: "Offering Type",
      type: "select",
      selectOptions: [
        { label: "New Offering", value: "NEW_OFFERING" },
        { label: "Transfer of Equity", value: "TRANSFER_OF_EQUITY" },
        { label: "Re-Write", value: "RE_WRITE" },
      ],
    },
    salesOrderNumber: {
      value: "",
      label: "Sales Order Number",
    },
    // applicationLanguage: {
    //   value: "",
    //   label: 'Application Language'
    // },
    equipmentPreapproval: {
      value: "",
      label: "Pre-Approval",
      type: "select",
      selectOptions: [{ value: true, label: "Yes" }, { value: "false", label: "No" }],
    },
    equipmentPreapprovalProgramName: {
      value: "",
      label: "Pre-Approval Program Name",
    },
    numberOfEngineCylinders: {
      value: "",
      label: "# of Cylinders",
      format: formatNums,
    },
    equipmentOptions: {
      value: "",
      label: "Other Options",
    },
  });

  const [tradeinAdditionalFields, setTradeinAdditionalFields] = useState({
    //base routeone trade-in fields
    tradeInBodyStyle: {
      value: "",
      label: "Trade Vehicle Body Style",
    },
    options: {
      value: "",
      label: "Options",
    },
    wholesaleValueAmount: {
      value: "",
      label: "Wholesale Value Amount",
    },
  });

  const [contractAdditionalFields, setContractAdditionalFields] = useState({
    vanConversionPerc: {
      value: "",
      label: "Van Conversion %",
    },
    vanConversionCost: {
      value: "",
      label: "Van Conversion/Uplift Cost",
    },
    estimatedAnnualMileage: {
      value: "",
      label: "Estimated Annual Mileage",
      format: formatNums,
    },
    miscellaneousNotes: {
      value: "",
      label: "Comments",
    },
  });

  const [formValid, setFormValid] = useState(false);

  const checkFormValidation = () => {
    let status = true;
    Object.keys(routeOneFields).forEach(fieldName => {
      if (!!routeOneFields[fieldName].validations && routeOneFields[fieldName].validations.length) {
        const value = routeOneFields[fieldName].value;
        const validations = routeOneFields[fieldName].validations;
        routeOneFields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (routeOneFields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    Object.keys(equipmentFields).forEach(fieldName => {
      if (!!equipmentFields[fieldName].validations && equipmentFields[fieldName].validations.length) {
        const value = equipmentFields[fieldName].value;
        const validations = equipmentFields[fieldName].validations;
        equipmentFields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
        if (equipmentFields[fieldName].validationStatus !== "success") {
          status = false;
        }
      }
    });
    if (showRouteOneAdditionalFields) {
      Object.keys(routeOneAdditonalBizFields).forEach(fieldName => {
        if (
          !!routeOneAdditonalBizFields[fieldName].validations &&
          routeOneAdditonalBizFields[fieldName].validations.length
        ) {
          const value = routeOneAdditonalBizFields[fieldName].value;
          const validations = routeOneAdditonalBizFields[fieldName].validations;
          routeOneAdditonalBizFields[fieldName].validationStatus = validateValue(value, validations)
            ? "success"
            : "error";
          if (routeOneAdditonalBizFields[fieldName].validationStatus !== "success") {
            status = false;
          }
        }
      });
    }
    if (showEquipmentAdditionalFields) {
      Object.keys(equipmentAdditionalFields).forEach(fieldName => {
        if (
          !!equipmentAdditionalFields[fieldName].validations &&
          equipmentAdditionalFields[fieldName].validations.length
        ) {
          const value = equipmentAdditionalFields[fieldName].value;
          const validations = equipmentAdditionalFields[fieldName].validations;
          equipmentAdditionalFields[fieldName].validationStatus = validateValue(value, validations)
            ? "success"
            : "error";
          if (equipmentAdditionalFields[fieldName].validationStatus !== "success") {
            status = false;
          }
        }
      });
    }
    if (showTradeInFields) {
      Object.keys(tradeinFields).forEach(fieldName => {
        if (!!tradeinFields[fieldName].validations && tradeinFields[fieldName].validations.length) {
          const value = tradeinFields[fieldName].value;
          const validations = tradeinFields[fieldName].validations;
          tradeinFields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
          if (tradeinFields[fieldName].validationStatus !== "success") {
            status = false;
          }
        }
      });
    }
    if (showTradeInAdditionalFields) {
      Object.keys(tradeinAdditionalFields).forEach(fieldName => {
        if (!!tradeinAdditionalFields[fieldName].validations && tradeinAdditionalFields[fieldName].validations.length) {
          const value = tradeinAdditionalFields[fieldName].value;
          const validations = tradeinAdditionalFields[fieldName].validations;
          tradeinAdditionalFields[fieldName].validationStatus = validateValue(value, validations) ? "success" : "error";
          if (tradeinAdditionalFields[fieldName].validationStatus !== "success") {
            status = false;
          }
        }
      });
    }
    setFormValid(status);
    return formValid;
  };

  const setValue = (value, fieldName, fieldList, setFieldList, label, type, options) =>
    setFieldValue(value, fieldName, fieldList, setFieldList, label, type, options);

  const setCoApplicantValue = (value, fieldName) =>
    setFieldValue(value, fieldName, coApplicantFields, setCoApplicantFields);

  const handleSubmit = async event => {
    try {
      checkFormValidation();
      const submitValues = {};
      const values = {
        ...routeOneFields,
        ...routeOneAdditonalBizFields,
        ...coApplicantFields,
        ...equipmentFields,
        ...equipmentAdditionalFields,
        ...tradeinAdditionalFields,
        ...tradeinFields,
      };
      submitValues.ocaType = "RouteOne - BizApp - portal";
      submitValues.applicationType = coApplicant ? "COMMERCIAL_WITH_GUARANTOR" : "COMMERCIAL";
      submitValues.vendorGUID = account.dynamicsAccountId;
      submitValues.businessName = values.businessName.value;
      submitValues.dba = values.dba.value;
      submitValues.legalStructure = values.legalStructure.value;
      submitValues.taxId = isNaN(parseInt(values.taxId.value)) ? null : parseInt(values.taxId.value);
      submitValues.yearsInBusiness = isNaN(parseInt(values.yearsInBusiness.value))
        ? null
        : parseInt(values.yearsInBusiness.value);
      submitValues.bizPhone = values.bizPhone.value;
      submitValues.address = values.businessAddress.value;
      submitValues.postalCode = values.businessPostalCode.value;
      submitValues.city = values.businessCity.value;
      submitValues.state = values.businessState.value;
      submitValues.timeAtAddressInYears = isNaN(parseInt(values.timeAtAddressInYears.value))
        ? null
        : parseInt(values.timeAtAddressInYears.value);
      submitValues.timeAtAddressInMonths = isNaN(parseInt(values.timeAtAddressInMonths.value))
        ? null
        : parseInt(values.timeAtAddressInMonths.value);
      submitValues.homeType = values.homeType.value;
      submitValues.monthlyHousingPayment = isNaN(parseFloat(values.monthlyHousingPayment.value))
        ? null
        : parseFloat(values.monthlyHousingPayment.value);
      submitValues.owners = [{}];
      submitValues.equipment = {};
      submitValues.tradeIn = {};
      submitValues.coApplicant = {};
      submitValues.equipment.equipmentIntendedUse = values.equipmentIntendedUse.value;
      submitValues.equipment.equipmentAssetType = values.equipmentAssetType.value;
      submitValues.equipment.newUsed = values.equipmentNewUsed.value;
      submitValues.equipment.stockId = values.stockId.value;
      submitValues.equipment.serialNumberVin = values.vin.value;
      submitValues.equipment.year = values.equipmentYear.value;
      submitValues.equipment.make = values.equipmentMake.value;
      // submitValues.equipment.makeOther = values.equipmentMakeOther.value;
      submitValues.equipment.model = values.equipmentModel.value;
      // submitValues.equipment.modelOther = values.equipmentModelOther.value;
      submitValues.equipment.style = values.equipmentStyle.value;
      // submitValues.equipment.styleOther = values.equipmentStyleOther.value;
      submitValues.equipment.mileage = isNaN(parseInt(values.equipmentMileage.value))
        ? null
        : parseInt(values.equipmentMileage.value);
      if (showRouteOneAdditionalFields) {
        submitValues.routeOneIndustryType = values.routeOneIndustryType.value;
        submitValues.dateIncorporated = new Date(values.dateIncorporated.value);
        submitValues.stateOfIncorp = values.stateOfIncorp.value;
        submitValues.email = values.bizEmail.value;
        submitValues.presAddress2 = values.presAddress2.value;
        submitValues.owners = [
          {
            lastName: values.principal1LastName.value,
            firstName: values.principal1FirstName.value,
            middleName: values.principal1MiddleName.value,
            nameSuffix: values.principal1Suffix.value,
            timeAtJobYears: isNaN(parseInt(values.principal1TimeAtJobYears.value))
              ? null
              : parseInt(values.principal1TimeAtJobYears.value),
            address: values.principal1Address.value,
            address2: values.principal1Address2.value,
            postalCode: values.principal1PostalCode.value,
            city: values.principal1City.value,
            state: values.principal1State.value,
            phone: values.principal1Phone.value,
            title: values.principal1Title.value,
            percOwner: isNaN(parseInt(values.principal1OwnerPercentage.value))
              ? null
              : parseInt(values.principal1OwnerPercentage.value),
            grossMonthlyIncome: isNaN(parseFloat(values.principal1YearlyIncome.value) / 12)
              ? null
              : parseFloat(values.principal1YearlyIncome.value) / 12,
            timeAtJobMonths: isNaN(parseInt(values.principal1MonthsAsOO.value))
              ? null
              : parseInt(values.principal1MonthsAsOO.value),
            netWorth: isNaN(parseFloat(values.netWorth.value)) ? null : parseFloat(values.netWorth.value),
          },
          {
            lastName: values.principal2LastName.value,
            firstName: values.principal2FirstName.value,
            middleName: values.principal2MiddleName.value,
            nameSuffix: values.principal2Suffix.value,
            title: values.principal2Title.value,
            percOwner: isNaN(parseInt(values.principal2OwnerPercentage.value))
              ? null
              : parseInt(values.principal2OwnerPercentage.value),
          },
        ];
        submitValues.insuranceCompanyName = values.insuranceCompanyName.value;
        submitValues.dunsNumber = values.dunsNumber.value;
        submitValues.primaryBankName = values.primaryBankName.value;
        submitValues.bankAccountType = values.bankAccountType.value;
        submitValues.primaryBankAccountNum = values.primaryBankAccountNum.value;
        submitValues.primaryBankAverageBalance = isNaN(parseFloat(values.primaryBankAverageBalance.value))
          ? null
          : parseFloat(values.primaryBankAverageBalance.value);
        submitValues.primaryBankPhone = values.primaryBankPhone.value;
        submitValues.bankAddress = values.bankAddress.value;
        submitValues.bankAddressLine2 = values.bankAddress2.value;
        submitValues.bankAddressZip = values.bankZip.value;
        submitValues.bankAddressCity = values.bankCity.value;
        submitValues.bankAddressState = values.bankState.value;
        submitValues.accountantName = values.accountantName.value;
        submitValues.accountantPhone = values.accountantPhone.value;
        submitValues.accountantFax = values.accountantFax.value;
        submitValues.accountantAddress = values.accountantAddress.value;
        submitValues.accountantAddress2 = values.accountantAddress2.value;
        submitValues.accountantZip = values.accountantZip.value;
        submitValues.accountantCity = values.accountantCity.value;
        submitValues.accountantState = values.accountantState.value;
        submitValues.currentAssets = isNaN(parseFloat(values.currentAssets.value))
          ? null
          : parseFloat(values.currentAssets.value);
        submitValues.intangibleAsset = isNaN(parseFloat(values.intangibleAsset.value))
          ? null
          : parseFloat(values.intangibleAsset.value);
        submitValues.fixedAssets = isNaN(parseFloat(values.fixedAssets.value))
          ? null
          : parseFloat(values.fixedAssets.value);
        submitValues.totalAssets = isNaN(parseFloat(values.totalAssets.value))
          ? null
          : parseFloat(values.totalAssets.value);
        submitValues.currentLiabilities = isNaN(parseFloat(values.currentLiabilities.value))
          ? null
          : parseFloat(values.currentLiabilities.value);
        submitValues.longTermDirectLiabilities = isNaN(parseFloat(values.longTermDirectLiabilities.value))
          ? null
          : parseFloat(values.longTermDirectLiabilities.value);
        submitValues.totalLiabilities = isNaN(parseFloat(values.totalLiabilities.value))
          ? null
          : parseFloat(values.totalLiabilities.value);
        submitValues.capital = isNaN(parseFloat(values.capital.value)) ? null : parseFloat(values.capital.value);
        submitValues.earnedSurplus = isNaN(parseFloat(values.earnedSurplus.value))
          ? null
          : parseFloat(values.earnedSurplus.value);
        submitValues.grossProfit = isNaN(parseFloat(values.grossProfit.value))
          ? null
          : parseFloat(values.grossProfit.value);
        submitValues.netProfit = isNaN(parseFloat(values.netProfit.value)) ? null : parseFloat(values.netProfit.value);
        submitValues.workingCapital = isNaN(parseFloat(values.workingCapital.value))
          ? null
          : parseFloat(values.workingCapital.value);
        submitValues.debtNetWorthRatio = isNaN(parseFloat(values.debtNetWorthRatio.value))
          ? null
          : parseFloat(values.debtNetWorthRatio.value);
        submitValues.currentAssetsLiabilitiesRatio = isNaN(parseFloat(values.currentAssetsLiabilitiesRatio.value))
          ? null
          : parseFloat(values.currentAssetsLiabilitiesRatio.value);
        submitValues.priorBankruptcy = values.bankruptcy.value;
        submitValues.bankruptcyDate = new Date(values.bankruptcyDate.value);
        submitValues.priorRepossesion = values.priorRepossesion.value;
        submitValues.repossesionDate = new Date(values.repossesionDate.value);
        submitValues.creditorOneName = values.creditorOneName.value;
        submitValues.creditorOneBalance = values.creditorOneBalance.value;
        submitValues.creditorOneMonthlyPayment = isNaN(parseFloat(values.creditorOneMonthlyPayment.value))
          ? null
          : parseFloat(values.creditorOneMonthlyPayment.value);
        submitValues.creditorTwoName = values.creditor2Name.value;
        submitValues.creditorTwoBalance = values.creditor2Balance.value;
        submitValues.creditorTwoMonthlyPayment = isNaN(parseFloat(values.creditor2MonthlyPayment.value))
          ? null
          : parseFloat(values.creditor2MonthlyPayment.value);
        submitValues.companyFinancingAutoLoan = values.companyFinancingAutoLoan.value;
        submitValues.previousAutoLoanAccountNumber = isNaN(parseFloat(values.prevAutoLoanAccountNumber.value))
          ? null
          : parseFloat(values.prevAutoLoanAccountNumber.value);
        submitValues.previousAutoLoanMonthlyPayment = isNaN(parseFloat(values.previousAutoLoanMonthlyPayment.value))
          ? null
          : parseFloat(values.previousAutoLoanMonthlyPayment.value);
        submitValues.routeOne = true;
        submitValues.equipment.operatorLastName = values.operatorLastName.value;
        submitValues.equipment.operatorFirstName = values.operatorFirstName.value;
        submitValues.equipment.operatorAddress = values.operatorAddress.value;
        submitValues.equipment.operatorZip = values.operatorZip.value;
        submitValues.equipment.operatorCity = values.operatorCity.value;
        submitValues.equipment.operatorState = values.operatorState.value;
        submitValues.equipment.operatorMiddleName = values.operatorMiddleName.value;
        submitValues.equipment.operatorAddress2 = values.operatorAddress2.value;
        submitValues.equipment.operatorRelationship = values.operatorRelationship.value;
      }
      if (coApplicant) {
        submitValues.coApplicant.relationshipCode = values.coApplicantRelationshipCode.value;
        submitValues.coApplicant.titleSalutation = values.coApplicantTitleSalutation.value;
        submitValues.coApplicant.lastName = values.coApplicantLastName.value;
        submitValues.coApplicant.firstName = values.coApplicantFirstName.value;
        submitValues.coApplicant.middleName = values.coApplicantMiddleName.value;
        submitValues.coApplicant.nameSuffix = values.coApplicantNameSuffix.value;
        submitValues.coApplicant.dateOfBirth = new Date(values.coApplicantDateOfBirth.value);
        submitValues.coApplicant.ssn = values.coApplicantSsn.value;
        submitValues.coApplicant.homePhone = values.coApplicantHomePhone.value;
        submitValues.coApplicant.phone = values.coApplicantPhone.value;
        submitValues.coApplicant.email = values.coApplicantEmail.value;
        submitValues.coApplicant.homeAddress = values.coApplicantHomeAddress.value;
        submitValues.coApplicant.homePostalCode = values.coApplicantHomePostalCode.value;
        submitValues.coApplicant.homeCity = values.coApplicantHomeCity.value;
        submitValues.coApplicant.homeState = values.coApplicantHomeState.value;
        submitValues.coApplicant.homeCounty = values.coApplicantHomeCounty.value;
        submitValues.coApplicant.timeAtAddressInMonths = isNaN(parseInt(values.coApplicantTimeAtAddressInMonths.value))
          ? null
          : parseInt(values.coApplicantTimeAtAddressInMonths.value);
        submitValues.coApplicant.timeAtAddressInYears = isNaN(parseInt(values.coApplicantTimeAtAddressInYears.value))
          ? null
          : parseInt(values.coApplicantTimeAtAddressInYears.value);
        submitValues.coApplicant.residenceType = values.coApplicantResidenceType.value;
        submitValues.coApplicant.monthlyHousingPayment = isNaN(
          parseFloat(values.coApplicantMonthlyHousingPayment.value)
        )
          ? null
          : parseFloat(values.coApplicantMonthlyHousingPayment.value);
        submitValues.coApplicant.educationLevel = values.coApplicantEducationLevel.value;
        submitValues.coApplicant.employeeType = values.coApplicantEmployeeType.value;
        submitValues.coApplicant.employeeTitle = values.coApplicantEmployeeTitle.value;
        submitValues.coApplicant.employmentStatus = values.coApplicantEmploymentStatus.value;
        submitValues.coApplicant.employerName = values.coApplicantEmployerName.value;
        submitValues.coApplicant.employerPhone = values.coApplicantEmployerPhone.value;
        submitValues.coApplicant.periodOfEmploymentYears = isNaN(
          parseInt(values.coApplicantPeriodOfEmploymentYears.value)
        )
          ? null
          : parseInt(values.coApplicantPeriodOfEmploymentYears.value);
        submitValues.coApplicant.periodOfEmploymentMonths = isNaN(
          parseInt(values.coApplicantPeriodOfEmploymentMonths.value)
        )
          ? null
          : parseInt(values.coApplicantPeriodOfEmploymentMonths.value);
        submitValues.coApplicant.annualIncome = isNaN(parseFloat(values.coApplicantAnnualIncome.value))
          ? null
          : parseFloat(values.coApplicantAnnualIncome.value);
        submitValues.coApplicant.incomeSourceOther = values.coApplicantIncomeSourceOther.value;
        submitValues.coApplicant.incomeAmountPerYearOther = isNaN(
          parseFloat(values.coApplicantIncomeAmountPerYearOther.value)
        )
          ? null
          : parseFloat(values.coApplicantIncomeAmountPerYearOther.value);
        submitValues.coApplicant.previousEmployerName = values.coApplicantPreviousEmployerName.value;
        submitValues.coApplicant.previousEmployerPeriodOfEmploymentYears = isNaN(
          parseInt(values.coApplicantPreviousEmployerPeriodOfEmploymentYears.value)
        )
          ? null
          : parseInt(values.coApplicantPreviousEmployerPeriodOfEmploymentYears.value);
        submitValues.coApplicant.previousEmployerPeriodOfEmploymentMonths = isNaN(
          parseInt(values.coApplicantPreviousEmployerPeriodOfEmploymentMonths.value)
        )
          ? null
          : parseInt(values.coApplicantPreviousEmployerPeriodOfEmploymentMonths.value);

        // submitValues.coApplicant.homeAddressLine2 = values.coApplicant.homeAddressLine2.value;
        submitValues.coApplicant.prevAddress = values.coApplicantPrevAddress.value;
        submitValues.coApplicant.prevAddressLine2 = values.coApplicantPrevAddressLine2.value;
        submitValues.coApplicant.prevPostalCode = values.coApplicantPrevPostalCode.value;
        submitValues.coApplicant.prevCity = values.coApplicantPrevCity.value;
        submitValues.coApplicant.prevState = values.coApplicantPrevState.value;
        submitValues.coApplicant.prevCounty = values.coApplicantPrevCounty.value;
        submitValues.coApplicant.timeAtPrevAddressYears = values.coApplicantTimeAtPrevAddressYears.value;
        submitValues.coApplicant.timeAtPrevAddressMonths = values.coApplicantTimeAtPrevAddressMonths.value;
        submitValues.coApplicant.creditorOneBalance = values.coApplicantCreditorOneBalance.value;
        submitValues.coApplicant.creditorOneMonthlyPayment = values.coApplicantCreditorOneMonthlyPayment.value;
        submitValues.coApplicant.creditorOneName = values.coApplicantCreditorOneName.value;
        submitValues.coApplicant.creditorTwoBalance = values.coApplicantCreditorTwoBalance.value;
        submitValues.coApplicant.creditorTwoMonthlyPayment = values.coApplicantCreditorTwoMonthlyPayment.value;
        submitValues.coApplicant.creditorTwoName = values.coApplicantCreditorTwoName.value;
        submitValues.coApplicant.bankAccountType = values.coApplicantBankAccountType.value;
        submitValues.coApplicant.bankName = values.coApplicantBankName.value;
        submitValues.coApplicant.bankAccountNum = values.coApplicantBankAccountNum.value;
        submitValues.coApplicant.bankAverageBalance = values.coApplicantBankAverageBalance.value;
        submitValues.coApplicant.bankPhone = values.coApplicantBankPhone.value;
        submitValues.coApplicant.bankAddress = values.coApplicantBankAddress.value;
        submitValues.coApplicant.bankAddressLine2 = values.coApplicantBankAddressLine2.value;
        submitValues.coApplicant.bankZip = values.coApplicantBankZip.value;
        submitValues.coApplicant.bankCity = values.coApplicantBankCity.value;
        submitValues.coApplicant.bankState = values.coApplicantBankState.value;
        submitValues.coApplicant.priorBankruptcy = values.coApplicantPriorBankruptcy.value;
        submitValues.coApplicant.bankruptcyDate = new Date(values.coApplicantBankruptcyDate.value);
        submitValues.coApplicant.priorRepossession = values.coApplicantPriorRepossession.value;
        submitValues.coApplicant.repossessionDate = new Date(values.coApplicantRepossessionDate.value);
        submitValues.coApplicant.companyFinancingAutoLoan = values.coApplicantCompanyFinancingAutoLoan.value;
        submitValues.coApplicant.previousAutoLoanAccountNumber = isNaN(
          parseFloat(values.coApplicantPreviousAutoLoanAccountNumber.value)
        )
          ? null
          : parseFloat(values.coApplicantPreviousAutoLoanAccountNumber.value);
        submitValues.coApplicant.previousAutoLoanMonthlyPayment = isNaN(
          parseFloat(values.coApplicantPreviousAutoLoanMonthlyPayment.value)
        )
          ? null
          : parseFloat(values.coApplicantPreviousAutoLoanMonthlyPayment.value);

        submitValues.coApplicant.coApplicantMaritalStatus = values.coApplicantMaritalStatus.value;
        submitValues.coApplicant.nonApplicantSpousesLastName = values.nonApplicantSpousesLastName.value;
        submitValues.coApplicant.nonApplicantSpousesFirstName = values.nonApplicantSpousesFirstName.value;
        submitValues.coApplicant.nonApplicantSpousesAddress = values.nonApplicantSpousesAddress.value;
        submitValues.coApplicant.nonApplicantSpousesCity = values.nonApplicantSpousesCity.value;
        submitValues.coApplicant.nonApplicantSpousesState = values.nonApplicantSpousesState.value;
        submitValues.coApplicant.nonApplicantSpousesZip = values.nonApplicantSpousesZip.value;
      }
      if (showEquipmentAdditionalFields) {
        submitValues.equipment.equipmentSpecialPrograms = values.equipmentSpecialPrograms.value;
        submitValues.equipment.equipmentOfferingType = values.equipmentOfferingType.value;
        submitValues.equipment.salesOrderNumber = values.salesOrderNumber.value;
        // submitValues.equipment.applicationLanguage = values.applicationLanguage.value;
        submitValues.equipment.equipmentPreapproval = values.equipmentPreapproval.value;
        submitValues.equipment.equipmentPreapprovalProgramName = values.equipmentPreapprovalProgramName.value;
        submitValues.equipment.numberOfEngineCylinders = isNaN(parseInt(values.numberOfEngineCylinders.value))
          ? null
          : parseInt(values.numberOfEngineCylinders.value);
        submitValues.equipment.equipmentOptions = values.equipmentOptions.value;
      }
      if (showTradeInFields) {
        submitValues.tradeIn.year = values.year.value;
        submitValues.tradeIn.make = values.make.value;
        submitValues.tradeIn.model = values.model.value;
      }
      if (showTradeInAdditionalFields) {
        submitValues.tradeIn.tradeInBodyStyle = values.tradeInBodyStyle.value;
        submitValues.tradeIn.options = values.options.value;
        submitValues.tradeIn.wholesaleValueAmount = isNaN(parseFloat(values.wholesaleValueAmount.value))
          ? null
          : parseFloat(values.wholesaleValueAmount.value);
      }

      await createCreditApp({
        variables: {
          creditApplication: {
            ...submitValues,
          },
        },
      });

      setSnackbarOpen(true);
    } catch (e) {
      console.log(e);
      setErrorMessage("Error submitting credit app!");
      setErrorOpen(true);
    }
  };

  return (
    <>
      <Form
        onSubmit={values => {
          handleSubmit();
        }}
        validate={checkFormValidation}
        // initialValues={}
        render={({ handleSubmit, pristine, valid, submitting, values }) => {
          function handleClose() {
            setSnackbarOpen(false);
            setErrorOpen(false);
          }

          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit().then(res => console.log(res));
              }}
            >
              <Grid container>
                <GridItem lg={12}>
                  <Tile>
                    <h3>Business Applicant</h3>
                    <Grid container>
                      {Object.entries(routeOneFields).map(item =>
                        item[1].type === "select" ? (
                          <GridItem xs={12} sm={6} md={6} lg={3}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                {item[1].label}
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  name: item[0],
                                  id: "simple-select",
                                  // onChange: e => handleChangeRouteOneFields
                                  onChange: e =>
                                    setValue(
                                      e.target.value,
                                      item[0],
                                      routeOneFields,
                                      setRouteOneFields,
                                      item[1].label,
                                      item[1].type,
                                      item[1].selectOptions
                                    ),
                                }}
                              >
                                {item[1].selectOptions.map(item => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        ) : item[1].type === "calculated" ? (
                          <GridItem xs={12} sm={6} md={6} lg={3}>
                            <CustomInput
                              required
                              labelText={item[1].label}
                              id={item[1].label}
                              disabled
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: true,
                                type: "text",
                                name: item[0],
                                value: item[1].value,
                                onChange: e =>
                                  setValue(e.target.value, item[0], routeOneFields, setRouteOneFields, item[1].label),
                              }}
                            />
                          </GridItem>
                        ) : (
                          <GridItem xs={12} sm={6} md={6} lg={3}>
                            <CustomInput
                              required
                              labelText={item[1].label}
                              id={item[1].label}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: item[0],
                                value: item[1].value,
                                onChange: e =>
                                  setValue(e.target.value, item[0], routeOneFields, setRouteOneFields, item[1].label),
                              }}
                            />
                          </GridItem>
                        )
                      )}
                    </Grid>
                    <Button onClick={() => setShowRouteOneAdditionalFields(!showRouteOneAdditionalFields)}>
                      {showRouteOneAdditionalFields
                        ? "Hide Additional Business Fields"
                        : "Show Additional Business Fields"}
                    </Button>
                    {showRouteOneAdditionalFields && (
                      <>
                        <h3>Business Applicant Additional Fields</h3>
                        <Grid container>
                          {Object.entries(routeOneAdditonalBizFields).map(item =>
                            item[1].type === "select" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                  <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                    {item[1].label}
                                  </InputLabel>
                                  <Select
                                    inputProps={{
                                      name: item[0],
                                      id: "simple-select",
                                      onChange: e =>
                                        setValue(
                                          e.target.value,
                                          item[0],
                                          routeOneAdditonalBizFields,
                                          setRouteOneAdditionalBizFields,
                                          item[1].label,
                                          item[1].type,
                                          item[1].selectOptions
                                        ),
                                    }}
                                  >
                                    {item[1].selectOptions.map(item => (
                                      <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            ) : item[1].type === "calculated" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  disabled
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(
                                        e.target.value,
                                        item[0],
                                        routeOneAdditonalBizFields,
                                        setRouteOneAdditionalBizFields,
                                        item[1].label
                                      ),
                                  }}
                                />
                              </GridItem>
                            ) : (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(
                                        e.target.value,
                                        item[0],
                                        routeOneAdditonalBizFields,
                                        setRouteOneAdditionalBizFields,
                                        item[1].label
                                      ),
                                  }}
                                />
                              </GridItem>
                            )
                          )}
                        </Grid>
                      </>
                    )}

                    <Grid container justify="center" alignItems="center">
                      <GridItem xs={8} sm={3} md={3} lg={2}>
                        <Button
                          className="formBtn"
                          color="primary"
                          variant="contained"
                          onClick={() => setCoApplicant(!coApplicant)}
                        >
                          {coApplicant ? "Remove Co-Applicant" : "Add Co-Applicant"}
                        </Button>
                      </GridItem>
                    </Grid>
                    {coApplicant && (
                      <>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={3} lg={4}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Relationship Code
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantRelationshipCode.value,
                                  name: "coApplicantRelationshipCode",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantRelationshipCode"),
                                }}
                              >
                                <MenuItem value={"PARENT"}>Parent</MenuItem>
                                <MenuItem value={"RESIDES_WITH"}>Resides With</MenuItem>
                                <MenuItem value={"OTHER"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={3} md={3} lg={2}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="coApplicantTitleSalutation">
                                Salutation
                              </InputLabel>
                              <Select
                                inputProps={{
                                  value: coApplicantFields.coApplicantTitleSalutation.value,
                                  name: "coApplicantTitleSalutation",
                                  id: "coApplicantTitleSalutation",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantTitleSalutation"),
                                }}
                              >
                                <MenuItem value={"Mr"}>Mr</MenuItem>
                                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                                <MenuItem value={"Miss"}>Miss</MenuItem>
                                <MenuItem value={"Dr"}>Dr</MenuItem>
                                <MenuItem value={"Ms"}>Ms</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3} lg={3}>
                            <CustomInput
                              labelText="Last Name"
                              id="last-name"
                              formControlProps={{ fullWidth: true }}
                              // error={
                              //   coApplicantFields.fullName.validationStatus === "error"
                              // }
                              inputProps={{
                                type: "text",
                                name: "coApplicantLastName",
                                value: coApplicantFields.coApplicantLastName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantLastName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3} lg={3}>
                            <CustomInput
                              labelText="First Name"
                              id="first-name"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantFirstName",
                                value: coApplicantFields.coApplicantFirstName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantFirstName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3} lg={2}>
                            <CustomInput
                              labelText="MI"
                              id="mi"
                              formControlProps={{ fullWidth: true }}
                              // error={
                              //   coApplicantFields.phone.validationStatus === "error"
                              // }
                              inputProps={{
                                type: "text",
                                name: "coApplicantMiddleName",
                                value: coApplicantFields.coApplicantMiddleName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantMiddleName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={3} md={3} lg={2}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="name-suffix">
                                Suffix
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantNameSuffix.value,
                                  name: "coApplicantNameSuffix",
                                  id: "name-suffix",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantNameSuffix"),
                                }}
                              >
                                <MenuItem value={"I"}>I</MenuItem>
                                <MenuItem value={"II"}>II</MenuItem>
                                <MenuItem value={"III"}>III</MenuItem>
                                <MenuItem value={"IV"}>IV</MenuItem>
                                <MenuItem value={"V"}>V</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CustomInput
                              labelText="DOB(MM/DD/YYYY)"
                              id="dob"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantDateOfBirth",
                                value: coApplicantFields.coApplicantDateOfBirth.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantDateOfBirth"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={6} lg={4}>
                            <CustomInput
                              labelText="SSN"
                              id="ssn"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantSsn",
                                value: coApplicantFields.coApplicantSsn.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantSsn"),
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={3} lg={4}>
                            <CustomInput
                              labelText="Home Phone"
                              id="home-phone"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantHomePhone",
                                value: coApplicantFields.coApplicantHomePhone.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantHomePhone"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={4}>
                            <CustomInput
                              labelText="Cell Phone"
                              id="cell-phone"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantPhone",
                                value: coApplicantFields.coApplicantPhone.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantPhone"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={4}>
                            <CustomInput
                              labelText="Preferred Email"
                              id="email"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantEmail",
                                value: coApplicantFields.coApplicantEmail.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantEmail"),
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={3} lg={4}>
                            <CustomInput
                              labelText="Home Address"
                              id="home-address"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantHomeAddress",
                                value: coApplicantFields.coApplicantHomeAddress.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantHomeAddress"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="City"
                              id="city"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantHomeCity",
                                value: coApplicantFields.coApplicantHomeCity.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantHomeCity"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="State"
                              id="state"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantHomeState",
                                value: coApplicantFields.coApplicantHomeState.value,
                                onChange: e => {
                                  setCoApplicantInWisconsin(e.target.value === "WI");
                                  setCoApplicantValue(e.target.value, "coApplicantHomeState");
                                },
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Zip"
                              id="zip"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantHomePostalCode",
                                value: coApplicantFields.coApplicantHomePostalCode.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantHomePostalCode"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="County"
                              id="homeCounty"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantHomeCounty",
                                value: coApplicantFields.coApplicantHomeCounty.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantHomeCounty"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Time At Present Address In Years"
                              id="time-years"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantTimeAtAddressInYears",
                                value: coApplicantFields.coApplicantTimeAtAddressInYears.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantTimeAtAddressInYears"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Time At Present Address In Months"
                              id="time-months"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantTimeAtAddressInMonths",
                                value: coApplicantFields.coApplicantTimeAtAddressInMonths.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantTimeAtAddressInMonths"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        {isCoApplicantInWisconsin && (
                          <>
                            <Grid container>
                              <GridItem xs={12} sm={12} md={3} lg={4}>
                                <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                  <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                    Co-Applicant Marital Status
                                  </InputLabel>
                                  <Select
                                    required
                                    inputProps={{
                                      value: coApplicantFields.coApplicantMaritalStatus.value,
                                      name: "coApplicantMaritalStatus",
                                      id: "simple-select",
                                      onChange: e => {
                                        setCoApplicantValue(e.target.value, "coApplicantMaritalStatus");
                                        if (e.target.value === "MARRIED" || e.target.value === "SEPARATED") {
                                          setCoApplicantIsOrWasMarried(true);
                                        } else {
                                          setCoApplicantIsOrWasMarried(false);
                                        }
                                      },
                                    }}
                                  >
                                    <MenuItem value={"UNMARRIED_SINGLE_WIDOWED_DIVORCED"}>
                                      Unmarried (Includes single, windowed, divorced)
                                    </MenuItem>
                                    <MenuItem value={"MARRIED"}>Married</MenuItem>
                                    <MenuItem value={"SEPARATED"}>Separated</MenuItem>
                                  </Select>
                                </FormControl>
                              </GridItem>
                              {coApplicantIsOrWasMarried && (
                                <>
                                  <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <CustomInput
                                      labelText="Co-Applicant Spouse's Last Name"
                                      id="prev-city"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        type: "text",
                                        name: "nonApplicantSpousesLastName",
                                        value: coApplicantFields.nonApplicantSpousesLastName.value,
                                        onChange: e =>
                                          setCoApplicantValue(e.target.value, "nonApplicantSpousesLastName"),
                                      }}
                                    />
                                  </GridItem>

                                  <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <CustomInput
                                      labelText="Co-Applicant Spouse's First Name"
                                      id="prev-city"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        type: "text",
                                        name: "nonApplicantSpousesFirstName",
                                        value: coApplicantFields.nonApplicantSpousesFirstName.value,
                                        onChange: e =>
                                          setCoApplicantValue(e.target.value, "nonApplicantSpousesFirstName"),
                                      }}
                                    />
                                  </GridItem>

                                  <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <CustomInput
                                      labelText="Co-Applicant Spouse's Address"
                                      id="prev-city"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        type: "text",
                                        name: "nonApplicantSpousesAddress",
                                        value: coApplicantFields.nonApplicantSpousesAddress.value,
                                        onChange: e =>
                                          setCoApplicantValue(e.target.value, "nonApplicantSpousesAddress"),
                                      }}
                                    />
                                  </GridItem>

                                  <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <CustomInput
                                      labelText="Co-Applicant Spouse's City"
                                      id="prev-city"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        type: "text",
                                        name: "nonApplicantSpousesCity",
                                        value: coApplicantFields.nonApplicantSpousesCity.value,
                                        onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesCity"),
                                      }}
                                    />
                                  </GridItem>

                                  <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <CustomInput
                                      labelText="Co-Applicant Spouse's State"
                                      id="prev-city"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        type: "text",
                                        name: "nonApplicantSpousesState",
                                        value: coApplicantFields.nonApplicantSpousesState.value,
                                        onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesState"),
                                      }}
                                    />
                                  </GridItem>

                                  <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <CustomInput
                                      labelText="Co-Applicant Spouse's Zip"
                                      id="prev-city"
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        type: "text",
                                        name: "nonApplicantSpousesZip",
                                        value: coApplicantFields.nonApplicantSpousesZip.value,
                                        onChange: e => setCoApplicantValue(e.target.value, "nonApplicantSpousesZip"),
                                      }}
                                    />
                                  </GridItem>
                                </>
                              )}
                            </Grid>
                          </>
                        )}

                        <Grid container>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Residence Type
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantResidenceType.value,
                                  name: "coApplicantResidenceType",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantResidenceType"),
                                }}
                              >
                                <MenuItem value={"OWNS_HOME_OUTRIGHT"}>Owns Home Outright</MenuItem>
                                <MenuItem value={"BUYING_HOME"}>Buying Home</MenuItem>
                                <MenuItem value={"LIVING_WITH_RELATIVES"}>Living With Relatives</MenuItem>
                                <MenuItem value={"LEASING_OR_RENTING"}>Leasing Or Renting</MenuItem>
                                <MenuItem value={"OWNS_BUYING_MOBILE_HOME"}>Owns/Buying Mobile Home</MenuItem>
                                <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Rent/Mortgage"
                              id="rent-mortgage"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantMonthlyHousingPayment",
                                value: coApplicantFields.coApplicantMonthlyHousingPayment.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantMonthlyHousingPayment"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        <Grid container>
                          <GridItem xs={12} sm={12} md={3} lg={4}>
                            <CustomInput
                              labelText="Prev Home Address"
                              id="prev-home-address"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "prevAddress",
                                value: coApplicantFields.coApplicantPrevAddress.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantPrevAddress"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Prev City"
                              id="prev-city"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "prevCity",
                                value: coApplicantFields.coApplicantPrevCity.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantPrevCity"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Prev State"
                              id="prev-state"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "prevState",
                                value: coApplicantFields.coApplicantPrevState.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantPrevState"),
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Prev Zip"
                              id="prev-zip"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "prevPostalCode",
                                value: coApplicantFields.coApplicantPrevPostalCode.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantPrevPostalCode"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Prev County"
                              id="prevCounty"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "prevCounty",
                                value: coApplicantFields.coApplicantPrevCounty.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantPrevCounty"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Time At Previous Address In Years"
                              id="time-at-previous-address-years"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "timeAtPrevAddressYears",
                                value: coApplicantFields.coApplicantTimeAtPrevAddressYears.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantTimeAtPrevAddressYears"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Time At Previous Address In Months"
                              id="time-at-previous-address-months"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "timeAtPrevAddressMonths",
                                value: coApplicantFields.coApplicantTimeAtPrevAddressMonths.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantTimeAtPrevAddressMonths"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        <Grid container>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Creditor One Name"
                              id="creditor-one-name"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "creditorOneName",
                                value: coApplicantFields.coApplicantCreditorOneName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantCreditorOneName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Creditor One Balance"
                              id="creditor-one-balance"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "creditorOneBalance",
                                value: coApplicantFields.coApplicantCreditorOneBalance.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantCreditorOneBalance"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Creditor One Monthly Payment"
                              id="creditor-one-monthly-payment"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "creditorOneMonthlyPayment",
                                value: coApplicantFields.coApplicantCreditorOneMonthlyPayment.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantCreditorOneMonthlyPayment"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        <Grid container>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Creditor Two Name"
                              id="creditor-two-name"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "creditorTwoName",
                                value: coApplicantFields.coApplicantCreditorTwoName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantCreditorTwoName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Creditor Two Balance"
                              id="creditor-two-balance"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "creditorTwoBalance",
                                value: coApplicantFields.coApplicantCreditorTwoBalance.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantCreditorTwoBalance"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Creditor Two Monthly Payment"
                              id="creditor-two-monthly-payment"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "creditorTwoMonthlyPayment",
                                value: coApplicantFields.coApplicantCreditorTwoMonthlyPayment.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantCreditorTwoMonthlyPayment"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        <Grid>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Co. Financing Auto Loan"
                              id="co-applicant-company-financing-auto-loan"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "companyFinancingAutoLoan",
                                value: coApplicantFields.coApplicantCompanyFinancingAutoLoan.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantCompanyFinancingAutoLoan"),
                              }}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Prev. Auto Loan Acct #"
                              id="co-applicant-prev-auto-loan-account-number"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "previousAutoLoanAccountNumber",
                                value: coApplicantFields.coApplicantPreviousAutoLoanAccountNumber.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantPreviousAutoLoanAccountNumber"),
                              }}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Prev. Auto Loan Monthly Payment"
                              id="co-applicant-prev-auto-loan-monthly-payment"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "previousAutoLoanMonthlyPayment",
                                value: coApplicantFields.coApplicantPreviousAutoLoanMonthlyPayment.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantPreviousAutoLoanMonthlyPayment"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        <Grid container>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <CustomInput
                              labelText="Bank Name"
                              id="co-applicant-bank-name"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankName",
                                value: coApplicantFields.coApplicantBankName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={2} lg={2}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Bank Account Type
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantBankAccountType.value,
                                  name: "bankAccountType",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankAccountType"),
                                }}
                              >
                                <MenuItem value={"CHECKING"}>Checking</MenuItem>
                                <MenuItem value={"SAVINGS"}>Savings</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank Account Number"
                              id="co-applicant-bank-account-number"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankAccountNum",
                                value: coApplicantFields.coApplicantBankAccountNum.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankAccountNum"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank Account Balance"
                              id="co-applicant-bank-account-balance"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankAverageBalance",
                                value: coApplicantFields.coApplicantBankAverageBalance.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankAverageBalance"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank Phone #"
                              id="co-applicant-bank-phone"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankPhone",
                                value: coApplicantFields.coApplicantBankPhone.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankPhone"),
                              }}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank Address"
                              id="co-applicant-bank-address"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankAddress",
                                value: coApplicantFields.coApplicantBankAddress.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankAddress"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank Address 2"
                              id="co-applicant-bank-address-2"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankAddressLine2",
                                value: coApplicantFields.coApplicantBankAddressLine2.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankAddressLine2"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank City"
                              id="co-applicant-bank-city"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankCity",
                                value: coApplicantFields.coApplicantBankCity.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankCity"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank State"
                              id="co-applicant-bank-state"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankState",
                                value: coApplicantFields.coApplicantBankState.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankState"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bank Zip"
                              id="co-applicant-bank-zip"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "bankZip",
                                value: coApplicantFields.coApplicantBankZip.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankZip"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        <Grid>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Bankruptcy
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantPriorBankruptcy.value,
                                  name: "priorBankruptcy",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantPriorBankruptcy"),
                                }}
                              >
                                <MenuItem value={"Has not declared bankruptcy"}>Has not declared bankruptcy</MenuItem>
                                <MenuItem value={"Has declared bankruptcy"}>Has declared bankruptcy</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Bankruptcy Date (MM/DD/YYYY)"
                              id="coApplicantBankruptcyDate"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantBankruptcyDate",
                                value: coApplicantFields.coApplicantBankruptcyDate.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantBankruptcyDate"),
                              }}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Prior Repossession
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantPriorRepossession.value,
                                  name: "coApplicantPriorRepossession",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantPriorRepossession"),
                                }}
                              >
                                <MenuItem value={"Yes"}>Has prior repossession</MenuItem>
                                <MenuItem value={"No"}>No prior repossession</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Repossession Date (MM/DD/YYYY)"
                              id="coApplicantRepossesionDate"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantRepossesionDate",
                                value: coApplicantFields.coApplicantRepossessionDate.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantRepossessionDate"),
                              }}
                            />
                          </GridItem>
                        </Grid>

                        <Grid container>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Education Level
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantEducationLevel.value,
                                  name: "coApplicantEducationLevel",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantEducationLevel"),
                                }}
                              >
                                <MenuItem value={"FOUR_YEAR_COLLEGE_GRAD"}>Four Year College Grad</MenuItem>
                                <MenuItem value={"TWO_YEAR_COLLEGE_GRAD"}>Two Year College Grad</MenuItem>
                                <MenuItem value={"SPECIALTY_TRAINING"}>Specialty Training</MenuItem>
                                <MenuItem value={"SOME_COLLEGE"}>Some College</MenuItem>
                                <MenuItem value={"HIGH_SCHOOL_GRAD"}>High School Grad</MenuItem>
                                <MenuItem value={"NOT_HIGH_SCHOOL_GRAD"}>Not High School Grad</MenuItem>
                                <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Employment Status
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantEmploymentStatus.value,
                                  name: "coApplicantEmploymentStatus",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantEmploymentStatus"),
                                }}
                              >
                                <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                                <MenuItem value={"PART_TIME"}>Part-Time</MenuItem>
                                <MenuItem value={"RETIRED"}>Retired</MenuItem>
                                <MenuItem value={"MILITARY"}>Military</MenuItem>
                                <MenuItem value={"SELF_EMPLOYED"}>Self-Employed</MenuItem>
                                <MenuItem value={"CONTRACT"}>Contract</MenuItem>
                                <MenuItem value={"SEASONAL"}>Seasonal</MenuItem>
                                <MenuItem value={"TEMPORARY"}>Temporary</MenuItem>
                                <MenuItem value={"NOT_APPLICABLE"}>Not Applicable</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                Employee Type
                              </InputLabel>
                              <Select
                                required
                                inputProps={{
                                  value: coApplicantFields.coApplicantEmployeeType.value,
                                  name: "coApplicantEmployeeType",
                                  id: "simple-select",
                                  onChange: e => setCoApplicantValue(e.target.value, "coApplicantEmployeeType"),
                                }}
                              >
                                <MenuItem value={"Professional"}>Professional</MenuItem>
                                <MenuItem value={"Executive/Managerial"}>Executive/Managerial</MenuItem>
                                <MenuItem value={"Clerical"}>Clerical</MenuItem>
                                <MenuItem value={"Skilled Labor"}>Skilled Labor</MenuItem>
                                <MenuItem value={"Semi-Skilled Labor"}>Semi-Skilled Labor</MenuItem>
                                <MenuItem value={"Craftsman"}>Craftsman</MenuItem>
                                <MenuItem value={"Sales/Advertising"}>Sales/Advertising</MenuItem>
                                <MenuItem value={"Government"}>Government</MenuItem>
                                <MenuItem value={"Auto Worker"}>Auto Worker</MenuItem>
                                <MenuItem value={"Farmer"}>Farmer</MenuItem>
                                <MenuItem value={"Fisherman"}>Fisherman</MenuItem>
                                <MenuItem value={"Homemaker"}>Homemaker</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Empl. Title"
                              id="empl-title"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantEmployeeTitle",
                                value: coApplicantFields.coApplicantEmployeeTitle.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantEmployeeTitle"),
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Employer"
                              id="employer"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantEmployerName",
                                value: coApplicantFields.coApplicantEmployerName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantEmployerName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Phone"
                              id="empl-phone"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantEmployerPhone",
                                value: coApplicantFields.coApplicantEmployerPhone.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantEmployerPhone"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Time At Job In Years"
                              id="job-time-years"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantPeriodOfEmploymentYears",
                                value: coApplicantFields.coApplicantPeriodOfEmploymentYears.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantPeriodOfEmploymentYears"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Time At Job In Month"
                              id="job-time-month"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantPeriodOfEmploymentMonths",
                                value: coApplicantFields.coApplicantPeriodOfEmploymentMonths.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantPeriodOfEmploymentMonths"),
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Gross Income"
                              id="gross-income"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantAnnualIncome",
                                value: coApplicantFields.coApplicantAnnualIncome.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantAnnualIncome"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Other Income Source"
                              id="other-income"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantIncomeSourceOther",
                                value: coApplicantFields.coApplicantIncomeSourceOther.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantIncomeSourceOther"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3} lg={3}>
                            <CustomInput
                              labelText="Other Income Amount"
                              id="other-income"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantIncomeAmountPerYearOther",
                                value: coApplicantFields.coApplicantIncomeAmountPerYearOther.value,
                                onChange: e =>
                                  setCoApplicantValue(e.target.value, "coApplicantIncomeAmountPerYearOther"),
                              }}
                            />
                          </GridItem>
                        </Grid>
                        <Grid container>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Previous Employer Name"
                              id="prev-employer"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantPreviousEmployerName",
                                value: coApplicantFields.coApplicantPreviousEmployerName.value,
                                onChange: e => setCoApplicantValue(e.target.value, "coApplicantPreviousEmployerName"),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Time At Previous Employer In Years"
                              id="prev-empl-year"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantPreviousEmployerPeriodOfEmploymentYears",
                                value: coApplicantFields.coApplicantPreviousEmployerPeriodOfEmploymentYears.value,
                                onChange: e =>
                                  setCoApplicantValue(
                                    e.target.value,
                                    "coApplicantPreviousEmployerPeriodOfEmploymentYears"
                                  ),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4} lg={4}>
                            <CustomInput
                              labelText="Time At Previous Employer In Months"
                              id="prev-empl-months"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: "coApplicantPreviousEmployerPeriodOfEmploymentMonths",
                                value: coApplicantFields.coApplicantPreviousEmployerPeriodOfEmploymentMonths.value,
                                onChange: e =>
                                  setCoApplicantValue(
                                    e.target.value,
                                    "coApplicantPreviousEmployerPeriodOfEmploymentMonths"
                                  ),
                              }}
                            />
                          </GridItem>
                        </Grid>
                      </>
                    )}

                    <h3>Sale Vehicle</h3>
                    <Grid container>
                      {Object.entries(equipmentFields).map(item =>
                        item[1].type === "select" ? (
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <FormControl style={{ paddingTop: "10px" }} fullWidth>
                              <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                {item[1].label}
                              </InputLabel>
                              <Select
                                inputProps={{
                                  name: item[0],
                                  id: "simple-select",
                                  onChange: e =>
                                    setValue(
                                      e.target.value,
                                      item[0],
                                      equipmentFields,
                                      setEquipmentFields,
                                      item[1].label,
                                      item[1].type,
                                      item[1].selectOptions
                                    ),
                                }}
                              >
                                {item[1].selectOptions.map(item => (
                                  <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        ) : item[1].type === "calculated" ? (
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText={item[1].label}
                              id={item[1].label}
                              disabled
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                disabled: true,
                                type: "text",
                                name: item[0],
                                value: item[1].value,
                                onChange: e =>
                                  setValue(e.target.value, item[0], equipmentFields, setEquipmentFields, item[1].label),
                              }}
                            />
                          </GridItem>
                        ) : (
                          <GridItem xs={12} sm={6} md={6} lg={6}>
                            <CustomInput
                              labelText={item[1].label}
                              id={item[1].label}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                type: "text",
                                name: item[0],
                                value: item[1].value,
                                onChange: e =>
                                  setValue(e.target.value, item[0], equipmentFields, setEquipmentFields, item[1].label),
                              }}
                            />
                          </GridItem>
                        )
                      )}
                    </Grid>
                    <Button onClick={() => setShowEquipmentAdditionalFields(!showEquipmentAdditionalFields)}>
                      {showEquipmentAdditionalFields
                        ? "Hide Additional Equipment Fields"
                        : "Show Additional Equipment Fields"}
                    </Button>
                    {showEquipmentAdditionalFields && (
                      <>
                        <h3>Sale Vehicle Additional Fields</h3>
                        <Grid container>
                          {Object.entries(equipmentAdditionalFields).map(item =>
                            item[1].type === "select" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                  <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                    {item[1].label}
                                  </InputLabel>
                                  <Select
                                    inputProps={{
                                      name: item[0],
                                      id: "simple-select",
                                      onChange: e =>
                                        setValue(
                                          e.target.value,
                                          item[0],
                                          equipmentAdditionalFields,
                                          setEquipmentAdditionalFields,
                                          item[1].label,
                                          item[1].type,
                                          item[1].selectOptions
                                        ),
                                    }}
                                  >
                                    {item[1].selectOptions.map(item => (
                                      <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            ) : item[1].type === "calculated" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  disabled
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(
                                        e.target.value,
                                        item[0],
                                        equipmentAdditionalFields,
                                        setEquipmentAdditionalFields,
                                        item[1].label
                                      ),
                                  }}
                                />
                              </GridItem>
                            ) : (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(
                                        e.target.value,
                                        item[0],
                                        equipmentAdditionalFields,
                                        setEquipmentAdditionalFields,
                                        item[1].label
                                      ),
                                  }}
                                />
                              </GridItem>
                            )
                          )}
                        </Grid>
                      </>
                    )}
                    <Button onClick={() => setShowTradeInFields(!showTradeInFields)}>
                      {showTradeInFields ? "Hide Trade-In" : "Add Trade-In"}
                    </Button>
                    {showTradeInFields && (
                      <>
                        <h3>Trade-In Fields</h3>
                        <Grid container>
                          {Object.entries(tradeinFields).map(item =>
                            item[1].type === "select" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                  <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                    {item[1].label}
                                  </InputLabel>
                                  <Select
                                    inputProps={{
                                      name: item[0],
                                      id: "simple-select",
                                      onChange: e =>
                                        setValue(
                                          e.target.value,
                                          item[0],
                                          tradeinFields,
                                          setTradeinFields,
                                          item[1].label,
                                          item[1].type,
                                          item[1].selectOptions
                                        ),
                                    }}
                                  >
                                    {item[1].selectOptions.map(item => (
                                      <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            ) : item[1].type === "calculated" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  disabled
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(e.target.value, item[0], tradeinFields, setTradeinFields, item[1].label),
                                  }}
                                />
                              </GridItem>
                            ) : (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(e.target.value, item[0], tradeinFields, setTradeinFields, item[1].label),
                                  }}
                                />
                              </GridItem>
                            )
                          )}
                        </Grid>
                        <Button onClick={() => setShowTradeInAdditionalFields(!showTradeInAdditionalFields)}>
                          {showTradeInAdditionalFields
                            ? "Hide Additional Trade-In Fields"
                            : "Add Trade-In Additional Fields"}
                        </Button>
                      </>
                    )}
                    {showTradeInAdditionalFields && (
                      <>
                        <h3>Trade-In Additional Fields</h3>
                        <Grid container>
                          {Object.entries(tradeinAdditionalFields).map(item =>
                            item[1].type === "select" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <FormControl style={{ paddingTop: "10px" }} fullWidth>
                                  <InputLabel style={{ paddingTop: "10px" }} htmlFor="simple-select">
                                    {item[1].label}
                                  </InputLabel>
                                  <Select
                                    inputProps={{
                                      name: item[0],
                                      id: "simple-select",
                                      onChange: e =>
                                        setValue(
                                          e.target.value,
                                          item[0],
                                          tradeinAdditionalFields,
                                          setTradeinAdditionalFields,
                                          item[1].label,
                                          item[1].type,
                                          item[1].selectOptions
                                        ),
                                    }}
                                  >
                                    {item[1].selectOptions.map(item => (
                                      <MenuItem value={item.value}>{item.label}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            ) : item[1].type === "calculated" ? (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  disabled
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(
                                        e.target.value,
                                        item[0],
                                        tradeinAdditionalFields,
                                        setTradeinAdditionalFields,
                                        item[1].label
                                      ),
                                  }}
                                />
                              </GridItem>
                            ) : (
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <CustomInput
                                  labelText={item[1].label}
                                  id={item[1].label}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    type: "text",
                                    name: item[0],
                                    value: item[1].value,
                                    onChange: e =>
                                      setValue(
                                        e.target.value,
                                        item[0],
                                        tradeinAdditionalFields,
                                        setTradeinAdditionalFields,
                                        item[1].label
                                      ),
                                  }}
                                />
                              </GridItem>
                            )
                          )}
                        </Grid>
                      </>
                    )}
                    {/* {contractType === "retail" ? (
                        <>
                          <h3>Retail Contract Information</h3>
                          <Grid container>
                          { 
                            Object.entries(retailContractFields).map(item => (
                              item[1].type === 'select' ? 
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    htmlFor="simple-select"
                                  >
                                    {item[1].label}
                                  </InputLabel>
                                  <Select
                                    inputProps={{
                                      name: item[0],
                                      id: "simple-select",
                                      onChange: (e) => setValue(e.target.value, item[0], retailContractFields, setRetailContractFields)
                                    }}
                                  >
                                    {item[1].selectOptions.map(item =>
                                      <MenuItem value={item.value}>{item.label}</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </GridItem>
                             : item[1].type === 'calculated' ? 
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                disabled
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  disabled:true,
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: (e) => setValue(e.target.value, item[0], retailContractFields, setRetailContractFields)
                                }}
                              />
                            </GridItem>
                             :
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: (e) => setValue(e.target.value, item[0], retailContractFields, setRetailContractFields)
                                }}
                              />
                            </GridItem>
                              ))
                            }
                          </Grid>
                        </>
                      ) : (
                        <>
                          <h3>Lease Contract Information</h3>
                          <Grid container>
                          { 
                            Object.entries(leaseContractFields).map(item => (
                              item[1].type === 'select' ? 
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    htmlFor="simple-select"
                                  >
                                    {item[1].label}
                                  </InputLabel>
                                  <Select
                                    inputProps={{
                                      name: item[0],
                                      id: "simple-select",
                                      onChange: (e) => setValue(e.target.value, item[0], leaseContractFields, setLeaseContractFields)
                                    }}
                                  >
                                    {item[1].selectOptions.map(item =>
                                      <MenuItem value={item.value}>{item.label}</MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </GridItem>
                             : item[1].type === 'calculated' ? 
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                disabled
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  disabled:true,
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: (e) => setValue(e.target.value, item[0], leaseContractFields, setLeaseContractFields)
                                }}
                              />
                            </GridItem>
                             :
                              <GridItem xs={12} sm={6} md={6} lg={6}>
                              <CustomInput
                                labelText={item[1].label}
                                id={item[1].label}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  type: "text",
                                  name: item[0],
                                  value: item[1].value,
                                  onChange: (e) => setValue(e.target.value, item[0], leaseContractFields, setLeaseContractFields)
                                }}
                              />
                            </GridItem>
                              ))
                            }
                          </Grid>
                        </>
                      )

                      } */}
                    <br />
                    <Grid container justify="center" alignItems="center">
                      <GridItem xs={8} sm={3} md={3} lg={2}>
                        <Button
                          className="formBtn"
                          color="primary"
                          variant="contained"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={submitting || !formValid}
                        >
                          {!submitting ? "Submit" : "Submitting"}
                        </Button>
                      </GridItem>
                    </Grid>
                  </Tile>
                </GridItem>
              </Grid>
              <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Successfully submitted your credit app!
                </Alert>
              </Snackbar>
              <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            </form>
          );
        }}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
    account: state.account,
  };
};

const mapDispatchToProps = {
  setRefetch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteOneBizApp);
