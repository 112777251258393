import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Modal } from "@material-ui/core";
import Tile from "../components/Tile/Tile";
import CompanySearchModal from "./CompanySearchModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Replay } from "@material-ui/icons";
import { FindInPage } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import gql from "graphql-tag";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import { theme } from "../theme";

const TRIGGER_CREDIT_REPORT_BUSINESS_PULL = gql`
  mutation($input: TriggerCreditReportBusinessPullInput!) {
    triggerCreditReportBusinessPull(input: $input) {
      creditReportBusinessId
      externalReportId
      vendorOpportunityId
      reportType
      score
      machineReadableReport
      humanReadableReportDocumentId
      errorDescription
      created_date_time
    }
  }
`;

const COMPANY_SEARCH = gql`
  query($input: CompanySearchInput!) {
    companySearch(input: $input) {
      id
      companyName
      street
      city
      postalCode
      provinceAbbreviation
      country
      confidenceScore
    }
  }
`;

const useStyles = makeStyles(theme => ({
  ...styles,
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 500,
    maxHeight: "80%",
    "@media (max-width: 768px)": {
      width: "96vw",
      minWidth: "96vw",
    },
  },
  closeButton: {
    margin: "auto 4px",
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
  },
  pullCreditContainer: {
    display: "flex",
    flexDirection: "column",
    width: 500,
    padding: "16px 32px 32px",

    "& button": {
      margin: "32px auto 16px",
    },
  },
  wrapperLoader: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.grey[500],
    width: "100%",
    alignItems: "center",

    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      marginBottom: 32,

      "& h4": {
        marginLeft: 8,
      },
    },
    "& .progressLoader": {
      margin: 32,
      color: theme.palette.grey[500],
    },
  },
}));

export default props => {
  const {
    vo,
    creditApp,
    userDocs,
    setCreditPullErrorSnackBarText,
    setOpenCreditPullErrorSnackBar,
    userDocsRefetch,
    refetchCreditApp,
    doneRefetchingCreditApp,
    setDoneRefetchingDocuments,
    setDocumentIdToAutomaticallyOpen,
  } = props;
  const classes = useStyles();
  const [businessCreditReportLoading, setBusinessCreditReportLoading] = useState(false);
  const [openCompanySearchModal, setOpenCompanySearchModal] = useState(false);
  const [companySearchResult, setCompanySearchResult] = useState(null);
  const [creditReportDownloaded, setCreditReportDownloaded] = useState(false);
  const [creditReportId, setCreditReportId] = useState(null);

  const [companySearch, { data: searchResult, refetch }] = useLazyQuery(COMPANY_SEARCH, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
  });

  const findUserDoc = id => {
    return userDocs?.documents?.find(d => d.documentId === id);
  };

  const handleViewCreditReportBusiness = id => {
    const doc = findUserDoc(id);
    if (doc) window.open(doc.source, "_newtab");
    return;
  };

  const autoScrollToDocuments = () => {
    const el = document.getElementById("documentList");
    el.scrollIntoView({ behavior: "smooth" });
  };

  async function triggerCommercialCreditPull() {
    setBusinessCreditReportLoading(true);
    let variables = {
      input: {
        countryAbbreviation: "US",
        companyName: creditApp.businessName,
        address: creditApp.address,
        city: creditApp.city,
        postalCode: creditApp.postalCode,
        provinceAbbreviation: creditApp.state,
        dba: creditApp.dba,
        phone: creditApp.phone,
        taxId: creditApp.taxId ? creditApp.taxId.toString() : "",
        // dynamicsCreditApplicationId: creditApp.dynamicsId,
        // creditReporterCode: "PAYNET",
      },
    };
    await companySearch({
      variables,
    });
    // await triggerCreditReportBusinessPull({
    //   variables,
    // });
    // setTimeout(async () => {
    //   await refetchCreditApp();
    //   setDoneRefetchingCreditApp(true);
    // }, 1000 * 7);
  }

  const handleCompanySearchModalClose = async (creditReportDocId, creditApp, autoscroll) => {
    await userDocsRefetch();
    setOpenCompanySearchModal(false);
    console.log(`CREDIT REPORT DOC ID: ${creditReportDocId}`);
    setDocumentIdToAutomaticallyOpen(creditReportDocId);
    setDoneRefetchingDocuments(true);
    if (creditApp?.mostRecentCreditReportBusiness?.humanReadableReportDocumentId && !autoscroll) {
      handleViewCreditReportBusiness(creditApp.mostRecentCreditReportBusiness.humanReadableReportDocumentId);
    }
    setCompanySearchResult(null);
    setCreditReportDownloaded(false);
    setCreditReportId(null);
    if (autoscroll) {
      autoScrollToDocuments();
    }
  };

  useEffect(() => {
    if (businessCreditReportLoading && doneRefetchingCreditApp) {
      const businessReport = creditApp.mostRecentCreditReportBusiness;
      setBusinessCreditReportLoading(false);
      if (businessReport && businessReport.humanReadableReportDocumentId) {
        setOpenCreditPullErrorSnackBar(false);
      } else {
        setCreditPullErrorSnackBarText("Could not pull commercial credit report. Please contact support.");
        setOpenCreditPullErrorSnackBar(true);
      }
    }
  }, [businessCreditReportLoading, doneRefetchingCreditApp]);

  useEffect(() => {
    if (searchResult && businessCreditReportLoading) {
      console.log("--------------------------------------");
      console.log(JSON.stringify(searchResult));
      console.log("--------------------------------------");
      setBusinessCreditReportLoading(false);
      setCompanySearchResult(searchResult);
    }
  }, [searchResult, businessCreditReportLoading]);

  const docExists = findUserDoc(creditApp?.mostRecentCreditReportBusiness?.humanReadableReportDocumentId)
    ? true
    : false;

  return (
    <span>
      {_.get(creditApp, "mostRecentCreditReportBusiness.humanReadableReportDocumentId", null) && docExists && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              color: theme.palette.primary.main,
              cursor: "pointer",
              textDecoration: "underline",
              marginRight: 4,
            }}
            onClick={() =>
              handleViewCreditReportBusiness(creditApp.mostRecentCreditReportBusiness.humanReadableReportDocumentId)
            }
          >
            View Credit Report
          </div>
          {!businessCreditReportLoading && (
            <>
              <IconButton onClick={() => autoScrollToDocuments()} title="View file in Documents" style={{ padding: 4 }}>
                <FindInPage />
              </IconButton>
              <IconButton
                onClick={() => setOpenCompanySearchModal(true)}
                title="Search Credit Report Again"
                style={{ padding: 4 }}
              >
                <Replay />
              </IconButton>
            </>
          )}
        </div>
      )}
      {(!creditApp.mostRecentCreditReportBusiness || !docExists) && (
        <span>
          {!businessCreditReportLoading && (
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{ marginLeft: 0 }}
              onClick={() => setOpenCompanySearchModal(true)}
            >
              Pull Paynet
            </Button>
          )}
        </span>
      )}
      <Modal open={openCompanySearchModal} onClose={handleCompanySearchModalClose} aria-labelledby="Company Search">
        <div className={classes.modalStyle}>
          <Tile
            title="Credit Report Search"
            titleButtonComponent={
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  handleCompanySearchModalClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {businessCreditReportLoading && (
              <div className={classes.wrapperLoader}>
                <CircularProgress className="progressLoader" size={48} />
                <div className="headerContainer">
                  <SearchIcon />
                  <h4>Searching for Company...</h4>
                </div>
              </div>
            )}
            {!!companySearchResult && !creditReportDownloaded ? (
              <CompanySearchModal
                vo={vo}
                companySearchResult={companySearchResult}
                creditApp={creditApp}
                refetchCreditApp={refetchCreditApp}
                userDocsRefetch={userDocsRefetch}
                setCreditReportDownloaded={setCreditReportDownloaded}
                setCreditReportId={setCreditReportId}
              />
            ) : (
              <>
                {!businessCreditReportLoading && !creditReportDownloaded && (
                  <div className={classes.pullCreditContainer}>
                    <p>Search credit report for applicant:</p>
                    <br />
                    <div style={{ margin: "0 auto" }}>
                      <p>{creditApp.businessName}</p>
                      <p>{creditApp.address}</p>
                      <p>
                        {creditApp.city}, {creditApp.state} {creditApp.postalCode}
                      </p>
                    </div>
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      startIcon={<SearchIcon />}
                      onClick={() => triggerCommercialCreditPull(creditApp.id)}
                    >
                      Search
                    </Button>
                  </div>
                )}
              </>
            )}
            {creditReportDownloaded && (
              <div className={classes.pullCreditContainer}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CheckIcon style={{ color: "#4CAF50" }} />
                  <h4 style={{ marginLeft: 4 }}>Credit Report Downloaded</h4>
                </div>
                <br />
                <p style={{ textAlign: "center" }}>
                  The report has been added to the opportunity{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCompanySearchModalClose(creditReportId, creditApp, true)}
                  >
                    documents
                  </a>
                  .
                </p>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleCompanySearchModalClose(creditReportId, creditApp)}
                >
                  View Report
                </Button>
              </div>
            )}
          </Tile>
        </div>
      </Modal>
    </span>
  );
};
