import React, { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "graphql-tag";
import { Grid, LinearProgress, Snackbar, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "../components/CustomButtons/Button";
import InfoIcon from "@material-ui/icons/Info";
import { formatCurrency } from "../utils";

const SEARCH_INVENTORY = gql`
  query SearchInventory($input: InventorySearchInput!) {
    inventorySearch(input: $input) {
      vin
      source
      stockNumber
      description
      year
      make
      model
      mileage
      condition
      retailPrice
    }
  }
`;

const SYNC_INVENTORY = gql`
  mutation SyncInventoryItemToVo($vendorOpportunityId: ID!, $stockNumber: String, $vin: String) {
    syncInventoryItemToVo(input: { vendorOpportunityId: $vendorOpportunityId, stockNumber: $stockNumber, vin: $vin }) {
      equipmentId
      success
      message
    }
  }
`;
const inputStyles = {
  padding: "8px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginRight: "10px",
};

const InventorySearch = ({
  vendorOpportunityId,
  handleReloadEquipmentData,
  hasAccessToInventorySearch,
  refetchDocs,
}) => {
  const [stockNumber, setStockNumber] = useState("");
  const [vin, setVin] = useState("");
  const [searchInventory, { data: inventoryData, loading: inventoryDataIsLoading }] = useLazyQuery(SEARCH_INVENTORY, {
    fetchPolicy: "no-cache",
  });
  const [syncInventory] = useMutation(SYNC_INVENTORY);
  const [inventorySyncIsLoading, setInventorySyncIsLoading] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [failureSnackbarOpen, setFailureSnackbarOpen] = useState(false);

  const handleSearch = () => {
    if (stockNumber || vin) {
      searchInventory({
        variables: {
          input: {
            stockNumber: stockNumber,
            vin: vin,
          },
        },
      });
    }
  };

  const handleSync = rowData => {
    setInventorySyncIsLoading(true);
    syncInventory({
      variables: {
        vendorOpportunityId: vendorOpportunityId,
        vin: rowData.vin,
        stockNumber: rowData.stockNumber,
      },
      context: { authRequired: true },
    })
      .then(response => {
        try {
          if (response.data.syncInventoryItemToVo.success) {
            setSuccessSnackbarOpen(true);
            handleReloadEquipmentData();
          } else {
            setInventorySyncIsLoading(false);
            setFailureSnackbarOpen(true);
          }
          //Reload documents
          if (refetchDocs) {
            refetchDocs();
          }
        } catch (e) {
          console.log(`Inventory sync error: ${e.message}`);
          setFailureSnackbarOpen(true);
          setInventorySyncIsLoading(false);
        }
      })
      .catch(e => {
        console.log(`Inventory sync error: ${e.message}`);
        setFailureSnackbarOpen(true);
        setInventorySyncIsLoading(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbarOpen(false);
    setFailureSnackbarOpen(false);
  };

  return (
    <div>
      <input
        style={inputStyles}
        type="text"
        placeholder="Enter Stock Number"
        value={stockNumber}
        onChange={e => setStockNumber(e.target.value)}
        disabled={!hasAccessToInventorySearch}
      />
      <input
        style={inputStyles}
        type="text"
        placeholder="Enter VIN"
        value={vin}
        onChange={e => setVin(e.target.value)}
        disabled={!hasAccessToInventorySearch}
      />
      <Button
        color="primary"
        size="sm"
        variant="contained"
        onClick={handleSearch}
        disabled={!hasAccessToInventorySearch}
      >
        Search
      </Button>
      {!hasAccessToInventorySearch && (
        <Grid container>
          <Grid item style={{ display: "flex", marginTop: 20 }}>
            <InfoIcon style={{ color: "#0c84fc", marginRight: 6 }} />
            <Typography>
              Contact your IT Department or Trnsact Account Manager to integrate your inventory feed
            </Typography>
          </Grid>
        </Grid>
      )}
      {(inventoryDataIsLoading || inventorySyncIsLoading) && (
        <div style={{ paddingTop: "16px", paddingBottom: "16px" }}>
          <LinearProgress size={48} />
        </div>
      )}
      {inventoryData && inventoryData.inventorySearch && !inventoryDataIsLoading && !inventorySyncIsLoading && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>VIN</TableCell>
              {/* <TableCell>Source</TableCell> */}
              <TableCell>Stock Number</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Make</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Mileage</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Retail Price</TableCell>
              <TableCell>Sync</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryData.inventorySearch.map(item => (
              <TableRow key={item.vin}>
                <TableCell>{item.vin}</TableCell>
                {/* <TableCell>{item.source}</TableCell> */}
                <TableCell>{item.stockNumber}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.year}</TableCell>
                <TableCell>{item.make}</TableCell>
                <TableCell>{item.model}</TableCell>
                <TableCell>{item.mileage}</TableCell>
                <TableCell>{item.condition}</TableCell>
                <TableCell>{formatCurrency(item.retailPrice)}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleSync(item)}>
                    Sync
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
          Success! Please wait...
        </MuiAlert>
      </Snackbar>
      <Snackbar open={failureSnackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
          Error: Please contact support
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default InventorySearch;
