import React, { useContext, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { DropzoneDialog } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import Modal from "@material-ui/core/Modal";
import { DocumentContext } from "../../contexts/DocumentContext";
import TemplateDescriptionModal from "./TemplateDescriptionModal";
// import mixpanel from '../../mixpanel';

const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};

const UPLOAD_TEMPLATE = gql`
  mutation($input: UpsertTemplateInput!, $file: Upload) {
    upsertTemplate(input: $input, file: $file)
  }
`;

export default function TemplateUploadModal(props) {
  const useStyles = makeStyles(theme => ({
    button: {
      margin: theme.spacing(0, 2),
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [openErrorDialogue, setOpenErrorDialogue] = useState(false);
  // const [openDescriptionDialogue, setOpenDescriptionDialogue] = useState(false);
  const [openUploadZone, setOpenUploadZone] = useState(false);
  const [mutationError, setMutationError] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [openFileDescriptionInputModal, setOpenFileDescriptionInputModal] = useState(false);
  const [openInvoiceQuoteUploadModal, setOpenInvoiceQuoteUploadModal] = useState(false);
  const [fileInput] = useMutation(UPLOAD_TEMPLATE, {
    context: { authRequired: true },
  });
  const documentContext = useContext(DocumentContext);
  const graphqlErrorMessage = {
    duplicateMessage:
      'GraphQL error: duplicate key value violates unique constraint "document_vendor_opportunity_id_doc_name_key"',
  };

  const errorDialogue = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Error</h2>
      <p id="simple-modal-description">
        {!!mutationError && mutationError.message === graphqlErrorMessage.duplicateMessage
          ? "This file is already uploaded"
          : ""}
      </p>
    </div>
  );

  const handleOpenErrorDialogue = () => setOpenErrorDialogue(true);
  const handleCloseErrorDialogue = () => setOpenErrorDialogue(false);
  const handleOpenUploadZone = () => setOpenUploadZone(true);
  const handleCloseUploadZone = () => setOpenUploadZone(false);
  const handleOpenFileDescriptionInputModal = () => setOpenFileDescriptionInputModal(true);
  const handleCloseFileDescriptionInputModal = () => setOpenFileDescriptionInputModal(false);
  const handleOpenInvoiceQuoteUploadModal = () => setOpenInvoiceQuoteUploadModal(true);
  const handleCloseInvoiceQuoteUploadModal = () => setOpenInvoiceQuoteUploadModal(false);
  const handleSaveFile = async uploadFile => {
    if (uploadFile.length === 0) setMutationError("No File Selected");
    try {
      const response = await fileInput({
        variables: {
          input: {
            name: templateName,
            description: fileDescription,
            status: "Draft",
            createdBy: "testing",
          },
          file: uploadFile[0],
        },
      });
      await documentContext.setLastDocument(response.data.upsertTemplate);
      await setTemplateName("");
      await setFileDescription("");
      props.templatesRefetch();

      // mixpanel.track('Document Uploaded');
    } catch (err) {
      console.log(err);
      setMutationError(err);
      handleOpenErrorDialogue();
      await setTemplateName("");
      await setFileDescription("");
    }
  };

  return (
    <div>
      <Button color="primary" size="sm" onClick={handleOpenUploadZone}>
        {props.buttonDescription ? props.buttonDescription() : ""}
      </Button>
      <DropzoneDialog
        open={openUploadZone}
        onDrop={handleOpenFileDescriptionInputModal}
        onSave={file => {
          handleSaveFile(file).then(res => console.log(res));
          handleCloseUploadZone();
        }}
        onClose={() => {
          handleCloseUploadZone();
          // handleOpenFileDescriptionInputModal();
          handleCloseFileDescriptionInputModal();
          setTemplateName("");
          setFileDescription("");
        }}
        filesLimit={1}
        dropzoneText={"Drag or Click Here To Upload File"}
        // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        showPreviews={true}
        maxFileSize={25000000}
      />
      <Modal
        open={openErrorDialogue}
        onClose={handleCloseErrorDialogue}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {errorDialogue}
      </Modal>
      <TemplateDescriptionModal
        setFileDescription={setFileDescription}
        setTemplateName={setTemplateName}
        templateName={templateName}
        fileDescription={fileDescription}
        openFileDescriptionInputModal={openFileDescriptionInputModal}
        handleCloseFileDescriptionInputModal={handleCloseFileDescriptionInputModal}
      />
    </div>
  );
}
