import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AftermarketProductVendorApiChannel, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import NTP_LOGO from "../../../../../assets/img/aftermarket-vendor-logos/ntp-logo.png";
import GAIG_LOGO from "../../../../../assets/img/aftermarket-vendor-logos/gaig-logo.png";
import PLACEHOLDER_LOGO from "../../../../../assets/img/aftermarket-vendor-logos/placeholder-logo.png";

export const ProductLogo = ({ product }: { product: ProposalProduct }) => {
  const classes = useStyles();

  let logo = PLACEHOLDER_LOGO;

  if (product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Ntp) {
    logo = NTP_LOGO;
  } else if (product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Gaig) {
    logo = GAIG_LOGO;
  }

  return <img className={classes.productLogo} src={logo} alt="Product Logo" />;
};

const useStyles = makeStyles({
  productLogo: {
    width: "32px",
    height: "32px",
    marginRight: "8px",
  },
});
