import { portalConfigurationTypes } from "./pages/Prequal/constants";

export const SESSION_TIMEOUT_COOKIE_NAME = "userSession";

export const initialPortalConfigTypes = [
  portalConfigurationTypes.scorecard,
  portalConfigurationTypes.leads,
  portalConfigurationTypes.opportunities,
  portalConfigurationTypes.userManagement,
  portalConfigurationTypes.securityManagement,
  portalConfigurationTypes.healthCheck,
  portalConfigurationTypes.creditReports,
  portalConfigurationTypes.documents,
  portalConfigurationTypes.portalSetup,
  portalConfigurationTypes.prescreen,
  portalConfigurationTypes.voChecklistTemplate,
  portalConfigurationTypes.voManagement,
  portalConfigurationTypes.aftermarket,
];

export const CADMPortalConfigTypes = [portalConfigurationTypes.CADMCommercial, portalConfigurationTypes.CADMIndividual];
