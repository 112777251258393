import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FactToCheck } from "../types";
import { deskingSelectors } from "../model";
import { factKeyMapToEquipment } from "../constants";

export function useSimpleAutoFill(onChange: (value: string) => void, fact: FactToCheck) {
  const equipment = useSelector(deskingSelectors.currentEquipment);
  const isNeedToAutoFill = useSelector(deskingSelectors.isNeedAutoFillProposalProductsFields);

  useEffect(() => {
    const equipmentValue = equipment?.[factKeyMapToEquipment?.[fact?.factKey]]?.toString();

    if (equipment && equipmentValue) onChange(equipmentValue);
  }, [isNeedToAutoFill, equipment, fact]);
}
