import React from "react";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const CREATE_PROPOSAL_PRODUCT = gql`
  mutation($input: CreateProposalProductInput!) {
    createProposalProduct(input: $input) {
      proposalProductId
    }
  }
`;

const UPDATE_PROPOSAL_PRODUCT = gql`
  mutation($input: UpdateProposalProductInput!) {
    updateProposalProduct(input: $input) {
      proposalProductId
    }
  }
`;

const styles = {};
const useStyles = makeStyles(styles);

const CreateEditProposalProductModal = ({
  isOpen,
  setIsOpen,
  proposalProduct,
  setProposalProduct,
  refetchProducts,
}) => {
  const classes = useStyles();

  const isEditing = proposalProduct?.proposalProductId;

  const [createProposalProduct, { loading: createLoading }] = useMutation(CREATE_PROPOSAL_PRODUCT, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const [updateProposalProduct, { loading: updateLoading }] = useMutation(UPDATE_PROPOSAL_PRODUCT, {
    context: { authRequired: true },
    onCompleted() {
      onMutationCompleted();
    },
  });

  const onMutationCompleted = () => {
    handleClose();
    refetchProducts();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    isEditing
      ? updateProposalProduct({
          variables: {
            input: {
              proposalProductId: proposalProduct.proposalProductId,
              title: proposalProduct.title,
              cost: proposalProduct.cost,
              retailCost: proposalProduct.retailCost,
              thirdPartyUrl: proposalProduct.thirdPartyUrl,
            },
          },
        })
      : createProposalProduct({
          variables: {
            input: proposalProduct,
          },
        });
  };

  if (!proposalProduct) {
    return null;
  }
  const loading = createLoading || updateLoading;
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <DialogTitle id="form-dialog-title">{isEditing ? "Edit Product" : "Create Proposal Product"}</DialogTitle>
      {!loading && (
        <>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Title"
                  fullWidth
                  size="small"
                  value={proposalProduct.title}
                  onChange={e => setProposalProduct({ ...proposalProduct, title: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}>
                <CurrencyTextField
                  fullWidth
                  label="Cost"
                  value={proposalProduct.cost}
                  onChange={(event, value) => setProposalProduct({ ...proposalProduct, cost: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <CurrencyTextField
                  fullWidth
                  label="Retail Cost"
                  value={proposalProduct.retailCost}
                  onChange={(event, value) => setProposalProduct({ ...proposalProduct, retailCost: value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="3rd Party Url"
                  fullWidth
                  size="small"
                  value={proposalProduct.thirdPartyUrl}
                  onChange={e => setProposalProduct({ ...proposalProduct, thirdPartyUrl: e.target.value })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave} color="primary" disabled={!proposalProduct.title}>
              Save
            </Button>
          </DialogActions>
        </>
      )}
      {loading && <LinearProgress style={{ margin: 10 }} />}
    </Dialog>
  );
};

export default CreateEditProposalProductModal;
