import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { Auth } from "aws-amplify";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { Grid, InputAdornment, LinearProgress, Typography } from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import IconButton from "@material-ui/core/IconButton";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(styles);

const ACCEPT_USER_INVITATION_MUTATION = gql`
  mutation($email: String!, $invitationCode: String!, $password: String!) {
    acceptUserInvitation(input: { email: $email, invitationCode: $invitationCode, password: $password }) {
      success
      errorMessage
      codeExpired
    }
  }
`;

const RESET_INVITATION_CODE_MUTATION = gql`
  mutation ResetInvitationCode($input: ResetInvationCode!) {
    resetInvitationCode(input: $input) {
      success
      invitationCode
      email
      errorMessage
    }
  }
`;

function doLogin(username, password) {
  Auth.signIn(username, password)
    .then(user => {
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        // ideally, this will never happen here
        console.log("New password required");
        window.alert("Internal error");
        return;
      }

      window.location.href = "/";
    })
    .catch(err => {
      console.log(err);
      window.alert(err);
    });
}

export default function AcceptInvitationForm(props) {
  const classes = useStyles();

  const handleInputChange = e => {
    const { name, value } = e.target;

    if (name === "newPassword") {
      setIsNewPasswordValid(true);
    }

    setValues({ ...values, [name]: value });
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showResetButton, setResetButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isSuccessReset, setSuccessReset] = useState(null);
  const [values, setValues] = useState({
    newPassword: null,
    invitationCode: null,
    email: null,
    submitSuccess: null,
    submitError: null,
  });
  const [isUserAlreadySetPassword, setIsUserAlreadySetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const [requestAcceptInvitation, { data, error }] = useMutation(ACCEPT_USER_INVITATION_MUTATION, {
    context: { authRequired: false },
  });

  const [resetInvitationCode] = useMutation(RESET_INVITATION_CODE_MUTATION, {
    context: { authRequired: false },
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCodeReset = () => {
    resetInvitationCode({
      variables: {
        input: {
          email: values.email,
        },
      },
    }).then(response => {
      if (
        response.data &&
        response.data.resetInvitationCode &&
        response.data.resetInvitationCode.success &&
        response.data.resetInvitationCode.invitationCode
      ) {
        const invitationCode = response.data.resetInvitationCode.invitationCode;

        history.push(`${location.search.replace(/code=([^&]+)/, `code=${invitationCode}`)}`);

        if (!startResetCode) {
          setAlertMessage("Invitation code was successfully refreshed");
          setSuccessReset(true);
          setOpenSnackbar(true);
        }
      } else {
        setAlertMessage(response.data.resetInvitationCode.errorMessage || "Something went wrong");
        setSuccessReset(false);
        setOpenSnackbar(true);
      }
      setStartResetCode(false);
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    const pwd = values.newPassword;
    const isPwdValid = pwd.length >= 6;

    if (!isPwdValid) {
      setIsNewPasswordValid(isPwdValid);
      return;
    } else {
      setIsNewPasswordValid(isPwdValid);
    }

    requestAcceptInvitation({
      variables: {
        email: values["email"],
        invitationCode: values["invitationCode"],
        password: values["newPassword"],
      },
    }).then(gqlResponse => {
      if (gqlResponse && gqlResponse.data && gqlResponse.data.acceptUserInvitation) {
        setAlertMessage(gqlResponse.data.acceptUserInvitation.errorMessage);
        setOpenSnackbar(true);
        try {
          if (gqlResponse.data.acceptUserInvitation.success) {
            doLogin(values["email"], values["newPassword"]);
          } else {
            setResetButton(true);
            setSuccessReset(false);
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  useEffect(() => {
    try {
      let queryParams = queryString.parse(props.location.search);
      setValues(v => ({
        ...v,
        invitationCode: queryParams.code,
        email: queryParams.email,
      }));

      checkIfUserExists(queryParams.email);
    } catch (err) {
      console.error(err);
    }
  }, [props]);

  const [cardAnimation, setCardAnimation] = useState("cardHidden");
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [startResetCode, setStartResetCode] = useState(true);

  const checkIfUserExists = async email => {
    const runCheckIfUserExists = async () => {
      // a solution from https://github.com/aws-amplify/amplify-js/issues/1067#issuecomment-436492775
      const code = "000000";
      return Auth.confirmSignUp(email, code, {
        forceAliasCreation: false,
      })
        .then(data => console.log(data))
        .catch(err => {
          switch (err.code) {
            case "UserNotFoundException":
              return { result: false, errorCode: err.code };
            case "NotAuthorizedException":
              return { result: true, errorCode: err.code };
            case "AliasExistsException":
              // Email alias already exists
              return { result: true, errorCode: err.code };
            case "CodeMismatchException":
              return { result: true, errorCode: err.code };
            case "ExpiredCodeException":
              return { result: true, errorCode: err.code };
            default:
              return { result: true, errorCode: err.code };
          }
        });
    };

    setIsLoading(true);
    const result = await runCheckIfUserExists();
    setIsUserAlreadySetPassword(result.errorCode === "NotAuthorizedException");
    setIsLoading(false);
  };

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <Card login className={classes[cardAnimation]}>
            <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
              <h4 className={classes.cardTitle}>Welcome to Trnsact!</h4>
            </CardHeader>
            <CardBody>
              {(error || (data && !data.acceptUserInvitation.success)) && <h3>Invalid invitation code</h3>}
              {!isLoading && (
                <form onSubmit={handleSubmit}>
                  {!isUserAlreadySetPassword && (
                    <GridContainer>
                      <GridItem lg={12} sm={12}>
                        <CustomInput
                          labelText="New Password"
                          id="email"
                          formControlProps={{ fullWidth: true }}
                          error={!isNewPasswordValid}
                          helperText={!isNewPasswordValid && "Password must be a minimum of 6 characters."}
                          inputProps={{
                            name: "newPassword",
                            onChange: handleInputChange,
                            autoFocus: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                  classes={{ root: classes.root }}
                                >
                                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            type: showPassword ? "text" : "password",
                          }}
                        />
                      </GridItem>
                      <GridItem lg={12} style={{ width: "100%" }}>
                        <Button type="submit" fullWidth simple size="lg" block color="primary">
                          Save Password and Sign In
                        </Button>
                        {showResetButton && (
                          <Button
                            lg={12}
                            fullWidth
                            simple
                            size="lg"
                            block
                            color="primary"
                            onClick={() => {
                              handleCodeReset();
                              setResetButton(false);
                            }}
                          >
                            Refresh invitation code
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  )}
                </form>
              )}

              {isUserAlreadySetPassword && (
                <Grid container>
                  <Typography align="center" variant="h5" style={{ marginBottom: 15 }}>
                    Ooops... appears you already have a password
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: 10 }}>
                    Need to reset your password? <NavLink to={"/forgotpassword"}>CLICK HERE</NavLink>
                  </Typography>

                  <Typography variant="body1">
                    Login <NavLink to={"/login"}>here</NavLink>
                  </Typography>
                </Grid>
              )}

              {isLoading && <LinearProgress style={{ width: "100%" }} />}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar
        style={{ marginTop: "50px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={isSuccessReset ? "success" : "error"}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
