import React, { useState } from "react";
import { Checkbox, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import { theme } from "../../theme";

const styles = {
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      padding: 0,
      fontSize: "0.85em",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
  },
};

const useStyles = makeStyles(styles);

export default ({
  id,
  label,
  options,
  selectRef,
  setSavedListSettings,
  savedListSettings,
  setPageNumber,
  width = "100%",
  multiSelect = false,
}) => {
  const [selectFilter, setSelectFilter] = useState(selectRef?.current?.currentSelect || "");
  const classes = useStyles();

  const throttleSelectRef = _.throttle(option => {
    selectRef.current = { ...selectRef.current, currentSelect: option };
  }, 500);

  const handleSelectChange = (newOption, curOption) => {
    let newValue = multiSelect
      ? !curOption.includes(newOption)
        ? curOption + "," + newOption
        : curOption.replace(`,${newOption}`, "")
      : newOption;
    if (newOption === "Show All") newValue = "";

    setSelectFilter(newValue);
    throttleSelectRef(newValue);
    setSavedListSettings({ ...savedListSettings, page: 0 });
    setPageNumber(0);
  };

  return (
    <FormControl variant="outlined" fullWidth className="select-filter" style={{ width }}>
      <InputLabel id={`${id}-select-label`} className={classes.selectLabel}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-select-label`}
        id={`${id}-select`}
        value={selectFilter}
        onChange={e => handleSelectChange(e.target.value, selectFilter)}
        label={label}
        className={classes.selectStyles}
      >
        <MenuItem value="Show All" className={classes.selectOption}>
          Show All
        </MenuItem>
        {options.map((item, index) => (
          <MenuItem className={classes.selectOption} key={id + "-" + index} value={item.value}>
            {multiSelect && (
              <Checkbox checked={selectFilter.includes(item.value)} style={{ color: theme.palette.primary.main }} />
            )}
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
