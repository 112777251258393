import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useViewTypeContext } from "../../contexts/contentViewType";

const TableHeader = props => {
  const classes = useStyles();

  const { isDesktop } = useViewTypeContext();

  return (
    <div className={classes.tableHeader}>
      {props.filterComps && (
        <div
          className={clsx(classes.tableHeader, {
            [classes.tableFiltersDesktop]: isDesktop,
            [classes.tableFiltersMobile]: !isDesktop,
          })}
        >
          {props.filterComps}
        </div>
      )}

      {props.children}
    </div>
  );
};

const useStyles = makeStyles({
  tableHeader: {
    gap: "16px",
    width: "100%",
    display: "flex",
    marginBottom: 24,
    alignItems: "center",
    justifyContent: "space-between",

    "& button": {
      height: "fit-content",
    },
  },
  tableFilters: {
    gap: "16px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  tableFiltersDesktop: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableFiltersMobile: {
    flexDirection: "column",
  },
});

export default TableHeader;
