import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { gql } from "apollo-boost";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import _ from "lodash";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { Box, CircularProgress, IconButton, TableHead, TextField } from "@material-ui/core";
import Button from "../../../components/CustomButtons/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";

import { Tab, Tabs } from "@material-ui/core";
import TabPanel from "../../../components/TabPanel";

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";

// images
import twoWayArrow from "../../../assets/img/icons/two-way-arrow.png";
import TextFieldComp from "./FieldComps/TextField";
import DateFieldComp from "./FieldComps/DateField";
import SelectFieldComp from "./FieldComps/SelectField";
import LenderDocumentAccessTable from "../LenderDocumentAccess/LenderDocumentAccessTable";
import { theme } from "../../../theme";

import { getCountriesArray, getDaysArray, getMonthsArray, getStatesArray, getYearsArray } from "../../../utils";
import { lenderTypeMap } from "./constants";
import moment from "moment";

import { Engine } from "json-rules-engine";
import { legalStructureOption } from "../../../utils";
import { Clear } from "@material-ui/icons";

import iconPlusSvg from "../../../assets/img/icons/plus.svg";
import iconMinusSvg from "../../../assets/img/icons/minus.svg";

const useStyles = makeStyles(theme => ({
  ...styles,
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 1200,
    "@media (max-width: 1200px)": {
      width: "120vw",
      minWidth: "120vw",
    },
  },
  dateSelect: {
    marginTop: "11px",
    width: "100%",

    "& label": {
      color: "#000000",
      fontSize: 14,
      fontWeight: 400,
    },
  },
  inputLabel: {
    transform: "translate(14px, 12px) scale(1)",
  },
  labelNotMapped: {
    width: "100%",
    float: "right",
    textAlign: "right",
  },
  textField: {
    margin: "8px 0 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  wrapperLoader: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.grey[500],
    width: "100%",
    alignItems: "center",
    margin: "64px 0",

    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      marginBottom: 32,

      "& h4": {
        marginLeft: 8,
      },
    },
    "& .progressLoader": {
      margin: 32,
      color: theme.palette.grey[500],
    },
  },
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      padding: 0,
      fontSize: "0.85em",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  collapseButton: {
    top: 0,
    right: 0,
    width: 50,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    zIndex: 3,
    cursor: "pointer",
    color: "#999",
  },
  icon: {
    width: 20,
    height: 20,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    opacity: 0.3,
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
}));

const GET_DEPENDENT_OPTION_SETS = gql`
  query DependentOptionSets($lenderFieldId: ID!, $lenderFieldParentSelectedOptionSetId: ID!) {
    getDependentOptionSets(
      lenderFieldId: $lenderFieldId
      lenderFieldParentSelectedOptionSetId: $lenderFieldParentSelectedOptionSetId
    ) {
      displayName
      lenderFieldId
      lenderFieldOptionSetId
      lenderFieldOptionSetParentId
      lenderValue
    }
  }
`;

const GET_DYNAMIC_LENDER_MAPPING = gql`
  query MappedLenderValuesForVendorOpportunity(
    $lenderType: LenderType!
    $vendorOpportunityId: ID!
    $lenderProfileDynamicsId: ID!
  ) {
    getMappedLenderValuesForVendorOpportunity(
      lenderType: $lenderType
      vendorOpportunityId: $vendorOpportunityId
      lenderProfileDynamicsId: $lenderProfileDynamicsId
    ) {
      lenderFieldId
      lenderProperty
      mappedValue
      originalMappedValue
      details {
        displayName # String
        section # Used to order inputs
        category # Used to order inputs within sections
        dcrPropertySource # String
        fieldInputType # String
        required # RequiredStatusType - REQUIRED, OPTIONAL, CONDITIONAL
        notesInternal # String
        notesExternal # String
        hidden # Boolean
        readOnly # Boolean
        rulesConfiguration # JSON
        ordinal
        lenderFieldParentId
        lenderFieldParentLenderProperty
        validRegexPattern
        inputErrorHelperText
        optionSets {
          displayName
          lenderFieldId
          lenderFieldOptionSetId
          lenderFieldOptionSetParentId
          lenderValue
        }
      }
    }
  }
`;

export default function DynamicLenderSubmissionMappingModal({
  vo,
  vp,
  lender,
  errors,
  setDynamicLenderMappingCallback, // function (lender.lenderProfileId, dynamicLenderMapping)
  handleClose,
  submitCreditApp,
  submissionMessage,
  portalConfiguration,
  transactionSummary,
}) {
  const apolloClient = useApolloClient();

  console.log("Change Bump -6/13/24");

  const [dlmRulesEngine, setDlmRulesEngine] = useState(new Engine());

  const classes = useStyles();

  const tableCellClasses = classnames(classes.tableCell);
  const [dynamicLenderMapping, setDynamicLenderMapping] = useState(null);
  const [structuredData, setStructuredData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [runningRules, setRunningRules] = useState(true);
  const [submittingApp, setSubmittingApp] = useState(false);
  const [openDlmErrorSnackbar, setOpenDlmErrorSnackbar] = useState(false);
  const [dlmErrorSnackbarMessage, setDlmErrorSnackbarMessage] = useState("");
  const [sharedDocuments, setSharedDocuments] = useState(null);

  const [dlmInputSetValueFunctions, setDlmInputSetValueFunctions] = useState({});
  const [dlmInputSetRequiredFunctions, setDlmInputSetRequiredFunctions] = useState({});
  const [dlmInputSetErrorFunctions, setDlmInputSetErrorFunctions] = useState({});
  const [dlmInputSetOptionSetFunctions, setDlmInputSetOptionSetFunctions] = useState({});

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const collapseInitialState = {};
  const [collapseState, setCollapseState] = useState(collapseInitialState);
  const [currentTabSelected, setCurrentTabSelected] = useState("dlm");

  const toggleCollapse = sectionId => setCollapseState({ ...collapseState, [sectionId]: !collapseState[sectionId] });

  const {
    data: dynamicLenderMappingData,
    loading: dynamicLenderMappingDataLoading,
    called: getDynamicLenderMappingCalled,
  } = useQuery(GET_DYNAMIC_LENDER_MAPPING, {
    variables: {
      lenderType: lender.submissionMethod || lenderTypeMap.get(lender.lenderChannelCode),
      vendorOpportunityId: vo.vendorOpportunityId,
      lenderProfileDynamicsId: lender.lenderProfileId,
    },
    fetchPolicy: "no-cache",
  });

  const setReferenceToChildSetValueFunction = (lenderProperty, setValueFunction) => {
    dlmInputSetValueFunctions[lenderProperty] = setValueFunction;
    setDlmInputSetValueFunctions(dlmInputSetValueFunctions);
  };

  const setReferenceToChildSetRequiredFunction = (lenderProperty, setDisplayFunction) => {
    dlmInputSetRequiredFunctions[lenderProperty] = setDisplayFunction;
    setDlmInputSetRequiredFunctions(dlmInputSetRequiredFunctions);
  };

  const setReferenceToChildSetErrorsFunction = (lenderProperty, setErrorFunction) => {
    dlmInputSetErrorFunctions[lenderProperty] = setErrorFunction;
    setDlmInputSetErrorFunctions(dlmInputSetErrorFunctions);
  };

  const setReferenceToSetOptionSetsFunction = (lenderProperty, setOptionSetFunction) => {
    dlmInputSetOptionSetFunctions[lenderProperty] = setOptionSetFunction;
    setDlmInputSetOptionSetFunctions(dlmInputSetOptionSetFunctions);
  };

  const getInputProps = lenderField => {
    if (lenderField?.details?.rulesConfiguration?.configuration) {
      const inputProps = lenderField?.details?.rulesConfiguration?.configuration;
      return inputProps;
    }
    return {};
  };

  const handleDlmRulesEvents = events => {
    // console.log("Required Functions ::", dlmInputSetRequiredFunctions);
    events.forEach(event => {
      // console.log(`EVENT TRIGGERED: ${JSON.stringify(event)}`);
      if (event.params.actions) {
        event.params.actions.forEach(async action => {
          // console.log(`setInputValue: ${action.inputKey} action: ${JSON.stringify(action)}`);
          if (action.type === "setInputValue") {
            if (_.has(action, "value") && action.inputKey && dlmInputSetValueFunctions[action.inputKey]) {
              dlmInputSetValueFunctions[action.inputKey](action.value);
            } else if (action.factKey && action.inputKey && dlmInputSetValueFunctions[action.inputKey]) {
              const fact = dlmRulesEngine.getFact(action.factKey);
              dlmInputSetValueFunctions[action.inputKey](fact.value);
            } else if (
              action.operations &&
              _.isArray(action.operations) &&
              dlmInputSetValueFunctions[action.inputKey]
            ) {
              try {
                let finalValue;
                action.operations.forEach(o => {
                  const operation = o.operation;
                  const outputType = o.outputType;
                  if (operation && o.operands && _.isArray(o.operands)) {
                    o.operands.forEach(operand => {
                      const operandTyped = operand;
                      let operandValue;
                      if (_.isObject(operandTyped) && operandTyped.factKey) {
                        const fact = dlmRulesEngine.getFact(operand.factKey);
                        if (_.isString(fact.value)) {
                          operandValue = outputType === "INTEGER" ? parseInt(fact.value) : parseFloat(fact.value);
                        } else {
                          operandValue = fact.value;
                        }
                      } else {
                        operandValue = operand;
                      }

                      if (!finalValue && _.isNumber(operandValue)) {
                        finalValue = operandValue;
                      } else if (operandValue) {
                        if (operation === "DIFFERENCE") {
                          finalValue = finalValue - operandValue;
                        } else if (operation === "SUM") {
                          finalValue = finalValue + operandValue;
                        }
                      }
                    });
                  } else {
                    console.warn(
                      `setInputValue: triggered with operations but no value could be found: ${JSON.stringify(action)}`
                    );
                  }
                });
                dlmInputSetValueFunctions[action.inputKey](finalValue);
              } catch (e) {
                console.error(
                  `setInputValue triggered with operations but an error occurred: ${JSON.stringify(action)}: ${
                    e.message
                  }`
                );
              }
            } else {
              console.warn(
                `setInputValue: ${action.inputKey}: triggered but no value was found: ${JSON.stringify(action)}`
              );
            }
          } else if (action.type === "setInputRequired") {
            if (dlmInputSetRequiredFunctions[action.inputKey]) {
              try {
                dlmInputSetRequiredFunctions[action.inputKey](action.value);
                const dlmInputIndex = dynamicLenderMapping.findIndex(el => el.lenderProperty === action.inputKey);
                dynamicLenderMapping[dlmInputIndex].details.required = action.value ? "REQUIRED" : "OPTIONAL";
              } catch (e) {
                console.log(`Error setting dlmInputSetRequiredFunctions[${action.inputKey}]: ${e.message}`);
              }
            }
          } else if (action.type === "setInputHidden") {
            try {
              let dlmInputIndex = dynamicLenderMapping.findIndex(el => el.lenderProperty === action.inputKey);
              if (dlmInputIndex !== -1) {
                dynamicLenderMapping[dlmInputIndex].details.hidden = action.value;
                dlmRulesEngine.addFact(`${action.inputKey}_hidden`, action.value);
              } else {
                let inputIteratorToHide = 0;
                do {
                  dlmInputIndex = dynamicLenderMapping.findIndex(
                    el => el.lenderProperty === `${action.inputKey}[${inputIteratorToHide}]`
                  );
                  dynamicLenderMapping[dlmInputIndex].details.hidden = action.value;
                  dlmRulesEngine.addFact(`${action.inputKey}_hidden`, action.value);
                  inputIteratorToHide++;
                } while (dlmInputIndex !== -1);
              }
            } catch (e) {
              console.log(`Error setting dlmInputSetHiddenFunctions[${action.inputKey}]: ${e.message}`);
            }
          } else if (action.type === "setInputError") {
            try {
              const dlmInputIndex = dynamicLenderMapping.findIndex(el => el.lenderProperty === action.inputKey);
              let currentErrors = _.get(dynamicLenderMapping[dlmInputIndex], "details.errors", []);
              /*
              errors MUST have the following shape:
              {
                name: string, // the KEY of the error
                message: string, // the human readable error message
              }
              */
              if (!currentErrors.find(error => error.name === action.error.name)) {
                currentErrors.push(action.error);
                dynamicLenderMapping[dlmInputIndex].details.errors = currentErrors;
              }
            } catch (e) {
              console.log(`Error setting dlmInputSetErrorFunctions[${action.inputKey}]: ${e.message}`);
            }
          } else if (action.type === "removeInputError") {
            /*
            {
              "type": "removeInputError",
              "inputKey": "FACT_NAME",
              "errorsToClear": [
                "ERROR_NAME_FROM_setInputError",
                ...
              ]
            }
            */
            try {
              const dlmInputIndex = dynamicLenderMapping.findIndex(el => el.lenderProperty === action.inputKey);
              let currentErrors = _.get(dynamicLenderMapping[dlmInputIndex], "details.errors", []);
              dynamicLenderMapping[dlmInputIndex].details.errors = currentErrors.filter(
                error => !action.errorsToClear.includes(error.name)
              );
              dlmInputSetErrorFunctions[action.inputKey](dynamicLenderMapping[dlmInputIndex].details.errors);
            } catch (e) {
              console.log(`Error setting dlmInputSetErrorFunctions[${action.inputKey}]: ${e.message}`);
            }
          } else if (action.type === "removeRule") {
            console.log(`Removing rule: ${action.ruleName}`);
            dlmRulesEngine.removeRule(action.ruleName);
          } else if (action.type === "setOptionSetInputValue") {
            try {
              const optionSetsFact = dlmRulesEngine.getFact(`${action.inputKey}-optionSets`);
              const likeDisplay = action.likeDisplay;
              const optionSet = optionSetsFact.value.find(os => {
                return os.displayName.toLowerCase().includes(likeDisplay.toLowerCase());
              });
              console.log(`Option Set Found via likeDisplay ${likeDisplay}: ${JSON.stringify(optionSet)}`);
              if (dlmInputSetValueFunctions[action.inputKey])
                dlmInputSetValueFunctions[action.inputKey](optionSet.lenderValue);
            } catch (e) {
              console.log(`Error setting default option set for ${action.inputKey}: ${e.message}`);
            }
          } else if (action.type === "getCascadingOptionSets") {
            // console.log(`getCascadingOptionSets ACTION: ${JSON.stringify(action)}`);
            /*
            {
              "type": "getCascadingOptionSets",
              "inputKey": "sheffield.Model Type",
              "lenderFieldId": "0b3e7afe-d7b2-11ed-8643-d7d42f0f0ca1",
              "parentInputKey": "sheffield.Manufacturer",
              "parentLenderFieldId": "0b314424-d7b2-11ed-8643-439fa3fe286c"
            }
            */
            console.log(
              `getCascadingOptionSets   FACT: ${JSON.stringify(dlmRulesEngine.getFact(action.parentInputKey))}`
            );
            console.log(
              `getCascadingOptioNSets PARENT OPTION SETS: ${JSON.stringify(
                dlmRulesEngine.getFact(`${action.parentInputKey}-optionSets`)
              )}`
            );
            const parentSelectedValue = dlmRulesEngine.getFact(action.parentInputKey);
            console.log(`getCascadingOptionSets: parentSelectedValue: ${JSON.stringify(parentSelectedValue)}`);

            const parentOptionSets = dlmRulesEngine.getFact(`${action.parentInputKey}-optionSets`);

            if (parentOptionSets) {
              const selectedParentOptionSet = parentOptionSets.value.find(os => {
                return os.lenderValue === parentSelectedValue.value;
              });
              console.log(
                `getCascadingOptionSets: selectedParentOptionSet: ${JSON.stringify(selectedParentOptionSet)}`
              );
              /* selected parent option set
              {
                "displayName": "Air-Flo",
                "lenderFieldId": "0b314424-d7b2-11ed-8643-439fa3fe286c",
                "lenderFieldOptionSetId": "0f4287da-d7b2-11ed-8643-ffb4255137b0",
                "lenderFieldOptionSetParentId": "",
                "lenderValue": "Air-Flo",
                "__typename": "LenderFieldOptionSet"
              }
              */
              if (selectedParentOptionSet && selectedParentOptionSet.lenderFieldOptionSetId) {
                console.log(
                  `getCascadingOptionSets: variables for getDependentOptionSets: ${JSON.stringify({
                    variables: {
                      lenderFieldId: action.lenderFieldId,
                      lenderFieldParentSelectedOptionSetId: selectedParentOptionSet.lenderFieldOptionSetId,
                    },
                  })}`
                );

                apolloClient
                  .query({
                    query: GET_DEPENDENT_OPTION_SETS,
                    variables: {
                      lenderFieldId: action.lenderFieldId,
                      lenderFieldParentSelectedOptionSetId: selectedParentOptionSet.lenderFieldOptionSetId,
                    },
                    fetchPolicy: "no-cache",
                  })
                  .then(dependentOptionSets => {
                    if (dynamicLenderMapping) {
                      const tempDynamicLenderMapping = [...dynamicLenderMapping];
                      const dependentOptionSetIndex = tempDynamicLenderMapping.findIndex(
                        tdlm => tdlm.lenderFieldId === action.lenderFieldId
                      );
                      if (dependentOptionSetIndex !== -1) {
                        tempDynamicLenderMapping[dependentOptionSetIndex].details.optionSets =
                          dependentOptionSets.data.getDependentOptionSets;
                        setDynamicLenderMapping(tempDynamicLenderMapping);
                      }
                    }
                    // console.log(`getCascadingOptionSets: dependentOptionSets: ${JSON.stringify(dependentOptionSets)}`);
                    if (dlmInputSetOptionSetFunctions) {
                      dlmInputSetOptionSetFunctions[action.inputKey](dependentOptionSets.data.getDependentOptionSets);
                      dlmRulesEngine.addFact(
                        `${action.inputKey}-optionSets`,
                        dependentOptionSets.data.getDependentOptionSets
                      );
                    }
                  });
              }
            }
            /*
            {
              "displayName": "4 Star Trailers",
              "lenderFieldId": "0b314424-d7b2-11ed-8643-439fa3fe286c",
              "lenderFieldOptionSetId": "0ef24fb8-d7b2-11ed-8643-af746f2a22d5",
              "lenderFieldOptionSetParentId": "",
              "lenderValue": "4 Star Trailers",
              "__typename": "LenderFieldOptionSet"
            }*/
          }
        });
      }
    });
    setCollapseStateFromDlmErrors();
  };

  const getTrnsactDisplayValue = field => {
    if (field.details.dcrPropertySource.includes("legalStructure")) {
      const selectedLso = legalStructureOption.find(lso => {
        return lso.value === field.originalMappedValue;
      });
      if (selectedLso) {
        return selectedLso.label;
      }
    }
    if (field.originalMappedValue === true) {
      return "Yes";
    }
    if (field.originalMappedValue === false) {
      return "No";
    }
    return field.originalMappedValue ? field.originalMappedValue : " ";
  };

  const getTrnsactDisplayName = field => {
    let displayName = undefined;
    try {
      const keyToFind = field.details.dcrPropertySource
        .replace("creditApp.", "")
        .replace("[", ".")
        .replace("]", "");
      portalConfiguration.jsonDefinition.sections.forEach(section => {
        section.blocks.forEach(block => {
          const matchingField = block.fields.find(field => {
            return field.key === keyToFind || field.ambiguousKey === keyToFind;
          });
          if (matchingField) {
            // console.log(
            //   `Found trnsact display name for ${keyToFind}: ${matchingField.ocaConfig.config.displayLabel.en}`
            // );
            displayName = matchingField.ocaConfig.config.displayLabel.en;
          }
        });
      });
      if (!displayName) {
        // console.log(`Did not find trnsact display name for DLM input with key: ${keyToFind}`);
        displayName = field.details.displayName;
      }
      // console.log(`Returning displayName of: ${displayName}`);
      return displayName;
    } catch (e) {
      console.log(
        `Error finding display name through portal config for key ${field.details.dcrPropertySource}, returning default displayName: ${e.message}`
      );
    }
    return field.details.displayName;
  };

  const setCollapseStateFromDlmErrors = () => {
    if (!dynamicLenderMapping) {
      return;
    }
    const localCollapseState = { ...collapseState };
    dynamicLenderMapping.forEach(field => {
      const categoryCollapseId = `category-${field.details.category}`;
      const sectionCollapseId = `section-${field.details.section}`;

      let optionSetValueIsOkay = true;
      try {
        if (field.details.fieldInputType === "OPTION_SET") {
          const validOptionSet = field.details.optionSets.find(os => os.lenderValue === field.mappedValue);
          if (!validOptionSet) {
            optionSetValueIsOkay = false;
          }
        }
      } catch (e) {
        console.log(`Error checking if we should collapse a section with option sets: ${e.message}`);
        optionSetValueIsOkay = false;
      }

      if (
        (!field.mappedValue &&
          field.mappedValue !== 0 &&
          field.details.required === "REQUIRED" &&
          !field.details.hidden) ||
        !optionSetValueIsOkay
      ) {
        // console.log(`_ _ _ setCollapseStateFromDlmErrors: setting ${categoryCollapseId} | ${sectionCollapseId} to FALSE: ${field.lenderProperty} = ${field.mappedValued} | REQUIRED: ${field.details.required}`);
        localCollapseState[categoryCollapseId] = false;
        localCollapseState[sectionCollapseId] = false;
      } else {
        if (localCollapseState[categoryCollapseId] !== false) {
          localCollapseState[categoryCollapseId] = true;
        }
        if (localCollapseState[sectionCollapseId] !== false) {
          // console.log(`_ _ _ setCollapseStateFromDlmErrors: setting ${categoryCollapseId} | ${sectionCollapseId} to TRUE: ${field.lenderProperty} = ${field.mappedValued} | REQUIRED: ${field.details.required}`);
          localCollapseState[sectionCollapseId] = true;
        }
      }
    });
    setCollapseState(localCollapseState);
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTabSelected(newValue);
  };

  useEffect(() => {
    if (!dynamicLenderMappingDataLoading && getDynamicLenderMappingCalled) {
      setTimeout(() => {
        try {
          const transactionSummaryIndex = dynamicLenderMappingData.getMappedLenderValuesForVendorOpportunity.findIndex(
            dv => {
              return dv.details?.dcrPropertySource === "vendorOpportunity.transactionSummary";
            }
          );
          if (transactionSummaryIndex !== -1) {
            dynamicLenderMappingData.getMappedLenderValuesForVendorOpportunity[
              transactionSummaryIndex
            ].mappedValue = transactionSummary;
          }
        } catch (e) {
          console.log(`Error finding transactionSummary`);
        }

        let structuredMapping = structureFields(
          dynamicLenderMappingData.getMappedLenderValuesForVendorOpportunity,
          "category"
        );
        Object.entries(structuredMapping).forEach(
          entry => (structuredMapping[entry[0]] = structureFields(entry[1], "section"))
        );
        structuredMapping = sortByOrdinal(structuredMapping);
        setStructuredData(structuredMapping);

        setDynamicLenderMapping(dynamicLenderMappingData.getMappedLenderValuesForVendorOpportunity);
        setDynamicLenderMappingCallback(
          lender.lenderProfileId,
          dynamicLenderMappingData.getMappedLenderValuesForVendorOpportunity
        );
        setLoading(false);

        dlmRulesEngine.addOperator("doesNotContainRegex", (factValue, regexValue) => {
          try {
            if (typeof factValue !== "string") {
              return true;
            }
            if (typeof regexValue !== "string") {
              return true;
            }
            const regex = new RegExp(regexValue);
            return !regex.test(factValue);
          } catch (e) {
            return true;
          }
        });

        dlmRulesEngine.addOperator("containsRegex", (factValue, regexValue) => {
          try {
            if (typeof factValue !== "string" || typeof regexValue !== "string") {
              return false;
            }
            const regex = new RegExp(regexValue);
            return regex.test(factValue);
          } catch (e) {
            return false;
          }
        });

        try {
          const localCollapseState = {};

          dynamicLenderMappingData.getMappedLenderValuesForVendorOpportunity.forEach(field => {
            // console.log(`Adding fact: ${field.lenderProperty}: ${field.mappedValue}`);
            dlmRulesEngine.addFact(field.lenderProperty, field.mappedValue);
            dlmRulesEngine.addFact(`${field.lenderProperty}_originalMappedValue`, field.originalMappedValue);
            dlmRulesEngine.addFact(`${field.lenderProperty}_hidden`, field.details?.hidden ?? false);

            const categoryCollapseId = `category-${field.details.category}`;
            const sectionCollapseId = `section-${field.details.section}`;
            localCollapseState[categoryCollapseId] = true;
            localCollapseState[sectionCollapseId] = true;

            if (
              field.details.fieldInputType === "OPTION_SET" &&
              field.details.optionSets === null &&
              field.details.lenderFieldParentLenderProperty
            ) {
              dlmRulesEngine.addRule({
                conditions: {
                  any: [
                    {
                      fact: field.details.lenderFieldParentLenderProperty,
                      operator: "notEqual",
                      value: undefined,
                    },
                  ],
                },
                event: {
                  type: `${field.lenderProperty}CascadingOptionSets`,
                  params: {
                    actions: [
                      {
                        type: "getCascadingOptionSets",
                        inputKey: field.lenderProperty,
                        lenderFieldId: field.lenderFieldId,
                        parentInputKey: field.details.lenderFieldParentLenderProperty,
                        parentLenderFieldId: field.details.lenderFieldParentId,
                      },
                    ],
                  },
                },
              });
            }

            try {
              if (field.details.optionSets) {
                dlmRulesEngine.addFact(`${field.lenderProperty}-optionSets`, field.details.optionSets);
              }
            } catch (e) {
              console.log(`Error setting option set fact: ${e.message}`);
            }

            const rules = field.details?.rulesConfiguration?.rules;
            if (rules) {
              rules.forEach(rule => {
                // console.log(`Adding rule: ${JSON.stringify(rule)}`);
                const conditionsAll = rule.conditions.all ? rule.conditions.all : [];
                const conditionsAny = rule.conditions.any ? rule.conditions.any : [];
                const conditionsNot = rule.conditions.not ? rule.conditions.not : [];
                const conditions = conditionsAll.concat(conditionsAny).concat(conditionsNot);
                conditions.forEach(c => {
                  if (c.fact && !dlmRulesEngine.getFact(c.fact)) {
                    dlmRulesEngine.addFact(c.fact, null);
                  }
                  if (c?.value?.fact && !dlmRulesEngine.getFact(c.value.fact)) {
                    dlmRulesEngine.addFact(c.value.fact, null);
                  }
                });
                dlmRulesEngine.addRule(rule);
              });
            }
          });

          setCollapseState(localCollapseState);
        } catch (e) {
          console.log(`Error adding DLM Rule: ${e.message}`);
        }

        // console.log(`ENGINE: ${JSON.stringify(dlmRulesEngine)}`);

        setDlmRulesEngine(dlmRulesEngine);

        dlmRulesEngine.run().then(({ events }) => {
          handleDlmRulesEvents(events);
        });
      }, 3000);
    }
  }, [dynamicLenderMappingDataLoading, getDynamicLenderMappingCalled]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        dlmRulesEngine.run().then(({ events }) => {
          handleDlmRulesEvents(events);
          setRunningRules(false);
        });
      }, 100);
    }
  }, [loading]);

  useEffect(() => {
    if (dynamicLenderMapping) {
      setSubmitDisabled(!checkAllRequiredFieldsHaveValues());
    }
  }, [dynamicLenderMapping]);

  const sortAlphabetically = mapping => {
    let sortedMapping = {};

    Object.entries(mapping)
      .sort((a, b) => a[0].toLocaleLowerCase().localeCompare(b[0].toLocaleLowerCase()))
      .forEach(entry => (sortedMapping[entry[0]] = entry[1]));

    return sortedMapping;
  };

  const sortByOrdinal = mapping => {
    let sortedMapping = {};

    Object.entries(mapping)
      .sort((a, b) => {
        let A = null;
        let B = null;

        try {
          A = a[1].DEFAULT[0].details?.ordinal;
        } catch (e) {
          A = null;
        }

        try {
          B = b[1].DEFAULT[0].details?.ordinal;
        } catch (e) {
          B = null;
        }

        if (A === null) return 1;
        if (B === null) return -1;

        return A < B ? -1 : 1;
      })
      .forEach(entry => (sortedMapping[entry[0]] = entry[1]));

    return sortedMapping;
  };

  const handleDynamicLenderMappingValueChange = (newValue, lenderMappingId) => {
    const index = dynamicLenderMapping.findIndex(el => el.lenderProperty === lenderMappingId);
    dynamicLenderMapping[index].mappedValue = newValue;

    dlmRulesEngine.addFact(lenderMappingId, newValue);

    // console.log("Calling rules engine run on input value change");
    // console.log(`ENGINE ON CHANGE: ${JSON.stringify(dlmRulesEngine)}`);
    dlmRulesEngine.run().then(({ events }) => {
      // console.log(`Ran rules engine on values change: events: ${JSON.stringify(events)}`);
      handleDlmRulesEvents(events);
      setDynamicLenderMapping([...dynamicLenderMapping]);
      setDynamicLenderMappingCallback(lender.lenderProfileId, [...dynamicLenderMapping]);
    });
  };

  const filterDynamicMapping = arr => {
    return [...arr].filter(i => checkIfError(i));
  };

  const checkIfError = item => {
    let error = true;
    errors.forEach(e => {
      if (item.details?.dcrPropertySource?.includes(e)) error = false;
    });
    return !error;
  };

  const structureFields = (fields, identifier) => {
    let obj = {};
    fields.forEach(field => {
      // const hidden = field.details?.hidden;

      // if (hidden) {
      //   return;
      // }

      const key = field.details?.[identifier];

      if (!key) {
        obj["DEFAULT"] = obj["DEFAULT"] ? [...obj["DEFAULT"], field] : [field];
      } else {
        obj[key] = obj[key] ? [...obj[key], field] : [field];
      }
    });

    return obj;
  };

  const checkAllRequiredFieldsHaveValues = () => {
    let allRequiredFieldsHaveValue = true;
    // console.log("DynamicLenderMapping", dynamicLenderMapping);
    dynamicLenderMapping.forEach(field => {
      if (
        (field.details.fieldInputType === "OPTION_SET"
          ? !field.details?.optionSets?.find(os => os.lenderValue === field.mappedValue)
          : !field.mappedValue) &&
        field.mappedValue !== 0 &&
        field.details.required === "REQUIRED" &&
        !field.details.hidden
      ) {
        allRequiredFieldsHaveValue = false;
      }
    });
    return allRequiredFieldsHaveValue;
  };

  const getFieldsWithErrors = () => {
    const fieldsWithErrors = [];
    dynamicLenderMapping.forEach(field => {
      try {
        if (field.details.errors && field.details.errors.length > 0 && field.details.hidden === false) {
          fieldsWithErrors.push(field);
        }
      } catch (e) {
        console.log(`Error checking if field has errors: ${JSON.stringify(field)}: ${e.message}`);
      }
    });
    return fieldsWithErrors;
  };

  const removeMasks = () => {
    dynamicLenderMapping.forEach((field, i) => {
      try {
        if (dynamicLenderMapping[i].mappedValue) {
          if (field.details?.fieldInputType === "PHONE_NUMBER") {
            dynamicLenderMapping[i].mappedValue = dynamicLenderMapping[i].mappedValue.replace(/[()\-\s]/g, "");
          } else if (field.details?.fieldInputType === "SSN" || field.details?.fieldInputType === "FEIN") {
            dynamicLenderMapping[i].mappedValue = dynamicLenderMapping[i].mappedValue.replace(/-/g, "");
          }
        }
      } catch (e) {
        console.log(`Error trying to remove mask: ${e.message}`);
      }
    });
  };

  const handleDlmFormSubmit = () => {
    const fieldsWithErrors = getFieldsWithErrors();
    const allRequiredFieldsHaveValue = checkAllRequiredFieldsHaveValues();
    if (allRequiredFieldsHaveValue && fieldsWithErrors.length === 0) {
      console.log("ALL REQUIRED FIELDS ARE GOOD");
      removeMasks();
      submitCreditApp(sharedDocuments);
      setSubmittingApp(true);
      handleClose();
      setOpenDlmErrorSnackbar(false);
      setDlmErrorSnackbarMessage("");
    } else {
      console.log("NOT ALL REQUIRED FIELDS ARE SET AND OR SOME FIELDS HAVE ERRORS");
      setDlmErrorSnackbarMessage(
        `Please: ${allRequiredFieldsHaveValue ? "" : "Fill out all required fields."} ${
          fieldsWithErrors.length > 0
            ? `Fix the following fields: ${fieldsWithErrors
                .map(f => `${f.details.category ?? ""} ${f.details.displayName}`)
                .join(", ")}`
            : ""
        }`
      );
      setOpenDlmErrorSnackbar(true);
    }
  };

  const isDate = function(date) {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
  };

  const createNewField = (type, field) => {
    switch (type) {
      case "YEAR":
        let yearsArray = getYearsArray().map(yr => ({ displayName: yr.toString(), lenderValue: yr.toString() }));
        field.details.optionSets = yearsArray;
        if (field.mappedValue) {
          if (typeof field.mappedValue === "string" && isDate(field.mappedValue)) {
            try {
              field.mappedValue = String(moment(field.mappedValue).year());
            } catch (error) {
              console.log("Error with mappedValue with field type Year");
            }
          }
        }
        return field;
      case "MONTH":
        let monthsArray = getMonthsArray().map(month => ({ displayName: month.long, lenderValue: month.long }));
        field.details.optionSets = monthsArray;
        if (field.mappedValue && typeof field.mappedValue === "string") {
          try {
            if (!isNaN(Date.parse(field.mappedValue))) field.mappedValue = moment(field.mappedValue).format("MMMM");
          } catch (error) {
            console.log("Error with mappedValue with field type Month");
          }
        }
        return field;
      case "DAY":
        let daysArray = getDaysArray().map(day => ({ displayName: day.toString(), lenderValue: day.toString() }));
        field.details.optionSets = daysArray;
        if (field.mappedValue && typeof field.mappedValue === "string" && field.mappedValue.length > 2) {
          try {
            field.mappedValue = String(moment(field.mappedValue).date());
          } catch (error) {
            console.log("Error with mappedValue with field type Day");
          }
        }
        return field;
      case "STATE":
        const stateIndex = field.mappedValue?.length <= 2 ? "abbr" : "name";
        let statesArray = getStatesArray().map(state => ({
          displayName: state[stateIndex],
          lenderValue: state[stateIndex],
        }));
        field.details.optionSets = statesArray;

        return field;
      case "STATE_ABBR":
        let statesABBRArray = getStatesArray().map(state => ({
          displayName: state["abbr"],
          lenderValue: state["abbr"],
        }));
        field.details.optionSets = statesABBRArray;

        return field;
      case "COUNTRY":
        const countryIndex = field.mappedValue?.length <= 2 ? "code" : "name";
        let countriesArray = getCountriesArray().map(state => ({
          displayName: state[countryIndex],
          lenderValue: state[countryIndex],
        }));
        field.details.optionSets = countriesArray;

        return field;
    }
  };

  const renderField = (field, id) => {
    const type = field.details?.fieldInputType;
    let newField = { ...field };

    switch (type) {
      case "DATE":
        return (
          <DateFieldComp
            key={id}
            field={field}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            inputProps={getInputProps(field)}
          />
        );
      case "OPTION_SET":
        return (
          <SelectFieldComp
            field={field}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            key={id}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            setReferenceToErrorsValueFunction={setReferenceToChildSetErrorsFunction}
            setReferenceToSetOptionSetsFunction={setReferenceToSetOptionSetsFunction}
            inputProps={getInputProps(field)}
          />
        );
      case "YEAR":
      case "MONTH":
      case "DAY":
      case "STATE":
      case "STATE_ABBR":
      case "COUNTRY":
        newField = createNewField(type, newField);
        if (field.mappedValue !== newField.mappedValue) handleDynamicLenderMappingValueChange(newField.mappedValue, id);
        return (
          <SelectFieldComp
            field={newField}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            key={id}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            setReferenceToErrorsValueFunction={setReferenceToChildSetErrorsFunction}
            setReferenceToSetOptionSetsFunction={setReferenceToSetOptionSetsFunction}
            inputProps={getInputProps(field)}
          />
        );
      case "INITIALS":
        let initials = field.mappedValue || "";
        if (initials && initials.length > 2) initials = initials.match(/\b(\w)/g).join("");
        newField.mappedValue = initials.toUpperCase();
        if (field.mappedValue !== newField.mappedValue) handleDynamicLenderMappingValueChange(newField.mappedValue, id);
        return (
          <TextFieldComp
            field={newField}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            key={id}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            setReferenceToErrorsValueFunction={setReferenceToChildSetErrorsFunction}
            inputProps={getInputProps(field)}
          />
        );
      case "SSN":
        if (field.mappedValue !== newField.mappedValue) handleDynamicLenderMappingValueChange(newField.mappedValue, id);
        return (
          <TextFieldComp
            field={newField}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            key={id}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            setReferenceToErrorsValueFunction={setReferenceToChildSetErrorsFunction}
            inputProps={getInputProps(field)}
            mask="999-99-9999"
          />
        );
      case "FEIN":
        if (field.mappedValue !== newField.mappedValue) handleDynamicLenderMappingValueChange(newField.mappedValue, id);
        return (
          <TextFieldComp
            field={newField}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            key={id}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            setReferenceToErrorsValueFunction={setReferenceToChildSetErrorsFunction}
            inputProps={getInputProps(field)}
            mask="99-9999999"
          />
        );
      case "PHONE_NUMBER":
        if (field.mappedValue !== newField.mappedValue) handleDynamicLenderMappingValueChange(newField.mappedValue, id);
        return (
          <TextFieldComp
            field={newField}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            key={id}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            setReferenceToErrorsValueFunction={setReferenceToChildSetErrorsFunction}
            inputProps={getInputProps(field)}
            mask="(999) 999-9999"
          />
        );
      default:
        return (
          <TextFieldComp
            field={field}
            type={type}
            index={id}
            handleBlur={handleDynamicLenderMappingValueChange}
            key={id}
            setReference={setReferenceToChildSetValueFunction}
            setReferenceToRequiredFunction={setReferenceToChildSetRequiredFunction}
            setReferenceToErrorsValueFunction={setReferenceToChildSetErrorsFunction}
            inputProps={getInputProps(field)}
            validRegexPattern={field.details?.validRegexPattern}
          />
        );
    }
  };

  const formatDCRProperty = property => {
    if (!property) return "";

    let strProps = property.split("|").map(prop =>
      prop
        .split(".")
        .filter(prop => prop !== "INTETIONALLY_BAD_MAPPING" && prop !== "BAD_INPUT")
        .map(prop => {
          let newProp = "";
          newProp = prop.replace("[0]", "");
          newProp = prop.replace("[1]", "");
          newProp = prop.replace("[2]", "");

          if (newProp === "creditApp") newProp = "Credit Application";
          else if (newProp.includes("creditApplicationOwner")) newProp = "Owner";
          else if (newProp.includes("reference")) {
            try {
              const referenceJson = JSON.parse(property.match(/reference\[(.*?)\]/)[1]);
              newProp = `Reference (${referenceJson.type})`;
            } catch (e) {
              console.log("Problem getting reference type");
              newProp = "Reference";
            }
          } else if (newProp.includes("equipments")) newProp = "Equipment";
          else if (newProp === "vendorOpportunity") newProp = "Opportunity";
          else if (newProp === "userProfile") newProp = "Current User";
          else if (newProp === "null") newProp = "";
          else
            newProp = newProp.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
              return str.toUpperCase();
            });

          if (newProp === "Ssn") {
            return "SSN";
          } else if (newProp.includes("Fedaral")) {
            newProp = newProp.replace("Fedaral", "Federal");
          }
          return newProp;
        })
    );

    return strProps.map(prop => prop.join(": "));
  };

  if (loading || runningRules)
    return (
      <div className={classes.wrapperLoader}>
        <CircularProgress className="progressLoader" size={48} />
        <div className="headerContainer">
          <h4>Loading Submission Data...</h4>
        </div>
      </div>
    );

  return (
    <>
      <Tabs
        value={currentTabSelected}
        onChange={handleTabChange}
        variant="fullWidth"
        aria-label="full width tabs example"
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <Tab
          value="dlm"
          label="Lender Data"
          style={{ color: currentTabSelected === "dlm" ? theme.palette.primary.main : "" }}
        />
        <Tab
          value="lenderDocumentAccess"
          label="Documents"
          style={{ color: currentTabSelected === "lenderDocumentAccess" ? theme.palette.primary.main : "" }}
        />
      </Tabs>
      <TabPanel value={currentTabSelected} index="lenderDocumentAccess">
        <LenderDocumentAccessTable
          classes={classes}
          vo={vo}
          vp={vp}
          lender={lender}
          handlesSubmit={false}
          handleSubmitCallback={sharedDocumentsLocal => {
            setSharedDocuments(sharedDocumentsLocal);
          }}
        />
      </TabPanel>
      <TabPanel value={currentTabSelected} index="dlm">
        <div style={{ paddingBottom: 16 }}>
          <p>Please confirm the following information before submitting</p>
          <p>All fields highlighted in red must be provided</p>
        </div>
        <Table className={classes.table} style={{ marginBottom: 8 }}>
          <TableHead className={classes.tableHeader}>
            <TableCell
              style={{ fontSize: 16, fontWeight: "bold", textAlign: "center", marginRight: "8px" }}
              className={tableCellClasses}
              width="40%"
            >
              {["Trnsact"]}
            </TableCell>
            <TableCell style={{ fontSize: 16, fontWeight: "bold" }} className={tableCellClasses} width="20%">
              {[""]}
            </TableCell>
            <TableCell
              style={{ fontSize: 16, fontWeight: "bold", marginRight: "8px" }}
              className={tableCellClasses}
              width="40%"
            >
              {[lender.lenderName]}
            </TableCell>
          </TableHead>
        </Table>
        <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {dynamicLenderMapping &&
            structuredData &&
            Object.entries(structuredData).map(category => (
              <div style={{ marginBottom: 40, width: "98%" }}>
                {category[0] !== "DEFAULT" &&
                  Object.entries(category[1]).find(s => {
                    return s[1].find(r => {
                      return r.details.hidden !== true;
                    });
                  }) && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ fontWeight: 500 }}>{category[0]}</span>
                      <span
                        className={classes.collapseButton + " collapseButton"}
                        onClick={() => toggleCollapse(`category-${category[0]}`)}
                      >
                        {collapseState[`category-${category[0]}`] ? (
                          <div className={classes.icon + " " + classes.iconPlus} />
                        ) : (
                          <div className={classes.icon + " " + classes.iconMinus} />
                        )}
                      </span>
                    </div>
                  )}
                {Object.entries(category[1]).map(section => (
                  <>
                    {section[0] !== "DEFAULT" &&
                      section[1].find(r => {
                        return r.details.hidden !== true;
                      }) &&
                      !collapseState[`category-${category[0]}`] && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ textDecoration: "italic", margin: "16px 0" }}>{section[0]}</span>
                          <span
                            className={classes.collapseButton + " collapseButton"}
                            onClick={() => toggleCollapse(`section-${section[0]}`)}
                          >
                            {collapseState[`section-${section[0]}`] ? (
                              <div className={classes.icon + " " + classes.iconPlus} />
                            ) : (
                              <div className={classes.icon + " " + classes.iconMinus} />
                            )}
                          </span>
                        </div>
                      )}

                    {section[1].map(row => (
                      <Table
                        className={classes.table}
                        style={{
                          display:
                            !collapseState[`category-${category[0]}`] && !collapseState[`section-${section[0]}`]
                              ? "table"
                              : "none",
                        }}
                      >
                        <TableBody>
                          {row.details.hidden !== true && (
                            <TableRow>
                              <TableCell className={tableCellClasses} width="40%">
                                <Box component="form" noValidate autoComplete="off">
                                  {row.details.dcrPropertySource && row.details.dcrPropertySource != "" && (
                                    <>
                                      <TextField
                                        className={classes.textField}
                                        disabled={true}
                                        fullWidth={true}
                                        label={
                                          (row.details.required === "REQUIRED" ? "* " : "") +
                                            formatDCRProperty(row.details.dcrPropertySource)[0] ||
                                          getTrnsactDisplayName(row)
                                        }
                                        defaultValue={getTrnsactDisplayValue(row)}
                                        variant="outlined"
                                      />
                                      {formatDCRProperty(row.details.dcrPropertySource).length > 1 && (
                                        <p
                                          style={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: "rgba(0, 0, 0, 0.38)",
                                            marginBottom: 8,
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          Alt Source:{" "}
                                          {formatDCRProperty(row.details.dcrPropertySource)
                                            .map((src, i) => i !== 0 && src)
                                            .filter(i => i)
                                            .join(" OR ")}
                                        </p>
                                      )}
                                    </>
                                  )}
                                  {(!row.details.dcrPropertySource || row.details.dcrPropertySource === "") && (
                                    <span className={classes.labelNotMapped}>Not Mapped</span>
                                  )}
                                </Box>
                              </TableCell>
                              <TableCell
                                className={tableCellClasses}
                                width="10%"
                                padding-left="70px"
                                padding-top="24px"
                                align="center"
                              >
                                <img style={{ marginBottom: 16 }} src={twoWayArrow} width="20px" height="10px" />
                              </TableCell>
                              <TableCell
                                className={tableCellClasses}
                                width="40%"
                                style={{ display: "flex", width: "100%", alignItems: "center" }}
                              >
                                {renderField(row, row.lenderProperty)}
                                {!_.isEmpty(row.mappedValue) ? (
                                  <IconButton
                                    onClick={() => {
                                      handleDynamicLenderMappingValueChange(null, row.lenderProperty);
                                    }}
                                    style={{ height: "fit-content" }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ) : (
                                  <div style={{ width: 48 }} />
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    ))}
                  </>
                ))}
              </div>
            ))}
        </div>
      </TabPanel>
      <div style={{ display: "flex", justifyContent: "center", margin: "16px 0" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openDlmErrorSnackbar}
          autoHideDuration={6000}
          onClose={() => {
            setOpenDlmErrorSnackbar(false);
          }}
        >
          <Alert severity={"error"}>{dlmErrorSnackbarMessage}</Alert>
        </Snackbar>
        <Button
          color="primary"
          autoFocus
          onClick={() => {
            handleDlmFormSubmit();
          }}
          // disabled={submitDisabled}
        >
          Submit
        </Button>
      </div>
    </>
  );
}
