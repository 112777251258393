import React, { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { ConfigurableItemActions } from "../ConfigurableItemActions";
import { InsertionContainer } from "../InsertionContainer";
import { Field } from "./Field";

import { ControlTypes } from "../../../DynamicOCA/untils/types";
import { configurableItemsTypes, ocaSectionTypes, pcwMovingElementActionTypes } from "../../../Prequal/constants";
import {
  blockTypes,
  defaultMovingElementSettings,
  getConfigurableItemWarnMessage,
  getFieldConfigBasedOnOCAControl,
} from "../../../../services/creditAppModalService";
import {
  getCoApplicantFieldsOCAConfiguration,
  getEquipmentFieldsOCAConfiguration,
  getReferenceFieldsOCAConfiguration,
} from "../../../../services/dynamicOCAService";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  blockRoot: {
    padding: "16px",
    border: "1px dashed grey",
    //marginBottom: "16px",
    margin: "16px 0",
  },
  blockTitle: {
    margin: "0",
  },
  typedBlockInfoMessage: {
    backgroundColor: "#5a78e1",
    color: "white",
    textAlign: "center",
    position: "relative",
    top: "-16px",
    marginLeft: "-16px",
    marginRight: "-16px",
  },
}));

export function Block({
  block,
  blockIndex,
  section,
  sectionIndex,
  setJSONDefinition,
  editConfigurableItem,
  movingElementSettings,
  setMovingElementSettings,
  handleBlockInsertion,
  isBlockMoving,
}) {
  const classes = useStyles();
  const [fields, setFields] = useState(block.fields);

  const isElementMoving = !!movingElementSettings.type;
  const isLegacyReferenceBlock = _.get(block, "type") === blockTypes.reference;
  const isDynamicReferenceBlock = _.get(block, "type") === blockTypes.dynamicReference;
  const isCoApplicantBlock = _.get(block, "type") === blockTypes.coApplicant;
  const isEquipmentBlock = _.get(block, "type") === blockTypes.equipment;

  useEffect(() => {
    if (!block) {
      return;
    }
    setFields(block.fields);
  }, [block]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [ControlTypes.CONTROL, configurableItemsTypes.field],
      drop: (ocaControl, monitor) => {
        // TODO: addNewField() if was not added yet
        setJSONDefinition(prevState => {
          const sectionToUpdate = prevState.sections[sectionIndex];
          const blockToUpdate = sectionToUpdate.blocks[blockIndex];
          const updatedBlock = {
            ...blockToUpdate,
            fields,
          };

          return {
            ...prevState,
            sections: _.map(prevState.sections, (section, idx) => {
              if (idx === sectionIndex) {
                return {
                  ...sectionToUpdate,
                  blocks: _.map(sectionToUpdate.blocks, (block, idx) => (idx === blockIndex ? updatedBlock : block)),
                };
              }
              return section;
            }),
          };
        });
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop: (item, monitor) => {
        if (monitor.getItemType() !== ControlTypes.CONTROL) {
          return false;
        }
        const isNeedToCheckForBlockType = block.type || item.sectionTypes;
        if (isNeedToCheckForBlockType) {
          if (block.type) {
            // typed block
            return _.includes(item.sectionTypes, block.type);
          }

          if (!block.type) {
            // common block (non typed)
            return _.some(item.sectionTypes) ? _.includes(item.sectionTypes, ocaSectionTypes.common) : true;
          }
          return true;
        }
        return true;
      },
      hover(ocaControl, monitor) {
        if (_.isEmpty(fields) && monitor.canDrop()) {
          switch (true) {
            case isDynamicReferenceBlock:
              ocaControl = _.first(
                getReferenceFieldsOCAConfiguration(
                  [ocaControl.config.fieldName],
                  block.referenceType,
                  block.referenceIdx
                )
              );
              break;
            case isEquipmentBlock:
              ocaControl = _.first(
                getEquipmentFieldsOCAConfiguration(
                  [ocaControl.config.fieldName],
                  block.equipmentType,
                  block.equipmentIdx
                )
              );
              break;
            case isCoApplicantBlock:
              ocaControl = _.first(getCoApplicantFieldsOCAConfiguration([ocaControl.config.fieldName]));
              break;
          }
          addNewField(ocaControl, 0);
        }
      },
    }),
    [fields]
  );

  const moveField = useCallback(
    (dragIndex, hoverIndex) => {
      setFields(prevFields => {
        const fieldsCopy = [...prevFields];
        fieldsCopy.splice(dragIndex, 1);
        fieldsCopy.splice(hoverIndex, 0, prevFields[dragIndex]);
        return fieldsCopy;
      });
    },
    [block, fields]
  );

  const addNewField = useCallback(
    (ocaControl, hoverIndex) => {
      const fieldToAdd = getFieldConfigBasedOnOCAControl(ocaControl, !block.type);
      setFields(prevFields => {
        return [...prevFields.slice(0, hoverIndex), fieldToAdd, ...prevFields.slice(hoverIndex)];
      });
    },
    [block, fields]
  );

  const renderField = useCallback(
    (field, index) => {
      if (!field) {
        return;
      }
      return (
        <Field
          key={field.key}
          section={section}
          block={block}
          field={field}
          sectionIndex={sectionIndex}
          blockIndex={blockIndex}
          fieldIndex={index}
          moveField={moveField}
          fields={fields}
          setFields={setFields}
          addNewField={addNewField}
          setJSONDefinition={setJSONDefinition}
          editConfigurableItem={editConfigurableItem}
          movingElementSettings={movingElementSettings}
          setMovingElementSettings={setMovingElementSettings}
          isFieldMoving={isFieldMoving}
          handleFieldInsertion={handleFieldInsertion}
        />
      );
    },
    [block, fields, movingElementSettings]
  );

  const onDelete = () => {
    setJSONDefinition(prevState => {
      const sectionToUpdate = prevState.sections[sectionIndex];
      const updatedSection = {
        ...sectionToUpdate,
        blocks: sectionToUpdate.blocks.filter((block, idx) => idx !== blockIndex),
      };
      return {
        ...prevState,
        sections: prevState.sections.map((section, idx) => (idx === sectionIndex ? updatedSection : section)),
      };
    });
  };

  const isCurrentBlockMoving = () => {
    return (
      isBlockMoving() &&
      movingElementSettings.sectionIndex === sectionIndex &&
      movingElementSettings.blockIndex === blockIndex
    );
  };

  const isNeedToShowMoveBlockInsertionContainerTop = () => {
    return isBlockMoving() && blockIndex === 0 && !isCurrentBlockMoving();
  };

  const isNeedToShowMoveBlockInsertionContainerBottom = () => {
    const isNextBlockMoving = () => {
      return (
        movingElementSettings.action === pcwMovingElementActionTypes.move &&
        movingElementSettings.type === configurableItemsTypes.block &&
        movingElementSettings.sectionIndex === sectionIndex &&
        movingElementSettings.blockIndex === blockIndex + 1
      );
    };

    return isBlockMoving() && !(isCurrentBlockMoving() || isNextBlockMoving());
  };

  const handleBlockMerging = indexToMerge => {
    setJSONDefinition(prevState => {
      const sectionToMergeFrom = prevState.sections[movingElementSettings.sectionIndex];
      const sectionToMergeIn = prevState.sections[sectionIndex];
      const blockToMergeFrom = sectionToMergeFrom.blocks.find((block, idx) => idx === movingElementSettings.blockIndex);
      const blockToMergeIn = sectionToMergeIn.blocks.find((block, idx) => idx === blockIndex);
      const updatedBlock = {
        ...blockToMergeIn,
        fields:
          indexToMerge === 0
            ? blockToMergeFrom.fields.concat(blockToMergeIn.fields)
            : blockToMergeIn.fields.concat(blockToMergeFrom.fields),
      };

      let sectionToMergeInUpdatedBlocks = sectionToMergeIn.blocks.map((block, idx) =>
        idx === blockIndex ? updatedBlock : block
      );
      let updatedSectionToMergeIn = {
        ...sectionToMergeIn,
        blocks: sectionToMergeInUpdatedBlocks,
      };
      if (sectionToMergeFrom === sectionToMergeIn) {
        // need to remove a block from MergeIn Section, and update only one section
        updatedSectionToMergeIn.blocks = updatedSectionToMergeIn.blocks.filter(
          (block, idx) => idx !== movingElementSettings.blockIndex
        );
        return {
          ...prevState,
          sections: prevState.sections.map((section, idx) =>
            idx === sectionIndex ? updatedSectionToMergeIn : section
          ),
        };
      }
      const updatedSectionToMergeFrom = {
        ...sectionToMergeFrom,
        blocks: sectionToMergeFrom.blocks.filter((block, idx) => idx !== movingElementSettings.blockIndex),
      };
      return {
        ...prevState,
        sections: prevState.sections.map((section, idx) => {
          switch (idx) {
            case movingElementSettings.sectionIndex:
              return updatedSectionToMergeFrom;
            case sectionIndex:
              return updatedSectionToMergeIn;
            default:
              return section;
          }
        }),
      };
    });
    setMovingElementSettings(defaultMovingElementSettings);
  };

  const isBlockMerging = () => {
    return (
      movingElementSettings.action === pcwMovingElementActionTypes.merge &&
      movingElementSettings.type === configurableItemsTypes.block
    );
  };

  const isCurrentBlockMerging = () => {
    return (
      isBlockMerging() &&
      movingElementSettings.sectionIndex === sectionIndex &&
      movingElementSettings.blockIndex === blockIndex
    );
  };

  const isNeedToShowMergeBlockInsertionContainerTop = () => {
    return isBlockMerging() && !isCurrentBlockMerging();
  };

  const isNeedToShowMergeBlockInsertionContainerBottom = () => {
    return isBlockMerging() && _.some(fields) && !isCurrentBlockMerging();
  };

  const isFieldMoving = () => {
    return (
      movingElementSettings.action === pcwMovingElementActionTypes.move &&
      movingElementSettings.type === configurableItemsTypes.field
    );
  };

  const handleFieldInsertion = (fieldIndexToInsert, refEl) => {
    setJSONDefinition(prevState => {
      const sectionToMoveFrom = prevState.sections[movingElementSettings.sectionIndex];
      const blockToMoveFrom = sectionToMoveFrom.blocks[movingElementSettings.blockIndex];
      const fieldToMove = blockToMoveFrom.fields[movingElementSettings.fieldIndex];

      const sectionToMoveIn = prevState.sections[sectionIndex];
      const blockToMoveIn = sectionToMoveIn.blocks[blockIndex];
      const blockToMoveInFieldsCopy = [...blockToMoveIn.fields];

      if (blockToMoveFrom === blockToMoveIn) {
        const tempObj = {}; // used to keep constant indexes of spliced array
        blockToMoveInFieldsCopy.splice(movingElementSettings.fieldIndex, 1, tempObj);
        blockToMoveInFieldsCopy.splice(fieldIndexToInsert, 0, fieldToMove);
        blockToMoveInFieldsCopy.splice(blockToMoveInFieldsCopy.indexOf(tempObj), 1);
        const updatedBlockToMoveIn = {
          ...blockToMoveIn,
          fields: blockToMoveInFieldsCopy,
        };
        return {
          ...prevState,
          sections: _.map(prevState.sections, (section, idx) => {
            if (idx === sectionIndex) {
              return {
                ...sectionToMoveFrom,
                blocks: _.map(sectionToMoveFrom.blocks, (block, idx) =>
                  idx === blockIndex ? updatedBlockToMoveIn : block
                ),
              };
            }
            return section;
          }),
        };
      }

      const blockToMoveFromFieldsCopy = [...blockToMoveFrom.fields];
      blockToMoveFromFieldsCopy.splice(movingElementSettings.fieldIndex, 1);
      blockToMoveInFieldsCopy.splice(fieldIndexToInsert, 0, fieldToMove);
      const updatedBlockToMoveFrom = {
        ...blockToMoveFrom,
        fields: blockToMoveFromFieldsCopy,
      };
      const updatedBlockToMoveIn = {
        ...blockToMoveIn,
        fields: blockToMoveInFieldsCopy,
      };

      if (sectionToMoveFrom === sectionToMoveIn) {
        return {
          ...prevState,
          sections: _.map(prevState.sections, (section, idx) => {
            if (idx === sectionIndex) {
              return {
                ...sectionToMoveFrom,
                blocks: _.map(sectionToMoveFrom.blocks, (block, idx) => {
                  switch (idx) {
                    case movingElementSettings.blockIndex:
                      return updatedBlockToMoveFrom;
                    case blockIndex:
                      return updatedBlockToMoveIn;
                    default:
                      return block;
                  }
                }),
              };
            }
            return section;
          }),
        };
      }

      const updatedSectionToMoveFrom = {
        ...sectionToMoveFrom,
        blocks: sectionToMoveFrom.blocks.map((block, idx) =>
          idx === movingElementSettings.blockIndex ? updatedBlockToMoveFrom : block
        ),
      };
      const updatedSectionToMoveIn = {
        ...sectionToMoveIn,
        blocks: sectionToMoveIn.blocks.map((block, idx) => (idx === blockIndex ? updatedBlockToMoveIn : block)),
      };
      return {
        ...prevState,
        sections: prevState.sections.map((section, idx) => {
          switch (idx) {
            case movingElementSettings.sectionIndex:
              return updatedSectionToMoveFrom;
            case sectionIndex:
              return updatedSectionToMoveIn;
            default:
              return section;
          }
        }),
      };
    });
    setMovingElementSettings({ ...defaultMovingElementSettings, refEl });
  };

  return (
    <Box>
      {isNeedToShowMoveBlockInsertionContainerTop() && (
        <InsertionContainer type={configurableItemsTypes.block} onClickFn={() => handleBlockInsertion(blockIndex)} />
      )}
      <Box className={classes.blockRoot} ref={drop}>
        {isLegacyReferenceBlock && (
          <div className={classes.typedBlockInfoMessage}>
            <span>
              Legacy Reference Block - <b>{block.referenceType}</b>
            </span>
          </div>
        )}
        {isDynamicReferenceBlock && (
          <div className={classes.typedBlockInfoMessage}>
            <span>
              Dynamic Reference Block - <b>{block.referenceType}</b>
            </span>
          </div>
        )}
        {isCoApplicantBlock && (
          <div className={classes.typedBlockInfoMessage}>
            <span>Co-Applicant Block</span>
          </div>
        )}
        {isEquipmentBlock && (
          <div className={classes.typedBlockInfoMessage}>
            <span>
              Equipment Block - <b>{block.equipmentType}</b>
            </span>
          </div>
        )}
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <h3 className={classes.blockTitle}>{block.config.title}</h3>
          </Grid>
          <Grid item xs={2} container justify="flex-end">
            {!isElementMoving && (
              <ConfigurableItemActions
                warningMessage={getConfigurableItemWarnMessage(block, configurableItemsTypes.block)}
                onMove={() =>
                  setMovingElementSettings({
                    action: pcwMovingElementActionTypes.move,
                    type: configurableItemsTypes.block,
                    sectionIndex,
                    blockIndex,
                  })
                }
                onMerge={() =>
                  setMovingElementSettings({
                    action: pcwMovingElementActionTypes.merge,
                    type: configurableItemsTypes.block,
                    sectionIndex,
                    blockIndex,
                  })
                }
                onEdit={() => editConfigurableItem(block, configurableItemsTypes.block, section)}
                onDelete={onDelete}
                itemType={configurableItemsTypes.block}
              />
            )}
          </Grid>
        </Grid>
        {isFieldMoving() && _.isEmpty(fields) && (
          <InsertionContainer type={configurableItemsTypes.field} onClickFn={() => handleFieldInsertion(0, drop)} />
        )}
        {isNeedToShowMergeBlockInsertionContainerTop() && (
          <InsertionContainer type={configurableItemsTypes.block} onClickFn={() => handleBlockMerging(0)} />
        )}
        {fields.map((field, index) => renderField(field, index))}
        {isNeedToShowMergeBlockInsertionContainerBottom() && (
          <InsertionContainer type={configurableItemsTypes.block} onClickFn={() => handleBlockMerging(fields.length)} />
        )}
      </Box>
      {isNeedToShowMoveBlockInsertionContainerBottom() && (
        <InsertionContainer
          type={configurableItemsTypes.block}
          onClickFn={() => handleBlockInsertion(blockIndex + 1)}
        />
      )}
    </Box>
  );
}
