import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tile from "../../components/Tile/Tile";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: "block",
    backgroundColor: "#FFFFFF",
    borderRadius: "5px",
    position: "absolute",
    width: 655,
    height: 700,
    boxShadow: theme.shadows[5],
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflowX: "hidden",
  },
  tile: {
    margin: 0,
    padding: 0,
    "& > div": {
      padding: 0,
    },
    "& > .makeStyles-cardHeader-249": {
      marginRight: "25px",
      marginLeft: "25px",
    },
  },
  tileHeader: {
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "500",
  },
  modalHeader: {
    fontWeight: 300,
    color: "#555555",
    fontSize: "26px",
    lineHeight: "26px",
  },
  listItemContainer: {
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  listItemHeader: {
    color: "#000000",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "Roboto",
    fontWeight: "500",
    minWidth: "200px",
  },
  listItemText: {
    color: "#000000",
    fontSize: "14px",
    lineHeight: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    textAlign: "left",
  },
  showAllTile: {
    cursor: "pointer",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "12",
    color: "#0A84FC",
  },
}));

const ApplicationSummaryModal = ({
  date_applied,
  applicant_name,
  address,
  street_address,
  city,
  state,
  postalCode,
  phone,
  dateEstablished,
  tib,
  amountRequested,
  equipmentDescription,
  owner1Fullname,
  owner1Title,
  owner1_percentage,
  owner1_dob,
  owner2_name,
  owner2_title,
  owner2_percentage,
  owner2_dob,
  agreed_to_term,
  rep,
  created_by,
  dealer_name,
  dealer_address,
  dealer_phone,
  dealer_email,
  dealer_website,
  signature,
  date_sign,
}) => {
  const classes = useStyles();
  const [allCollapse, setAllCollapse] = useState(true);

  const renderDescription = () => {
    if (!equipmentDescription) {
      return "";
    } else if (equipmentDescription.length > 40) {
      return "Download To View";
    } else {
      return equipmentDescription;
    }
  };

  return (
    <div className={classes.modal}>
      <Grid container justify="space-between" alignItems="center" style={{ padding: "30px 25px" }}>
        <Typography variant="h4" component="h4" className={classes.modalHeader}>
          Applicant Detail
        </Typography>
        <p
          onClick={() => {
            setAllCollapse(!allCollapse);
          }}
          className={classes.showAllTile}
        >
          Show All
        </p>
      </Grid>

      <Grid container direction="column">
        <Grid item xl={12}>
          <Tile
            useCollapsing
            title={<span className={classes.tileHeader}>Applicant Information</span>}
            initCollapsed
            isAllCollapsed={allCollapse}
            className={classes.tile}
          >
            <Grid container direction="column" style={{ backgroundColor: "#f3f3f3", paddingTop: "15px" }}>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Applicant Name:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {applicant_name}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Applicant Phone:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {phone}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Date Applied:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {date_applied}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xxl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Street Address:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {address}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    City:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {city}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    State:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {state}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Zipcode:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {postalCode}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Equipment Description:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {renderDescription()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Date Established:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {dateEstablished}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    TIB:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {tib}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Amount Requested::
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {amountRequested}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Tile>
        </Grid>

        <Grid item xl={12}>
          <Tile
            useCollapsing
            title={<span className={classes.tileHeader}>Owner #1 Information</span>}
            initCollapsed
            isAllCollapsed={allCollapse}
            className={classes.tile}
          >
            <Grid container direction="column" style={{ backgroundColor: "#f3f3f3", paddingTop: "15px" }}>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Owner 1
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}></Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Name
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {owner1Fullname}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Title
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {owner1Title}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Ownership Part
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {owner1_percentage}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    DOB
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {owner1_dob}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xxl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Agreed to T&C
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    Yes
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Representative
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {rep}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Created_by:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    Trnsact Admin
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Dealer name:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {dealer_name}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Dealer address:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {dealer_address}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Dealer phone:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {dealer_phone}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Signature
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {signature}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={12} className={classes.listItemContainer}>
                <Grid container>
                  <Grid item xl={6} className={classes.listItemHeader}>
                    Date sign:
                  </Grid>
                  <Grid item xl={6} className={classes.listItemText}>
                    {date_sign}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Tile>
        </Grid>
        {owner2_name && (
          <Grid item xl={12}>
            <Tile
              useCollapsing
              title={<span className={classes.tileHeader}>Owner #2 Information</span>}
              initCollapsed
              isAllCollapsed={allCollapse}
              className={classes.tile}
            >
              <Grid container direction="column" style={{ backgroundColor: "#f3f3f3", paddingTop: "15px" }}>
                <Grid item xl={12} className={classes.listItemContainer}>
                  <Grid container>
                    <Grid item xl={6} className={classes.listItemHeader}>
                      Owner 2
                    </Grid>
                    <Grid item xl={6} className={classes.listItemText}></Grid>
                  </Grid>
                </Grid>
                <Grid item xl={12} className={classes.listItemContainer}>
                  <Grid container>
                    <Grid item xl={6} className={classes.listItemHeader}>
                      Name
                    </Grid>
                    <Grid item xl={6} className={classes.listItemText}>
                      {owner2_name}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={12} className={classes.listItemContainer}>
                  <Grid container>
                    <Grid item xl={6} className={classes.listItemHeader}>
                      Title
                    </Grid>
                    <Grid item xl={6} className={classes.listItemText}>
                      {owner2_title}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={12} className={classes.listItemContainer}>
                  <Grid container>
                    <Grid item xl={6} className={classes.listItemHeader}>
                      Ownership Percentage:
                    </Grid>
                    <Grid item xl={6} className={classes.listItemText}>
                      {owner2_percentage}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={12} className={classes.listItemContainer}>
                  <Grid container>
                    <Grid item xl={6} className={classes.listItemHeader}>
                      DOB
                    </Grid>
                    <Grid item xl={6} className={classes.listItemText}>
                      {owner2_dob}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Tile>
          </Grid>
        )}
      </Grid>
      {/*         
        

        */}
    </div>
  );
};

export default React.forwardRef((props, ref) => <ApplicationSummaryModal {...props} forwardRef={ref} />);
