import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Switch,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Button from "../../components/CustomButtons/Button";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { isEmpty } from "lodash";
import MuiAlert from "@material-ui/lab/Alert";
import { lenderTypesSettings } from "../Prequal/constants";

import _ from "lodash";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "25px",
  },
  requiredHint: {
    fontSize: "10px",
    fontWeight: "300",
    textAlign: "right",
    width: "100%",
    margin: 0,
  },
  modalHeader: {
    fontWeight: 300,
    fontSize: 25,
    lineHeight: "29px",
    color: "#000000",
  },
  requiredItems: {
    fontWeight: 300,
    fontSize: 12,
    color: "#818181",
    marginRight: 30,
  },
  modalInputContainer: {
    marginBottom: 16,
    "& > div > label": {
      fontWeight: 400,
      fontSize: 12,
      color: "#818181",
    },
    "& .MuiFormLabel-filled": {
      color: "#3C4858",
      textTransform: "uppercase",
    },
    "& .Mui-focused": {
      color: "#3C4858",
      textTransform: "uppercase",
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
      fontSize: 12,
      color: "#818181",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#818181 !important",
    },
  },
  outlineInput: {
    marginTop: 20,
    "& .MuiInputBase-input": {
      fontWeight: 400,
      lineHeight: "14px",
      fontSize: 12,
      color: "#818181",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#818181 !important",
    },
  },
  subMethod: {
    fontWeight: 400,
    fontSize: 12,
    color: "#3C4858",
    textTransform: "uppercase",
    marginTop: 4,
  },
  submitButton: {
    backgroundColor: "#3E83E6",
    fontSize: "12px",
    fontWeight: 300,
    color: "#FFF",
    marginTop: "30px",
    padding: "10px 25px",
  },
  textError: {
    color: "#98260d",
    textTransform: "uppercase",
  },
  selectInput: {
    color: "#818181",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "-0.01px",
  },
  modalItem: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 20,
    },
  },

  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    background: "white",
    padding: "0 4px",
  },
});
const useStyles = makeStyles(styles);

const CREATE_NEW_LENDER_PROFILE = gql`
  mutation($input: LenderProfileWithAccountInput!) {
    createLenderProfileWithAccount(input: $input) {
      channel
      contactEmail
      contactName
      dealerId
      dynamicsContactSubmission
      id
      insuranceRequirements
      lenderAccountId
      lenderAccountIdPg
      lenderProfileId
      name
      logo
      vendorProfileId
    }
  }
`;

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
export default function AddNewLenderModal({
  open,
  handleClose: handleCloseModal,
  tourEnabled,
  userIsSuperAdmin,
  refetchData,
}) {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const vp = useSelector(state => state.vp);
  const [createLenderProfileWithAccount, { loading: mutationLoading }] = useMutation(CREATE_NEW_LENDER_PROFILE, {
    context: { authRequired: true },
  });
  const initialFormValues = {
    lenderName: "",
    lenderContactEmail: "",
    lenderContactFirstName: "",
    lenderContactLastName: "",
    channel: "",
    description: "",
    monthlyApplications: "",
    requestDocumentsMethod: "",
    averageApprovalRate: "",
    password: "",
    username: "",
    portalUrl: "",
    interestRates: "",
    lenderTypeCode: "",
    sendInvite: true,
  };

  const initialErrorStates = {
    lenderName: false,
    lenderContactEmail: false,
    lenderContactFirstName: false,
    lenderContactLastName: false,
    channel: false,
    description: false,
    requestDocumentsMethod: false,
    averageApprovalRate: false,
    password: false,
    username: false,
    portalUrl: false,
    monthlyApplications: false,
    interestRates: false,
    lenderTypeCode: false,
  };
  //Fields
  const [values, setValues] = useState(initialFormValues);

  //Contro Inputs
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [canDisplaySendInvite, setCanDisplaySendInvite] = useState(false);
  const [havePortal, setHavePortal] = useState(false);
  const [selectItems, setSelectItems] = useState([
    { value: 100, title: "Email Credit App" },
    {
      value: 200,
      title: "Enter into Lender’s Portal",
    },
    // { value: 400, title: "API" },
  ]);
  const lenderTypesSettingsByValue = _.keyBy(lenderTypesSettings, "code");

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });

    //User selected Manual submission to Portal, this enables Lender's Website fields
    if (prop == "channel") {
      setHavePortal(event.target.value == 200);
    }

    if (submitted) {
      runValidations();
    }
  };
  const resetStates = () => {
    setValues(initialFormValues);
    setErrors(initialErrorStates);
    setSubmitted(false);
  };
  const handleClose = () => {
    handleCloseModal();
    resetStates();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const [errors, setErrors] = useState(initialErrorStates);

  let checker = arr => arr.every(v => v === false);

  useEffect(() => {
    setSubmitSuccess(false);
  }, [open]);

  useEffect(() => {
    if (values.lenderContactFirstName && values.lenderContactLastName && values.lenderContactEmail) {
      setCanDisplaySendInvite(true);
    } else {
      setCanDisplaySendInvite(false);
    }
  }, [values]);

  const runValidations = () => {
    const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let errorCollect = {};
    if (userIsSuperAdmin) {
      errorCollect = {
        ...errors,
        lenderName: !values.lenderName,
        lenderContactFirstName: !values.lenderContactFirstName,
        lenderContactLastName: !values.lenderContactLastName,
        lenderContactEmail: !emailRegex.test(String(values.lenderContactEmail).toLowerCase()),
        lenderTypeCode: !values.lenderTypeCode,
        channel: !values.channel,
      };
    } else {
      errorCollect = {
        ...errors,
        lenderName: !values.lenderName,
        lenderContactFirstName: !values.lenderContactFirstName,
        lenderContactLastName: !values.lenderContactLastName,
        requestDocumentsMethod: !values.requestDocumentsMethod,
        interestRates: !values.interestRates,
        description: !values.description,
        lenderContactEmail: !emailRegex.test(String(values.lenderContactEmail).toLowerCase()),
        averageApprovalRate: isNaN(values.averageApprovalRate) || isEmpty(values.averageApprovalRate),
        monthlyApplications: isNaN(values.monthlyApplications) || isEmpty(values.monthlyApplications),
        channel: !values.channel,
        lenderTypeCode: !values.lenderTypeCode,
        portalUrl: values.portalUrl ? false : havePortal && true,
        username: values.username ? false : havePortal && true,
        password: values.password ? false : havePortal && true,
      };
    }

    setErrors(errorCollect);

    const result = checker(Object.values(errorCollect));
    console.log(result);
    return result;
  };
  const handleSubmit = async event => {
    event.preventDefault();
    const validationsPassed = runValidations();
    setSubmitSuccess(false);

    if (validationsPassed) {
      setSubmitted(true);
      setLoading(true);

      try {
        await createLenderProfileWithAccount({
          variables: {
            input: {
              name: values.lenderName,
              lenderContactEmail: values.lenderContactEmail,
              lenderContactFirstName: values.lenderContactFirstName,
              lenderContactLastName: values.lenderContactLastName,
              channel: _.get(values, "channel", 100),
              accountId: account.dynamicsAccountId, // dealer's dynamics accountId
              portalUrl: values.channel == 200 ? values.portalUrl : null,
              portalUsername: values.channel == 200 ? values.username : null,
              portalPassword: values.channel == 200 ? values.password : null,
              averageApprovalRate: parseInt(values.averageApprovalRate),
              monthlyApplications: parseInt(values.monthlyApplications),
              description: values.description,
              vendorProfileId: vp.dynamicsId, // dealer's dynamics VP
              requestDocumentsMethod: values.requestDocumentsMethod,
              referredBy: 100,
              interestRates: values.interestRates,
              lenderTypeCode: values.lenderTypeCode,
              accountType: "LENDER",
              sendInvite: userIsSuperAdmin ? values.sendInvite && canDisplaySendInvite : true,
            },
          },
        });
        if (refetchData) {
          await refetchData();
        }

        setLoading(false);
        setSubmitSuccess(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth maxWidth="sm">
      <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingBottom: 0 }}>
        <h4>
          <b>Lender</b>
          {loading && <CircularProgress style={{ marginLeft: 16, height: 24, width: 24 }} />}
        </h4>
        {handleClose ? (
          <IconButton size="medium" aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Snackbar
          open={submitSuccess}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert severity="success">Request Sent Successfully.</Alert>
        </Snackbar>

        <form onSubmit={handleSubmit}>
          <Grid container style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.lenderName}
                  label="Name"
                  name="lenderName"
                  value={values.lenderName}
                  onChange={handleChange("lenderName")}
                  required={true}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="select-filter"
                  style={{ margin: "8px 0" }}
                  error={errors.channel}
                >
                  <InputLabel
                    id={`submission-method-select-label`}
                    className={classes.selectLabel}
                    shrink={true}
                    htmlFor="channelid"
                  >
                    Current Submission Method{userIsSuperAdmin ? "" : "*"}
                  </InputLabel>
                  <Select
                    labelId={`submission-method-select-label`}
                    id={`submission-method-select`}
                    value={values.channel}
                    label="Current Submission Method*"
                    className={classes.selectStyles}
                    renderValue={() => selectItems.find(item => item.value === values.channel).title}
                    required={true}
                    onChange={handleChange("channel")}
                    style={{ width: "calc(100% - 8px)" }}
                    inputProps={{
                      name: "channel",
                      id: "channelid",
                      required: true,
                      helpertext: "Required",
                    }}
                  >
                    {selectItems.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="select-filter"
                  style={{ margin: "8px 0", display: "flex", alignItems: "flex-end" }}
                  error={errors.channel}
                >
                  <InputLabel
                    id={`lender-type-select-label`}
                    className={classes.selectLabel}
                    htmlFor="Lender Type"
                    shrink={true}
                  >
                    Lender Type{userIsSuperAdmin ? "" : "*"}
                  </InputLabel>
                  <Select
                    labelId={`lender-type-select-label`}
                    id={`lender-type-select`}
                    value={values.lenderTypeCode}
                    label="Lender Type*"
                    className={classes.selectStyles}
                    style={{ width: "calc(100% - 8px)" }}
                    renderValue={() => {
                      const selectedLenderTypeSetting = lenderTypesSettingsByValue[values.lenderTypeCode];
                      return selectedLenderTypeSetting ? (
                        <>
                          <b style={{ color: selectedLenderTypeSetting.color }}>
                            {_.get(selectedLenderTypeSetting, "letter", "")}
                          </b>
                          <span> {_.get(selectedLenderTypeSetting, "title", "")}</span>
                        </>
                      ) : null;
                    }}
                    onChange={handleChange("lenderTypeCode")}
                    required={true}
                    inputProps={{
                      name: "lenderTypeCode",
                      id: "lenderTypeId",
                      required: true,
                      helpertext: "Required",
                    }}
                  >
                    {lenderTypesSettings.map(lenderTypeSetting => (
                      <MenuItem key={lenderTypeSetting.code} value={lenderTypeSetting.code}>
                        <>
                          <b style={{ color: lenderTypeSetting.color }}>{_.get(lenderTypeSetting, "letter", "")}</b>
                          <span>&nbsp;{_.get(lenderTypeSetting, "title", "")}</span>
                        </>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <TextField
                  error={errors.monthlyApplications}
                  label="Monthly Applications"
                  value={values.monthlyApplications}
                  onChange={handleChange("monthlyApplications")}
                  type="number"
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={!userIsSuperAdmin}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <TextField
                  error={errors.averageApprovalRate}
                  label="Avg. Approval Rate"
                  value={values.averageApprovalRate}
                  onChange={handleChange("averageApprovalRate")}
                  type="number"
                  required={!userIsSuperAdmin}
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Request Documents Method"
                className={classes.textField}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.requestDocumentsMethod}
                value={values.requestDocumentsMethod}
                onChange={handleChange("requestDocumentsMethod")}
                type="text"
                required={!userIsSuperAdmin}
              />
            </Grid>
            {havePortal ? (
              <>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    error={errors.portalUrl}
                    label="Lender Portal's URL"
                    value={values.portalUrl}
                    onChange={handleChange("portalUrl")}
                    className={classes.textField}
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="text"
                    required={!userIsSuperAdmin}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Portal's Username"
                    id="portal-username"
                    type="text"
                    value={values.username}
                    error={errors.username}
                    required={false}
                    className={classes.textField}
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange("username")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PermIdentityIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Portal's Password"
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    error={errors.password}
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    required={false}
                    onChange={handleChange("password")}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                error={errors.interestRates}
                label="Program Options and Pricing"
                value={values.interestRates}
                onChange={handleChange("interestRates")}
                // multiline
                placeholder={`Please share their credit programs include interest rates. ${
                  userIsSuperAdmin ? "" : "*"
                }`}
                className={classes.textField}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                required={!userIsSuperAdmin}
                // helperText="Required"
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                error={errors.description}
                label="Description"
                value={values.description}
                onChange={handleChange("description")}
                // multiline
                placeholder={`Please share your relationship history with this Lender (if any) and how you currently submit new opportunities to them. ${
                  userIsSuperAdmin ? "" : "*"
                }`}
                className={classes.textField}
                variant="outlined"
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                required={!userIsSuperAdmin}
                // helperText="Required"
              />
            </Grid>
            <h4 style={{ margin: "8px 0" }}>
              <b>Contact</b>
            </h4>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.lenderContactFirstName}
                  label="First Name"
                  name="lenderContactFirstName"
                  value={values.lenderContactFirstName}
                  onChange={handleChange("lenderContactFirstName")}
                  type="text"
                  required={!userIsSuperAdmin}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "calc(100% - 8px)" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.lenderContactFirstName}
                  label="Last Name"
                  name="lenderContactLastName"
                  value={values.lenderContactLastName}
                  onChange={handleChange("lenderContactLastName")}
                  type="text"
                  required={!userIsSuperAdmin}
                />
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.lenderContactEmail}
                  label={
                    errors.lenderContactEmail ? (
                      <span className={classes.textError}>Valid email is required</span>
                    ) : (
                      "Email"
                    )
                  }
                  value={values.lenderContactEmail}
                  onChange={handleChange("lenderContactEmail")}
                  type="text"
                  required={!userIsSuperAdmin}
                />
              </FormControl>
            </Grid>
            {userIsSuperAdmin && canDisplaySendInvite && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} justifyContent="center">
                <FormControl>
                  <label
                    style={{
                      marginRight: 4,
                      color: values.sendInvite ? "rgba(0,0,0,0.85)" : "",
                      fontWeight: 400,
                    }}
                  >
                    {values.sendInvite ? "Send Invitation" : "Stage Invitation"}
                  </label>
                  <Switch
                    value={values.sendInvite}
                    defaultChecked={true}
                    onChange={() => {
                      setValues({ ...values, ["sendInvite"]: !values.sendInvite });
                    }}
                    color="primary"
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container style={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid item>
              <Button className={classes.submitButton} onClick={handleSubmit} type="submit" disabled={mutationLoading}>
                ADD LENDER
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
