import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import blue from "@material-ui/core/colors/blue";
import { connect } from "react-redux";

const useStylesPaper = makeStyles(theme => ({
  modal: {
    width: "400px",
  },
  paper: {
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "@media (max-width: 768px)": {
      minWidth: 240,
    },
  },
  labelRoot: {
    fontWeight: 800,
    color: "black",
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
  container: {
    padding: "40px 0 0",
  },
  containedBlue: {
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: blue[900],
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  formControl: {
    minWidth: 400,
    marginTop: "20px",
    marginBottom: "20px",
    "@media (max-width: 768px)": {
      minWidth: 240,
      width: 240,
    },
  },
  buttonContainer: {
    paddingBottom: "0 !important",
  },
}));

function ContactNotificationModal({ open, handleClose, handleSetEmailOk, handleSetEmailNo }) {
  const classesPaper = useStylesPaper();

  return (
    <div className={classesPaper.modal}>
      <Dialog onClose={handleClose} open={open} scroll={"body"}>
        <Paper className={classesPaper.paper}>
          <CardContent>
            <Typography variant="h5" component="h5">
              Send Email Notification?
            </Typography>
            <Divider />
            <div className={classesPaper.container}>
              <Grid container spacing={4} justify="center" alignItems="flex-end">
                <Grid item className={classesPaper.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSetEmailOk}
                    className={classesPaper.containedBlue}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item className={classesPaper.buttonContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSetEmailNo}
                    className={classesPaper.containedBlue}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </div>
            <br />
          </CardContent>
        </Paper>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => ({
  vo: state.vo,
  account: state.account,
});

export default connect(
  mapStateToProps,
  null
)(ContactNotificationModal);
