import { FC, ReactNode } from "react";

export interface CommonPropsForAllViews<Entity, CardEntity extends BaseEntity<Entity>> {
  actionsPanel?: FC<CardEntity>;
  isSelectEntityDisabled?: boolean;
  onSelectEntity?: (entity: CardEntity) => void;
}

export enum EntityMobileCardsViewType {
  NoGrouped = "NoGrouped",
  Grouped = "Grouped",
}

interface DataForView {
  label: string;
  value: ReactNode;
  type: "default" | "component";
  component?: FC<{ value: any }>;
  formatter?: (value: ReactNode) => string | null;
}

export interface BaseEntity<Original, Group extends keyof Original = any> {
  id: string;
  title?: ReactNode;
  group?: Group;
  groupName?: string;
  original: Original;
  titleIcon?: ReactNode;
  isSelected?: boolean;
  dateAndTime: string | null;
  dataForView: DataForView[];
}
