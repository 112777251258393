import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import _ from "lodash";
export const DateField = ({ fieldConfig }) => {
  const [value, setValue] = useState(undefined);

  const formattingValue = value => {
    const formattedValue = value.replace(/[^\d]/g, "");
    if (formattedValue.length > 8) {
      return formattedValue.slice(0, -1);
    }
    return formattedValue;
  };

  const validation = () => {
    const newValue = new Date(value && value.replace(/[^\d / ]/g, ""));

    if (newValue > new Date(fieldConfig.maxValue) || newValue < new Date(fieldConfig.minValue)) {
      return true;
    }
  };

  return (
    <TextField
      fullWidth={fieldConfig.fullWidth}
      disabled={fieldConfig.disabled}
      variant={fieldConfig.variant}
      label={_.get(fieldConfig, "displayLabel.en", "")}
      required={fieldConfig.required}
      helperText={_.get(fieldConfig, "errorMessage.en", "")}
      error={false}
      value={value}
      style={{ pointerEvents: "none" }}
    />
  );
};
