import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { formatCurrency } from "utils";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    height: "100svh",
    overflowY: "scroll",
    paddingBottom: 144,
  },
  searchContainer: {
    padding: "16px 32px",
    width: "100%",
    backgroundColor: "rgb(45,45,45)",
    height: "fit-content",
    position: "absolute",
    bottom: 0,
  },
}));

export default props => {
  const classes = useStyles();
  const {
    items,
    primaryKey = "",
    fallbackPrimaryKey = "",
    secondaryKey = "",
    subPrimaryKey1 = "",
    subPrimaryKey2 = "",
    onListItemClick = function() {},
  } = props;

  const [searchVal, setSearchVal] = useState("");

  const handleSearchChange = e => {
    e.preventDefault();

    setSearchVal(e.target.value);
  };

  const matchesFilters = item => {
    return item[primaryKey]?.includes(searchVal) || item[fallbackPrimaryKey]?.includes(searchVal);
  };

  return (
    <div style={{ height: "100%" }}>
      <List className={classes.root}>
        {items?.map(item => {
          if (matchesFilters(item)) {
            return (
              <>
                <ListItem button onClick={() => onListItemClick(item)}>
                  <ListItemText
                    primary={item[primaryKey] || item[fallbackPrimaryKey] || "Unknown"}
                    secondary={moment(item[subPrimaryKey1]).format("MM/DD/yyyy") + " - " + item[subPrimaryKey2]}
                  />
                  <ListItemText primary={formatCurrency(item[secondaryKey]?.toFixed(2))} style={{ textAlign: "end" }} />
                </ListItem>
                <Divider />
              </>
            );
          }
        })}
      </List>
      <div className={classes.searchContainer}>
        <FormControl style={{ margin: 0, width: "100%" }}>
          <TextField
            size="small"
            variant="outlined"
            onChange={handleSearchChange}
            value={searchVal}
            style={{ backgroundColor: "#fff", borderRadius: 4, width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ opacity: searchVal.length > 0 ? 1 : 0 }}
                  disablePointerEvents={searchVal.length === 0}
                  onClick={() => setSearchVal("")}
                >
                  <ClearIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
    </div>
  );
};
