import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./actionTypes";
import { AddNotificationAction, RemoveNotificationAction, Notification } from "./types";

const addNotification = (notification: Notification): AddNotificationAction => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});

const removeNotification = (id: number): RemoveNotificationAction => ({
  type: REMOVE_NOTIFICATION,
  payload: id,
});

export const notificationsActions = { addNotification, removeNotification };
