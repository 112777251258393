import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import classnames from "classnames";
import { gql } from "apollo-boost";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { CircularProgress } from "@material-ui/core";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CloudDownload from "@material-ui/icons/CloudDownload";
import MuiAlert from "@material-ui/lab/Alert";
import DownloadIcon from "@material-ui/icons/GetApp";
import { theme } from "../theme";

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";

const useStyles = makeStyles(theme => ({
  ...styles,
  noResults: {
    display: "flex",
    flexDirection: "column",
    padding: 32,
    color: theme.palette.grey[600],

    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      margin: "0 auto 48px",

      "& h4": {
        marginLeft: 8,
      },
    },
  },
  wrapperLoader: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.grey[500],
    width: "100%",
    alignItems: "center",

    "& .headerContainer": {
      display: "flex",
      alignItems: "center",
      marginBottom: 32,

      "& h4": {
        marginLeft: 8,
      },
    },
    "& .progressLoader": {
      margin: 32,
      color: theme.palette.grey[500],
    },
  },
}));

export function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CREDIT_REPORT_PULL_BY_THIRD_PARTY_ID = gql`
  mutation($input: CreditReportPullByThirdPartyIdInput!) {
    creditReportPullByThirdPartyId(input: $input) {
      creditReportBusinessId
      externalReportId
      vendorOpportunityId
      reportType
      score
      machineReadableReport
      humanReadableReportDocumentId
      errorDescription
    }
  }
`;

export default function CompanySearchModal({
  vo,
  creditApp,
  companySearchResult,
  refetchCreditApp,
  setCreditReportDownloaded,
  setCreditReportId,
  userDocsRefetch,
}) {
  const classes = useStyles();

  const [creditPullLoading, setCreditPullLoading] = useState(false);
  const [creditPullError, setCreditPullError] = useState(false);

  const tableCellClasses = classnames(classes.tableCell);

  // console.log(JSON.stringify(vo));
  // console.log(JSON.stringify(companySearchResult));

  const [creditReportPullByThirdPartyId] = useMutation(CREDIT_REPORT_PULL_BY_THIRD_PARTY_ID, {
    context: { authRequired: true },
  });

  const VOId = vo.vendorOpportunityId;

  async function handleCompanySelectedForCreditPull(company) {
    console.log(JSON.stringify(company));
    setCreditPullLoading(true);

    let variables = {
      input: {
        vendorOpportunityId: VOId,
        thirdPartyId: company.id,
      },
    };
    try {
      const response = await creditReportPullByThirdPartyId({
        variables,
      });
      // console.log('+++++++++++++++++++++++++++++++++');
      // console.log(JSON.stringify(response));
      // console.log('+++++++++++++++++++++++++++++++++');

      const creditReportResult = response.data.creditReportPullByThirdPartyId;

      if (!creditReportResult) {
        setCreditPullLoading(false);
        setCreditPullError(true);
      } else {
        console.log(creditReportResult);
        setCreditPullError(false);
        await refetchCreditApp();
        await userDocsRefetch();
        console.log("___________________________________________");
        // console.log(creditReportResult.humanReadableReportDocumentId);
        // console.log(JSON.strinsgify(creditReportResult));
        // console.log('___________________________________________');
        setCreditReportId(creditReportResult.humanReadableReportDocumentId);
        setCreditReportDownloaded(true);
      }
    } catch (e) {
      setCreditPullLoading(false);
      setCreditPullError(true);
    }
  }

  return (
    <>
      {!creditPullLoading && !creditPullError && companySearchResult.companySearch.length > 0 && (
        <>
          <div style={{ paddingLeft: "25px", paddingBottom: 32 }}>
            <div>{creditApp.businessName}</div>
            <div>{creditApp.address}</div>
            <div>
              {creditApp.city}, {creditApp.state} {creditApp.postalCode}
            </div>
          </div>
          <div>
            <div style={{ minWidth: 620, width: "40vw", maxHeight: "60vh", overflowY: "scroll" }}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow className={classes.tableHeader}>
                    <TableCell style={{ fontWeight: "bold" }} className={tableCellClasses}>
                      {["Company Name"]}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} className={tableCellClasses}>
                      {["Street Address"]}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }} className={tableCellClasses}>
                      {["City"]}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", textAlign: "center" }} className={tableCellClasses}>
                      {["State"]}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", textAlign: "center" }} className={tableCellClasses}>
                      {["Zip"]}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", textAlign: "center" }} className={tableCellClasses}>
                      {["Confidence Score"]}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold", textAlign: "center" }} className={tableCellClasses}>
                      {["Pull Credit"]}
                    </TableCell>
                  </TableRow>
                  {companySearchResult &&
                    companySearchResult.companySearch &&
                    companySearchResult.companySearch.map(company => (
                      <TableRow key={company} className={classes.tableRow}>
                        <TableCell className={tableCellClasses}>{[company.companyName]}</TableCell>
                        <TableCell className={tableCellClasses}>{[company.street]}</TableCell>
                        <TableCell className={tableCellClasses}>{[company.city]}</TableCell>
                        <TableCell className={tableCellClasses}>{[company.provinceAbbreviation]}</TableCell>
                        <TableCell className={tableCellClasses}>{[company.postalCode]}</TableCell>
                        <TableCell className={tableCellClasses} style={{ textAlign: "center" }}>
                          {[company.confidenceScore]}
                        </TableCell>
                        <TableCell className={classes.tableActions}>
                          <IconButton onClick={() => handleCompanySelectedForCreditPull(company)}>
                            <DownloadIcon style={{ color: theme.palette.primary.main }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </>
      )}
      {!creditPullLoading && !creditPullError && companySearchResult.companySearch.length === 0 && (
        <div className={classes.noResults}>
          <div className="headerContainer">
            <ErrorOutline />
            <h4>No matches found</h4>
          </div>
          <p>Please double check the company name and address and try again.</p>
        </div>
      )}
      {creditPullLoading && (
        <div className={classes.wrapperLoader}>
          <CircularProgress className="progressLoader" size={48} />
          <div className="headerContainer">
            <CloudDownload />
            <h4>Pulling credit report...</h4>
          </div>
        </div>
      )}
      {creditPullError && (
        <div className={classes.noResults}>
          <div className="headerContainer">
            <ErrorOutline />
            <h4>Unable to Download Report</h4>
          </div>
          <p style={{ textAlign: "center" }}>Please contact us for support if the issue persists.</p>
        </div>
      )}
    </>
  );
}
