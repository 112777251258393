import { LenderWaterfallPrescreenResult } from "@trnsact/trnsact-shared-types";

export const prepareDataForMobile = (waterfallResults: Record<string, LenderWaterfallPrescreenResult[]>) =>
  Object.entries(waterfallResults).reduce<LenderWaterfallPrescreenResult[]>((acc, [step, entitiesArray]) => {
    if (!Array.isArray(entitiesArray)) return acc;

    entitiesArray.forEach(entity => {
      acc.push({ ...entity, step: Number(step) });
    });

    return acc;
  }, []);
