import { SET_DOCS_REFETCH } from "./types";

const initialState = { refetch: false };

export const documents = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCS_REFETCH:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
