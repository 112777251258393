import { createContext } from "react";
import { ContentViewType } from "../../global";
import { ContentViewTypeContext, ContentViewTypeContextValue } from "./types";

export const viewTypeContextInitialValues: ContentViewTypeContextValue = {
  isMobile: false,
  isDesktop: true,
  contentViewType: ContentViewType.Desktop,
};

export const viewTypeContext = createContext<ContentViewTypeContext>({
  ...viewTypeContextInitialValues,
  onChaneContentViewType: nextContentViewType => {},
});
