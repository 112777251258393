import React, { ReactNode, useMemo } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ValueOf } from "../../../global";
import { BaseEntity, EntityMobileCardsViewType, CommonPropsForAllViews } from "./types";
import { EntityMobileCardsGroupedView } from "./EntityMobileCardsGroupedView";
import { EntityMobileCardsNoGroupedView } from "./EntityMobileCardsNoGroupedView";

interface Props<Entity, CardEntity extends BaseEntity<Entity>> extends CommonPropsForAllViews<Entity, CardEntity> {
  entities: Entity[];
  groupBy?: ValueOf<CardEntity, "group">;
  entityAdapter: (entity: Entity) => CardEntity;
}

export const EntityMobileCards = <Entity, CardEntity extends BaseEntity<Entity>>({
  entities,
  groupBy,
  entityAdapter,
  ...other
}: Props<Entity, CardEntity>) => {
  const classes = useStyles();

  const currentViewType = useMemo(
    () => (groupBy ? EntityMobileCardsViewType.Grouped : EntityMobileCardsViewType.NoGrouped),
    [groupBy]
  );

  const entitiesForRender = useMemo(() => entities?.map(entityAdapter), [entities, entityAdapter]);

  const renderCardsByViewType: Record<EntityMobileCardsViewType, ReactNode> = {
    [EntityMobileCardsViewType.Grouped]: (
      <EntityMobileCardsGroupedView entitiesForRender={entitiesForRender} groupBy={groupBy} {...other} />
    ),
    [EntityMobileCardsViewType.NoGrouped]: (
      <EntityMobileCardsNoGroupedView entitiesForRender={entitiesForRender} {...other} />
    ),
  };

  return <Box className={classes.container}>{renderCardsByViewType[currentViewType]}</Box>;
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
});
