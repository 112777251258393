import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MessageModal from "../../components/MessageModal";
import TextField from "@material-ui/core/TextField";
import { useLazyQuery } from "@apollo/react-hooks";
import { calculateRatesAndPayments } from "../../paymentSelectorMath";
import TableHead from "@material-ui/core/TableHead";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { formatCurrency } from "../../utils";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import FormLabel from "@material-ui/core/FormLabel";
import TableContainer from "@material-ui/core/TableContainer";
import mixpanel from "../../mixpanel";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
  submitButton: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: 360,
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControlLenders: {
    margin: theme.spacing(1),
    minWidth: 300,
    maxWidth: 700,
  },
  formControlInputs: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  formControlRate: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 200,
  },
}));

const useRadioStyles = makeStyles(customCheckboxRadioSwitch);

const GENERATE_QUOTE = gql`
  query(
    $id: ID
    $tier: String
    $amount: Float
    $creditProducts: String
    $lenderProgramIds: [String]
    $hours: Int
    $equipmentType: String
  ) {
    quotePaymentCards(
      id: $id
      tier: $tier
      amount: $amount
      creditProducts: $creditProducts
      hours: $hours
      equipmentType: $equipmentType
      lenderProgramIds: $lenderProgramIds
    ) {
      rateFactor
      markupDealer
      markupInternal
      term
      productHeader
      advancePayment
      docFee
    }
  }
`;
const SEND_QUOTE_APP_LINK = gql`
  mutation($input: SendQuoteAppLinkInput!) {
    sendQuoteAppLink(input: $input) {
      success
    }
  }
`;
const CREATE_LEAD = gql`
  mutation($input: CreateLeadInput!) {
    createLead(input: $input) {
      id
    }
  }
`;
const SEND_FEATURE_REQUEST = gql`
  mutation($input: sendFeatureRequestInput!) {
    sendFeatureRequest(input: $input) {
      success
    }
  }
`;

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ".", /\d/, /\d/, "%"]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      // format={'##.##'}
      decimalScale={2}
      fixedDecimalScale={true}
      allowLeadingZeros={true}
    />
  );
}

function NumberFormatHours(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      // format={'##.##'}
      decimalScale={0}
      fixedDecimalScale={true}
      allowLeadingZeros={false}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CreateQuote = ({ userProfile, account, vp }) => {
  const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const classes = useStyles();
  const classesRadio = useRadioStyles();
  const [email, setEmail] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [amount, setAmount] = useState(null);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [amountError, setAmountError] = useState(false);
  const [amountErrorMessage, setAmountErrorMessage] = useState("Required");
  const [emailError, setEmailError] = useState(false);
  const [applicantNameError, setApplicantNameError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("Required");
  const [renderOptions, setRenderOptions] = useState(false);
  const tier = "A";
  const [quoteGenerated, setQuoteGenerated] = useState(false);
  const [generateQuote, { data }] = useLazyQuery(GENERATE_QUOTE);
  const [requestSent, setRequestSent] = useState(false);
  const [rate, setRate] = useState("");
  const [hours, setHours] = useState("");
  const [equipmentType, setEquipmentType] = useState("");
  const [selectedOption, setSelectedOption] = useState("select");
  const [hoursInputRequired, setHoursInputRequired] = useState(false);
  const [equipmentTypeInputRequired, setEquipmentTypeInputRequired] = useState(false);
  const [options, setOptions] = useState([
    { term: 12, productHeader: "Option 1", monthlyPayments: "0" },
    { term: 24, productHeader: "Option 2", monthlyPayments: "0" },
    { term: 36, productHeader: "Option 3", monthlyPayments: "0" },
    { term: 48, productHeader: "Option 4", monthlyPayments: "0" },
    { term: 60, productHeader: "Option 5", monthlyPayments: "0" },
  ]);
  const [creditProducts, setCreditProducts] = useState(
    !!vp.creditProducts && vp.creditProducts.length > 0 ? [vp.creditProducts] : null
  );
  const [selectedCreditProducts, setSelectedCreditProducts] = useState([]);
  const [isAbleGenerateQuote, setIsAbleGenerateQuote] = useState(false);
  const [showRate, setShowRate] = useState("rate");
  const [businessName, setBusinessName] = useState("");
  const handleChangedEmail = event => setEmail(event.target.value);
  const handleChangedBusinessName = event => setBusinessName(event.target.value);
  // const handleAmount = event => setAmount(event.target.value);
  const handleChangedApplicantName = event => setApplicantName(event.target.value);
  const handleSubmit = event => {
    event.preventDefault();
    validateAmount(amount);
  };
  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);

  useEffect(() => {
    let hoursRequired = false;
    let equipmentTypeRequired = false;
    selectedCreditProducts.forEach(scp => {
      console.log(JSON.stringify(scp));
      if (scp.inputRequirements && scp.inputRequirements.includes("hours")) {
        hoursRequired = true;
      }
      if (scp.inputRequirements && scp.inputRequirements.includes("equipmentType")) {
        equipmentTypeRequired = true;
      }
    });
    setHoursInputRequired(hoursRequired);
    setEquipmentTypeInputRequired(equipmentTypeRequired);
  });

  useEffect(() => {
    if (!!vp && !!vp.creditProducts && Object.keys(vp).length !== 0 && !!vp.creditProducts.length > 0) {
      const creditProds = vp.creditProducts;
      setCreditProducts(creditProds);
    }
  }, [vp]);

  const [sendQuoteAppLink] = useMutation(SEND_QUOTE_APP_LINK, {
    context: { authRequired: true },
  });
  const [createLead] = useMutation(CREATE_LEAD, {
    context: { authRequired: true },
  });
  const [sendFeatureRequest] = useMutation(SEND_FEATURE_REQUEST, {
    context: { authRequired: true },
  });

  const validateAmount = amountRequested => {
    if (!amountRequested) {
      setAmountError(true);
      setAmountErrorMessage("Please enter an amount greater than $5,000.00");
    }
    if (parseFloat(amountRequested) < 4999) {
      setAmountError(true);
      setAmountErrorMessage("Please enter an amount greater than $5,000.00");
    } else {
      setAmountError(false);
      setAmountErrorMessage("");
      if (selectedOption === "rate" && vp.showPqt === "options") {
        calculateOptions();
      } else {
        setQuoteGenerated(true);
        if (vp.showPqt !== "options" || (vp.showPqt === "options" && !creditProducts)) {
          generateQuote({ variables: { id: account.id, tier: tier, amount: parseFloat(amountRequested) } });
        } else {
          const lenderProgramIds = selectedCreditProducts.map(scp => scp.lenderProgramId);
          const reduceCreditProducts = selectedCreditProducts.reduce(
            (acc, curr) => (acc.push(curr.productId), acc),
            []
          );
          const creditProductIds = reduceCreditProducts.join(",");
          console.log(`CREDIT PRODUCT IDS: ${JSON.stringify(creditProductIds)}`);
          console.log(`lenderProgramIds: ${JSON.stringify(lenderProgramIds)}`);
          console.log("Generating quote!");
          generateQuote({
            variables: {
              id: account.id,
              tier: tier,
              amount: parseFloat(amountRequested),
              creditProducts: creditProductIds.toString(),
              hours: parseInt(hours, 10),
              equipmentType: equipmentType,
              lenderProgramIds: lenderProgramIds,
            },
          });
        }
      }
    }
  };
  const validate = (email, name) => {
    if (!email && !name) {
      setEmailError(true);
      setApplicantNameError(true);
      return false;
    }
    if (!email) {
      setEmailError(true);
      return false;
    }
    if (!name) {
      setApplicantNameError(true);
      return false;
    } else {
      setApplicantNameError(false);
    }
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setEmailError(false);
      setEmailErrorMessage("");
      return true;
    } else {
      setEmailError(true);
      setEmailErrorMessage("Please ensure email has been entered correctly");
      return false;
    }
  };

  const sendLink = async () => {
    if (validate(email, applicantName)) {
      try {
        handleOpenModal();
        handleSubmissionMessage({ title: "Sending", message: "Please wait. Sending the quote..." });
        await sendQuoteAppLink({
          variables: {
            input: {
              email: email,
              fullName: applicantName,
              dynamicsContactId: userProfile.dynamicsContactId,
              amount: parseFloat(amount),
              options: rate && selectedOption === "rate" ? options : data.quotePaymentCards,
              sendWith: rate && selectedOption === "rate" ? showRate : null,
              businessName: businessName,
            },
          },
        });

        mixpanel.track("Quote Sent");

        handleSubmissionMessage({ title: "Success", message: "Sent Successfully" });
      } catch (err) {
        console.log(err);
        handleSubmissionMessage({ title: "Error", message: "There was something wrong with your request" });
      }
    }
  };
  const requestFeature = async () => {
    try {
      handleOpenModal();
      handleSubmissionMessage({ title: "Sending", message: "Please wait. Sending your feature request..." });
      await sendFeatureRequest({
        variables: {
          input: {
            feature: "edit markup on PQT",
            dynamicsContactId: userProfile.dynamicsContactId,
            accountName: account.name,
          },
        },
      });
      handleSubmissionMessage({ title: "Success", message: "Sent Successfully" });
      setRequestSent(true);
    } catch (err) {
      console.log(err);
      handleSubmissionMessage({ title: "Error", message: "There was something wrong with your request" });
    }
  };
  const calculateMonthlyOptions = async obj => {
    if (!!obj && obj.length) {
      obj.forEach((item, i) => {
        item.amountRequested = parseFloat(amount);
        item.monthlyPayments = item.amountRequested * item.rateFactor;
        if (typeof item.docFee !== "number") {
          item.docFee = parseFloat(item.docFee.replace(/[^0-9.-]+/g, ""));
        }
        item.mpWithPoints = item.monthlyPayments * (1 + (item.markupInternal / 100 + item.markupDealer / 100));
        item.monthlyPayments = item.mpWithPoints;
        item.monthlyPayments = item.monthlyPayments.toFixed(2);
        const rateSandPay = calculateRatesAndPayments(
          item.amountRequested,
          item.term,
          item.rate,
          item.markupInternal / 100 + item.markupDealer / 100
        );
        item.interestRate = (rateSandPay.simpleInterest * 100).toFixed(2);
        if (typeof item.advancePayment !== "number") {
          item.advancePayment = parseFloat(item.advancePayment.replace(/[^0-9.-]+/g, ""));
        }
        if (item.advancePayment > 0) {
          item.amountDue = item.advancePayment * parseFloat(item.monthlyPayments) + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        } else {
          item.amountDue = item.monthlyPayments + item.docFee;
          item.amountDue = (Math.round(item.amountDue * 100) / 100).toFixed(2);
        }
        item.monthlyPayments = numberWithCommas(item.monthlyPayments);
        item.emailHeader = "Option " + parseInt(i + 1);
        delete item.interestRate;
        delete item.credittProduct;
        delete item.mpWithPoints;
        delete item.__typename;
      });
      setTimeout(() => setRenderOptions(true), 500);
    }
  };

  const calculateOptions = async () => {
    const parsedFloat = parseFloat(rate);
    var optionsState = [...options];
    const option1rateSandPay = calculateRatesAndPayments(amount, 12, parsedFloat * 0.01, 0);
    const option1Interest = parseFloat((option1rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[0].rate = parsedFloat;
    optionsState[0].simpleInterest = option1Interest;
    optionsState[0].monthlyPayments = option1rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[0].emailHeader = "Option 1";

    const option2rateSandPay = calculateRatesAndPayments(amount, 24, parsedFloat * 0.01, 0);
    const option2Interest = parseFloat((option2rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[1].rate = parsedFloat;
    optionsState[1].simpleInterest = option2Interest;
    optionsState[1].monthlyPayments = option2rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[1].emailHeader = "Option 2";

    const option3rateSandPay = calculateRatesAndPayments(amount, 36, parsedFloat * 0.01, 0);
    const option3Interest = parseFloat((option3rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[2].rate = parsedFloat;
    optionsState[2].simpleInterest = option3Interest;
    optionsState[2].monthlyPayments = option3rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[2].emailHeader = "Option 3";

    const option4rateSandPay = calculateRatesAndPayments(amount, 48, parsedFloat * 0.01, 0);
    const option4Interest = parseFloat((option4rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[3].rate = parsedFloat;
    optionsState[3].simpleInterest = option4Interest;
    optionsState[3].monthlyPayments = option4rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[3].emailHeader = "Option 4";

    const option5rateSandPay = calculateRatesAndPayments(amount, 60, parsedFloat * 0.01, 0);
    const option5Interest = parseFloat((option5rateSandPay.simpleInterest * 100).toFixed(2));
    optionsState[4].rate = parsedFloat;
    optionsState[4].simpleInterest = option5Interest;
    optionsState[4].monthlyPayments = option5rateSandPay.buyRatePayment.toFixed(2).toString();
    optionsState[4].emailHeader = "Option 5";

    setOptions(optionsState);
    setRenderOptions(true);
  };

  if (data && data.quotePaymentCards) calculateMonthlyOptions(data.quotePaymentCards);

  const resetState = value => {
    if (data && data.quotePaymentCards) {
      data.quotePaymentCards = null;
    }
    setRenderOptions(false);
    setRate("");
    setOptions([
      { term: 12, productHeader: "Option 1", monthlyPayments: "0" },
      { term: 24, productHeader: "Option 2", monthlyPayments: "0" },
      { term: 36, productHeader: "Option 3", monthlyPayments: "0" },
      { term: 48, productHeader: "Option 4", monthlyPayments: "0" },
      { term: 60, productHeader: "Option 5", monthlyPayments: "0" },
    ]);
    setSelectedCreditProducts([]);
    setSelectedOption(value);
    setQuoteGenerated(false);
  };

  const handleSelectedProductChangeReset = e => {
    setSelectedCreditProducts(e.target.value);
    if (quoteGenerated && renderOptions) {
      setQuoteGenerated(false);
      setRenderOptions(false);
    }
    if (data && data.quotePaymentCards) {
      data.quotePaymentCards = null;
    }
  };

  const handleRateChange = e => {
    setRate(e.target.value);
  };

  const handleHoursChange = e => {
    setHours(e.target.value);
  };

  const handleEquipmentTypeChange = e => {
    setEquipmentType(e.target.value);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <CardHeader title="Inputs" />
            <Divider />
            <CardContent>
              <Grid container direction="column">
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                    <CurrencyTextField
                      label="Financed Amount"
                      value={amount}
                      onChange={(event, value) => setAmount(value)}
                      required={true}
                      currencySymbol="$"
                      error={amountError === true}
                      helperText={amountErrorMessage}
                    />
                  </FormControl>
                  {hoursInputRequired && (
                    <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                      <TextField
                        label="Hours"
                        value={hours}
                        name="hours"
                        onChange={e => handleHoursChange(e)}
                        id="hours"
                        InputProps={{
                          inputComponent: NumberFormatHours,
                          // endAdornment: <InputAdornment position="end"></InputAdornment>,
                        }}
                        required={true}
                      />
                    </FormControl>
                  )}
                  {equipmentTypeInputRequired && (
                    <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                        Equipment Type
                      </InputLabel>
                      <Select
                        label="Equipment Type"
                        // classes={{ select: uiClasses.select }}
                        value={equipmentType}
                        onChange={e => handleEquipmentTypeChange(e)}
                        inputProps={{
                          name: "equipmentType",
                          id: "equipmentType",
                        }}
                        required={true}
                      >
                        <MenuItem value={"Backhoes"}>Backhoes</MenuItem>
                        <MenuItem value={"Compact Wheel Loader"}>Compact Wheel Loader</MenuItem>
                        <MenuItem value={"Dozers"}>Dozers</MenuItem>
                        <MenuItem value={"Excavators"}>Excavators</MenuItem>
                        <MenuItem value={"Mini Excavators"}>Mini Excavators</MenuItem>
                        <MenuItem value={"Wheel Loaders"}>Wheel Loaders</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                {vp.showPqt === "options" && (
                  <Grid item xs={12} md={3} lg={3}>
                    <FormControl className={classes.formControlLenders}>
                      <RadioGroup row>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedOption === "rate"}
                              onChange={() => resetState("rate")}
                              value="rate"
                              name="radio button enabled"
                              aria-label="rate"
                              icon={<FiberManualRecord className={classesRadio.radioUnchecked} />}
                              checkedIcon={<FiberManualRecord className={classesRadio.radioChecked} />}
                              classes={{
                                checked: classesRadio.radio,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                          }}
                          label="Rate"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedOption === "select"}
                              onChange={() => resetState("select")}
                              value="select"
                              name="radio button enabled"
                              aria-label="Select"
                              icon={<FiberManualRecord className={classesRadio.radioUnchecked} />}
                              checkedIcon={<FiberManualRecord className={classesRadio.radioChecked} />}
                              classes={{
                                checked: classesRadio.radio,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                          }}
                          label="Select"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  {vp.showPqt === "options" && selectedOption === "rate" && (
                    <FormControl className={classes.formControlInputs} style={{ width: "150px" }}>
                      <TextField
                        label="Rate"
                        value={rate}
                        name="rate"
                        onChange={e => handleRateChange(e)}
                        id="rate"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  )}
                  {vp.showPqt === "options" && selectedOption === "select" && (
                    <FormControl className={classes.formControlLenders} fullWidth>
                      <Autocomplete
                        multiple
                        value={selectedCreditProducts}
                        size="small"
                        options={creditProducts}
                        onChange={(event, newValue) => {
                          setSelectedCreditProducts(newValue);
                        }}
                        getOptionLabel={option => option.productHeader}
                        renderInput={params => (
                          <TextField {...params} variant="standard" label="Credit Products" placeholder="" />
                        )}
                      />
                    </FormControl>
                  )}
                  {(vp.showPqt == "default" || !vp.showPqt) && (
                    <Button onClick={requestFeature} disabled={requestSent}>
                      Request Update Markup Feature
                    </Button>
                  )}
                </Grid>
              </Grid>
              <br />
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <Button
                    color="primary"
                    className={classes.submitButton}
                    onClick={handleSubmit}
                    type="submit"
                    disabled={
                      (vp.showPqt === "options" && selectedOption === "rate" && (!rate || !amount)) ||
                      (vp.showPqt === "options" &&
                        selectedOption === "select" &&
                        (selectedCreditProducts.length === 0 ||
                          !amount ||
                          (hoursInputRequired && !hours) ||
                          (equipmentTypeInputRequired && !equipmentType)))
                    }
                  >
                    Generate Quote
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <Grid item xs={12}>
              {renderOptions && (
                <>
                  <CardHeader
                    title="Generated Quote"
                    action={
                      rate &&
                      selectedOption === "rate" && (
                        <FormControl className={classes.formControlLenders}>
                          <RadioGroup row>
                            <FormLabel labelplacement="start" style={{ marginTop: "13px" }}>
                              Display
                            </FormLabel>
                            <FormControlLabel
                              control={
                                <Radio
                                  size="small"
                                  checked={showRate === "rate"}
                                  onChange={() => setShowRate("rate")}
                                  value="showRate"
                                  name="radio button enabled"
                                  aria-label="rate"
                                  icon={<FiberManualRecord className={classesRadio.radioUnchecked} />}
                                  checkedIcon={<FiberManualRecord className={classesRadio.radioChecked} />}
                                  classes={{
                                    checked: classesRadio.radio,
                                    root: classesRadio.radioRoot,
                                  }}
                                />
                              }
                              classes={{
                                label: classesRadio.label,
                              }}
                              label="APR"
                            />
                            <FormControlLabel
                              control={
                                <Radio
                                  size="small"
                                  checked={showRate === "interest"}
                                  onChange={() => setShowRate("interest")}
                                  value="showRate"
                                  name="radio button enabled"
                                  aria-label="Select"
                                  icon={<FiberManualRecord className={classesRadio.radioUnchecked} />}
                                  checkedIcon={<FiberManualRecord className={classesRadio.radioChecked} />}
                                  classes={{
                                    checked: classesRadio.radio,
                                    root: classesRadio.radioRoot,
                                  }}
                                />
                              }
                              classes={{
                                label: classesRadio.label,
                              }}
                              label="Annual Rate"
                            />
                          </RadioGroup>
                        </FormControl>
                      )
                    }
                  />
                  <Divider />
                  <CardContent style={{ padding: 0 }}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow className={classes.row}>
                            {rate && !data ? <TableCell>Credit Product</TableCell> : <TableCell />}
                            <TableCell>Term </TableCell>
                            <TableCell>Monthly Payment</TableCell>
                            {rate ? (
                              <TableCell>{showRate === "rate" ? "APR" : "Annual Rate"}</TableCell>
                            ) : (
                              <TableCell>Amount Due</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {renderOptions &&
                            rate &&
                            options.map(({ term, monthlyPayments, productHeader, simpleInterest, rate }, i) => (
                              <TableRow key={i}>
                                <TableCell>{productHeader}</TableCell>
                                <TableCell>{term} Months</TableCell>
                                <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                <TableCell>{showRate === "rate" ? rate + "%" : simpleInterest + "%"}</TableCell>
                              </TableRow>
                            ))}
                          {!rate &&
                            data.quotePaymentCards.map(({ term, monthlyPayments, productHeader, amountDue }, i) => (
                              <>
                                {(i === 0 || term < data.quotePaymentCards[i - 1].term) && (
                                  <TableRow key={i}>
                                    <TableCell colSpan={3} align="left">
                                      Credit Product {productHeader}
                                    </TableCell>
                                  </TableRow>
                                )}
                                <TableRow key={i}>
                                  <TableCell></TableCell>
                                  <TableCell>{term} Months</TableCell>
                                  <TableCell>{formatCurrency(monthlyPayments)}</TableCell>
                                  <TableCell>{formatCurrency(amountDue)}</TableCell>
                                </TableRow>
                              </>
                            ))}
                          <Divider />
                          <CardHeader title="Send Quote" />
                          <TableRow>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="Full Name"
                                  value={applicantName}
                                  onChange={handleChangedApplicantName}
                                  required={true}
                                  helperText="Required"
                                  error={applicantNameError === true}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="email"
                                  value={email}
                                  onChange={handleChangedEmail}
                                  required={true}
                                  error={emailError === true}
                                  helperText={emailErrorMessage}
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl>
                                <TextField
                                  label="Business Name"
                                  value={businessName}
                                  onChange={handleChangedBusinessName}
                                  helperText="Optional"
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <Button color="primary" onClick={sendLink}>
                                Send Quote
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>

        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseModal}
          title={submissionMessage.title}
          message={submissionMessage.message}
        />
      </Grid>
    </>
  );
};

const mapStateToProps = state => ({
  userProfile: state.userProfile,
  account: state.account,
  vp: state.vp,
});

export default connect(
  mapStateToProps,
  null
)(CreateQuote);
