import React from "react";
import { Column } from "react-table";
import { Typography } from "@material-ui/core";
import { ActionsCell, StageCell } from "./cells";
import { TableCell } from "../../../Table/TableCell";
import moment from "moment/moment";

interface Options {
  userProfile: any;
  resubmitCreditApp: any;
  currencyFormatter: any;
  handleUpdateCredentials: any;
  handleOpenLenderDetailModalView: any;
}

export function createTableColumns(options: Options): Array<Column<any>> {
  const {
    userProfile,
    resubmitCreditApp,
    handleUpdateCredentials,
    currencyFormatter,
    handleOpenLenderDetailModalView,
  } = options;

  return [
    {
      Header: "Submission Date",
      accessor: "submissionDate",
      Cell: ({ value }: any) => (
        <TableCell>
          <Typography variant="body2">{value ? moment(value).format("ll") : ""}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Lender",
      accessor: "lenderName",
      Cell: ({ value }: any) => (
        <TableCell>
          <Typography variant="body2">{value}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Stage",
      accessor: "creditSubStage",
      Cell: (props: any) => (
        <StageCell row={props.original} value={props.value} handleUpdateCredentials={handleUpdateCredentials} />
      ),
    },
    {
      Header: "Decision",
      accessor: "decision",
      Cell: ({ value }: any) => (
        <TableCell>
          <Typography variant="body2">{value}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Requested Amount",
      accessor: "amountRequested",
      Cell: (props: any) => (
        <TableCell>
          <Typography variant="body2">{props.value ? currencyFormatter.format(props.value) : ""}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Approval Amount",
      accessor: "approvedAmount",
      Cell: (props: any) => (
        <TableCell>
          <Typography variant="body2">{props.value ? currencyFormatter.format(props.value) : "-"}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Sydnication From",
      accessor: "masterLenderName",
      Cell: ({ value }: any) => (
        <TableCell>
          <Typography variant="body2">{value}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Syndicated By",
      accessor: "syndicatedBy.fullName",
      Cell: ({ value }: any) => (
        <TableCell>
          <Typography variant="body2">{value}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Display Status",
      accessor: "displayText",
      Cell: ({ value }: any) => (
        <TableCell>
          <Typography variant="body2">{value}</Typography>
        </TableCell>
      ),
    },
    {
      Header: "Action",
      sortable: false,
      Cell: (props: any) => (
        <ActionsCell
          row={props.original}
          userProfile={userProfile}
          resubmitCreditApp={resubmitCreditApp}
          handleOpenLenderDetailModalView={handleOpenLenderDetailModalView}
        />
      ),
    },
  ].map(header => ({ ...header, Filter: undefined, headerStyle: { fontWeight: "500", padding: "0.5rem" } }));
}
