import {
  SET_ACCOUNT,
  SET_ACCOUNT_REP,
  SET_COMPANY_PROFILE,
  SET_CREDIT_APP,
  SET_CREDIT_SUBMISSIONS,
  SET_LP,
  SET_REFETCH_ACCOUNT,
  SET_REFETCH_COMPANY_PROFILE,
  SET_REFETCH_CREDIT_APP,
  SET_REFETCH_CREDIT_SUBMISSIONS,
  SET_REFETCH_FEED,
  SET_REFETCH_PAST_SUBMISSIONS,
  SET_REFETCH_RELATED_USERS,
  SET_REFETCH_USER_PROFILE,
  SET_REFETCH_VO,
  SET_REFETCH_VP,
  SET_RELATED_USERS,
  SET_USER_PROFILE,
  SET_VO,
  SET_VP,
} from "./types";

function setValue(key, value) {
  switch (key) {
    case "account":
      return {
        type: SET_ACCOUNT,
        payload: value,
      };
    case "accountRep":
      return {
        type: SET_ACCOUNT_REP,
        payload: value,
      };
    case "userProfile":
      return {
        type: SET_USER_PROFILE,
        payload: value,
      };
    case "companyProfile":
      return {
        type: SET_COMPANY_PROFILE,
        payload: value,
      };
    case "vo":
      return {
        type: SET_VO,
        payload: value,
      };
    case "vp":
      return {
        type: SET_VP,
        payload: value,
      };
    case "lp":
      return {
        type: SET_LP,
        payload: value,
      };
    case "creditApp":
      return {
        type: SET_CREDIT_APP,
        payload: value,
      };
    case "relatedUsers":
      return {
        type: SET_RELATED_USERS,
        payload: value,
      };
    case "creditSubmissions":
      return {
        type: SET_CREDIT_SUBMISSIONS,
        payload: value,
      };
    default:
      break;
  }
}

function setRefetch(key, value) {
  switch (key) {
    case "account":
      return {
        type: SET_REFETCH_ACCOUNT,
        payload: value,
      };
    case "userProfile":
      return {
        type: SET_REFETCH_USER_PROFILE,
        payload: value,
      };
    case "companyProfile":
      return {
        type: SET_REFETCH_COMPANY_PROFILE,
        payload: value,
      };
    case "vo":
      return {
        type: SET_REFETCH_VO,
        payload: value,
      };
    case "vp":
      return {
        type: SET_REFETCH_VP,
        payload: value,
      };
    case "creditApp":
      return {
        type: SET_REFETCH_CREDIT_APP,
        payload: value,
      };
    case "pastSubmissions":
      return {
        type: SET_REFETCH_PAST_SUBMISSIONS,
        payload: value,
      };
    case "relatedUsers":
      return {
        type: SET_REFETCH_RELATED_USERS,
        payload: value,
      };
    case "feed":
      return {
        type: SET_REFETCH_FEED,
        payload: value,
      };
    case "creditSubmissions":
      return {
        type: SET_REFETCH_CREDIT_SUBMISSIONS,
        payload: value,
      };
    default:
      break;
  }
}

function setLoading(key, value) {}

export { setValue, setRefetch, setLoading };
