import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "assets/img/send.png";
import EyeIcon from "assets/img/eye.png";
import "./fast-track-modal.css";
import { useLazyQuery } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";
import fastTrackLogoAFG from "assets/img/fast-track-logo-AFG.png";
import fastTrackLogoUmpqua from "assets/img/fast-track-logo-Umpqua.png";
import fastTrackLogoClickLease from "assets/img/fast-track-logo-ClickLease.png";
import fastTrackLogoCit from "assets/img/fast-track-logo-CIT.png";
import bigRocket from "assets/img/big-rocket.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  withStyles,
} from "@material-ui/core";
import { CheckCircle, Close, Send } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { SETUP_FAST_TRACK_MODAL } from "../../redux/types";
import { useDispatch } from "react-redux";
import rocketFastTrack from "assets/img/big-rocket.svg";
import gql from "graphql-tag";
import _get from "lodash/get";
import { FAST_TRACK_STATUS, formatMoney } from "../../utils";
import _ from "lodash";
import { theme } from "../../theme";

const fastTrackStatuses = {
  AWAITING_LENDER_APPROVAL: "Awaiting Lender Approval",
  AWAITING_DEALER_APPROVAL: "Awaiting Dealer Approval",
  PROPOSAL_SENT: "Proposal Sent",
  PROPOSAL_VIEWED: "Proposal Viewed",
  PROPOSAL_ACCEPTED: "Proposal Accepted",
  LENDER_DECLINED: "Lender Declined",
  CANCELED: "Canceled",
};

const GET_ACCOUNT = gql`
  query($userProfileId: ID) {
    account(userProfileId: $userProfileId) {
      id
      name
      vendorProfileId
      dynamicsAccountId
      primaryContactId
      primaryContactName
      primaryContactPhone
      primaryContactEmail
      accountManagerDynamicsId
      address
      phone
      accountType
      contacts {
        id
        email
        firstName
        lastName
        fullName
        title
        accountId
        dynamicsContactId
      }
    }
    userProfile {
      id
      firstName
      lastName
      fullName
      mugshot
      phoneNumber
      vendorContactRole
      email
      dynamicsContactId
      mobilePhoneNumber
      portalType
      title
    }
    vendorProfile {
      id
      name
      dynamicsId
      logo
      showPrequal
      showPortalCreditApp
      showDocMgt
      showLenderDocsAccessMgmt
      showCreditApp
      showPsOptions
      showMarginTool
      showLenderSubmission
      industryGroupType
      role
      showChat
      manualVendorOpportunityCreationAccess
      showTasks
      showRouteOneApp
      showPqt
      creditSubmissionDisplay
      altLanguageOca
      showProposal
      showAftermarketOptions
      docusignDocMgmt
      defaultOcaContact
      multiLocationManagement
      dlRequired
      dlUploadRequired
      ocaForm
      ocaDocUpload
      amountRequested
      ownershipRequirement
      industryGroupType
      fastTrackSetting
      markupDealer
      ocaDefaultContactInfo {
        id
        email
        dynamicsContactId
        firstName
        lastName
        fullName
        title
        phoneNumber
        mobilePhoneNumber
      }
      creditProducts {
        productId
        productHeader
        productName
      }
      lenderProfiles {
        lenderName
        showCustomApp
        draftApplicationsEnabled
        pdfTemplate
        submissionMethod
        lenderProfileId
        lenderAccountId
        lenderContactId
        lenderChannelCode
        lenderTypeCode
        dealerRelationship
        lenderUserProfile {
          id
          email
          dynamicsContactId
          firstName
          lastName
          fullName
          phoneNumber
          mobilePhoneNumber
          mugshot
          title
          accountId
        }
        lenderAccount {
          id
          dynamicsAccountId
          vendorProfileId
          primaryContactId
          name
          address
          phone
          primaryContactName
          primaryContactPhone
          primaryContactEmail
        }
      }
    }
    accountRep {
      fullName
      email
      phoneNumber
      mugshot
    }
  }
`;

const GET_PROPOSALS = gql`
  query($userProfileId: ID, $accountId: ID, $voId: ID) {
    proposals(userProfileId: $userProfileId, accountId: $accountId, voId: $voId) {
      id
      proposalId
      proposalLink
      creditProductIds
    }
  }
`;

const UPDATE_VENDOR_PROFILE = gql`
  mutation($entityId: String!, $fieldsObj: UpdateVendorProfileInput) {
    updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;

const SEND_PROPOSAL_MUTATION = gql`
  mutation($input: SendProposalInput!) {
    sendProposal(input: $input) {
      success
      errorMessage
    }
  }
`;

const CREATE_PROPOSAL_MUTATION = gql`
  mutation($input: CreateProposalInput!) {
    createProposal(input: $input) {
      id
      vendorOpportunityId
      success
      errorMessage
    }
  }
`;

const GENERATE_QUOTE = gql`
  query(
    $id: ID
    $tier: String
    $amount: Float
    $creditProducts: String
    $lenderProgramIds: [String]
    $hours: Int
    $equipmentType: String
  ) {
    quotePaymentCards(
      id: $id
      tier: $tier
      amount: $amount
      creditProducts: $creditProducts
      hours: $hours
      equipmentType: $equipmentType
      lenderProgramIds: $lenderProgramIds
    ) {
      rateFactor
      markupDealer
      markupInternal
      term
      productHeader
      advancePayment
      docFee
      downPaymentAmount
      rate
      paymentCardId
    }
  }
`;

const UPDATE_VENDOR_OPPORTUNITY = gql`
  mutation($VOId: String!, $fieldsObj: UpdateVendorOpportunityInput!) {
    updateVendorOpportunity(VOId: $VOId, fieldsObj: $fieldsObj)
  }
`;

const CustomToolTip = withStyles({
  tooltip: {
    backgroundColor: "#373739",
    fontSize: "12px",
  },
})(Tooltip);

const CustomSwitch = withStyles({
  root: {
    right: "5px",
  },
  switchBase: {
    "&$checked": {
      color: "#0086fc",
    },
    "&$checked + $track": {
      backgroundColor: "#0086fc",
    },
  },
  checked: {},
  track: {},
})(Switch);

const styles = {
  textField: {
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectStyles: {
    height: 40,

    "& .MuiOutlinedInput-input": {
      padding: "8px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  },
  selectLabel: {
    transform: "translate(14px, 12px) scale(1)",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    background: "white",
    padding: "0 4px",
  },
};

const useStyle = makeStyles(styles);

const FastTrackModal = ({ open, currentVo, creditApp, globalVp, onClose }) => {
  const [vo, setVo] = useState({});
  const [vp, setVp] = useState({});
  const [markupValue, setMarkupValue] = useState(null);
  const [dollarMarkUpValue, setDollarMarkUpValue] = useState("");
  const [pointMarkUpValue, setPointMarkUpValue] = useState("");
  const [defaultValueTextMarkUp, setTextMarkUp] = useState("Points");
  const [riskTierClass, setRiskTierClass] = useState("deep-subprime");
  const [riskTier, setRiskTier] = useState("Deep subprime");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [approvedBy, setApprovedBy] = useState("Lender");
  const [markUpDealer, setMarkupDealer] = useState("");
  const [fastTrackSetting, setFastTrackSetting] = useState(null);
  const [automaticallySendProposal, setAutomaticallySendProposal] = useState(true);
  const [generatingProposal, setGeneratingProposal] = useState(false);

  const dispatch = useDispatch();

  const helpIconTooltip = `Subprime (buy rate greater than 18%)
  Near-prime (buy rate between 12.1% and 18.0%)
  Prime (buy rate between 7.0% and 12.0%)
  Super-prime (buy rate less than 7.0%)`;
  // const isOpened = useSelector(state => state.setupFastTrack.opened);
  // const currentVo = useSelector(state => state.setupFastTrack.currentVo);
  // const creditApp = useSelector(state => state.setupFastTrack.creditAppData);
  // const globalVp = useSelector(state => state.setupFastTrack.vp);

  const [requestedAmount, setRequestedAmount] = useState("");
  const [equipmentType, setEquipmentType] = useState("");
  const [equipmentHours, setEquipmentHours] = useState("");
  const [fieldsEmpty, setFieldsEmpty] = useState(false);

  const handleChangeDollarMarkUpValue = e => {
    setDollarMarkUpValue(e.target.value);
    if (defaultValueTextMarkUp === "Dollars") {
      setMarkupDealer(e.target.value);
    }
  };
  // if (value === 'Points') {
  //   setMarkupDealer(dollarMarkUpValue);
  // } else {
  //   setMarkupDealer(_get(accountData, 'vendorProfile.markupDealer', ''));
  // }
  // const handleChangePointMarkUpValue = e => {
  //   e.preventDefault();
  //   setPointMarkUpValue(e.target.value);
  //   if (defaultValueTextMarkUp === "Points") {
  //     setMarkupValue(e.target.value);
  //   }
  // };

  const [getAccountData, { data: accountData, refetch: accountDataRefetch }] = useLazyQuery(GET_ACCOUNT, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
  });

  const [getProposals, { data: proposalsData }] = useLazyQuery(GET_PROPOSALS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    context: { authRequired: true },
    onCompleted: () => {
      setGeneratingProposal(false);
    },
  });

  const [updateVendorProfile] = useMutation(UPDATE_VENDOR_PROFILE, {
    context: { authRequired: true },
  });

  const [updateVendorOpportunity] = useMutation(UPDATE_VENDOR_OPPORTUNITY, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (!applicationStatus) {
      return;
    }
    getProposals({
      variables: {
        userProfileId: accountData?.userProfile.id,
        accountId: accountData?.account?.id,
        voId: currentVo.vendorOpportunityId,
      },
    });
  }, [applicationStatus]);

  useEffect(() => {
    if (open) {
      setMarkupValue(pointMarkUpValue);
    }
  }, [open]);

  useEffect(() => {
    if (currentVo && open) {
      console.log("currentVO called");
      setVo(currentVo);
      setRequestedAmount(`${currentVo.invoiceTotal}`);

      getAccountData({
        variables: { userProfileId: _get(accountData, "userProfile.id", "") },
      });

      if (currentVo.fastTrackResults && currentVo.fastTrackResults.length) {
        let ftResults = currentVo.fastTrackResults[0];

        if (currentVo.fastTrackResults.length > 1) {
          ftResults = currentVo.fastTrackResults.find(ftr => {
            return !ftr.approvedBy.includes("Scorecard");
          });
        }
        setApplicationStatus(currentVo.fastTrackStatus);
        getRiskTierByRate(ftResults?.rate);
        console.log(`ftResults.approvedBy: ${ftResults.approvedBy}`);
        if (ftResults.approvedBy && !ftResults.approvedBy.includes("Scorecard")) {
          setApprovedBy(ftResults.approvedBy);
        } else {
          setApprovedBy(null);
        }
      } else {
        setApplicationStatus(null);
        setApprovedBy(null);
        setRiskTierClass("deep-subprime");
        setRiskTier("Deep subprime");
      }
    }
  }, [currentVo, open]);

  useEffect(() => {
    if (globalVp && open) {
      console.log("globalVp called");
      setVp(globalVp);
      setFastTrackSetting(globalVp.fastTrackSetting);
    }
  }, [globalVp, open]);

  useEffect(() => {
    if (accountData && open) {
      console.log("accountData called");
      setMarkupDealer(_get(accountData, "vendorProfile.markupDealer", ""));
    }
  }, [accountData, open]);

  const classes = useStyle();

  const handleClose = () => {
    onClose();
  };

  const handleChangeMarkUpText = event => {
    event.preventDefault();
    const value = document.querySelector(".default-markup-link").innerHTML;
    setTextMarkUp(value === "Points" ? "Dollars" : "Points");
    if (value === "Points") {
      // setMarkupDealer(currentVo.invoiceTotal * markUpDealer);
    } else {
      setMarkupDealer(_get(accountData, "vendorProfile.markupDealer", ""));
    }
  };

  const getRiskTierByRate = rate => {
    if (!rate) {
      setRiskTierClass("prime");
      setRiskTier("B");
      return;
    }

    if (rate < 6.9) {
      setRiskTierClass("super-subprime");
      setRiskTier("Super Subprime");
      return;
    }

    if (rate >= 7 && rate <= 12) {
      setRiskTierClass("prime");
      setRiskTier("Prime");
      return;
    }

    if (rate >= 12.1 && rate <= 18) {
      setRiskTierClass("near-subprime");
      setRiskTier("Near-prime");
      return;
    }

    if (rate > 18) {
      setRiskTierClass("subprime");
      setRiskTier("Subprime");
      return;
    }

    setRiskTierClass("deep-subprime");
    setRiskTier("Deep subprime");
    return;
  };

  function getFastTrackLogo() {
    let logo;
    switch (approvedBy) {
      case "AFG":
        logo = fastTrackLogoAFG;
        break;
      case "Umpqua Bank":
        logo = fastTrackLogoUmpqua;
        break;
      case "ClickLease":
        logo = fastTrackLogoClickLease;
        break;
      case "CIT":
        logo = fastTrackLogoCit;
        break;
      default:
        logo = fastTrackLogoAFG;
        break;
    }
    return logo;
  }

  async function updateFastTrackSetting(event) {
    const shouldAutomaticallySendProposals = event.target.checked;
    setFastTrackSetting(shouldAutomaticallySendProposals ? "YES_AUTOMATED_FOR_DEALER" : "YES_SET_CAMUNDA_PROCESS_CODE");
    await updateVendorProfile({
      variables: {
        entityId: vp.id,
        fieldsObj: { fastTrackEnabledSettingId: shouldAutomaticallySendProposals ? 200 : 100 },
      },
    });
    globalVp.fastTrackSetting = shouldAutomaticallySendProposals
      ? "YES_AUTOMATED_FOR_DEALER"
      : "YES_SET_CAMUNDA_PROCESS_CODE";
    setVp(globalVp);
  }

  async function updateFastTrackStatus(fastTrackStatus) {
    await updateVendorOpportunity({
      variables: {
        VOId: currentVo.vendorOpportunityId,
        fieldsObj: {
          fastTrackStatus,
        },
      },
    });
  }

  const [generateQuote, { data: generateQuoteData }] = useLazyQuery(GENERATE_QUOTE, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
    onCompleted: async () => {
      await createProposalMutation({
        variables: {
          input: {
            amountRequested: parseFloat(requestedAmount),
            // creditProductIds: creditProducts.map(cp => cp.creditProductId),
            riskTier: null,
            vendorOpportunityId: vo.vendorOpportunityId,
            accountId: accountData.account.id,
            vendorSalespersonId: accountData?.userProfile.id,
            fullName: creditApp.fullName,
            email: creditApp.email,
            businessName: creditApp.businessName,
            status: automaticallySendProposal ? "Proposal Sent" : "Awaiting Dealer Approval",
            type: "select",
            paymentCardIds: generateQuoteData.quotePaymentCards.map(qpc => qpc.paymentCardId),
          },
        },
      });
    },
    onError: error => {
      console.error(error);
    },
  });

  const [createProposalMutation, { data: createProposalData, loading: createProposalLoading }] = useMutation(
    CREATE_PROPOSAL_MUTATION,
    {
      context: { authRequired: true },
      onCompleted: async () => {
        // console.log(`createProposalData: ${JSON.stringify(createProposalData)}`);

        const newFastTrackResults = {
          fastTrackResults: currentVo.fastTrackResults,
          applicationStatus: automaticallySendProposal
            ? FAST_TRACK_STATUS.PROPOSAL_SENT
            : FAST_TRACK_STATUS.AWAITING_DEALER_APPROVAL,
        };

        dispatch({
          type: SETUP_FAST_TRACK_MODAL,
          payload: newFastTrackResults,
        });

        setApplicationStatus(
          automaticallySendProposal ? FAST_TRACK_STATUS.PROPOSAL_SENT : FAST_TRACK_STATUS.AWAITING_DEALER_APPROVAL
        );
        getProposals({
          variables: {
            userProfileId: accountData?.userProfile.id,
            accountId: accountData?.account?.id,
            voId: currentVo.vendorOpportunityId,
          },
        });
      },
      onError: error => {
        console.error(error);
      },
    }
  );

  const [sendProposal, { loading: sendProposalLoading }] = useMutation(SEND_PROPOSAL_MUTATION, {
    context: { authRequired: true },
    onCompleted: async () => {
      const newFastTrackResults = {
        fastTrackResults: currentVo.fastTrackResults,
        applicationStatus: FAST_TRACK_STATUS.PROPOSAL_SENT,
      };

      dispatch({
        type: SETUP_FAST_TRACK_MODAL,
        payload: newFastTrackResults,
      });

      setApplicationStatus(FAST_TRACK_STATUS.PROPOSAL_SENT);
    },
    onError: error => {
      console.error(error);
    },
  });

  const createProposal = async () => {
    const creditProducts = vp.creditProducts.filter(cp => {
      return cp.productId === "First Citizens Bank FMV Lease" || cp.productId === "First Citizens Bank Loan";
    });

    setGeneratingProposal(true);

    await generateQuote({
      variables: {
        id: accountData.account.id,
        amount: parseFloat(requestedAmount),
        // creditProducts: creditProducts.map(cp => cp.creditProductId).join(','),
        hours: parseInt(equipmentHours, 10),
        equipmentType: equipmentType,
        lenderProgramIds: creditProducts.map(cp => cp.lenderProgramId),
      },
    });
  };

  const handleUpdateApplicationStatus = async () => {
    updateFastTrackStatus("PROPOSAL_SENT");
    return await sendProposal({
      variables: {
        input: {
          id: proposalsData.proposals[0].id,
          accountId: accountData.account.id,
          vendorOpportunityId: currentVo.vendorOpportunityId,
          email: creditApp.email,
          phone: creditApp.phone,
          firstName: creditApp.firstName,
          lastName: creditApp.lastName,
        },
      },
    });
  };

  const setColor = fastTrackStatus => {
    if (fastTrackStatus === "PROPOSAL_ACCEPTED") {
      return "#54a954";
    } else if (fastTrackStatus === "CANCELED" || fastTrackStatus === "LENDER_DECLINED") {
      return "red";
    } else if (fastTrackStatus === "AWAITING_DEALER_APPROVAL") {
      return "rgb(131, 75, 255)";
    } else if (fastTrackStatus === "REQUIRES_DEALER_ATTENTION") {
      return "orange";
    } else {
      return "#0086fc";
    }
  };

  const renderProposalDataSection = () => {
    const renderWarningMessage = message => {
      return (
        <div className="default-markup">
          <Box sx={{ backgroundColor: "#fffcd4", p: 1 }}>
            <b>{message}</b>
          </Box>
        </div>
      );
    };
    // console.log(`proposalsData: ${JSON.stringify(proposalsData)}`);
    if (_.isEmpty(proposalsData)) {
      return null;
    }
    let warningMessages = {
      noProposals: "There are no proposals for this opportunity.",
      noCreditProducts:
        "A proposal could not be created for this opportunity because there are no associated Credit Products. Please contact your Account Manager if you have questions.",
    };
    // if (!_.get(proposalsData, "proposals")) {
    //   return renderWarningMessage(warningMessages.noProposals);
    // }
    // if (_.isEmpty(proposalsData.proposals[0].creditProductIds)) {
    //   return renderWarningMessage(warningMessages.noCreditProducts);
    // }
    if (applicationStatus === FAST_TRACK_STATUS.AWAITING_LENDER_APPROVAL) {
      return (
        <div className="fast-track-container" style={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
          <div
            className="fast-track-item-status"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <b>FAST TRACK STARTED</b>
          </div>
        </div>
      );
    }
    if (applicationStatus === FAST_TRACK_STATUS.LENDER_DECLINED) {
      return (
        <div className="fast-track-container" style={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
          <div
            className="fast-track-item-status"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <b>Lender Declined</b>
          </div>
        </div>
      );
    }
    if (applicationStatus === FAST_TRACK_STATUS.AWAITING_DEALER_APPROVAL && proposalsData.proposals) {
      return (
        <div className="preview-proposal">
          <div>
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<Send style={{ transform: "rotate(315deg)", transformOrigin: "center" }} />}
              disableElevation
              disabled={sendProposalLoading}
              onClick={() => handleUpdateApplicationStatus()}
            >
              Send Proposal
            </Button>
          </div>
          <span
            className="preview-proposal-text"
            onClick={() => window.open(proposalsData.proposals[0].proposalLink, "_blank").focus()}
          >
            Preview Proposal
          </span>
        </div>
      );
    }
    if (applicationStatus === FAST_TRACK_STATUS.AWAITING_DEALER_APPROVAL && !proposalsData.proposals) {
      return (
        <div>
          {!generatingProposal && (
            <div className="preview-proposal">
              <div>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<Send style={{ transform: "rotate(315deg)", transformOrigin: "center" }} />}
                  disableElevation
                  // disabled={sendProposalLoading}
                  // onClick={() => handleUpdateApplicationStatus()}
                  // onClick={createProposal}
                  onClick={e => {
                    e.stopPropagation();
                    if (!requestedAmount || !equipmentType || !equipmentHours) setFieldsEmpty(true);
                    else {
                      setFieldsEmpty(false);
                      createProposal();
                    }
                  }}
                >
                  Create Proposal
                </Button>
              </div>
              <div className="fast-track-remember-setting" style={{ margin: "16px 0 32px" }}>
                <div>
                  <CustomSwitch
                    onChange={e => {
                      setAutomaticallySendProposal(e.target.checked);
                    }}
                    checked={automaticallySendProposal}
                  />
                </div>
                <div className="automatically-send-proposals">Automatically send proposal to applicant?</div>
              </div>
            </div>
          )}
          {generatingProposal && (
            <div>
              Generating proposal <CircularProgress />
            </div>
          )}
        </div>
      );
    }
    if (applicationStatus === FAST_TRACK_STATUS.PROPOSAL_SENT) {
      return (
        <div className="fast-track-container" style={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
          <div
            className="fast-track-item-status"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <b>PROPOSAL SENT</b>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={SendIcon} alt="send-icon" className="fast-track-icon" />
              </div>
              <span
                className="view-proposal"
                onClick={() => window.open(proposalsData.proposals[0].proposalLink, "_blank").focus()}
              >
                View Customer Proposal
              </span>
            </div>
          </div>
        </div>
      );
    }
    if (applicationStatus === FAST_TRACK_STATUS.PROPOSAL_VIEWED) {
      return (
        <div className="fast-track-container" style={{ display: "flex", justifyContent: "center", margin: "24px 0" }}>
          <div
            className="fast-track-item-status"
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <b>PROPOSAL VIEWED</b>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={EyeIcon} alt="send-icon" className="fast-track-icon" />
              </div>
              <span
                className="view-proposal"
                onClick={() => window.open(proposalsData.proposals[0].proposalLink, "_blank").focus()}
              >
                View Customer Proposal
              </span>
            </div>
          </div>
        </div>
      );
    }
    if (applicationStatus === FAST_TRACK_STATUS.PROPOSAL_ACCEPTED) {
      return (
        <>
          <div className="fast-track-container">
            <CheckCircle fontSize="medium" style={{ color: "green" }} />
            <div className="fast-track-item-status">
              <div>
                <b>PROPOSAL ACCEPTED!</b>
              </div>
            </div>
          </div>
          <Button
            color="primary"
            className="view-proposal"
            onClick={() => (window.location.href = `/proposalDetail/${proposalsData.proposals[0].id}`)}
            style={{ color: "#fff", background: theme.palette.primary.main, margin: "24px 32px 16px" }}
          >
            View Accepted Proposal
          </Button>
        </>
      );
    }
  };

  return currentVo && globalVp ? (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" className="fast-track-modal-wrapper">
      <img className="big-rocket" src={bigRocket} />
      {/* <div className="learn-more-header">
        <span>Learn more about</span>&nbsp;
        <span className="fast-track-text">Fast Track</span>
        <img className="fast-track-video-img" src={fastTrackWatch} />
      </div> */}
      <div className="fast-track-modal">
        <div className="fast-track-modal-header" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="fast-track-modal-title">Well this just got a whole lot easier.</div>
            <div className="fast-track-modal-sub-title">
              {fastTrackStatuses[applicationStatus]
                ? `This opportunity has been Fast Tracked`
                : "Fast Track this opportunity!"}
            </div>
          </div>
          <Close fontSize="large" className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className="fast-track-modal-body">
          <div className="fast-track-left">
            <div className="fast-track-top">
              {/* <a>
                <img src={getFastTrackLogo()} className="company-logo" />
              </a> */}
              {/* <div className="fast-track-review">
                <img src={fastTrackStar} />
                <span className="fast-track-review-text">43 reviews</span>
              </div> */}
            </div>

            <div className="fast-track-application-content">
              <div className="fast-track-title">Application Summary</div>

              <div className="fast-track-item">
                <span className="fast-track-item-title">Status:</span>&nbsp;
                <span className="fast-track-status" style={{ color: setColor(applicationStatus) }}>
                  {fastTrackStatuses[applicationStatus] || "Ready for Submission"}
                </span>
              </div>

              <div className="fast-track-item">
                <span className="fast-track-item-title">Applicant:</span>&nbsp;
                <CustomToolTip
                  placement="top-start"
                  title={
                    <span style={{ whiteSpace: "pre-line" }}>
                      {`Contact Name: ${creditApp ? creditApp.creditApplication?.firstName : "" || ""} ${
                        creditApp ? creditApp.creditApplication?.lastName : "" || ""
                      }
                          Phone Number: ${
                            creditApp
                              ? creditApp.creditApplication?.phone
                                ? creditApp.creditApplication?.phone
                                : ""
                              : "" || ""
                          }
                          Email Address: ${creditApp ? creditApp.creditApplication?.email : "" || ""}
                        `}
                    </span>
                  }
                >
                  <a className="fast-track-applicant-link" href="#" style={{ fontWeight: "500" }}>
                    {currentVo.potentialCustomer?.name}
                  </a>
                </CustomToolTip>
              </div>

              {/* <div className="fast-track-item">
                <span className="fast-track-item-title">Risk Tier:</span>&nbsp;
                <span className={`fast-track-applicant-risk-tier ${riskTierClass}`}>{riskTier}</span>
                <span className="fast-track-applicant-detail">
                  <CustomToolTip
                    placement="top-start"
                    title={<span style={{ whiteSpace: "pre-line" }}>{helpIconTooltip}</span>}
                  >
                    <Help className="help-icon" style={{ fontSize: 15 }} />
                  </CustomToolTip>
                </span>
              </div> */}

              <div className="fast-track-item">
                <span className="fast-track-item-title">Equipment:</span>&nbsp;
                <span className="fast-track-item-data">{currentVo.equipmentDescription}</span>
              </div>

              {/* <div className="fast-track-item">
                <span className="fast-track-item-title">Amount Requested:</span>&nbsp;
                <span className="fast-track-item-data">${formatMoney(currentVo.invoiceTotal)}</span>
              </div> */}
            </div>

            {approvedBy && approvedBy !== "Lender" && (
              <div className="fast-track-application-content">
                <div className="fast-track-title">Approved Details</div>

                {/* <div className="fast-track-item">
                  <span className="fast-track-item-title">Max Approval Amount:</span>&nbsp;
                  <span className="fast-track-item-data">$65,000.00</span>
                </div> */}

                <div className="fast-track-item">
                  <span className="fast-track-item-title">Approved By:</span>&nbsp;
                  <span className="fast-track-applicant-detail">{approvedBy}</span>
                </div>
              </div>
            )}

            {applicationStatus === FAST_TRACK_STATUS.AWAITING_DEALER_APPROVAL && !proposalsData?.proposals && (
              <div className="fast-track-application-content">
                <div className="fast-track-title" style={{ marginBottom: 16 }}>
                  Required Fields
                </div>

                <div className="fast-track-item" style={{ maxWidth: 300 }}>
                  <Box component="form" noValidate autoComplete="off" style={{ width: "100%" }}>
                    <TextField
                      className={classes.textField}
                      fullWidth={true}
                      label={"* Amount Requested"}
                      value={requestedAmount}
                      style={{ width: "100%" }}
                      onChange={e => setRequestedAmount(e.target.value.replace(/[^\d\.]/g, ""))}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={fieldsEmpty && !requestedAmount}
                    />
                  </Box>
                </div>

                <div className="fast-track-item" style={{ maxWidth: 300 }}>
                  <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
                    <InputLabel
                      id={`equipment-type-select-label`}
                      className={classes.selectLabel}
                      error={fieldsEmpty && !equipmentType}
                      shrink={true}
                    >
                      * Equipment Type
                    </InputLabel>
                    <Select
                      labelId={`equipment-type-select-label`}
                      id={`equipment-type-select`}
                      value={equipmentType}
                      onChange={e => setEquipmentType(e.target.value)}
                      label="* Equipment Type"
                      className={classes.selectStyles}
                      error={fieldsEmpty && !equipmentType}
                    >
                      <MenuItem key={0} value={"Backhoes"}>
                        Backhoes
                      </MenuItem>
                      <MenuItem key={1} value={"Compact Wheel Loader"}>
                        Compact Wheel Loader
                      </MenuItem>
                      <MenuItem key={2} value={"Dozers"}>
                        Dozers
                      </MenuItem>
                      <MenuItem key={3} value={"Excavators"}>
                        Excavators
                      </MenuItem>
                      <MenuItem key={4} value={"Mini Excavators"}>
                        Mini Excavators
                      </MenuItem>
                      <MenuItem key={5} value={"Wheel Loaders"}>
                        Wheel Loaders
                      </MenuItem>
                      <MenuItem key={6} value={"Other"}>
                        Other
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="fast-track-item" style={{ maxWidth: 300 }}>
                  <FormControl variant="outlined" fullWidth className="select-filter" style={{ margin: "8px 0" }}>
                    <InputLabel
                      id={`equipment-hours-select-label`}
                      className={classes.selectLabel}
                      error={fieldsEmpty && !equipmentHours}
                      shrink={true}
                    >
                      * Equipment Hours Estimate
                    </InputLabel>
                    <Select
                      labelId={`equipment-hours-select-label`}
                      id={`equipment-hours-select`}
                      value={equipmentHours}
                      onChange={e => setEquipmentHours(e.target.value)}
                      label="* Equipment Hours"
                      className={classes.selectStyles}
                      error={fieldsEmpty && !equipmentHours}
                    >
                      <MenuItem key={500} value={"500"}>
                        1 - 500
                      </MenuItem>
                      <MenuItem key={1000} value={"1000"}>
                        501 - 1000
                      </MenuItem>
                      <MenuItem key={1500} value={"1500"}>
                        1001 - 1500
                      </MenuItem>
                      <MenuItem key={2000} value={"2000"}>
                        1501 - 2000
                      </MenuItem>
                      <MenuItem key={2001} value={"2000+"}>
                        2000+
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}

            {/* <div className="fast-track-application-content">
              <div className="fast-track-title-with-icon">
                Opportunity Performance
                <span className="fast-track-applicant-detail">
                  <Help className="help-icon" style={{ fontSize: 15 }} />
                </span>
              </div>

              <div className="fast-track-item">
                <span className="fast-track-item-title">Profit Generated:</span>&nbsp;
                <span className="fast-track-item-data">
                  {currentVo.invoiceTotal * (markUpDealer / 100)
                    ? formatCurrency(currentVo.invoiceTotal * (markUpDealer / 100))
                    : ""}
                </span>
              </div>
              <div className="fast-track-sub-item">
                <div className="fast-track-sub-item-title">Your Markup:</div>&nbsp;
                <div className="fast-track-sub-item-data">{markUpDealer}%</div>
              </div>
              <div className="fast-track-sub-item">
                <div className="fast-track-sub-item-title">Commission on Aftermarkets:</div>&nbsp;
                <div className="fast-track-sub-item-data">$0</div>
                <a className="fast-track-applicant-learn-more" href="#">
                  Learn More
                </a>
              </div>
            </div> */}
          </div>

          {fastTrackStatuses[applicationStatus] ? (
            <div className="fast-track-right">
              <fieldset>
                <legend>Keep Things Moving</legend>

                <div className="fieldset-content">
                  <span className="field-set-title">Your Deal Markup</span>

                  <div className="fast-track-mark-up-inputs">
                    <div className="fast-track-dollars">
                      <div>
                        <span style={{ fontWeight: "bold" }}>Dollars:</span> $
                        {formatMoney(currentVo.invoiceTotal * (markUpDealer / 100))}
                        {/* <TextField
                          label="Dollars"
                          value={formatMoney(currentVo.invoiceTotal * (markUpDealer / 100))}
                          onChange={handleChangeDollarMarkUpValue}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          className={classes.inputStart}
                        /> */}
                      </div>
                    </div>

                    <div className="fast-track-points">
                      <div>
                        <span style={{ fontWeight: "bold" }}>Points:</span> {markUpDealer}%
                        {/* <TextField
                          label="Points"
                          value={markUpDealer}
                          // onChange={handleChangePointMarkUpValue}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            startAdornment: <InputAdornment position="start">&nbsp;</InputAdornment>,
                          }}
                        /> */}
                      </div>
                    </div>
                    {/* <div>
                        <div>
                          <TextField
                            label="Customer Pmnt."
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            className={classes.textField}
                            helperText={
                              <p className="customer-pmnt-helper">
                                <span className="customer-pmnt-helper-text">60 mo. </span>
                                <span>term</span>
                              </p>
                            }
                          />
                        </div>
                      </div> */}
                  </div>

                  {/* <div className="default-markup">
                    <CustomSwitch />
                    <div className="default-markup-text">
                      <div className="default-markup-text-title">
                        Default markup for all future <br />
                        <span className="fast-track-text">Fast Track </span>
                        <span>deals? </span>
                        <span className="default-markup-default-text"> Default: </span>
                        <a onClick={handleChangeMarkUpText} href="#" className="default-markup-link">
                          {defaultValueTextMarkUp ? defaultValueTextMarkUp : ""}
                        </a>
                      </div>
                    </div>
                  </div> */}
                  {renderProposalDataSection()}
                  {/*
                  <div className="fast-track-remember-setting" style={{ margin: "16px 0 32px" }}>
                    <div>
                      <CustomSwitch
                        onChange={updateFastTrackSetting}
                        checked={fastTrackSetting === "YES_AUTOMATED_FOR_DEALER"}
                      />
                    </div>
                    <div className="automatically-send-proposals">
                      Automatically send proposals for all future <br />
                      <span className="fast-track-text">Fast Track </span> deals?
                    </div>
                  </div>
                  */}
                </div>

                {/* <fieldset className="finalize-the-deal-fieldset">
                  <legend>Finalize the Deal</legend>

                  <div className="invoice-total">
                    <TextField
                      className={classes.confirmTextField}
                      label="Confirm Invoice Total"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      fullWidth
                    />
                    <div className="invoice-actions">
                      <a className="invoice-action" href="#">
                        Edit Line Items
                      </a>
                      <div>OR</div>
                      <a className="invoice-action" href="#">
                        <CloudUpload fontSize="small" style={{ color: "gray" }} />
                        Upload Invoice
                      </a>
                    </div>

                    <div className="request-final-doc-btn">
                      <Button variant="contained" className={classes.secondaryButton} disableElevation>
                        Request Final Docs
                      </Button>
                    </div>
                  </div>
                </fieldset> */}
              </fieldset>
            </div>
          ) : (
            <div className="fast-track-right">
              <fieldset>
                <legend>Let's Get Things Started!</legend>
                <p style={{ margin: 0, padding: "0 24px" }}>
                  Use Fast Track to submit to your preferred lender waterfall and receive most decisions in minutes.
                  Next, quickly send a financing proposal to your customer on approval.
                </p>
                <div
                  className="fieldset-content"
                  style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                  <Button
                    color={"primary"}
                    onClick={e => {
                      e.stopPropagation();
                      window.location.href = `${window.location.pathname}?startFastTrack=true`;
                    }}
                    style={{
                      color: "#fff",
                      background: theme.palette.primary.main,
                      margin: "32px 0 48px",
                      fontWeight: "bold",
                      width: "50%",
                      fontSize: 16,
                      padding: 8,
                    }}
                    className={classes.shake}
                  >
                    <img src={rocketFastTrack} style={{ height: 32, marginRight: 4 }} alt="" />
                    Fast Track It!
                  </Button>
                </div>
              </fieldset>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  ) : (
    ""
  );
};
export default FastTrackModal;
