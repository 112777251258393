import moment from "moment";
import React from "react";
import { Column } from "react-table";
import { Task, UserProfile } from "@trnsact/trnsact-shared-types";
import { OptionCell } from "./cells";
import { DefaultTableCell } from "../../../Table/DefaultTableCell";
import { StatusCell, PriorityCell } from "../../../TasksCommon/tableCells";

interface Options {
  tasksQueryKey: string;
  userProfile: UserProfile;
  fetchTasks: () => Promise<any>;
  onOpenCreateTaskModel: (taskId: string) => void;
}

export function createTableColumns(options: Options): Array<Column<Task>> {
  const { fetchTasks, userProfile, tasksQueryKey, onOpenCreateTaskModel } = options;

  return [
    {
      filterable: false,
      Header: "Created on",
      accessor: "createdDateMilliseconds",
      Cell: ({ value }: any) => (
        <DefaultTableCell value={value} formatter={() => (value ? moment(value).format("ll") : "")} />
      ),
    },
    {
      filterable: false,
      Header: "Assigned to",
      accessor: "assignedToUser",
      Cell: ({ value }: any) => <DefaultTableCell value={value ?? "Trnsact"} />,
    },
    {
      Header: "Regarding",
      accessor: "regarding",
      Cell: ({ value }: any) => <DefaultTableCell value={value ?? "Trnsact"} />,
    },
    {
      resizable: false,
      Header: "Completed On",
      accessor: "completedDateTime",
      Cell: ({ value }: any) => (
        <DefaultTableCell value={value} formatter={() => (value ? moment(value).format("ll") : "Not completed")} />
      ),
    },
    {
      Header: "Status",
      filterable: false,
      accessor: "status",
      Cell: ({ original: task }: any) => <StatusCell task={task} />,
    },
    {
      Header: "Priority",
      filterable: false,
      accessor: "priority",
      Cell: ({ original: task }: any) => <PriorityCell task={task} />,
    },
    {
      sortable: false,
      Header: "Option",
      filterable: false,
      show: tasksQueryKey === "userRelatedTasks",
      Cell: ({ original: task }: any) => (
        <OptionCell
          task={task}
          fetchTasks={fetchTasks}
          onOpenCreateTaskModel={onOpenCreateTaskModel}
          isMyTask={userProfile.id === task.assignedToUserProfileId}
        />
      ),
    },
  ].map(header => ({ ...header, headerStyle: { fontWeight: "500", padding: "0.5rem" } }));
}
