import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { MenuTemplateGeneralFormInputs } from "modules/aftermarketMenuConstructor/types";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { InputField } from "components/form";
import { Control, FieldErrors, UseFormWatch } from "react-hook-form";
import { menuConstructorActions, menuConstructorSelectors } from "modules/aftermarketMenuConstructor/model";
import { usePermissions } from "modules/aftermarketMenuConstructor/hooks/usePermissions";

interface Props {
  formControl: Control<MenuTemplateGeneralFormInputs>;
  formErrors: FieldErrors<MenuTemplateGeneralFormInputs>;
  watch: UseFormWatch<MenuTemplateGeneralFormInputs>;
}

export const GeneralInformationCard = ({ formControl, formErrors, watch }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;

  const { hasWriteAccess } = usePermissions();

  useEffect(() => {
    const subscription = watch(value => {
      dispatch(menuConstructorActions.setMenuGeneralFormValues(value as MenuTemplateGeneralFormInputs));
    });

    return subscription.unsubscribe;
  }, [watch]);

  return (
    <CardContainer title="General" isLoading={isLoading}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputField
            label="Name"
            control={formControl}
            name="name"
            rules={{
              required: true,
            }}
            inputProps={{
              required: true,
              error: !!formErrors.name,
              helperText: formErrors.name && "Required",
              disabled: !hasWriteAccess,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            label="Description"
            control={formControl}
            name="description"
            inputProps={{ disabled: !hasWriteAccess }}
          />
        </Grid>
      </Grid>
    </CardContainer>
  );
};

const useStyles = makeStyles(({ palette }) => ({}));
