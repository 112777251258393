export const SET_ACCOUNT = "SET ACCOUNT";
export const SET_COMPANY_PROFILE = "SET COMPANY PROFILE";
export const SET_USER_PROFILE = "SET USER PROFILE";
export const SET_RELATED_USERS = "SET RELATED USERS";
export const SET_CHAT_UNREAD_MESSAGES = "SET CHAT UNREAD MESSAGES COUNTER";
export const SET_CHAT_TARGET_RECIPIENT = "SET CHAT TARGET RECIPIENT USER PROFILE";
export const SET_CHAT_TARGET_CS = "SET CHAT TARGET CREDIT SUBMISSION";
export const SET_CHAT_OPENED = "SET CHAT OPENED";
export const SET_LP = "SET LENDER PROFILES";
export const SET_CHAT_RESET = "CHAT STATE RESET";
export const SET_CHAT_TARGET_TOPIC = "SET CHAT TARGET TOPIC";
export const SET_CS = "SET CREDIT SUBMISSION";
export const SET_VO = "SET VO";
export const SET_CHAT_ENABLED = "SET CHAT ENABLED";
export const SET_CHAT_TARGET_VOID = "SET CHAT TARGET VOID";
export const SET_CS_REFETCH = "SET CS REFETCH";
export const SET_DOCS_REFETCH = "SET DOCS REFETCH";
export const SET_LENDER_PROFILE = "SET_LENDER_PROFILE";

// chat v2
export const CHAT_V2_OPEN = "CHAT V2 OPEN";
export const CHAT_V2_RELATED_TASK_ID = "CHAT V2 RELATED TASK ID";
export const CHAT_V2_SELECTED_CONVERSATION_SID = "CHAT V2 SELECTED CONVERSATION SID";
export const CHAT_V2_CONVERSATIONS = "CHAT V2 CONVERSATIONS";
export const CHAT_V2_TASKS = "CHAT V2 TASKS";
export const CHAT_V2_FETCHED_CONVERSATION = "CHAT V2 FETCHED CONVERSATION";
export const CHAT_V2_RESET = "CHAT V2 RESET";
export const CHAT_V2_SET_CREDIT_APP = "CHAT V2 SET CREDIT APP";
export const CHAT_V2_RESET_CONVERSATIONS = "CHAT V2 RESET CONVERSATIONS";
export const CHAT_V2_SET_TARGET_CREDIT_SUBMISSION_ID = "CHAT V2 SET TARGET CREDIT SUBMISSION ID";
export const CHAT_V2_SET_CREDIT_SUBMISSION = "CHAT V2 SET CREDIT SUBMISSION";
export const CHAT_V2_SET_RECIPIENTS_LIST = "CHAT V2 SET RECIPIENTS LIST";
