import React, { Component, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddOutlined } from "@material-ui/icons";
import { theme } from "../../theme";

export class LenderMatchingCriteria extends Component {
  constructor(props) {
    super(props);

    let startingCriteria = [{ criteria: "" }];

    if (this.props.lenderMatrixMatchingRules && this.props.lenderMatrixMatchingRules.length > 0) {
      startingCriteria = [];
      this.props.lenderMatrixMatchingRules.forEach(lmr => startingCriteria.push(lmr));
    }
    this.state = {
      formValues: startingCriteria,
    };
  }

  handleChange(i, field, value) {
    let formValues = [...this.state.formValues];
    formValues[i][field] = value;
    this.props.onLenderMatchingCriteriaChange(formValues);
    this.setState({ formValues });
  }

  addFormFields(criteria = "") {
    this.setState({
      formValues: [...this.state.formValues, { criteria }],
    });
  }

  removeFormFields(i) {
    let formValues = [...this.state.formValues];
    formValues.splice(i, 1);
    this.setState({ formValues });
  }

  render() {
    return (
      <div>
        <Grid container alignItems="center" spacing={2} className="button-section">
          <Grid item xs={10} sm={10} style={{ fontWeight: "bold" }}>
            Write out your criteria for matching to this lender. For better results use these{" "}
            <LenderMatrixKeywordsModal />
          </Grid>
          <Grid item xs={2} sm={2}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              style={{ backgroundColor: theme.palette.primary.main }}
              onClick={() => this.addFormFields()}
            >
              <AddOutlined style={{ fontSize: 16 }} />
              Add
            </Button>
          </Grid>
        </Grid>
        {this.state.formValues.map((element, index) => (
          <Grid container alignItems="center" spacing={2} key={index} style={{ paddingTop: "15px" }}>
            <Grid item xs={11} sm={11}>
              <TextField
                fullWidth
                variant="outlined"
                label={`Criteria ${index + 1}`}
                name="criteria"
                value={element.criteria || ""}
                onChange={e => this.handleChange(index, "criteria", e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton color="secondary" onClick={() => this.removeFormFields(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }
}

const LenderMatrixKeywordsModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <span>
      <Button
        style={{
          padding: 0, // Remove padding
          textTransform: "none", // Prevent text transformation (e.g., uppercase)
          color: theme.palette.primary.main, // Set the color to your desired hyperlink color
          textDecoration: "underline", // Add underline to mimic a hyperlink
          backgroundColor: "transparent", // Remove the background color
          border: "none", // Remove the border
          cursor: "pointer", // Change the cursor to a pointer on hover
        }}
        onClick={openModal}
      >
        key words
      </Button>

      <Modal open={isModalOpen} onClose={closeModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <Typography variant="h6">Lender Matching Key Words</Typography>
          <List>
            <ListItem>
              <ListItemText primary="Amount Requested" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Equipment Condition" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Time in Business" />
            </ListItem>
            <ListItem>
              <ListItemText primary="FICO Score" />
            </ListItem>
          </List>
        </div>
      </Modal>
    </span>
  );
};
