import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { vendorContactRole as vendorContactRoles } from "../../utils";
import { FormControlLabel, Grid, LinearProgress, Snackbar, Switch } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Tile from "../../components/Tile/Tile";
import { ItemConfigurationPanel } from "../PortalConfigurationWizard/CommonComponents/ItemConfigurationPanel";
import { getDefaultPortalConfigurationByType } from "../../services/pcwService";
import { configurableItemsTypes, portalConfigurationTypes } from "pages/Prequal/constants";
import DisclosuresCard from "./DisclosuresCard";
import LenderDocumentAccess from "./LenderDocumentAccess";
import TasksTemplates from "./TasksTemplates";
import OCAInsuranceModuleToggle from "./OCAInsuranceModuleToggle";
import RequireCreditAppRecertificationToggle from "./RequireCreditAppRecertificationToggle";
import ChecklistTemplate from "../../components/ChecklistTemplate/ChecklistTemplate";
import { M_UPDATE_VP, QUERY_PORTAL_CONFIGURATION, SAVE_PORTAL_CONFIGURATION } from "../../../src/queries/common";
import _ from "lodash";

const styles = {
  checklistRoot: {
    position: "relative",
  },
  checklistEnableToggle: {
    position: "absolute",
    zIndex: 5,
    top: 29,
    left: 340,
  },
};

const useStyles = makeStyles(styles);

const PortalSetup = props => {
  const classes = useStyles();

  const [updateVP, { data }] = useMutation(M_UPDATE_VP, {
    context: { authRequired: true },
    onCompleted() {
      setIsSavedSuccessfully(true);
    },
    onError() {
      setIsSavedWithError(true);
    },
  });

  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  const vendorProfile = useSelector(state => state.vp);

  const [pcwConfigurationsByType, setPCWConfigurationsByType] = useState();
  const [configurableItemsSettings, setConfigurableItemsSettings] = useState([]);
  const [isSavedSuccessfully, setIsSavedSuccessfully] = useState(false);
  const [isSavedWithError, setIsSavedWithError] = useState(false);
  const [isUserAbleToSeePortalSetup, setIsUserAbleToSeePortalSetup] = useState(true);
  const [isChecklistEnable, setIsChecklistEnable] = useState(false);

  const updateVPDebounced = useCallback(_.debounce(updateVP, 500), []);

  /*   const isTasksTemplatesVisible = _.includes(
    [
      "e899b85a-bbd1-11ea-ac22-ab842323b812", // Quinn Company
      "eb9038ca-efee-11eb-a417-9334515c0f4f", // Ziegler Inc.
      "c953dd86-bcb3-11e9-b368-3764d1c08f47", // Test Vendor V2 (for tests DEV)
      "2cceeaa4-7349-11ee-b291-0f0b02fcd022", // Test Vendor Auto (for tests DEV)
      "bb943b44-c57f-11e9-902b-c36843606426", // Test Vendor V2 (for tests PROD)
      "2d1a9314-7349-11ee-9b74-5fbba6ab324c", // Test Vendor Auto (for tests PROD)
    ],
    account.id
  ); */
  const isTasksTemplatesVisible = false; // decided to not use that approach for now

  const isChecklistTemplatesVisible = true;

  const portalSetupPortalConfigurationTypes = [
    portalConfigurationTypes.documents,
    portalConfigurationTypes.portalSetup,
    portalConfigurationTypes.voChecklistTemplate,
    portalConfigurationTypes.voManagement,
    portalConfigurationTypes.aftermarket,
  ];

  const portalSetupConfigurableItemsTypes = [
    configurableItemsTypes.documents,
    configurableItemsTypes.voManagement,
    configurableItemsTypes.aftermarket,
  ];

  const configurableItemsTypesToPortalConfigurationTypesMap = {
    [configurableItemsTypes.documents]: portalConfigurationTypes.documents,
    [configurableItemsTypes.voManagement]: portalConfigurationTypes.voManagement,
    [configurableItemsTypes.aftermarket]: portalConfigurationTypes.aftermarket,
  };

  const { data: portalConfigurationData, loading, error } = useQuery(QUERY_PORTAL_CONFIGURATION, {
    variables: {
      vendorProfileId: account.vendorProfileId,
      types: portalSetupPortalConfigurationTypes,
    },
    fetchPolicy: "no-cache",
  });

  const [savePortalConfiguration] = useMutation(SAVE_PORTAL_CONFIGURATION, {
    context: { authRequired: true },
    onCompleted() {
      setIsSavedSuccessfully(true);
    },
    onError() {
      setIsSavedWithError(true);
    },
  });

  const getConfigurableItemSettings = (configurableItemType, portalConfiguration) => {
    const getDefaultConfigurableItemSettings = (configurableItemType, portalConfiguration) => {
      return {
        item: portalConfiguration.jsonDefinition,
        type: configurableItemType,
        extraData: {
          portalConfigurationType: portalConfiguration.type,
        },
      };
    };

    const getDocumentsConfigurableItemSettings = defaultConfigurableItemSettings => {
      const portalSetupPortalConfiguration = pcwConfigurationsByType[portalConfigurationTypes.portalSetup];
      const portalSetupDocumentsByTypeSettingsHiddenForRoles = _.get(
        portalSetupPortalConfiguration,
        "jsonDefinition.config.rolesSettings.documentsByTypeSettingsHidden"
      );
      const isDocumentsByTypeSettingsHidden = _.includes(
        portalSetupDocumentsByTypeSettingsHiddenForRoles,
        userProfile.vendorContactRole
      );
      const documentsSpecificSettings = {
        configurationKeysToDisplay: isDocumentsByTypeSettingsHidden ? [] : ["documentsByTypeSettings"],
      };
      return _.merge({}, defaultConfigurableItemSettings, documentsSpecificSettings);
    };

    const getSettingsByConfigurableItemTypeHandlers = {
      [configurableItemsTypes.documents]: getDocumentsConfigurableItemSettings,
    };

    const defaultConfigurableItemSettings = getDefaultConfigurableItemSettings(
      configurableItemType,
      portalConfiguration
    );

    return getSettingsByConfigurableItemTypeHandlers[configurableItemType]
      ? getSettingsByConfigurableItemTypeHandlers[configurableItemType](defaultConfigurableItemSettings)
      : defaultConfigurableItemSettings;
  };

  // call savePortalConfiguration and set portalConfigurationId for new record
  const handleSavingPortalConfiguration = async configurationToSave => {
    try {
      const result = await savePortalConfiguration({
        variables: {
          input: configurationToSave,
        },
      });
      if (configurationToSave.portalConfigurationId) {
        return;
      }
      const pcwConfigurationsByTypeToSet = {
        ...pcwConfigurationsByType,
        [configurationToSave.type]: {
          ...pcwConfigurationsByType[configurationToSave.type],
          portalConfigurationId: _.get(result, "data.savePortalConfiguration", null),
        },
      };
      setPCWConfigurationsByType(pcwConfigurationsByTypeToSet);
    } catch (err) {
      console.error(err);
    }
  };

  const saveItemConfig = async configurableItemSettings => {
    const portalConfigurationType = _.get(configurableItemSettings, "extraData.portalConfigurationType");
    const configurationToSave = {
      ...pcwConfigurationsByType[portalConfigurationType],
      jsonDefinition: configurableItemSettings.item,
    };
    handleSavingPortalConfiguration(configurationToSave);
  };

  useEffect(() => {
    if (!portalConfigurationData) {
      return;
    }

    const getMerged = (savedConfiguration, defaultPortalConfiguration) => {
      const getMergedDocumentsConfiguration = () => {
        const customizer = (objValue, srcValue) => {
          return _.isUndefined(objValue) ? srcValue : objValue;
        };

        const mergeFn = _.partialRight(_.assignWith, customizer);

        // think about better solution to merge all config props, not only documentsByTypeSettings. Using _.merge works incorrect (adds default values to saved config)
        mergeFn(
          savedConfiguration.jsonDefinition.config.documentsByTypeSettings,
          defaultPortalConfiguration.jsonDefinition.config.documentsByTypeSettings
        );
        return savedConfiguration;
      };

      return savedConfiguration.type === portalConfigurationTypes.documents
        ? getMergedDocumentsConfiguration()
        : _.merge({}, defaultPortalConfiguration, savedConfiguration);
    };

    const pcwConfigurations = _.map(portalSetupPortalConfigurationTypes, portalConfigurationType => {
      const savedConfiguration = _.find(portalConfigurationData.portalConfigurations, {
        type: portalConfigurationType,
      });

      const defaultPortalConfiguration = getDefaultPortalConfigurationByType(
        portalConfigurationType,
        account.vendorProfileId
      );
      const configurationToSet = _.some(savedConfiguration)
        ? getMerged(savedConfiguration, defaultPortalConfiguration) // to be able to add new config props for existing configuration
        : defaultPortalConfiguration;
      delete configurationToSet.__typename;
      return configurationToSet;
    });

    setPCWConfigurationsByType(_.keyBy(pcwConfigurations, "type"));
  }, [portalConfigurationData]);

  useEffect(() => {
    if (_.isEmpty(pcwConfigurationsByType)) {
      return;
    }
    const portalSetupPortalConfiguration = pcwConfigurationsByType[portalConfigurationTypes.portalSetup];
    const portalSetupHiddenForRoles = _.get(
      portalSetupPortalConfiguration,
      "jsonDefinition.config.rolesSettings.hidden"
    );
    const isUserAbleToSeePortalSetup = !_.includes(portalSetupHiddenForRoles, userProfile.vendorContactRole);

    const configurableItemsSettingsToSet = _.map(portalSetupConfigurableItemsTypes, configurableItemType => {
      const portalConfigurationType = configurableItemsTypesToPortalConfigurationTypesMap[configurableItemType];
      return getConfigurableItemSettings(configurableItemType, pcwConfigurationsByType[portalConfigurationType]);
    });

    setConfigurableItemsSettings(configurableItemsSettingsToSet);
    setIsUserAbleToSeePortalSetup(pcwConfigurationsByType[portalConfigurationTypes.voChecklistTemplate]);

    const isChecklistHidden =
      _.get(pcwConfigurationsByType, `${portalConfigurationTypes.voChecklistTemplate}.jsonDefinition.config.hidden`) ===
      true;
    setIsChecklistEnable(!isChecklistHidden);
  }, [pcwConfigurationsByType]);

  const onChecklistSave = checklistToSave => {
    const configurationToSave = pcwConfigurationsByType[portalConfigurationTypes.voChecklistTemplate];
    _.set(configurationToSave, "jsonDefinition.config.checklist", checklistToSave);
    handleSavingPortalConfiguration(configurationToSave);
  };

  const onChecklistEnableToggle = () => {
    const newIsChecklistEnableValue = !isChecklistEnable;
    setIsChecklistEnable(newIsChecklistEnableValue);
    const configurationToSave = pcwConfigurationsByType[portalConfigurationTypes.voChecklistTemplate];
    _.set(configurationToSave, "jsonDefinition.config.hidden", !newIsChecklistEnableValue);
    handleSavingPortalConfiguration(configurationToSave);
  };

  const documentsConfigurableItem = _.find(configurableItemsSettings, { type: configurableItemsTypes.documents });
  const cadmConfigurableItem = _.find(configurableItemsSettings, { type: configurableItemsTypes.voManagement });
  const aftermarketConfigurableItem = _.find(configurableItemsSettings, { type: configurableItemsTypes.aftermarket });

  const isNeedToShowDocumentsSettings =
    documentsConfigurableItem &&
    (documentsConfigurableItem.configurationKeysToDisplay
      ? _.some(documentsConfigurableItem.configurationKeysToDisplay)
      : true);
  return (
    <div className={classes.root}>
      {loading && <LinearProgress />}
      {error && <h3>{String(error)}</h3>}
      {!loading && isUserAbleToSeePortalSetup ? (
        <Grid container spacing={4}>
          {isNeedToShowDocumentsSettings && (
            <Grid item sm={12} md={10}>
              <Tile>
                <ItemConfigurationPanel
                  configurableItemSettings={documentsConfigurableItem}
                  onSaveFn={saveItemConfig}
                  childComponent={
                    <LenderDocumentAccess
                      vendorProfile={vendorProfile}
                      userProfile={userProfile}
                      vendorContactRoles={vendorContactRoles}
                    />
                  }
                />
              </Tile>
            </Grid>
          )}

          {cadmConfigurableItem && (
            <Grid item sm={12} md={6}>
              <Tile>
                <ItemConfigurationPanel configurableItemSettings={cadmConfigurableItem} onSaveFn={saveItemConfig} />
              </Tile>
            </Grid>
          )}
          {aftermarketConfigurableItem && (
            <Grid item sm={12} md={6}>
              <Tile>
                <ItemConfigurationPanel
                  configurableItemSettings={aftermarketConfigurableItem}
                  onSaveFn={saveItemConfig}
                />
              </Tile>
            </Grid>
          )}

          {isTasksTemplatesVisible && (
            <Grid item xs={12}>
              <TasksTemplates account={account} />
            </Grid>
          )}

          {isChecklistTemplatesVisible && (
            <Grid item xs={12} className={classes.checklistRoot}>
              {/* Needs to be reworked in future to use ItemConfigurationPanel (common approach) instead of Switch */}
              <FormControlLabel
                className={classes.checklistEnableToggle}
                control={<Switch checked={isChecklistEnable} onChange={onChecklistEnableToggle} color="primary" />}
                label={isChecklistEnable ? "Enabled" : "Disabled"}
              />
              <ChecklistTemplate
                tileTitle="Opportunity Checklist"
                checklist={_.get(
                  pcwConfigurationsByType,
                  `${portalConfigurationTypes.voChecklistTemplate}.jsonDefinition.config.checklist`
                )}
                onChecklistSave={onChecklistSave}
                isPortalSetupPage={true}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Tile title="OCA Settings">
              {!_.includes(
                [vendorContactRoles.sales_rep, vendorContactRoles.sales_mgr],
                userProfile.vendorContactRole
              ) && (
                <Grid item xs={12}>
                  <DisclosuresCard vendorProfile={vendorProfile} />
                </Grid>
              )}
              <OCAInsuranceModuleToggle vendorProfile={vendorProfile} />
              <RequireCreditAppRecertificationToggle
                vendorProfile={vendorProfile}
                updateVPDebounced={updateVPDebounced}
              />
            </Tile>
          </Grid>
        </Grid>
      ) : (
        <>{!loading && <h3>Access Denied</h3>}</>
      )}
      <Snackbar
        open={isSavedSuccessfully}
        autoHideDuration={3000}
        onClose={() => setIsSavedSuccessfully(state => !state)}
      >
        <Alert severity="success">
          <AlertTitle>Changes Saved!</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar open={isSavedWithError} autoHideDuration={3000} onClose={() => setIsSavedWithError(state => !state)}>
        <Alert severity="error">
          <AlertTitle>Error on saving</AlertTitle>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PortalSetup;
