import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box } from "@material-ui/core";
import { menuConstructorActions, menuConstructorSelectors } from "../../../model";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { ProposalProductsFilters, ProposalProductsCard } from "modules/desking";
import { ProposalProductCardModes } from "modules/desking/types";

export const ProductsCard = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const allProducts = useSelector(menuConstructorSelectors.proposalsProducts) as ProposalProduct[];
  const selectedMenuOption = useSelector(menuConstructorSelectors.selectedMenuOption);
  const isLoading = useSelector(menuConstructorSelectors.isLoading) as boolean;

  const [productsToDisplay, setProductsToDisplay] = useState<ProposalProduct[]>([]);

  useEffect(() => {
    if (allProducts.length) {
      setProductsToDisplay(allProducts);
    }
  }, [allProducts]);

  const updateProductConfiguration = (productId: string, menuName: string, field: string, value: any) => {
    dispatch(
      menuConstructorActions.updateMenuProduct({
        productId,
        field,
        value,
      })
    );
  };

  const renderCards = useMemo(() => {
    const productsToEdit = selectedMenuOption?.products || [];

    const productCardsForEdit = productsToEdit.map((product: ProposalProduct) => {
      return (
        <ProposalProductsCard
          key={`forEdit-${product.proposalProductId}`}
          type="forEdit"
          product={product}
          updateProductConfiguration={updateProductConfiguration}
          proposalProductConfiguration={product}
          mode={ProposalProductCardModes.Constructor}
        />
      );
    });

    const productsForEditIds = productsToEdit.map((product: ProposalProduct) => product.proposalProductId);
    const restProductCards = productsToDisplay
      ?.filter((product: ProposalProduct) => !productsForEditIds.includes(product.proposalProductId))
      ?.map(product => (
        <ProposalProductsCard
          key={product.proposalProductId}
          type="simple"
          product={product}
          mode={ProposalProductCardModes.Constructor}
        />
      ));

    return [...productCardsForEdit, ...restProductCards];
  }, [productsToDisplay, selectedMenuOption]);

  return (
    <CardContainer title="Products" isLoading={isLoading}>
      <ProposalProductsFilters products={allProducts} onFiltered={setProductsToDisplay} />
      <Box className={classes.cardsContainer}>{renderCards}</Box>
    </CardContainer>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  cardsContainer: {
    overflow: "auto",
    height: 450,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));
