import React, { PropsWithChildren } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const CardBackground = ({ children }: PropsWithChildren) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paperBg} elevation={0}>
      {children}
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paperBg: {
    padding: "0.5rem 1.5rem",
  },
}));
