import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import Table from "components/Table";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const styles = {
  root: {
    width: "100%",
  },
  userStatusActive: {
    padding: "4px 8px",
    color: "#2E7D32",
    backgroundColor: "#DCEDC8",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  userStatusInvited: {
    padding: "4px 8px",
    color: "#1565C0",
    backgroundColor: "#BBDEFB",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  userStatusStaged: {
    padding: "4px 8px",
    color: "#1565C0",
    backgroundColor: "#BBDEFB",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  userStatusBlocked: {
    padding: "4px 8px",
    color: "#C62828",
    backgroundColor: "#FFCDD2",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  userStatusSharedInbox: {
    padding: "4px 8px",
    color: "#424242",
    backgroundColor: "#EEEEEE",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
};

const statusTitles = {
  Active: "Contact has registered successfully and is connected Trnsact.",
  Blocked: "Contact has been removed from the account.",
  Staged: "Contact has been added but has yet to receive a registration invite.",
  Invited: "Contact has been sent a registration invite but has yet to connect Trnsact.",
  SharedInbox: "Contact is a shared inbox.",
};

const useStyles = makeStyles(styles);

const updateStatuses = (status, isSharedInbox) => {
  const statuses = {
    pending_approval: "Staged",
    approved: "Staged",
    active: "Active",
    blocked: "Blocked",
  };

  let stat = statuses[status] ? statuses[status] : "Staged";
  stat = isSharedInbox ? "SharedInbox" : stat;
  return stat;
};

const LenderContactsTable = ({ lenderContacts, onRowClick, onEditClick, locations, territoryManagement }) => {
  const userProfile = useSelector(state => state.userProfile);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 5 });

  let columns = [
    {
      Header: "Name",
      accessor: "firstName",
      Cell: props => {
        if (props.original.userProfile) {
          return <>{props.original.userProfile.firstName + " " + props.original.userProfile.lastName}</>;
        }
        return <></>;
      },
      Filter: () => null,
      resizable: false,
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: props => {
        if (props.original.userProfile) {
          return <>{props.original.userProfile.title}</>;
        }
        return <></>;
      },
      Filter: () => null,
      resizable: false,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: props => {
        if (props.original.userProfile) {
          return <>{props.original.userProfile.email}</>;
        }
        if (props.original.notificationContact) {
          return <>{props.original.notificationContact}</>;
        }
        return <></>;
      },
      Filter: () => null,
      resizable: false,
    },
    {
      Header: "Phone",
      accessor: "phoneNumber",
      Cell: props => {
        if (props.original.userProfile) {
          return (
            <>
              {props.original.userProfile.phoneNumber
                ? props.original.userProfile.phoneNumber
                : props.original.userProfile.mobilePhoneNumber}
            </>
          );
        }
        return <></>;
      },
      Filter: () => null,
      resizable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: () => null,
      resizable: false,
      Cell: props => (
        <div
          title={statusTitles[updateStatuses(props.value, !!props.original.notificationContact)]}
          className={classes[`userStatus${updateStatuses(props.value, !!props.original.notificationContact)}`]}
        >
          {updateStatuses(props.value, !!props.original.notificationContact) === "SharedInbox"
            ? "Shared Inbox"
            : updateStatuses(props.value, !!props.original.notificationContact)}
        </div>
      ),
    },
    {
      Header: "Notifications",
      accessor: "receivesNotifications",
      Filter: () => null,
      resizable: false,
      Cell: props => (
        <div className={classes[`userStatus${props.value ? "Active" : "Blocked"}`]}>{props.value ? "Yes" : "No"}</div>
      ),
    },
    // {
    //   Header: "Last Login",
    //   accessor: "lastLogin",
    //   Filter: () => null,
    //   resizable: false,
    //   Cell: props =>
    //     props.value && updateStatuses(props.original.status) === "Active"
    //       ? new Date(props.value).toLocaleDateString("en-US")
    //       : "",
    // },
    // {
    //     Header: "Reps",
    //     accessor: "reps",
    //     Filter: () => null,
    //     resizable: false,
    // },
    {
      Header: "Update",
      id: "edit",
      Cell: props => (
        <IconButton
          style={{ padding: 4, marginLeft: 6 }}
          onClick={() => onEditClick(props.original)}
          color="primary"
          aria-label="change contact"
          component="button"
        >
          <EditOutlined style={{ fontSize: 24 }} />
        </IconButton>
      ),
    },
  ];

  if (territoryManagement) {
    columns.splice(columns.length - 1, 0, {
      Header: "Territory",
      accessor: "accessDealerLocationIds",
      Filter: () => null,
      resizable: false,
      Cell: props => {
        let territory = locations?.filter(loc => props.value?.includes(loc.locationId));
        return territory?.length > 0 ? (
          <ul style={{ paddingLeft: 4 }}>{territory?.map(loc => <li>{loc.locationName}</li>)}</ul>
        ) : (
          <>All Submissions</>
        );
      },
    });
  }

  return (
    <>
      <Table
        color="primary"
        data={lenderContacts}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              cursor: "pointer",
              fontSize: 12,
              fontWeight: 400,
              lineHeight: "14px",
            },
            onClick: e => {
              e.preventDefault();

              if (column?.id !== "edit") onRowClick(rowInfo.original);
            },
          };
        }}
        columns={columns}
        pageSize={pageSizeValue}
        pageNumber={pageNumber}
        defaultPageSize={savedListSettings.pageSize}
        showPaginationTop={false}
        showPaginationBottom={true}
        onPageChange={changePage => {
          setPageNumber(changePage);
        }}
        onPageSizeChange={changePageSize => {
          setPageSizeValue(changePageSize);
          setPageNumber(0);
        }}
        className={classes.root + " -highlight"}
        NoDataComponent={() => null}
      />
    </>
  );
};

export default LenderContactsTable;
