import { Button, MenuItem, Select, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles({
  paginationContainer: {
    height: "60px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0px",
    margin: "20px 0px",
  },
  paginationButton: {
    backgroundColor: "#e5e5e5",
    color: "rgba(0, 0, 0, 0.6)",
    cursor: "pointer",
    borderRadius: "3px",
    width: "calc(33.3333%)",
    height: "40px",
    fontWeight: "200",
    textTransform: "none",
    fontSize: "1em",
  },
  paginationCenter: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },
  paginationSteps: {
    color: "rgba(0, 0, 0, 0.6)",
    marginRight: "25px",
  },
  paginationCurrent: {
    margin: "0px 70px",
  },
  paginationStepsText: {
    margin: "0px 5px",
  },
  disabledButton: {
    cursor: "auto",
    opacity: "0.6",
  },
});

function CustomPagination({ rowsPerPageOptions, count, page, onChangePage, onChangeRowsPerPage }) {
  const classes = useStyles();
  const [options, setOptions] = useState(rowsPerPageOptions ? rowsPerPageOptions : [5, 10, 20, 25, 50, 100]);
  const [activeOption, setActiveOption] = useState(options[0]);

  const countWithFilter = Math.ceil(count / activeOption);

  return (
    <div className={classes.paginationContainer}>
      <Button
        classes={{
          root: classes.paginationButton,
          focusVisible: classes.paginationButton,
        }}
        onClick={e => {
          if (page + 1 > 0) {
            onChangePage(e, page - 1);
          }
        }}
        disabled={page === 0}
      >
        Previos
      </Button>
      <div className={classes.paginationCenter}>
        <div className={classes.paginationSteps}>
          Page <span className={classes.paginationCurrent}>{page + 1}</span> of{" "}
          <span className={classes.paginationStepsText}>{countWithFilter}</span>
        </div>
        <Select
          value={activeOption}
          onChange={e => {
            setActiveOption(e.target.value);
            onChangeRowsPerPage(e);
          }}
        >
          {options.map(option => {
            return (
              <MenuItem key={option} value={option}>
                {option} rows
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <Button
        className={classes.paginationButton}
        onClick={e => {
          if (page < countWithFilter - 1) {
            onChangePage(e, page + 1);
          }
        }}
        disabled={page === countWithFilter - 1}
      >
        Next
      </Button>
    </div>
  );
}

export default CustomPagination;
