import React, { useContext, useEffect, useRef, useState } from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddOutlined, EditOutlined, VisibilityOutlined } from "@material-ui/icons";
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import Tile from "components/Tile/Tile";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import TableSearch from "components/Table/TableSearch";
import Button from "components/CustomButtons/Button";
import { useSelector } from "react-redux";
import _ from "lodash";
import FinanceProgramModal from "./FinaceProgramModal";
import { ProgramsPreScreen } from "../LenderWaterfall/components";
import { CommonDataContext } from "contexts/CommonDataContext";
import { createDate, programStatus } from "services/proposalsService";

const styles = {
  root: {
    "& .ReactTable .access-control-locations-cell": {
      overflow: "visible",
    },
    "& .ReactTable .rt-table": {
      overflow: "visible",
    },
    "& .ReactTable .rt-tbody": {
      overflow: "visible",
    },
    // to overwrite some styles from LocationsTreeViewDropdown component

    "& .access-control-locations-cell .tag-list .tag-item": {
      display: "block",
    },
    "& .access-control-locations-cell .tag-list .tag-item span": {
      color: "black",
      fontSize: "14px",
    },
    /*     "& .access-control-locations-cell .tag-list .tag-item:not(:last-child) .tag:after": {
        content: `", "`,
      }, */
    "& .access-control-locations-cell .tag-list .tag-item:nth-child(2)": {
      position: "initial",
    },
  },
  right: {
    textAlign: "right",
  },
  programActive: {
    padding: "4px 8px",
    color: "#2E7D32",
    backgroundColor: "#DCEDC8",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  programExpired: {
    padding: "4px 8px",
    color: "#424242",
    backgroundColor: "#EEEEEE",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
  programPrepared: {
    padding: "4px 8px",
    color: "#1565C0",
    backgroundColor: "#BBDEFB",
    width: "fit-content",
    minWidth: 76,
    textAlign: "center",
    borderRadius: 8,
  },
};

const useStyles = makeStyles(styles);

const GET_VP = gql`
  query {
    vendorProfile {
      territoryManagement
      lenderProfiles {
        id
        lenderName
        lenderProfileId
        lenderChannelCode
        lenderTypeCode
        dealerRelationship
        lenderLogo
      }
    }
  }
`;

const GET_FINANCE_PROGRAMS = gql`
  query GetFinancePrograms($accountId: ID!, $lenderProfileIds: [ID]) {
    financePrograms(accountId: $accountId, lenderProfileIds: $lenderProfileIds) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      createdDateTime
      modifiedDateTime
      financeProgramToLenderProfiles {
        lenderProfileId
      }
      prescreenCriteria {
        prescreenCriteriaId
        accountId
        active
        jsonCriteria
        name
        guidelines
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

const QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER = gql`
  query vendorProfilesByDynamicsLenderAccountId($dynamicsAccountId: ID!) {
    vendorProfilesByDynamicsLenderAccountId(dynamicsAccountId: $dynamicsAccountId) {
      id
      dynamicsId
      name
      accountId
      dynamicsAccountId
      vpStatus
      lenderProfiles {
        lenderProfileId
      }
    }
  }
`;

const statusTitles = {
  Active: "Finance program is availabled for you to use.",
  Expired: "Finance program has expired and is no longer available to use.",
  Prepared: "Finance program is ready to be used but not available yet.",
};

const FinanceProgramMgmt = props => {
  const { targetLenderProfileId } = props;
  const classes = useStyles();
  const userProfile = useSelector(state => state.userProfile);
  const account = useSelector(state => state.account);
  const { isLenderUser } = useContext(CommonDataContext);
  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [lenderProfiles, setLenderProfiles] = useState([]);
  const [financePrograms, setFinancePrograms] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSizeValue, setPageSizeValue] = useState(5);
  const [savedListSettings, setSavedListSettings] = useState({ page: 0, pageSize: 20 });
  const [openModal, setOpenModal] = useState(false);
  const [createProgram, setCreateProgram] = useState(false);
  const [financeProgram, setFinanceProgram] = useState(null);
  const [dealerVendorProfiles, setDealerVendorProfiles] = useState(null);

  const { data: fpData, loading: fpLoading, refetch: fetchFinancePrograms } = useQuery(GET_FINANCE_PROGRAMS, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
    variables: {
      accountId: account.id,
      lenderProfileIds: targetLenderProfileId ? [targetLenderProfileId] : [],
    },
    onCompleted: data => {
      if (data.financePrograms) {
        setFinancePrograms(data.financePrograms);
      }
    },
  });

  const { data: vpData } = useQuery(GET_VP, {
    fetchPolicy: "no-cache",
    context: { authRequired: true },
    onCompleted: data => {
      let lenderProfiles = data.vendorProfile?.lenderProfiles;
      if (lenderProfiles) {
        setLenderProfiles(lenderProfiles.filter(profile => profile.id));
      }
    },
  });

  const [
    fetchDealerVPs,
    {
      loading: isLoadingVendorProfilesAssociatedToLender,
      data: vendorProfilesAssociatedToLenderData,
      refetch: refetchVPs,
    },
  ] = useLazyQuery(QUERY_VENDOR_PROFILES_ASSOCIATED_TO_LENDER, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data.vendorProfilesByDynamicsLenderAccountId)
        setDealerVendorProfiles(data.vendorProfilesByDynamicsLenderAccountId);
    },
  });

  let columns = [
    {
      Header: "Name",
      accessor: "nameInternal",
    },
    {
      Header: "Status",
      Cell: ({ original }) => {
        const status = programStatus(original);

        return (
          <div title={statusTitles[status]} className={classes[`program${status}`]}>
            {status}
          </div>
        );
      },
    },
    {
      Header: "Created",
      accessor: "createdDateTime",
      Cell: ({ value }) => createDate(value).toLocaleDateString(),
    },
    {
      Header: "Modified",
      accessor: "modifiedDateTime",
      Cell: ({ value }) => createDate(value).toLocaleDateString(),
    },
    {
      Header: "Owned By",
      accessor: "accountId",
      Cell: props => {
        if (props.value === account.id) {
          return "You";
        } else if (isLenderUser) {
          return "Dealer";
        } else {
          return "Lender";
        }
      },
    },
    {
      Header: "Actions",
      id: "edit",
      accessor: "accountId",
      Cell: props => (
        <IconButton
          style={{ padding: 4, marginLeft: 6 }}
          onClick={() => handleEditClick(props.original)}
          color="primary"
          aria-label="change contact"
          component="button"
        >
          {props.value === account.id || isLenderUser ? (
            <>
              <EditOutlined style={{ fontSize: 24 }} />
              <span style={{ fontSize: 12, marginLeft: 4 }}>Edit</span>
            </>
          ) : (
            <>
              <VisibilityOutlined style={{ fontSize: 24 }} />
              <span style={{ fontSize: 12, marginLeft: 4 }}>View</span>
            </>
          )}
        </IconButton>
      ),
    },
  ];

  const findLender = props => {
    return lenderProfiles?.find(lp => lp.id === _.get(props, "value[0].lenderProfileId"));
  };

  if (isLenderUser) {
    columns.splice(1, 0, {
      Header: "Dealers (# of)",
      accessor: "financeProgramToLenderProfiles",
      Cell: props => {
        return (
          <div style={{ width: "100%", textAlign: "center" }}>
            {props.value && dealerVendorProfiles
              ? [...dealerVendorProfiles]?.filter(vp =>
                  props.value.map(lp => lp.lenderProfileId)?.includes(_.get(vp, "lenderProfiles[0].lenderProfileId"))
                )?.length
              : ""}
          </div>
        );
      },
    });
  } else {
    columns.splice(1, 0, {
      Header: "Lender",
      accessor: "financeProgramToLenderProfiles",
      Cell: props => {
        const lender = findLender(props);

        return <>{lender?.lenderName}</>;
      },
    });
  }

  const handleEditClick = program => {
    setFinanceProgram({
      accountId: program.accountId,
      financeProgramId: program.financeProgramId,
      nameInternal: program.nameInternal,
      paymentOptionsConfiguration: program.paymentOptionsConfiguration,
      lenderProfileIds:
        program.financeProgramToLenderProfiles?.length > 0
          ? [...program.financeProgramToLenderProfiles].map(lp => lp.lenderProfileId)
          : [],
      validDateEnd: program.validDateEnd,
      validDateStart: program.validDateStart,
      prescreenCriteria: program.prescreenCriteria,
      financeProgramModificationSettings: program.financeProgramModificationSettings,
    });
    setOpenModal(true);
  };

  useEffect(() => {
    if (isLenderUser) {
      fetchDealerVPs({
        variables: {
          dynamicsAccountId: account?.dynamicsAccountId,
        },
      });
    }
  }, [account]);

  return (
    <>
      {!fpLoading && (
        <div className={classes.root}>
          <Tile>
            <TableHeader
              filterComps={
                <TableSearch
                  setPageNumber={setPageNumber}
                  setSavedListSettings={setSavedListSettings}
                  searchRef={searchRef}
                  savedListSettings={savedListSettings}
                />
              }
            >
              {!_.includes(["creditMgr"], userProfile.vendorContactRole) && (
                <Button
                  color="primary"
                  size="sm"
                  data-tour="add-user-button"
                  onClick={() => {
                    setCreateProgram(true);
                    setOpenModal(true);
                  }}
                >
                  <AddOutlined />
                  Add Program
                </Button>
              )}
            </TableHeader>
            <Table
              color="primary"
              data={financePrograms?.sort((a, b) => a?.nameInternal?.localeCompare(b?.nameInternal)) || []}
              columns={columns}
              pageSize={pageSizeValue}
              pageNumber={pageNumber}
              defaultPageSize={savedListSettings.pageSize}
              showPaginationTop={false}
              showPaginationBottom={true}
              onPageChange={changePage => {
                setPageNumber(changePage);
              }}
              onPageSizeChange={changePageSize => {
                setPageSizeValue(changePageSize);
                setPageNumber(0);
              }}
              className={classes.root + " -highlight"}
              NoDataComponent={() => null}
            />
          </Tile>
        </div>
      )}
      <ProgramsPreScreen
        open={openModal}
        onClose={() => {
          setCreateProgram(false);
          setOpenModal(false);
        }}
        lenderProfiles={lenderProfiles}
        dealerVendorProfiles={dealerVendorProfiles}
        create={createProgram}
        refetchPrograms={() => {
          fetchFinancePrograms();
        }}
        financeProgram={financeProgram}
        isLenderUser={false}
        targetLenderProfileId={targetLenderProfileId}
      />

      {/* <FinanceProgramModal
        open={openModal}
        onClose={() => {
          setCreateProgram(false);
          setOpenModal(false);
        }}
        lenderProfiles={lenderProfiles}
        dealerVendorProfiles={dealerVendorProfiles}
        create={createProgram}
        refetchPrograms={() => fetchFinancePrograms()}
        financeProgram={financeProgram}
        isLenderUser={isLenderUser}
      /> */}
    </>
  );
};

export default FinanceProgramMgmt;
