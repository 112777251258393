import React from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { rolesSettingsHumanReadable, rolesSettingsKeys } from "../../../services/pcwService";
import { vendorContactRoleHumanReadable } from "../../../utils";
import _ from "lodash";
import { theme } from "../../../theme";

export function RoleSettingSelect({
  rolesSettingsSelected,
  rolesSettingsSelectedKey,
  updateRolesSettingsValue,
  itemType,
  optionsByRoleSettingKey,
  isHideHelperText,
}) {
  const vendorContactRolesKeys = _.keys(vendorContactRoleHumanReadable);
  const isInversed = !!_.get(optionsByRoleSettingKey, `${rolesSettingsSelectedKey}.isInversed`);

  const getLabelText = () => {
    return (
      _.get(optionsByRoleSettingKey, `${rolesSettingsSelectedKey}.label`) ||
      `${rolesSettingsHumanReadable[rolesSettingsSelectedKey]} For:`
    );
  };

  const getHelperText = () => {
    const getHelperTextByRoleSettingKey = key => {
      const getItemTypeDisplayValue = itemType => _.startCase(_.upperFirst(itemType));

      const helperTextsByKey = {
        [rolesSettingsKeys.masked]: () => `Field will be displayed as *Access Restricted* instead of the real value`,
        [rolesSettingsKeys.hidden]: () => `${getItemTypeDisplayValue(itemType)} will be hidden for selected roles`,
        [rolesSettingsKeys.readOnly]: () =>
          `${getItemTypeDisplayValue(itemType)} will be unavailable for editing for selected roles`,
        [rolesSettingsKeys.hiddenOnPDF]: () => `${getItemTypeDisplayValue(itemType)} will be hidden on PDF`,
        [rolesSettingsKeys.reportsHidden]: () => `Reports will be hidden for selected roles`,
        [rolesSettingsKeys.assignedOnly]: () =>
          `The selected roles will be able to see only those ${getItemTypeDisplayValue(
            itemType
          )} that are assigned to them`,
        [rolesSettingsKeys.locationBased]: () =>
          `You will be able to set Access Control by specific Locations for the selected roles`,
        [rolesSettingsKeys.documentsByTypeSettingsHidden]: () =>
          `Documents By Type Settings will be hidden on portal setup page for selected roles`,
      };

      return helperTextsByKey[key] ? helperTextsByKey[key]() : "";
    };

    return (
      _.get(optionsByRoleSettingKey, `${rolesSettingsSelectedKey}.helperText`) ||
      getHelperTextByRoleSettingKey(rolesSettingsSelectedKey)
    );
  };

  const onChangeHandler = newValue => {
    const valueToSet = isInversed ? _.difference(vendorContactRolesKeys, newValue) : newValue;
    updateRolesSettingsValue(valueToSet, rolesSettingsSelectedKey);
  };

  const getValue = () => {
    return isInversed
      ? _.reject(vendorContactRolesKeys, roleKey => _.includes(rolesSettingsSelected, roleKey))
      : _.filter(vendorContactRolesKeys, roleKey => _.includes(rolesSettingsSelected, roleKey));
  };

  const value = getValue();
  return (
    <Grid item container>
      <FormControl fullWidth>
        <InputLabel htmlFor={`roles-setting-${rolesSettingsSelectedKey}`}>{getLabelText()}</InputLabel>
        <Select
          labelId={`roles-setting-${rolesSettingsSelectedKey}`}
          multiple
          renderValue={() => {
            return _(value)
              .map(selectedRole => vendorContactRoleHumanReadable[selectedRole])
              .join(", ");
          }}
          onChange={e => onChangeHandler(e.target.value)}
          value={value}
          style={{ width: "100%" }}
          MenuProps={{
            getContentAnchorEl: null,
            variant: "menu",
          }}
        >
          {_.map(vendorContactRolesKeys, roleKey => {
            const roleDisplayLabel = vendorContactRoleHumanReadable[roleKey];
            return (
              <MenuItem key={roleKey} value={roleKey} name={roleDisplayLabel}>
                <Checkbox checked={value.indexOf(roleKey) > -1} style={{ color: theme.palette.primary.main }} />
                <ListItemText primary={roleDisplayLabel} />
              </MenuItem>
            );
          })}
        </Select>
        {!isHideHelperText && <FormHelperText>{getHelperText()}</FormHelperText>}
      </FormControl>
    </Grid>
  );
}
