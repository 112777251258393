import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import _ from "lodash";
export const TaxIdField = ({ fieldConfig }) => {
  const [value, setValue] = useState(undefined);

  return (
    <TextField
      style={{ pointerEvents: "none" }}
      fullWidth={fieldConfig.fullWidth}
      disabled={fieldConfig.disabled}
      variant={fieldConfig.variant}
      label={_.get(fieldConfig, "displayLabel.en", "")}
      helperText={_.get(fieldConfig, "errorMessage.en", "")}
      required={fieldConfig.required}
      value={""}
    />
  );
};
