import React, { useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { DocumentContext } from "contexts/DocumentContext";
import MessageModal from "components/MessageModal";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import TemplateUploadModal from "./TemplateUploadModal";
import Tile from "../../components/Tile/Tile";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import DocSetModal from "./DocSetModal";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import TemplateDetailModal from "./TemplateDetailModal";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DocSetDetailModal from "./DocSetDetailModal";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDocSetModal from "./DeleteDocSetModal";
import Tooltip from "@material-ui/core/Tooltip";

const DEACTIVATE_TEMPLATE = gql`
  mutation($id: ID!) {
    deactivateTemplate(id: $id)
  }
`;

const TEMPLATES = gql`
  query($accountId: ID) {
    templates(accountId: $accountId) {
      templateId
      docusignTemplateId
      name
      description
      status
      active
      createdBy
      source
    }
  }
`;

const DOC_SETS = gql`
  query($accountId: ID) {
    docSets(accountId: $accountId) {
      docSetId
      name
      docusignTemplateIds
      approvalTerms
      description
      status
      active
      createdBy
      templateName
    }
  }
`;

const useStyles = makeStyles(() => ({
  clearButton: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  tileCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    "@media (max-width: 768px)": {
      flexWrap: "wrap",
    },
  },
  cardIconTitle: {
    ...cardTitle,
  },
  flexJustifyEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default function DocList() {
  const classes = useStyles();
  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  let templatesTableData = [[]];
  let docSetsTableData = [[]];
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [openDocSetModal, setOpenDocSetModal] = useState(false);
  const [openTemplateDetailModal, setOpenTemplateDetailModal] = useState(false);
  const [selectedTemplateRow, setSelectedTemplateRow] = useState("");
  const [openDocSetDetailModal, setOpenDocSetDetailModal] = useState(false);
  const [openDeleteDocSetDetailModal, setOpenDeleteDocSetDetailModal] = useState(false);
  const [selectedDocSetRow, setSelectedDocSetRow] = useState("");

  const [deactivateTemplateRequest, { data: deactivateTemplateResponseData }] = useMutation(DEACTIVATE_TEMPLATE, {
    context: { authRequired: true },
  });

  const documentContext = useContext(DocumentContext);
  const handleOpenMessageModal = () => setOpenMessageModal(true);
  const handleCloseMessageModal = () => setOpenMessageModal(false);
  const handleOpenDocSetModal = () => setOpenDocSetModal(true);
  const handleCloseDocSetModal = () => setOpenDocSetModal(false);
  const handleOpenTemplateDetailModal = row => (setSelectedTemplateRow(row), setOpenTemplateDetailModal(true));
  const handleCloseTemplateDetailModal = () => setOpenTemplateDetailModal(false);
  const handleOpenDocSetDetailModal = row => (setSelectedDocSetRow(row), setOpenDocSetDetailModal(true));
  const handleCloseDocSetDetailModal = () => setOpenDocSetDetailModal(false);
  const handleOpenDeleteDocSetDetailModal = row => (setSelectedDocSetRow(row), setOpenDeleteDocSetDetailModal(true));
  const handleCloseDeleteDocSetDetailModal = () => setOpenDeleteDocSetDetailModal(false);

  const [lastDocument, setLastDocument] = useState();

  const { data: templates, refetch: templatesRefetch, loading: templatesLoading, error: templatesError } = useQuery(
    TEMPLATES,
    {
      variables: {
        accountId: account.id,
      },
    }
  );

  const { data: docSets, refetch: docSetsRefetch, loading: docSetsLoading, error: docSetsError } = useQuery(DOC_SETS, {
    variables: {
      accountId: account.id,
    },
  });

  if (templatesLoading) console.log("Loading templates...");
  if (templatesError) console.log(`Error! ${templatesError.message}`);
  if (docSetsLoading) console.log("Loading docSetsLoading...");
  if (docSetsError) console.log(`Error! ${docSetsError.message}`);

  const mapData = (key, name, description, createdBy, status, link, docusignTemplateId) => {
    return { key, name, description, createdBy, status, link, docusignTemplateId };
  };

  const mapDocSetData = (key, name, description, approvalTerms, createdBy, templateName) => {
    return { key, name, description, approvalTerms, createdBy, templateName };
  };

  const templatesTableHead = [
    "Name",
    "Descrption",
    "Uploaded By",
    "DocuSign Template ID",
    "Download",
    account.dynamicsAccountId === "5e7f2efe-5c02-eb11-8125-065d4ad39109" ? "Edit" : "",
  ];
  const docSetsTableHead = ["Name", "Descrption", "Created By"];

  function addTemplatesToTableData(templates) {
    const templatesRows = [];
    if (!!templates && templates.length) {
      if (account.dynamicsAccountId === "5e7f2efe-5c02-eb11-8125-065d4ad39109") {
        templates.forEach(row => {
          const tableRow = [
            row.name,
            row.description,
            row.createdBy,
            row.docusignTemplateId,
            <IconButton aria-label="delete" size="sm" href={row.link} target="_blank">
              <CloudDownloadIcon />
            </IconButton>,
            <IconButton
              aria-label="edit"
              size="sm"
              key={row.templateId}
              onClick={() => handleOpenTemplateDetailModal(row)}
            >
              <EditIcon />
            </IconButton>,
          ];
          templatesRows.push(tableRow);
        });
      } else {
        templates.forEach(row => {
          const tableRow = [
            row.name,
            row.description,
            row.createdBy,
            row.docusignTemplateId,
            <IconButton aria-label="delete" size="sm" href={row.link} target="_blank">
              <CloudDownloadIcon />
            </IconButton>,
          ];
          templatesRows.push(tableRow);
        });
      }
    }
    templatesTableData = [...templatesRows];
  }

  function addDocSetsToTableData(docSets) {
    const docSetsRows = [];
    if (!!docSets && docSets.length) {
      docSets.forEach(row => {
        const tableRow = [
          row.name,
          row.description,
          row.createdBy,
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              size="sm"
              key={row.key}
              onClick={() => handleOpenDeleteDocSetDetailModal(row)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Edit">
            <IconButton aria-label="delete" size="sm" key={row.key} onClick={() => handleOpenDocSetDetailModal(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>,
        ];
        docSetsRows.push(tableRow);
      });
    }
    docSetsTableData = [...docSetsRows];
  }

  let allTemplates;
  if (!!templates && !!templates.templates) {
    allTemplates = templates.templates.map(row =>
      mapData(row.templateId, row.name, row.description, row.createdBy, row.status, row.source, row.docusignTemplateId)
    );
  }
  let allDocSets;
  if (!!docSets && !!docSets.docSets) {
    allDocSets = docSets.docSets.map(row =>
      mapDocSetData(row.docSetId, row.name, row.description, row.approvalTerms, row.createdBy, row.templateName)
    );
  }
  addTemplatesToTableData(allTemplates);
  addDocSetsToTableData(allDocSets);

  useEffect(() => {
    //refetch data after lastDoc context changed
    templatesRefetch();
  }, [templatesRefetch, documentContext.lastDoc]);

  useEffect(() => {
    //refetch data after doc deactivated
    templatesRefetch();
  }, [templatesRefetch, deactivateTemplateResponseData]);

  const handleDeactivateDoc = async value => {
    try {
      await deactivateTemplateRequest({
        variables: {
          id: value,
        },
      });
      await templatesRefetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DocumentContext.Provider value={{ lastDoc: lastDocument, setLastDocument }}>
      <Tile
        title="Templates"
        titleButtonComponent={
          <>
            <TemplateUploadModal
              templatesRefetch={templatesRefetch}
              buttonDescription={() => {
                return (
                  <>
                    <CloudUploadIcon style={{ marginRight: "8px" }} /> Add Template
                  </>
                );
              }}
            />
          </>
        }
        useCollapsing={true}
      >
        <Table
          tableHead={templatesTableHead}
          tableData={templatesTableData}
          customCellClasses={[classes.flexJustifyEnd]}
          customClassesForCells={[5]}
          customHeadCellClasses={[classes.flexJustifyEnd]}
          customHeadClassesForCells={[5]}
        />
        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseMessageModal}
          title={"Loading"}
          message={"Please wait while we prepare your document."}
        />
      </Tile>
      <br />
      <Tile
        title="Doc Sets"
        titleButtonComponent={
          <>
            {!!templates && !!templates.templates && (
              <Button color="primary" size="small" startIcon={<CreateNewFolderIcon />} onClick={handleOpenDocSetModal}>
                Create Doc Set
              </Button>
            )}
          </>
        }
        useCollapsing={true}
      >
        <Table
          tableHead={docSetsTableHead}
          tableData={docSetsTableData}
          customCellClasses={[classes.flexJustifyEnd]}
          customClassesForCells={[5]}
          customHeadCellClasses={[classes.flexJustifyEnd]}
          customHeadClassesForCells={[5]}
        />
        <MessageModal
          isOpen={openMessageModal}
          handleCloseModal={handleCloseMessageModal}
          title={"Loading"}
          message={"Please wait while we prepare your document."}
        />
      </Tile>
      <DocSetModal
        onClick={handleOpenDocSetModal}
        open={openDocSetModal}
        handleOpen={handleOpenDocSetModal}
        handleCloseDocSetModal={handleCloseDocSetModal}
        templates={!!templates && !!templates.templates ? templates.templates : null}
        docSetsRefetch={docSetsRefetch}
      />
      <TemplateDetailModal
        open={openTemplateDetailModal}
        handleClose={handleCloseTemplateDetailModal}
        template={selectedTemplateRow}
        templatesRefetch={templatesRefetch}
      />
      <DocSetDetailModal
        open={openDocSetDetailModal}
        handleClose={handleCloseDocSetDetailModal}
        selectedDocSet={selectedDocSetRow}
        docSetRefetch={docSetsRefetch}
      />
      <DeleteDocSetModal
        open={openDeleteDocSetDetailModal}
        handleClose={handleCloseDeleteDocSetDetailModal}
        selectedDocSet={selectedDocSetRow}
        docSetRefetch={docSetsRefetch}
      />
    </DocumentContext.Provider>
  );
}
