import React, { useEffect, useState } from "react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Button as MUIButton,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import FinanceProgramForm from "components/FinanceProgramForm";
import Button from "components/CustomButtons/Button";
import { useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const styles = makeStyles(theme => ({
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    marginLeft: "auto",
    padding: 4,
  },
  textField: {
    "& p > MuiFormHelperText-root": {
      color: "red",
    },
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  button: {
    backgroundColor: "#0086fc",
    color: "white",
  },
  secondaryButton: {
    backgroundColor: "#e4e4e4",
  },
  inputStart: {
    "& .MuiInputBase-adornedStart .MuiInputBase-inputAdornedStart": {
      textAlign: "right",
    },
  },
  confirmTextField: {
    "& label": {
      fontWeight: 100,
      fontSize: "16px",
    },
  },
  closeIcon: {
    color: "gray",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "50%",
    opacity: 0.8,
    fontSize: 24,
  },
  shake: {
    animation: "$shake 0.15s infinite",
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "25%": { transform: "rotate(0.5deg)" },
    "50%": { transform: "rotate(0eg)" },
    "75%": { transform: "rotate(-0.5deg)" },
    "00%": { transform: "rotate(0deg)" },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
  selectField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
      background: "#fff",
    },
  },
  locationTree: {
    padding: "10px 0",
    "& .tag-item": {
      position: "inherit !important",
    },
    "& .tag-item span": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "#f4f4f4",
      border: "1px solid #e9e9e9",
      padding: "2px 0 2px 8px",
      borderRadius: "4px",
      display: "inline-block",
    },
    "& .tag-item button": {
      display: "inline-block",
      fontSize: "100%",
    },
    "& .tag-item span.placeholder": {
      color: "#000000 !important",
      fontSize: "14px !important",
      marginRight: 8,
      backgroundColor: "transparent",
      border: "none",
      padding: "0",
      borderRadius: "0",
      display: "inline-block",
    },
  },
}));

const CREATE_FINANCE_PROGRAM = gql`
  mutation CreateFinanceProgram($input: CreateFinanceProgramInput!) {
    createFinanceProgram(input: $input) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

const UPDATE_FINANCE_PROGRAM = gql`
  mutation UpdateFinanceProgram($input: UpdateFinanceProgramInput!) {
    updateFinanceProgram(input: $input) {
      accountId
      financeProgramId
      nameInternal
      paymentOptionsConfiguration
      validDateEnd
      validDateStart
      prescreenCriteria {
        accountId
        active
        jsonCriteria
        name
      }
      financeProgramModificationSettings {
        markup
      }
    }
  }
`;

const FinanceProgramModal = ({
  open,
  onClose,
  lenderProfiles,
  create,
  refetchPrograms,
  financeProgram,
  isLenderUser,
  dealerVendorProfiles,
  targetLenderProfileId,
}) => {
  const classes = styles();
  const account = useSelector(state => state.account);
  const [loading, setLoading] = useState(false);
  const [initFinanceProgram, setInitFinanceProgram] = useState(null);
  const [removingFP, setRemovingFP] = useState(false);
  const [program, setProgram] = useState({
    accountId: account.id,
    lenderProfileIds: [],
    nameInternal: "",
    validDateStart: "",
    validDateEnd: "",
    paymentOptionsConfiguration: {
      financeProgramName: "",
      flatFees: [],
      markups: [],
      numberOfAdvancePayments: 0,
      paymentPeriodTiming: "",
      terms: [],
      type: "LOAN",
    },
  });

  useEffect(() => {
    if (targetLenderProfileId) {
      if (financeProgram) {
        setProgram({ ...financeProgram, lenderProfileIds: [targetLenderProfileId] });
      } else {
        setProgram({ ...program, lenderProfileIds: [targetLenderProfileId] });
      }
    }
  }, [targetLenderProfileId]);
  const [createLenderProgram] = useMutation(CREATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
  });
  const [updateLenderProgram] = useMutation(UPDATE_FINANCE_PROGRAM, {
    context: { authRequired: true },
  });

  const handleSubmit = async () => {
    setLoading(true);

    if (create) {
      await createLenderProgram({ variables: { input: { ...program } } });
    } else {
      await updateLenderProgram({
        variables: {
          input: {
            ...program,
            accountId: undefined,
            lenderProfileIds: undefined,
            lenderProfileIdsToDisassociate: _.isEqual(initFinanceProgram.lenderProfileIds, program.lenderProfileIds)
              ? undefined
              : initFinanceProgram.lenderProfileIds.filter(id => !program.lenderProfileIds.includes(id)),
            lenderProfileIdsToAssociate: program.lenderProfileIds,
          },
        },
      });
    }

    refetchPrograms();
    setLoading(false);
    onClose();
  };

  const handleClose = () => {
    setInitFinanceProgram(null);
    setProgram({
      accountId: account.id,
      lenderProfileIds: [],
      nameInternal: "",
      validDateStart: "",
      validDateEnd: "",
      paymentOptionsConfiguration: {
        financeProgramName: "",
        flatFees: [],
        markups: [],
        numberOfAdvancePayments: 0,
        paymentPeriodTiming: "",
        rate: 0,
        terms: [],
        type: "LOAN",
      },
    });
    onClose();
  };
  useEffect(() => {
    console.log({ program, financeProgram });
  }, [program, financeProgram]);
  useEffect(() => {
    if (financeProgram?.paymentOptionsConfiguration) {
      const programToSet = {
        ...program,
        ...financeProgram,
      };
      setInitFinanceProgram(programToSet);
      if (programToSet.nameInternal !== "") setProgram(programToSet);
    }
  }, [financeProgram]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
        <h4>
          <b>Finance Program</b>
        </h4>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      {loading ? (
        <DialogContent
          style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: 320 }}
        >
          <CircularProgress size={72} />
        </DialogContent>
      ) : (
        <>
          {removingFP ? (
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: 320,
              }}
            >
              <h4>Are you sure you want to remove this finance program?</h4>
              <div style={{ display: "flex", gap: 4, marginTop: 24 }}>
                <MUIButton color="primary" onClick={() => setRemovingFP(false)}>
                  No
                </MUIButton>
                <MUIButton
                  color="default"
                  onClick={async () => {
                    setLoading(true);
                    await updateLenderProgram({
                      variables: {
                        input: {
                          financeProgramId: program.financeProgramId,
                          active: false,
                        },
                      },
                    });
                    refetchPrograms();
                    setRemovingFP(false);
                    setLoading(false);
                    handleClose();
                  }}
                >
                  Yes
                </MUIButton>
              </div>
            </DialogContent>
          ) : (
            <DialogContent>
              {lenderProfiles && (
                <FormControl className={classes.selectField} variant="outlined" style={{ width: "100%" }}>
                  <InputLabel shrink={true} id="lender-profile-label">
                    {isLenderUser ? "Dealers" : "Lender"}
                  </InputLabel>
                  <Select
                    labelId="lender-profile-label"
                    id="lender-profile"
                    defaultValue={isLenderUser ? [] : ""}
                    value={isLenderUser ? program.lenderProfileIds : program.lenderProfileIds[0]}
                    onChange={e =>
                      isLenderUser
                        ? setProgram({ ...program, lenderProfileIds: e.target.value })
                        : setProgram({ ...program, lenderProfileIds: [e.target.value] })
                    }
                    label="Lender"
                    style={{ height: 40 }}
                    multiple={isLenderUser}
                    renderValue={selected =>
                      isLenderUser
                        ? dealerVendorProfiles &&
                          [...dealerVendorProfiles]
                            .filter(vp => selected.includes(vp.lenderProfiles[0]?.lenderProfileId))
                            .map(vp => vp.name.replace("VP - ", ""))
                            .join(", ")
                        : lenderProfiles.find(lp => lp.id === selected)?.lenderName
                    }
                    disabled={
                      isLenderUser ? false : program.accountId !== account.id || !_.isEmpty(targetLenderProfileId)
                    }
                  >
                    {isLenderUser
                      ? dealerVendorProfiles &&
                        dealerVendorProfiles
                          .map(vp => ({ ...vp, name: vp.name.replace("VP - ", "") }))
                          .sort((a, b) => a?.name?.localeCompare(b?.name))
                          .map((vp, i) => (
                            <MenuItem key={i} value={vp.lenderProfiles[0]?.lenderProfileId}>
                              {isLenderUser && (
                                <Checkbox
                                  checked={program.lenderProfileIds.indexOf(vp.lenderProfiles[0]?.lenderProfileId) > -1}
                                />
                              )}
                              <ListItemText primary={vp.name} />
                            </MenuItem>
                          ))
                      : lenderProfiles
                          .sort((a, b) => a?.lenderName?.localeCompare(b?.lenderName))
                          .map((lp, i) => (
                            <MenuItem key={i} value={lp.id}>
                              {isLenderUser && <Checkbox checked={program.lenderProfileIds.indexOf(lp.id) > -1} />}
                              <ListItemText primary={lp.lenderName} />
                            </MenuItem>
                          ))}
                  </Select>
                </FormControl>
              )}
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                label="Finance Program Name"
                variant="outlined"
                value={program.nameInternal ? program.nameInternal : ""}
                onChange={e => setProgram({ ...program, nameInternal: e.target.value })}
                fullWidth
                margin="normal"
                disabled={isLenderUser ? false : program.accountId !== account.id}
              />
              <FinanceProgramForm
                initFinanceProgram={
                  !_.isEmpty(initFinanceProgram)
                    ? {
                        ...initFinanceProgram?.paymentOptionsConfiguration,
                        validDateEnd: initFinanceProgram?.validDateEnd,
                        validDateStart: initFinanceProgram?.validDateStart,
                      }
                    : null
                }
                onValuesChange={values =>
                  setProgram({
                    ...program,
                    paymentOptionsConfiguration: {
                      ...values,
                      validDateStart: undefined,
                      validDateEnd: undefined,
                    },
                    validDateEnd: values.validDateEnd,
                    validDateStart: values.validDateStart,
                  })
                }
                isProgramOwner={isLenderUser || program.accountId === account.id}
              />
            </DialogContent>
          )}
        </>
      )}
      <DialogActions>
        {!create && !removingFP && (isLenderUser || program.accountId === account.id) && (
          <MUIButton color="default" style={{ color: "#f44336" }} onClick={() => setRemovingFP(true)}>
            Remove
          </MUIButton>
        )}
        {(isLenderUser || program.accountId === account.id) && (
          <Button color="primary" size="md" data-tour="submit-button" onClick={() => handleSubmit()}>
            {create ? "Create" : "Update"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FinanceProgramModal;
