import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Page } from "../FormBuilder/Page";
import { Button, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Modal from "@material-ui/core/Modal";
import Tile from "../../../../components/Tile/Tile";
import { PageInfoPanel } from "../PageInfoPanel/PageInfoPanel";
import { complexFieldNamesSettersBySectionType, getNewPageConfiguration } from "../../../../services/dynamicOCAService";
import _ from "lodash";
const useStyles = makeStyles(theme => ({
  area: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  pages: {
    marginBottom: "50px",
    marginTop: "50px",
  },
  navBar: {
    padding: "15px 40px 15px",
    position: "fixed",
    bottom: 0,
    background: "white",
    width: "calc(100% - 400px) !important",
  },
  navBarButton: {
    textTransform: "none",
    fontSize: "12px",
    fontWeight: "400",
  },
  navBarButtonBlue: {
    color: theme.palette.primary.main,
  },
  navBarDeleteButton: {
    color: "#8D1B11",
  },
  navBarItem: {
    height: "100%",
  },
  navBarPageInfo: {
    paddingRight: "10px",
    color: "#818181",
    fontSize: "14px",
    margin: 0,
  },
}));
const getModalStyle = () => {
  return {
    position: "absolute",
    minHeight: "30%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    border: "1px solid #000",
    padding: 10,
  };
};

const PagePositionModal = ({ pagePositionMode, addNewPage, changePagePosition, totalPages }) => {
  const [modalStyle] = useState(getModalStyle);

  const [pageIndex, setPageIndex] = useState(0);

  const isAddNewPageMode = pagePositionMode === "add-new-page";
  const handler = isAddNewPageMode ? addNewPage : changePagePosition;

  return (
    <div style={modalStyle}>
      <Tile title={isAddNewPageMode ? "Add New Page" : "Change Page Position"} maxHeightLimitByScreen={false}>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h4>Choose Position</h4>
          </Grid>
          {isAddNewPageMode && (
            <Grid item container>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Button
                  variant="contained"
                  color={"primary"}
                  fullWidth={true}
                  onClick={e => {
                    handler(0);
                  }}
                >
                  Add Before First Page
                </Button>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Button
                  variant="contained"
                  color={"primary"}
                  fullWidth={true}
                  onClick={e => {
                    handler(totalPages + 1);
                  }}
                >
                  Add After Last Page
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                <InputLabel id="rule-satisfaction-label">Position</InputLabel>
                <Select
                  value={pageIndex}
                  variant="standard"
                  fullWidth={true}
                  labelId="rule-satisfaction-label"
                  onChange={e => {
                    setPageIndex(e.target.value);
                  }}
                >
                  {_.range(0, totalPages + 1).map(index => (
                    <MenuItem key={`pageNumber.${index}`} value={index}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                <Button
                  variant="contained"
                  color={"primary"}
                  fullWidth={true}
                  onClick={e => {
                    handler(pageIndex);
                  }}
                >
                  {isAddNewPageMode ? `Add Before Page #${pageIndex + 1}` : `Move to #${pageIndex + 1}`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Tile>
    </div>
  );
};

export function Area({ setSelectedItem, config, setConfig, setSelectedSection, selectedSection }) {
  const classes = useStyles();
  const [isEditorMode, setEditorMode] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);

  const [pagePositionDialogOpen, setPagePositionDialogOpen] = useState(false);
  const [pagePositionMode, setPagePositionMode] = useState(); // "add-new-page" | "change-page-position"
  const [isFormElementMoving, setIsFormElementMoving] = useState(false);
  const [formElementMovingSettings, setFormElementMovingSettings] = useState({});

  const updateFieldNameOnMoving = (field, sectionToMove) => {
    if (complexFieldNamesSettersBySectionType[sectionToMove.sectionType]) {
      const initialFieldName = field.config.fieldName.slice(field.config.fieldName.lastIndexOf(".") + 1);
      field.config.fieldName = complexFieldNamesSettersBySectionType[sectionToMove.sectionType](
        initialFieldName,
        sectionToMove
      );
    }
  };

  const updateSection = useCallback(
    function(index) {
      return function(section, pageIndex) {
        setConfig(prevConfig => {
          const currentPage = { ...prevConfig[pageIndex] };

          if (!currentPage.sections || currentPage.sections.length === 0) {
            currentPage.sections = [section];
          } else {
            currentPage.sections = currentPage.sections.map((s, i) => (i === index ? section : s));
          }

          return prevConfig.map((p, i) => (i === pageIndex ? currentPage : p));
        });
      };
    },
    [setConfig]
  );

  const deleteSection = useCallback(
    function(index) {
      return function(sectionIndex, pageIndex) {
        setConfig(prevConfig => {
          const currentPage = { ...prevConfig[pageIndex] };
          if (!currentPage.sections || currentPage.sections.length === 0) {
            console.log("No sections to remove!");
            return prevConfig;
          } else {
            currentPage.sections.splice(sectionIndex, 1);
          }
          return prevConfig.map((p, i) => (i === pageIndex ? currentPage : p));
        });
      };
    },
    [setConfig]
  );

  const addNewPage = (position = 0) => {
    setConfig(prevConfig => {
      const newConfig = [...prevConfig];
      newConfig.splice(position, 0, getNewPageConfiguration());
      return newConfig;
    });
    setPagePositionDialogOpen(false);
  };

  const changePagePosition = (newPosition = 0) => {
    if (newPosition !== pageNumber) {
      setConfig(prevConfig => {
        const newConfig = [...prevConfig];
        const currentPage = { ...newConfig[pageNumber] };

        if (newPosition > pageNumber) {
          for (let i = pageNumber; i <= newPosition; i++) {
            newConfig[i] = newConfig[i + 1];
          }
        } else {
          for (let i = pageNumber; i >= newPosition; i--) {
            newConfig[i] = newConfig[i - 1];
          }
        }
        newConfig[newPosition] = currentPage;
        return newConfig;
      });
    }

    setPagePositionDialogOpen(false);
  };

  function addSection(section, pageIndex) {
    setConfig(prevConfig => {
      const currentPage = config[pageIndex];
      if (currentPage.sections) {
        currentPage.sections = currentPage.sections.concat(section);
      } else {
        currentPage.sections = [section];
      }
      return prevConfig.map((page, index) => (index === pageIndex ? currentPage : page));
    });
  }

  const swapSections = useCallback(
    (indexA, indexB, pageIndex) => {
      setConfig(prevConfig => {
        const currentPage = prevConfig[pageIndex];
        const sections = [...currentPage.sections];
        const temp = { ...sections[indexA] };
        sections[indexA] = { ...sections[indexB] };

        sections[indexB] = temp;

        currentPage.sections = [...sections];

        return prevConfig.map((p, i) => (i === pageIndex ? currentPage : p));
      });
    },
    [config, setConfig]
  );

  const swapPages = useCallback(
    (indexA, indexB) => {
      setConfig(prevConfig => {
        const newConfig = [...prevConfig];
        const temp = { ...prevConfig[indexA] };
        newConfig[indexA] = { ...newConfig[indexB] };
        newConfig[indexB] = temp;

        return newConfig;
      });
    },
    [setConfig]
  );

  function swapFields(sectionIndex) {
    return function(indexA, indexB, pageIndex, indexSectionA, indexSectionB) {
      if (indexSectionA === indexSectionB) {
        setConfig(prevConfig => {
          return prevConfig.map((p, i) => {
            if (pageIndex === i) {
              const section = p.sections[sectionIndex];
              const temp = section.fields[indexA];

              section.fields[indexA] = section.fields[indexB];
              section.fields[indexB] = temp;

              return {
                ...p,
                sections: p.sections.map((s, i) => {
                  if (i === sectionIndex) {
                    return {
                      ...section,
                    };
                  }

                  return s;
                }),
              };
            }
            return p;
          });
        });
      } else {
        setConfig(prevConfig => {
          return prevConfig.map((p, i) => {
            if (pageIndex === i) {
              const sectionFieldsA = p.sections[indexSectionA].fields;
              const sectionFieldsB = p.sections[indexSectionB].fields;
              const fieldA = sectionFieldsA[indexA];
              const fieldB = sectionFieldsB[indexB];
              updateFieldNameOnMoving(fieldB, p.sections[indexSectionA]);
              updateFieldNameOnMoving(fieldA, p.sections[indexSectionB]);
              sectionFieldsA[indexA] = fieldB;
              sectionFieldsB[indexB] = fieldA;
            }
            return p;
          });
        });
      }
    };
  }

  const handleFieldInsertion = (pageIdxToInsert, sectionIdxToInsert, fieldIdxToInsert) => {
    setConfig(prevConfig => {
      return prevConfig.map((p, i) => {
        if (i !== pageIdxToInsert) {
          return p;
        }
        const pageToMoveFrom = prevConfig[formElementMovingSettings.pageIndex];
        const sectionToMoveFrom = pageToMoveFrom.sections[formElementMovingSettings.sectionIndex];
        const fieldToMove = sectionToMoveFrom.fields[formElementMovingSettings.fieldIndex];

        const pageToMoveIn = prevConfig[pageIdxToInsert];
        const sectionToMoveIn = pageToMoveIn.sections[sectionIdxToInsert];
        const sectionToMoveInFieldsCopy = [...sectionToMoveIn.fields];

        if (sectionToMoveFrom === sectionToMoveIn) {
          const tempObj = {}; // used to keep constant indexes of spliced array
          sectionToMoveInFieldsCopy.splice(formElementMovingSettings.fieldIndex, 1, tempObj);
          sectionToMoveInFieldsCopy.splice(fieldIdxToInsert, 0, fieldToMove);
          sectionToMoveInFieldsCopy.splice(sectionToMoveInFieldsCopy.indexOf(tempObj), 1);
          return {
            ...p,
            sections: p.sections.map((s, i) => {
              if (i === sectionIdxToInsert) {
                return {
                  ...s,
                  fields: sectionToMoveInFieldsCopy,
                };
              }

              return s;
            }),
          };
        }

        const sectionToMoveFromFieldsCopy = [...sectionToMoveFrom.fields];
        sectionToMoveFromFieldsCopy.splice(formElementMovingSettings.fieldIndex, 1);
        sectionToMoveInFieldsCopy.splice(fieldIdxToInsert, 0, fieldToMove);
        updateFieldNameOnMoving(fieldToMove, sectionToMoveIn);

        return {
          ...p,
          sections: p.sections.map((s, i) => {
            if (i === sectionIdxToInsert) {
              return {
                ...s,
                fields: sectionToMoveInFieldsCopy,
              };
            }
            if (i === formElementMovingSettings.sectionIndex) {
              return {
                ...s,
                fields: sectionToMoveFromFieldsCopy,
              };
            }

            return s;
          }),
        };
      });
    });
    setIsFormElementMoving(false);
    setFormElementMovingSettings({});
  };

  const handleCloseNewPageDialog = () => {
    setPagePositionDialogOpen(false);
  };

  return (
    <div className={classes.area}>
      <Grid className={classes.pages}>
        <Modal
          open={pagePositionDialogOpen}
          onClose={handleCloseNewPageDialog}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableScrollLock={true}
        >
          <PagePositionModal
            pagePositionMode={pagePositionMode}
            addNewPage={addNewPage}
            changePagePosition={changePagePosition}
            totalPages={_.size(config) - 1}
          />
        </Modal>
        {isEditorMode ? (
          <>
            <PageInfoPanel
              setSelectedSection={setSelectedSection}
              selectedSection={selectedSection}
              swapSections={swapSections}
              addSection={addSection}
              pageNumber={pageNumber}
              pageConfig={config[pageNumber]}
              templateConfig={config}
              setSelectedItem={setSelectedItem}
            />
            <Page
              isEditorMode={isEditorMode}
              setSelectedItem={setSelectedItem}
              config={config[pageNumber]}
              updateSection={updateSection}
              deleteSection={deleteSection}
              addSection={addSection}
              swapFields={swapFields}
              swapPages={swapPages}
              swapSections={swapSections}
              page={pageNumber}
              key={pageNumber}
              isFormElementMoving={isFormElementMoving}
              setIsFormElementMoving={setIsFormElementMoving}
              handleFieldInsertion={handleFieldInsertion}
              setFormElementMovingSettings={setFormElementMovingSettings}
            />
          </>
        ) : (
          <div>
            {config.map((page, index) => {
              return (
                <Page
                  isEditorMode={isEditorMode}
                  setSelectedItem={setSelectedItem}
                  config={page}
                  updateSection={updateSection}
                  deleteSection={deleteSection}
                  addSection={addSection}
                  swapFields={swapFields}
                  swapPages={swapPages}
                  swapSections={swapSections}
                  page={index}
                  key={index}
                  isFormElementMoving={isFormElementMoving}
                  setIsFormElementMoving={setIsFormElementMoving}
                  handleFieldInsertion={handleFieldInsertion}
                  setFormElementMovingSettings={setFormElementMovingSettings}
                />
              );
            })}
          </div>
        )}
      </Grid>
      <Grid container className={classes.navBar} justify="space-between">
        <Grid item xs={4}>
          <Grid container justify="flex-start" alignItems="center" className={classes.navBarItem}>
            {isEditorMode && (
              <Grid item>
                <p className={classes.navBarPageInfo}>Page {pageNumber + 1}</p>
                <Divider orientation="vertical" style={{ height: "70%" }} />
              </Grid>
            )}
            <Button
              onClick={() => setEditorMode(prevState => !prevState)}
              className={`${classes.navBarButton} ${classes.navBarButtonBlue}`}
            >
              {isEditorMode ? "View Mode" : "Editor Mode"}
            </Button>
          </Grid>
        </Grid>
        {isEditorMode && (
          <Grid item xs={4}>
            <Grid container justify="center" alignItems="center" className={classes.navBarItem}>
              <Button
                className={classes.navBarButton}
                startIcon={<ArrowBackIcon color="primary" />}
                onClick={() => setPageNumber(prevState => prevState - 1)}
                disabled={pageNumber === 0}
              >
                Previous Page
              </Button>
              <Divider orientation="vertical" style={{ height: "70%" }} />
              <Button
                className={classes.navBarButton}
                endIcon={<ArrowForwardIcon color="primary" />}
                onClick={() => setPageNumber(prevState => prevState + 1)}
                disabled={pageNumber === config.length - 1}
              >
                Next Page
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={4}>
          <Grid container justify="flex-end" alignItems="center" className={classes.navBarItem}>
            <Button
              onClick={() => {
                setPagePositionMode("change-page-position");
                setPagePositionDialogOpen(true);
              }}
              className={`${classes.navBarButton} ${classes.navBarButtonBlue}`}
            >
              Change Page Position
            </Button>
            <Divider orientation="vertical" style={{ height: "70%" }} />
            <Button
              onClick={() => {
                setPagePositionMode("add-new-page");
                setPagePositionDialogOpen(true);
              }}
              className={`${classes.navBarButton} ${classes.navBarButtonBlue}`}
            >
              Add New Page
            </Button>
            <Divider orientation="vertical" style={{ height: "70%" }} />
            <Button
              className={`${classes.navBarButton} ${classes.navBarDeleteButton}`}
              onClick={() => setConfig(prevState => prevState.filter((page, index) => index !== pageNumber))}
              disabled={config.length === pageNumber + 1}
            >
              Delete page
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
