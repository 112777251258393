import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useMutation, useQuery } from "@apollo/react-hooks";
import classNames from "classnames";
import { Box, Button, CircularProgress, Grid, Modal, Paper, Typography } from "@material-ui/core";
import { Check, Send } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import {
  blockTypes,
  getCreditAppHTMLForPDF,
  getDateTimeDisplayValue,
  getNewSignorAndGuarantorSignatureTaskForContact,
  getRecertificationTaskForContact,
  getSignatureTaskForContact,
  isItemHasRestrictionForRole,
  refSettingsByRefType,
  sectionTypes,
  uneditableFieldKeys,
  uneditableFieldTypes,
} from "../../../../services/creditAppModalService";
import { Q_CREDIT_APP } from "../../../../queries/common";
import {
  checkIfIndividualCreditApp,
  formatCurrency,
  formatPercentage,
  formatPhoneNumber,
  formatSSN,
} from "../../../../utils";

import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../../../services/locationsService";
import { rolesSettingsKeys } from "../../../../services/pcwService";
import { DCR_API_BASE_SERVER_URL } from "../../../../config";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import {
  ASSIGN_SALES_REP,
  GET_LOCATIONS,
  GET_SALES_REPS_FOR_ADMIN,
  UPDATE_ACCOUNT_CRM_QUERY,
  UPDATE_ACCOUNT_QUERY,
  UPDATE_CREDIT_APPLICATION_V2_QUERY,
  UPDATE_DYNAMICS_VO_LOCATION,
  UPDATE_VO_LOCATION,
  UPSERT_TASK,
} from "../../queries";
import iconPlusSvg from "../../../../assets/img/icons/plus.svg";
import iconMinusSvg from "../../../../assets/img/icons/minus.svg";
import { CreditApplicationRouterState } from "../../types";
import AuthLinkDialog from "../dialods/AuthLinkDialog";
import { CreditApplicationDetailsPanel } from "../panel/CreditApplicationDetailsPanel";
import { ValueOf } from "../../../../global";
import { creditAppTypes, signatureDateKeys, taskRegardingValues } from "../../../../pages/Prequal/constants";
import CreditAppModalEditableInput from "../../../../pages/VendorOpportunity/CreditAppModalEditableInput";
import CreditAppModifierConfirmModal from "../../../../pages/VendorOpportunity/CreditAppModifierConfirmModal";
import NewCreditAppModal from "../../../../pages/VendorOpportunity/NewCreditAppModal";
import { ContactModal, RequestRecertificationModal } from "modules/creditApplicationDetailsActions";
import {
  getContactToAddGuaranteeFormValues,
  getContactToSave,
  getCreditAppInputForDocumentOrVerbalAuthorization,
  getRecertifiedCreditAppUploadFileInput,
  processFormValuesToPayload,
} from "modules/creditApplicationDetailsActions/lib";
import {
  ContactFormValues,
  ContactPayloadInput,
  RecertificationFormValues,
  AddContactConfirmationModalTitle,
  ContactType,
} from "modules/creditApplicationDetailsActions/types";
import { iniitialRecertificationFormValues } from "modules/creditApplicationDetailsActions/constants";
import { UPLOAD_FILE } from "modules/creditApplicationDetailsActions/queries";
import { useModal } from "../../../../hooks/useModal";
import { ModalsKeys } from "../../../../components/shared/Modals";
import { AuthorizationTaskConfirmationBodyLayout } from "modules/creditApplicationDetailsActions/ui/ContactModalLayout/FormLayout/AuthorizationTaskConfirmationBodyLayout";

export const CreditApplicationDetailsLayout = ({
  vo,
  account,
  vendorProfile,
  equipmentData,
  creditAppModalConfig,
  vendorOpportunityTasks = [],
  isEditingDisabled,
  isLegacyOCA = false,

  isForPDFOnly,
  setIsPDFDownloaded,

  isForHTMLOnly,
  setHTMLForPDF,

  refetchVO,
  refetchTasks,
  refetchEquipment,
  refetchCreditAppOuter, // to update credit app data outside of CADM
  isCreditAppNeedsRecertification,
  creditAppContacts,
  backToCommonView,
}: ValueOf<CreditApplicationRouterState, "creditApplicationState"> & { backToCommonView?: VoidFunction }) => {
  // Redux
  const userProfile = useSelector((state: any) => state.userProfile);
  const { vendorContactRole: userRole, adminRole: userAdminRole } = userProfile;

  const { handleClose: handleCloseLoadingModal, handleOpen: handleOpenLoadingModal } = useModal(ModalsKeys.Load);

  const [detailsStateToSend, setDetailsStateToSend] = useState<any>(null);
  const [creditAppModalData, setCreditAppModalData] = useState<any>(null);
  const [creditAppUpdateIsSubmitting, setCreditAppUpdateIsSubmitting] = useState<any>(false);
  const [locations, setLocations] = useState<any>(null);
  const [locationsTree, setLocationsTree] = useState<any>(null);
  const [salesReps, setSalesReps] = useState<any>(null);
  const [isEditMode, setIsEditMode] = useState<any>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<any>(false);
  const [sectionsWrapperEl, setSectionsWrapperEl] = useState<any>(null);
  const [isIndividualCreditApp, setIsIndividualCreditApp] = useState<any>(null);
  const [isSignatureDateWasUpdated, setIsSignatureDateWasUpdated] = useState<any>(false);
  const [isAuthLinkModalOpen, setIsAuthLinkModalOpen] = useState<any>(false);
  const [creatingAuthLinkTask, setCreatingAuthLinkTask] = useState<any>(false);
  const [taskCreated, setTaskCreated] = useState<any>(false);
  const [authLinkOwner, setAuthLinkOwner] = useState<any>({});
  const [voDetilsToUpdate, setVoDetilsToUpdate] = useState<any>();
  const [isNeedToRefetchEquipmentData, setIsNeedToRefetchEquipmentData] = useState<any>(false);
  const [isCreditAppConvertTypeConfirmationModalOpen, setIsCreditAppConvertTypeConfirmationModalOpen] = useState<any>(
    false
  );
  const [isCreditAppConvertTypeLoading, setIsCreditAppConvertTypeLoading] = useState<any>(false);

  const [detailsState, setDetailsState] = useState<any>({});

  const [openLegacyCreditAppModal, setOpenLegacyCreditAppModal] = useState<boolean>(false);
  const [legacyPDFData, setLegacyPDFData] = useState<any>();

  // Add Contact Modal
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState<boolean>(false);
  const [addContactModalLoading, setAddContactModalLoading] = useState<boolean>(false);
  const [contactToSave, setContactToSave] = useState<ContactPayloadInput | null>(null);
  const [addAuthorizationTaskConfirmationTitle, setAddAuthorizationTaskConfirmationTitle] = useState<string>("");
  const [isAuthLinkConfirmationModalOpen, setIsAuthLinkConfirmationModalOpen] = useState<boolean>(false);

  // Guarantee Contact Modal
  const [isGuaranteeContactModalOpen, setIsGuaranteeContactModalOpen] = useState<boolean>(false);
  const [isGuaranteeContactModalLoading, setIsGuaranteeContactModalLoading] = useState<boolean>(false);
  const [contactToAddGuaranteeFormValues, setContactToAddGuaranteeFormValues] = useState<any>(null);

  // Request Recertification Modal
  const [isRequestRecertificationModalOpen, setIsRequestRecertificationModalOpen] = useState<boolean>(false);
  const [isRequestRecertificationModalLoading, setIsRequestRecertificationModalLoading] = useState<boolean>(false);
  const [recertificationFormValues, setRecertificationFormValues] = useState<any>(null);

  const classes = useStyles({ isEditMode });

  const handleEditableInputUpdate = (field: any, val: any, block: any, blockIdx: any) => {
    let fieldKey = field.key;

    const isDynamicReferenceField = _.get(block, "type") === blockTypes.dynamicReference;
    const isEquipmentField = _.get(block, "type") === blockTypes.equipment;

    const keysSameForCreditAppAndPOC = ["firstName", "middleName", "lastName", "nameSuffix", "email", "phone"];
    const isPrimaryContactField = _.includes(fieldKey, "primaryContact.");
    const isNeedToProcessAsOwners =
      _.includes([blockTypes.coApplicant, blockTypes.owner], block.type) || isPrimaryContactField;

    if (isNeedToProcessAsOwners) {
      detailsState.owners = detailsState.owners || [];
      fieldKey = field.key.slice(field.key.lastIndexOf(".") + 1);
      if (isPrimaryContactField && _.includes(keysSameForCreditAppAndPOC, fieldKey)) {
        detailsState[fieldKey] = val; // to update credit app as well
      }

      let ownerPgId;
      if (isPrimaryContactField) {
        ownerPgId = _.get(creditAppModalData, `primaryContact.ownerPgId`);
      } else {
        const ownerIdx = block.type === blockTypes.coApplicant ? 1 : blockIdx;
        ownerPgId = _.get(creditAppModalData, `owners[${ownerIdx}].ownerPgId`);
      }

      let ownerToUpdate = _.find(detailsState.owners, { ownerPgId });
      if (!ownerToUpdate) {
        ownerToUpdate = { ownerPgId, creditApplicationId: creditAppModalData.id };
        detailsState.owners.push(ownerToUpdate);
      }
      ownerToUpdate[fieldKey] = val;
    } else if (isDynamicReferenceField) {
      const originalReference = _.find(creditAppModalData.references, { referenceIdx: block.referenceIdx });
      const alreadyUpdatedRef = _.find(detailsState.references, { referenceIdx: block.referenceIdx });
      const fieldKey = _.get(field, "ocaConfig.config.fieldName", null);
      if (alreadyUpdatedRef) {
        alreadyUpdatedRef[fieldKey] = val;
      } else {
        const refToUpsert = {
          referenceId: _.get(originalReference, "referenceId", undefined),
          referenceIdx: _.get(originalReference, "referenceIdx", block.referenceIdx),
          [fieldKey]: val,
        };
        detailsState.references = detailsState.references
          ? _.concat(detailsState.references, refToUpsert)
          : [refToUpsert];
      }
    } else if (isEquipmentField) {
      const originalEquipment = _.find(creditAppModalData.equipments, { equipmentIdx: block.equipmentIdx });
      const alreadyUpdatedEquipment = _.find(detailsState.equipments, { equipmentIdx: block.equipmentIdx });
      const fieldKey = _.get(field, "ocaConfig.config.fieldName", null);
      if (alreadyUpdatedEquipment) {
        alreadyUpdatedEquipment[fieldKey] = val;
      } else {
        const equipmentToUpsert = {
          equipmentId: _.get(originalEquipment, "equipmentId", undefined),
          equipmentIdx: _.get(originalEquipment, "equipmentIdx", block.equipmentIdx),
          [fieldKey]: val,
        };
        detailsState.equipments = detailsState.equipments
          ? _.concat(detailsState.equipments, equipmentToUpsert)
          : [equipmentToUpsert];
      }
    } else {
      detailsState[fieldKey] = val;
    }

    setDetailsState(detailsState);
  };

  const toggleCollapse = (sectionId: any) =>
    setCollapseState({ ...collapseState, [sectionId]: !collapseState[sectionId] });

  const [upsertTask] = useMutation(UPSERT_TASK, {
    context: { authRequired: true },
  });

  const [updateCreditAppRequest] = useMutation(UPDATE_CREDIT_APPLICATION_V2_QUERY, {
    context: { authRequired: true },
  });

  const [updateAccountRequest] = useMutation(UPDATE_ACCOUNT_QUERY, {
    context: { authRequired: true },
  });

  const [updateAccountCRMRequest] = useMutation(UPDATE_ACCOUNT_CRM_QUERY, {
    context: { authRequired: true },
  });

  const [updateSalesRep] = useMutation(ASSIGN_SALES_REP, {
    context: { authRequired: true },
  });

  const [updateDynamicsVOLocation] = useMutation(UPDATE_DYNAMICS_VO_LOCATION, {
    context: { authRequired: true },
  });

  const [updateVOLocation] = useMutation(UPDATE_VO_LOCATION, {
    context: { authRequired: true },
  });

  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE);

  const { data: creditAppModalDataResponse, loading: creditAppLoading, refetch: creditAppModalDataRefetch } = useQuery(
    Q_CREDIT_APP,
    {
      variables: {
        VOId: vo.vendorOpportunityId,
      },
      fetchPolicy: "no-cache",
    }
  );

  const { data: locationDataResponse, loading: loadingLocations } = useQuery(GET_LOCATIONS, {
    context: { authRequired: true },
    variables: {
      accountId: account.id,
    },
  });

  const { data: salesRepsDataResponse, loading: loadingSalesReps } = useQuery(GET_SALES_REPS_FOR_ADMIN, {
    context: { authRequired: true },
    fetchPolicy: "no-cache",
    variables: {
      accountId: account.id,
    },
  });

  useEffect(() => {
    if (!creditAppModalDataResponse) {
      return;
    }
    const disclosureToSet = _.get(creditAppModalDataResponse, "creditApplication.disclosure")
      ? JSON.parse(creditAppModalDataResponse.creditApplication.disclosure)
      : null;
    setCreditAppModalData({
      ...creditAppModalDataResponse.creditApplication,
      owners: creditAppModalDataResponse.creditApplication.creditApplicationOwner, // to have strict mappings
      equipments: _.get(equipmentData, "equipments"),
      disclosure: disclosureToSet,
    });
    setIsIndividualCreditApp(
      checkIfIndividualCreditApp(_.get(creditAppModalDataResponse, "creditApplication.applicationType"))
    );
  }, [creditAppModalDataResponse, equipmentData]);

  useEffect(() => {
    if (!locationDataResponse) {
      return;
    }
    setLocations(locationDataResponse.locations);
  }, [locationDataResponse]);

  useEffect(() => {
    if (_.isEmpty(creditAppModalData) || _.isEmpty(locations)) {
      return;
    }
    const locationsOptions = _.map(locations, location => {
      return {
        ...location,
        isDefaultValue: _.isArray(creditAppModalData.locationId)
          ? _.includes(creditAppModalData.locationId, location.locationId)
          : creditAppModalData.locationId === location.locationId,
      };
    });
    setLocationsTree(buildTree(locationsOptions));
  }, [creditAppModalData, locations]);

  useEffect(() => {
    if (_.isEmpty(salesRepsDataResponse) || _.isEmpty(creditAppModalConfig)) {
      return;
    }
    const repField = _(creditAppModalConfig.sections)
      .flatMap("blocks")
      .flatMap("fields")
      .find({ key: "vendorContactId" });

    if (!repField || !repField.ocaConfig) {
      return;
    }
    repField.ocaConfig.config.fieldOptions = _.map(salesRepsDataResponse.salesRepsForAdmin, rep => {
      const repLabel = `${rep.fullName} (${rep.email})`;
      return {
        optionLabel: repLabel,
        optionValue: rep.id,
        displayLabel: {
          en: repLabel,
          es: repLabel,
        },
      };
    });
    setSalesReps(salesRepsDataResponse.salesRepsForAdmin);
  }, [salesRepsDataResponse, creditAppModalConfig]);

  useEffect(() => {
    if (!sectionsWrapperEl) {
      return;
    }
    if (isForPDFOnly) {
      handleCreditAppPdfRequest();
    }
    if (isForHTMLOnly) {
      const html = getCreditAppHTMLForPDF(
        sectionsWrapperEl.innerHTML,
        creditAppModalData,
        vendorProfile,
        account,
        userProfile,
        isIndividualCreditApp
      );
      setHTMLForPDF(html);
    }
  }, [sectionsWrapperEl]);

  const sectionsWrapperRef = useCallback((node: any) => {
    if (node !== null) {
      setSectionsWrapperEl(node);
    }
  }, []);

  const collapseInitialState = _.reduce(
    creditAppModalConfig.sections,
    (result: any, section: any) => {
      result[section.id] = _.get(section, "config.isCollapsed", false);
      return result;
    },
    {}
  );
  const [collapseState, setCollapseState] = useState<any>(collapseInitialState);

  const getFieldDisplayValue = (field: any) => {
    const getDisplayValueFromSelectOption = (selectOption: any) => {
      return _.get(selectOption, "displayLabel.en", "")
        ? _.get(selectOption, "displayLabel.en", "")
        : _.get(selectOption, "optionLabel", "");
    };
    const getSelectFieldDisplayValue = (field: any) => {
      const fieldOptions = _.get(field, "ocaConfig.config.fieldOptions", []);
      const selectedOption = _.find(fieldOptions, option => {
        let valuesToFind = [field.value];
        if (field.value === true) {
          valuesToFind = ["true", "yes", true];
        }
        if (field.value === false) {
          valuesToFind = ["false", "no", false];
        }
        return _.includes(valuesToFind, option.optionValue) || _.includes(valuesToFind, option.optionLabel);
      });
      return getDisplayValueFromSelectOption(selectedOption);
    };

    const getRadioQuestionFieldDisplayValue = (field: any) => {
      const fieldOptions = _.get(field, "ocaConfig.config.valuesList", []);
      const selectedOption = _.find(fieldOptions, { value: field.value });
      return getDisplayValueFromSelectOption(selectedOption);
    };

    const getMultiSelectFieldDisplayValue = (field: any) => {
      const fieldOptions = _.get(field, "ocaConfig.config.fieldOptions", []);
      const selectedOptions = _.filter(fieldOptions, option => _.includes(field.value, option.optionValue));
      return _(selectedOptions)
        .map(getDisplayValueFromSelectOption)
        .compact()
        .join(", ");
    };

    const getTaxIdFieldDisplayValue = (field: any) => {
      const TAX_ID_LENGTH = 9;
      return field.value
        ? field.value
            .toString()
            .replace(/[^0-9]/g, "")
            .padStart(TAX_ID_LENGTH, "0")
            .slice(0, 2) +
            "-" +
            field.value
              .toString()
              .replace(/[^0-9]/g, "")
              .padStart(TAX_ID_LENGTH, "0")
              .slice(2)
        : "";
    };

    const getLocationDisplayValue = (field: any) => {
      const location = _.find(locations, { locationId: field.value });
      return _.get(location, "locationName");
    };

    const getHtmlOutputFieldDisplayValue = (field: any) => {
      return field.selectedApiId ? _.get(creditAppModalData, field.selectedApiId, null) : field.customMarkup;
    };

    const isMaskedField = field.value && isItemHasRestrictionForRole(field, rolesSettingsKeys.masked, userRole);
    if (isMaskedField) {
      return `*Access Restricted*`;
    }

    const getDisplayValueByFieldTypeHashMap: any = {
      "select-field": getSelectFieldDisplayValue,
      "multiselect-field": getMultiSelectFieldDisplayValue,
      "radio-question": getRadioQuestionFieldDisplayValue,
      "amount-field": (field: any) => formatCurrency(field.value, true),
      "percent-field": (field: any) => formatPercentage(field.value),
      "taxid-field": getTaxIdFieldDisplayValue,
      "location-select": getLocationDisplayValue,
      "representative-select": getSelectFieldDisplayValue,
      "phone-field": (field: any) => formatPhoneNumber(field.value),
      "socialsecurity-field": (field: any) => formatSSN(field.value),
      "html-output-field": getHtmlOutputFieldDisplayValue,
    };
    const getDisplayValueByFieldNameHashMap: any = {
      signatureDate: (field: any) => (field.value ? getDateTimeDisplayValue(field.value) : "Not Signed"),
      personalGuaranteeSignatureDate: (field: any) =>
        field.value ? getDateTimeDisplayValue(field.value) : "Not Signed",
    };
    const fieldName = _.get(field, "ocaConfig.config.fieldName", null);
    const fieldType = _.get(field, "ocaConfig.type", null);

    if (getDisplayValueByFieldNameHashMap[fieldName]) {
      return getDisplayValueByFieldNameHashMap[fieldName](field);
    }
    if (getDisplayValueByFieldTypeHashMap[fieldType]) {
      return getDisplayValueByFieldTypeHashMap[fieldType](field);
    }
    return field.value === 0 ? _.toString(field.value) : field.value;
  };

  const getMonthFromDateByKey = (key: any) => {
    const date = _.get(creditAppModalData, key, null);
    return date ? date.slice(0, 2) : null;
  };

  const getYearFromDateByKey = (key: any) => {
    const date = _.get(creditAppModalData, key, null);
    return date ? _.parseInt(date.slice(6, 10)) : null;
  };

  const getFieldValue = (field: any, block: any, blockIdx: any) => {
    const combinedFieldKeysHashMap: any = {
      monthBusinessEstablished: () => getMonthFromDateByKey("dateEstablished"),
      yearBusinessEstablished: () => getYearFromDateByKey("dateEstablished"),
      monthBusinessIncorporated: () => getMonthFromDateByKey("dateIncorporated"),
      yearBusinessIncorporated: () => getYearFromDateByKey("dateIncorporated"),
    };

    let defaultFieldValue: any;
    if (!block.type || block.type === blockTypes.coApplicant) {
      defaultFieldValue = _.isFunction(combinedFieldKeysHashMap[field.key])
        ? combinedFieldKeysHashMap[field.key]()
        : _.get(creditAppModalData, field.key, null);
    }
    if (block.type === blockTypes.reference) {
      const refSettings = refSettingsByRefType[block.referenceType];
      const refObject = _.find(creditAppModalData.references, {
        referenceType: refSettings.referenceType,
        referenceIdx: null,
      });
      const key: any = _.findKey(refSettings.mappings, key => key === field.key);
      defaultFieldValue = _.get(refObject, key, null);
    }
    if (block.type === blockTypes.dynamicReference) {
      const refObject = _.find(creditAppModalData.references, { referenceIdx: block.referenceIdx });
      const key = _.get(field, "ocaConfig.config.fieldName", null);
      defaultFieldValue = _.get(refObject, key, null);
    }
    if (block.type === blockTypes.equipment) {
      const equipmentObject = _.find(creditAppModalData.equipments, { equipmentIdx: block.equipmentIdx });
      const key = _.get(field, "ocaConfig.config.fieldName", null);
      defaultFieldValue = _.get(equipmentObject, key, null);
    }
    if (block.type === blockTypes.owner) {
      const ownerObject = creditAppModalData.owners[blockIdx];
      defaultFieldValue = _.get(ownerObject, field.key, null);
    }

    if (_.get(field, "ocaConfig.type") === "html-output-field") {
      defaultFieldValue = getFieldDisplayValue(field);
    }

    if (_.includes(signatureDateKeys, field.key) && !block.type) {
      // POC section for business app when POC is not the same person as owner
      defaultFieldValue = _.get(creditAppModalData, `${field.key}`, null);
    }

    if (field.key === "vendorContactId") {
      defaultFieldValue = _.get(vo, "salesRepresentative.id", "");
    }

    return defaultFieldValue;
  };

  const handleAuthConfirm = async (owner: any) => {
    setCreatingAuthLinkTask(true);

    const payloadInput = getSignatureTaskForContact(
      owner,
      vo.vendorOpportunityId,
      creditAppModalData.dynamicsId,
      account,
      userProfile
    );

    await upsertTask({
      variables: {
        input: payloadInput,
      },
    });

    refetchTasks();

    setCreatingAuthLinkTask(false);
    setTaskCreated(true);
    setTimeout(() => {
      setIsAuthLinkModalOpen(false);
      setTimeout(() => {
        setAuthLinkOwner({});
        setTaskCreated(false);
        setCreatingAuthLinkTask(false);
      }, 1000);
    }, 3000);
  };

  const handleAuthClick = (owner: any) => {
    setIsAuthLinkModalOpen(true);
    setAuthLinkOwner(owner);
  };

  const getFieldRow = (field: any, block: any, blockIdx: any, section: any) => {
    const isFieldHidden = isItemHasRestrictionForRole(field, rolesSettingsKeys.hidden, userRole);
    if (vo.entityType === "CASH_SALE" && field.key?.includes("signature")) {
      return null;
    }
    if (isFieldHidden) {
      return null;
    }
    if (isEditMode && _.includes(uneditableFieldTypes, _.get(field, "ocaConfig.type"))) {
      return null;
    }
    if (isEditMode && _.includes(uneditableFieldKeys, field.key)) {
      return null;
    }

    if (_.includes(signatureDateKeys, field.key) && !block.type && !isIndividualCreditApp) {
      // assuming it's a POC section for commercial.
      const isPOCOwnerOrOfficer = !creditAppModalData.primaryContact.pcOnly;
      if (isPOCOwnerOrOfficer) {
        return null; // signatures will be shown at ownership section
      }
    }

    if (section.type === sectionTypes.disclosures && !isEditMode) {
      return <div dangerouslySetInnerHTML={{ __html: getFieldValue(field, block, blockIdx) }}></div>;
    }

    field.value = getFieldValue(field, block, blockIdx);
    field.displayValue = getFieldDisplayValue(field);
    if (!isEditMode && !field.displayValue) {
      return null;
    }

    if (_.get(field, "ocaConfig.type") === "html-output-field") {
      return <div dangerouslySetInnerHTML={{ __html: field.displayValue }}></div>;
    }

    const isFieldHiddenOnPDF = isItemHasRestrictionForRole(field, rolesSettingsKeys.hiddenOnPDF, userRole);
    const isFieldReadOnly = isItemHasRestrictionForRole(field, rolesSettingsKeys.readOnly, userRole);
    const isFieldMasked = isItemHasRestrictionForRole(field, rolesSettingsKeys.masked, userRole);
    const isBlockReadOnly = isItemHasRestrictionForRole(block, rolesSettingsKeys.readOnly, userRole);
    const isSectionReadOnly = isItemHasRestrictionForRole(section, rolesSettingsKeys.readOnly, userRole);
    const isReadOnly = _.some([isFieldReadOnly, isFieldMasked, isBlockReadOnly, isSectionReadOnly]);

    //TODO: move all Authorization Link logic in separate function/component
    const isUnsignedField = _.some(signatureDateKeys, k => _.includes(field.key, k)) && !field.value;
    const getOwner = () => {
      if (block.type === blockTypes.owner) {
        return creditAppModalData.owners[blockIdx];
      }
      if (block.type === blockTypes.coApplicant) {
        return creditAppModalData.owners[1];
      }
      return creditAppModalData.primaryContact;
    };

    const owner = getOwner();
    const ownerAuthorizationTaskCreated = _.find(vendorOpportunityTasks, t => {
      return t.ownerPgId === _.get(owner, "ownerPgId") && t.regarding === taskRegardingValues.signatures;
    });

    return (
      <>
        {isEditMode && !isReadOnly ? (
          <Box style={{ width: "49%" }}>
            <CreditAppModalEditableInput
              field={field}
              classes={classes}
              onChangeFn={(field: any, val: any) => handleEditableInputUpdate(field, val, block, blockIdx)}
              locationsTree={locationsTree}
              locationsTreeOptions={getDropdownTreeSelectDefaultOptions({
                onChange: (currentNode: any) => handleEditableInputUpdate(field, currentNode.value, block, blockIdx),
              })}
            />
          </Box>
        ) : (
          <div
            className={classNames(classes.fieldRow + " fieldRow" + (isFieldHiddenOnPDF ? " hiddenOnPdf" : ""), {
              [classes.fieldRowEditMode]: isEditMode,
            })}
            key={field.key}
          >
            <div
              className={classNames(classes.detailsCell + " " + classes.detailsLeftCol + " fieldTitleWrapper", {
                [classes.detailsLeftColEditMode]: isEditMode,
                [classes.detailsCelllEditMode]: isEditMode,
              })}
            >
              <Typography component="span" className={classes.fieldTitle + " fieldTitle"}>
                {vo.entityType === "CASH_SALE" ? field.config.title.replace("Financing", "") : field.config.title}
              </Typography>
            </div>
            <div
              className={classNames(classes.detailsCell, classes.detailsRightCol, classes.root, "fieldValueWrapper", {
                [classes.detailsCelllEditMode]: isEditMode,
              })}
            >
              <>
                {!isUnsignedField ? (
                  <Typography component="span" variant="body2">
                    {field.displayValue}
                  </Typography>
                ) : (
                  <div
                    style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <span>Not Signed</span>
                    {ownerAuthorizationTaskCreated ? (
                      <span className={classes.authorizationLinkSent + " hiddenOnPdf"}>
                        <Check style={{ fontSize: 20, marginRight: 4, color: "#2e7d32" }} />
                        Authorization Link Sent
                      </span>
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleAuthClick(owner)}
                        disabled={!!ownerAuthorizationTaskCreated}
                        className={"hiddenOnPdf"}
                        startIcon={<Send />}
                      >
                        Send Authorization Link
                      </Button>
                    )}
                  </div>
                )}
              </>
            </div>
          </div>
        )}
      </>
    );
  };

  const getBlocks = (section: any) => {
    const getSimpleBlock = (block: any, blockIdx: any, section: any) => {
      return (
        <div key={block.id} className={"blockWrapper"}>
          {block.config.title && (
            <Typography component="h3" variant="subtitle2" className={"blockTitle"}>
              {block.config.title}
            </Typography>
          )}
          <div className={classNames("fieldsWrapper", { [classes.editRow]: isEditMode })}>
            {block.fields.map((field: any) => getFieldRow(field, block, blockIdx, section))}
          </div>
        </div>
      );
    };

    const getOwnersBlocks = (block: any, section: any) => {
      const getOwnerBlockTitle = (idx: any) => {
        if (!isIndividualCreditApp) {
          return `Owner ${idx + 1}`;
        }
        return idx === 0 ? "Applicant" : "Co-Applicant";
      };

      const blocksCount = _.size(creditAppModalData.owners);
      const ownersBlocks = _(Array(blocksCount))
        .fill("")
        .map(() => _.cloneDeep(block))
        .value();
      return _.map(ownersBlocks, (block, idx) => {
        const isNeedToRenderAddContactButton = isEditMode && blocksCount === idx + 1;
        const owner = creditAppModalData.owners[idx];

        return (
          <Grid container key={`block_${Math.random()}`} className={"blockWrapper"} spacing={2}>
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="subtitle2"
                className={classNames("blockTitle", classes.ownerBlockTitle)}
              >
                {/*                 <IconButton size="small" color="secondary" onClick={() => { handleRemoveOwner(owner)}}>
                  <DeleteIcon />
                </IconButton> */}

                {getOwnerBlockTitle(idx)}
              </Typography>

              {!isEditMode && !owner.pg && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classNames("hiddenOnPdf", classes.contactTopActionButton)}
                  onClick={() => handleGuaranteeClick(owner)}
                >
                  Add PG
                </Button>
              )}

              <div className={classNames("fieldsWrapper", { [classes.editRow]: isEditMode })}>
                {block.fields.map((field: any) => getFieldRow(field, block, idx, section))}
              </div>
            </Grid>
            {isNeedToRenderAddContactButton && (
              <Box className={classes.ownerActions}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="hiddenOnPdf"
                  onClick={handleAddContact}
                >
                  Add Contact
                </Button>
              </Box>
            )}
          </Grid>
        );
      });
    };

    return section.blocks.flatMap((block: any, blockIdx: any) => {
      if (!isEditMode && isBlockHidden(block, blockIdx)) {
        return null;
      }
      return block.type === blockTypes.owner
        ? getOwnersBlocks(block, section)
        : getSimpleBlock(block, blockIdx, section);
    });
  };

  const handleCreditAppPdfRequest = () => {
    handleOpenLoadingModal({ message: "Please wait... We are creating file" });

    const html = getCreditAppHTMLForPDF(
      sectionsWrapperEl.innerHTML,
      creditAppModalData,
      vendorProfile,
      account,
      userProfile,
      isIndividualCreditApp
    );

    // Helps to render on a separate tab
    // const blob = new Blob([html], { type: "text/html" });
    // const url = URL.createObjectURL(blob);
    // window.open(url);
    // return;

    axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/html-to-pdf`,
        {
          html,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      )
      .then(response => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        const pdfWindow = window.open();
        if (pdfWindow?.location?.href) pdfWindow.location.href = fileURL;
      })
      .catch(error => console.log(error))
      .finally(() => {
        if (isForPDFOnly) setIsPDFDownloaded(true);
        handleCloseLoadingModal();
      });
  };

  const onCreditAppConvertTypeConfirmationModalClose = async (event: any, reason: any) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }
    if (event.result !== "ok") {
      setIsCreditAppConvertTypeConfirmationModalOpen(false);
      return;
    }

    setIsCreditAppConvertTypeLoading(true);
    const input = {
      applicationType: creditAppTypes.commercial,
    };

    await updateCreditAppRequest({ variables: { creditAppId: creditAppModalData.id, input } });

    await creditAppModalDataRefetch();

    if (refetchCreditAppOuter) await refetchCreditAppOuter();

    setIsEditMode(false);
    setIsCreditAppConvertTypeConfirmationModalOpen(false);
    setIsCreditAppConvertTypeLoading(false);
  };

  const handleSubmit = async () => {
    try {
      const updateSalesRepIfNeeded = async () => {
        if (!_.some(voDetilsToUpdate?.salesRep)) {
          return;
        }
        await updateSalesRep({
          variables: {
            fieldsObj: voDetilsToUpdate.salesRep,
            VOId: vo.vendorOpportunityId,
            entityId: vo.dynamicsVendorOpportunityId,
          },
        });
      };

      const updateLocationIfNeeded = async () => {
        if (!_.some(voDetilsToUpdate?.location)) {
          return;
        }
        await updateDynamicsVOLocation({
          variables: {
            fieldsObj: {
              locationId: voDetilsToUpdate.location.locationId,
            },
            entityId: vo.dynamicsVendorOpportunityId,
          },
        });
        await updateVOLocation({
          variables: {
            fieldsObj: {
              locationId: voDetilsToUpdate.location.locationId,
            },
            VOId: vo.vendorOpportunityId,
          },
        });
      };

      const updateBusinessNameIfNeeded = async () => {
        if (!voDetilsToUpdate?.potentialCustomer) {
          return;
        }
        const potentialCustomerName = voDetilsToUpdate.potentialCustomer.name;

        if (potentialCustomerName || potentialCustomerName === "") {
          await updateAccountRequest({
            variables: {
              dynamicsId: vo?.potentialCustomer?.dynamicsAccountId,
              fieldsObj: {
                name: potentialCustomerName,
              },
            },
          });
          await updateAccountCRMRequest({
            variables: {
              entityId: vo?.potentialCustomer?.dynamicsAccountId,
              fieldsObj: {
                name: potentialCustomerName,
              },
            },
          });
        }
      };

      console.log(`detailsStateToSend: ${JSON.stringify(detailsStateToSend)}`);
      setCreditAppUpdateIsSubmitting(true);

      await updateSalesRepIfNeeded();
      await updateLocationIfNeeded();
      await updateBusinessNameIfNeeded();
      await updateCreditAppRequest({ variables: { creditAppId: creditAppModalData.id, input: detailsStateToSend } });

      await creditAppModalDataRefetch();
      if (refetchCreditAppOuter) await refetchCreditAppOuter();

      if (_.some(voDetilsToUpdate)) {
        await refetchVO();
      }
      if (isNeedToRefetchEquipmentData) {
        await refetchEquipment();
      }

      handleCloseConfirmationModal();
    } catch (err) {
      console.log(err);
    } finally {
      setVoDetilsToUpdate(null);
      setIsNeedToRefetchEquipmentData(false);
      setCreditAppUpdateIsSubmitting(false);
    }
  };

  const massageUpdateCreditAppInput = (updateCreditAppInput: any) => {
    const checkIfNeedToUpdateBusinessName = () => {
      if (_.has(updateCreditAppInput, "businessName")) {
        return {
          result: true,
          name: updateCreditAppInput.businessName,
        };
      }

      if (isIndividualCreditApp) {
        const applicant = creditAppModalData.primaryContact;
        const applicantUpdated = _.find((updateCreditAppInput as any).owners, { ownerPgId: applicant.ownerPgId });
        if (!!applicantUpdated) {
          const individualCreditAppKeysToReflect = ["firstName", "middleName", "lastName", "nameSuffix"];
          const newName = _(individualCreditAppKeysToReflect)
            .map(key => (_.has(applicantUpdated, key) ? applicantUpdated[key] : applicant[key]))
            .compact()
            .join(" ");
          return {
            result: true,
            name: newName,
          };
        }
      }

      return {
        result: false,
      };
    };

    let log = "";

    console.log(`updateCreditAppInput: ${JSON.stringify(updateCreditAppInput)}`);

    if (!updateCreditAppInput) {
      return;
    }

    if (updateCreditAppInput.monthBusinessIncorporated || updateCreditAppInput.yearBusinessIncorporated) {
      const yearBusinessIncorporatedToSet =
        updateCreditAppInput.yearBusinessIncorporated || getYearFromDateByKey("dateIncorporated");
      if (yearBusinessIncorporatedToSet) {
        const monthBusinessIncorporatedToSet =
          updateCreditAppInput.monthBusinessIncorporated || getMonthFromDateByKey("dateIncorporated") || "01";
        //15th used to fix issue when there may be a discrepancy with the server time and as a result the wrong month will be established
        updateCreditAppInput.dateIncorporated = `${monthBusinessIncorporatedToSet}-15-${yearBusinessIncorporatedToSet}`;
      }
    }
    if (updateCreditAppInput.monthBusinessEstablished || updateCreditAppInput.yearBusinessEstablished) {
      const yearBusinessEstablishedToSet =
        updateCreditAppInput.yearBusinessEstablished || getYearFromDateByKey("dateEstablished");
      if (yearBusinessEstablishedToSet) {
        const monthBusinessEstablishedToSet =
          updateCreditAppInput.monthBusinessEstablished || getMonthFromDateByKey("dateEstablished") || "01";
        //15th used to fix issue when there may be a discrepancy with the server time and as a result the wrong month will be established
        updateCreditAppInput.dateEstablished = `${monthBusinessEstablishedToSet}-15-${yearBusinessEstablishedToSet}`;
      }
    }

    const parseFieldsForPayload = (item: any, fields: any, parseFn: any) => {
      _.forEach(fields, fieldKey => {
        if (item[fieldKey] || item[fieldKey] === 0 || item[fieldKey] === false) {
          item[fieldKey] = parseFn(item[fieldKey]);
        }
      });
    };

    const creditAppStringifiedFields = ["timeAtPrevAddressYears", "timeAtPrevAddressMonths", "previousFinanceExp"];
    const ownersStringifiedFields = ["timeAtAddressYears", "timeAtAddressMonths"];
    const equipmentsStringifiedFields = ["year"];
    const referencesStringifiedFields = ["accountNum"];
    parseFieldsForPayload(updateCreditAppInput, creditAppStringifiedFields, _.toString);
    _.forEach(updateCreditAppInput.owners, o => parseFieldsForPayload(o, ownersStringifiedFields, _.toString));
    _.forEach(updateCreditAppInput.equipments, e => parseFieldsForPayload(e, equipmentsStringifiedFields, _.toString));
    _.forEach(updateCreditAppInput.references, r => parseFieldsForPayload(r, referencesStringifiedFields, _.toString));

    setIsNeedToRefetchEquipmentData(_.some(updateCreditAppInput.equipments));

    if (updateCreditAppInput.vendorContactId) {
      const selectedSalesRep = _.find(salesReps, { id: updateCreditAppInput.vendorContactId });
      setVoDetilsToUpdate((prevState: any) => ({
        ...prevState,
        salesRep: {
          dynamicsSalesRepContactId: _.get(selectedSalesRep, "dynamicsContactId", null) || undefined,
          salesRepresentativeId: _.get(selectedSalesRep, "id", null) || undefined,
        },
      }));
    }

    if (updateCreditAppInput.locationId) {
      setVoDetilsToUpdate((prevState: any) => ({
        ...prevState,
        location: {
          locationId: updateCreditAppInput.locationId,
        },
      }));
    }

    const checkIfNeedToUpdateBusinessNameResult = checkIfNeedToUpdateBusinessName();
    if (checkIfNeedToUpdateBusinessNameResult.result) {
      setVoDetilsToUpdate((prevState: any) => ({
        ...prevState,
        potentialCustomer: {
          name: checkIfNeedToUpdateBusinessNameResult.name,
        },
      }));
      updateCreditAppInput.businessName = checkIfNeedToUpdateBusinessNameResult.name;
    }

    const setReferenceField = (referenceField: any, referenceType: any) => {
      const cadmV2ReferenceKeySuffix = "";
      if (updateCreditAppInput[`${referenceField.cadmV2Key}`]) {
        if (!updateCreditAppInput.references) {
          updateCreditAppInput.references = [];
        }
        let referenceIndex = updateCreditAppInput.references.findIndex((reference: any) => {
          return reference.referenceType === referenceType;
        });
        if (referenceIndex === -1) {
          updateCreditAppInput.references.push({ referenceType });
          referenceIndex = updateCreditAppInput.references.length - 1;
          const referenceFromCreditApp = creditAppModalData.references.find(
            (r: any) => r.referenceType === referenceType
          );
          if (referenceFromCreditApp) {
            updateCreditAppInput.references[referenceIndex].referenceId = referenceFromCreditApp.referenceId;
          }
        }

        log += `\r\n${referenceType} referenceIndex: ${referenceIndex} | updateCreditAppInput.references: ${JSON.stringify(
          updateCreditAppInput.references
        )} [Found reference field ${referenceField.updateCreditAppV2Key} and set it to: ${
          updateCreditAppInput[`${referenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`]
        }`;
        updateCreditAppInput.references[referenceIndex][referenceField.updateCreditAppV2Key] =
          updateCreditAppInput[`${referenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`];
      }
    };

    // Trade References
    try {
      let tradeReferenceFields = [
        { cadmV2Key: "tradeReferenceName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "tradeReferenceContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "tradeReferenceContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "tradeReferencePhone", updateCreditAppV2Key: "contactPhone" },
        { cadmV2Key: "tradeReferenceFax", updateCreditAppV2Key: "contactFax" },
        { cadmV2Key: "tradeReferenceAccountNum", updateCreditAppV2Key: "accountNum" },
        { cadmV2Key: "percRevenue", updateCreditAppV2Key: "percRevenue" },
        { cadmV2Key: "tradeReferenceYears", updateCreditAppV2Key: "years" },
        { cadmV2Key: "tradeReferenceMonths", updateCreditAppV2Key: "months" },
      ];

      /*
      MISSING FIELDS:
      referenceId: ID
      originalLoanAmt: Float
      lineOfCredit: Boolean
      creditLimit: Float
      currentBalance: Float
      monthlyPayment: Float
      renewalDate: String
      referenceType: ReferenceType
      */

      for (let i = 0; i < 3; i++) {
        let referenceType: any;
        if (i === 0) {
          referenceType = "TRADE";
        } else if (i === 1) {
          referenceType = "TRADE_TWO";
        } else if (i === 2) {
          referenceType = "TRADE_THREE";
        }
        tradeReferenceFields.forEach(tradeReferenceField => {
          const cadmV2ReferenceKeySuffix = i === 0 ? "" : (i + 1).toString();
          if (updateCreditAppInput[`${tradeReferenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`]) {
            if (!updateCreditAppInput.references) {
              updateCreditAppInput.references = [];
            }
            let referenceIndex = updateCreditAppInput.references.findIndex((reference: any) => {
              return reference.referenceType === referenceType;
            });
            if (referenceIndex === -1) {
              updateCreditAppInput.references.push({ referenceType });
              referenceIndex = updateCreditAppInput.references.length - 1;
              const referenceFromCreditApp = creditAppModalData.references.find(
                (r: any) => r.referenceType === referenceType
              );
              if (referenceFromCreditApp) {
                updateCreditAppInput.references[referenceIndex].referenceId = referenceFromCreditApp.referenceId;
              }
            }

            log += `\r\n${referenceType} referenceIndex: ${referenceIndex} | updateCreditAppInput.references: ${JSON.stringify(
              updateCreditAppInput.references
            )} [Found reference field ${tradeReferenceField.updateCreditAppV2Key} and set it to: ${
              updateCreditAppInput[`${tradeReferenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`]
            }`;
            updateCreditAppInput.references[referenceIndex][tradeReferenceField.updateCreditAppV2Key] =
              updateCreditAppInput[`${tradeReferenceField.cadmV2Key}${cadmV2ReferenceKeySuffix}`];
          }
        });
      }
    } catch (e) {
      console.log(`Error updating trade reference fields: ${(e as any).message}`);
    }

    // Bank Reference
    try {
      let bankReferenceFields = [
        { cadmV2Key: "primaryBankName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "primaryBankContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "primaryBankContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "primaryBankPhone", updateCreditAppV2Key: "contactPhone" },
        { cadmV2Key: "primaryBankAccountNum", updateCreditAppV2Key: "accountNum" },
        { cadmV2Key: "primaryBankAverageBalance", updateCreditAppV2Key: "currentBalance" },
      ];

      bankReferenceFields.forEach(field => setReferenceField(field, "BANK"));
    } catch (e) {
      console.log(`Error updating BANK reference fields: ${(e as any).message}`);
    }

    // Line of Credit (Loan) Reference
    try {
      let loanReferenceFields = [
        { cadmV2Key: "locReferenceCreditLimit", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "locReferenceCurrentBalance", updateCreditAppV2Key: "currentBalance" },
        { cadmV2Key: "locReferenceMonthlyPayment", updateCreditAppV2Key: "monthlyPayment" },
        { cadmV2Key: "locReferenceRenewalDate", updateCreditAppV2Key: "renewalDate" },

        { cadmV2Key: "loanReferenceName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "loanReferenceContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "loanReferenceContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "loanReferenceMonthlyPayment", updateCreditAppV2Key: "monthlyPayment" },
      ];

      loanReferenceFields.forEach(field => setReferenceField(field, "LOAN"));
    } catch (e) {
      console.log(`Error updating Line of Credit LOAN reference fields: ${(e as any).message}`);
    }

    // Primary Credit (Loan) Reference
    try {
      let loanReferenceFields = [
        { cadmV2Key: "primaryCreditName", updateCreditAppV2Key: "companyName" },
        { cadmV2Key: "primaryCreditContactName", updateCreditAppV2Key: "contactName" },
        { cadmV2Key: "primaryCreditContactEmail", updateCreditAppV2Key: "contactEmail" },
        { cadmV2Key: "primaryCreditPhone", updateCreditAppV2Key: "contactPhone" },
        { cadmV2Key: "primaryCreditAccountNum", updateCreditAppV2Key: "accountNum" },
        // { cadmV2Key: "primaryCreditAverageBalance", updateCreditAppV2Key: "averageBalance" },
      ];

      loanReferenceFields.forEach(field => setReferenceField(field, "LOAN"));
    } catch (e) {
      console.log(`Error updating Primary Credit LOAN reference fields: ${(e as any).message}`);
    }

    // Line of Credit (LOC) Reference
    try {
      let lineOfCreditReferenceFields = [
        { cadmV2Key: "locReferenceCreditLimit", updateCreditAppV2Key: "creditLimit" },
        { cadmV2Key: "locReferenceCurrentBalance", updateCreditAppV2Key: "currentBalance" },
        { cadmV2Key: "locReferenceMonthlyPayment", updateCreditAppV2Key: "monthlyPayment" },
        { cadmV2Key: "locReferenceRenewalDate", updateCreditAppV2Key: "renewalDate" },
        { cadmV2Key: "locReferenceLineOfCredit", updateCreditAppV2Key: "lineOfCredit" },
      ];
      lineOfCreditReferenceFields.forEach(field => setReferenceField(field, "LOC"));
    } catch (e) {
      console.log(`Error updating Line of Credit (LOC) reference fields: ${(e as any).message}`);
    }

    const ownersWithUpdatedSignatureDate = _.filter(updateCreditAppInput.owners, o =>
      _.some(signatureDateKeys, signatureKey => _.has(o, signatureKey))
    );
    const isSignatureDateWasUpdated = _.some(ownersWithUpdatedSignatureDate);
    setIsSignatureDateWasUpdated(isSignatureDateWasUpdated);
    if (isSignatureDateWasUpdated) {
      updateCreditAppInput.recertificationDate = new Date().toISOString();
      const recertificationHistoryToAdd = _.map(ownersWithUpdatedSignatureDate, updatedOwner => {
        const originalOwner = _.find(creditAppModalData.owners, { ownerPgId: updatedOwner.ownerPgId });
        const ownerFullName = _([
          updatedOwner.firstName || originalOwner.firstName,
          updatedOwner.lastName || originalOwner.lastName,
        ])
          .compact()
          .join(" ");
        return `Recertification for ${ownerFullName} on ${getDateTimeDisplayValue(new Date().getTime())} by ${
          userProfile.fullName
        }`;
      });
      updateCreditAppInput.recertificationHistory = _(creditAppModalData.recertificationHistory)
        .concat(recertificationHistoryToAdd)
        .compact()
        .value();
    }

    const keysToCleanup = [
      "monthBusinessIncorporated",
      "yearBusinessIncorporated",
      "monthBusinessEstablished",
      "yearBusinessEstablished",
      "vendorContactId",
    ];
    _.forEach(keysToCleanup, key => {
      delete updateCreditAppInput[key];
    });

    if (log !== "") {
      log = `Issues when updating Credit App V2\r\n${log}`;
      console.warn(log);
    }

    if (updateCreditAppInput?.equipmentNumInFleet !== null || updateCreditAppInput?.equipmentNumInFleet !== undefined) {
      setVoDetilsToUpdate((prev: any) => ({
        ...prev,
        equipmentNumInFleet: Number(updateCreditAppInput?.equipmentNumInFleet),
      }));

      updateCreditAppInput.equipmentNumInFleet = Number(updateCreditAppInput?.equipmentNumInFleet);
    }

    return updateCreditAppInput;
  };

  const handleConfirm = () => {
    setDetailsStateToSend(massageUpdateCreditAppInput(detailsState));
    setDetailsState({});
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsEditMode(false);
    setIsConfirmModalOpen(false);
  };

  const isSectionHidden = (section: any) => {
    const isAllBlocksAreHidden = (section: any) =>
      _.every(section.blocks, (block, blockIdx) => isBlockHidden(block, blockIdx));
    const isSectionHiddenForCurrentRole = isItemHasRestrictionForRole(section, rolesSettingsKeys.hidden, userRole);
    return isSectionHiddenForCurrentRole || isAllBlocksAreHidden(section);
  };

  const isBlockHidden = (block: any, blockIdx: any) => {
    const getFieldsWithValues = (block: any, blockIdx: any) => {
      return _.filter(block.fields, field => {
        const value = getFieldValue(field, block, blockIdx);
        return value || value === 0;
      });
    };

    const isAllFieldsWithValuesAreHidden = (fields: any) => {
      return _.every(fields, field => isItemHasRestrictionForRole(field, rolesSettingsKeys.hidden, userRole));
    };

    const fieldsWithValues = getFieldsWithValues(block, blockIdx);
    const isBlockHiddenForCurrentRole = isItemHasRestrictionForRole(block, rolesSettingsKeys.hidden, userRole);
    return (
      isBlockHiddenForCurrentRole || _.isEmpty(fieldsWithValues) || isAllFieldsWithValuesAreHidden(fieldsWithValues)
    );
  };

  /* Add Contact */

  const handleAddContact = () => {
    setIsAddContactModalOpen(true);
    /*     setCreditAppModalData({
      ...creditAppModalData,
      owners: [...creditAppModalData.owners, {}],
    }); */
  };

  const handleSaveAddContactModal = async (contactFormValues: ContactFormValues, contactType: ContactType) => {
    const contactToSave: ContactPayloadInput = getContactToSave(creditAppModalData, contactFormValues, contactType);
    setContactToSave(contactToSave);
    if (contactType === "contact-only") {
      setAddContactModalLoading(true);
      await hanldeSavingContact(contactToSave);
      await creditAppModalDataRefetch();
      setIsAddContactModalOpen(false);
      setAddContactModalLoading(false);
      setContactToSave(null);
      return;
    }

    const addAuthorizationTaskConfirmationTitle =
      contactType === "signor-only"
        ? AddContactConfirmationModalTitle.SignorOnly
        : AddContactConfirmationModalTitle.SignorAndGuarantor;
    setAddAuthorizationTaskConfirmationTitle(addAuthorizationTaskConfirmationTitle);
    setIsAuthLinkConfirmationModalOpen(true);
  };

  const hanldeSavingContact = async (contactToSave: ContactPayloadInput | null) => {
    if (!contactToSave) {
      return;
    }
    await updateCreditAppRequest({
      variables: {
        creditAppId: creditAppModalData.id,
        input: {
          owners: [contactToSave],
        },
      },
    });
  };

  const handleCloseAddContactModal = () => {
    setIsAddContactModalOpen(false);
  };

  const onAuthLinkConfirmationModalClose = async (event: any, reason: string) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }
    setIsAuthLinkConfirmationModalOpen(false);
    if (event.result !== "ok") {
      return;
    }

    if (isAddContactModalOpen) {
      setAddContactModalLoading(true);
      await hanldeSavingContact(contactToSave);
      const refetchedCreditAppData = await creditAppModalDataRefetch();
      const savedContact = _.find(refetchedCreditAppData?.data?.creditApplication?.creditApplicationOwner, {
        email: contactToSave?.email,
        ownerNumber: contactToSave?.ownerNumber,
      });
      const getTaskFnToUse = savedContact.pg
        ? getNewSignorAndGuarantorSignatureTaskForContact
        : getSignatureTaskForContact;
      const taskToCreate = getTaskFnToUse(
        savedContact,
        vo.vendorOpportunityId,
        creditAppModalData.dynamicsId,
        account,
        userProfile
      );
      await upsertTask({
        variables: {
          input: taskToCreate,
        },
      });
      setIsAddContactModalOpen(false);
      setAddContactModalLoading(false);
    }

    if (isGuaranteeContactModalOpen) {
      setIsGuaranteeContactModalLoading(true);
      await hanldeSavingContact(contactToSave);
      await creditAppModalDataRefetch();
      const taskToCreate = getNewSignorAndGuarantorSignatureTaskForContact(
        contactToSave,
        vo.vendorOpportunityId,
        creditAppModalData.dynamicsId,
        account,
        userProfile
      );
      await upsertTask({
        variables: {
          input: taskToCreate,
        },
      });
      setIsGuaranteeContactModalOpen(false);
      setIsGuaranteeContactModalLoading(false);
    }
    setContactToSave(null);
    setAddAuthorizationTaskConfirmationTitle("");
  };

  /////

  /* Add Guarantee */

  const handleGuaranteeClick = (contact: any) => {
    const contactToAddGuaranteeFormValues: ContactFormValues = getContactToAddGuaranteeFormValues(contact);
    setContactToSave(contact);
    setContactToAddGuaranteeFormValues(contactToAddGuaranteeFormValues);
    setIsGuaranteeContactModalOpen(true);
  };

  const handleSaveGuaranteeContactModal = async (contactFormValues: ContactFormValues) => {
    const initialValues = {
      ownerPgId: contactToSave?.ownerPgId,
      isOwnerPg: true,
    };
    const updatedContactToSave: ContactPayloadInput = processFormValuesToPayload(contactFormValues, initialValues);
    setContactToSave(updatedContactToSave);
    setIsAuthLinkConfirmationModalOpen(true);
  };

  const handleCloseGuaranteeContactModal = () => {
    setIsGuaranteeContactModalOpen(false);
  };

  /////

  /* Recertification */

  const handleRecertifyClick = () => {
    setIsRequestRecertificationModalOpen(true);
    const recertificationFormValues: RecertificationFormValues = {
      ...iniitialRecertificationFormValues,
      recertificationDate: creditAppModalData.recertificationDate
        ? getDateTimeDisplayValue(creditAppModalData.recertificationDate, "yyyy-MM-dd'T'HH:mm")
        : getDateTimeDisplayValue(Date.now(), "yyyy-MM-dd'T'HH:mm"),
    };
    setRecertificationFormValues(recertificationFormValues);
  };

  const handleSaveRequestRecertificationModal = async (recertificationFormValues: RecertificationFormValues) => {
    const handleDocumentOrVerbalAuthorization = async () => {
      const creditAppInput = getCreditAppInputForDocumentOrVerbalAuthorization(
        creditAppModalData,
        recertificationFormValues,
        userProfile
      );
      await updateCreditAppRequest({
        variables: {
          creditAppId: creditAppModalData.id,
          input: creditAppInput,
        },
      });
      if (recertificationFormValues.file) {
        await uploadFile({
          variables: {
            input: getRecertifiedCreditAppUploadFileInput(vo),
            file: recertificationFormValues.file,
          },
        });
      }
    };

    const handleRecertificationTasksCreation = async () => {
      const creditAppContactsByOwnerPgId = _.keyBy(creditAppContacts, "ownerPgId");
      const createTasksPromises = recertificationFormValues.recertificationContactIds?.map(ownerId => {
        const contact = creditAppContactsByOwnerPgId[ownerId];
        const taskToCreate = getRecertificationTaskForContact(
          contact,
          vo.vendorOpportunityId,
          creditAppModalData.dynamicsId,
          account,
          userProfile
        );
        return upsertTask({
          variables: {
            input: taskToCreate,
          },
        });
      });
      return Promise.all(createTasksPromises);
    };

    setIsRequestRecertificationModalLoading(true);
    recertificationFormValues.recertificationOption === "send-task"
      ? await handleRecertificationTasksCreation()
      : await handleDocumentOrVerbalAuthorization();
    await creditAppModalDataRefetch();
    setIsRequestRecertificationModalOpen(false);
    setIsRequestRecertificationModalLoading(false);
    /*     setContactToSave(null);*/
  };

  const handleCloseRequestRecertificationModal = () => {
    setIsRequestRecertificationModalOpen(false);
  };

  /////

  const handleRemoveOwner = (owner: any) => {
    //TODO: to implement
  };

  const getLegacyPDFData = async () => {
    const url = `${DCR_API_BASE_SERVER_URL}/create-pdf?transaction_id=${vo.transactionId}`;
    const data = {
      vendorProfile,
      account: { account, userProfile },
      userProfile: userProfile,
      download: false,
    };
    const config: any = {
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/html",
      },
    };
    return axios
      .post(url, data, config)
      .then(response => response.data)
      .catch(error => console.log(error));
  };

  const handleLegacyCreditAppPdfRequest = async () => {
    const legacyPDFData = await getLegacyPDFData();
    setLegacyPDFData(legacyPDFData);
    setOpenLegacyCreditAppModal(true);
  };

  const isLoading = (creditAppLoading || loadingLocations || loadingSalesReps) && !creditAppUpdateIsSubmitting;

  return (
    <>
      <Paper elevation={0} className={classes.cardContainer}>
        {isLoading && (
          <Box className={classes.containerForLoading}>
            <CircularProgress />
          </Box>
        )}

        {!isLoading && (
          <CreditApplicationDetailsPanel
            isEditMode={isEditMode}
            isLegacyOCA={isLegacyOCA}
            userAdminRole={userAdminRole}
            backToCommonView={backToCommonView}
            creditAppModalData={creditAppModalData}
            isEditingDisabled={!!isEditingDisabled}
            isIndividualCreditApp={isIndividualCreditApp}
            onChangeMode={() => setIsEditMode(true)}
            handleCreditAppPdfRequest={handleCreditAppPdfRequest}
            handleLegacyCreditAppPdfRequest={handleLegacyCreditAppPdfRequest}
            title={vo.entityType === "CASH_SALE" ? "Cash Sale Detail" : creditAppModalConfig.config.title}
            onConvertToBusiness={() => setIsCreditAppConvertTypeConfirmationModalOpen(true)}
            handleRecertifyClick={handleRecertifyClick}
            isCreditAppNeedsRecertification={isCreditAppNeedsRecertification}
          />
        )}

        {!isLoading && creditAppModalData && (
          <div
            ref={sectionsWrapperRef}
            className={classNames(classes.containerForContent, {
              [classes.containerForContentView]: !isEditMode,
              [classes.containerForContentEdit]: isEditMode,
            })}
          >
            {creditAppModalConfig.sections.map((section: any) => {
              if (isEditMode && section.config.title === "Authorization And Acceptance") {
                return null;
              }
              if (!isEditMode && isSectionHidden(section)) {
                return null;
              }
              return (
                <div
                  key={section.id}
                  className={
                    "sectionWrapper" + (section.type === sectionTypes.disclosures ? " disclosures-section" : "")
                  }
                >
                  <div className={classes.sectionTitle + " sectionTitle"}>
                    <Typography component="h2" variant="subtitle2">
                      {vo.entityType === "CASH_SALE"
                        ? section.config.title.replace("Finance", "")
                        : section.config.title}
                    </Typography>
                    <div
                      className={classes.collapseButton + " collapseButton"}
                      onClick={() => toggleCollapse(section.id)}
                    >
                      {collapseState[section.id] ? (
                        <div className={classes.icon + " " + classes.iconPlus} />
                      ) : (
                        <div className={classes.icon + " " + classes.iconMinus} />
                      )}
                    </div>
                  </div>
                  {!collapseState[section.id] && <div className={classes.blocks}>{getBlocks(section)}</div>}
                </div>
              );
            })}

            {_.some(creditAppModalData.recertificationHistory) && (
              <div className={"hiddenOnPdf"}>
                <h3>Recertification History</h3>
                {creditAppModalData.recertificationHistory /* .reverse() */
                  .map((row: any, idx: any) => {
                    return <div key={idx}>{row}</div>;
                  })}
              </div>
            )}
          </div>
        )}

        {isEditMode && (
          <div className={classes.containerForActions}>
            <Button color="primary" variant="outlined" onClick={() => setIsEditMode(false)}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={handleConfirm}>
              Save Details
            </Button>
          </div>
        )}
      </Paper>

      <CreditAppModifierConfirmModal
        open={isConfirmModalOpen}
        close={handleCloseConfirmationModal}
        handleSubmit={handleSubmit}
        creditAppUpdateIsSubmitting={creditAppUpdateIsSubmitting}
        additionalMessage={
          isSignatureDateWasUpdated &&
          "Please confirm you have recertified the below information with this applicant before proceeding."
        }
      />

      <AuthLinkDialog
        taskCreated={taskCreated}
        isOpen={isAuthLinkModalOpen}
        authLinkOwner={authLinkOwner}
        handleAuthConfirm={handleAuthConfirm}
        creatingAuthLinkTask={creatingAuthLinkTask}
        isCreatingAuthLinkTaskExist={!!creatingAuthLinkTask}
        onClose={() => {
          setIsAuthLinkModalOpen(false);
          setTimeout(() => setAuthLinkOwner({}), 1000);
        }}
      />

      <ConfirmationDialog
        title="Confirmation"
        messageLayout={() => (
          <>
            <Typography>
              You are trying to change credit application type. Are you sure that this Applicant applied as an
              Individual, but are really a Business Applicant?
            </Typography>
            <Typography variant="body2" style={{ marginTop: 15, color: "#666666" }}>
              This action can’t be undone
            </Typography>
          </>
        )}
        okButtonText="Confirm"
        open={isCreditAppConvertTypeConfirmationModalOpen}
        onClose={onCreditAppConvertTypeConfirmationModalClose}
        loading={isCreditAppConvertTypeLoading}
      />

      {/* Legacy Modal (CADMv1) */}
      <Modal
        open={openLegacyCreditAppModal}
        onClose={() => setOpenLegacyCreditAppModal(false)}
        aria-labelledby="Credit Application"
        aria-describedby="Summary"
      >
        <>
          {!!legacyPDFData && (
            <NewCreditAppModal
              {...legacyPDFData}
              {...vo}
              contactEmail={legacyPDFData.businessEmail}
              softPullInDealerPortal={vendorProfile.softPullInDealerPortal}
              refetchCreditApp={() => {}}
              handleRefetchCreditAppRequest={() => {}}
            />
          )}
        </>
      </Modal>

      <ContactModal
        isOpen={isAddContactModalOpen}
        onClose={handleCloseAddContactModal}
        onSave={handleSaveAddContactModal}
        isLoading={addContactModalLoading}
        mode="add-contact"
      />

      <ContactModal
        isOpen={isGuaranteeContactModalOpen}
        onClose={handleCloseGuaranteeContactModal}
        onSave={handleSaveGuaranteeContactModal}
        isLoading={isGuaranteeContactModalLoading}
        mode="guarantee"
        defaultContactType="signor-and-guarantor"
        defaultFormValues={contactToAddGuaranteeFormValues}
      />

      <ConfirmationDialog
        title={addAuthorizationTaskConfirmationTitle}
        messageLayout={() => <AuthorizationTaskConfirmationBodyLayout contactToSave={contactToSave!} />}
        loading={false}
        open={isAuthLinkConfirmationModalOpen}
        okButtonText="Confirm"
        cancelButtonText="Cancel"
        onClose={onAuthLinkConfirmationModalClose}
      />

      <RequestRecertificationModal
        isOpen={isRequestRecertificationModalOpen}
        onClose={handleCloseRequestRecertificationModal}
        onSave={handleSaveRequestRecertificationModal}
        isLoading={isRequestRecertificationModalLoading}
        vendorOpportunityTasks={vendorOpportunityTasks}
        defaultFormValues={recertificationFormValues}
        creditAppContacts={creditAppContacts}
      />
    </>
  );
};

const useStyles = makeStyles<Theme, { isEditMode: boolean }>(theme => ({
  cardContainer: {
    padding: "1rem",
    overflow: "hidden",
    /* height: "calc(100vh - 285px)", */
  },
  containerForLoading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  containerForContent: {
    overflow: "hidden auto",
  },
  containerForContentView: {
    height: "calc(100% - 134px)",
  },
  containerForContentEdit: {
    height: "calc(100% - 165px)",
  },
  containerForActions: {
    gap: "12px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  ownerBlockTitle: {
    padding: "4px 4px",
    background: "#eeeeee",
  },
  ownerActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    width: "100%",
    marginLeft: "10px",
  },
  root: {
    "& .MuiInput-underline:focus-within::after": {
      borderColor: theme.palette.primary.main,
    },
    "& .MuiInput-underline.Mui-error:focus-within::after": {
      borderColor: "#f44336",
    },
    "& .MuiInput-underline.Mui-error::after": {
      borderColor: "#ededed",
    },
    "& .locations-tree-view-dropdown": {
      width: "100%",
    },
  },
  fieldRow: {
    minWidth: 500,
    display: "flex",
    borderBottom: "1px solid lightgray",
    "@media (max-width: 768px)": {
      minWidth: "80vw",
    },
  },
  fieldRowEditMode: {
    minWidth: 500,
    display: "flex",
    borderBottom: "none",
    "@media (max-width: 768px)": {
      minWidth: "80vw",
    },
  },
  fieldTitle: {
    fontSize: "14px",
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  },
  detailsCell: {
    padding: "10px 5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  detailsCellEditMode: {
    padding: "0 5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  editRow: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
  },
  detailsLeftCol: {
    width: "30%",
    borderBottom: "none",
  },
  detailsLeftColEditMode: {
    width: "30%",
    borderBottom: "1px solid lightgray",
  },
  detailsRightCol: {
    width: "70%",
  },
  inputSlim: {
    margin: 0,
    padding: 0,
  },
  blocks: {
    position: "relative",
  },
  sectionTitle: {
    position: "relative",
    padding: "6px 12px",
    borderRadius: "4px",
    background: "lightgray",
    marginBottom: "8px",
    marginTop: "16px",
  },
  collapseButton: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 50,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 20,
    zIndex: 3,
    cursor: "pointer",
    color: "#999",
  },
  icon: {
    width: 20,
    height: 20,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    display: "inline-block",
    opacity: 0.3,
  },
  iconPlus: {
    backgroundImage: `url("${iconPlusSvg}")`,
  },
  iconMinus: {
    backgroundImage: `url("${iconMinusSvg}")`,
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  tileSubtitle: {
    color: "#3C4858",
    "&:first-of-type": {
      margin: "-15px 0 0",
    },
  },
  authorizationLinkSent: {
    display: "flex",
    alignItems: "center",
  },
  contactTopActionButton: {
    margin: "15px 15px 15px 0",
  },
}));
