import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useApolloClient } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useSelector } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Grid, LinearProgress, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tile from "components/Tile/Tile";
import PersonalLinkAndQRCode from "components/PersonalLinkAndQRCode";

import _ from "lodash";
import { REACT_APP_OCA_BASE_URL, S3_BUCKET_NAME } from "config";

const QUERY_VENDOR_PROFILE = gql`
  query($id: ID) {
    vendorProfile(id: $id) {
      id
      name
      dynamicsId
      hasLenderOcaAccess
    }
  }
`;

const useStyles = makeStyles({
  QRCode: {
    "& .MuiGrid-grid-sm-6.personalLinkAndQRCodeRoot": {
      maxWidth: "100%",
    },
  },
});

export default function DealerRelatedLdcOcaLinks(props) {
  const { userDetails } = props;
  const classes = useStyles();
  const userProfile = useSelector(state => state.userProfile);
  const lenderProfiles = useSelector(state => state.lp);

  const [isLoading, setIsLoading] = useState(false);
  const [lenderProfilesByVpIdHashMap, setLenderProfilesByVpIdHashMap] = useState();
  const [lenderVPsWithLenderOcaAccess, setLenderVPsWithLenderOcaAccess] = useState();

  const client = useApolloClient();

  useEffect(() => {
    if (!lenderProfiles) {
      return;
    }
    const lenderVPIds = _(lenderProfiles)
      .map("lenderAccount.vendorProfileId")
      .uniq()
      .compact()
      .value();
    const lenderProfilesByVpIdHashMap = _.groupBy(lenderProfiles, "lenderAccount.vendorProfileId"); // we may have multiple LPs for same vendorProfileId. Probably it's just a broken records
    setLenderProfilesByVpIdHashMap(lenderProfilesByVpIdHashMap);

    const getLendersVPsPromises = lenderVPIds.map(lenderVpId => {
      return client.query({
        // hotfix from https://github.com/apollographql/apollo-client/issues/9755#issuecomment-1224974719
        query: QUERY_VENDOR_PROFILE,
        variables: {
          id: lenderVpId,
        },
      });
    });

    setIsLoading(true);
    Promise.all(getLendersVPsPromises)
      .then(data => {
        const lenderVPs = _.map(data, "data.vendorProfile");
        const lenderVPsWithLenderOcaAccess = lenderVPs.filter(vp => vp.hasLenderOcaAccess);
        setLenderVPsWithLenderOcaAccess(lenderVPsWithLenderOcaAccess);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [lenderProfiles]);

  return (
    <Tile title="Lender Dealer Personal Credit Application Links">
      {lenderVPsWithLenderOcaAccess?.length ? (
        <Grid container>
          {lenderVPsWithLenderOcaAccess.map(lenderVp => {
            const lenderProfiles = lenderProfilesByVpIdHashMap[lenderVp.id];
            return lenderProfiles?.map(lenderProfile => {
              return (
                <Accordion key={lenderProfile.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${lenderProfile.id}-content`}
                    id={`${lenderProfile.id}-header`}
                  >
                    <Typography variant="h5">{lenderProfile.lenderName}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={6}>
                        {lenderProfile.lenderLogo && (
                          <img
                            alt="Lender Logo"
                            style={{
                              maxHeight: "250px",
                              maxWidth: "100%",
                            }}
                            src={`https://${S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${lenderProfile.lenderLogo}`}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6} className={classes.QRCode}>
                        <PersonalLinkAndQRCode
                          personalLink={`${REACT_APP_OCA_BASE_URL}/credit-app?lenderProfileId=${lenderProfile.id}&vendorcontactId=${userDetails.dynamicsContactId}`}
                          linkHeader="Personal Lender Dealer Credit Application Link"
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            });
          })}
        </Grid>
      ) : isLoading ? (
        <LinearProgress style={{ width: "100%" }} />
      ) : (
        <h3>No Related Lenders with Lender Dealer Oca Access</h3>
      )}
    </Tile>
  );
}
