import React from "react";
import { formatCurrency } from "utils";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import { ProposalProductAddon } from "@trnsact/trnsact-shared-types/src/generated";

interface Props {
  addons: ProposalProductAddon[];
  onRemoveAddon: (category: string) => void;
}

export const ProposalProductCardAddons = ({ onRemoveAddon, addons = [] }: Props) => {
  const classes = useStyles();

  return (
    <>
      {addons.map(addon => (
        <Box key={addon.title} className={classes.container}>
          <Divider orientation="horizontal" variant="fullWidth" flexItem style={{ height: "1px" }} />

          <Box className={classes.groupTitle}>
            <Typography component="span" variant="subtitle2">
              {addon.title} {addon.retailCost && `${formatCurrency(addon.retailCost)}`}
            </Typography>

            <Box className="actions">
              <IconButton
                size="small"
                onClick={event => {
                  event.stopPropagation();
                  onRemoveAddon(addon.title);
                }}
              >
                <DeleteOutlineIcon color="error" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
  groupTitle: {
    gap: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
