import { gql } from "apollo-boost";

export const UPDATE_CREDIT_APPLICATION_V2_QUERY = gql`
  mutation($creditAppId: ID!, $input: UpdateCreditApplicationV2Input!) {
    updateCreditApplicationV2(creditAppId: $creditAppId, input: $input)
  }
`;

export const UPSERT_TASK = gql`
  mutation($input: UpsertTaskInput!) {
    upsertTask(input: $input)
  }
`;

export const UPLOAD_FILE = gql`
  mutation($input: UpsertDocumentInput!, $file: Upload) {
    upsertDocument(input: $input, file: $file)
  }
`;
