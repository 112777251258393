import Mixpanel from "mixpanel-browser";
const config = require("config");

var mixpanel;

function noop() {}

// setting Mixpanel up if the token is set (usually in prod env only)
if (config.MIXPANEL_TOKEN) {
  Mixpanel.init(config.MIXPANEL_TOKEN);
  mixpanel = Mixpanel;
} else {
  // else setting a mock to prevent errors
  mixpanel = {
    init: noop,
    track: noop,
    register: noop,
  };
}

export default mixpanel;
