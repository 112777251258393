import React from "react";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { prescreenActions, prescreenSelector } from "../../../model";
import { FormSwitch } from "../../../../../components/form";

export const Header = () => {
  const dispatch = useDispatch();
  const isActive = useSelector(prescreenSelector.isActive);

  const updateActiveStatus = (nextValue: boolean) => {
    dispatch(prescreenActions.updateActiveStatus(nextValue));
  };

  return (
    <Paper className="pageHeader" elevation={0}>
      <Box className="pageHeaderMain">
        <Box className="pageHeaderMainTitle">
          <Typography variant="h5">Lender Waterfall</Typography>

          <Typography
            className={clsx({
              statusActive: isActive,
              statusInactive: !isActive,
            })}
          >
            {isActive ? "Active" : "Inactive"}
          </Typography>
        </Box>

        <FormSwitch
          value={isActive}
          label={
            <Typography component="span" variant="subtitle2">
              Start Lender Waterfall
            </Typography>
          }
          onChange={(_, nextValue) => {
            updateActiveStatus(nextValue);
          }}
        />
      </Box>

      <Button color="primary" variant="outlined" size="small" startIcon={<SaveIcon />}>
        Save config
      </Button>
    </Paper>
  );
};
