import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, Grid, InputLabel, TextField, Typography } from "@material-ui/core";
import { Customization } from "../Customization/Customization";
import { SavePanel } from "./SavePanel";
import { BlockList } from "./BlockList";

import _ from "lodash";

const useStyles = makeStyles(theme => ({
  formBuilder: {
    boxShadow: "-10px 0px 38px rgba(0, 0, 0, 0.319498)",
    position: "fixed",
    height: "100%",
    width: "400px",
    zIndex: "12",
    backgroundColor: "white",
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",
  },
  header: {
    color: "#000000",
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "500",
    padding: "30px 30px 10px",
    margin: 0,
  },
  divider: {
    margin: "0 20px",
  },
  formBuilderText: {
    textAlign: "center",
    color: "#818181",
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 50px 10px",
    margin: 0,
  },
  saveTemplateContainer: {
    position: "sticky",
    top: "0",
    backgroundColor: "#f7f7f7",
    zIndex: "2",
    borderBottom: "3px solid #377dc3",
    left: "0",
    width: "100%",
    boxShadow: "0px 4px 4px 2px rgb(0 0 0 / 30%)",
  },
  saveTemplateButton: {
    marginTop: "30px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 700,
    fontSize: 13,
    padding: "8px",
    borderRadius: 7,
  },
  changeTemplate: {
    textAlign: "center",
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginTop: "5px",
  },
  sectionNameContainer: {
    margin: "30px 20px 0",
  },
  sectionNameHeader: {
    textAlign: "center",
    marginBottom: "20px",
  },
  currentTemplateTitle: {
    textAlign: "center",
    display: "inline-block",
    fontSize: 16,
  },
  confirmationPageSettingsSaveButton: {
    backgroundColor: theme.palette.primary.main,
  },
  confirmationPageSettingsCancelButton: {
    backgroundColor: "#818181",
    color: "white",
  },
  templateSettingsOuterContainer: {
    margin: "40px 20px",
  },
  templateSettingsInnerContainer: {
    margin: "20px 0",
  },
  templateSettingContainer: {
    marginBottom: "20px",
  },
  templateSettingInputLabelContainer: {
    "&.MuiGrid-item": {
      paddingBottom: "0",
      paddingTop: "0",
      marginTop: "24px",
    },
  },
  templateSettingInputContainer: {
    marginRight: "40px",
  },
}));

export function FormBuilder({
  selectNewElement,
  config,
  prevTemplateName,
  setConfig,
  selectedItem,
  apiNames,
  setSelectedItem,
  setCustomizationMode,
  initialItem,
  customizationMode,
  deleteCreatedTemplate,
  selectedSection,
  handleChangeTemplateList,
  setEditTemplateMode,
  editTemplateSettingsMode,
  setEditTemplateSettingsMode,
  selectedTemplate,
  locations,
}) {
  const [saveMode, setSaveMode] = useState(false);
  const [prevConfig, setPrevConfig] = useState(config);
  const [templateHasChanged, setTemplateHasChanged] = useState(false);
  const initialConfirmationPageSettings = _.get(selectedTemplate, "jsonSettings.confirmationPage", null);
  const [confirmationPageSettings, setConfirmationPageSettings] = useState(initialConfirmationPageSettings);
  const initialDisclosuresSettings = _.get(selectedTemplate, "jsonSettings.disclosure", null);
  const [disclosuresSettings, setDisclosuresSettings] = useState(initialDisclosuresSettings);

  const classes = useStyles({ customizationMode, saveMode });

  const findSectionTitle = () => {
    for (let page of config) {
      const sectionWithTile = page.sections.find(section => section.title.id == selectedSection);
      if (sectionWithTile !== undefined) {
        return sectionWithTile.title.config.text;
      }
    }

    return null;
  };

  useEffect(() => {
    if (config !== prevConfig) {
      setPrevConfig(config);
      setTemplateHasChanged(true);
    }
  }, [config]);

  const handleSettingsUpdate = (key, value, setFn) => {
    setFn(prevConfig => {
      const objToSet = { ...prevConfig };
      _.set(objToSet, key, value);
      return objToSet;
    });
  };

  const dynamicDisclosuresInputsSettings = [
    {
      key: "authorizationLanguage",
      label: "Authorization Language",
      languages: [
        { key: "en", label: "Authorization Language English (html)" },
        { key: "es", label: "Authorization Language Spanish (html)" },
      ],
    },
    {
      key: "termsAndConditionsLanguage",
      label: "Terms And Conditions Language",
      languages: [
        { key: "en", label: "Terms And Conditions English (html)" },
        { key: "es", label: "Terms And Conditions Spanish (html)" },
      ],
    },
  ];

  const dynamicDisclosuresLayoutSettings = [
    {
      key: "commercial",
      label: "Commercial App",
      inputsSettings: dynamicDisclosuresInputsSettings,
    },
    {
      key: "individual",
      label: "Individual App",
      inputsSettings: dynamicDisclosuresInputsSettings,
    },
  ];

  const saveTemplateSettings = () => {
    const newSettings = {
      ...selectedTemplate.jsonSettings,
      confirmationPage: confirmationPageSettings,
      disclosure: disclosuresSettings,
    };
    handleChangeTemplateList(selectedTemplate.name, newSettings);
  };

  return (
    <Grid item xs={12} className={classes.formBuilder}>
      {!customizationMode ? (
        <Grid container spacing={2} justify={"center"} className={classes.saveTemplateContainer}>
          <Grid item xs={12}>
            <span className={classes.currentTemplateTitle}>
              Current Template: <b>{prevTemplateName}</b>
            </span>
          </Grid>
          <Grid item xs={5}>
            <Button
              fullWidth={true}
              color={"primary"}
              variant={"contained"}
              disabled={!templateHasChanged}
              onClick={() => {
                handleChangeTemplateList(prevTemplateName);
              }}
            >
              SAVE
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              fullWidth={true}
              color={"secondary"}
              variant={"contained"}
              onClick={() => {
                setEditTemplateMode(false);
                setCustomizationMode(false);
                setEditTemplateSettingsMode(false);
                setSaveMode(true);
              }}
            >
              SAVE AS ...
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Button
              fullWidth={true}
              color={"primary"}
              variant={"contained"}
              onClick={() => {
                setEditTemplateMode(false);
                setCustomizationMode(false);
                setSaveMode(false);
                setEditTemplateSettingsMode(true);
              }}
            >
              Template Settings
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.changeTemplate}>
            <span onClick={deleteCreatedTemplate}>View All Templates</span>
          </Grid>
        </Grid>
      ) : null}

      {selectedSection && !saveMode && !customizationMode && !editTemplateSettingsMode && (
        <>
          <Grid container justify="center">
            <Grid item xs={12} className={classes.divider}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.sectionNameContainer}>
            <Typography variant="h5" component="h5" className={classes.sectionNameHeader}>
              Section Name
            </Typography>
            <TextField variant="standard" fullWidth disabled value={findSectionTitle()} />
          </Grid>
        </>
      )}
      {customizationMode && (
        <Grid>
          <Customization
            config={config}
            setConfig={setConfig}
            apiNames={apiNames}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setCustomizationMode={setCustomizationMode}
            initialItem={initialItem}
            selectedSection={selectedSection}
            locations={locations}
          />
        </Grid>
      )}
      {saveMode && (
        <SavePanel
          setSaveMode={setSaveMode}
          prevTemplateName={prevTemplateName}
          deleteCreatedTemplate={deleteCreatedTemplate}
          handleChangeTemplateList={handleChangeTemplateList}
        />
      )}
      {editTemplateSettingsMode && (
        <Grid container justify="center" className={classes.templateSettingsOuterContainer}>
          <Typography variant="h4">Template Settings: </Typography>
          <Grid container className={classes.templateSettingsInnerContainer}>
            <Grid item container className={classes.templateSettingContainer}>
              <Grid item xs={12}>
                <Typography variant="h5">Confirmation Page: </Typography>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12} className={classes.templateSettingInputContainer}>
                  <TextField
                    variant="outlined"
                    label="Page layout (html)"
                    fullWidth
                    value={_.get(confirmationPageSettings, "layout", "")}
                    onChange={e => handleSettingsUpdate("layout", e.target.value, setConfirmationPageSettings)}
                  />
                </Grid>
                <Grid item xs={12} className={classes.templateSettingInputContainer}>
                  <TextField
                    variant="outlined"
                    label="Redirect link"
                    fullWidth
                    value={_.get(confirmationPageSettings, "redirectLink", "")}
                    onChange={e => handleSettingsUpdate("redirectLink", e.target.value, setConfirmationPageSettings)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container className={classes.templateSettingContainer}>
              <Grid item xs={12}>
                <Typography variant="h5">Dynamic Disclosures: </Typography>
              </Grid>
              {dynamicDisclosuresLayoutSettings.map(layoutSetting => {
                return (
                  <Grid key={layoutSetting.key} item container spacing={3}>
                    <Grid item spacing={1} className={classes.templateSettingInputLabelContainer}>
                      <InputLabel>{layoutSetting.label}:</InputLabel>
                    </Grid>
                    {layoutSetting.inputsSettings.map(inputSetting => {
                      return inputSetting.languages.map(language => {
                        const key = `${layoutSetting.key}.${inputSetting.key}.${language.key}`;

                        return (
                          <Grid item container spacing={1} key={key}>
                            <Grid item xs={12} className={classes.templateSettingInputContainer}>
                              <TextField
                                variant="outlined"
                                label={`${language.label}`}
                                fullWidth
                                value={_.get(disclosuresSettings, key)}
                                onChange={e => handleSettingsUpdate(key, e.target.value, setDisclosuresSettings)}
                              />
                            </Grid>
                          </Grid>
                        );
                      });
                    })}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid container justify="center" xs={12} spacing={2}>
            <Grid item>
              <Button
                className={`${classes.customizationButton} ${classes.confirmationPageSettingsSaveButton}`}
                variant="contained"
                color="primary"
                onClick={saveTemplateSettings}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={`${classes.customizationButton} ${classes.confirmationPageSettingsCancelButton}`}
                variant="contained"
                onClick={() => {
                  setConfirmationPageSettings(initialConfirmationPageSettings);
                  setEditTemplateSettingsMode(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!customizationMode && !saveMode && !editTemplateSettingsMode && (
        <BlockList selectNewElement={selectNewElement} />
      )}
    </Grid>
  );
}
