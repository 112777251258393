import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0c84fc",
      light: "#54a8fc",
      dark: "#085cb0",
      contrastText: "#fff",
    },
  },
});

const ThemeWrapper = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
