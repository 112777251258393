import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../model/types";
import { notificationsActions } from "../model/actions";
import { notificationsSelectors } from "../model/selectors";

interface Options {
  duration: number;
  type: "info" | "warning" | "success" | "error";
}

export function useNotifications() {
  const notifications = useSelector(notificationsSelectors.notifications) as Notification[];

  const dispatch = useDispatch();

  const showNotification = useCallback(
    (message: string, options?: Partial<Options>) => {
      const id = new Date().getTime();

      const notification: Notification = {
        id,
        message,
        type: options?.type ?? "success",
        duration: options?.duration ?? 3000,
      };

      dispatch(notificationsActions.addNotification(notification));
    },
    [dispatch]
  );

  const hideNotification = useCallback(
    (id: number) => {
      dispatch(notificationsActions.removeNotification(id));
    },
    [dispatch]
  );

  return {
    showNotification,
    hideNotification,
    notifications,
  };
}
