import React, { useContext } from "react";
import { CommonDataContext } from "../../../contexts/CommonDataContext";
import PageOpportunityDetails_V2 from "./PageOpportunityDetails_V2";
import PageOpportunityDetails from "../PageOpportunityDetails";
import { useViewTypeContext } from "../../../contexts/contentViewType";

export const PageOpportunityDetailsContainer = ({ match }: any) => {
  const { isNewVoLayout } = useContext(CommonDataContext);
  const { isMobile } = useViewTypeContext();

  return isNewVoLayout || isMobile ? (
    <PageOpportunityDetails_V2 match={match} />
  ) : (
    <PageOpportunityDetails match={match} />
  );
};
