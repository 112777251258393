import { gql } from "apollo-boost";

export const Q_VO = gql`
  query($voId: ID!) {
    vendorOpportunity(id: $voId) {
      accountProfileContact {
        fullName
      }
      transactionId
      applicationStage
      accountPrimaryContactName
      accountPrimaryContactId
      createdOn
      statusDetail
      deliveryDate
      deliveryStatus
      downPayment
      equipmentApprovalStatus
      equipmentAcceptanceDate
      equipmentDescription
      equipmentCondition
      equipmentCost
      equipmentModelYear
      expectedDeliveryDate
      freightLaborInstallation
      invoiceStatus
      invoiceTotal
      orderDate
      dealerMarkUpRate
      dealerMarkUpAmount
      partnerProfileDynamicsId
      partnerLogo
      partnerName
      creditLineAmountRequested
      creditLineStatus
      creditLineNotes
      vendorProfileId
      sourceVendorProfileId
      extendedWarrantyAmount
      gapInsuranceAmount
      roadsideCoverageAmount
      roadHazardAmount
      docFeeAmount
      physicalDamageCoverageAmount
      federalExciseTaxAmount
      lengthOfContractInMonths
      checklist
      potentialCustomer {
        name
        id
        dynamicsAccountId
      }
      salesManager {
        id
        fullName
        dynamicsContactId
        firstName
        lastName
        email
        phoneNumber
        title
      }
      salesRepresentative {
        id
        fullName
        dynamicsContactId
        firstName
        lastName
        email
        phoneNumber
        title
      }
      salesTaxOnInvoice
      invoiceWarrantyAndInsuranceCharges
      manufacturerRebateAmount
      tradeOwedBalanceAmount
      tradeAllowanceGrossTradeIn
      vendorOpportunityId
      dynamicsVendorOpportunityId
      financeManager {
        id
        email
        dynamicsContactId
        firstName
        lastName
        fullName
        phoneNumber
        title
      }
      dynamicsVOStatus
      dynamicsVOStage
      transactionStage
      voProposal {
        productId
        rate
        riskTier
        markupDealer
        amountRequested
        paymentCards {
          productName
          productHeader
          term
          rate
          payment
          rateFactor
          riskTier
          amountMin
          amountMax
          docFee
          advancePayment
          markupInternal
          productId
        }
      }
      contactFormNotes
      sourceUrl
      location {
        locationId
        locationName
        apiIdentifierEngs
        apiIdentifierPaccar
        category
        parentLocation {
          locationId
          locationName
          category
        }
      }
      fastTrackResults {
        creditSubmissionId
        fastTrackId
        applicationStatus
        rate
        maxApprovalAmount
        approvedBy
        inputs {
          key
          value
        }
        outputs {
          key
          value
        }
      }
      fastTrackStatus
      notes
      entityType
    }
  }
`;

export const Q_CREDIT_APP = gql`
  query($VOId: ID!) {
    creditApplication(VOId: $VOId) {
      id
      dynamicsId
      amountRequested
      firstName
      middleName
      lastName
      nameSuffix
      email
      businessName
      title
      phone
      bizPhone
      businessAddressCounty
      address
      city
      state
      stateOfIncorp
      postalCode
      legalStructure
      tib
      repId
      vendorSalespersonId
      transactionGuid
      yearsLicensed
      signature
      title
      ssn
      ssnExempt
      ownerPercentage
      businessType
      parentCompany
      country
      dateEstablished
      dateIncorporated
      taxId
      monthlyRevenue
      dateOfBirth
      industryExpYears
      monthlyIncome
      previousFinanceExp
      trucksOwned
      bizPhone
      homeSameAsBiz
      homeAddress
      homeCity
      homeState
      homePostalCode
      equipmentDescription
      createdDateTime
      modifiedDateTime
      paymentSelectorURL
      dcrType
      dcrProductId
      paymentSelectorURLV2
      proposalId
      rate
      riskTier
      markupInternal
      markupDealer
      dba
      vendorGUID
      stockId
      stateOfIncorp
      businessAddressCounty
      driversLicenseNum
      dlStateIssued
      homeType
      homePhone
      applicationType
      applyingFor
      creditLineAmountRequested
      creditLineStatus
      creditLineNotes
      sic
      equipmentCategoryCode
      equipmentNumInFleet
      purchaseReason
      piecesOfHeavyEqpt
      piecesOfMediumEqpt
      piecesOfTrailerEqpt
      totalEqpt
      eqptUse
      numOfOwnerOps
      typesOfGoods
      longHaulRegional
      hazmat
      yearMakeModel
      payment
      eqptPaidOff
      eqptTrade
      ownAuth
      mcNumber
      usDotNum
      eqptAddOrReplace
      applyingFor
      invoiceDelivery
      purchaseOrder
      primaryAgriculturalProduct
      farmingStartYear
      farmingIncome
      nonFarmingIncome
      nearestRelativeFirstName
      nearestRelativeLastName
      nearestRelativeCity
      nearestRelativePhone
      nearestRelativeState
      nearestRelativeZip
      billingAddress
      billingCity
      billingState
      billingPostalCode
      numberOfEmployees
      useByApplicant
      kubotaPrincipalUse
      medicalPractice
      industryType
      taxId
      createdDateTime
      entityType
      ocaTemplateId
      ocaTemplate {
        vendorProfileId
        jsonDefinition
        name
      }
      location {
        locationId
        locationName
        category
        accountDynamicsId
        apiIdentifierEngs
        apiIdentifierPaccar
        contactIds
      }
      primaryContact {
        ownerPgId
        pc
        pg
        pcOnly
        firstName
        middleName
        lastName
        nameSuffix
        ownerPercentage
        ssn
        title
        dateOfBirth
        address
        address2
        city
        state
        postalCode
        addressCounty
        driversLicenseNum
        dlStateIssued
        driverLicenseExp
        ownerNumber
        homeType
        homePhone
        phone
        timeAtAddressYears
        timeAtAddressMonths
        netWorth
        grossMonthlyIncome
        monthlyHousingPayment
        employerName
        employerAddress
        employerAddress2
        employerCity
        employerState
        employerZip
        employmentStatus
        workPhone
        employerEmail
        timeAtJobYears
        timeAtJobMonths
        mobilePhone
        signature
        signatureDate
        dlUploaded
        email
        usCitizen
        commercialDriverMonths
        commercialDriverYears
        residentStatus
        providingGuaranty
        maritalStatus
        companyDriverYears
        ownerOperatorYears
        countryOfResidence
        ownershipType
        firstTimeFinancing
        nearestRelativeFirstName
        nearestRelativeLastName
        nearestRelativePhone
        nearestRelativeCity
        nearestRelativeZip
        prevAddress
        prevAddress2
        prevCity
        prevState
        prevCounty
        prevPostalCode
        reasonForDeclining
        yearsWithCdl
        personalGuaranteeSignature
        personalGuaranteeSignatureDate
        addendum
        ipAddress
      }
      mostRecentCreditReportBusiness {
        creditReportBusinessId
        externalReportId
        vendorOpportunityId
        reportType
        score
        machineReadableReport
        humanReadableReportDocumentId
        createdDateTime
      }
      mostRecentCreditReportOfac {
        creditReportBusinessId
        externalReportId
        vendorOpportunityId
        reportType
        score
        machineReadableReport
        humanReadableReportDocumentId
        createdDateTime
      }
      creditApplicationOwner {
        ownerPgId
        pc
        pg
        pcOnly
        email
        creditApplicationId
        firstName
        middleName
        lastName
        nameSuffix
        ownerPercentage
        prevCounty
        ssn
        title
        dateOfBirth
        address
        address2
        city
        state
        postalCode
        driversLicenseNum
        dlStateIssued
        ownerNumber
        homeType
        homePhone
        phone
        timeAtAddressYears
        timeAtAddressMonths
        netWorth
        grossMonthlyIncome
        monthlyHousingPayment
        employerName
        employerAddress
        employerAddress2
        employerCity
        employerState
        employerZip
        employerEmail
        employmentStatus
        workPhone
        timeAtJobYears
        timeAtJobMonths
        mobilePhone
        signature
        signatureTitle
        signatureDate
        dlUploaded
        usCitizen
        commercialDriverMonths
        commercialDriverYears
        residentStatus
        providingGuaranty
        maritalStatus
        companyDriverYears
        ownerOperatorYears
        countryOfResidence
        ownershipType
        firstTimeFinancing
        nearestRelativeFirstName
        nearestRelativeLastName
        nearestRelativePhone
        nearestRelativeCity
        nearestRelativeZip
        prevAddress
        prevAddress2
        prevCity
        prevState
        prevCounty
        prevPostalCode
        reasonForDeclining
        yearsWithCdl
        personalGuaranteeSignature
        personalGuaranteeSignatureDate
        addendum
        ipAddress
        personalGuarantor {
          personalGuarantorId
          personalGuarantorDynamicsId
          personalGuarantorName
          mostRecentCbrs {
            bureauType
            creditBureauType
            creditReportConsumerId
            auditLog
            humanReadableReportDocumentId
            ownerPgId
            scoreType
            ficoScore
            fields {
              key
              value
            }
            publicRecordCount
            pastAmountDue
            totalInquiryCount
            satisfactoryAccountsCount
            installmentBalance
            monthlyPayment
            inquiryCountLastSixMonths
            derogAccountsCount
            realEstateBalance
            realEstatePayment
            tradelineCount
            pastDerogAccountsCount
            collectionRecordsCount
            revolvingAvailPercent
            paidAccountsCount
            oldestTradeline
            bankruptcyRecordCount
            revolvingBalance
            revolvingTotalCredit
            errorDescription
            createdDateTime
            modifiedDateTime
          }
        }
      }
      creditLineStatus
      truckType
      references {
        referenceId
        companyName
        contactName
        contactPhone
        accountNum
        originalLoanAmt
        percRevenue
        years
        months
        lineOfCredit
        creditLimit
        currentBalance
        monthlyPayment
        renewalDate
        referenceType
        contactEmail
        contactFax
        address
        city
        state
        zip
        county
        jobTitle
        startDate
        endDate
        industry
        salary
        monthlyRevenue
        paidPerMile
        percentGross
        averageBalance
        yearMakeModel
        paidOff
        trade
        securedBy
        physicalDamageDeduction
        liabilityCoverage
        productType
        okayToContact
        driverType
        futureEmployerSameAsCurrent
        monthlyMiles
        custom1
        custom2
        custom3
        referenceIdx
        ownerId
      }
      locationId
      location {
        locationId
        locationName
        category
        accountDynamicsId
      }
      businessOrIndividual
      farmType
      usCitizen
      farmingStatus
      addressCounty
      agriculturalExempt
      annualMileage
      annualRevenue
      apContactEmail
      apContactName
      apContactPhone
      bankruptcyDate
      contractorLicenseNum
      currentOwnerStartBusiness
      downPaymentAmount
      employerAddress
      employerAddress2
      employerCity
      employerEmail
      employerName
      employerPhone
      employerState
      employerZip
      existingCustomer
      fleetOperation
      fleetSegments
      formId
      homeCounty
      inactiveMonths
      incomeAmountPerYearOther
      incomeSourceOther
      lienOrJudgement
      lienOrJudgementDetail
      numOfEmployees
      numberOfAdditions
      numberOfReplacements
      ocaType
      operatingLine
      periodOfEmploymentMonths
      periodOfEmploymentYears
      piecesOfTractorEqpt
      piecesOfTruckEqpt
      piecesOfUnitsPurchased
      prevAddress
      prevCity
      prevCounty
      prevPostalCode
      prevState
      previousEmployerName
      previousEmployerPeriodOfEmploymentYears
      primaryBankAccountNum
      primaryBankAverageBalance
      primaryBankContactName
      primaryBankName
      primaryBankPhone
      primaryCreditAccountNum
      primaryCreditAverageBalance
      primaryCreditContactEmail
      primaryCreditContactName
      primaryCreditName
      primaryCreditPhone
      priorBankruptcy
      salesTaxExempt
      seasonalBusiness
      termRequested
      timeAtAddressInMonths
      timeAtAddressInYears
      timeAtPrevAddressMonths
      timeAtPrevAddressYears
      totalAssets
      totalLiabilities
      yearsInBusiness
      pastExperience
      disclosure
      applicantType
      hadItemRepossessed
      repossessionDetail
      rentalHouse
      firstTimeBuyer
      addendum
      addendumContent
      insuranceWaiver
      insuranceWaiverContent
      providingInsurance
      purchaseTimeframe
      authorizedParties
      workingCapitalRequestedAmount
      averageDailyBalance
      workingCapitalOffer
      recertificationDate
      recertificationHistory
    }
  }
`;

export const QUERY_ACCOUNT_BY_VP_ID = gql`
  query ocaTemplateAccount($vendorProfileId: ID!) {
    accountByVPId(vendorProfileId: $vendorProfileId) {
      id
      name
      vendorProfileId
      accountType
    }
  }
`;

export const QUERY_PORTAL_CONFIGURATION = gql`
  query($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
    }
  }
`;

export const Q_VENDOR_PROFILE = gql`
  query($id: ID, $dynamicsId: ID, $userProfileId: ID) {
    vendorProfile(id: $id, dynamicsId: $dynamicsId, userProfileId: $userProfileId) {
      id
      name
      dynamicsId
      showDocMgt
      showLenderDocsAccessMgmt
      showCreditApp
      showPsOptions
      showMarginTool
      showLenderSubmission
      showPortalCreditApp
      showPrequal
      industryGroupType
      role
      logo
      dcrPrescreenStatus
      rawDisclosureTermsPage1
      rawDisclosureTermsPage2
      showChat
      manualVendorOpportunityCreationAccess
      showTasks
      amountRequested
      showContract
      showRouteOneApp
      softPullInDealerPortal
      settingBusinessCreditReportPullInDealerPortal
      showNotes
      dcrDisclosureLanguage
      dcrDisclosureLanguageSpanish
      dcrDisclosureTerms
      dcrDisclosureTermsSpanish
      dcrDisclosureUseDefaultTerms
      ocaPersonalGuaranteeAgreementDisclosure
      ofacRedFlagComplianceAccess
      daysToCreditAppRecertification
      requireCreditAppRecertification
      creditPullSettings {
        vendorProfileCreditPullSettingsId
        vendorProfileId
        autoPull
        creditBureauType
      }
      lenderProfiles {
        id
        lenderName
        submissionMethod
        submissionReviewShowMappingModal
        lenderChannelCode
        lenderTypeCode
        showCustomApp
        draftApplicationsEnabled
        pdfTemplate
        lenderProfileId
        lenderAccountId
        lenderContactId
        lenderUserProfile {
          id
          email
          dynamicsContactId
          firstName
          lastName
          fullName
          phoneNumber
          mobilePhoneNumber
          mugshot
          accountId
        }
        lenderAccount {
          id
          dynamicsAccountId
          vendorProfileId
          primaryContactId
          name
          address
          phone
          primaryContactName
          primaryContactPhone
          primaryContactEmail
        }
      }
    }
  }
`;

export const M_UPDATE_LENDER_WATERFALL = gql`
  mutation UpdateLenderWaterfall(
    $input: UpdateLenderWaterfallPrescreenResultInput!
    $lenderWaterfallPrescreenCriteriaResultId: ID!
  ) {
    updateLenderWaterfallPrescreenResult(
      input: $input
      lenderWaterfallPrescreenCriteriaResultId: $lenderWaterfallPrescreenCriteriaResultId
    ) {
      lenderWaterfallPrescreenCriteriaResultId
      lenderProfileId
      createdDateTime
      modifiedDateTime
      step
      vendorOpportunityId
      processInstanceId
    }
  }
`;

export const QUERY_ACTIVE_OCA_TEMPLATE = gql`
  query($vendorProfileId: ID!, $isActive: Boolean) {
    getOcaTemplatesByVendorProfile(vendorProfileId: $vendorProfileId, isActive: $isActive) {
      ocaTemplateId
      name
      vendorProfileName
      jsonDefinition
      active
    }
  }
`;

export const SAVE_PORTAL_CONFIGURATION = gql`
  mutation($input: UpsertPortalConfigurationInput!) {
    savePortalConfiguration(input: $input)
  }
`;

export const M_UPDATE_VP = gql`
  mutation($entityId: String!, $fieldsObj: UpdateVendorProfileInput!) {
    updateVendorProfile(entityId: $entityId, fieldsObj: $fieldsObj) {
      id
    }
  }
`;
