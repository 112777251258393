import React from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import TaskList from "../../components/TaskList";

const QUERY_TASKS_BY_CS = gql`
  query vendorOpportunityTaskByCS($csId: ID!, $limit: Int) {
    vendorOpportunityTask(csId: $csId, limit: $limit) {
      taskId
      vendorOpportunityId
      regarding
      status
      assignedToUserProfileId
      assignedToAccountId
      assignedByUserProfileId
      assignedByAccountId
      notes
      priority
      createdBy
      createdDateTime
      assignedByUser
      assignedByAccount
      assignedToUser
      assignedToAccount
      notifyByEmail
      notifyByText
      lastReminderSent
      nextReminder
      taskItems {
        taskItemId
        type
        docType
        taskAssignorNotes
        taskAssigneeNotes
        doc {
          documentId
          vendorOpportunityId
          source
          docType
          docDescription
          createdBy
          docName
        }
      }
    }
  }
`;

const QUERY_RELATED_USERS = gql`
  query($accountId: ID) {
    relatedUsers(accountId: $accountId) {
      id
      dynamicsContactId
      email
      firstName
      lastName
      fullName
      phoneNumber
      mugshot
      availability
      vendorContactRole
      title
      accountId
    }
  }
`;

export default function SpecificTasks({ vo, account, userProfile, relatedUsers, cs, refetchDocs }) {
  //   const [relatedUsers, setRelatedUsers] = useState([]);

  const [
    getRelatedUsers,
    { loading: relatedUsersLoading, error: relatedUsersError, data: relatedUsersData, refetch: relatedUsersRefetch },
  ] = useLazyQuery(QUERY_RELATED_USERS, {
    variables: {
      accountId: account.id,
    },
    context: { authRequired: true },
  });
  const creditSubId = cs.creditSubId;

  const relatedUser = {
    id: cs.contactUserProfileId,
    fullName: cs.contact,
    accountId: cs.dealerId,
  };

  //   useEffect(() => {
  //     getRelatedUsers();
  //     if (relatedUsersData !== null && relatedUsersData !== undefined && relatedUsersData.relatedUsers !== null) {
  //       setRelatedUsers(relatedUsersData.relatedUsers);
  //     }
  //   }, [relatedUsersData]);

  //   if (relatedUsersLoading) console.log('Loading related users profiles...');
  //   if (relatedUsersError) console.log(`Error! ${relatedUsersError.message}`);
  return (
    <TaskList
      vo={vo}
      account={account}
      queryTasksOptions={
        !creditSubId
          ? {
              variables: {
                VOId: vo.vendorOpportunityId,
                csId: null,
                limit: 100,
              },
              context: { authRequired: true },
              fetchPolicy: "no-cache",
            }
          : {
              variables: {
                VOId: null,
                csId: creditSubId,
                limit: 100,
              },
              context: { authRequired: true },
              fetchPolicy: "no-cache",
            }
      }
      queryTasks={QUERY_TASKS_BY_CS}
      userProfile={userProfile}
      creditSubId={creditSubId}
      tasksQueryKey="vendorOpportunityTask"
      relatedUsers={relatedUsers}
      relatedUser={relatedUser}
      taskOnClickAction="editTask"
      addTaskFlag={true}
      cs={cs}
      refetchDocs={refetchDocs}
    ></TaskList>
  );

  // return (
  //   <Tasks
  //     vo={vo}
  //     account={account}
  //     queryTasks={!creditSubId ? QUERY_TASKS : QUERY_TASKS_BY_CS}
  //     userProfile={userProfile}
  //     queryTasksOptions={
  //       !creditSubId
  //         ? {
  //             variables: {
  //               VOId: vo.vendorOpportunityId,
  //               csId: null,
  //               limit: 100,
  //             },
  //             context: { authRequired: true },
  //           }
  //         : {
  //             variables: {
  //               VOId: null,
  //               csId: creditSubId,
  //               limit: 100,
  //             },
  //             context: { authRequired: true },
  //           }
  //     }
  //     creditSubId={creditSubId ? creditSubId : null}
  //     tasksQueryKey="vendorOpportunityTask"
  //     relatedUsers={relatedUsers}
  //     relatedUser={relatedUser}
  //     taskOnClickAction="editTask"
  //     addTaskFlag={true}
  //     cs={cs}
  //   />
  // );
}
