import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useDrag } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemText } from "@material-ui/core";
import { ConfigurableItemActions } from "../PortalConfigurationWizard/CreditAppDetailModal/ConfigurableItemActions";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationDialog from "components/ConfirmationDialog";
import { proposalMenuDraggableTypes } from "../../../src/pages/Prequal/constants";
import _ from "lodash";

const UPDATE_PROPOSAL_PRODUCT = gql`
  mutation($input: UpdateProposalProductInput!) {
    updateProposalProduct(input: $input) {
      proposalProductId
    }
  }
`;

const styles = {};

const useStyles = makeStyles(styles);

const DraggableProductItem = ({ product, handleEditProductClick, refetchProducts }) => {
  const classes = useStyles();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [updateProposalProduct, { loading: updateLoading }] = useMutation(UPDATE_PROPOSAL_PRODUCT, {
    context: { authRequired: true },
    onCompleted() {
      refetchProducts();
    },
  });

  const handleRemoveProposalProductClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = (event, reason) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      updateProposalProduct({
        variables: {
          input: {
            proposalProductId: product.proposalProductId,
            archived: true,
          },
        },
      });
    }
  };

  const [{ isDragging, handlerId }, drag] = useDrag(
    () => ({
      type: proposalMenuDraggableTypes.proposalProduct,
      item: product,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        canDrag: monitor.canDrag(),
      }),
      canDrag: true,
    }),
    []
  );

  return (
    <>
      <ListItem button ref={drag} data-handler-id={handlerId} style={{ cursor: "move" }}>
        <DragHandleIcon />
        <ListItemText primary={product.title} style={{ marginLeft: 6 }} />
        <ConfigurableItemActions
          itemType="Product"
          onEdit={() => handleEditProductClick(product)}
          onRemove={handleRemoveProposalProductClick}
          onRemoveCustomIcon={DeleteIcon}
        />
      </ListItem>

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove Product?"
        open={isConfirmationModalOpen}
        onClose={onConfiramtionModalClose}
      />
    </>
  );
};

export default DraggableProductItem;
