import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { grey } from "@material-ui/core/colors";
import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useMenuOptionTerms } from "../../../hooks";

export const MenuOptionCardsHeader = () => {
  const classes = useStyles();

  const {
    currentTerm,
    forwardTooltip,
    backwardTooltip,
    handleUpdateTerm,
    isForwardDisabled,
    isBackwardDisabled,
  } = useMenuOptionTerms();

  return (
    <Box className={classes.termPanel}>
      <Tooltip title={backwardTooltip}>
        <IconButton size="small" disabled={isBackwardDisabled} onClick={() => handleUpdateTerm("sub")}>
          <ArrowBackIos />
        </IconButton>
      </Tooltip>

      <Typography component="span" variant="subtitle2">
        {currentTerm.term} Months
      </Typography>

      <Tooltip title={forwardTooltip}>
        <IconButton size="small" disabled={isForwardDisabled} onClick={() => handleUpdateTerm("add")}>
          <ArrowForwardIos />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const useStyles = makeStyles({
  termPanel: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
    justifyContent: "space-between",
  },
});
