import { makeStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles({
  container: {
    borderTop: "1px solid white",
    backgroundColor: "#535353",
    zIndex: "100",
  },
  title: {
    fontSize: "30px",
    textAlign: "center",
    textDecoration: "underline",
    color: "#FFF",
  },
  termsItem: {
    padding: "5px",
    color: "#FFF",
  },
});

export default function TermsAndConditionsPage() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Terms and Conditions</h2>
      <h4>
        Following Terms and Conditions are applicable to the Base and Enterprise versions of Trnsact’s
        Software-as-a-Service licenses and products. PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE SAAS
        PRODUCTS. THIS SAAS TERMS AND CONDITIONS CONSTITUTES A LEGAL AND ENFORCEABLE CONTRACT BETWEEN CUSTOMER AND
        Trnsact. BY INDICATING CONSENT ELECTRONICALLY, OR ACCESSING OR OTHERWISE USING THE SAAS PRODUCTS, CUSTOMER
        AGREES TO THE TERMS AND CONDITIONS OF THIS AGREEMENT.
      </h4>
      <ul>
        <li className={classes.termsItem}>
          <b>Contracting Parties.</b> This Agreement is binding between Dealer/Manufacturer, as specified above,
          referred to hereinafter as (“Dealer/Manufacturer/Customer”), and Trnsact, Inc.
        </li>

        <li className={classes.termsItem}>
          <b>Use.</b> Subject to payment of all applicable fees set forth in the Trnsact Licensing Agreement or payment
          in accordance with an Indirect Order through Trnsact’s Distribution Partner and the terms and conditions of
          this Agreement, Trnsact grants Customer, during the Subscription Term, a non-exclusive, non-transferable right
          to access and use Trnsact SaaS Products and applicable Documentation solely for Customer’s and its Affiliates’
          internal business purposes in accordance with the Documentation and in the quantity specified in the Licensing
          Agreement.
        </li>

        <li className={classes.termsItem}>
          <b>Access.</b> Customer is responsible for ensuring: (i) that only Authorized Users have access to the SaaS
          Products, (ii) that such Authorized Users have been trained in proper use of the Trnsact SaaS Products, and
          (iii) proper usage of passwords, tokens, and access procedures with respect to logging into the SaaS Products.
          Trnsact reserves the right to refuse registration of, or to cancel, login IDs that it reasonably believes to
          violate the terms and conditions set forth in this Agreement. In addition to the rights set forth in this
          Agreement, Trnsact may suspend Customer’s access and use of the SaaS Products if there is an unusual and
          material spike or increase in Customer’s use of the SaaS Products and Trnsact reasonably suspects or knows
          that such traffic or use is fraudulent or materially and negatively impacting the operating capability of the
          SaaS Products.
        </li>

        <li className={classes.termsItem}>
          <b>Products & Services.</b> Customer is entitled to use the above-described Module(s) created and supported by
          Trnsact, including all new features created and released, without an increase in the monthly fee. Additional
          new Modules may be incorporated into the ‘System’ which may increase the monthly fee to the Customer. The
          addition of new sites will increase the monthly fee to the Customer. It is required by the customer to
          maintain at least one site at all times overall. In addition, it is required by the customer to purchase at
          least one site license per physical location.
        </li>

        <li className={classes.termsItem}>
          <b>System.</b> The use of the “System” herein, shall mean the Trnsact software system and all associated
          training and marketing materials provided by Trnsact. The customer is aware that the System may be updated
          and/or enhanced during the term of the agreement; and as such the look, feel, usability, and features may
          change during the agreement period.
        </li>

        <li className={classes.termsItem}>
          <b>License.</b> Terms and Conditions outlined in this agreement are applicable to Trnsact’s Base as well as
          Enterprise versions of Software-as-a-Service licenses. The Base License covers single vendor, dealer, and/or
          location). The Enterprise version of the license covers multiple vendors, dealers, stores, and locations of a
          single larger legal entity. Payment and additional terms related to Base and Enterprise SaaS licenses are set
          forth in the separate Trnsact Licensing Agreement and Pricing Schedules.
        </li>

        <li className={classes.termsItem}>
          <b>Service Term & Termination.</b> The Term of this Agreement shall begin on the day of execution of this
          agreement and shall continue for a period on one year unless specified otherwise in the “Purchase Terms”
          section above (the “Agreement Period”), and renew automatically unless either party provides sixty (60) days
          prior written notice to the other of its intent not to renew the agreement to the end of the initial agreement
          period or then current Renewal Period. After the initial term, the Renewal Period will be year to year. In
          order to cancel or opt out of the contract after the initial term, sixty (60) days written notice is required.
          Upon any termination hereunder all rights to use Trnsact terminate immediately.
        </li>

        <li className={classes.termsItem}>
          <b>Technology Support. Shall be provided Mon.</b> – Fri. from 8 am PST to 5 pm PST.
        </li>

        <li className={classes.termsItem}>
          <b>Setup Fees, Last Period Deposit, Implementation & Training.</b> The setup fees specified above include
          Setup, Integration, Training, and Data Import where required. A separate fee will be charged for custom system
          integrations, if any. A setup fee equal to three times the monthly site fee is due each time a new site is
          added. It is necessary that the Customer provide the required source files where requested and complete tasks
          assigned to them during the implementation.
        </li>

        <li className={classes.termsItem}>
          <b>Scheduling Onsite Implementation.</b> When requested, due to scheduling of resources and travel
          considerations, once the implementation date has been confirmed with the Customer, Trnsact cannot change the
          implementation date without an additional expense to the Customer. A minimum implementation date change fee of
          $1,500 plus applicable travel related change fees will apply. Usage fees will start on the actual
          implementation start date.
        </li>

        <li className={classes.termsItem}>
          <b>Training.</b> Customer shall receive unlimited access to the on-line training for each module and feature
          of Trnsact. Also, additional live training via web-conferencing will be scheduled for select modules/features
          after the Customer has completed viewing all applicable on-line training videos. If the Customer has
          additional questions or clarifications regarding Trnsact, they may call the support line at no additional
          charge.
        </li>

        <li className={classes.termsItem}>
          <b>Customer Responsibilities.</b> The Customer is responsible for determining whether the System will achieve
          the results the Customer desires and maintaining the necessary computer system required to access the System.
          During the Term of the Agreement, Dealer agrees to not introduce another financing sources to any of its
          customers unless the customer application has already been to Trnsact and Trnsact has declined in writing to
          offer financing from any of its lenders. All information provided to Company by Dealer concerning any
          applicant in question is true, accurate, complete, and not misleading. All the Equipment described in such
          Contract has been delivered to the location stated in the Contract, was new at the time of such delivery
          unless otherwise disclosed to Company in writing and approved by Company in writing and has been
          unconditionally accepted by the Customer in a condition satisfactory to the Customer. The Customer is not in
          default with respect to any other agreement with Vendor and, Vendor is aware of no event which with the
          passing of time or the giving of notice or both would constitute an event of default by Customer thereunder.
          Vendor has not and will not pay any rent or debt service payable by the Customer under the Contract.
        </li>

        <li className={classes.termsItem}>
          <b>Payment.</b> Customer will pay all licensing fees, plus appropriate taxes charged by the city, county,
          state in which they reside, or any tax imposed on the sale of the license and services provided by Trnsact, to
          Trnsact on the first day of the month and are considered past due after the 15th day of the month. All
          invoices for incidental and custom development work must be paid within thirty (30) days of date of invoice.
        </li>

        <li className={classes.termsItem}>
          <b>Grant of License.</b> Trnsact hereby grants, and the Customer accepts on behalf of itself and its
          employees, a nonexclusive, license to use the Trnsact Software and System for the limited purpose of its
          internal business purposes during the term of the Agreement.
        </li>

        <li className={classes.termsItem}>
          <b>Authorized Use.</b> Customer shall cause all persons (“Users”) obtaining access to the System to access the
          System solely in accordance with the terms hereof. A minimum of one User must be maintained per physical
          location during the length of the Agreement or Renewal period. Users are authorized to share licenses,
          however, when two people share a User, Trnsact cannot track which of these people created, modified, or
          approved records in the system. Also, when two or more people share a User, they have access to the same
          records and data in Trnsact (i.e., Security and Audit Tracking is done on the User level.)
        </li>

        <li className={classes.termsItem}>
          <b>Custom Development.</b> In addition to the setup and implementation of Trnsact’s SaaS products, Trnsact may
          provide custom software development services to the Customer. The pricing, development schedule, and
          additional terms may be documented in a separate Licensing Agreements and Scope of Work document.
        </li>

        <li className={classes.termsItem}>
          <b>Representations and Warranties.</b> Each party represents and warrants to the other that it is duly
          organized, validly existing, and in good standing under the laws of the jurisdiction of its incorporation or
          organization. Each party represents and warrants to the other that its officers are duly authorized to execute
          and deliver this Agreement and any other documents contemplated hereby, and they will continue to be duly
          authorized to perform all of such party’s obligations under this Agreement and under each instrument and
          document delivered in connection with this Agreement and that the obligations set forth in this Agreement and
          any such other documents are its valid and binding obligations, enforceable in accordance with their terms.
        </li>

        <li className={classes.termsItem}>
          <b>Confidentiality.</b> Confidential Information means nonpublic information that the disclosing party
          designates as being confidential or which under the circumstances surrounding disclosure should be treated as
          confidential (“Confidential Information”). Confidential Information includes, without limitation: information
          relating to the disclosing party's software or hardware products which may include source code, API data
          files, documentation, specifications, databases, networks, system design, file layouts, tool combinations and
          development methods as well as information relating to the disclosing party's business or financial affairs,
          which may include business methods, marketing strategies, pricing, competitor information, product development
          strategies and methods, customer lists and financial results. Confidential Information also includes
          information received from others that the disclosing party is obligated to treat as confidential and oral
          information that is identified by the disclosing party as confidential. Confidential Information disclosed by
          a subsidiary of the disclosing party and/or its agents is covered by this Agreement. Confidential Information
          includes all tangible materials which contain Confidential Information whether written or printed documents,
          computer disks or tapes whether user or machine-readable. The terms and provisions of this Confidentiality
          Section shall survive any termination of the services agreement for any reason
        </li>

        <li className={classes.termsItem}>
          <b>Intellectual Property Rights.</b> In the course of this Agreement, Trnsact may create or use enhancements,
          discoveries, processes, methods, designs, software code and know-how, whether or not copyrightable or
          patentable, which Trnsact conceived while working with you during the course of its ongoing business
          activities. In addition, Trnsact may independently develop enhancements, processes, methods, designs or
          know-how during the term of this Agreement. The parties mutually acknowledge that Trnsact shall own all right,
          title and interest in and to such enhancements, processes, methods, designs, and know-how including without
          limitation the intellectual property rights relating thereto, and may use such enhancements, processes,
          methods, designs and know-how in their business operations with other customers, without limitation. Trnsact
          shall own all intellectual property developed or created during this Agreement, including but not limited to
          all code developed for the Customer.
        </li>

        <li className={classes.termsItem}>
          <b>General Warranty Disclaimer.</b> Trnsact makes and the Customer receives no warranties, express, implied,
          or statutory, except as expressly set forth herein. The system and all services are provided "as is," without
          warranty of any kind, either express or implied, including without limitation, any warranties concerning the
          availability, accuracy, usefulness, or content of information, products or services or any warranties of
          merchantability or fitness for a particular purpose. Customer acknowledges that data transmission and storage
          is subject to the likelihood of human and machine errors, omissions, delays, and losses, including inadvertent
          loss of data or damage to media, that may give rise to loss or damage. In addition, Customer agrees that
          Trnsact does not represent that the System or any Services will be uninterrupted, without omissions, or error
          free.
        </li>

        <li className={classes.termsItem}>
          <b>Limitation of Liability.</b> Neither Trnsact nor any of its employees, agents, successors, assigns,
          affiliates, or consultants or service providers, shall be liable to the Customer or any third party for any
          indirect, incidental, special or consequential damages arising out of use of the System, the performance of
          the Services, or inability to gain access to or use the System, or out of any breach of any warranty. The
          limitation of liability shall include, without limitation, damages for loss of business profits, business
          interruption, loss of business information, or other pecuniary loss arising out of this services agreement or
          the performance or failure to perform support or Services, even if Trnsact or Customer has been advised of the
          possibility of such damages. The foregoing allocation of risk and limitation of liability has been negotiated
          and agreed by the parties and forms the basis of their willingness to enter this transaction.
        </li>

        <li className={classes.termsItem}>
          <b>Independent Contractor.</b> Trnsact and Customer are independent contractors. Neither Trnsact nor Customer
          are, or shall be deemed for any purpose to be, employees or agents of the other and neither party shall have
          the power or authority to bind the other party to any contract or obligation.
        </li>

        <li className={classes.termsItem}>
          <b>Governing Law.</b> This Agreement shall be governed by and construed in accordance with the laws of the
          State of California. Except as provided in Section 24, Customer and Trnsact consent to the jurisdiction of the
          state courts of the State of California located in Orange County and the U.S. District Court for the Central
          District of California. Each party acknowledges that it has read this Agreement and agrees that this Agreement
          is the complete and exclusive statement of the parties and supersedes and merges all prior proposals
          understandings and agreements, oral or written, between the parties relating to the subject matter hereof,
          including without limitation, the terms of any customer request for proposal or the standard printed terms on
          any Customer purchase order. No modification, amendment, supplement to or waiver of this Agreement shall be
          binding upon the parties hereto unless made in writing and duly signed by both parties.
        </li>

        <li className={classes.termsItem}>
          <b>Severability.</b> In the event any one or more of the provisions of this Agreement is held to be invalid or
          otherwise unenforceable, the enforceability of the remaining provisions shall be unimpaired.
        </li>

        <li className={classes.termsItem}>
          <b>Assignment.</b> This Agreement shall be binding upon and inure to the benefit of the parties hereto and
          their respective heirs, personal representatives, successors and permitted assigns; No party shall assign this
          Agreement or any of its rights and obligations hereunder without the prior written consent of the other
          parties; provided, however, that either party may assign this Agreement and all of its rights and obligations
          hereunder to a purchaser of all or substantially all of the assets of such party who expressly assumes all of
          such party's obligations under this Agreement in connection with such assignment.
        </li>

        <li className={classes.termsItem}>
          <b>Survival.</b> The obligations of confidentiality imposed herein shall survive termination of this Agreement
          for a period of four (4) years. Any other provisions of this Agreement that by their nature extend beyond the
          expiration or termination of this Agreement shall survive the expiration or termination of this Agreement and
          shall remain in effect until all such obligations are satisfied.
        </li>

        <li className={classes.termsItem}>
          <b>Arbitration.</b> Except for collection actions for payment of fees and for the right of either party to
          apply to a court of competent jurisdiction for a temporary restraining order, a preliminary injunction, or
          other equitable relief to preserve the status quo or prevent irreparable harm, any controversy or claim
          arising out of or relating to this agreement or to its breach shall be settled by arbitration by a panel of
          three arbitrators in accordance with American Arbitration Rules, pursuant to an arbitration held in Orange
          County, California, and judgment upon the award rendered by the arbitrator may be entered in any court of
          competent jurisdiction. The prevailing party shall be entitled to receive from the other party its attorney’s
          fees and costs incurred in connection with any action, proceeding or arbitration hereunder.
        </li>

        <li className={classes.termsItem}>
          <b>Force Majeure.</b> Trnsact shall not be responsible for failure to perform in a timely manner under this
          services agreement when its failure results from any of the following causes: Acts of God or public enemies,
          civil war, insurrection or riot, fire, flood, explosion, earthquake or serious accident, strike, labor trouble
          or work interruption or any cause beyond its reasonable control.
        </li>

        <li className={classes.termsItem}>
          <b>User Content.</b> You may have the opportunity to submit information to Trnsact by posting to a public area
          of Trnsact Websites or by other submission to us through email or electronic communication facilitated by
          Trnsact. These may include feedbacks, reviews, inventions, techniques, know-how, data, materials or other
          postings or communications (collectively, “User Content”). By submitting any such User Content, you grant
          Trnsact and Trnsact affiliates the royaltyfree, perpetual, irrevocable, nonexclusive right and license to use,
          reproduce, modify, adapt, aggregate, translate, prepare derivative works from, publicly display, publicly
          perform and distribute such User Content (in whole or in part) alone or as incorporated into other works in
          any form, media or technology now known or hereafter developed, on a worldwide basis. You also grant Trnsact
          and its affiliates the right to use the name that you use in connection with such submission or post. You
          represent and warrant that you own or otherwise control all of the rights to the User Content that you submit
          to us, the User Content is accurate and that the use of the User Content you supply does not violate Trnsact’s
          Terms of Use and will not cause injury or damage to any person or entity. You permit other users of Trnsact
          Websites to access, view, store or reproduce your User Content.
        </li>

        <li className={classes.termsItem}>
          <b>Definitions.</b> Following definitions apply to this agreement.
          <ul>
            <li>
              <b>SaaS:</b> “SaaS Products” means the software-as-a-service products specified in the Licensing Agreement
              as further described in the Documentation (including any updates and upgrades to the SaaS Products
              provided by Trnsact in its sole discretion, and any software, systems and locally-installed software
              agents and connectors that interact with the SaaS Products as may be provided by Trnsact in connection
              with the SaaS Products), provided that any free trial SaaS software, proof of concept of the SaaS
              Products, beta version of the SaaS Products, or any other free-of-charge software product (“Trial
              Version”) will not be subject to this Agreement and instead will be governed by the applicable terms of
              service embedded in, or provided with, such Trial Version
            </li>
            <li>
              <b>Subscription Term:</b> “Subscription Term” means the period during which Customer is subscribed to
              Trnsact’s SaaS Products.
            </li>
            <li>
              <b>Third Party Material:</b> “Third-Party Materials” means open-source software programs that are made
              available by third parties under their respective OSS Licenses.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
