import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "./actionTypes";
import { NotificationActionTypes, Notification } from "./types";

const initialState: Notification[] = [];

export const notificationsReducer = (state = initialState, action: NotificationActionTypes): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.payload];
    case REMOVE_NOTIFICATION:
      return state.filter(notification => notification.id !== action.payload);
    default:
      return state;
  }
};
