import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import Table from "../../components/Table/Table";
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import InvoiceDetailModal from "./InvoiceDetailModal";

export default function FileUploadModal({
  invoiceDetailTableBody,
  account,
  salesManager,
  salesRepresentative,
  vo,
  refetchVO,
}) {
  const useStyles = makeStyles(() => ({
    clearButton: {
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    tileCardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "15px",
    },
    cardIconTitle: {
      ...cardTitle,
    },
    flexJustifyEnd: {
      display: "flex",
      justifyContent: "flex-end",
    },
  }));
  const classes = useStyles();
  const [openInvoiceDetailModal, setOpenInvoiceDetailModal] = useState(false);
  const handleOpenInvoiceDetailModal = () => setOpenInvoiceDetailModal(true);
  const handleCloseInvoiceDetailModal = () => setOpenInvoiceDetailModal(false);

  return (
    <Card>
      <CardHeader>
        <div className={classes.tileCardHeader}>
          <h3 className={classes.cardIconTitle}>Invoice</h3>
          <Button color="primary" size="sm" onClick={handleOpenInvoiceDetailModal}>
            Edit
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <Table tableHeaderColor="primary" tableData={invoiceDetailTableBody} colorsColls={["primary"]} />
      </CardBody>
      <InvoiceDetailModal
        open={openInvoiceDetailModal}
        account={account}
        handleClose={handleCloseInvoiceDetailModal}
        salesManager={salesManager}
        salesRepresentative={salesRepresentative}
        vo={vo}
        refetchVO={refetchVO}
      />
    </Card>
  );
}
