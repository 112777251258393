import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Grid, TextField } from "@material-ui/core";
import gql from "graphql-tag";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import FinanceProgramCard from "../../components/FinanceProgramCard";

import { checkProposalRequiredFields, parseProposal } from "../../services/proposalsService";
import { makeStyles } from "@material-ui/core/styles";
import { validationTypes } from "../../utils";
import _ from "lodash";
const CREATE_QUOTE = gql`
  mutation createQuote($input: CreateQuoteInput!) {
    createQuote(input: $input) {
      id
      success
      message
    }
  }
`;

const useStyles = makeStyles(() => ({
  selectFinanceProgramFormControl: {
    width: "250px",
    margin: "0 auto 0 40px",
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiInputLabel-shrink": {
      color: "rgba(0, 0, 0, 0.54) !important",
    },
  },
}));

const PQT = ({ userProfile, account, vp }) => {
  const classes = useStyles();

  const [createQuote, { loading: createQuoteLoading }] = useMutation(CREATE_QUOTE, {
    context: { authRequired: true },
    onCompleted: data => {
      if (data.createQuote.success) {
        window.location.href = `/proposalDetail/${data.createQuote.id}`;
      }
    },
  });
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState([]);
  const [quoteInfo, setQuoteInfo] = useState({
    businessName: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    equipmentDescription: "",
    fullName: "",
  });

  const [formValid, setFormValid] = useState(null);
  const [error, setError] = useState({
    email: false,
    phone: false,
    firstName: false,
    lastName: false,
    equipmentDescription: false,
  });

  useEffect(() => {
    const collectedErrors = {
      ...error,
      email: quoteInfo.email ? !validationTypes["email"](quoteInfo.email) : false,
      phone: quoteInfo.phone ? !validationTypes["requiredPhone"](quoteInfo.phone) : false,
      firstName: quoteInfo.firstName ? !validationTypes["minLen"](quoteInfo.firstName, 2) : false,
      lastName: quoteInfo.lastName ? !validationTypes["minLen"](quoteInfo.lastName, 2) : false,
      equipmentDescription: quoteInfo.equipmentDescription
        ? !validationTypes["minLen"](quoteInfo.equipmentDescription, 3)
        : false,
    };
    const { businessName, fullName, ...requiredFields } = quoteInfo;

    setFormValid(
      Object.values(collectedErrors).every(v => v === false) && Object.values(requiredFields).every(v => !_.isEmpty(v))
    );
    setError(collectedErrors);
  }, [quoteInfo]);

  const handleQuoteInputChange = (field, value) => {
    setQuoteInfo({ ...quoteInfo, [field]: value.target.value });
  };

  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  const formatPhone = val => {
    const digits = (val || "").replace(/[^\d]/g, "").substr(0, 10);
    if (!digits.length) {
      return "";
    }
    let ret = "(" + digits.substr(0, 3);
    if (digits.length >= 4) {
      ret += ") " + digits.substr(3, 3);
    }
    if (digits.length >= 7) {
      ret += "-" + digits.substr(6, 4);
    }
    return ret;
  };

  const handleCreateQuote = async program => {
    const parsedProposal = parseProposal(program);
    let errorMessage = [];

    if (!formValid) {
      errorMessage.push("Quote info form section is either incomplete or has errors");
    }

    if (!checkProposalRequiredFields(parsedProposal)) {
      errorMessage.push("Finance Program form is either incomplete or has errors");
    }

    if (!_.isEmpty(errorMessage)) {
      setErrorSnackbarMessage(errorMessage);
      setOpenErrorSnackbar(true);
      return false;
    } else {
      await createQuote({
        variables: {
          input: {
            paymentOptions: parsedProposal,
            sendQuote: true,
            info: { ...quoteInfo, fullName: `${quoteInfo.firstName} ${quoteInfo.lastName}` },
            financeProgramId: program.financeProgramId,
          },
        },
      });
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <FinanceProgramCard
        style={{ overflowX: "hidden" }}
        account={account}
        vp={vp}
        isVOView={true}
        vo={null}
        cardTitle={`Select Finance Program`}
        quoteToolModeEnabled={true}
        quoteInfoComponent={
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <hr></hr>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <h3 style={{ textDecoration: "underline", textShadow: "3px 3px 6px #a5a5a5" }}>Quote Info</h3>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                size="small"
                label="Equipment Description"
                helperText={error.equipmentDescription ? "Min Length is 4 characters long" : false}
                error={error.equipmentDescription}
                variant="outlined"
                value={quoteInfo.equipmentDescription ? quoteInfo.equipmentDescription : ""}
                onChange={e => handleQuoteInputChange("equipmentDescription", e)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: false,
                }}
                size="small"
                label="Business Name (optional)"
                variant="outlined"
                value={quoteInfo.businessName ? quoteInfo.businessName : ""}
                onChange={e => handleQuoteInputChange("businessName", e)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                size="small"
                label="First Name"
                variant="outlined"
                error={error.firstName}
                helperText={error.firstName ? "Min Length is 4 characters long" : false}
                value={quoteInfo.firstName ? quoteInfo.firstName : ""}
                onChange={e => handleQuoteInputChange("firstName", e)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                size="small"
                label="Last Name"
                error={error.lastName}
                helperText={error.lastName ? "Min Length is 4 characters long" : false}
                variant="outlined"
                value={quoteInfo.lastName ? quoteInfo.lastName : ""}
                onChange={e => handleQuoteInputChange("lastName", e)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                size="small"
                label="Phone"
                helperText={error.phone ? "Please enter a valid phone number" : false}
                error={error.phone}
                variant="outlined"
                value={quoteInfo.phone ? quoteInfo.phone : ""}
                onChange={e => {
                  e.target.value = formatPhone(e.target.value);
                  return handleQuoteInputChange("phone", e);
                }}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  style: { width: "100%" },
                  required: true,
                }}
                size="small"
                label="Email"
                error={error.email}
                helperText={error.email ? "Please enter a valid email" : false}
                variant="outlined"
                value={quoteInfo.email ? quoteInfo.email : ""}
                onChange={e => handleQuoteInputChange("email", e)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        }
        submitCallback={handleCreateQuote}
        externalLoadingState={createQuoteLoading}
      />
      <Snackbar
        className={classes.loginSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          {errorSnackbarMessage.map((error, idx) => (
            <p key={`errorDetail_${idx}`}>{error}</p>
          ))}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = state => ({
  userProfile: state.userProfile,
  account: state.account,
  vp: state.vp,
});

export default connect(
  mapStateToProps,
  null
)(PQT);
