import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MuiButton from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { AddOutlined, CloseOutlined } from "@material-ui/icons";
import {
  checkProposalRequiredFields,
  getDefaultFinanceProgram,
  parseProposal,
} from "../../../../services/proposalsService";
import FinanceProgramCard from "../../../../components/FinanceProgramCard";
import _ from "lodash";

const styles = theme => ({
  financeProgramCardContainer: {
    position: "relative",
    marginBottom: "20px",
  },
  financeProgramCardCloseButton: {
    position: "absolute",
    top: "25px",
    right: "30px",
    zIndex: 5,
  },
  addFinanceProgramButton: {
    color: theme.palette.primary.main,
    margin: "0 auto 15px",
  },
});

const useStyles = makeStyles(styles);

const CREATE_PROPOSAL_V2 = gql`
  mutation CreateProposalV2($input: CreateProposalV2Input!) {
    createProposalV2(input: $input) {
      errorMessage
      id
      success
      vendorOpportunityId
    }
  }
`;

export const CreditSubmissionProposalV2 = ({
  vendorOpportunityId,
  triggerCreateProposalV2,
  proposalV2Options,
  requiredFieldsFilled,
  cs,
}) => {
  const classes = useStyles();
  const account = useSelector(state => state.account);

  const proposalSetsToSet = _.some(proposalV2Options)
    ? proposalV2Options
    : [
        {
          ...getDefaultFinanceProgram(false, true),
          amountFinanced: cs.amountRequested,
          tempId: _.uniqueId("temp-id-"),
        },
      ];
  const [proposalSets, setProposalSets] = useState(proposalSetsToSet);
  const [isNeedToSendEmailOnProposalCreation, setIsNeedToSendEmailOnProposalCreation] = useState(false);

  const [createProposalV2, { loading: createProposalLoading, data: propsalCreationResponse }] = useMutation(
    CREATE_PROPOSAL_V2,
    {
      context: { authRequired: true },
    }
  );

  useEffect(() => {
    if (!triggerCreateProposalV2 || _.isEmpty(proposalSets)) {
      return;
    }
    const parsedProposals = _.map(proposalSets, parseProposal);
    createProposalV2({
      variables: {
        input: {
          paymentOptions: parsedProposals,
          creditSubmissionId: cs.creditSubmissionId,
          vendorOpportunityId,
          sendProposal: isNeedToSendEmailOnProposalCreation,
        },
      },
    });
  }, [triggerCreateProposalV2]);

  useEffect(() => {
    checkRequiredFields();
  }, [proposalSets]);

  const checkRequiredFields = () => {
    const result = _.every(proposalSets, checkProposalRequiredFields);
    requiredFieldsFilled(result);
  };

  const handleAddSet = () => {
    setProposalSets([
      ...proposalSets,
      { ...getDefaultFinanceProgram(false, true), amountFinanced: cs.amountRequested, tempId: _.uniqueId("temp-id-") },
    ]);
  };

  const handleRemoveSet = program => {
    setProposalSets(prevValue => prevValue.filter(prev => prev.tempId !== program.tempId));
  };

  const onProgramChange = (newProgram, idx, tempId) => {
    if (_.isEmpty(newProgram)) {
      return;
    }
    if (!newProgram.tempId) {
      const programToSet = {
        ...getDefaultFinanceProgram(false, true),
        ...newProgram,
        downPaymentAmount: isNaN(newProgram?.downPaymentAmount) ? 0 : newProgram?.downPaymentAmount,
        tempId,
      };
      setProposalSets(prevValue => prevValue.map((prev, prevIdx) => (prevIdx === idx ? programToSet : prev)));
      return;
    }
    setProposalSets(prevValue => prevValue.map(prev => (prev.tempId === newProgram.tempId ? newProgram : prev)));
  };

  return (
    <>
      {!createProposalLoading && (
        <Grid container>
          <Grid item container>
            {_.some(proposalSets) && (
              <FormControlLabel
                style={{ marginBottom: "15px" }}
                control={
                  <Switch
                    checked={isNeedToSendEmailOnProposalCreation}
                    onChange={() => setIsNeedToSendEmailOnProposalCreation(!isNeedToSendEmailOnProposalCreation)}
                    color="primary"
                  />
                }
                label={isNeedToSendEmailOnProposalCreation ? "Send Proposal" : "Staged Proposal"}
              />
            )}
          </Grid>
          {proposalSets.map((loanSet, idx) => (
            <Grid item key={loanSet.tempId} className={classes.financeProgramCardContainer}>
              <IconButton onClick={() => handleRemoveSet(loanSet)} className={classes.financeProgramCardCloseButton}>
                <CloseOutlined />
              </IconButton>
              <FinanceProgramCard
                account={account}
                initProgram={loanSet}
                cardTitle={`Finance Program #${idx + 1}`}
                isCreditSubmissionView={true}
                cs={cs}
                onProgramChange={program => onProgramChange(program, idx, loanSet.tempId)}
              />
            </Grid>
          ))}
          <Grid item container>
            <MuiButton onClick={handleAddSet} className={classes.addFinanceProgramButton}>
              <AddOutlined />
              Add Finance Program
            </MuiButton>
          </Grid>
        </Grid>
      )}

      {createProposalLoading && (
        <>
          <CircularProgress size={24} />
        </>
      )}
    </>
  );
};
