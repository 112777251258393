import {
  CONVERSATION_CREATED,
  SET_CHAT_CONVERSATIONS,
  SET_CHAT_OPENED,
  SET_CHAT_RESET,
  SET_CHAT_TARGET_CS,
  SET_CHAT_TARGET_RECIPIENT,
  SET_CHAT_TARGET_TOPIC,
  SET_CHAT_TARGET_VOID,
  SET_CHAT_UNREAD_MESSAGES,
  START_CREATING_CONVERSATION,
} from "./types";

const initialState = {
  unread: [],
  targetRecipient: [],
  targetCS: null,
  opened: false,
  targetTopic: "",
  conversations: null,
  creating: false,
  createdConversationSid: null,
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHAT_UNREAD_MESSAGES:
      return { ...state, ...action.payload };
    case SET_CHAT_TARGET_RECIPIENT:
      return { ...state, ...action.payload };
    case SET_CHAT_TARGET_CS:
      return { ...state, ...action.payload };
    case SET_CHAT_OPENED:
      return { ...state, ...action.payload };
    case SET_CHAT_TARGET_TOPIC:
      return { ...state, ...action.payload };
    case SET_CHAT_TARGET_VOID:
      return { ...state, ...action.payload };
    case SET_CHAT_RESET:
      return {
        ...state,
        ...{
          targetRecipient: [],
          targetCS: null,
          opened: false,
          targetTopic: "",
        },
      };
    case SET_CHAT_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
      };
    case START_CREATING_CONVERSATION:
      return {
        ...state,
        creating: action.payload,
      };
    case CONVERSATION_CREATED:
      return {
        ...state,
        createdConversationSid: action.payload,
      };
    default:
      return state;
  }
};
