import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

interface Props {
  open: boolean;
  handleSubmit: any;
  close: VoidFunction;
  additionalMessage: any;
  creditAppUpdateIsSubmitting: boolean;
}

const CreditAppModifierConfirmModal = ({
  open,
  close,
  handleSubmit,
  additionalMessage,
  creditAppUpdateIsSubmitting,
}: Props) => {
  const classes = useStyles();

  const handleConfirm = async () => {
    await handleSubmit();
    close();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      classes={{
        paper: classes.container,
      }}>
      <DialogTitle>
        <Typography variant="h6">Confirm change</Typography>
      </DialogTitle>

      <DialogContent>
        <Typography component="p" variant="body1">
          Did you want to make these changes?
        </Typography>

        <Typography component="p" color="textSecondary" variant="caption">
          Note: Changes to credit application are permanent and recorded into server log!
        </Typography>

        {additionalMessage && (
          <Typography component="p" variant="body1">
            {additionalMessage}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Box className={classes.actions}>
          <Button color="primary" variant="outlined" disabled={creditAppUpdateIsSubmitting} onClick={close}>
            Cancel
          </Button>

          <Button color="primary" variant="contained" disabled={creditAppUpdateIsSubmitting} onClick={handleConfirm}>
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreditAppModifierConfirmModal;

const useStyles = makeStyles({
  container: {
    padding: "16px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  actions: {
    gap: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});
