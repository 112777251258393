import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import { Auth } from "aws-amplify";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { oAuthLoginEnabled } from "../../config";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { IconButton } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { MobileContext } from "../../contexts/MobileContext";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LoginPage(props) {
  const mobileContext = useContext(MobileContext);
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  setTimeout(() => setCardAnimation(""), 700);
  const classes = useStyles();

  const [values, setValues] = useState({ userName: "", userPassword: "", verificationCode: "" });
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [displayVerificationCodeInput, setDisplayVerificationCodeInput] = React.useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState(null);
  const [canHandleSubmit, setCanHandleSubmit] = React.useState(true);

  const [showPassword, setShowPassword] = React.useState(false);
  const [formValidationStatus, setFormValidationStatus] = useState({
    userName: true,
    password: true,
    verificationCode: true,
  });

  function validateEmail(email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(email).toLowerCase());
  }

  const handleSoftwareTokenMfa = session => {
    setCognitoUser(session);
  };

  const handleSmsTokenMfa = session => {
    setValues({ ...values, verificationCode: "" });
    setCognitoUser(session);
    setDisplayVerificationCodeInput(true);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const userName = values.userName.trim().length > 0;
    const password = values.userPassword.trim().length > 0;

    if (!userName || !password || !validateEmail(values.userName)) {
      setFormValidationStatus({ userName: userName && validateEmail(values.userName), password });
      return;
    }

    if (canHandleSubmit) {
      setCanHandleSubmit(false);
      setSubmitting(true);
      try {
        const user = await Auth.signIn(values["userName"], values["userPassword"]);
        switch (user.challengeName) {
          case "SOFTWARE_TOKEN_MFA":
            handleSoftwareTokenMfa(user);

            break;
          case "SMS_MFA":
            handleSmsTokenMfa(user);

            break;
          case "NEW_PASSWORD_REQUIRED":
            let errLogin = new Error("New password required");
            if (props.onLoginError) {
              props.onLoginError(errLogin);
            }
            throw errLogin;
          default:
            if (props.onLoginSuccess) {
              props.onLoginSuccess(user);
            } else {
              console.log("no onLoginSuccess()");
            }
        }
      } catch (error) {
        setForgotPassword(true);
        switch (error.code) {
          case "UserNotFoundException":
            setErrorSnackbarMessage("Oops, the email provided is not registered");
            setOpenErrorSnackbar(true);
            setCanHandleSubmit(true);
            break;
          case "NotAuthorizedException":
            setErrorSnackbarMessage("Oops, incorrect user or password");
            setOpenErrorSnackbar(true);
            setCanHandleSubmit(true);
            break;
          case "UserLambdaValidationException":
            setErrorSnackbarMessage("Oops, your account is blocked or not yet approved");
            setOpenErrorSnackbar(true);
            setCanHandleSubmit(true);
            break;
          default:
            //undetermined error
            setErrorSnackbarMessage(
              `There was an error when trying to login, please contact support@trnsact.com with the subject 'Undetermined login error: ${error.code}'`
            );
            setOpenErrorSnackbar(true);
            break;
        }
        if (props.onLoginError) {
          props.onLoginError(error);
        }
      }

      setSubmitting(false);
    }
  };
  const handleInputChange = e => {
    const { name, value } = e.target;
    if (e.target.name === "userName") {
      setFormValidationStatus({ ...formValidationStatus, userName: true });
    }
    if (e.target.name === "userPassword") {
      setFormValidationStatus({ ...formValidationStatus, password: true });
    }
    if (e.target.name === "verificationCode") {
      setFormValidationStatus({ ...formValidationStatus, verificationCode: true });
    }
    setValues({ ...values, [name]: name === "userName" ? value.toLowerCase() : value });
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };
  const handleVerifySMSCode = async event => {
    event.preventDefault();

    try {
      setCanHandleSubmit(false);
      setSubmitting(true);
      const user = await Auth.confirmSignIn(cognitoUser, values.verificationCode, "SMS_MFA");

      if (props.onLoginSuccess) {
        props.onLoginSuccess(user);
      }
    } catch (error) {
      setSubmitting(false);
      switch (error.code) {
        case "CodeMismatchException":
          setErrorSnackbarMessage("Code Mismatch: The code provided is invalid");
          setOpenErrorSnackbar(true);
          setValues({ ...values, verificationCode: "" });
          setDisplayVerificationCodeInput(false);

          break;
        case "NotAuthorizedException":
          setErrorSnackbarMessage("Not Authorized: The code provided is invalid");
          setOpenErrorSnackbar(true);
          setValues({ verificationCode: "" });
          setDisplayVerificationCodeInput(false);
          break;
      }
      if (props.onLoginError) {
        props.onLoginError(error);
      }
      setCanHandleSubmit(true);
    }
  };

  const handleResendConfirmationCode = async event => {
    event.preventDefault();
    if (values["userName"] && values["userPassword"]) {
      await Auth.signIn(values["userName"], values["userPassword"]);
    }
  };

  let { isMobile } = mobileContext;

  return (
    <div className={classes.container} style={isMobile ? { height: "100vh" } : {}}>
      <GridContainer justify="center" style={isMobile ? {} : { margin: "0 -85px" }}>
        <GridItem xs={12} sm={6} md={4}>
          <form
            className={classes.cardContainer}
            style={isMobile ? { width: "fit-content" } : {}}
            onSubmit={handleSubmit}
          >
            <Card login className={classes[cardAnimation]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`}>
                <h4 className={`${classes.cardTitle} ${classes.loginPageHeader}`}>Sign In</h4>
              </CardHeader>
              <CardBody>
                {displayVerificationCodeInput ? (
                  <>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { width: "100%" },
                      }}
                      label="Please enter the code sent to your phone number"
                      variant="filled"
                      name="verificationCode"
                      id="verificationCode"
                      value={values.verificationCode}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      inputRef={input => input && input.focus()}
                    />

                    {/* <CustomInput
                      className={classes.loginInput}
                      labelText="Please enter your verification SMS code"
                      id="verificationCode"
                      formControlProps={{ fullWidth: true }}
                      success={formValidationStatus.verificationCode}
                      inputProps={{
                        name: "verificationCode",
                        className: classes.loginInput,
                        onChange: handleInputChange,
                        autoComplete: "off",
                      }}
                      labelProps={{
                        shrink: true,
                        className: classes.loginLabel,
                      }}
                    /> */}
                  </>
                ) : (
                  <>
                    <CustomInput
                      openUsernameError={!formValidationStatus.userName}
                      className={classes.loginInput}
                      labelText="Email Address"
                      id="userName"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "userName",
                        className: classes.loginInput,
                        onChange: handleInputChange,
                        autoComplete: "email",
                        autoFocus: true,
                      }}
                      labelProps={{
                        shrink: true,
                        className: classes.loginLabel,
                      }}
                    />
                    <CustomInput
                      openPasswordError={!formValidationStatus.password}
                      showForgotPassword={forgotPassword}
                      labelText="Password"
                      id="password"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "userPassword",
                        className: `${classes.loginInput} ${classes.loginPassword}`,
                        onChange: handleInputChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className={classes.inputAdornmentIcon}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        type: showPassword ? "text" : "password",
                        autoComplete: "current-password",
                      }}
                      labelProps={{
                        shrink: true,
                        className: classes.loginLabel,
                      }}
                    />
                  </>
                )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter} style={{ marginTop: "18px" }}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12} lg={12} style={{ minHeight: "25px" }}>
                    {submitting ? <LinearProgress /> : null}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center" }}>
                    {displayVerificationCodeInput ? (
                      <>
                        <Button
                          className={classes.loginButton}
                          simple={"simple"}
                          block
                          type="button"
                          onClick={handleVerifySMSCode}
                        >
                          Verify Code
                        </Button>
                        <Button
                          className={classes.loginButton}
                          simple={"simple"}
                          block
                          type="button"
                          onClick={handleResendConfirmationCode}
                        >
                          Re-send Code
                        </Button>
                        {/* <Tooltip title={"Resend the code"} placement="top-start" arrow>
                          <InputAdornment position="end" style={{ cursor: "pointer" }}>
                            <CachedIcon
                              className={classes.inputAdornmentIcon}
                              onClick={handleResendConfirmationCode}
                            ></CachedIcon>
                          </InputAdornment>
                        </Tooltip> */}
                      </>
                    ) : (
                      <Button
                        id="signInSubmitBtn"
                        className={classes.loginButton}
                        disabled={!canHandleSubmit}
                        simple={oAuthLoginEnabled ? null : "simple"}
                        block
                        type="submit"
                      >
                        Let's Go
                      </Button>
                    )}
                  </GridItem>
                  {/* {oAuthLoginEnabled ? (
                    <>
                      <h2 className={classes.loginOptionsSeparatorText}>
                        <span className={classes.loginOptionsSeparator}>or</span>
                      </h2>
                      <GridItem xs={12} sm={12} md={12} lg={12} className={classes.oAuthButtons}>
                        <OAuthButton brand="google" title="Sign With Google" />
                        <OAuthButton brand="microsoft" title="Sign With Microsoft" />
                      </GridItem>
                    </>
                  ) : (
                    ""
                  )} */}
                </GridContainer>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <Snackbar
        style={{ marginTop: "50px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          {errorSnackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
