import { Task } from "@trnsact/trnsact-shared-types";
import { TasksTableFilters } from "../types";

export function filterTableDataByFiltersValues(data: Task[] = [], filters: TasksTableFilters) {
  const isFiltersEmpty = !Object.values(filters).filter(Boolean).length;

  if (isFiltersEmpty) return data;

  const filterAction: Record<keyof TasksTableFilters, (task: Task) => boolean> = {
    status: ({ status }) => filters.status.includes("all") || filters.status.includes(status!),
    priority: ({ priority }) => filters.priority.includes("all") || filters.priority.includes(priority!),
    search: ({ createdBy, assignedToUser, regarding }) =>
      createdBy!.toLowerCase().includes(filters.search.toLowerCase()) ||
      assignedToUser!.toLowerCase().includes(filters.search.toLowerCase()) ||
      regarding!.toLowerCase().includes(filters.search.toLowerCase()),
  };

  return data.filter(row => Object.values(filterAction).every(fn => fn(row)));
}
