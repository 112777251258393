import { useDispatch, useSelector } from "react-redux";
import { actions, modalsSelectors } from "../redux/modalsReducer";
import { useCallback } from "react";

export function useModal(modalKey: string) {
  const dispatch = useDispatch();

  const isOpen = useSelector((state: any) => modalsSelectors.selectIsOpen(state, modalKey));
  const data = useSelector((state: any) => modalsSelectors.selectData(state, modalKey));

  const handleOpen = useCallback(
    (data: any = null) => {
      dispatch(actions.onOpenModal(modalKey, data));
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch(actions.onCloseModal(modalKey));
  }, [dispatch]);

  return {
    data,
    isOpen,
    handleOpen,
    handleClose,
  };
}
