import React, { ReactNode, useContext, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useQuery } from "@apollo/react-hooks";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import _ from "lodash";
import LaunchIcon from "@material-ui/icons/Launch";
import { CommonDataContext } from "contexts/CommonDataContext";
import { ProgramsPreScreen } from "pages/LenderWaterfall/components";
import { LenderWaterfallPrescreenResult } from "@trnsact/trnsact-shared-types";
import { useViewTypeContext } from "../../../contexts/contentViewType";
import { ContentViewType } from "../../../global";
import { EntityMobileCards } from "../../../components/shared/EntityMobileCards";
import { LenderWaterfallEntityMobile } from "./types";
import { GET_FINANCE_PROGRAMS, GET_LENDER_WATERFALL_RESULTS } from "./queries";
import { CardContainer } from "../../../components/shared/CardContainer/CardContainer";
import { mobileEntityAdapter } from "./lib/mobileEntityAdapter";
import { prepareDataForMobile } from "./lib/prepareDataForMobile";
import { LenderInfo } from "./ui/LenderInfo";
import { FormattedText } from "./ui/FormattedText";
import { useSelector } from "react-redux";
import { portalConfigurationsSelectors } from "../../../redux/portalConfigurationReducer";

const LenderWaterfallResults = ({
  accountId,
  vendorOpportunityId,
  creditSubmissions,
  handleSubmitToLenderByProfileId,
  lenderProfiles,
}: {
  accountId: string;
  vendorOpportunityId: string;
  creditSubmissions: any;
  handleSubmitToLenderByProfileId: any;
  lenderProfiles: any;
}) => {
  const classes = useStyles();
  const { isLenderUser } = useContext(CommonDataContext);

  const userProfile = useSelector((state: any) => state.userProfile);
  const prescreenName = useSelector(portalConfigurationsSelectors.prescreenModuleName);
  const isUserHaveAccessToSubmit = useSelector((state: any) =>
    portalConfigurationsSelectors.checkIfUserHaveAccessToSubmit(state, userProfile)
  );

  const [openModal, setOpenModal] = useState(false);
  const [createProgram, setCreateProgram] = useState(false);
  const [financeProgram, setFinanceProgram] = useState(null);
  const [targetLenderProfileId, setTargetLenderProfileId] = useState(null);
  const [dealerVendorProfiles, setDealerVendorProfiles] = useState(null);
  const [financePrograms, setFinancePrograms] = useState<any>([]);
  const [waterfallResults, setWaterfallResults] = useState<any>([]);
  const [openRowKey, setOpenRowKey] = useState<string | null>(null);
  const [openRowMap, setOpenRowMap] = useState<Array<any>>([]);

  const { loading: loadingFinancePrograms, refetch: fetchFinancePrograms, error: errorFinancePrograms } = useQuery(
    GET_FINANCE_PROGRAMS,
    {
      context: { authRequired: true },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: { accountId: accountId },
      onCompleted: data => {
        if (data.financePrograms) {
          setFinancePrograms(data.financePrograms);
        }
      },
    }
  );

  const { loading: loadingLenderWaterfallResults, error: errorLenderWaterfallResults } = useQuery(
    GET_LENDER_WATERFALL_RESULTS,
    {
      context: { authRequired: true },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: { vendorOpportunityId },
      onCompleted: data => {
        if (data.lenderWaterfallPrescreenResults) {
          //filtering only the lender profiles that are available for submission
          let aggregatedResults = [];
          if (!_.isEmpty(data.lenderWaterfallPrescreenResults)) {
            aggregatedResults = data.lenderWaterfallPrescreenResults.filter(
              (result: any) => !result.lenderProfileId || _.find(lenderProfiles, { id: result.lenderProfileId })
            );
          }

          // Remove duplicates based on lenderProfileId and financeProgramId for each step

          aggregatedResults = aggregatedResults.reduce((acc: { [key: number]: any[] }, item: any) => {
            if (!acc[item.step]) {
              acc[item.step] = [];
            }
            acc[item.step].push(item);
            acc[item.step] = _.uniqBy(
              acc[item.step],
              (result: any) => `${result.lenderProfileId}-${result.financeProgramId}`
            );
            return acc;
          }, {});

          setWaterfallResults(aggregatedResults);
          let rowMap: any = [];
          _.forEach(aggregatedResults, (rows, step) => {
            _.forEach(rows, (row, rowIndex) => {
              const calcIndex = parseInt(step) - 1;
              const hasDetails = !_.isEmpty(_.get(row, "runtimeOutput.conditions.all", []));
              _.set(rowMap, `[${calcIndex}][${rowIndex}].opened`, false);
              _.set(rowMap, `[${calcIndex}][${rowIndex}].hasDetails`, hasDetails);
            });
          });
          setOpenRowMap(rowMap);
        }
      },
    }
  );

  const handleRowClick = (stepIndex: number, rowIndex: number, rowKey: string) => {
    setOpenRowMap(prev => {
      const key = `[${stepIndex}][${rowIndex}].opened`;
      let rowMap: any[] = prev;
      _.set(rowMap, key, !_.get(prev, key));
      return rowMap;
    });
    setOpenRowKey(openRowKey === rowKey ? null : rowKey);
  };

  if (loadingFinancePrograms || loadingLenderWaterfallResults) {
    return <div>Loading...</div>;
  }

  if (errorFinancePrograms || errorLenderWaterfallResults) {
    return <div>Error loading data</div>;
  }

  const getRateSpecs = (row: any, key: "term" | "rate"): string => {
    const objecPath = "runtimeOutput.event.params.financeProgram.paymentOptionsConfiguration.terms";
    const value: string = _.get(_.last(_.get(row, objecPath, [])), key) || "";
    if (!value) {
      if (Array.isArray(_.get(row, objecPath))) {
        switch (key) {
          case "rate":
            return "N/A";
          case "term":
            return `${_.last(_.get(row, objecPath))}`;
        }
      }
    }

    return key == "rate" ? `${value}%` : value;
  };

  const countOpenedItems = (array: any) => {
    return _.map(array, subArray => _.filter(subArray, { opened: true }).length);
  };

  const friendlyCondition = (condition: any) => {
    return <FormattedText condition={condition} />;
  };

  const renderConditions = (conditions: []) => (
    <Table size="small">
      <TableBody>
        {conditions.map((condition: any, index: number) => (
          <TableRow key={index}>
            <TableCell>
              <Box className={classes.resultDetails}>
                {condition.result ? (
                  <CheckCircleIcon className={classes.conditionTrue} />
                ) : (
                  <CancelIcon className={classes.conditionFalse} />
                )}

                {friendlyCondition(condition)}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const getAction = (row: any) => {
    if (!row.creditSubmissionId) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Button
              size="small"
              color="primary"
              variant="text"
              className={classes.button}
              onClick={() => {
                handleSubmitToLenderByProfileId(row.lenderProfileId);
              }}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      const decisionLbl = _.get(
        _.find(creditSubmissions, { creditSubmissionId: row.creditSubmissionId }),
        "decision",
        ""
      );

      const wasAutoSubmitted = _.get(row, "creditSubmissionAutoSubmitted");

      return (
        <Grid container>
          {wasAutoSubmitted && (
            <Grid item xs={12} className={classes.autoSubmittedLbl}>
              Auto submitted
            </Grid>
          )}
          <Grid item xs={12} className={classes.decisionLbl}>
            {decisionLbl}
          </Grid>
        </Grid>
      );
    }
  };

  const getRate = (row: any) => (
    <>
      <Typography component="span" variant="body2">
        {getRateSpecs(row, "rate")}
      </Typography>

      <Link
        component="button"
        style={{ fontSize: "12px" }}
        variant="button"
        onClick={() => {
          setTargetLenderProfileId(row.lenderProfileId);
          const targetFinanceProgram = _.find(financePrograms, {
            financeProgramId: row.financeProgramId,
          });
          if (targetFinanceProgram) {
            targetFinanceProgram.__typename = undefined;

            if (targetFinanceProgram.prescreenCriteria === null) {
              //no criteria linked, so remove this field to avoid error
              targetFinanceProgram.prescreenCriteria = undefined;
            } else {
              //set criteria ID to the prescreenCriteriaIdToLink field
              targetFinanceProgram.prescreenCriteriaIdToLink =
                targetFinanceProgram.prescreenCriteria.prescreenCriteriaId;
            }
            setFinanceProgram({
              ...targetFinanceProgram,
              lenderProfileIds: [row.lenderProfileId],
            });
            setOpenModal(true);
          }
        }}
      >
        {_.find(financePrograms, { financeProgramId: row.financeProgramId }) && (
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <LaunchIcon color="primary" fontSize="small" />
            <Typography component="span" variant="body2" style={{ textTransform: "capitalize" }}>
              Program Price
            </Typography>
          </Box>
        )}
      </Link>
    </>
  );

  const waterfallResultsEntries: [string, any[]][] = Object.entries(waterfallResults);

  const { contentViewType } = useViewTypeContext();

  const renderByViewType: Record<ContentViewType, ReactNode> = {
    [ContentViewType.Mobile]: (
      <EntityMobileCards<LenderWaterfallPrescreenResult, LenderWaterfallEntityMobile>
        groupBy="step"
        entities={prepareDataForMobile(waterfallResults)}
        entityAdapter={entity => mobileEntityAdapter(entity, getAction, getRateSpecs, getRate)}
      />
    ),
    [ContentViewType.Desktop]: (
      <>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="lender waterfall results table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell} style={{ backgroundColor: "#E8EAF6", width: "20px" }}>
                  Step
                </TableCell>
                <TableCell className={classes.headerCell} style={{ width: "8rem" }}>
                  Result
                </TableCell>
                {isUserHaveAccessToSubmit && <TableCell className={classes.headerCell}>Actions</TableCell>}
                <TableCell className={classes.headerCell}>Lender</TableCell>
                <TableCell className={classes.headerCell}>Program</TableCell>
                <TableCell className={classes.headerCell}>Max term (mos.)</TableCell>
                <TableCell className={classes.headerCell}>Interest Rate</TableCell>
                <TableCell className={classes.headerCell}>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!waterfallResultsEntries?.length ? (
                waterfallResultsEntries.map(([step, rows], stepIndex) => {
                  return (
                    <React.Fragment key={stepIndex}>
                      {rows.map((row: any, rowIndex: number) => {
                        const rowKey = `${stepIndex}-${rowIndex}`;
                        const isRowOpen = _.get(openRowMap, `[${stepIndex}][${rowIndex}].opened`, false);
                        const hasDetails = _.get(openRowMap, `[${stepIndex}][${rowIndex}].hasDetails`, false);
                        const rowSpan = countOpenedItems(openRowMap);

                        return (
                          <React.Fragment key={rowIndex}>
                            <TableRow>
                              {rowIndex === 0 && (
                                <TableCell className={classes.stepCell} rowSpan={_.size(rows) + rowSpan[stepIndex]}>
                                  <Typography component="span" variant="subtitle2">
                                    {row.step}
                                  </Typography>
                                </TableCell>
                              )}
                              <TableCell className={classes.tableCell}>
                                <Box className={classes.centredBox}>
                                  <Chip
                                    size="small"
                                    icon={
                                      row.result === "PASS" ? (
                                        <CheckCircleIcon style={{ color: "#388E3C" }} />
                                      ) : (
                                        <CancelIcon style={{ color: "#FF1744" }} />
                                      )
                                    }
                                    label={row.result}
                                    style={{ borderRadius: "0.3rem", padding: "0.3rem" }}
                                    className={row.result === "PASS" ? classes.chipPASS : classes.chipFAIL}
                                  />

                                  {hasDetails && (
                                    <IconButton
                                      size="small"
                                      aria-label="expand row"
                                      onClick={() => handleRowClick(stepIndex, rowIndex, rowKey)}
                                    >
                                      {isRowOpen ? (
                                        <RemoveCircleOutlineIcon color="primary" />
                                      ) : (
                                        <ControlPointIcon color="primary" />
                                      )}
                                    </IconButton>
                                  )}
                                </Box>
                              </TableCell>
                              {isUserHaveAccessToSubmit && (
                                <TableCell className={classes.tableCell}>{getAction(row)}</TableCell>
                              )}
                              <TableCell className={classes.tableCell}>
                                <Box className={classes.centredBox}>
                                  <LenderInfo lenderWaterfallResult={row} />
                                </Box>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Typography component="span" variant="body2">
                                  {_.get(row, "runtimeOutput.event.params.financeProgram.nameInternal")}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Typography component="span" variant="body2">
                                  {getRateSpecs(row, "term")}
                                </Typography>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Box className={classes.linkContent}>{getRate(row)}</Box>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <Typography component="span" variant="body2">
                                  {_.get(
                                    row,
                                    "runtimeOutput.event.params.financeProgram.paymentOptionsConfiguration.type"
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                            {isRowOpen && hasDetails && (
                              <TableRow className={classes.expandedRow}>
                                <TableCell style={{ padding: 0 }} colSpan={8}>
                                  <Collapse in={isRowOpen} appear timeout="auto" unmountOnExit>
                                    {renderConditions(_.get(row, "runtimeOutput.conditions.all", []))}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography component="span" variant="body1">
                        No data
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ),
  };

  return (
    <CardContainer contentViewType={contentViewType} title={`${prescreenName.full} Results`}>
      {renderByViewType[contentViewType]}

      <ProgramsPreScreen
        open={openModal}
        onClose={() => {
          setCreateProgram(false);
          setOpenModal(false);
        }}
        create={createProgram}
        isLenderUser={isLenderUser}
        financeProgram={financeProgram}
        lenderProfiles={lenderProfiles}
        refetchPrograms={fetchFinancePrograms}
        dealerVendorProfiles={dealerVendorProfiles}
        targetLenderProfileId={targetLenderProfileId}
      />
    </CardContainer>
  );
};

export default LenderWaterfallResults;

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
    "& th": {
      fontWeight: "bold",
      backgroundColor: "#f5f5f5",
      textAlign: "left",
      borderBottom: "2px solid #ddd",
      fontSize: "14px",
    },
    "& td": {
      borderBottom: "1px solid #ddd",
      fontSize: "12px",
    },
  },
  chipPASS: {
    color: "#388E3C",
    backgroundColor: "#DCEDC8",
  },
  chipFAIL: {
    color: "#FF1744",
    backgroundColor: "#FEEBEE",
  },
  stepCell: {
    backgroundColor: "#E8EAF6",
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    fontSize: "12px",
  },
  tableCell: {
    fontSize: "12px",
  },
  headerCell: {
    fontSize: "14px",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "12px",
  },
  button: {
    textTransform: "none",
    fontSize: "12px",
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: "small",
  },
  lenderName: {
    top: "1px",
    display: "inline-block",
  },
  conditionTrue: {
    color: "#388E3C",
  },
  conditionFalse: {
    color: "#FF1744",
  },
  conditionText: {
    marginLeft: theme.spacing(1),
    fontSize: "13px",
  },
  autoSubmittedLbl: {
    fontSize: "12px",
    color: "grey",
  },
  decisionLbl: {
    fontSize: "13px",
  },
  listStyleNone: {
    listStyleType: "none",
  },
  centredBox: {
    gap: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  linkContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  expandedRow: {
    backgroundColor: "#f9f9f9",
  },
  resultDetails: {
    display: "flex",
    padding: "0.7rem 0",
    alignItems: "center",
    gap: "0.5rem",
    fontSize: "0.7rem",
  },
}));
