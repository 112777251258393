import { CreditSubmission } from "@trnsact/trnsact-shared-types";
import { formatCurrency, getNested } from "../../../utils";
import { InvoiceDetailAPIKey } from "../types";

export const parseTableData = (cs: CreditSubmission, { label, apiKey, format }: InvoiceDetailAPIKey) => {
  const rowData = getNested(cs, apiKey);

  if (rowData) {
    if (format === "upperCase") {
      let formattedString;
      if (rowData.includes("_")) {
        const data = cs[apiKey] as string;

        formattedString = data
          .toLowerCase()
          .split("_")
          .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      } else {
        formattedString = rowData
          .toLowerCase()
          .split(" ")
          .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      }
      return [label, formattedString];
    } else if (format === "currency") {
      const formattedString = formatCurrency(rowData);
      return [label, formattedString];
    } else if (format === "date") {
      let inputDate = rowData;
      inputDate = inputDate.split("-");
      const outputDate = new Date(inputDate[0], inputDate[1], inputDate[2]).toLocaleDateString();
      return [label, outputDate];
    } else {
      return [label, rowData];
    }
  } else {
    return [label, null];
  }
};
