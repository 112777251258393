import React, { useMemo } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { EntityMobileCard } from "./EntityMobileCard";
import _ from "lodash";
import { BaseEntity, CommonPropsForAllViews } from "./types";
import { ValueOf } from "../../../global";
import { makeStyles } from "@material-ui/core/styles";

interface Props<Entity, CardEntity extends BaseEntity<Entity>> extends CommonPropsForAllViews<Entity, CardEntity> {
  entitiesForRender: CardEntity[];
  groupBy?: ValueOf<CardEntity, "group">;
}

export const EntityMobileCardsGroupedView = <Entity, CardEntity extends BaseEntity<Entity>>({
  entitiesForRender,
  groupBy,
  ...other
}: Props<Entity, CardEntity>) => {
  const classes = useStyles();

  const groupedEntitiesForRender = useMemo(() => _.groupBy(entitiesForRender, groupBy), [entitiesForRender, groupBy]);

  return (
    <>
      {Object.entries(groupedEntitiesForRender).map(([groupName, entities], groupIndex) => (
        <Accordion key={groupName} defaultExpanded elevation={0} classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            classes={{
              root: classes.accordionSummaryRoot,
              content: classes.accordionSummaryContent,
              expanded: classes.accordionSummaryExpanded,
            }}
            expandIcon={<ExpandMoreIcon fontSize="small" style={{ color: "black" }} />}
          >
            <Typography component="span" variant="subtitle2">
              {entities?.[0]?.groupName ?? `Group ${groupIndex + 1}`}
            </Typography>
          </AccordionSummary>

          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <Box className={classes.container}>
              {!!entities?.length &&
                entities.map(entity => <EntityMobileCard<Entity, CardEntity> entity={entity} {...other} />)}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  accordionRoot: {
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryRoot: {
    borderRadius: "4px",
    backgroundColor: indigo["50"],

    "&.Mui-expanded": {
      minHeight: "auto",
      marginBottom: "12px",
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  accordionSummaryExpanded: {
    "&.MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  accordionDetailsRoot: {
    padding: 0,
  },
  container: {
    gap: "8px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
});
