import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import gql from "graphql-tag";
// material ui

import { Grid, Switch } from "@material-ui/core";

import Table from "components/Table/Table";

import { DocumentContext } from "contexts/DocumentContext";
import { CircularProgress } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import _ from "lodash";
import { documentsByKey } from "../../Prequal/constants";
// theme components
const Q_DOCUMENTS_BY_VOID = gql`
  query($docId: ID, $VOId: ID) {
    documents(docId: $docId, VOId: $VOId) {
      documentId
      creditSubId
      vendorOpportunityId
      source
      docType
      docDescription
      createdBy
      createdDateTime
      docName
      documentCreditSubmission {
        creditSubmissionId
        lenderAccess
      }
      __typename
    }
  }
`;
const Q_PORTAL_CONFIGURATION_DOCUMENTS = gql`
  query($vendorProfileId: ID!, $types: [PortalConfigurationType]) {
    portalConfigurations(vendorProfileId: $vendorProfileId, types: $types) {
      portalConfigurationId
      vendorProfileId
      jsonDefinition
      type
      __typename
    }
  }
`;

const useStyles = makeStyles(theme => ({
  row: {
    border: "none",
    height: "12px !important",
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Roboto",
    // color: "#000",
    color: "red",
    border: "none",
  },
  tableCell: {
    border: "none",
    fontSize: 10,
    fontWeight: 400,
    fontFamily: "Roboto",
    color: "#000",
  },
}));

const tableHead = ["File Name", "Uploaded By", "Uploaded On", "Type", "Description", "Lender Access"];

export default function LenderDocumentAccess({ vo, vp: vendorProfile, handleSubmitCallback, lender }) {
  const classes = useStyles();
  const documentContext = useContext(DocumentContext);
  const [userDocs, setUserDocs] = useState([]);
  const [allowedDocuments, setAllowedDocuments] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [portalDocumentConfig, setPortalDocumentConfig] = useState([]);
  const [isDTFS, setIsDTFS] = useState(false);
  /**
   * Query for Portal Configuration Documents Settings by Vendor Profile
   */
  const { data: portalConfigurationData } = useQuery(Q_PORTAL_CONFIGURATION_DOCUMENTS, {
    variables: {
      vendorProfileId: vendorProfile.id,
      types: ["DOCUMENTS"],
    },
    fetchPolicy: "no-cache",
  });

  /**
   * Query for Documents by Vendor Opportunity
   */
  const { data: userDocsData, refetch: refetchDocs, loading: userDocsLoading, error: userDocsError } = useQuery(
    Q_DOCUMENTS_BY_VOID,
    {
      variables: {
        VOId: vo.vendorOpportunityId,
        docId: null,
      },
    }
  );

  const prepareMutationInput = async allowedDocumentsLocal => {
    //API receives the credit submission ID, the list of documents allowed and the list of documents not allowed
    const allDocuments = userDocsData.documents.map(doc => doc.documentId);

    const documents = allDocuments.map(doc => ({
      documentId: doc,
      lenderAccess: allowedDocumentsLocal.includes(doc),
    }));

    handleSubmitCallback(documents);
  };

  useEffect(() => {
    //refetch data after lastDoc context changed
    if (refetchDocs) refetchDocs();
  }, [documentContext.lastDoc]);

  useEffect(() => {
    if (lender) {
      setIsDTFS(_.get(lender, "submissionMethod", "") === "DTFS");
    }
  }, [lender]);

  /**
   * Toggle Allow Document ON/OFF
   *
   * @param {} documentId
   */
  const handleAllowDocument = documentId => {
    let copy = _.cloneDeep(allowedDocuments);

    if (copy.includes(documentId)) {
      copy = _.remove(copy, (element, index) => element !== documentId);
    } else {
      copy.push(documentId);
    }

    const allowedDocumentsLocal = _.filter(copy, e => !_.isEmpty(e));
    setAllowedDocuments(allowedDocumentsLocal);
    prepareMutationInput(allowedDocumentsLocal);
  };

  // useEffect(() => {
  //   prepareMutationInput();
  // }, [allowedDocuments]);

  useEffect(() => {
    if (userDocsLoading) {
      setLoadingState(true);
    } else {
      //larger loading period
      setTimeout(() => {
        setLoadingState(false);
      }, 1000);
    }
  }, [userDocsLoading]);

  useEffect(() => {
    if (userDocsData && userDocsData.documents != null) {
      const table = userDocsData.documents.map(
        ({ documentId, docType, docDescription, createdBy, createdDateTime, docName }) => {
          const checked = allowedDocuments.includes(documentId);

          return {
            color: checked ? "" : "disabled",
            data: [
              docName,
              createdBy,
              createdDateTime,
              docType,
              docDescription,
              <div
                key={documentId}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  padding: "16px 0",
                }}
              >
                <label style={{ marginRight: 4, color: "rgba(0,0,0,0.85)", fontWeight: 400 }}>Allow</label>
                <Switch
                  value={checked ? "true" : "false"}
                  checked={checked}
                  onChange={e => handleAllowDocument(documentId)}
                  defaultChecked={true}
                  color="primary"
                />
              </div>,
            ],
          };
        }
      );
      if (isDTFS) {
        table.push({
          color: "",
          data: [
            "Credit Application Snapshot",
            "Trnsact",
            new Date().toISOString(),
            "Snapshot",
            "Credit Application",
            <div
              key={"isDTFSDefaultDocumentEnabled-key"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "16px 0",
              }}
            >
              <label style={{ marginRight: 4, color: "rgba(0,0,0,0.85)", fontWeight: 400 }}>Allowed</label>
            </div>,
          ],
        });
      }
      setUserDocs(table);
    }
  }, [allowedDocuments, portalDocumentConfig]);

  useEffect(() => {
    if (vendorProfile) {
      if (
        userDocsData &&
        userDocsData.documents !== null &&
        portalConfigurationData &&
        portalConfigurationData?.portalConfigurations?.length > 0
      ) {
        let portalConfigDocs;
        let portalConfiguration = _.first(portalConfigurationData.portalConfigurations);
        portalConfigDocs = _(portalConfiguration)
          .chain()
          .get("jsonDefinition.config.documentsByTypeSettings")
          .pickBy((value, key) => value.hiddenForLenders)
          .keys()
          .value();
        setPortalDocumentConfig(portalConfigDocs);
        //Set to NOT ALLOWED all documents that are hidden for lenders in the portal configuration
        const targetByText = portalConfigDocs.map(e => _.get(documentsByKey, `${e}.text`));
        const targetByValue = portalConfigDocs.map(e => _.get(documentsByKey, `${e}.value`));

        setAllowedDocuments(
          _.filter(
            userDocsData.documents.map(function(doc) {
              console.log({ docType: doc.docType, targetByText, targetByValue });
              if (!targetByText.includes(doc.docType) && !targetByValue.includes(doc.docType)) {
                return doc.documentId;
              }
            }),
            e => !_.isEmpty(e)
          )
        );
      }
    }
  }, [vendorProfile, userDocsData, portalConfigurationData]);

  return (
    <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
      {loadingState && (
        <div className={classes.wrapperLoader}>
          <CircularProgress className="progressLoader" size={48} />
          <div className="headerContainer">
            <CloudDownload />
            <h4>Loading documents ...</h4>
          </div>
        </div>
      )}

      {!_.isEmpty(userDocs) && !loadingState ? (
        <Table tableHead={tableHead} tableData={userDocs} tableCellStyles={classes.tableCell} />
      ) : (
        !loadingState && (
          <Grid content>
            <Grid item>No documents available</Grid>
          </Grid>
        )
      )}
    </div>
  );
}
