import React from "react";
import { Box } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { FormInputSelect } from "../../../components/form";
import { VendorOpportunity } from "@trnsact/trnsact-shared-types";
import {
  cancelledStageCodeOptions,
  VOStageMapValueToString,
  stageCodeOptions,
  stageOptions,
  VOStatusMapValueToString,
  VOStatus,
  VOStage,
} from "./constants";
import {
  UPDATE_VO_STATUS_MUTATION,
  UPDATE_DYNAMICS_VO_STATUS_MUTATION,
  UPDATE_DYNAMICS_VO_STAGE_MUTATION,
  UPDATE_VO_STAGE_MUTATION,
} from "./queries";

interface Props {
  salesRep: string;
  customerName: string;
  vo: VendorOpportunity;
  refetchVO: () => Promise<any>;
}

export const VOStatusAndStageModifier = ({ vo, refetchVO, customerName, salesRep }: Props) => {
  const classes = useStyles();

  const [updateDynamicsVOStatus, { loading: isUpdatingDynamicsVOStatus }] = useMutation(
    UPDATE_DYNAMICS_VO_STATUS_MUTATION
  );
  const [updateVOStatus, { loading: isUpdatingVOStatus }] = useMutation(UPDATE_VO_STATUS_MUTATION);

  const handleStatusChange = async (nextValue: VOStatus) => {
    try {
      await updateDynamicsVOStatus({
        variables: { fieldsObj: { dynamicsVOStatus: nextValue }, entityId: vo.dynamicsVendorOpportunityId },
      });

      await updateVOStatus({
        variables: {
          fieldsObj: { applicationStage: VOStatusMapValueToString[nextValue] },
          VOId: vo.vendorOpportunityId,
        },
      });

      await refetchVO();
    } catch (err) {
      console.log(err);
    }
  };

  const [updateDynamicsVOStage, { loading: isUpdatingDynamicsVOStage }] = useMutation(
    UPDATE_DYNAMICS_VO_STAGE_MUTATION
  );
  const [updateVOStage, { loading: isUpdatingVOStage }] = useMutation(UPDATE_VO_STAGE_MUTATION);

  const handleStageChange = async (nextValue: VOStage) => {
    try {
      await updateDynamicsVOStage({
        variables: {
          fieldsObj: { dynamicsVOStage: nextValue },
          entityId: vo.dynamicsVendorOpportunityId,
        },
      });

      await updateVOStage({
        variables: {
          fieldsObj: { transactionStage: VOStageMapValueToString[nextValue] },
          emailInput: { customerName: customerName, salesRep: salesRep },
          VOId: vo.vendorOpportunityId,
        },
      });

      await refetchVO();
    } catch (err) {
      console.log(err);
    }
  };

  const isVOHaveCancelledStage = vo?.dynamicsVOStatus === VOStatus.Declined;
  const isLoading = isUpdatingDynamicsVOStatus || isUpdatingVOStatus || isUpdatingDynamicsVOStage || isUpdatingVOStage;

  return (
    <Box className={classes.container}>
      <FormInputSelect<VOStatus>
        size="small"
        label="Set stage"
        variant="standard"
        disabled={isLoading}
        options={stageOptions}
        onChange={handleStatusChange}
        value={vo?.dynamicsVOStatus ?? ""}
      />

      <FormInputSelect<VOStage>
        size="small"
        label="Set status"
        variant="standard"
        disabled={isLoading}
        onChange={handleStageChange}
        value={vo?.dynamicsVOStage ?? ""}
        options={isVOHaveCancelledStage ? cancelledStageCodeOptions : stageCodeOptions}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    padding: "12px",
    height: "100%",
    display: "flex",
    background: "white",
    flexDirection: "column",
    borderRadius: "0 6px 6px 0",
    justifyContent: "space-between",
  },
});
