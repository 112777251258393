import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { LenderProfiles } from "@trnsact/trnsact-shared-types/src/generated";
import { LendersFilter } from "./LendersFilter";
import { LenderCard } from "../lenderCard/LenderCard";
import { CardContainer } from "../../../../../components/shared/CardContainer/CardContainer";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  lenders: LenderProfiles[];
}

export const LendersList = ({ lenders = [] }: Props) => {
  const classes = useStyles();

  const [filteredLenders, setFilteredLenders] = useState<LenderProfiles[]>(lenders);

  return (
    <CardContainer title="Lenders" containerClassName="list" elevation={0}>
      <Box className={classes.listWithFilters}>
        <LendersFilter lenders={lenders} onFiltered={setFilteredLenders} />

        <Box className={classes.listContainer}>
          {!!filteredLenders.length &&
            filteredLenders.map(lender => <LenderCard key={lender.id} lender={lender} showProgramsAmount />)}
        </Box>
      </Box>
    </CardContainer>
  );
};

const useStyles = makeStyles({
  listWithFilters: {
    gap: "12px",
    display: "flex",
    flexDirection: "column",
  },
  listContainer: {
    gap: "4px",
    height: "53vh",
    display: "flex",
    overflow: "auto",
    paddingRight: "4px",
    flexDirection: "column",
  },
});
