import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Table from "components/Table/Table";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Box, CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { DCR_API_BASE_SERVER_URL } from "config";
import Tile from "../Tile/Tile";
import { useSelector } from "react-redux";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import _ from "lodash";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import { portalConfigurationTypes } from "../../pages/Prequal/constants";
import EditIcon from "@material-ui/icons/Edit";
import { TableFiltersPanel } from "../Table/TableFiltersPanel";
import { DEACTIVATE_DOC, QUERY_USER_DOCS } from "./queries";
import { logError } from "../../utils/logger";
import { DocumentsTableFilters } from "../DocumentsCommon/types";
import { filterTableDataByFiltersValues } from "../DocumentsCommon/lib";
import { NewCreditAppDocumentsModal, UpdateDocumentModal } from "../DocumentsCommon/modals";
import {
  creditSubmissionDocumentsFiltersInitialValue,
  creditSubmissionDocumentsTypesFiltersOptions,
} from "../DocumentsCommon/constants";
// ???????
import FileUpLoadModal from "../../copiedLP/src/pages/CreditSubmissions/FileUploadModal";
import { DocumentContext } from "../../copiedLP/src/contexts/DocumentContext";
import { CreditApplicationDetailsDialog } from "../../modules/creditApplicationDetails";
import { PageTypes } from "../../global";

interface Props {
  cs: any;
  CADMData: any;
  handleSetDocuments: any;
  triggerRefetchDocs: any;
  portalConfigurations: any;
}

export const DocumentsCard = ({
  cs,
  CADMData,
  triggerRefetchDocs,
  handleSetDocuments,
  portalConfigurations,
}: Props) => {
  const classes = useStyles();

  const account = useSelector((state: any) => state.account);
  const refetchDocs = useSelector((state: any) => state.docs.refetch);
  const lenderProfile = useSelector((state: any) => state.lenderProfile);

  const userProfile = useSelector((state: any) => state.userProfile);
  const vendorProfile = useSelector((state: any) => state.vp);

  const documentContext = useContext(DocumentContext);

  const [tableFilters, setTableFilters] = useState<DocumentsTableFilters>(creditSubmissionDocumentsFiltersInitialValue);

  // modals
  const [isUpdateDocumentModalOpen, setIsUpdateDocumentModalOpen] = useState<boolean>(false);
  const [isCreditAppModalOpen, setIsCreditAppModalOpen] = useState<boolean>(false);
  const [isOpenCADMv2ModalOpen, setIsOpenCADMv2ModalOpen] = useState<boolean>(false);

  const [creditApp, setCreditApp] = useState<any>();
  const [documents, setDocuments] = useState<any>([]);

  const [isCADMv2ForPDFOnly, setIsCADMv2ForPDFOnly] = useState(false);
  const [isPDFDownloaded, setIsPDFDownloaded] = useState(false);

  const [updateDocument, setUpdateDocument] = useState<any>(null);

  const documentsPortalConfiguration = _.find(portalConfigurations, { type: portalConfigurationTypes.documents });
  const creditAppHiddenForLenderProfileIdsSetting = _.get(
    documentsPortalConfiguration,
    "jsonDefinition.config.creditAppHiddenForLenderProfileIds"
  );
  const isNeedToHideCreditApp = _.includes(creditAppHiddenForLenderProfileIdsSetting, cs.dynamicsLenderProfileId);

  const { data: documentsData, refetch: refetchDocuments, loading } = useQuery(QUERY_USER_DOCS, {
    variables: { creditSubId: cs.creditSubId, VOId: cs.voId },
  });

  const [deactivateDocRequest, { loading: isDeactivatingDoc }] = useMutation(DEACTIVATE_DOC, {
    context: { authRequired: true },
  });

  useEffect(() => {
    if (documentsData) refetchDocuments();
  }, [refetchDocs, triggerRefetchDocs]);

  const handleDeactivateDoc = async (value: any) => {
    try {
      await deactivateDocRequest({ variables: { id: value } });
      await refetchDocuments();
    } catch (err) {
      logError(err);
    }
  };

  //Fetch document for the very first time:
  useEffect(() => {
    if (documentsData !== null && documentsData !== undefined && documentsData.creditSubmissionDocuments !== null) {
      let docsMerged = documentsData.creditSubmissionDocuments;

      if (
        documentsData.lenderDecisionLetters !== null &&
        documentsData.lenderDecisionLetters !== undefined &&
        documentsData.lenderDecisionLetters.length > 0
      ) {
        docsMerged = _.union(documentsData.creditSubmissionDocuments, documentsData.lenderDecisionLetters);
      }

      const docsToSet = docsMerged.map((row: any) => ({
        key: row.documentId,
        createdBy: row.createdBy,
        createdDateTime: row.createdDateTime,
        createdByUserProfileId: row.createdByUserProfileId,
        name: row.docName,
        uploaded_by: row.createdBy,
        description: row.docDescription,
        docType: row.docType,
        link: row.source,
      }));

      setDocuments(docsToSet);
      handleSetDocuments(docsToSet);
    }
  }, [documentsData]);

  useEffect(() => {
    //refetch data after lastDoc context changed
    refetchDocuments();
  }, [documentContext.lastDoc]);

  useEffect(() => {
    if (!isPDFDownloaded) return;

    setIsOpenCADMv2ModalOpen(false);
    setIsPDFDownloaded(false);
    setIsCADMv2ForPDFOnly(false);
  }, [isPDFDownloaded]);

  const handleCreditAppRequest = () => {
    if (CADMData) {
      setIsOpenCADMv2ModalOpen(true);
      return;
    }

    setIsCreditAppModalOpen(true);

    return axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-lender-pdf?transaction_id=${cs.pgTransactionId}`,
        {
          dealerName: cs.dealer,
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          download: false,
        },
        {
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "text/html",
          },
        }
      )
      .then(response => {
        setCreditApp(response.data);
      })
      .catch(logError);
  };

  const handleCreditAppRequestDownload = () => {
    if (CADMData) {
      setIsCADMv2ForPDFOnly(true);
      setIsOpenCADMv2ModalOpen(true);
      return;
    }

    return axios
      .post(
        `${DCR_API_BASE_SERVER_URL}/create-lender-pdf?transaction_id=${cs.pgTransactionId}`,
        {
          dealerName: cs.dealer,
          userProfile: userProfile,
          vendorProfile: vendorProfile,
          download: true,
          showCustomApp: lenderProfile.showCustomApp,
          lenderCode: lenderProfile.pdfTemplate,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      )
      .then(async response => {
        const fileURL = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", "credit_app.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(logError);
  };

  const tableRows = (tableData: any) => {
    return tableData.map((row: any) => {
      const isImOwnerOfDocument = row.createdByUserProfileId === userProfile?.id;

      return [
        row.name,
        row.createdBy,
        row.createdDateTime,
        row.docType,
        row.description,
        row.link ? (
          <Box className={classes.cell}>
            <Tooltip title="Download" placement="top">
              <IconButton size="small" href={row.link} target="_blank">
                <VerticalAlignBottomIcon color="primary" />
              </IconButton>
            </Tooltip>

            {isImOwnerOfDocument && (
              <>
                <Tooltip title="Edit" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setUpdateDocument(row);
                      setIsUpdateDocumentModalOpen(true);
                    }}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete" placement="top">
                  <IconButton size="small" disabled={isDeactivatingDoc} onClick={() => handleDeactivateDoc(row.key)}>
                    <DeleteOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
        ) : (
          <Tooltip title="Access Restricted" aria-label="Access Restricted" placement="top">
            <ReportProblemOutlinedIcon className={`${classes.reportProblemIcon}`} />
          </Tooltip>
        ),
      ];
    });
  };

  const tableHead = ["File name", "Uploaded By", "Uploaded On", "Type", "Description", "Actions"];

  const creditAppRow = [
    "Credit Application",
    "-",
    "-",
    "Trnsact",
    "Credit Application",
    <Box className={classes.cell}>
      <Tooltip title="View" placement="top">
        <IconButton size="small" onClick={handleCreditAppRequest}>
          <DescriptionIcon color="primary" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Download" placement="top">
        <IconButton size="small" onClick={handleCreditAppRequestDownload}>
          <VerticalAlignBottomIcon color="primary" />
        </IconButton>
      </Tooltip>
    </Box>,
  ];

  const tableData = useMemo(() => {
    const filteredDocs = filterTableDataByFiltersValues(documents, tableFilters, {
      type: "docType",
      search: "name",
    });

    let preparedTableRows = tableRows(filteredDocs);

    if (!isNeedToHideCreditApp) preparedTableRows.unshift(creditAppRow);

    return preparedTableRows;
  }, [isNeedToHideCreditApp, documents, tableFilters]);

  if (loading) {
    return (
      <Tile title="Documents">
        <div style={{ padding: "0 12px", display: "inline-block" }}>
          <CircularProgress />
        </div>
      </Tile>
    );
  }

  return (
    <>
      <Tile useCollapsing title="Documents" titleButtonComponent={<FileUpLoadModal cs={cs} />}>
        <Box mb="1rem">
          <TableFiltersPanel
            options={{
              type: {
                label: "Type",
                values: creditSubmissionDocumentsTypesFiltersOptions,
              },
            }}
            onOptionsChange={(filter, nextValue) => {
              setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
            }}
            onSearchChange={(filter, nextValue) => {
              setTableFilters(prev => ({ ...prev, [filter]: nextValue }));
            }}
          />
        </Box>

        <Table tableHead={tableHead} tableData={tableData} />
      </Tile>

      <NewCreditAppDocumentsModal
        creditApp={creditApp}
        isOpen={isCreditAppModalOpen}
        onClose={() => {
          setIsCreditAppModalOpen(false);
        }}
      />

      <CreditApplicationDetailsDialog
        isOpen={isOpenCADMv2ModalOpen}
        onClose={() => {
          setIsOpenCADMv2ModalOpen(false);
        }}
        data={{
          account,
          vo: CADMData?.vo,
          setIsPDFDownloaded,
          isEditingDisabled: true,
          applicant: cs?.applicant,
          isForPDFOnly: isCADMv2ForPDFOnly,
          pageType: PageTypes.CreditSubmission,
          equipmentData: CADMData?.equipmentData,
          vendorProfile: CADMData?.vendorProfile,
          creditAppModalConfig: CADMData?.CADMPortalConfiguration?.jsonDefinition,
        }}
      />

      <UpdateDocumentModal
        refetchDocuments={refetchDocuments}
        isOpen={isUpdateDocumentModalOpen}
        updateDocument={updateDocument}
        setUpdateDocument={setUpdateDocument}
        onClose={() => {
          setIsUpdateDocumentModalOpen(false);
        }}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: spacing(1),
    },
  },
  right: {
    textAlign: "right",
  },
  reportProblemIcon: {
    color: "#ec9c12",
  },
  cell: {
    gap: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));
